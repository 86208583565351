<template>
	<div name="vehicleDashboard">
		<AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

		<v-container v-if="isPermissionCanView">
			<v-row dense>
				<v-col cols="12" class="pb-2">
					<VehicleInformationCard :data="data" />
				</v-col>
				<v-col cols="12" class="pb-2">
					<v-card elevation="2" rounded="lg">
						<v-card-text>
							<CustomFilterDateTypingPickerRange
								placeholder="เลือก"
								title="เลือกช่วงเวลา"
								v-model="joinDate"
								:isRequired="false"
								:isSameLine="true"
								:initRange="initRangeDate"
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" class="pb-0">
					<VehicleInformationSummary :data="data" />
				</v-col>

				<v-col cols="12" class="pt-0 pb-3">
					<VehicleInformationNetProfitChart :data="data" />
				</v-col>

				<v-col cols="12" class="pt-0">
					<VehicleInformationScatterChart :data="data" />
				</v-col>
			</v-row>
		</v-container>
		<AppOverlay :isLoading="isLoading" />
		<AppPageNotFound v-if="!isPermissionCanView" />
	</div>
</template>
<script>
	import _ from "lodash";
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import AppOverlay from "@/components/AppOverlay.vue";
	import InputView from "@/components/InputView.vue";
	import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { processPermission } from "@/services/permissions";
	import { mapGetters } from "vuex";
	import VueApexCharts from "vue-apexcharts";
	import moment from "moment";
	import VehicleInformationCard from "./chart-components/VehicleInformationCard.vue";
	import { dateStringToDateFormat } from "@/services/appDate";
	import { URL_VEHICLE_LIST } from "@/services/routerQueryPath";
	import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
	import VehicleInformationSummary from "./chart-components/VehicleInformationSummary.vue";
	import VehicleInformationNetProfitChart from "./chart-components/VehicleInformationNetProfitChart.vue";
	import VehicleInformationScatterChart from "./chart-components/VehicleInformationScatterChart.vue";
	import { getVehicleReportInfoService } from "@/services/api/vehicle_report";

	export default {
		name: "VehicleDashboard",
		components: {
			CustomCardFormViewModern,
			AppOverlay,
			InputView,
			AppBreadCrumbs,
			AppPageNotFound,
			apexchart: VueApexCharts,
			VehicleInformationCard,
			CustomFilterDateTypingPickerRange,
			VehicleInformationSummary,
			VehicleInformationNetProfitChart,
			VehicleInformationScatterChart,
		},
		data() {
			return {
				breadcrumbsItems: [
					{
						text: "รายการรถโดยสาร",
						disabled: false,
						to: `/vehicle${URL_VEHICLE_LIST}`,
					},
					{
						text: "รายงานรถโดยสาร",
						disabled: true,
						to: "",
					},
				],
				data: null,
				joinDate: [],
				startDate: null,
				endDate: null,
				startDateCurrent: null,
				endDateCurrent: null,
				initRangeDate: 90,
				isPermissionCanView: false,
				isLoading: false,
				permList: {},
			};
		},

		computed: {
			...mapGetters({
				jobsStatusColor: "jobs/jobsStatusColor",
				jobsStatusText: "jobs/jobsStatusText",
			}),
		},
		watch: {
			"$route.params.id"(newId, oldId) {
				if (newId !== undefined) {
					this.startPage();
				}
			},
			joinDate(newDate, oldDate) {
				// console.log("joinDate", newDate);
				if (!_.isNil(newDate)) {
					// console.log("newDate", newDate);
					if (newDate.length === 2) {
						this.startDate = newDate[0];
						this.endDate = newDate[1];
						this.countDays = moment
							.duration(moment(this.endDate).diff(moment(this.startDate)))
							.asDays();

						if (
							this.startDateCurrent !== this.startDate ||
							this.endDateCurrent !== this.endDate
						) {
							let isSorted =
								moment(this.startDate).diff(moment(this.endDate)) <= 0;
							// console.log(moment(this.startDate).diff(moment(this.endDate)));
							if (isSorted) {
								this.startDateCurrent = this.startDate;
								this.endDateCurrent = this.endDate;
								this.getData();
							}
						}
					}
				}
			},
		},
		methods: {
			dateStringToDateFormat: dateStringToDateFormat,
			startPage() {
				if (this.$route.params.id !== undefined) {
					this._id = this.$route.params.id;
					this.initDate();
				} else {
					this.$router.push(`/vehicle${URL_VEHICLE_LIST}`);
				}
			},

			initDate() {
				let start = moment()
					.subtract(this.initRangeDate, "days")
					.format("YYYY-MM-DD");
				let end = moment().format("YYYY-MM-DD");
				// console.log(start, end);
				this.startDate = start;
				this.endDate = end;
				this.joinDate = [start, end];
			},
			async getData() {
				this.isLoading = true;
				try {
					const queryParams = new URLSearchParams({
						id: this._id,
						startdate: this.startDate,
						enddate: this.endDate,
					});

					const responseData = await getVehicleReportInfoService(
						this.$store,
						queryParams.toString()
					);
					if (responseData["code"] === 200) {
						this.data = responseData["data"];
					} else {
						throw "response failed";
					}

					// await getVehicleReportInfoService(this.$store);
				} catch (err) {}

				this.isLoading = false;
			},

			chartOptions(val) {
				return {
					chart: {
						height: 250,
						type: "scatter",
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
					},
					legend: { show: false },
					grid: {
						show: false,
					},
					markers: {
						colors: ["#AAB7B8", "#567DF4"],
						size: [7, 10],
					},

					annotations: {
						yaxis: [
							{
								y: 2.5,
								strokeDashArray: 5,
								borderWidth: 2,
								borderColor: "#AAB7B8",
							},
						],
						xaxis: [
							{
								x: 20,
								strokeDashArray: 5,
								borderWidth: 2,
								borderColor: "#AAB7B8",
							},
						],
					},
					xaxis: {
						tickAmount: 10,
						// title: {
						//   text: this.graphType[val],
						//   offsetX: -120,
						//   offsetY: 0,
						// },
					},
					yaxis: {
						tickAmount: 7,
						title: {
							text: "รายได้",
							offsetX: 0,
							offsetY: -80,
						},
					},
				};
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "VEHICLE");
					if (
						this.permList.admin_all === true ||
						this.permList.vehicle_report === true
					) {
						this.isPermissionCanView = true;
					}
				}
			},
		},
		mounted() {
			this.checkUserPermissions();
			if (this.isPermissionCanView) {
				this.startPage();
			}
		},
	};
</script>
