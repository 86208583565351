<template>
  <v-dialog v-model="show" width="900">
    <v-card v-if="show">
      <v-toolbar elevation="0" color="primary" dark>
        <p class="mt-4 px-3">
          <v-icon class="mr-2"> mdi-download </v-icon> นำเข้าเส้นทาง
        </p>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-autocomplete
              :key="`importRouteFromContractAutocomplete-${inputCounterKey}`"
              class="app-custom-input"
              :items="items"
              placeholder="ค้นหาโดยชื่อ"
              return-object
              filled
              outlined
              item-value="_id"
              item-text="name"
              ref="importRouteFromContractAutocomplete"
              autofocus
              v-model="searchValueInput"
              :loading="isLoading"
              :disabled="isLoading"
              :label="isLoading ? 'กำลังโหลดเส้นทาง' : 'เลือกเส้นทาง'"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pt-0">
            <v-autocomplete
              :key="`importRouteFromContractTimeLabelAutocomplete-${inputCounterKey}`"
              class="app-custom-input"
              :items="timeLabelItems"
              placeholder="ค้นหาโดยชื่อ"
              return-object
              filled
              outlined
              item-value="code"
              item-text="timeLabelText"
              ref="importRouteFromContractTimeLabelAutocomplete"
              autofocus
              v-model="searchTimeLabelValueInput"
              :loading="isLoading"
              :disabled="isLoading"
              :label="
                isLoading ? 'กำลังโหลดป้ายกำกับเวลา' : 'เลือกป้ายกำกับเวลา'
              "
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-form ref="routeForm" v-model="valid">
              <CustomDateTypingPickerFormInput
                placeholder="เลือก"
                label="วันที่ (วัน/เดือน/ปี)"
                v-model="timeLabelDate"
                :isDense="false"
                :isShowTitle="false"
                :disabled="
                  searchTimeLabelValueInput === null || isTimeTableNotSelected
                "
                :expendMargin="false"
                :is-required="true"
                :rules="
                  !isTimeTableNotSelected
                    ? [(v) => !!v || 'จำเป็นต้องเลือกวันที่']
                    : []
                "
              />
            </v-form>
          </v-col>
        </v-row>

        <div v-if="routeSelect !== null">
          <p class="text-app-title font-weight-medium mt-2 mb-0">คำอธิบาย</p>
          <p class="text-app-normal">{{ routeSelect.desc }}</p>
        </div>

        <p class="text-app-title font-weight-medium mt-3 mb-0">
          รายการจุดวิ่งรถ
        </p>
        <v-simple-table v-if="routeSelect !== null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับ</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทาง</th>
                <th class="text-left">ระยะเวลา</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="marker in routeSelect.route" :key="marker._id">
                <td>{{ marker.order }}</td>
                <td>{{ marker.name }}</td>
                <td>{{ processDistanceText(marker.distanceText) }}</td>
                <td>{{ processDurationText(marker.durationText) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-if="routeSelect === null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับ</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทาง</th>
                <th class="text-left">ระยะเวลา</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" colspan="4">
                  {{
                    isLoading || isTableLoading
                      ? `กำลังโหลดเส้นทาง`
                      : `เลือกเส้นทาง`
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-1"
          color="success"
          @click="save"
          :disabled="routeSelect === null"
        >
          เลือก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
import {
  getGeneratedRouteListService,
  getJobMarkerDetailTemplateImportService,
} from "@/services/api/jobs";

import {
  processDurationText,
  processDistanceText,
} from "@/services/appFuncHelpper";

import { getTemplateTimeLabelList } from "@/services/api/contract_job_routes";
import moment from "moment";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";

export default {
  name: "ImportRouteFromContractDialog",
  props: ["dialogStatus"],
  mounted() {},
  data() {
    return {
      //
      inputCounterKey: 0,
      searchValueInput: null,
      searchTimeLabelValueInput: null,
      timeLabelDate: null,
      routeSelect: null, // from detail route
      items: [],
      timeLabelItems: [],
      templateId: null,
      isTimeTableNotSelected: true,
      isLoading: true,
      isTableLoading: false,
      searchInput: false,
      //
      valid: null,
      show: false,
      contractId: null,
      // form data
      resolve: null,
      reject: null,
    };
  },
  watch: {
    searchValueInput: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          // this.getTimeLabelDataList();
          this.getRouteDetail();
        }
      },
    },

    searchTimeLabelValueInput: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          if (!_.isNil(newValue["code"])) {
            this.isTimeTableNotSelected = false;
          } else {
            this.isTimeTableNotSelected = true;
          }
        }
      },
    },
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    async open(contractId) {
      if (_.isNil(contractId)) {
        this.close();
      }

      this.contractId = contractId;
      this.resetForm();
      this.getDataList();

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(null);
      this.show = false;
    },
    resetForm() {
      this.searchValueInput = null;
      this.searchTimeLabelValueInput = null;
      this.isTimeTableNotSelected = true;
      this.timeLabelDate = null;
      this.routeSelect = null;
      this.items = [];
      this.timeLabelItems = [];
    },
    async getDataList() {
      this.isLoading = true;
      try {
        let queryString = new URLSearchParams({
          id: this.contractId,
        }).toString();
        const responseData = await getGeneratedRouteListService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.items = responseData["data"];
          setTimeout(() => {
            if (this.items.length > 0) {
              this.$refs.importRouteFromContractAutocomplete.$refs.input.focus();
              this.$refs.importRouteFromContractAutocomplete.$refs.menu.isActive = true;
            }
            // this.inputCounterKey += 1;
          }, 200);
        } else {
          throw "API_CALL_ERROR";
        }
      } catch (error) {
        console.log("error getDataList:", error);
      }
      this.isLoading = false;
    },

    async getRouteDetail() {
      this.routeSelect = null;
      this.isTableLoading = true;
      try {
        let queryStringParams = {
          id: this.searchValueInput["_id"],
        };

        const queryString = new URLSearchParams(queryStringParams).toString();
        const responseData = await getJobMarkerDetailTemplateImportService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.routeSelect = responseData["data"];
          this.processTimeLabelText();
        } else {
          throw "API_CALL_ERROR";
        }
      } catch (error) {
        console.log("error getRouteList:", error);
      }
      this.isTableLoading = false;
    },

    processTimeLabelText() {
      if (!_.isNil(this.routeSelect)) {
        this.timeLabelItems = this.routeSelect["timeLabel"];
        if (!_.isNil(this.timeLabelItems)) {
          this.timeLabelItems.forEach((label) => {
            let confirmDate = null;
            let startDate = null;
            let endDate = null;
            if (!_.isNil(label["confirmDate"])) {
              confirmDate = moment(
                label["confirmDate"],
                "YYYY-MM-DDTHH:mm"
              ).format("HH:mm");
            }

            if (!_.isNil(label["startDate"])) {
              startDate = moment(label["startDate"], "YYYY-MM-DDTHH:mm").format(
                "HH:mm"
              );
            }

            if (!_.isNil(label["endDate"])) {
              endDate = moment(label["endDate"], "YYYY-MM-DDTHH:mm").format(
                "HH:mm"
              );
            } else {
              endDate = moment(
                label["estimateEndDate"],
                "YYYY-MM-DDTHH:mm"
              ).format("HH:mm");
            }

            label[
              "timeLabelText"
            ] = `${label["name"]}: ${confirmDate} - ${startDate} - ${endDate}`;
          });
        }
        this.timeLabelItems.unshift({ timeLabelText: "ไม่ระบุ", code: null });
      }
    },

    processTotalDuration() {
      let total = 0;
      this.routeSelect["route"].forEach((route) => {
        if (!_.isNil(route) && !_.isNil(route["duration"])) {
          total += route["duration"];
        }
      });
      return total;
    },

    processTimeLabelDateTime() {
      if (!_.isNil(this.searchTimeLabelValueInput)) {
        if (!_.isNil(this.searchTimeLabelValueInput["confirmDate"])) {
          let confirmTime =
            this.searchTimeLabelValueInput["confirmDate"].split("T")[1];
          let selectedConfirmDateTime = `${this.timeLabelDate}T${confirmTime}`;
          this.searchTimeLabelValueInput["confirmDate"] =
            selectedConfirmDateTime;
        }
        if (!_.isNil(this.searchTimeLabelValueInput["startDate"])) {
          let startTime =
            this.searchTimeLabelValueInput["startDate"].split("T")[1];
          let selectedStartDateTime = `${this.timeLabelDate}T${startTime}`;
          this.searchTimeLabelValueInput["startDate"] = selectedStartDateTime;
        }
        if (!_.isNil(this.searchTimeLabelValueInput["endDate"])) {
          let endTime = this.searchTimeLabelValueInput["endDate"].split("T")[1];
          let selectedEndDateTime = `${this.timeLabelDate}T${endTime}`;
          this.searchTimeLabelValueInput["endDate"] = selectedEndDateTime;
        }
        if (!_.isNil(this.searchTimeLabelValueInput["estimateEndDate"])) {
          let estimateEndTime =
            this.searchTimeLabelValueInput["estimateEndDate"].split("T")[1];
          let selectedEstimateEndDateTime = `${this.timeLabelDate}T${estimateEndTime}`;
          this.searchTimeLabelValueInput["estimateEndDate"] =
            selectedEstimateEndDateTime;
        }
      }
    },

    async save() {
      if (this.$refs.routeForm.validate() === true) {
        this.processTimeLabelDateTime();
        let duration = this.processTotalDuration();

        this.resolve({
          // templateId: this.searchValueInput["_id"],
          text: this.searchValueInput["name"],
          value: this.searchValueInput["_id"],
          timeLabel: this.searchTimeLabelValueInput,
          totalDuration: duration,
          disableMarker: true,
        });
        this.show = false;
      } else {
        // alert error form
        console.log("FORM_VALIDATE_ERROR");
      }
    },
  },
  components: {
    CustomDateTypingPickerFormInput,
  },
};
</script>
