<template>
  <v-container name="RouteList" fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการเส้นทางหลัก</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AutoCompleteRouteSearching
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="AppAutoCompleteRouteSearchingRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>

        <!-- <v-col cols="auto">
					<v-menu
						v-model="isShowMenu"
						:close-on-content-click="false"
						:nudge-width="200"
						:nudge-bottom="10"
						:nudge-left="100"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="#D5DBDB"
								light
								large
								v-bind="attrs"
								v-on="on"
								elevation="0"
								outlined
								:style="{
									backgroundColor: 'white !important',
								}"
							>
								<v-icon left color="#4D5656"> mdi-filter </v-icon>
								<div class="normal-button-text">{{ "ตัวกรอง" }}</div>
							</v-btn>
						</template>

						<RouteFilterGroup
							:key="componentRefreshCounter"
							@close-menu="isShowMenu = false"
							@returnFiltedData="getFilterItem"
						/>
					</v-menu>
				</v-col> -->

        <v-col cols="auto">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="itemLists"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-row no-gutters class="pa-0">
              <v-card class="d-flex flex-row" flat tile width="320">
                <v-select
                  class="app-custom-input"
                  :items="sortList"
                  label="เรียงลำดับโดย"
                  dense
                  outlined
                  v-model="sortVal"
                  @change="tableSortHandler"
                ></v-select>
              </v-card>

              <v-card class="ml-3 d-flex flex-row" flat tile width="320"
                ><v-select
                  class="app-custom-input"
                  :items="filterContractTitleList"
                  v-model="contractNameValue"
                  label="สัญญาเดินรถ"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="value"
                  clearable
                  outlined
                  dense
                  filled
                  :loading="isLoadingContractList"
                  @change="tableContractHandler"
                ></v-select
              ></v-card>
            </v-row>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="valign-top pa-4">
              <router-link
                class="text-decoration-none"
                :to="{ name: 'routesEdit', params: { id: item._id } }"
              >
                {{ item.name }}
              </router-link>
            </td>
            <td class="valign-top pa-4">
              <div v-if="item.contract !== null">
                <p class="mb-0">
                  {{ item.contract }}
                </p>
              </div>
              <div v-else>-</div>
            </td>
            <td class="valign-top pa-4" style="text-align: center">
              <div v-if="item.totalCheckpoint !== 0">
                {{ item.totalCheckpoint ? item.totalCheckpoint : "-" }}
              </div>
              <div v-else>-</div>
            </td>

            <td class="valign-top pa-4">
              <div v-if="item.distance !== 0">
                {{ processDistanceText(item.distance) }}
              </div>
              <div v-else>-</div>
            </td>
            <td class="valign-top pa-4">
              <div v-if="item.duration !== 0">
                {{ processDurationText(item.duration) }}
              </div>
              <div v-else>-</div>
            </td>

            <td class="valign-top px-4 py-1">
              <v-list-item
                v-if="item.timeLabel !== null && item.timeLabel !== undefined"
                two-line
                class="px-0"
              >
                <v-list-item-content v-if="item.timeLabel.length > 0">
                  <div v-for="(time, idx) in item.timeLabel" :key="idx">
                    <span>
                      {{
                        time.confirmDate
                          ? timeStringFormat(time.confirmDate, false)
                          : "-"
                      }}
                      <v-icon>mdi-arrow-right-thin</v-icon>
                      {{
                        time.startDate
                          ? timeStringFormat(time.startDate, false)
                          : "-"
                      }}
                      <v-icon>mdi-arrow-right-thin</v-icon>
                      {{
                        time.endDate
                          ? timeStringFormat(time.endDate, false)
                          : time.estimateEndDate
                          ? timeStringFormat(time.estimateEndDate, false)
                          : "-"
                      }}
                    </span>
                    <div v-if="idx < item.timeLabel.length - 1">
                      ------------------------------------
                    </div>
                  </div>
                </v-list-item-content>
                <div v-else>-</div>
              </v-list-item>
              <div v-else>-</div>
            </td>

            <td class="valign-top pa-4">
              <div>
                <p class="mb-0 success--text" v-if="item.public">เผยแพร่</p>
                <p class="mb-0 error--text" v-else>วางแผน</p>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <CreateRoutesTemplateDialog ref="createRouteTemplateDialog" />
      <AppDialogConfirm ref="confirmDelete" />
    </div>
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>
</template>

<script>
import NormalButton from "@/components/NormalButton.vue";
import AutoCompleteRouteSearching from "./components/AutoCompleteRouteSearching.vue";
import { getContractTitleListService } from "@/services/api/contract";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";
import RouteFilterGroup from "./components/RouteFilterGroup.vue";
import {
  deleteRouteTemplate,
  getRouteTemplateListService,
} from "@/services/api/contract_job_routes";
import {
  processDurationText,
  processDistanceText,
} from "@/services/appFuncHelpper";
import PrimaryButton from "@/components/PrimaryButton.vue";
import CreateRoutesTemplateDialog from "./components/dialog/CreateRoutesTemplateDialog.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { processPermission } from "@/services/permissions";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { timeStringFormat } from "@/services/appDate";

export default {
  name: "RouteList",
  components: {
    NormalButton,
    AutoCompleteRouteSearching,
    RouteFilterGroup,
    PrimaryButton,
    CreateRoutesTemplateDialog,
    AppDialogConfirm,
    AppPageNotFound,
  },
  computed: {
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อเส้นทางหลัก",
          align: "start",
          valign: "top",
          sortable: false,
          value: "name",
          width: "20%",
        },
        {
          text: "ชื่อสัญญา",
          value: "contract",
          sortable: false,
          align: "left",
        },
        {
          text: "จำนวนจุดเป้าหมาย",
          value: "totalCheckpoint",
          sortable: false,
          align: "center",
        },

        {
          text: "ระยะรวมโดยประมาณ",
          value: "distance",
          sortable: false,
          align: "left",
        },
        {
          text: "เวลารวมโดยประมาณ",
          value: "duration",
          sortable: false,
          align: "left",
        },
        {
          text: "ป้ายกำกับเวลา",
          value: "timeLabel",
          sortable: false,
          align: "left",
          width: "20%",
        },
        {
          text: "สถานะ",
          value: "public",
          sortable: false,
          align: "left",
          width: "7%",
        },
      ];

      if (this.isPermissionCanView || this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "tools",
          sortable: false,
          align: "center",
        });
      }
      return defaultdata;
    },
  },
  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,

    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
      {
        text: "ชื่อเส้นทางหลัก เรียงจาก ก-ฮ",
        value: "name-asc",
      },
      {
        text: "ชื่อเส้นทางหลัก เรียงจาก ฮ-ก",
        value: "name-desc",
      },
      {
        text: "จำนวน Checkpoint จากน้อยไปมาก",
        value: "checkpoint-asc",
      },
      {
        text: "จำนวน Checkpoint จากมากไปน้อย",
        value: "checkpoint-desc",
      },
      {
        text: "ระยะโดยรวม จากน้อยไปมาก",
        value: "distance-asc",
      },
      {
        text: "ระยะโดยรวม จากมากไปน้อย",
        value: "distance-desc",
      },
      {
        text: "เวลาโดยรวม จากน้อยไปมาก",
        value: "duration-asc",
      },
      {
        text: "เวลาโดยรวม จากมากไปน้อย",
        value: "duration-desc",
      },
    ],
    filterContractTitleList: [],
    isLoadingContractList: false,
    contractNameValue: null,

    tableLoading: false,
    serverItemsLength: 0,
    itemLists: [],
    queryFilterList: {},
    queryStringSearch: "", // combin filter and search text
    querySearchAndFilterList: {}, // combine filter and search
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    sortVal: null,
    isShowMenu: false,
    //
    autoCompleteLoading: false,
    autoCompleteList: [],
    searchList: null,
    searchCountTimer: undefined,
    //
    permList: {
      admin_all: false,
    },
    isPermissionCanEdit: false,
    isPermissionCanView: false,
  }),

  methods: {
    timeStringFormat: timeStringFormat,
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    tableContractHandler(value) {
      // console.log("tableContractHandler", value);
      let _queryParameter = { contract: value };
      this.getFilterItem(_queryParameter);
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.contractNameValue = null;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },
    getSearchItem(_searchingParameter) {
      // console.log(_searchingParameter);
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["text"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter.value)) {
          _queryParameter["text"] = _searchingParameter["value"];
        }
      }

      // _searchingParameter
      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    getFilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      // console.log("getFilterItem", _queryParameter);
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }

      if (!_.isEmpty(_queryParameter)) {
        Object.keys(_queryParameter).forEach((key) => {
          if (_.isNil(_queryParameter[key])) {
            delete _queryParameter[key];
          }
        });
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    async getContractTitleList() {
      this.isLoadingContractList = true;
      // call api
      try {
        let responseData = await getContractTitleListService(this.$store);
        if (responseData["code"] === 200) {
          this.filterContractTitleList = responseData["data"];
        }
      } catch (error) {
        //
      }
      this.isLoadingContractList = false;
    },

    async getData() {
      this.tableLoading = true;
      this.itemLists = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getRouteTemplateListService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData["code"] === 200) {
        this.serverItemsLength = responseData.data["total"];
        this.itemLists = responseData["data"]["routeTemplateList"];
      } else {
        this.itemLists = [];
      }
      this.tableLoading = false;
    },
    async handleAddClick() {
      await this.$refs["createRouteTemplateDialog"].open();
    },
    handleEditClick(_id) {
      this.$router.push(`/caj/routes/edit/${_id}`);
    },
    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteRouteTemplate(this.$store, `id=${_id}`);
        this.getData();
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "CONTRACT");

        if (
          this.permList.admin_all === true ||
          this.permList.contract_route === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
  },

  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView === true) {
      this.processRouterQuery(this.$route.query);
    }
    this.getContractTitleList();
  },
};
</script>

<style scoped>
.valign-top {
  vertical-align: top;
}
</style>
