<template>
  <div class="my-5 px-3">
    <v-text-field
      v-model="inputValue"
      placeholder="จำนวนคน"
      :name="idName"
      :id="idName"
      @keyup.enter="keyupHandler"
      hide-details
      type="number"
      min="0"
      oninput="validity.valid||(value='');"
      solo
      flat
      dense
      :class="[
        { 'tb-cell-input-pair': isBetweenPair },
        { 'tb-cell-input-no-pair': !isBetweenPair },
        'assign-multiple-job-tb-cell-input',
      ]"
      style="width: 100px"
    ></v-text-field>
  </div>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { putMultipleJobTableCellDataService } from "@/services/api/assign_multiple_job";
export default {
  name: "AssignMultipleJobDriverTableCellInputState",
  components: { CustomTextInputImprove },
  props: {
    value: {},
    idName: {
      require: true,
    },
    tableId: {
      require: true,
    },
    dataRowIdx: {
      require: true,
    },
    dataColsIdx: {
      require: true,
    },
    vuexRowIdx: {
      require: true,
    },
    vuexColsIdx: {
      require: true,
    },
  },

  data: () => ({
    _inputValue: null,
  }),

  computed: {
    inputValue: {
      // getter
      get: function () {
        return _.get(
          this.$store.state["assignMultipleJob"],
          `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}.numberOfSelectedDriver`,
          null
        );
      },
      // setter
      set: function (newValue) {
        this._inputValue = newValue;
      },
    },
    isBetweenPair: function () {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `columnsData.${this.tableId}.${this.sheetColIdx}.isBetweenPair`,
        false
      );
    },
  },

  data: () => ({}),

  methods: {
    async keyupHandler() {
      try {
        this.$emit("next-input-handler", {
          rowIdx: this.vuexRowIdx,
          colIdx: this.vuexColsIdx,
          tableId: this.tableId,
        });

        this._inputValue = parseInt(this._inputValue);

        this.$store.dispatch("assignMultipleJob/updateInputCell", {
          rowIdx: this.vuexRowIdx,
          colIdx: this.vuexColsIdx,
          tableId: this.tableId,
          data: {
            inputState: -1,
          },
        });

        const currentCellData = _.get(
          this.$store.state["assignMultipleJob"],
          `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}`,
          null
        );

        //
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();
        // // //
        const responseData = await putMultipleJobTableCellDataService(
          this.$store,
          queryString,
          {
            colsId: currentCellData["tableColsId"],
            rowId: currentCellData["tableRowsId"],
            numberOfSelectedDriver: this._inputValue || 0,
          }
        );
        // assign add cell
        if (responseData["code"] === 200) {
          this.$store.dispatch(
            "assignMultipleJob/updateInputCellDirectly",
            responseData["data"]
          );
        } else {
          this.$store.dispatch("assignMultipleJob/updateInputCell", {
            rowIdx: this.vuexRowIdx,
            colIdx: this.vuexColsIdx,
            tableId: this.tableId,
            data: {
              inputState: -2,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      //

      // this.$store.dispatch("assignMultipleJob/updateInputCell", {
      //   rowIdx: this.sheetRowIdx,
      //   colIdx: this.sheetColIdx,
      //   tableId: this.tableId,
      //   numberOfSelectedDriver: this._inputValue || 0,
      // });

      // // emit next
    },
  },
};
</script>

<style scoped>
.assign-multiple-job-tb-cell-input {
  border: 2px solid #354253;
}

.assign-multiple-job-tb-cell-input >>> input {
  text-align: center;
  -moz-appearance: textfield;
}

.assign-multiple-job-tb-cell-input >>> input::-webkit-outer-spin-button,
.assign-multiple-job-tb-cell-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.tb-cell-input-pair {
  background-color: #ffffff;
}
.tb-cell-input-no-pair {
  background-color: #ecf1f8;
}
</style>
