<template>
  <!--  <v-chip-group v-model="valueInput" color="primary" :multiple="true" column>  </v-chip-group>-->

  <div class="d-flex flex-row" style="width: max-content">
    <!-- <v-chip
      :key="'ALL'"
      value="all"
      :disabled="disabled"
      @click="selectStatus('all')"
      class="mr-1"
      filter
      outlined
    >
      <v-icon v-if="valueInput.includes('all')"> mdi-check </v-icon>
      <span class="px-1">ทั้งหมด</span>
    </v-chip> -->

    <v-chip
      class="mr-1"
      v-for="(value, key) in filterJobsStatusObj"
      :key="key"
      :value="key.toLowerCase()"
      :color="jobsStatusColor(key)"
      :disabled="disabled"
      outlined
      filter
      @click="selectStatus(key)"
    >
      <v-icon v-if="valueInput.includes(key.toLowerCase())"> mdi-check </v-icon>
      <span class="px-1">{{ value }} </span>
    </v-chip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "JobsFilterStatus",
  mounted() {
    // this.getParamQuery();
  },
  props: {
    value: {},
    disabled: {
      required: true,
    },
  },
  data: () => ({
    queryOrder: {
      status: "status",
    },
  }),
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "status":
              this.settingValue = this.$route.query[key];
          }
        }
      });
    },
    selectStatus(key) {
      const lkey = key.toLowerCase();
      if (this.valueInput.includes(lkey)) {
        this.valueInput = this.valueInput.filter((item) => item !== lkey);
      } else {
        this.valueInput = _.concat(this.valueInput, [lkey]);
      }
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusObj: "jobs/jobsStatusObj",
      jobsStatusKeyMapObj: "jobs/jobsStatusKeyMapObj",
    }),
    filterJobsStatusObj() {
      return _.pick(this.jobsStatusObj, [
        this.jobsStatusKeyMapObj.PREPARING,
        this.jobsStatusKeyMapObj.CONFIRM,
        this.jobsStatusKeyMapObj.PROCESS,
      ]);
    },

    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped></style>
