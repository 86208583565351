<template>
  <v-alert
    dense
    colored-border
    color="#B2C8DF"
    icon="mdi-gas-station"
    class="mb-2"
    border="left"
    ><span class="text-title">เติมน้ำมันล่าสุด</span>

    <span class="float-right">
      <router-link
        v-if="docData !== null"
        class="d-block text-decoration-none text-app-detail-normal mt-1 text-decoration-underline"
        :to="`/vehicle/fuel?text=${docData.licenseNumber}`"
        >ประวัติ
      </router-link>
    </span>
    <div
      v-if="
        docData.last_diff_fuel_payment !== null &&
        docData.last_diff_fuel_payment !== 0
      "
      class="text-app-small-detail"
    >
      <p class="ma-0">
        เติมครั้งล่าสุด: {{ docData.last_diff_fuel_payment }} วันก่อน
      </p>

      <span>เมื่อวันที่: </span
      ><span> {{ processDate(docData.last_fuel_payment) }}</span>
    </div>
    <div v-else class="text-app-small-detail">ไม่มีข้อมูล</div>
  </v-alert>
</template>

<script>
import { dateStringToDateFormat } from "@/services/appDate";
export default {
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    docData: {
      get() {
        return this.data;
      },
      set(val) {},
    },
  },
  methods: {
    processDate(_value) {
      return dateStringToDateFormat(_value, {
        returnThai: false,
      });
    },
  },
};
</script>

<style scoped>
.detail-section {
  font-size: 14px;
  color: var(--v-bodyText);
}
</style>
