<template>
  <div name="vehicleDashboard">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <div class="mb-3" v-if="isPermissionCanView">
            <v-card elevation="2" rounded="lg">
              <v-card-title class="px-10">
                <div class="py-3 text-title font-weight-bold black--text">
                  รายได้รวม
                </div>
                <v-spacer></v-spacer>

                <span class="text-app-detail-normal"
                  ><span class="dashboard-small font-weight-bold black--text"
                    >12,345</span
                  >
                  บาท</span
                >
              </v-card-title>
            </v-card>
          </div>
        </v-col>
        <v-col cols="4" class="pl-3">
          <div class="mb-3" v-if="isPermissionCanView">
            <v-card elevation="2" rounded="lg">
              <v-card-title class="px-10">
                <div class="py-3 text-title font-weight-bold black--text">
                  รายจ่ายรวม
                </div>
                <v-spacer></v-spacer>

                <span class="text-app-detail-normal"
                  ><span class="dashboard-small font-weight-bold black--text"
                    >12,345</span
                  >
                  บาท</span
                >
              </v-card-title>
            </v-card>
          </div>
        </v-col>
        <v-col cols="4" class="pl-3">
          <div class="mb-3" v-if="isPermissionCanView">
            <v-card elevation="2" rounded="lg">
              <v-card-title class="px-10">
                <div class="py-3 text-title font-weight-bold black--text">
                  Margin รวม
                </div>
                <v-spacer></v-spacer>

                <span class="text-app-detail-normal"
                  ><span class="dashboard-small font-weight-bold black--text"
                    >12,345</span
                  >
                  บาท</span
                >
              </v-card-title>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-card class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายรับ/รายจ่าย
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="pr-9">
                <div id="chartArea" class="pl-0">
                  <apexchart
                    type="area"
                    height="350"
                    :options="chartOptionsArea"
                    :series="seriesArea"
                  ></apexchart>
                </div>
                <span
                  class="mx-12 px-0 text-app-small-detail font-weight-bold black--text"
                  >วันอาทิตย์</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <span class="mx-6 mb-6 text-app-detail-normal">
            <span class="font-weight-bold black--text">Lorem Ipsum</span> is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. It has survived not only five
            centuries, but also the leap into electronic typesetting, remaining
            essentially unchanged.
          </span>
        </v-row>
      </v-card>

      <v-row no-gutters>
        <v-col cols="12" class="pr-0">
          <div class="mb-3" v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="รายรับ/รายจ่าย"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="4" class="px-3 mt-3">
                  <div>
                    <span class="text-app-detail-normal">จำนวนงานทั้งหมด</span>
                  </div>
                  <div class="mt-3">
                    <span
                      class="text-app-detail-large font-weight-bold black--text"
                      >123</span
                    >
                    <span class="text-app-detail-normal"> งาน</span>
                  </div>
                </v-col>
                <v-col cols="4" class="px-3 mt-3">
                  <div>
                    <span class="text-app-detail-normal"
                      >จำนวนงานเฉลี่ยต่อวัน</span
                    >
                  </div>
                  <div class="mt-3">
                    <span
                      class="text-app-detail-large font-weight-bold black--text"
                      >12</span
                    >
                    <span class="text-app-detail-normal"> งาน</span>
                  </div>
                </v-col>
                <v-col cols="4" class="px-3 mt-3">
                  <div>
                    <span class="text-app-detail-normal"
                      >รายได้ต่องานโดยเฉลี่ย</span
                    >
                  </div>
                  <div class="mt-3">
                    <span
                      class="text-app-detail-large font-weight-bold black--text"
                      >1,234</span
                    >
                    <span class="text-app-detail-normal"> บาท</span>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mx-6 my-3" />
              <v-row no-gutters class="px-3 pb-3">
                <v-col
                  cols="2"
                  v-for="(value, key) in jobsStatusObj"
                  :key="key"
                  class="px-3 mt-3"
                >
                  <span class="mr-2">
                    <div>
                      <!-- <v-icon :color="jobsStatusColor(key)">mdi-circle</v-icon> -->
                      <span :class="['px-1', `${jobsStatusColor(key)}--text`]"
                        >{{ value }}
                      </span>
                    </div>
                    <div class="mt-3 ml-0">
                      <span class="dashboard-small font-weight-bold black--text"
                        >12</span
                      >
                      <span class="text-app-detail-normal"> งาน</span>
                    </div>
                  </span>
                </v-col>
              </v-row>
              <!-- <AppSubmitFormButton
              @on-click-save="saveChange()"
            />  -->
            </CustomCardFormViewModern>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-3">
          <div class="mb-3" v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="ระยะทางที่วิ่ง"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">ทั้งหมด</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> กม.</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">เฉลี่ยต่อวัน</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >123</span
                  >
                  <span class="text-app-detail-normal"> กม.</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >รายได้โดยเฉลี่ยต่อ 1 กม.</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> บาท</span>
                </v-col>
              </v-row>
            </CustomCardFormViewModern>
          </div>
        </v-col>

        <v-col cols="6" class="pr-0">
          <div class="mb-3" v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="เวลาทำงาน"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">ทั้งหมด</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >123</span
                  >
                  <span class="text-app-detail-normal"> ชม.</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">เฉลี่ยต่อวัน</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >12</span
                  >
                  <span class="text-app-detail-normal"> ชม.</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >รายได้โดยเฉลี่ยต่อ 1 ชั่วโมง</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> บาท</span>
                </v-col>
              </v-row>
            </CustomCardFormViewModern>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="pr-3">
          <div class="mb-3" v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="ผู้ขับร่วม"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">ทั้งหมด</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >12</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">รายได้เฉลี่ยต่อคน</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> บาท</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >จำนวนงานโดยเฉลี่ยต่อคนต่อวัน</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >3</span
                  >
                  <span class="text-app-detail-normal"> งาน</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >ระยะทางโดยเฉลี่ยต่อคนต่อวัน</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >123</span
                  >
                  <span class="text-app-detail-normal"> กม.</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >เวลาโดยเฉลี่ยต่อคนต่อวัน</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >8</span
                  >
                  <span class="text-app-detail-normal"> ชม.</span>
                </v-col>
              </v-row>
            </CustomCardFormViewModern>
          </div>
        </v-col>
        <v-col cols="6" class="pr-0">
          <div class="mb-3" v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="รถร่วม"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">ทั้งหมด</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >12</span
                  >
                  <span class="text-app-detail-normal"> คัน</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col class="px-3 mt-3">
                  <span class="text-app-detail-normal">รายได้เฉลี่ยต่อคัน</span>
                </v-col>
                <v-spacer />
                <v-col class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> บาท</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal">จำนวนประเภทรถ</span>
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >3</span
                  >
                  <span class="text-app-detail-normal"> ประเภท</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="px-3 pb-3 mb-12">
                <v-col cols="8" class="px-3 mt-3">
                  <span class="text-app-detail-normal"
                    >รายได้เฉลี่ยของแต่ละประเภทรถ</span
                  >
                </v-col>
                <v-spacer />
                <v-col cols="4" class="px-3 mt-2 text-end">
                  <span class="dashboard-small font-weight-bold black--text"
                    >1,234</span
                  >
                  <span class="text-app-detail-normal"> บาท</span>
                </v-col>
              </v-row>
            </CustomCardFormViewModern>
          </div>
        </v-col>
      </v-row>

      <AppDialogConfirm ref="alertSaving" />
      <AppDialogConfirm ref="dialogAlert" />
      <AppOverlay :isLoading="isLoading" />
      <AppPageNotFound :show="isPermissionCanView" />
    </v-container>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { getAppDataService } from "@/services/api/app";
import { errorMessageMapper } from "@/services/error";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
import _ from "lodash";
import {
  getVehicleDocsVelist,
  getVehicleDocsService,
  postVehicleDocsService,
  putVehicleDocsService,
} from "@/services/api/vehicle";
import { URL_CONTRACT_LIST } from "@/services/routerQueryPath";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import { URL_VEHICLE_DOC_INS } from "@/services/routerQueryPath";
import { URL_VEHICLE_LIST } from "@/services/routerQueryPath";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  name: "ContractDashboard",
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    CustomAutocomplete,
    AppOverlay,
    CustomSelectsArrOfObj,
    CustomDateTypingPickerFormInput,
    AppDialogConfirm,
    AppFilesUploadVue,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomTextInputImprove,
    AppSubmitFormButton,
    FormDataUploadInput,
    CustomTextInputCurrency,
    AppComboboxVehicleSearching,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "รายการสัญญาเดินรถ",
          to: `/caj${URL_CONTRACT_LIST}`,
          disabled: false,
          exact: true,
        },
        {
          text: "รายงานสัญญา",
          disabled: true,
          to: "",
        },
      ],
      title: "เพิ่มข้อมูลประกันภัยรถ",

      valid: false,
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      isLoading: false,
      docType: "ins",
      input: 1,
      companyType: "com_ins",
      docsCompanySelected: null,
      docsCompanyList: [],
      // vehicleList: null,
      _id: null,
      isFail: false,
      info: null,
      vehicleSearch: null,

      upFiles: [], // wait from appFile component
      upImages: [], // wait from appFile component
      localUpFile: [],
      localUpImages: [],

      formData: {
        doc: null,
        vehicle: null,
        type: null,
        amount: null,
        company: null,
        issueDate: null,
        expiryDate: null,
        files: [],
        images: [],
      },
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {},

      seriesArea: [
        {
          name: "รายได้ที่ได้รับทั้งหมด",
          data: [9100, 9000, 9800, 8100, 7200, 10900, 10000],
        },
        {
          name: "รายจ่ายทั้งหมด",
          data: [1100, 3200, 4500, 3200, 3400, 5200, 4100],
        },
      ],

      chartOptionsArea: {
        chart: {
          height: 350,
          type: "area",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#49c496", "#ed5555"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["1", "2", "3", "4", "5", "6"],
        },
        annotations: {
          yaxis: [
            {
              y: 9000,
              strokeDashArray: 1,
              borderWidth: 2,
              borderColor: "#AAB7B8",
              label: {
                text: "รายได้เฉลี่ย: 9,000 บาท",
              },
            },
            {
              y: 3000,
              strokeDashArray: 1,
              borderWidth: 2,
              borderColor: "#AAB7B8",
              label: {
                text: "รายจ่ายเฉลี่ย: 3,000 บาท",
              },
            },
          ],
        },
        tooltip: {
          x: {
            show: true,
            formatter: function (value, opts) {
              if (opts) {
                // var val =
                //   opts.w.config.series[opts.seriesIndex]["data"][
                //     opts.dataPointIndex
                //   ];
                // console.log("val", val);
                return `<span class="text-app-title font-weight-bold black--text">สัปดาห์ที่ ${
                  opts.dataPointIndex + 1
                }</span>`;
              }
            },
          },
        },
      },

      graphType: {
        DISTANCE: "ระยะทาง",
        HOURS: "ชั่วโมงทำงาน",
        JOBS: "จำนวนงาน",
      },
    };
  },

  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      jobsStatusObj: "jobs/jobsStatusObj",
    }),
  },
  watch: {
    upFiles(val) {
      // console.log(val);
    },
    upImages(val) {
      // console.log(val);
    },
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },
    vehicleSearch(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          this.formData["vehicle"] = _.cloneDeep(value);
        } else {
          this.formData["vehicle"] = null;
        }
      }
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    startPage() {
      if (this.$route.params.id !== undefined) {
        this.title = "แก้ไขข้อมูลประกันภัยรถ";
        this._id = this.$route.params.id;
      } else {
        this._id = null;
        this.title = "เพิ่มข้อมูลประกันภัยรถ";
      }
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      if (this.isPermissionCanView) {
        // let respVelist = await getVehicleDocsVelist(this.$store);
        // if (respVelist["code"] === 200) {
        //   this.vehicleList = respVelist.data;
        // } else {
        //   this.isFail = true;
        // }
        this.docsCompanyList = await getAppDataService(
          this.$store,
          this.companyType
        );

        if (!this.isFail) {
          if (this._id !== null) {
            let params = new URLSearchParams({
              id: this._id,
              doc: this.docType,
              input: this.input,
            }).toString();
            let respData = await getVehicleDocsService(this.$store, params);
            if (respData["code"] === 200) {
              this.formData = _.clone(respData["data"]);
              if (!_.isNil(this.formData["vehicle"])) {
                this.vehicleSearch = _.cloneDeep(this.formData["vehicle"]);
              }
              // reset
              this.upImages = [];
              this.upFiles = [];
              this.localUpFile = [];
              this.localUpImages = [];
              this.localUpFile = _.clone(this.formData["files"]);
              this.localUpImages = _.clone(this.formData["images"]);
            } else {
              this.isFail = true;
            }
          }
        }
      }

      this.isLoading = false;
    },

    chartOptions(val) {
      return {
        chart: {
          height: 250,
          type: "scatter",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        legend: { show: false },
        grid: {
          show: false,
        },
        markers: {
          colors: ["#AAB7B8", "#567DF4"],
          size: [7, 10],
        },

        annotations: {
          yaxis: [
            {
              y: 2.5,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: 20,
              borderColor: "#AAB7B8",
            },
          ],
        },
        xaxis: {
          tickAmount: 10,
          // title: {
          //   text: this.graphType[val],
          //   offsetX: -120,
          //   offsetY: 0,
          // },
        },
        yaxis: {
          tickAmount: 7,
          title: {
            text: "รายได้",
            offsetX: 0,
            offsetY: -80,
          },
        },
      };
    },

    async saveChange() {
      if (this.$refs.form.validate() === true) {
        this.isLoading = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.formData.doc = this.docType;

        let _toUpdateByAPI = false;
        let resUploadFile = await this.$refs["vehicleCRUDUploadFile"].upload();
        let respUploadImage = await this.$refs[
          "vehicleCRUDUploadImageFile"
        ].upload();

        if (this.docsCompanySelected !== null) {
          this.formData.company["key"] = this.docsCompanySelected.key;
          this.formData.company["nameTh"] = this.docsCompanySelected.nameTh;
        }

        // check file upload is faill alert error
        // TODO::

        if (!resUploadFile.status || !respUploadImage.status) {
          // is upload has error response restore all file
          this.callUploadFileErrorDialog();
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
          // this.disableLoading();
        } else {
          const sendingFormData = _.cloneDeep(this.formData);
          sendingFormData.files = resUploadFile.result;
          sendingFormData.images = respUploadImage.result;
          if (this._id !== null) {
            let resp = await putVehicleDocsService(
              this.$store,
              this._id,
              sendingFormData
            );
            if (resp["code"] === 200) {
              this.isLoading = false;
              this.alertSaveChange(this._id);
              _toUpdateByAPI = true;
              this.getData();
            } else {
              _toUpdateByAPI = false;
            }
          } else {
            let resp = await postVehicleDocsService(
              this.$store,
              sendingFormData
            );
            this.isLoading = false;
            if (resp["code"] === 200) {
              _toUpdateByAPI = true;
              this.alertSaveChange(this._id);
              this.resetFormData();
              this.$router.push(`/vehicle${URL_VEHICLE_DOC_INS}`);
            } else {
              _toUpdateByAPI = false;
            }
          }
        }
        if (_toUpdateByAPI === false) {
          // alert fail to update data
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
        }
        this.isLoading = false;
      }
    },

    // ==== for app upload ====
    remove_upfile(index) {
      this.$refs.mulFilesUpload.removeInput(index);
    },
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    // ====
    remove_localImagefile(atLocation) {
      this.localUpImages.splice(atLocation, 1);
    },
    remove_localUpfile(atLocation) {
      this.localUpFile.splice(atLocation, 1);
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    async callUploadImageErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_EXPENSES");
        if (
          this.permList.admin_all === true ||
          this.permList.vehicle_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },

    resetFormData() {
      this.formData = {
        doc: null,
        vehicle: null,
        type: null,
        amount: null,
        company: null,
        issueDate: null,
        expiryDate: null,
        files: [],
        images: [],
      };
      this.clearInput();
    },
    clearInput() {
      this.docsCompanySelected = null;
      this.docsCompanyList = [];
      this.vehicleSearch = null;
      // this.vehicleList = null;
      this.upFiles = [];
      this.upImages = [];
      this.localUpFile = [];
      this.localUpImages = [];
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.startPage();
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}
.image_list_float_left {
  float: right;
}
.dashboard-large {
  font-size: 80px !important;
}

.dashboard-medium {
  font-size: 40px !important;
}
.dashboard-small {
  font-size: 30px !important;
}
</style>
