<template>
  <v-file-input
    :background-color="getBackgrounddColor()"
    :small-chips="smallChips"
    show-size
    :placeholder="placeholder"
    :multiple="multiple"
    class="app-custom-input"
    outlined
    dense
    :accept="
      allowImageOnly ? 'image/x-png,image/png,image/jpeg,image/jpg' : '.pdf'
    "
    v-model="localData"
    :loading="loading"
    :disabled="disabled"
    :prepend-icon="!isPrependInnerIcon ? icon : ''"
    :prepend-inner-icon="isPrependInnerIcon ? icon : ''"
    ref="componentFileInput"
    :rules="rules"
  >
  </v-file-input>
</template>

<script>
import _ from "lodash";

import {
  getUploadAuthConfig,
  uploadPath,
  uploadWithScalePath,
} from "@/services/api/my_axios";
import axios from "axios";
import { regUserFace } from "@/services/api/face";

export default {
  name: "AppFileUploadComponent",
  props: {
    value: {},
    placeholder: {
      type: String,
      default: "เลือกรูปภาพ",
    },
    icon: {
      type: String,
      default: "mdi-camera",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    allowImageOnly: {
      type: Boolean,
      default: true,
    },
    uploadWithScale: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isBackUpFile: {
      type: Boolean,
      default: false,
    },
    isClearInput: {
      type: Boolean,
      default: true,
    },
    isPrependInnerIcon: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
      // [(v) => !!v || 'จำเป็นต้องระบุ']
    },
    uploadTag: {
      type: String,
      default: "",
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    localData: null,
    upfile: [],
    viewfile: [],
    backupFile: [],
    backupViewfile: [],
    loading: false,
  }),

  watch: {
    localData(newVal) {
      this.handleInput(newVal);
    },
  },

  mounted() {},

  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        if (this.isDark) {
          return "#222831";
        }
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },

    async selectFileAction(_files) {
      if (this.multiple) {
        let totalFiles = _files.length;

        for (var i = 0; i < totalFiles; i++) {
          let file = _files[i];
          this.upfile.push(file);
          this.viewfile.push({
            name: file.name,
            newFile: 1,
            src: await URL.createObjectURL(file),
            progressPercent: 0,
          });
        }

        this.$refs.componentFileInput.reset();
      } else {
        this.clearObjectUrl();
        this.upfile.push(_files);
        this.viewfile.push({
          name: _files.name,
          src: await URL.createObjectURL(_files),
          newFile: 1,
          progressPercent: 0,
        });
      }
      // append and reset
    },

    async uploadFaceAction(userId) {
      this.loading = true;
      let resultUpload = [];
      //
      if (this.isBackUpFile) {
        this.backupFile = _.cloneDeep(this.upfile);
        this.backupViewfile = _.cloneDeep(this.viewfile);
      }

      for (let i = 0; i < this.upfile.length; i++) {
        const file = this.upfile[i];
        let respObj = await regUserFace(this.$store, file, userId);
        resultUpload.push(respObj);
      }

      // clear where success
      if (this.isClearInput) {
        this.clearInput();
      }
      this.loading = false;

      return resultUpload;
    },

    clearInput() {
      this.clearObjectUrl();
      this.localData = null;
      this.$emit("input", this.viewfile);
    },

    clearObjectUrl() {
      try {
        for (var i = 0; i < this.viewfile.length; i++) {
          URL.revokeObjectURL(this.viewfile.src);
        }
      } catch (error) {
        console.error(error);
      }
      this.viewfile = [];
      this.upfile = [];
    },

    clearBackupObjectUrl() {
      try {
        for (var i = 0; i < this.backupViewfile.length; i++) {
          URL.revokeObjectURL(this.backupViewfile.src);
        }
      } catch (error) {
        console.error(error);
      }
      this.backupViewfile = [];
      this.backupFile = [];
    },

    handleInput(_files) {
      let isFileArray = _.isArray(_files);
      if (isFileArray) {
        // is multiple
        if (_files.length > 0) {
          this.selectFileAction(_files);
          this.$emit("input", this.viewfile);
        }
        // if null or undefined do noting
      } else {
        // not multiple
        if (!_.isNil(_files)) {
          // if file
          this.selectFileAction(_files);
          this.$emit("input", this.viewfile);
        } else if (_.isNil(_files)) {
          // not select not
          this.clearInput();
          this.$emit("input", this.viewfile);
        } else {
          // is string file from serve
          // for now do noting
          // console.log(_files);
        }
      }
    },

    removeInput(atLocation) {
      // get atLocation from viewfile
      let _file = this.viewfile[atLocation];
      // console.log(_file);
      try {
        URL.revokeObjectURL(_file.src);
      } catch (error) {
        console.log(error);
      }
      this.viewfile.splice(atLocation, 1);
      this.upfile.splice(atLocation, 1);
      this.$emit("input", this.viewfile);
    },

    restoreFile() {
      this.upfile = _.cloneDeep(this.backupFile);
      this.viewfile = _.cloneDeep(this.backupViewfile);
      this.$emit("input", this.viewfile);
    },
  },
  //
  destroyed() {
    this.clearObjectUrl();
    this.clearBackupObjectUrl();
  },
};
</script>
