<template>
  <v-container name="vehicletListView" fluid>
    <v-row class="mb-1">
      <v-col cols="auto">
        <h1 class="text-h5 mt-1">รายการวางงานจำนวนมาก</h1>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto">
        <PrimaryButton
          v-if="isPermissionCanEdit"
          :large="true"
          icon="mdi-plus"
          @on-click="handleAddClick()"
        >
          เพิ่มข้อมูล
        </PrimaryButton>
      </v-col>
    </v-row>

    <v-data-table
      :headers="tableHeaders"
      :items="tableRows"
      :items-per-page="queryOptionsTable.limit"
      :page="queryOptionsTable.page"
      :loading="tableLoading"
      :server-items-length="serverItemsLength"
      :footer-props="dataTableFooterProps"
      :no-data-text="dataTableNoDataText"
      :loading-text="dataTableLoadingText"
      @update:page="tablePageHandler"
      @update:items-per-page="tableItemPerPageHandler"
      class="app-datatable"
    >
      <template v-slot:top>
        <div class="app-table-list-identity pt-4 px-4">
          <div class="d-flex">
            <div style="width: 320px">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
                hide-details
              ></v-select>
            </div>
            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-list-item-title
          v-if="item.disabled === 'ลบ'"
          class="text-app-title font-weight-bold"
        >
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-title
          v-else
          class="text-app-title font-weight-bold primary--text"
          style="cursor: pointer"
          @click="openSheet(item._id)"
        >
          {{ item.name }}
        </v-list-item-title>
      </template>

      <template v-slot:[`item.responsibleUser`]="{ item }">
        {{ item.responsibleUser ? item.responsibleUser.dpNameTh : "-" }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ dateStringToDateFormat(item.createdAt, { returnThai: false }) }}
      </template>

      <template v-slot:[`item.contract`]="{ item }">
        {{
          item.contract
            ? `${item.contract.title} (${item.contract.contractNumber})`
            : `-`
        }}
      </template>

      <template v-slot:[`item.tools`]="{ item }">
        <!-- menu bar -->
        <v-menu
          bottom
          left
          v-if="permList.jobs_multiple_crud || permList.admin_all"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="handleEditClick(item._id)"
              v-if="permList.jobs_multiple_crud || permList.admin_all"
              :disabled="item.disabled"
            >
              <v-list-item-icon>
                <v-icon> mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title">
                {{ editTextBtn }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="handleDeleteClick(item._id)"
              :disabled="item.totalTable > 0 || item.disabled"
            >
              <v-list-item-icon>
                <v-icon> mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-app-title"> ลบ </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <AppDialogConfirm ref="confirmDelete" />
    <AssignMultipleJobCreateSheetDialog
      ref="assignMultipleJobCreateSheetDialog"
    />
  </v-container>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import AssignMultipleJobCreateSheetDialog from "./components/AssignMultipleJobCreateSheetDialog.vue";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";
import { dateStringToDateFormat } from "@/services/appDate";
import { processPermission } from "@/services/permissions";
import {
  getMultipleJobSheetListService,
  deleteMultipleJobSheetService,
} from "@/services/api/assign_multiple_job";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import NormalButton from "@/components/NormalButton.vue";
//
export default {
  name: "AssignMultipleTasksListView",
  data() {
    return {
      componentRefreshCounter: 0,
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      tableLoading: false,
      field_sort_by: null,
      serverItemsLength: 0,

      options: {},
      queryStringSearch: "",
      sortVal: null,
      itemsPerPage: 20,
      dataList: [],
      statusFilterValue: "all",

      queryFilterList: {},
      queryStringSearch: "", // combin filter and search text
      querySearchAndFilterList: {}, // combine filter and search
      queryOptionsTable: {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },
      sortVal: null,
      isShowMenu: false,
      permList: {
        admin_all: false,
        jobs_multiple_crud: false,
        jobs_multiple_tracking: false,
      },
      editTextBtn: "แก้ไขข้อมูล",
      isPermissionCanEdit: false,
      isPermissionCanView: false,
      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createddate-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createddate-desc",
        },
      ],
    };
  },
  mounted() {
    this.checkUserPermissions();
    this.processRouterQuery(this.$route.query);
  },
  methods: {
    //table
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      // encode
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.querySearchAndFilterList,
          ...this.queryOptionsTable,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
        };
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.getData();
    },
    processSearchingParams(_queryParameter) {
      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList, // old query data
        ...this.queryOptionsTable, // default table
        ..._queryParameter,
      };
      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.processSearchingParams({});
      this.getData();
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    //
    async handleAddClick() {
      const responseData = await this.$refs[
        "assignMultipleJobCreateSheetDialog"
      ].open(null);
      if (responseData["status"]) {
        const routeData = this.$router.resolve({
          name: "multipleJobCreate",
          query: { id: responseData["id"] },
        });
        window.open(routeData.href, "_blank");
        this.getData();
      }
    },
    async handleEditClick(_id) {
      const responseData = await this.$refs[
        "assignMultipleJobCreateSheetDialog"
      ].open(_id);

      if (!_.isNil(responseData)) {
        if (responseData["status"]) {
          this.getData();
        }
      }
    },
    openSheet(_id) {
      const routeData = this.$router.resolve({
        name: "multipleJobCreate",
        query: { id: _id },
      });
      window.open(routeData.href, "_blank");
    },
    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบแผนงานนี้ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteMultipleJobSheetService(this.$store, _id);
        this.getData();
      }
    },

    async getData() {
      this.tableLoading = true;
      this.dataList = [];
      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      try {
        let responseData = await getMultipleJobSheetListService(
          this.$store,
          this.queryStringSearch
        );
        if (responseData.code == 200) {
          this.serverItemsLength = responseData.data["total"];
          //
          // for (let i = 0; i < responseData.data["jobList"].length; i++) {
          //   responseData.data["jobList"][i]["statusLoading"] = false;
          //   responseData.data["jobList"][i]["statusOpen"] = false;
          //   responseData.data["jobList"][i]["keyCounter"] = 0;
          // }
          this.dataList = responseData.data["multipleJobList"];
        }
      } catch (error) {
        console.log(error);
      }

      this.tableLoading = false;
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");

        if (this.permList.admin_all === true) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          return;
        }

        if (this.permList.jobs_multiple_crud) {
          this.isPermissionCanEdit = true;
        } else if (this.permList.jobs_multiple_tracking) {
          this.isPermissionCanView = true;
        }
      }
    },
  },
  computed: {
    tableHeaders() {
      let defaultHeader = [
        {
          text: "ชื่อแผนงาน",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "สัญญา",
          align: "start",
          sortable: false,
          value: "contract",
          width: "20%",
        },
        {
          text: "ผู้รับผิดชอบ",
          align: "start",
          sortable: false,
          value: "responsibleUser",
          width: "10%",
        },
        {
          text: "จำนวนตาราง",
          align: "start",
          sortable: false,
          value: "totalTable",
          width: "10%",
        },
        {
          text: "จำนวนงานที่สร้างไปแล้ว",
          align: "start",
          sortable: false,
          value: "totalPublicJob",
          align: "left",
          width: "10%",
        },
        {
          text: "วันที่สร้าง",
          align: "start",
          sortable: false,
          value: "createdAt",
          align: "left",
          width: "10%",
        },
      ];
      //
      if (this.permList.admin_all) {
        defaultHeader.push({
          text: "สถานะ",
          value: "disabled",
          sortable: false,
          align: "center",
        });
      }

      if (this.permList.admin_all || this.permList.jobs_multiple_crud) {
        defaultHeader.push({
          text: "",
          value: "tools",
          sortable: false,
          align: "center",
          width: "5%",
        });
      }

      //
      return defaultHeader;
    },
    tableRows() {
      const rows = this.dataList.map((row, rowIdx) => {
        return {
          ...row,
          disabled: row["disabled"] ? "ลบ" : "ใช้งานอยู่",
        };
      });

      return rows;
    },
    canEdit: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanEdit"];
    },
    canView: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanView"];
    },
  },
  components: {
    PrimaryButton,
    AssignMultipleJobCreateSheetDialog,
    AppDialogConfirm,
    NormalButton,
  },
};
</script>

<style scoped></style>
