<template>
  <v-dialog scrollable v-model="show" width="800" persistent>
    <v-card v-if="show">
      <!-- <v-card-title class="text-h5"> ใบลางาน </v-card-title> -->
      <v-toolbar color="primary" dark>
        <v-card-title> พฤติกรรมพนักงาน </v-card-title> <v-spacer></v-spacer
        ><v-btn icon @click.native="cancelStatus"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >

      <v-card-text class="pt-5 px-0" v-if="fetchDataError === 0">
        <v-card class="mb-6 pb-3 mx-0 px-12" elevation="0">
          <div v-if="id !== null" class="title-input">ผู้กระทำผิด</div>
          <div v-if="id !== null" class="d-inline-flex mb-6 mt-3">
            <v-avatar size="64">
              <v-img
                :src="
                  !penaltyForm.profileImage
                    ? defaultPFImage
                    : penaltyForm.profileImage
                "
              >
              </v-img>
            </v-avatar>
            <div class="pl-2 align-self-center">
              <p class="ma-0 font-weight-bold">
                {{
                  `${
                    penaltyForm.salutation === null
                      ? ""
                      : penaltyForm.salutation.th
                  } ${
                    penaltyForm.firstName === null
                      ? ""
                      : penaltyForm.firstName.th
                  } ${
                    penaltyForm.lastName === null ? "" : penaltyForm.lastName.th
                  } `
                }}
                {{
                  penaltyForm.nickName === null
                    ? ""
                    : `(${penaltyForm.nickName.th})`
                }}
              </p>
              <p class="ma-0 text-app-detail-normal">
                เบอร์โทร:
                <span v-if="id !== null">
                  <span
                    v-for="(phoneNumber, index) in penaltyForm.phoneNumber"
                    :key="index"
                    ><span
                      v-if="index > 0 && index < penaltyForm.phoneNumber.length"
                    >
                      , </span
                    >{{ phoneNumber }}</span
                  >
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-divider class="mx-3 my-3" vertical></v-divider>
            <div class="pl-2 align-self-center text-app-detail-normal">
              <p class="ma-0">
                รหัสพนักงาน:
                <span v-if="id !== null">{{ penaltyForm.empId }}</span>
                <span v-else>-</span>
              </p>
              <p class="ma-0">
                ตำแหน่ง:
                <span
                  v-if="
                    penaltyForm.position !== null &&
                    penaltyForm.position !== undefined
                  "
                  >{{ penaltyForm.position }}</span
                >
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <v-form ref="form" v-model="valid">
            <v-row no-gutters>
              <v-col cols="12" lg="12" sm="12">
                <!-- <CustomAutocomplete
                    v-if="id == null"
                    v-model="penaltyForm.employee"
                    title="ชื่อ-นามสกุลผู้กระทำผิด"
                    :isRequired="true"
                    :items="empList"
                    :loading="isLoading"
                    menu-props="auto"
                    selectionTextIf="value"
                    itemTextListTextIf="value"
                    itemText="value"
                    itemValue="value"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  >
                  </CustomAutocomplete> -->
                <InputView
                  v-if="id == null"
                  title="ชื่อ-นามสกุลผู้กระทำผิด"
                  :is-required="true"
                  :expend-margin="false"
                >
                  <AppComboboxUserInputSearching
                    :value="null"
                    ref="AppAutoCompletePenaltyUserSearchingRef"
                    :is-required="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    @searchItemChange="employeeInputSearchingAction"
                  />
                </InputView>
                <div v-else class="pb-6">
                  <span class="title-input">ชื่อ-นามสกุลผู้บันทึก:</span>
                  <span class="text-app-detail-normal">
                    {{
                      `${
                        penaltyForm.salutation === null
                          ? ""
                          : penaltyForm.salutation.th
                      } ${
                        penaltyForm.firstName === null
                          ? ""
                          : penaltyForm.firstName.th
                      } ${
                        penaltyForm.lastName === null
                          ? ""
                          : penaltyForm.lastName.th
                      } `
                    }}
                    {{
                      penaltyForm.nickName === null
                        ? ""
                        : `(${penaltyForm.nickName.th})`
                    }}
                  </span>
                </div>
                <!--  -->
                <EmployeePenaltyConJobVehRowForm
                  v-if="penaltyForm.relatedJob === null"
                  @change-data="employeePenaltyConJobVehRowFormHandler"
                  class="pb-6"
                />

                <!-- has relatedJob -->
                <div v-if="penaltyForm.relatedJob !== null" class="mb-6">
                  <div
                    class="grey lighten-2 d-inline px-2 py-1 mr-1 rounded-lg"
                  >
                    <a
                      v-if="isPermissionCanViewContract"
                      @click="
                        createLinks(
                          'vehicleContractEdit',
                          penaltyForm.relatedJob.contractId
                        )
                      "
                      class="text-decoration-none"
                    >
                      สัญญาเดินรถ:
                      {{ penaltyForm.relatedJob.contractTitle }}({{
                        penaltyForm.relatedJob.contractNumber
                      }})
                    </a>

                    <span v-else>
                      สัญญาเดินรถ:
                      {{ penaltyForm.relatedJob.contractTitle }}({{
                        penaltyForm.relatedJob.contractNumber
                      }})
                    </span>
                  </div>

                  <div
                    class="grey lighten-2 d-inline px-2 py-1 mr-1 rounded-lg"
                  >
                    <a
                      v-if="isPermissionCanViewJob"
                      class="text-decoration-none"
                      @click="
                        createLinks('jobsEdit', penaltyForm.relatedJob.jobId)
                      "
                    >
                      งาน: {{ penaltyForm.relatedJob.jobName }}
                    </a>

                    <span v-else>
                      งาน: {{ penaltyForm.relatedJob.jobName }}
                    </span>
                  </div>

                  <div class="grey lighten-2 d-inline px-2 py-1 rounded-lg">
                    <span v-if="isPermissionCanViewVehicle">
                      <a
                        v-if="penaltyForm.relatedJob.vehicleId"
                        class="text-decoration-none"
                        @click="
                          createLinks(
                            'vehicleEdit',
                            penaltyForm.relatedJob.vehicleId
                          )
                        "
                      >
                        รถโดยสาร:
                        {{ penaltyForm.relatedJob.vehicleLicenseNumber }}
                        <span v-if="penaltyForm.relatedJob.vehicleType">
                          ({{
                            vehicleTypeText(penaltyForm.relatedJob.vehicleType)
                          }})
                        </span>
                      </a>
                      <span v-else> รถโดยสาร: - </span>
                    </span>

                    <span v-else>
                      <span v-if="penaltyForm.relatedJob.vehicleId">
                        รถโดยสาร:
                        {{ penaltyForm.relatedJob.vehicleLicenseNumber }}
                        <span v-if="penaltyForm.relatedJob.vehicleType">
                          ({{
                            vehicleTypeText(penaltyForm.relatedJob.vehicleType)
                          }})
                        </span>
                      </span>
                      <span v-else> รถโดยสาร: - </span>
                    </span>
                    <!--  -->
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" lg="8" sm="8">
                <CustomAutocomplete
                  v-if="id == null"
                  v-model="penaltyForm.title"
                  title="หัวข้อการกระทำความผิด"
                  :isRequired="true"
                  :items="titleList"
                  menu-props="auto"
                  :loading="isLoading"
                  selectionTextIf="topic"
                  itemTextListTextIf="topic"
                  itemText="topic"
                  itemValue="topic"
                  :returnObject="true"
                  :readonly="id !== null ? true : false"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  :expend-margin="false"
                >
                </CustomAutocomplete>
                <CustomTextInputImprove
                  v-else
                  v-model="penaltyForm.topic"
                  ref="penaltyType"
                  title="หัวข้อการกระทำความผิด"
                  :isRequired="false"
                  :disabled="id !== null ? true : false"
                  dataInputName="penaltyType"
                  :expend-margin="false"
                />
              </v-col>
              <v-col cols="12" lg="4" sm="4">
                <CustomTextInputImprove
                  v-model="penaltyForm.score"
                  ref="penaltyScore"
                  title="คะแนนที่หัก"
                  :isRequired="true"
                  inputType="floatNumberWithOutSign"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  dataInputName="penaltyScore"
                  :expend-margin="false"
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-6">
              <v-col cols="12">
                <CustomTextareaImprove
                  v-model="penaltyForm.detail"
                  :hideDetail="true"
                  title="สาเหตุการกระทำความผิด"
                  :is-required="false"
                  :expend-margin="false"
                ></CustomTextareaImprove>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <FormDataUploadInput
                  ref="vehicleCRUDUploadFile"
                  component-name="vehicleCRUDUploadFileForm"
                  title="ชุดเอกสาร (pdf)"
                  placeholder="เพิ่มเอกสารกดที่นี่"
                  :uploaded-data="penaltyForm.files"
                  @waitToUploadFiles="isWaitToUploadFiles"
                  uploadTag="EMPLOYEE_PENALITY"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" class="py-0">
                <FormDataUploadInput
                  ref="vehicleCRUDUploadImageFile"
                  component-name="vehicleCRUDUploadImageFileForm"
                  title="รูปภาพ (jpg, png)"
                  placeholder="เพิ่มรูปภาพที่นี่"
                  :isImageType="true"
                  :uploaded-data="penaltyForm.images"
                  @waitToUploadImages="isWaitToUploadImages"
                  uploadTag="EMPLOYEE_PENALITY"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-6">
              <v-col cols="12">
                <v-card elevation="0" class="px-0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    class="px-0"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      lg="6"
                      class="px-1"
                      v-for="(value, key) in fundDataStatus"
                      :key="key"
                    >
                      <v-card
                        :color="getStatusColor(value)"
                        @click="statusOnClick(value)"
                        :outlined="getStatusOutline(value)"
                        ><v-card-title class="pt-2 pl-2 pb-0">
                          <v-icon
                            class="status-color"
                            :dark="penaltyStatus[value.key]"
                          >
                            mdi-check-circle
                          </v-icon> </v-card-title
                        ><v-card-text align="center">
                          <v-icon x-large :dark="penaltyStatus[value.key]">
                            {{ statusIcon[value.key] }}
                          </v-icon>
                          <div v-if="getStatusOutline(value)" class="icon pt-2">
                            {{ value.value }}
                          </div>
                          <div v-else class="white--text icon pt-2">
                            {{ value.value }}
                          </div>
                        </v-card-text></v-card
                      ></v-col
                    >
                  </v-row>
                </v-card></v-col
              ></v-row
            >
          </v-form>
        </v-card>
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 1">
        กำลังบันทึกข้อมูล...
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
        กำลังดึงข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 0"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 0"
          color="primary"
          class="px-6"
          :disabled="isNotEdited"
          :loading="isSubmiting"
          @click="saveChange"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>

    <AppDialogConfirm ref="alertStatus" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { getVehicleFundamental } from "@/services/api/vehicle";
import defaultImage from "@/assets/user.png";
import { mapGetters } from "vuex";
import { DialogType } from "@/services/dialog";
import { getEmployeeTimeOffStatService } from "@/services/api/employee";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import AppComboboxUserInputSearching from "@/components/AppComboboxUserInputSearching.vue";
import InputView from "@/components/InputView.vue";
import {
  getEmployeeSelectList,
  getPenaltyTitleSelectList,
  getEmployeePenaltyService,
} from "@/services/api/employee";
import { processPermission } from "@/services/permissions";
import EmployeePenaltyConJobVehRowForm from "./EmployeePenaltyConJobVehRowForm.vue";

export default {
  name: "EmployeePenaltyDetailDialog",
  props: ["dialogStatus"],
  data: () => ({
    defaultPFImage: defaultImage,
    isLoading: false,
    valid: false,
    show: false,
    resolve: null,
    reject: null,
    fundData: {},
    isNotEdited: true,
    initStatus: null,
    statusIcon: {
      YES: "mdi-thumb-down-outline",
      NO: "mdi-thumb-up-outline",
    },
    fundDataStatus: [
      { key: "YES", value: "ผิดจริง" },
      { key: "NO", value: "ไม่ผิดจริง" },
    ],
    penaltyStatus: {
      YES: null,
      NO: null,
    },
    isSubmiting: false,
    isFormNotEdited: false,
    isWaitToUploadAttachFiles: false,
    isWaitToUploadAttachImages: false,
    id: null,
    // empList: null,
    titleList: null,
    initPenaltyForm: null,
    penaltyForm: {
      status: null,
      firstName: null,
      lastName: null,
      salutation: null,
      nickName: null,
      employee: null,
      empId: null,
      userId: null,
      phoneNumber: null,
      position: null,
      score: null,
      title: null,
      detail: null,
      files: [],
      images: [],
      relatedJob: null,
    },
    //
    fetchDataError: 0,
    isPermissionCanViewContract: false,
    isPermissionCanViewJob: false,
    isPermissionCanViewVehicle: false,
    // contract job vehicle
    contractJobVehicleSelectedData: {},
  }),
  watch: {
    penaltyForm: {
      handler(newValue) {
        // console.log(this.initPenaltyForm, newValue);
        if (!_.isNil(this.initPenaltyForm) && !_.isNil(newValue)) {
          this.isFormNotEdited = _.isEqual(this.initPenaltyForm, newValue);
          // console.log(this.isFormNotEdited);
          if (
            this.isFormNotEdited &&
            !this.isWaitToUploadAttachFiles &&
            !this.isWaitToUploadAttachImages
          ) {
            this.isNotEdited = true;
          } else {
            this.isNotEdited = false;
          }
        }
      },
      deep: true,
    },
    isWaitToUploadAttachFiles(value) {
      if (value || this.isWaitToUploadAttachImages || !this.isFormNotEdited) {
        this.isNotEdited = false;
      } else {
        this.isNotEdited = true;
      }
      // console.log("waitFileEdited", this.isNotEdited);
    },

    isWaitToUploadAttachImages(value) {
      if (value || this.isWaitToUploadAttachFiles || !this.isFormNotEdited) {
        this.isNotEdited = false;
      } else {
        this.isNotEdited = true;
      }
      // console.log("waitImageEdited", this.isNotEdited);
    },

    "penaltyForm.title": function (newValue) {
      if (!_.isNil(newValue)) {
        this.penaltyForm.score = _.clone(newValue.score);
      }
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      empPenaltyStatusColor: "employee/empPenaltyStatusColor",
      empPenaltyStatusText: "employee/empPenaltyStatusText",
    }),
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.statusOnClick({
        key: null,
        value: null,
      });
      this.penaltyForm = {
        status: null,
        firstName: null,
        lastName: null,
        salutation: null,
        nickName: null,
        employee: null,
        empId: null,
        userId: null,
        phoneNumber: null,
        position: null,
        score: null,
        title: null,
        detail: null,
        files: [],
        images: [],
        relatedJob: null,
      };
      this.initPenaltyForm = _.cloneDeep(this.penaltyForm);
      this.id = null;
      // this.empList = null;
      this.titleList = null;

      this.isNotEdited = true;
    },

    setFormData() {
      if (!_.isNil(this.fundData)) {
        this.id = this.fundData["id"];
        this.titleList = this.fundData["titleList"];
        // this.empList = this.fundData["empList"];
      }
    },

    async getData() {
      this.fetchDataError = 2;
      if (!_.isNil(this.id)) {
        let respPenaltyInfo = await getEmployeePenaltyService(
          this.$store,
          this.id
        );
        if (respPenaltyInfo["code"] === 200) {
          this.penaltyForm = _.clone(respPenaltyInfo["data"]);
          if (!_.isNil(this.penaltyForm)) {
            this.initStatus = _.clone(this.penaltyForm["status"]);
            this.statusOnClick({
              key: this.initStatus,
              value: this.empPenaltyStatusText(this.initStatus),
            });
            this.initPenaltyForm = _.cloneDeep(this.penaltyForm);
          }
        }
      } else {
        // if (this.empList.length === 0) {
        //   this.isLoading = true;
        //   let respEmpList = await getEmployeeSelectList(this.$store);
        //   if (respEmpList["code"] === 200) {
        //     this.empList = respEmpList["data"];
        //   }
        //   this.isLoading = false;
        // }
        if (this.titleList.length === 0) {
          this.isLoading = true;
          let respPenaltyTitleList = await getPenaltyTitleSelectList(
            this.$store
          );
          if (respPenaltyTitleList["code"] === 200) {
            this.titleList = respPenaltyTitleList["data"];
          }
          this.isLoading = false;
        }
      }
      this.fetchDataError = 0;
    },

    isWaitToUploadFiles(isWait) {
      this.isWaitToUploadAttachFiles = isWait;
      // console.log("isWaitFiles", this.isWaitToUploadAttachFiles);
    },

    isWaitToUploadImages(isWait) {
      this.isWaitToUploadAttachImages = isWait;
      // console.log("isWaitImages", this.isWaitToUploadAttachImages);
    },

    getStatusColor(status) {
      if (this.penaltyForm["status"] !== null) {
        let prevStatus = this.penaltyForm["status"].key;
        this.penaltyStatus[prevStatus] = true;
      }
      if (this.penaltyStatus[status.key]) {
        return this.empPenaltyStatusColor(status.key);
      }
      return "";
    },

    statusOnClick(selectedStatus) {
      this.penaltyStatus["YES"] = null;
      this.penaltyStatus["NO"] = null;

      this.penaltyStatus[selectedStatus.key] = true;
      this.penaltyForm["status"] = selectedStatus;

      if (this.initStatus !== null) {
        if (this.initStatus === selectedStatus.key) {
          this.isNotEdited = true;
        }
        // else {
        //   this.isNotEdited = false;
        // }
      }
    },

    getStatusOutline(status) {
      if (this.penaltyForm["status"] !== null) {
        let prevStatus = this.penaltyForm["status"].key;
        this.penaltyStatus[prevStatus] = true;
      }
      if (this.penaltyStatus[status.key]) {
        return false;
      }
      return true;
    },

    open(fundObj) {
      this.resetFormData();
      this.fundData = _.clone(fundObj);
      this.setFormData();
      this.getData();
      this.checkUserPermissions();

      // this.getStat();

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    handleApprovedClick() {},
    handleRejectedClick() {},
    async saveChange() {
      this.isSubmiting = true;
      if (this.$refs.form.validate() === true) {
        if (this.penaltyForm["status"] !== null) {
          if (this.penaltyForm["status"].key !== "NEW") {
            // let _toUpdateByAPI = false;
            let resUploadFile = await this.$refs[
              "vehicleCRUDUploadFile"
            ].upload();
            let respUploadImage = await this.$refs[
              "vehicleCRUDUploadImageFile"
            ].upload();

            if (!resUploadFile.status || !respUploadImage.status) {
              // is upload has error response restore all file
              this.callUploadFileErrorDialog();
              this.$refs["vehicleCRUDUploadFile"].restoreFile();
              this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
              // this.disableLoading();
            } else {
              let userId = null;
              let _formData = null;

              if (!_.isNil(this.penaltyForm["userId"])) {
                // edit
                _formData = {
                  id: this.penaltyForm["_id"],
                  score: parseFloat(this.penaltyForm["score"]),
                  detail: this.penaltyForm["detail"],
                  guilty: this.penaltyForm["status"]["key"],
                  files: resUploadFile.result,
                  images: respUploadImage.result,
                };
              } else {
                // add new
                if (
                  _.isNil(this.penaltyForm["employee"]) ||
                  _.isString(this.penaltyForm["employee"])
                ) {
                  this.isSubmiting = false;
                  return;
                }
                userId = this.penaltyForm["employee"]["_id"];
                _formData = {
                  userId: userId,
                  topic: this.penaltyForm["title"]["topic"],
                  score: parseFloat(this.penaltyForm["score"]),
                  detail: this.penaltyForm["detail"],
                  guilty: this.penaltyForm["status"]["key"],
                  files: resUploadFile.result,
                  images: respUploadImage.result,
                  ...this.contractJobVehicleSelectedData,
                };
              }
              this.resolve(_formData);
              this.show = false;
            }
          } else {
            await this.$refs.alertStatus.open(
              "กรุณาเลือกสถานะ ผิดจริง หรือ ไม่ผิดจริง",
              null,
              DialogType.ACTION,
              {
                noconfirm: false,
              }
            );
          }
        } else {
          await this.$refs.alertStatus.open(
            "กรุณาเลือกสถานะ ผิดจริง หรือ ไม่ผิดจริง",
            null,
            DialogType.ACTION,
            {
              noconfirm: false,
            }
          );
        }
      }
      this.isSubmiting = false;
    },

    employeeInputSearchingAction(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          this.penaltyForm["employee"] = _.cloneDeep(value);
        } else {
          this.penaltyForm["employee"] = null;
        }
      }
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    cancelStatus() {
      this.show = false;
      this.resolve(null);
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER_PENALITY_EDIT");

        if (
          this.permList.contract_info_crud ||
          this.permList.contract_info ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewContract = true;
        }

        if (
          this.permList.jobs_info ||
          this.permList.jobs_crud ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewJob = true;
        }
        if (
          this.permList.vehicle_info ||
          this.permList.vehicle_info_crud ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewVehicle = true;
        }
      }
    },
    // contract, job, vehicle
    employeePenaltyConJobVehRowFormHandler(responseData) {
      this.contractJobVehicleSelectedData = responseData;
    },
    createLinks(nameView, id) {
      const routeData = this.$router.resolve({
        name: nameView,
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
  },
  components: {
    CustomTextInputImprove,
    PrimaryButton,
    AppDialogConfirm,
    CustomTextareaImprove,
    FormDataUploadInput,
    CustomAutocomplete,
    AppComboboxUserInputSearching,
    InputView,
    EmployeePenaltyConJobVehRowForm,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}

.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
</style>
