import _ from "lodash";
import { dateStringToDateFormat } from "@/services/appDate";

export const createfuelTypeStr = function (dataRow) {
  return !_.isNil(dataRow["fuelType"])
    ? `${dataRow["fuelType"]["nameTh"]} (${dataRow["fuelType"]["station"]})`
    : "-";
};

export const createCreatedAtStr = function (dataRow) {
  return !_.isNil(dataRow["createdAt"])
    ? `${dateStringToDateFormat(dataRow["createdAt"], {
        returnThai: false,
        type: "datetime",
      })}`
    : "-";
};

export const createAmountStr = function (dataRow) {
  return !_.isNil(dataRow["amount"])
    ? `${dataRow["amount"].toLocaleString()} บาท`
    : "-";
};

export const createAmountUnitStr = function (dataRow) {
  return !_.isNil(dataRow["amountUnit"])
    ? `${dataRow["amountUnit"].toFixed(2)} ลิตร`
    : "-";
};

export const createPriceUnitStr = function (dataRow) {
  // console.log(dataRow);
  return !_.isNil(dataRow["pricePerUnit"])
    ? `${dataRow["pricePerUnit"].toFixed(2)} บาท`
    : "-";
};
