<template>
  <v-sheet height="600">
    <v-calendar
      ref="calendar"
      :now="calculateToday"
      :events="allEventData"
      color="primary"
      locale="th"
      :start="calculateStartDateBoundary"
      :end="calculateEndDateBoundary"
      type="custom-daily"
      :max-days="7"
      :weekday-format="formatWeekDayCalendarElement"
      :event-ripple="false"
      :interval-height="120"
      @click:event="showEvent"
      v-model="focus"
    >
      <template v-slot:event="{ event }">
        <v-sheet
          height="100%"
          :dark="event.dark"
          elevation="1"
          outlined
          class="py-2 pl-1 pr-2"
          :rounded="true"
          :color="event.color"
          :style="getBorderProp(event)"
        >
          <div class="event-name font-weight-bold">
            {{ event.name }}
          </div>
          <div>
            <!-- <v-icon class="pr1">mdi-clock-outline </v-icon> -->
            {{ getStartEndTimeToEventCard(event) }}
          </div>
          <!-- <div>
          <v-icon class="pr-1">mdi-map-marker-path</v-icon>
          {{ event.distance || "-" }}
        </div>
        <div>
          <v-icon>mdi-card-text</v-icon>
          {{ event.license || "-" }}
        </div>
        <div :style="getJobsTextColor(event)">
          {{ event.describe }}
        </div> -->
        </v-sheet>
      </template>
    </v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card max-width="350px" flat>
        <v-card-text>
          <p class="font-weight-bold mb-1">{{ selectedEvent.name }}</p>
          <p class="mb-0">
            <v-icon class="pr1">mdi-clock-outline</v-icon>
            {{ getStartEndDayTimeToEventCard(selectedEvent) }}
          </p>

          <p
            class="mb-0"
            :style="getJobsTextColor(selectedEvent)"
            v-html="selectedEvent.details"
          ></p>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-sheet>
</template>

<script>
import { dateStringToDateFormat } from "@/services/appDate";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "ScheduleCalendarComponent",
  props: {
    selectedCalendarOfDriver: {
      type: [Object, null],
      default: null,
    },
    selectedStartDate: {
      type: [String, null],
      default: null,
    },
    selectedEndDate: {
      type: [String, null],
      default: null,
    },
    vuexStoreName: {
      type: String,
      default: "manageRoute",
    },
  },
  watch: {
    selectedCalendarOfDriver: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          this.processNewData(newValue);
        }
      },
    },
    selectedStartDate: {
      immediate: true,
      handler(newValue) {
        // console.log("selected start date", newValue);
      },
    },
    selectedEndDate: {
      immediate: true,
      handler(newValue) {
        // console.log("selected end date", newValue);
      },
    },
  },
  data: () => ({
    today: null,
    startDateBoundary: null,
    endDateBoundary: null,
    allEventData: [],
    eventStartEndDateObjList: [],
    customBackgoundColors: {
      gray: "#D8D9DD",
      orange: "#FF8C26",
      red: "#F44336",
      green: "#4CAF50",
    },
    isEnableToSave: false,
    isSameJobIdEvent: false,
    // variable for dialog event
    focus: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    allowStatus: ["SUCCESS", "CANCEL"],
  }),
  mounted() {},
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      jobsStatusText: "jobs/jobsStatusText",

      driverAndVehicleStatusColor: "jobs/driverAndVehicleStatusColor",
      driverAndVehicleStatusColorValue: "jobs/driverAndVehicleStatusColorValue",
      driverAndVehicleStatusText: "jobs/driverAndVehicleStatusText",
    }),
    calculateToday() {
      if (_.isNil(this.selectedStartDate)) {
        return moment().format("YYYY-MM-DD");
      } else {
        return moment(this.selectedStartDate).format("YYYY-MM-DD");
      }
    },
    calculateStartDateBoundary() {
      let _processDate = null;
      if (_.isNil(this.startDateBoundary)) {
        _processDate = moment().subtract(3, "days");
      } else {
        _processDate = moment(this.startDateBoundary);
      }

      return _processDate.toDate();
    },
    calculateEndDateBoundary() {
      let _processDate = null;
      if (_.isNil(this.endDateBoundary)) {
        _processDate = moment().add(3, "days");
      } else {
        _processDate = moment(this.endDateBoundary);
      }

      return _processDate.toDate();
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    padTo2Digits(num) {
      return String(num).padStart(2, "0");
    },
    getTime(valDate) {
      return (
        this.padTo2Digits(valDate.getHours()) +
        ":" +
        this.padTo2Digits(valDate.getMinutes())
      );
    },
    //
    formatWeekDayCalendarElement(props) {
      return new Date(props.date.replace(/-/g, "\/")).toLocaleDateString(
        "th-TH",
        { weekday: "long", year: "2-digit", month: "numeric" }
      );
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    createDriverEventCard(jobsList) {
      let events = [];
      for (let i = 0; i < jobsList.length; i++) {
        events.push({
          isSameJobId: false,
          eventId: jobsList[i]["_id"],
          eventStatus: jobsList[i]["status"],
          isEdit: false,
          name: jobsList[i]["name"],
          start: moment(jobsList[i]["startDate"]).toDate(),
          end: moment(jobsList[i]["endDate"]).toDate(),
          color: this.customBackgoundColors["gray"],
          timed: true,
          dark: false,
          isEnableToSave: false,
          details: this.jobsStatusText(jobsList[i]["status"]),
        });

        this.eventStartEndDateObjList.push({
          start: moment(jobsList[i]["startDate"]),
          end: moment(jobsList[i]["endDate"]),
        });
      }

      this.allEventData = events;
    },
    //
    createSelectedEventCard() {
      let events = [];
      this.isEnableToSave = false;
      this.isSameJobId = false;
      const selectedStartDateEventMoment = moment(this.selectedStartDate);
      const selectedEndDateEventMoment = moment(this.selectedEndDate);

      for (let i = 0; i < this.eventStartEndDateObjList.length; i++) {
        if (
          selectedStartDateEventMoment.isBetween(
            this.eventStartEndDateObjList[i]["start"],
            this.eventStartEndDateObjList[i]["end"],
            null,
            "[]"
          ) ||
          selectedEndDateEventMoment.isBetween(
            this.eventStartEndDateObjList[i]["start"],
            this.eventStartEndDateObjList[i]["end"],
            null,
            "[]"
          )
        ) {
          if (!this.allowStatus.includes(this.allEventData[i]["eventStatus"])) {
            this.allEventData[i]["isEnableToSave"] = true;
            this.isEnableToSave = true;
          }

          const jobId = _.get(
            this.$store.state[this.manageRoute],
            "jobData._id",
            null
          );
          //
          if (this.allEventData[i]["eventId"] === jobId) {
            this.allEventData[i] = {
              ...this.allEventData[i],
              color: this.customBackgoundColors["orange"],
              dark: true,
              details: "เป็นผู้ขับหรือรถถูกมอบหมายงานนี้",
            };
            this.allEventData[i]["isSameJobId"] = true;
            this.isSameJobId = true;
          }
        }
      }
      // assign current job
      if (!this.isSameJobId) {
        events.push({
          eventId: null,
          eventStatus: null,
          isEdit: true,
          dark: true,
          name: "งานนี้",
          start: selectedStartDateEventMoment.toDate(),
          end: selectedEndDateEventMoment.toDate(),
          isEnableToSave: this.isEnableToSave,
          color: this.isEnableToSave
            ? this.customBackgoundColors["red"]
            : this.customBackgoundColors["green"],
          timed: true,
          details: this.isEnableToSave ? "ไม่ว่าง (ทับ)" : "ว่าง",
        });
      }

      this.allEventData = [...this.allEventData, ...events];
      //
      if (!_.isNil(this.selectedStartDate)) {
        this.$refs.calendar.scrollToTime(
          moment(this.selectedStartDate).subtract(20, "m").format("HH:mm")
        );
      }
    },

    processNewData(calendarData) {
      this.allEventData = [];
      this.eventStartEndDateObjList = [];

      if (calendarData["jobsList"].length > 0) {
        this.createDriverEventCard(calendarData["jobsList"]);
      }

      this.createSelectedEventCard();

      this.startDateBoundary = calendarData["startDate"];
      this.endDateBoundary = calendarData["endDate"];
      this.$emit("is-overlap-selected-event", this.isEnableToSave);
    },
    getStartEndTimeToEventCard(event) {
      if (!_.isNil(event["start"]) && !_.isNil(event["end"])) {
        return `${this.getTime(event["start"])} - ${this.getTime(
          event["end"]
        )}`;
      }
      return "ไม่มีข้อมูล";
    },
    getStartEndDayTimeToEventCard(event) {
      if (!_.isNil(event["start"]) && !_.isNil(event["end"])) {
        return `${dateStringToDateFormat(event["start"], {
          type: "datetime",
          returnThai: false,
        })} - ${dateStringToDateFormat(event["end"], {
          type: "datetime",
          returnThai: false,
        })}`;
      }
      return "-";
    },
    getBorderProp(event) {
      if (!_.isNil(event["eventStatus"])) {
        let color = this.jobsStatusColorValue(event["eventStatus"]);
        let lineStyle = "solid";
        if (event["isEnableToSave"] && !event["isSameJobId"]) {
          lineStyle = "dashed";
        }
        return `border: 2px ${lineStyle} ${color}`;
      }
      return "";
    },
    getJobsTextColor(event) {
      if (!_.isNil(event["eventStatus"])) {
        let color = this.jobsStatusColorValue(event["eventStatus"]);
        return `color: ${color}`;
      }
      return "";
    },
    // main v-calendar event
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style scoped>
.event-name {
  /* display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4em;
  line-height: 2em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  /* border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px; */

  /* margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative; */
}
</style>
