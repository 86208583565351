<template>
  <div>
    <v-combobox
      v-model="valueInput"
      :items="itemList"
      :loading="loading"
      :search-input.sync="searching"
      :label="label"
      :placeholder="placeholder"
      return-object
      filled
      hide-selected
      hide-no-data
      hide-details
      outlined
      item-value="_id"
      item-text="contractNumber"
      dense
      append-icon=""
      @keyup="enterToReturnEmit"
      :disabled="disabled"
      :rules="newRules"
    >
      <!-- <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            ไม่มีข้อมูล "<strong>{{ searching }}</strong
            >" กด <kbd>enter</kbd> เพื่อเพิ่มใหม่
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template> -->
    </v-combobox>
    <span v-if="!validId" class="px-3 text-subtitle-2 red--text text-caption"
      >โปรดระบุ รหัสสัญญาใหม่</span
    >
  </div>
  <!-- @keyup="enterToReturnEmit" -->
  <!-- prepend-inner-icon="mdi-magnify" -->
  <!-- :item-value="itemValueKey" -->
  <!--  item-text="value" -->
</template>

<script>
import { getContractBySeachingService } from "@/services/api/contract";
import _ from "lodash";

export default {
  name: "DynamicContractElement",
  components: {},

  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    previousId: {
      type: String,
      default: "",
    },
    // rules: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  data: () => ({
    itemList: [],
    loading: false,
    searching: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryList: [],
    hideText: true,
    newRules: [(v) => !!v || "จำเป็นต้องระบุ รหัสสัญญา"],
    validId: true,
  }),
  watch: {
    searching(val) {
      // this.validId = true;
      if (!_.isNil(val) && _.isString(val)) {
        this.currentSearchText = val;
        if (val.length >= 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1500);
        } else {
          this.hideText = true;
        }
      } else {
        this.hideText = true;
        // this.validId = false;
      }
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        // console.log(val);
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async callApi(val) {
      //
      let output = "check";
      let resp_data = await getContractBySeachingService(
        this.$store,
        val,
        output
      );

      if (resp_data.code === 200) {
        this.itemList = resp_data.data.dataList;
        if (resp_data.data.countData > 0 && val !== this.previousId) {
          this.validId = false;
        } else {
          this.validId = true;
        }
        console.log(val, this.previousId);
        if (val !== this.previousId) {
          console.log("Duplicated");
          this.newRules = [
            (v) =>
              (!!v && resp_data.data.countData == 0) ||
              "จำเป็นต้องระบุ รหัสสัญญาใหม่",
          ];
        } else {
          this.newRules = [(v) => !!v || "จำเป็นต้องระบุ รหัสสัญญาใหม่"];
        }

        this.hideText = false;
      } else {
        this.itemList = [];
        this.hideText = true;
        this.validId = true;
      }
      this.loading = false;
    },

    enterToReturnEmit(event) {
      if (event.code == "Enter") {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        this.itemList = [];
        return;
      } else if (event.code == "Backspace") {
        this.hideText = true;
      }
    },
  },

  mounted() {},
};
</script>

<style scope></style>
