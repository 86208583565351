<template>
  <v-dialog v-model="dialog" :max-width="500">
    <v-card>
      <v-card-title> รับ URL สำหรับสร้างรหัสผ่าน </v-card-title>
      <v-card-text>
        <div v-if="urlText">
          <v-text-field
            class="app-custom-input"
            outlined
            dense
            :value="urlText"
            :readonly="true"
            append-outer-icon="mdi-content-copy"
            @click:append-outer="copyAction"
          ></v-text-field>
        </div>
        <div v-else>
          <p>ไม่สามารถสร้างได้ ลองใหม่อีกครั้ง</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> ปิด </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import _ from "lodash";

export default {
  name: "AccountGeneratePasswordDialog",
  components: { CustomTextInputImprove },
  data: () => ({
    dialog: false,
    urlText: null,
  }),
  methods: {
    open(linkData) {
      this.dialog = true;
      this.urlText = linkData;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    copyAction() {
      navigator.clipboard.writeText(this.urlText);
    },
    close() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
