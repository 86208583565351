<template>
  <v-card class="main-bg-color rounded-0 mapMarkerView" elevation="0">
    <v-app-bar dense elevation="0" color="primary">
      <p
        class="ma-0 text-app-detail white--text d-sm-none d-md-none d-lg-none d-xl-flex"
      >
        <v-icon color="white"> mdi-map-marker </v-icon>
        จุดวิ่งรถ:
        {{ markerList.length }}
      </p>
      <p
        class="ma-0 text-app-detail white--text d-none d-sm-flex d-md-flex d-lg-flex d-xl-none"
      >
        <v-icon color="white"> mdi-map-marker </v-icon>
        {{ markerList.length }}
      </p>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-1"
        elevation="0"
        @click="openSaveRouteDialog()"
        small
        :disabled="
          markerList.length <= 1 ||
          componentLoading ||
          !isEditView ||
          !isPermissionCanEdit ||
          isTemplateRouteView
        "
      >
        <v-icon> mdi-content-save </v-icon>
        บันทีกเส้นทางใหม่
      </v-btn>
    </v-app-bar>

    <v-sheet
      elevation="0"
      class="rounded-0 subMapMarkerView"
      color="#F2F5F9"
      v-if="markerList.length === 0"
    >
    </v-sheet>

    <v-sheet
      elevation="0"
      class="rounded-0 subMapMarkerView"
      color="#F2F5F9"
      v-if="markerList.length !== 0"
    >
      <v-card
        v-if="isTemplateRouteView"
        outlined
        elevation="0"
        class="ma-2 rounded-lg"
        color="#ffe0b2"
      >
        <v-card-text>
          <p class="body-1 text-grey text--darken-4 ma-0">
            เส้นทางใหม่ที่จะกำหนดให้
          </p>
        </v-card-text>
      </v-card>
      <v-card
        v-for="(marker, idx) in markerList"
        :key="marker.gplaceId + '-' + idx"
        outlined
        elevation="0"
        class="ma-2 rounded-lg"
        color="white"
      >
        <div class="pa-2 d-flex justify-space-between">
          <font-awesome-layers full-width class="fa-2x mt-3 mr-2">
            <font-awesome-icon
              icon="fa-solid fa-location-pin"
              :color="marker.markerColor"
            />
            <font-awesome-layers-text
              class="white--text"
              transform="top-1 shrink-9"
              :value="idx + 1"
            />
          </font-awesome-layers>
          <div class="marker-text-title-wrapper">
            <p class="marker-text-title pl-1 mb-2">{{ marker.name }}</p>

            <div>
              <v-chip small :color="displayColorStatus(marker.status)">
                <span
                  :class="
                    'font-weight-medium ' +
                    displayFontColorStatus(marker.status)
                  "
                >
                  {{ displayStatus(marker.status) }}
                </span>
              </v-chip>
              <v-chip
                v-if="marker.closestDistance"
                small
                color="#F8F8F8"
                class="mx-1"
              >
                {{
                  marker.closestDistance
                    ? `เข้าใกล้: ${marker.closestDistance}`
                    : "-"
                }}
              </v-chip>
            </div>
          </div>

          <!-- </div> -->

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="copyLocationText(idx)">
                <v-list-item-icon>
                  <v-icon> mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-title> คัดลอกตำแหน่ง </v-list-item-title>
              </v-list-item>
              <!--  -->

              <v-list-item
                @click="editDialog(idx, false)"
                v-if="isOnlyPermissionCanView"
              >
                <v-list-item-icon>
                  <v-icon> mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title> ดูข้อมูล</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isPermissionCanEdit && !isTemplateRouteView"
                @click="editDialog(idx, true)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title> แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="deleteDialog(idx)"
                v-if="isPermissionCanEdit && !isTemplateRouteView"
              >
                <v-list-item-icon>
                  <v-icon> mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title> ลบ</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-card-text class="pt-2" v-if="idx !== 0">
          <p class="marker-text-topic mb-0">ข้อมูลโดยประมาณ</p>
          <v-row no-gutters>
            <v-col cols="3">
              <p class="marker-text-headline mb-0">ระยะทาง</p>
              <p class="marker-text-data mb-0">
                {{ processDistanceText(marker.distanceText) }}
              </p>
            </v-col>
            <v-col cols="3">
              <p class="marker-text-headline mb-0">ระยะเวลา</p>
              <p class="marker-text-data mb-0">
                {{ processDurationText(marker.durationText) }}
              </p>
            </v-col>
            <v-col cols="6">
              <p class="marker-text-headline mb-0">ถึงเวลา</p>
              <p class="marker-text-data mb-0">
                {{
                  marker.estimateArrivalTime
                    ? dateStringToDateFormat(marker.estimateArrivalTime, {
                        type: "datetime",
                        returnThai: false,
                      })
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>

          <p class="marker-text-topic mb-0 mt-3">ข้อมูลจริง</p>
          <v-row no-gutters>
            <v-col cols="3">
              <p class="marker-text-headline mb-0">ระยะทาง</p>
              <p class="marker-text-data mb-0">
                {{ processDistanceText(marker.checkpointArrivalDistance) }}
              </p>
            </v-col>
            <v-col cols="3">
              <p class="marker-text-headline mb-0">ระยะเวลา</p>
              <p class="marker-text-data mb-0">
                <span :class="marker.checkpointArrivalDurationTextStyle">
                  {{ marker.checkpointArrivalDurationText }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="marker-text-headline mb-0">ถึงเวลา</p>
              <p class="marker-text-data mb-0">
                {{
                  marker.checkpointArrivalDate
                    ? dateStringToDateFormat(marker.checkpointArrivalDate, {
                        type: "datetime",
                        returnThai: false,
                      })
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pt-2" v-if="idx === 0">
          <p class="marker-text-topic mb-0">ข้อมูลจริง</p>
          <v-row no-gutters>
            <v-col cols="4">
              <p class="marker-text-headline mb-0">ระยะทาง</p>
              <p class="marker-text-data mb-0">
                {{ processDistanceText(marker.checkpointArrivalDistance) }}
              </p>
            </v-col>
            <v-col cols="4">
              <p class="marker-text-headline mb-0">ระยะเวลา</p>
              <p class="marker-text-data mb-0">
                <span :class="marker.checkpointArrivalDurationTextStyle">
                  {{ marker.checkpointArrivalDurationText }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p class="marker-text-headline mb-0">ถึงเวลา</p>
              <p class="marker-text-data mb-0">
                {{
                  marker.checkpointArrivalDate
                    ? dateStringToDateFormat(marker.checkpointArrivalDate, {
                        type: "datetime",
                        returnThai: false,
                      })
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
    <v-card class="ma-0 pa-0 white" elevation="0" flat>
      <v-card-text class="d-flex flex-wrap py-1 px-0">
        <v-btn
          class="flex-grow-1 ma-1"
          elevation="0"
          color="primary"
          @click="openNewDataDialog(0)"
          :disabled="!isPermissionCanEdit || isTemplateRouteView"
        >
          <v-icon> mdi-plus </v-icon> เพิ่มตำแหน่งใหม่
        </v-btn>

        <v-btn
          class="flex-grow-1 ma-1"
          elevation="0"
          @click="sortDialog()"
          :disabled="
            markerList.length <= 1 ||
            !isPermissionCanEdit ||
            isTemplateRouteView
          "
        >
          <v-icon> mdi-sort-variant </v-icon>
          สลับตำแหน่ง
        </v-btn>
      </v-card-text>
    </v-card>
    <AppOverlay :absolute="true" :isLoading="componentLoading" :opacity="0.1" />
    <AppOverlayReloadData :isShow="componentfetchfail" @reload-data="getData" />
    <!-- <v-btn @click="clearMarker()" class="mt-2"> clear marker </v-btn> -->
    <CreateMarkerDialog ref="createMarkerDialog" />
    <SortMarkerDialog ref="sortMarkerDialog" />
    <AppDialogConfirm ref="confirmDelete" />
    <SaveRouteDialog ref="saveRouteDialog" />
    <!-- <ImportRouteDialog ref="importRouteDialog" /> -->
    <AppDialogConfirm ref="routeOperationAlertDialog" />
  </v-card>
</template>

<script>
import Vue from "vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CreateMarkerDialog from "./CreateMarkerDialog.vue";
import { CUSTOM_MARKER_ICON } from "@/services/map/mapSettings";
import _ from "lodash";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import {
  processDurationText,
  processDistanceText,
  processDurationObject,
  processDistanceObject,
  processSecondtoMinuteDurationCeil,
  processMetertoKmDistanceCeil,
  processDurationCeilHelper,
  processDistanceCeilHelper,
} from "@/services/appFuncHelpper";
//
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

import moment from "moment";

import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import SortMarkerDialog from "./SortMarkerDialog.vue";
import SaveRouteDialog from "./SaveRouteDialog.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import {
  convertLocationToGObject,
  getDurationFromResponseDataMapService,
  getDistanceFromResponseDataMapService,
  getMapDirection,
  getNewGoogleMapObj,
} from "@/views/jobs/manageView/googleMapComponents/GoogleMapService";

import {
  getMarkerService,
  postJobMarkerTemplateService,
  putJobMarkerTemplateService,
  putMarkerService,
} from "@/services/api/jobs";
import AppOverlay from "@/components/AppOverlay.vue";
import AppOverlayReloadData from "@/components/AppOverlayReloadData.vue";
import ImportRouteDialog from "./ImportRouteDialog.vue";
import { getRouteTemplateMarkerService } from "@/services/api/contract_job_routes";
import { processPermission } from "@/services/permissions";

export default {
  computed: {},
  mounted() {
    // DONE:: please check from url string
    this.componentLoading = true;
    if (this.$route.name !== "jobsEdit") {
      // jobsCreate
      this._setMapObject();
      this.componentLoading = false;
      this.isTemplateRouteView = false;
    } else {
      this.isEditView = true;
    }
    this.checkUserPermissions();
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    dateStringToDateFormat: dateStringToDateFormat,

    startComponent() {
      if (!_.isNil(this.$route.params.id)) {
        this.firstLoad = true;
        this.jobId = this.$route.params.id;
        this.getData();
      } else {
        this.$router.push("/caj/jobs/list");
      }
    },

    _collectMarkerDuration() {
      this.markerListDuration = [];
      for (let i = 0; i < this.markerList.length; i++) {
        if (_.isNil(this.markerList[i]["duration"])) {
          continue;
        }
        // 'value' in second
        this.markerListDuration.push(this.markerList[i]["duration"]);
      }
      this.$store.commit(
        "manageRoute/setMarkerListDuration",
        _.clone(this.markerListDuration)
      );
    },
    _processNewJobData(responseData) {
      return {
        estimateEndDate: responseData["jobEstimateEndTime"],
        jobStatusList: responseData["jobStatusList"],
        ...responseData["incomeAndRecomponse"],
      };
    },
    _processMarkerAction(newJobData) {
      this.mapPlotMarker();
      this.mapPlotLine();
      this._collectMarkerDuration();

      if (!_.isNil(newJobData)) {
        this.$store.dispatch("manageRoute/updateJobData", newJobData);
      }
    },
    async _setMapObject() {
      if (this.googleMapObject === null) {
        this.googleMapObject = await getNewGoogleMapObj();
      }
    },
    async getData() {
      await this._setMapObject();
      try {
        const responseData = await getMarkerService(
          this.$store,
          `id=${this.jobId}`
        );

        if (responseData["code"] === 200) {
          this.tempMarkerList = _.cloneDeep(responseData["data"]);
          this.markerList = responseData["data"];

          this.processMarkerList();
        }
        // plot marker and line
        if (this.markerList.length > 0) {
          this._processMarkerAction(null);
        }
        this.componentfetchfail = false;
        this.componentLoading = false;
      } catch (error) {
        // error
        console.error(error);
        this.componentfetchfail = true;
        this.componentLoading = false;
      }
    },
    checkTempldateRouteIsSameNew() {
      const _editMarkerRouteId =
        this.$store.state.manageRoute.editMarkerRouteId;
      const _currentRouteIdInJob = _.get(
        this.$store.state.manageRoute,
        "jobData.templateRoute.value",
        null
      );

      if (this.isEditView && _editMarkerRouteId !== null) {
        // case select default
        if (_editMarkerRouteId === _currentRouteIdInJob) {
          this.isTemplateRouteView = false;
          return true;
        }
      } else if (this.isEditView && _editMarkerRouteId === null) {
        // case clear all
        this.isTemplateRouteView = false;
        return true;
      }

      this.isTemplateRouteView = true;
      return false;
    },

    async getTemplateRouteData() {
      this._setMapObject();
      this.componentfetchfail = false;
      this.componentLoading = true;

      try {
        if (this.checkTempldateRouteIsSameNew()) {
          this.setTempldateDataToMarkerList();
          return;
        }

        const responseData = await getRouteTemplateMarkerService(
          this.$store,
          `id=${this.$store.state.manageRoute.editMarkerRouteId}`
        );

        this.markerList = this.processNewStartDateToEstimateArrivalTime(
          responseData["data"],
          "editMarkerRouteStartDate"
        );

        this.processMarkerList();

        if (this.markerList.length > 0) {
          this._processMarkerAction(null);
        }
      } catch (error) {
        console.error(error);
        this.componentfetchfail = false;
        this.isTemplateRouteView = false;
      }

      this.componentLoading = false;
    },
    setTempldateDataToMarkerList() {
      this.componentLoading = true;

      this.markerList = this.processNewStartDateToEstimateArrivalTime(
        this.tempMarkerList,
        "editMarkerRouteStartDate"
      );

      this.processMarkerList();

      if (this.markerList.length > 0) {
        this._processMarkerAction(null);
      } else {
        this.mapPlotMarker();
        this.mapPlotLine();
        this._collectMarkerDuration();
      }

      this.checkTempldateRouteIsSameNew();
      this.componentLoading = false;
    },
    processNewStartDateToEstimateArrivalTime(markers, fieldEditMarker) {
      // process startDate data list
      let _markerList = _.cloneDeep(markers);
      const _st = _.get(
        this.$store.state,
        `manageRoute.${fieldEditMarker}`,
        null
      );

      if (!_.isNil(_st) && _markerList.length > 0) {
        let _stMoment = moment(_st);
        for (let i = 1; i < _markerList.length; i++) {
          _markerList[i]["estimateArrivalTime"] = _stMoment
            .add(_markerList[i]["duration"], "s")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }

      return _markerList;
    },
    // Dialog
    async openNewDataDialog() {
      this.isOnOperation = true;
      let lastMarker = null;
      let selectedMarker = null;

      if (this.markerList.length !== 0) {
        // copy last
        lastMarker = _.cloneDeep(this.markerList[this.markerList.length - 1]);
      }
      //
      let markerData = await this.$refs.createMarkerDialog.open(
        lastMarker,
        selectedMarker,
        null,
        this.markerListPlaceId,
        this.markerList.length,
        false
      );

      // check and update
      if (!_.isNil(markerData)) {
        let { newData } = markerData;
        this.componentLoading = true;

        try {
          // create mockup
          let _reqBody = [];
          for (let i = 0; i < this.markerList.length; i++) {
            _reqBody.push({
              _id: this.markerList[i]["_id"],
              order: i + 1,
            });
          }

          newData["order"] = this.markerList.length + 1;
          _reqBody.push(newData);
          // TODO:: sending to database;
          let responseData = await putMarkerService(
            this.$store,
            `id=${this.jobId}&mode=1`,
            {
              marker: _reqBody,
            }
          );
          let newJobData = null;

          if (responseData["code"] === 200) {
            newData["_id"] = responseData["data"].insertId; // set id
            newData["parentMarkerId"] = responseData["data"].parentId[0]; // parent marker
            newData["order"] = newData["order"];
            // assign estimate arrival time and departure time
            newData["estimateDepartureTime"] =
              responseData["data"]["estimateDepartureTimeStr"][0];
            newData["estimateArrivalTime"] =
              responseData["data"]["estimateArrivalTimeStr"][0];
            newData["estimateDepartureTimePosix"] =
              responseData["data"]["estimateDepartureTimePosix"][0];
            newData["estimateArrivalTimePosix"] =
              responseData["data"]["estimateArrivalTimePosix"][0];
            // end
            newData["distanceText"] = processDistanceObject(
              newData["distance"]
            );
            newData["durationText"] = processDurationObject(
              newData["duration"]
            );
            newJobData = this._processNewJobData(responseData["data"]);
            //
            this.$store.commit(
              "manageRoute/setJobReportData",
              responseData["data"]["jobReport"]
            );
            this.markerList.push(newData);
          } else {
            // this disabled form
            // show reload data
            throw "Fail from server";
          }
          // plot marker and line
          this.processMarkerList();
          this._processMarkerAction(newJobData);
        } catch (error) {
          // this disabled form
          // alert fail to from network
          console.error("ADD MARKER FAIL", error);
        }
      } else {
        console.error("NEW MARKER DATA IS EMPTY");
      }
      //
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async sortDialog() {
      // has mongo id
      let _orderData = [];
      this.isOnOperation = true;
      for (let i = 0; i < this.markerList.length; i++) {
        _orderData.push({
          _id: this.markerList[i]["_id"],
          order: i,
          orderText: i + 1,
          parentMarkerId: this.markerList[i]["parentMarkerId"],
          gPlaceId: this.markerList[i]["gplaceId"],
          name: this.markerList[i]["name"],
          //
          estimateDuration: getDurationFromResponseDataMapService(
            this.markerList[i]["gDirection"]
          ),
          estimateDistance: getDistanceFromResponseDataMapService(
            this.markerList[i]["gDirection"]
          ),
          duration: this.markerList[i]["duration"],
          distance: this.markerList[i]["distance"],
          distanceText: this.markerList[i]["distanceText"],
          durationText: this.markerList[i]["durationText"],
          //
          coordinates: this.markerList[i]["coordinates"],
          gDirectionOption: this.markerList[i]["gDirectionOption"],
          estimateArrivalTimePosix:
            this.markerList[i]["estimateArrivalTimePosix"],
          estimateDepartureTimePosix:
            this.markerList[i]["estimateDepartureTimePosix"],
        });
      }

      _orderData = await this.$refs.sortMarkerDialog.open(
        _orderData,
        "manageRoute"
      );

      let _reqBody = []; // for update data
      let _markerList = []; // for display data
      let changeIndex = false;

      const selected_reqBody_key = [
        "_id",
        "order",
        "duration",
        "distance",
        "parentMarkerId",
        "gDirection",
        "gDirectionOption",
        "edited",
      ];
      this.componentLoading = true;

      if (_orderData !== null) {
        for (let i = 0; i < _orderData.length; i++) {
          // set
          _markerList.push({
            ...this.markerList[_orderData[i]["order"]],
            order: i + 1,
            duration: _orderData[i]["duration"],
            distance: _orderData[i]["distance"],
            parentMarkerId: _orderData[i]["parentMarkerId"],
            gDirection:
              i === 0
                ? null
                : _orderData[i]["gDirection"] === null
                ? this.markerList[_orderData[i]["order"]]["gDirection"]
                : _orderData[i]["gDirection"],
            gDirectionOption: _orderData[i]["gDirectionOption"],
          });

          if (_orderData[i]["edited"] === true) {
            // order by select current order
            // update duration, distance, gDirection
            let item = _.pickBy(_markerList[i], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            item["edited"] = true;
            _reqBody.push(item);
            changeIndex = true;
          } else {
            // keep order,
            _reqBody.push({
              _id: this.markerList[_orderData[i]["order"]]["_id"],
              parentMarkerId: _orderData[i]["parentMarkerId"],
              edited: false,
              order: i + 1,
            });
          }
        }

        // calculate new distance and time
        if (changeIndex) {
          try {
            // DONE:: update to server
            let responseData = await putMarkerService(
              this.$store,
              `id=${this.jobId}&mode=3`,
              {
                marker: _reqBody,
              }
            );

            if (responseData["code"] === 200) {
              let newJobData = null;
              // loop responseData data to updateMarkerList
              for (let i = 0; i < _markerList.length; i++) {
                _markerList[i]["estimateDepartureTime"] =
                  responseData["data"]["estimateDepartureTimeStr"][i];
                _markerList[i]["estimateArrivalTime"] =
                  responseData["data"]["estimateArrivalTimeStr"][i];
                _markerList[i]["estimateDepartureTimePosix"] =
                  responseData["data"]["estimateDepartureTimePosix"][i];
                _markerList[i]["estimateArrivalTimePosix"] =
                  responseData["data"]["estimateArrivalTimePosix"][i];
                //

                if (_.isNumber(_markerList[i]["duration"])) {
                  _markerList[i]["durationText"] = processDurationObject(
                    _markerList[i]["duration"]
                  );
                  _markerList[i]["distanceText"] = processDistanceObject(
                    _markerList[i]["distance"]
                  );
                } else {
                  _markerList[i]["durationText"] = null;
                  _markerList[i]["distanceText"] = null;
                }
              }
              //
              this.$store.commit(
                "manageRoute/setJobReportData",
                responseData["data"]["jobReport"]
              );
              //
              newJobData = this._processNewJobData(responseData["data"]);
              this.markerList = _.cloneDeep(_markerList);

              this.processMarkerList();
              this._processMarkerAction(newJobData);
            } else {
              console.error("order marker fail from server");
            }
          } catch (error) {
            // DONE:: update to server
            console.error("order marker fail from process");
          }
        }
      }
      this.isOnOperation = false;
      this.componentLoading = false;
    },
    async editDialog(idx, canEdit) {
      this.isOnOperation = true;
      let lastMarker = null;
      let selectedMarker = _.cloneDeep(this.markerList[idx]);
      let nextMarker = null;

      if (idx > 0) {
        lastMarker = _.cloneDeep(this.markerList[idx - 1]);
      }

      if (idx < this.markerList.length - 1) {
        nextMarker = _.cloneDeep(this.markerList[idx + 1]);
      }
      //// OPEN DIALOG
      let markerData = await this.$refs.createMarkerDialog.open(
        lastMarker,
        selectedMarker,
        nextMarker,
        this.markerListPlaceId,
        idx,
        canEdit,
        false
      );

      if (_.isNil(markerData)) {
        return;
      }

      this.componentLoading = true;

      try {
        // TODO:: update to server
        const selected_reqBody_key = [
          "_id",
          "order",
          "name",
          "coordinates",
          "duration",
          "distance",
          //
          "gplaceId",
          "gplace",
          "gplaceGeocode",
          "gDirection",
          "gDirectionOption",
          //
          "checkpointRadiusInMeter",
          "checkpointDurationPercent",
          "checkpointLimitSpeedKmPerHours",
        ];

        let _reqBody = [];
        // process request body at index
        const currentIdx = idx;
        const childIdx = idx + 1;

        for (let i = 0; i < this.markerList.length; i++) {
          // if current marker
          if (i === currentIdx) {
            let _obj = _.pickBy(markerData["newData"], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            _obj["_id"] = this.markerList[i]["_id"];
            _obj["edit"] = true;
            _reqBody.push(_obj);
          } else if (i === childIdx && !_.isNil(markerData["newNextMarker"])) {
            // next of change marker
            let _obj = _.pickBy(markerData["newNextMarker"], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            _obj["_id"] = this.markerList[i]["_id"];
            _obj["edit"] = true;
            _reqBody.push(_obj);
          } else {
            _reqBody.push({
              _id: this.markerList[i]["_id"],
              order: i + 1,
              edit: false,
            });
          }
        }

        // === CALL API
        let responseData = await putMarkerService(
          this.$store,
          `id=${this.jobId}&mode=2`,
          {
            marker: _reqBody,
          }
        );

        if (responseData["code"] === 200) {
          let newJobData = null;
          if (!_.isNil(markerData["newNextMarker"])) {
            // update next marker direction (child marker)
            let _newNextMarker = {
              ...nextMarker,
              ...markerData["newNextMarker"],
            };
            this.markerList.splice(childIdx, 1);
            this.markerList.splice(childIdx, 0, _newNextMarker);
          }
          // update edit marker
          let _selectedMarker = { ...selectedMarker, ...markerData["newData"] };
          this.markerList.splice(currentIdx, 1);
          this.markerList.splice(currentIdx, 0, _selectedMarker);
          // loop responseData data to updateMarkerList
          for (let i = 0; i < this.markerList.length; i++) {
            this.markerList[i]["estimateDepartureTime"] =
              responseData["data"]["estimateDepartureTimeStr"][i];
            this.markerList[i]["estimateArrivalTime"] =
              responseData["data"]["estimateArrivalTimeStr"][i];
            this.markerList[i]["estimateDepartureTimePosix"] =
              responseData["data"]["estimateDepartureTimePosix"][i];
            this.markerList[i]["estimateArrivalTimePosix"] =
              responseData["data"]["estimateArrivalTimePosix"][i];
          }
          newJobData = this._processNewJobData(responseData["data"]);
          this.$store.commit(
            "manageRoute/setJobReportData",
            responseData["data"]["jobReport"]
          );
          // plot marker and line
          this.processMarkerList();
          this._processMarkerAction(newJobData);
        } else {
          throw "fail to update from server";
        }
      } catch (error) {
        console.log("edit marker fail", error);
      }
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async deleteDialog(idx) {
      this.isOnOperation = true;
      let marker = this.markerList[idx];
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบตำแหน่งนี้ ?",
          `ลบตำแหน่ง "${marker.name}" ใช่หรือไหม`,
          DialogType.ERROR,
          {
            width: 400,
            zIndex: 200,
            noconfirm: true,
          }
        )
      ) {
        // call main loading
        this.componentLoading = true;
        let updateRemaining = false;

        try {
          let _reqBody = {};
          let newDurationOfMarker = null;
          let newDistanceOfMarker = null;
          let _gDirectionOption = null;
          if (this.markerList.length > 1) {
            if (idx === 0) {
              // update next to first order
              _reqBody = {
                reId: marker["_id"],
                upId: this.markerList[idx + 1]["_id"],
                paId: null,
                newOrder: idx + 1, // 0 => 1 (readable number),
                duration: null,
                distance: null,
                gDirection: null,
              };
              updateRemaining = true;
            } else if (idx === this.markerList.length - 1) {
              // remove last index
              _reqBody = {
                reId: marker["_id"],
                upId: null,
                paId: null,
                newOrder: null,
              };
            } else {
              // get map direction from google
              _gDirectionOption = {
                ...this.markerList[idx]["gDirectionOption"],
              };

              // PROCESS: Next Departure date
              if (
                !_.isNil(this.markerList[idx - 1]["estimateArrivalTimePosix"])
              ) {
                let nowDate = new Date();
                let processDate = new Date(
                  this.markerList[idx - 1]["estimateArrivalTimePosix"]
                );
                if (nowDate.valueOf() < processDate.valueOf()) {
                  _gDirectionOption["drivingOptions"]["departureTime"] =
                    processDate;
                } else {
                  _gDirectionOption["drivingOptions"]["departureTime"] =
                    moment()
                      .add(60 * 10, "s", true)
                      .toDate();
                }
              } else {
                // get now if cannot estimat
                _gDirectionOption["drivingOptions"]["departureTime"] = moment()
                  .add(60 * 10, "s", true)
                  .toDate();
              }
              //

              // get last arrive time
              let newDirect = await getMapDirection(
                this.googleMapObject,
                convertLocationToGObject(
                  this.googleMapObject,
                  this.markerList[idx - 1]["coordinates"]
                ),
                convertLocationToGObject(
                  this.googleMapObject,
                  this.markerList[idx + 1]["coordinates"]
                ),
                _gDirectionOption
              );

              newDurationOfMarker = getDurationFromResponseDataMapService(
                newDirect["data"]
              );
              newDistanceOfMarker = getDistanceFromResponseDataMapService(
                newDirect["data"]
              );

              if (newDirect["status"] === "OK") {
                _reqBody = {
                  reId: marker["_id"],
                  upId: this.markerList[idx + 1]["_id"],
                  paId: this.markerList[idx - 1]["_id"],
                  newOrder: idx + 1, // 0 => 1 (readable number),
                  duration: !_.isNil(newDirect["data"])
                    ? processDurationCeilHelper(newDurationOfMarker)
                    : null,
                  distance: !_.isNil(newDirect["data"])
                    ? processDistanceCeilHelper(newDistanceOfMarker)
                    : null,
                  estimateDuration: getDurationFromResponseDataMapService(
                    newDirect["data"]
                  ),
                  estimateDistance: getDistanceFromResponseDataMapService(
                    newDirect["data"]
                  ),
                  gDirection: newDirect["data"],
                  gDirectionOption: _gDirectionOption,
                };
              } else {
                throw "Fetch direction fail";
              }

              updateRemaining = true;
            }
          } else {
            // only one
            _reqBody = {
              reId: marker["_id"],
              upId: null,
              paId: null,
              newOrder: null,
            };
          }

          let responseData = await putMarkerService(
            this.$store,
            `id=${this.jobId}&mode=4`,
            _reqBody
          );

          if (responseData["code"] === 200) {
            let newJobData = null;
            // updateRemaining
            // response must return new google option
            // response must return all posix time
            if (updateRemaining === true) {
              if (idx === 0) {
                this.markerList[idx + 1]["duration"] = null;
                this.markerList[idx + 1]["distance"] = null;
                this.markerList[idx + 1]["gDirection"] = null;
                this.markerList[idx + 1]["parentMarkerId"] = null;
                this.markerList[idx + 1]["order"] = 1;
              } else {
                this.markerList[idx + 1]["duration"] = newDurationOfMarker;
                this.markerList[idx + 1]["distance"] = newDistanceOfMarker;
                this.markerList[idx + 1]["durationText"] =
                  processDurationObject(newDurationOfMarker);
                this.markerList[idx + 1]["distanceText"] =
                  processDistanceObject(newDistanceOfMarker);
                this.markerList[idx + 1]["gDirection"] = _reqBody["gDirection"];
                this.markerList[idx + 1]["parentMarkerId"] = _reqBody["paId"];
                this.markerList[idx + 1]["order"] = idx + 1;
              }
              // update new gDirectionOption
              this.markerList[idx + 1]["gDirectionOption"] =
                responseData["data"]["gDirectionOption"];
            }
            // remove current
            this.markerList.splice(idx, 1);
            // set new depart and arrivel
            for (let i = 0; i < this.markerList.length; i++) {
              this.markerList[i]["estimateDepartureTime"] =
                responseData["data"]["estimateDepartureTimeStr"][i];
              this.markerList[i]["estimateArrivalTime"] =
                responseData["data"]["estimateArrivalTimeStr"][i];
              this.markerList[i]["estimateDepartureTimePosix"] =
                responseData["data"]["estimateDepartureTimePosix"][i];
              this.markerList[i]["estimateArrivalTimePosix"] =
                responseData["data"]["estimateArrivalTimePosix"][i];
            }

            this.$store.commit(
              "manageRoute/setJobReportData",
              responseData["data"]["jobReport"]
            );

            newJobData = this._processNewJobData(responseData["data"]);
            this.processMarkerList();
            this._processMarkerAction(newJobData);
          } else {
            // TODO:: dialog error;
            throw "Web Request fail";
          }
        } catch (error) {
          console.log("Delete marker view from process::", error);
        }
        this.componentLoading = false;
        this.isOnOperation = false;
      }
    },
    async openSaveRouteDialog() {
      this.isOnOperation = true;
      let _dialogData = {
        jobId: this.jobId,
        markerList: _.map(this.markerList, (obj) =>
          _.pick(obj, [
            "_id",
            "name",
            "order",
            "duration",
            "distance",
            "durationText",
            "distanceText",
          ])
        ),
      };

      let resDialogData = await this.$refs.saveRouteDialog.open(_dialogData);
      if (!_.isNil(resDialogData)) {
        this.componentLoading = true;
        try {
          let _markerList = [];
          // get only id
          for (let i = 0; i < this.markerList.length; i++) {
            _markerList.push(this.markerList[i]["_id"]);
          }
          let _reqData = {
            ...resDialogData,
            markerId: _markerList,
          };
          //
          let queryString = new URLSearchParams({ id: this.jobId }).toString();
          let respData = await postJobMarkerTemplateService(
            this.$store,
            queryString,
            _reqData
          );

          if (respData["code"] === 200) {
            // alert..
            await this.$refs.routeOperationAlertDialog.open(
              "บันทึกเส้นทางใหม่สำเร็จ",
              null,
              DialogType.POSITIVE,
              {
                width: 400,
                zIndex: 200,
                noconfirm: false,
              }
            );
          } else {
            throw respData["data"];
          }
        } catch (error) {
          await this.$refs.routeOperationAlertDialog.open(
            "บันทึกเส้นทางใหม่ไม่สำเร็จ",
            error["message"],
            DialogType.ERROR,
            {
              width: 400,
              zIndex: 200,
              noconfirm: false,
            }
          );
          console.error("Save route from process::", error["message"]);
        }
      } else {
        console.error("save route: cancel by user");
      }
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async openImportRouteDialog() {
      this.isOnOperation = true;
      let resDialogData = await this.$refs.importRouteDialog.open();
      if (!_.isNil(resDialogData)) {
        this.componentLoading = true;
        try {
          //
          let queryString = new URLSearchParams({ id: this.jobId }).toString();
          let respData = await putJobMarkerTemplateService(
            this.$store,
            queryString,
            resDialogData
          );
          // get data

          if (respData["code"] === 200) {
            // await this.$refs.routeOperationAlertDialog.open(
            //   "นำเข้าเส้นทางสำเร็จ",
            //   null,
            //   DialogType.POSITIVE,
            //   {
            //     width: 400,
            //     zIndex: 200,
            //     noconfirm: false,
            //     persistence: true,
            //   }
            // );
            this.getData();
            this.$store.dispatch(
              "manageRoute/updateJobData",
              this._processNewJobData(respData["data"])
            );
            this.$store.commit(
              "manageRoute/setJobReportData",
              respData["data"]["jobReport"]
            );
          } else {
            throw respData["data"];
          }
        } catch (error) {
          await this.$refs.routeOperationAlertDialog.open(
            "นำเส้นทางไม่สำเร็จ",
            error["message"],
            DialogType.ERROR,
            {
              width: 400,
              zIndex: 200,
              noconfirm: false,
            }
          );
          console.error("import route from process::", error["message"]);
        }
      } else {
        console.error("import route: cancel by user");
      }
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async copyLocationText(idx) {
      let copyText = null;
      this.componentLoading = true;
      if (!_.isNil(this.markerList[idx]["coordinates"])) {
        try {
          copyText = `${this.markerList[idx]["coordinates"][0]},${this.markerList[idx]["coordinates"][1]}`;
          await navigator.clipboard.writeText(copyText);
          // console.log(`Content copied to clipboard ${copyText}`);
        } catch (err) {
          // console.error("Failed to copy: ", err);
        }
      }
      this.componentLoading = false;
    },
    mapPlotMarker() {
      // set icon to marker
      let processMarker = [];
      let processMapBound = [];
      // process each marker to pin object
      for (let i = 0; i < this.markerList.length; i++) {
        processMarker.push({
          id: `${this.markerList[i].gplaceId}_${new Date().getTime()}`,
          position: {
            lat: this.markerList[i]["coordinates"][0],
            lng: this.markerList[i]["coordinates"][1],
          },
          //
          duration: this.markerList[i]["duration"],
          distance: this.markerList[i]["distance"],
          //
          iconMode: i === 0 ? 0 : i === this.markerList.length - 1 ? 1 : 2,
          icon:
            i === 0
              ? _.clone(CUSTOM_MARKER_ICON.START_POINT)
              : i === this.markerList.length - 1
              ? _.clone(CUSTOM_MARKER_ICON.FINISH_POINT)
              : _.clone(CUSTOM_MARKER_ICON.CHECKPOINT),
          //
          disableAnimation: false,
          vehicleAnchor: false,
        });

        processMapBound.push(this.markerList[i]["coordinates"]);
      }

      // process pin
      for (let i = 0; i < processMarker.length; i++) {
        if (!_.isNil(processMarker[i]["icon"])) {
          let selectedIcon = faLocationPin.icon;
          // set text
          processMarker[i]["label"] = {
            text: String(i + 1),
            fontSize: "18px",
            fontWeight: "700",
            color: "#ffffff",
          };
          // https://stackoverflow.com/questions/33321440/google-maps-api-add-custom-svg-marker-with-label
          processMarker[i]["icon"]["labelOrigin"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1] / 3
            );
          //

          // console.log(processMarker[i]["icon"], selectedIcon);
          processMarker[i]["icon"]["path"] = selectedIcon[4];
          processMarker[i]["icon"]["anchor"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1]
            );
          //
        }
      }
      // return data and update center
      this.$store.dispatch("manageRoute/updateMarkerList", processMarker);
      this.$store.dispatch(
        "manageRoute/updateBoundLatLngCenter",
        processMapBound
      );
    },

    async mapPlotLine() {
      let lines = [];

      for (let i = 0; i < this.markerList.length; i++) {
        if (!_.isNil(this.markerList[i]["gDirection"])) {
          lines.push({
            id: `line_${
              this.markerList[i]["gplaceId"]
            }_${new Date().getTime()}`,
            dashLine: true,
            gDirectionFormat: true, // from server
            ...this.markerList[i]["gDirection"],
          });
        }
      }

      // console.log(lines);

      this.$store.dispatch("manageRoute/updatePolyLine", lines);
    },
    // v-card process
    processMarkerList() {
      // clear placeId
      this.clearPlaceIdFromList();
      for (let i = 0; i < this.markerList.length; i++) {
        // get pleceId
        this.addPlaceIdToList(this.markerList[i]["gplaceId"]);
        this.markerList[i]["markerStyle"] =
          i === 0
            ? "marker-start"
            : i === this.markerList.length - 1
            ? "marker-finish"
            : "marker-location";

        this.markerList[i]["markerColor"] = "#03A6F9"; //"#659bde";
        //
        this.processMarkerWhenBeformMissing(i);
      }
    },
    processMarkerWhenBeformMissing(markerIndex) {
      this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] = "";
      if (!_.isNil(this.markerList[markerIndex]["checkpointArrivalDuration"])) {
        this.markerList[markerIndex]["checkpointArrivalDurationText"] =
          processDurationText(
            this.markerList[markerIndex]["checkpointArrivalDuration"]
          );
      } else if (
        !_.isNil(
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateSign"
          ]
        )
      ) {
        let displayText = "(";

        if (
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateSign"
          ] === 1
        ) {
          displayText = `${displayText}+`;
          this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] =
            "red--text";
        } else {
          displayText = `${displayText}`;
          this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] =
            "green--text";
        }

        displayText = `${displayText}${this.processDurationText(
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
          ]
        )}`;

        this.markerList[markerIndex][
          "checkpointArrivalDurationText"
        ] = `${displayText})`;
      } else {
        // noot data
        this.markerList[markerIndex]["checkpointArrivalDurationText"] = "-";
      }
    },
    addPlaceIdToList(placeId) {
      this.markerListPlaceId.push(placeId);
    },
    updatePlaceIdToList(idx, placeId) {
      this.markerListPlaceId[idx] = placeId;
    },
    removePlaceIdFromList(idx) {
      this.markerListPlaceId.splice(idx, 1);
    },
    clearPlaceIdFromList() {
      this.markerListPlaceId = [];
    },
    //
    displayStatus(_status) {
      switch (_status) {
        case "W":
          return "กำลังไป";
        case "P":
          return "ถึงเป้าหมาย";
        case "M":
          return "พลาด";
        default:
          return "ไม่มีข้อมูล";
      }
    },
    displayFontColorStatus(_status) {
      switch (_status) {
        case "W":
          return "status-waiting";
        case "P":
          return "status-pass";
        case "M":
          return "status-missing";
        default:
          return "info";
      }
    },
    displayColorStatus(_status) {
      switch (_status) {
        case "W":
          return "#FFF5E5";
        case "P":
          return "#EDF7ED";
        case "M":
          return "#FEECEB";
        default:
          return "info";
      }
    },
    //
    setStatusFromStoreData(newStatusList) {
      if (this.isOnOperation === false) {
        // console.log("Marker Updated");
        if (!_.isNil(newStatusList["markerList"])) {
          for (let i = 0; i < this.markerList.length; i++) {
            let _localMarker =
              newStatusList["markerList"][this.markerList[i]["_id"]];
            this.markerList[i]["closestDistance"] =
              _localMarker["closestDistance"];
            this.markerList[i]["status"] = _localMarker["status"];
            this.markerList[i]["checkpointArrivalDistance"] =
              _localMarker["checkpointArrivalDistance"];
            this.markerList[i]["checkpointArrivalDuration"] =
              _localMarker["checkpointArrivalDuration"];
            this.markerList[i]["checkpointArrivalDate"] =
              _localMarker["checkpointArrivalDate"];
            this.markerList[i]["checkpointArrivalDate"] =
              _localMarker["checkpointArrivalDate"];
            this.markerList[i][
              "checkpointArrivalDifferenceSecondEstimateSign"
            ] =
              _localMarker["checkpointArrivalDifferenceSecondEstimateSign"] ||
              null;
            this.markerList[i][
              "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
            ] =
              _localMarker[
                "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
              ] || null;
            this.processMarkerWhenBeformMissing(i);
          }
        }
      } else {
        console.error("Marker Under OnOperetation");
      }
    },
    resetData() {
      // this.isOnOperation = false;
      // this.componentLoading = true;
      // this.componentfetchfail = false;
      // this.firstLoad = false;
      // this.jobId = null;
      // this.isPermissionCanEdit = false;
      // this.isPermissionCanView = true;
      // this.isEditView = false;
      // this.editMarkerRouteInit = false;
      this.isTemplateRouteView = false;
      //
      this.markerListDuration = [];
      this.markerList = [];
      this.tempMarkerList = [];
      this.markerListPlaceId = [];
      this.orderMapping = null;
      this.ployLine = [];
      this.googleMapObject = null;
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");

        if (this.permList.jobs_info || this.isEditView === true) {
          this.isOnlyPermissionCanView = true;
        }
      }
    },
    // async _pinLineVehicle() {
    //   //
    //   let respData = await getVehicleDirectionService();
    //   //
    //   for (let i = 0; i < respData.length; i++) {
    //     respData[i]["dashLine"] = false;
    //   }
    //   //
    //   this.$store.dispatch("manageRoute/updateVehiclePolyLine", respData);
    // },
  },
  watch: {
    "$store.state.manageRoute.jobData": {
      immediate: false,
      handler() {
        if (
          this.$route.name === "jobsEdit" &&
          !_.isNil(this.$store.state.manageRoute.jobData) &&
          !this.firstLoad
        ) {
          this.startComponent();
        }
      },
    },

    "$store.state.manageRoute.jobData.status": {
      immediate: true,
      handler(newValue) {
        let isNotPlanning = newValue !== "PLANNING";
        if (
          (this.permList.jobs_info || this.isEditView === true) &&
          isNotPlanning
        ) {
          this.isOnlyPermissionCanView = true;
        } else {
          this.isOnlyPermissionCanView = false;
        }
      },
    },

    "$store.state.manageRoute.reloadMarker": {
      immediate: false,
      handler() {
        this.componentLoading = true;
        this.resetData();
        this.getData();
      },
    },
    "$store.state.manageRoute.isActivateInput": function (newValue) {
      if (!_.isNil(newValue)) {
        this.isPermissionCanEdit = newValue;
      }
    },
    "$store.state.manageRoute.markerStatusData": function (newValue) {
      if (!_.isNil(newValue)) {
        this.setStatusFromStoreData(newValue);
      }
    },
    "$store.state.manageRoute.editMarkerUpdateTs": {
      immediate: true,
      handler() {
        if (!this.editMarkerRouteInit) {
          this.editMarkerRouteInit = true;
          return;
        }
        if (!_.isNil(this.$store.state.manageRoute.editMarkerRouteId)) {
          this.getTemplateRouteData();
        } else {
          this.setTempldateDataToMarkerList();
        }
      },
    },
    "$store.state.manageRoute.editMarkerRouteStartDateUserAction": {
      immediate: true,
      handler() {
        this.markerList = this.processNewStartDateToEstimateArrivalTime(
          this.markerList,
          "editMarkerRouteStartDateUserAction"
        );
      },
    },
  },
  components: {
    CustomTextInputImprove,
    CreateMarkerDialog,
    SortMarkerDialog,
    AppDialogConfirm,
    AppOverlay,
    AppOverlayReloadData,
    SaveRouteDialog,
    ImportRouteDialog,
  },
  data() {
    return {
      isOnOperation: false,
      componentLoading: true,
      componentfetchfail: false,
      firstLoad: false,
      jobId: null,
      isPermissionCanEdit: false,
      isPermissionCanView: true,
      isOnlyPermissionCanView: false,
      isEditView: false,
      isTemplateRouteView: false,
      editMarkerRouteInit: false,
      //
      markerListDuration: [],
      markerList: [],
      tempMarkerList: [],
      markerListPlaceId: [],
      orderMapping: null,
      ployLine: [],
      googleMapObject: null,
      permList: {
        admin_all: false,
        jobs_list: false,
        jobs_info: false,
        jobs_crud: false,
        jobs_report: false,
        jobs_total_report: false,
      },
    };
  },
  destroyed() {
    this.tempMarkerList = [];
    this.markerList = [];
  },
};
</script>

<style lang="scss" scoped>
@import "./MarkerView.scss";
</style>
