<template>
  <div name="vehicleFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
      <div class="pa-6">
        <v-row>
          <v-col cols="12" sm="4">
            <InputView title="ช่วงจำนวนที่นั่ง" :isRequired="false">
              <v-row>
                <v-col class="px-0 pt-1">
                  <CustomTextInput
                    type="number"
                    v-model="formData.minSeat"
                    placeholder="เริ่มต้น"
                    :isRequired="false"
                    :value="formData.minSeat"
                  ></CustomTextInput>
                </v-col>
                <p class="title-filter pt-5">—</p>

                <v-col class="px-0 pt-1">
                  <CustomTextInput
                    type="number"
                    v-model="formData.maxSeat"
                    placeholder="สิ้นสุด"
                    :isRequired="false"
                    :value="formData.maxSeat"
                  ></CustomTextInput>
                </v-col>
              </v-row>
            </InputView>
          </v-col>
          <v-col cols="12" sm="4">
            <div>
              <InputView title="บริษัทประกันฯ" :isRequired="false">
                <v-select
                  class="app-custom-input"
                  :items="filterInsuranceCompanyList"
                  v-model="formData.company"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="nameTh"
                  outlined
                  dense
                  rounded
                  filled
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เวลาหมดประกันอยู่ในช่วงนี้"
                v-model="formData.joinInsDate"
                :isRequired="false"
                :isUpwardRange="true"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เวลาหมด พ.ร.บ. อยู่ในช่วงนี้"
                v-model="formData.joinActDate"
                :isRequired="false"
                :isUpwardRange="true"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เวลาต้องจ่าย ภาษี อยู่ในช่วงนี้"
                v-model="formData.joinTaxDate"
                :isRequired="false"
                :isUpwardRange="true"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เวลาหมด gps อยู่ในช่วงนี้"
                v-model="formData.joinGpsDate"
                :isRequired="false"
                :isUpwardRange="true"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เวลาจ่าย ค่างวด อยู่ในช่วงนี้"
                v-model="formData.joinLoanDate"
                :isRequired="false"
                :isUpwardRange="true"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <div>
              <InputView title="สถานะประกันภัยรถ" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.insStatus"
                  dense
                  color="primary"
                >
                  <v-btn v-for="(status, insIdx) in insStatus" :key="insIdx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <InputView title="สถานะ พ.ร.บ. รถ" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.actStatus"
                  dense
                  color="primary"
                >
                  <v-btn v-for="(status, actIdx) in actStatus" :key="actIdx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <InputView title="สถานะภาษีรถ" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.taxStatus"
                  dense
                  color="primary"
                >
                  <v-btn v-for="(status, taxIdx) in taxStatus" :key="taxIdx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <InputView title="สถานะการใช้งานรถ" :isRequired="false">
              <v-btn-toggle
                v-model="formData.vehicleStatus"
                dense
                color="primary"
              >
                <v-btn v-for="(status, vehIdx) in vehicleStatus" :key="vehIdx">
                  {{ status }}
                </v-btn>
              </v-btn-toggle>
            </InputView>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { getAppDataService } from "@/services/api/app";
import { getVehicleFundamental } from "@/services/api/vehicle";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "vehicleFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    CustomTextInput,
    CustomFilterDateTypingPickerRange,
    InputView,
  },

  data() {
    return {
      // input selection
      insStatus: ["ไม่หมด", "หมด"],
      actStatus: ["ไม่หมด", "หมด"],
      taxStatus: ["ไม่หมด", "หมด"],
      // filterInsuranceCompanySelected: null,
      // filterVehicleStatus: null,
      // filterInsStatus: null,
      // filterActStatus: null,
      // filterTaxStatus: null,
      // minseatInput: null,
      // maxseatInput: null,

      // filterPositionDataList: [],
      // filterVehicleBrand: [],
      // filterZoneDataList: [],

      filterInsuranceCompanyList: [],

      queryOrder: {
        // query : formData
        minseat: "minSeat",
        maxseat: "maxSeat",
        insur: "company",

        mininsurdate: "joinInsDate",
        maxinsurdate: "joinInsDate",

        minactdate: "joinActDate",
        maxactdate: "joinActDate",

        mintaxdate: "joinTaxDate",
        maxtaxdate: "joinTaxDate",

        mingpsdate: "joinGpsDate",
        maxgpsdate: "joinGpsDate",

        minloandate: "joinLoanDate",
        maxloandate: "joinLoanDate",

        insurexpired: "insStatus",
        actexpired: "actStatus",
        taxexpired: "taxStatus",
        status: "vehicleStatus",
      },
      formData: {
        minSeat: null,
        maxSeat: null,
        company: null,

        joinInsDate: [],
        joinActDate: [],
        joinTaxDate: [],
        joinGpsDate: [],
        joinLoanDate: [],

        insStatus: null,
        actStatus: null,
        taxStatus: null,
        vehicleStatus: null,
      },
      copyFormData: null,
      // filterSeatRangeDataList: [
      //   { key: { minseat: 0, maxseat: 100 }, value: "ทั้งหมด" },
      //   { key: { minseat: 6, maxseat: 10 }, value: "6-10 ที่นั่ง" },
      //   { key: { minseat: 6, maxseat: 14 }, value: "6-14 ที่นั่ง" },
      //   { key: { minseat: 10, maxseat: 15 }, value: "10-15 ที่นั่ง" },
      //   { key: { minseat: 15, maxseat: 20 }, value: "15-20 ที่นั่ง" },
      // ],

      // filterInsuranceRangeSelectedValue: [],

      // filterActRangeSelectedValue: [],

      // filterTaxRangeSelectedValue: [],

      // filterGpsRangeSelectedValue: [],

      // filterLoanRangeSelectedValue: [],
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "mininsurdate":
            case "maxinsurdate":
              this.formData.joinInsDate.push(this.$route.query[key]);
              break;
            case "minactdate":
            case "maxactdate":
              this.formData.joinActDate.push(this.$route.query[key]);
              break;
            case "mintaxdate":
            case "maxtaxdate":
              this.formData.joinTaxDate.push(this.$route.query[key]);
              break;
            case "mingpsdate":
            case "maxgpsdate":
              this.formData.joinGpsDate.push(this.$route.query[key]);
              break;
            case "minloandate":
            case "maxloandate":
              this.formData.joinLoanDate.push(this.$route.query[key]);
              break;
            case "status":
              // this.formData.vehicleStatus = this.$store.getters[
              //   "vehicle/vehicleStatusText"
              // ].indexOf(this.$route.query[key]);
              this.formData["vehicleStatus"] = this.vehicleStatus.findIndex(
                (p) => p.key == this.$route.query[key]
              );
              break;
            case "insurexpired":
              console.log(this.queryOrder[key], this.$route.query[key]);
              this.formData[this.queryOrder[key]] = this.$route.query[key];
              break;
            case "actexpired":
              console.log(this.queryOrder[key], this.$route.query[key]);
              this.formData[this.queryOrder[key]] = this.$route.query[key];
              break;
            case "taxexpired":
              console.log(this.queryOrder[key], this.$route.query[key]);
              this.formData[this.queryOrder[key]] = this.$route.query[key];
              break;
            // default:
            //   this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      // this.filterZoneDataList = await getAppDataService(this.$store, "zone");
      this.filterInsuranceCompanyList = await getAppDataService(
        this.$store,
        "com_ins"
      );
      // this.filterPositionDataList = await getAppDataService(
      //   this.$store,
      //   "position"
      // );
    },
    // async getVehicleColor() {
    //   let resp_data = await getVehicleFundamental(this.$store, "type=filter");
    //   if (resp_data.code === 200) {
    //     this.filterVehicleBrand = resp_data.data.color;
    //   } else {
    //     this.filterVehicleBrand = [];
    //   }
    // },

    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      // console.log("FormData:", this.formData);
      let filterParams = {};

      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "minSeat":
              if (this.formData["minSeat"] !== "") {
                filterParams["minseat"] = this.formData["minSeat"];
              }
              break;
            case "maxSeat":
              if (this.formData["maxSeat"] !== "") {
                filterParams["maxseat"] = this.formData["maxSeat"];
              }
              break;
            case "joinInsDate":
              if (this.formData.joinInsDate.length === 1) {
                filterParams["mininsurdate"] = this.formData.joinInsDate[0];
              } else if (this.formData.joinInsDate.length === 2) {
                filterParams["mininsurdate"] = this.formData.joinInsDate[0];
                filterParams["maxinsurdate"] = this.formData.joinInsDate[1];
              }
              break;
            case "joinActDate":
              if (this.formData.joinActDate.length === 1) {
                filterParams["minactdate"] = this.formData.joinActDate[0];
              } else if (this.formData.joinActDate.length === 2) {
                filterParams["minactdate"] = this.formData.joinActDate[0];
                filterParams["maxactdate"] = this.formData.joinActDate[1];
              }
              break;
            case "joinTaxDate":
              if (this.formData.joinTaxDate.length === 1) {
                filterParams["mintaxdate"] = this.formData.joinTaxDate[0];
              } else if (this.formData.joinTaxDate.length === 2) {
                filterParams["mintaxdate"] = this.formData.joinTaxDate[0];
                filterParams["maxtaxdate"] = this.formData.joinTaxDate[1];
              }
              break;
            case "joinGpsDate":
              if (this.formData.joinGpsDate.length === 1) {
                filterParams["mingpsdate"] = this.formData.joinGpsDate[0];
              } else if (this.formData.joinGpsDate.length === 2) {
                filterParams["mingpsdate"] = this.formData.joinGpsDate[0];
                filterParams["maxgpsdate"] = this.formData.joinGpsDate[1];
              }
              break;
            case "joinLoanDate":
              if (this.formData.joinLoanDate.length === 1) {
                filterParams["minloandate"] = this.formData.joinLoanDate[0];
              } else if (this.formData.joinLoanDate.length === 2) {
                filterParams["minloandate"] = this.formData.joinLoanDate[0];
                filterParams["maxloandate"] = this.formData.joinLoanDate[1];
              }
              break;
            case "vehicleStatus":
              filterParams[key] = this.$store.getters[
                "vehicle/vehicleStatusTextInvert"
              ](this.vehicleStatus[this.formData[this.queryOrder[key]]]);
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });

      // return
      console.log("filterParams:", filterParams);
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      this.copyFormData = _.cloneDeep(this.formData);
      this.formData = {
        minSeat: "",
        maxSeat: "",
        company: null,

        joinInsDate: [],
        joinActDate: [],
        joinTaxDate: [],
        joinGpsDate: [],
        joinLoanDate: [],

        insStatus: null,
        actStatus: null,
        taxStatus: null,
        vehicleStatus: null,
      };

      // this.minseatInput = "";
      // this.maxseatInput = "";
      // this.filterInsuranceCompanySelected = null;
      // this.filterVehicleStatus = null;
      // this.filterInsStatus = null;
      // this.filterActStatus = null;
      // this.filterTaxStatus = null;
      // this.filterInsuranceRangeSelectedValue = [];
      // this.filterActRangeSelectedValue = [];
      // this.filterTaxRangeSelectedValue = [];
      // this.filterGpsRangeSelectedValue = [];
      // this.filterLoanRangeSelectedValue = [];
    },
  },
  computed: {
    vehicleStatus() {
      return this.$store.getters["vehicle/vehicleStatusList"] || [];
    },
  },
  mounted() {
    this.getData();
    // this.getVehicleColor();
    this.getParamQuery();
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
