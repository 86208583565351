import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getRegisteredAppCustomerService(store, queryString) {
  let uri = "/job/job-registered-app-passenger";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getRegisteredAppCustomerSearchService(
  store,
  queryString
) {
  let uri = `/job/job-registered-app-passenger-search?text=${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function putRegisteredAppCustomerToJobService(
  store,
  queryString,
  payload
) {
  let uri = "/job/job-registered-app-passenger";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, payload);
}

export async function deleteRegisteredAppCustomerToJobService(
  store,
  queryString
) {
  let uri = "/job/job-registered-app-passenger";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}
