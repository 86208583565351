<template>
  <v-form ref="form" v-model="formVaild">
    <CustomCardFormViewModern
      :title="isPermissionCanEdit ? title : 'ข้อมูลรถ'"
      :elevation="0"
      :isShowTitle="true"
      :isTitleHighlight="true"
    >
      <div>
        <v-row no-gutters class="px-6">
          <v-row no-gutters class="px-3">
            <v-col cols="12" class="mb-2">
              <span class="title-input"> สถานะ </span>
              <span class="required-color"> * </span>
            </v-col>
            <v-col cols="12" class="py-0 mb-6">
              <div>
                <!-- <v-card elevation="0" height="100%" class="px-0 py-0"> -->
                <v-row no-gutters align="center" justify="center" class="px-0">
                  <v-col
                    cols="12"
                    sm="4"
                    lg="4"
                    class="px-1"
                    v-for="(value, key) in fundData.status"
                    :key="key"
                  >
                    <v-card
                      :disabled="!isPermissionCanEdit"
                      :color="getStatusColor(value)"
                      @click="statusOnClick(value)"
                      :outlined="getStatusOutline(value)"
                      ><v-card-title class="pt-2 pl-2">
                        <v-icon dark> mdi-check-circle </v-icon> </v-card-title
                      ><v-card-text align="center" class="mb-2">
                        <v-icon :dark="vehicleStatus[value.key]">
                          {{ statusIcon[value.key] }}
                        </v-icon>
                        <div v-if="getStatusOutline(value)" class="icon pt-2">
                          {{ value.value }}
                        </div>
                        <div v-else class="white--text icon pt-2">
                          {{ value.value }}
                        </div>
                      </v-card-text>
                      <v-card-actions></v-card-actions> </v-card
                  ></v-col>
                </v-row>
                <!-- </v-card> -->
              </div></v-col
            ></v-row
          >
          <v-col cols="6" lg="6" md="12" sm="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <CustomTextInputImprove
                    ref="licenseNumber"
                    v-model="formData.licenseNumber"
                    title="เลขป้ายทะเบียน"
                    placeholder="AA-1234"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุเลขป้ายทะเบียน']"
                  ></CustomTextInputImprove>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                  <CustomSelectsArrOfObj
                    ref="licenseType"
                    v-model="formData.licenseType"
                    class="custom-placeholer-color"
                    :items="fundData['licenseType'] || []"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    :loading="isLoading"
                    title="สีป้ายทะเบียน"
                    item-text="value"
                    item-value="key"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุสีป้ายทะเบียน']"
                  >
                  </CustomSelectsArrOfObj>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                  <CustomTextInputImprove
                    ref="cassieId"
                    v-model="formData.cassieId"
                    title="Cassie ID"
                    placeholder=""
                    type="text"
                    :disabled="!isPermissionCanEdit"
                    :isRequired="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุหมายเลขตัวถัง']"
                  ></CustomTextInputImprove>
                </v-col>
              </v-row> </v-card
          ></v-col>
        </v-row>
      </div>
      <v-divider class="mt-3 mb-6"></v-divider>
      <div>
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters>
                <v-col cols="12" lg="3" sm="3">
                  <!-- <CustomSelectsArrOfObj
                    ref="color"
                    title="สีรถยนต์"
                    v-model="formData.color"
                    class="custom-placeholer-color"
                    :items="fundData['color'] || []"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    item-text="value"
                    item-value="key"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุสีรถยนต์']"
                  >
                  </CustomSelectsArrOfObj> -->
                  <CustomAutocomplete
                    ref="color"
                    title="สีรถยนต์"
                    v-model="formData.color"
                    menu-props="auto"
                    itemText="value"
                    itemValue="key"
                    selectionTextIf="value"
                    itemTextListTextIf="value"
                    :smallText="true"
                    :isRequired="true"
                    :loading="isLoading"
                    :items="fundData['color'] || []"
                    :disabled="!isPermissionCanEdit"
                    :returnObject="true"
                    placeholder="เลือก หรือ พิมพ์เพื่อค้นหา"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุสีรถยนต์']"
                  >
                  </CustomAutocomplete>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomSelectsArrOfIconObj
                    ref="type"
                    title="ชนิดรถยนต์"
                    v-model="formData.type"
                    class="custom-placeholer-color"
                    :items="fundData['type'] || []"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    item-text="value"
                    item-value="key"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุชนิดรถยนต์']"
                  >
                  </CustomSelectsArrOfIconObj>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomSelectsArrOfObj
                    ref="engine"
                    title="ประเภทน้ำมันที่เติม"
                    v-model="formData.engine"
                    class="custom-placeholer-color"
                    :items="fundData['engine'] || []"
                    placeholder="เลือก"
                    :isRequired="true"
                    item-text="value"
                    item-value="key"
                    :disabled="!isPermissionCanEdit"
                    :loading="isLoading"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุประเภทน้ำมันที่เติม']"
                  >
                  </CustomSelectsArrOfObj>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomSelectsArrOfObj
                    placeholder="เลือก"
                    ref="gear"
                    v-model="formData.gear"
                    class="custom-placeholer-color"
                    :items="fundData['gear'] || []"
                    :isRequired="true"
                    title="ประเภทเกียร์"
                    item-text="value"
                    item-value="key"
                    :disabled="!isPermissionCanEdit"
                    :loading="isLoading"
                    :returnObject="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุประเภทเกียร์']"
                  >
                  </CustomSelectsArrOfObj>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="numSeat"
                    inputType="numberWithoutSign"
                    v-model="formData.seat"
                    title="จำนวนที่นั่งตามทะเบียน"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    :rules="[
                      (v) => !!v || 'จำเป็นต้องระบุจำนวนที่นั่งตามทะเบียน',
                    ]"
                  ></CustomTextInputImprove>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="numregisterdSeat"
                    inputType="numberWithoutSign"
                    v-model="formData.registerdSeat"
                    title="จำนวนที่นั่งตามจริง"
                    :isRequired="true"
                    :disabled="!isPermissionCanEdit"
                    :value="formData.registerdSeat"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุจำนวนที่นั่งตามจริง']"
                  ></CustomTextInputImprove>
                </v-col>
                <v-col cols="12" lg="6" sm="6">
                  <CustomDateTypingPickerFormInput
                    placeholder="เลือก"
                    title="วันที่ได้เป็นเจ้าของ (วัน/เดือน/ปีคศ)"
                    v-model="formData.ownershipDate"
                    :disabled="!isPermissionCanEdit"
                    :isRequired="true"
                    ref="ownershipDate"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุวันที่เป็นเจ้าของ']"
                  />
                </v-col> </v-row></v-card></v-col
        ></v-row>
      </div>
      <v-divider class="mt-3 mb-6"></v-divider>
      <div>
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="vehicleBrand"
                    v-model="formData.brand"
                    title="ยี่ห้อ"
                    :value="formData.brand"
                    :disabled="!isPermissionCanEdit"
                  ></CustomTextInputImprove>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="vehicleModel"
                    v-model="formData.model"
                    title="รุ่น"
                    type="text"
                    :disabled="!isPermissionCanEdit"
                  ></CustomTextInputImprove>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="year"
                    inputType="numberWithoutSign"
                    type="number"
                    v-model="formData.year"
                    title="ปี"
                    :disabled="!isPermissionCanEdit"
                  ></CustomTextInputImprove>
                </v-col>
                <v-col cols="12" lg="3" sm="3">
                  <CustomTextInputImprove
                    ref="vehicleStyle"
                    v-model="formData.style"
                    title="รูปแบบรถยนต์"
                    placeholder="ทรงรถยนต์ เช่นหน้าสั้น หน้ายาว"
                    type="text"
                    :disabled="!isPermissionCanEdit"
                  ></CustomTextInputImprove>
                </v-col> </v-row></v-card></v-col
        ></v-row>
      </div>
      <v-divider class="mt-3 mb-6"></v-divider>
      <div>
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <!-- <v-row no-gutters>
                <v-col cols="12" class="px-3 pb-6">
                  <InputView title="อุปกรณ์เสริม">
                    <v-row no-gutters>
                      <v-col cols="3" lg="3" md="4" sm="12">
                        <v-checkbox
                          v-model="formData.accessories.curtain"
                          label="ผ้าม่าน"
                          color="primary"
                          hide-details
                          dense
                          :disabled="!isPermissionCanEdit"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="3" lg="3" md="4" sm="12">
                        <v-checkbox
                          v-model="formData.accessories.softCloseAutomaticDoor"
                          label="ตัวดูด"
                          color="primary"
                          :disabled="!isPermissionCanEdit"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3" lg="3" md="4" sm="12">
                        <v-checkbox
                          v-model="formData.accessories.dashcam"
                          label="กล้องหน้ารถ"
                          :disabled="!isPermissionCanEdit"
                          color="primary"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3" lg="3" md="4" sm="12">
                        <v-checkbox
                          v-model="formData.accessories.incarcam"
                          label="กล้องภายในรถ"
                          :disabled="!isPermissionCanEdit"
                          color="primary"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col v-if="isEdit" cols="6" lg="6" md="6" sm="12">
                        <v-checkbox
                          v-model="formData.accessories.gps"
                          label="GPS (รายละเอียดอยู่ในหัวข้อ GPS)"
                          color="primary"
                          :disabled="!isPermissionCanEdit"
                          hide-details
                          dense
                          @change="toggleGPSBox"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row no-gutters></v-row>
                  </InputView>
                </v-col>
              </v-row> -->
              <v-row no-gutters>
                <v-col cols="12" class="pb-6">
                  <InputView
                    title="อุปกรณ์เสริม"
                    :isRequired="false"
                    class="px-3"
                  >
                    <VehicleDynamicAccessories
                      v-model="formData.accessories"
                      :disabled="!isPermissionCanEdit"
                      placeholder="พิมพ์ค้นหาอุปกรณ์เสริม"
                      :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    />
                  </InputView> </v-col
              ></v-row>
              <v-row no-gutters v-if="!isEdit">
                <v-col cols="12" class="mb-3">
                  <v-card outlined
                    ><v-card-title class="pt-2 pl-3">
                      <v-row>
                        <v-col cols="12" lg="6" md="6">
                          <v-checkbox
                            v-model="hasGPS"
                            label="GPS"
                            color="primary"
                            :disabled="!isPermissionCanEdit"
                            hide-details
                            dense
                          ></v-checkbox
                        ></v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="4"
                          md="6"
                          sm="12"
                          class="mx-0 px-0"
                        >
                          <CustomTextInputImprove
                            ref="gpsBrand"
                            v-model="formData.gps"
                            title="GPS ที่ใช้"
                            placeholder="ชื่อของ GPS ที่ใช้งาน"
                            :isRequired="false"
                            :disabled="isGpsDisable"
                          ></CustomTextInputImprove
                        ></v-col>
                        <v-col cols="12" lg="4" md="6" sm="12" class="mx-0 px-0"
                          ><CustomDateTypingPickerFormInput
                            v-model="formData.gpsExpiryDate"
                            title="วันหมดอายุรอบถัดไป (วัน/เดือน/ปีคศ)"
                            :disabled="isGpsDisable"
                            :isRequired="false"
                            ref="gpsExpiryDate"
                        /></v-col>
                      </v-row> </v-card-text></v-card
                ></v-col>
              </v-row> </v-card></v-col
        ></v-row>
      </div>
      <!-- <v-divider v-if="!isEdit" class="mt-3 mb-6"></v-divider>
      <div v-if="!isEdit">
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters class="mr-3">
                <v-col cols="12" lg="4" md="6" sm="12">
                  <CustomTextInputCurrency
                    ref="loanAmount"
                    v-model="formData.loanAmount"
                    title="ราคาค่างวดรถ (บาท)"
                    placeholder=""
                    :isRequired="false"
                    :disabled="!isPermissionCanEdit"
                  >
                  </CustomTextInputCurrency>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <CustomSelectsArrOfObj
                    ref="loanRoundpayDay"
                    v-model="formData.loanRoundpayDay"
                    class="custom-placeholer-color"
                    :items="dayNumber"
                    :isRequired="false"
                    :disabled="!isPermissionCanEdit"
                    :loading="isLoading"
                    title="วันที่ต้องชำระประจำเดือน"
                    item-text="value"
                    item-value="key"
                    :returnObject="true"
                  >
                  </CustomSelectsArrOfObj>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12" class="pl-3">
                  <CustomTextInputImprove
                    ref="financeCompany"
                    v-model="formData.financeCompany"
                    title="ชื่อไฟแนนซ์"
                    placeholder="ชื่อไฟแนนซ์"
                    :disabled="!isPermissionCanEdit"
                    :isRequired="false"
                    :expendMargin="false"
                  ></CustomTextInputImprove
                ></v-col>
              </v-row> </v-card></v-col
        ></v-row>
      </div> -->
      <v-divider class="mt-3 mb-6"></v-divider>
      <div>
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <CustomTextareaImprove
                    v-model="formData.note"
                    title="ข้อมูลเพิ่มเติม"
                    type="text"
                    :isRequired="false"
                    :disabled="!isPermissionCanEdit"
                  >
                  </CustomTextareaImprove>
                </v-col> </v-row></v-card></v-col
        ></v-row>
      </div>
      <v-divider class="mt-3 mb-6"></v-divider>
      <div>
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row dense class="px-3">
                <v-col cols="12">
                  <FormDataUploadInput
                    ref="vehicleCRUDUploadFile"
                    component-name="vehicleCRUDUploadFileForm"
                    title="ชุดเอกสาร (pdf)"
                    placeholder="เพิ่มเอกสารกดที่นี่"
                    :uploaded-data="formData.files"
                    :is-permission-can-edit="!isPermissionCanEdit"
                    uploadTag="VEHICLE"
                  />
                </v-col>
              </v-row>
              <v-row dense class="px-3">
                <v-col cols="12">
                  <FormDataUploadInput
                    ref="vehicleCRUDUploadImageFile"
                    component-name="vehicleCRUDUploadImageFileForm"
                    title="รูปภาพรอบคันรถ (jpg, png)"
                    placeholder="เพิ่มรูปภาพที่นี่"
                    :isImageType="true"
                    :uploaded-data="formData.images"
                    :is-permission-can-edit="!isPermissionCanEdit"
                    uploadTag="VEHICLE"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <AppSubmitFormButton
        @on-click-save="saveChange()"
        v-if="isPermissionCanEdit"
      />
    </CustomCardFormViewModern>

    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-form>
</template>

<script>
import _ from "lodash";
//
import { mapGetters } from "vuex";
import { dateStringToDateFormat } from "@/services/appDate";
import { DialogType } from "@/services/dialog";
// Component
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import CustomSelectsArrOfIconObj from "@/components/CustomSelectsArrOfIconObj.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import InputView from "@/components/InputView.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
// Service
import { postVehicleService, putVehicleService } from "@/services/api/vehicle";

import FormDataUploadInput from "../../../components/FormDataUploadInput.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import VehicleDynamicAccessories from "./components/VehicleDynamicAccessories.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import { appEncodeSingleURIComponentHelper } from "@/services/appStringHelpper";
import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";

export default {
  props: {
    vehicleData: {
      default: () => ({}),
      type: Object,
    },
    vehicleFundData: {
      default: () => ({}),
      type: Object,
    },
    isEdit: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    isPermissionCanEdit: {
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: true,
      type: Boolean,
    },
    loadingFunc: {
      default: () => {},
      type: Function,
    },
    setGpsStatus: {
      default: () => {},
      type: Function,
    },
    callApiFunc: {
      default: () => {},
      type: Function,
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
      vehicleTypeIconText: "vehicle/vehicleTypeIconText",
    }),
    isGpsDisable() {
      if (this.hasGPS && this.isPermissionCanEdit) {
        return false;
      }
      return true;
    },
  },
  watch: {
    vehicleData: {
      immediate: false,
      handler(newValue) {
        this.setFormData(newValue);
      },
    },
    vehicleFundData: {
      immediate: false,
      handler(newValue) {
        this.fundData = newValue;
      },
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    enableLoading() {
      this.$emit("loading-func", true);
    },
    disableLoading() {
      this.$emit("loading-func", false);
    },
    async startComponent() {
      this.getDayNumber();
    },
    getDayNumber() {
      let i = 1;
      while (i <= 31) {
        this.dayNumber.push(i);
        i++;
      }
    },
    // component main function
    setFormData(_formData) {
      this.formData = _.cloneDeep(_formData);

      this._id = this.formData["_id"];
      delete this.formData["_id"];

      // gps
      this.hasGPS = !_.isNil(this.formData.gps) ? true : false;
      //
      this.statusOnClick(this.formData.status);
    },
    getStatusColor(status) {
      if (this.formData["status"] !== null) {
        let prevStatus = this.formData["status"].key;
        this.vehicleStatus[prevStatus] = true;
      }
      //
      if (this.vehicleStatus[status.key]) {
        return this.vehicleStatusColor(status.key);
      }
      return "";
    },
    getStatusOutline(status) {
      if (this.formData["status"] !== null) {
        let prevStatus = this.formData["status"].key;
        this.vehicleStatus[prevStatus] = true;
      }
      //
      if (this.vehicleStatus[status.key]) {
        return false;
      }
      return true;
    },
    statusOnClick(selectedStatus) {
      this.vehicleStatus["ACTIVE"] = null;
      this.vehicleStatus["INACTIVE"] = null;
      this.vehicleStatus["SALED"] = null;

      this.vehicleStatus[selectedStatus.key] = true;
      this.formData["status"] = selectedStatus;
    },
    changeVehicleColor(colorObj) {
      console.log("Return Color Obj:", colorObj);
    },
    checkCompleteData() {
      if (!this.formData.licenseNumber || this.formData.licenseNumber === "") {
        this.formData.licenseNumber = null;
        this.$refs.licenseNumber.$el.scrollIntoView(this.focus);
      } else if (
        !this.formData.licenseType ||
        this.formData.licenseType === ""
      ) {
        this.formData.licenseType = null;
        this.$refs.licenseType.$el.scrollIntoView(this.focus);
      } else if (!this.formData.cassieId || this.formData.cassieId === "") {
        this.formData.cassieId = null;
        this.$refs.cassieId.$el.scrollIntoView(this.focus);
      } else if (!this.formData.color || this.formData.color === "") {
        this.formData.color = null;
        this.$refs.color.$el.scrollIntoView(this.focus);
      } else if (!this.formData.type || this.formData.type === "") {
        this.formData.type = null;
        this.$refs.type.$el.scrollIntoView(this.focus);
      } else if (!this.formData.engine || this.formData.engine === "") {
        this.formData.engine = null;
        this.$refs.engine.$el.scrollIntoView(this.focus);
      } else if (!this.formData.gear || this.formData.gear === "") {
        this.formData.gear = null;
        this.$refs.gear.$el.scrollIntoView(this.focus);
      } else if (!this.formData.seat || this.formData.seat === "") {
        this.formData.seat = null;
        this.$refs.numSeat.$el.scrollIntoView(this.focus);
      } else if (
        !this.formData.registerdSeat ||
        this.formData.registerdSeat === ""
      ) {
        this.formData.registerdSeat = null;
        this.$refs.numregisterdSeat.$el.scrollIntoView(this.focus);
      } else if (
        !this.formData.ownershipDate ||
        this.formData.ownershipDate === ""
      ) {
        this.formData.ownershipDate = null;
        this.$refs.ownershipDate.$el.scrollIntoView(this.focus);
      } else if (!this.formData.status || this.formData.status === "") {
        this.formData.status = null;
        this.$refs.status.$el.scrollIntoView(this.focus);
      }
    },
    // toggleGPSBox() {
    //   this.$emit("set-gps-status", this.formData.accessories.gps);
    // },
    async saveChange() {
      // scorlling
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      //https://stackoverflow.com/questions/57054957/how-to-validate-both-input-fields-when-one-updates
      if (this.$refs.form.validate() === true) {
        this.enableLoading();
        let _toUpdateByAPI = false;
        let resUploadFile = await this.$refs["vehicleCRUDUploadFile"].upload();
        let respUploadImage = await this.$refs[
          "vehicleCRUDUploadImageFile"
        ].upload();
        if (!resUploadFile.status || !respUploadImage.status) {
          // is upload has error response restore all file
          this.callUploadFileErrorDialog();
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
          this.disableLoading();
        } else {
          const sendingFormData = _.cloneDeep(this.formData);
          sendingFormData.files = resUploadFile.result;
          sendingFormData.images = respUploadImage.result;
          // check up file and append new file
          if (this.isEdit) {
            // edit vehicle data
            let respUpdated = await putVehicleService(
              this.$store,
              this._id,
              sendingFormData
            );
            if (respUpdated["code"] === 200) {
              this.$emit("call-api-func");
              this.alertSaveChange();
              _toUpdateByAPI = true;
            } else {
              _toUpdateByAPI = false;
            }
          } else {
            // create new vehicle data
            let respUpdated = await postVehicleService(
              this.$store,
              sendingFormData
            );

            if (respUpdated["code"] === 200) {
              _toUpdateByAPI = true;
              this.alertSaveChange();
              this.$router.push(
                `/vehicle/edit/${respUpdated["data"]["vehicleId"]}`
              );
            } else {
              _toUpdateByAPI = false;
            }
          }
        }
        // if it can not post or put the data
        if (_toUpdateByAPI === false) {
          // alert fail to update data
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
          this.disableLoading();
        }
      } else {
        this.checkCompleteData();
      }
    },

    async alertSaveChange() {
      if (this.isEdit) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },

    async callUploadFileErrorDialog() {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
    },
  },

  mounted() {
    this.startComponent();
  },

  data: () => ({
    formVaild: false,
    fundData: {},
    dayNumber: [],
    _id: null,
    formData: {
      status: { key: "ACTIVE", value: "พร้อม" },
      licenseNumber: null,
      licenseType: null,
      cassieId: null,
      type: null,
      color: null,
      brand: null,
      model: null,
      year: null,
      seat: null,
      registerdSeat: null,
      style: null,
      gear: null,
      engine: null,
      note: null,
      ownershipDate: null,
      accessories: [],
      // accessoriesObjs: null,
      // accessories: {
      //   curtain: null,
      //   gps: null,
      //   dashcam: null,
      //   incarcam: null,
      //   softCloseAutomaticDoor: null,
      // },
      files: [],
      images: [],
    },
    hasGPS: false,
    fileMatchUrl: {},
    vehicleStatus: {
      ACTIVE: null,
      INACTIVE: null,
      SALED: null,
    },
    statusIcon: {
      ACTIVE: "mdi-car-connected",
      INACTIVE: "mdi-car-clock",
      SALED: "mdi-alpha-x-circle",
    },
  }),
  components: {
    InputView,
    CustomCardFormViewModern,
    CustomTextareaImprove,
    CustomTextInputImprove,
    CustomDateTypingPickerFormInput,
    CustomSelectsArrOfObj,
    CustomSelectsArrOfIconObj,
    FormDataUploadInput,
    AppDialogConfirm,
    AppSubmitFormButton,
    VehicleDynamicAccessories,
    CustomAutocomplete,
    CustomTextInputCurrency,
  },
};
</script>
