<template>
  <v-overlay
    :absolute="absolute"
    :value="isLoading"
    :opacity="opacity"
    :z-index="zIndex"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "customAppOverlay",

  components: {},

  props: {
    opacity: {
      default: 0.46,
      type: Number,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    absolute: {
      default: false,
      type: Boolean,
    },
    zIndex: {
      default: 5,
      type: Number,
    },
  },

  data() {
    return {};
  },

  mounted() {},

  watch: {},
  methods: {},
  components: {},
};
</script>
