<template>
  <div class="amsd-fill-height">
    <div
      class="text-center"
      @click="openManageDialogHandler"
      style="height: 100%; cursor: pointer; position: relative"
      v-if="assignedDrivers.length !== 0"
    >
      <p
        :class="[
          {
            'red--text': assignedDriversTotal !== numberOfSelectedDriver,
          },
          'text-center pt-2 mb-1 text-app-detail-md font-weight-bold',
        ]"
      >
        {{ assignedDriversTotal }}/{{ numberOfSelectedDriver }}
      </p>

      <div
        style="position: absolute; top: 0; right: 0"
        v-if="rowData.jobOpsLoading"
      >
        <v-progress-circular
          class="mr-1"
          :size="20"
          :width="2"
          indeterminate
          color="primary"
        />
      </div>

      <div style="padding: 0 8px">
        <div
          v-for="(drivers, mainIdx) in assignedDrivers"
          :key="mainIdx"
          class="d-flex"
        >
          <div v-for="(driver, idx) in drivers" :key="`${mainIdx}-${idx}`">
            <JobDuplicateMenuHoverOnChip
              v-if="!driver.jobId && driver.jobStatus === '!OK'"
              :driver="driver"
              :is-between-pair="isBetweenPair"
              :driver-idx="idx"
              :vuex-row-idx="vuexRowIdx"
              :vuex-cols-idx="vuexColsIdx"
              :table-id="tableId"
            />

            <div
              v-else
              :class="[
                'amsd-short',
                'd-flex',
                { 'amsd-has-job': driver.jobId },
                { 'amsd-pair-background': isBetweenPair },
                { 'mr-1': (idx + 1) % 3 !== 0 },
              ]"
            >
              <v-avatar size="26">
                <img :src="driver.profileImage" />
              </v-avatar>
              <span style="font-size: 13px; line-height: 2" class="ml-1">
                <span
                  :class="[
                    {
                      'red--text text--lighten-1 font-weight-medium':
                        existingDriverInCellJobStatusById(
                          tableId,
                          driver._id,
                          cellData.cellMId
                        ) && !driver.jobId,
                    },
                  ]"
                >
                  {{ driver.dpNickNameTh }}
                </span>
              </span>
            </div>
          </div>

          <div
            v-if="mainIdx === 3 && assignedDriversTotal > 11"
            :class="[
              'amsd-short',
              'mr-1',
              'text-center',
              { 'amsd-pair-background': isBetweenPair },
            ]"
            style="font-size: 13px; line-height: 2"
          >
            <span> +{{ assignedDriversTotal - 11 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="d-flex flex-column align-center amsd-fill-height"
      v-if="assignedDrivers.length === 0"
    >
      <p
        :class="[
          {
            'red--text': assignedDriversTotal !== numberOfSelectedDriver,
          },
          'text-center mt-2 mb-1 text-app-detail-md font-weight-bold',
        ]"
      >
        {{ assignedDriversTotal }}/{{ numberOfSelectedDriver }}
      </p>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          fab
          outlined
          color="primary"
          v-if="assignedDrivers.length === 0 && canEdit"
          class=""
          @click="openManageDialogHandler"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateStringToDateFormat } from "@/services/appDate";
import JobDuplicateMenuHoverOnChip from "../AssignMultipleJobComponents/menu-hover/JobDuplicateMenuHoverOnChip.vue";
export default {
  name: "AssignMultipleSelectedassignedDrivers",
  props: {
    idName: {
      require: true,
    },
    vuexRowIdx: {
      require: true,
    },
    vuexColsIdx: {
      require: true,
    },
    loopRowIdx: {
      require: true,
    },
    loopColsIdx: {
      require: true,
    },
    tableId: {
      require: true,
    },
  },
  components: {
    JobDuplicateMenuHoverOnChip,
  },
  mounted() {},
  data: () => ({}),
  computed: {
    ...mapGetters({
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      jobsStatusText: "jobs/jobsStatusText",
      existingDriverInCellJobStatusById:
        "assignMultipleJob/existingDriverInCellJobStatusById",
    }),
    cellData: function () {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}`,
        null
      );
    },
    rowData: function () {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `rowData.${this.tableId}.${this.vuexRowIdx}`,
        {}
      );
    },
    isBetweenPair: function () {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `columnsData.${this.tableId}.${this.vuexColsIdx}.isBetweenPair`,
        0
      );
    },
    numberOfSelectedDriver: function () {
      return parseInt(
        _.get(
          this.$store.state["assignMultipleJob"],
          `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}.numberOfSelectedDriver`,
          0
        )
      );
    },

    assignedDriversTotal: function () {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}.drivers`,
        []
      ).length;
    },

    assignedDrivers: function () {
      const drivers = _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}.drivers`,
        []
      );

      const totalSelectedDriverOnView =
        drivers.length > 11 ? 11 : drivers.length;

      return _.chunk(_.take(drivers, totalSelectedDriverOnView), 3);
    },
    canEdit: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanEdit"];
    },
    canView: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanView"];
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    procossRowDate(row, key) {
      if (_.isNil(row[key])) {
        return "-";
      }
      return dateStringToDateFormat(row[key], {
        type: "datetime",
        returnThai: false,
        showSecond: false,
      });
    },
    // randomNumberForTest() {
    //   // select first
    //   // this.assignedDrivers = [[]];
    //   const totalSelectedDriverOnView =
    //     this.numberOfSelectedDriver > 11 ? 11 : this.numberOfSelectedDriver;
    //   const takeA = _.take(this.demoName, totalSelectedDriverOnView);
    //   this.assignedDrivers = _.chunk(takeA, 3);
    // },
    openManageDialogHandler() {
      if (!this.rowData.jobOpsLoading) {
        this.$emit("open-manage-dialog", {
          tableId: this.tableId,
          row: this.vuexRowIdx,
          col: this.vuexColsIdx,
        });
      }
    },
    clickOnChipHandler() {
      console.log("in");
    },
  },
};
</script>

<style lang="scss">
.amsd-fill-height {
  height: 100%;
}
.amsd-short {
  min-width: 75px;
  background-color: #ecf1f8;
  border-radius: 3em;
  margin-top: 2px;
  margin-bottom: 2px;

  &.on-hover {
    background-color: rgba(#bebebe, 0.8);
  }
}
.amsd-short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amsd-pair-background {
  background-color: #ffffff;
}

.amsd-has-job {
  border: 1px solid #00897b;
}
.amsd-no-has-job {
  border: 1px solid #c62828;
}
</style>
