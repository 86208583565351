import VehicleContractListView from "@/views/contract/ContractListView.vue";
import ContractManageView from "@/views/contract/ContractManageView.vue";
import ContractDashboardView from "@/views/contract/contract_dashboard/ContractDashboardView.vue";
import ContractOverviewDashboardView from "@/views/contract/contract_dashboard/ContractOverviewDashboardView.vue";

export default [
  {
    path: "/caj/contract",
    name: "contractList",
    component: VehicleContractListView,
    meta: { requiresAuth: true, topic: "contract" },
  },
  {
    path: "/caj/contract/create",
    name: "vehicleContractAdd",
    component: ContractManageView,
    meta: { requiresAuth: true, topic: "contract" },
  },
  {
    path: "/caj/contract/edit/:id",
    name: "vehicleContractEdit",
    component: ContractManageView,
    meta: { requiresAuth: true, topic: "contract" },
  },
  {
    path: "/caj/contract/dashboard_contract/:id",
    name: "contractDashboard",
    component: ContractDashboardView,
    meta: { requiresAuth: true, topic: "contract" },
  },
  {
    path: "/caj/contract/dashboard",
    name: "contractDashboardOverview",
    component: ContractOverviewDashboardView,
    meta: { requiresAuth: true, topic: "contractDashboardOverview" },
  },
];
