<template>
  <div name="employeeDashboard">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

    <v-container v-if="isPermissionCanView">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="mb-1">
            <v-card elevation="2" rounded="lg" class="px-4 py-3">
              <v-row no-gutters align="center">
                <v-col cols="4" class="px-6">
                  <v-row no-gutters>
                    <v-list-item-avatar size="60" class="mr-6">
                      <v-img :src="employee.profileImage || defaultPFImage">
                      </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div>
                        <v-list-item-title
                          class="text-app-detail-large black--text font-weight-bold"
                        >
                          {{
                            `${
                              employee.salutation === null ||
                              employee.salutation === undefined
                                ? ""
                                : employee.salutation.th
                            }${
                              employee.firstName === null ||
                              employee.firstName === undefined
                                ? ""
                                : employee.firstName.th
                            } ${
                              employee.lastName === null ||
                              employee.lastName === undefined
                                ? ""
                                : employee.lastName.th
                            } `
                          }}
                          {{
                            employee.nickName !== null &&
                            employee.nickName !== undefined
                              ? employee.nickName.th !== null &&
                                employee.nickName.th !== undefined
                                ? `(${employee.nickName.th})`
                                : "-"
                              : "-"
                          }}
                        </v-list-item-title>
                        <span class="text-app-normal">
                          {{ employee.empId }}
                        </span>
                        <span class="text-app-normal" v-if="employee.position"
                          >|</span
                        >
                        <span class="text-app-normal">
                          {{ employee.position }}
                        </span>
                      </div>
                      <span v-if="employee.email" class="text-app-normal"
                        >อีเมล: {{ employee.email }}
                      </span>
                      <span v-else class="text-app-normal">-</span>
                      <div v-if="employee.phoneNumber">
                        <span class="text-app-normal"> โทร:</span>
                        <span
                          class="text-app-normal"
                          v-for="(number, idx) in employee.phoneNumber"
                          :key="idx"
                        >
                          {{ number }}
                          <span v-if="idx < employee.phoneNumber.length - 1"
                            >,</span
                          >
                        </span>
                      </div>
                      <span v-else class="text-app-normal">-</span>
                    </v-list-item-content>
                  </v-row>
                </v-col>
                <v-divider vertical />
                <v-col cols="8" class="px-6 mt-3">
                  <v-card elevation="1" class="rounded-lg">
                    <v-card-text class="pt-1">
                      <v-row>
                        <v-col cols="4">
                          <div class="d-flex justify-space-between">
                            <div>
                              <p
                                v-if="employee.ownedVehicle"
                                class="mb-1 text-app-title font-weight-bold black--text"
                              >
                                {{ employee.ownedVehicle.licenseNumber }}
                                <span
                                  v-if="employee.ownedVehicle.type !== null"
                                >
                                  ({{
                                    vehicleTypeText(employee.ownedVehicle.type)
                                  }})
                                </span>
                              </p>
                              <p
                                v-else
                                class="mb-1 text-app-title font-weight-bold black--text"
                              >
                                ไม่มีข้อมูลรถประจำตัว
                              </p>
                              <span
                                v-if="employee.ownedVehicle"
                                class="text-app-detail-normal pr-6"
                              >
                                <span class="mx-0 px-0">
                                  <v-icon
                                    v-if="
                                      employee.ownedVehicle.licenseType ===
                                      'WHITE'
                                    "
                                    >mdi-card-text-outline</v-icon
                                  >
                                  <v-icon
                                    v-else
                                    :color="
                                      vehicleLicenseTypeColor(
                                        employee.ownedVehicle.licenseType
                                      )
                                    "
                                    >mdi-card-text</v-icon
                                  >
                                  <!-- <v-icon>mdi-card-text</v-icon> -->
                                </span>
                                {{
                                  vehicleLicenseTypeText(
                                    employee.ownedVehicle.licenseType
                                  )
                                }}
                                <!-- แดง -->
                              </span>
                              <span v-else class="text-app-detail-normal pr-6"
                                ><v-icon class="pr-1">mdi-card-text</v-icon
                                >-</span
                              >
                              <span
                                v-if="employee.ownedVehicle"
                                class="text-app-detail-normal"
                              >
                                <span class="mx-0 px-0">
                                  <v-icon>
                                    {{
                                      vehicleTypeIcon(
                                        employee.ownedVehicle.type
                                      )
                                    }}
                                  </v-icon>
                                </span>
                                {{ employee.ownedVehicle.seat }} ที่นั่ง</span
                              >
                              <span v-else class="text-app-detail-normal"
                                ><span class="mx-0 pl-0 pr-1">
                                  <v-icon> mdi-bus </v-icon> </span
                                >- ที่นั่ง</span
                              >
                            </div>
                            <!-- <v-btn icon v-if="canEdit">
                              <v-icon dark @click="openDialog()">
                                mdi-pencil
                              </v-icon>
                            </v-btn> -->
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <p class="text-app-small font-weight-light my-0">
                            สถานะรถ
                          </p>
                          <p class="text-app-detail font-weight-bold my-0">
                            <v-chip
                              v-if="employee.ownedVehicle"
                              fab
                              small
                              class="text-app-title"
                              :color="
                                vehicleStatusColor(employee.ownedVehicle.status)
                              "
                              dark
                            >
                              {{
                                vehicleStatusText(employee.ownedVehicle.status)
                              }}
                            </v-chip>
                            <span v-else>-</span>
                            <!-- <v-chip
                              fab
                              small
                              class="text-app-title"
                              color="primary"
                              dark
                            >
                              พร้อม
                            </v-chip> -->
                          </p>
                        </v-col>
                        <v-col cols="3">
                          <p class="text-app-small font-weight-light my-0">
                            ข้อมูลรถ
                          </p>
                          <!-- <p class="text-app-detail font-weight-bold my-0">
                            Toyota 2023
                          </p> -->
                          <p
                            class="text-app-detail font-weight-bold my-0"
                            v-if="employee.ownedVehicle"
                          >
                            <span
                              v-if="
                                employee.ownedVehicle.brand !== null ||
                                employee.ownedVehicle.model !== null ||
                                employee.ownedVehicle.year !== null
                              "
                            >
                              {{ employee.ownedVehicle.brand }}
                              {{ employee.ownedVehicle.model }}
                              {{ employee.ownedVehicle.year }}</span
                            >
                            <span v-else>-</span>
                          </p>
                          <p v-else>-</p>
                        </v-col>
                        <v-col cols="3">
                          <p class="text-app-small font-weight-light my-0">
                            ถูกใช้งานล่าสุด
                          </p>
                          <p
                            v-if="employee.ownedVehicle"
                            class="text-app-detail font-weight-bold my-0"
                          >
                            {{
                              dateStringToDateFormat(
                                employee.ownedVehicle.lastUsed,
                                {
                                  returnThai: false,
                                  type: "datetime",
                                }
                              ) || "-"
                            }}
                          </p>
                          <p v-else>-</p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </div></v-col
        >
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <div class="mb-3">
            <v-card elevation="2" rounded="lg" class="px-4 pb-3 pt-6">
              <v-row no-gutters align="center">
                <v-col cols="9" class="px-6">
                  <div>
                    <CustomFilterDateTypingPickerRange
                      placeholder="เลือก"
                      title="เลือกช่วงเวลา"
                      v-model="joinDate"
                      :isRequired="false"
                      :isSameLine="true"
                      :initRange="initRangeDate"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="7">
          <div class="mb-3">
            <CustomCardFormViewModern
              :title="`ผลสรุป ${countDays} วัน`"
              :isShowTitle="true"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 mb-3">
                <v-col cols="12" class="px-3 mt-3">
                  <v-row no-gutters>
                    <v-col cols="5" class="px-0">
                      <div>
                        <span class="text-app-detail-normal">
                          จำนวนงานและระยะทางที่วิ่ง</span
                        >
                      </div>
                      <div class="mt-9">
                        <span
                          v-if="employeeNumberStats !== null"
                          class="dashboard-large font-weight-bold black--text"
                          >{{
                            employeeNumberStats["job"]
                              ? getPriceString(
                                  employeeNumberStats["job"]["amount"]
                                )
                              : 0
                          }}</span
                        >
                        <span class="text-app-detail-normal" v-else> 0 </span>
                        <span class="text-app-detail-normal"> งาน</span>
                      </div>
                      <div class="mt-1">
                        <span
                          v-if="employeeNumberStats !== null"
                          class="dashboard-small font-weight-bold"
                          >{{
                            employeeNumberStats["job"]
                              ? getPriceString(
                                  (
                                    employeeNumberStats["job"][
                                      "totalDistance"
                                    ] / 1000
                                  ).toFixed(2)
                                )
                              : 0
                          }}</span
                        >
                        <span class="text-app-detail-normal" v-else> 0 </span>
                        <span class="text-app-detail-normal"> กม.</span>
                      </div>
                    </v-col>
                    <v-col cols="7" class="px-0">
                      <v-row no-gutters class="mt-3">
                        <v-col cols="4">
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? getJobPercentageByStatus(
                                      employeeNumberStats["job"]["statusCount"][
                                        "SUCCESS"
                                      ]
                                    )
                                  : 0
                              }}</span
                            >
                            <span
                              v-else
                              class="dashboard-small font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> %</span>
                          </div>
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="text-app-detail-normal"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? employeeNumberStats["job"]["statusCount"][
                                      "SUCCESS"
                                    ]
                                  : 0
                              }}</span
                            >
                            <span v-else class="text-app-detail-normal">0</span>
                            <span class="text-app-detail-normal"> งาน</span>
                          </div>
                          <div>
                            <v-chip
                              class="mr-1 mt-1 font-weight-bold"
                              filter
                              small
                              outlined
                              :color="jobsStatusColor('SUCCESS')"
                            >
                              <span class="px-1">{{
                                jobsStatusText("SUCCESS")
                              }}</span>
                            </v-chip>
                          </div></v-col
                        ><v-col cols="4">
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? getJobPercentageByStatus(
                                      employeeNumberStats["job"]["statusCount"][
                                        "PROCESS"
                                      ]
                                    )
                                  : 0
                              }}</span
                            >
                            <span
                              v-else
                              class="dashboard-small font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> %</span>
                          </div>
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="text-app-detail-normal"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? employeeNumberStats["job"]["statusCount"][
                                      "PROCESS"
                                    ]
                                  : 0
                              }}</span
                            >
                            <span v-else class="text-app-detail-normal">0</span>
                            <span class="text-app-detail-normal"> งาน</span>
                          </div>
                          <div>
                            <v-chip
                              class="mr-1 mt-1 font-weight-bold"
                              filter
                              small
                              outlined
                              :color="jobsStatusColor('PROCESS')"
                            >
                              <span class="px-1">{{
                                jobsStatusText("PROCESS")
                              }}</span>
                            </v-chip>
                          </div></v-col
                        >
                        <v-col cols="4">
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? getJobPercentageByStatus(
                                      employeeNumberStats["job"]["statusCount"][
                                        "CONFIRM"
                                      ]
                                    )
                                  : 0
                              }}</span
                            >
                            <span
                              v-else
                              class="dashboard-small font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> %</span>
                          </div>
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="text-app-detail-normal"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? employeeNumberStats["job"]["statusCount"][
                                      "CONFIRM"
                                    ]
                                  : 0
                              }}</span
                            >
                            <span v-else class="text-app-detail-normal">0</span>
                            <span class="text-app-detail-normal"> งาน</span>
                          </div>
                          <div>
                            <v-chip
                              class="mr-1 mt-1 font-weight-bold"
                              filter
                              small
                              outlined
                              :color="jobsStatusColor('CONFIRM')"
                            >
                              <span class="px-1">{{
                                jobsStatusText("CONFIRM")
                              }}</span>
                            </v-chip>
                          </div></v-col
                        >
                      </v-row>
                      <v-row no-gutters class="mt-8">
                        <v-col cols="4">
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? getJobPercentageByStatus(
                                      employeeNumberStats["job"]["statusCount"][
                                        "PREPARING"
                                      ]
                                    )
                                  : 0
                              }}</span
                            >
                            <span
                              v-else
                              class="dashboard-small font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> %</span>
                          </div>
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="text-app-detail-normal"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? employeeNumberStats["job"]["statusCount"][
                                      "PREPARING"
                                    ]
                                  : 0
                              }}</span
                            >
                            <span v-else class="text-app-detail-normal">0</span>
                            <span class="text-app-detail-normal"> งาน</span>
                          </div>
                          <div>
                            <v-chip
                              class="mr-1 mt-1 font-weight-bold"
                              filter
                              small
                              outlined
                              :color="jobsStatusColor('PREPARING')"
                            >
                              <span class="px-1">{{
                                jobsStatusText("PREPARING")
                              }}</span>
                            </v-chip>
                          </div></v-col
                        >
                        <v-col cols="4">
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? getJobPercentageByStatus(
                                      employeeNumberStats["job"]["statusCount"][
                                        "CANCEL"
                                      ]
                                    )
                                  : 0
                              }}</span
                            ><span
                              v-else
                              class="dashboard-small font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> %</span>
                          </div>
                          <div>
                            <span
                              v-if="employeeNumberStats['job']"
                              class="text-app-detail-normal"
                              >{{
                                employeeNumberStats["job"]["statusCount"]
                                  ? employeeNumberStats["job"]["statusCount"][
                                      "CANCEL"
                                    ]
                                  : 0
                              }}</span
                            >
                            <span v-else class="text-app-detail-normal">0</span>
                            <span class="text-app-detail-normal"> งาน</span>
                          </div>
                          <div>
                            <v-chip
                              class="mr-1 mt-1 font-weight-bold"
                              filter
                              small
                              outlined
                              :color="jobsStatusColor('CANCEL')"
                            >
                              <span class="px-1">{{
                                jobsStatusText("CANCEL")
                              }}</span>
                            </v-chip>
                          </div></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col></v-row
              >
              <v-divider class="mx-4" />
              <v-row no-gutters class="px-3">
                <v-col cols="12" class="px-3 mt-3">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <div>
                        <span class="text-app-detail-normal"> รายได้รวม</span>
                      </div>
                      <div class="mt-3">
                        <span
                          v-if="employeeNumberStats !== null"
                          class="dashboard-small font-weight-bold black--text"
                          >{{
                            employeeNumberStats["job"]
                              ? getPriceString(
                                  employeeNumberStats["job"]["totalIncome"]
                                )
                              : 0
                          }}</span
                        >
                        <span v-else class="text-app-detail-normal">0</span>
                        <span class="text-app-detail-normal"> บาท</span>
                      </div>
                    </v-col>
                    <v-divider vertical />
                    <v-col cols="6" class="pl-6">
                      <div>
                        <span class="text-app-detail-normal"> รายหักรวม</span>
                      </div>
                      <div class="mt-3">
                        <span
                          v-if="
                            employeeNumberStats !== null &&
                            employeeNumberStats['job'] !== null
                          "
                          class="dashboard-small font-weight-light black--text"
                          >{{
                            employeeNumberStats["job"]
                              ? getPriceString(
                                  employeeNumberStats["job"]["totalReduct"] * -1
                                )
                              : 0
                          }}</span
                        >
                        <span v-else class="text-app-detail-normal">0</span>
                        <span class="text-app-detail-normal"> บาท</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6"> </v-col
              ></v-row>
              <!-- <AppSubmitFormButton
              @on-click-save="saveChange()"
            />  -->
            </CustomCardFormViewModern>
          </div>
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <div class="mb-3">
                <v-card elevation="2" rounded="lg">
                  <v-card-title class="px-10">
                    <div class="py-3 text-title font-weight-bold black--text">
                      การซ่อมรถ
                    </div>
                    <v-spacer></v-spacer>

                    <span class="text-app-detail-normal"
                      ><span
                        v-if="employeeNumberStats.vehicleEvent"
                        class="dashboard-small font-weight-bold black--text"
                        >{{ employeeNumberStats.vehicleEvent.amount }}</span
                      >
                      <span
                        v-else
                        class="dashboard-small font-weight-bold black--text"
                        >0</span
                      >
                      ครั้ง</span
                    >
                  </v-card-title>
                </v-card>
              </div>
            </v-col>
            <v-col cols="6" class="pl-1">
              <div class="mb-3">
                <v-card elevation="2" rounded="lg">
                  <v-card-title class="px-10">
                    <div class="py-3 text-title font-weight-bold black--text">
                      การเติมน้ำมัน
                    </div>
                    <v-spacer></v-spacer>

                    <span class="text-app-detail-normal"
                      ><span
                        v-if="employeeNumberStats.fuelPayment"
                        class="dashboard-small font-weight-bold black--text"
                        >{{ employeeNumberStats.fuelPayment.amount }}</span
                      >
                      <span
                        v-else
                        class="dashboard-small font-weight-bold black--text"
                        >0</span
                      >
                      ครั้ง</span
                    >
                  </v-card-title>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5" class="pl-3">
          <div class="mb-3">
            <CustomCardFormViewModern
              title="คะแนนคงเหลือ"
              :isShowTitle="true"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="mt-0 mb-3 pl-6 pr-5">
                <v-col cols="3" class="px-0">
                  <div class="mt-1">
                    <span
                      v-if="penaltyData.sumScore"
                      class="dashboard-xsmall font-weight-bold warning--text"
                      ><span
                        v-if="
                          totalScore - penaltyData.sumScore !==
                          parseInt(totalScore - penaltyData.sumScore)
                        "
                        >{{
                          (totalScore - penaltyData.sumScore).toFixed(2)
                        }}</span
                      ><span v-else>{{
                        totalScore - penaltyData.sumScore
                      }}</span> </span
                    ><span
                      v-else
                      class="dashboard-xsmall font-weight-bold warning--text"
                      >0</span
                    >
                  </div>
                  <div class="mt-1">
                    <span class="text-app-detail-normal">ทำผิด </span>
                    <span
                      v-if="penaltyData.sumCounter"
                      class="text-app-detail-normal font-weight-bold black--text"
                      >{{ penaltyData.sumCounter }}</span
                    >
                    <span
                      v-else
                      class="text-app-detail-normal font-weight-bold black--text"
                      >0</span
                    >
                    <span class="text-app-detail-normal"> ครั้ง</span>
                  </div>
                  <div>
                    <span
                      class="text-app-detail-normal text-decoration-underline"
                      >ทั้งหมด</span
                    >
                  </div>
                </v-col>
                <v-divider vertical class="mt-1" />
                <v-col cols="3" class="px-3">
                  <div class="mt-1">
                    <span
                      v-if="penaltyData.stats"
                      class="dashboard-xsmall font-weight-bold warning--text"
                      ><span
                        v-if="
                          penaltyData.stats[0].maxScore -
                            penaltyData.stats[0].score !==
                          parseInt(
                            penaltyData.stats[0].maxScore -
                              penaltyData.stats[0].score
                          )
                        "
                        >{{
                          (
                            penaltyData.stats[0].maxScore -
                            penaltyData.stats[0].score
                          ).toFixed(2)
                        }}</span
                      ><span v-else>
                        {{
                          penaltyData.stats[0].maxScore -
                          penaltyData.stats[0].score
                        }}
                      </span></span
                    >
                    <span
                      v-else
                      class="dashboard-xsmall font-weight-bold warning--text"
                      >0</span
                    >
                  </div>
                  <div class="mt-1">
                    <span class="text-app-detail-normal">ทำผิด </span>
                    <span
                      v-if="penaltyData.stats"
                      class="text-app-detail-normal font-weight-bold black--text"
                      >{{ penaltyData.stats[0].counter }}</span
                    >
                    <span
                      v-else
                      class="text-app-detail-normal font-weight-bold black--text"
                      >0</span
                    >
                    <span class="text-app-detail-normal"> ครั้ง</span>
                  </div>
                  <div>
                    <span
                      class="text-app-detail-normal text-decoration-underline"
                      >การปฎิบัติงาน</span
                    >
                  </div>
                </v-col>
                <v-divider vertical class="mt-1" />
                <v-col cols="3" class="px-3">
                  <div class="mt-1">
                    <span
                      v-if="penaltyData.stats"
                      class="dashboard-xsmall font-weight-bold warning--text"
                      ><span
                        v-if="
                          penaltyData.stats[1].maxScore -
                            penaltyData.stats[1].score !==
                          parseInt(
                            penaltyData.stats[1].maxScore -
                              penaltyData.stats[1].score
                          )
                        "
                        >{{
                          (
                            penaltyData.stats[1].maxScore -
                            penaltyData.stats[1].score
                          ).toFixed(2)
                        }}</span
                      ><span v-else>{{
                        penaltyData.stats[1].maxScore -
                        penaltyData.stats[1].score
                      }}</span></span
                    >
                    <span
                      v-else
                      class="dashboard-xsmall font-weight-bold warning--text"
                      >0</span
                    >
                  </div>
                  <div class="mt-1">
                    <span class="text-app-detail-normal">ทำผิด </span>
                    <span
                      v-if="penaltyData.stats"
                      class="text-app-detail-normal font-weight-bold black--text"
                      >{{ penaltyData.stats[1].counter }}</span
                    >
                    <span
                      v-else
                      class="text-app-detail-normal font-weight-bold black--text"
                      >0</span
                    >
                    <span class="text-app-detail-normal"> ครั้ง</span>
                  </div>
                  <div>
                    <span
                      class="text-app-detail-normal text-decoration-underline"
                      >ความปลอดภัยและ GPS</span
                    >
                  </div></v-col
                >
                <v-divider vertical class="mt-1" />
                <v-col cols="3" class="pl-3">
                  <div class="mt-1">
                    <span
                      v-if="penaltyData.stats"
                      class="dashboard-xsmall font-weight-bold warning--text"
                    >
                      <span
                        v-if="
                          penaltyData.stats[2].maxScore -
                            penaltyData.stats[2].score !==
                          parseInt(
                            penaltyData.stats[2].maxScore -
                              penaltyData.stats[2].score
                          )
                        "
                      >
                        {{
                          (
                            penaltyData.stats[2].maxScore -
                            penaltyData.stats[2].score
                          ).toFixed(2)
                        }} </span
                      ><span v-else>{{
                        penaltyData.stats[2].maxScore -
                        penaltyData.stats[2].score
                      }}</span></span
                    >
                    <span
                      v-else
                      class="dashboard-xsmall font-weight-bold warning--text"
                      >0</span
                    >
                  </div>
                  <div class="mt-1">
                    <span class="text-app-detail-normal">ทำผิด </span>
                    <span
                      v-if="penaltyData.stats"
                      class="text-app-detail-normal font-weight-bold black--text"
                      >{{ penaltyData.stats[2].counter }}</span
                    >
                    <span
                      v-else
                      class="text-app-detail-normal font-weight-bold black--text"
                      >0</span
                    >
                    <span class="text-app-detail-normal"> ครั้ง</span>
                  </div>
                  <div>
                    <span
                      class="text-app-detail-normal text-decoration-underline"
                      >ทรัพยากรมนุษย์และผู้ดูแลระบบ</span
                    >
                  </div></v-col
                >
              </v-row>
            </CustomCardFormViewModern>
          </div>
          <div class="mb-3">
            <CustomCardFormViewModern
              title="จำนวนวันลา"
              :isShowTitle="true"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="mt-0 mb-4 px-6">
                <v-col cols="3" class="px-0">
                  <div class="mt-6">
                    <span
                      v-if="employeeNumberStats.timeoff"
                      class="dashboard-large font-weight-bold black--text"
                      >{{ parseInt(employeeNumberStats.timeoff.total) }}</span
                    ><span
                      v-else
                      class="dashboard-large font-weight-bold black--text"
                      >0</span
                    >
                  </div>
                  <div>
                    <span
                      class="text-app-detail-normal text-decoration-underline"
                      >ทั้งหมด</span
                    >
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-row no-gutters>
                    <v-col cols="4" class="px-3">
                      <div class="mt-0">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[0].val }}/{{
                            employeeNumberStats.timeoff.list[0].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>

                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลาป่วย</span
                        >
                      </div>
                    </v-col>
                    <v-divider vertical class="mt-1" />
                    <v-col cols="4" class="px-3">
                      <div class="mt-0">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[1].val }}/{{
                            employeeNumberStats.timeoff.list[1].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>
                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลากิจ</span
                        >
                      </div></v-col
                    >
                    <v-divider vertical class="mt-1" />
                    <v-col cols="4" class="px-3">
                      <div class="mt-0">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[3].val }}/{{
                            employeeNumberStats.timeoff.list[3].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>
                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลาพักร้อน</span
                        >
                      </div></v-col
                    >
                  </v-row>
                  <v-divider class="my-3" />
                  <v-row no-gutters>
                    <v-col cols="4" class="px-3">
                      <div class="mt-1">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[4].val }}/{{
                            employeeNumberStats.timeoff.list[4].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>

                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลาคลอด</span
                        >
                      </div>
                    </v-col>
                    <v-divider vertical class="mt-1" />
                    <v-col cols="4" class="px-3">
                      <div class="mt-1">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[5].val }}/{{
                            employeeNumberStats.timeoff.list[5].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>
                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลารับราชการทหาร</span
                        >
                      </div></v-col
                    >
                    <v-divider vertical class="mt-1" />
                    <v-col cols="4" class="px-3">
                      <div class="mt-1">
                        <span
                          v-if="employeeNumberStats.timeoff"
                          class="text-app-detail-large font-weight-bold black--text"
                          >{{ employeeNumberStats.timeoff.list[2].val }}/{{
                            employeeNumberStats.timeoff.list[2].limit
                          }}</span
                        >
                        <span
                          v-else
                          class="text-app-detail-large font-weight-bold black--text"
                          >0/0</span
                        >
                      </div>
                      <div>
                        <span
                          class="text-app-detail-normal text-decoration-underline"
                          >ลาบุคคลในครอบครัวเสียชีวิต</span
                        >
                      </div></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </CustomCardFormViewModern>
          </div>
        </v-col>
      </v-row>
      <v-card class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายรับ/รายจ่าย
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="pr-9">
                <apexchart
                  type="line"
                  height="350"
                  :options="chartBreakEvenOptionsArea()"
                  :series="seriesBreakEvenArea"
                ></apexchart>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="px-4" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="4" class="pr-6 pb-6">
            <v-card-title class="px-6">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ(บริษัท)/ระยะทาง(กม.)
              </div>
            </v-card-title>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptions('DISTANCE')"
                  :series="seriesDistance"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >ระยะทาง</span
            >
          </v-col>
          <v-divider class="my-6" vertical />
          <v-col cols="4" class="pl-3 pr-6 pb-6">
            <v-card-title class="px-6">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ(บริษัท)/ชั่วโมงทำงาน
              </div>
            </v-card-title>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptions('HOURS')"
                  :series="seriesHour"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >ชั่วโมงทำงาน</span
            >
          </v-col>
          <v-divider class="my-6" vertical />
          <v-col cols="4" class="px-3 pb-6">
            <v-card-title class="px-6">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ(บริษัท)/จำนวนงาน
              </div>
            </v-card-title>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptions('JOBS')"
                  :series="seriesJob"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >จำนวนงาน</span
            >
          </v-col>
        </v-row>
      </v-card>

      <AppDialogConfirm ref="alertSaving" />
      <AppDialogConfirm ref="dialogAlert" />
    </v-container>
    <AppOverlay :isLoading="isLoading" />
    <AppPageNotFound v-if="!isPermissionCanView" />
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { getAppDataService } from "@/services/api/app";
import { errorMessageMapper } from "@/services/error";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
import _ from "lodash";
import {
  getVehicleDocsVelist,
  getVehicleDocsService,
  postVehicleDocsService,
  putVehicleDocsService,
} from "@/services/api/vehicle";

import {
  getEmployeeReportService,
  getEmployeePenaltyService,
  getEmployeeReportPenaltyService,
  getEmployeeReportTimeoffService,
  getEmployeeReportStatsService,
  getEmployeeIncomeService,
} from "@/services/api/report";

import { getPenaltyReportService } from "@/services/api/employee";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import { URL_VEHICLE_DOC_INS } from "@/services/routerQueryPath";
import { URL_EMPLOYEE_LIST } from "@/services/routerQueryPath";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { dateStringToDateFormat } from "@/services/appDate";
import moment from "moment";
import defaultImage from "@/assets/user.png";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import { getEmployeeListService } from "@/services/api/employee";
import { numberWithCommasHelper } from "@/services/appNumberHelper";

export default {
  name: "EmployeeDashboard",
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    CustomAutocomplete,
    AppOverlay,
    CustomSelectsArrOfObj,
    CustomDateTypingPickerFormInput,
    AppDialogConfirm,
    AppFilesUploadVue,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomTextInputImprove,
    AppSubmitFormButton,
    FormDataUploadInput,
    CustomTextInputCurrency,
    AppComboboxVehicleSearching,
    CustomFilterDateTypingPickerRange,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "รายการพนักงาน",
          disabled: false,
          to: `/employee${URL_EMPLOYEE_LIST}`,
        },
        {
          text: "รายงานส่วนบุคคล",
          disabled: true,
          to: "",
        },
      ],
      defaultPFImage: defaultImage,
      valid: false,
      isPermissionCanView: false,
      isLoading: false,
      docType: "ins",
      input: 1,
      companyType: "com_ins",
      docsCompanySelected: null,
      docsCompanyList: [],
      // vehicleList: null,
      _id: null,
      empId: null,
      isFail: false,
      info: null,
      vehicleSearch: null,

      querySearchAndFilterList: {},
      queryOptionsTable: {
        by: "createddate", // key
        order: "desc", // asc or desc
      },

      upFiles: [], // wait from appFile component
      upImages: [], // wait from appFile component
      localUpFile: [],
      localUpImages: [],

      employee: {},
      employeeNumberStats: { graphs: [] },
      countDays: 0,
      penaltyData: {},
      joinDate: [], // for date
      startDate: null,
      endDate: null,
      startDateCurrent: null,
      endDateCurrent: null,
      initRangeDate: 90,
      employeeIncome: [],

      totalScore: 100,

      formData: {
        doc: null,
        vehicle: null,
        type: null,
        amount: null,
        company: null,
        issueDate: null,
        expiryDate: null,
        files: [],
        images: [],
      },
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },

      previewItems: {},

      permList: {},
      distanceAvg: null,
      hourAvg: null,
      jobAvg: null,
      distanceXaxisRange: null,
      distanceYaxisRange: null,
      hourXaxisRange: null,
      hourYaxisRange: null,
      jobXaxisRange: null,
      jobYaxisRange: null,

      seriesDistance: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      seriesHour: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      seriesJob: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],

      seriesBreakEvenArea: [
        {
          name: "รายรับ",
          data: [],
        },
        {
          name: "รายจ่าย",
          data: [],
        },
      ],

      xLabelList: [],
      graphType: {
        DISTANCE: "ระยะทาง",
        HOURS: "ชั่วโมงทำงาน",
        JOBS: "จำนวนงาน",
      },
    };
  },

  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
    }),

    // employee: function () {
    //   return this.$store.state.employee.currentEmployeeData;
    // },
  },
  watch: {
    upFiles(val) {
      // console.log(val);
    },
    upImages(val) {
      // console.log(val);
    },
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },
    "employeeNumberStats.graphs"(newGraphs, oldGraphs) {
      if (!_.isNil(newGraphs)) {
        this.setGraphData(newGraphs);
      }
    },

    // joinDate(newDate, oldDate) {
    //   if (!_.isNil(newDate)) {
    //     // console.log("newDate", newDate);
    //     if (newDate.length === 2) {
    //       this.startDate = newDate[0];
    //       this.endDate = newDate[1];
    //       this.countDays = moment
    //         .duration(moment(this.endDate).diff(moment(this.startDate)))
    //         .asDays();
    //       console.log("joinDate", this.startDate, this.endDate);
    //       this.getData();
    //     }
    //   }
    // },

    joinDate(newDate, oldDate) {
      // console.log("joinDate", newDate);
      if (!_.isNil(newDate)) {
        // console.log("newDate", newDate);
        if (newDate.length === 2) {
          this.startDate = newDate[0];
          this.endDate = newDate[1];
          this.countDays = moment
            .duration(moment(this.endDate).diff(moment(this.startDate)))
            .asDays();

          if (
            this.startDateCurrent !== this.startDate ||
            this.endDateCurrent !== this.endDate
          ) {
            let isSorted =
              moment(this.startDate).diff(moment(this.endDate)) <= 0;
            // console.log(moment(this.startDate).diff(moment(this.endDate)));
            if (isSorted) {
              this.startDateCurrent = this.startDate;
              this.endDateCurrent = this.endDate;
              this.getData();
            }
          }
        }
      }
    },

    vehicleSearch(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          this.formData["vehicle"] = _.cloneDeep(value);
        } else {
          this.formData["vehicle"] = null;
        }
      }
    },

    employeeIncome(value) {
      if (!_.isNil(value) && value.length > 0) {
        this.seriesBreakEvenArea = [
          {
            name: "รายรับ",
            data: [],
          },
          {
            name: "รายจ่าย",
            data: [],
          },
        ];

        this.xLabelList = [];
        for (let i = 0; i < value.length; i++) {
          this.seriesBreakEvenArea[0]["data"].push(value[i]["totalIncome"]);
          this.seriesBreakEvenArea[1]["data"].push(value[i]["totalRecompense"]);
          this.xLabelList.push(
            dateStringToDateFormat(value[i]["date"], {
              returnThai: false,
            })
          );
        }
      }
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    startPage() {
      if (this.$route.params.id !== undefined) {
        // this.title = "แก้ไขข้อมูลประกันภัยรถ";
        this._id = this.$route.params.id;
        this.empId = this.$route.params.empId;
      } else {
        this._id = null;
        this.empId = null;
        // this.title = "เพิ่มข้อมูลประกันภัยรถ";
      }
      // employeeData = this.$store.state.employee.currentEmployeeData;
      // if (!_.isNil(this.employeeData)) {
      //   this.employee = this.employeeData;
      // }
      // console.log("employee", employeeData);

      if (!_.isNil(this.empId)) {
        this.getSearchItem({ value: this.empId });
      }
      this.initDate();
      // this.getData();
    },
    initDate() {
      let start = moment()
        .subtract(this.initRangeDate, "days")
        .format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      // console.log(start, end);
      this.startDate = start;
      this.endDate = end;
      this.joinDate = [start, end];
    },

    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      delete this.querySearchAndFilterList["text"]; // name
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["value"])) {
          _queryParameter["text"] = _searchingParameter["value"];
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getEmployeeDetail();
    },

    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ..._queryParameter,
      };

      // this.setRouterQuery(this.querySearchAndFilterList);
    },

    async getEmployeeDetail() {
      // this.isLoading = true;
      this.employee = {};

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getEmployeeListService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let worksheets = {};
        if (responseData.data["userList"].length > 0) {
          worksheets = responseData.data["userList"][0];
        }
        this.employee = _.cloneDeep(worksheets);
      } else {
        this.employee = {};
      }

      if (this.previewItems.length === 0) {
        this.fetchDataError = 0;
      }

      // this.isLoading = false;
    },

    async getData() {
      this.isLoading = true;

      // let respVelist = await getVehicleDocsVelist(this.$store);
      // if (respVelist["code"] === 200) {
      //   this.vehicleList = respVelist.data;
      // } else {
      //   this.isFail = true;
      // }
      // this.docsCompanyList = await getAppDataService(
      //   this.$store,
      //   this.companyType
      // );

      // let startDate = moment("2023-08-01");
      // let endDate = moment("2023-10-01");
      this.employeeNumberStats = {};
      this.employeeIncome = [];

      if (this._id !== null) {
        let params = new URLSearchParams({
          start: this.startDate,
          end: this.endDate,
          driverId: this._id,
        }).toString();
        let respData = await getEmployeeReportService(this.$store, params);
        if (respData["code"] === 200) {
          if (!_.isNil(respData["data"])) {
            this.employeeNumberStats = _.clone(respData["data"]);
          } else {
            this.employeeNumberStats = {};
          }
        } else {
          this.isFail = true;
        }

        // this.getSearchItem({ value: this._id });
        let currentYear = new Date("2023-10-01").getFullYear();
        let queryPayload = { year: currentYear, userId: this._id.toString() };
        let queryStringReport = new URLSearchParams(queryPayload).toString();

        let responsePenaltyReportData = await getPenaltyReportService(
          this.$store,
          queryStringReport
        );
        if (responsePenaltyReportData["code"] === 200) {
          this.penaltyData = responsePenaltyReportData["data"][this._id];
          this.penaltyData["sumCounter"] = this.getTotalCounter(
            this.penaltyData["stats"]
          );
        }

        // incomes
        let paramsIncome = new URLSearchParams({
          start: this.startDate,
          end: this.endDate,
          userId: this._id,
        }).toString();
        let respIncomeData = await getEmployeeIncomeService(
          this.$store,
          paramsIncome
        );
        // console.log("respIncomeData", respIncomeData);
        if (respIncomeData["code"] === 200) {
          if (!_.isNil(respIncomeData["data"])) {
            this.employeeIncome = _.clone(respIncomeData["data"]["timeseries"]);
          } else {
            this.employeeIncome = [];
          }
        } else {
          this.isFail = true;
        }

        // let paramsPenalty = new URLSearchParams({
        //   start: "2023-08-01",
        //   end: "2023-10-01",
        // }).toString();
        // let respPenaltyData = await getEmployeeReportPenaltyService(
        //   this.$store,
        //   paramsPenalty
        // );
        // console.log("respPenaltyData", respPenaltyData);

        // let paramsTimeoff = new URLSearchParams({
        //   start: "2023-08-01",
        //   end: "2023-10-01",
        // }).toString();
        // let respTimeoffData = await getEmployeeReportTimeoffService(
        //   this.$store,
        //   paramsTimeoff
        // );
        // console.log("respTimeoffData", respTimeoffData);

        // let respStatsData = await getEmployeeReportStatsService(this.$store);
        // console.log("respStatsData", respStatsData);
      }

      this.isLoading = false;
    },

    getTotalCounter(stats) {
      if (!_.isNil(stats)) {
        let sumCounter = 0;
        for (let st in stats) {
          sumCounter += stats[st].counter;
        }

        return sumCounter;
      }
      return 0;
    },

    setGraphData(val) {
      this.distanceAvg = val[0].avg;
      this.hourAvg = val[1].avg;
      this.jobAvg = val[2].avg;

      let distanceKm = [
        (val[0].user[0] / 1000).toFixed(2),
        val[0].user[1].toFixed(2),
      ];
      let hours = [
        (val[1].user[0] / 3600).toFixed(2),
        val[1].user[1].toFixed(2),
      ];
      let jobs = [val[2].user[0].toFixed(2), val[2].user[1].toFixed(2)];

      this.distanceXaxisRange = [
        Math.min(val[0].avg[0] / 1000, val[0].user[0] / 1000) - 3,
        Math.max(val[0].avg[0] / 1000, val[0].user[0] / 1000) + 3,
      ];
      this.distanceYaxisRange = [
        Math.min(val[0].avg[1], val[0].user[1]) - 100,
        Math.max(val[0].avg[1], val[0].user[1]) + 100,
      ];
      this.hourXaxisRange = [
        Math.min(val[1].avg[0] / 3600, val[1].user[0] / 3600) - 3,
        Math.max(val[1].avg[0] / 3600, val[1].user[0] / 3600) + 3,
      ];
      this.hourYaxisRange = [
        Math.min(val[1].avg[1], val[1].user[1]) - 100,
        Math.max(val[1].avg[1], val[1].user[1]) + 100,
      ];
      this.jobXaxisRange = [
        Math.min(val[2].avg[0], val[2].user[0]) - 3,
        Math.max(val[2].avg[0], val[2].user[0]) + 3,
      ];
      this.jobYaxisRange = [
        Math.min(val[2].avg[1], val[2].user[1]) - 100,
        Math.max(val[2].avg[1], val[2].user[1]) + 100,
      ];

      this.seriesDistance = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [distanceKm],
        },
      ];
      this.seriesHour = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [hours],
        },
      ];
      this.seriesJob = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [jobs],
        },
      ];
    },
    chartBreakEvenOptionsArea() {
      return {
        chart: {
          height: 350,
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return `${numberWithCommasHelper(val)}`;
          },
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },
        // colors: ["#49c496", "#ed5555"],
        colors: ["#567DF4", "#AAB7B8"],
        xaxis: {
          categories: this.xLabelList,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${numberWithCommasHelper(value)}`;
            },
          },
        },
        // annotations: {
        //   yaxis: this.annotationYaxis,
        //   points: this.annotationPoints,
        //   //[
        //   // {
        //   //   x: "1",
        //   //   y: 5000,
        //   //   marker: {
        //   //     size: 0,
        //   //   },
        //   //   label: {
        //   //     borderColor: "#AAB7B8",
        //   //     offsetY: 0,
        //   //     style: {
        //   //       color: "#fff",
        //   //       background: "#AAB7B8",
        //   //     },
        //   //     text: "1,234",
        //   //   },
        //   // },
        //   // {
        //   // ]
        // },
        tooltip: {
          x: {
            show: true,
            // formatter: function (value, opts) {
            //   if (opts) {
            //     // var val =
            //     //   opts.w.config.series[opts.seriesIndex]["data"][
            //     //     opts.dataPointIndex
            //     //   ];
            //     // console.log("val", val);
            //     return `<span class="text-app-title font-weight-bold black--text">สัปดาห์ที่ ${
            //       opts.dataPointIndex + 1
            //     }</span>`;
            //   }
            // },
          },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
      };
    },

    chartOptions(val) {
      let graphAnnotation = {};
      let graphXaxis = {};
      let graphYaxis = {};
      if (val === "DISTANCE") {
        graphAnnotation = {
          yaxis: [
            {
              y: this.distanceAvg
                ? this.distanceAvg[1]
                  ? (this.distanceAvg[1] / 1000).toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.distanceAvg
                ? this.distanceAvg[0]
                  ? (this.distanceAvg[0] / 1000).toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };
        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.distanceXaxisRange ? this.distanceXaxisRange[0] : null,
          max: this.distanceXaxisRange ? this.distanceXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.distanceYaxisRange ? this.distanceYaxisRange[0] : null,
          max: this.distanceYaxisRange ? this.distanceYaxisRange[1] : null,
          title: {
            text: "รายได้",
            offsetX: 0,
            offsetY: -80,
          },
        };
      } else if (val === "HOURS") {
        graphAnnotation = {
          yaxis: [
            {
              y: this.hourAvg
                ? this.hourAvg[1]
                  ? (this.hourAvg[1] / 3600).toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.hourAvg
                ? this.hourAvg[0]
                  ? (this.hourAvg[0] / 3600).toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };

        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.hourXaxisRange ? this.hourXaxisRange[0] : null,
          max: this.hourXaxisRange ? this.hourXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.hourYaxisRange ? this.hourYaxisRange[0] : null,
          max: this.hourYaxisRange ? this.hourYaxisRange[1] : null,
          title: {
            text: "รายได้",
            offsetX: 0,
            offsetY: -80,
          },
        };
      } else {
        graphAnnotation = {
          yaxis: [
            {
              y: this.jobAvg ? (this.jobAvg[1] ? this.jobAvg[1] : 0) : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.jobAvg ? (this.jobAvg[0] ? this.jobAvg[0] : 0) : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };

        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.jobXaxisRange ? this.jobXaxisRange[0] : null,
          max: this.jobXaxisRange ? this.jobXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.jobYaxisRange ? this.jobYaxisRange[0] : null,
          max: this.jobYaxisRange ? this.jobYaxisRange[1] : null,
          title: {
            text: "รายได้",
            offsetX: 0,
            offsetY: -80,
          },
        };
      }

      return {
        chart: {
          height: 250,
          type: "scatter",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        legend: { show: false },
        grid: {
          show: false,
        },
        markers: {
          colors: ["#AAB7B8", "#567DF4"],
          size: [7, 10],
        },

        annotations: graphAnnotation,
        xaxis: graphXaxis,
        yaxis: graphYaxis,
      };
    },

    // ==== for app upload ====
    remove_upfile(index) {
      this.$refs.mulFilesUpload.removeInput(index);
    },
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    // ====
    remove_localImagefile(atLocation) {
      this.localUpImages.splice(atLocation, 1);
    },
    remove_localUpfile(atLocation) {
      this.localUpFile.splice(atLocation, 1);
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    async callUploadImageErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    getJobPercentageByStatus(val) {
      if (!_.isNil(this.employeeNumberStats)) {
        if (!_.isNil(this.employeeNumberStats["job"])) {
          if (this.employeeNumberStats["job"]["amount"] === 0) {
            return 0;
          } else {
            return Math.ceil(
              (val * 100) / this.employeeNumberStats["job"]["amount"]
            );
          }
        }
      }
      return 0;
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (
          this.permList.admin_all === true ||
          this.permList.user_report === true
        ) {
          this.isPermissionCanView = true;
        }
      }
    },
    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },

    resetFormData() {
      this.querySearchAndFilterList = {};
      this.queryOptionsTable = {
        by: "createddate", // key
        order: "desc", // asc or desc
      };

      this.employeeNumberStats = { graphs: [] };
      this.employee = {};
      this.countDays = 0;
      this.previewItems = {};
      this.penaltyData = {};
      this.joinDate = [];
      this.startDate = null;
      this.endDate = null;
      this.formData = {
        doc: null,
        vehicle: null,
        type: null,
        amount: null,
        company: null,
        issueDate: null,
        expiryDate: null,
        files: [],
        images: [],
      };
      this.clearInput();
    },
    clearInput() {
      this.docsCompanySelected = null;
      this.docsCompanyList = [];
      this.vehicleSearch = null;
      // this.vehicleList = null;
      this.upFiles = [];
      this.upImages = [];
      this.localUpFile = [];
      this.localUpImages = [];
    },
  },
  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.startPage();
    }
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}
.image_list_float_left {
  float: right;
}
.dashboard-large {
  font-size: 80px !important;
}

.dashboard-medium {
  font-size: 40px !important;
}
.dashboard-small {
  font-size: 30px !important;
}

.dashboard-xsmall {
  font-size: 26px !important;
}
</style>
