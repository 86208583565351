<template>
  <v-dialog v-model="show" width="30%" persistent>
    <v-card v-if="show">
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>สร้างแผนงานซ้ำ</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 0">
        <template>
          <v-row dense>
            <v-col>
              <CustomAutocompleteBase
                name="sheet-selector"
                :items="items"
                v-model="select"
                :loading="loading"
                title="เลือกแผนงานที่ต้องการ"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <!--  -->
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>

      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>
      <!--  -->
      <v-divider v-if="fetchDataError <= 0"></v-divider>
      <v-card-actions v-if="fetchDataError <= 0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndCloseHandler" v-if="fetchDataError <= 0">
          ยกเลิก
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveAndCloseHandler"
          v-if="fetchDataError === 0"
          :disabled="select === null"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomAutocompleteBase from "@/components/CustomAutocompleteBase.vue";
import {
  getSheetForDuplicateService,
  putDuplicateTableToSheetService,
} from "@/services/api/assign_multiple_job";

export default {
  name: "AssignMultipleDuplicateTable",
  data: () => ({
    show: false,
    fetchDataError: 1,
    //
    select: null,
    items: [],
    loading: false,
    disabled: false,
    tableId: null,
  }),
  methods: {
    open(tableId) {
      this.resetForm();
      this.tableId = tableId;
      this.show = true;
      this.getData();
      //
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async getData() {
      this.fetchDataError = 0;
      try {
        this.loading = true;
        const responseData = await getSheetForDuplicateService(
          this.$store,
          new URLSearchParams({
            id: this.tableId,
          }).toString()
        );
        if (responseData["code"] === 200) {
          this.items = responseData["data"];
        } else {
          throw "RESPONSE_DATA_FAIL";
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.fetchDataError = -2;
      }
    },
    resetForm() {
      this.select = null;
      this.items = [];
      this.searchLoading = false;
      this.tableId = null;
      this.disabled = false;
    },
    async saveAndCloseHandler() {
      this.fetchDataError = 2;
      if (this.select === null) {
        return;
      }
      try {
        const responseData = await putDuplicateTableToSheetService(
          this.$store,
          new URLSearchParams({
            id: this.tableId,
          }).toString(),
          {
            sheetId: this.select["key"],
          }
        );
        if (responseData["code"] === 200) {
          this.show = false;
          this.resolve({
            sheetId: this.select["key"],
          });
        } else {
          throw "RESPONSE_DATA_FAIL";
        }
      } catch (error) {
        console.error(error);
        this.fetchDataError = -1;
      }
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
  },
  components: { CustomAutocompleteBase },
};
</script>

<style scoped></style>
