<template>
  <div name="employeeDetailView">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />
    <v-container grid-list-xs>
      <!-- <h2 class="mb-5" v-if="isPermissionCanView">{{ viewTitle }}</h2> -->

      <v-row v-if="isPermissionCanView">
        <v-col cols="12">
          <EmployeeStatusDataView
            :userId="mongoId"
            :isEdit="true"
            :canEdit="isPermissionCanEdit"
            @on-click-save="saveCompany"
          ></EmployeeStatusDataView>
        </v-col>

        <v-col cols="12">
          <EmployeePersonalInformationFormView
            :canEdit="isPermissionCanEdit"
            @on-click-save="savePersonalInformation"
            :userId="mongoId"
            :data="formData"
          ></EmployeePersonalInformationFormView>
        </v-col>

        <v-col cols="12">
          <StudyingInformationFormView
            :educational="educational"
            @on-click-save="saveStudyingInformation"
            :canEdit="isPermissionCanEdit"
          ></StudyingInformationFormView>
        </v-col>

        <v-col cols="12">
          <WorkingExperienceFormView
            :careerExperience="careerExperience"
            @on-click-save="saveCareerExperienceInformation"
            :canEdit="isPermissionCanEdit"
          ></WorkingExperienceFormView>
        </v-col>

        <v-col cols="12">
          <FamilyInformationFormView
            :data="family"
            @on-click-save="saveFamilyInformation"
            :canEdit="isPermissionCanEdit"
          ></FamilyInformationFormView>
        </v-col>

        <v-col cols="12">
          <AccountFormView
            :user-id="mongoId"
            :is-edit="true"
            :data="formData"
            :enable-password="false"
            :can-edit="isPermissionCanEdit"
            @on-click-save="saveAccount"
            @enable-loading-func="enableMainLoading"
            @disable-loading-func="disableMainLoading"
          ></AccountFormView>
        </v-col>

        <v-col cols="12">
          <FacePictureView
            @on-click-save="saveFacePicture"
            :face-images="faceImages"
            :userId="mongoId"
            :profile-image="profileImage"
            :can-edit="isPermissionCanEdit"
          ></FacePictureView>
        </v-col>

        <v-col cols="12">
          <VehicleFormView
            @on-click-save="saveVehicleDetail"
            :user-id="mongoId"
            :can-edit="isPermissionCanEdit"
            :data="formData"
          />
        </v-col>
        <v-col cols="12">
          <FeedCardDataView
            class="py-0 px-0"
            :user-id="mongoId"
            :is-loading="isLoading"
            :can-edit="isPermissionCanEdit"
          />
        </v-col>

        <v-col cols="12">
          <DrivingLicenseDataView
            :driverLicence="driverLicence"
            @on-click-save="saveDrivingLicenseData"
            :canEdit="isPermissionCanEdit"
            :isLoading="isLoading"
          ></DrivingLicenseDataView>
        </v-col>

        <v-col cols="12" v-if="isPermissionFinView">
          <FinancialAccountDataView
            :data="formData"
            :userId="mongoId"
            :canEdit="isPermissionCanEdit"
            :isAddingData="isAddingData"
            @on-click-save="saveFinancialAccountData"
          ></FinancialAccountDataView>
        </v-col>

        <v-col cols="12">
          <CompanyDataView
            :data="formData"
            :userId="mongoId"
            :isEdit="true"
            :canEdit="isPermissionCanEdit"
            @on-click-save="saveCompany"
          ></CompanyDataView>
        </v-col>
      </v-row>

      <AppPageNotFound :show="isPermissionCanView" />
      <AppOverlay :isLoading="isLoading" />
      <AppDialogConfirm ref="dialogAlert" />
    </v-container>
  </div>
</template>
<script>
import EmployeePersonalInformationFormView from "@/views/employee/employee_detail/components/EmployeePersonalInformationFormView.vue";
import StudyingInformationFormView from "@/views/employee/employee_detail/components/StudyingInformationFormView.vue";
import WorkingExperienceFormView from "@/views/employee/employee_detail/components/WorkingExperienceFormView.vue";
import FamilyInformationFormView from "@/views/employee/employee_detail/components/FamilyInformationFormView.vue";
import AccountFormView from "@/views/employee/employee_detail/components/AccountFormView.vue";
import FacePictureView from "@/views/employee/employee_detail/components/FacePictureView.vue";
import FinancialAccountDataView from "@/views/employee/employee_detail/components/FinancialAccountDataView.vue";
import EmployeeStatusDataView from "@/views/employee/employee_detail/components/EmployeeStatusDataView.vue";
import CompanyDataView from "@/views/employee/employee_detail/components/CompanyDataView.vue";
import DrivingLicenseDataView from "@/views/employee/employee_detail/components/DrivingLicenseDataView.vue";
import {
  getEmployeeService,
  putUserInfoEmployee,
} from "@/services/api/employee";
import AppOverlay from "@/components/AppOverlay.vue";
import { processPermission } from "@/services/permissions";
import { getUserAppDataService } from "@/services/api/app";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { errorMessageMapper } from "@/services/error";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import _ from "lodash";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import VehicleFormView from "./components/VehicleFormView.vue";
import FeedCardDataView from "./components/FeedCardDataView.vue";
import { URL_EMPLOYEE_LIST } from "@/services/routerQueryPath";

export default {
  name: "EmployeeDetailView",

  props: {},

  components: {
    EmployeePersonalInformationFormView,
    StudyingInformationFormView,
    WorkingExperienceFormView,
    FamilyInformationFormView,
    AccountFormView,
    FacePictureView,
    FinancialAccountDataView,
    EmployeeStatusDataView,
    CompanyDataView,
    DrivingLicenseDataView,
    AppOverlay,
    AppDialogConfirm,
    FeedCardDataView,
    AppBreadCrumbs,
    AppPageNotFound,
    VehicleFormView,
  },

  data() {
    return {
      isAddingData: false,
      viewTitle: "ข้อมูลพนักงาน",
      isPermissionCanEdit: false,
      mongoId: null,
      formData: null,
      //
      profileImage: null,
      faceImages: null,
      educational: null,
      careerExperience: null,
      family: null,
      driverLicence: null,
      companyData: null,

      permissions: null,
      appCompanyData: null,
      breadcrumbsItems: [
        {
          text: "รายการพนักงาน",
          disabled: false,
          to: `/employee${URL_EMPLOYEE_LIST}`,
        },
        {
          text: "ข้อมูลพนักงาน",
          disabled: true,
          to: "",
        },
      ],
      permList: {},
      isPermissionCanView: false,
      isPermissionFinView: false,
      isLoading: true,
    };
  },

  mounted() {
    if (_.isNil(this.$route.params.id) === false) {
      this.mongoId = this.$route.params.id;
      this.getData();
    } else {
      this.$router.push(`/employee${URL_EMPLOYEE_LIST}`);
    }
  },

  methods: {
    enableMainLoading() {
      this.isLoading = true;
    },
    disableMainLoading() {
      this.isLoading = false;
    },
    //
    setEmployeeInfo(data) {
      this.profileImage = data.profileImage ?? null;
      this.faceImages = data.faceImage ?? null; //this.testFaceImages
      this.driverLicence = data.driverLicence ?? null;
      this.educational = data.educational ?? null;
      this.careerExperience = data.careerExperience ?? null;
      this.family = data.family ?? null;
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");

        if (
          this.permList.admin_all === true ||
          this.permList.user_info === true ||
          this.permList.user_info_fin === true ||
          this.permList.user_info_crud === true
        ) {
          // view
          this.isPermissionCanView = true;
          //
          if (
            this.permList.admin_all === true ||
            this.permList.user_info_fin === true
          ) {
            this.isPermissionFinView = true;
          }

          if (
            this.permList.admin_all === true ||
            this.permList.user_info_crud === true
          ) {
            this.viewTitle = "แก้ไขข้อมูลพนักงาน";
            this.isPermissionCanEdit = true;
          }
        }
      }
    },
    // saveVehicleDetail() {},
    saveFeedCardData() {},
    setIdCardDataToStore(data, addressDataList) {
      this.$store.dispatch("employee/updateIDCardData", data);
      this.$store.dispatch("employee/updateAddressData", addressDataList);
    },
    async getData() {
      this.checkUserPermissions();
      let resp = null;
      // ===
      resp = await getUserAppDataService(this.$store);
      if (resp["code"] === 200) {
        this.appCompanyData = {
          ...resp.data,
        };
      } else {
        this.$router.push(`/employee${URL_EMPLOYEE_LIST}`);
      }

      resp = await getEmployeeService(this.$store, this.mongoId, true);
      if (resp["code"] === 200) {
        this.formData = resp.data;

        if (!_.isNil(this.formData.payeeData)) {
          if (!_.isNil(this.formData.payeeData.vatPercent)) {
            this.formData.payeeData.vatPercent =
              "" + this.formData.payeeData.vatPercent;
          }
        }

        if (!_.isNil(this.formData.payeeData)) {
          if (!_.isNil(this.formData.payeeData.socialInsurance)) {
            if (this.formData.payeeData.socialInsurance) {
              this.formData.payeeData.socialInsurance = "เอา";
            } else {
              this.formData.payeeData.socialInsurance = "ไม่เอา";
            }
          }
        }

        this.setIdCardDataToStore(this.formData, this.formData.address);

        this.formData["_appCompanyData"] = this.appCompanyData;
        this.setEmployeeInfo(resp.data);
      } else {
        this.$router.push(`/employee${URL_EMPLOYEE_LIST}`);
      }
      //===
      this.isLoading = false;
    },

    async showErrorDialog(responseData) {
      let text_error = "ไม่สามารถแก้ไขข้อมูลได้ ลองใหม่อีกครั้ง";
      if (responseData.code === 400) {
        text_error = `${text_error} (${
          errorMessageMapper[responseData.data.message]
        })`;
      }
      await this.$refs.dialogAlert.open(text_error, null, DialogType.ERROR, {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      });
    },

    async showSuccessDialog(section) {
      await this.$refs.dialogAlert.open(
        `บันทึกแก้ไข${section}เรียบร้อย`,
        null,
        DialogType.POSITIVE,
        {
          noconfirm: false,
        }
      );
    },
    // step => 1 action
    async savePersonalInformation(data, addressDataList) {
      this.isLoading = true;

      let resp = await putUserInfoEmployee(
        this.$store,
        "info",
        this.mongoId,
        data
      );

      if (resp.code == 200) {
        this.showSuccessDialog("ข้อมูลส่วนตัว");
        this.setIdCardDataToStore(data, addressDataList);
      } else {
        this.showErrorDialog(resp);
      }
      this.isLoading = false;
    },
    // step => 2 action
    async saveStudyingInformation(data) {
      this.isLoading = true;
      let sep = await putUserInfoEmployee(
        this.$store,
        "edu",
        this.mongoId,
        data
      );

      if (sep.code == 200) {
        this.showSuccessDialog("ประวัติการศึกษา");
      } else {
        this.showErrorDialog(sep);
      }

      this.isLoading = false;
    },
    // step => 3 action
    async saveCareerExperienceInformation(data) {
      this.isLoading = true;
      let sep = await putUserInfoEmployee(
        this.$store,
        "career",
        this.mongoId,
        data
      );

      if (sep.code == 200) {
        this.showSuccessDialog("ประวัติการทำงานที่ผ่านมา");
      } else {
        this.showErrorDialog(sep);
      }

      this.isLoading = false;
    },
    // step => 4 action
    async saveFamilyInformation(data) {
      this.isLoading = true;
      // console.log("Family Data", data);
      let sep = await putUserInfoEmployee(
        this.$store,
        "family",
        this.mongoId,
        data
      );

      if (sep.code == 200) {
        this.showSuccessDialog("ประวัติครอบครัว");
      } else {
        this.showErrorDialog(sep);
      }

      this.isLoading = false;
    },

    // step => 5 action
    async saveAccount(data) {
      this.isLoading = true;

      let sep = await putUserInfoEmployee(
        this.$store,
        "account",
        this.mongoId,
        data
      );

      if (sep.code == 200) {
        this.showSuccessDialog("ข้อมูลบัญชี");
      } else {
        this.showErrorDialog(sep);
      }

      this.isLoading = false;
    },
    // step => 6 action
    async saveFacePicture(data) {
      this.isLoading = true;
      let resp = await putUserInfoEmployee(
        this.$store,
        "profile",
        this.mongoId,
        data
      );

      if (resp.code == 200) {
        this.getData();
        this.showSuccessDialog("ภาพใบหน้า");
      } else {
        this.showErrorDialog(resp);
      }
      this.isLoading = false;
    },
    // step => 7 action
    async saveDrivingLicenseData(data) {
      this.isLoading = true;
      let resp = await putUserInfoEmployee(
        this.$store,
        "license",
        this.mongoId,
        data
      );

      if (resp.code == 200) {
        this.getData();
        this.showSuccessDialog("ข้อมูลใบขับขี่");
      } else {
        this.showErrorDialog(resp);
      }
      this.isLoading = false;
    },
    // step => 8 action
    async saveFinancialAccountData(data) {
      if (data.payeeData.socialInsurance == "เอา") {
        data.payeeData.socialInsurance = true;
      } else {
        data.payeeData.socialInsurance = false;
      }

      this.isLoading = true;
      let responseData = await putUserInfoEmployee(
        this.$store,
        "fin",
        this.mongoId,
        data
      );

      if (responseData.code == 200) {
        this.getData();
        this.showSuccessDialog("ข้อมูลการเงิน");
      } else {
        this.showErrorDialog(responseData);
      }
      this.isLoading = false;
    },
    // step => 9 action
    async saveCompany(data) {
      this.isLoading = true;
      let responseData = await putUserInfoEmployee(
        this.$store,
        "company",
        this.mongoId,
        data
      );

      if (responseData.code == 200) {
        this.showSuccessDialog("ข้อมูลที่ทำงาน");
      } else {
        this.showErrorDialog(responseData);
      }
      this.isLoading = false;
    },
    async saveVehicleDetail() {
      this.isLoading = true;
      this.getData();
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1500px;
}
</style>
