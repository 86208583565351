<template>
  <v-container name="RouteTemplateDetailFormDataView">
    <v-form ref="RouteTemplateDetailForm" v-if="formIsOk === 1">
      <v-row no-gutters>
        <v-col lg="3" sm="3" class="px-3">
          <InputView
            title="สัญญา"
            :is-required="true"
            :bottom-margin="false"
            :small="true"
          >
            <JobContractSelectorInput
              ref="JobContractSelectorInputRef"
              @searchItemChange="getSearchContractItem"
              :disabled="!isPermissionCanEdit"
              :value="formData.contract"
            />
          </InputView>
        </v-col>
        <v-col lg="9" md="9">
          <CustomTextInputImprove
            ref="jobTitle"
            v-model="formData.name"
            title="ชื่อเส้นทาง"
            :is-required="true"
            :disabled="!isPermissionCanEdit"
            :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
            :hide-detail="true"
            :small="true"
            :input-view-bottom-margin="false"
          ></CustomTextInputImprove>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <CustomTextareaImprove
            class="flex-grow-1"
            title="คำอธิบายเส้นทาง"
            ref="desc"
            v-model="formData.desc"
            :height="60"
            :hideDetail="true"
            :small="true"
            :input-view-bottom-margin="false"
          />
        </v-col>
      </v-row>
      <v-row justify="end" dense>
        <v-col cols="auto">
          <PrimaryButton
            class="align-self-end pr-3"
            color="primary"
            @on-click="($event) => handleSaveOnClick()"
          >
            บันทึก
          </PrimaryButton>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="formIsOk === -1"></div>
  </v-container>
</template>
<script>
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import { putRouteTemplate } from "@/services/api/contract_job_routes";

export default {
  name: "RouteTemplateDetailFormData",
  data() {
    return {
      _id: null,
      vaild: false,
      formData: {
        contract: null,
        name: null,
        desc: null,
      },
      formIsOk: 0,
      isPermissionCanEdit: true,
    };
  },
  watch: {
    "$store.state.routeSetting.templateData": function (newValue) {
      this.processData(newValue);
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    getSearchContractItem(searchItem) {
      this.formData["contract"] = searchItem;
    },
    processData(jobData) {
      if (!_.isNil(jobData)) {
        let _formData = _.cloneDeep(jobData);
        this._id = _formData["_id"];
        this.formData = _.cloneDeep(_formData);
        this.formIsOk = 1;
      }
    },
    async handleSaveOnClick() {
      this.$store.dispatch("routeSetting/updateMainLoading", true);
      if (this.$refs["RouteTemplateDetailForm"].validate() === true) {
        const requestBodyData = _.pick(this.formData, [
          "contract",
          "name",
          "desc",
        ]);

        const responseData = await putRouteTemplate(
          this.$store,
          `id=${this._id}`,
          requestBodyData
        );

        if (responseData["code"] === 200) {
          this.$store.dispatch(
            "routeSetting/updateTemplateData",
            _.cloneDeep(this.formData)
          );
        } else {
          console.error("REQUEST_FAIL");
          this.formIsOk = -1;
        }

        this.$store.dispatch("routeSetting/updateMainLoading", false);
      } else {
        console.error("FORM_INVALIDATE");
      }
    },
  },
  components: {
    JobContractSelectorInput,
    InputView,
    CustomTextInputImprove,
    CustomTextareaImprove,

    PrimaryButton,
  },
};
</script>
<style scoped></style>
