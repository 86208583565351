<template>
  <CustomCardFormViewModern
    title="รายได้/รายจ่าย"
    :is-show-title="true"
    :is-add-title-margin="true"
  >
    <v-row dense>
      <v-col cols="12">
        <apexchart
          :series="series"
          :options="chartOptions"
          :height="400"
        ></apexchart>
      </v-col>
    </v-row>
  </CustomCardFormViewModern>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import VueApexCharts from "vue-apexcharts";
import {
  numberWithCommasHelper,
  displayNumberLocaleString,
  displayNumberFixedPointHelper,
} from "@/services/appNumberHelper";
import {
  nFormatterHelper,
  processWeekYearStringList,
} from "@/services/appSummaryHelper";
export default {
  name: "VehicleBreakEvenPointChart",
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: "รายรับ",
          data: [9000, 8500, 7300, 9500],
        },
        {
          name: "รายจ่าย",
          data: [3300, 5000, 6000, 5800],
        },
      ],
      categories: [],
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return `${nFormatterHelper(value)}`;
          },
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },
        colors: ["#567DF4", "#AAB7B8"],
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${nFormatterHelper(value)}`;
            },
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: (val) => {
              return `${displayNumberLocaleString(val, 2)} บาท`;
            },
          },
        },
      };
    },
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        const _week = _.get(this.data, "weekSerials", []);

        let _income = [];
        let _expense = [];
        for (let i = 0; i < _week.length; i++) {
          _income.push(_week[i]["totalIncome"]);
          _expense.push(_week[i]["totalExpenses"]);
        }

        this.categories = processWeekYearStringList(_week);
        this.series = [
          {
            name: "รายได้",
            data: _income,
          },
          {
            name: "รายจ่าย",
            data: _expense,
          },
        ];
      }
    },
  },
  components: {
    apexchart: VueApexCharts,
    CustomCardFormViewModern,
  },
  mounted() {
    this.processData();
  },
};
</script>
