<template>
  <div name="facePicture">
    <v-form v-model="valid">
      <CustomCardFormViewModern
        title="รูปภาพโปรไฟล์"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <!-- <v-row class="px-9" no-gutters justify="center">
          <v-col cols="auto">
            <div class="title-form mb-5">รูปภาพโปรไฟล์</div>
          </v-col>
        </v-row> -->

        <v-row no-gutters justify="center" class="mt-2">
          <v-col cols="auto">
            <div class="error--text">
              * กรุณาเลือกรูปภาพที่มีขนาดไฟล์ไม่เกิน 10MB
            </div>
            <AppFilesUpload
              placeholder="เลือกรูปภาพ"
              :multiple="false"
              :small-chips="false"
              :allow-image-only="true"
              :upload-with-scale="true"
              v-model="selectedProfileImageFile"
              :isPrependInnerIcon="true"
              ref="AppFillesUploadRef"
              :disabled="disabled"
              uploadTag="EMP_PROFILE_IMAGE"
            />
          </v-col>
        </v-row>

        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <v-img
              :src="profileImageInput.src"
              height="300"
              width="300"
              contain
              class="backgroud-image"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" justify="center">
                  <v-icon x-large>mdi-image</v-icon>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <!--  -->
        <!-- <div class="mx-9 my-10" v-if="showFaceImage">
          <v-divider></v-divider>
        </div> -->
        <div class="mx-0 mt-6">
          <!-- <v-row no-gutters justify="center">
            <v-col cols="auto">
              <div class="title-form mx-9" v-if="showFaceImage">
                รูปภาพลงทะเบียน
              </div>
            </v-col>
          </v-row> -->
          <v-row v-if="showFaceImage" class="px-3 pt-3">
            <v-col cols="12"
              ><v-card color="main-bg-color" flat
                ><v-card-title class="px-6 py-3 text-title font-weight-bold">
                  รูปภาพลงทะเบียน</v-card-title
                >
              </v-card></v-col
            ></v-row
          >
          <v-row justify="center" v-if="showFaceImage">
            <v-col cols="auto">
              <v-card outlined width="300">
                <v-card-title primary-title class="justify-center">
                  <div class="text-subtitle-2">
                    {{
                      faceFrontImageInput.name === "front"
                        ? "ใบหน้าด้านหน้า"
                        : ""
                    }}
                  </div>
                </v-card-title>

                <div v-if="faceFrontImageInput.src !== null">
                  <v-img
                    :src="faceFrontImageInput.src"
                    height="200"
                    contain
                    class="backgroud-image text-app-title"
                  ></v-img>
                </div>
                <div
                  v-else-if="
                    faceFrontImageInput.fileKey !== null &&
                    frontFaceSrc !== null
                  "
                >
                  <v-img
                    :src="frontFaceSrc.data.url"
                    height="200"
                    contain
                    class="backgroud-image text-app-title"
                  ></v-img>
                </div>
                <div v-else>
                  <p class="text-center">ไม่มีข้อมูลรูปภาพใบหน้า</p>
                </div>

                <v-card-actions class="justify-center">
                  <AppFilesFaceUpload
                    class="px-2 pt-5"
                    placeholder="เลือกรูปภาพ"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="true"
                    v-model="selectedFaceFrontFile"
                    ref="FaceFrontfileUploadRef"
                    :disabled="disabled"
                    uploadTag="EMP_FACE_IMAGE"
                  />
                </v-card-actions>
              </v-card>
            </v-col>

            <!-- <v-col cols="auto">
              <v-card outlined width="280">
                <v-card-title primary-title class="justify-center">
                  <div class="text-subtitle-2">
                    {{ faceLeftImageInput.name }}
                  </div>
                </v-card-title>

                <v-img
                  :src="faceLeftImageInput.fileKey"
                  height="200"
                  contain
                  class="backgroud-image"
                ></v-img>

                <v-card-actions class="justify-center">
                  <AppFilesUpload
                    class="px-2 pt-5"
                    placeholder="เลือกรูปภาพ"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="true"
                    v-model="selectedFaceLeftFile"
                    ref="FaceLeftfileUploadRef"
                    :disabled="disabled"
                    uploadTag="EMP_FACE_IMAGE"
                  />
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="auto">
              <v-card outlined width="280">
                <v-card-title primary-title class="justify-center">
                  <div class="text-subtitle-2">
                    {{ faceRightImageInput.name }}
                  </div>
                </v-card-title>

                <v-img
                  :src="faceRightImageInput.fileKey"
                  contain
                  height="200"
                  class="backgroud-image"
                ></v-img>
                <v-card-actions class="justify-center">
                  <AppFilesUpload
                    class="px-2 pt-5"
                    placeholder="เลือกรูปภาพ"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="true"
                    v-model="selectedFaceRightFile"
                    ref="FaceRightfileUploadRef"
                    :disabled="disabled"
                    uploadTag="EMP_FACE_IMAGE"
                  />
                </v-card-actions>
              </v-card>
            </v-col> -->
          </v-row>
        </div>
        <div class="pb-10"></div>

        <!-- <AppSubmitFormButton
          @on-click-save="saveChange()"
          @on-click-skip="onClickSkip()"
          v-if="canEdit"
          :is-enable-skip="isEnableSkip"
          :disabled="disabled"
          text="บันทึกข้อมูลรูปภาพโปรไฟล์"
        /> -->
      </CustomCardFormViewModern>
    </v-form>
    <AppDialogConfirm ref="dialogUploadProfileAndFaceAlert" />
  </div>
</template>
<script>
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import AppFilesFaceUpload from "@/components/AppFilesFaceUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { errorMessageMapper } from "@/services/error";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { DialogType } from "@/services/dialog";
import { getFileUrl } from "@/services/api/file";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";

export default {
  name: "FacePictureView",

  props: {
    showFaceImage: {
      default: true,
      type: Boolean,
    },
    profileImage: {
      type: [Object, null],
      default: null,
    },
    faceImages: {
      type: [Array, null],
      default: null,
    },
    userId: {
      type: String,
      default: "",
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isEnableSkip: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AppFilesUpload,
    AppFilesFaceUpload,
    AppDialogConfirm,
    CustomCardFormViewModern,
    AppSubmitFormButton,
  },

  data() {
    return {
      valid: false,
      dragging: false,
      // response to server
      profileImageInput: {
        fileKey: "",
        scaleFileKey: "",
        src: "",
        updatedAt: "",
      },
      profileImageRawSource: null,

      faceFrontImageInput: { fileKey: "", name: "front" },
      // faceLeftImageInput: { fileKey: "", name: "left" },
      // faceRightImageInput: { fileKey: "", name: "right" },

      selectedProfileImageFile: [],
      selectedFaceFrontFile: [],
      // selectedFaceLeftFile: [],
      // selectedFaceRightFile: [],

      disabled: false,

      editDataFrontFaceImage: null,
      editDataLeftFaceImage: null,
      editDataRightFaceImage: null,
      editDataProfile: null,
      frontFaceSrc: null,

      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
    };
  },

  mounted() {
    this.disabled = !this.canEdit;
  },

  watch: {
    profileImage(newValue) {
      if (!_.isNil(newValue)) {
        let checkValue = { ...newValue };
        if (_.isNil(checkValue["fileKey"])) {
          checkValue["fileKey"] = "";
        }

        this.profileImageInput = _.clone(newValue);
        this.editDataProfile = _.clone(newValue);
      }
    },

    // profileImageInput(newValue) {
    //   if (!_.isNil(newValue)) {
    //     this.saveChange();
    //   }
    // },

    async faceImages(newValue) {
      if (!_.isNil(newValue) && newValue.length > 0) {
        for (const [index, value] of newValue.entries()) {
          let checkValue = { ...value };
          if (_.isNil(checkValue["fileKey"])) {
            checkValue["fileKey"] = "";
          }

          switch (index) {
            case 0:
              this.editDataFrontFaceImage = _.clone(checkValue);
              this.faceFrontImageInput = _.clone(checkValue);
              let respFileURL = await getFileUrl(
                this.$store,
                this.faceFrontImageInput.fileKey[0]
              );
              if (respFileURL["code"] === 200) {
                this.frontFaceSrc = _.clone(respFileURL);
              }
              break;
            // case 1:
            //   this.editDataLeftFaceImage = _.clone(checkValue);
            //   this.faceLeftImageInput = _.clone(checkValue);
            //   break;
            // case 2:
            //   this.editDataRightFaceImage = _.clone(checkValue);
            //   this.faceRightImageInput = _.clone(checkValue);
            //   break;
          }
        }
      }
    },

    async selectedProfileImageFile(newFile) {
      if (newFile.length === 0) {
        if (!_.isNil(this.editDataProfile)) {
          this.profileImageInput = _.clone(this.editDataProfile);
        } else {
          this.profileImageInput.fileKey = "";
        }
      } else {
        if (!this.showFaceImage) {
          // keep raw source
          this.profileImageRawSource = newFile[0];
        }
        this.profileImageInput.fileKey = newFile[0]["src"];
        this.saveChange();
      }
    },

    async selectedFaceFrontFile(newFile) {
      if (newFile.length === 0) {
        if (!_.isNil(this.editDataFrontFaceImage)) {
          this.faceFrontImageInput = _.clone(this.editDataFrontFaceImage);
        } else {
          this.faceFrontImageInput.fileKey = "";
        }
      } else {
        this.faceFrontImageInput.fileKey = newFile[0]["src"];
        this.saveFrontFaceChange();
      }
    },

    // async selectedFaceLeftFile(newFile) {
    //   if (newFile.length === 0) {
    //     if (!_.isNil(this.editDataLeftFaceImage)) {
    //       this.faceLeftImageInput = _.clone(this.editDataLeftFaceImage);
    //     } else {
    //       this.faceLeftImageInput.fileKey = "";
    //     }
    //   } else {
    //     this.faceLeftImageInput.fileKey = newFile[0]["src"];
    //   }
    // },

    // async selectedFaceRightFile(newFile) {
    //   if (newFile.length === 0) {
    //     if (!_.isNil(this.editDataRightFaceImage)) {
    //       this.faceRightImageInput = _.clone(this.editDataRightFaceImage);
    //     } else {
    //       this.faceRightImageInput.fileKey = "";
    //     }
    //   } else {
    //     this.faceRightImageInput.fileKey = newFile[0]["src"];
    //   }
    // },
  },

  methods: {
    onClickSkip() {
      this.$emit("on-click-skip");
    },
    async showDialogError(responseData) {
      let text_error = "ไม่สามารถแก้ไขข้อมูลบัญชีได้ ลองใหม่อีกครั้ง";
      text_error = `${text_error} (${
        errorMessageMapper[responseData["message"]]
      })`;

      await this.$refs.dialogUploadProfileAndFaceAlert.open(
        text_error,
        null,
        DialogType.ERROR,
        {
          width: 400,
          zIndex: 200,
          noconfirm: false,
        }
      );
    },
    checkCompleteData() {
      if (this.profileImageInput.fileKey === "") {
        this.profileImageInput.fileKey = "";
        this.$refs.AppFillesUploadRef.$el.scrollIntoView(this.focus);
        return false;
      } else {
        return true;
      }
    },

    async saveChange() {
      this.disabled = true;
      let returnProfileImage = null;
      // var returnFaceImage = {
      //   front: null,
      //   left: null,
      //   right: null,
      // };

      try {
        // if not data
        let resultProfileImageUpload =
          await this.$refs.AppFillesUploadRef.uploadAction();

        if (resultProfileImageUpload.length !== 0) {
          if (resultProfileImageUpload[0]["code"] >= 400) {
            // is error
            throw resultProfileImageUpload[0];
            this.$refs.AppFillesUploadRef.clearInput();
          } else {
            returnProfileImage = resultProfileImageUpload[0].data;
          }
        }

        if (this.showFaceImage) {
          // let resultFaceFrontImageUpload =
          //   await this.$refs.FaceFrontfileUploadRef.uploadAction();
          // if (resultFaceFrontImageUpload.length !== 0) {
          //   if (resultFaceFrontImageUpload[0]["code"] >= 400) {
          //     // is error
          //     throw resultFaceFrontImageUpload[0];
          //     // this.$refs.FaceFrontfileUploadRef.clearInput();
          //   } else {
          //     returnFaceImage.front =
          //       resultFaceFrontImageUpload[0].data.fileKey;
          //   }
          // }
          // let resultFaceLeftImageUpload =
          //   await this.$refs.FaceLeftfileUploadRef.uploadAction();
          // if (resultFaceLeftImageUpload.length !== 0) {
          //   if (resultFaceLeftImageUpload[0]["code"] >= 400) {
          //     // is error
          //     throw resultFaceLeftImageUpload[0];
          //     this.$refs.FaceLeftfileUploadRef.clearInput();
          //   } else {
          //     returnFaceImage.left = resultFaceLeftImageUpload[0].data.fileKey;
          //   }
          // }
          // let resultFaceRightImageUpload =
          //   await this.$refs.FaceRightfileUploadRef.uploadAction();
          // if (resultFaceRightImageUpload.length !== 0) {
          //   if (resultFaceRightImageUpload[0]["code"] >= 400) {
          //     // is error
          //     throw resultFaceRightImageUpload[0];
          //     this.$refs.FaceRightfileUploadRef.clearInput();
          //   } else {
          //     returnFaceImage.right =
          //       resultFaceRightImageUpload[0].data.fileKey;
          //   }
          // }
        } else {
          // set default for profileImage
          this.profileImageInput.src = this.profileImageRawSource["src"];
        }

        let returnData = {
          profileImage: returnProfileImage,
          // faceImage: returnFaceImage,
        };

        this.disabled = false;
        this.$emit("on-click-save", returnData);
      } catch (error) {
        this.showDialogError(error);
        this.disabled = false;
      }
    },

    async saveFrontFaceChange() {
      this.disabled = true;
      // let returnFrontFaceImage = null;
      var returnFaceImage = {
        front: null,
        left: null,
        right: null,
      };

      if (_.isNil(this.userId) || this.userId === "") {
        return;
      }

      try {
        if (!_.isNil(this.userId) && this.userId !== "") {
          let resultFaceFrontImageUpload =
            await this.$refs.FaceFrontfileUploadRef.uploadFaceAction(
              this.userId
            );
          if (resultFaceFrontImageUpload.length !== 0) {
            if (resultFaceFrontImageUpload[0]["code"] >= 400) {
              // is error
              throw resultFaceFrontImageUpload[0];
              // this.$refs.FaceFrontfileUploadRef.clearInput();
            } else {
              returnFaceImage.front =
                resultFaceFrontImageUpload[0].data.faceKeys;
            }
          }

          let returnData = {
            // profileImage: returnProfileImage,
            faceImage: returnFaceImage,
          };

          this.disabled = false;
          this.$emit("on-click-save", returnData);
        }
      } catch (error) {
        this.showDialogError(error);
        this.disabled = false;
      }
    },
  },
};
</script>
<style scoped>
.file-input {
  display: none;
}

.backgroud-image {
  background: #f4f6f6;
}

.v-text-field {
  width: 300px;
}
</style>
