<template>
  <div name="CustomTextInputCurrency" :class="expendMargin ? 'px-3' : ''">
    <InputView
      :title="title"
      :isRequired="isRequired"
      :isDark="isDark"
      :bottomMargin="inputViewBottomMargin"
      :small="small"
    >
      <v-text-field
        ref="input"
        v-currency="options"
        :value="formattedValue"
        :class="
          small
            ? 'app-custom-input custom-placeholer-color-sm'
            : 'app-custom-input custom-placeholer-color'
        "
        :placeholder="placeholder"
        :background-color="getBackgroundColor"
        :disabled="disabled"
        :rules="rules"
        :readonly="readonly"
        :autofocus="autofocusInput"
        @blur="handleUpdateItem($event)"
        @change="onChange"
        @input="onInput"
        :name="dataInputName"
        :dense="dense"
        :label="label"
        :error-count="errorCount"
        :hide-details="hideDetail"
        :error="error"
        :error-messages="errorMessages"
        :loading="loading"
        outlined
      />
    </InputView>
  </div>
</template>

<script>
import InputView from "./InputView.vue";

export default {
  name: "CustomTextInputCurrency",
  components: { InputView },
  components: {
    InputView,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: "text",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    errorCount: {
      type: Number,
      default: 1,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  computed: {
    options() {
      return {
        locale: "us",
        currency: null,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: false,
        valueRange: { min: 0 },
        allowNegative: false,
      };
    },

    autofocusInput: {
      get() {
        return this.autofocus;
      },
      set(val) {},
    },
    getBackgroundColor: function () {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },

  methods: {
    handleUpdateItem(evt) {
      this.$emit("blurHandler", this.dataInputName);
    },
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value);
    },
    onInput(value) {
      this.$emit("input", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
    onChange(value) {
      this.$emit("change", this.$ci.getValue(this.$refs.input));
      this.formattedValue = value;
    },
  },
};
</script>
