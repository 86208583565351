import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

const thailandLocations = [
  { lat: 13.7563, lng: 100.5018 },
  { lat: 7.8804, lng: 98.3923 },
  { lat: 18.7877, lng: 98.9931 },
  { lat: 12.8797, lng: 121.774 },
  { lat: 14.8972, lng: 100.8326 },
  { lat: 8.1091, lng: 98.306 },
  { lat: 13.69, lng: 100.7501 },
  { lat: 9.145, lng: 99.3333 },
  { lat: 16.7754, lng: 100.218 },
  { lat: 7.8804, lng: 99.9826 },
  { lat: 15.87, lng: 100.9925 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 18.7718, lng: 98.9747 },
  { lat: 8.054, lng: 98.9167 },
  { lat: 14.4716, lng: 100.1236 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 7.0086, lng: 100.4747 },
  { lat: 9.1192, lng: 99.3333 },
  { lat: 16.7754, lng: 100.218 },
  { lat: 12.6111, lng: 102.1047 },
  { lat: 14.6345, lng: 100.4972 },
  { lat: 18.7877, lng: 98.9931 },
  { lat: 8.1091, lng: 98.306 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 7.8804, lng: 98.3923 },
  { lat: 18.7877, lng: 98.9931 },
  { lat: 12.8797, lng: 121.774 },
  { lat: 14.8972, lng: 100.8326 },
  { lat: 8.1091, lng: 98.306 },
  { lat: 13.69, lng: 100.7501 },
  { lat: 9.145, lng: 99.3333 },
  { lat: 16.7754, lng: 100.218 },
  { lat: 7.8804, lng: 99.9826 },
  { lat: 15.87, lng: 100.9925 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 18.7718, lng: 98.9747 },
  { lat: 8.054, lng: 98.9167 },
  { lat: 14.4716, lng: 100.1236 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 7.0086, lng: 100.4747 },
  { lat: 9.1192, lng: 99.3333 },
  { lat: 16.7754, lng: 100.218 },
  { lat: 12.6111, lng: 102.1047 },
  { lat: 14.6345, lng: 100.4972 },
  { lat: 18.7877, lng: 98.9931 },
  { lat: 8.1091, lng: 98.306 },
  { lat: 13.7563, lng: 100.5018 },
  { lat: 7.8804, lng: 98.3923 },
  { lat: 18.7877, lng: 98.9931 },
  { lat: 12.8797, lng: 121.774 },
  { lat: 14.8972, lng: 100.8326 },
];

export const jobsMonitoring = {
  namespaced: true,
  state: {
    show: false,
    showLeftPanel: false,
    isMainGoogleMapActive: false,
    //map
    // currentVehicleDotLineList: [],
    currentLatLngCenterBoundList: [],

    // select job info
    isLoadingJobInfo: false,
    loadJobInfo: null,
    activeJobId: [],
    currentRouteInfo: [],
    // vehicle
    focusAtCard: null,
    isCurrentJobToVehicleLocation: false,
    currentJobToVehicleLocation: {},
  },
  getters: {},
  mutations: {
    setMainGMapActive(state, payload) {
      state.isMainGoogleMapActive = payload;
    },
    setShowLeftPanel(state, payload) {
      state.showLeftPanel = payload;
    },
    toggleShowLeftPanel(state) {
      state.showLeftPanel = !state.showLeftPanel;
    },
    //
    // setCurrentVehicleDotLineList(state, payload) {
    //   state.currentVehicleDotLineList = payload;
    // },
    setCurrentLatLngCenterBoundList(state, payload) {
      state.currentLatLngCenterBoundList = payload;
    },
    //
    setActiveJobId(state, payload) {
      state.activeJobId = payload;
    },
    setIsLoadingJobInfo(state, payload) {
      state.isLoadingJobInfo = payload;
    },
    setLoadJobInfo(state, payload) {
      state.loadJobInfo = payload;
    },
    setCurrentRouteInfo(state, payload) {
      state.currentRouteInfo = payload;
    },
    //
    setCurrentJobToVehicleLocation(state, payload) {
      state.currentJobToVehicleLocation = payload;
    },
    setIsCurrentJobToVehicleLocation(state, payload) {
      state.isCurrentJobToVehicleLocation = payload;
    },
    setFocusAtCard(state, payload) {
      state.focusAtCard = payload;
    },
  },
  actions: {
    updateMainGMapActive(context, payload) {
      context.commit("setMainGMapActive", payload);
    },
    updateLeftPanel(context, payload) {
      context.commit("setShowLeftPanel", payload);
    },
    toggleLeftPanel(context) {
      context.commit("toggleShowLeftPanel");
    },
    //
    // updateCurrentVehicleDotLineList(context, payload) {
    //   context.commit("setCurrentVehicleDotLineList", payload);
    // },
    updateCurrentLatLngCenterBoundList(context, payload) {
      context.commit("setCurrentLatLngCenterBoundList", payload);
    },

    //
    updateSelectJobId(context, payload) {
      const { id } = payload;
      if (payload["status"] === "R") {
        const newData = _.filter(
          context.state["activeJobId"],
          (item) => item !== id
        );

        context.commit("setActiveJobId", newData);
      } else {
        // ...context.state["activeJobId"],
        const newData = [id];
        context.commit("setActiveJobId", newData);
      }
    },
    updateCurrentRouteInfo(context, payload) {
      const { data } = payload;

      if (payload["status"] === "R") {
        const newData = _.filter(
          context.state["currentRouteInfo"],
          (item) => item["_id"] !== payload["id"]
        );

        context.commit("setCurrentRouteInfo", newData);
        if (newData.length === 0) {
          context.commit("setLoadJobInfo", null);
        }
      } else {
        // ...context.state["currentRouteInfo"],
        const newData = [data];
        context.commit("setCurrentRouteInfo", newData);
      }
    },
    updateIsLoadingJobInfo(context, payload) {
      context.commit("setIsLoadingJobInfo", payload);
    },

    selectMonitorVehicle(context, payload) {
      if (context.state.isLoadingJobInfo) {
        return;
      }

      if (context.state.activeJobId.includes(payload["id"])) {
        context.dispatch("updateSelectJobId", {
          status: "R",
          id: payload["id"],
        });
        context.dispatch("updateCurrentRouteInfo", {
          status: "R",
          id: payload["id"],
          data: null,
        });

        if (payload["from"] === "VEH") {
          context.commit("setFocusAtCard", null);
        }
      } else {
        context.dispatch(`updateSelectJobId`, {
          status: "A",
          id: payload["id"],
        });

        if (payload["from"] === "VEH") {
          context.commit("setFocusAtCard", payload["id"]);
        }

        context.commit("setLoadJobInfo", payload["id"]);
      }
    },

    //
    updateCurrentJobToVehicleLocation(context, jobDataList) {
      let newData = {};
      context.commit("setIsCurrentJobToVehicleLocation", false);
      //
      for (let i = 0; i < jobDataList.length; i++) {
        newData[jobDataList[i]["_id"]] = {
          vehicle: jobDataList[i]["vehicle"],
          driver: jobDataList[i]["driver"],
          position: null,
        };
      }
      //
      context.commit("setIsCurrentJobToVehicleLocation", true);
      context.commit("setCurrentJobToVehicleLocation", newData);
    },

    updatePositionJobToVehicleLocation(context, newPositionData) {
      let newData = _.cloneDeep(context.state.currentJobToVehicleLocation);

      for (const [key, value] of Object.entries(newPositionData)) {
        newData[key] = {
          ...newData[key],
          position: {
            lat: value["lat"],
            lng: value["lng"],
          },
        };
      }

      context.commit("setCurrentJobToVehicleLocation", newData);

      return newData;
    },

    updateClear(context) {
      context.commit("setMainGMapActive", false);
      context.commit("setCurrentRouteInfo", []);
      context.commit("setActiveJobId", []);
      context.commit("setIsLoadingJobInfo", false);
      context.commit("setCurrentLatLngCenterBoundList", []);
      context.commit("setCurrentJobToVehicleLocation", {});
      context.commit("setIsCurrentJobToVehicleLocation", false);
    },
  },
  modules: {},
};
