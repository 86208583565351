var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isPermissionCanView)?_c('div',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-h5 mt-1"},[_vm._v("รายการใบลางาน")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AppAutoCompleteUserSearching',{key:_vm.componentRefreshCounter,ref:"AppAutoCompleteUserSearchingRef",attrs:{"tableLoading":_vm.tableLoading},on:{"searchItemChange":_vm.getSearchItem}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"nudge-bottom":10,"nudge-left":100,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
                backgroundColor: 'white !important',
              }),attrs:{"color":"#D5DBDB","light":"","large":"","elevation":"0","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"#4D5656"}},[_vm._v(" mdi-filter ")]),_c('div',{staticClass:"normal-button-text"},[_vm._v(_vm._s("ตัวกรอง"))])],1)]}}],null,false,2816123789),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c('TimeOffFilterGroup',{key:_vm.componentRefreshCounter,on:{"close-menu":function($event){_vm.isShowMenu = false},"returnFiltedData":_vm.getfilterItem}})],1)],1)],1),_c('v-data-table',{staticClass:"app-datatable",attrs:{"headers":_vm.tableHeaders,"items":_vm.employeeDataList,"items-per-page":_vm.queryOptionsTable.limit,"page":_vm.queryOptionsTable.page,"loading":_vm.tableLoading,"server-items-length":_vm.serverItemsLength,"footer-props":_vm.dataTableFooterProps,"no-data-text":_vm.dataTableNoDataText,"loading-text":_vm.dataTableLoadingText},on:{"update:page":_vm.tablePageHandler,"update:items-per-page":_vm.tableItemPerPageHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"},[_c('v-card',{staticClass:"d-flex flex-row",attrs:{"flat":"","tile":"","width":"320"}},[_c('v-select',{staticClass:"app-custom-input",attrs:{"items":_vm.sortList,"label":"เรียงลำดับโดย","dense":"","outlined":""},on:{"change":_vm.tableSortHandler},model:{value:(_vm.sortVal),callback:function ($$v) {_vm.sortVal=$$v},expression:"sortVal"}})],1),_c('v-divider',{staticClass:"mx-3 mb-6",attrs:{"vertical":""}}),_c('TimeOffFilterStatus',{key:_vm.componentRefreshCounter,attrs:{"value":_vm.statusFilterValue},on:{"input":_vm.processStatus}}),_c('v-spacer'),_c('NormalButton',{attrs:{"icon":"mdi-refresh"},on:{"on-click":function($event){return _vm.reset()}}},[_vm._v(" รีเซ็ต ")])],1)]},proxy:true},{key:"item.createdDate",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.createdDate, { returnThai: false, type: "datetime", }) || "-")+" ")])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.profileImage === null
                  ? _vm.defaultPFImage
                  : item.profileImage}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title font-weight-bold"},[(_vm.isPermissionCanEdit)?_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.editTimeOffAction(item)}}},[_vm._v(" "+_vm._s(item.dpfullName)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.dpfullName))])]),_c('span',{staticClass:"text-app-normal"},[_vm._v(_vm._s(item.email === null ? "-" : ("" + (item.email))))])],1)],1)]}},{key:"item.phoneNumber",fn:function(ref){
                  var item = ref.item;
return [(item.phoneNumber.length !== 0)?_c('div',_vm._l((item.phoneNumber),function(tel,idx){return _c('div',{key:'phone_row' + idx,staticClass:"d-block"},[_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(tel)+" ")])])}),0):_c('div',[_vm._v("-")])]}},{key:"item.empId",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.empId)+" ")])]}},{key:"item.position",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.position)+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status !== null)?_c('div',{class:item.status == "APPROVED"
              ? ((_vm.empTimeOffStatusTextColor(item.status)) + " text--darken-2")
              : _vm.empTimeOffStatusTextColor(item.status),attrs:{"dark":""}},[_c('span',{staticClass:"text-app-title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.empTimeOffStatusText(item.status))+" ")])]):_c('div',[_vm._v("-")])]}},{key:"item.issueDate",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('span',{staticClass:"text-app-normal"},[_vm._v("เริ่มลา:")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.startDate, { returnThai: false, type: "date", }) || "-")+" ")])]),_c('div',[_c('span',{staticClass:"text-app-normal"},[_vm._v("ลาถึง:")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.endDate, { returnThai: false, type: "date", }) || "-")+" ")])])]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.amount || "-")+" "),(item.amount)?_c('span',[_vm._v("วัน")]):_vm._e(),_vm._v(" "+_vm._s((" " + (item.timeType == "FIRST_HALF" ? "(เช้า)" : "") + " " + (item.timeType == "SECOND_HALF" ? "(บ่าย)" : "") + " "))+" ")])]}},{key:"item.note",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.timeoffType)+" ")]),_c('div',[_c('span',{staticClass:"time-off-note"},[_vm._v(_vm._s(item.note || item.detail ? item.detail + item.note : "-"))])])]}}],null,true)})],1):_vm._e(),_c('EmployeeTimeOffApprovalDialog',{ref:"timeOffApprovalDialog"}),_c('AppPageNotFound',{attrs:{"show":_vm.isPermissionCanView}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }