<template>
  <GoogleMapLoader
    refs="mainMap"
    vuex-status-action-name="updateMainGMapActive"
    :vuex-store-name="storeName"
    :map-config="mapConfig"
    :bound-ploy-line="mainRouteLine"
    :bound-lat-lng-center-list="boundLatLngCenterList"
  >
    <!-- insert your google maps api key to render styled map -->
    <template slot-scope="{ google, map }">
      <!-- All marker -->
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
      <GoogleMapMarker
        v-for="veMarker in vehicleMarker"
        :key="`ve_${veMarker.id}`"
        :marker="veMarker"
        :google="google"
        :map="map"
      />
      <!--  -->

      <!-- Planned Line -->
      <GoogleMapLine
        v-for="settingLine in settingLines"
        :key="settingLine.id"
        :data="settingLine"
        :google="google"
        :map="map"
      />
      <!-- Vehicle Line -->
      <GoogleMapLine
        v-for="vehicleLine in vehicleLines"
        :key="vehicleLine.id"
        :data="vehicleLine"
        :google="google"
        :map="map"
      />
      <!-- OverLimit  -->
      <GoogleMapLine
        v-for="overlimitSpeed in vehicleOverLimitSpeed"
        :key="overlimitSpeed.id"
        :data="overlimitSpeed"
        :google="google"
        :map="map"
      />
      <!-- <GoogleMapMarker
        v-for="volsMarker in vehicleOverLimitSpeedMarkerList"
        :key="`vols_${volsMarker.id}`"
        :marker="volsMarker"
        :google="google"
        :map="map"
      /> -->
    </template>
  </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./googleMapComponents/GoogleMapLoader";
import GoogleMapMarker from "./googleMapComponents/GoogleMapMarker";
import GoogleMapLine from "./googleMapComponents/GoogleMapLine";

export default {
  name: "CoreMap",
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapLine,
  },

  props: {
    storeName: {
      type: String,
      default: "manageRoute",
    },
  },

  data: () => ({
    mapConfig: { center: { lat: 13.736717, lng: 100.523186 } },
  }),

  watch: {
    markers(newValue) {
      if (newValue.length === 0) {
        this.mapConfig = {
          center: { lat: 13.736717, lng: 100.523186 },
          zoom: 15,
        };
      }
    },
  },

  computed: {
    mainRouteLine() {
      return this.$store.state[this.storeName].boundPloyLine || [];
    },
    boundLatLngCenterList() {
      return this.$store.state[this.storeName].boundLatLngCenterList || [];
    },
    settingLines() {
      return this.$store.state[this.storeName].polylineList || [];
    },
    vehicleLines() {
      return this.$store.state[this.storeName].vehiclePolylineList || [];
    },
    vehicleMarker() {
      return this.$store.state[this.storeName].vehicleMarkerLocation || [];

      // return [
      //   {
      //     id: `vehicle_position_${new Date().getTime()}`,
      //     position: {
      //       lat: 13.713546861023683,
      //       lng: 101.09102270970949,
      //     },
      //   },
      // ];
    },
    vehicleOverLimitSpeed() {
      return this.$store.state[this.storeName].vehicleOverLimitSpeedList || [];
    },
    vehicleOverLimitSpeedMarkerList() {
      return (
        this.$store.state[this.storeName].vehicleOverLimitSpeedMarkerList || []
      );
    },
    markers() {
      return this.$store.getters[`${this.storeName}/getAllMarker`];
    },
  },
};
</script>
