<template>
  <!-- <v-btn-toggle v-model="valueInput" dense color="blue accent-2" mandatory>
      <v-btn
        v-for="(value, key) in vehicleFuelStatusObj"
        :value="key"
        :key="key"
      >
        {{ value }}
      </v-btn>
    </v-btn-toggle> -->
  <v-chip-group v-model="valueInput" color="primary" column mandatory>
    <v-chip
      filter
      v-for="(value, key) in _vehicleFuelStatusObj"
      :value="key"
      :color="vehicleFuelStatusColor(key)"
      outlined
      :key="key"
      :disabled="disabled"
    >
      <span class="px-1">{{ value }}</span>
    </v-chip>
  </v-chip-group>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VehicleFuelFilterStatus",
  mounted() {},
  props: {
    value: {},
    showAll: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      vehicleFuelStatusObj: "vehicle/vehicleFuelStatusObj",
      vehicleFuelStatusColor: "vehicle/vehicleFuelStatusColor",
    }),
    _vehicleFuelStatusObj: function () {
      return this.showAll
        ? this.$store.state.vehicle.vehicleFuelStatus
        : this.$store.state.vehicle.vehicleFuelApprovalStatus;
    },
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("on-change", val);
      },
    },
  },
};
</script>

<style scoped></style>
