<template>
	<div name="CustomTextInputImprove" :class="expendMargin ? 'px-3' : ''">
		<InputView
			:title="title"
			:is-required="isRequired"
			:isShowTitle="isShowTitle"
			:bottom-margin="inputViewBottomMargin"
			:small="small"
			:is-dark="isDark"
			:show-required-bullet="showRequiredBullet"
			:isTitleDisabled="isTitleDisabled"
		>
			<v-text-field
				outlined
				:class="
					small
						? 'app-custom-input custom-placeholer-color-sm'
						: 'app-custom-input custom-placeholer-color'
				"
				v-model="valueInput"
				:clearable="clearable"
				:placeholder="placeholder"
				:type="type"
				:background-color="getBackgroundColor"
				:disabled="disabled"
				:rules="rules"
				:readonly="readonly"
				:autofocus="autofocusInput"
				@keypress="filterTextInput"
				@keyup="filterTextReleaseInput"
				@blur="handleUpdateItem($event)"
				:name="dataInputName"
				:dense="dense"
				:label="label"
				:error-count="errorCount"
				:hide-details="hideDetail"
				:error="error"
				:error-messages="errorMessages"
				:loading="loading"
			></v-text-field>
		</InputView>
	</div>
</template>
<script>
	import InputView from "./InputView.vue";

	export default {
		name: "CustomTextInputImprove",
		components: { InputView },
		components: {
			InputView,
		},

		props: {
			value: {},
			placeholder: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			label: {
				type: String,
				default: "",
			},
			isRequired: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			type: {
				type: String,
				default: "input",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			isTitleDisabled: {
				type: Boolean,
				default: false,
			},
			readonly: {
				type: Boolean,
				default: false,
			},
			rules: {
				type: Array,
				default: () => [],
			},
			isShowTitle: {
				type: Boolean,
				default: true,
			},
			inputType: {
				type: String,
				default: "text",
			},
			autofocus: {
				type: Boolean,
				default: false,
			},
			expendMargin: {
				type: Boolean,
				default: true,
			},
			dataInputName: {
				type: String,
				default: "",
			},
			dense: {
				type: Boolean,
				default: true,
			},
			hideDetail: {
				type: Boolean,
				default: false,
			},
			inputViewBottomMargin: {
				type: Boolean,
				default: true,
			},
			small: {
				type: Boolean,
				default: false,
			},
			errorCount: {
				type: Number,
				default: 1,
			},
			error: {
				type: Boolean,
				default: false,
			},
			errorMessages: {
				type: String,
				default: "",
			},
			loading: {
				type: Boolean,
				default: false,
			},
			isDark: {
				type: Boolean,
				default: false,
			},
			showRequiredBullet: {
				type: Boolean,
				default: true,
			},
		},

		computed: {
			valueInput: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				},
			},
			autofocusInput: {
				get() {
					return this.autofocus;
				},
				set(val) {},
			},
			getBackgroundColor: function () {
				if (this.disabled) {
					if (this.isDark) {
						return this.$vuetify.theme.themes.light
							.backgroudDarkDisableInputView;
					} else {
						return this.$vuetify.theme.themes.light.backgroudDisableInputView;
					}
				} else {
					return this.$vuetify.theme.themes.light.backgroundInputView;
				}
			},
		},

		methods: {
			filterTextReleaseInput(evt) {
				evt = evt ? evt : window.event;
				let expect = null;

				if (evt.key !== undefined) {
					expect = evt.target.value.toString() + evt.key.toString();
				} else {
					expect = evt.target.value.toString();
				}

				switch (this.inputType) {
					case "monthYear":
						if (this.valueInput && this.valueInput.length === 2) {
							this.valueInput = this.valueInput.slice(0, 3) + "/";
						}
						if (!/^\d+$/.test(expect) || expect.length > 6) {
							evt.preventDefault();
							return;
						}
						break;
				}
				return true;
			},
			filterTextInput(evt) {
				evt = evt ? evt : window.event;
				let expect = null;

				if (evt.key !== undefined) {
					expect = evt.target.value.toString() + evt.key.toString();
				} else {
					expect = evt.target.value.toString();
				}

				expect = expect.replace(/\//g, "");

				switch (this.inputType) {
					case "numberWithoutSign":
						if (!/^\d+$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "numberWithSign":
						if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "floatNumberWithOutSign":
						if (!/^-?\d+\.?\d*$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "floatNumberWithOutSignTwo":
						if (!/^-?\d+\.?\d*$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "monthYear":
						if (!/^\d+$/.test(expect) || expect.length > 6) {
							evt.preventDefault();
							return;
						}
						break;
					case "enOnly":
						if (!/^[A-Za-z]+$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "thOnly":
						if (!/^[\u0E00-\u0E7F]+$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					case "thOnlyWithSpace":
						if (!/^[\u0E00-\u0E7F ]+$/.test(expect)) {
							evt.preventDefault();
							return;
						}
						break;
					default:
						break;
				}

				return true;
			},
			handleUpdateItem(evt) {
				this.$emit("blurHandler", this.dataInputName);
			},
		},
	};
</script>
<style></style>
