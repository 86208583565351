<template>
  <div class="text-center">
    <v-dialog scrollable width="75%" v-model="show" persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title v-if="report">
            สัญญา: {{ report.contract.contractNumber }}
            {{ report.contract.title }}
          </v-card-title>
          <v-card-title v-else> รายงานแต่ละสัญญา... </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="cancelAndCloseHandler">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-5" v-if="fetchDataError === 0">
          <div class="d-flex justify-space-between">
            <div>
              <p class="text-app-md font-weight-bold mb-0">รายงานแต่ละสัญญา</p>
              <p class="ext-app-detail-md ma-0">
                ข้อมูลล่าสุด:
                {{
                  dateStringToDateFormat(report.date, {
                    returnThai: true,
                  })
                }}
              </p>
            </div>
            <!-- <div class="mr-2">
              <AppAutoCompleteContractInJobsSearching></AppAutoCompleteContractInJobsSearching>
            </div> -->
            <div class="d-flex justify-space-start">
              <div class="mr-2">
                <CustomDatePickerInput
                  data-input-name="reportDate"
                  label="เริ่มต้น"
                  :clearable="false"
                  :value="startDate"
                  @change="startDateHandler"
                  :disabled="!isInputSearching"
                />
              </div>

              <div class="mr-2">
                <CustomDatePickerInput
                  data-input-name="reportDate"
                  label="สิ้นสุด"
                  :clearable="false"
                  :value="endDate"
                  @change="endDateHandler"
                  :disabled="!isInputSearching"
                />
              </div>
            </div>
          </div>

          <ContractIncomeAndExpensesSummary :data="report" />
          <v-row no-gutters>
            <v-col cols="12" class="px-6 pb-6">
              <v-row no-gutters>
                <v-col cols="12" class="pr-9">
                  <ContractIncomeAndExpensesWeekly :data="report" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <JobStatSummary :data="report" :vertical="false" />
          <JobStatDriversAndVehicles :data="report" />
        </v-card-text>

        <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
          ไม่สามารถดึงข้อมูลได้
        </v-card-text>

        <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
          ไม่สามารถบันทึกข้อมูลได้
        </v-card-text>
        <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
          กำลังดึงข้อมูล...
        </v-card-text>
        <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
          กำลังบันทึกข้อมูล...
        </v-card-text>
      </v-card>
      <AppOverlay :isLoading="isLoading" />
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import PrimaryButton from "@/components/PrimaryButton.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import VueApexCharts from "vue-apexcharts";
import {
  appDateYearMonthDayWithEnDashFormat,
  dateStringToDateFormat,
} from "@/services/appDate";
import { getContractInfoReportService } from "@/services/api/report";
import ContractIncomeAndExpensesWeekly from "../contract_dashboard/chart-components/ContractIncomeAndExpensesWeekly.vue";
import ContractIncomeAndExpensesSummary from "../contract_dashboard/chart-components/ContractIncomeAndExpensesSummary.vue";
import JobStatSummary from "../contract_dashboard/chart-components/JobStatSummary.vue";
import JobStatDriversAndVehicles from "../contract_dashboard/chart-components/JobStatDriversAndVehicles.vue";
import CustomDatePickerInput from "@/components/CustomDatePickerInput.vue";
import moment from "moment";
// import AppAutoCompleteContractInJobsSearching from "@/components/AppAutoCompleteContractInJobsSearching.vue";

export default {
  name: "ContractChartReportInfo",
  data: () => ({
    isLoading: false,
    fetchDataError: 1,
    show: false,
    resolve: null,
    reject: null,
    //
    isInputSearching: false,

    contractId: null,
    durationRange: 90,
    startDate: null,
    endDate: null,
    report: null,
    //
    chartOption: null,
    series: [],
  }),
  watch: {},
  computed: {},
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    open(arg) {
      this.resetForm();
      this.contractId = arg.contractId;
      this.startDate = arg.startDate;
      this.endDate = arg.endDate;
      this.isInputSearching = arg.isInputSearching;
      this.setDefaultDate();

      this.show = true;
      this.getData();
      //
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async getData() {
      try {
        this.loading = true;
        const responseData = await getContractInfoReportService(
          this.$store,
          new URLSearchParams({
            id: this.contractId,
            startdate: this.startDate,
            enddate: this.endDate,
          }).toString()
        );
        if (responseData["code"] === 200) {
          this.report = responseData["data"];
          this.fetchDataError = 0;
        } else {
          throw "RESPONSE_DATA_FAIL";
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.fetchDataError = -2;
      }
    },
    setDefaultDate() {
      if (_.isNil(this.startDate)) {
        this.startDate = moment()
          .subtract(this.durationRange, "days")
          .format(appDateYearMonthDayWithEnDashFormat);
      }

      if (_.isNil(this.endDate)) {
        this.endDate = moment().format(appDateYearMonthDayWithEnDashFormat);
      }
      //
    },
    resetForm() {
      this.fetchDataError = 1;
      this.isInputSearching = false;
      this.contractId = null;
      this.startDate = null;
      this.endDate = null;
      this.report = null;
      this.chartOption = null;
      this.series = [];
    },

    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },

    //
    startDateHandler(value) {
      this.fetchDataError = 1;
      this.startDate = value;

      this.getData();
    },
    endDateHandler(value) {
      this.fetchDataError = 1;
      this.endDate = value;

      this.getData();
    },
  },
  components: {
    PrimaryButton,
    AppDialogConfirm,
    AppOverlay,
    apexchart: VueApexCharts,
    ContractIncomeAndExpensesWeekly,
    ContractIncomeAndExpensesSummary,
    JobStatSummary,
    JobStatDriversAndVehicles,
    CustomDatePickerInput,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}
</style>
