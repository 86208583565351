import JobsList from "@/views/jobs/JobsList.vue";
import JobsCreateView from "@/views/jobs/manageView/JobsCreateView.vue";
import JobsEditView from "@/views/jobs/manageView/JobsEditView.vue";
import JobsMonitorPagesView from "@/views/jobs_monitor/JobsMonitorPagesView.vue";
export default [
  {
    path: "/caj/jobs/list",
    name: "jobsList",
    component: JobsList,
    meta: { requiresAuth: true, topic: "job" },
  },
  {
    path: "/caj/jobs/create",
    name: "jobsCreate",
    component: JobsCreateView,
    meta: { requiresAuth: true, topic: "job" },
  },
  {
    path: "/caj/jobs/edit/:id",
    name: "jobsEdit",
    component: JobsEditView,
    meta: { requiresAuth: true, topic: "job" },
  },
  {
    path: "/caj/jobs/monitoring",
    name: "jobsMonitoring",
    component: JobsMonitorPagesView,
    meta: { requiresAuth: true, topic: "jobsMonitorList" },
  },
];
