<template>
  <div class="px-3">
    <InputView
      title="รหัสพนักงาน"
      :isRequired="true"
      :bottomMargin="true"
      :small="false"
    >
      <v-text-field
        outlined
        :class="processStyle"
        v-model="valueInput"
        placeholder=""
        type="text"
        :background-color="getBackgroundColor"
        :disabled="disabled"
        :rules="rules"
        :readonly="readonly"
        :autofocus="autofocusInput"
        @blur="handleUpdateItem($event)"
        @update:error="handlerVaildateError"
        name="empId"
        :dense="dense"
        :label="label"
        :hide-details="false"
        :loading="loading"
        :error="isInputError"
        :success="isInputSuccess"
        :messages="successMessage"
        autocomplete="null"
      ></v-text-field>
    </InputView>
  </div>
</template>
<script>
import InputView from "@/components/InputView.vue";
import { checkUserService } from "@/services/api/user";
import _ from "lodash";

export default {
  name: "EmployeePersonalEmpIdInputComponent",
  components: { InputView },
  components: {
    InputView,
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    handlerProcessingFunc: {
      type: Function,
      default: () => {},
    },
    //
    editAtEmpId: {
      type: [String, null],
      default: null,
    },
  },

  data: () => ({
    loading: false,
    massageMapper: {
      NO_DATA: "ต้องระบุรหัสพนักงาน",
      DUP: "รหัสพนักงานถูกใช้งานแล้ว",
      FAIL: "รูปแบบรหัสพนักงานไม่ถูกต้อง", // Email and Telephone FORMAT FAIL
    },
    rules: [(v) => !!v || "ต้องระบุรหัสพนักงาน"],
    successMessage: "",
    searchCountTimer: undefined,
    //
    isInputError: false,
    isInputSuccess: false,
  }),

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.inputVaildate(val);
      },
    },

    autofocusInput: {
      get() {
        return this.autofocus;
      },
      set(val) {},
    },
    getBackgroundColor: function () {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },

    processStyle: function () {
      if (!this.isInputError && !this.isInputSuccess) {
        return "custom-placeholer-color app-custom-input";
      } else if (this.isInputError) {
        return "custom-placeholer-color  error-outline";
      } else {
        return "custom-placeholer-color success-outline";
      }
    },
  },

  methods: {
    handlerVaildateError(isError) {
      // if true
      if (isError) {
        this.isInputError = true;
      }
    },

    setStatus(errorType) {
      this.successMessage = "";
      this.rules = [];
      switch (errorType) {
        case "NO_DATA":
        case "DUP":
        case "FAIL":
          this.rules = [(v) => this.massageMapper[errorType]];
          this.isInputSuccess = false;
          this.isInputError = true;
          break;
        case "OK":
          this.successMessage = "รหัสพนักงานสามารถใช้งานได้";
          this.isInputSuccess = true;
          this.isInputError = false;
          break;
        default:
          this.isInputSuccess = false;
          this.isInputError = false;
          break;
      }
      this.loading = false;
      this.$emit("handler-processing-func", this.loading);
    },

    inputVaildate(newValue) {
      this.setStatus("DEFAULT");
      this.isInputError = false;
      this.isInputSuccess = false;

      if (_.isNil(newValue)) {
        this.setStatus("NO_DATA");
      } else if (newValue.length === 0) {
        this.setStatus("NO_DATA");
      } else if (this.isEdit && newValue === this.editAtEmpId) {
        this.setStatus("DEFAULT");
      } else {
        clearTimeout(this.searchCountTimer);
        this.loading = true;
        this.$emit("handler-processing-func", this.loading);
        this.searchCountTimer = setTimeout(() => {
          this.callApi(); // call api function
        }, 1250);
      }
    },

    async callApi() {
      try {
        if (this.valueInput.length !== 0) {
          // check again
          if (this.isEdit && this.valueInput === this.editAtEmpId) {
            throw "DEFAULT";
          }

          let uriParams = new URLSearchParams({
            field: "empid",
            value: this.valueInput,
          }).toString();

          const respData = await checkUserService(this.$store, uriParams);

          if (respData["code"] >= 400) {
            this.setStatus(respData["data"]["message"]);
          } else {
            this.setStatus("OK");
          }
        } else {
          this.setStatus("NO_DATA");
        }
      } catch (error) {
        this.setStatus(error);
      }
      this.loading = false;
      this.$emit("handler-processing-func", this.loading);
    },

    handleUpdateItem(evt) {
      this.$emit("blurHandler", this.dataInputName);
    },
  },
};
</script>
<style></style>
