<template>
  <div name="CustomAutocomplete" :class="expendMargin ? 'px-3' : ''">
    <InputView
      :title="title"
      :bottomMargin="inputViewBottomMargin"
      :small="small"
      :isRequired="isRequired"
    >
      <v-autocomplete
        dense
        outlined
        hide-no-data
        :hide-details="hideDetails"
        v-model="valueInput"
        class="app-custom-input custom-placeholer-color"
        :items="items"
        :placeholder="placeholder"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        :rules="_rules"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        :loading="loading"
        :no-data-text="noDataText"
        :readonly="readonly"
        :name="dataInputName"
      >
        <template v-slot:selection="{ item }">
          <div v-if="!isCassieSub">
            <span v-if="item[selectionTextIf]">
              {{ item[selectionTextIf] }}</span
            >
            <span v-else>{{ item[selectionTextElse] }}</span>
            <span class="text-app-normal">
              <span class="text-app-normal" v-if="item.ownedVehicle">
                |
                {{
                  item.ownedVehicle.licenseNumber
                    ? item.ownedVehicle.licenseNumber
                    : ""
                }}

                <span v-if="item.ownedVehicle.type !== null"
                  >: {{ vehicleTypeText(item.ownedVehicle.type) }}</span
                >
                ({{ item.ownedVehicle.seat }} ที่นั่ง)
              </span>
            </span>
          </div>
          <div v-else>
            <div
              v-if="item['licenseNumber'] && item['cassieId']"
              :class="smallText ? `text-select font-weight-medium` : ``"
            >
              <span
                >{{ item["licenseNumber"] }}
                <span class="text-app-detail-normal"
                  >({{ item["cassieId"] }})</span
                ></span
              >
            </div>
            <div v-else>
              <span v-if="item[selectionTextIf]">
                {{ item[selectionTextIf] }}</span
              >
              <span v-else>{{ item[selectionTextElse] }}</span>
            </div>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="!isCassieSub">
            <span
              :class="smallText ? `text-select font-weight-medium` : ``"
              v-if="item[itemTextListTextIf]"
              >{{ item[itemTextListTextIf] }}</span
            >
            <span
              v-else
              :class="smallText ? `text-select font-weight-medium` : ``"
              >{{ item[itemTextListElse] }}</span
            >
            <!-- <div :class="smallText ? `text-select font-weight-medium` : ``">
              <span class="text-app-normal">Drvier 4W</span>
            </div> -->
            <div :class="smallText ? `text-select font-weight-medium` : ``">
              <span class="text-app-normal">{{ item.position }}</span>
              <span class="text-app-normal" v-if="item.ownedVehicle">
                |
                {{
                  item.ownedVehicle.licenseNumber
                    ? item.ownedVehicle.licenseNumber
                    : ""
                }}
                <span v-if="item.ownedVehicle.type !== null">
                  : {{ vehicleTypeText(item.ownedVehicle.type) }}</span
                >
                ({{ item.ownedVehicle.seat }} ที่นั่ง)
              </span>
            </div>
          </div>
          <div v-else>
            <div
              v-if="item['licenseNumber'] && item['cassieId']"
              :class="smallText ? `text-select font-weight-medium` : ``"
            >
              <span
                >{{ item["licenseNumber"] }}
                <span class="text-app-detail-normal"
                  >({{ item["cassieId"] }})</span
                ></span
              >
            </div>
            <div v-else>
              <span
                :class="smallText ? `text-select font-weight-medium` : ``"
                v-if="item[itemTextListTextIf]"
                >{{ item[itemTextListTextIf] }}</span
              >
              <span
                v-else
                :class="smallText ? `text-select font-weight-medium` : ``"
                >{{ item[itemTextListElse] }}</span
              >
            </div>
          </div>
        </template>
      </v-autocomplete>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "CustomAutocomplete",

  components: {
    InputView,
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isCassieSub: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      // <select> text </select>
      type: String,
      default: "value",
    },
    itemValue: {
      // <select value="{{key}}">
      type: String,
      default: "key",
    },
    returnObject: {
      default: true,
      type: Boolean,
    },
    selectionTextIf: {
      default: "nameTh",
      type: String,
    },
    selectionTextElse: {
      default: "nameEn",
      type: String,
    },
    itemTextListTextIf: {
      default: "nameTh",
      type: String,
    },
    itemTextListElse: {
      default: "nameEn",
      type: String,
    },
    noDataText: {
      type: String,
      default: "ไม่มีข้อมูล",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: true,
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      vehicleTypeText: "vehicle/vehicleTypeText",
    }),
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("on-change", val);
      },
    },
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ");
        }
        return r;
      },
      set(val) {},
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>

<style scope>
.theme--light.v-select .v-select__selections {
  color: rgb(86, 82, 77) !important;
  font-size: 14px;
}
.text-select {
  font-size: 13px;
}
</style>
