<template>
  <v-container name="RouteTemplateRecompenseTab">
    <v-card elevation="0" v-if="!isLoading">
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <div class="d-flex flex-column">
              <InputView title="รูปภาพประกอบ (jpg, png)" style="width: 80%">
                <AppFilesUpload
                  placeholder="เพิ่มและกดบันทึก"
                  :multiple="true"
                  :smallChips="false"
                  :allowImageOnly="true"
                  :uploadWithScale="false"
                  v-model="upImages"
                  icon="mdi-image"
                  ref="mulImagesUpload"
                  uploadTag="RouteTemplateIllustration"
                />
              </InputView>
              <p class="red--text" v-if="errorMessage.length > 0">
                {{ errorMessage }}
              </p>
              <PrimaryButton :height="39" @on-click="save()">
                บันทึก
              </PrimaryButton>
            </div>
          </v-col>
          <v-col v-if="upImages.length !== 0 || localUpImages.length !== 0">
            <v-row v-viewer no-gutters>
              <v-col
                v-for="(file, fileIdx) in upImages"
                :key="file.title + '_' + fileIdx"
                class="d-flex child-flex"
                cols="2"
              >
                <div class="image-viewer-container">
                  <img width="100%" :src="file.src" />
                  <v-btn
                    class="image_list_float_left"
                    icon
                    color="grey lighten-1"
                    @click="remove_imagefile(fileIdx)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <!--  -->
              <v-col
                v-for="(file, fileIdx) in localUpImages"
                :key="'localUpImages_' + fileIdx"
                class="d-flex child-flex"
                cols="2"
              >
                <div class="image-viewer-container">
                  <img width="100%" :src="file.src" />
                  <v-btn
                    class="image_list_float_left"
                    icon
                    color="grey lighten-1"
                    @click="remove_localImagefile(fileIdx)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col v-if="errorMessage.length > 0" class="d-flex align-center">
            <p class="red--text">{{ errorMessage }}</p>
          </v-col>
          <v-col cols="3">
            <div class="mt-1">
              <PrimaryButton :height="39" @on-click="save()">
                บันทึก
              </PrimaryButton>
            </div>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
    <div v-else>
      <p class="text-app-detail-large font-weight-bold black--text">
        กำลังทำงาน....
      </p>
    </div>
  </v-container>
</template>

<script>
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import InputView from "@/components/InputView.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import {
  putRouteTemplateIllustration,
  getRouteTemplateIllustration,
} from "@/services/api/contract_job_routes";

export default {
  name: "RouteTempldateIllustrationTab",
  props: {
    mainStore: {
      default: "routeSetting",
      type: String,
    },
  },
  data: () => ({
    isLoading: false,
    errorMessage: "",
    disabled: false,
    localUpImages: [],
    tmpLocalUpImages: [],
    upImages: [], // wait from appFile component
    routeId: null,
  }),

  mounted() {
    const templateData = _.get(
      this.$store,
      `state.${this.mainStore}.templateData`,
      null
    );
    if (templateData !== null) {
      this.processData(templateData);
    }
  },

  watch: {},

  methods: {
    // ==== for app upload ====
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    remove_localImagefile(atLocation) {
      this.localUpImages.splice(atLocation, 1);
    },
    // ===
    processData(newData) {
      this.localUpImages = _.cloneDeep(_.get(newData, "files", []));
      this.tmpLocalUpImages = _.cloneDeep(_.get(newData, "files", []));
      this.routeId = !_.isNil(newData["_id"]) ? newData["_id"] : this.routeId;
    },
    async getData() {
      try {
        const respData = await getRouteTemplateIllustration(
          this.$store,
          `id=${this.routeId}`
        );
        if (respData["code"] !== 200) {
          throw "GET_ILLUSTRATION_FAIL";
        }
        this.processData(respData["data"]);
      } catch (error) {
        this.errorMessage = "ไม่สามารถทำงานได้";
        console.error(error);
      }
      this.isLoading = false;
    },
    async save() {
      this.$store.dispatch("routeSetting/updateMainLoading", true);

      let respMessageImages = [];
      let upImageResult = [];
      //
      try {
        if (this.upImages.length !== 0) {
          let resp_image = await this.$refs.mulImagesUpload.uploadAction();
          for (let i = 0; i < resp_image.length; i++) {
            if (resp_image[i]["error"] === 0) {
              upImageResult.push(resp_image[i]["data"]);
            }
            if (resp_image[i]["code"] !== 200) {
              respMessageImages.push(resp_image[i]["message"]);
              throw "UPLOAD_IMAGE_FAIL";
            }
          }
        }
      } catch (error) {
        this.errorMessage = "ไม่สามารถอัพโหลดได้";
        console.error(error);
        this.upImages = [];
        return;
      }
      this.isLoading = true;
      this.errorMessage = "";
      if (respMessageImages.length === 0) {
        let payload = {
          files: [],
        };

        if (this.localUpImages.length !== this.tmpLocalUpImages.length) {
          payload.files = _.map(
            this.localUpImages,
            _.partialRight(_.pick, ["fileKey"])
          );
        } else {
          payload.files = _.map(
            this.tmpLocalUpImages,
            _.partialRight(_.pick, ["fileKey"])
          );
        }

        if (upImageResult.length !== 0) {
          for (let i = 0; i < upImageResult.length; i++) {
            payload.files.unshift(upImageResult[i]);
          }
        }

        try {
          const responseData = await putRouteTemplateIllustration(
            this.$store,
            `id=${this.routeId}`,
            payload
          );

          if (responseData["code"] !== 200) {
            throw "UPLOAD_ILLUSTRATION_FAIL";
          } else {
            // call get info
            this.getData();
          }
        } catch (error) {
          this.errorMessage = "ไม่สามารถบันทึกได้";
          this.isLoading = false;
          console.error(error);
        }
      }

      this.$store.dispatch(`${this.mainStore}/updateMainLoading`, false);
    },
  },
  components: { AppFilesUpload, InputView, PrimaryButton },
};
</script>

<style lang="scss" scoped></style>
