var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isPermissionCanView)?_c('div',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-h5 mt-1"},[_vm._v("รายการสรุปข้อมูลพฤติกรรมพนักงาน")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AppAutoCompleteUserSearching',{key:_vm.componentRefreshCounter,ref:"AppAutoCompleteUserSearchingRef",attrs:{"tableLoading":_vm.tableLoading},on:{"searchItemChange":_vm.getSearchItem}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"nudge-bottom":10,"nudge-left":100,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
                backgroundColor: 'white !important',
              }),attrs:{"color":"#D5DBDB","light":"","large":"","elevation":"0","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"#4D5656"}},[_vm._v(" mdi-filter ")]),_c('div',{staticClass:"normal-button-text"},[_vm._v(_vm._s("ตัวกรอง"))])],1)]}}],null,false,2816123789),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c('PenaltyFilterGroup',{key:_vm.componentRefreshCounter,on:{"close-menu":function($event){_vm.isShowMenu = false},"returnFiltedData":_vm.getfilterItem}})],1)],1)],1),_c('v-data-table',{staticClass:"app-datatable",attrs:{"headers":_vm.tableHeaders,"items":_vm.employeeDataList,"items-per-page":_vm.queryOptionsTable.limit,"page":_vm.queryOptionsTable.page,"loading":_vm.tableLoading,"server-items-length":_vm.serverItemsLength,"footer-props":_vm.dataTableFooterProps,"no-data-text":_vm.dataTableNoDataText,"loading-text":_vm.dataTableLoadingText},on:{"update:page":_vm.tablePageHandler,"update:items-per-page":_vm.tableItemPerPageHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"},[_c('v-card',{staticClass:"d-flex flex-row",attrs:{"flat":"","tile":"","width":"320"}},[_c('v-select',{staticClass:"app-custom-input",attrs:{"items":_vm.sortList,"label":"สรุปผลประจำปี","dense":"","outlined":""},on:{"change":_vm.tableSortHandler},model:{value:(_vm.sortVal),callback:function ($$v) {_vm.sortVal=$$v},expression:"sortVal"}})],1),_c('v-spacer'),_c('NormalButton',{attrs:{"icon":"mdi-refresh"},on:{"on-click":function($event){return _vm.reset()}}},[_vm._v(" รีเซ็ต ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.profileImage === null
                  ? _vm.defaultPFImage
                  : item.profileImage}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title font-weight-bold"},[(_vm.isPermissionCanEdit)?_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.editPenaltyAction(item)}}},[_vm._v(" "+_vm._s(item.dpfullName)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.dpfullName))])]),_c('span',{staticClass:"text-app-normal"},[_vm._v(_vm._s(item.email === null ? "-" : ("" + (item.email))))])],1)],1)]}},{key:"item.empId",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.empId ? item.empId : "-")+" ")])]}},{key:"item.workingPenalityScore",fn:function(ref){
                  var item = ref.item;
return [(
            item.penaltyReport.stats[0].score !== null &&
            item.penaltyReport.stats[0].score !== undefined
          )?_c('div',[_c('div',{staticClass:"text-app-detail-normal"},[_vm._v(" "+_vm._s(item.penaltyReport.stats[0].counter)+" ครั้ง ")]),_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                item.penaltyReport.stats[0].maxScore -
                  item.penaltyReport.stats[0].score
              )) + "--text font-weight-black")},[(
                  item.penaltyReport.stats[0].maxScore -
                    item.penaltyReport.stats[0].score !==
                  parseInt(
                    item.penaltyReport.stats[0].maxScore -
                      item.penaltyReport.stats[0].score
                  )
                )?_c('span',[_vm._v(" "+_vm._s(( item.penaltyReport.stats[0].maxScore - item.penaltyReport.stats[0].score ).toFixed(2))+" ")]):_c('span',[_vm._v(_vm._s(item.penaltyReport.stats[0].maxScore - item.penaltyReport.stats[0].score))])])])]):_c('span',[_vm._v(" - ")])]}},{key:"item.securityPenalityScore",fn:function(ref){
                var item = ref.item;
return [(
            item.penaltyReport.stats[1].score !== null &&
            item.penaltyReport.stats[1].score !== undefined
          )?_c('div',[_c('div',{staticClass:"text-app-detail-normal"},[_vm._v(" "+_vm._s(item.penaltyReport.stats[1].counter)+" ครั้ง ")]),_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                item.penaltyReport.stats[1].maxScore -
                  item.penaltyReport.stats[1].score
              )) + "--text font-weight-black")},[(
                  item.penaltyReport.stats[1].maxScore -
                    item.penaltyReport.stats[1].score !==
                  parseInt(
                    item.penaltyReport.stats[1].maxScore -
                      item.penaltyReport.stats[1].score
                  )
                )?_c('span',[_vm._v(" "+_vm._s(( item.penaltyReport.stats[1].maxScore - item.penaltyReport.stats[1].score ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.penaltyReport.stats[1].maxScore - item.penaltyReport.stats[1].score)+" ")])])])]):_c('span',[_vm._v(" - ")])]}},{key:"item.hrPenalityScore",fn:function(ref){
                var item = ref.item;
return [(
            item.penaltyReport.stats[2].score !== null &&
            item.penaltyReport.stats[2].score !== undefined
          )?_c('div',[_c('div',{staticClass:"text-app-detail-normal"},[_vm._v(" "+_vm._s(item.penaltyReport.stats[2].counter)+" ครั้ง ")]),_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                item.penaltyReport.stats[2].maxScore -
                  item.penaltyReport.stats[2].score
              )) + "--text font-weight-black")},[(
                  item.penaltyReport.stats[2].maxScore -
                    item.penaltyReport.stats[2].score !==
                  parseInt(
                    item.penaltyReport.stats[2].maxScore -
                      item.penaltyReport.stats[2].score
                  )
                )?_c('span',[_vm._v(" "+_vm._s(( item.penaltyReport.stats[2].maxScore - item.penaltyReport.stats[2].score ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.penaltyReport.stats[2].maxScore - item.penaltyReport.stats[2].score)+" ")])])])]):_c('span',[_vm._v(" - ")])]}},{key:"item.totalPenalityScore",fn:function(ref){
                var item = ref.item;
return [_c('v-row',[_c('v-divider',{staticClass:"mr-3",attrs:{"vertical":""}}),(
              item.penaltyReport.sumScore !== null &&
              item.penaltyReport.sumScore !== undefined
            )?_c('div',[_c('div',{staticClass:"text-app-md"},[_vm._v(" "+_vm._s(_vm.getTotalCounter(item.penaltyReport.stats))+" ครั้ง ")]),_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" คะแนนคงเหลือ")]),(item !== null)?_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                  _vm.totalScore - item.penaltyReport.sumScore
                )) + "--text text-app-detail-large font-weight-black")},[(
                    _vm.totalScore - item.penaltyReport.sumScore !==
                    parseInt(_vm.totalScore - item.penaltyReport.sumScore)
                  )?_c('span',[_vm._v(" "+_vm._s((_vm.totalScore - item.penaltyReport.sumScore).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.totalScore - item.penaltyReport.sumScore)+" ")])]):_vm._e()])]):_c('span',[_vm._v(" - ")])],1)]}},(_vm.isPermissionCanEdit)?{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editPenaltyAction(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}:null],null,true)})],1):_vm._e(),_c('EmployeePenaltyOverviewDetailDialog',{ref:"penaltyOverviewDialog"}),_c('AppPageNotFound',{attrs:{"show":_vm.isPermissionCanView}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }