<template>
  <v-row dense>
    <v-col cols="4">
      <InputView title="สัญญา" :expend-margin="false">
        <v-autocomplete
          :items="contractsItems"
          :loading="isContractLoading"
          :value="contractSelected"
          placeholder="เลือกสัญญา"
          append-icon=""
          item-text="value"
          item-value="key"
          clearable
          return-object
          filled
          dense
          hide-selected
          hide-details
          flat
          height="44"
          outlined
          class="app-custom-input"
          @change="contractChangeHandler"
        />
      </InputView>
    </v-col>
    <v-col>
      <InputView title="งานเดินรถ" :expend-margin="false">
        <v-autocomplete
          :items="jobItems"
          :value="jobSelected"
          placeholder="เลือกงาน"
          append-icon=""
          :loading="isJobAndVehicleLoading"
          clearable
          return-object
          filled
          dense
          hide-selected
          hide-details
          flat
          height="44"
          outlined
          item-text="value"
          item-value="key"
          class="app-custom-input"
          @change="jobChangeHandler"
        />
      </InputView>
    </v-col>
    <v-col>
      <InputView title="รถโดยสาร" :expend-margin="false">
        <v-autocomplete
          :items="vehicleItems"
          :value="vehicleSelected"
          placeholder="เลือกรถโดยสาร"
          :loading="isJobAndVehicleLoading"
          append-icon=""
          item-text="value"
          item-value="key"
          clearable
          return-object
          filled
          dense
          hide-selected
          hide-details
          flat
          height="44"
          outlined
          class="app-custom-input"
          @change="vehicleChangeHandler"
        />
      </InputView>
    </v-col>
  </v-row>
</template>

<script>
import InputView from "@/components/InputView.vue";
import {
  getContractForEmployeePenaltyService,
  getJobVehicleForEmployeePenaltyService,
} from "@/services/api/employee";
export default {
  name: "EmployeePenaltyConJobVehRowForm",
  components: {
    InputView,
  },

  data: () => ({
    contractsItems: [],
    jobItems: [],
    vehicleItems: [],

    contractSelected: null,
    jobSelected: null,
    vehicleSelected: null,

    isContractLoading: false,
    isJobAndVehicleLoading: false,
  }),
  methods: {
    async getContractList() {
      this.isContractLoading = true;
      const responseData = await getContractForEmployeePenaltyService(
        this.$store
      );

      if (responseData["code"] === 200) {
        this.contractsItems = responseData["data"];
      }
      //
      this.isContractLoading = false;
    },
    async getJobAndVehicleList() {
      this.isJobAndVehicleLoading = true;
      //
      const queryStringSearch = new URLSearchParams({
        id: this.contractSelected["key"],
      }).toString();

      const responseData = await getJobVehicleForEmployeePenaltyService(
        this.$store,
        queryStringSearch
      );

      if (responseData["code"] === 200) {
        this.jobItems = responseData["data"];
      }

      this.isJobAndVehicleLoading = false;
    },
    //
    contractChangeHandler(newItem) {
      if (newItem !== null) {
        this.contractSelected = newItem;
        this.getJobAndVehicleList();
      } else {
        this.contractSelected = null;
        this.jobItems = [];
        this.jobSelected = null;
        this.vehicleItems = [];
        this.vehicleSelected = null;
      }

      this.returnDataHandler();
    },
    jobChangeHandler(newItem) {
      if (newItem !== null) {
        this.jobSelected = newItem;
        this.vehicleSelected = null;
        this.vehicleItems = newItem["vehicles"];
      } else {
        this.jobSelected = null;
        this.vehicleItems = [];
        this.vehicleSelected = null;
      }

      this.returnDataHandler();
    },
    vehicleChangeHandler(newItem) {
      if (newItem !== null) {
        this.vehicleSelected = newItem;
      } else {
        this.vehicleSelected = null;
      }
      this.returnDataHandler();
    },
    //
    returnDataHandler() {
      this.$emit("change-data", {
        contractId: _.get(this.contractSelected, "key", null),
        jobId: _.get(this.jobSelected, "key", null),
        confirmedVehicleId: _.get(this.vehicleSelected, "key", null),
      });
    },
  },

  mounted() {
    this.getContractList();
  },
};
</script>
