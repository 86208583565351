<template>
  <div name="TimeOffFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

      <div class="pa-6">
        <v-row dense>
          <v-col cols="12">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เลือกช่วงเวลาการลา"
                v-model="formData.timeoffDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense> </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="6">
            <div>
              <InputView title="ประเภทการลา" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.empTimeOffType"
                  dense
                  color="primary"
                  mandatory
                >
                  <v-btn v-for="(status, idx) in empTimeOffType" :key="idx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import { getAppDataService } from "@/services/api/app";
import { getContractTitleListService } from "@/services/api/contract";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";

export default {
  name: "TimeOffFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    InputView,
    CustomTextInputImprove,
    CustomFilterDateTypingPickerRange,
  },

  mounted() {
    // this.getData();
    // set from query
    this.getParamQuery();
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        timeoffType: "empTimeOffType",
        ///
        // pos: "position",
        // zone: "zone",
        // contractnumber: "contractNumber",
        //
        startDate: "timeoffDate",
        endDate: "timeoffDate",
      },
      formData: {
        empTimeOffType: null,
        // position: null,
        // contractNumber: null,
        // zone: null,
        timeoffDate: [], // for date
      },
      copyFormData: null,
      // from api
      isLoading: false,
      // filterPositionDataList: [],
      // filterZoneDataList: [],
      // filterContractTitleList: [],
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "startDate":
            case "endDate":
              this.formData.timeoffDate.push(this.$route.query[key]);
              break;
            case "timeoffType":
              this.formData.empTimeOffType = this.$store.getters[
                "employee/empTimeOffTypeList"
              ].indexOf(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      this.isLoading = true;
      // this.filterZoneDataList = await getAppDataService(
      //   this.$store,
      //   "zone&filter=1"
      // );
      // this.filterPositionDataList = await getAppDataService(
      //   this.$store,
      //   "position&filter=1"
      // );

      // try {
      //   let resp = await getContractTitleListService(this.$store);
      //   if (resp.code === 200) {
      //     this.filterContractTitleList = resp.data;
      //   }
      // } catch (error) {
      //   //
      // }
      this.isLoading = false;
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "timeoffDate":
              if (this.formData.timeoffDate.length === 1) {
                filterParams["startDate"] = this.formData.timeoffDate[0];
              } else if (this.formData.timeoffDate.length === 2) {
                filterParams["startDate"] = this.formData.timeoffDate[0];
                filterParams["endDate"] = this.formData.timeoffDate[1];
              }
              break;
            case "empTimeOffType":
              filterParams[key] =
                this.empTimeOffType[this.formData[this.queryOrder[key]]];
              if (filterParams[key] == "ทั้งหมด") {
                delete filterParams[key];
              }
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      // console.log("filterParams", filterParams);
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      // clear all
      this.copyFormData = _.cloneDeep(this.formData);

      this.formData = {
        empTimeOffType: null,
        // position: null,
        // contractNumber: null,
        // zone: null,
        timeoffDate: [], // for date
      };
    },
  },
  computed: {
    empTimeOffType() {
      return this.$store.getters["employee/empTimeOffTypeList"] || [];
    },
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
