<template>
  <v-combobox
    class="app-custom-input"
    v-model="valueInput"
    :items="itemList"
    :loading="loading"
    :search-input.sync="searching"
    :label="label"
    :placeholder="placeholder"
    filled
    :hide-no-data="false"
    @keyup="enterToReturnEmit"
    solo
    flat
    hide-details
    chips
    outlined
    return-object
    multiple
    item-value="_id"
    item-text="contractNumber"
    :disabled="disabled"
    :rules="rules"
  >
    <!-- <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            ไม่มีข้อมูล "<strong>{{ searching }}</strong
            >"
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template> -->
  </v-combobox>

  <!-- @keyup="enterToReturnEmit" -->
  <!-- prepend-inner-icon="mdi-magnify" -->
  <!-- :item-value="itemValueKey" -->
  <!--  item-text="value" -->
</template>

<script>
import {
  getContractsService,
  getContractBySeachingService,
} from "@/services/api/contract";

import _ from "lodash";

export default {
  name: "PermissionContractElement",
  components: {},

  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    itemList: [],
    // itemList: [],
    loading: false,
    searching: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryList: [],
    hideText: true,
  }),
  watch: {
    searching(val) {
      if (!_.isNil(val) && _.isString(val)) {
        this.currentSearchText = val;
        clearTimeout(this.searchCountTimer);
        this.loading = true;
        this.searchCountTimer = setTimeout(() => {
          this.callApi(val); // call api function
        }, 1500);
      } else {
        clearTimeout(this.searchCountTimer);
        this.loading = true;
        this.searchCountTimer = setTimeout(() => {
          this.callApi(""); // call api function
        }, 1500);
        // this.hideText = true;
      }
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async callApi(val) {
      let resp_data = await getContractBySeachingService(
        this.$store,
        null,
        "list"
      );

      if (resp_data["code"] === 200) {
        let contractList = resp_data["data"];
        this.itemList = contractList.map(function (item) {
          return { _id: item.key, contractNumber: item.value };
        });
        this.hideText = false;
      } else {
        this.itemList = [];
        this.hideText = true;
      }

      // if (!_.isNil(val)) {
      //   if (!this.itemList.includes(val)) {
      //     this.hideText = false;
      //   } else {
      //     this.hideText = true;
      //   }
      // }

      this.loading = false;
    },

    // enterToReturnEmit(event) {
    //   if (event.code == "Enter") {
    //     if (!_.isNil(this.value)) {
    //       this.value.pop();
    //       // let addItem = this.value[this.value.length - 1];
    //       // if (!this.itemList.includes(addItem)) {
    //       //   let newItem = { name: addItem };
    //       //   this.itemList.push(newItem);
    //       // }
    //     }
    //     clearTimeout(this.searchCountTimer);
    //     this.loading = false;
    //     this.hideText = true;
    //     // this.itemList = [];
    //     return;
    //   } else if (event.code == "Backspace") {
    //     this.hideText = true;
    //   }
    // },
    enterToReturnEmit(event) {
      if (event.code == "Enter") {
        if (this.currentSearchText !== "") {
          this.value.pop();
        }
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        // this.itemList = [];
        return;
      } else if (event.code == "Backspace") {
        this.hideText = true;
      }
    },
  },

  mounted() {
    this.callApi("");
  },
};
</script>

<style scope></style>
