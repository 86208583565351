<template>
  <div v-if="series.length > 0">
    <apexchart
      type="bar"
      :height="getChartHeight"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
  <v-card-text class="pa-5 text-center" v-else>
    <span v-if="isItemChange">กำลังตรวจสอบรายชื่อ...</span>
    <span v-else>ไม่มีรายชื่อผู้ขับ...</span>
  </v-card-text>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";

export default {
  name: "CurrentDriverStatusChart",
  components: { apexchart: VueApexCharts },
  props: {
    cellData: {
      type: Object,
      default: () => {},
    },
    refreshKey: {
      type: Number,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    sheetTableData: {
      type: [Object, null],
      default: () => {},
    },
    selectedDriverList: {
      type: Array,
      default: () => [],
    },
    overlapJob: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    numUniqueRows: 0,
    barHeight: 15,
    categoryHeight: 40,
    initChartHeight: 130,
    maxXaxis: 0,
    seriesGraph: [],
    isItemChange: false,
    nextCellTitle: "",
    lastPriorityDriverName: null,
  }),
  mounted() {
    this.itemChange();
  },
  watch: {
    refreshKey: function (newValue) {
      this.itemChange();
    },
  },
  computed: {
    ...mapGetters({
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    series: {
      get() {
        return this.seriesGraph;
      },
      set(val) {
        this.seriesGraph = val;
      },
    },
    colsDriversPrev() {
      const drivers = _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.sheetTableData["row"]}C${
          this.sheetTableData["col"] - 1
        }.drivers`,
        []
      );
      return drivers;
    },
    colsDriversNext() {
      const drivers = _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.sheetTableData["row"]}C${
          this.sheetTableData["col"] + 1
        }.drivers`,
        []
      );
      return drivers;
    },
    getChartHeight() {
      this.numUniqueRows = 0;
      this.numUniqueRows = this.jobs.length;
      if (!_.isNil(this.numUniqueRows)) {
        if (this.numUniqueRows === 1) {
          return this.initChartHeight * 2;
        }
        let height =
          this.numUniqueRows * this.categoryHeight + this.initChartHeight;
        return height;
      }
      return this.initChartHeight * 2;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          offsetX: 10,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          events: {
            click: (event, chartContext, config) => {
              try {
                let isAdded =
                  config.config.series[config.seriesIndex]["isAdded"][
                    config.dataPointIndex
                  ];

                if (!isAdded) {
                  let driverId =
                    config.config.series[config.seriesIndex]["_id"][
                      config.dataPointIndex
                    ];
                  this.itemChangeGraph(driverId);
                }
              } catch (error) {}
            },
          },
        },
        fill: {
          colors: [
            function ({ value, seriesIndex, dataPointIndex, w }) {
              let driverColor =
                w.config.series[seriesIndex]["fillColor"][dataPointIndex];
              if (value < 0) {
                return driverColor[0];
              }
              return driverColor[1];
            },
          ],
          // type: "pattern",
          // opacity: 1,
          // pattern: {
          //   style: "slantedLines",
          // },
        },
        // colors: ["#ed5555", "#567DF4"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: this.barHeight,
          },
        },
        grid: {
          show: false,
        },

        dataLabels: {
          enabled: true,
          offsetY: -7,
          // offsetX: -100,
          // textAnchor: "middle",
          formatter: (val, opts) => {
            let driverName = "";
            if (val >= 0) {
              try {
                driverName =
                  opts.w.config.series[opts.seriesIndex]["drivers"][
                    opts.dataPointIndex
                  ];
                let driverJobs =
                  opts.w.config.series[1]["data"][opts.dataPointIndex] - 1;

                let driverFree =
                  Math.abs(
                    opts.w.config.series[0]["data"][opts.dataPointIndex]
                  ) - 1;

                const onCartDriver = _.get(
                  this.$store.state["assignMultipleJob"],
                  `driverInCart.${this.tableId}`,
                  null
                );

                const driver = _.find(onCartDriver, {
                  fullName: driverName,
                });

                if (!_.isNil(driver)) {
                  return this.checkUserCellHasOverlap(driver["_id"])
                    ? `[!!!] ${driverName} ${driver.driverCellStat.jobs} ${driver.driverCellStat.free}`
                    : `${driverName} | วิ่ง ${driverJobs}[${driver.driverCellStat.jobs}] ฟรี ${driverFree}[${driver.driverCellStat.free}]`;
                }
                //
              } catch (error) {
                console.error(error);
              }
            }
            return driverName;
          },
          style: {
            colors: [
              (opts) => {
                try {
                  let driverName =
                    opts.w.config.series[opts.seriesIndex]["drivers"][
                      opts.dataPointIndex
                    ];

                  const onCartDriver = _.get(
                    this.$store.state["assignMultipleJob"],
                    `driverInCart.${this.tableId}`,
                    null
                  );

                  const driver = _.find(onCartDriver, {
                    fullName: driverName,
                  });

                  if (!_.isNil(driver)) {
                    return this.checkUserJobHasOverlap(driver["_id"])
                      ? "#EF5350"
                      : "#4d5656";
                  }
                } catch (error) {}
                return "#4d5656";
              },
            ],
            fontSize: "16px",
          },
        },
        yaxis: {
          min: -this.maxXaxis,
          max: this.maxXaxis,
          // min: Math.min(...this.jobFree),
          // max: Math.max(...this.jobWork),
          labels: {
            show: true,
            formatter: (value) => {
              return "";
            },
          },

          // title: {
          //   text: "Age",
          // },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val;
            },
          },
          y: {
            formatter: function (val) {
              if (val < 0) {
                return Math.abs(val + 1);
              }
              return Math.abs(val - 1);
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          offsetY: 15,
          markers: {
            fillColors: ["#ed5555", "#567DF4"],
          },
        },
        title: {
          text: "",
        },
        // legend: {
        //   position: "bottom",
        // },
        xaxis: {
          categories: this.categoryName,
          position: "top",
          labels: {
            offsetX: 0,
            offsetY: 5,
            formatter: function (val) {
              // return val;
              let posVal = Math.abs(Math.round(val));
              if (posVal > 0) {
                return posVal - 1;
              }
              return 0;
            },
          },
          // axisBorder: {
          //   show: true,
          //   // color: '#78909C',
          //   height: 1,
          //   width: "100%",
          //   offsetX: 0,
          //   offsetY: 0,
          // },
          // axisTicks: {
          //   show: true,
          //   borderType: "solid",
          //   // color: "#78909C",
          //   height: 6,
          //   offsetX: 0,
          //   offsetY: 0,
          // },
        },
        annotations: {
          yaxis: [
            {
              y: this.lastPriorityDriverName,
              borderColor: "#49c496",
              strokeDashArray: 2,
              offsetX: 0,
              offsetY: 15,
              width: this.lastPriorityDriverName ? "100%" : "0%",
              label: {
                textAnchor: "start",
                position: "left",
                borderColor: "#fff",
                offsetX: 15,
                offsetY: 10,
                style: {
                  fontSize: "15px",
                  color: "#49c496",
                },
                text: this.lastPriorityDriverName ? this.nextCellTitle : "",
              },
            },
          ],
        },
      };
    },
    defaultDriverItems() {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `driverInCart.${this.tableId}`,
        null
      );
    },
    colsData() {
      return _.get(
        this.$store.state,
        `assignMultipleJob.columnsData.${this.tableId}.${this.sheetTableData["col"]}`,
        null
      );
    },
  },
  methods: {
    itemChangeGraph(driverId) {
      let driverAdding = [];
      driverAdding = this.defaultDriverItems.filter(
        (item) => item._id === driverId
      );
      if (driverAdding.length === 1) {
        // TODO:: emit to driver
        this.$emit("update-table-driver", driverAdding[0]);
      }
    },
    itemChange() {
      this.isItemChange = true;
      this.series = [];
      setTimeout(() => {
        this.resetChartData();
        this.isItemChange = false;
      }, 500);
    },
    getNextCellDriverId() {
      let driverId = [];
      if (this.colsData.type === 1) {
        driverId = this.colsDriversNext.map((el) => el._id);
      } else {
        driverId = this.colsDriversPrev.map((el) => el._id);
      }

      return driverId;
    },
    getSeriesData() {
      let preparedSeries = [];
      let priorityDrivers = [];
      let priorityDriverId = this.getNextCellDriverId();

      if (this.jobs.length > 0) {
        let sortedJobs = this.jobs.sort((a, b) => a.work - b.work);
        for (let i = 0; i < priorityDriverId.length; i++) {
          let driver = this.jobs.find((obj) => {
            return obj._id === priorityDriverId[i];
          });
          const jobIndex = sortedJobs.findIndex((obj) => {
            return obj._id === priorityDriverId[i];
          });
          priorityDrivers.push(driver);
          sortedJobs.splice(jobIndex, 1);
        }

        for (let i = 0; i < priorityDrivers.length; i++) {
          sortedJobs.unshift(priorityDrivers[i]);
          if (i === 0) {
            this.lastPriorityDriverName = priorityDrivers[i]["name"];
            this.nextCellTitle =
              this.colsData.type === 2 ? "ผู้ขับขาเข้า" : "ผู้ขับขาออก";
          }
        }

        this.categoryName = sortedJobs.map((a) => a.name);
        this.jobWork = sortedJobs.map((a) => a.work);
        this.jobFree = sortedJobs.map((a) => a.free);

        if (Math.abs(Math.min(...this.jobFree)) < Math.max(...this.jobWork)) {
          this.maxXaxis = Math.max(...this.jobWork) + 1;
        } else {
          this.maxXaxis = Math.abs(Math.min(...this.jobFree)) + 1;
        }

        preparedSeries = [
          {
            name: "ฟรี",
            data: sortedJobs.map((a) => a.free),
            drivers: sortedJobs.map((a) => a.name),
            isAdded: sortedJobs.map((a) => a.isAdded),
            fillColor: sortedJobs.map((a) => a.fillColor),
            _id: sortedJobs.map((a) => a._id),
          },
          {
            name: "วิ่ง",
            data: sortedJobs.map((a) => a.work),
            drivers: sortedJobs.map((a) => a.name),
            isAdded: sortedJobs.map((a) => a.isAdded),
            fillColor: sortedJobs.map((a) => a.fillColor),
            _id: sortedJobs.map((a) => a._id),
          },
        ];
      }
      return preparedSeries;
    },
    resetChartData() {
      this.jobs = [];
      this.defaultDriverItems.forEach((driver) => {
        const isDriverInTable = this.selectedDriverList.includes(driver["_id"]);
        // driver cell stat = in cell
        const onCellWorkCount = driver.driverCellStat.jobs;
        const onCellFreeCount = driver.driverCellStat.free;
        const driverName = `${driver.dpNameTh} (${driver.nickName.th})`;
        const selectedSumDriver = _.get(
          this.sumDriverStatOnSheet,
          driver["_id"],
          {
            free: 0,
            jobs: 0,
          }
        );

        if (isDriverInTable) {
          this.jobs.push({
            work: selectedSumDriver["jobs"] + 1, // sheet
            free: -(selectedSumDriver["free"] + 1), // sheet
            cellWork: onCellWorkCount,
            cellFree: onCellFreeCount,
            name: driverName,
            isAdded: true,
            fillColor: ["#B2C8DF", "#AAB7B8"],
            _id: driver["_id"],
          });
        } else {
          this.jobs.push({
            work: selectedSumDriver["jobs"] + 1, // sheet
            free: -(selectedSumDriver["free"] + 1), // sheet
            cellWork: onCellWorkCount,
            cellFree: onCellFreeCount,
            name: driverName,
            isAdded: false,
            fillColor: ["#ed5555", "#567DF4"],
            _id: driver["_id"],
          });
        }
      });

      this.series = [];
      if (this.jobs.length > 0) {
        this.series = this.getSeriesData();
      }
    },
    checkUserJobHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapJob, `${driverId}`, []);
        const jobInOverlop = _.map(_d, (item) => item["_id"]);
        const _driverInCell = _.filter(
          this.cellData.drivers,
          (item) => item["_id"] === driverId
        );
        // check duplication
        let jobIntersect = [];
        if (_driverInCell.length > 0) {
          jobIntersect = _.intersection(jobInOverlop, [
            _driverInCell[0]["jobId"],
          ]);
        }
        return _d.length > 0 && jobIntersect.length === 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },
    checkUserCellHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapCell, `${driverId}`, []);
        const cellInOverlop = _.map(_d, (item) => item["matchCellId"]);
        // check duplication
        let cellIntersect = [];
        if (cellInOverlop.length > 0) {
          cellIntersect = _.filter(
            cellInOverlop,
            (item) => item !== this.cellData.cellMId
          );
        }
        return cellIntersect.length > 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },
  },
};
</script>
