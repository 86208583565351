<template>
  <v-container>
    <div
      name="jobsDetailOnMapFloat"
      class="mb-5"
      v-if="$store.state.manageRoute.isPermissionCanView"
    >
      <v-form ref="JobDetailForm" v-model="valid">
        <v-col cols="12" class="pa-0 ma-0">
          <v-row no-gutters class="px-3 pb-3">
            <v-col lg="3" sm="3" class="px-3">
              <InputView
                title="สัญญา"
                :is-required="true"
                :bottom-margin="false"
                :small="true"
              >
                <JobContractSelectorInput
                  ref="JobContractSelectorInputRef"
                  @searchItemChange="getSearchContractItem"
                  :disabled="!isPermissionCanEdit"
                  :value="formData.contract"
                />
              </InputView>
            </v-col>
            <v-col lg="3" md="3" class="px-3">
              <JobDynamicRouteInput
                :value="formData.templateRoute"
                :routeItems="currentRouteItems"
                :current-contract="formData.contract"
                @change-route-value="changeRouteHandler"
                :can-edit="isPermissionCanEdit"
              />
            </v-col>
            <v-col lg="6" md="6">
              <CustomTextInputImprove
                ref="jobTitle"
                v-model="formData.name"
                placeholder="ชื่องาน"
                title="ชื่องาน"
                :is-required="true"
                :disabled="!isPermissionCanEdit || !isContractCompleted"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :hide-detail="true"
                :small="true"
                :input-view-bottom-margin="false"
                :loading="nameLoading"
              ></CustomTextInputImprove>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-row no-gutters class="px-3 py-0">
          <v-col cols="12" lg="3" sm="3" class="py-0">
            <CustomDateTimePickerFormInput
              placeholder="เลือก"
              title="เวลายืนยันรับงาน"
              v-model="formData.confirmDate"
              :disabled="!isPermissionCanEdit || !isContractCompleted"
              :is-required="true"
              :hide-detail="true"
              :small="true"
              :input-view-bottom-margin="false"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
              :clearable="false"
            />
          </v-col>
          <v-col cols="12" lg="3" sm="3" class="py-0">
            <CustomDateTimePickerFormInput
              placeholder="เลือก"
              title="เวลาเริ่มงาน"
              v-model="formData.startDate"
              :disabled="!isPermissionCanEdit || !isContractCompleted"
              :is-required="true"
              :hide-detail="true"
              :small="true"
              :minDate="minConfirmDate"
              :input-view-bottom-margin="false"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
              :clearable="false"
              @comfim-update="updateStartDateHandler"
            />
          </v-col>

          <v-col cols="12" lg="3" sm="3" class="pr-1 py-0">
            <CustomDateTimePickerFormInput
              placeholder="เลือก"
              title="เวลาจบงาน"
              v-model="formData.endDate"
              :disabled="!isPermissionCanEdit || !isContractCompleted"
              :is-required="false"
              :minDate="minStartDate"
              :hide-detail="true"
              :small="true"
              :input-view-bottom-margin="false"
            />
          </v-col>
          <v-col cols="12" lg="3" sm="3" class="py-0">
            <p class="title-input-small mb-0 mt-2">เวลาจบงานโดยประมาณ</p>
            <p class="text-app-title mb-1 mt-1">
              {{
                formData.estimateEndDate
                  ? dateStringToDateFormat(formData.estimateEndDate, {
                      type: "datetime",
                      returnThai: false,
                    })
                  : "-"
              }}
            </p>
          </v-col>
        </v-row>
        <v-row class="px-6 py-0 my-0">
          <v-col cols="12" lg="3" sm="3" v-if="isFormEdit">
            <p class="ma-0 title-input-small">ยืนยันจริง</p>
            <p class="mb-1 text-app-title" v-if="formData.actualConfirmDate">
              {{
                dateStringToDateFormat(formData.actualConfirmDate, {
                  type: "datetime",
                  returnThai: false,
                })
              }}
            </p>
            <p v-else class="ma-0 text-detail-bar">-</p>
          </v-col>
          <v-col cols="12" lg="3" sm="3" v-if="isFormEdit">
            <p class="ma-0 title-input-small">เริ่มงานจริง</p>
            <p class="mb-1 text-app-title" v-if="formData.actualStartDate">
              {{
                dateStringToDateFormat(formData.actualStartDate, {
                  type: "datetime",
                  returnThai: false,
                })
              }}
            </p>
            <p v-else class="ma-0 text-detail-bar">-</p>
          </v-col>
          <v-col cols="12" lg="3" sm="3" v-if="isFormEdit">
            <p class="ma-0 title-input-small">จบงานจริง</p>
            <p class="mb-1 text-app-title" v-if="formData.actualEndDate">
              {{
                dateStringToDateFormat(formData.actualEndDate, {
                  type: "datetime",
                  returnThai: false,
                })
              }}
            </p>
            <p v-else class="ma-0 text-detail-bar">-</p>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mt-3"
            color="primary"
            :disabled="!isPermissionCanEdit"
            @click="handleSaveOnClick()"
          >
            บันทึก & ถัดไป
          </v-btn>

          <!-- <v-spacer></v-spacer> -->

          <!-- <v-btn
            label="เปิดกล่องทดสอบ"
            @click="handleRecompenseDialogTesting()"
          ></v-btn> -->
        </v-row>
      </v-form>
    </div>
    <AppDialogConfirm ref="alertJobAdded" />
    <RecompenseFillDialog ref="recompenseFillDialog" />
  </v-container>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";
import DynamicContractElement from "./DynamicContractElement.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import SaveJobsFormButton from "../SaveJobsFormButton.vue";
import { DialogType } from "@/services/dialog";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput.vue";
import AppAutoCompleteContractInJobsSearching from "@/components/AppAutoCompleteContractInJobsSearching.vue";
import _ from "lodash";
import {
  getGeneratedJobNameService,
  postJobsService,
  putJobsService,
} from "@/services/api/jobs";
import { convertToDateTimeForRequestData } from "@/services/appFuncHelpper";
import RecompenseFillDialog from "../dialog/RecompenseFillDialog.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import JobContractSelectorInput from "./JobContractSelectorInput.vue";
import moment from "moment";
import JobDynamicRouteInput from "./JobDynamicRouteInput.vue";

export default {
  name: "JobsDetailOnMapFloat",
  data() {
    return {
      _id: null,
      queryStringSearchList: {},
      contractId: null,
      valid: false,
      isContractCompleted: false,
      minConfirmDate: null,
      minStartDate: null,
      snackbar: false,
      textIsJobAdded: "คุณสามารถ เพิ่มผู้ขับ รถ และข้อมูลการเดินรถได้",
      isMounted: true,
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      contractNumberItems: [],
      nameLoading: false,
      //
      formData: {
        contract: null,
        name: "",
        startDate: null,
        endDate: null,
        confirmDate: null,
        estimateEndDate: null,
        templateRoute: null,
      },
      currentRouteItems: [],
      //
      recomposeFormData: {
        incomePerRound: null,
        recompenseAmount: null,
      },
      isPermissionCanEdit: false,
      isFormEdit: false,
    };
  },
  // mounted() {
  //   this.isMounted = true;
  // },
  watch: {
    "formData.confirmDate": {
      immediate: false,
      handler: function (val) {
        this.checkToSetStartEndDate(val);
      },
    },
    "formData.startDate": {
      immediate: false,
      handler: function (val) {
        this.checkToSetConfirmEndDate(val);
        this.processEstimateEndDate(val);
      },
    },
    "formData.endDate": {
      immediate: false,
      handler: function (val) {
        this.checkStartDateIsEmpty(val);
      },
    },
    "$store.state.manageRoute.jobData": function (newValue) {
      this.processData(newValue);
    },
    "$store.state.manageRoute.isActivateInput": async function (newValue) {
      if (!_.isNil(newValue) && this.$store.state.manageRoute.isEdit === 1) {
        this.isPermissionCanEdit = newValue;
      }
    },
    "$store.state.manageRoute.isPermissionCanEdit": async function (newValue) {
      if (!_.isNil(newValue) && this.$store.state.manageRoute.isEdit === -1) {
        this.isPermissionCanEdit = newValue;
      }
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    processData(jobData) {
      // check data
      if (!_.isNil(jobData)) {
        // Edit mode
        let _formData = _.cloneDeep(jobData);
        this._id = _formData["_id"];
        this.formData = _.cloneDeep(_formData);
        this.isContractCompleted = true;
        this._updateStartDate();
        this._updateEndDate();
        this.isFormEdit = true;
        if (!_.isNil(this.formData["templateRoute"])) {
          this.currentRouteItems = [_.clone(this.formData["templateRoute"])];
        }
      }
    },
    checkCompletedForm() {
      if (this.isMounted) {
        if (!_.isNil(this.formData)) {
          if (
            !_.isNil(this.formData["contract"]) &&
            !_.isNil(this.formData["name"]) &&
            !_.isNil(this.formData["confirmDate"]) &&
            !_.isNil(this.formData["startDate"])
          ) {
            this.$store.dispatch("manageRoute/updateMoveToTab", 1);
            this.isMounted = false;
          }
        }
      }
    },
    // contract list
    getSearchContractItem(searchItem) {
      this.formData["contract"] = searchItem;
      if (!_.isNil(this._id)) {
        // edit mode
        // idle get new data
      } else {
        // insert mode
        this.isContractCompleted = true;
        this.getDefaultJobTitle();
      }
    },

    getDefaultTimeWithCurrentDate(val) {
      if (_.isNil(val)) {
        return null;
      }
      const timeDefault = val.split("T")[1];
      const currentDate = `${moment().format("YYYY-MM-DD")}T${timeDefault}`;
      const _d = moment(currentDate, "YYYY-MM-DDTHH:mm");
      return _d.format("YYYY-MM-DDTHH:mm");
    },

    processEstimateEndDate(startDate) {
      if (
        !_.isNil(this.formData["templateRoute"]) &&
        !_.isNil(this.formData["templateRoute"]["totalDuration"])
      ) {
        const totalDuration = _.clone(
          this.formData["templateRoute"]["totalDuration"]
        );

        let estimateEndDate = moment(startDate)
          .add(totalDuration, "seconds")
          .format("YYYY-MM-DDTHH:mm:ss");

        this.formData["estimateEndDate"] = estimateEndDate;
      }
    },

    getDefaultTime() {
      if (!_.isNil(this.formData["contract"])) {
        if (!_.isNil(this.formData["templateRoute"])) {
          if (
            !_.isNil(this.formData["templateRoute"]["timeLabel"]) &&
            !_.isNil(this.formData["templateRoute"]["timeLabel"]["code"])
          ) {
            this.formData["confirmDate"] = null;
            this.formData["startDate"] = null;
            this.formData["endDate"] = null;
            // this.formData["estimateEndDate"] = null;

            const timeLabel = _.clone(
              this.formData["templateRoute"]["timeLabel"]
            );

            this.formData["confirmDate"] = timeLabel["confirmDate"];
            this.formData["startDate"] = timeLabel["startDate"];
            this.formData["endDate"] = timeLabel["endDate"];
            // this.formData["estimateEndDate"] = timeLabel["estimateEndDate"];
          }

          if (
            !_.isNil(this.formData["templateRoute"]["totalDuration"]) &&
            !_.isNil(this.formData["startDate"])
          ) {
            this.processEstimateEndDate(this.formData["startDate"]);
          }
        }
      }
    },

    async getDefaultJobTitle() {
      this.nameLoading = true;
      if (!_.isNil(this.formData["contract"])) {
        try {
          let queryStringParams = {
            id: this.formData["contract"]["key"],
          };

          if (!_.isNil(this.formData["templateRoute"])) {
            queryStringParams["route"] =
              this.formData["templateRoute"]["value"];
          }

          const responseData = await getGeneratedJobNameService(
            this.$store,
            new URLSearchParams(queryStringParams).toString()
          );

          if (responseData["code"] === 200) {
            this.formData["name"] = responseData["data"]["name"];
            this.recomposeFormData = _.pick(responseData["data"], [
              "incomePerRound",
              "recompenseAmount",
            ]);
          } else {
            // loading: fail
            throw "response error";
          }
        } catch (error) {
          // loading: error
          this.isContractCompleted = false;
        }
      }
      this.nameLoading = false;
    },
    // save form
    pickAttrForRequestData() {
      let _requestData = _.pickBy(this.formData, (_, key) =>
        [
          "contract",
          "name",
          "startDate",
          "endDate",
          "confirmDate",
          "templateRoute",
        ].includes(key)
      );

      if (!_.isNil(_requestData["templateRoute"])) {
        _requestData["templateRoute"] = _requestData["templateRoute"]["value"];
      }

      _requestData["startDate"] = convertToDateTimeForRequestData(
        _requestData["startDate"]
      );
      _requestData["endDate"] = convertToDateTimeForRequestData(
        _requestData["endDate"]
      );
      _requestData["confirmDate"] = convertToDateTimeForRequestData(
        _requestData["confirmDate"]
      );
      return _requestData;
    },
    async handleRecompenseDialogTesting() {
      //** for testing */
      let _recompenseAmount = await this.$refs.recompenseFillDialog.open(
        this.recomposeFormData
      );
      // console.log("recompense amount", _recompenseAmount);
    },
    async handleSaveOnClick() {
      if (this.$refs.JobDetailForm.validate() === true) {
        try {
          this.$store.dispatch("manageRoute/updateMainLoading", true);
          if (_.isNil(this._id)) {
            //
            let _recompenseAmount = null;

            if (_.isNil(this.recomposeFormData["recompenseAmount"])) {
              _recompenseAmount = await this.$refs.recompenseFillDialog.open(
                this.recomposeFormData
              );
            } else {
              _recompenseAmount = this.recomposeFormData;
            }

            if (_.isNil(_recompenseAmount)) {
              this.$store.dispatch("manageRoute/updateMainLoading", false);
              return;
            }

            let requestData = this.pickAttrForRequestData();
            requestData = { ...requestData, ..._recompenseAmount };

            // new job process
            let responseData = await postJobsService(
              this.$store,
              null,
              requestData
            );

            if (responseData["code"] === 200) {
              this._id = responseData.data["jobId"];
              this._openDialogJobAdded();
              this.$store.dispatch("manageRoute/updateMainLoading", false);
              this._setQueryIdToUrl();
            } else {
              // return to job list
              this.$router.push("/caj/jobs/list");
            }
          } else {
            // update
            const requestBodyData = this.pickAttrForRequestData();

            const responseData = await putJobsService(
              this.$store,
              `id=${this._id}`,
              requestBodyData
            );

            if (responseData["code"] === 200) {
              this._updateStartDate();
              this._updateEndDate();

              let selectDataFromResponseData = {
                jobStatusList: responseData["data"]["jobStatusList"],
                estimateEndDate: responseData["data"]["jobEstimateEndDate"],
              };

              const selectDataFromResponseDataKey = [
                "driverId",
                "vehicleId",
                "recompenseAmount",
                "recompenseTotal",
              ];
              for (let i = 0; i < selectDataFromResponseDataKey.length; i++) {
                if (
                  !_.isUndefined(
                    responseData["data"][selectDataFromResponseDataKey[i]]
                  )
                ) {
                  selectDataFromResponseData[selectDataFromResponseDataKey[i]] =
                    responseData["data"][selectDataFromResponseDataKey[i]];
                }
              }

              this.$store.dispatch("manageRoute/updateJobData", {
                ...this.formData,
                ...selectDataFromResponseData,
              });
              //
              if (!_.isUndefined(responseData["data"]["jobReport"])) {
                this.$store.commit(
                  "manageRoute/setJobReportData",
                  responseData["data"]["jobReport"]
                );
              }

              this.$store.commit("manageRoute/setReloadMarker", null);

              this.$store.dispatch("manageRoute/updateMainLoading", false);
            } else {
              // return to job list
              this.$router.push("/caj/jobs/list");
            }
          }
        } catch (error) {
          // return to job list
          console.error(error);
        }
      } else {
        // alert error form
        console.error("FORM_VALIDAE_ERROR");
      }
    },

    async _openDialogJobAdded(_id) {
      await this.$refs.alertJobAdded.open(
        "เพิ่มข้อมูลงานสำเร็จ",
        "คุณสามารถเพิ่มข้อมูล ผู้ขับและรถ และการเดินรถได้",
        DialogType.POSITIVE,
        this.alertOptions
      );
    },
    getDate(newVal) {
      return newVal.split(" ")[0];
    },
    _setQueryIdToUrl() {
      this.$store.commit("manageRoute/setMode", 1);
      this.$router.push(`/caj/jobs/edit/${this._id}`);
    },

    _activateMarker() {
      this.$store.dispatch("manageRoute/updateActivateMarker", true);
    },

    _updateStartDate() {
      this.$store.dispatch(
        "manageRoute/updateStartDate",
        this.formData["startDate"]
      );
    },
    _updateEndDate() {
      this.$store.dispatch(
        "manageRoute/updateEndDate",
        this.formData["endDate"]
      );
    },

    checkToSetStartEndDate(val) {
      if (!_.isNil(val)) {
        this.minConfirmDate = val;
        const _confirmDate = moment(val);
        let _startDate = null;
        let _endDate = null;

        if (!_.isNil(this.formData["startDate"])) {
          _startDate = moment(this.formData["startDate"]);
          if (_startDate < _confirmDate) {
            this.formData["startDate"] = null;
          }
        }

        if (!_.isNil(this.formData["endDate"])) {
          _endDate = moment(this.formData["endDate"]);
          if (_endDate < _confirmDate) {
            this.formData["endDate"] = null;
          }
        }
      }
    },

    checkToSetConfirmEndDate(val) {
      if (!_.isNil(val)) {
        this.minStartDate = val;
        const _startDate = moment(val);
        let _confirmDate = null;
        let _endDate = null;

        if (!_.isNil(this.formData["confirmDate"])) {
          _confirmDate = moment(this.formData["confirmDate"]);
          if (_startDate < _confirmDate) {
            this.formData["confirmDate"] = null;
          }
        }

        if (!_.isNil(this.formData["endDate"])) {
          _endDate = moment(this.formData["endDate"]);
          if (_startDate > _endDate) {
            this.formData["endDate"] = null;
          }
        }
      }
    },

    checkStartDateIsEmpty(val) {
      if (!_.isNil(val)) {
        const _endDate = moment(val);
        let _startDate = null;

        if (!_.isNil(this.formData["startDate"])) {
          _startDate = moment(this.formData["startDate"]);
          if (_endDate < _startDate) {
            this.formData["endDate"] = null;
          }
        } else {
          this.formData["endDate"] = null;
        }
      }
    },

    changeRouteHandler(selectRoute) {
      if (!_.isNil(selectRoute)) {
        this.formData["templateRoute"] = selectRoute;
      } else {
        this.formData["templateRoute"] = null;
      }
      this.getDefaultJobTitle();
      this.getDefaultTime();
      //
      // const _timeLabel = _.get(selectRoute, "timeLabel", null);

      // if (_.isNil(_timeLabel)) {
      this.setDetailtoRouteMarker(selectRoute);
      // }
    },

    setDetailtoRouteMarker(selectRoute) {
      let _st = null;
      const timeLabelStartDate = _.get(
        selectRoute,
        "timeLabel.startDate",
        null
      );
      //
      if (!_.isNil(this.formData["startDate"])) {
        _st = new Date(this.formData["startDate"]).valueOf();
      } else if (!_.isNil(timeLabelStartDate)) {
        _st = new Date(timeLabelStartDate).valueOf();
      }

      this.$store.dispatch("manageRoute/updateEditMarkerRouteAction", {
        routeId: _.get(selectRoute, "value", null),
        startDate: _st,
      });
    },

    updateStartDateHandler(evt) {
      if (!_.isNil(evt)) {
        this.$store.commit(
          "manageRoute/setEditMarkerRouteStartDateUserAction",
          evt
        );
      }
    },
  },
  computed: {},
  components: {
    CustomTextInputImprove,
    InputView,
    DynamicContractElement,
    CustomSelectsImprove,
    SaveJobsFormButton,
    CustomDateTimePickerFormInput,
    AppDialogConfirm,
    AppAutoCompleteContractInJobsSearching,
    RecompenseFillDialog,
    JobContractSelectorInput,
    JobDynamicRouteInput,
  },
};
</script>

<style scoped>
.title-input-small {
  font-size: 12px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
</style>
