<template>
  <v-dialog scrollable v-model="show" width="800" persistent>
    <v-card v-if="show">
      <!-- <v-card-title class="text-h5"> ใบลางาน </v-card-title> -->
      <v-toolbar color="primary" dark>
        <v-card-title> กลุ่มเดินรถ </v-card-title> <v-spacer></v-spacer
        ><v-btn icon @click.native="cancelStatus"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >

      <v-card class="my-6 pb-3 mx-0 px-12" elevation="0">
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12" lg="12" sm="12">
              <CustomTextInputImprove
                ref="groupName"
                v-model="formData.name"
                placeholder="ชื่อกลุ่ม"
                title="ชื่อกลุ่ม"
                :is-required="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อกลุ่ม']"
              ></CustomTextInputImprove>
              <!--  -->
            </v-col></v-row
          ></v-form
        >
      </v-card>
      <v-divider v-if="fetchDataError === 0"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 0"
          color="primary"
          class="px-6"
          :loading="isSubmiting"
          @click="saveChange"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>

    <AppDialogConfirm ref="alertStatus" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { processPermission } from "@/services/permissions";
// import EmployeePenaltyConJobVehRowForm from "./EmployeePenaltyConJobVehRowForm.vue";

export default {
  name: "groupNameDialog",
  data: () => ({
    valid: false,
    show: false,
    resolve: null,
    reject: null,
    formData: {
      name: null,
    },

    //
    isSubmiting: false,
    fetchDataError: 0,
    isPermissionCanEdit: false,
    isPermissionCanViewContract: false,
    isPermissionCanViewJob: false,
    isPermissionCanViewVehicle: false,
    // contract job vehicle
  }),
  watch: {},
  computed: {},
  methods: {
    resetFormData() {},

    setFormData() {},

    open() {
      this.resetFormData();
      this.setFormData();
      this.checkUserPermissions();

      // this.getStat();

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async saveChange() {
      this.isSubmiting = true;
      if (this.$refs.form.validate() === true) {
        this.resolve(this.formData);
        this.show = false;
      }

      this.isSubmiting = false;
    },

    cancelStatus() {
      this.show = false;
      this.resolve(null);
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER_PENALITY_EDIT");

        if (
          this.permList.contract_info_crud ||
          this.permList.contract_info ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewContract = true;
        }

        if (
          this.permList.jobs_info ||
          this.permList.jobs_crud ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewJob = true;
        }
        if (
          this.permList.vehicle_info ||
          this.permList.vehicle_info_crud ||
          this.permList.admin_all
        ) {
          this.isPermissionCanViewVehicle = true;
        }
      }
    },
  },
  components: {
    CustomTextInputImprove,
    AppDialogConfirm,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}

.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
</style>
