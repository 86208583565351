<template>
  <CustomCardFormViewModern
    title="Passenger QR Code"
    :isShowTitle="false"
    :setPaddingToCardText="true"
    align="center"
  >
    <div
      class="qr-container pt-8"
      v-if="contractPassengerQrcodeSrc"
      @click="downloadQr()"
    >
      <div style="opacity: 0; position: absolute; z-index: 0">
        <div ref="qrContainer">
          <qrcode-vue
            :value="contractPassengerQrcodeSrc"
            :size="size"
            level="H"
          />
          <p class="qr-text">
            {{ truncateTextByPixelWidth(contractTitle, 150) }}
          </p>
        </div>
      </div>

      <div
        ref="imageQrContainer"
        id="imageQrContainer"
        style="z-index: 10"
      ></div>
    </div>
    <p class="pb-2">คลิกที่รูปเพื่อดาว์นโหลด</p>
  </CustomCardFormViewModern>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { getContractPassageQRCodeService } from "@/services/api/contract";
import _ from "lodash";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";

export default {
  name: "ContractPassagerEmbededQRCodeView",
  props: {
    contractId: {
      type: String,
      required: true,
    },
    contractNumber: {
      type: [String, null],
      default: null,
    },
    contractPassengerQrcode: {
      type: [String, undefined],
      default: undefined,
    },
    contractTitle: {
      type: [String, undefined],
      default: undefined,
    },
  },

  components: {
    QrcodeVue,
    CustomCardFormViewModern,
  },

  data() {
    return {
      contractPassengerQrcodeSrc: null,
      src: "",
      size: 250,
      isError: false,
      isLoading: false,
    };
  },

  mounted() {
    this.renderAsImage();
  },

  methods: {
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async renderAsImage() {
      try {
        //
        if (!this.contractPassengerQrcode) {
          const queryString = new URLSearchParams({
            id: this.contractId,
          }).toString();

          const responseData = await getContractPassageQRCodeService(
            this.$store,
            queryString
          );

          if (responseData["code"] === 200) {
            this.contractPassengerQrcodeSrc = responseData["data"]["code"];
          } else {
            throw "REQUEDT_FAIL";
          }
        } else {
          this.contractPassengerQrcodeSrc = this.contractPassengerQrcode;
        }

        await this.timeout(2000);

        html2canvas(this.$refs.qrContainer, {
          backgroundColor: "#fff",
        }).then((canvas) => {
          const img = new Image();
          this.src = canvas.toDataURL("image/png");
          img.src = this.src;
          img.style.maxWidth = "100%";

          this.$refs.imageQrContainer.innerHTML = "";
          this.$refs.imageQrContainer.appendChild(img);
        });
      } catch (error) {
        console.error(error);
      }
    },

    truncateTextByPixelWidth(text, maxWidth) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "14px Noto Sans Thai, sans-serif, sans-serif !important";

      if (context.measureText(text).width <= maxWidth) {
        return text;
      }

      let left = 0;
      let right = text.length;
      let mid;

      while (left < right) {
        mid = Math.floor((left + right + 1) / 2);
        const truncated = text.slice(0, mid) + "...";

        if (context.measureText(truncated).width <= maxWidth) {
          left = mid;
        } else {
          right = mid - 1;
        }
      }

      return text.slice(0, left) + "...";
    },

    async downloadQr() {
      const link = document.createElement("a");
      link.download = `${this.contractNumber}-qrcode-with-text.png`;
      link.href = this.src;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
}

.qr-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px 10px;
  font-size: 14px;
  font-weight: 700;
}
</style>
