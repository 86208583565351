import _ from "lodash";

const getPermissionList = (key) => {
  let p = {
    USER: {
      admin_all: false,
      user_list: false,
      user_info: false,
      user_info_fin: false,
      user_info_crud: false,
      user_timeoff: false,
      user_penality: false,
      user_report: false,
      user_total_report: false,
    },
    USER_PENALITY_EDIT: {
      contract_info_crud: false,
      contract_info: false,
      jobs_info: false,
      jobs_crud: false,
      vehicle_info: false,
      vehicle_info_crud: false,
    },
    VEHICLE: {
      admin_all: false,
      vehicle_list: false,
      vehicle_info: false,
      vehicle_crud: false,
      vehicle_report: false,
      vehicle_total_report: false,
    },
    VEHICLE_EXPENSES: {
      admin_all: false,
      vehicle_info: false,
      vehicle_expenses: false,
    },
    VEHICLE_REPARATION: {
      admin_all: false,
      vehicle_info: false,
      vehireparation_list: false,
      vehireparation_info: false,
      vehireparation_crud: false,
      vehireparation_update_status: false,
      vehireparation_close_status: false,
      vehireparation_report: false,
    },
    VEHICLE_FUEL: {
      admin_all: false,
      vehicle_info: false,
      fuelpayment_list: false,
      fuelpayment_update_status: false,
      fuelpayment_report: false,
    },
    CONTRACT: {
      admin_all: false,
      contract_list: false,
      contract_info: false,
      contract_route: false,
      contract_info_crud: false,
      contract_report: false,
      contract_total_report: false,
    },
    JOBS: {
      admin_all: false,
      jobs_list: false,
      jobs_info: false,
      jobs_crud: false,
      jobs_multiple_crud: false,
      jobs_multiple_tracking: false,
      jobs_report: false,
      jobs_total_report: false,
    },
    PAYMENT: {
      payment_list: false,
      payment_report: false,
      payment_crud: false,
      payment_update_status: false,
      payment_update_close_status: false,
      payment_view_total_report: false,
      payment_evidence_crud: false,
    },
    OTHER: {
      other_dashboard_view: false,
    },
  };
  return _.clone(p[key]);
};

export function processPermission(userPermissions, pagePermissionsName) {
  let pagePermissions = getPermissionList(pagePermissionsName);
  if (pagePermissions === undefined) {
    return null;
  }

  for (let i = 0; i < userPermissions.length; i++) {
    pagePermissions[userPermissions[i].toLowerCase()] = true;
  }
  return pagePermissions;
}
