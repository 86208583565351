<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="headline mt-1">ตารางพนักงานและงาน</h1>
        <!-- <v-btn to="/example"> Example view </v-btn>
        <v-btn to="/gps-csv"> Csv Gps View </v-btn> -->
        <!-- <img :src="QRData" /> -->
      </v-col>
    </v-row>
    <!-- <EmployeeJobDialog ref="employeeJobDialogRef" /> -->
  </v-container>
</template>

<script>
// import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";

// import { getPassengerQRCode } from "@/services/api/jobs";

export default {
  name: "EmployeeJobOverview",

  components: {},

  data() {
    return {
      titleList: [],
    };
  },
  watch: {},
  methods: {
    async init() {
      // let fundObj = {
      //   id: null,
      //   titleList: this.titleList,
      // };
      // const empJob = await this.$refs.employeeJobDialogRef.open();
      // console.log("employeeJob:", empJob);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
