<template>
	<div class="d-flex flex-column flex-md-row">
		<CustomCardFormViewModern
			:class="{
				'app-w-100': $vuetify.breakpoint.smAndDown,
				'app-w-70': $vuetify.breakpoint.mdAndUp,
				'mr-md-2': true,
				'mb-3': true,
			}"
			title="รายได้/รายจ่าย"
			:is-show-title="true"
			:is-adding-title-margin="true"
			:fill-height="true"
		>
			<v-row no-gutters>
				<v-col cols="6">
					<div>
						<span class="dashboard-report-keyunit-text">รายได้</span>
					</div>
					<div class="mt-3">
						<span class="dashboard-small font-weight-bold black--text">
							{{ income }}
						</span>
						<span class="text-app-detail-normal"> บาท</span>
					</div>
					<v-divider class="my-6"></v-divider>
					<div>
						<span class="dashboard-report-keyunit-text"> กำไรสุทธิ </span>
					</div>
					<div class="mt-3">
						<span
							:class="[
								'dashboard-small font-weight-bold',
								{ 'success--text': rawNetProfit > 0 },
								{ 'red--text': rawNetProfit < 0 },
							]"
						>
							{{ netProfit }}
						</span>
						<span class="text-app-detail-normal"> บาท</span>
					</div>
				</v-col>

				<v-divider vertical />

				<v-col cols="6" class="pl-4 text-right">
					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text"> รายจ่าย </span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.total }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>

					<v-divider class="my-4"></v-divider>

					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text"> ค่าเชื้อเพลิง </span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.fuelTotalAmount }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>

					<v-divider class="my-4 app-dash-line"></v-divider>

					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text"> ค่างวดรถ </span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.installmentTotalAmount }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>

					<v-divider class="my-4 app-dash-line"></v-divider>

					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text"> ค่าซ่อมบำรุง </span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.eventsTotalAmount }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>

					<v-divider class="my-4 app-dash-line"></v-divider>

					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text"> ค่าตอบแทนผู้ขับ </span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.jobsTotalAmount }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>

					<v-divider class="my-4 app-dash-line"></v-divider>

					<div class="d-flex justify-space-between">
						<span class="dashboard-report-keyunit-text">
							ค่าใช้จ่ายเอกสาร (ประกัน พรบ ภาษี)
						</span>
						<p class="text-app-detail-large font-weight-bold black--text mb-0">
							{{ expenses.docTotalAmount }}
							<span class="text-app-detail-normal"> บาท</span>
						</p>
					</div>
				</v-col>
			</v-row>
		</CustomCardFormViewModern>

		<CustomCardFormViewModern
			:class="{
				'app-w-100': $vuetify.breakpoint.smAndDown,
				'app-w-30': $vuetify.breakpoint.mdAndUp,
				'mb-3': true,
			}"
			title="รายละเอียดการทำงาน"
			:is-show-title="true"
			:is-adding-title-margin="true"
			:fill-height="true"
		>
			<div class="d-flex justify-space-between">
				<span class="dashboard-report-keyunit-text mr-3">จำนวนงาน</span>
				<p class="text-app-detail-large font-weight-bold black--text mb-0">
					{{ countJob }}
					<span class="text-app-detail-normal"> งาน</span>
				</p>
			</div>
			<v-divider class="my-4"></v-divider>
			<div class="d-flex justify-space-between">
				<span class="dashboard-report-keyunit-text mr-3">ระยะทางที่วิ่ง</span>
				<p class="text-app-detail-large font-weight-bold black--text mb-0">
					{{ totalDistance }}
					<span class="text-app-detail-normal"> กม</span>
				</p>
			</div>
			<v-divider class="my-4"></v-divider>
			<div class="d-flex justify-space-between">
				<span class="dashboard-report-keyunit-text mr-3"
					>ไม่ผ่านการตรวจภายใน</span
				>
				<p class="text-app-detail-large font-weight-bold black--text mb-0">
					{{ RejectedInspection || "-" }}
					<span class="text-app-detail-normal"> หัวข้อ</span>
				</p>
			</div>
		</CustomCardFormViewModern>
	</div>
</template>

<script>
	import _ from "lodash";
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import {
		displayNumberLocaleString,
		numberWithCommasHelper,
	} from "@/services/appNumberHelper";
	import { processMetertoKmDistanceCeil } from "@/services/appFuncHelpper";

	export default {
		name: "VehicleInformationSummary",
		props: {
			data: {},
		},
		watch: {
			data: function (newValue) {
				this.processData();
			},
		},
		data() {
			return {
				income: null,
				expenses: {
					total: 0,
					eventsTotalAmount: 0,
					fuelTotalAmount: 0,
					installmentTotalAmount: 0,
					jobsTotalAmount: 0,
					docTotalAmount: 0,
				},
				rawNetProfit: null,
				netProfit: null,
				countJob: 0,
				totalDistance: 0,
				RejectedInspection: null,
			};
		},
		components: { CustomCardFormViewModern },
		mounted() {
			this.processData();
		},
		methods: {
			currencyProcess(value, assignMinus) {
				if (assignMinus) {
					return `-${numberWithCommasHelper(
						displayNumberLocaleString(value, 2)
					)}`;
				}
				return numberWithCommasHelper(displayNumberLocaleString(value, 2));
			},
			processData() {
				if (!_.isNil(this.data)) {
					this.income = this.currencyProcess(
						_.get(this.data, "totalIncome", 0),
						false
					);
					this.rawNetProfit = _.get(this.data, "totalNetProfit", 0);
					this.netProfit = this.currencyProcess(this.rawNetProfit, false);

					this.expenses = {
						total: this.currencyProcess(
							_.get(this.data, "totalExpenses", 0),
							true
						),
						eventsTotalAmount: this.currencyProcess(
							_.get(this.data, "events.totalAmount", 0),
							false
						),
						fuelTotalAmount: this.currencyProcess(
							_.get(this.data, "fuel.totalAmount", 0),
							false
						),
						installmentTotalAmount: this.currencyProcess(
							_.get(this.data, "installment.totalAmount", 0),
							false
						),
						jobsTotalAmount: this.currencyProcess(
							_.get(this.data, "jobs.totalAmount", 0),
							false
						),
						docTotalAmount: this.currencyProcess(
							_.get(this.data, "doc.totalAmount", 0),
							false
						),
					};
					// console.log(this.expenses);
				}

				this.countJob = _.get(this.data, "jobs.totalCount", 0);
				this.totalDistance = this.currencyProcess(
					processMetertoKmDistanceCeil(
						_.get(this.data, "jobs.totalDistance", 0)
					),
					false
				);
			},
		},
		mounted() {
			this.processData();
		},
	};
</script>
<style scope>
	.app-w-100 {
		width: 100%;
	}

	.app-w-70 {
		width: 70%;
	}

	.app-w-30 {
		width: 30%;
	}

	.app-dash-line {
		border-style: dashed;
		border-width: thin;
	}
</style>
