<template>
  <v-card class="main-bg-color rounded-0" elevation="0">
    <div class="d-flex flex-column">
      <div class="d-flex pa-4">
        <!-- <v-combobox
          v-model="searchItem"
          :items="[]"
          :search-input.sync="searchString"
          item-text="value"
          item-value="key"
          class="app-custom-input"
          placeholder="พิมพ์ชื่องานและกด Enter"
          prepend-inner-icon="mdi-magnify"
          append-icon=""
          clearable
          return-object
          filled
          dense
          hide-no-data
          hide-selected
          hide-details
          flat
          outlined
        ></v-combobox> -->

        <v-text-field
          v-model="searchString"
          class="app-custom-input"
          placeholder="พิมพ์ชื่องานและกด Enter"
          prepend-inner-icon="mdi-magnify"
          append-icon=""
          :clearable="false"
          filled
          dense
          hide-selected
          hide-details
          flat
          outlined
          v-on:keyup.enter="enterToSearchHandler"
        >
        </v-text-field>
        <v-btn class="ml-2" icon color="primary" @click="hiddenPanel">
          <v-icon> mdi-menu </v-icon>
        </v-btn>

        <!-- <v-btn icon>
          <v-icon> mdi mdi-filter </v-icon>
        </v-btn> -->
      </div>

      <div style="position: relative">
        <div
          id="job-monitor-card-menu"
          :class="
            showMenuDiv && !itemsLoading
              ? 'job-monitor-card-menu-active'
              : 'job-monitor-card-menu-hidden'
          "
        >
          <div
            id="sub-job-monitor-card-menu"
            class="px-4 py-2 d-flex flex-column"
          >
            <JobsMonitorFilterStatus
              class="mb-2"
              :value="selectedStatus"
              :disabled="false"
              @input="jobMonitorFilterStatusHandler"
            />
            <JobsMonitorFilterEvent
              :value="selectedEvent"
              :disabled="false"
              @input="jobMonitorFilterEventHandler"
            />
          </div>

          <v-divider></v-divider>
        </div>
        <div
          id="job-monitor-card-list"
          ref="jobMonitorCardList"
          :class="['pa-4', { 'card-overlap-h90': showMenuDiv }]"
          @scroll="onScrollJobCardList"
        >
          <!--  @scroll="onScrollJobCardList" -->
          <JobsMonitorJobCard
            v-for="(cardItem, cardIdx) in dataList"
            :key="`jobs-monitor-job-card-${cardIdx}`"
            :item="cardItem"
            class="mb-2"
            :ref="`card-idx-${cardIdx}`"
            @select-item="selectMonitorItemHandler"
          />

          <div>
            <v-pagination
              v-model="queryOptionsTable.page"
              :length="pageDataLength"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
    <AppOverlay :is-loading="itemsLoading" :zIndex="100" />
  </v-card>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import JobsMonitorFilterStatus from "./JobsMonitorFilterStatus.vue";
import JobsMonitorFilterEvent from "./JobsMonitorFilterEvent.vue";
import JobsMonitorJobCard from "./JobsMonitorJobCard.vue";
import {
  getJobMonitorListService,
  getJobMonitorInfoService,
} from "@/services/api/jobs_monitor";
import AppOverlay from "@/components/AppOverlay.vue";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";
import { dateStringToDateFormat } from "@/services/appDate";
export default {
  components: {
    CustomTextInputImprove,
    JobsMonitorFilterStatus,
    JobsMonitorFilterEvent,
    JobsMonitorJobCard,
    AppOverlay,
  },
  data: () => ({
    selectedStatus: [],
    selectedEvent: ["ALL"],
    searchItem: "",
    searchString: "",
    queryOptionsTable: {
      limit: 10,
      order: "desc",
      page: 1,
    },
    querySearchAndFilterList: {},
    shouldContinueLoading: true,
    //
    jobCardOnScrollY: 0,
    showMenuDiv: true,
    //
    itemsLoading: false,
    dataTotal: 0,
    pageDataLength: 0,
    dataList: [],
    storeName: "jobsMonitoring",
  }),
  watch: {
    "$store.state.jobsMonitoring.loadJobInfo": function (newId) {
      if (newId !== null) {
        this.getDataInfo(newId);
      }
    },
    "$store.state.jobsMonitoring.focusAtCard": function (newVal) {
      if (newVal !== null) {
        this.scrollToCard(newVal);
      }
    },
    "queryOptionsTable.page": function (newVal) {
      this.tablePageHandler(newVal);
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    resetSeachAndFilter() {},
    processStringListToArrayList(val) {
      const pVal = appDecodeSingleURIComponentHelper(val);
      if (pVal.indexOf(",") === -1) {
        return [pVal];
      } else {
        return pVal.split(",");
      }
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "status":
                const newStatusList = this.processStringListToArrayList(
                  _queryRoute[key]
                );

                this.selectedStatus = newStatusList;
                this.querySearchAndFilterList[key] = newStatusList;
                break;

              case "event":
                const newEventList = this.processStringListToArrayList(
                  _queryRoute[key]
                );

                this.selectedEvent = newEventList;
                this.querySearchAndFilterList[key] = newEventList;
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
      this.$refs.jobMonitorCardList.scrollTop = 0;
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    searchingAction() {
      this.queryOptionsTable.page = 1;
      this.shouldContinueLoading = true;
    },
    hiddenPanel() {
      this.$store.dispatch("jobsMonitoring/updateLeftPanel", false);
    },
    onScrollJobCardList(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;

      if (this.jobCardOnScrollY < scrollTop) {
        this.showMenuDiv = false;
      } else {
        this.showMenuDiv = true;
      }
      this.jobCardOnScrollY = scrollTop;

      // if (scrollTop - (scrollHeight - offsetHeight) >= 0) {
      //   if (this.shouldContinueLoading) {
      //     this.queryOptionsTable["page"] += 1;
      //     this.getData(true);
      //   }
      // }
    },
    //
    scrollToCard(selectVehId) {
      const cardHeight =
        this.$refs[`jobMonitorCardList`].children[0].offsetHeight; //[5].offsetHeight;
      let atIdx = this.dataList.findIndex(
        (item) => item["_id"] === selectVehId
      );

      if (atIdx > 1 && cardHeight !== undefined) {
        // atIdx += 1;
        this.$refs[`jobMonitorCardList`].scrollTo({
          top: cardHeight * atIdx,
          left: 0,
          behavior: "smooth",
        });
      } else if (atIdx === 0 && cardHeight !== undefined) {
        this.$refs[`jobMonitorCardList`].scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    //
    async getData() {
      this.itemsLoading = true;

      try {
        const queryStr = new URLSearchParams(
          this.querySearchAndFilterList
        ).toString();
        let responseData = await getJobMonitorListService(
          this.$store,
          queryStr
        );
        if (responseData["code"] === 200) {
          const { jobList, total, totalPage } = responseData["data"];
          this.dataTotal = total;
          this.pageDataLength = totalPage;
          this.dataList = jobList;
          // assign to currentJobToVehicleLocation
          this.$store.dispatch(
            "jobsMonitoring/updateCurrentJobToVehicleLocation",
            this.dataList
          );

          // setTimeout(() => {
          //   this.scrollToCard();
          // }, 1000);
        } else {
          throw "FAIL";
        }
      } catch (error) {
        console.error(error);
      }

      this.itemsLoading = false;
    },
    enterToSearchHandler() {
      if (this.searchString.length > 0) {
        this.querySearchAndFilterList["text"] = this.searchString;
        this.querySearchAndFilterList["page"] = 1;
      } else {
        delete this.querySearchAndFilterList["text"];
      }

      this.getData(false);
    },
    async getDataInfo(_id) {
      try {
        this.$store.dispatch(`${this.storeName}/updateIsLoadingJobInfo`, true);
        this.itemsLoading = true;
        let responseData = await getJobMonitorInfoService(
          this.$store,
          new URLSearchParams({
            id: _id,
          }).toString()
        );

        if (responseData["code"] === 200) {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          const strRandomColor = `#${randomColor}`;
          this.$store.dispatch(`${this.storeName}/updateCurrentRouteInfo`, {
            status: "A",
            data: {
              _id: _id,
              markers: responseData["data"],
              rColor: strRandomColor,
            },
          });
        } else {
          throw "FAIL";
        }
      } catch (error) {
        console.error(error);
      }

      this.$store.dispatch(`${this.storeName}/updateIsLoadingJobInfo`, false);
      this.itemsLoading = false;
    },
    async selectMonitorItemHandler(selectItem) {
      this.$store.dispatch(`${this.storeName}/selectMonitorVehicle`, {
        from: "LIST",
        id: selectItem["_id"],
      });
    },
    //
    jobMonitorFilterStatusHandler(selectItem) {
      this.selectedStatus = selectItem;
      // const statusList = appEncodeSingleURIComponentHelper(this.selectedStatus);
      if (this.selectedStatus.length === 0) {
        delete this.querySearchAndFilterList["status"];
      } else {
        this.querySearchAndFilterList["status"] = this.selectedStatus;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    jobMonitorFilterEventHandler(selectItem) {
      this.selectedEvent = selectItem;
      if (this.selectedEvent.length === 0) {
        delete this.querySearchAndFilterList["event"];
      } else {
        this.querySearchAndFilterList["event"] = this.selectedEvent;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
  },

  mounted() {
    //  this.checkUserPermissions();
    this.processRouterQuery(this.$route.query);
  },
};
</script>

<style lang="scss" scoped>
$dpsmall: 500px;

#job-monitor-card-list {
  position: relative;
  height: calc(100vh - 8rem);
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

#job-monitor-card-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

#job-monitor-card-list::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}

/** job-monitor-card-menu  */

#job-monitor-card-menu {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  // transform: translateY(-100%);
  // -webkit-transform: translateY(-100%);

  z-index: 11;
  width: 100%;
  height: 90px;
}

.job-monitor-card-menu-active {
  animation: fadeInOpacity 0.3s forwards;
  -webkit-animation: fadeInOpacity 0.3s forwards;
}

.job-monitor-card-menu-hidden {
  animation: fadeOutOpacity 0.3s forwards;
  -webkit-animation: fadeOutOpacity 0.3s forwards;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.card-overlap-h90 {
  margin-top: 90px;
}

#sub-job-monitor-card-menu {
  overflow-x: auto;
}

#sub-job-monitor-card-menu::-webkit-scrollbar {
  height: 5px;
  background-color: #f1f1f1;
}

#sub-job-monitor-card-menu::-webkit-scrollbar-thumb:horizontal {
  background: #a8a8a8;
  border-radius: 5px;
}
</style>
