<template>
  <v-row dense v-if="data">
    <v-col cols="6">
      <v-card elevation="2" rounded="lg">
        <v-card-title class="px-10">
          <div class="py-3 text-title font-weight-bold black--text">
            จำนวนสัญญารวม
          </div>
          <v-spacer></v-spacer>

          <span class="text-app-detail-normal">
            <span class="dashboard-small font-weight-bold black--text">
              {{ displayNumberLocaleString(data.stat.totalContract, 0) }}
            </span>
            ฉบับ
          </span>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card elevation="2" rounded="lg">
        <v-card-title class="px-10">
          <div class="py-3 text-title font-weight-bold black--text">
            รายได้โดยเฉลี่ยต่อฉบับ
          </div>
          <v-spacer></v-spacer>

          <span class="text-app-detail-normal"
            ><span class="dashboard-small font-weight-bold black--text">
              {{ displayNumberLocaleString(data.stat.totalIncomeAverage, 2) }}
            </span>
            บาท
          </span>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { displayNumberLocaleString } from "@/services/appNumberHelper";
export default {
  name: "ContractStatSummary",
  props: {
    data: {
      required: true,
    },
  },
  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
  },
};
</script>
