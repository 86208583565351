<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div>
          <v-col cols="12" class="py-0 my-0 px-6">
            <span
              v-for="(legend, idx) in seriesPosition"
              :key="idx"
              :style="{ color: `${chartPosOptions.fill.colors[idx]}` }"
              class="text-app-detail-md"
            >
              {{ legend.name }}
              <span
                class="text-app-detail-normal"
                v-if="idx < seriesPosition.length - 1"
              >
                |
              </span>
            </span>
          </v-col>
          <div id="chart" class="pr-6">
            <apexchart
              type="bar"
              height="200"
              :options="chartPosOptions"
              :series="seriesPosition"
            ></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3 ml-6 mr-6" />
    <v-row no-gutters>
      <v-col cols="12">
        <div>
          <v-col cols="12" class="py-0 my-0 px-6">
            <span
              v-for="(legend, idx) in seriesDepartment"
              :key="idx"
              :style="{ color: `${chartDepartOptions.fill.colors[idx]}` }"
              class="text-app-detail-md"
            >
              {{ legend.name }}
              <span
                class="text-app-detail-normal"
                v-if="idx < seriesDepartment.length - 1"
              >
                |
              </span></span
            >
          </v-col>
          <div id="chart" class="pr-6">
            <apexchart
              type="bar"
              height="200"
              :options="chartDepartOptions"
              :series="seriesDepartment"
            ></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { displayNumberLocaleString } from "@/services/appNumberHelper";

export default {
  name: "UserPaymentDepAndPosChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      required: true,
    },
  },
  data: () => {
    return {
      seriesPosition: [],
      seriesDepartment: [],
      categories: [],
      posColor: [],
      depColor: [],
    };
  },
  computed: {
    chartDepartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return displayNumberLocaleString(val, 2) + " บาท";
            },
          },
          x: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
          colors: this.depColor,
        },
        legend: {
          show: false,
        },
      };
    },
    chartPosOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return displayNumberLocaleString(val, 2) + " บาท";
            },
          },
          x: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
          colors: this.posColor,
        },
        legend: {
          show: false,
        },
      };
    },
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
    processData() {
      let driverPosCompareOther = _.get(this.data, "driverPosCompareOther", []);
      let otherPosCompare = _.get(this.data, "otherPosCompare", []);
      driverPosCompareOther = _.reverse(
        _.sortBy(driverPosCompareOther, ["amount"])
      );
      otherPosCompare = _.reverse(_.sortBy(otherPosCompare, ["amount"]));
      //
      // ["#567DF4", "#AAB7B8"]
      let _positionTmp = [];
      for (let i = 0; i < driverPosCompareOther.length; i++) {
        _positionTmp.push({
          name: driverPosCompareOther[i]["name"],
          data: [driverPosCompareOther[i]["amount"]],
        });

        if (i === 0) {
          this.posColor.push("#567DF4");
        } else {
          this.posColor.push("#AAB7B8");
        }
      }
      //
      let _departmentsTmp = [];
      for (let i = 0; i < otherPosCompare.length; i++) {
        _departmentsTmp.push({
          name: otherPosCompare[i]["name"],
          data: [otherPosCompare[i]["amount"]],
        });

        if (i === 0) {
          this.depColor.push("#567DF4");
        } else {
          this.depColor.push("#AAB7B8");
        }
      }

      this.seriesPosition = _positionTmp;
      this.seriesDepartment = _departmentsTmp;
    },
  },
  mounted() {
    this.processData();
  },
};
</script>
