import RouteEditView from "@/views/routes/RouteEditView.vue";
import routeList from "@/views/routes/RouteListView.vue";

export default [
  {
    path: "/caj/routes",
    name: "routesList",
    component: routeList,
    meta: { requiresAuth: true, topic: "routes" },
  },
  {
    path: "/caj/routes/edit/:id",
    name: "routesEdit",
    component: RouteEditView,
    meta: { requiresAuth: true, topic: "routes" },
  },
];
