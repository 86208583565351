<template>
  <div name="EmployeeFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

      <div class="pa-6">
        <v-row dense>
          <v-col cols="12">
            <div>
              <InputView title="เลือกตำแหน่งงาน" :isRequired="false">
                <v-select
                  :items="filterPositionDataList"
                  v-model="formData.position"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="nameEn"
                  outlined
                  rounded
                  dense
                  filled
                  class="app-custom-input"
                  :loading="isLoading"
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <div>
              <InputView title="เลือกโซน" :isRequired="false">
                <v-select
                  :items="filterZoneDataList"
                  v-model="formData.zone"
                  item-value="key"
                  item-text="nameTh"
                  placeholder="เลือก"
                  outlined
                  rounded
                  dense
                  filled
                  class="app-custom-input"
                  :loading="isLoading"
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <div>
              <InputView title="เลือกงานปัจจุบัน (สัญญา)" :isRequired="false">
                <v-select
                  :items="filterContractTitleList"
                  v-model="formData.contractNumber"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="value"
                  outlined
                  rounded
                  dense
                  filled
                  class="app-custom-input"
                  :loading="isLoading"
                ></v-select>
              </InputView></div></v-col
        ></v-row>
        <v-row dense>
          <v-col cols="12">
            <InputView title="ช่วงอายุ" :isRequired="false">
              <v-row>
                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    v-model="formData.minAge"
                    ref="minAge"
                    placeholder=""
                    inputType="numberWithoutSign"
                    dataInputName="searchingMaxAge"
                  />
                </v-col>
                <p class="title-filter pt-5">—</p>

                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    v-model="formData.maxAge"
                    ref="maxAge"
                    placeholder=""
                    inputType="numberWithoutSign"
                    dataInputName="searchingMinAge"
                  />
                </v-col>
              </v-row>
            </InputView>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เลือกช่วงเวลาเริ่มเข้าทำงาน"
                v-model="formData.joinDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="6">
            <div>
              <InputView title="สถานะการทำงาน" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.empStatus"
                  dense
                  color="primary"
                >
                  <v-btn v-for="(status, idx) in empStatus" :key="'emp' + idx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import { getAppDataService } from "@/services/api/app";
import { getContractTitleListService } from "@/services/api/contract";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";

export default {
  name: "EmployeeFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    InputView,
    CustomTextInputImprove,
    CustomFilterDateTypingPickerRange,
  },

  mounted() {
    this.getData();
    // set from query
    this.getParamQuery();
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        vehiclestatus: "vehicleStatus",
        empstatus: "empStatus",
        ///
        minage: "minAge",
        maxage: "maxAge",
        pos: "position",
        zone: "zone",
        // contractnumber: "contractNumber",
        //
        minjoindate: "joinDate",
        maxjoindate: "joinDate",
      },
      formData: {
        vehicleStatus: null,
        empStatus: null,
        minAge: null,
        maxAge: null,
        position: null,
        contractNumber: null,
        zone: null,
        joinDate: [], // for date
      },
      copyFormData: null,
      // from api
      isLoading: false,
      filterPositionDataList: [],
      filterZoneDataList: [],
      filterContractTitleList: [],
      filterAgeRangeDataList: [],
      isWorkingDateRangeOpen: false,
    };
  },

  methods: {
    getAgeRangeList() {
      // not use
      return [
        { key: { minage: 0, maxage: 200 }, value: "ทั้งหมด" },
        { key: { minage: 18, maxage: 30 }, value: "อายุ 18-30 ปี" },
        { key: { minage: 30, maxage: 40 }, value: "อายุ 30-40 ปี" },
        { key: { minage: 40, maxage: 50 }, value: "อายุ 40-50 ปี" },
        { key: { minage: 50, maxage: 60 }, value: "อายุ 50-60 ปี" },
        { key: { minage: 60, maxage: 40 }, value: "อายุ 60+ ปี" },
      ];
    },
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "minjoindate":
            case "maxjoindate":
              this.formData.joinDate.push(this.$route.query[key]);
              break;
            case "empstatus":
              this.formData.empStatus = this.$store.getters[
                "employee/empStatusTextList"
              ].indexOf(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      this.isLoading = true;
      this.filterZoneDataList = await getAppDataService(
        this.$store,
        "zone&filter=1"
      );
      this.filterPositionDataList = await getAppDataService(
        this.$store,
        "position&filter=1"
      );

      try {
        let resp = await getContractTitleListService(this.$store);
        if (resp.code === 200) {
          this.filterContractTitleList = resp.data;
        }
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "joinDate":
              if (this.formData.joinDate.length === 1) {
                filterParams["minjoindate"] = this.formData.joinDate[0];
              } else if (this.formData.joinDate.length === 2) {
                filterParams["minjoindate"] = this.formData.joinDate[0];
                filterParams["maxjoindate"] = this.formData.joinDate[1];
              }
              break;
            // case "vehicleStatus":
            //   filterParams[this.queryOrder[key]] = this.$store.getters[
            //     "employee/vehicleStatusTextInvert"
            //   ](this.formData[key]);
            //   break;
            case "empStatus":
              filterParams[key] = this.$store.getters[
                "employee/empStatusTextInvert"
              ](this.empStatus[this.formData[this.queryOrder[key]]]);
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      // console.log("filterParams", filterParams);
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      // clear all
      this.copyFormData = _.cloneDeep(this.formData);

      this.formData = {
        vehicleStatus: null,
        empStatus: null,
        minAge: null,
        maxAge: null,
        position: null,
        contractNumber: null,
        zone: null,
        joinDate: [], // for date
      };
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusText: "employee/vehicleStatusText",
      empStatusText: "employee/empStatusText",
    }),
    // joinDateRangeText() {
    //   return this.formData.joinDate.sort().join(" - ");
    // },
    empStatus() {
      return this.$store.getters["employee/empStatusList"] || [];
    },
    vehiceStatus() {
      return this.$store.getters["employee/vehicleStatusList"] || [];
    },
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
