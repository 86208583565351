<template>
	<v-combobox
		class="custom-placeholer-color"
		ref="myCombobox"
		:items="itemList"
		:loading="loading"
		:readonly="tableLoading"
		item-value="key_system"
		v-model="modelVal"
		@keyup="enterToReturnEmit"
		placeholder="ค้นหา"
		prepend-inner-icon="mdi-magnify"
		append-icon=""
		item-title="key_user"
		return-object
		outlined
		dense
		hide-no-data
		hide-details
		flat
		:background-color="getBackgroundColor()"
		:dark="isDark"
		:disabled="disabled"
	>
	</v-combobox>
	<!-- hide-no-data -->
	<!--     auto-select-first -->
	<!-- @keyup="enterToReturnEmit" -->
	<!-- @keydown.enter.prevent="blurCombobox" -->
	<!-- @change="itemChange" -->

	<!-- :search-input.sync="searchingAction" -->
</template>
<script>
	// import { getEmployeeBySeachingService } from "@/services/api/employee";
	import _ from "lodash";
	// import { KEYS } from "@/states/keys";
	// import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

	export default {
		name: "AppAutoCompleteLocalSearching",
		props: {
			itemValueKey: {
				default: "key", // _id
				type: String,
			},
			itemList: {
				type: Array,
				default: () => [],
			},
			enableEnterSearch: {
				default: true,
				type: Boolean,
			},
			isDark: {
				default: false,
				type: Boolean,
			},
			tableLoading: {
				default: false,
				type: Boolean,
			},

			disabled: {
				type: Boolean,
				default: false,
			},
		},
		mounted() {
			// this.getParamQuery();
			// this.itemList = KEYS;
		},
		data: () => ({
			modelVal: null,
			// itemList: [],
			loading: false,
			// searchingAction: null,
			currentSearchText: "",
			searchCountTimer: undefined,
			queryOrder: {
				text: "text",
				empid: "empId",
			},
			formData: {
				text: null,
				empId: null,
			},
			firstInit: false,
			selectFromListChecker: false,
		}),
		watch: {
			// modelVal: {
			// 	immediate: true,
			// 	handler: function (val) {
			// 		if (!_.isString(val)) {
			// 			this.$emit("searchItemChange", val);
			// 			if (!_.isNil(val)) {
			// 				this.$refs.myCombobox.blur();
			// 			}
			// 		} else {
			// 			this.$emit("searchItemChange", { value: val });
			// 		}
			// 	},
			// },
		},
		computed: {},

		methods: {
			getBackgroundColor() {
				console.log(
					"disabled",
					this.disabled,
					this.$vuetify.theme.themes.light.backgroudDisableInputView
				);
				if (this.disabled) {
					return this.$vuetify.theme.themes.light.backgroudDisableInputView;
				} else {
					return this.$vuetify.theme.themes.light.backgroundInputView;
				}
			},
			// blurCombobox() {
			//   // Programmatically blur the combobox when Enter key is pressed
			//   this.$refs.myCombobox.blur();
			// },
			// enter
			enterToReturnEmit(event) {
				if (this.enableEnterSearch) {
					if (event.code === "Enter") {
						// console.log("enterToReturnEmit Enter");
						// clearTimeout(this.searchCountTimer);
						this.loading = false;
						this.$refs.myCombobox.blur();
						return;
					}
				}
			},
		},
	};
</script>
<style scoped>
	/* .v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
} */
</style>
