<template>
  <v-row>
    <v-col cols="12">
      <div class="mb-3">
        <v-card elevation="2" rounded="lg" class="px-4 py-3">
          <v-row no-gutters class="px-3 pb-3">
            <v-col cols="3" class="px-3 mt-3">
              <div>
                <span class="text-app-detail-normal black--text">
                  จ่ายเงินไปทั้งหมด
                </span>
              </div>
              <div class="mt-1">
                <span class="dashboard-small font-weight-bold black--text">
                  {{ displayNumberLocaleString(data.total, 2) }}
                </span>
                <span class="text-app-detail-normal"> บาท</span>
              </div>
            </v-col>
            <v-col cols="3" class="px-3 mt-3">
              <div>
                <span class="text-app-detail-normal black--text">
                  เฉลี่ยต่อเดือน
                </span>
              </div>
              <div class="mt-1">
                <span class="dashboard-small font-weight-bold black--text">
                  {{ displayNumberLocaleString(data.averagePerMonth, 2) }}
                </span>
                <span class="text-app-detail-normal"> บาท</span>
              </div>
            </v-col>
            <v-col cols="3" class="px-3 mt-3">
              <div>
                <span class="text-app-detail-normal black--text">
                  บิลมากสุด
                  <span
                    class="text-decoration-underline font-weight-bold"
                    v-if="data.maxAmountAtUser"
                  >
                    ({{ data.maxAmountAtUser.dpNameTh }})
                  </span>
                </span>
              </div>
              <div class="mt-1">
                <span class="dashboard-small font-weight-bold black--text">
                  {{
                    data.maxAmountAtUser
                      ? displayNumberLocaleString(
                          data.maxAmountAtUser.netAmount,
                          2
                        )
                      : "-"
                  }}
                </span>
                <span class="text-app-detail-normal"> บาท</span>
              </div>
            </v-col>
            <v-col cols="3" class="px-3 mt-3">
              <div>
                <span class="text-app-detail-normal black--text">
                  บิลน้อยสุด
                  <span
                    class="text-decoration-underline font-weight-bold"
                    v-if="data.minAmountAtUser"
                  >
                    ({{ data.minAmountAtUser.dpNameTh }})
                  </span>
                </span>
              </div>
              <div class="mt-1">
                <span class="dashboard-small font-weight-bold black--text">
                  {{
                    data.minAmountAtUser
                      ? displayNumberLocaleString(
                          data.minAmountAtUser.netAmount,
                          2
                        )
                      : "-"
                  }}
                </span>
                <span class="text-app-detail-normal"> บาท</span>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import _ from "lodash";
import { displayNumberLocaleString } from "@/services/appNumberHelper";

export default {
  name: "UserPaymentSummaryCard",
  props: {
    data: {
      required: true,
    },
  },
  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
    // processData() {
    //   try {
    //     if (!_.isNil(this.data)) {
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  mounted() {
    // this.processData();
  },
};
</script>
