<template>
  <v-dialog v-model="show" width="20%" :persistent="true">
    <v-card class="card-bg" v-if="show">
      <AppOverlay :absolute="false" :isLoading="componentLoading" />

      <v-app-bar elevation="0" class="card-bg" color="transparent">
        <v-toolbar-title class="text-h6"> เพิ่มลูกค้า </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="cancelStatus()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <AppCustomerDetailSearchInput
          @searchItemChange="searchItemChangeHandler"
        />
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
        <v-btn
          :disabled="formDataDisabled"
          color="primary"
          text
          @click="saveStatus"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import AppOverlay from "@/components/AppOverlay.vue";
import AppFormCardFooter from "@/components/AppFormCardFooter.vue";
import AppCustomerDetailSearchInput from "./AppCustomerDetailSearchInput.vue";
import { putRegisteredAppCustomerToJobService } from "@/services/api/jobs_registered_customer";

export default {
  name: "AppCustomerDetailDialog",
  props: {},
  components: {
    AppOverlay,
    AppFormCardFooter,
    AppCustomerDetailSearchInput,
  },
  mounted() {},
  data() {
    return {
      selectedCustomer: null,
      componentLoading: false,
      show: false,
      // form data
      valid: null,
      resolve: null,
      reject: null,
      currentJobId: null,
    };
  },
  computed: {
    formDataDisabled() {
      return false; // this.selectedCustomer === null;
    },
  },
  methods: {
    searchItemChangeHandler(newItem) {
      this.selectedCustomer = newItem;
    },

    //
    open(jobId) {
      this.show = true;
      this.currentJobId = jobId;
      this.resetForm();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    resetForm() {
      this.selectedCustomer = null;
    },

    async saveStatus() {
      this.componentLoading = true;
      try {
        const queryString = new URLSearchParams({
          id: this.currentJobId,
        }).toString();
        //
        const payload = {
          customer_id: this.selectedCustomer.key,
        };

        const rsp = await putRegisteredAppCustomerToJobService(
          this.$store,
          queryString,
          payload
        );

        if (rsp["code"] !== 200) {
          throw "";
        }
      } catch (error) {
        console.log(error);
        this.cancelStatus();
        return;
      }

      this.resolve(true);
      this.resetForm();
      this.show = false;
      this.componentLoading = false;
    },

    cancelStatus() {
      this.resolve(null);
      this.resetForm();
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
