<template>
  <div class="d-flex">
    <div
      class="d-flex"
      v-for="(item, idx) in statusJob"
      :key="`${idx}-${item.key}`"
    >
      <v-btn
        :class="[
          'custom-ss-button px-2 mr-1',
          { 'btn-text-active-color': valueInput.includes(item.key) },
        ]"
        :color="valueInput.includes(item.key) ? '#D8DEFF' : ''"
        :text="!valueInput.includes(item.key)"
        :disabled="disabled"
        @click="selectValue(item)"
        depressed
        height="32"
      >
        {{ item.value }} ({{ idx + 10 }})
      </v-btn>
    </div>

    <!-- <div class="d-flex align-center">
      <v-btn
        color="primary"
        height="32"
        width="32"
        min-width="0"
        class="mr-1 pa-0"
      >
        <v-icon> mdi-apps </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        height="32"
        width="32"
        min-width="0"
        class="mr-1 pa-0"
      >
        <v-icon> mdi-apps </v-icon>
      </v-btn>
      <v-btn color="primary" height="32" width="32" min-width="0" class="pa-0">
        <v-icon> mdi-apps </v-icon>
      </v-btn>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JobsMonitorFilterSpecialStatus",
  mounted() {
    this.getParamQuery();
  },
  props: {
    value: {},
    disabled: {
      required: true,
    },
  },
  data: () => ({
    queryOrder: {
      status: "status",
    },
    statusJob: [
      { key: "all", value: "ทั้งหมด" },
      { key: "not_start", value: "ไม่รับงาน" },
      { key: "late", value: "ล่าช้า" },
      { key: "duplicate", value: "ทับซ้อน" },
      { key: "report", value: "แจ้งเรื่อง" },
    ],
    statusColor: [
      { key: "all", value: "primary" },
      { key: "not_start", value: "info" },
      { key: "late", value: "warning" },
      { key: "duplicate", value: "error" },
      { key: "report", value: "secondary" },
    ],
  }),
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "status":
              this.settingValue = this.$route.query[key];
          }
        }
      });
    },
    selectValue(selectItem) {
      this.valueInput = [selectItem["key"]];
    },
  },
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.btn-text-active-color {
  color: #325ff8 !important;
}
.custom-ss-button {
  width: max-content;
  font-size: 13px;
  border-radius: 0.5rem;
  color: #515151;
  font-weight: 600;
}
</style>
