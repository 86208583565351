<template>
  <div :class="expendMargin ? 'px-3' : ''">
    <div class="d-flex">
      <div class="px-1">
        <p class="mb-0 caption">วันเริ่มต้น</p>
        <v-text-field
          class="app-custom-input"
          prepend-inner-icon="mdi-calendar"
          :value="startDate"
          placeholder=""
          dense
          outlined
          :rules="rules"
          :disabled="disabled"
          :readonly="false"
          clearable
          :hide-details="hideDetail"
          @click:clear="datepickerModelValue = []"
          @keyup="filterStartTextReleaseInput"
          @keydown="filterKeyDownStartInput"
          @keypress="filterTextStartInput"
          @input="onInputStartDateHandler"
          :background-color="getBackgrounddColor()"
          :name="dataInputName"
        >
          <template v-slot:prepend-inner>
            <v-menu
              v-model="openStart"
              :close-on-content-click="false"
              transition="slide-x-transition"
              min-width="auto"
              left
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="pa-1">mdi-calendar</v-icon>
              </template>
              <v-date-picker
                v-model="datepickerModelValue"
                no-title
                :min="minDate"
                range
              />
            </v-menu>
          </template>
        </v-text-field>
      </div>
      <div class="px-1">
        <p class="mb-0 caption">วันสิ้นสุด</p>
        <v-text-field
          class="app-custom-input"
          prepend-inner-icon="mdi-calendar"
          :value="endDate"
          placeholder=""
          dense
          outlined
          :rules="rules"
          :disabled="disabled"
          :readonly="false"
          clearable
          :hide-details="hideDetail"
          @click:clear="datepickerModelValue = []"
          @keyup="filterEndTextReleaseInput"
          @keydown="filterKeyDownEndInput"
          @keypress="filterTextEndInput"
          :background-color="getBackgrounddColor()"
          :name="dataInputName"
          @input="onInputEndDateHandler"
        >
          <template v-slot:prepend-inner>
            <v-menu
              v-model="openEnd"
              :close-on-content-click="false"
              transition="slide-x-transition"
              min-width="auto"
              left
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="pa-1">mdi-calendar</v-icon>
              </template>
              <v-date-picker
                v-model="datepickerModelValue"
                no-title
                :min="minDate"
                range
              />
            </v-menu>
          </template>
        </v-text-field>
      </div>
      <div v-if="isOptionRange">
        <p class="mb-0 caption">ช่วงเวลา</p>
        <v-btn-toggle v-model="numDays" dense color="primary" class="pb-3">
          <v-btn height="40" v-for="(days, idx) in numDaysItem" :key="idx">
            {{ days }}
          </v-btn>
        </v-btn-toggle>
        <span v-if="showDayText" class="px-3 text-app-small-detail">วัน</span>
      </div>
    </div>
  </div>
</template>
<script>
import InputView from "@/components/InputView.vue";
import moment from "moment";
export default {
  name: "CustomFilterValueDateTypingPickerRange",

  data: () => ({
    dateMin: null,
    openStart: false,
    openEnd: false,
    startDate: null,
    endDate: null,
    startDateTyping: null,
    endDateTyping: null,
    isStartDateDeleting: false,
    isEndDateDeleting: false,
    numDays: null,
    tmpNum: null,
    currentStartEnd: [],
    datepickerModelValue: null,
    firstInit: false,
  }),

  props: {
    value: [], // :value
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    isSameLine: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    initRange: {
      type: Number,
      require: false,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    isOptionRange: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    expendMargin: {
      type: Boolean,
      default: false,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    minDate: null,
    showDayText: {
      type: Boolean,
      default: false,
    },
    numDaysItem: {
      type: Array,
      default: [15, 30, 45, 60, 90],
    },
  },
  mounted() {
    if (this.value.length !== 0) {
      this.formatRange(this.value);
    } else {
      if (!_.isNil(this.initRange)) {
        if (this.numDaysItem.includes(this.initRange)) {
          this.numDays = this.numDaysItem.indexOf(this.initRange);
        }
      }
    }
  },

  watch: {
    numDays(newValue) {
      if (newValue !== undefined && newValue !== null) {
        let dayRange = this.numDaysItem[newValue];
        this.setDateRange(dayRange);
      }
    },
    datepickerModelValue(newValue) {
      if (newValue.length === 2) {
        this.startDate = this.formatDate(newValue[0]);
        this.endDate = this.formatDate(newValue[1]);
        this.openEnd = false;
        this.openStart = false;
      } else if (newValue.length === 1) {
        this.startDate = this.formatDate(newValue[0]);
        this.endDate = null;
      }

      if (this.firstInit) {
        this.$emit("update-join-date", newValue);
      } else {
        this.firstInit = true;
      }
    },
  },

  methods: {
    setDateRange(range) {
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - range);

      let startDay = startDate.getUTCDate();
      let startMonth = startDate.getUTCMonth() + 1;
      let startYear = startDate.getUTCFullYear();
      startDay = startDay < 10 ? "0" + startDay : startDay;
      startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
      let startFullDate = startYear + "-" + startMonth + "-" + startDay;

      let endDay = endDate.getUTCDate();
      let endMonth = endDate.getUTCMonth() + 1;
      let endYear = endDate.getUTCFullYear();
      endDay = endDay < 10 ? "0" + endDay : endDay;
      endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
      let endFullDate = endYear + "-" + endMonth + "-" + endDay;
      this.datepickerModelValue = [endFullDate, startFullDate];
    },

    filterStartTextReleaseInput(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString();

      if (this.startDateTyping && !this.isStartDateDeleting) {
        if (this.startDateTyping.length === 2) {
          this.startDate = this.startDateTyping.slice(0, 3) + "/";
        } else if (this.startDateTyping.length === 5) {
          this.startDate = this.startDateTyping.slice(0, 6) + "/";
        }
      }

      return true;
    },

    filterEndTextReleaseInput(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString();

      if (this.endDateTyping && !this.isEndDateDeleting) {
        if (this.endDateTyping.length === 2) {
          this.endDate = this.endDateTyping.slice(0, 3) + "/";
        } else if (this.endDateTyping.length === 5) {
          this.endDate = this.endDateTyping.slice(0, 6) + "/";
        }
      }

      return true;
    },
    filterKeyDownStartInput(evt) {
      evt = evt ? evt : window.event;
      const key = evt.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        this.isStartDateDeleting = true;
        return false;
      } else {
        this.isStartDateDeleting = false;
      }
    },
    filterKeyDownEndInput(evt) {
      evt = evt ? evt : window.event;
      const key = evt.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        this.isEndDateDeleting = true;
        return false;
      } else {
        this.isEndDateDeleting = false;
      }
    },
    filterTextStartInput(evt) {
      evt = evt ? evt : window.event;
      let expect = null;

      if (evt.key !== undefined) {
        expect = evt.target.value.toString() + evt.key.toString();
      } else {
        expect = evt.target.value.toString();
      }
      expect = expect.replace(/\//g, "");

      if (!/^\d+$/.test(expect) || expect.length > 8) {
        evt.preventDefault();
        return;
      }
      return true;
    },
    filterTextEndInput(evt) {
      evt = evt ? evt : window.event;
      let expect = null;

      if (evt.key !== undefined) {
        expect = evt.target.value.toString() + evt.key.toString();
      } else {
        expect = evt.target.value.toString();
      }
      expect = expect.replace(/\//g, "");

      if (!/^\d+$/.test(expect) || expect.length > 8) {
        evt.preventDefault();
        return;
      }
      return true;
    },
    parseDatePicker(date) {
      if (!date) return null;
      let [day, month, year] = date.split("/");

      if (parseInt(month) > 12) {
        month = "12";
      }

      var d = new Date(parseInt(year), parseInt(month), 0);
      let maxDay = d.getDate();
      if (parseInt(day) > maxDay) {
        day = maxDay.toString();
      }

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      // }
    },
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
    formatRange(dateRange) {
      if (dateRange !== this.currentStartEnd) {
        this.currentStartEnd = _.clone(dateRange);
        if (dateRange !== null) {
          if (dateRange.length !== 0) {
            if (dateRange.length == 2) {
              let sortedDateRange = dateRange.sort();
              this.datepickerModelValue = [
                sortedDateRange[0],
                sortedDateRange[1],
              ];
            } else {
              this.datepickerModelValue = [dateRange[0]];
            }
          } else {
            this.numDays = null;
            this.startDate = null;
            this.endDate = null;
            this.datepickerModelValue = [];
          }
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onInputStartDateHandler(newValue) {
      if (newValue) {
        this.startDateTyping = newValue;
        const [day, month, year] = newValue.split("/");

        if (day && month && year) {
          if (year.length !== 4) {
            return;
          }
          const currentValueInput = _.clone(this.datepickerModelValue);
          if (currentValueInput.length > 0) {
            currentValueInput[0] = this.parseDatePicker(newValue);
          } else {
            currentValueInput.push(this.parseDatePicker(newValue));
          }
          if (currentValueInput.length == 2) {
            var diff = moment(currentValueInput[1]).diff(
              moment(currentValueInput[0]),
              "days"
            );

            let isEndDateToday = false;
            if (
              moment(currentValueInput[1]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
            ) {
              isEndDateToday = true;
            } else {
              isEndDateToday = false;
            }

            if (this.numDaysItem.includes(diff) && isEndDateToday) {
              this.numDays = this.numDaysItem.indexOf(diff);
            } else {
              this.numDays = null;
            }
          }
          this.datepickerModelValue = _.clone(currentValueInput);
        }
      }
    },
    onInputEndDateHandler(newValue) {
      if (newValue) {
        this.endDateTyping = newValue;
        const [day, month, year] = newValue.split("/");

        if (day && month && year) {
          if (year.length !== 4) {
            return;
          }

          let currentValueInput = _.clone(this.datepickerModelValue);
          if (currentValueInput.length == 1) {
            currentValueInput.push(this.parseDatePicker(newValue));
          } else if (currentValueInput.length == 2) {
            currentValueInput[1] = this.parseDatePicker(newValue);
            var diff = moment(currentValueInput[1]).diff(
              moment(currentValueInput[0]),
              "days"
            );

            let isEndDateToday = false;
            if (
              moment(currentValueInput[1]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
            ) {
              isEndDateToday = true;
            } else {
              isEndDateToday = false;
            }

            if (this.numDaysItem.includes(diff) && isEndDateToday) {
              this.numDays = this.numDaysItem.indexOf(diff);
            } else {
              this.numDays = null;
            }
          }
          this.datepickerModelValue = _.clone(currentValueInput);
        }
      }
    },
  },
  components: {
    InputView,
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  padding-left: 0px;
  color: rgb(137, 137, 137);
}

.left-text-field {
  padding-right: 2px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.right-text-field {
  padding-left: 1px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
</style>
