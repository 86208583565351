<template>
  <v-card elevation="0">
    <v-card-title class="pb-1">
      <span class="text-title font-weight-bold black--text"> {{ title }} </span>
      <v-list-item-subtitle>โดยเฉลี่ยต่อวัน</v-list-item-subtitle>
    </v-card-title>

    <apexchart :options="chartOptions" :series="series"></apexchart>
    <span class="mx-12 text-app-small-detail font-weight-bold black--text">
      {{ axisXLabel }}
    </span>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {
  displayNumberLocaleString,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";
export default {
  name: "VehicleEventScatterChart",
  props: {
    data: {
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    axisXLabel: {
      type: String,
      default: "",
    },
    axisYLabel: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 450,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      annotationsYaxis: [],
      annotationsXaxis: [],
      colors: [],
      vehicleMapList: [],
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: this.height,
          type: "scatter",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          events: {
            click: (event, chartContext, config) => {
              const selectedVehicle = _.get(
                this.vehicleMapList,
                `${config.seriesIndex}`,
                null
              );

              if (selectedVehicle !== null) {
                const routeData = this.$router.resolve({
                  name: "vehicleDashboard",
                  params: { id: selectedVehicle },
                });
                window.open(routeData.href, "_blank");
              }

              return;
            },
          },
        },
        legend: { show: false },
        grid: {
          show: false,
        },
        markers: {
          colors: this.colors,
          size: [7],
        },
        annotations: {
          yaxis: this.annotationsYaxis,
          xaxis: this.annotationsXaxis,
        },
        tooltip: {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `${this.axisXLabel} ${displayNumberLocaleString(
                value,
                2
              )}`;
            },
          },
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `${displayNumberLocaleString(value, 2)}`;
            },
          },
        },
        xaxis: {
          tickAmount: 10,
          // title: {
          //   text: this.axisXLabel,
          // },
          labels: {
            formatter: (value) => {
              return `${numberWithCommasHelper(
                displayNumberLocaleString(value, 2)
              )}`;
            },
          },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 7,
          title: {
            text: this.axisYLabel,
            offsetX: 0,
            offsetY: -60,
          },
          labels: {
            formatter: (value) => {
              return `${nFormatterHelper(value, 1)}`;
            },
          },
        },
      };
    },
  },

  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        this.vehicleMapList = _.get(this.data, "vehicleMapId", []);
        this.series = _.get(this.data, "data", []);
        this.annotationsXaxis = _.get(this.data, "options.annoXaxis", []);
        this.annotationsYaxis = _.get(this.data, "options.annoYaxis", []);
        this.colors = _.get(this.data, "options.colors", ["#567DF4"]);
      }
    },
  },
  mounted() {
    this.processData();
  },
};
</script>
