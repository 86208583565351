<template>
	<v-row no-gutters>
		<v-col cols="12">
			<CustomCardFormViewModern
				title="จำนวนรถ"
				:is-show-title="true"
				:is-add-title-margin="true"
			>
				<v-row no-gutters class="px-3 mb-3">
					<v-col cols="3" class="px-3 mt-3">
						<v-row no-gutters>
							<v-col cols="12" class="px-0">
								<div class="mt-6 mb-0">
									<span class="dashboard-large font-weight-bold black--text">
										{{ veTotalCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div>
									<span class="font-weight-bold px-1">ทั้งหมด</span>
								</div>
							</v-col>
						</v-row>
					</v-col>
					<!-- <v-divider vertical class="mt-3" /> -->
					<v-col cols="9" class="px-3 mt-9">
						<v-row no-gutters class="mt-0">
							<v-col cols="2" class="pt-4"
								><div class="pl-3">
									<span class="dashboard-small font-weight-bold black--text">
										{{ veActiveCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div class="pl-3">
									<span
										:class="`font-weight-bold  px-1 ${getStatusVehicleColor(
											'ACTIVE'
										)}`"
										>{{ getStatusVehicleText("ACTIVE") }}</span
									>
								</div></v-col
							>
							<v-divider vertical class="mt-3" />
							<v-col cols="2" class="pt-4">
								<div class="pl-3">
									<span class="dashboard-small font-weight-bold black--text">
										{{ veInActiveCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div class="pl-3">
									<span
										:class="`font-weight-bold  px-1 ${getStatusVehicleColor(
											'INACTIVE'
										)}`"
										>{{ getStatusVehicleText("INACTIVE") }}</span
									>
								</div>
							</v-col>
							<v-divider vertical class="mt-3" />
							<v-col cols="2" class="pt-4">
								<div class="pl-3">
									<span class="dashboard-small font-weight-bold black--text">
										{{ veSoldCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div class="pl-3">
									<span
										:class="`font-weight-bold  px-1 ${getStatusVehicleColor(
											'SALED'
										)}`"
									>
										{{ getStatusVehicleText("SALED") }}
									</span>
								</div></v-col
							>
							<v-divider vertical class="mt-3" />
							<v-col cols="2" class="pt-4"
								><div class="pl-3">
									<span class="dashboard-small font-weight-bold black--text">
										{{ veOutLoanActiveCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div class="pl-3">
									<span class="text-app-detail-normal font-weight-bold px-1">
										ผ่อนชำระหมดแล้ว
									</span>
								</div></v-col
							>
							<v-divider vertical class="mt-3" />
							<v-col cols="2" class="pt-4"
								><div class="pl-3">
									<span class="dashboard-small font-weight-bold black--text">
										{{ veInLoanActiveCount }}
									</span>
									<span class="text-app-detail-normal"> คัน</span>
								</div>
								<div class="pl-3">
									<span class="text-app-detail-normal font-weight-bold px-1">
										ยังไม่หมด
									</span>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</CustomCardFormViewModern>
		</v-col>
	</v-row>
</template>

<script>
	import CustomCardFormViewModern from "../../../../components/CustomCardFormViewModern";
	const VehicleStatus = Object.freeze({
		ACTIVE: "พร้อม",
		INACTIVE: "ไม่พร้อม",
		SALED: "ขายแล้ว",
	});

	export default {
		name: "OverallVehicleCard",
		props: {
			data: {
				required: true,
			},
		},
		data() {
			return {
				veTotalCount: null,
				veActiveCount: null,
				veInActiveCount: null,
				veSoldCount: null,
				veInLoanActiveCount: null,
				veOutLoanActiveCount: null,
			};
		},
		watch: {
			data: function (newValue) {
				this.processData();
			},
		},
		methods: {
			getStatusVehicleText(status) {
				switch (status) {
					case "ACTIVE":
						return VehicleStatus.ACTIVE;
					case "INACTIVE":
						return VehicleStatus.INACTIVE;
					case "SALED":
						return VehicleStatus.SALED;
					default:
						return null;
				}
			},

			getStatusVehicleColor(status) {
				switch (status) {
					case "ACTIVE":
						return "primary--text";
					case "INACTIVE":
						return "warning--text";
					case "SALED":
						return "secondary--text";
					default:
						return null;
				}
			},
			processData() {
				if (_.isNil(this.data)) {
					return;
				}
				//
				if (!_.isNil(this.data["vehicleStat"])) {
					this.veTotalCount = _.get(this.data, "vehicleStat.totalVehicle", 0);
					this.veActiveCount = _.get(
						this.data,
						"vehicleStat.countActiveStatus",
						0
					);
					this.veInActiveCount = _.get(
						this.data,
						"vehicleStat.countInActiveStatus",
						0
					);
					this.veSoldCount = _.get(
						this.data,
						"vehicleStat.countSaledStatus",
						0
					);
					this.veInLoanActiveCount = _.get(
						this.data,
						"vehicleStat.countExistingLoanExpiry",
						0
					);
					this.veOutLoanActiveCount = _.get(
						this.data,
						"vehicleStat.countNoExistingLoadExpiry",
						0
					);
				}
			},
		},
		components: {
			CustomCardFormViewModern,
		},
		mounted() {
			this.processData();
		},
	};
</script>
