<template>
  <div name="StudyingInformationFormView">
    <v-form ref="StudyingInformationFormViewRef" v-model="valid">
      <CustomCardFormViewModern
        title="ประวัติการศึกษา"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div v-for="(studying, idx) in educationalList" :key="idx">
          <v-row v-if="idx > 0" class="px-12 pl-9 pt-3 pb-6"
            ><v-divider></v-divider
          ></v-row>
          <v-row class="px-12" v-if="idx > 0">
            <v-spacer></v-spacer>
            <!-- <div
              class="text-decoration-underline error--text diplay--inline-block"
              :disabled="!canEdit"
              @click="handleDeleteItem(idx)"
            >
              ลบ
            </div> -->
            <v-btn icon :disabled="!canEdit" @click="handleDeleteItem(idx)">
              <v-icon color="error lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" sm="6" class="px-6 mb-6 pt-0">
              <v-col cols="12" class="pb-0">
                <div class="px-3">
                  <span class="title-input"> รูปภาพไฟล์วุฒิการศึกษา </span>
                  <!-- <span class="red--text"> * </span> -->
                  <div class="mt-2"></div>
                  <AppFilesUpload
                    placeholder="เลือกรูปภาพ"
                    :isClearInput="false"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="false"
                    :isPrependInnerIcon="true"
                    v-model="studying.imageFile"
                    :ref="`AppFilesUploadRef${idx}`"
                    :disabled="!canEdit"
                    uploadTag="EMP_DRIVING_LICENSE"
                  />
                </div>
                <!-- :rules="[(v) => !!v || 'จำเป็นต้องระบุ']" -->
              </v-col>
              <v-img
                :src="studying.imageFile[0] ? studying.imageFile[0].src : ''"
                height="365"
                contain
                class="backgroud-image"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" justify="center">
                    <v-icon x-large>mdi-image</v-icon>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <CustomSelectsImprove
                    ref="studyingLevel"
                    v-model="studying.level"
                    title="ระดับการศึกษา"
                    :items="studyingLevelItem"
                    :disabled="!canEdit"
                    data-input-name="studyingLevel"
                  />
                </v-col>
                <v-col cols="12">
                  <CustomTextInputImprove
                    v-model="studying.school"
                    ref="school"
                    title="สถาบันการศึกษา"
                    placeholder="สถาบันการศึกษา"
                    :disabled="!canEdit"
                    dataInputName="school"
                  />
                </v-col>
                <v-col cols="12">
                  <CustomTextInputImprove
                    v-model="studying.major"
                    ref="major"
                    title="สาขาวิชา"
                    placeholder="สาขาวิชา"
                    :disabled="!canEdit"
                    dataInputName="major"
                  />
                </v-col>
                <v-col cols="12">
                  <CustomTextInputImprove
                    v-model="studying.gpa"
                    ref="gpa"
                    title="เกรด/ผลการเรียน"
                    placeholder="เกรด/ผลการเรียน"
                    :disabled="!canEdit"
                    dataInputName="gpa"
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <CustomDateTypingPickerFormInput
                    ref="renewalDate"
                    v-model="studying.issueDate"
                    title="ระยะเวลาการศึกษา"
                    placeholder="วัน/เดือน/ปี"
                    :is-required="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    :disabled="!canEdit"
                    data-input-name="renewalDate"
                  />
                </v-col> -->
                <v-col cols="12">
                  <InputView
                    title="ระยะเวลาการศึกษา (ปีค.ศ.- ปีค.ศ.)"
                    class="mx-3"
                  >
                    <v-row>
                      <v-col class="px-0 pt-1">
                        <CustomTextInputImprove
                          v-model="studying.minYear"
                          ref="minYear"
                          placeholder="ปีค.ศ. ที่เริ่ม"
                          input-type="numberWithoutSign"
                          data-input-name="searchingMinYear"
                          :show-required-bullet="false"
                        />
                      </v-col>
                      <p class="title-filter subtitle--text pt-5">—</p>

                      <v-col class="px-0 pt-1">
                        <CustomTextInputImprove
                          v-model="studying.maxYear"
                          ref="maxYear"
                          placeholder="ปีค.ศ. สิ้นสุด"
                          input-type="numberWithoutSign"
                          data-input-name="searchingMaxYear"
                          :show-required-bullet="false"
                        />
                      </v-col>
                    </v-row>
                  </InputView>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row class="px-12" v-if="canEdit">
          <v-spacer></v-spacer>
          <PrimaryButton
            v-if="canEdit"
            icon="mdi-plus"
            :outlined="true"
            :disabled="this.educationalList.length == 3"
            @on-click="handleAddClick()"
          >
            เพิ่มประวัติการศึกษา
          </PrimaryButton>
          <v-spacer></v-spacer>
        </v-row>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          @on-click-skip="onClickSkip()"
          :is-enable-skip="isEnableSkip"
          :disabled="!canEdit"
          v-if="canEdit"
          text="บันทึกข้อมูลประวัติการศึกษา"
        />
      </CustomCardFormViewModern>
      <AppDialogConfirm ref="dialogError" />
    </v-form>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { errorMessageMapper } from "@/services/error";
import { DialogType } from "@/services/dialog";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "StudyingInformationFormView",

  components: {
    CustomTextInput,
    CustomSelects,
    AppFilesUpload,
    AppDialogConfirm,
    CustomCardFormViewModern,
    AppSubmitFormButton,
    CustomSelectsImprove,
    PrimaryButton,
    CustomTextInputImprove,
    InputView,
  },

  props: {
    educational: {
      type: [Array, null],
      default: null,
    },
    isEnableSkip: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  // watch: {

  // },

  data() {
    return {
      valid: false,
      educationalList: [],
      formData: {
        image: null,
        level: null,
        school: null,
        major: null,
        gpa: null,
        minYear: null,
        maxYear: null,
        imageFile: [],
      },

      // expirationDateInput: null,
      // renewalDateInput: null,
      // driverLicenseTypeInput: null,
      //
      studyingLevelItem: [
        "ปวช./ม.3",
        "ปวส./ม.6",
        "ปริญญาตรี",
        "ปริญญาโท",
        "ปริญญาเอก",
        "ต่ำกว่า ปวช./ม.3",
        "ไม่มีวุฒิฯ",
      ],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },

      rawData: null,
      // selectedDriverLicenseImageFile: [],
      resultDriverLicenseImageUpload: [],
      //
      disabled: false,
    };
  },

  mounted() {
    this.handleAddClick();
  },
  watch: {
    canEdit: function (newValue) {
      this.disabled = !newValue;
    },

    async educational(newValue) {
      if (!_.isNil(newValue)) {
        this.rawData = _.cloneDeep(newValue); // for backup data
        let resp = [];
        if (newValue.length !== 0) {
          for (let idx in newValue) {
            let image = _.cloneDeep(newValue[idx].imageFile);
            let imageFile = [image];
            resp.push({
              ...newValue[idx],
              imageFile,
            });
          }
          this.educationalList = _.cloneDeep(resp);
        }
      }
    },
  },

  methods: {
    handleAddClick() {
      if (this.educationalList.length < 3) {
        let studying = _.cloneDeep(this.formData);
        this.educationalList.push(studying);
      }
    },
    handleDeleteItem(idx) {
      this.educationalList.splice(idx, 1);
    },

    async saveChange() {
      this.disabled = true;
      if (this.$refs["StudyingInformationFormViewRef"].validate() === true) {
        // try {
        let returnData = { educational: [] };
        for (let idx in this.educationalList) {
          let fileKeyChecker = null;
          if (this.educationalList[idx]["imageFile"].length !== 0) {
            let resultDriverLicenseImageUpload = await this.$refs[
              `AppFilesUploadRef${idx}`
            ][0].uploadAction();

            if (resultDriverLicenseImageUpload.length !== 0) {
              if (resultDriverLicenseImageUpload[0]["code"] >= 400) {
                // is error
                throw resultDriverLicenseImageUpload[0];
                this.$refs[`AppFilesUploadRef${idx}`][0].clearInput();
              } else {
                fileKeyChecker = resultDriverLicenseImageUpload[0].data;
              }
            } else {
              if (!_.isNil(this.rawData[idx].imageFile)) {
                fileKeyChecker = {
                  fileKey: this.rawData[idx].imageFile.fileKey,
                };
              }
            }

            let studying = _.cloneDeep(this.educationalList[idx]);
            let licenseItem = { ...studying, imageFile: fileKeyChecker };
            // delete licenseItem["imageFile"];

            returnData["educational"].push(licenseItem);
          }
        }
        this.$emit("on-click-save", returnData);
        // } catch (error) {
        //   this.showDialogError(error);
        // }
      } else {
        this.$refs["StudyingInformationFormViewRef"].$el.scrollIntoView(
          this.focus
        );
      }
      this.disabled = false;
    },

    async showDialogError(responseData) {
      let text_error = "ไม่สามารถแก้ไขข้อมูลบัญชีได้ ลองใหม่อีกครั้ง";
      if (!_.isNil(responseData)) {
        text_error = `${text_error} (${
          errorMessageMapper[responseData["message"]]
        })`;
      }

      await this.$refs.dialogError.open(text_error, null, DialogType.ERROR, {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      });
    },

    onClickSkip() {
      this.$emit("on-click-skip");
    },
  },
};
</script>
<style>
.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
.backgroud-image {
  background: #f4f6f6;
}

.v-input {
  font-size: 14px;
}

.theme--light.v-file-input .v-file-input__text--placeholder {
  color: rgb(170, 183, 184) !important;
}
</style>
