import VehicleInsuranceListView from "@/views/vehicle/vehicle_docs_insurance/VehicleInsuranceList.vue";
import VehicleActList from "@/views/vehicle/vehicle_docs_act/VehicleActList.vue";
import VehicleTaxList from "@/views/vehicle/vehicle_docs_tax/VehicleTaxList.vue";
import VehicleGPSList from "@/views/vehicle/vehicle_docs_gps/VehicleGPSList.vue";
import VehicleLive from "@/views/vehicle/vehicle_docs_live/VehicleLive.vue";
import VehicleLoanList from "@/views/vehicle/vehicle_docs_loan/VehicleLoanList.vue";

import VehicleInsuranceManageView from "@/views/vehicle/vehicle_docs_insurance/VehicleInsuranceManageView.vue";
import VehicleActManageView from "@/views/vehicle/vehicle_docs_act/VehicleActManageView.vue";
import VehicleTaxManageView from "@/views/vehicle/vehicle_docs_tax/VehicleTaxManageView.vue";
import VehicleGPSManageView from "@/views/vehicle/vehicle_docs_gps/VehicleGPSManageView.vue";
import VehicleLoanManageView from "@/views/vehicle/vehicle_docs_loan/VehicleLoanManageView.vue";

export default [
	{
		path: "/vehicle/ins",
		name: "vehicleInsurList",
		component: VehicleInsuranceListView,
		meta: { requiresAuth: true, topic: "vehicleIns" },
	},
	// {
	//   path: "/vehicle/ins/:licenseNumber",
	//   name: "vehicleInsurSearchList",
	//   component: VehicleInsuranceListView,
	//   meta: { requiresAuth: true },
	// },
	{
		path: "/vehicle/act",
		name: "vehicleActList",
		component: VehicleActList,
		meta: {
			requiresAuth: true,
			topic: "vehicleAct",
		},
	},
	// {
	//   path: "/vehicle/act/:licenseNumber",
	//   name: "vehicleActSearchList",
	//   component: VehicleActList,
	//   meta: { requiresAuth: true },
	// },
	{
		path: "/vehicle/tax",
		name: "vehicleTaxList",
		component: VehicleTaxList,
		meta: { requiresAuth: true, topic: "vehicleTax" },
	},

	// {
	//   path: "/vehicle/tax/:licenseNumber",
	//   name: "vehicleTaxSearchList",
	//   component: VehicleTaxList,
	//   meta: { requiresAuth: true },
	// },
	{
		path: "/vehicle/ins/create",
		name: "vehicleInsurManage",
		component: VehicleInsuranceManageView,
		meta: { requiresAuth: true, topic: "vehicleIns" },
	},
	{
		path: "/vehicle/ins/edit/:id",
		name: "vehicleInsurEdit",
		component: VehicleInsuranceManageView,
		meta: { requiresAuth: true, topic: "vehicleIns" },
	},
	{
		path: "/vehicle/act/create",
		name: "vehicleActManage",
		component: VehicleActManageView,
		meta: { requiresAuth: true, topic: "vehicleAct" },
	},
	{
		path: "/vehicle/act/edit/:id",
		name: "vehicleActEdit",
		component: VehicleActManageView,
		meta: { requiresAuth: true, topic: "vehicleAct" },
	},
	{
		path: "/vehicle/tax/create",
		name: "vehicleTaxManage",
		component: VehicleTaxManageView,
		meta: { requiresAuth: true, topic: "vehicleTax" },
	},
	{
		path: "/vehicle/tax/edit/:id",
		name: "vehicleTaxEdit",
		component: VehicleTaxManageView,
		meta: { requiresAuth: true, topic: "vehicleTax" },
	},

	{
		path: "/vehicle/gps",
		name: "vehicleGPSList",
		component: VehicleGPSList,
		meta: { requiresAuth: true, topic: "vehicleGPS" },
	},
	{
		path: "/vehicle/live",
		name: "vehicleLive",
		component: VehicleLive,
		meta: { requiresAuth: true, topic: "vehicleLive" },
	},
	{
		path: "/vehicle/gps/create",
		name: "vehicleGPSManage",
		component: VehicleGPSManageView,
		meta: { requiresAuth: true, topic: "vehicleGPS" },
	},
	{
		path: "/vehicle/gps/edit/:id",
		name: "vehicleGPSEdit",
		component: VehicleGPSManageView,
		meta: { requiresAuth: true, topic: "vehicleGPS" },
	},

	{
		path: "/vehicle/loan",
		name: "vehicleLoanList",
		component: VehicleLoanList,
		meta: { requiresAuth: true, topic: "vehicleLoan" },
	},
	{
		path: "/vehicle/loan/create",
		name: "vehicleLoanManage",
		component: VehicleLoanManageView,
		meta: { requiresAuth: true, topic: "vehicleLoan" },
	},
	{
		path: "/vehicle/loan/edit/:id",
		name: "vehicleLoanEdit",
		component: VehicleLoanManageView,
		meta: { requiresAuth: true, topic: "vehicleLoan" },
	},
];
