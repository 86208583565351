<template>
	<div class="main-div">
		<v-row class="pl-4 pt-3 pb-1 pr-2">
			<div class="pt-2">
				{{ playerName }} {{ cameraTypeText[cameraType] }}
			</div>
			<v-spacer />
            <v-btn
              icon
			  class="pa-0 ma-0"
			  @click="refreshAction()"
            >
				<v-icon 
					small
				>
					mdi-refresh
				</v-icon>
			</v-btn>

            <v-btn
              icon
			  class="pa-0 ma-0"
			  @click="stopAction(false)"
            >
				<v-icon 
					small
				>
					mdi-stop
				</v-icon>
			</v-btn>

            <v-btn
              icon
			  class="pa-0 ma-0"
			  @click="stopAction(true)"
            >
				<v-icon 
					small
				>
					mdi-close-box
				</v-icon>
			</v-btn>
			
            <v-btn
			  v-if="focusPlayerCallBack!=null"
              icon
			  class="pa-0 ma-0"
			  @click="focusAction(true)"
            >
				<v-icon 
					small
				>
				mdi-image-filter-center-focus
				</v-icon>
			</v-btn>
			
            <v-btn
			  v-if="deletePlayerCallBack!=null"
              icon
			  class="pa-0 ma-0"
			  @click="deleteAction(true)"
            >
				<v-icon 
					small
				>
					mdi-delete
				</v-icon>
			</v-btn>
		</v-row>
		<v-row>
			<v-card outlined class="card-player">
			<video class="video-player" ref="video" muted="muted" controls></video>
				<v-overlay :value="streamLoading" absolute
					><v-progress-circular indeterminate size="64"></v-progress-circular
				></v-overlay>
				<v-overlay :value="streamStatus != 'PLAYING'" absolute>{{
					streamStatusText[streamStatus]
				}}
				</v-overlay>
			</v-card>
		</v-row>
	</div>
</template>
<script>
	import Hls from "hls.js";
	import { getGPSVideoStreamingService, stopGPSVideoStreamingService } from "@/services/api/box";
	import axios from "axios";

	export default {
		name: "customDeepPlayerCore",
		components: {},
		props: {
			playerName: {
				default: "",
				type: String,
			},
			cameraType: {
				default: "",
				type: String,
			},
			serialNumber: {
				default: null,
				type: String,
			},
			focusPlayerCallBack: {
				default: null,
				type: Function,
			},
			deletePlayerCallBack: {
				default: null,
				type: Function,
			},
			listIndex: {
				default: null,
				type: Number,
			},
			isFocus:{
				default: false,
				type: Boolean,
			}
		},

		data: () => ({
			hls: null,
			cameraTypeText: {
				F: "กล้องหน้า",
				I: "กล้องหลัง",
			},
			streamStatusText: {
				REFRESHING: "กำลังทำการเชื่อมต่อใหม่",
				REQUESTING: "กำลังขอสัญญาณ",
				CONNECTING: "กำลังรอสัญญาณ",
				FAILED: "ขอสัญญาณไม่สำเร็จ",
				FAILED_NOT_FOUND: "เชื่อมต่อสัญญาณไม่สำเร็จ",
				STOP: "หยุดเล่น",
				PLAYING: "กำลังเล่น",
			},
			streamStatus: null,
			streamLoading: false,
			streamUrl: null,
			streamKey: null,
			streamPingInterval: null,
			maxTry: 30,
		}),

		mounted() {
			this.init();
		},
		destroyed() {
			console.log("destroyed");
			this.stopAction(false);
		},
		watch: {},
		methods: {
			async init() {
				console.log("Mounted");
				this.startStreamAction();
			},
			parentCloseEvent(){
				console.log("Parent close event in child");
				this.stopAction(false);
			},
			async startStreamAction() {
				if (this.streamPingInterval != null) {
					clearInterval(this.streamPingInterval);
					this.streamPingInterval = null;
				}

				this.streamLoading = true;
				this.streamStatus = "REQUESTING";

				const queryString = new URLSearchParams({
					serialNumber: this.serialNumber,
					camera: this.cameraType,
				}).toString();

				const result = await getGPSVideoStreamingService(
					this.$store,
					queryString
				);

				if (result.code == 200) {
					console.log(result.data);

					this.streamUrl = result.data['URL'];
					this.streamKey = result.data['key'];
					this.startPlayingStream();
				} else {
					this.streamLoading = false;
					this.streamStatus = "FAILED";
				}
			},
			async startPlayingStream() {
				if (this.streamUrl != null) {
					console.log(this.streamUrl)
					this.streamStatus = "CONNECTING";

					let tryCounter = 0;
					let startStreamingCompleted = false;
					while (
						this.streamStatus == "CONNECTING" &&
						tryCounter <= this.maxTry
					) {
						console.log("Checking stream path ...");
						try {
							const config = {
								headers: {
									'Authorization': "Basic " + this.streamKey
								}
							};

							const checkResult = await axios.get(this.streamUrl, config);
							// const checkResult = await axios.get(this.streamUrl);
							console.log("checkResult", checkResult);
							if (checkResult.status == 200) {
								startStreamingCompleted = true;
								break;
							}
						} catch (error) {
							console.log(error);
						}
						await new Promise((resolve) => setTimeout(resolve, 1000));
						tryCounter = tryCounter + 1;
						console.log("tryCounter", tryCounter);
					}

					if (this.streamStatus != "CONNECTING") {
						console.log("WTFG")
						return;
					}
					
					if (startStreamingCompleted) {
						if (this.hls == null) {
							console.log("hls is null creating new one.");
							this.hls = new Hls({
								xhrSetup: xhr => {
									xhr.setRequestHeader('Authorization', "Basic " + this.streamKey);
								}
							});

							this.hls.on(Hls.Events.MANIFEST_LOADING, async (event, data) => {
								console.log("MANIFEST_LOADING");
							});

							this.hls.on(Hls.Events.MANIFEST_PARSED, async (event, data) => {
								console.log("MANIFEST_PARSED");
							});

							this.hls.on(Hls.Events.MANIFEST_LOADED, async (event, data) => {
								console.log("MANIFEST_LOADED");
								this.streamLoading = false;
								this.streamStatus = "PLAYING";
								if (this.$refs["video"]) {
									this.$refs["video"].play();
								}

								if (this.streamPingInterval == null) {
									this.streamPingInterval = setInterval(
										this.pingStreamAction,
										60 * 1000
									);
								}
							});
							this.hls.on(Hls.Events.DESTROYING, async (event, data) => {
								console.log("DESTROYING");
							});
							this.hls.on(Hls.Events.MEDIA_DETACHING, async (event, data) => {
								console.log("MEDIA_DETACHING");
							});
							this.hls.on(Hls.Events.MEDIA_DETACHED, async (event, data) => {
								console.log("MEDIA_DETACHED");
							});
							// this.hls.on(Hls.Events.BUFFER_RESET, async (event, data) => {
							// 	console.log("BUFFER_RESET");
							// });
							// this.hls.on(Hls.Events.BUFFER_CODECS, async (event, data) => {
							// 	console.log("BUFFER_CODECS");
							// });
							// this.hls.on(Hls.Events.BUFFER_CREATED , async (event, data) => {
							// 	console.log("BUFFER_CREATED");
							// });
							// this.hls.on(Hls.Events.BUFFER_APPENDING, async (event, data) => {
							// 	console.log("BUFFER_APPENDING");
							// });
							// this.hls.on(Hls.Events.BUFFER_APPENDED, async (event, data) => {
							// 	console.log("BUFFER_APPENDED");
							// });
							this.hls.on(Hls.Events.BUFFER_EOS, async (event, data) => {
								console.log("BUFFER_EOS");
							});
							this.hls.on(Hls.Events.BUFFER_FLUSHING, async (event, data) => {
								console.log("BUFFER_FLUSHING");
							});
							this.hls.on(Hls.Events.BUFFER_FLUSHED, async (event, data) => {
								console.log("BUFFER_FLUSHED");
							});
							this.hls.on(Hls.Events.BACK_BUFFER_REACHED, async (event, data) => {
								console.log("BACK_BUFFER_REACHED");
							});

							this.hls.on(Hls.Events.ERROR, async (event, data) => {
								console.log("ERROR");
								const errorType = data.type;
								const errorDetails = data.details;
								const errorFatal = data.fatal;

								// Error Types:
								// Hls.ErrorTypes.NETWORK_ERROR for network related errors
								// Hls.ErrorTypes.MEDIA_ERROR for media/video related errors
								// Hls.ErrorTypes.KEY_SYSTEM_ERROR for EME related errors
								// Hls.ErrorTypes.MUX_ERROR for demuxing/remuxing related errors
								// Hls.ErrorTypes.OTHER_ERROR for all other errors

								console.log(errorType)

								switch (data.type) {
									case Hls.ErrorTypes.NETWORK_ERROR:
									console.log(errorDetails)

									if(errorDetails == "levelLoadError"){
										this.stopAction(false);
									}
									break;
									case Hls.ErrorTypes.MEDIA_ERROR:
									console.log(errorDetails)
									break;
									case Hls.ErrorTypes.OTHER_ERROR:
									console.log(errorDetails)
									break;
								}
							});

							this.hls.loadSource(this.streamUrl);
							this.hls.attachMedia(this.$refs["video"]);
						}else{
							console.log("hls is not null")
						}

					} else {
						this.streamLoading = false;
						this.streamStatus = "FAILED_NOT_FOUND";
					}
				}
			},
			async pingStreamAction() {
				console.log("PING STERAM");
				const queryString = new URLSearchParams({
					serialNumber: this.serialNumber,
					camera: this.cameraType,
				}).toString();

				const result = await getGPSVideoStreamingService(
					this.$store,
					queryString
				);
			},
			async stopAction(force) {
				this.streamLoading = false;
				if (this.streamPingInterval != null) {
					clearInterval(this.streamPingInterval);
					this.streamPingInterval = null;
				}
				if (this.hls != null) {
					if (this.$refs["video"]) {
						this.$refs["video"].pause();
					}
					this.hls.destroy();
					this.hls = null;
				}
				
				if(force){
					console.log("Force")
					const queryString = new URLSearchParams({
						serialNumber: this.serialNumber,
						camera: this.cameraType,
					}).toString();

					const result = await stopGPSVideoStreamingService(
						this.$store,
						queryString
					);
					console.log(result);
				}else{
					console.log("Not Force");
				}

				this.streamStatus = "STOP";
				this.streamUrl = null;
			},
			async refreshAction() {
				this.stopAction(false);
				this.startStreamAction();
			},
			async focusAction() {
				this.focusPlayerCallBack(this.listIndex)
			},
			async deleteAction() {
				this.stopAction(false);
				if(this.isFocus) {
					this.deletePlayerCallBack(this.listIndex);
				} else {
					this.deletePlayerCallBack(this.listIndex);
				}
			},
		},
		components: {},
	};
</script>

<style scoped>
	.player-status {
		color: #000000;
	}
	.card-player {
		width:100%;
	}
	.video-player {
		width:100%;
	}
	.main-div {
		border-style: dotted;
		width:100%;
	}
</style>

