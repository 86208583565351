<template>
	<v-row class="mt-2">
		<v-col class="py-0" cols="12">
			<v-subheader v
				>รายการใบตรวจ (ทั้งหมด {{ checklistItems.length }} ข้อ)</v-subheader
			>
		</v-col>
		<v-col cols="12">
			<!-- <AppAutoCompleteLocalSearching
				:tableLoading="isLoading"
				ref="AppAutoCompleteLocalSearchingRef"
				@searchItemChange="getSearchItemByKeyName"
				:isDark="false"
				class="pl-4 pb-3"
				:disabled="isItemsReOrder"
				:itemList="items"
			/> -->
			<v-row class="px-4">
				<v-col class="py-0 pr-0">
					<CustomTextInputImprove
						v-model="searchedChecklist"
						ref="checklistFilter"
						title=""
						:clearable="true"
						:hideDetail="true"
						:isShowTitle="false"
						dataInputName="checklistFilter"
						placeholder="ค้นหาโจทย์ใบตรวจ"
						:disabled="
							isItemsReOrder || isTypesReOrder || selectedType === null
						"
						:expend-margin="false"
					/>
				</v-col>
				<v-col class="py-0" align="right" cols="auto">
					<v-btn
						color="primary"
						:outlined="!isItemsReOrder"
						class="ml-0"
						fab
						small
						depressed
						:disabled="
							isSearchingItem || isTypesReOrder || selectedType === null
						"
						@click="rePosition()"
						><v-icon>mdi-sort</v-icon></v-btn
					>
					<v-btn
						:disabled="
							isItemsReOrder || isTypesReOrder || selectedType === null
						"
						@click="handleAddNewItem()"
						class="ml-2"
						fab
						small
						depressed
						><v-icon>mdi-plus</v-icon></v-btn
					></v-col
				>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-list two-line v-if="fetchDataError === 0">
				<v-list-item-group v-model="selectedChecklist">
					<draggable
						v-model="filteredItems"
						:disabled="!isItemsReOrder && !isTypesReOrder"
						ghost-class="ghost"
						@start="drag = true"
						@end="drag = false"
						@change="changeMove"
						handle=".handle"
					>
						<template v-for="(item, index) in filteredItems">
							<v-row no-gutters class="handle">
								<!-- <v-divider
								v-if="item.divider"
								:key="index"
								:inset="item.inset"
							></v-divider> -->
								<v-col cols="12">
									<v-list-item :key="item.name">
										<v-list-item-content class="text-app-title py-0">
											<span
												class="my-0 font-weight-bold"
												v-html="item.name"
											></span>
											<v-list-item-subtitle
												class="my-0"
												v-html="item.detail"
											></v-list-item-subtitle>
											<!-- <v-icon class="handle"> mdi-reorder-horizontal </v-icon> -->
										</v-list-item-content>
									</v-list-item>
								</v-col>
								<v-col cols="12">
									<v-divider />
								</v-col>
							</v-row>
						</template>
					</draggable>
				</v-list-item-group>
			</v-list>
			<v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
				กำลังดึงข้อมูล...
			</v-card-text>
			<v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
				ไม่พบข้อมูล
			</v-card-text>
			<v-card-text
				:class="['pa-5 text-center']"
				v-if="fetchDataError === 3 && !isTypesReOrder"
			>
				เลือกประเภทใบตรวจ เพื่อแสดงรายการใบตรวจ...
			</v-card-text>
		</v-col>
	</v-row>
</template>
<script>
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import draggable from "vuedraggable";
	import AppAutoCompleteLocalSearching from "@/components/AppAutoCompleteLocalSearching.vue";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	export default {
		name: "vehicleCreateCheckListTable",
		components: {
			PrimaryButton,
			draggable,
			AppAutoCompleteLocalSearching,
			CustomTextInputImprove,
		},
		props: {
			checklistItems: {
				type: Array,
				default: () => [],
			},

			isTypesReOrder: {
				type: Boolean,
				default: false,
			},

			selectedType: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				isLoading: false,
				selectedChecklist: null,
				// isAddingNewChecklist: false,
				isItemsReOrder: false,
				reOrderText: "เรียงลำดับใหม่",
				searchedChecklist: null,
				isSearchingItem: false,
				itemList: [],
				filteredItems: [],
				orderList: [],
				fetchDataError: 3,
				// draggedIndex: null,
			};
		},
		watch: {
			isItemsReOrder(val) {
				this.$emit("is-reorder", val);
			},

			checklistItems(val) {
				// sort the items
				this.filteredItems = [];
				this.itemList = _.cloneDeep(val);
				this.itemList.sort((a, b) => a.order - b.order);

				this.searchedChecklist = null;
				this.selectedChecklist = null;
				this.filteredItems = _.cloneDeep(this.itemList);
				this.processOriOrderList();
				// this.processChecklistItems(this.itemList);
			},

			selectedChecklist(val) {
				this.$emit("selectedChecklist", this.filteredItems[val]);
			},

			filteredItems(val) {
				if (!_.isNil(val) && val.length === 0) {
					if (this.isSearchingItem) {
						this.fetchDataError = 2;
					} else if (_.isNil(this.selectedType)) {
						this.fetchDataError = 3;
					} else {
						this.fetchDataError = 1;
					}
				} else {
					this.fetchDataError = 0;
				}
			},

			searchedChecklist(key) {
				this.filteredItems = [];
				if (!_.isNil(key)) {
					this.itemList.forEach((e) => {
						let searchPos = e.name.search(key);
						if (searchPos !== -1) {
							this.filteredItems.push(e);
						}
					});
					if (key.length == 0) {
						this.isSearchingItem = false;
					} else {
						this.isSearchingItem = true;
					}
					// if (this.filteredItems.length > 1) {
					// 	this.filteredItems.shift();
					// }
				} else {
					this.filteredItems = _.cloneDeep(this.itemList);
					this.isSearchingItem = false;
				}
			},
		},
		methods: {
			// onDragStart(index) {
			// 	this.draggedIndex = index;
			// },
			// onDrop() {
			// 	// Reorder the list based on the dragged item
			// 	const draggedItem = this.items.splice(this.draggedIndex, 1)[0];
			// 	this.items.splice(this.$vuetify.draggedIndex, 0, draggedItem);

			// 	// Reset the draggedIndex
			// 	this.draggedIndex = null;
			// },
			// processChecklistItems(checklists) {
			// 	console.log("checklists", checklists);
			// 	// reset
			// 	this.items = [];
			// 	this.selectedChecklist = null;
			// 	let item = null;
			// 	let title = null;
			// 	let subtitle = null;
			// 	for (let i = 0; i < checklists.length; i++) {
			// 		title = checklists[i]["name"];
			// 		subtitle = checklists[i]["detail"];
			// 		item = { title: title, subtitle: subtitle };
			// 		this.items.push(item);
			// 	}
			// 	this.filteredItems = _.cloneDeep(this.items);
			// },

			// getSearchItemByKeyName(key) {},

			processOriOrderList() {
				this.orderList = [];
				for (let i = 0; i < this.filteredItems.length; i++) {
					let _id = this.filteredItems[i]["_id"];
					this.orderList.push(_id);
				}
			},

			changeMove: function (e) {
				// this.showTextLoading = true;
				// this.getNewDirection();
			},
			rePosition() {
				// available only when the searching text box is clear
				if (this.isItemsReOrder) {
					this.isItemsReOrder = false;
					this.processOriOrderList();
					this.$emit("orderListOnChange", this.orderList);
				} else {
					this.selectedChecklist = null;
					this.searchedChecklist = null;
					this.isItemsReOrder = true;

					// this.reOrderText = "ยืนยันการเรียงลำดับ";
				}
			},

			handleAddNewItem() {
				this.selectedChecklist = null;
				this.searchedChecklist = null;
				// this.isAddingNewChecklist = !this.isAddingNewChecklist;
				// this.$emit("addNewItem", true);
			},
		},
	};
</script>
<style scoped>
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}

	.handle:hover {
		cursor: pointer;
		/* color: #2196f3; */
	}
</style>
