<template>
  <CustomCardFormViewModern
    title="QR Code"
    class="pb-1"
    :elevation="0"
    :isShowTitle="false"
    :setPaddingToCardText="true"
    align="center"
  >
    <div class="qr-container" v-if="data">
      <div style="opacity: 0; position: absolute; z-index: 0">
        <div ref="qrContainer">
          <qrcode-vue :value="data._id" :size="size" level="H" />
          <p class="qr-text">{{ data.licenseNumber }}</p>
        </div>
      </div>

      <div ref="imageQrContainer" style="z-index: 10"></div>
    </div>
  </CustomCardFormViewModern>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import _ from "lodash";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";

export default {
  name: "VehicleEmbededQRCode",
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => {
    return {
      size: 250,
    };
  },
  mounted() {},
  beforeDestroy() {},
  watch: {
    data: {
      immediate: false,
      handler: function (newValue) {
        this.renderAsImage();
      },
    },
  },
  methods: {
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async renderAsImage() {
      try {
        if (_.isNil(this.data)) {
          return;
        }

        await this.timeout(1000);

        html2canvas(this.$refs.qrContainer, {
          backgroundColor: "#fff",
        }).then((canvas) => {
          const img = new Image();
          img.src = canvas.toDataURL("image/png");
          img.style.maxWidth = "100%";

          this.$refs.imageQrContainer.innerHTML = "";
          this.$refs.imageQrContainer.appendChild(img);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: { CustomCardFormViewModern, QrcodeVue },
};
</script>

<style lang="scss" scoped>
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  // border: 1px solid #ccc;
  // border-radius: 8px;
}

.qr-text {
  margin: 0px 10px;
  font-size: 24px;
  font-weight: 700;
}
</style>
