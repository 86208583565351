<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :name="dataInputName"
        slot="activator"
        prepend-inner-icon="mdi-calendar"
        v-model="dateFormatted"
        :placeholder="placeholder"
        v-on="on"
        dense
        outlined
        :rules="rules"
        :disabled="disabled"
        :readonly="true"
        class="app-custom-input"
        :clearable="clearable"
        @click:clear="valueInput = null"
        :label="label"
        :error-messages="errorMsg"
        :background-color="getBackgroundColor()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="valueInput"
      no-title
      @input="open = false"
      :min="minDate"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "CustomDatePickerInput",

  components: {},

  data: () => ({
    dateFormatted: null,
    dateMin: null,
    open: false,
  }),

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    dataInputName: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMassage: {
      type: String,
      default: "",
    },
    minDate: null,
  },

  computed: {
    valueInput: {
      get() {
        this.dateFormatted = this.formatDate(this.value);
        return this.value;
      },
      set(val) {
        this.dateFormatted = this.formatDate(val);
        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
    errorMsg: {
      get() {
        return this.errorMassage;
      },
      set(val) {},
    },
  },

  methods: {
    getBackgroundColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
