<template>
  <div>
    <CustomCardFormViewModern
      title="ประวัติครอบครัว"
      :isShowTitle="true"
      :isTitleHighlight="true"
    >
      <v-form ref="familyInformationForm" v-model="valid">
        <v-row no-gutters class="px-0">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.fatherFullName"
                    ref="fatherName"
                    title="ชื่อ-นามสกุล บิดา"
                    inputType="thOnlyWithSpace"
                    placeholder="ชื่อ-นามสกุล บิดา"
                    :disabled="!enableEdit"
                    dataInputName="fatherName"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.fatherAge"
                    ref="fatherAge"
                    title="อายุของบิดา"
                    :disabled="!enableEdit"
                    placeholder="อายุของบิดา"
                    inputType="numberWithoutSign"
                    dataInputName="fatherAge"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.fatherJob"
                    ref="fatherJob"
                    title="อาชีพของบิดา"
                    :disabled="!enableEdit"
                    placeholder="อาชีพของบิดา"
                    dataInputName="fatherJob"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.motherFullName"
                    ref="motherName"
                    title="ชื่อ-นามสกุล มารดา"
                    inputType="thOnlyWithSpace"
                    placeholder="ชื่อ-นามสกุล บิดา"
                    :disabled="!enableEdit"
                    dataInputName="motherName"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.motherAge"
                    ref="motherAge"
                    title="อายุของมารดา"
                    :disabled="!enableEdit"
                    placeholder="อายุของมารดา"
                    inputType="numberWithoutSign"
                    dataInputName="motherAge"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.motherJob"
                    ref="motherJob"
                    title="อาชีพของมารดา"
                    :disabled="!enableEdit"
                    placeholder="อาชีพของมารดา"
                    dataInputName="motherJob"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.coupleFullName"
                    ref="coupleName"
                    title="ชื่อ-นามสกุล คู่สมรส"
                    inputType="thOnlyWithSpace"
                    placeholder="ชื่อ-นามสกุล คู่สมรส"
                    :disabled="!enableEdit || isSingle"
                    dataInputName="coupleName"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.coupleAge"
                    ref="coupleAge"
                    title="อายุของคู่สมรส"
                    :disabled="!enableEdit || isSingle"
                    placeholder="อายุของคู่สมรส"
                    inputType="numberWithoutSign"
                    dataInputName="coupleAge"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.coupleJob"
                    ref="coupleJob"
                    title="อาชีพของคู่สมรส"
                    :disabled="!enableEdit || isSingle"
                    placeholder="อาชีพของคู่สมรส"
                    dataInputName="coupleJob"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.coupleWorkPlace"
                    ref="coupleWorkPlace"
                    title="สถานที่ทำงานของคู่สมรส"
                    :disabled="!enableEdit || isSingle"
                    placeholder="สถานที่ทำงานของคู่สมรส"
                    dataInputName="coupleWorkPlace"
                  /> </v-col
                ><v-col cols="12" lg="4" sm="6">
                  <CustomSelectsImprove
                    ref="children"
                    v-model="formData.children"
                    placeholder="เลือก"
                    title="มีบุตร"
                    :items="['ไม่มี', 'มี']"
                    :disabled="!enableEdit"
                    dataInputName="children"
                  />
                </v-col>
                <v-col cols="12" lg="4" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.numberChildren"
                    ref="numberChildren"
                    title="จำนวนบุตร"
                    placeholder="จำนวนบุตร"
                    inputType="numberWithoutSign"
                    :disabled="!enableEdit || formData.children !== 'มี'"
                    dataInputName="numberChildren"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- <div class="mt-6 mb-4">
          <v-divider></v-divider>
        </div> -->
      </v-form>
      <AppSubmitFormButton
        @on-click-save="saveChange"
        v-if="enableEdit"
        :disabled="submitBtnState"
        text="บันทึกประวัติครอบครัว"
      />
    </CustomCardFormViewModern>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import AddressFormView from "./AddressFormView.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";

export default {
  name: "FamilyInformationForm",
  components: {
    CustomTextInput,
    CustomTextarea,
    CustomAutocomplete,
    AddressFormView,
    CustomSelects,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    CustomSelectsImprove,
    AppSubmitFormButton,
    AppFilesUpload,
  },

  props: {
    canEdit: true,
    userId: {
      type: [String, null],
      default: null,
    },
    data: {
      type: [Object, null],
      default: null,
    },
  },

  data() {
    return {
      // formdata
      valid: false,
      submitBtnState: false,
      formData: {
        fatherFullName: null,
        fatherAge: null,
        fatherJob: null,
        motherFullName: null,
        motherAge: null,
        motherJob: null,
        coupleFullName: null,
        coupleAge: null,
        coupleJob: null,
        coupleWorkPlace: null,
        children: null,
        numberChildren: null,
      },
      isSingle: null,
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
    };
  },

  computed: {
    enableEdit: {
      get() {
        return this.canEdit;
      },
    },
  },

  watch: {
    data(newValue) {
      this.processData(newValue);
    },

    "$store.state.employee.idCardData": function (newValue) {
      if (!_.isNil(newValue)) {
        if (!_.isNil(newValue["maritalStatus"])) {
          if (newValue["maritalStatus"] === "โสด") {
            this.isSingle = true;
          } else {
            this.isSingle = false;
          }
        }
      }
    },
  },

  methods: {
    checkLoadingStatus(loadingStatus) {
      this.submitBtnState = loadingStatus;
    },

    processData(_vd) {
      if (_.isPlainObject(_vd)) {
        this.formData = _.clone(_vd);
        this.checkMaritalStatus();
      } else {
        this.canEdit = false;
      }
    },

    checkMaritalStatus() {
      if (!_.isNil(this.$store.state.employee.idCardData)) {
        if (!_.isNil(this.$store.state.employee.idCardData.maritalStatus)) {
          if (this.$store.state.employee.idCardData.maritalStatus === "โสด") {
            this.isSingle = true;
          } else {
            this.isSingle = false;
          }
        }
      }
    },

    redictToInvalid() {
      this.$refs.familyInformationForm.$el.scrollIntoView();
      return true;
    },

    saveChange() {
      if (this.$refs.familyInformationForm.validate() === true) {
        let responseData = _.cloneDeepWith(this.formData);
        // console.log("responseData", responseData);
        this.$emit("on-click-save", responseData);
      } else {
        this.redictToInvalid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 16px;
  color: rgb(77, 86, 86);
  font-weight: bold;
}

.text-age {
  font-size: 14px;
  color: var(--v-bodytext);
  font-weight: normal;
}

.backgroud-image {
  background: #f4f6f6;
}
</style>
