<template>
  <div name="vehicleDataManage">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />
    <v-container>
      <div
        name="vehicleInformationForm"
        v-if="isPermissionCanView"
        class="mb-5"
      >
        <v-form ref="form" v-model="valid">
          <CustomCardFormViewModern
            :title="title"
            :isShowTitle="true"
            :isTitleHighlight="true"
          >
            <v-row no-gutters class="px-6">
              <v-col cols="12">
                <v-card elevation="0" class="px-0">
                  <v-row no-gutters>
                    <v-col cols="12" lg="6" sm="6">
                      <!-- <AppAutoCompleteVehicle
                        v-model="formData.vehicle"
                        title="ป้ายทะเบียนรถ"
                        :isRequired="true"
                        :disabled="!isPermissionCanEdit"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุป้ายทะเบียนรถ']"
                      /> -->

                      <InputView
                        class="px-3"
                        title="ป้ายทะเบียนรถ"
                        :isRequired="true"
                      >
                        <AppComboboxVehicleSearching
                          ref="AppAutoCompleteVehicleSearchingRef"
                          v-model="vehicleSearch"
                          :disabled="!isPermissionCanEdit"
                          :isRequired="true"
                          :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                        />
                      </InputView>
                    </v-col>

                    <v-col cols="12" lg="6" sm="6">
                      <CustomSelectsArrOfObj
                        title="ประเภทรายการ"
                        :isRequired="true"
                        :items="vehicleEventTypeList"
                        v-model="formData.type"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุประเภทรายการ']"
                        :disabled="!isPermissionCanEdit"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" sm="6">
                      <CustomTextInput
                        v-model="formData.title"
                        title="ชื่อรายการ"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อรายการ']"
                        :isRequired="true"
                        :disabled="!isPermissionCanEdit"
                      />
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" lg="12" sm="12">
                      <CustomTextareaImprove
                        title="รายละเอียด"
                        v-model="formData.detail"
                        :disabled="!isPermissionCanEdit"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <AppFormCardFooter @on-click-save="saveChange()" />
          </CustomCardFormViewModern>
        </v-form>
      </div>
      <AppOverlay :isLoading="isLoading" />
      <AppDialogConfirm ref="alertSaving" />
      <AppDialogConfirm ref="dialogAlert" />
      <AppPageNotFound :show="isPermissionCanView" />
    </v-container>
  </div>
</template>
<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import _ from "lodash";
import AppOverlay from "@/components/AppOverlay.vue";
import { DialogType } from "@/services/dialog";
import { errorMessageMapper } from "@/services/error";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";

//
import AppFormCardFooter from "@/components/AppFormCardFooter.vue";
import AppAutoCompleteVehicle from "@/components/AppAutoCompleteVehicle.vue";
import InputView from "@/components/InputView.vue";
import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import {
  getVehicleEventInfoService,
  postVehicleEventService,
  putVehicleEventService,
} from "@/services/api/vehicle_event";
import { ProcessSelectListFormatHelpper } from "@/services/arrayAndObjHelpper";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import { URL_VEHICLE_DOC_EVENT_LIST } from "@/services/routerQueryPath";

export default {
  name: "VehicleEventManage",
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    AppOverlay,
    AppDialogConfirm,
    AppBreadCrumbs,
    AppFormCardFooter,
    AppAutoCompleteVehicle,
    CustomTextInput,
    CustomTextareaImprove,
    CustomSelectsArrOfObj,
    CustomTextInput,
    AppPageNotFound,
    InputView,
    AppComboboxVehicleSearching,
  },
  data() {
    return {
      title: "",
      isLoading: false,
      disabledAll: false,
      breadcrumbsItems: [
        {
          text: "รายการซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ",
          disabled: false,
          exact: true,
          to: `/vehicle${URL_VEHICLE_DOC_EVENT_LIST}`,
        },
        {
          text: "จัดการข้อมูลรายการ",
          disabled: true,
          to: "",
        },
      ],
      isFail: false,
      fundData: {},
      //
      valid: false,
      _id: null,
      formData: {
        type: null,
        vehicle: null,
        title: null,
        detail: null,
      },
      //
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      //
      vehicleSearch: null,
      permList: {},
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  watch: {
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },

    vehicleSearch(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          this.formData["vehicle"] = _.cloneDeep(value);
        } else {
          this.formData["vehicle"] = null;
        }
      }
    },
  },
  methods: {
    async saveChange() {
      if (this.$refs.form.validate() === true) {
        this.isLoading = true;
        if (this._id !== null) {
          // for update data
          let respData = await putVehicleEventService(
            this.$store,
            this._id,
            this.formData
          );
          if (respData["code"] === 200) {
            // update success
            this.isLoading = false;
            this.alertSaveChange(this._id);
            this.getData();
          } else {
            // update fail
            this.isLoading = false;
            this.alertErrorDialog();
          }
        } else {
          let respData = await postVehicleEventService(
            this.$store,
            this.formData
          );
          this.isLoading = false;
          if (respData["code"] === 200) {
            // update success
            this.alertSaveChange(null);
            this.$router.push(
              `/vehicle/event/detail/${respData["data"]["eventId"]}`
            );
          } else {
            // update fail
            this.alertErrorDialog();
          }
        }
      }
    },
    // ==== for app upload ====
    async getData() {
      this.isLoading = true;
      // for edit data
      if (this._id !== null) {
        //
        let uriParams = new URLSearchParams({
          id: this._id,
          type: "input",
        }).toString();

        let respData = await getVehicleEventInfoService(this.$store, uriParams);

        if (respData["code"] === 200) {
          this.formData = _.clone(respData["data"]);
          // console.log(this.formData["vehicle"]);
          if (!_.isNil(this.formData["vehicle"])) {
            this.vehicleSearch = _.cloneDeep(this.formData["vehicle"]);
          }
        } else {
          this.isFail = true;
          return;
        }
      }
      this.isLoading = false;
    },

    startPage() {
      if (this.$route.params.id !== undefined) {
        this.title = "แก้ไขข้อมูลซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ";
        this._id = this.$route.params.id;
        if (this.isPermissionCanView) {
          this.getData();
        }
      } else {
        this._id = null;
        this.title = "เพิ่มข้อมูลซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ";
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        for (let i = 0; i < getPermission.length; i++) {
          this.permList[getPermission[i].toLowerCase()] = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.vehicle_info_crud === true
        ) {
          this.disabledAll = false;
        } else {
          this.disabledAll = true;
        }
      }
    },

    async alertErrorDialog() {
      await this.$refs.dialogAlert.open(null, null, DialogType.ERROR, {
        noconfirm: false,
      });
    },

    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    //
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_REPARATION");

        if (
          this.permList.admin_all === true ||
          this.permList.vehireparation_list === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
  },
  computed: {
    vehicleEventTypeList() {
      const typeList = this.$store.getters["vehicle/vehicleEventTypeObj"];
      let _items = ProcessSelectListFormatHelpper(typeList);
      return _items;
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.startPage();
  },
};
</script>

<style scoped>
.container {
  max-width: 1500px;
}
</style>
