import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const employee = {
  namespaced: true,
  state: {
    addressData: null,
    idCardData: null,
    currentEmployeeData: null,
    vehicleStatusColor: {
      null: "white--text",
    },
    empStatusColor: {
      ACTIVE: "success--text",
      PROBATION: "primary--text",
      RESIGNED: "orange--text",
      FIRED: "red--text",
      null: "white--text",
    },
    empTimeOffStatusTextColor: {
      APPROVED: "success--text",
      NOT_APPROVED: "red--text",
      CANCELED: "secondary--text",
      NEW: "orange--text",
      null: "white--text",
    },
    empTimeOffStatusColor: {
      APPROVED: "success",
      NOT_APPROVED: "red",
      NEW: "orange",
      CANCELED: "secondary",
      null: "white--text",
    },
    empPenaltyStatusColor: {
      YES: "red",
      NO: "secondary",
      null: "white--text",
    },
    empPenaltyStatusTextColor: {
      YES: "red--text",
      NO: "secondary--text",
      null: "white--text",
    },
    empStatusCardColor: {
      APPLIED: "subtitle text--darken-2",
      REJECTED: "subtitle text--darken-4",
      PROBATION: "primary",
      ACTIVE: "success",
      RESIGNED: "orange",
      TERMINATED: "error text--lighten-1",
      FIRED: "error text--darken-1",
      // DELETE: "",
      null: "white",
    },

    empStatusTextColor: {
      APPLIED: "subtitle--text text--darken-2",
      REJECTED: "subtitle--text text--darken-4",
      PROBATION: "primary--text",
      ACTIVE: "success--text",
      RESIGNED: "orange--text",
      TERMINATED: "error--text text--lighten-1",
      FIRED: "error--text text--darken-1",
      // DELETE: "",
      null: "white",
    },

    vehicleStatusText: {
      null: "ไม่มีข้อมูล",
      empty: "ว่าง",
      running: "วิ่ง",
      maintain: "บำรุงรักษา",
    },
    empStatusText: {
      // ACTIVE: "ทำงาน",
      // PROBATION: "ทดลองงาน",
      // RESIGNED: "ลาออก",
      // FIRED: "ไล่ออก",
      // DELETE: "ลบ",

      APPLIED: "ขอสมัคร",
      REJECTED: "ไม่รับ",
      PROBATION: "ทดลองงาน",
      ACTIVE: "ทำงาน",
      RESIGNED: "ลาออก",
      TERMINATED: "เลิกจ้าง",
      FIRED: "ไล่ออก",
      DELETE: "ลบ",
    },
    empTimeOffTypeText: {
      ALL: "ทั้งหมด",
      PERSONAL: "ลากิจ",
      SICK: "ลาป่วย",
      VACATION: "ลาพักร้อน",
      BIRTH: "ลาคลอด",
      ORDAIN: "ลาบวช",
    },
    empTimeOffStatusText: {
      // ACTIVE: "อนุมัติ",
      // PROBATION: "ไม่อนุมัติ",
      ALL: "ทั้งหมด",
      APPROVED: "อนุมัติ",
      NOT_APPROVED: "ไม่อนุมัติ",
      NEW: "รออนุมัติ",
      CANCELED: "ยกเลิก",
    },
    empPenaltyStatusText: {
      YES: "ผิดจริง",
      NO: "ไม่ผิดจริง",
    },
  },
  getters: {
    empStatusTextList: (state) => Object.keys(state.empStatusText),
    empTimeOffTypeTextList: (state) => Object.keys(state.empTimeOffTypeText),
    vehicleStatusList: (state) =>
      Object.keys(
        Object.fromEntries(
          Object.entries(state.vehicleStatusText).map((a) => a.reverse())
        )
      ).filter((value) => value !== "ไม่มีข้อมูล"),
    empStatusList: (state) =>
      Object.keys(
        Object.fromEntries(
          Object.entries(state.empStatusText).map((a) => a.reverse())
        )
      ),
    empTimeOffTypeList: (state) =>
      Object.keys(
        Object.fromEntries(
          Object.entries(state.empTimeOffTypeText).map((a) => a.reverse())
        )
      ),
    vehicleStatusColor: (state) => (key) => state.vehicleStatusColor[key],
    empStatusColor: (state) => (key) => state.empStatusColor[key],
    empTimeOffStatusColor: (state) => (key) => state.empTimeOffStatusColor[key],
    empTimeOffStatusTextColor: (state) => (key) =>
      state.empTimeOffStatusTextColor[key],
    empStatusCardColor: (state) => (key) => state.empStatusCardColor[key],
    empStatusTextColor: (state) => (key) => state.empStatusTextColor[key],
    vehicleStatusText: (state) => (key) => state.vehicleStatusText[key],
    empStatusText: (state) => (key) => state.empStatusText[key],
    empTimeOffTypeText: (state) => (key) => state.empTimeOffTypeText[key],
    empTimeOffStatusText: (state) => (key) => state.empTimeOffStatusText[key],
    empTimeOffStatusObj: (state) => state.empTimeOffStatusText,
    empPenaltyStatusColor: (state) => (key) => state.empPenaltyStatusColor[key],
    empPenaltyStatusTextColor: (state) => (key) => state.empPenaltyStatusTextColor[key],
    empPenaltyStatusText: (state) => (key) => state.empPenaltyStatusText[key],
    vehicleStatusTextInvert: (state) => (key) =>
      Object.fromEntries(
        Object.entries(state.vehicleStatusText).map((a) => a.reverse())
      )[key],
    empStatusTextInvert: (state) => (key) =>
      Object.fromEntries(
        Object.entries(state.empStatusText).map((a) => a.reverse())
      )[key],
  },
  mutations: {
    setCurrentEmployeeData(state, payload) {
      state.currentEmployeeData = payload;
    },
    setIdCardData(state, payload) {
      state.idCardData = payload;
    },
    setAddressData(state, payload) {
      state.addressData = payload;
    },
  },
  actions: {
    updateCurrentEmployeeData(context, payload) {
      context.commit("setCurrentEmployeeData", payload);
    },
    updateIDCardData(context, payload) {
      context.commit("setIdCardData", payload);
    },
    updateAddressData(context, payload) {
      context.commit("setAddressData", payload);
    },
  },
  modules: {},
};
