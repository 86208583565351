<template>
  <v-alert
    dense
    colored-border
    color="#9BB6E5"
    class="mb-2"
    icon="mdi-hammer-screwdriver"
    border="left"
    ><span class="text-title">ซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ</span>
    <span class="float-right">
      <router-link
        v-if="docData !== null"
        class="d-block text-decoration-none text-app-detail-normal mt-1 text-decoration-underline"
        :to="`/vehicle/events?text=${docData.licenseNumber}`"
        >ประวัติ
      </router-link>
    </span>
    <div v-if="docData.eventCount !== null || docData.eventCount !== 0">
      <!-- <div class="detail-section pb-1">
        <span>ซ่อม/บำรุงมาแล้ว </span>
      </div> -->

      <span class="text-h6 font-weight-bold">
        {{ docData.eventCount }} ครั้ง</span
      >

      <div class="detail-section">
        <span class="text-app-small-detail">
          ค่าใช้จ่ายทั้งหมด {{ docData.eventTotalAmount }} บาท</span
        >
      </div>
    </div>
    <div v-else class="text-app-small-detail">ไม่มีข้อมูล</div>
  </v-alert>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    docData: {
      get() {
        return this.data || null;
      },
      set(val) {},
    },
  },
};
</script>

<style scoped>
.detail-section {
  font-size: 14px;
  color: var(--v-bodyText);
}
</style>
