<template>
  <div name="WorksheetPaymentOverAllDashboardView">
    <v-container v-if="isPermissionCanView">
      <v-row>
        <v-col col="6">
          <div class="text-h5 font-weight-bold mt-1">
            สัดส่วนด้านการเงินทั้งหมด
          </div>
          <!-- <p class="ext-app-detail-md ma-0" v-if="data">
            ข้อมูลล่าสุด:
            {{
              dateStringToDateFormat(data.date, {
                returnThai: true,
              })
            }}
          </p> -->
        </v-col>
        <v-col cols="6">
          <div class="d-flex justify-end mt-3">
            <div class="mr-2">
              <CustomDatePickerInput
                data-input-name="reportStartDate"
                label="เริ่มต้น"
                :clearable="false"
                :value="startDate"
                @change="startDateHandler"
              />
            </div>

            <div class="mr-2">
              <CustomDatePickerInput
                data-input-name="reportStartDate"
                label="สิ้นสุด"
                :clearable="false"
                :value="endDate"
                @change="endDateHandler"
              />
            </div>
          </div>
        </v-col>
      </v-row>

      <div ref="paymetExpenseSbc">
        <v-card class="mb-3">
          <v-card-text>
            <v-row>
              <v-col cols="5">
                <p class="pa-4 dashboard-text-sm font-weight-bold black--text">
                  รายจ่ายรายหมวด
                </p>
                <PaymentExpensesStackBarChart
                  :data="reportData"
                  :parent-height-chart="parentHeightChart"
                />
              </v-col>
              <v-col cols="7">
                <p
                  class="pa-4 dashboard-text-sm font-weight-bold black--text"
                ></p>
                <PaymentExpensesWeeklyLineChart :data="reportData" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-card class="mb-3">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="pa-4 dashboard-text-sm font-weight-bold black--text">
                รายรับ / รายจ่าย ต่อสัญญา
              </p>
              <PaymentDriverContractStackBarChart :data="reportData" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <AppOverlay :isLoading="isLoading" />
    <AppPageNotFound :show="isPermissionCanView" />
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import CustomDatePickerInput from "@/components/CustomDatePickerInput.vue";
import PaymentExpensesStackBarChart from "./PaymentExpensesStackBarChart.vue";
import PaymentDriverContractStackBarChart from "./PaymentDriverContractStackBarChart.vue";

import {
  appDateYearMonthDayWithEnDashFormat,
  dateStringToDateFormat,
} from "@/services/appDate";
import { getWorksheetPaymentOverallReport } from "@/services/api/worksheet_report";
import AppOverlay from "@/components/AppOverlay.vue";
import PaymentExpensesWeeklyLineChart from "./PaymentExpensesWeeklyLineChart.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";

export default {
  name: "WorksheetPaymentOverAllDashboardView",
  components: {
    PaymentExpensesStackBarChart,
    PaymentDriverContractStackBarChart,
    CustomDatePickerInput,
    AppOverlay,
    PaymentExpensesWeeklyLineChart,
    AppPageNotFound,
  },

  data() {
    return {
      isLoading: false,
      reportData: null,
      uriQueryObj: {
        startdate: null,
        enddate: null,
      },
      data: null,
      startDate: null,
      endDate: null,
      parentHeightChart: 0,
      isPermissionCanView: false,
      permList: {
        payment_view_total_report: false,
      },
      durationRange: 30,
    };
  },

  computed: {},

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    startPage() {
      //
      if (Object.keys(this.$route.query).length > 0) {
        Object.keys(this.$route.query).forEach((key) => {
          if (!_.isNil(this.$route.query[key])) {
            this.uriQueryObj[key] = this.$route.query[key];
          }
        });
      }
      this.validateQueryDate();
      this.getData();
    },
    validateQueryDate() {
      if (!_.isNil(this.uriQueryObj["startdate"])) {
        this.startDate = this.uriQueryObj["startdate"];
      } else {
        this.startDate = moment()
          .subtract(this.durationRange, "days")
          .format(appDateYearMonthDayWithEnDashFormat);
        this.uriQueryObj["startdate"] = this.startDate;
      }

      if (!_.isNil(this.uriQueryObj["enddate"])) {
        this.endDate = this.uriQueryObj["startdate"];
      } else {
        this.endDate = moment().format(appDateYearMonthDayWithEnDashFormat);
        this.uriQueryObj["enddate"] = this.endDate;
      }

      this.setRouterQuery(this.uriQueryObj);
    },
    startDateHandler(value) {
      this.startDate = value;
      this.uriQueryObj["startdate"] = value;
      this.setRouterQuery(this.uriQueryObj);
      this.getData();
    },
    endDateHandler(value) {
      this.endDate = value;
      this.uriQueryObj["enddate"] = value;
      this.setRouterQuery(this.uriQueryObj);
      this.getData();
    },

    async getData() {
      this.isLoading = true;
      try {
        this.reportData = null;
        const queryParams = new URLSearchParams(this.uriQueryObj);
        const responseData = await getWorksheetPaymentOverallReport(
          this.$store,
          queryParams.toString()
        );
        if (responseData["code"] === 200) {
          this.reportData = responseData["data"];
        } else {
          throw "response failed";
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
      setTimeout(() => {
        this.parentHeightChart = this.$refs.paymetExpenseSbc.clientHeight || 0;
      }, 1000);
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        const getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        if (this.permList.admin_all === true) {
          this.isPermissionCanView = true;
        }
      }
    },
  },
  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.startPage();
    }
  },
};
</script>
