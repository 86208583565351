import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getOverAllVehicleReportService(store, queryString) {
  let uri = `/vehicle/report`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri, false);
}

export async function getVehicleReportInfoService(store, queryString) {
  let uri = `/vehicle/report-info`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri, false);
}
