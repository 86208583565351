<template>
  <v-app-bar
    flat
    app
    dense
    class="main-bg-color"
    v-if="['login', 'logout'].includes(currentRouteName) !== true"
  >
    <v-app-bar-nav-icon
      small
      v-if="$store.getters.userInfo"
      @click="toggleDrawerVuex"
    ></v-app-bar-nav-icon>
    <!-- <v-toolbar-title></v-toolbar-title> -->
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AppBar",
  data: () => ({}),
  watch: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(["toggleDrawerVuex"]),
    // async logoutAction() {
    //   await userLogout(this.$store);
    //   this.$router.push("/login");
    // },
  },
};
</script>

<style scoped>
.v-app-bar {
  border-bottom: 0.15em solid #e8edf5;
}
</style>
