<template>
  <div name="customTextarea" class="px-3">
    <InputView
    :title="title"
    :isRequired="isRequired"
    >
      <v-textarea
        class="custom-placeholer-color"
        v-model="valueInput"
        v-on:input="updateValue()"
        :placeholder="placeholder"
        :type="type"
        :background-color="getBackgrounddColor()"
        :height="height"
        :rules="[() => !!valueInput || 'จำเป็นต้องระบุ']"
        outlined
        dense
        :disabled="disabled"
      ></v-textarea>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";

export default {
  name: "customTextarea",

  components: {
    InputView,
  },

  props: {
    value: String,
    placeholder: String,
    title: String,
    isRequired: Boolean,
    type: String,
    height: Number,
    disabled: Boolean,
    rules: null,
  },

  data() {
    return {
      valueInput: this.value,
      disabledInput: this.disabled,
      rulesDefault: this.isRequired ? [(v) => !!v || "จำเป็นต้องระบุ"] : [],
      rulesItems: this.rulesDefault,
    };
  },

  watch: {
    disabled() {
      this.disabledInput = this.disabled;
    },

    rules() {
      this.rulesItems = this.rules;
    },

    valueInput() {
      if (!this.rules) {
        this.rulesItems = this.rulesDefault;
      }
    },

    value(newValue) {
      if (newValue != undefined && newValue != null) {
        this.valueInput = newValue;
      }
    },
  },

  methods: {
    updateValue() {
      this.$emit("input", this.valueInput);
    },

    getBackgrounddColor() {
     if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>

<style></style>
