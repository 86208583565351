import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import moment from "moment";
import { POINT_MARKER_ICON_CONFIG } from "@/services/map/mapSettings";

const processVehicleMarker = (payload) => {
  return {
    id: `currentVehicleData_${new Date().getTime()}`,
    position: { ...payload["position"] },
    icon: {},
    disableAnimation: true,
    vehicleAnchor: true,
  };
};

const processVehicleLine = () => {
  return {
    id: `veline_${new Date().getTime()}`,
    dashLine: false,
    gDirectionFormat: false,
    path: [],
    // geodesic: true,
    strokeColor: "#4FCAF8",
    strokeOpacity: 1.0,
    strokeWeight: 4,
    zIndex: 10,
  };
};

const processOverLimitSpeedLine = (dataIdx) => {
  return {
    id: `overlimitline_${new Date().getTime()}-${dataIdx}`,
    dashLine: false,
    gDirectionFormat: false,
    path: [],
    // geodesic: true,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    zIndex: 15,
  };
};

const processVehiclePolyLine = (dataPayLoad) => {
  if (!_.isNil(dataPayLoad["directionList"])) {
    let _vehicleLineList = processVehicleLine();
    for (let i = 0; i < dataPayLoad["directionList"].length; i++) {
      _vehicleLineList["path"].push({
        lat: dataPayLoad["directionList"][i]["lat"],
        lng: dataPayLoad["directionList"][i]["lng"],
      });
    }
    return [_vehicleLineList];
  } else {
    return [];
  }
};

const processOverLimitSpeedPolyLine = (dataPayLoad) => {
  let pairLineList = [];
  if (!_.isNil(dataPayLoad["overLimitSpeedPositionList"])) {
    for (let i = 0; i < dataPayLoad["overLimitSpeedPositionList"].length; i++) {
      let _LineList = processOverLimitSpeedLine(i);
      _LineList["path"].push({
        lat: dataPayLoad["overLimitSpeedPositionList"][i]["startLat"],
        lng: dataPayLoad["overLimitSpeedPositionList"][i]["startLng"],
      });
      _LineList["path"].push({
        lat: dataPayLoad["overLimitSpeedPositionList"][i]["endLat"],
        lng: dataPayLoad["overLimitSpeedPositionList"][i]["endLng"],
      });
      pairLineList.push(_LineList);
    }
    return pairLineList;
  } else {
    return [];
  }
};

const processOverLimitSpeedMarker = (dataPayLoad) => {
  let pairMarkerList = [];
  if (!_.isNil(dataPayLoad["overLimitSpeedPositionList"])) {
    let countDouble = 0;
    for (let i = 0; i < dataPayLoad["overLimitSpeedPositionList"].length; i++) {
      pairMarkerList.push({
        id: `ovsMarker_${new Date().getTime()}-${countDouble}`,
        position: {
          lat: dataPayLoad["overLimitSpeedPositionList"][i]["startLat"],
          lng: dataPayLoad["overLimitSpeedPositionList"][i]["startLng"],
        },
        disableAnimation: false,
        vehicleAnchor: false,
        label: {
          text: String(countDouble),
          fontSize: "18px",
          fontWeight: "700",
          color: "#ffffff",
        },
      });
      countDouble += 1;
      //
      pairMarkerList.push({
        id: `ovsMarker_${new Date().getTime()}-${countDouble}`,
        position: {
          lat: dataPayLoad["overLimitSpeedPositionList"][i]["endLat"],
          lng: dataPayLoad["overLimitSpeedPositionList"][i]["endLng"],
        },
        disableAnimation: false,
        vehicleAnchor: false,
        label: {
          text: String(countDouble),
          fontSize: "18px",
          fontWeight: "700",
          color: "#ffffff",
        },
      });
      countDouble += 1;
    }
    return pairMarkerList;
  } else {
    return [];
  }
};

Vue.use(Vuex);

export const manageRoute = {
  namespaced: true,
  state: {
    // SVG
    // - จุดเริ่ม (หมุดดำใหญ่)
    // - จุดสุดท้าย (ธง)
    // - จุด Check point (icon เช็กอินสีแดง)
    // - จุด Check point Highlight (icon เช็กอินสีเขียว)
    isEdit: 0,
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    forceGetData: false,
    mainLoading: false,
    mainGMapActive: false,
    createGMapActive: false,
    tabData: 0,

    // job data
    jobData: null,
    jobReport: null,
    // jobStatusData: null,
    jobDetailData: null,
    markerStatusData: null,
    markerListDuration: [],

    editMarkerUpdateTs: null,
    editMarkerRouteId: null,
    editMarkerRouteStartDate: null,
    editMarkerRouteStartDateUserAction: null,

    isActivateInput: false,
    isActivateForm: false,
    reloadMarker: 0,
    startDate: "",
    endDate: "",
    estimateEndDateFromMarker: null,
    estimateEndDateFromMarkerStr: null,
    // currentVehicleLocation: [
    //   {
    //     id: "currentVehicle",
    //     position: { lat: 13.28512228843949, lng: 100.9190655740224 },
    //     icon: POINT_MARKER_ICON_CONFIG,
    //   },
    // ],

    // for Map
    vehicleMarkerLocation: [],
    vehiclePolylineList: [],
    vehicleOverLimitSpeedList: [],
    vehicleOverLimitSpeedMarkerList: [],
    // center
    boundLatLngCenterList: [],
    // for marker
    markerList: [],
    currentFocusMarkerId: "",
    // for line
    polylineList: [],
    boundPloyLine: null,
  },
  getters: {
    getAllMarker(state) {
      // console.log(
      //   _.unionBy(state.currentVehicleLocation, state.markerList, "id")
      // );
      //
      return state.markerList;
      // return _.unionBy(state.currentVehicleLocation, state.markerList, "id");
    },
    getJobData(state) {
      return state.jobData;
    },
    getMoveToTab(state) {
      return state.tabData;
    },
  },
  mutations: {
    // main loading
    setMainLoading(state, payload) {
      state.mainLoading = payload;
    },
    setIsPermissionCanEdit(state, payload) {
      state.isPermissionCanEdit = payload;
    },
    setIsPermissionCanView(state, payload) {
      state.isPermissionCanView = payload;
    },
    // marker
    setMainGMapActive(state, payload) {
      state.mainGMapActive = payload;
    },
    setCreateGMapActive(state, payload) {
      state.createGMapActive = payload;
    },
    setMapMarker(state, payload) {
      state.markerList = payload;
    },
    setPolyline(state, payload) {
      state.polylineList = payload;
    },
    setBoundPloyLine(state, payload) {
      state.boundPloyLine = payload;
    },
    setVehiclePloyLine(state, payload) {
      state.vehiclePolylineList = payload;
    },
    setBoundLatLngCenter(state, payload) {
      state.boundLatLngCenterList = payload;
    },
    // set of marker

    // bottom detail
    setActivateInput(state, payload) {
      state.isActivateInput = payload;
    },
    setActiveForm(state, payload) {
      state.isActivateForm = payload;
    },
    startDateTime(state, payload) {
      state.startDate = payload;
    },
    endDateTime(state, payload) {
      state.endDate = payload;
    },
    setEstimateEndDateFromMarker(state, payload) {
      state.estimateEndDateFromMarker = payload;
    },
    setEstimateEndDateFromMarkerStr(state, payload) {
      state.estimateEndDateFromMarkerStr = payload;
    },
    // main data
    setMode(state, payload) {
      state.isEdit = payload;
    },
    setJobData(state, payload) {
      state.jobData = payload;
    },
    setJobReportData(state, payload) {
      state.jobReport = payload;
    },
    // setJobStatusData(state, payload) {
    //   state.jobStatusData = payload;
    // },
    setJobDetailData(state, payload) {
      state.jobDetailData = payload;
    },
    // marker
    setMarkerStatusData(state, payload) {
      state.markerStatusData = payload;
    },
    setForceGetDate(state, payload) {
      state.forceGetData = payload;
    },
    setMarkerListDuration(state, payload) {
      state.markerListDuration = payload;
    },
    setEditRouteMarkerId(state, payload) {
      state.editMarkerRouteId = payload;
    },
    setEditMarkerUpdateTs(state, payload) {
      state.editMarkerUpdateTs = payload;
    },
    setEditMarkerRouteStartDate(state, payload) {
      state.editMarkerRouteStartDate = payload;
    },
    setEditMarkerRouteStartDateUserAction(state, payload) {
      state.editMarkerRouteStartDateUserAction = payload;
    },
    //
    setVehiclePositionMarker(state, payload) {
      state.vehicleMarkerLocation = payload;
    },
    setOverLimitSpeedList(state, payload) {
      state.vehicleOverLimitSpeedList = payload;
    },
    setOverLimitSpeedMarker(state, payload) {
      state.vehicleOverLimitSpeedMarkerList = payload;
    },
    //
    setMoveToTab(state, payload) {
      state.tabData = payload;
    },
    setReloadMarker(state, payload) {
      state.reloadMarker = state.reloadMarker + 1;
    },
  },
  actions: {
    updateMainGMapActive(context, payload) {
      context.commit("setMainGMapActive", payload);
    },
    updateCreateGMapActive(context, payload) {
      context.commit("setCreateGMapActive", payload);
    },
    updateMainLoading(context, payload) {
      context.commit("setMainLoading", payload);
    },
    updateStartDate(context, payload) {
      context.commit("startDateTime", payload);
    },
    updateEndDate(context, payload) {
      context.commit("endDateTime", payload);
    },
    updateActivateMarker(context, payload) {
      context.commit("setActivateInput", payload);
    },
    //

    // map and marker view
    updateMarkerList(context, payload) {
      context.commit("setMapMarker", payload);
    },
    updatePolyLine(context, payload) {
      context.commit("setPolyline", payload);
    },
    updateBoundPloyLine(context, payload) {
      context.commit("setBoundPloyLine", payload);
    },
    updateVehiclePolyLine(context, payload) {
      context.commit("setVehiclePloyLine", payload);
    },
    updateBoundLatLngCenter(context, payload) {
      context.commit("setBoundLatLngCenter", payload);
    },
    updateforceGetData(context, payload) {
      context.commit("setForceGetDate", payload);
    },
    //
    updateEditMarkerRouteAction(context, payload) {
      context.commit("setEditMarkerRouteStartDate", payload["startDate"]);
      context.commit("setEditRouteMarkerId", payload["routeId"]);
      context.commit("setEditMarkerUpdateTs", new Date().getTime());
    },
    //
    updateJobAndStatusData(context, payload) {
      context.commit("setJobData", payload);
      if (!_.isNil(_.get(payload, "jobReport", null))) {
        context.commit("setJobReportData", payload["jobReport"]);
      }
      //
      //
      if (!_.isNil(_.get(payload, "vehiclePosition", null))) {
        context.commit("setVehiclePositionMarker", []);
        let marker = processVehicleMarker({
          position: payload["vehiclePosition"],
        });
        context.commit("setVehiclePositionMarker", [marker]);
      }
      //
      //
      const veLine = processVehiclePolyLine(payload);
      if (veLine.length !== 0) {
        context.commit("setVehiclePloyLine", veLine);
      }
    },

    updateJobStatus(context, payload) {
      let allowActive = ["PROCESS", "SUCCESS"];
      if (!_.isNil(context.state.jobData)) {
        if (allowActive.includes(context.state.jobData.status)) {
          context.commit("setJobDetailData", payload);
        }
        context.commit("setMarkerStatusData", _.pick(payload, ["markerList"]));
        if (!_.isNil(_.get(payload, "jobReport", null))) {
          context.commit("setJobReportData", payload["jobReport"]);
        }
      }
      // context.commit(
      //   "setJobStatusData",
      //   _.pick(payload, ["status", "jobStatusList", "driverId", "vehicleId"])
      // );
      // // allow active status
      // let allowActive = ["PROCESS", "SUCCESS"];
      // if (allowActive.includes(context.state.jobStatusData.status)) {
      //   context.commit("setJobDetailData", payload);
      // }

      // context.commit("setMarkerStatusData", _.pick(payload, ["markerList"]));
    },

    updateActivateForm(context, payload) {
      context.commit("setActiveForm", payload);
    },

    updateMoveToTab(context, payload) {
      // console.log("moveToTab:", payload);
      context.commit("setMoveToTab", payload);
    },

    updateVehiclePositionMarker(context, payload) {
      if (!_.isNil(payload["position"])) {
        context.commit("setVehiclePositionMarker", []);
        let marker = processVehicleMarker(payload);
        context.commit("setVehiclePositionMarker", [marker]);
      }

      const veLine = processVehiclePolyLine(payload);
      // console.log("veLine from update status", veLine);
      if (veLine.length !== 0) {
        context.commit("setVehiclePloyLine", veLine);
      }
    },

    updateActivateInput(context, payload) {
      // allow active status
      if (context.state.isPermissionCanEdit === true) {
        let allowActive = ["PLANNING"];
        if (!_.isNil(context.state.jobData)) {
          if (allowActive.includes(context.state.jobData["status"])) {
            context.commit("setActivateInput", payload);
          } else {
            context.commit("setActivateInput", false);
          }
        }
      }
    },

    updateJobData(context, payload) {
      // calculate endDate from marker where user not set endDate checkFromJobData
      if (!_.isNil(payload)) {
        context.commit("setJobData", {
          ...context.state.jobData,
          ...payload,
        });
      }
    },

    updateVehicleOverSpeedLimitLine(context, payload) {
      if (!_.isNil(payload)) {
        const overLimitSpeedList = processOverLimitSpeedPolyLine(payload);
        context.commit("setOverLimitSpeedList", overLimitSpeedList);
        // const overLimitSpeedMarkerList = processOverLimitSpeedMarker(payload);
        // context.commit("setOverLimitSpeedMarker", overLimitSpeedMarkerList);
      }
    },

    updateResetStore(context, payload) {
      if (payload === true) {
        context.commit("setActivateInput", false);
        context.commit("setIsPermissionCanEdit", false);
        context.commit("setIsPermissionCanView", false);
        context.commit("setVehiclePositionMarker", []);
        context.commit("setVehiclePloyLine", []);
        context.commit("setPolyline", []);
        context.commit("setMarkerListDuration", []);
        context.commit("setJobReportData", null);
        context.commit("startDateTime", null);
        context.commit("endDateTime", null);
        context.commit("setOverLimitSpeedList", []);
        context.commit("setOverLimitSpeedMarker", []);
        context.commit("setJobDetailData", null);
        context.commit("setEditRouteMarkerId", null);
        context.commit("setEditMarkerRouteStartDate", null);
      }
    },

    //
    // async getJobData(context, payload) {
    //   try {
    //     console.log(context);
    //     let jobId = null;
    //     if (!_.isNil(context.state.jobData)) {
    //       jobId = context.state["jobData"]["_id"];
    //     } else {
    //       jobId = payload["jobId"];
    //     }

    //     const respData = await getJobInfo(context.rootState, `id=${jobId}`);

    //     if (respData["code"] === 200) {
    //       context.commit("setJobData", respData.data);
    //       context.commit("setActivateInput", true);
    //       // replace id
    //     } else {
    //       // getData fail return return to list
    //       this.$router.push("/jobs/list");
    //     }

    //     context.commit("setMainLoading", false);
    //   } catch (error) {
    //     console.log(error);
    //     context.commit("setMainLoading", false);
    //     this.$router.push("/jobs/list");
    //   }
    // },
  },
  modules: {},
};
