<template>
  <div name="accountForm">
    <v-form ref="formAccount" v-model="valid">
      <CustomCardFormViewModern
        title="บัญชีผู้ใช้"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div class="px-7">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <AccountUsernameInputComponent
                v-model="emailInput"
                ref="email"
                title="อีเมล / เบอร์โทรศัพท์ในการเข้าสู่ระบบ"
                placeholder="name@gmail.com หรือ 06xxxxxxxx"
                :is-edit="isEdit"
                :disabled="!enableEdit"
                @handler-processing-func="checkLoadingStatus"
                :edit-at-email="oldEmailValue"
              />
              <div class="px-3" v-if="isEdit && enableEdit">
                <a class="text-decoration-underline" @click="getPasswordUrl"
                  >รับ URL สำหรับสร้างรหัสผ่าน</a
                >
              </div>
            </v-col>
            <!--  -->
            <v-col cols="12" sm="6">
              <CustomTextInputImprove
                v-model="lineIdInput"
                ref="lineId"
                title="LINE ID"
                placeholder="LINE ID"
                :is-required="true"
                :disabled="!enableEdit"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                data-input-name="lineId"
              />
            </v-col>
          </v-row>

          <v-row no-gutters v-if="enablePassword && enableEdit">
            <v-col cols="12" sm="6">
              <CustomTextInput
                ref="password"
                v-model="passwordInput"
                title="รหัสผ่าน"
                placeholder="รหัสผ่าน"
                :isRequired="false"
                type="password"
                :disabled="!enableEdit"
                dataInputName="password"
              ></CustomTextInput>
            </v-col>

            <v-col cols="12" sm="6" v-if="enablePassword && enableEdit">
              <CustomTextInput
                ref="confirmPasswordInput"
                v-model="confirmPasswordInput"
                title="ยืนยันรหัสผ่าน"
                placeholder="ยืนยันรหัสผ่าน"
                :isRequired="false"
                type="password"
                :disabled="!enableEdit"
                :rules="[(v) => v === passwordInput || 'รหัสผ่านไม่ตรงกัน']"
                dataInputName="confirmPassword"
              ></CustomTextInput>
            </v-col>
          </v-row>
        </div>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          v-if="enableEdit"
          :disabled="submitBtnState"
          text="บันทึกข้อมูลบัญชีผู้ใช้"
        ></AppSubmitFormButton>
      </CustomCardFormViewModern>
    </v-form>
    <AccountGeneratePasswordDialog ref="accountGeneratePasswordDialog" />
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import AccountUsernameInputComponent from "./AccountUsernameInputComponent.vue";
import AccountGeneratePasswordDialog from "./AccountGeneratePasswordDialog.vue";
import { getResetPasswordLinkService } from "@/services/api/user";

export default {
  name: "AccountForm",
  components: {
    CustomTextInput,
    AppOverlay,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    AppSubmitFormButton,
    AccountUsernameInputComponent,
    AccountGeneratePasswordDialog,
  },

  props: {
    userId: {
      type: [String, null],
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    enablePassword: {
      type: Boolean,
      default: true,
    },
    data: {
      type: [Object, null],
      default: null,
    },
  },

  data() {
    return {
      valid: false,
      emailInput: null,
      oldEmailValue: null,
      lineIdInput: null,
      passwordInput: "",
      confirmPasswordInput: "",
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      submitBtnState: false,
    };
  },

  mounted() {
    if (this.enablePassword === true) {
      this.$refs.password.$el.setAttribute("autocomplete", "off");
      this.$refs.confirmPasswordInput.$el.setAttribute("autocomplete", "off");
    }
  },

  computed: {
    enableEdit: {
      get() {
        return this.canEdit;
      },
    },
  },

  watch: {
    data: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          this.emailInput = newValue.email || null;
          this.oldEmailValue = newValue.email || null;
          this.lineIdInput = newValue.lineId || null;
        }
      },
    },
  },

  methods: {
    checkCompleteData() {
      this.$refs.formAccount.$el.scrollIntoView();
    },

    checkLoadingStatus(loadingStatus) {
      this.submitBtnState = loadingStatus;
    },

    async getPasswordUrl() {
      // loading from api
      this.$emit("enable-loading-func");
      let respData = await getResetPasswordLinkService(this.$store, {
        userId: this.userId,
      });
      let linkData = null;
      if (respData["code"] === 200) {
        linkData = respData["data"]["link"];
      }
      this.$emit("disable-loading-func");
      await this.$refs.accountGeneratePasswordDialog.open(linkData);
    },

    saveChange() {
      if (this.$refs.formAccount.validate() === true) {
        let respData = null;

        if (this.enablePassword) {
          respData = {
            email: this.emailInput,
            lineId: this.lineIdInput,
            password: this.passwordInput.length > 0 ? this.passwordInput : null,
          };
        } else {
          respData = {
            email: this.emailInput,
            lineId: this.lineIdInput,
          };
        }

        this.oldEmailValue = this.emailInput;

        this.$emit("on-click-save", respData);
      } else {
        this.checkCompleteData();
      }
      //
    },
  },
};
</script>
<style lang=""></style>
