<template>
	<div>
		<!-- <v-subheader
			v-if="item.header"
			:key="item.header"
			v-text="item.header"
			:class="[
				'px-4 mb-3 text-app-detail-large black--text font-weight-bold',
				isItemsReOrder ? 'placeholerColor--text' : '',
			]"
		></v-subheader> -->

		<v-subheader
			:class="[
				'px-4 mb-3 text-app-detail-large black--text font-weight-bold',
				isItemsReOrder ? 'placeholerColor--text' : '',
			]"
			>ประเภทใบตรวจ</v-subheader
		>
		<v-list v-if="!isEditing" :disabled="isItemsReOrder">
			<v-list-item-group v-model="selectedItem">
				<draggable
					v-model="items"
					ghost-class="ghost"
					@start="drag = true"
					@end="drag = false"
					@change="changeMove"
					handle=".handle"
				>
					<template v-for="(item, index) in items">
						<v-row no-gutters class="handle">
							<v-col cols="12">
								<!-- <v-divider
								v-else-if="item.divider"
								:key="index"
								:inset="item.inset"
							></v-divider> -->

								<v-list-item :key="item.name" class="pr-0">
									<!-- <v-list-item-avatar>
						<v-img :src="item.avatar"></v-img>
					</v-list-item-avatar> -->

									<v-list-item-content class="text-app-title">
										<v-row class="py-0" align="center">
											<v-col class="py-0" cols="auto">
												<span
													:class="isItemsReOrder ? 'placeholerColor--text' : ''"
													>{{ item.name }}</span
												>
											</v-col>
											<v-spacer />
											<v-switch
												class="py-0 mr-3"
												inset
												v-model="item.active"
												@click="handleActiveOnChange(item)"
												color="success"
											></v-switch>
										</v-row>
									</v-list-item-content>
								</v-list-item>
							</v-col>
							<v-col cols="12">
								<v-divider />
							</v-col>
						</v-row>
					</template>
				</draggable>
			</v-list-item-group>
		</v-list>
		<v-list v-else :disabled="isItemsReOrder">
			<div v-for="(item, index) in items">
				<v-list-item :key="item._id">
					<!-- <v-list-item-avatar>
						<v-img :src="item.avatar"></v-img>
					</v-list-item-avatar> -->
					<v-list-item-content class="text-app-title">
						<v-row no-gutters align="center">
							<v-col cols="12">
								<ChecklistTextInputImprove
									v-model="item.name"
									title=""
									placeholder="แก้ไขประเภทใบตรวจ"
									:item="item"
									:expendMargin="false"
									:hideDetail="true"
									:isRequired="false"
									:disabled="isItemsReOrder"
									dataInputName="name"
									@typeOnChange="handleEditingType"
								/>
							</v-col>
						</v-row>
					</v-list-item-content>
				</v-list-item>
			</div>
		</v-list>
		<v-row no-gutters v-if="isAddingNewType" class="mt-3">
			<v-col cols="12">
				<ChecklistTextInputImprove
					v-model="newType"
					ref="newType"
					title=""
					placeholder="เพิ่มประเภทใบตรวจ"
					:expendMargin="false"
					:hideDetail="true"
					:isRequired="false"
					:disabled="isItemsReOrder"
					dataInputName="newType"
					@typeOnChange="handleAddingType"
			/></v-col>
		</v-row>

		<v-row no-gutters class="mt-5" align="center" justify="center">
			<v-btn
				color="primary"
				:outlined="!isReOrderTypes"
				class="mx-1"
				fab
				small
				depressed
				:disabled="isEditing || isItemsReOrder || isAddingNewType"
				@click="rePositionOnClick()"
				><v-icon>mdi-sort</v-icon></v-btn
			>
			<v-btn
				:disabled="isItemsReOrder || isReOrderTypes"
				class="mx-1"
				@click="editingOnClick()"
				fab
				small
				depressed
				><v-icon>mdi-pencil</v-icon></v-btn
			><v-btn
				:disabled="isItemsReOrder || isReOrderTypes"
				class="mx-1"
				@click="addingOnClick()"
				fab
				small
				depressed
				><v-icon>mdi-plus</v-icon></v-btn
			></v-row
		>
	</div>
	<!-- <v-list dense>
		<v-subheader class="px-4">ประเภทใบตรวจ</v-subheader>
		<v-list-item-group v-model="selectedItem" color="primary">
			<v-list-item v-for="(item, i) in items" :key="i">
				<v-list-item-content>
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list> -->
</template>
<script>
	import ChecklistTextInputImprove from "@/views/vehicle/vehicle_checklist/components/ChecklistTextInputImprove.vue";
	import draggable from "vuedraggable";
	export default {
		name: "vehicleCreateCheckListType",
		components: {
			ChecklistTextInputImprove,
			draggable,
		},
		props: {
			isItemsReOrder: {
				type: Boolean,
				default: false,
			},
			checklistTypeItems: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				selectedItem: null,
				typeList: [],
				orderList: [],
				searchCountTimer: null,

				isReOrderTypes: false,

				newType: null,
				isEditing: false,
				isAddingNewType: false,
				isEditingIdx: null,
				items: [],
				_itemsList: [],
			};
		},
		watch: {
			isReOrderTypes(val) {
				this.$emit("isReOrderTypes", val);
			},

			checklistTypeItems(val) {
				// sort the items
				this.typeList = _.cloneDeep(val);
				this.typeList.sort((a, b) => a.order - b.order);
				this.processChecklistTypeItems(this.typeList);
				this.processOriOrderList();
			},
			selectedItem(val) {
				this.$emit("selectedType", this.typeList[val]);
			},
		},
		methods: {
			processChecklistTypeItems(types) {
				this.items = [];
				let name = null;
				for (let i = 0; i < types.length; i++) {
					name = types[i]["name"];

					let item = {
						name: name,
						amount: types[i]["amount"],
						id: types[i]["_id"],
						order: types[i]["order"],
						active: types[i]["active"],
					};
					this.items.push(item);
				}
			},

			changeMove: function (e) {
				// this.showTextLoading = true;
				// this.getNewDirection();
			},

			handleAddingType(val) {
				this.isAddingNewType = false;
				this.newType = null;
				this.$emit("newTypeOnSubmit", { name: val });
			},

			handleEditingType(item) {
				// this.$emit("newTypeOnSubmit", val);
				if (!_.isNil(item)) {
					delete item["amount"];
				}
				this.isEditing = false;
				this.$emit("typeOnChange", item);
			},

			processOriOrderList() {
				this.orderList = [];
				for (let i = 0; i < this.items.length; i++) {
					let _id = this.items[i]["id"];
					this.orderList.push(_id);
				}
			},

			handleActiveOnChange(item) {
				let _item = _.clone(item);
				delete _item["amount"];
				delete _item["order"];
				clearTimeout(this.searchCountTimer);
				this.searchCountTimer = setTimeout(() => {
					this.$emit("activeOnChange", _item);
				}, 500);
			},

			rePositionOnClick() {
				// available only when the searching text box is clear
				if (this.isReOrderTypes) {
					this.isReOrderTypes = false;
					this.processOriOrderList();
					// console.log("typeOrderOnChange", this.orderList);
					this.$emit("typeOrderOnChange", this.orderList);
				} else {
					this.selectedItem = null;
					this.isReOrderTypes = true;

					// this.reOrderText = "ยืนยันการเรียงลำดับ";
				}
			},

			addingOnClick() {
				if (!this.isAddingNewType) {
					this.isEditing = false;
				}

				this.isAddingNewType = !this.isAddingNewType;
			},

			editingOnClick() {
				if (this.isEditing) {
					this.items = _.cloneDeep(this._itemsList);
				} else {
					this.isAddingNewType = false;
					this._itemsList = _.cloneDeep(this.items);
				}
				this.isEditing = !this.isEditing;
			},
		},
	};
</script>
<style scoped>
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}

	.handle:hover {
		cursor: pointer;
		/* color: #2196f3; */
	}
</style>
