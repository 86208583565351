<template>
  <v-row dense v-if="data">
    <v-col cols="4">
      <v-card elevation="2" rounded="lg">
        <v-card-title class="px-4">
          <div class="py-2 text-title font-weight-bold black--text">
            รายได้รวม
          </div>
          <v-spacer></v-spacer>

          <span class="text-app-detail-normal">
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ displayNumberLocaleString(data.stat.totalIncome, 2) }}
            </span>
            บาท
          </span>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card elevation="2" rounded="lg">
        <v-card-title class="px-4">
          <div class="py-2 text-title font-weight-bold black--text">
            รายจ่ายรวม
          </div>
          <v-spacer></v-spacer>

          <span class="text-app-detail-normal"
            ><span class="dashboard-text-sm font-weight-bold black--text">
              {{ displayNumberLocaleString(data.stat.totalExpenses, 2) }}</span
            >
            บาท
          </span>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card elevation="2" rounded="lg">
        <v-card-title class="px-4">
          <div class="py-2 text-title font-weight-bold black--text">
            กำไร/ขาดทุน
          </div>
          <v-spacer></v-spacer>

          <span class="text-app-detail-normal">
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ displayNumberLocaleString(data.stat.totalMargin, 2) }}
            </span>
            บาท
          </span>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { displayNumberLocaleString } from "@/services/appNumberHelper";
export default {
  name: "ContractIncomeAndExpensesSummary",
  props: {
    data: {
      required: true,
    },
  },
  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
  },
};
</script>
