<template>
  <v-overlay :absolute="absolute" :value="isShow">
    <p>ไม่สามารถดึงข้อมูลได้</p>
    <v-btn @click="$emit('reload-data')"> รีเฟรชข้อมูล </v-btn>
  </v-overlay>
</template>
<script>
export default {
  name: "AppOverlayReloadData",

  components: {},

  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    absolute: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {};
  },

  mounted() {},

  watch: {},
  methods: {},
  components: {},
};
</script>
