import { authHttpClientGet } from "@/services/api/my_axios";
import _ from "lodash";

export async function getAppDataService(store, queryString, group) {
	// ALL COLLECTION KEY
	// affiliation
	// bank
	// com_act
	// com_ins
	// com_user_cont
	// gps_com
	// department
	// permission
	// level
	// position
	// salutation
	// zone
	let uri = "/app/found?collection=";
	if (queryString !== null) {
		uri = `${uri}${queryString}`;
	}

	if (group) {
		uri = uri + "&group=" + `${group}`;
	}

	let responseData = await authHttpClientGet(store, uri);

	if (responseData.code == 200) {
		return responseData.data;
	}

	return [];
}

export async function getUserAppDataService(store) {
	let uri = "/app/company-found";
	return await authHttpClientGet(store, uri);
}

export async function getProvinceService(store) {
	let uri = "/app/province";
	if (store.getters.appProvince.length !== 0) {
		return _.clone(store.getters.appProvince);
	}

	const responseData = await authHttpClientGet(store, uri);
	if (responseData.code == 200) {
		store.commit("setAppProvince", responseData.data);
		return responseData.data;
	}
	return [];
}

export async function getDistrictService(store, payload) {
	let uri = `/app/district?id=`;

	if (payload === null) {
		return [];
	}
	uri = `${uri}${payload}`;
	const responseData = await authHttpClientGet(store, uri);
	if (responseData.code == 200) {
		return _.clone(responseData.data);
	}
	return [];
}

export async function getSubDistrictService(store, payload) {
	let uri = "/app/subdistrict?id=";
	if (payload === null) {
		return [];
	}
	uri = `${uri}${payload}`;
	const responseData = await authHttpClientGet(store, uri);
	if (responseData.code == 200) {
		return _.clone(responseData.data);
	}
	return [];
}
