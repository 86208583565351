<script>
export default {
  name: "MonitorGMapLine",
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    lineData: null,
  }),

  mounted() {
    const { Polyline } = this.google.maps;
    let lineOption = null;
    if (this.data.gDirectionFormat) {
      lineOption = this.updateDirection();
    } else {
      lineOption = this.processSimpleLine();
    }
    this.lineData = new Polyline({
      map: this.map,
      ...lineOption,
    });
  },

  methods: {
    processSimpleLine() {
      let _l = { ...this.data };
      for (let i = 0; i < this.data["path"].length; i++) {
        _l["path"][i] = new this.google.maps.LatLng(
          this.data["path"][i]["lat"],
          this.data["path"][i]["lng"]
        );
      }
      return _l;
    },

    updateDirection() {
      if (this.map) {
        const constBounds = _.get(this.data, "routes.0.bounds", null);
        if (constBounds === null) {
          let bounds = new this.google.maps.LatLngBounds(
            constBounds.southwest,
            constBounds.northeast
          );
          this.data.routes[0].bounds = bounds;
        }

        if (!_.isNil(this.data["rawOptionData"])) {
          const constOverviewPath = _.get(
            this.data,
            "routes.0.overview_polyline",
            null
          );

          if (constOverviewPath) {
            this.data.routes[0].overview_path =
              this.google.maps.geometry.encoding.decodePath(
                constOverviewPath.points
              );
          }

          this.data.routes[0].legs = this.data.routes[0].legs.map((leg) => {
            leg.start_location = new this.google.maps.LatLng(
              leg.start_location.lat,
              leg.start_location.lng
            );
            leg.end_location = new this.google.maps.LatLng(
              leg.end_location.lat,
              leg.end_location.lng
            );

            leg.steps = leg.steps.map((step) => {
              step.path = this.google.maps.geometry.encoding.decodePath(
                step.polyline.points
              );
              step.start_location = new this.google.maps.LatLng(
                step.start_location.lat,
                step.start_location.lng
              );
              step.end_location = new this.google.maps.LatLng(
                step.end_location.lat,
                step.end_location.lng
              );
              return step;
            });

            return leg;
          });
        }

        let _plotlyLineOption = _.clone(this.getCustomLineStyle(this.data));
        _plotlyLineOption["path"] = this.data.routes[0].overview_path;
        return _plotlyLineOption;
      }
    },

    getCustomLineStyle(plotOption) {
      // #00ACF2 เส้นทึบ

      // let lineColor = ["#FF0000", "#00FF00"];

      let plotlyLineOption = {
        path: null,
        strokeColor: "#000000", //lineColor[i],
        // strokeOpacity: 0.8,
        // strokeWeight: 10,
        strokeOpacity: 0,
        fillOpacity: 0,
      };

      // process line
      if (plotOption["dashLine"]) {
        plotlyLineOption["icons"] = [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 6,
            },
            offset: "0",
            repeat: "30px",
          },
        ];

        plotlyLineOption["strokeColor"] = plotOption["lineColor"] || "#03A6F9";
        plotlyLineOption["zIndex"] = 1;
      } else {
        plotlyLineOption["strokeOpacity"] = 0.7;
        plotlyLineOption["strokeWeight"] = 4;
        plotlyLineOption["strokeColor"] = plotOption["lineColor"] || "#00ACF2";
      }

      return plotlyLineOption;
    },
  },

  render() {},
  beforeDestroy() {
    this.lineData.setMap(null);
  },
};
</script>
