import _ from "lodash";
import { Loader } from "@googlemaps/js-api-loader";

// pass google map object
export const getNewGoogleMapObj = async () => {
  const loader = new Loader({
    libraries: ["geometry", "places", "visualization"],
    apiKey: process.env.VUE_APP_GMAP_KEY,
  });

  return await loader.load();
};

export const convertLocationToGObject = (google, _location) => {
  return new google.maps.LatLng(_location[0], _location[1]);
};

export const getMapDirection = async (google, origin, destination, options) => {
  const directionsService = new google.maps.DirectionsService();
  // get avoidTolls
  try {
    let result = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      // avoidTolls: true, // หลีกเลี่ยงการจ่ายเงิน
      // avoidHighways: false, // หลีกเลี่ยงทางหลวง
      provideRouteAlternatives: false,
      ...options,
    });
    // save to database
    return { status: "OK", data: result };
  } catch (error) {
    return { status: "FAIL", message: error };
  }
};

export const getPlace = async (google, queryStr) => {
  const getPlacePromise = (_service, _queryParam) =>
    new Promise((resolve, reject) => {
      _service.findPlaceFromQuery(_queryParam, (response, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(response);
        } else {
          reject(null);
        }
      });
    });

  const placeService = new google.maps.places.PlacesService(this.map);
  // { query: queryStr }
  const reqParams = {
    ...queryStr,
    fields: ["name", "geometry"],
  };

  try {
    const respData = await getPlacePromise(placeService, reqParams);
    return respData;
  } catch (error) {
    return error;
  }
};

export const getPlaceByGeocoder = async (google, queryStr) => {
  // === abdc ===
  const getPlaceByGeoCoderPromise = (_service, _queryParam) =>
    new Promise((resolve, reject) => {
      _service.geocode(_queryParam, (response, status) => {
        if (status == "OK") {
          resolve(response[0]);
        } else {
          reject(new Error("Geocode was not successful"));
        }
      });
    });

  try {
    const geoCoder = new google.maps.Geocoder();
    const reqParams = {
      ...queryStr,
    };

    const respData = await getPlaceByGeoCoderPromise(geoCoder, reqParams);
    return respData;
  } catch (error) {
    return null;
  }
};

// // get auto complete by google service.
export const getLocationAutocomplete = async (google, queryStr) => {
  const getAutoCompletePromise = (_service, _queryParam) =>
    new Promise((resolve, reject) => {
      _service.getPlacePredictions(_queryParam, (response, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(response);
        } else {
          reject(null);
        }
      });
    });

  const autoCompleteService = new google.maps.places.AutocompleteService();

  try {
    const respData = await getAutoCompletePromise(autoCompleteService, {
      input: queryStr,
      componentRestrictions: { country: "th" },
    });
    return respData;
  } catch (error) {
    return error;
  }
};

export const calculateDurationFromAPIMapService = (second) => {
  return (second / 60).toFixed(2);
};

export const calculateDistanceFromAPIMapService = (meter) => {
  return (meter / 1000).toFixed(2);
};

export const getDistanceFromResponseDataMapService = (gDirectionData) => {
  return _.get(gDirectionData, "routes[0].legs[0].distance.value", null);
};

export const getDurationFromResponseDataMapService = (gDirectionData) => {
  let _value = _.get(
    gDirectionData,
    "routes[0].legs[0].duration_in_traffic.value",
    null
  );
  if (_.isNil(_value)) {
    return _.get(gDirectionData, "routes[0].legs[0].duration.value", null);
  }
  return _value;
};

// headmap
export const getGoogleHeatMap = (google, map, positionList, hList) => {
  let heatMapData = [];
  // { location: new google.maps.LatLng(13.2849467, 100.9216237), weight: 1 },
  const maxValutOfHList = _.max(hList);
  for (let i = 0; i < positionList.length; i++) {
    let w = (hList[i] / maxValutOfHList) * 2;
    // if (w > 1) {
    //   w = 1;
    // }
    heatMapData.push({
      location: new google.maps.LatLng(positionList[i][0], positionList[i][1]),
      weight: w,
    });
  }

  return new google.maps.visualization.HeatmapLayer({
    data: heatMapData,
    map: map,
    // radius: 20,
    // opacity: 1,
  });
};
