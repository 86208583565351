<template>
  <div id="WSSummaryList" :class="[{ white: !isPermissionCanView }]">
    <v-container v-if="isPermissionCanView" fluid class="pb-6">
      <v-row class="mb-1">
        <v-col cols="auto">
          <div class="text-h5 mt-1 white--text">รายการสรุปใบงาน</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteUserSearching
            :key="componentRefreshCounter"
            :tableLoading="isLoading"
            ref="AppAutoCompleteUserSearchingRef"
            @searchItemChange="getSearchItem"
            :isDark="true"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <AppAutoCompleteWorksheetSearching
            :key="componentRefreshCounter"
            :tableLoading="isLoading"
            ref="AppAutoCompleteUserSearchingRef"
            @searchItemChange="getSearchItemByPaymentName"
            :isDark="true"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
              >
                <v-icon left dark> mdi-filter </v-icon>
                <div class="normal-button-text white--text">
                  {{ "ตัวกรอง" }}
                </div>
              </v-btn>
            </template>

            <WorksheetFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="worksheetDataList"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="isLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable dark-table text-white"
        dark
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input dark-table"
                :items="sortList"
                label="เรียงลำดับโดย"
                style="width: 100px"
                dense
                dark
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>
            <v-divider class="mx-3 mb-6" vertical></v-divider>

            <WorksheetFilterStatus
              :key="componentRefreshCounter"
              :value="statusFilterValue"
              @input="processStatus"
            />
            <v-spacer></v-spacer>
            <div class="px-3">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    elevation="0"
                    light
                    large
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list dark style="background-color: #323841">
                  <v-list-item @click="handleFinanceFileImport()">
                    <v-list-item-title class="text-app-title">
                      นำเข้ารายการเงินเพิ่มเติม</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="handleStatusFileImport()">
                    <v-list-item-title class="text-app-title">
                      นำเข้าการเปลี่ยนสถานะ</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="exportBankPayroll()">
                    <v-list-item-title class="text-app-title">
                      ส่งออกรายการจ่าย</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <input
                ref="financeUploader"
                class="d-none"
                type="file"
                @change="onFinanceFileChanged"
              />
              <input
                ref="statusUploader"
                class="d-none"
                type="file"
                @change="onStatusFileChanged"
              />
            </div>
            <NormalButtonDark icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButtonDark>
          </div>
          <WorksheetMonthlyTab
            class="px-3"
            :selectedMonth="selectedMonth"
            @processSelectedMonthTitle="processSelectedMonthTitle"
            @processSelectedMonth="processSelectedMonth"
          />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-row no-gutters align="center" justify="center" class="my-1">
            <v-avatar size="30" class="pa-0">
              <v-img
                :src="
                  item.profileImage === null
                    ? defaultPFImage
                    : item.profileImage
                "
              >
              </v-img>
            </v-avatar>
            <v-col class="pl-3">
              <span
                class="text-app-title white--text font-weight-bold app-link-text"
                @click="handleEditClick(item._id)"
              >
                {{
                  `${item.salutation === null ? "" : item.salutation.th}${
                    item.firstName === null ? "" : item.firstName.th
                  } ${item.lastName === null ? "" : item.lastName.th} `
                }}
                {{
                  item.nickName !== null
                    ? item.nickName.th !== null
                      ? `(${item.nickName.th})`
                      : "-"
                    : "-"
                }}</span
              >
              <div>
                <span class="text-app-normal" v-if="item.empId">
                  {{ item.empId }}
                </span>
                <span class="text-app-normal" v-if="item.empId && item.position"
                  >|</span
                >
                <span class="text-app-normal" v-if="item.position">
                  {{ item.position }}
                </span>
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.phoneNumber`]="{ item }">
          <div class="my-2" v-if="item.phoneNumber.length !== 0">
            <div
              class="d-block"
              v-for="(tel, idx) in item.phoneNumber"
              :key="'phone_row' + idx"
            >
              <span v-if="tel.length <= 12" class="text-app-title">
                {{ tel }}
              </span>
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <span class="text-app-normal my-2" v-if="item.email">{{
            item.email
          }}</span
          ><span v-else>-</span>
        </template>
        <template v-slot:[`item.paymentName`]="{ item }">
          <div class="value_style">
            {{ item.paymentName ? item.paymentName : "-" }}
          </div>
        </template>

        <template v-slot:[`item.createdDate`]="{ item }">
          <div>
            <span class="text-app-small">เริ่มงาน:</span>
            <span class="value_style">
              {{
                item.createdDate
                  ? dateStringToDateFormat(item.createdDate)
                  : "-"
              }}
            </span>
          </div>
          <div>
            <span class="text-app-small">สรุปยอด:</span>
            <span class="value_style">
              {{
                item.summarizedDate
                  ? dateStringToDateFormat(item.summarizedDate)
                  : "-"
              }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.contractJobAmount`]="{ item }">
          <div class="value_style">
            {{ item.contractJobAmount ? item.contractJobAmount : "-" }}
          </div>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <div class="value_style one_row_only">
            {{ item.note ? item.note : "-" }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-row no-gutters class="pt-1" align="center" justify="center">
            <v-card
              rounded="pill"
              outlined
              :color="worksheetStatusCardColor(item.status)"
              width="100"
            >
              <span class="text-app-title">
                {{ worksheetStatusText(item.status) }}
              </span>
            </v-card>
            <br />
            <span
              v-if="item.netAmount !== null"
              class="font-weight-bold text-app-title"
            >
              {{ getPriceString(item.netAmount) }} บาท
            </span>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :disabled="!isPermissionCanEdit"
            text
            x-small
            fab
            color="#72D5FF"
            class="font-weight-bold"
            @click="handleDownloadPDF(item._id)"
          >
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <WorksheetSummaryDetail ref="worksheetSummaryDialog" />
      <WorksheetExcelPreviewDialog ref="worksheetExcelPreviewDialog" />
      <WorksheetExportBankPayrollDialog
        ref="worksheetExportBankPayrollDialog"
      />
      <WorksheetStatusExcelPreviewDialog
        ref="worksheetStatusExcelPreviewDialog"
      />
    </v-container>
    <AppOverlay :absolute="false" :isLoading="isLoading" />
    <AppPageNotFound :show="isPermissionCanView" />
  </div>
</template>

<script>
import WorksheetMonthlyTab from "./components/WorksheetMonthlyTab.vue";
import WorksheetSummaryDetail from "./worksheet_summary_detail/WorksheetSummaryDetail.vue";
import WorksheetExcelPreviewDialog from "./worksheet_summary_detail/WorksheetExcelPreviewDialog.vue";
import WorksheetStatusExcelPreviewDialog from "./worksheet_summary_detail/WorksheetStatusExcelPreviewDialog.vue";
import WorksheetExportBankPayrollDialog from "./worksheet_summary_detail/WorksheetExportBankPayrollDialog.vue";
import WorksheetFilterGroup from "./components/WorksheetFilterGroup.vue";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import WorksheetFilterStatus from "./components/WorksheetFilterStatus.vue";
import NormalButtonDark from "@/components/NormalButtonDark.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { processPermission } from "@/services/permissions";
import { DialogType } from "@/services/dialog";
import { dateStringToDateFormat } from "@/services/appDate";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import AppAutoCompleteWorksheetSearching from "@/components/AppWorksheetSearching.vue";
import {
  getWorksheetListService,
  postDownloadPDFService,
} from "@/services/api/worksheet";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import { mapGetters } from "vuex";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { read, utils, writeFile } from "xlsx";
import moment from "moment";

export default {
  name: "worksheetSummaryListExcel",

  components: {
    AppOverlay,
    WorksheetMonthlyTab,
    WorksheetSummaryDetail,
    WorksheetExcelPreviewDialog,
    WorksheetExportBankPayrollDialog,
    WorksheetStatusExcelPreviewDialog,
    AppAutoCompleteUserSearching,
    WorksheetFilterGroup,
    WorksheetFilterStatus,
    NormalButtonDark,
    AppAutoCompleteWorksheetSearching,
    AppPageNotFound,
    AppDialogConfirm,
  },

  data() {
    return {
      isLoading: false,
      componentRefreshCounter: 0,
      worksheetDataList: [],
      queryFilterList: {},
      queryMonthlyList: {},
      queryStringSearch: "", // combin filter and search text
      querySearchAndFilterList: {}, // combine filter and search
      querySearchAndFilterListBackground: {},
      statusFilterValue: "ALL",
      pageDataLength: 0,
      currentPage: 1,
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      serverItemsLength: 0,

      isFinanceSelecting: false,
      isStatusSelecting: false,
      selectedFile: null,

      empData: null,
      empStatData: null,

      selectedMonth: 0,

      fileUploadErrorMessageType: 0,
      headerName: ["ID_EMP", "N_EMP", "CODE", "ID_CON"],

      headerKey: {
        ID_EMP: "ID_EMP",
        N_EMP: "N_EMP",
        CODE: "CODE",
        ID_CON: "ID_CON",
      },
      errorMsgEachRow: [],
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },

      queryOptionsTable: {
        limit: 30, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },
      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createddate-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createddate-desc",
        },
        {
          text: "ชื่อ-นามสกุล เรียงจาก A-Z",
          value: "name-asc",
        },
        {
          text: "ชื่อ-นามสกุล เรียงจาก Z-A",
          value: "name-desc",
        },
        {
          text: "ยอดสุทธิ เรียงจาก A-Z",
          value: "netAmount-asc",
        },
        {
          text: "ยอดสุทธิ เรียงจาก Z-A",
          value: "netAmount-desc",
        },
      ],
      financialDocURL: null,
      checkboxData: {
        thisMonth: 0,
      },
      sortVal: null,
      isShowMenu: false,
      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },
      defaultPFImage: defaultImage,
      editTextBtn: "แก้ไข",
      rejectTextBtn: "ปฏิเสธ",
      isPermissionCanEdit: false,
      isPermissionCanView: false,
      shiftedData: [],
      countShifted: 0,
    };
  },
  watch: {
    "queryOptionsTable.page": function (newVal) {
      this.currentPage = newVal;
      this.worksheetDataList = [];
      this.tablePageHandler(newVal);
      // this.getData();
    },
  },
  computed: {
    ...mapGetters({
      worksheetStatusColor: "worksheet/worksheetStatusColor",
      worksheetStatusCardColor: "worksheet/worksheetStatusCardColor",
      worksheetStatusText: "worksheet/worksheetStatusText",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อ-นามสกุล ",
          align: "start",
          sortable: false,
          value: "name",
          width: "22%",
        },
        // {
        // 	text: "รหัสพนักงาน",
        // 	value: "empId",
        // 	sortable: false,
        // 	align: "left",
        // },
        {
          text: "เบอร์ติดต่อ",
          value: "phoneNumber",
          sortable: false,
          align: "left",
          width: "10%",
        },
        {
          text: "อีเมล",
          value: "email",
          sortable: false,
          align: "left",
          width: "10%",
        },
        {
          text: "รหัสใบงาน",
          value: "paymentName",
          sortable: false,
          align: "left",
          width: "9%",
        },
        {
          text: "วันที่",
          value: "createdDate",
          sortable: false,
          align: "left",
          width: "15%",
        },
        // {
        // 	text: "วันที่สรุปยอด",
        // 	value: "summarizedDate",
        // 	sortable: false,
        // 	align: "left",
        // },
        {
          text: "จำนวนงาน",
          value: "contractJobAmount",
          sortable: false,
          align: "left",
          width: "10%",
        },

        {
          text: "หมายเหตุ",
          value: "note",
          sortable: false,
          align: "left",
          width: "10%",
        },

        {
          text: "",
          value: "status",
          sortable: false,
          align: "center",
        },

        // { text: "ตำแหน่ง", value: "company", sortable: false, align: "left" },
        // { text: "สถานะ", value: "empStatus", sortable: false, align: "left" },
        // { text: "อายุ", value: "age", sortable: false, align: "center" },
        // {
        //   text: "แอปเวอร์ชั่น",
        //   value: "appVersion",
        //   sortable: false,
        //   align: "center",
        //   width: "15%",
        // },
        // {
        //   text: "รถประจำตัว",
        //   value: "vehicleStatus",
        //   align: "center",
        //   sortable: false,
        // },
      ];
      //
      if (this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        });
      }
      //
      return defaultdata;
    },
  },
  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.processRouterQuery(this.$route.query);
    }
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        if (this.permList.payment_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
          // this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไข";
        }
      }
    },

    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "limit":
              // this.queryOptionsTable[key] = 2;
              // break;
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });
        // init Date range
        this.querySearchAndFilterList["startDate"] = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        this.querySearchAndFilterList["endDate"] = moment()
          .endOf("month")
          .format("YYYY-MM-DD");

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.setRouterQuery(this.querySearchAndFilterList);
      // this.getData();
    },

    async getData() {
      this.isLoading = true;
      this.worksheetDataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getWorksheetListService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let worksheets = responseData.data["paginatedResults"];
        this.worksheetDataList = _.cloneDeep(worksheets);
        this.pageDataLength = Math.ceil(
          responseData.data["total"] / this.queryOptionsTable["limit"]
        );
      } else {
        this.worksheetDataList = [];
      }
      this.isLoading = false;
    },

    async getShiftedData() {
      let shiftedData = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterListBackground
      ).toString();

      let responseData = await getWorksheetListService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData.code == 200) {
        //
        //
        shiftedData = _.cloneDeep(responseData.data["paginatedResults"]);
      }
      return shiftedData;
    },

    async handleEditClick(id) {
      if (!this.permList.payment_report && !this.permList.payment_crud) {
        return;
      }

      const respWorksheet = await this.$refs.worksheetSummaryDialog.open(id);
      if (!_.isNil(respWorksheet)) {
        if (respWorksheet["code"] === 200) {
          if (!_.isNil(respWorksheet["data"])) {
            let newValue = _.clone(respWorksheet["data"]);
            let editIndex = this.worksheetDataList.findIndex(
              (x) => x["_id"] == newValue["_id"]
            );
            if (this.statusFilterValue === "ALL") {
              this.worksheetDataList.splice(editIndex, 1, newValue);
            } else {
              if (this.statusFilterValue !== newValue["status"]) {
                this.processShiftingItem(editIndex);
              } else {
                this.worksheetDataList.splice(editIndex, 1, newValue);
              }
            }
          }
        } else {
          // insert fail
        }
      }
    },

    async processShiftingItem(editIndex) {
      let _queryParameter = {};

      _queryParameter["skip"] =
        this.queryOptionsTable["limit"] * this.currentPage - 1;
      _queryParameter["limit"] = 1;

      this.processSearchingParamsBackground(_queryParameter);
      this.shiftedData = await this.getShiftedData();

      if (!_.isNil(this.shiftedData)) {
        this.worksheetDataList.splice(editIndex, 1);
        if (this.shiftedData.length > 0) {
          this.worksheetDataList.push(this.shiftedData[0]);
        }

        this.countShifted += 1;

        this.pageDataLength = Math.ceil(
          (this.serverItemsLength - this.countShifted) /
            this.queryOptionsTable["limit"]
        );

        if (!_.isNil(this.worksheetDataList)) {
          if (this.pageDataLength > 0 && this.worksheetDataList.length === 0) {
            this.queryOptionsTable["page"] -= 1;
          }
        }
      }
    },

    async handleDownloadPDF(id) {
      this.isLoading = true;
      if (!_.isNil(id)) {
        let queryString = { id: id };
        let respData = await postDownloadPDFService(this.$store, queryString);
        //
        if (respData["code"] === 200) {
          this.financialDocURL = respData["data"]["pdfURL"];
          window.open(this.financialDocURL, "_blank");
          // await this.getData();
        } else {
          // insert fail
        }
      }
      this.isLoading = false;
    },
    processSelectedMonthTitle(val) {
      this.selectedMonth = val;
    },

    processSelectedMonth(_queryParameter) {
      // this.selectedMonth = val;
      // this.selectedMonth = { startDate: "2023-12-01", endDate: "2023-12-31" };
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryMonthlyList)) {
        Object.keys(this.queryMonthlyList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryOptionsTable["page"] = 1;
      this.queryMonthlyList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    changeValueOfCheckboxStatus() {
      let searchObj = {};
      Object.keys(this.checkboxData).forEach((key) => {
        if (this.checkboxData[key]) {
          searchObj[key] = "1";
        } else {
          delete this.querySearchAndFilterList[key];
        }
      });

      this.querySearchAndFilterList["page"] = 1;

      this.processSearchingParams(searchObj);
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();

      this.tablePageHandler(1);
    },
    // === end action button of table
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    processSearchingParamsBackground(_queryParameter) {
      // combine query search
      this.querySearchAndFilterListBackground = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
    },

    processStatus(newStatusVal) {
      this.worksheetDataList = [];
      this.statusFilterValue = newStatusVal;
      this.countShifted = 0;

      if (_.isNil(this.statusFilterValue)) {
        // remove from list
        delete this.querySearchAndFilterList["status"];
        return;
      }

      delete this.querySearchAndFilterList["skip"];

      this.queryOptionsTable["page"] = 1;
      this.querySearchAndFilterList["status"] = this.statusFilterValue;
      if (this.querySearchAndFilterList["status"] === "ALL") {
        // remove from list
        delete this.querySearchAndFilterList["status"];
      }
      // this.setQueryStringSearch(this.querySearchAndFilterList);
      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    getSearchItemByPaymentName(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["codeSearch"]; // id
      let _queryParameter = {};

      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["codeSearch"]) {
          // is true => select at
          _queryParameter["codeSearch"] = _searchingParameter.value;
        } else if (
          !_searchingParameter["codeSearch"] &&
          !_.isNil(_searchingParameter.value)
        ) {
          if (_searchingParameter.value.length > 0) {
            // not empty
            _queryParameter["codeSearch"] = _searchingParameter.value;
          }
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    vaildateHeader(excelJsonData) {
      //excelJsonData: any[]
      for (let i = 0; i < this.headerName.length; i++) {
        if (excelJsonData[0].includes(this.headerName[i]) === false) {
          return false;
        }
      }
      return true;
    },

    async exportBankPayroll() {
      const bankPayroll =
        await this.$refs.worksheetExportBankPayrollDialog.open();
    },

    async uploadFinanceExcel() {
      let fileInput = this.selectedFile;

      const readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
          //error
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new Error("INPUT_FILE"));
          };
          //set
          temporaryFileReader.onload = () => {
            let arrayBuffer = temporaryFileReader.result;
            let workbook = read(arrayBuffer, {
              type: "binary",
            });

            let firstSheet = workbook.SheetNames[0];
            let exceljsondata = utils.sheet_to_json(
              workbook.Sheets[firstSheet],
              {
                header: 1,
              }
            );
            resolve(exceljsondata);
          };

          temporaryFileReader.readAsBinaryString(inputFile);
        });
      };

      try {
        let excelArrayData = await readUploadedFileAsText(fileInput);
        // let excelArrayData = await readUploadedFileAsText(null);
        if (excelArrayData.length < 1) {
          throw new Error("EMPTY_FILE");
        }

        // if (!this.vaildateHeader(excelArrayData)) {
        //   throw new Error("NOT_VAILDATE_HEADER");
        // }

        // prcoess to json object
        let empJsonData = new Array();
        let userJsonData = new Array();
        let errorAtKey = false;
        for (let i = 1; i < excelArrayData.length; i++) {
          let ObjEmpData = {};
          let errorEmpEachKey = [];
          for (let j = 0; j < this.headerName.length; j++) {
            if (excelArrayData[i][j] === undefined) {
              ObjEmpData[this.headerKey[this.headerName[j]]] = null;
              errorEmpEachKey.push(false);
              // errorAtKey = true;
            } else {
              ObjEmpData[this.headerKey[this.headerName[j]]] =
                excelArrayData[i][j];
              errorEmpEachKey.push(true);
            }
          }
          // this.errorMsgEachRow.push(errorEachKey);
          empJsonData.push(ObjEmpData);

          let ObjData = {};
          let errorEachKey = [];
          let maxCol = Math.max(
            excelArrayData[0].length,
            excelArrayData[1].length,
            excelArrayData[2].length
          );
          for (let j = 0; j < maxCol; j++) {
            if (excelArrayData[0][j] === undefined) {
              let emptyHeader = `${excelArrayData[0][j - 1]}_status`;
              if (excelArrayData[i][j] === undefined) {
                ObjData[emptyHeader] = null;
              } else {
                ObjData[emptyHeader] = excelArrayData[i][j];
              }

              errorEachKey.push(false);
            } else {
              if (excelArrayData[i][j] === undefined) {
                ObjData[excelArrayData[0][j]] = null;
              } else {
                ObjData[excelArrayData[0][j]] = excelArrayData[i][j];
              }

              errorEachKey.push(true);
            }
          }
          this.errorMsgEachRow.push(errorEachKey);
          userJsonData.push(ObjData);
        }

        if (errorAtKey) {
          //

          throw new Error("FIELDS_REQUIRED");
        }

        this.empData = empJsonData;
        this.empStatData = userJsonData;
      } catch (error) {
        console.error(error);
      }
    },

    async uploadStatusExcel() {
      let fileInput = this.selectedFile;

      const readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
          //error
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new Error("INPUT_FILE"));
          };
          //set
          temporaryFileReader.onload = () => {
            let arrayBuffer = temporaryFileReader.result;
            let workbook = read(arrayBuffer, {
              type: "binary",
            });

            let firstSheet = workbook.SheetNames[0];
            let exceljsondata = utils.sheet_to_json(
              workbook.Sheets[firstSheet],
              {
                header: 1,
              }
            );
            resolve(exceljsondata);
          };

          temporaryFileReader.readAsBinaryString(inputFile);
        });
      };

      try {
        let excelArrayData = await readUploadedFileAsText(fileInput);
        if (excelArrayData.length < 1) {
          throw new Error("EMPTY_FILE");
        }

        // if (!this.vaildateHeader(excelArrayData)) {
        //   throw new Error("NOT_VAILDATE_HEADER");
        // }

        // prcoess to json object
        let empJsonData = new Array();
        let userJsonData = new Array();
        let errorAtKey = false;
        for (let i = 1; i < excelArrayData.length; i++) {
          let ObjEmpData = {};
          let errorEmpEachKey = [];
          for (let j = 0; j < this.headerName.length; j++) {
            if (excelArrayData[i][j] === undefined) {
              ObjEmpData[this.headerKey[this.headerName[j]]] = null;
              errorEmpEachKey.push(false);
              // errorAtKey = true;
            } else {
              ObjEmpData[this.headerKey[this.headerName[j]]] =
                excelArrayData[i][j];
              errorEmpEachKey.push(true);
            }
          }
          // this.errorMsgEachRow.push(errorEachKey);
          empJsonData.push(ObjEmpData);

          let ObjData = {};
          let errorEachKey = [];
          let maxCol = Math.max(
            excelArrayData[0].length,
            excelArrayData[1].length
          );
          for (let j = 0; j < maxCol; j++) {
            if (excelArrayData[i][j] === undefined) {
              ObjData[excelArrayData[0][j]] = null;
            } else {
              ObjData[excelArrayData[0][j]] = excelArrayData[i][j];
            }

            errorEachKey.push(true);
          }
          this.errorMsgEachRow.push(errorEachKey);
          userJsonData.push(ObjData);
        }

        if (errorAtKey) {
          //

          throw new Error("FIELDS_REQUIRED");
        }

        this.empData = empJsonData;
        this.empStatData = userJsonData;
      } catch (error) {
        console.error(error);
      }
    },

    handleFinanceFileImport() {
      this.isFinanceSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isFinanceSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.financeUploader.click();
    },

    async onFinanceFileChanged(e) {
      this.selectedFile = e.target.files[0];
      await this.uploadFinanceExcel();

      const excelPreview = await this.$refs.worksheetExcelPreviewDialog.open(
        this.empData,
        this.empStatData
      );

      this.$refs.financeUploader.value = null;

      if (!_.isNil(excelPreview)) {
        if (excelPreview) {
          this.getData();
        }
      }
    },

    handleStatusFileImport() {
      this.isStatusSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isStatusSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.statusUploader.click();
    },

    async onStatusFileChanged(e) {
      this.selectedFile = e.target.files[0];
      await this.uploadStatusExcel();

      const excelPreview =
        await this.$refs.worksheetStatusExcelPreviewDialog.open(
          this.empData,
          this.empStatData
        );

      this.$refs.statusUploader.value = null;

      if (!_.isNil(excelPreview)) {
        if (excelPreview) {
          this.getData();
        }
      }
    },

    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["empid"]; // id
      delete this.querySearchAndFilterList["idname"]; // name
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["empId"]) {
          // is true => select at
          _queryParameter["empid"] = _searchingParameter.key;
        } else if (
          !_searchingParameter["empId"] &&
          !_.isNil(_searchingParameter.key)
        ) {
          if (_searchingParameter.key.length > 0) {
            // not empty
            _queryParameter["idname"] = _searchingParameter.key;
          }
        } else if (!_.isNil(_searchingParameter["value"])) {
          _queryParameter["idname"] = _searchingParameter["value"];
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryOptionsTable["page"] = 1;
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    //
    //  end method scope
    //
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 30, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.currentPage = 1;
      this.shiftedData = [];
      this.countShifted = 0;
      this.selectedMonth = 0;

      this.querySearchAndFilterList["startDate"] = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.querySearchAndFilterList["endDate"] = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      this.processSearchingParams();
      // this.getData();
    },
  },
};
</script>

<style scoped>
#WSSummaryList {
  display: flex;
  height: 100%;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  background-color: #222831;
}

.one_row_only {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name_style {
  font-size: 20px;
  font-weight: 500;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 12px;
  font-weight: bold;
  color: #fcfdfd;
}

.status_paid_style {
  border: 2px solid #6ecc95;
  background-color: rgba(110, 204, 149, 0.3);
}

.status_not_yet_paid_style {
  border: 2px solid #8a96a4;
  background-color: rgba(138, 150, 164, 0.3);
}

.status_text_style {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.total_money_style {
  font-size: 16px;
  font-weight: bold;
}

.dark-table {
  background-color: #323841;
}
.dark-table-bg {
  /* Set the display to `table-row` because Vuetify makes `flex` */
  display: table-row;
}
</style>
