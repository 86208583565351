<template>
  <v-dialog v-model="show" width="50%" persistent>
    <v-card v-if="show">
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>เพิ่มตารางใหม่</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="cancelAndCloseHandler">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 1">
        <v-form ref="AssignMultipleJobCreate" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12">
              <InputView
                title="สัญญา"
                :is-required="true"
                :bottom-margin="false"
                :small="true"
              >
                <JobContractSelectorInput
                  ref="JobContractSelectorInputRef"
                  @searchItemChange="getSearchContractItem"
                  :value="formData.contract"
                />
              </InputView>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!--  -->
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 0">
        กำลังดึงข้อมูล
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 1"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 1"
          color="primary"
          text
          @click="saveAndCloseHandler"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { postMultipleJobTableService } from "@/services/api/assign_multiple_job";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";

export default {
  name: "AssignMultipleCreateTableDialog",
  data: () => ({
    valid: null,
    resolve: null,
    reject: null,
    show: false,
    fetchDataError: 0,
    //
    sheetId: null,
    sheetContractId: null,
    formData: {
      contract: null,
    },
  }),
  methods: {
    open(sheetData) {
      this.resetForm();
      this.sheetId = sheetData._id;
      this.sheetContractId = _.get(sheetData, "data.defaultContractId", null);
      this.show = true;

      setTimeout(() => {
        if (!_.isNil(this.sheetContractId)) {
          this.fetchDataError = 2;
          this.save(this.sheetContractId);
        } else {
          this.fetchDataError = 1;
        }
      }, 500);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    //

    getSearchContractItem(searchItem) {
      this.formData["contract"] = searchItem;
    },
    async save(sheetContractId) {
      const requestBodyData = {
        contractId: _.isNil(sheetContractId)
          ? this.formData["contract"]["key"]
          : sheetContractId,
      };
      //
      const queryString = new URLSearchParams({
        id: this.sheetId,
      }).toString();
      //
      const responseData = await postMultipleJobTableService(
        this.$store,
        queryString,
        requestBodyData
      );
      if (responseData["code"] === 200) {
        this.show = false;
        this.resolve(responseData["data"]);
      } else {
        this.fetchDataError = -1;
      }
    },
    async saveAndCloseHandler() {
      this.fetchDataError = 2;
      if (this.$refs["AssignMultipleJobCreate"].validate() === true) {
        this.save(null);
      } else {
        // this.fetchDataError = -1;
        console.error("FORM_INVALIDATE");
      }
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
    resetForm() {
      this.fetchDataError = 0;
      this.formData = {
        contractId: null,
      };
    },
  },
  components: {
    CustomTextareaImprove,
    CustomTextInputImprove,
    JobContractSelectorInput,
    InputView,
  },
};
</script>
