<template>
  <div class="d-flex flex-column">
    <CustomCardFormViewModern
      v-if="!vertical"
      :is-show-title="false"
      :is-add-title-margin="false"
      :fill-height="true"
    >
      <div class="d-flex justify-space-around">
        <div class="px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text"> จำนวนงานทั้งหมด </span>

          <div class="mt-2">
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ totalJob || "-" }}
            </span>
            <span class="text-app-detail-normal"> งาน</span>
          </div>
        </div>
        <div class="px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">
            จำนวนงานเฉลี่ยต่อวัน
          </span>

          <div class="mt-2">
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageJobByDurationDay || "-" }}
            </span>
            <span class="text-app-detail-normal"> งาน</span>
          </div>
        </div>

        <div class="px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">
            รายได้ต่องานโดยเฉลี่ย
          </span>

          <div class="mt-2">
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageIncomeByJob || "-" }}
            </span>
            <span class="text-app-detail-normal"> บาท</span>
          </div>
        </div>
      </div>

      <!-- <v-divider class="mx-6 my-3" />

      <v-row v-if="allJobStatusData" no-gutters class="px-3 pb-3">
        <v-col
          cols="2"
          v-for="(value, key) in jobsStatusObj"
          :key="key"
          class="px-3 mt-3"
        >
          <span class="mr-2">
            <div>
              <span :class="['px-1', `${jobsStatusColor(key)}--text`]">
                {{ value }}
              </span>
            </div>
            <div class="mt-3 ml-0">
              <span class="dashboard-text-sm font-weight-bold black--text">
                {{ getNumberOfStatus(key) }}
              </span>
              <span class="dashboard-report-keyunit-text"> งาน</span>
            </div>
          </span>
        </v-col>
      </v-row> -->
    </CustomCardFormViewModern>
    <div class="d-flex justify-sm-start justify-md-space-between mb-2">
      <CustomCardFormViewModern
        v-if="vertical"
        title="จำนวนงาน"
        class="job-stat-sm-width mr-md-2"
        :is-show-title="true"
        :is-add-title-margin="false"
        :fill-height="true"
      >
        <div class="d-flex px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">ทั้งหมด</span>

          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ totalJob }}
            </span>
            <span class="text-app-detail-normal"> งาน</span>
          </div>
        </div>
        <div class="d-flex px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">เฉลี่ยต่อฉบับ</span>

          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageJobByContract }}
            </span>
            <span class="text-app-detail-normal"> งาน</span>
          </div>
        </div>
        <div class="d-flex px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">เฉลี่ยต่อวัน</span>

          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageJobByDurationDay }}
            </span>
            <span class="text-app-detail-normal"> งาน</span>
          </div>
        </div>
        <div class="d-flex px-3 pb-3 mt-3">
          <span class="dashboard-report-keyunit-text">
            รายได้ต่องานโดยเฉลี่ย
          </span>

          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageIncomeByJob }}
            </span>
            <span class="dtext-app-detail-normal"> บาท</span>
          </div>
        </div>

        <v-divider class="mx-6 my-3" />
        <v-row no-gutters class="px-3 pb-0">
          <v-col cols="4" class="px-3 mt-3">
            <span class="mr-2">
              <div>
                <!-- <v-icon :color="jobsStatusColor(key)">mdi-circle</v-icon> -->
                <span :class="`${jobsStatusColor('SUCCESS')}--text`"
                  >จบงาน
                </span>
              </div>
              <div class="mt-3 ml-0">
                <span class="dashboard-text-sm font-weight-bold black--text">{{
                  successJob
                }}</span>
                <span class="text-app-detail-normal"> งาน</span>
              </div>
            </span>
          </v-col>
          <v-col cols="4" class="px-3 mt-3">
            <span class="mr-2">
              <div>
                <!-- <v-icon :color="jobsStatusColor(key)">mdi-circle</v-icon> -->
                <span :class="`${jobsStatusColor('CANCEL')}--text`"
                  >ถูกยกเลิก
                </span>
              </div>
              <div class="mt-3 ml-0">
                <span class="dashboard-text-sm font-weight-bold black--text">{{
                  cancelJob
                }}</span>
                <span class="text-app-detail-normal"> งาน</span>
              </div>
            </span>
          </v-col>
          <v-col cols="4" class="px-3 mt-3">
            <span class="mr-2">
              <div>
                <!-- <v-icon :color="jobsStatusColor(key)">mdi-circle</v-icon> -->
                <span :class="`red--text`">คิดเป็น </span>
              </div>
              <div class="mt-3 ml-0">
                <span class="dashboard-text-sm font-weight-bold black--text">{{
                  cancelPercentJob
                }}</span>
                <span class="text-app-detail-normal"> %</span>
              </div>
            </span>
          </v-col>
        </v-row>
      </CustomCardFormViewModern>

      <CustomCardFormViewModern
        title="เวลาทำงาน"
        class="job-stat-sm-width mr-md-2"
        :is-show-title="true"
        :is-add-title-margin="false"
        :fill-height="true"
      >
        <div class="d-flex px-3 pb-4">
          <span class="dashboard-report-keyunit-text"> ทั้งหมด </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ totalDuration }}
            </span>
            <span class="text-app-detail-normal"> ชม.</span>
          </div>
        </div>

        <div class="d-flex px-3 pb-4">
          <span class="dashboard-report-keyunit-text"> เฉลี่ยต่อวัน </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageDurationByDay || "-" }}
            </span>
            <span class="text-app-detail-normal"> ชม.</span>
          </div>
        </div>

        <div class="d-flex px-3">
          <span class="dashboard-report-keyunit-text">
            รายได้โดยเฉลี่ยต่อ <br />
            1 ชั่วโมง
          </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageIncomeByDuration || "-" }}
            </span>
            <span class="text-app-detail-normal"> บาท</span>
          </div>
        </div>
      </CustomCardFormViewModern>

      <CustomCardFormViewModern
        title="ระยะทางที่วิ่ง"
        class="job-stat-sm-width"
        :is-show-title="true"
        :is-add-title-margin="false"
        :fill-height="true"
      >
        <div class="d-flex px-3 pb-4">
          <span class="dashboard-report-keyunit-text"> ทั้งหมด </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ totalDistance }}
            </span>
            <span class="text-app-detail-normal"> กม.</span>
          </div>
        </div>

        <div class="d-flex px-3 pb-4">
          <span class="dashboard-report-keyunit-text"> เฉลี่ยต่อวัน </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageDistanceByDay || "-" }}
            </span>
            <span class="text-app-detail-normal"> กม.</span>
          </div>
        </div>

        <div class="d-flex px-3">
          <span class="dashboard-report-keyunit-text">
            รายได้โดยเฉลี่ยต่อ <br />1 กม.
          </span>
          <v-spacer></v-spacer>
          <div>
            <span class="dashboard-text-sm font-weight-bold black--text">
              {{ averageIncomeByDistance || "-" }}
            </span>
            <span class="text-app-detail-normal"> บาท</span>
          </div>
        </div>
      </CustomCardFormViewModern>
    </div>
  </div>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { mapGetters } from "vuex";
import {
  calculateAverageHelper,
  calculatePercentHelper,
  displayNumberLocaleString,
} from "@/services/appNumberHelper";

import { calculateHours, calculateKm } from "@/services/appSummaryHelper";
export default {
  name: "JobStatSummary",
  components: {
    CustomCardFormViewModern,
  },
  props: {
    data: {
      required: true,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      jobData: null,
      totalJob: null,
      averageJobByContract: null,
      averageIncomeByJob: null,
      averageJobByDurationDay: null,
      successJob: null,
      cancelJob: null,
      cancelPercentJob: null,
      //
      totalDistance: null,
      averageDistanceByDay: null,
      averageIncomeByDistance: null,

      totalDuration: null,
      averageDurationByDay: null,
      averageIncomeByDuration: null,

      //
      allJobStatusData: null,
    };
  },

  mounted() {
    this.processData();
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      jobsStatusObj: "jobs/jobsStatusObj",
    }),
  },
  methods: {
    getNumberOfStatus(key) {
      return _.get(this.allJobStatusData, `${String(key).toLowerCase()}`, 0);
    },
    processData() {
      if (!_.isNil(this.data)) {
        if (!_.isEmpty(this.data["jobSection"])) {
          this.jobData = this.data["jobSection"];
          this.totalJob = this.jobData["totalJob"];
          this.averageJobByContract = displayNumberLocaleString(
            this.jobData["averageJobByContract"],
            2
          );
          this.averageIncomeByJob = displayNumberLocaleString(
            this.jobData["averageIncomeByJob"],
            2
          );
          this.averageJobByDurationDay = displayNumberLocaleString(
            this.jobData["averageJobByDurationDay"],
            2
          );
          this.successJob = _.get(this.jobData, "jobStatus.success", 0);
          this.cancelJob = _.get(this.jobData, "jobStatus.cancel", 0);
          this.cancelPercentJob = calculatePercentHelper(
            this.cancelJob,
            this.totalJob,
            null,
            true,
            2
          );

          this.totalDistance = displayNumberLocaleString(
            calculateKm(_.get(this.jobData, "totalDistance", 0)),
            2
          );
          this.totalDuration = displayNumberLocaleString(
            calculateHours(_.get(this.jobData, "totalDuration", 0)),
            2
          );

          this.averageDistanceByDay = displayNumberLocaleString(
            _.get(this.jobData, "averageDistanceByDay", 0),
            2
          );
          this.averageIncomeByDistance = displayNumberLocaleString(
            _.get(this.jobData, "averageIncomeByDistance", 0),
            2
          );

          this.averageDurationByDay = displayNumberLocaleString(
            _.get(this.jobData, "averageDurationByDay", 0),
            2
          );
          this.averageIncomeByDuration = displayNumberLocaleString(
            _.get(this.jobData, "averageIncomeByDuration", 0),
            2
          );
          //
          this.allJobStatusData = _.get(this.jobData, "jobStatus", null);
        }
      }
    },
  },
};
</script>
<style scoped>
.job-stat-sm-width {
  width: 100%;
}
</style>
