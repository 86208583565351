<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500">
      <v-card v-if="show">
        <v-toolbar color="primary" dark>
          <v-card-title> {{ title }} </v-card-title> <v-spacer></v-spacer
          ><v-btn icon @click.native="cancelStatus"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-toolbar
        >
        <v-card-text>
          <!-- <CustomCardFormViewModern :elevation="0" :isShowTitle="false"> -->
          <v-form ref="form" class="pt-5">
            <CustomTextInputImprove
              ref="feedCardName"
              v-model="formData.name"
              title="ชื่อการ์ด"
              :isRequired="true"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อการ์ด']"
            ></CustomTextInputImprove>
            <CustomSelectsImprove
              ref="feedCardCode"
              v-model="formData.type"
              :items="fleetCardTypeList"
              title="ประเภทการ์ด"
              :is-required="true"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุประเภทการ์ด']"
            />
            <CustomTextInputImprove
              ref="feedCardCode"
              v-model="formData.code"
              title="หมายเลข"
              :isRequired="true"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุหมายเลขการ์ด']"
            ></CustomTextInputImprove>
            <CustomTextInputCurrency
              ref="limitAmount"
              v-model="formData.limitAmount"
              title="วงเงิน (หน่วยบาท)"
              :isRequired="true"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุวงเงิน']"
            />
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";

export default {
  name: "VehicleFeedCardDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    formData: {
      name: null,
      code: null,
      limitAmount: null,
      type: null,
    },
    fleetCardTypeList: [],
    title: "เพิ่ม Fleet Card",
  }),
  methods: {
    resetFormData() {
      this.formData = {
        name: null,
        code: null,
        limitAmount: null,
        type: null,
      };
    },
    open(fleetCardData, fleetCardTypeList) {
      this.resetFormData();
      this.show = true;
      this.fleetCardTypeList = fleetCardTypeList;
      if (fleetCardData !== null) {
        this.formData = fleetCardData;
        this.title = "แก้ไข Fleet Card";
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      if (this.$refs.form.validate() === true) {
        this.resolve(_.clone(this.formData));
        this.show = false;
      }
    },
    cancelStatus() {
      this.resolve(null);
      this.show = false;
    },
  },
  watch: {
    show() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
  },
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    CustomTextInputCurrency,
    CustomSelectsImprove,
  },
};
</script>

<style scoped></style>
