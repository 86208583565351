<template>
  <div>
    <div class="pl-4">
      <span
        v-for="(legend, idx) in serires"
        :key="idx"
        :style="{ color: `${chartOptions.fill.colors[idx]}` }"
        class="text-app-detail-md"
      >
        {{ legend.name }}
        <span class="text-app-detail-normal" v-if="idx < serires.length - 1">
          |
        </span>
      </span>
    </div>

    <apexchart
      height="90"
      :series="serires"
      :options="chartOptions"
    ></apexchart>
  </div>
</template>

<script>
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "VehicleEventProblemSbarChart",
  data() {
    return {
      serires: [],
    };
  },
  props: {
    data: {
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          offsetY: -20,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " ครั้ง";
            },
          },
          x: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
          colors: [
            "#567DF4",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
          ],
        },
        legend: {
          show: false,
        },
      };
    },
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        const vehicleEventProblemsList = _.get(
          this.data,
          "events.vehicleEventProblemsList",
          []
        );
        //
        let _tmp = [];
        for (let i = 0; i < vehicleEventProblemsList.length; i++) {
          _tmp.push({
            name: vehicleEventProblemsList[i]["text"],
            data: [vehicleEventProblemsList[i]["count"]],
          });
        }

        this.serires = _tmp;
      }
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  mounted() {
    this.processData();
  },
};
</script>
