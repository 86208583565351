<template>
  <v-menu open-on-hover :offset-y="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :block="btnBlock"
        class="rounded-t-0"
        elevation="0"
        :class="vehicleFuelStatusColorValue(currentStatus)"
        v-bind="attrs"
        v-on="on"
        :disabled="!isPermissionCanEdit"
        :loading="isLoading"
      >
        {{ vehicleFuelStatusValue(currentStatus) }}
        <v-icon> mdi-menu-down </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(statusItem, statusIdx) in vehicleFuelStatusApprovalObj"
        :key="statusIdx"
        :disabled="currentStatus == statusIdx"
        @click="statusActionClick(statusIdx)"
      >
        <v-list-item-title>{{ statusItem }} </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { putVehicleFuelPaymentUpdateStatusService } from "@/services/api/vehicle_fuel_payment";
import { mapGetters } from "vuex";
import _ from "lodash";
import { processPermission } from "@/services/permissions";

export default {
  name: "VehicleFuelStatusSelectionBtn",
  props: {
    value: {},
    fuelPaymentId: {
      type: String,
      default: null,
    },
    confirmDialog: {
      type: Boolean,
      default: false,
    },
    btnBlock: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "vehicleFuelStatusSelectionBtnTemp",
    },
    showAmountInput: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      vehicleFuelStatusColorObj: "vehicle/vehicleFuelStatusColorObj",
      vehicleFuelStatusApprovalObj: "vehicle/vehicleFuelStatusApprovalObj",
      vehicleFuelStatusValue: "vehicle/vehicleFuelStatusValue",
      vehicleFuelStatusColorValue: "vehicle/vehicleFuelStatusColorValue",
    }),
  },

  data: () => ({
    isLoading: false,
    data: null,
    currentStatus: null,
    isPermissionCanEdit: false,
  }),
  watch: {
    value: function (newValue) {
      if (!_.isNil(newValue)) {
        this.data = newValue;
        this.currentStatus = newValue["status"];
      }
    },
  },

  mounted() {
    // check permission
    this.checkUserPermissions();
    if (!_.isNil(this.value)) {
      this.data = this.value;
      this.currentStatus = this.value["status"];
    }
  },

  methods: {
    async statusActionClick(statusItem) {
      // showAmountInput
      this.isLoading = true;
      let requestBodyData = {
        status: statusItem,
      };

      let uriQuery = new URLSearchParams({
        id: this.fuelPaymentId,
        type: "status",
      }).toString();
      //
      this.$emit("progressing", true);
      await putVehicleFuelPaymentUpdateStatusService(
        this.$store,
        uriQuery,
        requestBodyData
      );
      this.$emit("progressing", false);
      this.isLoading = false;
      this.currentStatus = statusItem;
      this.$emit("status-onchange", { ...this.data, ...requestBodyData });
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_FUEL");

        if (
          this.permList.admin_all === true ||
          this.permList.fuelpayment_update_status === true
        ) {
          this.isPermissionCanEdit = true;
        }
      }
    },
  },
};
</script>
