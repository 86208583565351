import { render, staticRenderFns } from "./VehicleDynamicAccessories.vue?vue&type=template&id=1a7f4973&"
import script from "./VehicleDynamicAccessories.vue?vue&type=script&lang=js&"
export * from "./VehicleDynamicAccessories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VListItem,VListItemContent,VListItemTitle})
