<template>
  <v-combobox
    ref="input"
    class="app-custom-input"
    :value="formattedValue"
    :items="itemList"
    :loading="loading"
    :search-input.sync="searching"
    :label="label"
    :placeholder="placeholder"
    :hide-no-data="hideText"
    hide-details
    filled
    chips
    outlined
    return-object
    multiple
    item-value="_id"
    item-text="name"
    @input="onInput"
    :disabled="disabled"
    :readonly="readOnly"
    :rules="rules"
    :loader-height="5"
    :auto-select-first="true"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            ไม่มีข้อมูล "<strong>{{ searching }}</strong
            >" กด <kbd>enter</kbd> เพื่อเพิ่มใหม่
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>

  <!-- @keyup="enterToReturnEmit" -->
  <!-- prepend-inner-icon="mdi-magnify" -->
  <!-- :item-value="itemValueKey" -->
  <!--  item-text="value" -->
</template>

<script>
import { getVehicleAccessoriesService } from "@/services/api/vehicle";

import _ from "lodash";

export default {
  name: "VehicleDynamicAccessories",
  components: {},

  props: {
    value: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    itemList: [],
    loading: false,
    searching: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    hideText: true,
    firstInit: false,
    formattedValue: [],
    backupValue: [],
    readOnly: false,
  }),
  watch: {
    value(value) {
      this.setValue(value);
    },
    searching: {
      immediate: false,
      handler: function (val) {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        this.readOnly = false;
        //
        if (!_.isNil(val) && this.firstInit) {
          if (val.length > 2) {
            this.searchCountTimer = setTimeout(() => {
              this.callApi(val); // call api function
            }, 1500);
          } else {
          }
        }
        this.firstInit = true;
      },
    },
  },

  methods: {
    async callApi(val) {
      try {
        if (val.length === 0) {
          throw "NOT_VALUE";
        }
        this.loading = true;
        this.readOnly = true;
        let responseData = await getVehicleAccessoriesService(
          this.$store,
          new URLSearchParams({ text: val }).toString()
        );
        if (responseData["code"] === 200) {
          if (responseData["data"].length > 0) {
            this.itemList = responseData["data"];
            this.hideText = true;
          } else {
            throw "NOT_DATA";
          }
        }
      } catch (error) {
        if (error === "NOT_DATA") {
          this.itemList = [];
          this.hideText = false;
        }
      }
      this.readOnly = false;
      this.loading = false;
    },
    processNewValue(newValue) {
      let copyNewItem = _.cloneDeep(newValue);
      for (let i = 0; i < copyNewItem.length; i++) {
        if (_.isString(copyNewItem[i])) {
          copyNewItem[i] = { _id: null, name: copyNewItem[i] };
        }
      }
      return copyNewItem;
    },
    setValue(value) {
      this.$refs.input.setValue(value);
    },
    onInput(value) {
      const processedValue = this.processNewValue(value);
      this.$emit("input", processedValue);
      this.formattedValue = processedValue;
    },
    // onChange(value) {
    //   this.$emit("value", this.processNewValue(value));
    //   this.formattedValue = this.processNewValue(value);
    // },
  },

  mounted() {
    this.setValue(this.value);
  },
};
</script>

<style scope></style>
