<template>
  <v-row dense>
    <v-col cols="12" sm="6">
      <AppAutoCompleteUserSearching @searchItemChange="getUserSearching" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-combobox
        class="app-custom-input"
        v-model="modelVal"
        :items="itemList"
        :loading="loading"
        :readonly="tableLoading"
        :search-input.sync="searchingAction"
        item-text="value"
        :item-value="itemValueKey"
        placeholder="ค้นหาจากชื่อสัญญา / ชื่องาน / เลขทะเบียนรถ "
        prepend-inner-icon="mdi-magnify"
        append-icon=""
        return-object
        filled
        dense
        hide-no-data
        hide-selected
        hide-details
        flat
        height="44"
        outlined
        @change="itemChange"
      ></v-combobox>
    </v-col>
  </v-row>
</template>
<script>
import _ from "lodash";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";
import { getSearchForEmployeePenaltyService } from "@/services/api/employee";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";

export default {
  name: "EmployeePenaltySearchInput",
  props: {
    itemValueKey: {
      default: "key",
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    tableLoading: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    modelVal: null,
    itemList: [],
    loading: false,
    searchingAction: null,
    searchCountTimer: undefined,
    //
    queryOrder: ["cjvtext"],
    queryOrder: {
      cjvtext: "text",
    },
    formData: {
      text: null,
    },
    firstInit: false,
    selectFromListChecker: false,
  }),
  watch: {
    searchingAction: {
      immediate: false,
      handler: function (val) {
        if (!_.isNil(val) && this.firstInit && !this.selectFromListChecker) {
          if (val.length > 2) {
            clearTimeout(this.searchCountTimer);
            this.loading = true;
            this.searchCountTimer = setTimeout(() => {
              this.callApi(val); // call api function
            }, 1500);
          }
        }
        this.firstInit = true;
        this.selectFromListChecker = false;
      },
    },
  },
  mounted() {
    this.getParamQuery();
  },
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "cjvtext":
              this.formData["text"] = appDecodeSingleURIComponentHelper(
                this.$route.query[key]
              );
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });

      if (!_.isNil(this.formData["text"])) {
        this.modelVal = this.formData["text"];
      }
    },
    async callApi(val) {
      // getVehicleBySeachingService
      let responseData = await getSearchForEmployeePenaltyService(
        this.$store,
        val
      );
      if (responseData.code === 200) {
        this.itemList = responseData["data"];
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },
    itemChange() {
      if (
        _.isString(this.modelVal) === false &&
        _.isNil(this.modelVal) === false
      ) {
        this.selectFromListChecker = true;
        this.$emit("searchItemChange", this.modelVal);
        this.modelVal = this.modelVal["value"];
        this.itemList = [];
      } else if (_.isString(this.modelVal) === true) {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.$emit("searchItemChange", {
          value: this.modelVal,
          useId: false,
        });
        this.itemList = [];
      } else {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;

        this.$emit("searchItemChange", {
          value: null,
        });
        this.itemList = [];
      }
      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },
    //
    getUserSearching(responseData) {
      this.$emit("searchItemChange", {
        ...responseData,
        c: "us",
      });
    },
  },
  components: { AppAutoCompleteUserSearching },
};
</script>
