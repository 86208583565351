var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.show)?_c('v-card',[_c('v-sheet',{attrs:{"height":"60","color":"primary","dark":""}},[_c('v-card-title',{staticClass:"mx-2"},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v("แก้ไขใบตรวจสภาพ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1)],1),_c('v-divider'),(_vm.fetchDataError === 0)?_c('v-card-text',[_c('v-card-subtitle',{staticClass:"mt-3 pb-3"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',{staticClass:"px-0 pb-3",attrs:{"no-gutters":""}},[_c('p',{staticClass:"ma-0 text-app-detail-large font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.vehicleLicenseNumber)+" ")]),(_vm.vehicleCheckListForm.vehicleLicenseTypeKey)?_c('span',{staticClass:"text-app-small-detail pl-6"},[_c('span',{staticClass:"mx-0 px-0"},[(
                        _vm.vehicleCheckListForm.vehicleLicenseTypeKey == 'WHITE'
                      )?_c('v-icon',[_vm._v("mdi-card-text-outline")]):_c('v-icon',{attrs:{"color":_vm.vehicleLicenseTypeColor(
                          _vm.vehicleCheckListForm.vehicleLicenseTypeKey
                        )}},[_vm._v("mdi-card-text")])],1),_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.vehicleLicenseType))]):_vm._e(),(_vm.vehicleCheckListForm.vehicleSeat)?_c('span',{staticClass:"text-app-small-detail pl-6"},[_c('span',{staticClass:"mx-0 px-0"},[_c('v-icon',[_vm._v("mdi-car-side")]),_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.vehicleType)+" ")],1),_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.vehicleSeat)+" ที่นั่ง")]):_vm._e()]),_c('v-row',{staticClass:"px-0 pb-3",attrs:{"no-gutters":""}},[(_vm.vehicleCheckListForm.createdDate)?_c('span',{staticClass:"text-app-normal mr-6"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-folder-plus-outline")]),_vm._v("สร้างเมื่อ: "+_vm._s(_vm.vehicleCheckListForm.createdDate ? _vm.dateStringToDateFormat( _vm.vehicleCheckListForm.createdDate, { type: "datetime" } ) : "-")+" ")],1):_vm._e(),(_vm.vehicleCheckListForm.updatedDate)?_c('span',{staticClass:"text-app-normal mr-6"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-pencil-outline")]),_vm._v("อัพเดทเมื่อ: "+_vm._s(_vm.vehicleCheckListForm.updatedDate ? _vm.dateStringToDateFormat( _vm.vehicleCheckListForm.updatedDate, { type: "datetime" } ) : "-")+" ")],1):_vm._e()]),(_vm.vehicleCheckListForm.group)?_c('v-row',{staticClass:"px-0",attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-app-normal"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-format-list-bulleted-type")]),_vm._v("ประเภทใบตรวจ: "+_vm._s(_vm.vehicleCheckListForm.group)+" ")],1)]):_vm._e()],1),_c('v-spacer'),(_vm.vehicleCheckListForm.status)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var props = ref.props;
                        var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"px-0",attrs:{"outlined":"","color":_vm.vehicleCheckListStatusCardColor(
                      _vm.vehicleCheckListForm.status
                    ),"height":"34","width":"130"}},'v-card',props,false),on),[_c('div',{staticClass:"status_text_style white--text"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListStatusText(_vm.vehicleCheckListForm.status))+" ")])])]}}],null,false,3038717624)},[_c('v-list',[_c('v-card',{attrs:{"elevation":"0","disabled":!_vm.isPermissionCanView ||
                    !_vm.isPermissionCanViewInfo ||
                    !_vm.isPermissionCanEdit}},_vm._l((_vm._vehicleCheckListStatusText),function(status,idx){return _c('v-list-item',{key:idx,attrs:{"value":idx,"disabled":_vm.vehicleCheckListForm.status == idx},on:{"click":function($event){return _vm.updateCheckListStatus(_vm.vehicleCheckListForm._id, idx)}}},[_c('v-list-item-title',{class:("text-app-title " + (_vm.vehicleCheckListStatusCardColorDisabled(
                        idx,
                        _vm.vehicleCheckListForm.status
                      )))},[_vm._v(" "+_vm._s(status))])],1)}),1)],1)],1):_vm._e()],1)],1),(_vm.vehicleCheckListForm.note || _vm.vehicleCheckListForm.images)?_c('div',{staticClass:"mx-4"},[(
              _vm.vehicleCheckListForm.note ||
              _vm.vehicleCheckListForm.images.length > 0
            )?_c('v-divider'):_vm._e()],1):_vm._e(),(_vm.vehicleCheckListForm.note || _vm.vehicleCheckListForm.images)?_c('div',[(
              _vm.vehicleCheckListForm.note ||
              _vm.vehicleCheckListForm.images.length > 0
            )?_c('v-card-subtitle',{staticClass:"py-3"},[(_vm.vehicleCheckListForm.note)?_c('v-row',{staticClass:"px-0 my-0",attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.note)+" ")])]):_vm._e(),(_vm.vehicleCheckListForm.images)?_c('v-row',{staticClass:"mx-0 px-0 pt-3",attrs:{"no-gutters":""}},[(_vm.vehicleCheckListForm.images.length > 0)?_c('v-row',{staticClass:"px-0 mb-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ImageDataImprove',{ref:"vehicleCRUDUploadImageFile",attrs:{"component-name":"vehicleCRUDUploadImageFileForm","title":"","isImageType":true,"uploaded-data":_vm.vehicleCheckListForm.images,"is-permission-can-edit":!_vm.isPermissionCanEdit,"uploadTag":"VEHICLE_CHECK_LIST"}})],1)],1):_vm._e()],1):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.vehicleCheckListForm.checkerEmpId)?_c('v-divider',{staticClass:"mx-4 mb-3"}):_vm._e(),_c('v-card-subtitle',{staticClass:"mt-0 pt-0"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.vehicleCheckListForm.checkerEmpId)?_c('span',{staticClass:"text-app-title font-weight-bold"},[_vm._v("ผู้ตรวจ")]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.vehicleCheckListForm.checkerProfileImage === null
                        ? _vm.defaultPFImage
                        : _vm.vehicleCheckListForm.checkerProfileImage}})],1),_c('v-list-item-content',[_c('div',[_c('v-list-item-title',{staticClass:"text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(("" + (_vm.vehicleCheckListForm.checkerSalutation === null ? "" : _vm.vehicleCheckListForm.checkerSalutation.th) + (_vm.vehicleCheckListForm.checkerFirstName === null ? "" : _vm.vehicleCheckListForm.checkerFirstName.th) + " " + (_vm.vehicleCheckListForm.checkerLastName === null ? "" : _vm.vehicleCheckListForm.checkerLastName.th) + " "))+" "+_vm._s(_vm.vehicleCheckListForm.checkerNickName !== null ? _vm.vehicleCheckListForm.checkerNickName.th !== null ? ("(" + (_vm.vehicleCheckListForm.checkerNickName.th) + ")") : "" : ""))]),(_vm.vehicleCheckListForm.checkerEmpId)?_c('span',{staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.checkerEmpId)+" ")]):_vm._e(),(
                        _vm.vehicleCheckListForm.checkerEmpId &&
                        _vm.vehicleCheckListForm.checkerPosition
                      )?_c('span',{staticClass:"text-app-normal"},[_vm._v(" | ")]):_vm._e(),(_vm.vehicleCheckListForm.checkerPosition)?_c('span',{staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.checkerPosition)+" ")]):_vm._e()],1),(_vm.vehicleCheckListForm.checkerEmail)?_c('span',{staticClass:"text-app-normal"},[_vm._v("อีเมล: "+_vm._s(_vm.vehicleCheckListForm.checkerEmail))]):_vm._e(),(_vm.vehicleCheckListForm.checkerPhoneNumber)?_c('div',[_c('span',{staticClass:"text-app-normal"},[_vm._v(" โทร: ")]),_vm._l((_vm.vehicleCheckListForm.checkerPhoneNumber),function(number,idx){return _c('span',{key:idx,staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(number)+" "),(
                          idx <
                          _vm.vehicleCheckListForm.checkerPhoneNumber.length - 1
                        )?_c('span',[_vm._v(",")]):_vm._e()])})],2):_vm._e()])],1)],1),(
                _vm.vehicleCheckListForm.closerEmpId &&
                _vm.vehicleCheckListForm.status == 'CLOSED'
              )?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(
                _vm.vehicleCheckListForm.closerEmpId &&
                _vm.vehicleCheckListForm.status == 'CLOSED'
              )?_c('v-col',{staticClass:"px-6",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-app-title font-weight-bold"},[_vm._v("ผู้ปิด")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.vehicleCheckListForm.closerProfileImage === null
                        ? _vm.defaultPFImage
                        : _vm.vehicleCheckListForm.closerProfileImage}})],1),_c('v-list-item-content',[_c('div',[_c('v-list-item-title',{staticClass:"text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(("" + (_vm.vehicleCheckListForm.closerSalutation === null ? "" : _vm.vehicleCheckListForm.closerSalutation.th) + (_vm.vehicleCheckListForm.closerFirstName === null ? "" : _vm.vehicleCheckListForm.closerFirstName.th) + " " + (_vm.vehicleCheckListForm.closerLastName === null ? "" : _vm.vehicleCheckListForm.closerLastName.th) + " "))+" "+_vm._s(_vm.vehicleCheckListForm.closerNickName !== null ? _vm.vehicleCheckListForm.closerNickName.th !== null ? ("(" + (_vm.vehicleCheckListForm.closerNickName.th) + ")") : "-" : "-"))]),(_vm.vehicleCheckListForm.closerEmpId)?_c('span',{staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.closerEmpId)+" ")]):_vm._e(),(
                        _vm.vehicleCheckListForm.closerEmpId &&
                        _vm.vehicleCheckListForm.closerPosition
                      )?_c('span',{staticClass:"text-app-normal"},[_vm._v(" | ")]):_vm._e(),(_vm.vehicleCheckListForm.closerPosition)?_c('span',{staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(_vm.vehicleCheckListForm.closerPosition)+" ")]):_vm._e()],1),(_vm.vehicleCheckListForm.closerEmail)?_c('span',{staticClass:"text-app-normal"},[_vm._v("อีเมล: "+_vm._s(_vm.vehicleCheckListForm.closerEmail))]):_vm._e(),(_vm.vehicleCheckListForm.closerPhoneNumber)?_c('div',[_c('span',{staticClass:"text-app-normal"},[_vm._v(" โทร: ")]),_vm._l((_vm.vehicleCheckListForm.closerPhoneNumber),function(number,idx){return _c('span',{key:idx,staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(number)+" "),(
                          idx <
                          _vm.vehicleCheckListForm.closerPhoneNumber.length - 1
                        )?_c('span',[_vm._v(",")]):_vm._e()])})],2):_vm._e()])],1)],1):_vm._e()],1),(_vm.vehicleCheckListForm.checkItems.length > 0)?_c('div',{staticClass:"pb-0 list_main px-0 mx-0"},_vm._l((_vm.vehicleCheckListForm.checkItems),function(checkItem,itemIdx){return _c('v-sheet',{key:itemIdx,staticClass:"px-0 mx-0"},[_c('v-divider'),_c('v-row',{staticClass:"mx-0 px-0 mt-3 mb-0 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-app-title font-weight-bold pt-0",attrs:{"cols":"9"}},[_c('span',{staticClass:"text-app-normal mt-0 mb-1"},[_vm._v(_vm._s(itemIdx + 1)+" | ")]),_vm._v(" "+_vm._s(("" + (checkItem.name === null ? "" : checkItem.name)))+" ")]),_c('v-spacer'),_c('VehicleCheckListItemMenuBtn',{attrs:{"disabled":!_vm.isPermissionCanView ||
                    !_vm.isPermissionCanViewInfo ||
                    !_vm.isPermissionCanEdit,"id":_vm.id,"check-item":checkItem,"check-item-idx":itemIdx}})],1),_c('v-row',{staticClass:"mx-0 px-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',[_c('p',{staticClass:"text-app-normal"},[_vm._v(" "+_vm._s(("" + (checkItem.note === null ? "" : checkItem.note)))+" ")])])])],1),(checkItem.images)?_c('v-row',{staticClass:"mx-0 px-0",attrs:{"no-gutters":""}},[(checkItem.images.length > 0)?_c('v-row',{staticClass:"px-0 mb-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ImageDataImprove',{ref:"vehicleItemCRUDUploadImageFile",refInFor:true,attrs:{"component-name":"vehicleItemCRUDUploadImageFileForm","title":"","isImageType":true,"uploaded-data":checkItem.images,"is-permission-can-edit":!_vm.isPermissionCanEdit,"uploadTag":"VEHICLE_CHECK_LIST_ITEM"}})],1)],1):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)],1):_vm._e(),(_vm.fetchDataError === 2)?_c('v-card-text',{staticClass:"my-2 pa-2 text-center"},[_vm._v(" กำลังดึงข้อมูล... ")]):_vm._e(),_c('v-divider')],1):_vm._e(),_c('AppOverlay',{attrs:{"absolute":false,"isLoading":_vm.componentLoading}})],1),_c('AppDialogConfirm',{ref:"alertSaving"}),_c('AppDialogConfirm',{ref:"dialogAlert"}),_c('AppDialogConfirm',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }