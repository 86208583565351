<template>
  <div name="VehicleCheckListFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

      <div class="pa-6">
        <v-row dense>
          <v-col cols="12">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เลือกช่วงเวลา"
                v-model="formData.checklistDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import { getAppDataService } from "@/services/api/app";
import { getContractTitleListService } from "@/services/api/contract";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";

export default {
  name: "VehicleCheckListFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    InputView,
    CustomTextInputImprove,
    CustomFilterDateTypingPickerRange,
  },

  mounted() {
    // set from query
    this.getParamQuery();
  },

  data() {
    return {
      queryOrder: {
        startDate: "checklistDate",
        endDate: "checklistDate",
      },
      formData: {
        checklistDate: [], // for date
      },
      copyFormData: null,
      // from api
      isLoading: false,
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "startDate":
            case "endDate":
              this.formData.checklistDate.push(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "checklistDate":
              if (this.formData.checklistDate.length === 1) {
                filterParams["startDate"] = this.formData.checklistDate[0];
              } else if (this.formData.checklistDate.length === 2) {
                filterParams["startDate"] = this.formData.checklistDate[0];
                filterParams["endDate"] = this.formData.checklistDate[1];
              }
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      // clear all
      this.copyFormData = _.cloneDeep(this.formData);

      this.formData = {
        checklistDate: [], // for date
      };
    },
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
