<template>
  <v-chip-group v-model="valueInput" color="primary" column mandatory>
    <v-chip filter value="all" outlined :key="'ALL'" :disabled="disabled">
      <span class="px-1">ทั้งหมด</span></v-chip
    >
    <v-chip
      v-for="(value, key) in jobsStatusObj"
      :key="key"
      :value="key.toLowerCase()"
      :color="jobsStatusColor(key)"
      :disabled="disabled"
      outlined
      filter
    >
      <span class="px-1">{{ value }}</span>
    </v-chip>
  </v-chip-group>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JobsFilterStatus",
  mounted() {
    this.getParamQuery();
  },
  props: {
    value: {},
    disabled: {
      required: true,
    },
  },
  data: () => ({
    queryOrder: {
      status: "status",
    },
    settingValue: "all",
  }),
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "status":
              this.settingValue = this.$route.query[key];
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusObj: "jobs/jobsStatusObj",
    }),
    valueInput: {
      get() {
        return this.settingValue;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped></style>
