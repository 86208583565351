<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Job Id"
                  placeholder=""
                  v-model="formData.jobId"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <!-- <v-text-field
          label="Csv Url"
          placeholder=""
          v-model="csvUrl"
          outlined
        ></v-text-field -->
                <v-file-input
                  v-model="rawCSVFile"
                  label="File input"
                ></v-file-input>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Driver Id"
                  outlined
                  v-model="formData.driverId"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Vehicle Id"
                  outlined
                  v-model="formData.vehicleId"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Device Id"
                  placeholder=""
                  v-model="formData.deviceId"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="เวลาต่อแถว (วินาที)"
                  placeholder=""
                  v-model="delayTimeInSecond"
                  outlined
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn @click="getJobAndCheckpointData" color="gray" class="mr-2">
          Get job data
        </v-btn>
        <v-btn @click="confirmAction" color="info" class="mr-2">
          Confirm
        </v-btn>
        <v-btn @click="callApiToPutToRegisterBox" color="orange" class="mr-2">
          PULL TO DEEP BOX</v-btn
        >
        <v-btn @click="startAction" color="primary" class="mr-2"> Start </v-btn>

        <v-btn @click="stopAction" color="error" class="mr-2"> Stop </v-btn>
        <v-btn @click="setPreparing" color="info">
          Set to PREPARING status
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn @click="callApiRegisterBox" class="mr-2">
          Register or Resend Box API
        </v-btn>
        <v-btn @click="callApiSendingToBox" class="mr-2">
          Create Crypto and Sending
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn @click="setPause" class="mr-2">
          Turn {{ isPause ? "on" : "off" }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="mb-2">
          <v-card-text>
            process and total row::: => {{ countIdx }} /
            {{ csvData.length || "-" }}</v-card-text
          >
        </v-card>

        <v-card>
          <v-card-text>
            <pre>
            {{ registerBoxData | pretty }}
          </pre
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(text, idx) in textStatus" :key="idx">
                <v-list-item-content>
                  <v-list-item-title>{{ text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title> processMarker Request </v-card-title>
          <v-card-text>
            <pre> {{ processCheckpoint | pretty }}</pre>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title> processCheckpointRespData </v-card-title>
          <v-card-text>
            <pre> {{ processCheckpointRespData | pretty }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title> jobData </v-card-title>
          <v-card-text>
            <pre>
            {{ jobData | pretty }}
          </pre
            >
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title> checkpointData </v-card-title>
          <v-card-text>
            <pre>
            {{ checkpointData | pretty }}
          </pre
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import {
  putJobDriverSubmit,
  putJobDriverCheckpoint,
  getJobInfo,
  getMarkerService,
  putJobStatusService,
  postRegisterBoxService,
  putRegisterBoxService,
  postSendCoordinatesBoxService,
  putJobDriverAllCheckpoint,
} from "@/services/api/jobs";
import { getLocalStorage, setLocalStorage } from "@/services/local_storage";
import moment from "moment";

import CryptoJS from "crypto-js";
export default {
  name: "CsvGpsView",
  data() {
    return {
      textStatus: ["Initialize"],
      respStatusTextList: [],
      jobData: null,

      checkpointData: null,
      processCheckpoint: null,
      processCheckpointRespData: null,

      formData: {
        vehicleId: "633cfe2bfcf2639d63d67401",
        driverId: null,
        jobId: "63875b9f8a4c75c48a60a53e",
        deviceId: "xyz",
        userId: "633d0dc63bdedeb4e8df21a6",
      },
      eventCount: 0,
      delayTimeInSecond: 3,
      csvUrl:
        "https://streaming.deepbox.deepinnoline.com/csv/2022-11-03_10-38-09.csv",
      csvData: [],
      rawCSVFile: null,
      //
      timeinterval: null,
      runningStatus: 0,

      //
      countIdx: 0,
      minDistance: [],
      minDistanceTime: [],

      // deepbox data
      registerBoxData: null,
      registerBoxPutData: null,
      isPause: false,
    };
  },

  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },

  methods: {
    async getCsv() {
      // axios({
      //   url: this.csvUrl,
      //   method: "GET",
      //   responseType: "blob", // important
      // }).then((response) => {
      //   console.log(response);
      //   // const url = window.URL.createObjectURL(new Blob([response.data]));
      //   // const link = document.createElement("a");
      //   // link.href = url;
      //   // link.setAttribute("download", "file.pdf");
      //   // document.body.appendChild(link);
      //   // link.click();
      // });
      // const api = axios.create({
      //   baseURL: this.csvUrl,
      //   method: "GET",
      //   responseType: "blob",
      //   headers: {
      //     post: {
      //       "Access-Control-Allow-Origin": true,
      //     },
      //   },
      // });
      // let respData = await api.get();
      // console.log(respData);
    },
    async getDataFromFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            let csv = reader.result;
            let lines = csv.split("\r\n");
            let headers = lines[0].split(",");
            let result = [];
            for (let i = 1; i < lines.length; i++) {
              let obj = {};
              let currentline = lines[i].split(",");
              for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
              }
              result.push(obj);
            }

            // result = JSON.stringify(result);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsText(this.rawCSVFile);
      });
    },

    loadRegisterBox() {
      let payloadString = getLocalStorage("deepbox");
      if (!_.isNil(payloadString)) {
        this.registerBoxData = JSON.parse(payloadString);
      }
    },
    async callApiToPutToRegisterBox() {
      let reqData = {
        userId: this.userId,
        deviceId: this.formData.deviceId,
        companyAssetId: this.jobData.vehicleId["_id"],
        companyJobId: this.formData.jobId,
        os: "web",
        osVersion: "1.0",
        deviceModel: "chrome",
      };
      let respData = null;
      respData = await putRegisterBoxService(this.$store, null, reqData);
      if (respData["code"] === 200) {
        this.textStatus.push("Job Confirm: Pairing Success");
      } else {
        this.textStatus.push("Job Confirm: Pairing Fail");
      }
    },
    async callApiRegisterBox() {
      this.loadRegisterBox();

      if (this.registerBoxData !== null) {
        this.textStatus.push(
          "Job Confirm: Box Register LOADED form localstorage"
        );
        return;
      }

      this.textStatus.push("Job Confirm: Box ");
      // let queryParams = null;
      let respData = null;
      let reqData = {
        userId: this.userId,
        deviceId: this.formData.deviceId,
        companyAssetId: this.jobData.vehicleId["_id"],
        jobId: this.formData.jobId,
        os: "web",
        osVersion: "1.0",
        deviceModel: "chrome",
      };
      if (_.isNil(this.registerBoxData)) {
        respData = await postRegisterBoxService(this.$store, null, reqData);
        if (respData["code"] === 200) {
          this.registerBoxData = respData["data"];
          setLocalStorage("deepbox", JSON.stringify(respData["data"]));
          this.textStatus.push("Job Confirm: Box Register POST DONE");
        } else {
          this.registerBoxData = null;
          this.textStatus.push("Job Confirm: Box Register POST Fail");
        }
      }
    },
    async callApiSendingToBox(resqData) {
      if (this.registerBoxData === null) {
        this.textStatus.push(
          "Job Confirm: Box Register NOT FOUND registered token"
        );
        return;
      }

      let currentTimeStamp = moment().unix(); // get posix in second
      currentTimeStamp = currentTimeStamp - (currentTimeStamp % 60);

      const input =
        this.formData["deviceId"] +
        this.registerBoxData["emmcSN"] +
        this.registerBoxData["secretKey"] +
        currentTimeStamp;

      const hashDigest = CryptoJS.SHA256(input);
      const hexDigest = hashDigest.toString(CryptoJS.enc.Hex); // use this
      // hash.toString(CryptoJS.enc.Base64)
      const dilidentity = this.registerBoxData["identity"];
      try {
        let respData = await postSendCoordinatesBoxService(
          this.$store,
          {
            ts: moment().valueOf(),
            lat: resqData["lat"],
            lng: resqData["lng"],
            speed: resqData["speed"],
            engine: 1,
            movingStatus: 2,
            trackData: true,
          },
          null,
          {
            "Content-Type": "application/json",
            authorization: `Bearer ${hexDigest}`,
            dilidentity: dilidentity,
          }
        );
        console.log("sending to to box success", respData);
      } catch (error) {
        console.log("sending to to box error", error);
      }
    },
    async confirmAction() {
      this.textStatus.push("Job Confirm: Getting...");

      let queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();

      this.getJobAndCheckpointData();

      queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();

      let startRespData = await putJobDriverSubmit(this.$store, queryParams, {
        status: "CONFIRM",
        vehicleId: this.jobData.vehicleId["_id"], //this.formData["vehicleId"],
      });

      if (startRespData["code"] === 200) {
        this.textStatus.push("Job Confirm: Ok...");
        this.runningStatus = 1;
      } else {
        // alert error
        this.textStatus.push("Job Confirm: Fail");
        return;
      }

      this.loadRegisterBox();
    },

    async startAction() {
      this.textStatus = ["Initialize"];
      this.textStatus.push("Job detail: Getting...");
      let queryParams = null;

      queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();

      if (_.isNil(this.jobData["driverId"])) {
        this.textStatus.push("Error:: Driver ID not found");
        return;
      }
      //
      this.textStatus.push("Checkpoint: Connecting...");
      let _reqBody = {
        status: "START",
        vehicleId: this.jobData.vehicleId["_id"],
        // vehicleId: this.formData.vehicleId,
      };
      console.log("StartAction request body:", _reqBody);
      let startRespData = await putJobDriverSubmit(
        this.$store,
        queryParams,
        _reqBody
      );

      if (startRespData["code"] === 200) {
        this.textStatus.push("Checkpoint: Running...");
        this.runningStatus = 1;
      } else {
        // alert error
        this.textStatus.push("Checkpoint Start Fail!!");
        return;
      }

      let gpsData = null;
      try {
        this.csvData = await this.getDataFromFile();
        // console.log(this.csvData);
        // this.csvData = [
        //   {
        //     timestamp: "1667446237895",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446238886",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446239887",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446240886",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446241887",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446242887",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446243888",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446244888",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        //   {
        //     timestamp: "1667446245887",
        //     latitude: "19.9583649",
        //     longitude: "100.3115513",
        //     speed: "0.07955276221036911",
        //   },
        // ];
        this.startMain();
      } catch (error) {
        console.error(error);
      }
    },
    async callApiToStop() {
      let queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();

      let _reqBody = {
        status: "STOP",
        vehicleId: this.jobData.vehicleId["_id"],
        // vehicleId: this.formData.vehicleId,
      };
      console.log("callApiToStop requrest body:", _reqBody);
      let endRespData = await putJobDriverSubmit(
        this.$store,
        queryParams,
        _reqBody
      );

      if (endRespData["code"] === 200) {
        this.textStatus.push("Stop Checkpoint...");
        this.runningStatus = 0;
      } else {
        // alert error
        this.textStatus.push("Stop Fail...");
      }
    },
    async callApiToStamp(checkpointIdx, pass, saveObjParameter) {
      let t = {
        markerId: this.checkpointData[checkpointIdx]["_id"],
        closestDistance: this.minDistance[checkpointIdx],
        currentDistance: null,
        timestamp: pass ? new Date().getTime() : null,
        speed: !_.isNil(saveObjParameter["speed"])
          ? saveObjParameter["speed"]
          : null,
        status: pass ? "P" : "M",
        totalDistance: Math.floor(Math.random() * 50),
        overLimitSpeedCount: Math.floor(Math.random() * 11),
      };

      this.processCheckpoint.push(t);
      //
      try {
        let respData = await putJobDriverCheckpoint(
          this.$store,
          `id=${this.jobData["_id"]}`,
          t
        );
        //
        if (respData["code"] === 200) {
          this.processCheckpointRespData.push(respData["data"]);
        } else {
          throw respData;
        }
      } catch (error) {
        this.processCheckpointRespData.push({ status: "error" });
      }
    },
    //
    async callApiToUpdateAllCheckpoint() {
      try {
        // preprocess data
        let reqData = [];
        for (let i = 0; i < this.checkpointData.length; i++) {
          reqData.push({
            markerId: this.checkpointData[i]["_id"],
            closestDistance: this.minDistance[i],
            timestamp: this.minDistanceTime[i],
          });
        }

        let respData = await putJobDriverAllCheckpoint(
          this.$store,
          `id=${this.jobData["_id"]}`,
          {
            checkpoints: reqData,
          }
        );

        if (respData["code"] === 200) {
          this.textStatus.push(`callApiToUpdateAllCheckpoint: sending success`);
        } else {
          this.textStatus.push(
            `callApiToUpdateAllCheckpoint: sending fail, server error`
          );
        }
      } catch (error) {
        this.textStatus.push(
          `callApiToUpdateAllCheckpoint: sending success, programming or  server error`
        );
        console.error(error);
      }
    },

    async stopAction() {
      if (this.runningStatus === 1) {
        clearInterval(this.timeinterval);
        //
        await this.callApiToStop();
      } else {
        this.textStatus.push("Already stop");
      }
    },
    async setPreparing() {
      let queryParams = null;

      queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();
      //
      let respData = await putJobStatusService(this.$store, queryParams, {
        status: "PREPARING",
      });

      if (respData["code"] === 200) {
        this.textStatus.push("Set Preparing status: success");
      } else {
        this.textStatus.push("Set Preparing status: fail");
      }
    },
    async getJobAndCheckpointData() {
      let respData = null;
      let queryParams = new URLSearchParams({
        id: this.formData["jobId"],
      }).toString();

      respData = await getJobInfo(this.$store, queryParams);
      if (respData["code"] === 200) {
        this.jobData = respData["data"];
        this.textStatus.push("Job detail: Success");
      } else {
        this.textStatus.push("Cannot Get Job detail fail! \n");
        return;
      }
      // get checkpoint
      respData = await getMarkerService(this.$store, queryParams);
      if (respData["code"] === 200) {
        this.checkpointData = respData["data"];
        this.textStatus.push("Job Marker detail: Success");
      } else {
        this.textStatus.push("Cannot Get Job Marker fail!");
        return;
      }
    },
    //
    //
    calculateDistance(latP1, latP2, lonP1, lonP2) {
      latP1 = (latP1 * Math.PI) / 180;
      latP2 = (latP2 * Math.PI) / 180;

      lonP1 = (lonP1 * Math.PI) / 180;
      lonP2 = (lonP2 * Math.PI) / 180;

      // Haversine formula
      let dlat = latP2 - latP1;
      let dlon = lonP2 - lonP1;

      let a =
        Math.pow(Math.sin(dlat / 2), 2) +
        Math.cos(latP1) * Math.cos(latP2) * Math.pow(Math.sin(dlon / 2), 2);

      let c = 2 * Math.asin(Math.sqrt(a));

      // Radius of earth in kilometers. Use 3956  for miles
      let r = 6371;

      // calculate the result
      return c * r;
    },
    onTick(vehicleLat, vehicleLng, vehicleSpeed, _countIdx) {
      // CORE FUNCTION
      let lastIndexCheck = -1;
      for (let i = 0; i < this.checkpointData.length; i++) {
        let cLat = this.checkpointData[i]["coordinates"][0];
        let cLng = this.checkpointData[i]["coordinates"][1];

        // this.textStatus.push(
        //   `checkpoint coordinate: ${cLat},${cLng} / ${vehicleLat},${vehicleLng}`
        // );

        let _distance = this.calculateDistance(
          cLat,
          vehicleLat,
          cLng,
          vehicleLng
        );

        let _distanceInMeter = _distance * 1000;

        // this.textStatus.push(
        //   `checkpoint distance: ${_distance} km, ${_distanceInMeter} meter`
        // );

        if (_distanceInMeter < this.minDistance[i]) {
          this.minDistance[i] = _distanceInMeter;
          this.minDistanceTime[i] = new Date().valueOf();
        }
        //

        if (this.checkpointData[i]["isStamp"] === true) {
          lastIndexCheck = i;
          continue;
        }

        // Out of target
        if (
          _distanceInMeter > this.checkpointData[i]["checkpointRadiusInMeter"]
        ) {
          continue;
        }

        // In target
        this.callApiToStamp(i, true, { speed: vehicleSpeed });
        this.checkpointData[i]["isStamp"] = true;
        this.textStatus.push(
          `checkpoint: isStemp at marker index ${i} csvIndex${_countIdx}`
        );

        if (i - 1 === lastIndexCheck) {
          lastIndexCheck = i;
          continue;
        }
        //

        while (true) {
          lastIndexCheck += 1;
          if (i === lastIndexCheck) {
            break;
          }
          this.callApiToStamp(lastIndexCheck, false, { speed: vehicleSpeed });
          this.checkpointData[lastIndexCheck]["isStamp"] = true;
        }
      }
    },
    async startMain() {
      //
      this.minDistance = [];
      this.processCheckpoint = [];
      this.processCheckpointRespData = [];

      for (let i = 0; i < this.checkpointData.length; i++) {
        this.checkpointData[i]["isStamp"] = false;
        this.minDistance.push(999999);
        this.minDistanceTime.push(null);
      }

      this.textStatus.push(`Start OnTick`);
      this.countIdx = 0;
      this.isPause = false;

      this.timeinterval = setInterval(() => {
        let _lat = parseFloat(this.csvData[this.countIdx]["latitude"]);
        let _lng = parseFloat(this.csvData[this.countIdx]["longitude"]);
        let _speed = parseFloat(this.csvData[this.countIdx]["speed"]);
        if (!this.isPause) {
          // 40
          if (this.countIdx % 20 === 0) {
            // this.textStatus.push(
            //   `checkpoint: callApiSendingToBox ${new Date().valueOf()}`
            // );
            let toBoxData = { ...this.csvData[this.countIdx] };
            toBoxData["lat"] = _lat;
            toBoxData["lng"] = _lng;
            this.callApiSendingToBox(toBoxData);
          }

          this.onTick(_lat, _lng, _speed, this.countIdx);

          this.countIdx += 1;
          if (this.countIdx >= this.csvData.length) {
            // TODO:: call final update
            this.stopAction();
            this.callApiToUpdateAllCheckpoint();
          }
        }
      }, 100);

      // for (let i = 0; i < this.csvData.length; i++) {
      //   let _lat = parseFloat(this.csvData[i]["latitude"]);
      //   let _lng = parseFloat(this.csvData[i]["longitude"]);
      //   let speed = parseFloat(this.csvData[i]["speed"]);
      //   //
      //   this.onTick(_lat, _lng, _speed);
      //   //
      //   // eventCount += 1;
      //   // this.textStatus.push(`Sending Checkpoint ${eventCount}`);
      // }

      // this.timeinterval = setInterval(() => {
      //   this.textStatus.push(`Sending Checkpoint ${this.eventCount}`);
      //   this.sendingRequest(countingIndex);
      // }, this.delayTimeInSecond * 1000);
    },
    setPause() {
      this.isPause = !this.isPause;
    },
  },
  destroyed() {
    clearInterval(this.timeinterval);
  },
};
</script>
