<template>
  <div name="WorkingExperienceFormView">
    <v-form ref="WorkingExperienceFormViewRef" v-model="valid">
      <CustomCardFormViewModern
        title="ประวัติการทำงานที่ผ่านมา"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div v-for="(working, idx) in careerInfoList" :key="idx">
          <v-row v-if="idx > 0" class="px-12 pt-3 pb-6"
            ><v-divider></v-divider
          ></v-row>
          <v-row class="px-12" v-if="idx > 0">
            <v-spacer></v-spacer>
            <v-btn icon :disabled="!canEdit" @click="handleDeleteItem(idx)">
              <v-icon color="error lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-row>

          <v-row no-gutters class="px-6">
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <CustomTextInputImprove
                    v-model="working.company"
                    ref="company"
                    title="ชื่อบริษัท/สถานที่ทำงาน"
                    placeholder="ชื่อบริษัท/สถานที่ทำงาน"
                    :disabled="!canEdit"
                    dataInputName="company"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <CustomTextInputImprove
                    v-model="working.position"
                    ref="position"
                    title="ตำแหน่งงาน"
                    placeholder="ตำแหน่งงาน"
                    :disabled="!canEdit"
                    dataInputName="position"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <CustomTextInputCurrency
                    title="ค่าจ้าง (฿)"
                    ref="salary"
                    v-model="working.salary"
                    placeholder="จำนวนเงินหน่วยบาท"
                    :readonly="!canEdit"
                    dataInputName="previoussalary"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <InputView title="ระยะเวลาการทำงาน" class="mx-3">
                    <v-row>
                      <v-col class="px-0 pt-1">
                        <CustomTextInputImprove
                          v-model="working.minYear"
                          ref="minYear"
                          placeholder="เดือน/ปีค.ศ.เริ่ม"
                          inputType="monthYear"
                          :clearable="true"
                          dataInputName="searchingMinYear"
                          :isMonthYear="true"
                          :showRequiredBullet="false"
                        />
                      </v-col>
                      <p class="title-filter subtitle--text pt-5">—</p>

                      <v-col class="px-0 pt-1">
                        <CustomTextInputImprove
                          v-model="working.maxYear"
                          ref="maxYear"
                          placeholder="เดือน/ปีค.ศ.สิ้นสุด"
                          inputType="monthYear"
                          dataInputName="searchingMaxYear"
                          :clearable="true"
                          :isMonthYear="true"
                          :showRequiredBullet="false"
                        />
                      </v-col>
                    </v-row>
                  </InputView>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <CustomTextareaImprove
                v-model="working.resignationReason"
                title="สาเหตุที่ออก"
                type="text"
                placeholder="สาเหตุที่ออก"
                :height="136"
                :disabled="disabled"
                dataInputName="resignationReason"
              />
            </v-col>
          </v-row>
        </div>
        <v-row class="px-12 mt-3" v-if="canEdit">
          <v-spacer></v-spacer>
          <PrimaryButton
            v-if="canEdit"
            icon="mdi-plus"
            :outlined="true"
            :disabled="this.careerInfoList.length == 3"
            @on-click="handleAddClick()"
          >
            เพิ่มประวัติการทำงาน
          </PrimaryButton>
          <v-spacer></v-spacer>
        </v-row>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          @on-click-skip="onClickSkip()"
          :is-enable-skip="isEnableSkip"
          :disabled="!canEdit"
          v-if="canEdit"
          text="บันทึกประวัติการทำงาน"
        />
      </CustomCardFormViewModern>
      <AppDialogConfirm ref="dialogError" />
    </v-form>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { errorMessageMapper } from "@/services/error";
import { DialogType } from "@/services/dialog";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";

export default {
  name: "WorkingExperienceFormView",

  components: {
    CustomTextInput,
    CustomSelects,
    AppFilesUpload,
    AppDialogConfirm,
    CustomCardFormViewModern,
    AppSubmitFormButton,
    CustomSelectsImprove,
    PrimaryButton,
    CustomTextInputImprove,
    InputView,
    CustomTextInputCurrency,
    CustomTextareaImprove,
  },

  props: {
    careerExperience: {
      type: [Array, null],
      default: null,
    },
    isEnableSkip: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  // watch: {

  // },

  data() {
    return {
      valid: false,
      careerInfoList: [],
      formData: {
        company: null,
        position: null,
        salary: null,
        minYear: null,
        maxYear: null,
        resignationReason: null,
      },
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      disabled: false,
    };
  },

  mounted() {
    this.handleAddClick();
  },
  watch: {
    canEdit: function (newValue) {
      this.disabled = !newValue;
    },

    async careerExperience(newValue) {
      if (!_.isNil(newValue)) {
        if (newValue.length > 0) {
          this.careerInfoList = _.cloneDeep(newValue);
        }
      }
    },
  },

  methods: {
    handleAddClick() {
      if (this.careerInfoList.length < 3) {
        let working = _.cloneDeep(this.formData);
        this.careerInfoList.push(working);
      }
    },
    handleDeleteItem(idx) {
      this.careerInfoList.splice(idx, 1);
    },

    async saveChange() {
      this.disabled = true;
      if (this.$refs["WorkingExperienceFormViewRef"].validate() === true) {
        // try {
        let returnData = { careerExperience: [] };
        for (let idx in this.careerInfoList) {
          let career = _.cloneDeep(this.careerInfoList[idx]);
          returnData["careerExperience"].push(career);
        }
        this.$emit("on-click-save", returnData);
        // } catch (error) {
        //   this.showDialogError(error);
        // }
      } else {
        this.$refs["WorkingExperienceFormViewRef"].$el.scrollIntoView(
          this.focus
        );
      }
      this.disabled = false;
    },

    async showDialogError(responseData) {
      let text_error = "ไม่สามารถแก้ไขข้อมูลบัญชีได้ ลองใหม่อีกครั้ง";
      if (!_.isNil(responseData)) {
        text_error = `${text_error} (${
          errorMessageMapper[responseData["message"]]
        })`;
      }

      await this.$refs.dialogError.open(text_error, null, DialogType.ERROR, {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      });
    },

    onClickSkip() {
      this.$emit("on-click-skip");
    },
  },
};
</script>
<style>
.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
.backgroud-image {
  background: #f4f6f6;
}

.v-input {
  font-size: 14px;
}

.theme--light.v-file-input .v-file-input__text--placeholder {
  color: rgb(170, 183, 184) !important;
}
</style>
