<template>
  <AssignMultipleJobTable
    :sheet-id="sheetId"
    :table-id="tableId"
    :table-idx="tableIdx"
  />
</template>

<script>
import AssignMultipleJobTable from "./AssignMultipleJobTable.vue";

export default {
  name: "AssignMultipleJobSection",
  components: { AssignMultipleJobTable },
  props: {
    sheetId: {
      require: true,
    },
    tableId: {
      required: true,
    },
    tableIdx: {
      required: true,
    },
  },
};
</script>
