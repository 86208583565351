import moment from "moment";
import { getShortMonthNameHelper } from "@/services/appDate";

export function calculateHours(d) {
  return Math.floor(d / 3600);
}
export function calculateKm(d) {
  return Math.floor(d / 1000);
}

export function processWeekYearStringList(weeklyDataList) {
  let weekName = [];
  for (let i = 0; i < weeklyDataList.length; i++) {
    const startDateOnWeek = moment()
      .day(0)
      .year(weeklyDataList[i]["year"])
      .week(weeklyDataList[i]["week"]);
    const endDateOnWeek = moment()
      .day(0)
      .year(weeklyDataList[i]["year"])
      .week(weeklyDataList[i]["week"])
      .add(6, "days");

    // DD - DD MM YYYY
    // DD MM - DD MM YYY
    // DD MM YYYY -DD MMM -YYYY
    let startDateStr = startDateOnWeek.format("DD");
    let endDateStr = endDateOnWeek.format("DD");
    if (startDateOnWeek.format("MM") !== endDateOnWeek.format("MM")) {
      startDateStr = `${startDateStr} ${getShortMonthNameHelper(
        startDateOnWeek.month()
      )}`;
      endDateStr = `${endDateStr} ${getShortMonthNameHelper(
        endDateOnWeek.month()
      )}`;
    } else {
      endDateStr = `${endDateStr} ${getShortMonthNameHelper(
        endDateOnWeek.month()
      )}`;
    }

    // if (startDateOnWeek.format("YYYY") !== endDateOnWeek.format("YYYY")) {
    //   startDateStr = `${startDateStr} ${startDateOnWeek.format("YYYY")}`;
    //   endDateStr = `${endDateStr} ${endDateOnWeek.format("YYYY")}`;
    // } else {
    //   endDateStr = `${endDateStr} ${startDateOnWeek.format("YYYY")}`;
    // }

    weekName.push(`${startDateStr} - ${endDateStr}`);
  }

  return weekName;
}

export function nFormatterHelper(number) {
  if (number === undefined) {
    return number;
  }
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
}
