import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

//
export async function getMultipleJobSheetListService(store, queryString) {
  let uri = "/multiple-job";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getMultipleJobSheetInfoService(store, queryString) {
  let uri = "/multiple-job/info";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postMultipleJobSheetService(store, queryString, data) {
  let uri = "/multiple-job";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

export async function putMultipleJobSheetService(store, queryString, data) {
  let uri = "/multiple-job";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteMultipleJobSheetService(store, id) {
  let uri = `/multiple-job?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

//
export async function getMultipleJobTableService(store, queryString) {
  let uri = "/multiple-job/table";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postMultipleJobTableService(store, queryString, data) {
  let uri = "/multiple-job/table";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

export async function deleteMultipleJobTableService(store, id) {
  let uri = `/multiple-job/table?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

// table col
export async function getMultipleJobTableInfoService(store, queryString) {
  let uri = "/multiple-job/table-info";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putMultipleJobTableHeaderService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-header";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteMultipleJobTableHeaderService(store, queryString) {
  let uri = "/multiple-job/table-header";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

//
// table row
//
export async function putMultipleJobTableRowDataService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-row";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteMultipleJobTableRowDataService(store, queryString) {
  let uri = "/multiple-job/table-row";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

export async function putCreateJobFromTableRowService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-job";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

//
// table cell
//
export async function putMultipleJobTableCellDataService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-cell";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function putAllocateToMultipleJobTableCellDataService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-allocate-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteClearAllocatedDriverInCellService(
  store,
  queryString
) {
  let uri = "/multiple-job/table-clear-cell-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

export async function getTableCellDriverInCartHasJobService(
  store,
  queryString
) {
  let uri = "/multiple-job/table-cell-driver-in-cart-job";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// utility sheet
// utility table
export async function getRouteListByContractIdService(store, queryString) {
  let uri = "/multiple-job/sheet-contract";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
export async function getTimeLabelFromRouteService(store, queryString) {
  let uri = "/multiple-job/sheet-contract-timelabel";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putDriverToMultipleJobService(store, queryString, data) {
  let uri = "/multiple-job/submit-cell-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function getCheckDriverOnTableCellService(store, queryString) {
  let uri = "/multiple-job/check-driver-to-cell";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// utility cell

// driver drawer
export async function getDriverToCartTableService(store, queryString) {
  let uri = "/multiple-job/table-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putDriverToCartTableService(store, queryString, data) {
  let uri = "/multiple-job/table-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function getSearchDriverService(store, queryString) {
  let uri = "/multiple-job/table-search-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// driver
export async function putImportDriverToCartTableService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-import-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteDriverFromCartTableService(store, queryString) {
  let uri = "/multiple-job/table-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

// force to create job
export async function putForceCreateJobFromCellTableService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-job-force-create";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// table duplicate
export async function getSheetForDuplicateService(store, queryString) {
  let uri = "/multiple-job/table-sheet-list";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putDuplicateTableToSheetService(
  store,
  queryString,
  data
) {
  let uri = "/multiple-job/table-duplicate";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// copy multiple table to new sheet
export async function postCopyMultipleTableToNewSheetServer(store, data) {
  let uri = "/multiple-job/multiple-table-duplicate";

  return await authHttpClientPost(store, uri, data);
}
