<template>
  <div class="text-center">
    <v-dialog scrollable v-model="show" width="800" persistent>
      <v-card>
        <!-- <v-card-title class="text-h5"> ใบลางาน </v-card-title> -->
        <v-toolbar color="primary" dark>
          <v-card-title> สรุปข้อมูลพฤติกรรม </v-card-title> <v-spacer></v-spacer
          ><v-btn icon @click.native="cancelStatus"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-toolbar
        >

        <v-card-text class="pt-5 px-0">
          <v-card class="mb-6 pb-3 mx-0 px-12" elevation="0">
            <div v-if="id !== null" class="title-input pl-3">ผู้กระทำผิด</div>
            <div v-if="id !== null" class="d-inline-flex px-3 mb-6 mt-3">
              <v-avatar size="64">
                <v-img
                  :src="
                    !employee.profileImage
                      ? defaultPFImage
                      : employee.profileImage
                  "
                >
                </v-img>
              </v-avatar>
              <div class="pl-2 align-self-center">
                <p class="ma-0 font-weight-bold">
                  {{
                    `${
                      employee.salutation === null ? "" : employee.salutation.th
                    } ${
                      employee.firstName === null ? "" : employee.firstName.th
                    } ${
                      employee.lastName === null ? "" : employee.lastName.th
                    } `
                  }}
                  {{
                    employee.nickName === null
                      ? ""
                      : `(${employee.nickName.th})`
                  }}
                </p>
                <p class="ma-0 text-app-detail-normal">
                  เบอร์โทร:
                  <span v-if="id !== null">
                    <span
                      v-for="(phoneNumber, index) in employee.phoneNumber"
                      :key="index"
                      ><span
                        v-if="index > 0 && index < employee.phoneNumber.length"
                      >
                        , </span
                      >{{ phoneNumber }}</span
                    >
                  </span>
                  <span v-else>-</span>
                </p>
              </div>
              <v-spacer></v-spacer>
              <v-divider class="mx-3 my-3" vertical></v-divider>
              <div class="pl-2 align-self-center text-app-detail-normal">
                <p class="ma-0">
                  รหัสพนักงาน:
                  <span v-if="id !== null">{{ employee.empId }}</span>
                  <span v-else>-</span>
                </p>
                <p class="ma-0">
                  ตำแหน่ง:
                  <span
                    v-if="
                      employee.position !== null &&
                      employee.position !== undefined
                    "
                    >{{ employee.position }}</span
                  >
                  <span v-else>-</span>
                </p>
              </div>
            </div>
            <v-row no-gutters class="mx-3">
              <v-col cols="12" lg="12" sm="12" class="mb-9">
                <v-row no-gutters>
                  <div class="title-input text-app-detail-large">
                    คะแนนการกระทำผิดทั้งหมด
                    <!-- <span class="text-app-detail-normal"
                      >(ทำผิด {{ percentCateg[0] / 10 }} ครั้ง)</span
                    > -->
                  </div>
                  <v-spacer></v-spacer>
                  <!-- <v-col cols="2" align="right">
                    <span class="text-app-md font-weight-bold"
                      >categoryTotalScore - {{ percentCateg[0] }} คะแนน</span
                    ></v-col
                  > -->
                </v-row>
                <v-row no-gutters>
                  <div>
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนคงเหลือ</span
                    >
                    <span
                      v-if="employee !== null"
                      :class="`ma-0 ${getPercentCategColor(
                        totalScore - employee.penaltyReport.sumScore
                      )}--text text-app-detail-large font-weight-black`"
                    >
                      <span
                        v-if="
                          totalScore - employee.penaltyReport.sumScore !==
                          parseInt(totalScore - employee.penaltyReport.sumScore)
                        "
                      >
                        {{
                          (
                            totalScore - employee.penaltyReport.sumScore
                          ).toFixed(2)
                        }}
                      </span>
                      <span v-else>{{
                        totalScore - employee.penaltyReport.sumScore
                      }}</span>
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col align="right">
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนทั้งหมด</span
                    >
                    <span class="ma-0 text-app-detail-large font-weight-bold">
                      {{ totalScore }}
                    </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                  class="mt-1"
                  height="20"
                  :value="totalScore - employee.penaltyReport.sumScore"
                  striped
                  rounded
                  :color="
                    getPercentCategColor(
                      totalScore - employee.penaltyReport.sumScore
                    )
                  "
                >
                </v-progress-linear>
                <v-row no-gutters>
                  <div>
                    <p class="ma-0 text-app-detail-normal">
                      ทำผิด
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        >{{ employee.penaltyReport.sumCounter }}</span
                      >
                      ครั้ง
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <p class="ma-0 text-app-detail-normal">
                      ถูกหัก
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        ><span v-if="employee.penaltyReport.sumScore > 0"
                          >-</span
                        >
                        <span
                          v-if="
                            employee.penaltyReport.sumScore !==
                            parseInt(employee.penaltyReport.sumScore)
                          "
                        >
                          {{
                            employee.penaltyReport.sumScore
                              ? employee.penaltyReport.sumScore.toFixed(2)
                              : ""
                          }}</span
                        >
                        <span v-else>
                          {{ employee.penaltyReport.sumScore }}</span
                        >
                      </span>
                      คะแนน
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-6">
                  <v-col
                    v-if="employee.penaltyReport.stats"
                    cols="8"
                    class="text-app-small-detail"
                  >
                    <p class="my-0 font-weight-bold text-app-detail-normal">
                      * หักคะแนนรวมคำนวนอย่างไร
                    </p>
                    <p class="my-0">
                      <span class="text-decoration-underline"
                        >ด้านการปฏิบัติงาน</span
                      >
                      มีความสำคัญ {{ employee.penaltyReport.stats[0].weight }}%
                      และถูกหัก
                      <span v-if="employee.penaltyReport.stats[0].score > 0"
                        >-</span
                      >
                      <span
                        v-if="
                          employee.penaltyReport.stats[0].score !==
                          parseInt(employee.penaltyReport.stats[0].score)
                        "
                        >{{
                          employee.penaltyReport.stats[0].score.toFixed(2)
                        }}</span
                      >
                      <span v-else>{{
                        employee.penaltyReport.stats[0].score
                      }}</span>
                      คะแนน คิดเป็น
                      <span class="primary--text">
                        <span v-if="totalScoreRatio[0] > 0">-</span
                        ><span
                          v-if="
                            totalScoreRatio[0] !== parseInt(totalScoreRatio[0])
                          "
                          >{{ totalScoreRatio[0].toFixed(2) }}</span
                        ><span v-else>{{ totalScoreRatio[0] }}</span></span
                      >
                    </p>
                    <p class="my-0">
                      <span class="text-decoration-underline"
                        >ด้านความปลอดภัยและจีพีเอส</span
                      >
                      มีความสำคัญ {{ employee.penaltyReport.stats[1].weight }}%
                      และถูกหัก
                      <span v-if="employee.penaltyReport.stats[1].score > 0"
                        >-</span
                      ><span
                        v-if="
                          employee.penaltyReport.stats[1].score !==
                          parseInt(employee.penaltyReport.stats[1].score)
                        "
                        >{{
                          employee.penaltyReport.stats[1].score.toFixed(2)
                        }}</span
                      ><span v-else>{{
                        employee.penaltyReport.stats[1].score
                      }}</span>
                      คะแนน คิดเป็น
                      <span class="primary--text"
                        ><span v-if="totalScoreRatio[1] > 0">-</span
                        ><span
                          v-if="
                            totalScoreRatio[1] !== parseInt(totalScoreRatio[1])
                          "
                          >{{ totalScoreRatio[1].toFixed(2) }}</span
                        ><span v-else>{{ totalScoreRatio[1] }}</span></span
                      >
                    </p>
                    <p class="my-0">
                      <span class="text-decoration-underline"
                        >ด้านทรัพยากรมนุษย์และผู้ดูแลระบบ</span
                      >
                      มีความสำคัญ {{ employee.penaltyReport.stats[2].weight }}%
                      และถูกหัก
                      <span v-if="employee.penaltyReport.stats[2].score > 0"
                        >-</span
                      ><span
                        v-if="
                          employee.penaltyReport.stats[2].score !==
                          parseInt(employee.penaltyReport.stats[2].score)
                        "
                        >{{
                          employee.penaltyReport.stats[2].score.toFixed(2)
                        }}</span
                      ><span v-else>{{
                        employee.penaltyReport.stats[2].score
                      }}</span>
                      คะแนน คิดเป็น
                      <span class="primary--text"
                        ><span v-if="totalScoreRatio[2] > 0">-</span
                        >{{ totalScoreRatio[2] }}</span
                      >
                    </p>
                    <p class="mt-6">
                      <router-link
                        v-if="employee.penaltyReport.sumScore > 0"
                        class="text-decoration-none text-app-detail-normal text-decoration-underline"
                        :to="`/employee/penality/list?empId=${employee.empId}`"
                        >** ดูประวัติการกระทำผิด
                      </router-link>
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="d-flex justify-end pt-3">
                    <div class="align-self-end" align="right">
                      <span class="font-weight-bold" style="font-size: 60px">
                        <span
                          v-if="
                            totalScore - employee.penaltyReport.sumScore !==
                            parseInt(
                              totalScore - employee.penaltyReport.sumScore
                            )
                          "
                          >{{
                            (
                              totalScore - employee.penaltyReport.sumScore
                            ).toFixed(2)
                          }}</span
                        >
                        <span v-else>{{
                          totalScore - employee.penaltyReport.sumScore
                        }}</span></span
                      ><br />
                      <span class="text-app-md font-weight-bold"
                        >คะแนนที่เหลือ</span
                      ><br />
                      <span class="text-app-small-detail"
                        >ไม่ควรเหลือต่ำกว่า </span
                      ><span
                        class="text-app-detail-md font-weight-bold error--text"
                        >{{ employee.penaltyReport.floorScore }}
                      </span>
                      <span class="text-app-small-detail">คะแนน</span>
                      <br />
                      <span class="text-app-small-detail"
                        >มิฉะนั้นจะ
                        <span class="font-weight-bold"
                          >ถูกลงโทษร้ายแรง</span
                        ></span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mb-6" />
            <v-row no-gutters class="mx-3" v-if="employee.penaltyReport.stats">
              <v-col cols="12" lg="12" sm="12" class="mb-9">
                <v-row no-gutters
                  ><div class="title-input text-app-detail-large">
                    คะแนนการกระทำผิดในแต่ละด้าน
                    <!-- <span class="text-app-detail-normal"
                      >(ทำผิด {{ percentCateg[0] / 10 }} ครั้ง)</span
                    > -->
                  </div></v-row
                >
                <v-row no-gutters>
                  <div class="title-input">
                    ด้านการปฏิบัติงาน
                    <!-- <span class="text-app-detail-normal"
                      >(ทำผิด {{ percentCateg[0] / 10 }} ครั้ง)</span
                    > -->
                  </div>
                  <v-spacer></v-spacer>
                  <!-- <v-col cols="2" align="right">
                    <span class="text-app-md font-weight-bold"
                      >categoryTotalScore - {{ percentCateg[0] }} คะแนน</span
                    ></v-col
                  > -->
                </v-row>
                <v-row no-gutters>
                  <div>
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนคงเหลือ</span
                    >
                    <span
                      :class="`ma-0 ${getPercentCategColor(
                        employee.penaltyReport.stats[0].maxScore -
                          employee.penaltyReport.stats[0].score
                      )}--text text-app-detail-large font-weight-black`"
                    >
                      <span
                        v-if="
                          employee.penaltyReport.stats[0].maxScore -
                            employee.penaltyReport.stats[0].score !==
                          parseInt(
                            employee.penaltyReport.stats[0].maxScore -
                              employee.penaltyReport.stats[0].score
                          )
                        "
                      >
                        {{
                          (
                            employee.penaltyReport.stats[0].maxScore -
                            employee.penaltyReport.stats[0].score
                          ).toFixed(2)
                        }}
                      </span>
                      <span v-else>
                        {{
                          employee.penaltyReport.stats[0].maxScore -
                          employee.penaltyReport.stats[0].score
                        }}
                      </span>
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col align="right">
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนทั้งหมด</span
                    >
                    <span class="ma-0 text-app-detail-large font-weight-bold">
                      {{ employee.penaltyReport.stats[0].maxScore }}
                    </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                  class="mt-1"
                  height="20"
                  :value="
                    employee.penaltyReport.stats[0].maxScore -
                    employee.penaltyReport.stats[0].score
                  "
                  striped
                  rounded
                  :color="
                    getPercentCategColor(
                      employee.penaltyReport.stats[0].maxScore -
                        employee.penaltyReport.stats[0].score
                    )
                  "
                >
                </v-progress-linear>
                <v-row no-gutters>
                  <div>
                    <p class="ma-0 text-app-detail-normal">
                      ทำผิด
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        >{{ employee.penaltyReport.stats[0].counter }}</span
                      >
                      ครั้ง
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <p class="ma-0 text-app-detail-normal">
                      ถูกหัก
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        ><span v-if="employee.penaltyReport.stats[0].score > 0"
                          >-</span
                        ><span
                          v-if="
                            employee.penaltyReport.stats[0].score !==
                            parseInt(employee.penaltyReport.stats[0].score)
                          "
                          >{{
                            employee.penaltyReport.stats[0].score.toFixed(2)
                          }}</span
                        >
                        <span v-else>
                          {{ employee.penaltyReport.stats[0].score }}
                        </span></span
                      >
                      คะแนน
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-3" v-if="employee.penaltyReport.stats">
              <v-col cols="12" lg="12" sm="12" class="mb-9">
                <v-row no-gutters>
                  <div class="title-input">
                    ด้านความปลอดภัยและจีพีเอส
                    <!-- <span class="text-app-detail-normal"
                      >(ทำผิด {{ percentCateg[1] / 10 }} ครั้ง)</span
                    > -->
                  </div>
                  <!-- <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <span class="text-app-md font-weight-bold"
                      >categoryTotalScore - {{ percentCateg[1] }} คะแนน</span
                    ></v-col
                  > -->
                </v-row>
                <v-row no-gutters>
                  <div>
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนคงเหลือ</span
                    >
                    <span
                      :class="`ma-0 ${getPercentCategColor(
                        employee.penaltyReport.stats[1].maxScore -
                          employee.penaltyReport.stats[1].score
                      )}--text text-app-detail-large font-weight-black`"
                      ><span
                        v-if="
                          employee.penaltyReport.stats[1].maxScore -
                            employee.penaltyReport.stats[1].score !==
                          parseInt(
                            employee.penaltyReport.stats[1].maxScore -
                              employee.penaltyReport.stats[1].score
                          )
                        "
                      >
                        {{
                          (
                            employee.penaltyReport.stats[1].maxScore -
                            employee.penaltyReport.stats[1].score
                          ).toFixed(2)
                        }}
                      </span>
                      <span v-else>
                        {{
                          employee.penaltyReport.stats[1].maxScore -
                          employee.penaltyReport.stats[1].score
                        }}
                      </span>
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col align="right">
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนทั้งหมด</span
                    >
                    <span class="ma-0 text-app-detail-large font-weight-bold">
                      {{ employee.penaltyReport.stats[1].maxScore }}
                    </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                  class="mt-1"
                  height="20"
                  :value="
                    employee.penaltyReport.stats[1].maxScore -
                    employee.penaltyReport.stats[1].score
                  "
                  striped
                  rounded
                  :color="
                    getPercentCategColor(
                      employee.penaltyReport.stats[1].maxScore -
                        employee.penaltyReport.stats[1].score
                    )
                  "
                >
                </v-progress-linear>
                <v-row no-gutters>
                  <div>
                    <p class="ma-0 text-app-detail-normal">
                      ทำผิด
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        >{{ employee.penaltyReport.stats[1].counter }}</span
                      >
                      ครั้ง
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <p class="ma-0 text-app-detail-normal">
                      ถูกหัก
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        ><span v-if="employee.penaltyReport.stats[1].score > 0"
                          >-</span
                        >
                        <span
                          v-if="
                            employee.penaltyReport.stats[1].score !==
                            parseInt(employee.penaltyReport.stats[1].score)
                          "
                          >{{
                            employee.penaltyReport.stats[1].score.toFixed(2)
                          }}</span
                        >
                        <span v-else>{{
                          employee.penaltyReport.stats[1].score
                        }}</span>
                      </span>
                      คะแนน
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-3" v-if="employee.penaltyReport.stats">
              <v-col cols="12" lg="12" sm="12" class="mb-9">
                <v-row no-gutters>
                  <div class="title-input">
                    ด้านทรัพยากรมนุษย์และผู้ดูแลระบบ
                    <!-- <span class="text-app-detail-normal"
                      >(ทำผิด {{ percentCateg[2] / 10 }} ครั้ง)</span
                    > -->
                  </div>
                  <!-- <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <span class="text-app-md font-weight-bold">
                      categoryTotalScore - {{ percentCateg[2] }} คะแนน</span
                    ></v-col
                  > -->
                </v-row>
                <v-row no-gutters>
                  <div>
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนคงเหลือ</span
                    >
                    <span
                      :class="`ma-0 ${getPercentCategColor(
                        employee.penaltyReport.stats[2].maxScore -
                          employee.penaltyReport.stats[2].score
                      )}--text text-app-detail-large font-weight-black`"
                    >
                      <span
                        v-if="
                          employee.penaltyReport.stats[2].maxScore -
                            employee.penaltyReport.stats[2].score !==
                          parseInt(
                            employee.penaltyReport.stats[2].maxScore -
                              employee.penaltyReport.stats[2].score
                          )
                        "
                      >
                        {{
                          (
                            employee.penaltyReport.stats[2].maxScore -
                            employee.penaltyReport.stats[2].score
                          ).toFixed(2)
                        }}
                      </span>
                      <span v-else>
                        {{
                          employee.penaltyReport.stats[2].maxScore -
                          employee.penaltyReport.stats[2].score
                        }}
                      </span>
                    </span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col align="right">
                    <span class="ma-0 text-app-detail-normal"
                      >คะแนนทั้งหมด</span
                    >
                    <span class="ma-0 text-app-detail-large font-weight-bold">
                      {{ employee.penaltyReport.stats[2].maxScore }}
                    </span>
                  </v-col>
                </v-row>
                <v-progress-linear
                  class="mt-1"
                  height="20"
                  :value="
                    employee.penaltyReport.stats[2].maxScore -
                    employee.penaltyReport.stats[2].score
                  "
                  striped
                  rounded
                  :color="
                    getPercentCategColor(
                      employee.penaltyReport.stats[2].maxScore -
                        employee.penaltyReport.stats[2].score
                    )
                  "
                >
                </v-progress-linear>
                <v-row no-gutters>
                  <div>
                    <p class="ma-0 text-app-detail-normal">
                      ทำผิด
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        >{{ employee.penaltyReport.stats[2].counter }}</span
                      >
                      ครั้ง
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <p class="ma-0 text-app-detail-normal">
                      ถูกหัก
                      <span
                        class="black--text text-app-detail-large font-weight-bold"
                        ><span v-if="employee.penaltyReport.stats[2].score > 0"
                          >-</span
                        ><span
                          v-if="
                            employee.penaltyReport.stats[2].score !==
                            parseInt(employee.penaltyReport.stats[2].score)
                          "
                          >{{
                            employee.penaltyReport.stats[2].score.toFixed(2)
                          }}</span
                        ><span v-else>{{
                          employee.penaltyReport.stats[2].score
                        }}</span></span
                      >
                      คะแนน
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AppDialogConfirm ref="alertStatus" />
    <AppDialogConfirm ref="dialogAlert" />
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { getVehicleFundamental } from "@/services/api/vehicle";
import defaultImage from "@/assets/user.png";
import { mapGetters } from "vuex";
import { DialogType } from "@/services/dialog";
import { getEmployeeTimeOffStatService } from "@/services/api/employee";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import InputView from "@/components/InputView.vue";
import {
  getEmployeeSelectList,
  getPenaltyTitleSelectList,
  getEmployeePenaltyService,
  getPenaltyReportService,
} from "@/services/api/employee";

export default {
  name: "EmployeePenaltyOverviewDetailDialog",
  props: ["dialogStatus"],
  data: () => ({
    defaultPFImage: defaultImage,
    isLoading: false,
    show: false,
    resolve: null,
    reject: null,
    percentCateg: [50, 0, 80],
    totalScoreRatio: [0, 0, 0],
    id: null,
    totalScore: 100,
    categoryTotalScore: 100,
    employee: {
      penaltyReport: {
        floorScore: null,
        stats: null,
        sumCounter: null,
        sumScore: null,
        year: null,
      },
    },
  }),
  watch: {},
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      empPenaltyStatusColor: "employee/empPenaltyStatusColor",
      empPenaltyStatusText: "employee/empPenaltyStatusText",
    }),
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.employee = {
        penaltyReport: {
          floorScore: null,
          stats: null,
          sumCounter: null,
          sumScore: null,
          year: null,
        },
      };
      this.id = null;
    },

    setFormData(employee) {
      if (!_.isNil(employee)) {
        this.employee = _.cloneDeep(employee);
        this.id = this.employee["_id"];
        // this.titleList = this.employee["titleList"];
        // this.empList = this.employee["empList"];

        if (!_.isNil(this.employee["penaltyReport"]["stats"])) {
          for (let idx in this.employee["penaltyReport"]["stats"]) {
            let ratioScore =
              (this.employee["penaltyReport"]["stats"][idx]["weight"] *
                this.employee["penaltyReport"]["stats"][idx]["score"]) /
              100;
            this.totalScoreRatio[idx] = ratioScore;
          }
        }
      }
    },

    isWaitToUploadFiles(isWait) {
      this.isWaitToUploadAttachFiles = isWait;
      // console.log("isWaitFiles", this.isWaitToUploadAttachFiles);
    },

    isWaitToUploadImages(isWait) {
      this.isWaitToUploadAttachImages = isWait;
      // console.log("isWaitImages", this.isWaitToUploadAttachImages);
    },

    getPercentCategColor(percent) {
      if (percent < 50) {
        return "error";
      } else if (percent < this.categoryTotalScore) {
        return "warning";
      }
      return "success";
    },

    open(employee) {
      this.resetFormData();
      // this.employee = _.clone(employee);
      this.setFormData(employee);
      // this.getData();

      // this.getStat();

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelStatus() {
      // this.resetFormData();
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInputImprove,
    PrimaryButton,
    AppDialogConfirm,
    CustomTextareaImprove,
    FormDataUploadInput,
    CustomAutocomplete,
    InputView,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}

.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
</style>
