import {
	authHttpClientGet,
	authHttpClientPost,
	authHttpClientPut,
	authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getVehicleCheckListService(store, queryString) {
	let uri = "/vehicle-check-list/admin/doc/list";
	if (queryString !== null) {
		uri = `${uri}?${queryString}`;
	}

	return await authHttpClientGet(store, uri);
}

export async function putVehicleCheckListService(store, id, payload) {
	let uri = `/vehicle-check-list/admin/doc/info?id=${id}`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function getVehicleCheckListInfoService(store, id) {
	let uri = `/vehicle-check-list/admin/doc/info?id=${id}`;
	return await authHttpClientGet(store, uri);
}

export async function postVehicleFeedCardService(store, id, payload) {
	let uri = `/vehicle/feedcard?id=${id}`;
	return await authHttpClientPost(store, uri, payload, false);
}

export async function deleteVehicleFeedCardService(store, id) {
	let uri = `/vehicle/feedcard?id=${id}`;
	return await authHttpClientDelete(store, uri);
}

export async function putVehicleService(store, id, payload) {
	let uri = `/vehicle?id=${id}`;
	return await authHttpClientPut(store, uri, payload, false);
}

//
export async function putSetCheckListStatus(store, id, payload) {
	const uri = `/vehicle-check-list/admin/doc/check-list-status?id=${id}`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function getCheckListGroupService(store) {
	let uri = "/vehicle-check-list/admin/group/list";
	return await authHttpClientGet(store, uri);
}

export async function postCheckListGroupService(store, payload) {
	const uri = `/vehicle-check-list/admin/group`;
	return await authHttpClientPost(store, uri, payload, false);
}

export async function putCheckListGroupService(store, payload) {
	const uri = `/vehicle-check-list/admin/group`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function getCheckListItemService(store, queryString) {
	let uri = "/vehicle-check-list/admin/item/list";
	if (queryString !== null) {
		uri = `${uri}?${queryString}`;
	}

	return await authHttpClientGet(store, uri);
}

export async function putOrderListTypesService(store, payload) {
	const uri = `/vehicle-check-list/admin/group/order`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function putOrderListItemsService(store, payload) {
	const uri = `/vehicle-check-list/admin/item/order`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function getCheckListInfoService(store, id) {
	let uri = `/vehicle-check-list/admin/item/info?id=${id}`;
	return await authHttpClientGet(store, uri);
}

export async function putChecklistInfoService(store, payload) {
	const uri = `/vehicle-check-list/admin/item`;
	return await authHttpClientPut(store, uri, payload, false);
}

export async function postChecklistInfoService(store, payload) {
	const uri = `/vehicle-check-list/admin/item`;
	return await authHttpClientPost(store, uri, payload, false);
}
