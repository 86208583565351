<template>
  <div name="employeeGroupManage">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

    <v-container>
      <div class="mb-5" v-if="isPermissionCanView">
        <CustomCardFormViewModern
          :title="title"
          :isShowTitle="true"
          :isTitleHighlight="true"
        >
          <v-form ref="form" v-model="valid">
            <v-row no-gutters class="px-6">
              <v-col cols="12">
                <v-card elevation="0" class="px-0">
                  <v-row no-gutters>
                    <v-col cols="12" lg="4" sm="4">
                      <CustomTextInputImprove
                        ref="groupTitle"
                        v-model="formData.name"
                        placeholder="ชื่อกลุ่ม"
                        title="ชื่อกลุ่ม"
                        :is-required="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อกลุ่ม']"
                      ></CustomTextInputImprove>
                    </v-col>
                    <v-col cols="12" lg="4" sm="4">
                      <InputView
                        class="px-3"
                        title="โซนที่อยู่"
                        :isRequired="false"
                      >
                        <v-select
                          :items="zoneDataList"
                          v-model="formData.zone"
                          return-object
                          item-value="key"
                          item-text="nameTh"
                          placeholder="เลือก"
                          outlined
                          dense
                          filled
                          class="app-custom-input"
                          :loading="isLoading"
                        ></v-select>
                      </InputView>
                    </v-col>

                    <v-col cols="12" lg="4" sm="4" class="py-0 px-2">
                      <p class="title-input font-weight-bold mb-2 mt-0">
                        จำนวนคน
                      </p>
                      <p class="text-app-title mb-1 mt-1">
                        {{ formData.driverId ? formData.driverId.length : "-" }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" lg="12" sm="12">
                      <InputView
                        class="px-3"
                        title="สัญญาที่วิ่งบ่อย"
                        :is-required="false"
                        :bottom-margin="true"
                      >
                        <EmployeeGroupContractSelectorInput
                          ref="EmployeeGroupContractSelectorInputRef"
                          :disabled="!isPermissionCanEdit"
                          :value="formData.contractId"
                          @searchItemChange="getSearchContractItem"
                        />
                      </InputView>
                    </v-col>
                  </v-row> </v-card></v-col
            ></v-row>
          </v-form>
          <AppSubmitFormButton
            :isDivider="false"
            @on-click-save="saveChange()"
          />
          <v-divider class="mx-3 mt-9 mb-6"></v-divider>
          <v-row no-gutters class="px-6">
            <v-spacer></v-spacer>
            <!-- <v-btn icon @click="handleSchedule()" class="mx-3">
              <v-icon>mdi-bird</v-icon>
            </v-btn> -->
            <v-btn
              @click="handleSchedule()"
              class="mx-3"
              color="primary"
              elevation="0"
              light
              large
              outlined
              ><v-icon>mdi-bird</v-icon></v-btn
            >

            <!-- <NormalButton
              :isNoText="true"
              icon="mdi-bird"
              class="mx-3"
              @on-click="handleSchedule()"
            >
            </NormalButton> -->
          </v-row>
          <v-row no-gutters class="px-6">
            <v-col cols="12" lg="10" sm="10">
              <CustomMultiLineAutocomplete
                v-model="newEmployee"
                title="ชื่อ-นามสกุล"
                :isRequired="false"
                :items="empList"
                menu-props="auto"
                selectionTextIf="value"
                itemTextListTextIf="value"
                itemText="value"
                itemValue="key"
                :placeholder="`ค้นหาพนักงาน`"
                :small="false"
                :input-view-bottom-margin="true"
                :returnObject="true"
              >
              </CustomMultiLineAutocomplete>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="12" lg="2" sm="2" class="align-self-center mt-1 px-3">
              <PrimaryButton
                small
                :height="39"
                :isFullWidth="true"
                icon="mdi-plus"
                @on-click="handleAddClick()"
              >
                เพิ่มพนักงาน
              </PrimaryButton>
            </v-col>
          </v-row>
          <div class="px-9 pb-3">
            <v-data-table
              :headers="tableHeaders"
              :items="tableData"
              :loading="isLoading"
              :no-data-text="dataTableNoDataText"
              :loading-text="dataTableLoadingText"
              :hide-default-footer="true"
              class="app-datatable"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item two-line class="px-0">
                  <v-list-item-avatar>
                    <v-img
                      :src="
                        item.profileImage === null
                          ? defaultPFImage
                          : item.profileImage
                      "
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div v-if="item.dpNameTh || item.email">
                      <v-list-item-title
                        class="text-app-title font-weight-bold"
                      >
                        <router-link
                          v-if="
                            permList.jobs_multiple_crud || permList.admin_all
                          "
                          class="text-decoration-none black--text"
                          :to="{
                            name: 'employeeEdit',
                            params: { id: item._id },
                          }"
                        >
                          {{ item.dpNameTh }}
                        </router-link>
                        <span v-else> {{ item.dpNameTh }}</span>
                      </v-list-item-title>
                      <span class="text-app-normal">{{
                        item.email ? item.email : "-"
                      }}</span>
                    </div>
                    <div v-else>-</div>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:[`item.phoneNumber`]="{ item }">
                <div v-if="item.phoneNumber && item.phoneNumber.length !== 0">
                  <div
                    class="d-block"
                    v-for="(tel, idx) in item.phoneNumber"
                    :key="'phone_row' + idx"
                  >
                    <span class="text-app-title"> {{ tel }} </span>
                  </div>
                </div>
                <div v-else>-</div>
              </template>

              <template v-slot:[`item.empId`]="{ item }">
                <span class="text-app-title">
                  {{ item.empId ? item.empId : "-" }}
                </span>
              </template>

              <template v-slot:[`item.position`]="{ item }">
                <span v-if="item.position" class="text-app-title">
                  {{ item.position }}
                </span>
                <span v-else>-</span>
              </template>

              <template v-slot:[`item.empStatus`]="{ item }">
                <div
                  v-if="item.empStatus"
                  :class="
                    item.empStatus == `ACTIVE`
                      ? `${empStatusTextColor(item.empStatus)} text--darken-2`
                      : `${empStatusTextColor(item.empStatus)}`
                  "
                  dark
                >
                  <span class="text-app-title font-weight-bold">
                    {{ empStatusText(item.empStatus) }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>

              <template v-slot:[`item.age`]="{ item }">
                <span v-if="item.age" class="text-app-title">
                  {{ item.age === 0 || item.age === null ? "-" : item.age }}
                </span>
                <span v-else>-</span>
              </template>

              <template v-slot:[`item.vehicleStatus`]="{ item }">
                <v-list-item two-line class="px-0" v-if="item.ownedVehicle">
                  <v-list-item-content
                    v-if="item.ownedVehicle"
                    class="text-center"
                  >
                    <v-list-item-title class="text-app-title font-weight-bold">
                      <a
                        class="black--text"
                        @click="handleApprovingRequest(item)"
                      >
                        {{ item.ownedVehicle.licenseNumber }}
                      </a>
                    </v-list-item-title>
                    <span class="mx-0 px-0 text-app-normal">
                      <a
                        class="normal-text"
                        @click="handleApprovingRequest(item)"
                      >
                        <span v-if="item.ownedVehicle.type !== null"
                          >{{ vehicleTypeText(item.ownedVehicle.type) }}<br />
                          <v-icon>{{
                            vehicleTypeIcon(item.ownedVehicle.type)
                          }}</v-icon></span
                        >
                        {{ item.ownedVehicle.seat }} ที่นั่ง
                      </a>
                    </span>
                  </v-list-item-content>
                </v-list-item>
                <span v-else>-</span>
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                v-if="isPermissionCanEdit"
              >
                <v-btn
                  plain
                  class="text-decoration-underline error--text diplay--inline-block"
                  @click="handleDeleteItem(item._id)"
                >
                  ลบ
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </CustomCardFormViewModern>
        <EmployeeJobDialog ref="employeeJobDialogRef" />
        <AppDialogConfirm ref="confirmDelete" />
        <AppDialogConfirm ref="alertSaving" />
        <AppDialogConfirm ref="dialogAlert" />
      </div>
      <AppOverlay :isLoading="isLoading" />
      <AppPageNotFound :show="isPermissionCanView" />
    </v-container>
  </div>
</template>
<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { getAppDataService } from "@/services/api/app";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import _ from "lodash";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import { URL_EMPLOYEE_GROUP_LIST } from "@/services/routerQueryPath";
import PrimaryButton from "@/components/PrimaryButton.vue";
import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";
import NormalButton from "@/components/NormalButton.vue";

import EmployeeGroupContractSelectorInput from "./components/EmployeeGroupContractSelectorInput.vue";
import CustomMultiLineAutocomplete from "@/components/CustomMultiLineAutocomplete.vue";
import { getEmployeeSelectDetailList } from "@/services/api/employee";
import {
  getEmployeeGroupInfoService,
  putEmployeeGroupService,
  putSingleEmployeeGroupService,
  deleteSingleEmployeeGroupService,
} from "@/services/api/employeeGroup";
import { mapGetters } from "vuex";

import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import defaultImage from "@/assets/user.png";

export default {
  name: "employeeGroupManage",
  components: {
    CustomCardFormViewModern,
    AppOverlay,
    AppDialogConfirm,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomTextInputImprove,
    AppSubmitFormButton,

    EmployeeGroupContractSelectorInput,
    PrimaryButton,
    NormalButton,
    CustomMultiLineAutocomplete,
    EmployeeJobDialog,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "รายการกลุ่มเดินรถ",
          disabled: false,
          to: `/caj${URL_EMPLOYEE_GROUP_LIST}`,
          exact: true,
        },
        {
          text: "จัดการข้อมูลกลุ่มเดินรถ",
          disabled: true,
          to: "",
        },
      ],
      title: "แก้ไขข้อมูลกลุ่มเดินรถ",

      valid: false,
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      isLoading: false,
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      // vehicleList: null,
      _id: null,

      zoneDataList: [],
      defaultPFImage: defaultImage,

      formData: {
        name: null,
        zone: null,
        contractId: null,
        driverId: null,
      },

      empList: null,
      newEmployee: null,
      tableData: [],

      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {},
    };
  },
  watch: {
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },
  },

  computed: {
    ...mapGetters({
      empStatusTextColor: "employee/empStatusTextColor",
      empStatusText: "employee/empStatusText",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อ-นามสกุล ",
          align: "start",
          sortable: false,
          value: "name",
          width: "20%",
        },
        { text: "รหัสพนักงาน", value: "empId", sortable: false, align: "left" },
        {
          text: "เบอร์ติดต่อ",
          value: "phoneNumber",
          sortable: false,
          align: "left",
        },
        { text: "ตำแหน่ง", value: "position", sortable: false, align: "left" },
        { text: "สถานะ", value: "empStatus", sortable: false, align: "left" },
        { text: "อายุ", value: "age", sortable: false, align: "center" },
        {
          text: "รถประจำตัว",
          value: "vehicleStatus",
          align: "center",
          sortable: false,
        },
      ];
      //
      if (this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        });
      }
      //
      return defaultdata;
    },
  },
  methods: {
    startPage() {
      if (this.$route.params.id !== undefined) {
        this._id = this.$route.params.id;
      } else {
        this._id = null;
      }
      this.getData();
    },

    async getData() {
      this.isLoading = true;
      if (this.isPermissionCanView) {
        if (this._id !== null) {
          this.zoneDataList = await getAppDataService(
            this.$store,
            "zone&filter=1"
          );

          let respEmpList = await getEmployeeSelectDetailList(this.$store);
          if (respEmpList["code"] === 200) {
            this.empList = respEmpList["data"];
          } else {
            // this.isFail = true;
          }

          // edit

          let params = new URLSearchParams({
            id: this._id,
          }).toString();
          let respData = await getEmployeeGroupInfoService(this.$store, params);
          //
          if (respData["code"] === 200) {
            this.formData = _.clone(respData["data"]);
            if (!_.isNil(this.formData["driverId"])) {
              this.tableData = _.clone(this.formData["driverId"]);
            }
          } else {
          }
        }
      }

      this.isLoading = false;
    },

    // contract list
    getSearchContractItem(searchItem) {
      this.formData["contractId"] = searchItem;
    },

    async handleAddClick() {
      if (!_.isNil(this.newEmployee)) {
        if (
          !this.tableData.some((el) => el["_id"] === this.newEmployee["key"])
        ) {
          // let emp = _.clone(this.newEmployee);
          // emp["_id"] = this.newEmployee["key"];
          // emp[
          //   "dpNameTh"
          // ] = `${this.newEmployee["firstName"]["th"]} ${this.newEmployee["lastName"]["th"]}`;

          // this.tableData.push(emp);
          // this.formData["driverId"].push(emp);

          //prepare saving data

          let sendingFormData = null;
          sendingFormData = {
            driverId: this.newEmployee["key"],
          };

          let resp = await putSingleEmployeeGroupService(
            this.$store,
            this._id,
            sendingFormData
          );
          if (resp["code"] === 200) {
            this.newEmployee = null;
            this.getData();
          } else {
            throw "ERROR_TO_ADD_DATA";
          }
        }
      }
    },

    async handleDeleteItem(driverId) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        // let delIndex = this.tableData.findIndex((x) => x["_id"] == driverId);
        // this.tableData.splice(delIndex, 1);
        // this.formData["driverId"].splice(delIndex, 1);

        try {
          // const queryString = new URLSearchParams({
          //   id: this._id,
          //   driverId: driverId,
          // }).toString();

          const responseData = await deleteSingleEmployeeGroupService(
            this.$store,
            this._id,
            driverId
          );

          if (responseData["code"] === 200) {
            this.getData();
          } else {
            throw "ERROR_TO_DELETE_DATA";
          }
        } catch (error) {
          console.error(error);
        }
      }
    },

    async saveChange() {
      if (this.$refs.form.validate() === true) {
        this.isLoading = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        //prepare saving data
        console.log(this.formData["contractId"]);

        let sendingFormData = null;
        sendingFormData = {
          name: this.formData["name"],
          zone: this.formData["zone"],
          contractId: this.formData["contractId"].map((a) => a.key),
          driverId: this.formData["driverId"].map((a) => a._id),
        };

        let resp = await putEmployeeGroupService(
          this.$store,
          this._id,
          sendingFormData
        );
        if (resp["code"] === 200) {
          this.alertSaveChange(this._id);
          this.getData();
        } else {
        }

        this.isLoading = false;
      }
    },

    async handleSchedule() {
      if (!_.isNil(this.tableData)) {
        let initedEmpList = [];
        this.tableData.forEach((employee) => {
          let fullName = `${
            employee["nickName"] ? employee["nickName"]["th"] : ""
          } ${employee["firstName"] ? employee["firstName"]["th"] : ""} ${
            employee["lastName"] ? employee["lastName"]["th"] : ""
          } ${employee["empId"] ? "(" + employee["empId"] + ")" : ""}`;
          initedEmpList.push({
            key: employee["_id"],
            value: fullName,
          });
        });

        let empJob = null;
        if (initedEmpList.length > 0) {
          empJob = await this.$refs.employeeJobDialogRef.open(initedEmpList);
        } else {
          empJob = await this.$refs.employeeJobDialogRef.open();
        }
      }
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");
        if (
          this.permList.admin_all === true ||
          this.permList.jobs_multiple_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    resetFormData() {
      this.formData = {
        name: null,
        zone: null,
        contractId: null,
        driverId: [],
      };
      this.clearInput();
    },
    clearInput() {},
  },
  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.startPage();
    }
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}
.image_list_float_left {
  float: right;
}

.normal-text {
  color: #88949e;
}
</style>
