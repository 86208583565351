<template>
  <v-dialog v-model="show" width="50%" persistent>
    <v-card v-if="show">
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title> {{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="cancelAndCloseHandler"
          v-if="fetchDataError <= 0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 0">
        <v-form ref="AssignMultipleJobCreate" v-model="valid">
          <CustomTextInputImprove
            title="ชื่อแผนงาน"
            ref="name"
            v-model="formData.name"
            :isRequired="true"
            :rules="[(v) => !!v || 'จำเป็นต้องกรอกชื่อแผนงาน']"
          />
          <CustomTextareaImprove
            title="คำอธิบายแผนงาน"
            ref="desc"
            v-model="formData.desc"
          />
          <CustomMultiLineAutocomplete
            v-model="formData.responsibleUserId"
            title="ผู้รับผิดชอบ"
            :is-required="false"
            :items="empList"
            menu-props="auto"
            selectionTextIf="value"
            itemTextListTextIf="value"
            itemText="value"
            itemValue="key"
            :placeholder="`ค้นหาพนักงาน`"
            :small="true"
            :input-view-bottom-margin="false"
            :return-object="false"
          >
          </CustomMultiLineAutocomplete>

          <InputView
            title="สัญญา"
            :is-required="false"
            :bottom-margin="false"
            class="px-3"
          >
            <JobContractSelectorInput
              ref="JobContractSelectorInputRef"
              @searchItemChange="getSearchContractItem"
              :value="formData.contractId"
              :clearable="true"
              :required="false"
            />
          </InputView>
        </v-form>
      </v-card-text>
      <!--  -->
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 0"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 0"
          color="primary"
          text
          @click="saveAndCloseHandler"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import {
  getMultipleJobSheetInfoService,
  postMultipleJobSheetService,
  putMultipleJobSheetService,
} from "@/services/api/assign_multiple_job";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import InputView from "@/components/InputView.vue";
import CustomMultiLineAutocomplete from "@/components/CustomMultiLineAutocomplete.vue";
import { getEmployeeSelectDetailList } from "@/services/api/employee";

export default {
  name: "AssignMultipleJobCreateDialog",
  data: () => ({
    valid: null,
    resolve: null,
    reject: null,
    show: false,
    fetchDataError: 0,
    formData: {
      name: "",
      desc: "",
      contractId: null,
      responsibleUserId: null,
    },
    sheetId: null,
    dialogTitle: "เพิ่มแผนงาน",
    empList: [],
  }),
  methods: {
    open(sheetId) {
      this.sheetId = sheetId;
      this.resetForm();
      this.show = true;
      if (!_.isNil(sheetId)) {
        this.dialogTitle = "แก้ไขแผนงาน";
        this.getData();
      } else {
        this.getEmployeeData();
        this.fetchDataError = 0;
      }
      //

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async saveAndCloseHandler() {
      if (this.$refs["AssignMultipleJobCreate"].validate() === true) {
        this.fetchDataError = 2;
        let responseData = null;
        const payload = {
          ...this.formData,
          contractId: _.get(this.formData, "contractId.key", null),
        };

        if (!_.isNil(this.sheetId)) {
          responseData = await putMultipleJobSheetService(
            this.$store,
            `id=${this.sheetId}`,
            payload
          );
        } else {
          responseData = await postMultipleJobSheetService(
            this.$store,
            null,
            payload
          );
        }

        if (responseData["code"] === 200) {
          this.show = false;
          this.resolve(responseData["data"]);
        } else {
          this.fetchDataError = -1;
        }
      } else {
        // this.fetchDataError = -1;
        console.error("FORM_INVALIDATE");
      }
    },
    async getData() {
      try {
        const responseData = await getMultipleJobSheetInfoService(
          this.$store,
          new URLSearchParams({
            id: this.sheetId,
          }).toString()
        );
        //
        if (responseData["code"] === 200) {
          this.formData = {
            name: _.get(responseData, "data.name", null),
            desc: _.get(responseData, "data.desc", null),
            contractId: _.get(responseData, "data.contract", null),
            responsibleUserId: _.get(
              responseData,
              "data.responsibleUserId",
              null
            ),
          };
          this.getEmployeeData();
          this.fetchDataError = 0;
        } else {
          throw "RESPONSE_DATA_FAIL";
        }
        //
      } catch (error) {
        console.error(error);
        this.fetchDataError = -2;
      }
    },
    async getEmployeeData() {
      try {
        const rep = await getEmployeeSelectDetailList(this.$store);
        if (rep["code"] === 200) {
          this.empList = rep["data"];

          this.fetchDataError = 0;
        } else {
          throw "RESPONSE_DATA_FAIL";
        }
      } catch (error) {
        console.error(error);
        this.fetchDataError = -2;
      }
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
    resetForm() {
      this.fetchDataError = 1;
      this.formData = {
        name: null,
        desc: null,
        contractId: null,
      };
    },
    getSearchContractItem(searchItem) {
      this.formData["contractId"] = searchItem;
    },
  },
  components: {
    CustomTextareaImprove,
    CustomTextInputImprove,
    JobContractSelectorInput,
    InputView,
    CustomMultiLineAutocomplete,
  },
};
</script>
