<template>
  <div name="employeeNewView">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />
    <v-container>
      <h2 class="mx-6" v-if="isPermissionCanView">เพิ่มข้อมูลพนักงานใหม่</h2>
      <div class="mt-5" v-if="isPermissionCanView">
        <EmployeePersonalInformationFormView
          @on-click-save="savePersonalInformation"
          :canEdit="isPermissionCanEdit"
        ></EmployeePersonalInformationFormView>
      </div>
      <div v-else>
        <AppPageNotFound :show="false" />
      </div>
      <AppOverlay :isLoading="isLoading" />
      <AppDialogConfirm ref="dialogAlert" />
    </v-container>
  </div>
</template>
<script>
import EmployeePersonalInformationFormView from "@/views/employee/employee_detail/components/EmployeePersonalInformationFormView.vue";
import { getUserAppDataService } from "@/services/api/app";
import { postUserRegisterInfoEmployee } from "@/services/api/employee";
import { processPermission } from "@/services/permissions";
import AppOverlay from "@/components/AppOverlay.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { errorMessageMapper } from "@/services/error";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import _ from "lodash";
import { URL_EMPLOYEE_LIST } from "@/services/routerQueryPath";

export default {
  name: "employeeNewView",
  props: {},
  components: {
    EmployeePersonalInformationFormView,
    AppOverlay,
    AppDialogConfirm,
    AppBreadCrumbs,
    AppPageNotFound,
  },

  data() {
    return {
      currentStep: 0,
      isAddingData: true,
      formData: null,
      insertAddressData: null,
      appCompanyData: null,
      permList: {},
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      isLoading: true,
      breadcrumbsItems: [
        {
          text: "รายการพนักงาน",
          disabled: false,
          to: `/employee${URL_EMPLOYEE_LIST}`,
        },
        {
          text: "ข้อมูลพนักงาน",
          disabled: true,
          to: "",
        },
      ],
    };
  },

  mounted() {
    this.checkUserPermissions();
    this.getUserAppData();
  },

  methods: {
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");

        if (
          this.permList.admin_all === true ||
          this.permList.user_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    async getUserAppData() {
      //
      let resp = null;
      resp = await getUserAppDataService(this.$store);
      if (resp["code"] === 200) {
        this.appCompanyData = {
          ...resp.data,
        };
        this.formData = { _appCompanyData: this.appCompanyData };
      } else {
        console.log("user app data: Loading fail");
        return;
      }
      this.currentStep = 1;
      this.isLoading = false;
    },
    getStepComplete(step) {
      return this.currentStep >= step;
    },

    async showSuccessDialog() {
      await this.$refs.dialogAlert.open(
        `บันทึกเรียบร้อย`,
        null,
        DialogType.POSITIVE,
        {
          noconfirm: false,
        }
      );

      this.$router.push(`/employee${URL_EMPLOYEE_LIST}`);
    },

    async showErrorDialog(responseData) {
      let text_error = "ไม่สามารถบันทึกข้อมูลได้ ลองใหม่อีกครั้ง";
      if (responseData.code === 400) {
        text_error = `${text_error} (${
          errorMessageMapper[responseData.data.message]
        })`;
      }
      await this.$refs.dialogAlert.open(null, text_error, DialogType.ERROR, {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      });
    },

    async savePersonalInformation(data, addressDataList) {
      this.isLoading = true;
      if (this.isAddingData) {
        // this.insertAddressData = addressDataList;
        this.$store.dispatch("employee/updateIDCardData", data);
        this.$store.dispatch("employee/updateAddressData", addressDataList);
      }

      let responseData = await postUserRegisterInfoEmployee(
        this.$store,
        "info",
        null,
        data,
        false
      );

      if (responseData.code == 200) {
        this._id = responseData.data._id;
        this.$router.push("/employee/edit/" + this._id);
      } else {
        this.showErrorDialog(responseData);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.theme--light.v-stepper {
  background: #ffffff00 !important;
}

.v-stepper__step__step .v-icon.v-icon {
  font-size: 14px !important;
}

.container {
  max-width: 1500px;
}
</style>
