<template>
  <v-app :style="cssProps">
    <!-- <AppBar /> -->

    <v-dialog v-model="showErrorDialog" max-width="290">
      <v-card>
        <v-layout justify-center>
          <v-card-title class="subtitle-1 pa-1 red--text">
            แจ้งเตือน
          </v-card-title>
        </v-layout>

        <v-layout justify-center>
          <v-card-text>
            <div class="text-card-subtitle text-center">
              {{
                errorMessage !== ""
                  ? errorMessageMap[errorMessage]
                    ? errorMessageMap[errorMessage]
                    : errorMessage
                  : ""
              }}
            </div>
          </v-card-text>
        </v-layout>
        <v-layout justify-center>
          <v-btn tile block @click="$store.commit('setShowErrorDialog', false)">
            ปิด
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
    <AppDrawer v-if="$store.getters.userInfo" />

    <v-main class="main-bg-color" v-if="!loadingStore" :key="reloadView">
      <router-view />
      <v-btn
        v-if="['login', 'logout'].includes(currentRouteName) !== true"
        color="white"
        absolute
        min-width="20px"
        min-height="50px"
        class="toggle-btn mt-12 px-0 subtitle--text"
        @click="toggleDrawerVuex"
        style="z-index: 11"
      >
        <v-icon small v-if="$store.getters.userInfo">
          {{
            $store.state.appDrawer ? "mdi-chevron-left" : "mdi-chevron-right"
          }}
        </v-icon>
      </v-btn>
    </v-main>
    <AppOverlay :isLoading="loadingStore" />
    <JobCheckpointHeatMap />
  </v-app>
</template>

<script>
import { loadLocalStorageData } from "@/services/api/token_manager";
import AppBar from "./components/AppBar.vue";
import AppDrawer from "./components/AppDrawer.vue";
import { errorMessageMapper } from "./services/error";
import AppOverlay from "./components/AppOverlay.vue";
import { mapActions } from "vuex";
import JobCheckpointHeatMap from "./views/global_view/JobCheckpointHeatMap.vue";

export default {
  name: "App",
  data: () => ({
    appTitle: "Chokchaiaree system",
    showErrorDialog: false,
    errorMessage: "",
    errorMessageMap: errorMessageMapper,
    loadingStore: true,
  }),
  async mounted() {
    document.title = this.appTitle;
    await this.initApp();
  },
  methods: {
    ...mapActions(["toggleDrawerVuex"]),
    async initApp() {
      await loadLocalStorageData(this.$store);
      this.$store.commit("setInitStoreCompleted", true);
      this.loadingStore = false;
    },
  },
  watch: {
    "$store.state.forceToLogin": function () {
      console.log("forceToLogin Changed");
      console.log(this.$route.fullPath);
      console.log(this.$route.path);
      if (
        this.$store.state.forceToLogin == true &&
        !this.$route.path.startsWith("/login") &&
        !this.$route.path.startsWith("/forgot-password") &&
        !this.$route.path.startsWith("/change-password/")
      ) {
        console.log("redirect to login");
        this.$store.state.forceToLogin = false;
        this.$router.push("/login");
      }
    },
    "$store.state.showErrorDialog": function () {
      this.showErrorDialog = this.$store.state.showErrorDialog;
      this.errorMessage = this.$store.state.errorMessage;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },

    reloadView() {
      return this.$store.state.reloadViewKey;
    },
    calculateView() {
      return {
        "pl-16": this.$vuetify.breakpoint.lgAndUp,
        "pl-0": this.$vuetify.breakpoint.mdAndDown,
      };
    },

    cssProps() {
      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
  },
  components: { AppBar, AppDrawer, AppOverlay, JobCheckpointHeatMap },
};
</script>

<style lang="scss">
@import "./variables.scss";
</style>
