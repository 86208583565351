<template>
  <div name="customCardFormView">
    <v-card flat rounded="lg">
      <HeaderCardNormal :title="title"></HeaderCardNormal>
      <div class="pt-5"></div>
      <slot></slot>
    </v-card>
  </div>
</template>
<script>
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";

export default {
  name: "customCardFormView",

  components: {
    HeaderCardNormal,
  },

  props: {
    title: String,
  },
};
</script>
<style lang="">
</style>