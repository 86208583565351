<template>
  <div name="sortButton">
    <v-btn
      @click="onClick()"
      elevation="0"
      height="35"
      light
      large
      :outlined="!active"
      
      :style="{
        backgroundColor: bgColorUnSelect + ' !important',
        color: bgColorSelected,
      }"
    >
      <div :class="active ? 'sort-button-selected-text' : 'sort-button-text'">{{ text }}</div>
      <div v-if="active">
        <v-icon right :color="textColorSelected">
          {{ getIcon() }}
        </v-icon>
      </div>
    </v-btn>
  </div>
</template>
<script>
const SortData = Object.freeze({
  Ascending: 2,
  Descending: 1,
  none: 0,
});

export default {
  name: "sortButton",
  props: {
    text: String,
    active: Boolean,
    sortKey: String,
  },

  data() {
    return {
      isAscending: false,

      bgColorSelected: this.$vuetify.theme.themes.light.primary,
      bgColorUnSelect: this.$vuetify.theme.themes.light.backgroundNormalButton,
      textColorSelected: this.$vuetify.theme.themes.light.primary,
    };
  },

  watch: {},

  methods: {
    getIcon() {
      // console.log("update value sort = " + this.value);
      if (this.isAscending == null) {
        return null;
      } else {
        return this.isAscending ? "mdi-arrow-up" : "mdi-arrow-down";
      }
    },
    onClick() {
      this.isAscending = this.isAscending == null ? true : !this.isAscending;

      this.$emit("on-change", {
        sortKey: this.sortKey,
        isAscending: this.isAscending ? "asc" : "desc",
      });
    },
  },
};
</script>
<style>
.sort-button-text {
  color: #4d5656;
  font-weight: normal;
  font-size: 12px;
}

.sort-button-selected-text {
  font-weight: bold;
  font-size: 12px;
}
</style>
