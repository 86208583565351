<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable persistent>
      <v-card dark color="#222831" v-if="show">
        <v-toolbar color="#323841" dark>
          <v-card-title> Preview </v-card-title> <v-spacer></v-spacer
          ><v-btn icon @click="cancel()"
            ><v-icon>mdi-window-close</v-icon></v-btn
          ></v-toolbar
        >

        <v-divider></v-divider>
        <v-card-text class="px-0 py-0">
          <v-row no-gutters class="pa-0">
            <v-col cols="12" class="pa-0">
              <table
                class="excel-preview-table"
                dense
                style="background-color: #222831"
              >
                <tbody>
                  <tr>
                    <td
                      class="table_body_text_style px-3 py-3 text-center"
                      style="width: 50px"
                    >
                      <span>1</span>
                    </td>
                    <td
                      v-for="(header, headIdx) in previewHeaders"
                      :key="headIdx"
                      class="text-left table_header_text_style px-3 py-3"
                      :style="
                        headIdx > 3
                          ? `width: 150px`
                          : `width: ${colWidth[headIdx]}`
                      "
                    >
                      <span v-if="!header.includes('status')">
                        {{ header }}
                      </span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in previewItems" :key="idx">
                    <td
                      :class="`table_body_text_style px-3 py-3 text-center ${
                        idx === errorRow
                          ? 'font-weight-bold black--text clickable-row'
                          : ''
                      }`"
                      :style="
                        idx === errorRow ? `background-color: #ed5555` : ``
                      "
                      @click="handleErrorRow(idx, row[0])"
                    >
                      <span>{{ idx + 2 }}</span>
                    </td>
                    <td
                      v-for="(header, headIdx) in previewHeaders"
                      :key="headIdx"
                      :class="`table_body_text_style px-3 py-3 ${
                        idx === errorRow
                          ? 'font-weight-bold black--text clickable-row'
                          : ''
                      }`"
                      @click="handleErrorRow(idx, row[0])"
                      :style="
                        idx === errorRow ? `background-color: #ed5555` : ``
                      "
                    >
                      <span v-if="!header.includes('status')">
                        <span
                          v-if="headIdx >= 5 && headIdx % 2 == 1 && idx !== 0"
                          >{{ row[headIdx] ? `${row[headIdx]}` : "-" }}</span
                        >
                        <span v-else>{{
                          row[headIdx] ? row[headIdx] : "-"
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            class="px-9 mx-3"
            outlined
            @click="handleFinanceFileImport"
            :loading="isFinanceSelecting"
            dark
            elevation="0"
          >
            นำเข้าอีกครั้ง
          </v-btn>
          <input
            ref="financeReUploader"
            class="d-none"
            type="file"
            @change="onFinanceFileChanged"
          />

          <v-btn
            color="primary"
            class="px-9"
            @click="saveChange"
            :loading="isSubmiting"
            dark
            elevation="0"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
    <WorksheetErrorPreviewDialog ref="worksheetErrorPreviewDialog" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import { postFinanceCSVService } from "@/services/api/worksheet";
import WorksheetErrorPreviewDialog from "./WorksheetErrorPreviewDialog.vue";
import { read, utils, writeFile } from "xlsx";

export default {
  name: "worksheetExcelPreviewDialog",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    AppDialogConfirm,
    AppOverlay,
    WorksheetErrorPreviewDialog,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,

      isSubmiting: false,
      componentLoading: false,
      headers: [],
      items: [],

      previewHeaders: [],
      previewItems: [],
      excelSting: "",
      errorRow: -1,
      errorCol: -1,

      colWidth: ["100px", "150px", "100px", "150px"],
      isFinanceSelecting: false,
      selectedFile: null,
      errorMsgEachRow: [],
      empData: null,
      empStatData: null,
      headerName: ["ID_EMP", "N_EMP", "CODE", "ID_CON"],
      headerKey: {
        ID_EMP: "ID_EMP",
        N_EMP: "N_EMP",
        CODE: "CODE",
        ID_CON: "ID_CON",
      },

      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },

      isPermissionCanEdit: false,
      isPermissionCanView: false,
    };
  },

  watch: {},

  computed: {},

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.isSubmiting = false;
      this.componentLoading = false;
      this.headers = [];
      this.items = [];

      this.previewHeaders = [];
      this.previewItems = [];
      this.excelSting = "";
      this.errorRow = -1;
      this.errorCol = -1;
    },

    open(empData, empStatData) {
      // console.log("empData", empData);
      // console.log("empStatData", empStatData);
      // console.log("empStatKeys", Object.keys(empStatData[0]));
      this.resetFormData();
      if (!_.isNil(empStatData)) {
        this.headers = Object.keys(empStatData[0]);
        this.items = empStatData;
        this.convertToString();

        this.setPreviewItems(Object.keys(empStatData[0]), empStatData);
      }

      this.checkUserPermissions();
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    reuploadedData() {
      this.resetFormData();
      if (!_.isNil(this.empStatData)) {
        this.headers = Object.keys(this.empStatData[0]);
        this.items = this.empStatData;
        this.convertToString();

        this.setPreviewItems(
          Object.keys(this.empStatData[0]),
          this.empStatData
        );
      }
    },

    handleFinanceFileImport() {
      this.isFinanceSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isFinanceSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.financeReUploader.click();
    },

    async onFinanceFileChanged(e) {
      this.selectedFile = e.target.files[0];
      // console.log("selectedFile", this.selectedFile);
      await this.uploadFinanceExcel();

      if (!_.isNil(this.empStatData)) {
        this.reuploadedData();
      }

      this.$refs.financeReUploader.value = null;
    },

    async uploadFinanceExcel() {
      let fileInput = this.selectedFile;

      const readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
          //error
          temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new Error("INPUT_FILE"));
          };
          //set
          temporaryFileReader.onload = () => {
            let arrayBuffer = temporaryFileReader.result;
            let workbook = read(arrayBuffer, {
              type: "binary",
            });

            let firstSheet = workbook.SheetNames[0];
            let exceljsondata = utils.sheet_to_json(
              workbook.Sheets[firstSheet],
              {
                header: 1,
              }
            );
            resolve(exceljsondata);
          };

          temporaryFileReader.readAsBinaryString(inputFile);
        });
      };

      try {
        let excelArrayData = await readUploadedFileAsText(fileInput);
        // console.log("excelArrayData", excelArrayData);
        // let excelArrayData = await readUploadedFileAsText(null);
        if (excelArrayData.length < 1) {
          throw new Error("EMPTY_FILE");
        }

        // if (!this.vaildateHeader(excelArrayData)) {
        //   throw new Error("NOT_VAILDATE_HEADER");
        // }

        // prcoess to json object
        let empJsonData = new Array();
        let userJsonData = new Array();
        let errorAtKey = false;
        for (let i = 1; i < excelArrayData.length; i++) {
          let ObjEmpData = {};
          let errorEmpEachKey = [];
          for (let j = 0; j < this.headerName.length; j++) {
            if (excelArrayData[i][j] === undefined) {
              ObjEmpData[this.headerKey[this.headerName[j]]] = null;
              errorEmpEachKey.push(false);
              // errorAtKey = true;
            } else {
              ObjEmpData[this.headerKey[this.headerName[j]]] =
                excelArrayData[i][j];
              errorEmpEachKey.push(true);
            }
          }
          // this.errorMsgEachRow.push(errorEachKey);
          empJsonData.push(ObjEmpData);

          let ObjData = {};
          let errorEachKey = [];
          let maxCol = Math.max(
            excelArrayData[0].length,
            excelArrayData[1].length,
            excelArrayData[2].length
          );
          for (let j = 0; j < maxCol; j++) {
            if (excelArrayData[0][j] === undefined) {
              let emptyHeader = `${excelArrayData[0][j - 1]}_status`;
              if (excelArrayData[i][j] === undefined) {
                ObjData[emptyHeader] = null;
              } else {
                ObjData[emptyHeader] = excelArrayData[i][j];
              }

              errorEachKey.push(false);
            } else {
              if (excelArrayData[i][j] === undefined) {
                ObjData[excelArrayData[0][j]] = null;
              } else {
                ObjData[excelArrayData[0][j]] = excelArrayData[i][j];
              }

              errorEachKey.push(true);
            }
          }
          this.errorMsgEachRow.push(errorEachKey);
          userJsonData.push(ObjData);
        }

        if (errorAtKey) {
          //

          throw new Error("FIELDS_REQUIRED");
        }

        this.empData = empJsonData;
        this.empStatData = userJsonData;

        // console.log(empJsonData);
        // console.log(userJsonData);
        // console.log(JSON.stringify(userJsonData));
        // console.log(this.errorMsgEachRow);
      } catch (error) {
        console.error(error);
      }
    },

    setPreviewItems(headers, items) {
      // console.log(headers, items);
      this.previewHeaders = [];
      this.previewItems = [];

      for (let i = 0; i < headers.length; i++) {
        if (!headers[i].includes("status")) {
          this.previewHeaders.push(headers[i]);
        }
      }

      for (let i = 0; i < items.length; i++) {
        let empData = [];
        if (_.isNil(items[i][headers[0]])) {
          continue;
        }
        for (let j = 0; j < headers.length; j++) {
          if (!headers[j].includes("status")) {
            if (j >= 5 && j % 2 == 1 && i !== 0) {
              let amount = items[i][headers[j]] ? items[i][headers[j]] : "-";
              let status = items[i][headers[j + 1]]
                ? items[i][headers[j + 1]]
                : "-";
              let item = "-";
              if (amount !== "-" || status !== "-") {
                item = `${amount} (${status})`;
              }
              empData.push(item);
            } else {
              empData.push(items[i][headers[j]]);
            }
          }
        }
        this.previewItems.push(empData);
      }
    },

    getRow(id_emp, code, id_con) {
      const index = this.items.findIndex(
        (product) =>
          product.ID_EMP === id_emp &&
          product.CODE === code &&
          product.ID_CON === id_con
      );
      return index;
    },

    handleError(message) {
      let errorType = "";
      if (message.startsWith("FORMAT_NOT_SUPPORT")) {
        errorType = "FORMAT_NOT_SUPPORT";
        this.getErrorRowColumnRecord("FORMAT_NOT_SUPPORT_", message);
      } else if (message.startsWith("FOUND_PAYMENT_SHEET_MORE_THAN_ONE")) {
        errorType = "FOUND_PAYMENT_SHEET_MORE_THAN_ONE";
        this.getErrorRecord("FOUND_PAYMENT_SHEET_MORE_THAN_ONE_", message);
        this.alertErrorDialog(errorType);
      } else if (message.startsWith("PAYMENT_SHEET_NOT_FOUND")) {
        errorType = "PAYMENT_SHEET_NOT_FOUND";
        this.getErrorRecord("PAYMENT_SHEET_NOT_FOUND_", message);
        this.alertErrorDialog(errorType);
      } else {
        errorType = message;
        this.alertErrorDialog(errorType);
      }
    },

    async getErrorRowColumnRecord(typeMsg, message) {
      let record = message.split(typeMsg);
      // console.log("record", record);
      if (!_.isNil(record) && record.length === 2) {
        // this.errorRow = parseInt(record[1]) - 1;
        let emp = record[1].split("_");
        let ROW_IDX = null;
        let COL_IDX = null;
        if (!_.isNil(emp) && emp.length === 4) {
          ROW_IDX = parseInt(emp[1]) - 2;
          COL_IDX = parseInt(emp[3]) - 1;
        }
        if (!_.isNil(ROW_IDX) && !_.isNil(COL_IDX)) {
          this.errorRow = ROW_IDX;
          this.errorCol = COL_IDX;
          if (this.errorCol <= 3) {
            await this.$refs.dialogAlert.open(
              "กรุณาตรวจสอบข้อมูลอีกครั้ง",
              "FORMAT_NOT_SUPPORT",
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
          } else {
            await this.$refs.dialogAlert.open(
              `กรุณาตรวจสอบ "ข้อมูลการเงิน"`,
              null,
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
          }
        }
      }
    },

    getErrorRecord(typeMsg, message) {
      let record = message.split(typeMsg);
      if (!_.isNil(record) && record.length === 2) {
        let emp = record[1].split(",");
        let ID_EMP = null;
        let CODE = null;
        let ID_CON = null;
        if (!_.isNil(emp) && emp.length === 3) {
          ID_EMP = emp[0] !== "null" ? emp[0] : null;
          CODE = emp[1] !== "null" ? emp[1] : null;
          ID_CON = emp[2] !== "null" ? emp[2] : null;
        }
        // console.log(ID_EMP, CODE, ID_CON);
        this.errorRow = this.getRow(ID_EMP, CODE, ID_CON);
        // if (!_.isNil(ID_EMP) && !_.isNil(CODE) && !_.isNil(ID_CON)) {
        //   this.errorRow = this.getRow(ID_EMP, CODE, ID_CON);
        // }
      }
    },

    async alertErrorDialog(msg) {
      await this.$refs.dialogAlert.open(
        "กรุณาตรวจสอบข้อมูลอีกครั้ง",
        msg,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
    },

    convertToString() {
      if (!_.isNil(this.headers) && !_.isNil(this.items)) {
        //headers
        for (let i = 0; i < this.headers.length; i++) {
          if (!this.headers[i].includes("status")) {
            this.excelSting = this.excelSting + this.headers[i];
          }

          if (i !== this.headers.length - 1) {
            this.excelSting += ",";
          } else {
            this.excelSting += ",\n";
          }
        }

        //items
        for (let i = 0; i < this.items.length; i++) {
          for (let j = 0; j < this.headers.length; j++) {
            if (!_.isNil(this.items[i][this.headers[j]])) {
              if (j >= 5 && j % 2 === 1 && i > 1) {
                let amount = this.items[i][this.headers[j]];
                let floatAmount = parseFloat(
                  amount.toString().replaceAll(",", "")
                );
                this.excelSting = this.excelSting + floatAmount;
              } else {
                this.excelSting =
                  this.excelSting + this.items[i][this.headers[j]];
              }
            }

            if (j !== this.headers.length - 1) {
              this.excelSting += ",";
            } else {
              this.excelSting += ",\n";
            }
          }
        }
        // console.log(this.excelSting);
      }
    },

    async handleErrorRow(idx, empId) {
      if (idx === this.errorRow) {
        if (this.errorCol <= 3) {
          if (!_.isNil(empId)) {
            const respErrorWorksheet =
              await this.$refs.worksheetErrorPreviewDialog.open(empId);
          }
        } else {
          await this.$refs.dialogAlert.open(
            `กรุณาตรวจสอบ "ข้อมูลการเงิน"`,
            null,
            DialogType.ERROR,
            {
              noconfirm: false,
            }
          );
        }
      }
    },

    async saveChange() {
      this.isSubmiting = true;
      if (!_.isNil(this.excelSting)) {
        let queryString = { csv: this.excelSting };
        let respData = await postFinanceCSVService(this.$store, queryString);
        //
        if (respData["code"] === 200) {
          this.alertSaveChange();
          this.show = false;
          this.resolve(true);
        } else {
          // insert fail
          this.handleError(respData["data"]["message"]);
        }
      }
      this.isSubmiting = false;
    },

    cancel() {
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
      this.resetFormData();
    },

    async alertSaveChange() {
      await this.$refs.alertSaving.open(
        "เพิ่มข้อมูลสำเร็จ!",
        null,
        DialogType.POSITIVE,
        this.alertOptions
      );
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        // console.log("permission:", this.permList)
        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.excel-preview-table {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#171b21, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: transparentize(#171b21, 0.3);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.excel-preview-table tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
  // min-height: 300px;
}

.excel-preview-table th td {
  // background: #f7faff;
  border: 1px solid #222831;
  background-color: #171b21;
  border-collapse: collapse;
  border-top: 0;
}

// .excel-preview-table tr:nth-child(even) td {
//   background-color: #222831;
// }

.excel-preview-table tr:first-child {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(2) {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.excel-preview-table tr:nth-child(even) td:nth-child(n + 6) {
  background-color: transparentize(#171b21, 0.5);
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
}

.excel-preview-table td:nth-child(1) {
  background-color: #171b21;
  // border: 2px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 2px;
  z-index: 2;
}

.excel-preview-table td:nth-child(2) {
  background-color: #171b21;
  // border: 2px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 54px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(1) {
//   // background-color: #171b21;
//   z-index: 4;
// }

.excel-preview-table td:nth-child(3) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 155px;
  z-index: 2;
}
// .excel-preview-table th:nth-child(2) {
//   // background-color: #171b21;
//   z-index: 4;
// }
.excel-preview-table td:nth-child(4) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 307px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(3) {
//   // background-color: #171b21;
//   z-index: 4;
// }
.excel-preview-table td:nth-child(5) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 409px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(4) {
//   // background-color: #171b21;
//   z-index: 4;
// }

// .excel-preview-table tr td:first-child {
//   border-left: none;
// }

.clickable-row {
  cursor: pointer;
}

.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}
.table_header_text_style {
  font-weight: bold;
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
