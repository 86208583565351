<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="headline mt-1">ข้อมูลผู้ใช้งาน</h1>
        <v-btn class="mr-3" @click="logoutAction"> ออกจากระบบ </v-btn>
        <v-btn @click="changePaswordAction"> เปลี่ยนรหัสผ่าน </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserProfileView",
  data: () => ({}),
  watch: {},
  computed: {},
  methods: {
    async logoutAction() {
      this.$router.push("/logout");
    },
    async changePaswordAction() {
      this.$router.push("/change-password");
    },
  },
};
</script>

<style scoped></style>
