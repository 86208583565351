<template>
  <v-card
    rounded="xl"
    :color="active ? '#1045F6' : ''"
    :dark="active"
    @click="selectActiveHandler"
  >
    <div class="pa-4 d-flex">
      <div class="mr-5 d-inline-block" style="width: 65%">
        <p class="mb-0 caption text-truncate">
          <span v-if="item.contractData">
            ({{ item.contractData.contractNumber }})
          </span>
          <span v-else> - </span>
          {{ item.contractData ? item.contractData.title : "" }}
        </p>
        <p class="mb-0 subtitle-2 text-truncate">
          {{ item.name }}
        </p>
      </div>
      <div class="d-inline-block pt-2 d-flex justify-end" style="width: 35%">
        <v-btn
          :color="jobsStatusColor(item.status)"
          elevation="0"
          class="text-app-title"
          small
        >
          {{ jobsStatusText(item.status) }}
        </v-btn>
      </div>
    </div>
    <v-divider
      :class="[
        active ? 'custom-active-ss-divider' : 'custom-inactive-ss-divider',
      ]"
    ></v-divider>

    <div
      class="pt-4 px-4 d-flex justify-space-between items-center"
      style="width: 100%"
    >
      <div class="d-inline-block" style="width: 45%">
        <p class="mb-0 caption text-truncate">
          {{ firstMarkerItem.name }}
        </p>
        <p class="mb-0 subtitle-2">{{ startJobDate }}</p>
      </div>
      <div class="text-center" style="width: 10%">
        <v-icon> mdi-arrow-right-thick </v-icon>
      </div>
      <div class="d-inline-block" style="width: 45%">
        <p class="mb-0 caption text-truncate">{{ lastMarkerItem.name }}</p>
        <p class="mb-0 subtitle-2">
          {{ endJobDate }}
        </p>
      </div>
    </div>
    <div class="px-4 pb-4" v-if="item.templateData">
      <p class="mb-0 caption text-truncate">
        เส้นทางหลัก: {{ item.templateData.name }}
      </p>
    </div>
    <v-divider
      :class="[
        active ? 'custom-active-ss-divider' : 'custom-inactive-ss-divider',
      ]"
    ></v-divider>
    <div class="pa-4 d-flex" style="width: 100%">
      <div class="" style="width: 40%">
        <p class="mb-0 caption">จำนวนผู้โดยสาร</p>
        <p class="mb-0 subtitle-2">-</p>
      </div>

      <div class="text-center" style="width: 20%">
        <p class="mb-0 caption">จุดจอด</p>
        <p class="mb-0 subtitle-2">
          {{ item.markers ? item.markers.length : "-" }}
        </p>
      </div>

      <div class="text-center" style="width: 40%">
        <p class="mb-0 caption">ความคืบหน้า</p>
        <p class="mb-0 subtitle-2">-</p>
      </div>
    </div>
    <v-divider
      :class="[
        active ? 'custom-active-ss-divider' : 'custom-inactive-ss-divider',
      ]"
    ></v-divider>
    <div class="pa-4 d-flex justify-space-between align-self-center">
      <div class="d-flex">
        <v-avatar
          :class="[active ? 'custom-active-avatar' : 'custom-inactive-avatar']"
        >
          <img
            :src="item.driver.profileImage"
            :alt="item.driver.dpNickNameEn"
          />
        </v-avatar>
        <div class="ml-2 mt-1">
          <p class="mb-0 subtitle-2">{{ item.driver.dpNameTh }}</p>
          <p class="mb-0 caption">{{ item.driver.dpPhoneNumeber }}</p>
        </div>
      </div>
      <div class="mt-1">
        <p class="mb-0 subtitle-2">
          {{ item.vehicle ? item.vehicle.licenseNumber : "-" }}
        </p>
        <p class="mb-0 caption">{{ item.vehicle ? item.vehicle.type : "-" }}</p>
        <!-- <v-btn large icon>
          <v-icon> mdi-chat</v-icon>
        </v-btn>
        <v-btn large icon>
          <v-icon> mdi-phone-hangup </v-icon>
        </v-btn> -->
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { dateStringToDateFormat } from "@/services/appDate";
export default {
  name: "JobsMonitorJobCard",
  props: {
    item: {},
  },
  data: () => ({
    // item: {
    //   status: "PROCESS",
    // },
    firstMarkerItem: { name: "" },
    lastMarkerItem: { name: "" },
    startJobDate: "",
    endJobDate: "",
  }),
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
    }),
    active: function () {
      return this.$store.state["jobsMonitoring"].activeJobId.includes(
        this.item["_id"]
      );
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    processFirstMarker() {
      if (_.isArray(this.item["markers"])) {
        this.firstMarkerItem = _.get(this.item, `markers.0`, {});
      }
    },
    processLastMasker() {
      if (_.isArray(this.item["markers"])) {
        const lastIdx = this.item["markers"].length - 1;
        this.lastMarkerItem = _.get(this.item, `markers.${lastIdx}`, {});
      }
    },
    processEstimateStartDate() {
      this.startJobDate = dateStringToDateFormat(this.item.startDate, {
        type: "datetime",
        returnThai: true,
        showSecond: false,
      });
    },
    processEstimateEndDate() {
      const selectDate = _.isNil(this.item.endDate)
        ? this.item.estimateEndDate
        : this.item.endDate;

      this.endJobDate = dateStringToDateFormat(selectDate, {
        type: "datetime",
        returnThai: true,
        showSecond: false,
      });
    },

    selectActiveHandler() {
      this.$emit("select-item", this.item);
    },
  },
  mounted() {
    this.processFirstMarker();
    this.processLastMasker();
    this.processEstimateStartDate();
    this.processEstimateEndDate();
  },
};
</script>

<style scoped>
.custom-active-ss-divider {
  border-width: 1.75px;
  border-color: #4770f8;
}

.custom-inactive-ss-divider {
  border-width: 1.75px;
}

.custom-active-avatar {
  border: 3px solid #fff;
}

.custom-inactive-avatar {
  border: 3px solid #4770f8;
}
</style>
