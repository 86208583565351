<template>
	<div name="primaryButton">
		<v-btn
			class="white--text font-weight-bold"
			:color="color"
			light
			elevation="0"
			@click="onClick()"
			large
			:block="isFullWidth"
			:height="height"
			:width="width"
			:disabled="disabled"
			:outlined="outlined"
			:loading="loading"
		>
			<div>
				<v-icon left v-if="icon != null"> {{ icon }} </v-icon>
			</div>
			<slot></slot>
		</v-btn>
	</div>
</template>
<script>
	export default {
		name: "primaryButton",
		props: {
			text: String,
			icon: String,
			color: {
				type: String,
				default: "primary",
			},
			height: Number,
			width: Number,
			large: {
				type: Boolean,
				default: false,
			},
			isFullWidth: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			outlined: {
				type: Boolean,
				default: false,
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			onClick() {
				this.$emit("on-click");
			},
		},
	};
</script>
<style scoped></style>
