<template>
  <v-dialog v-model="show" width="75%" persistent>
    <v-card v-if="show">
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{
          !isEdit ? "เพิ่มเส้นทาง" : "แก้ไขเส้นทาง"
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelAndCloseHandler" v-if="fetchDataError <= 0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="fetchDataError === 0">
        <v-row dense>
          <v-col cols="6">
            <v-card outlined>
              <v-toolbar
                :color="isSelectInput ? 'primary' : '#7B7F9E'"
                dark
                flat
                dense
              >
                <v-spacer></v-spacer>
                <v-toolbar-title>ขาเข้า</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="isSelectInput"
                  icon
                  @click="deleteInputRoute"
                  :disabled="!canEdit"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text
                v-if="isSelectInput"
                style="background-color: #f7f8fb"
              >
                <!--  -->
                <v-form ref="inputRouteForm" v-model="inputRouteFormVaild">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <AssignMultipleJobRouteSelect
                        v-model="formData.inputRoute"
                        :table-id="tableId"
                        :is-required="true"
                        :disabled="!canEdit"
                      />
                    </v-col>
                    <v-col cols="12">
                      <CustomAutocompleteBase
                        :items="timeLabelInputItems"
                        v-model="timeLabelInputSelected"
                        label="ป้ายกำกับเวลา"
                        :disabled="formData.inputRoute === null"
                        :expendMargin="false"
                        no-data-text="ไม่มีข้อมูลป้ายกำกับเวลา"
                        :dense="false"
                        :hide-details="true"
                        item-text="value"
                        item-value="key"
                        :returnObject="true"
                        data-input-name="`inputTimeLabel"
                        :is-dark="true"
                        :loading="timeLabelInputLoading"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลายืนยันงาน"
                        :value="formData.inputConfirmDate"
                        :is-required="true"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerInputConfirmDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลาเริ่มงาน"
                        :value="formData.inputStartDate"
                        :is-required="true"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerInputStartDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลาจบงาน"
                        :value="formData.inputEndDate"
                        :is-required="false"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerInputEndDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>

                    <v-col>
                      <InputView title="เวลาจบงานโดยประมาณ" :small="true">
                        {{
                          formData.inputEstimateEndDate
                            ? timeStringFormat(
                                formData.inputEstimateEndDate,
                                true
                              )
                            : "-"
                        }}
                      </InputView>
                    </v-col>
                  </v-row>
                </v-form>

                <!--  -->
              </v-card-text>
              <v-card-text v-else>
                <div
                  class="d-flex d-flex-column justify-center align-center"
                  style="height: 270px"
                >
                  <v-btn
                    x-large
                    fab
                    outlined
                    color="primary"
                    :disabled="!canEdit"
                    @click="isSelectInput = true"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card outlined>
              <v-toolbar
                :color="isSelectOutput ? 'primary' : '#7B7F9E'"
                dark
                flat
                dense
              >
                <v-spacer></v-spacer>
                <v-toolbar-title>ขาออก</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="isSelectOutput"
                  icon
                  @click="deleteOutputRoute"
                  :disabled="!canEdit"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text
                v-if="isSelectOutput"
                style="background-color: #f7f8fb"
              >
                <v-form ref="outputRouteForm" v-model="outputRouteFormValid">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <AssignMultipleJobRouteSelect
                        v-model="formData.outputRoute"
                        :table-id="tableId"
                        :is-required="true"
                        :disabled="!canEdit"
                      />
                    </v-col>
                    <v-col cols="12">
                      <CustomAutocompleteBase
                        :items="timeLabelOutputItems"
                        v-model="timeLabelOutputSelected"
                        label="ป้ายกำกับเวลา"
                        :disabled="formData.outputRoute === null"
                        :expendMargin="false"
                        no-data-text="ไม่มีข้อมูลป้ายกำกับเวลา"
                        :dense="false"
                        :hide-details="true"
                        item-text="value"
                        item-value="key"
                        :returnObject="true"
                        data-input-name="`outputRoute"
                        :is-dark="true"
                        :loading="timeLabelOutputLoading"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลายืนยันงาน"
                        :value="formData.outputConfirmDate"
                        :is-required="true"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerOutputConfirmDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลาเริ่มงาน"
                        :value="formData.outputStartDate"
                        :is-required="true"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerOutputStartDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <CustomTimePickerInput
                        title="เวลาจบงาน"
                        :value="formData.outputEndDate"
                        :is-required="false"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :hide-detail="true"
                        :is-dark="true"
                        @input="handlerOutputEndDateChange"
                        :disabled="!canEdit"
                      />
                    </v-col>
                    <v-col>
                      <InputView title="เวลาจบงานโดยประมาณ" :small="true">
                        {{
                          formData.outputEstimateEndDate
                            ? timeStringFormat(
                                formData.outputEstimateEndDate,
                                true
                              )
                            : "-"
                        }}
                      </InputView>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-text v-else>
                <div
                  class="d-flex d-flex-column justify-center align-center"
                  style="height: 270px"
                >
                  <v-btn
                    x-large
                    fab
                    outlined
                    color="primary"
                    @click="isSelectOutput = true"
                    :disabled="!canEdit"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <!--  -->
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่ดึงข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>
      <!--  -->
      <v-divider v-if="fetchDataError <= 0"></v-divider>
      <v-card-actions v-if="fetchDataError <= 0">
        <v-btn
          color="error"
          depressed
          v-if="fetchDataError === 0 && isEdit"
          @click="deleteCols()"
        >
          <v-icon>mdi-delete</v-icon>
          ลบเส้นทางนี้
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="saveAndCloseHandler"
          :disabled="!canEdit || !formOk"
          v-if="fetchDataError === 0"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomAutocompleteBase from "@/components/CustomAutocompleteBase.vue";
import CustomTimePickerInput from "@/components/CustomTimePickerInput.vue";
import InputView from "@/components/InputView.vue";
import { dateStringToDateFormat, timeStringFormat } from "@/services/appDate";
import moment from "moment";
import AssignMultipleJobRouteSelect from "./AssignMultipleJobRouteSelect.vue";
import { getTimeLabelFromRouteService } from "@/services/api/assign_multiple_job";
import { mapGetters } from "vuex";

export default {
  name: "AssignMultipleJobTimeDialog",
  computed: {
    ...mapGetters({
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
  },
  data: () => ({
    show: false,
    fetchDataError: 1,
    routeList: [],
    isSelectInput: false,
    isSelectOutput: false,
    formData: {
      inputRoute: null,
      inputConfirmDate: null,
      inputStartDate: null,
      inputEndDate: null,
      inputEstimateEndDate: null, //"2023-04-22T13:00:00",
      outputRoute: null,
      outputConfirmDate: null,
      outputStartDate: null,
      outputEndDate: null,
      outputEstimateEndDate: null, //"2023-04-22T17:00:00",
    },
    //
    timeLabelInputItems: [],
    timeLabelInputSelected: null,
    timeLabelOutputItems: [],
    timeLabelOutputSelected: null,
    timeLabelInputLoading: false,
    timeLabelOutputLoading: false,
    //
    tableId: null,
    selectColsCode: null,
    isEdit: false,
    canEdit: false,
    inputRouteFormVaild: null,
    outputRouteFormValid: null,
  }),
  watch: {
    "formData.inputRoute": function (newValue) {
      this.getTimeLabel("I", newValue);
      this.processEstimateEndDate(
        newValue,
        "inputStartDate",
        "inputEstimateEndDate"
      );
    },
    "formData.outputRoute": function (newValue) {
      this.getTimeLabel("O", newValue);
      this.processEstimateEndDate(
        newValue,
        "outputStartDate",
        "outputEstimateEndDate"
      );
    },
    timeLabelInputSelected: function (newValue) {
      if (newValue !== null) {
        this.setTimeFromLabel("I", newValue);
      }
    },
    timeLabelOutputSelected: function (newValue) {
      if (newValue !== null) {
        this.setTimeFromLabel("O", newValue);
      }
    },
  },
  methods: {
    timeStringFormat: timeStringFormat,
    dateStringToDateFormat: dateStringToDateFormat,
    isValidDate(dateObject) {
      if (dateObject === null || dateObject === undefined) {
        return false;
      }

      return dateObject.toString() !== "Invalid Date";
    },
    open(tableId, colsCode) {
      this.resetForm();
      this.tableId = tableId;
      this.selectColsCode = colsCode;
      //
      if (this.selectColsCode !== null) {
        this.isEdit = true;
        this.canEdit = this.checkCanEdit(colsCode);
        this.getData();
      } else {
        this.fetchDataError = 0;
        this.canEdit = true;
      }

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    checkCanEdit(colsCode) {
      return (
        this.$store.state.assignMultipleJob.columnsStat[this.tableId][
          colsCode
        ] === 0
      );
    },
    saveAndCloseHandler() {
      let responseData = _.cloneDeep(this.formData);
      // responseData["selectInput"] = this.isSelectInput;
      // responseData["selectOutput"] = this.isSelectOutput;
      //
      // check isSelectInput
      if (!this.isSelectInput) {
        responseData = {
          ...responseData,
          inputRoute: null,
          inputConfirmDate: null,
          inputStartDate: null,
          inputEndDate: null,
          inputEstimateEndDate: null,
        };
      } else {
        if (this.inputRouteFormVaild === false) {
          return;
        }
        // else to do noting
        responseData["inputRoute"] = responseData["inputRoute"]["_id"];
      }
      //
      // check isSelectOutput
      if (!this.isSelectOutput) {
        responseData = {
          ...responseData,
          outputRoute: null,
          outputConfirmDate: null,
          outputStartDate: null,
          outputEndDate: null,
          outputEstimateEndDate: null,
        };
      } else {
        if (this.outputRouteFormValid === false) {
          return;
        }
        // else to do noting
        responseData["outputRoute"] = responseData["outputRoute"]["_id"];
      }

      //
      this.show = false;
      this.resolve({ status: 1, data: responseData });
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve({ status: 0, data: null });
    },
    resetForm() {
      this.formData = {
        inputRoute: null,
        inputConfirmDate: null,
        inputStartDate: null,
        inputEndDate: null,
        inputEstimateEndDate: null,
        outputRoute: null,
        outputConfirmDate: null,
        outputStartDate: null,
        outputEndDate: null,
        outputEstimateEndDate: null,
      };
      this.isSelectInput = false;
      this.isSelectOutput = false;
      this.timeLabelInputItems = [];
      this.timeLabelInputSelected = null;
      this.timeLabelOutputItems = [];
      this.timeLabelOutputSelected = null;
      this.isEdit = false;
    },
    handlerInputConfirmDateChange(newValue) {
      this.processDataToUpdate(newValue, "input", "ConfirmDate");
    },
    handlerInputStartDateChange(newValue) {
      this.processDataToUpdate(newValue, "input", "StartDate");
    },
    handlerInputEndDateChange(newValue) {
      this.processDataToUpdate(newValue, "input", "EndDate");
    },
    handlerOutputConfirmDateChange(newValue) {
      this.processDataToUpdate(newValue, "output", "ConfirmDate");
    },
    handlerOutputStartDateChange(newValue) {
      this.processDataToUpdate(newValue, "output", "StartDate");
    },
    handlerOutputEndDateChange(newValue) {
      this.processDataToUpdate(newValue, "output", "EndDate");
    },
    processDataToUpdate(newValue, atForm, assignAtValue) {
      try {
        const getHourAndMinute = function (m) {
          return m.minutes() + m.hours() * 60;
        };
        const atSelectDateKey = `${atForm}${assignAtValue}`;
        const atConfirmDateKey = `${atForm}ConfirmDate`;
        const atStartDateKey = `${atForm}StartDate`;
        const atEndDateKey = `${atForm}EndDate`;
        const atEstimateDateKey = `${atForm}EstimateEndDate`;

        if (assignAtValue === "ConfirmDate") {
          this.formData[atSelectDateKey] = newValue;
          if (!_.isNil(this.formData[atConfirmDateKey])) {
            if (!_.isNil(this.formData[atStartDateKey])) {
              const confirmDate = moment(this.formData[atConfirmDateKey]);
              const startDate = moment(this.formData[atStartDateKey]);
              if (getHourAndMinute(confirmDate) > getHourAndMinute(startDate)) {
                this.formData[atStartDateKey] = null;
                this.formData[atEstimateDateKey] = null;
              } else {
                // not do thing
              }
            }
          } else {
            // not do thing
          }
        } else if (assignAtValue === "StartDate") {
          this.formData[atSelectDateKey] = newValue;
          if (_.isNil(this.formData[atStartDateKey])) {
            this.formData[atEstimateDateKey] = null;
          } else {
            let startDate = moment(this.formData[atStartDateKey]);
            const duration = _.get(
              this.formData,
              `${atForm}Route.duration`,
              null
            );

            if (duration !== null) {
              startDate.add(duration, "s");
              const newEstimateEnddate = startDate.format(
                "YYYY-MM-DDTHH:mm:ss"
              );

              if (newEstimateEnddate !== this.formData[atEstimateDateKey]) {
                this.formData[atEstimateDateKey] = newEstimateEnddate;
              } else {
                // not do thing
              }
            } else {
              this.formData[atEstimateDateKey] = null;
            }
          }
        } else if (assignAtValue === "EndDate") {
          // end date
          this.formData[atSelectDateKey] = newValue;
          if (!_.isNil(this.formData[assignAtValue])) {
            const startDate = moment(this.formData[atStartDateKey]);
            const endDate = moment(this.formData[atEndDateKey]);
            if (getHourAndMinute(startDate) > getHourAndMinute(endDate)) {
              this.formData[atStartDateKey] = null;
              this.formData[atEstimateDateKey] = null;
            } else {
              // not do thing
            }
          }
        }
        //
      } catch (error) {
        console.error(error);
      }
    },
    processEstimateEndDate(newRoute, atField, toField) {
      // atField = inputStartDate, outputStartDate
      const duration = _.get(newRoute, "duration", null);
      let startDate = _.get(this.formData, atField, null);
      if (duration !== null && startDate !== null) {
        startDate = moment(startDate);
        startDate.add(duration, "s");
        const newEstimateEnddate = startDate.format("YYYY-MM-DDTHH:mm:ss");

        if (newEstimateEnddate !== this.formData[toField]) {
          this.formData[toField] = newEstimateEnddate;
        } else {
          // not do thing
        }
      } else {
        // not do thing
      }
    },
    // async route data
    getData() {
      const inputType = [1, 2];
      let _formData = _.cloneDeep(this.formData);
      for (let i = 0; i < inputType.length; i++) {
        let _columnData = _.pickBy(
          this.$store.state.assignMultipleJob.columnsData[this.tableId],
          { colsCode: this.selectColsCode, type: inputType[i] }
        );

        if (_.isEmpty(_columnData)) {
          continue;
        } else {
          _columnData = Object.values(_columnData)[0];
        }

        if (inputType[i] === 1 && !_.isNil(_columnData["route"])) {
          _formData = {
            ..._formData,
            inputRoute: _columnData["route"],
            inputConfirmDate: _columnData["confirmDate"],
            inputStartDate: _columnData["startDate"],
            inputEndDate: _columnData["endDate"],
            inputEstimateEndDate: _columnData["estimateEndDate"], //"2023-04-22T13:00:00",
          };
          this.isSelectInput = true;
        } else if (inputType[i] === 2 && !_.isNil(_columnData["route"])) {
          _formData = {
            ..._formData,
            outputRoute: _columnData["route"],
            outputConfirmDate: _columnData["confirmDate"],
            outputStartDate: _columnData["startDate"],
            outputEndDate: _columnData["endDate"],
            outputEstimateEndDate: _columnData["estimateEndDate"], //"2023-04-22T13:00:00",
          };
          this.isSelectOutput = true;
        }
      }

      this.formData = _formData;
      this.fetchDataError = 0;
    },
    async getTimeLabel(atField, route) {
      if (_.isNil(route)) {
        return;
      }

      try {
        const queryString = new URLSearchParams({
          id: route._id,
        }).toString();

        if (atField === "I") {
          this.timeLabelInputLoading = true;
        } else {
          this.timeLabelOutputLoading = true;
        }

        const responseData = await getTimeLabelFromRouteService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          if (atField === "I") {
            this.timeLabelInputItems = responseData["data"];
            this.timeLabelInputLoading = false;
          } else {
            this.timeLabelOutputItems = responseData["data"];
            this.timeLabelOutputLoading = false;
          }
        } else {
          throw "GET ROUTE LABEL FAIL";
        }
      } catch (error) {
        this.timeLabelInputLoading = false;
        this.timeLabelOutputLoading = false;
        console.error(error);
      }
    },
    setTimeFromLabel(atField, timeLabel) {
      if (atField === "I") {
        this.formData["inputConfirmDate"] = timeLabel["confirmDate"];
        this.formData["inputStartDate"] = timeLabel["startDate"];
        this.formData["inputEndDate"] = timeLabel["endDate"];
        this.processEstimateEndDate(
          this.formData["inputRoute"],
          "inputStartDate",
          "inputEstimateEndDate"
        );
      } else {
        this.formData["outputConfirmDate"] = timeLabel["confirmDate"];
        this.formData["outputStartDate"] = timeLabel["startDate"];
        this.formData["outputEndDate"] = timeLabel["endDate"];
        this.processEstimateEndDate(
          this.formData["outputRoute"],
          "outputStartDate",
          "outputEstimateEndDate"
        );
      }
    },
    //
    deleteInputRoute() {
      this.formData = {
        ...this.formData,
        inputRoute: null,
        inputConfirmDate: null,
        inputStartDate: null,
        inputEndDate: null,
        inputEstimateEndDate: null,
      };
      this.isSelectInput = false;
      this.inputRouteFormVaild = null;
    },
    deleteOutputRoute() {
      this.formData = {
        ...this.formData,
        outputRoute: null,
        outputConfirmDate: null,
        outputStartDate: null,
        outputEndDate: null,
        outputEstimateEndDate: null,
      };
      this.isSelectOutput = false;
      this.outputRouteFormValid = null;
    },
    //
    deleteCols() {
      this.show = false;
      this.resolve({ status: -1, data: null });
    },
  },
  components: {
    CustomAutocompleteBase,
    CustomTimePickerInput,
    InputView,
    AssignMultipleJobRouteSelect,
  },
  computed: {
    formOk() {
      if (
        this.formData.inputRoute !== null &&
        this.formData.inputEndDate === null
      ) {
        if (!this.isValidDate(this.formData.inputEstimateEndDate)) {
          return false;
        }
      }

      if (
        this.formData.outputRoute !== null &&
        this.formData.outputEndDate === null
      ) {
        if (!this.isValidDate(this.formData.outputEstimateEndDate)) {
          return false;
        }
      }

      if (this.isSelectInput === false && this.isSelectOutput === false) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped></style>
