<template>
  <v-combobox
    :items="itemList"
    :loading="loading"
    :search-input.sync="searchingAction"
    :item-value="itemValueKey"
    :value="modelVal"
    @change="itemChange"
    placeholder="ค้นหาจากรหัสพนักงาน, ชื่อ, นามสกุล, ชื่อเล่น แล้วกดเลือก"
    append-icon=""
    item-text="value"
    return-object
    :error-messages="errorMessages()"
    :rules="_rules"
    filled
    dense
    clearable
    hide-no-data
    hide-selected
    flat
    height="44"
    outlined
    class="app-custom-input"
    @update:search-input="textSearchAction"
    :disabled="disabled"
  >
  </v-combobox>

  <!-- hide-no-data -->
  <!--     auto-select-first -->
  <!-- @keyup="enterToReturnEmit" -->
</template>
<script>
import { getEmployeeBySeachingService } from "@/services/api/employee";
import _ from "lodash";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "AppComboboxUserInputSearching",
  props: {
    value: {},
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getParamQuery();
  },
  data: () => ({
    modelVal: null,
    itemList: [],
    loading: false,
    searchingAction: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryOrder: {
      text: "text",
      empid: "empId",
    },
    formData: {
      text: null,
      empId: null,
    },
    firstInit: false,
    selectFromListChecker: false,
  }),
  computed: {
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ");
        }
        return r;
      },
      set(val) {},
    },
  },
  watch: {},

  mounted() {
    this.modelVal = this.value;
  },
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "text":
              this.formData[this.queryOrder[key]] =
                appDecodeSingleURIComponentHelper(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });

      if (!_.isNil(this.formData["text"])) {
        this.modelVal = this.formData["text"];
      }
    },

    alert(message) {
      alert(message);
    },

    async callApi(val) {
      let responseData = await getEmployeeBySeachingService(this.$store, val);
      if (responseData.code === 200) {
        this.itemList = responseData["data"];
      } else {
        clearTimeout(this.searchCountTimer);
        this.itemList = [];
      }
      this.loading = false;
    },

    errorMessages() {
      if (this.modelVal !== null) {
        if (_.isString(this.modelVal)) {
          return "กรุณาเลือกชื่อพนักงานจากรายการที่แสดง";
        }
      }
      return "";
    },

    itemChange(newData) {
      if (!_.isString(newData) && !_.isNil(newData)) {
        this.selectFromListChecker = true;
        this.$emit("searchItemChange", newData);
        this.modelVal = newData;
        this.itemList = [];
      }

      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },

    textSearchAction(searchText) {
      if (
        !_.isNil(searchText) &&
        this.firstInit &&
        !this.selectFromListChecker
      ) {
        if (searchText.length > 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(searchText); // call api function
          }, 1500);
        } else {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
        }
      }
      this.firstInit = true;
      this.selectFromListChecker = false;
    },
  },
};
</script>
<style>
.v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
}
</style>
