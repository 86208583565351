const displayNumberFixedPointHelper = (data, point) => {
  try {
    const pN = Number(data);
    return pN.toFixed(point);
  } catch (error) {
    return null;
  }
};

const displayNumberLocaleString = (data, fixedPoint) => {
  try {
    if (fixedPoint === null || fixedPoint === undefined) {
      throw "CAN NOT PARSE";
    }

    if (data % 1 !== 0) {
      return data.toLocaleString(undefined, {
        minimumFractionDigits: fixedPoint,
        maximumFractionDigits: fixedPoint,
      });
    } else {
      return data.toLocaleString();
    }
  } catch (error) {
    return data;
  }
};

const numberWithCommasHelper = (x) => {
  if (x === undefined || x === null) {
    return undefined;
  }

  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const calculateAverageHelper = (
  a,
  b,
  defaultValue,
  returnLocalString,
  fixedPoint
) => {
  try {
    if (b === 0) {
      throw "CONNOT_DIVIDER";
    }

    const c = a / b;

    if (isNaN(c)) {
      throw "PARSE_ERROR";
    }

    return returnLocalString ? displayNumberLocaleString(c, fixedPoint) : c;
  } catch (error) {
    return defaultValue;
  }
};

const calculatePercentHelper = (
  a,
  b,
  defaultValue,
  returnLocalString,
  fixedPoint
) => {
  try {
    if (b === 0) {
      throw "CONNOT_DIVIDER";
    }

    const c = (a / b) * 100;

    if (isNaN(c)) {
      throw "PARSE_ERROR";
    }

    return returnLocalString ? displayNumberLocaleString(c, fixedPoint) : c;
  } catch (error) {
    return defaultValue;
  }
};

module.exports = {
  numberWithCommasHelper,
  calculateAverageHelper,
  calculatePercentHelper,
  displayNumberFixedPointHelper,
  displayNumberLocaleString,
};
