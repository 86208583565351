import colorPalette from "@/services/colorPalette";

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT,
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER,
};

const CUSTOM_MARKER_ICON = {
  START_POINT: {
    path: "faLocationPin",
    // fillColor: "#659bde",
    fillColor: "#03A6F9",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 45,
    scale: 0.085,
  }, // back pin
  FINISH_POINT: {
    path: "faLocationPin",
    fillColor: "#03A6F9",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 45,
    scale: 0.085,
  }, // flag
  CHECKPOINT: {
    path: "faLocationPin",
    fillColor: "#03A6F9",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 45,
    scale: 0.085,
  },
  CHECKPOINT_HIGHLIGHT: {
    // //
    // path: "faLocationPin",
    // fillColor: "green",
    // fillOpacity: 1,
    // strokeWeight: 0,
    // rotation: 0,
    // scale: 0.06,
  },
};

// const POINT_MARKER_ICON_CONFIG = {
//   path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
//   strokeOpacity: 0.7,
//   strokeWeight: 4,
//   strokeColor: COLORS.POINT,
//   fillColor: COLORS.POINT_FILL,
//   fillOpacity: 0.7,
//   scale: 1
// };

const POINT_MARKER_ICON_CONFIG = {
  url: require("../../assets/van_1.png"),
  // scaledSize: new google.maps.Size(50, 50),
  scaledSize: { width: 50, height: 50, f: "px", b: "px" },
};

const LINE_SYMBOL_CONFIG = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1,
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,

      repeat: "10px",
    },
  ],
};

const VIEW_PARAMS = {
  center: { lat: 13.283859, lng: 100.915876 },
  zoom: 18,
  heading: 40,
  tilt: 65,
};

const ANIMATION_DURATION = 12000;

const ANIMATION_POINTS = [
  { lat: 13.283859, lng: 100.915876 },
  // { lat: 13.283742, lng: 100.915856 },
  // { lat: 13.283622, lng: 100.915749 },
  { lat: 13.283674, lng: 100.915534 },
  // { lat: 13.284108, lng: 100.915394 },
  // { lat: 13.286486, lng: 100.913384 },
  // { lat: 13.287799, lng: 100.912296 },
  { lat: 13.288602, lng: 100.91324 },
  // { lat: 13.289394, lng: 100.914134 },
  { lat: 13.287987, lng: 100.915324 },
  // { lat: 13.286577, lng: 100.916473 },
  // { lat: 13.284867, lng: 100.9179 },
  { lat: 13.284745, lng: 100.917921 },
  // { lat: 13.284661, lng: 100.917562 },
  { lat: 13.283859, lng: 100.915876 },
];

// const ANIMATION_POINTS = [
//   {lat: 53.554473, lng: 10.008226},
//   {lat: 53.554913, lng: 10.008124},
//   {lat: 53.554986, lng: 10.007928},
//   {lat: 53.554775, lng: 10.006363},
//   {lat: 53.554674, lng: 10.006383},
//   {lat: 53.554473, lng: 10.006681},
//   {lat: 53.554363, lng: 10.006971},
//   {lat: 53.554453, lng: 10.008091},
//   {lat: 53.554424, lng: 10.008201},
//   {lat: 53.554473, lng: 10.008226}
// ];

// const mapSettings = {
//   clickableIcons: false,
//   streetViewControl: false,
//   panControlOptions: false,
//   gestureHandling: "cooperative",
//   backgroundColor: COLORS.LANDSCAPE,
//   mapTypeControl: false,
//   zoomControlOptions: {
//     style: "SMALL",
//   },
//   zoom: 8,
//   minZoom: 8,
//   maxZoom: 20,
//   styles: [
//     {
//       featureType: "all",
//       elementType: "geometry",
//       stylers: [
//         {
//           color: "#293037",
//         },
//       ],
//     },
//     {
//       featureType: "all",
//       elementType: "labels.text.fill",
//       stylers: [
//         {
//           gamma: 0.01,
//         },
//         {
//           lightness: 20,
//         },
//         {
//           color: "#949aa6",
//         },
//       ],
//     },
//     {
//       featureType: "all",
//       elementType: "labels.text.stroke",
//       stylers: [
//         {
//           saturation: -31,
//         },
//         {
//           lightness: -33,
//         },
//         {
//           weight: 2,
//         },
//         {
//           gamma: "0.00",
//         },
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "all",
//       elementType: "labels.icon",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.country",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.province",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.locality",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "simplified",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.locality",
//       elementType: "labels.icon",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.neighborhood",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "administrative.land_parcel",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "landscape",
//       elementType: "geometry",
//       stylers: [
//         {
//           lightness: 30,
//         },
//         {
//           saturation: 30,
//         },
//         {
//           color: "#344150",
//         },
//         {
//           visibility: "on",
//         },
//       ],
//     },
//     {
//       featureType: "poi",
//       elementType: "geometry",
//       stylers: [
//         {
//           saturation: "0",
//         },
//         {
//           lightness: "0",
//         },
//         {
//           gamma: "0.30",
//         },
//         {
//           weight: "0.01",
//         },
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "poi",
//       elementType: "labels",
//       stylers: [
//         {
//           visibility: "simplified",
//         },
//         {
//           color: "#1e242b",
//         },
//         {
//           lightness: "30",
//         },
//       ],
//     },
//     {
//       featureType: "poi",
//       elementType: "labels.text",
//       stylers: [
//         {
//           gamma: "1.5",
//         },
//       ],
//     },
//     {
//       featureType: "poi.park",
//       elementType: "geometry",
//       stylers: [
//         {
//           lightness: "100",
//         },
//         {
//           saturation: "-20",
//         },
//         {
//           visibility: "simplified",
//         },
//         {
//           color: "#344150",
//         },
//         {
//           gamma: "0.92",
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "geometry",
//       stylers: [
//         {
//           lightness: 10,
//         },
//         {
//           saturation: -30,
//         },
//         {
//           color: "#28323f",
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "geometry.stroke",
//       stylers: [
//         {
//           saturation: "-100",
//         },
//         {
//           lightness: "-100",
//         },
//         {
//           gamma: "0.00",
//         },
//         {
//           color: "#0076ff",
//         },
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "labels",
//       stylers: [
//         {
//           visibility: "on",
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "labels.text",
//       stylers: [
//         {
//           visibility: "on",
//         },
//         {
//           color: "#575e6b",
//         },
//         {
//           invert_lightness: true,
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "labels.text.stroke",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "road",
//       elementType: "labels.icon",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "road.highway",
//       elementType: "geometry.fill",
//       stylers: [
//         {
//           color: "#232c37",
//         },
//         {
//           visibility: "on",
//         },
//       ],
//     },
//     {
//       featureType: "road.highway",
//       elementType: "geometry.stroke",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "transit",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "transit",
//       elementType: "geometry",
//       stylers: [
//         {
//           visibility: "simplified",
//         },
//         {
//           color: "#222935",
//         },
//       ],
//     },
//     {
//       featureType: "transit.station.airport",
//       elementType: "all",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//     {
//       featureType: "water",
//       elementType: "all",
//       stylers: [
//         {
//           lightness: -20,
//         },
//         {
//           color: "#212a35",
//         },
//       ],
//     },
//   ],
// };

const MAP_STYLE_SETTINGS = {
  styles: [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        {
          color: "#293037",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          gamma: 0.01,
        },
        {
          lightness: 20,
        },
        {
          color: "#949aa6",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          saturation: -31,
        },
        {
          lightness: -33,
        },
        {
          weight: 2,
        },
        {
          gamma: "0.00",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          lightness: 30,
        },
        {
          saturation: 30,
        },
        {
          color: "#344150",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          saturation: "0",
        },
        {
          lightness: "0",
        },
        {
          gamma: "0.30",
        },
        {
          weight: "0.01",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#1e242b",
        },
        {
          lightness: "30",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          gamma: "1.5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          lightness: "100",
        },
        {
          saturation: "-20",
        },
        {
          visibility: "simplified",
        },
        {
          color: "#344150",
        },
        {
          gamma: "0.92",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 10,
        },
        {
          saturation: -30,
        },
        {
          color: "#28323f",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          saturation: "-100",
        },
        {
          lightness: "-100",
        },
        {
          gamma: "0.00",
        },
        {
          color: "#0076ff",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#575e6b",
        },
        {
          invert_lightness: true,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#232c37",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          color: "#222935",
        },
      ],
    },
    {
      featureType: "transit.station.airport",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          lightness: -20,
        },
        {
          color: "#212a35",
        },
      ],
    },
  ],
};

export {
  MAP_STYLE_SETTINGS,
  CUSTOM_MARKER_ICON,
  LINE_PATH_CONFIG,
  POINT_MARKER_ICON_CONFIG,
  VIEW_PARAMS,
  ANIMATION_POINTS,
  ANIMATION_DURATION,
};
