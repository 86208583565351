<template>
  <v-breadcrumbs v-if="show" class="app-bread-crumbs" large :items="items">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "AppBreadCrumbs",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scope>
.app-bread-crumbs {
  border-bottom: 0.15em solid #e8edf5;
}
</style>
