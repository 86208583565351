<template>
	<div name="ChecklistTextInputImprove">
		<v-text-field
			outlined
			:class="
				small
					? 'app-custom-input custom-placeholer-color-sm'
					: 'app-custom-input custom-placeholer-color'
			"
			v-model="valueInput"
			:clearable="clearable"
			:placeholder="placeholder"
			:type="type"
			:background-color="getBackgroundColor"
			:disabled="disabled"
			:rules="rules"
			:readonly="readonly"
			:autofocus="autofocusInput"
			@keyup="enterToReturnEmit"
			@blur="handleUpdateItem($event)"
			:name="dataInputName"
			:dense="dense"
			:label="label"
			:hide-details="hideDetail"
			:error="error"
			:error-messages="errorMessages"
			:loading="loading"
		></v-text-field>
	</div>
</template>
<script>
	import _ from "lodash";
	export default {
		name: "ChecklistTextInputImprove",

		props: {
			value: {},
			placeholder: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			label: {
				type: String,
				default: "",
			},
			isRequired: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			type: {
				type: String,
				default: "input",
			},
			item: {
				type: Object,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			readonly: {
				type: Boolean,
				default: false,
			},
			rules: {
				type: Array,
				default: () => [],
			},
			autofocus: {
				type: Boolean,
				default: false,
			},
			dataInputName: {
				type: String,
				default: "",
			},
			dense: {
				type: Boolean,
				default: true,
			},
			hideDetail: {
				type: Boolean,
				default: false,
			},
			small: {
				type: Boolean,
				default: false,
			},
			error: {
				type: Boolean,
				default: false,
			},
			errorMessages: {
				type: String,
				default: "",
			},
			loading: {
				type: Boolean,
				default: false,
			},
			isDark: {
				type: Boolean,
				default: false,
			},
		},

		data() {
			return { currentSearchText: "" };
		},

		computed: {
			valueInput: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				},
			},
			autofocusInput: {
				get() {
					return this.autofocus;
				},
				set(val) {},
			},
			getBackgroundColor: function () {
				if (this.disabled) {
					if (this.isDark) {
						return this.$vuetify.theme.themes.light
							.backgroudDarkDisableInputView;
					} else {
						return this.$vuetify.theme.themes.light.backgroudDisableInputView;
					}
				} else {
					return this.$vuetify.theme.themes.light.backgroundInputView;
				}
			},
		},

		methods: {
			// enter
			enterToReturnEmit(event) {
				if (event.code == "Enter") {
					this.loading = false;
					if (this.valueInput !== null) {
						if (this.item !== null) {
							// this.item["name"] = this.valueInput;
							this.$emit("typeOnChange", this.item);
						} else {
							this.$emit("typeOnChange", this.valueInput);
						}
						// this.modelVal = "";
					}
					return;
				}
			},

			handleUpdateItem(evt) {
				this.$emit("blurHandler", this.dataInputName);
			},
		},
	};
</script>
<style></style>
