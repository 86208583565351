<template>
	<div name="inputView">
		<div v-if="isShowTitle">
			<span :class="small ? 'title-input-sm' : 'title-input'">
				<span :class="isDark ? 'white--text' : ''"
					><span :class="isTitleDisabled ? 'placeholerColor--text' : ''">{{
						title
					}}</span></span
				></span
			>
			<span class="required-color" v-if="isRequired && showRequiredBullet">
				*
			</span>
			<div v-if="bottomMargin" class="mt-2"></div>
		</div>
		<slot></slot>
	</div>
</template>
<script>
	export default {
		name: "inputView",

		props: {
			title: String,
			isRequired: Boolean,
			showRequiredBullet: {
				type: Boolean,
				default: true,
			},
			bottomMargin: {
				type: Boolean,
				default: true,
			},
			small: {
				type: Boolean,
				default: false,
			},
			isDark: {
				type: Boolean,
				default: false,
			},
			isShowTitle: {
				type: Boolean,
				default: true,
			},
			isTitleDisabled: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
<style scoped>
	.title-input {
		font-size: 14px;
		padding-bottom: 5px;
		color: var(--v-bodyText);
		font-weight: bold;
	}

	.title-input-sm {
		font-size: 12px;
		padding-bottom: 5px;
		color: var(--v-bodyText);
		font-weight: bold;
	}

	.required-color {
		color: var(--v-error);
	}
</style>
