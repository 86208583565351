<template>
  <v-row no-gutters>
    <v-col cols="12" class="pl-6 pr-6 my-6">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn> -->

          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="calendarValue"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          color="primary"
          @change="getEvents"
          locale="th"
        ></v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  name: "UserPaymentCalendarChart",
  props: {
    data: {
      require: true,
    },
    startDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      calendarValue: "",
      type: "month",
      mode: "stack",
      weekday: [0, 1, 2, 3, 4, 5, 6],

      events: [],
      colors: [
        "primary lighten-3",
        "primary lighten-2",
        "primary lighten-1",
        "primary",
        "primary darken-1",
        "primary darken-2",
        "primary darken-3",
      ],

      permList: {},
    };
  },
  mounted() {
    this.calendarValue = new Date(this.startDate);
  },
  watch: {
    startDate: function () {
      this.processEvent(this.startDate, null);
    },
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    processEvent(startDate, endDate) {
      this.calendarValue = new Date(startDate);
      let mStartDate = moment(startDate);
      let mEndDate = null;
      if (endDate === null) {
        mEndDate = mStartDate.endOf("month").toDate();
        mStartDate = mStartDate.toDate();
      } else {
        mEndDate = moment(endDate).toDate();
        mStartDate = mStartDate.toDate();
      }

      // for (let i = 0; i < this.data["billDate"]; i++) {
      const selectDays = _.filter(
        this.data["billDate"],
        (item) =>
          moment(item["date"]).toDate() >= mStartDate &&
          moment(item["date"]).toDate() <= mEndDate
      );

      const selectDayGroup = _.groupBy(selectDays, "date");

      let events = [];
      for (const [dateKey, dateItem] of Object.entries(selectDayGroup)) {
        events.push({
          name: `${dateItem.length} บิล`,
          start: new Date(dateKey),
          color: this.colors[this.rnd(0, 6)],
          timed: false,
        });
      }
      this.events = events;
      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = true;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + 900000);

      //   if (!events.some((e) => e.start.getDate() === first.getDate())) {
      //     events.push({
      //       name: this.names[i % 7],
      //       start: first,
      //       end: second,
      //       color: this.colors[i % 7],
      //       timed: !allDay,
      //     });
      //   }
      // }
      //
    },
    getEvents({ start, end }) {
      this.processEvent(start.date, end.date);
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
