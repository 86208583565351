<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="950px" scrollable persistent>
      <v-card dark color="#222831" v-if="show">
        <v-toolbar color="#323841" dark>
          <v-card-title> ตรวจสอบรายการสรุปใบงาน </v-card-title>
          <v-spacer></v-spacer
          ><v-btn icon @click="cancel()"
            ><v-icon>mdi-window-close</v-icon></v-btn
          ></v-toolbar
        >

        <v-divider></v-divider>
        <v-card-text class="px-0 py-0">
          <v-row no-gutters class="pa-0">
            <v-col cols="12" class="pa-0">
              <table
                v-if="previewItems !== null && previewItems.length > 0"
                class="excel-preview-table"
                dense
                style="background-color: #222831"
              >
                <tbody>
                  <tr>
                    <td
                      v-for="(header, headIdx) in previewHeaders"
                      :key="headIdx"
                      class="text-left table_header_text_style px-3 py-3"
                      :style="`width: ${colWidth[headIdx]}`"
                    >
                      {{ header }}
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in previewItems" :key="idx">
                    <td
                      class="table_body_text_style px-3 py-3"
                      style="width: 150px"
                    >
                      <span>{{ row["empId"] ? row["empId"] : "-" }}</span>
                    </td>
                    <td
                      class="table_body_text_style px-3 py-3"
                      style="width: 200px"
                    >
                      <span
                        v-if="
                          row['firstName'] === null &&
                          row['lastName'] === null &&
                          row['nickName'] === null
                        "
                      >
                        -
                      </span>
                      <span v-else>{{
                        `${row["salutation"]["th"]}${row["firstName"]["th"]} ${row["lastName"]["th"]} (${row["nickName"]["th"]})`
                      }}</span>
                    </td>
                    <td
                      class="table_body_text_style px-3 py-3"
                      style="width: 150px"
                    >
                      <span>{{
                        row["paymentName"]
                          ? row["paymentName"].slice(0, 5)
                          : "-"
                      }}</span>
                    </td>
                    <td
                      class="table_body_text_style px-3 py-3"
                      style="width: 150px"
                    >
                      <span>{{
                        row["contractNumber"] ? row["contractNumber"] : ""
                      }}</span>
                    </td>
                    <td
                      class="table_body_text_style px-3 py-3"
                      style="width: 150px"
                    >
                      <v-card
                        rounded="pill"
                        outlined
                        :color="worksheetStatusCardColor(row.status)"
                        height="34"
                        width="130"
                      >
                        <div class="status_text_style">
                          {{ worksheetStatusText(row.status) }}
                        </div>
                      </v-card>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <v-list
                  dense
                  class="text-center"
                  style="background-color: #222831"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="pa-5 text-center"
                        v-if="fetchDataError === 1"
                      >
                        กำลังดึงข้อมูล...
                      </v-list-item-title>
                      <v-list-item-title class="text-app-title" v-else
                        >ไม่มีข้อมูล
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-9"
            @click="saveChange"
            :loading="isSubmiting"
            dark
            elevation="0"
          >
            บันทึก
          </v-btn>
        </v-card-actions> -->
      </v-card>
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import { postFinanceCSVService } from "@/services/api/worksheet";
import { mapGetters } from "vuex";

import { getWorksheetListService } from "@/services/api/worksheet";

export default {
  name: "worksheetErrorPreviewDialog",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    AppDialogConfirm,
    AppOverlay,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,

      componentLoading: false,

      previewItems: [],

      colWidth: ["100px", "150px", "100px", "100px", "100px"],

      previewHeaders: ["ID_EMP", "N_EMP", "CODE", "ID_CON", "STATUS"],
      querySearchAndFilterList: {},
      queryOptionsTable: {
        by: "createddate", // key
        order: "desc", // asc or desc
      },

      fetchDataError: 1,

      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },

      isPermissionCanEdit: false,
      isPermissionCanView: false,
    };
  },

  watch: {},

  computed: {
    ...mapGetters({
      worksheetStatusCardColor: "worksheet/worksheetStatusCardColor",
      worksheetStatusText: "worksheet/worksheetStatusText",
    }),
  },

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.componentLoading = false;
      this.fetchDataError = 1;
      this.previewItems = [];

      this.querySearchAndFilterList = {};
      this.queryOptionsTable = {
        by: "createddate", // key
        order: "desc", // asc or desc
      };
    },

    open(empId) {
      // console.log("empId", empId);
      this.resetFormData();
      this.checkUserPermissions();

      if (!_.isNil(empId)) {
        this.getSearchItem({ value: empId });
      }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      delete this.querySearchAndFilterList["idname"]; // name
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["value"])) {
          _queryParameter["idname"] = _searchingParameter["value"];
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ..._queryParameter,
      };

      // this.setRouterQuery(this.querySearchAndFilterList);
    },

    async getData() {
      this.componentLoading = true;
      this.previewItems = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getWorksheetListService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let worksheets = responseData.data["paginatedResults"];
        this.previewItems = _.cloneDeep(worksheets);
      } else {
        this.previewItems = [];
      }

      if (this.previewItems.length === 0) {
        this.fetchDataError = 0;
      }

      this.componentLoading = false;
    },

    cancel() {
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
      this.resetFormData();
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.excel-preview-table {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#171b21, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: transparentize(#171b21, 0.3);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.excel-preview-table tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
  // min-height: 300px;
}

.excel-preview-table th td {
  // background: #f7faff;
  border: 2px solid #222831;
  background-color: #171b21;
  border-collapse: collapse;
  border-top: 0;
}

// .excel-preview-table tr:nth-child(even) td {
//   background-color: #222831;
// }

.excel-preview-table tr:first-child {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(1) {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(even) {
  background-color: transparentize(#171b21, 0.5);
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.status_text_style {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
}

.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.clickable-row {
  cursor: pointer;
}

.table_header_text_style {
  font-weight: bold;
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
