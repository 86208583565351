<template>
  <v-combobox
    :items="itemList"
    :loading="loading"
    :search-input.sync="searchingAction"
    :item-value="itemValueKey"
    v-model="modelVal"
    @change="itemChange"
    placeholder="ค้นหาจากเลขทะเบียนรถ/ตัวถัง แล้วกดเลือก"
    append-icon=""
    item-text="value"
    :error-messages="errorMessages()"
    return-object
    :rules="_rules"
    filled
    dense
    clearable
    hide-no-data
    hide-selected
    :disabled="disabled"
    flat
    height="44"
    outlined
    class="app-custom-input"
  >
  </v-combobox>
  <!-- :error-messages="errorMessages()" -->
  <!-- hide-no-data -->
  <!--     auto-select-first -->
  <!-- @keyup="enterToReturnEmit" -->
</template>
<script>
import { getVehicleSeachingSelectService } from "@/services/api/vehicle";
import _ from "lodash";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "AppComboboxVehicleSearching",
  props: {
    value: {},
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
  },
  mounted() {
    this.getParamQuery();
  },
  data: () => ({
    // modelVal: null,
    itemList: [],
    loading: false,
    searchingAction: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryOrder: {
      text: "text",
      cassieId: "cassieId",
    },
    formData: {
      text: null,
      cassieId: null,
    },
    firstInit: false,
  }),
  computed: {
    modelVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("on-change", val);
      },
    },
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ");
        }
        return r;
      },
      set(val) {},
    },
  },
  watch: {
    searchingAction: {
      immediate: false,
      handler: function (val) {
        if (!_.isNil(val) && this.firstInit) {
          if (val.length > 2) {
            clearTimeout(this.searchCountTimer);
            this.loading = true;
            this.searchCountTimer = setTimeout(() => {
              this.callApi(val); // call api function
            }, 1500);
          }
        }
        this.firstInit = true;
      },
    },
  },
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "text":
              this.formData[this.queryOrder[key]] =
                appDecodeSingleURIComponentHelper(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });

      if (!_.isNil(this.formData["text"])) {
        this.modelVal = this.formData["text"];
      }
    },

    alert(message) {
      alert(message);
    },

    async callApi(val) {
      let resp_data = await getVehicleSeachingSelectService(this.$store, val);
      if (resp_data.code === 200) {
        this.itemList = resp_data["data"];
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },

    errorMessages() {
      if (this.modelVal !== null) {
        if (_.isString(this.modelVal) && this.modelVal !== "") {
          // console.log(this.modelVal);
          return "กรุณาเลือกรถยนต์จากรายการที่แสดง";
        }
      }
      return "";
    },

    itemChange() {
      if (!_.isString(this.modelVal) && !_.isNil(this.modelVal)) {
        this.$emit("searchItemChange", this.modelVal);
        // this.modelVal = this.modelVal["value"];
        this.itemList = [];
      }
    },
    // enter
    enterToReturnEmit(event) {
      if (this.enableEnterSearch) {
        if (event.code === "Enter") {
          clearTimeout(this.searchCountTimer);
          this.loading = false;

          if (_.isString(this.modelVal) === true) {
            this.$emit("searchItemChange", {
              value: this.modelVal,
            });
          } else {
            this.$emit("searchItemChange", null);
          }

          this.itemList = [];
          return;
        }
      }
    },
  },
};
</script>
<style>
.v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
}
</style>
