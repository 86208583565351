var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"80%","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.show)?_c('v-card',[_c('v-card-title',[_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":_vm.driverItem ? _vm.driverItem.profileImage : ''}})]),_c('p',{staticClass:"mb-0 mx-2"},[_vm._v(_vm._s(_vm.driverItem ? _vm.driverItem.fullName : ""))]),_c('v-spacer'),_c('p',{staticClass:"mb-0"},[_c('span',[_vm._v(" วิ่ง: "+_vm._s(_vm.sumDriverStatOnSheet[_vm.driverId] ? _vm.sumDriverStatOnSheet[_vm.driverId]["jobs"] : "-")+" ["+_vm._s(_vm.currentDriverStatusOnTable["jobs"])+"] ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" ฟรี: "+_vm._s(_vm.sumDriverStatOnSheet[_vm.driverId] ? _vm.sumDriverStatOnSheet[_vm.driverId]["free"] : "-")+" ["+_vm._s(_vm.currentDriverStatusOnTable["free"])+"] ")])])],1),(_vm.fetchDataError == 0)?_c('v-card-text',[_c('table',{staticClass:"table-checker-main"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center white--text",staticStyle:{"width":"30px","height":"60px","background-color":"#7b7f9e"}},[_vm._v(" วันที่ "),_c('br'),_vm._v(" สายวิ่ง ")]),_vm._l((_vm.columnsData),function(header,idx){return _c('td',{key:("dc-" + (header.row) + "-" + (header.col) + "-" + idx),class:[
                {
                  'table-text-noData': header.noData,
                  'table-text-pair-cols': header.isBetweenPair,
                  'table-data-bg-cols': !header.isBetweenPair,
                },
                'text-center' ],staticStyle:{"width":"50px"},attrs:{"rowspan":header.noData ? _vm.rowData.length + 1 : 1}},[(header.noData === 0)?_c('div',{staticStyle:{"vertical-align":"top"}},[_c('p',{class:[
                    'mb-0',
                    {
                      'red--text':
                        header.outputStartDateLessThanInputStartDate,
                    } ]},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(header.type === 1 ? "เข้า" : "ออก"))]),_vm._v(" "+_vm._s(header.title)+" ")])]):_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(header.type === 1 ? "ไม่มีขาเข้า" : "ไม่มีขาออก")+" ")])])])})],2),_vm._l((_vm.rowData),function(rowData,dataRowIdx){return _c('tr',{key:((rowData.row) + "-" + (rowData.col))},[_c('td',{staticClass:"text-center",staticStyle:{"height":"60px"}},[_c('p',{staticClass:"mb-1 text-app-md font-weight-bold"},[_vm._v(" "+_vm._s(rowData.title)+" ")]),_c('p',{staticClass:"text-app-title font-weight-bold"},[_vm._v(" "+_vm._s(rowData.date)+" ")])]),_vm._l((rowData.data),function(data,dataColIdx){return _c('td',{key:("dc-" + dataRowIdx + "r" + dataColIdx),class:_vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))]
                  ? _vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))]['className']
                  : '',on:{"click":function($event){return _vm.selectedCellHandler(
                  _vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))]
                )}}},[(
                  _vm.calculateNumberOfSelectedDriver(
                    _vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))]
                  )
                )?_c('p',{class:[
                  {
                    'red--text':
                      _vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))][
                        'equalSelectedDriver'
                      ],
                  },
                  'text-center pt-2 mb-1 text-app-detail-md font-weight-bold' ]},[_vm._v(" "+_vm._s(_vm.columnMatch[("r" + (data["row"]) + "c" + (data["col"]))]["drivers"])+"/"+_vm._s(_vm.columnMatch[("r" + (data["row"]) + "c" + (data["col"]))][ "numberOfSelectedDriver" ])+" "),(
                    _vm.columnMatch[("r" + (data['row']) + "c" + (data['col']))]['overlap']
                  )?_c('span',[_vm._v(" [!!!] ")]):_vm._e()]):_c('p',{staticClass:"text-center pt-2 mb-1 text-app-detail-md font-weight-bold"},[_vm._v(" - ")])])})],2)})],2)])]):_vm._e(),(_vm.fetchDataError === -2)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" ไม่สามารถดึงข้อมูลได้ ")]):_vm._e(),(_vm.fetchDataError === -1)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" ไม่สามารถบันทึกข้อมูลได้ ")]):_vm._e(),(_vm.fetchDataError === 1)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" กำลังดึงข้อมูล... ")]):_vm._e(),(_vm.fetchDataError === 2)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" กำลังบันทึกข้อมูล... ")]):_vm._e(),(_vm.fetchDataError <= 0)?_c('v-divider'):_vm._e(),(_vm.fetchDataError <= 0)?_c('v-card-actions',[_c('v-spacer'),(_vm.fetchDataError <= 0)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelAndCloseHandler}},[_vm._v(" ยกเลิก ")]):_vm._e(),(_vm.fetchDataError === 0)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveAndCloseHandler}},[_vm._v(" บันทึก ")]):_vm._e()],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }