import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getWorksheetUserPaymentReport(store, queryString) {
  let uri = "/report/summary/fin";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getWorksheetPaymentOverallReport(store, queryString) {
  let uri = "/report/summary/fin-all";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
