<template>
  <v-combobox
    class="app-custom-input"
    v-model="valueInput"
    :items="itemList"
    :loading="loading"
    :search-input.sync="searching"
    :label="label"
    :placeholder="placeholder"
    return-object
    filled
    :hide-no-data="hideText"
    hide-details
    outlined
    item-value="_id"
    item-text="nameTh"
    dense
    @keyup="enterToReturnEmit"
    :disabled="disabled"
    :rules="rules"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            ไม่มีข้อมูล "<strong>{{ searching }}</strong
            >" กด <kbd>enter</kbd> เพื่อเพิ่มใหม่
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>

  <!-- @keyup="enterToReturnEmit" -->
  <!-- prepend-inner-icon="mdi-magnify" -->
  <!-- :item-value="itemValueKey" -->
  <!--  item-text="value" -->
</template>

<script>
import { searchContractCompanyElementService } from "@/services/api/contract";
import _ from "lodash";

export default {
  name: "DynamicCompanyElement",
  components: {},

  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    itemList: [],
    loading: false,
    searching: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryList: [],
    hideText: true,
  }),
  watch: {
    searching(val) {
      if (!_.isNil(val) && _.isString(val)) {
        this.currentSearchText = val;
        if (val.length >= 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1500);
        } else {
          this.hideText = true;
        }
      } else {
        clearTimeout(this.searchCountTimer);
        this.loading = true;
        this.searchCountTimer = setTimeout(() => {
          this.callApi(""); // call api function
        }, 1500);
        // this.hideText = true;
      }
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        // console.log(val);
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async callApi(val) {
      if (val.length === 0) {
        this.loading = false;
        return;
      }
      //
      let resp_data = await searchContractCompanyElementService(
        this.$store,
        new URLSearchParams({ text: val }).toString()
      );

      if (resp_data.code === 200) {
        this.itemList = resp_data.data;
        this.hideText = false;
      } else {
        this.itemList = [];
        this.hideText = true;
      }
      this.loading = false;
    },

    enterToReturnEmit(event) {
      if (event.code == "Enter") {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        this.itemList = [];
        return;
      } else if (event.code == "Backspace") {
        this.hideText = true;
      }
    },
  },

  mounted() {
    this.callApi("");
  },
};
</script>

<style scope></style>
