<template>
  <div name="forgotPassword">
    <v-container fluid pa-0>
      <v-form @submit.prevent="forgotPasswordAction()">
        <v-row
          align-content="center"
          justify="center"
          dense
          style="height: 80vh"
        >
          <v-col cols="12" align-content="center" justify="center">
            <v-card
              class="mx-auto pa-10"
              max-width="500"
              elevation="0"
              rounded="xl"
            >
              <div v-if="!completed">
                <div class="mb-5">
                  <span class="text-login"> ลืมรหัสผ่านใช่หรือไม่ </span>
                  <span>
                    <div>กรุณาระบุอีเมลของคุณ</div>
                  </span>
                </div>

                <v-text-field
                  class="custom-placeholer-color .v-label"
                  placeholder="อีเมล"
                  v-model="email"
                  outlined
                  rounded
                  dense
                  :disabled="loading"
                ></v-text-field>

                <v-btn
                  color="primary"
                  rounded
                  block
                  large
                  :loading="loading"
                  :disabled="loading"
                  @click="forgotPasswordAction()"
                >
                  <div class="font-weight-bold">รีเซ็ตรหัสผ่านทางอีเมล</div>
                </v-btn>
              </div>
              <div v-if="completed">
                <div class="mb-5">
                  <span class="text-login"> ส่งลิงค์เปลี่ยนรหัสผ่านแล้ว </span>
                  <span>
                    <div>
                      เราได้ส่งลิงค์สำหรับเปลี่ยนรหัสผ่านใหม่ไปที่อีเมล์
                      {{
                        email
                      }}
                      แล้ว<br />กรุณาตรวจสอบอีเมล์และทำการคลิกลิงค์เพื่อทำการเปลี่ยนรหัสผ่าน<br />หากไม่พบอีเมล์
                      กรุณาตรวจสอบใน "กล่องขยะ หรือ สแปม" หรือติดต่อผู้ดูแลระบบ
                    </div>
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { forgotPassword } from "@/services/api/user";
export default {
  name: "forgotPassword",

  data() {
    return {
      email: "",
      loading: false,
      completed: false,
    };
  },

  methods: {
    async forgotPasswordAction() {
      this.loading = true;
      const result = await forgotPassword(this.$store, this.email);
      if (result.code == 200) {
        this.completed = true;
      }
      this.loading = false;
    },
  },
};
</script>
<style lang=""></style>
