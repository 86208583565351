<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="800px" persistent>
      <v-card v-if="show">
        <v-sheet height="60" color="primary" dark>
          <v-card-title class="mx-2">
            <div style="font-size: 16px">แก้ไขใบตรวจสภาพ</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancel()">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-sheet>

        <v-divider></v-divider>

        <v-card-text v-if="fetchDataError === 0">
          <v-card-subtitle class="mt-3 pb-3">
            <v-row no-gutters class="mb-3">
              <v-col cols="9">
                <v-row no-gutters class="px-0 pb-3">
                  <p
                    class="ma-0 text-app-detail-large font-weight-bold black--text"
                  >
                    {{ vehicleCheckListForm.vehicleLicenseNumber }}
                  </p>
                  <span
                    class="text-app-small-detail pl-6"
                    v-if="vehicleCheckListForm.vehicleLicenseTypeKey"
                  >
                    <span class="mx-0 px-0">
                      <v-icon
                        v-if="
                          vehicleCheckListForm.vehicleLicenseTypeKey == 'WHITE'
                        "
                        >mdi-card-text-outline</v-icon
                      >
                      <v-icon
                        v-else
                        :color="
                          vehicleLicenseTypeColor(
                            vehicleCheckListForm.vehicleLicenseTypeKey
                          )
                        "
                        >mdi-card-text</v-icon
                      >
                    </span>
                    {{ vehicleCheckListForm.vehicleLicenseType }}</span
                  >
                  <span
                    class="text-app-small-detail pl-6"
                    v-if="vehicleCheckListForm.vehicleSeat"
                  >
                    <span class="mx-0 px-0">
                      <v-icon>mdi-car-side</v-icon>
                      {{ vehicleCheckListForm.vehicleType }}
                    </span>
                    {{ vehicleCheckListForm.vehicleSeat }} ที่นั่ง</span
                  >
                </v-row>
                <v-row no-gutters class="px-0 pb-3">
                  <span
                    class="text-app-normal mr-6"
                    v-if="vehicleCheckListForm.createdDate"
                  >
                    <v-icon class="mr-1">mdi-folder-plus-outline</v-icon
                    >สร้างเมื่อ:
                    {{
                      vehicleCheckListForm.createdDate
                        ? dateStringToDateFormat(
                            vehicleCheckListForm.createdDate,
                            { type: "datetime" }
                          )
                        : "-"
                    }}
                  </span>
                  <span
                    class="text-app-normal mr-6"
                    v-if="vehicleCheckListForm.updatedDate"
                  >
                    <v-icon class="mr-1">mdi-pencil-outline</v-icon>อัพเดทเมื่อ:
                    {{
                      vehicleCheckListForm.updatedDate
                        ? dateStringToDateFormat(
                            vehicleCheckListForm.updatedDate,
                            { type: "datetime" }
                          )
                        : "-"
                    }}
                  </span>
                </v-row>
                <v-row
                  no-gutters
                  class="px-0"
                  v-if="vehicleCheckListForm.group"
                >
                  <span class="text-app-normal">
                    <v-icon class="mr-1">mdi-format-list-bulleted-type</v-icon
                    >ประเภทใบตรวจ: {{ vehicleCheckListForm.group }}
                  </span>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-menu offset-y v-if="vehicleCheckListForm.status">
                <template v-slot:activator="{ props, on }">
                  <v-card
                    v-bind="props"
                    v-on="on"
                    outlined
                    :color="
                      vehicleCheckListStatusCardColor(
                        vehicleCheckListForm.status
                      )
                    "
                    class="px-0"
                    height="34"
                    width="130"
                  >
                    <div class="status_text_style white--text">
                      {{
                        vehicleCheckListStatusText(vehicleCheckListForm.status)
                      }}
                    </div>
                  </v-card>
                </template>

                <v-list>
                  <v-card
                    elevation="0"
                    :disabled="
                      !isPermissionCanView ||
                      !isPermissionCanViewInfo ||
                      !isPermissionCanEdit
                    "
                  >
                    <v-list-item
                      v-for="(status, idx) in _vehicleCheckListStatusText"
                      :key="idx"
                      :value="idx"
                      @click="
                        updateCheckListStatus(vehicleCheckListForm._id, idx)
                      "
                      :disabled="vehicleCheckListForm.status == idx"
                    >
                      <v-list-item-title
                        :class="`text-app-title ${vehicleCheckListStatusCardColorDisabled(
                          idx,
                          vehicleCheckListForm.status
                        )}`"
                      >
                        {{ status }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-card>
                </v-list>
              </v-menu>
            </v-row>
          </v-card-subtitle>
          <div
            class="mx-4"
            v-if="vehicleCheckListForm.note || vehicleCheckListForm.images"
          >
            <v-divider
              v-if="
                vehicleCheckListForm.note ||
                vehicleCheckListForm.images.length > 0
              "
            />
          </div>
          <div v-if="vehicleCheckListForm.note || vehicleCheckListForm.images">
            <v-card-subtitle
              class="py-3"
              v-if="
                vehicleCheckListForm.note ||
                vehicleCheckListForm.images.length > 0
              "
            >
              <v-row
                no-gutters
                class="px-0 my-0"
                v-if="vehicleCheckListForm.note"
              >
                <span class="text-app-title">
                  {{ vehicleCheckListForm.note }}
                </span>
              </v-row>
              <v-row
                no-gutters
                class="mx-0 px-0 pt-3"
                v-if="vehicleCheckListForm.images"
              >
                <v-row
                  dense
                  class="px-0 mb-3"
                  v-if="vehicleCheckListForm.images.length > 0"
                >
                  <v-col cols="12">
                    <ImageDataImprove
                      ref="vehicleCRUDUploadImageFile"
                      component-name="vehicleCRUDUploadImageFileForm"
                      title=""
                      :isImageType="true"
                      :uploaded-data="vehicleCheckListForm.images"
                      :is-permission-can-edit="!isPermissionCanEdit"
                      uploadTag="VEHICLE_CHECK_LIST"
                    />
                  </v-col>
                </v-row>
              </v-row>
            </v-card-subtitle>
          </div>
          <v-divider
            class="mx-4 mb-3"
            v-if="vehicleCheckListForm.checkerEmpId"
          />
          <v-card-subtitle class="mt-0 pt-0">
            <v-row no-gutters class="mb-3">
              <v-col cols="6">
                <span
                  v-if="vehicleCheckListForm.checkerEmpId"
                  class="text-app-title font-weight-bold"
                  >ผู้ตรวจ</span
                >
                <v-row no-gutters>
                  <v-list-item-avatar size="60" class="mr-3">
                    <v-img
                      :src="
                        vehicleCheckListForm.checkerProfileImage === null
                          ? defaultPFImage
                          : vehicleCheckListForm.checkerProfileImage
                      "
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div>
                      <v-list-item-title
                        class="text-app-detail-large font-weight-bold"
                      >
                        {{
                          `${
                            vehicleCheckListForm.checkerSalutation === null
                              ? ""
                              : vehicleCheckListForm.checkerSalutation.th
                          }${
                            vehicleCheckListForm.checkerFirstName === null
                              ? ""
                              : vehicleCheckListForm.checkerFirstName.th
                          } ${
                            vehicleCheckListForm.checkerLastName === null
                              ? ""
                              : vehicleCheckListForm.checkerLastName.th
                          } `
                        }}
                        {{
                          vehicleCheckListForm.checkerNickName !== null
                            ? vehicleCheckListForm.checkerNickName.th !== null
                              ? `(${vehicleCheckListForm.checkerNickName.th})`
                              : ""
                            : ""
                        }}</v-list-item-title
                      >
                      <span
                        class="text-app-normal"
                        v-if="vehicleCheckListForm.checkerEmpId"
                      >
                        {{ vehicleCheckListForm.checkerEmpId }}
                      </span>
                      <span
                        class="text-app-normal"
                        v-if="
                          vehicleCheckListForm.checkerEmpId &&
                          vehicleCheckListForm.checkerPosition
                        "
                      >
                        |
                      </span>
                      <span
                        class="text-app-normal"
                        v-if="vehicleCheckListForm.checkerPosition"
                      >
                        {{ vehicleCheckListForm.checkerPosition }}
                      </span>
                    </div>
                    <span
                      class="text-app-normal"
                      v-if="vehicleCheckListForm.checkerEmail"
                      >อีเมล: {{ vehicleCheckListForm.checkerEmail }}</span
                    >
                    <div v-if="vehicleCheckListForm.checkerPhoneNumber">
                      <span class="text-app-normal"> โทร: </span>
                      <span
                        class="text-app-normal"
                        v-for="(
                          number, idx
                        ) in vehicleCheckListForm.checkerPhoneNumber"
                        :key="idx"
                      >
                        {{ number }}
                        <span
                          v-if="
                            idx <
                            vehicleCheckListForm.checkerPhoneNumber.length - 1
                          "
                          >,</span
                        >
                      </span>
                    </div>
                  </v-list-item-content>
                </v-row>
              </v-col>
              <v-divider
                vertical
                v-if="
                  vehicleCheckListForm.closerEmpId &&
                  vehicleCheckListForm.status == 'CLOSED'
                "
              />
              <v-col
                cols="6"
                class="px-6"
                v-if="
                  vehicleCheckListForm.closerEmpId &&
                  vehicleCheckListForm.status == 'CLOSED'
                "
              >
                <span class="text-app-title font-weight-bold">ผู้ปิด</span>
                <v-row no-gutters>
                  <v-list-item-avatar size="60" class="mr-3">
                    <v-img
                      :src="
                        vehicleCheckListForm.closerProfileImage === null
                          ? defaultPFImage
                          : vehicleCheckListForm.closerProfileImage
                      "
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div>
                      <v-list-item-title
                        class="text-app-detail-large font-weight-bold"
                      >
                        {{
                          `${
                            vehicleCheckListForm.closerSalutation === null
                              ? ""
                              : vehicleCheckListForm.closerSalutation.th
                          }${
                            vehicleCheckListForm.closerFirstName === null
                              ? ""
                              : vehicleCheckListForm.closerFirstName.th
                          } ${
                            vehicleCheckListForm.closerLastName === null
                              ? ""
                              : vehicleCheckListForm.closerLastName.th
                          } `
                        }}
                        {{
                          vehicleCheckListForm.closerNickName !== null
                            ? vehicleCheckListForm.closerNickName.th !== null
                              ? `(${vehicleCheckListForm.closerNickName.th})`
                              : "-"
                            : "-"
                        }}</v-list-item-title
                      >
                      <span
                        class="text-app-normal"
                        v-if="vehicleCheckListForm.closerEmpId"
                      >
                        {{ vehicleCheckListForm.closerEmpId }}
                      </span>
                      <span
                        class="text-app-normal"
                        v-if="
                          vehicleCheckListForm.closerEmpId &&
                          vehicleCheckListForm.closerPosition
                        "
                      >
                        |
                      </span>
                      <span
                        class="text-app-normal"
                        v-if="vehicleCheckListForm.closerPosition"
                      >
                        {{ vehicleCheckListForm.closerPosition }}
                      </span>
                    </div>
                    <span
                      class="text-app-normal"
                      v-if="vehicleCheckListForm.closerEmail"
                      >อีเมล: {{ vehicleCheckListForm.closerEmail }}</span
                    >
                    <div v-if="vehicleCheckListForm.closerPhoneNumber">
                      <span class="text-app-normal"> โทร: </span>
                      <span
                        class="text-app-normal"
                        v-for="(
                          number, idx
                        ) in vehicleCheckListForm.closerPhoneNumber"
                        :key="idx"
                      >
                        {{ number }}
                        <span
                          v-if="
                            idx <
                            vehicleCheckListForm.closerPhoneNumber.length - 1
                          "
                          >,</span
                        >
                      </span>
                    </div>
                  </v-list-item-content>
                </v-row>
              </v-col>
            </v-row>

            <div
              v-if="vehicleCheckListForm.checkItems.length > 0"
              class="pb-0 list_main px-0 mx-0"
            >
              <v-sheet
                class="px-0 mx-0"
                v-for="(checkItem, itemIdx) in vehicleCheckListForm.checkItems"
                :key="itemIdx"
              >
                <!-- <v-sheet class="mb-4" width="100%"> -->
                <v-divider />
                <v-row no-gutters class="mx-0 px-0 mt-3 mb-0 pb-0">
                  <v-col cols="9" class="text-app-title font-weight-bold pt-0">
                    <span class="text-app-normal mt-0 mb-1"
                      >{{ itemIdx + 1 }} |
                    </span>
                    {{ `${checkItem.name === null ? "" : checkItem.name}` }}
                  </v-col>
                  <v-spacer></v-spacer>

                  <VehicleCheckListItemMenuBtn
                    :disabled="
                      !isPermissionCanView ||
                      !isPermissionCanViewInfo ||
                      !isPermissionCanEdit
                    "
                    :id="id"
                    :check-item="checkItem"
                    :check-item-idx="itemIdx"
                  />
                  <!-- <v-menu
                    :disabled="
                      !isPermissionCanView ||
                      !isPermissionCanViewInfo ||
                      !isPermissionCanEdit
                    "
                  >
                    <template v-slot:activator="{ props, on }">
                      <v-btn
                        outlined
                        v-bind="props"
                        v-on="on"
                        small
                        :color="
                          vehicleCheckListItemStatusCardColor(checkItem.status)
                        "
                        :loading="checkListLoading[checkItem.itemId]"
                      >
                        <div>
                          {{ vehicleCheckListItemStatusText(checkItem.status) }}
                        </div>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(
                          statusName, statusKey
                        ) in _vehicleCheckListItemStatusText"
                        :key="`${itemIdx}-itemOptions-${statusKey}`"
                        :value="statusKey"
                        @click="
                          updateCheckListItemStatus(
                            itemIdx,
                            checkItem,
                            statusKey
                          )
                        "
                        :disabled="checkItem.status == statusKey"
                      >
                        <v-list-item-title
                          :class="`text-app-title ${vehicleCheckListItemStatusCardColorDisabled(
                            statusKey,
                            checkItem.status
                          )}`"
                        >
                          {{ statusName }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu> -->
                </v-row>
                <v-row no-gutters class="mx-0 px-0">
                  <v-col cols="12" class="py-0">
                    <div>
                      <p class="text-app-normal">
                        {{ `${checkItem.note === null ? "" : checkItem.note}` }}
                        <!-- รายละเอียดแต่ละข้อ -->
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mx-0 px-0" v-if="checkItem.images">
                  <v-row
                    dense
                    class="px-0 mb-5"
                    v-if="checkItem.images.length > 0"
                  >
                    <v-col cols="12">
                      <ImageDataImprove
                        ref="vehicleItemCRUDUploadImageFile"
                        component-name="vehicleItemCRUDUploadImageFileForm"
                        title=""
                        :isImageType="true"
                        :uploaded-data="checkItem.images"
                        :is-permission-can-edit="!isPermissionCanEdit"
                        uploadTag="VEHICLE_CHECK_LIST_ITEM"
                      />
                    </v-col>
                  </v-row>
                </v-row>
              </v-sheet>
            </div>
          </v-card-subtitle>
        </v-card-text>

        <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
          กำลังดึงข้อมูล...
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
    <AppDialogConfirm ref="confirmDelete" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import ImageDataImprove from "@/components/ImageDataImprove.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  getVehicleCheckListInfoService,
  putVehicleCheckListService,
} from "@/services/api/vehicle_checklist";
import VehicleCheckListItemMenuBtn from "./components/VehicleCheckListItemMenuBtn.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import { mapGetters } from "vuex";

export default {
  name: "vehicleCheckListDetail",

  components: {
    PrimaryButtonVue,
    CustomTextareaImprove,
    ImageDataImprove,
    AppDialogConfirm,
    AppOverlay,
    VehicleCheckListItemMenuBtn,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,
      componentRefreshCounter: 0,
      filterSelected: [],
      extraIncomePaymentTotal: 0,
      extraOutcomePaymentTotal: 0,
      componentLoading: false,
      isWaitToUploadAttachFiles: false,
      isWaitToUploadAttachImages: false,
      financialDocURL: null,
      paymentListIncome: 0,
      paymentListOutcome: 0,
      currentVatPercent: 0,
      currentBrokerPercent: 0,
      resturnWorksheetObj: null,
      defaultPFImage: defaultImage,
      worksheetOriStatus: "NOT_PAID",

      vehicleCheckListForm: {
        checkItems: [],
        checkerSalutation: null,
        checkerFirstName: null,
        checkerLastName: null,
        checkerNickName: null,
        checkerEmpId: null,
        checkerPosition: null,
        checkerEmail: null,
        checkerPhoneNumber: null,

        closerSalutation: null,
        closerFirstName: null,
        closerLastName: null,
        closerNickName: null,
        closerEmpId: null,
        closerPosition: null,
        closerEmail: null,
        closerPhoneNumber: null,

        createdDate: null,
        updatedDate: null,
        vehicleLicenseNumber: null,
        vehicleType: null,
        vehicleLicenseType: null,
        vehicleSeat: null,

        note: null,
        images: [],
      },
      checkListLoading: {},
      fetchDataError: 0,
      formData: {},
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {
        admin_all: false,
      },

      isPermissionCanEdit: true,
      isPermissionCanView: true,
      isPermissionCanViewInfo: true,
    };
  },

  watch: {},

  computed: {
    ...mapGetters({
      vehicleCheckListStatusColor:
        "vehicle_checklist/vehicleCheckListStatusColor",
      vehicleCheckListStatusCardColor:
        "vehicle_checklist/vehicleCheckListStatusCardColor",
      vehicleCheckListStatusText:
        "vehicle_checklist/vehicleCheckListStatusText",
      vehicleCheckListItemStatusCardColor:
        "vehicle_checklist/vehicleCheckListItemStatusCardColor",
      vehicleCheckListItemStatusText:
        "vehicle_checklist/vehicleCheckListItemStatusText",
    }),
    _vehicleCheckListStatusText: function () {
      return this.$store.state.vehicle_checklist.vehicleCheckListStatusText;
    },
    _vehicleCheckListItemStatusText: function () {
      return this.$store.state.vehicle_checklist.vehicleCheckListItemStatusText;
    },
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.vehicleCheckListForm = {
        checkItems: [],
        checkerSalutation: null,
        checkerFirstName: null,
        checkerLastName: null,
        checkerNickName: null,
        checkerEmpId: null,
        checkerPosition: null,
        checkerEmail: null,
        checkerPhoneNumber: null,

        closerSalutation: null,
        closerFirstName: null,
        closerLastName: null,
        closerNickName: null,
        closerEmpId: null,
        closerPosition: null,
        closerEmail: null,
        closerPhoneNumber: null,

        createdDate: null,
        updatedDate: null,
        vehicleLicenseNumber: null,
        vehicleType: null,
        vehicleLicenseType: null,
        vehicleSeat: null,

        note: null,
        images: [],
      };
      this.checkListLoading = {};
      this.id = null;
      this.isNotEdited = true;
    },

    open(_id) {
      this.resetFormData();
      this.id = _id;
      this.getData();
      // this.checkUserPermissions();
      // if (!_.isNil(id)) {
      //   this.setFormData(id);
      // }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getData() {
      this.fetchDataError = 2;
      if (!_.isNil(this.id)) {
        const responseData = await getVehicleCheckListInfoService(
          this.$store,
          this.id
        );

        if (responseData["code"] === 200) {
          let _vehicleCheckListForm = _.clone(responseData["data"]);
          for (let i = 0; i < _vehicleCheckListForm.checkItems.length; i++) {
            this.checkListLoading[
              _vehicleCheckListForm.checkItems[i]["itemId"]
            ] = false;
          }
          this.vehicleCheckListForm = _vehicleCheckListForm;
        }
      }
      this.fetchDataError = 0;
    },

    async updateCheckListStatus(_id, status) {
      this.componentLoading = true;
      const sendingFormData = {
        status: status,
      };
      if (!_.isNil(status)) {
        let respData = await putVehicleCheckListService(
          this.$store,
          _id,
          sendingFormData
        );
        if (respData["code"] === 200) {
          this.vehicleCheckListForm["status"] = status;
          if (this.vehicleCheckListForm["status"] === "CLOSED") {
            this.cancel();
          }
        } else {
          // insert fail
        }
      }
      this.componentLoading = false;
    },

    handleItemStatus(itemIdx) {
      let itemStatus =
        this.vehicleCheckListForm["checkItems"][itemIdx]["status"];
      if (!_.isNil(itemStatus)) {
        if (itemStatus === "CHECKED") {
          this.vehicleCheckListForm["checkItems"][itemIdx]["status"] =
            "NOT_CHECKED";
        } else {
          this.vehicleCheckListForm["checkItems"][itemIdx]["status"] =
            "CHECKED";
        }
      }
    },

    cancel() {
      if (!_.isNil(this.id) && !_.isNil(this.vehicleCheckListForm["status"])) {
        let returnedData = {
          id: this.id,
          status: this.vehicleCheckListForm["status"],
        };
        this.resolve(returnedData);
      } else {
        this.resolve(null);
      }
      // this.resolve(null);
      this.show = false;
      // this.resetFormData();
    },

    vehicleLicenseTypeColor: function (licenseType) {
      return this.$store.getters["vehicle/vehicleLicenseTypeColor"](
        licenseType
      );
    },

    vehicleCheckListStatusCardColorDisabled(status, disabledStatus) {
      if (status === disabledStatus) {
        return "borderColor--text";
      } else {
        return `${this.vehicleCheckListStatusCardColor(status)}--text`;
      }
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getPriceColor(number) {
      return number < 0 ? "#FF6745" : "#FFFFFF";
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        // this.permList = processPermission(getPermission, "PAYMENT");
        // if (this.permList.payment_report === true) {
        //   this.isPermissionCanView = true;
        //   this.isPermissionCanViewInfo = true;
        // }

        // if (
        //   this.permList.admin_all === true ||
        //   this.permList.payment_crud === true
        // ) {
        //   this.isPermissionCanView = true;
        //   this.isPermissionCanEdit = true;
        //   this.isPermissionCanViewInfo = true;
        // }
        //
      }
    },
  },
};
</script>

<style scoped>
.status_text_style {
  font-size: 16px;
  /* font-weight: 600; */
  /* color: #ffffff; */
  text-align: center;
  padding-top: 4px;
}
.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.table_header_text_style {
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
