<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="colorBackgroudTopBar" dense flat>
        <v-toolbar-title class="mx-2">
          <v-icon :color="colorTitle" class="mt-1 mr-2" v-if="icon">{{
            icon
          }}</v-icon>
          <span
            :style="{
              color: colorTitle,
              fontSize: 14 + 'px',
              fontWeight: 'bold',
            }"
          >
            {{ textHeader }}
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div
          class="mt-5"
          :style="{
            color: colorTitle,
            fontSize: 18 + 'px',
            fontWeight: 'bold',
          }"
        >
          {{ title }}
        </div>
        <div
          class="mt-1"
          :style="{
            fontSize: 12 + 'px',
            color: $vuetify.theme.themes.light.bodyText,
          }"
        >
          {{ message }}
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="options.noconfirm"
          @click.native="cancel"
          text
          class="mr-2"
        >
          แก้ไขข้อมูล
        </v-btn>
        <v-btn
          :color="colorButtonSubmit"
          @click.native="agree"
          dark
          elevation="0"
        >
          ส่งใหม่อีกครั้ง
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DialogType } from "@/services/dialog";

export default {
  name: "AppDialogReloadForm",

  components: {},

  data: () => ({
    type: DialogType.ERROR,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      width: 400,
      zIndex: 200,
      noconfirm: false,
    },
  }),

  computed: {
    colorTitle() {
      switch (this.type) {
        case DialogType.DEFAULT:
          return this.$vuetify.theme.themes.light.title;
        case DialogType.POSITIVE:
          return this.$vuetify.theme.themes.light.success;
        case DialogType.ERROR:
          return this.$vuetify.theme.themes.light.error;
        case DialogType.ACTION:
          return this.$vuetify.theme.themes.light.primary;
      }
    },

    colorBackgroudTopBar() {
      switch (this.type) {
        case DialogType.DEFAULT:
          return this.addAlpha(this.$vuetify.theme.themes.light.title, 0.2);
        case DialogType.POSITIVE:
          return this.addAlpha(this.$vuetify.theme.themes.light.success, 0.2);
        case DialogType.ERROR:
          return this.addAlpha(this.$vuetify.theme.themes.light.error, 0.2);
        case DialogType.ACTION:
          return this.addAlpha(this.$vuetify.theme.themes.light.primary, 0.2);
      }
    },

    colorButtonSubmit() {
      switch (this.type) {
        case DialogType.DEFAULT:
          return this.$vuetify.theme.themes.light.primary;
        case DialogType.POSITIVE:
          return this.$vuetify.theme.themes.light.success;
        case DialogType.ERROR:
          return this.$vuetify.theme.themes.light.error;
        case DialogType.ACTION:
          return this.$vuetify.theme.themes.light.primary;
      }
    },

    textHeader() {
      switch (this.type) {
        case DialogType.DEFAULT:
          return "การแจ้งเตือนข้อความ";
        case DialogType.POSITIVE:
          return "สำเร็จ";
        case DialogType.ERROR:
          return "เกิดข้อผิดพลาด";
        case DialogType.ACTION:
          return "แจ้งการตกลง";
      }
    },

    icon() {
      switch (this.type) {
        case DialogType.DEFAULT:
          return null;
        case DialogType.POSITIVE:
          return "mdi-check-circle";
        case DialogType.ERROR:
          return "mdi-alert-circle";
        case DialogType.ACTION:
          return null;
      }
    },
  },

  methods: {
    addAlpha(color, opacity) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },

    open(title, message, type, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.type = type;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve("RELOAD");
      this.dialog = false;
    },

    cancel() {
      this.resolve("CANCEL");
      this.dialog = false;
    },
  },
};
</script>
