const checkEmptyValueHelpper = (value) => {
  return value === null || value === undefined;
};
const checkEmptyInputValueHelpper = (value) => {
  return value === null || value === undefined || value === "";
};

const convertToDateTimeForRequestData = (value) => {
  // the value not second number and space
  if (value === null) {
    return null;
  }

  const splitVal = value.split("T");
  if (splitVal.length !== 2) {
    return null;
  }
  // 1 = time
  if (splitVal[1].length === 5) {
    return `${splitVal[0]}T${splitVal[1]}:00`;
  }
  return `${splitVal[0]}T${splitVal[1]}`;
};

const processDistanceText = (data) => {
  return !checkEmptyValueHelpper(data)
    ? `${data.value} ${data.unit === "m" ? "ม" : "กม"}`
    : "-";
};

const processDurationText = (data) => {
  return !checkEmptyValueHelpper(data)
    ? `${data.value} ${data.unit === "s" ? "วินาที" : "นาที"}`
    : "-";
};

const processDurationObject = (_second) => {
  if (!checkEmptyValueHelpper(_second)) {
    if (_second < 60) {
      if (_second < 10) {
        return { value: `0${_second}`, unit: "s" };
      }
      return { value: `${_second}`, unit: "s" };
    } else {
      return {
        value:
          Math.floor(_second / 60) +
          ":" +
          (_second % 60 ? Math.ceil(_second % 60) : "00"),
        unit: "m",
      };
    }
  }
  return null;
};

const processDistanceObject = (_distance) => {
  if (!checkEmptyValueHelpper(_distance)) {
    if (_distance > 1000) {
      return { value: `${(_distance / 1000).toFixed(2)}`, unit: "km" };
    } else {
      return {
        value: `${_distance.toFixed(2)}`,
        unit: "m",
      };
    }
  }
  return null;
};

const processMetertoKmDistanceCeil = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    return data / 1000;
  } else {
    return null;
  }
};

const processSecondtoMinuteDurationCeil = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    if (data < 60) {
      return (data % 60) * 0.01;
    } else {
      return Math.ceil(data / 60);
    }
  } else {
    return null;
  }
};

const processKmtoMeterDistance = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    return data * 1000;
  } else {
    return null;
  }
};

const processMinuteToSecondDuration = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    if (data < 1) {
      return data / 0.01;
    } else {
      return data * 60;
    }
  } else {
    return null;
  }
};

const processDurationCeilHelper = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    return processKmtoMeterDistance(processMetertoKmDistanceCeil(data));
  } else {
    return null;
  }
};

const processDistanceCeilHelper = (data) => {
  if (!checkEmptyValueHelpper(data)) {
    return processMinuteToSecondDuration(
      processSecondtoMinuteDurationCeil(data)
    );
  } else {
    return null;
  }
};

module.exports = {
  processDistanceText,
  processDurationText,
  processDurationObject,
  processDistanceObject,
  checkEmptyValueHelpper,
  checkEmptyInputValueHelpper,
  convertToDateTimeForRequestData,
  processMetertoKmDistanceCeil,
  processSecondtoMinuteDurationCeil,
  processKmtoMeterDistance,
  processMinuteToSecondDuration,
  processDurationCeilHelper,
  processDistanceCeilHelper,
};
