<template>
	<v-container name="vehicleLive" fluid>
		<div>
			<v-row class="pa-0">
				<PrimaryButton
					:large="true"
					icon="mdi-plus"
					@on-click="handleAddLiveBtn()"
				>
					เพิ่มหน้าจอ
				</PrimaryButton>
			</v-row>
			<v-row class="pa-0">
				<v-col lg="12" md="12" sm="12" xs="12" v-if="focusData != null">
					<DeepPlayerCoreV2
						:cameraType="focusData.cameraType"
						:serialNumber="focusData.serialNumber"
						:playerName="focusData.playerName"
						:isFocus="true"
						:deletePlayerCallBack="deleteFocusedLive"
						ref="focusPlayer"
					></DeepPlayerCoreV2>
      			</v-col>
				<v-col xl="2" lg="3" md="4" sm="6" xs="12" v-for="(liveItem, idx) in liveList" :key="`normal-${idx}`">
					<DeepPlayerCoreV2
						v-if="!liveItem.focus"
						:cameraType="liveItem.cameraType"
						:serialNumber="liveItem.serialNumber"
						:playerName="liveItem.playerName"
						:listIndex="idx"
						:deletePlayerCallBack="deleteLiveAtIndex"
						:focusPlayerCallBack="focusLiveAtIndex"
					></DeepPlayerCoreV2>
      			</v-col>
			</v-row>
		</div>

		<v-dialog v-model="showAddLiveWindowDialog" max-width="300">
			<v-card>
				<v-card-title>
					เพิ่มหน้าจอถ่ายทอดสด
				</v-card-title>
				<v-card-text class="pt-4">
					<v-row>
					<AppComboboxVehicleSearching
						ref="AppAutoCompleteVehicleSearchingRef"
						v-model="vehicleSearch"
						:disabled="false"
						:isRequired="false"
					/>
				</v-row>
					<v-row>
						<div class="pl-3"><b>ชนิดกล้อง</b></div>
						<v-radio-group 
							class="pl-4"
							v-model="cameraType"
							row
						>
						<v-radio label="กล้องหน้า" value="F"></v-radio>
						<v-radio label="กล้องหลัง" value="I"></v-radio>
						</v-radio-group>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					
					<PrimaryButton
						:large="true"
						icon="mdi-plus"
						:disabled="addLiveLoading || disableAddActionBtn()"
						@on-click="handleAddLiveAction()"
					>
						เพิ่มหน้าจอ
					</PrimaryButton>

				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>

	import {
		getLiveSerialNumber,
	} from "@/services/api/vehicle";
	import _ from "lodash";
	
	export default {
		name: "customDeepPlayerV2",
		beforeCreate: function () {
			this.$options.components.DeepPlayerCoreV2 =
				require("@/components/DeepPlayerCoreV2.vue").default;
			this.$options.components.PrimaryButton =
				require("@/components/PrimaryButton.vue").default;
			this.$options.components.AppComboboxVehicleSearching =
				require("@/components/AppComboboxVehicleSearching.vue").default;
		},
		data: () => ({
			showAddLiveWindowDialog: false,
			addLiveLoading: false,
			cameraType: "F",
			vehicleSearch: null,
			focusData: null,
			liveList: [
			],
		}),

		mounted() {},

		watch: {},
		methods: {
			disableAddActionBtn(){
				if(this.vehicleSearch == null){
					return true;
				}
				return _.isNil(this.vehicleSearch['key']);
			},
			async handleAddLiveAction(){
				if(!_.isNil(this.vehicleSearch['key'])) {
					this.addLiveLoading = true;
					const result = await getLiveSerialNumber(this.$store, this.vehicleSearch['key']);
					if(result.code == "200"){
						this.liveList.push({
							serialNumber: result.data['serialNumber'],
							cameraType: this.cameraType,
							focus: false,
							playerName: this.vehicleSearch['licenseNumber']
						})
						this.showAddLiveWindowDialog = false;
						console.log(this.liveList);
					}
					this.addLiveLoading = false;
				}
			},
			async handleAddLiveBtn(){
				this.showAddLiveWindowDialog = true;
				this.cameraType = "F";
				this.vehicleSearch = null;
			},
			deleteLiveAtIndex(index){
				let value = this.liveList[index];
				this.liveList = this.liveList.filter(item => item !== value)
			},
			focusLiveAtIndex(index){
				this.focusData = this.liveList[index];
				this.$refs.focusPlayer.refreshAction();
			},
			deleteFocusedLive(){
				this.focusData = null;
			},
		},
		components: {},
	};
</script>

