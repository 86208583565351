+
<template>
  <v-combobox
    class="app-custom-input custom-placeholer-color-sm"
    v-model="valueInput"
    :items="itemList"
    :loading="loading"
    :search-input.sync="searchingAction"
    item-text="value"
    item-value="key"
    placeholder="ค้นหาและเลือกสัญญา"
    append-icon=""
    return-object
    filled
    dense
    hide-no-data
    hide-details
    flat
    outlined
    :multiple="false"
    :name="dataInputName"
    :disabled="disabled"
    :rules="rules"
  >
  </v-combobox>
</template>
<script>
import { getContractBySeachingService } from "@/services/api/contract";
import _ from "lodash";

export default {
  name: "AppAutoCompleteContractInJobsSearching",
  props: {
    value: {},
    dataInputName: {
      default: "jobContract",
      type: String,
    },
    itemValueKey: {
      default: "key",
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    itemList: [],
    loading: false,
    searchingAction: null,
    currentSearchText: "",
    searchCountTimer: null,
    firstInit: false,
  }),
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        if (_.isString(val) === false) {
          this.$emit("searchItemChange", val);
        }
      },
    },
  },
  watch: {
    searchingAction(val) {
      if (!_.isNil(val) && this.firstInit) {
        this.currentSearchText = val;
        if (val.length > 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1000);
        }
      }
      this.firstInit = true;
    },
  },
  methods: {
    async callApi(val) {
      let output = "input";
      let resp_data = await getContractBySeachingService(
        this.$store,
        val,
        output
      );
      if (resp_data.code === 200) {
        this.itemList = resp_data.data;
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },
    itemChange() {},

    // enter
  },
};
</script>
