<template>
  <CustomCardFormViewModern
    title="แนวโน้มเงินต่องวดที่ผ่อน และจำนวนรถที่ผ่อน"
    :is-show-title="true"
    :is-add-title-margin="true"
  >
    <apexchart
      type="line"
      height="250"
      :options="amountChartOptions"
      :series="seriesAmount"
    ></apexchart>

    <apexchart
      height="250"
      :options="countChartOptions"
      :series="seriesCount"
    ></apexchart>
  </CustomCardFormViewModern>
</template>

<script>
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import {
  displayNumberLocaleString,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";
export default {
  name: "InstallmentLineChart",
  props: {
    data: {
      required: true,
    },
  },
  computed: {
    amountChartOptions() {
      return {
        chart: {
          type: "line",

          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },

        colors: ["#567DF4"],
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.xAmountLabelList,
        },
        yaxis: {
          title: {
            text: "จำนวนเงิน (บาท)",
            offsetX: 0,
          },
          labels: {
            formatter: (value) => {
              return `${nFormatterHelper(value)}`;
            },
          },
        },
        annotations: {
          yaxis: this.annotationsAmountYaxis,
        },
      };
    },
    countChartOptions() {
      return {
        chart: {
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },
        colors: ["#AAB7B8"],
        grid: {
          show: false,
        },
        xaxis: {
          title: {
            text: "งวด (เดือน/ปี)",
            offsetX: 0,
          },
          categories: this.xCountLabelList,
        },
        yaxis: {
          title: {
            text: "จำนวนรถ",
            offsetX: 0,
          },
          labels: {
            formatter: (value) => {
              return `${numberWithCommasHelper(value)}`;
            },
          },
        },
      };
    },
  },
  data() {
    return {
      seriesAmount: [],
      seriesAmountAvg: 0,
      annotationsAmountYaxis: [],
      xAmountLabelList: [],
      //
      seriesCount: [],
      seriesAmountCount: 0,
      xCountLabelList: [],
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        if (!_.isNil(this.data["installmentAvg"])) {
          this.annotationsAmountYaxis = [
            {
              y: this.data["installmentAvg"],
              strokeDashArray: 1,
              borderWidth: 2,
              borderColor: "#775DD0",
              label: {
                text: `ค่าเฉลี่ยเงินผ่อน: ${displayNumberLocaleString(
                  this.data["installmentAvg"],
                  2
                )}`,
              },
            },
          ];
        }

        if (!_.isEmpty(this.data["installment"])) {
          const installmentData = this.data["installment"];
          let _installmentValue = [];
          let _countValue = [];
          let _xLabelList = [];
          for (let i = 0; i < installmentData.length; i++) {
            _installmentValue.push(installmentData[i]["amount"]);
            _xLabelList.push(
              `${installmentData[i]["month"]}/${installmentData[i]["year"]}`
            );
            _countValue.push(installmentData[i]["count"]);
          }
          this.xAmountLabelList = _xLabelList;
          this.xCountLabelList = _xLabelList;

          this.seriesAmount = [
            {
              name: "จำนวนเงินต่องวดที่ผ่อน",
              data: _installmentValue,
            },
          ];

          this.seriesCount = [
            {
              name: "จำนวนรถที่ผ่อน",
              data: _countValue,
            },
          ];
        }
      }
    },
  },
  components: {
    apexchart: VueApexCharts,
    CustomCardFormViewModern,
  },
  mounted() {
    this.processData();
  },
};
</script>
