<template>
  <v-dialog v-model="show" width="60%" persistent>
    <v-card v-if="show">
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>คัดลอกหลายตารางไปยังแผนงานใหม่</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="cancelAndCloseHandler">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 1">
        <v-row>
          <v-col cols="5">
            <p class="mb-1">ตารางเดินรถ</p>

            <v-checkbox
              class="checkbox-custom"
              label="เลือกทั้งหมด"
              dense
              hide-details
              v-model="selectAllVal"
              @click="selectAllHandler"
            >
            </v-checkbox>
            <v-divider></v-divider>

            <div style="height: 300px; overflow-y: auto">
              <v-checkbox
                class="checkbox-custom"
                v-for="(item, itemIdx) in tableSheetData"
                :key="`${itemIdx}-multiple-table-to-select`"
                v-model="selectTableSheetData"
                :label="`#${itemIdx + 1} ${item.contract.title} (${
                  item.contract.contractNumber
                })`"
                :value="item._id"
                dense
                hide-details
              ></v-checkbox>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <div class="d-flex flex-column">
              <v-date-picker
                v-model="selectedDate"
                multiple
                full-width
                no-title
                locale="th"
                :min="today"
              ></v-date-picker>
              <p></p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <!--  -->
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 0">
        กำลังดึงข้อมูล
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 1"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 1"
          color="primary"
          text
          @click="saveAndCloseHandler"
          :disabled="!disabledSaveBtn"
        >
          ตกลง
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputView from "@/components/InputView.vue";
import { postCopyMultipleTableToNewSheetServer } from "@/services/api/assign_multiple_job";
import _ from "lodash";

export default {
  name: "AssignMultipleCreateDuplicateTable",
  data: () => ({
    valid: null,
    resolve: null,
    reject: null,
    show: false,
    fetchDataError: 0,

    sheetId: null,
    tableSheetData: null,
    selectTableSheetData: [],
    selectedDate: [],
    selectAllVal: false,
  }),
  methods: {
    open(sheetId) {
      this.resetForm();
      this.sheetId = sheetId;
      this.show = true;
      this.loadDataFromStore();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    loadDataFromStore() {
      this.tableSheetData =
        _.values(this.$store.state["assignMultipleJob"]["tableSheetData"]) ||
        [];
      this.fetchDataError = 1;
    },
    selectAllHandler() {
      if (this.selectAllVal) {
        this.selectTableSheetData = _.map(this.tableSheetData, "_id");
      } else {
        this.selectTableSheetData = [];
      }
    },

    async save() {
      const requestBodyData = {
        sheetId: this.sheetId,
        tableId: this.selectTableSheetData,
        createDate: this.selectedDate,
      };

      const responseData = await postCopyMultipleTableToNewSheetServer(
        this.$store,
        requestBodyData
      );
      if (responseData["code"] === 200) {
        // force to create
        this.show = false;
        this.resolve(responseData["data"]);
      } else {
        this.fetchDataError = -1;
      }
    },
    async saveAndCloseHandler() {
      this.fetchDataError = 2;
      this.save();
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
    resetForm() {
      this.selectTableSheetData = [];
      this.selectedDate = [];
      this.fetchDataError = 0;
    },
  },
  watch: {
    selectAllVal: function () {
      console.log(this.selectAllVal);
    },
    selectTableSheetData: function () {
      if (this.selectTableSheetData.length === this.tableSheetData.length) {
        this.selectAllVal = true;
      } else {
        this.selectAllVal = false;
      }
    },
  },
  computed: {
    today() {
      return new Date().toISOString();
    },
    disabledSaveBtn() {
      return (
        this.selectTableSheetData.length > 0 && this.selectedDate.length > 0
      );
    },
  },
  components: {
    InputView,
  },
};
</script>

<style scoped>
.checkbox-custom {
  font-size: 20px !important;
}
</style>
