<template>
  <div name="customSelects" class="px-0 mx-0">
    <InputView :title="title" :isRequired="isRequired">
      <v-select
        v-model="valueInput"
        class="custom-placeholer-color"
        :items="items"
        :placeholder="placeholder"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        :rules="rules"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        dense
        outlined
        hide-no-data
      >
        <template slot="item" slot-scope="data">
          <v-row class="px-0 mx-0">
            <v-icon class="pr-1">{{ typeIcon[data.item.key] }}</v-icon>
            <span class="text-app-normal"> {{ data.item.value }}</span></v-row
          >
        </template>
        <template v-slot:selection="{ item }">
          <v-row class="px-0 mx-0">
            <v-icon class="pr-1">{{ typeIcon[item.key] }}</v-icon>
            <span class="text-app-normal"> {{ item.value }}</span></v-row
          >
        </template>
      </v-select>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "customSelectsArrOfIconObj",

  components: {
    InputView,
  },

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "value",
    },
    itemValue: {
      type: String,
      default: "key",
    },
    returnObject: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      typeIcon: {
        NO_DATA: null,
        OWNER: "mdi-cog-outline",
        DRIVER: "mdi-clipboard-account",
      },
    };
  },
  mounted() {},
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
  components: { InputView },
};
</script>
<style>
.text-select {
  font-size: 13px;
}
</style>
