import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const jobs = {
  namespaced: true,
  state: {
    jobsStatusColorValue: {
      PLANNING: "#2196F3",
      PREPARING: "#ed5555",
      CANCEL: "#B2C8DF",
      CONFIRM: "#567DF4",
      PROCESS: "#ff8c26",
      SUCCESS: "#49c496",
      CANCEL_DARK: "#4d5656",
      default: "#CACFD2",
      null: "white",
    },
    jobsStatusColor: {
      PLANNING: "info",
      PREPARING: "error",
      CANCEL: "secondary",
      CONFIRM: "primary",
      PROCESS: "warning",
      SUCCESS: "success",
      null: "white",
    },
    jobsStatusText: {
      PLANNING: "วางแผน",
      PREPARING: "รอยืนยัน", //
      CONFIRM: "รับงานแล้ว", //
      PROCESS: "กำลังทำงาน", //
      SUCCESS: "จบงาน",
      CANCEL: "ถูกยกเลิก",
    },
    driverAndVehicleStatusColorValue: {
      AVAILABLE: "#49c496",
      UNAVAILABLE: "#ed5555",
      default: "#CACFD2",
      null: "white",
    },
    driverAndVehicleStatusColor: {
      AVAILABLE: "success",
      UNAVAILABLE: "error",
      null: "white",
    },
    driverAndVehicleStatusText: {
      AVAILABLE: "ว่าง",
      UNAVAILABLE: "ไม่ว่าง",
    },
    // for duplicate job
    jobData: null,
    refreshFormDuplicateCounter: 0,

    driverGroupsList: [],
    jobStatus: {
      PLANNING: "PLANNING",
      PREPARING: "PREPARING", //
      CONFIRM: "CONFIRM", //
      PROCESS: "PROCESS", //
      SUCCESS: "SUCCESS",
      CANCEL: "CANCEL",
    },
  },
  getters: {
    jobsStatusColorValue: (state) => (key) => state.jobsStatusColorValue[key],
    jobsStatusColor: (state) => (key) => state.jobsStatusColor[key],
    jobsStatusText: (state) => (key) => state.jobsStatusText[key],
    jobsStatusObj: (state) => state.jobsStatusText,
    jobsStatusKeyMapObj: (state) => state.jobStatus,

    driverAndVehicleStatusColorValue: (state) => (key) =>
      state.driverAndVehicleStatusColorValue[key],
    driverAndVehicleStatusColor: (state) => (key) =>
      state.driverAndVehicleStatusColor[key],
    driverAndVehicleStatusText: (state) => (key) =>
      state.driverAndVehicleStatusText[key],
  },
  mutations: {
    setJobData(state, payload) {
      state.jobData = { ...state.jobData, ...payload };
    },
    setRefreshFormDuplicateCounter(state) {
      state.refreshFormDuplicateCounter += 1;
    },
    setDriverGroupsList(state, payload) {
      state.driverGroupsList = payload;
    },
  },
  actions: {
    updateJobData(context, payload) {
      context.commit("setJobData", payload);
    },
    updateRefreshFormDuplicateCounter(context, payload) {
      context.commit("setRefreshFormDuplicateCounter");
    },
  },
  modules: {},
};
