<template>
  <div class="mx-3 my-3">
    <v-card width="100%" rounded="lg" elevation="5">
      <v-tabs v-model="tab" v-show="!isShowJobsForm">
        <v-tab class="text-app-title font-weight-bold" key="0">
          <div :class="tab == '0' ? 'primary--text' : 'black--text'">
            ข้อมูลงาน
          </div>
        </v-tab>

        <v-tooltip top :disabled="$store.state.manageRoute.isActivateForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-tab
                class="text-app-title font-weight-bold"
                key="1"
                :disabled="!$store.state.manageRoute.isActivateForm"
              >
                <v-icon
                  v-show="!$store.state.manageRoute.isActivateForm"
                  class="pr-1"
                  dense
                  >mdi-lock-outline</v-icon
                >
                <div :class="tab == '1' ? 'primary--text' : 'black--text'">
                  ค่าตอบแทน
                </div>
              </v-tab>
            </div>
          </template>
          <span>กรุณากรอกและบันทึกข้อมูลงาน</span>
        </v-tooltip>

        <v-tooltip top :disabled="$store.state.manageRoute.isActivateForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-tab
                class="text-app-title font-weight-bold"
                key="2"
                :disabled="!$store.state.manageRoute.isActivateForm"
              >
                <v-icon
                  v-show="!$store.state.manageRoute.isActivateForm"
                  class="pr-1"
                  dense
                  >mdi-lock-outline</v-icon
                >
                <div :class="tab == '2' ? 'primary--text' : 'black--text'">
                  ผู้ขับและรถ
                </div>
              </v-tab>
            </div>
          </template>
          <span>กรุณากรอกและบันทึกข้อมูลงาน</span>
        </v-tooltip>

        <v-tooltip top :disabled="$store.state.manageRoute.isActivateForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-tab
                class="text-app-title font-weight-bold"
                key="3"
                :disabled="!$store.state.manageRoute.isActivateForm"
              >
                <v-icon
                  v-show="!$store.state.manageRoute.isActivateForm"
                  class="pr-1"
                  dense
                  >mdi-lock-outline</v-icon
                >
                <div :class="tab == '3' ? 'primary--text' : 'black--text'">
                  ข้อมูลการเดินรถ
                </div>
              </v-tab>
            </div>
          </template>
          <span>กรุณากรอกและบันทึกข้อมูลงาน</span>
        </v-tooltip>
        <v-tooltip top :disabled="$store.state.manageRoute.isActivateForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-tab
                class="text-app-title font-weight-bold"
                key="4"
                :disabled="!$store.state.manageRoute.isActivateForm"
              >
                <v-icon
                  v-show="!$store.state.manageRoute.isActivateForm"
                  class="pr-1"
                  dense
                  >mdi-lock-outline</v-icon
                >
                <div :class="tab == '4' ? 'primary--text' : 'black--text'">
                  หลักฐาน
                </div>
              </v-tab>
            </div>
          </template>
        </v-tooltip>

        <v-tooltip top :disabled="$store.state.manageRoute.isActivateForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex">
              <v-tab
                class="text-app-title font-weight-bold"
                key="5"
                :disabled="!$store.state.manageRoute.isActivateForm"
              >
                <v-icon
                  v-show="!$store.state.manageRoute.isActivateForm"
                  class="pr-1"
                  dense
                  >mdi-lock-outline</v-icon
                >
                <div :class="tab == '5' ? 'primary--text' : 'black--text'">
                  ลูกค้า
                </div>
              </v-tab>
            </div>
          </template>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2">
              <v-btn
                v-show="$store.state.manageRoute.isActivateForm"
                v-bind="attrs"
                v-on="on"
                icon
                @click="isShowJobsForm = !isShowJobsForm"
              >
                <v-icon>{{
                  isShowJobsForm ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>ขยายดูฟอร์ม / ปิดฟอร์ม</span>
        </v-tooltip>
      </v-tabs>

      <v-divider v-show="!isShowJobsForm"></v-divider>
      <v-expand-transition>
        <v-tabs-items
          :class="isFullHeight ? `full-height-tab` : ``"
          v-model="tab"
          v-show="!isShowJobsForm"
        >
          <v-tab-item>
            <v-card flat height="220" rounded="0">
              <JobsDetailOnMapFloat />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="220" rounded="0">
              <RecompenseTab />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="220" rounded="0">
              <DriverDetailOnMapFloat />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="220" class="main-bg-color" rounded="0">
              <JobsProgressDetailOnMapFloat />
            </v-card>
          </v-tab-item>
          <v-tab-item class="main-bg-color">
            <JobsEvidenceDetailOnMapFloat />
          </v-tab-item>
          <v-tab-item class="main-bg-color">
            <AppCustomerDetailTab />
          </v-tab-item>
        </v-tabs-items>
      </v-expand-transition>
    </v-card>

    <v-expand-transition>
      <v-card
        v-show="isShowJobsForm"
        width="100%"
        rounded="lg"
        elevation="5"
        class="mb-6"
      >
        <v-row class="py-0 px-6">
          <div>
            <v-col class="text-app-normal pb-0">เวลายืนยันรับงาน</v-col>
            <v-col class="pt-0 font-weight-bold">
              {{ processDateToDisplay(jobData, "confirmDate") }}
            </v-col>
          </div>
          <v-divider class="my-2 mx-3" vertical />
          <div>
            <v-col class="text-app-normal pb-0">เวลาเริ่มงาน</v-col>
            <v-col class="pt-0 font-weight-bold">
              {{ processDateToDisplay(jobData, "startDate") }}
            </v-col>
          </div>
          <v-divider class="my-2 mx-3" vertical />
          <div>
            <v-col class="text-app-normal pb-0">เวลาจบงาน</v-col>
            <v-col class="pt-0 font-weight-bold">
              {{ processDateToDisplay(jobData, "endDate") }}
            </v-col>
          </div>
          <v-divider class="my-2 mx-3" vertical />
          <div>
            <v-col class="text-app-normal pb-0">เวลาจบงานโดยประมาณ</v-col>
            <v-col class="pt-0 font-weight-bold">
              {{ processDateToDisplay(jobData, "estimateEndDate") }}
            </v-col>
          </div>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-4 px-0">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="isShowJobsForm = !isShowJobsForm"
                >
                  <v-icon>{{
                    isShowJobsForm ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
            <span>ขยายดูฟอร์ม / ปิดฟอร์ม</span>
          </v-tooltip>
        </v-row>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import JobsDetailOnMapFloat from "./jobDetail/JobsDetailOnMapFloat.vue";
import DriverDetailOnMapFloat from "./driverAndVehicle/DriverDetailOnMapFloat.vue";
import JobsProgressDetailOnMapFloat from "./jobResults/JobsProgressDetailOnMapFloat.vue";
import JobsEvidenceDetailOnMapFloat from "./jobEvidence/JobsEvidenceDetailOnMapFloat.vue";
import RecompenseTab from "./recompense/RecompenseTab.vue";
import AppCustomerDetailTab from "./app-customer/AppCustomerDetailTab.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import _ from "lodash";

export default {
  data() {
    return {
      tab: 0,
      isShowJobsForm: false,
      isFullHeight: false,
    };
  },
  watch: {
    "$store.state.manageRoute.jobData": {
      immediate: false,
      handler(newVal) {
        this.processTabDisplay(newVal);
      },
    },

    tab: {
      immediate: false,
      handler(newVal) {
        if (newVal === 4) {
          this.isFullHeight = true;
        } else {
          this.isFullHeight = false;
        }
      },
    },
  },
  computed: {
    jobData: function () {
      return this.$store.getters["manageRoute/getJobData"] || null;
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    processDateToDisplay(_jobData, _key) {
      if (!_.isNil(_jobData)) {
        if (!_.isNil(_jobData[_key])) {
          return `${this.dateStringToDateFormat(_jobData[_key], {
            type: "datetime",
            returnThai: false,
          })} น.`;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    processTabDisplay(_jobData) {
      if (!_.isNil(_jobData)) {
        if (
          !_.isNil(_jobData["contract"]) &&
          !_.isNil(_jobData["name"]) &&
          !_.isNil(_jobData["confirmDate"]) &&
          !_.isNil(_jobData["startDate"])
        ) {
          this.tab = 1;
        }
        if (!_.isNil(_jobData["recompenseAmount"])) {
          this.tab = 2;
        }

        const _verified = _.get(
          this.$store,
          "state.manageRoute.jobData.verifiedVehicleId",
          null
        );

        if (
          !_.isNil(_jobData["driverFaceMatched"]) &&
          !_.isNil(_jobData["driverStatus"]) &&
          !_.isNil(_verified) &&
          (_jobData["status"] == "PROCESS" || _jobData["status"] == "SUCCESS")
        ) {
          this.tab = 3;
        }
      }
    },
  },

  components: {
    JobsDetailOnMapFloat,
    DriverDetailOnMapFloat,
    JobsProgressDetailOnMapFloat,
    JobsEvidenceDetailOnMapFloat,
    RecompenseTab,
    AppCustomerDetailTab,
  },
};
</script>
<style scoped>
.v-tabs-items.full-height-tab .v-window-item {
  height: calc(100vh - 120px); /* Adjust 270px to suits your needs */
  overflow-y: auto;
}
</style>
