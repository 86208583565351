<template>
  <div>
    <v-row no-gutters class="mt-6 ml-9">
      <v-col class="py-0 ma-0" cols="12" lg="4" sm="4">
        <CustomTextInputImprove
          v-model="driverFilter"
          ref="driverFilter"
          title=""
          :clearable="true"
          :hideDetail="true"
          :placeholder="`ค้นหาชื่อพนักงาน`"
          dataInputName="driverFilter"
          :expend-margin="false"
        />
      </v-col>
    </v-row>
    <div v-if="series.length > 0">
      <div v-if="series[0]['drivers'].length > 0">
        <apexchart
          type="bar"
          :height="getChartHeight"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-card-text class="pa-5 text-center" v-else>
        <span v-if="isItemChange">กำลังตรวจสอบรายชื่อ...</span>
        <span v-else>ไม่มีรายชื่อผู้ขับ...</span>
      </v-card-text>
    </div>
    <v-card-text class="pa-5 text-center" v-else>
      <span v-if="isItemChange">กำลังตรวจสอบรายชื่อ...</span>
      <span v-else>ไม่มีรายชื่อผู้ขับ...</span>
    </v-card-text>
    <SelectTableDialog ref="selectTableDialog" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { mapGetters } from "vuex";
import SelectTableDialog from "@/views/assign_multiple_job/AssignMultipleJobComponents/dialog/SelectTableDialog.vue";

export default {
  name: "CurrentDriverStatusChart",
  components: {
    apexchart: VueApexCharts,
    CustomTextInputImprove,
    SelectTableDialog,
  },
  props: {
    cellData: {
      type: Object,
      default: () => {},
    },
    refreshKey: {
      type: Number,
      required: true,
    },
    tableId: {
      type: String,
      required: false,
    },
    sheetTableData: {
      type: [Object, null],
      default: () => {},
    },
    selectedDriverList: {
      type: Array,
      default: () => [],
    },
    overlapJob: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    numUniqueRows: 0,
    barHeight: 15,
    categoryHeight: 40,
    initChartHeight: 130,
    maxXaxis: 0,
    driverFilter: null,
    seriesGraph: [],
    isItemChange: false,
    nextCellTitle: "",
    lastPriorityDriverName: null,
  }),
  mounted() {
    this.itemChange();
  },
  watch: {
    refreshKey: function (newValue) {
      this.itemChange();
    },

    driverFilter(empName) {
      if (!_.isNil(empName) && empName !== undefined && empName.length > 0) {
        this.isItemChange = true;
        this.series = [];
        setTimeout(() => {
          this.filterChartData(empName);
          this.isItemChange = false;
        }, 500);
      } else {
        this.isItemChange = true;
        this.series = [];
        setTimeout(() => {
          this.resetChartData();
          this.isItemChange = false;
        }, 500);
      }
    },
  },
  computed: {
    ...mapGetters({
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    series: {
      get() {
        return this.seriesGraph;
      },
      set(val) {
        this.seriesGraph = val;
      },
    },
    getChartHeight() {
      this.numUniqueRows = 0;
      this.numUniqueRows = this.jobs.length;
      if (!_.isNil(this.numUniqueRows)) {
        if (this.numUniqueRows === 1) {
          return this.initChartHeight * 2;
        }
        let height =
          this.numUniqueRows * this.categoryHeight + this.initChartHeight;
        return height;
      }
      return this.initChartHeight * 2;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          offsetX: 10,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          events: {
            click: (event, chartContext, config) => {
              let driverId =
                config.config.series[config.seriesIndex]["_id"][
                  config.dataPointIndex
                ];

              this.openSelectTable(driverId);
            },
          },
          // events: {
          //   click: (event, chartContext, config) => {
          //     try {
          //       let isAdded =
          //         config.config.series[config.seriesIndex]["isAdded"][
          //           config.dataPointIndex
          //         ];

          //       if (!isAdded) {
          //         let driverId =
          //           config.config.series[config.seriesIndex]["_id"][
          //             config.dataPointIndex
          //           ];
          //         this.itemChangeGraph(driverId);
          //       }
          //     } catch (error) {}
          //   },
          // },
        },
        // fill: {
        //   colors: [
        //     function ({ value, seriesIndex, dataPointIndex, w }) {
        //       console.log(value, seriesIndex, dataPointIndex, w);
        //       if (value !== null && value !== undefined) {
        //         let driverColor =
        //           w.config.series[seriesIndex]["fillColor"][dataPointIndex];
        //         if (driverColor !== null && driverColor !== undefined) {
        //           if (value < 0) {
        //             return driverColor[0];
        //           }
        //           return driverColor[1];
        //         }
        //       }
        //       return;
        //     },
        //   ],
        // },
        fill: { colors: ["#ed5555", "#567DF4"] },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: this.barHeight,
          },
        },
        grid: {
          // row: {
          //   colors: ["#fff", "#f2f2f2"],
          // },
          //       column: {
          //     colors: undefined,
          //     opacity: 0.5
          // },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        dataLabels: {
          enabled: true,
          offsetY: -7,
          // offsetX: -100,
          // textAnchor: "middle",
          formatter: (val, opts) => {
            let driverName = "";
            if (val >= 0) {
              try {
                driverName = this.categoryName[opts.dataPointIndex];

                if (driverName !== null && driverName !== undefined) {
                  // opts.w.config.series[opts.seriesIndex]["drivers"][
                  //   opts.dataPointIndex
                  // ];

                  // console.log("driverName", driverName, opts);
                  //
                  // const onCartDriver = _.get(
                  //   this.$store.state["assignMultipleJob"],
                  //   `driverInCart.${this.tableId}`,
                  //   null
                  // );

                  const driver = _.find(this.sumDriverStatOnSheet, {
                    fullName: driverName,
                  });

                  return `${driverName} | วิ่ง ${driver.jobs} ฟรี ${driver.free}`;
                }
                return;

                // if (!_.isNil(driver)) {
                //   return this.checkUserCellHasOverlap(driver["_id"])
                //     ? `[!!!] ${driverName} ${driver.driverCellStat.jobs} ${driver.driverCellStat.free}`
                //     : `${driverName} [${driver.driverCellStat.jobs}/${driver.driverCellStat.free}]`;
                // }
                //
              } catch (error) {
                console.error(error);
              }
            }
            return driverName;
          },
          style: {
            colors: [
              (opts) => {
                try {
                  let driverName =
                    opts.w.config.series[opts.seriesIndex]["drivers"][
                      opts.dataPointIndex
                    ];

                  // const onCartDriver = _.get(
                  //   this.$store.state["assignMultipleJob"],
                  //   `driverInCart.${this.tableId}`,
                  //   null
                  // );

                  const driver = _.find(this.sumDriverStatOnSheet, {
                    fullName: driverName,
                  });

                  return "#4d5656";

                  // if (!_.isNil(driver)) {
                  //   return this.checkUserJobHasOverlap(driver["_id"])
                  //     ? "#EF5350"
                  //     : "#4d5656";
                  // }
                } catch (error) {}
                return "#4d5656";
              },
            ],
            fontSize: "16px",
          },
        },
        yaxis: {
          min: -this.maxXaxis,
          max: this.maxXaxis,
          // min: Math.min(...this.jobFree),
          // max: Math.max(...this.jobWork),
          labels: {
            show: false,
            formatter: (value) => {
              return "";
            },
          },

          // title: {
          //   text: "Age",
          // },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val;
            },
          },
          y: {
            formatter: function (val) {
              if (val < 0) {
                return Math.abs(val + 1);
              }
              return Math.abs(val - 1);
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          offsetY: 40,
          markers: {
            fillColors: ["#ed5555", "#567DF4"],
          },
        },
        title: {
          text: "",
        },
        // legend: {
        //   position: "bottom",
        // },
        xaxis: {
          categories: this.categoryName,
          position: "top",
          labels: {
            offsetX: 0,
            offsetY: 5,
            formatter: function (val) {
              // return val;
              let posVal = Math.abs(Math.round(val));
              if (posVal > 0) {
                return posVal - 1;
              }
              return 0;
            },
          },
          // axisBorder: {
          //   show: true,
          //   // color: '#78909C',
          //   height: 1,
          //   width: "100%",
          //   offsetX: 0,
          //   offsetY: 0,
          // },
          // axisTicks: {
          //   show: true,
          //   borderType: "solid",
          //   // color: "#78909C",
          //   height: 6,
          //   offsetX: 0,
          //   offsetY: 0,
          // },
        },
        annotations: {
          yaxis: [
            {
              y: this.lastPriorityDriverName,
              borderColor: "#49c496",
              strokeDashArray: 2,
              offsetX: 0,
              offsetY: 15,
              width: this.lastPriorityDriverName ? "100%" : "0%",
              label: {
                textAnchor: "start",
                position: "left",
                borderColor: "#fff",
                offsetX: 15,
                offsetY: 10,
                style: {
                  fontSize: "15px",
                  color: "#49c496",
                },
                text: this.lastPriorityDriverName ? this.nextCellTitle : "",
              },
            },
          ],
        },
      };
    },
    // defaultDriverItems() {
    //   return _.get(
    //     this.$store.state["assignMultipleJob"],
    //     `driverInCart.${this.tableId}`,
    //     null
    //   );
    // },
  },
  methods: {
    // itemChangeGraph(driverId) {
    //   let driverAdding = [];
    //   driverAdding = this.defaultDriverItems.filter(
    //     (item) => item._id === driverId
    //   );
    //   if (driverAdding.length === 1) {
    //     // TODO:: emit to driver
    //     this.$emit("update-table-driver", driverAdding[0]);
    //   }
    // },
    itemChange() {
      this.isItemChange = true;
      this.series = [];
      setTimeout(() => {
        this.resetChartData();
        this.isItemChange = false;
      }, 500);
    },

    resetChartData() {
      this.jobs = [];
      // console.log("this.sumDriverStatOnSheet", this.sumDriverStatOnSheet);
      for (const [driverIdKey, driver] of Object.entries(
        this.sumDriverStatOnSheet
      )) {
        // console.log("driver", driver);
        const onCellWorkCount = driver.jobs;
        const onCellFreeCount = driver.free;
        const driverName = `${driver.dpNameTh} (${driver.nickName.th})`;
        this.jobs.push({
          work: driver["jobs"] + 1, // sheet
          free: -(driver["free"] + 1), // sheet
          cellWork: onCellWorkCount,
          cellFree: onCellFreeCount,
          name: driverName,
          isAdded: false,
          fillColor: ["#ed5555", "#567DF4"],
          _id: driver["_id"],
        });
      }

      this.series = [];
      if (this.jobs.length > 0) {
        this.series = this.getSeriesData();
      }
    },

    filterChartData(empName) {
      this.jobs = [];
      // console.log("this.sumDriverStatOnSheet", this.sumDriverStatOnSheet);
      for (const [driverIdKey, driver] of Object.entries(
        this.sumDriverStatOnSheet
      )) {
        // console.log("driver", driver);
        const driverName = `${driver.dpNameTh} (${driver.nickName.th})`;
        let searchPos = driverName.search(empName);
        if (searchPos !== -1) {
          const onCellWorkCount = driver.jobs;
          const onCellFreeCount = driver.free;
          this.jobs.push({
            work: driver["jobs"] + 1, // sheet
            free: -(driver["free"] + 1), // sheet
            cellWork: onCellWorkCount,
            cellFree: onCellFreeCount,
            name: driverName,
            isAdded: false,
            fillColor: ["#ed5555", "#567DF4"],
            _id: driver["_id"],
          });
        }
      }
      this.series = [];
      if (this.jobs.length > 0) {
        this.series = this.getSeriesData();
        // console.log("series:", this.series);
      }
    },

    getSeriesData() {
      let preparedSeries = [];
      this.maxXaxis = 0;

      if (this.jobs.length > 0) {
        let sortedJobs = this.jobs.sort((a, b) => a.work - b.work);

        this.categoryName = sortedJobs.map((a) => a.name);
        this.jobWork = sortedJobs.map((a) => a.work);
        this.jobFree = sortedJobs.map((a) => a.free);

        if (Math.abs(Math.min(...this.jobFree)) < Math.max(...this.jobWork)) {
          this.maxXaxis = Math.max(...this.jobWork) + 1;
        } else {
          this.maxXaxis = Math.abs(Math.min(...this.jobFree)) + 1;
        }

        preparedSeries = [
          {
            name: "ฟรี",
            data: sortedJobs.map((a) => a.free),
            drivers: sortedJobs.map((a) => a.name),
            isAdded: sortedJobs.map((a) => a.isAdded),
            fillColor: sortedJobs.map((a) => a.fillColor),
            _id: sortedJobs.map((a) => a._id),
          },
          {
            name: "วิ่ง",
            data: sortedJobs.map((a) => a.work),
            drivers: sortedJobs.map((a) => a.name),
            isAdded: sortedJobs.map((a) => a.isAdded),
            fillColor: sortedJobs.map((a) => a.fillColor),
            _id: sortedJobs.map((a) => a._id),
          },
        ];
      }
      return preparedSeries;
    },

    async openSelectTable(driverId) {
      let driverData = this.sumDriverStatOnSheet[driverId];
      const toSelectTable = await this.$refs["selectTableDialog"].open(
        driverData
      );
    },

    checkUserJobHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapJob, `${driverId}`, []);
        const jobInOverlop = _.map(_d, (item) => item["_id"]);
        const _driverInCell = _.filter(
          this.cellData.drivers,
          (item) => item["_id"] === driverId
        );
        // check duplication
        let jobIntersect = [];
        if (_driverInCell.length > 0) {
          jobIntersect = _.intersection(jobInOverlop, [
            _driverInCell[0]["jobId"],
          ]);
        }
        return _d.length > 0 && jobIntersect.length === 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },

    checkUserCellHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapCell, `${driverId}`, []);
        const cellInOverlop = _.map(_d, (item) => item["matchCellId"]);
        // check duplication
        let cellIntersect = [];
        if (cellInOverlop.length > 0) {
          cellIntersect = _.filter(
            cellInOverlop,
            (item) => item !== this.cellData.cellMId
          );
        }
        return cellIntersect.length > 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },
  },
};
</script>
