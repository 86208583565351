import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getEmployeeGroupService(store, queryString) {
  let uri = "/user-driver-groups";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeGroupInfoService(store, queryString) {
  let uri = `/user-driver-groups/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function postEmployeeGroupService(store, data) {
  let uri = "/user-driver-groups/";
  return await authHttpClientPost(store, uri, data);
}

export async function putEmployeeGroupService(store, id, data) {
  let uri = `/user-driver-groups?id=${id}`;
  return await authHttpClientPut(store, uri, data);
}

export async function deleteEmployeeGroupService(store, id) {
  let uri = `/user-driver-groups?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function getEmployeeGroupListSelectService(store) {
  let uri = `/user-driver-groups/groups-list-select`;
  return await authHttpClientGet(store, uri);
}

export async function putSingleEmployeeGroupService(store, id, data) {
  let uri = `/user-driver-groups/driver?id=${id}`;
  return await authHttpClientPut(store, uri, data);
}

export async function deleteSingleEmployeeGroupService(store, id, driverId) {
  let uri = `/user-driver-groups/driver?id=${id}&driverId=${driverId}`;
  return await authHttpClientDelete(store, uri);
}