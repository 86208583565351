import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const vehicle = {
  namespaced: true,
  state: {
    vehicleExpiryDay: {
      red: 10,
      orange: 60,
      green: null,
    },
    vehicleStatusColor: {
      ACTIVE: "primary",
      INACTIVE: "warning",
      SALED: "secondary",
      null: "white",
    },
    vehicleStatusText: {
      ACTIVE: "พร้อม",
      INACTIVE: "ไม่พร้อม",
      SALED: "ขายแล้ว",
    },
    vehicleContractStatusColor: {
      PROCESS: "primary",
      SUSPEND: "warning",
      EXPIRED: "secondary",
      null: "white",
    },
    vehicleContractStatusText: {
      PROCESS: "ดำเนินการ",
      SUSPEND: "ระงับ",
      EXPIRED: "หมดอายุ",
    },
    vehicleLicenseTypeText: {
      WHITE: "ขาว",
      YELLOW: "เหลือง",
      RED: "แดง",
      null: "-",
      undefined: "-",
      "": "-",
    },
    vehicleTypeText: {
      NO_DATA: "ไม่ข้อมูล",
      PICKUP: "กระบะ",
      SEDAN: "รถเก๋ง",
      TOUR_BUS_4W: "รถทัวร์ 4 ล้อ",
      TOUR_BUS_6W: "รถทัวร์ 6 ล้อ",
      TOUR_BUS_8W: "รถทัวร์ 8 ล้อ",
      TOUR_BUS_10W: "รถทัวร์ 10 ล้อ",
      VAN: "รถตู้",
    },
    vehicleTypeIcon: {
      NO_DATA: null,
      PICKUP: "mdi-truck-flatbed",
      SEDAN: "mdi-car-side",
      TOUR_BUS_4W: "mdi-bus",
      TOUR_BUS_6W: "mdi-bus-school",
      TOUR_BUS_8W: "mdi-bus-side",
      TOUR_BUS_10W: "mdi-bus-double-decker",
      VAN: "mdi-van-utility",
    },
    vehicleTypeIconText: {
      NO_DATA: null,
      PICKUP: "mdi-truck-flatbed",
      SEDAN: "mdi-car-side",
      TOUR_BUS_4W: "mdi-bus",
      TOUR_BUS_6W: "mdi-bus-school",
      TOUR_BUS_8W: "mdi-bus-side",
      TOUR_BUS_10W: "mdi-bus-double-decker",
      VAN: "mdi-van-utility",
    },

    vehicleLicenseTypeColor: {
      WHITE: "white",
      YELLOW: "yellow darken-2",
      RED: "red",
    },
    vehicleEventType: {
      REPAIR: "ซ่อม",
      DECORATE: "ตกแต่ง",
      MAINTAIN: "บำรุงรักษา",
      CHECKCOND: "ตรวจสภาพ",
    },
    vehicleEventTypeColor: {
      REPAIR: "red--text text--lighten-1",
      DECORATE: "purple--text text--lighten-1",
      MAINTAIN: "orange--text text--darken-3",
      CHECKCOND: "primary--text text--darken-2",
      // REPAIR: "",
      // DECORATE: "",
      // MAINTAIN: "",
      // CHECKCOND: "",
    },
    vehicleEventProcessStatus: {
      P: "กำลังดำเนินการ",
      I: "กำลังตรวจสอบ",
      S: "สำเร็จ",
      C: "ยกเลิก",
    },
    vehicleEventProcessStatusColor: {
      P: "orange darken-4 white--text",
      I: "yellow",
      S: "green white--text",
      C: "red white--text",
    },
    vehicleFuelColorStatus: {
      P: "orange darken-4 white--text",
      A: "green white--text",
      R: "red white--text",
    },
    vehicleFuelStatusColor: {
      A: "success",
      R: "red",
      P: "orange",
      null: "white",
    },

    vehicleFuelStatus: {
      ALL: "ทั้งหมด",
      P: "รอตรวจสอบ",
      A: "ผ่าน",
      R: "ไม่ผ่าน",
    },
    vehicleFuelApprovalStatus: {
      P: "รอตรวจสอบ",
      A: "ผ่าน",
      R: "ไม่ผ่าน",
    },
  },
  getters: {
    vehicleExpiryDay: (state) => state.vehicleExpiryDay,
    vehicleStatusList: (state) =>
      Object.keys(
        Object.fromEntries(
          Object.entries(state.vehicleStatusText).map((a) => a.reverse())
        )
      ).filter((value) => value !== "ไม่มีข้อมูล"),
    vehicleStatusColor: (state) => (key) => state.vehicleStatusColor[key],
    vehicleStatusText: (state) => (key) => state.vehicleStatusText[key],
    vehicleContractStatusColor: (state) => (key) =>
      state.vehicleContractStatusColor[key],
    vehicleContractStatusText: (state) => (key) =>
      state.vehicleContractStatusText[key],
    vehicleStatusTextInvert: (state) => (key) =>
      Object.fromEntries(
        Object.entries(state.vehicleStatusText).map((a) => a.reverse())
      )[key],

    vehicleLicenseTypeText: (state) => (key) =>
      state.vehicleLicenseTypeText[key],
    vehicleTypeText: (state) => (key) => state.vehicleTypeText[key],
    vehicleTypeIcon: (state) => (key) => state.vehicleTypeIcon[key],
    vehicleTypeIconText: (state) => (key) => state.vehicleTypeIconText[key],
    vehicleLicenseTypeColor: (state) => (key) =>
      state.vehicleLicenseTypeColor[key],
    // event
    vehicleEventTypeObj: (state) => state.vehicleEventType,
    vehicleEventProcessStatusObj: (state) => state.vehicleEventProcessStatus,
    // get fuel list and get fuel value
    vehicleFuelStatusColorObj: (state) => state.vehicleFuelColorStatus,
    vehicleFuelStatusObj: (state) => state.vehicleFuelStatus,
    vehicleFuelStatusApprovalObj: (state) => state.vehicleFuelApprovalStatus,
    vehicleFuelStatusValue: (state) => (key) => state.vehicleFuelStatus[key],
    vehicleFuelStatusColorValue: (state) => (key) =>
      state.vehicleFuelColorStatus[key],
    vehicleFuelStatusColor: (state) => (key) =>
      state.vehicleFuelStatusColor[key],
    //
    vehicleEventTypeValue: (state) => (key) => state.vehicleEventType[key],
    vehicleEventTypeColor: (state) => (key) => state.vehicleEventTypeColor[key],
    vehicleEventProcessStatus: (state) => (key) => {
      return key === null ? "" : state.vehicleEventProcessStatus[key];
    },
    vehicleEventProcessStatusColor: (state) => (key) => {
      return key === null ? "" : state.vehicleEventProcessStatusColor[key];
    },
  },
  mutations: {},
  actions: {},
  modules: {},
};
