<template>
  <v-row>
    <v-col cols="4">
      <apexchart
        type="bar"
        :height="chartHeight"
        :options="chartIncomeOptions"
        :series="incomeSeries"
      ></apexchart>
    </v-col>
    <v-col cols="4" ref="infoBox">
      <table style="width: 100%">
        <thead>
          <tr>
            <td class="text-center">รายรับ</td>
            <td class="text-center">รายจ่าย</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sdItem, sdIdx) in dataTableList" :key="sdIdx">
            <td
              width="50%"
              :class="[
                'py-1 pdc-text-top',
                { 'hover-bg-red': hoverTable[sdItem['income']['key']] },
                sdIdx < 4
                  ? incomeColosPattern[sdIdx]
                  : defalutIncomeColorPattern,
              ]"
              @mouseover="mouseOverTableHandler(sdItem['income'])"
              @mouseleave="mouseLeaveTableHandler(sdItem['income'])"
            >
              <div class="d-flex justify-space-between">
                <div class="pl-2">
                  <p class="text-app-detail-md black--text mb-0">
                    {{ sdItem["income"]["title"] }}
                  </p>
                  <p class="text-app-detail-normal mb-1">
                    {{ sdItem["income"]["name"] }}
                  </p>

                  <p
                    class="text-app-detail-large font-weight-bold black--text mb-1"
                  >
                    {{ displayNumberLocaleString(sdItem["income"]["inc"], 2) }}
                    บาท
                  </p>
                  <p class="mb-0 text-app-title">
                    {{
                      sdItem["income"]["incP"]
                        ? `คิดเป็น ${sdItem["income"]["incP"]} %`
                        : ""
                    }}
                  </p>
                </div>
                <div
                  :class="[
                    'px-3 text-app-md',
                    sdItem['incomeOrder'].indexOf('+') > -1
                      ? 'green--text text--darken-1 font-weight-bold'
                      : 'black--text',
                  ]"
                >
                  {{ sdItem["incomeOrder"] }}
                </div>
              </div>
            </td>
            <td
              class="py-1 pdc-text-top"
              :class="[
                'py-1',
                { 'hover-bg-red': hoverTable[expenseTable[sdIdx]['key']] },
                sdIdx < 4
                  ? expenseColorPattern[sdIdx]
                  : defaultExpenseColorPatern,
              ]"
              @mouseover="mouseOverTableHandler(expenseTable[sdIdx])"
              @mouseleave="mouseLeaveTableHandler(expenseTable[sdIdx])"
            >
              <div class="d-flex justify-space-between">
                <div
                  :class="[
                    'px-3 text-app-md',
                    sdItem['expenseOrder'].indexOf('+') > -1
                      ? 'green--text text--darken-1 font-weight-bold'
                      : 'black--text',
                  ]"
                >
                  {{ sdItem["expenseOrder"] }}
                </div>
                <div class="text-right pr-2">
                  <p class="text-app-detail-md black--text mb-0">
                    {{ sdItem["expense"]["title"] }}
                  </p>

                  <p class="text-app-detail-normal mb-1">
                    {{ sdItem["expense"]["name"] }}
                  </p>

                  <p
                    class="text-app-detail-large font-weight-bold black--text mb-1"
                  >
                    {{ displayNumberLocaleString(sdItem["expense"]["exp"], 2) }}
                    บาท
                  </p>
                  <p class="mb-0 text-app-title">
                    {{
                      sdItem["expense"]["expP"]
                        ? `คิดเป็น ${sdItem["expense"]["expP"]} %`
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>

    <v-col cols="4">
      <apexchart
        type="bar"
        :height="chartHeight"
        :options="chartExpenseOptions"
        :series="expensesSeries"
      ></apexchart>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { displayNumberLocaleString } from "@/services/appNumberHelper";

export default {
  name: "PaymentDriverContractStackBarChart",
  props: {
    data: {
      require: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },

  data: () => {
    return {
      incomeSeries: [],
      expensesSeries: [],

      chartColors: [],
      expChartColors: [],

      incomeTable: [],
      expenseTable: [],

      hoverTable: {},
      orderTable: [],
      dataTableList: [],
      chartHeight: 600,
      defalutIncomeColorPattern: "other-order-income",
      incomeColosPattern: [
        "firsh-order-income",
        "second-order-income",
        "thrid-order-income",
        "forth-order-income",
      ],
      // defaultIncomeColorPatternChart: "#e8f5e9",
      // incomeColorsPatternChart: ["#66bb6a", "#81c784", "#a5d6a7", "#c8e6c9"],
      defaultIncomeColorPatternChart: "#E3F2FD",
      incomeColorsPatternChart: ["#42A5F5", "#64B5F6", "#90CAF9", "#BBDEFB"],
      //
      defaultExpenseColorPatern: "other-order-expense",
      expenseColorPattern: [
        "firsh-order-expense",
        "second-order-expense",
        "thrid-order-expense",
        "forth-order-expense",
      ],
      // defaultExpenseColorPatternChart: "#ffebee",
      // expenseColorPatternChart: ["#ef5350", "#e57373", "#ef9a9a", "#ffcdd2"],
      defaultExpenseColorPatternChart: "#ECEFF1",
      expenseColorPatternChart: ["#78909C", "#90A4AE", "#B0BEC5", "#CFD8DC"],
    };
  },

  watch: {
    data: function (newValue) {
      this.processData();
    },
  },

  computed: {
    chartIncomeOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          formatter: (val, opts) => {
            const dIdx = opts.seriesIndex;
            const lb = _.get(this.incomeSeries, `${dIdx}.name`, "");

            if (val < 10) {
              return `${lb} ${_.round(val, 2)} %`;
            }
            return [lb, `${_.round(val, 2)} %`];
          },
        },
        legend: {
          show: false,
        },

        colors: this.chartColors,
        grid: {
          show: false,
          padding: {
            right: -50,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [0],
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
          x: {
            show: false,
          },
        },
      };
    },
    chartExpenseOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          formatter: (val, opts) => {
            const dIdx = opts.seriesIndex;
            const lb = _.get(this.expensesSeries, `${dIdx}.name`, "");

            if (val < 10) {
              return `${lb} ${_.round(val, 2)} %`;
            }
            return [lb, `${_.round(val, 2)} %`];
          },
        },
        legend: {
          show: false,
        },

        colors: this.expChartColors,
        grid: {
          show: false,
          padding: {
            left: -50,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [0],
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
          x: {
            show: false,
          },
        },
      };
    },
  },

  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
    matchHeight() {
      // const heightString = this.$refs.infoBox.clientHeight + "px";
      const _h =
        this.$refs.infoBox.clientHeight < 600
          ? 600
          : this.$refs.infoBox.clientHeight;
      this.chartHeight = _h;
    },

    processData() {
      try {
        if (!_.isNil(this.data)) {
          let _sIncome = [];
          let _sExpense = [];

          let _sIncomePlot = [];
          let _sExpensePlot = [];

          this.incomeSeries = [];
          this.expensesSeries = [];

          this.incomeTable = [];
          this.expenseTable = [];

          this.chartColors = [];
          this.expChartColors = [];
          this.dataTableList = [];

          let _hoverTable = {};

          let contractsId = [];

          if (this.data["contractsPayment"].length > 0) {
            const contractsPayment = _.get(this.data, "contractsPayment", []);
            const sumIncTotal = _.sumBy(contractsPayment, "totalIncome");
            const sumExpTotal = _.sumBy(contractsPayment, "totalExpenses");

            for (let i = 0; i < contractsPayment.length; i++) {
              const _baseField = {
                name: contractsPayment[i]["contractNumber"],
                title: contractsPayment[i]["title"],
                key: contractsPayment[i]["contractId"],
              };

              _hoverTable[_baseField["key"]] = false;

              _sIncome.push({
                ..._baseField,
                inc: contractsPayment[i]["totalIncome"] || 0,
                incP:
                  _.round(
                    _.divide(contractsPayment[i]["totalIncome"], sumIncTotal) *
                      100.0,
                    2
                  ) || 0,
              });

              _sExpense.push({
                ..._baseField,
                exp: contractsPayment[i]["totalExpenses"] || 0,
                expP:
                  _.round(
                    _.divide(
                      contractsPayment[i]["totalExpenses"],
                      sumExpTotal
                    ) * 100.0,
                    2
                  ) || 0,
              });
            }

            _sIncome = _.reverse(_.sortBy(_sIncome, ["inc"]));
            _sExpense = _.reverse(_.sortBy(_sExpense, ["exp"]));
            contractsId = _.flatten(_.map(_sIncome, "key"));

            //
            for (let i = 0; i < _sIncome.length; i++) {
              _sIncomePlot.unshift({
                name: _sIncome[i]["name"],
                data: [_sIncome[i]["incP"]],
              });

              if (_sIncome[i]["incP"] > 0 && i < 4) {
                this.chartColors.unshift(this.incomeColorsPatternChart[i]);
              } else {
                this.chartColors.unshift(this.defaultExpenseColorPatern);
              }

              this.incomeTable.push({
                ..._sIncome[i],
              });
            }

            for (let i = 0; i < _sExpense.length; i++) {
              _sExpensePlot.unshift({
                name: _sExpense[i]["name"],
                data: [_sExpense[i]["expP"]],
              });
              //

              if (_sExpense[i]["expP"] > 0 && i < 4) {
                this.expChartColors.unshift(this.expenseColorPatternChart[i]);
              } else {
                this.expChartColors.unshift(
                  this.defaultExpenseColorPatternChart
                );
              }

              this.expenseTable.push({
                ..._sExpense[i],
              });
            }

            // process order

            for (let i = 0; i < contractsId.length; i++) {
              let incomeIdx = _.findIndex(
                this.incomeTable,
                (item) => item["key"] === contractsId[i]
              );
              let expenseIdx = _.findIndex(
                this.expenseTable,
                (item) => item["key"] === contractsId[i]
              );

              let orderIncomeIdx = incomeIdx + 1 - (expenseIdx + 1);
              let orderExpenseIdx = expenseIdx + 1 - (incomeIdx + 1);
              //

              if (orderExpenseIdx === 0 && orderExpenseIdx === 0) {
                orderIncomeIdx = `${0}`;
                orderExpenseIdx = `${0}`;
              } else if (orderIncomeIdx < orderExpenseIdx) {
                orderIncomeIdx = `+${Math.abs(orderIncomeIdx)}`;
                orderExpenseIdx = `-${Math.abs(orderExpenseIdx)}`;
              } else {
                orderIncomeIdx = `-${Math.abs(orderIncomeIdx)}`;
                orderExpenseIdx = `+${Math.abs(orderExpenseIdx)}`;
              }

              this.orderTable[contractsId[i]] = {
                income: orderIncomeIdx,
                expense: orderExpenseIdx,
              };
            }

            let _dataTableList = [];
            for (let i = 0; i < _sIncome.length; i++) {
              _dataTableList.push({
                income: this.incomeTable[i],
                expense: this.expenseTable[i],
                incomeOrder:
                  this.orderTable[this.incomeTable[i]["key"]]["income"],
                expenseOrder:
                  this.orderTable[this.expenseTable[i]["key"]]["expense"],
              });
            }

            this.dataTableList = _dataTableList;
            this.incomeSeries = _sIncomePlot;
            this.expensesSeries = _sExpensePlot;
            this.hoverTable = _hoverTable;

            setTimeout(() => {
              this.matchHeight();
            }, 500);
          }
        } else {
          throw "no data";
        }
      } catch (error) {
        console.error(error);
      }
    },

    mouseOverTableHandler(selectItem) {
      this.hoverTable[selectItem["key"]] = true;
    },
    mouseLeaveTableHandler(selectItem) {
      this.hoverTable[selectItem["key"]] = false;
    },
  },

  mounted() {
    this.processData();
  },
};
</script>

<style scoped>
.pdc-text-top {
  vertical-align: top;
}

.pdc-text-left {
}

.hover-bg-red {
  background-color: #dcdee4;
}

.firsh-order-income {
  /* border-left: 5px solid #66bb6a; */
  border-left: 5px solid #42a5f5;
}

.second-order-income {
  /* border-left: 5px solid #81c784; */
  border-left: 5px solid #64b5f6;
}

.thrid-order-income {
  /* border-left: 5px solid #a5d6a7; */
  border-left: 5px solid #90caf9;
}

.forth-order-income {
  /* border-left: 5px solid #c8e6c9; */
  border-left: 5px solid #bbdefb;
}

.other-order-income {
  /* border-left: 5px solid #e8f5e9; */
  border-left: 5px solid #e3f2fd;
}

.firsh-order-expense {
  border-right: 5px solid #bdbdbd;
}

.second-order-expense {
  border-right: 5px solid #e0e0e0;
}

.thrid-order-expense {
  border-right: 5px solid #eeeeee;
}

.forth-order-expense {
  border-right: 5px solid #f5f5f5;
}

.other-order-expense {
  border-right: 5px solid #fafafa;
}
</style>
