<template>
  <v-dialog v-model="show" width="30%" :persistent="true">
    <v-card class="card-bg" v-if="!dialogError && show">
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
      <v-app-bar elevation="0" class="card-bg" color="transparent">
        <v-toolbar-title class="text-h6"> ค้นหารถ </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelStatus()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="px-0">
            <v-row dense class="px-3">
              <v-col cols="12">
                <InputView title="" :isRequired="false">
                  <v-text-field
                    class="white"
                    outlined
                    dense
                    placeholder="พิมพ์เลขทะเบียน"
                    v-model="searchVehicleText"
                    prepend-inner-icon="mdi-magnify"
                    hide-details=""
                  ></v-text-field>
                </InputView>
              </v-col>
            </v-row>

            <v-row class="pr-0 mt-3">
              <v-col cols="12" class="px-0 py-0 my-0">
                <v-list
                  v-if="vehicles.length !== 0"
                  dense
                  style="overflow-y: auto"
                  class="card-bg white_list"
                  transition="scale-transition"
                >
                  <v-list-item-group v-model="selectedValueIdxList">
                    <div v-for="(vehicle, i) in filterVehicles" :key="`dr${i}`">
                      <v-list-item two-line class="px-3">
                        <v-list-item-content>
                          <v-col cols="12" class="py-1 d-flex">
                            <div>
                              <p
                                class="ma-0 text-app-detail font-weight-bold black--text"
                              >
                                {{ vehicle.licenseNumber }}
                                {{ vehicleTypeText(vehicle.type) }}
                              </p>

                              <span class="text-app-small-detail pr-6">
                                <span class="mx-0 px-0">
                                  <v-icon
                                    v-if="
                                      vehicleLicenseTypeColor(
                                        vehicle.licenseType
                                      ) === 'white'
                                    "
                                    >mdi-card-text-outline</v-icon
                                  >
                                  <v-icon
                                    v-else
                                    :color="
                                      vehicleLicenseTypeColor(
                                        vehicle.licenseType
                                      )
                                    "
                                    >mdi-card-text</v-icon
                                  >
                                </span>
                                {{
                                  vehicleLicenseTypeText(vehicle.licenseType)
                                }}</span
                              >

                              <span class="text-app-small-detail pr-6">
                                <span class="mx-0 px-0">
                                  <v-icon>mdi-bus</v-icon>
                                </span>
                                {{
                                  vehicle.seat
                                    ? `${vehicle.seat} ที่นั่ง`
                                    : "ไม่มีข้อมูลจำนวนที่นั่ง"
                                }}</span
                              >

                              <v-chip
                                small
                                label
                                color="primary"
                                v-if="vehicle.ownedVehicle"
                              >
                                รถประจำตัว
                              </v-chip>
                            </div>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="i < vehicles.length - 1"
                        :key="`dv${i}`"
                      ></v-divider>
                    </div>
                  </v-list-item-group>
                </v-list>

                <v-card
                  v-else
                  elevation="0"
                  class="d-flex justify-center align-center card-bg"
                >
                  <p
                    v-if="componentLoading"
                    class="text-app-detail-normal align-stretch"
                  >
                    กำลังดึงข้อมูล...
                  </p>
                  <p v-else class="text-app-detail-normal align-stretch">
                    ไม่มีข้อมูล
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title> เกิดข้อผิดพลาด </v-card-title>
      <v-card-text>
        <v-btn @click="cancelStatus()" text> ปิด </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomSelectsRoleOfIcon from "@/components/CustomSelectsRoleOfIcon.vue";
import InputView from "@/components/InputView.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import NormalButton from "@/components/NormalButton.vue";
import magnify_image from "@/assets/search.png";
import AppOverlay from "@/components/AppOverlay.vue";
import { getAllVehicleService } from '@/services/api/jobs';
import { mapGetters } from "vuex";

export default {
  name: "DuplicateVehicleDialog",
  props: {},
  data: () => ({
    searchIcon: magnify_image,
    searchVehicleText: "",
    vehicles: [],
    selectData: null,
    selectedValueIdxList: null,
    searchboxLoading: false,
    show: false,
    resolve: null,
    reject: null,
    dialogError: false,
    // for api
    componentLoading: false,
    componetKeyCounter: 1,
  }),
  watch: {
    selectedValueIdxList: {
      immediate: false,
      handler(valueIdx) {
        if(valueIdx !== null) {
          const selectVehicle = this.filterVehicles[valueIdx];
          this.saveStatus(selectVehicle)
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleStatusText: "vehicle/vehicleStatusText",
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    filterVehicles() {  
      return _.orderBy(this.vehicles.filter(item => item.fullText.includes(this.searchVehicleText)));
    }
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    async getData() {
      this.componentLoading = true;
      const responseData = await getAllVehicleService(this.$store);
      
      if(this.vehicles.length !== 0) {
        this.componentLoading = false;
        return;
      }

      if(responseData['code'] === 200) {
        this.vehicles = responseData['data'];
      } else {
        this.vehicles = []; 
      }
      this.componentLoading = false;
    },
    open() {
      this.show = true;
      this.getData();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus(selectVehicle) {
      this.selectedValueIdxList = null;
      this.resolve(selectVehicle);
      this.show = false;
    },
    //
    cancelStatus() {
      this.selectedValueIdxList = null;
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
    InputView,
    CustomSelectsRoleOfIcon,
    PrimaryButton,
    CustomTextInputImprove,
    NormalButton,
    AppOverlay,
  },
  mounted() {},
};
</script>

<style scoped>
/* .title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
} */
.landing-not-selected {
  margin: auto;
  border: dashed;
  height: 600px;
}
.card-bg {
  background-color: #eff1f7;
}
.icon_magnify {
  font-size: 100px;
}
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.white_list .v-list-item-group .v-list-item--active {
  background-color: white;
  color: white;
}
.v-list {
  height: 400px; /* or any height you want */
  overflow-y: auto;
}
.image {
  opacity: 0.7;
}
</style>
