import axios from "axios";
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from "@/services/local_storage";
import { Buffer } from "buffer";

const STORAGE_ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
const STORAGE_REFRESH_TOKEN_KEY = "REFRESH_TOKEN";
const STORAGE_USER_INFO_KEY = "USER_INFO";

const baseURL = process.env.VUE_APP_API_URL;
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

function parseJwt(token) {
  let base64Payload = token.split(".")[1];
  let payload = Buffer.from(base64Payload, "base64").toString();
  return JSON.parse(payload);
}

function isTokenExpired(token, beforeExpire = 30) {
  const expTimestamp = parseJwt(token).exp;
  if (expTimestamp) {
    const currentTimestamp = Date.now() / 1000;
    if (expTimestamp - beforeExpire > currentTimestamp) {
      return false;
    } else {
      return true;
    }
  }

  return false;
}

export function removeTokensAndInfo(store) {
  removeLocalStorage(STORAGE_REFRESH_TOKEN_KEY);
  removeLocalStorage(STORAGE_ACCESS_TOKEN_KEY);
  removeLocalStorage(STORAGE_USER_INFO_KEY);

  store.commit("setAccessToken", null);
  store.commit("setRefreshToken", null);
  store.commit("setUserInfo", null);
}

export function setTokensAndInfo(store, data) {
  if (data["refreshToken"] != null) {
    setLocalStorage(STORAGE_REFRESH_TOKEN_KEY, data["refreshToken"]);
    store.commit("setRefreshToken", data["refreshToken"]);
  }

  setLocalStorage(STORAGE_ACCESS_TOKEN_KEY, data["accessToken"]);
  setLocalStorage(STORAGE_USER_INFO_KEY, JSON.stringify(data["userInfo"]));

  store.commit("setAccessToken", data["accessToken"]);
  store.commit("setUserInfo", data["userInfo"]);
}

async function refreshTokens(store) {
  store.commit("setRefreshingAccessToken", true);
  console.log("Refresh Token");
  const refreshToken = store.state.refreshToken;

  if (refreshToken) {
    if (isTokenExpired(refreshToken, 0)) {
      console.log("Refresh Token Expired");
      removeTokensAndInfo(store);
      store.commit("setRefreshingAccessToken", false);
      return false;
    }

    //refresh tokens
    console.log("Refreshing");
    try {
      config["headers"]["Authorization"] = "Bearer " + refreshToken;
      const response = await axios.post(
        baseURL + "/user/login/refresh",
        {},
        config
      );
      if (response.status == 200) {
        console.log("Refresh Success");
        setTokensAndInfo(store, response.data.data);
        store.commit("setRefreshingAccessToken", false);
        return true;
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        console.log(error.response.data);
        store.commit("setRefreshingAccessToken", false);
        // console.log("Refresh FAIL!!!!")
        removeTokensAndInfo(store);
        return false;
      }
    }

    //other return like 500 dont force refresh
    store.commit("setRefreshingAccessToken", false);
    console.log("Refresh FAIL!!!!");
    return true;
  }
  console.log("Refresh Token is null");
  store.commit("setRefreshingAccessToken", false);
  return false;
}

export async function getAccessToken(store) {
  console.log("Get Access Token");
  const accessToken = store.state.accessToken;

  if (accessToken) {
    if (!isTokenExpired(accessToken)) {
      console.log("Access Token Not Expired");
      return accessToken;
    }
  }
  console.log("Access Token Expired!!!!!");

  if (store.state.refreshingAccessToken == false) {
    const refreshResult = await refreshTokens(store);
    //cant refresh access token

    if (refreshResult == false) {
      console.log("setForceToLogin");
      store.commit("setForceToLogin", true);
    }
  } else {
    while (store.state.refreshingAccessToken == true) {
      console.log("Waiting for new access token from another request");
      await new Promise((r) => setTimeout(r, 500));
    }
  }

  console.log("newAccessToken " + store.state.accessToken);
  console.log("newRefreshToken " + store.state.refreshToken);
  return store.state.accessToken;
}

export async function loadLocalStorageData(store) {
  store.commit("setAccessToken", getLocalStorage(STORAGE_ACCESS_TOKEN_KEY));
  store.commit("setRefreshToken", getLocalStorage(STORAGE_REFRESH_TOKEN_KEY));
  store.commit(
    "setUserInfo",
    JSON.parse(getLocalStorage(STORAGE_USER_INFO_KEY))
  );

  const refreshResult = await refreshTokens(store);
  if (refreshResult == false) {
    store.commit("setForceToLogin", true);
  }
}
