<template>
  <v-app-bar dense v-if="showBar" color="orange lighten-4">
    <div class="pr-4">
      <p class="ma-0 text-detail-bar font-weight-bold black--text">
        ผู้วางแผนเส้นทางนี้
      </p>
    </div>
    <!-- <v-divider class="mx-4 py-3" vertical /> -->
    <div class="d-inline-flex" v-if="templateData">
      <v-avatar size="32">
        <v-img
          :src="
            templateData
              ? defaultPFImage
              : templateData.userId.profileimage === null
              ? defaultPFImage
              : templateData.userId.profileimage
          "
        >
        </v-img>
      </v-avatar>
      <div class="pl-2">
        <p class="ma-0 text-detail-bar font-weight-bold black--text">
          {{ templateData.userId.dpNameTh }}
        </p>
        <p class="ma-0 text-detail-bar">
          เบอร์โทร: {{ templateData.userId.dpPhoneNumeber }}
        </p>
      </div>
    </div>

    <v-spacer></v-spacer>
    <v-chip class="mr-1" v-if="isPublic === true" color="green" dark>
      <v-icon left> mdi-earth </v-icon>
      กำลังเผยแพร่
    </v-chip>
    <v-chip class="mr-1" v-if="isPublic === false" color="red" dark>
      <v-icon left> mdi-alert-octagon </v-icon>
      ยังไม่ได้กำลังเผยแพร่
    </v-chip>
    <!--  -->
    <div v-if="selectedStatus !== null">
      <v-menu offset-y left :disabled="!isPermissionCanEdit">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-detail-bar"
            :dark="!canChangeStatus"
            :color="jobsStatusColor(selectedStatus)"
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :disabled="canChangeStatus"
          >
            {{ jobsStatusText(selectedStatus) }}
            <v-icon> mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="selectedStatus">
            <v-list-item
              v-for="(item, index) in jobStatusList"
              :key="index"
              :disabled="index === selectedStatus ? true : false"
            >
              <v-list-item-title>
                {{ jobsStatusText(index) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>

    <AppDialogConfirm ref="DetailBarConfirm" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { putJobStatusService } from "@/services/api/jobs";
import { processPermission } from "@/services/permissions";
import { dateStringToDateFormat } from "@/services/appDate";
import _ from "lodash";
import { putRouteTemplatemStatusService } from "@/services/api/contract_job_routes";

export default {
  name: "RouteTemplateDetailSubComponent",
  data: () => ({
    defaultPFImage: defaultImage,
    jobStatusList: ["PRIVATE", "PUBLIC"],
    selectedStatus: null,
    previousSelectedStatus: null,
    isPermissionCanEdit: true,
    showBar: false,
    templateId: null,
  }),
  mounted() {
    if (!_.isNil(this.$route.params["id"])) {
      this.templateId = this.$route.params["id"];
      this.showBar = true;
    }
  },
  computed: {
    isPublic: function () {
      if (!_.isNil(this.$store.state["routeSetting"]["templateData"])) {
        return this.$store.state["routeSetting"]["templateData"]["public"];
      }
      return null;
    },
    templateData: function () {
      this.setSelectedStatus();
      return this.$store.state["routeSetting"].templateData || null;
    },
    canChangeStatus: function () {
      return !(
        !_.isNil(
          this.$store.state["routeSetting"]["templateData"]["incomePerRound"]
        ) &&
        !_.isNil(
          this.$store.state["routeSetting"]["templateData"]["recompenseAmount"]
        ) &&
        this.$store.state["routeSetting"]["templateData"]["totalCheckpoint"] > 1
      );
    },
  },
  watch: {
    selectedStatus: function (newValue) {
      if (!_.isNil(newValue)) {
        if (newValue !== this.previousSelectedStatus) {
          this.openConfirmDialog();
        }
      }
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    jobsStatusColor(selectedIdx) {
      const selectItem = this.jobStatusList[selectedIdx];
      switch (selectItem) {
        case "PRIVATE":
          return "primary";
        case "PUBLIC":
          return "green";
        default:
          return null;
      }
    },
    jobsStatusText(selectedIdx) {
      const selectItem = this.jobStatusList[selectedIdx];
      switch (selectItem) {
        case "PRIVATE":
          return "วางแผน";
        case "PUBLIC":
          return "เผยแพร่";
        default:
          return "-";
      }
    },
    setSelectedStatus() {
      if (!_.isNil(this.$store.state["routeSetting"]["templateData"])) {
        let currentStatus = this.$store.state["routeSetting"]["templateData"][
          "public"
        ]
          ? 1
          : 0;
        this.previousSelectedStatus = currentStatus;
        this.selectedStatus = currentStatus;
      }
    },
    async openConfirmDialog() {
      let _dialogStatus = await this.$refs.DetailBarConfirm.open(
        "ต้องการเปลี่ยนสถานะ ?",
        null,
        DialogType.ACTION,
        {
          noconfirm: true,
        }
      );
      if (_dialogStatus === true) {
        const _previousSelectedStatus = this.previousSelectedStatus;
        this.$store.dispatch("routeSetting/updateMainLoading", true);
        const responseData = await putRouteTemplatemStatusService(
          this.$store,
          `id=${this.templateId}`,
          {
            status: this.jobStatusList[this.selectedStatus],
          }
        );

        if (responseData["code"] === 200) {
          this.previousSelectedStatus = this.selectedStatus;
          this.$store.dispatch("routeSetting/updateTemplateData", {
            public: this.selectedStatus === 1 ? true : false,
          });
        } else {
          this.previousSelectedStatus = _previousSelectedStatus;
          this.selectedStatus = this.previousSelectedStatus;
        }

        this.$store.dispatch("routeSetting/updateMainLoading", false);
      }
    },
  },
  components: { AppDialogConfirm },
};
</script>

<style lang="scss" scoped>
.text-detail-bar {
  font-size: 12px;
}
</style>
