<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5"> เพิ่มอะไหล่ </v-card-title>

        <v-card-text class="px-3">
          <!-- status -->
          <v-col cols="12" class="pt-0 px-0">
            <v-form ref="form">
              <CustomCardFormViewModern :elevation="0" :isShowTitle="false">
                <v-col class="pt-0 px-0" cols="12">
                  <div>
                    <!-- <CustomTextInputImprove
                    ref="replacementName"
                    v-model="formData.parts"
                    placeholder="ชื่ออะไหล่"
                    title="ชื่ออะไหล่"
                    :isRequired="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่ออะไหล่']"
                  ></CustomTextInputImprove> -->
                    <!-- <InputView title="ชื่ออะไหล่" :isRequired="true"> -->
                    <!-- <p class="mb-2 text-body">
                      การเลือกอะไหล่ให้พิมพ์ตามลักษณะดังนี้
                      &lt;รหัสอะไหล่&gt;:&lt;ชื่ออะไหล่&gt;
                    </p> -->
                    <DynamicReportElement
                      v-model="formData.parts"
                      title="ชื่ออะไหล่"
                      :isRequired="true"
                      column="parts"
                      :isMultiple="false"
                      :isChips="false"
                      placeholder="พิมพ์ค้นหาอะไหล่หรือเพิ่มอะไหล่"
                      :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    />
                    <!-- </InputView> -->

                    <!-- <CustomTextInputImprove
                    ref="replacementProblem"
                    v-model="formData.problem"
                    placeholder="เกี่ยวกับอาการ"
                    title="เกี่ยวกับอาการ"
                    :isRequired="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุอาการ']"
                  ></CustomTextInputImprove> -->
                    <!-- <InputView title="เกี่ยวกับอาการ" :isRequired="true"> -->
                    <DynamicReportElement
                      v-model="formData.problem"
                      title="เกี่ยวกับอาการ"
                      :isRequired="true"
                      column="problem"
                      :isMultiple="true"
                      :isChips="true"
                      placeholder="พิมพ์ค้นหาอาการหรือเพิ่มอาการใหม่"
                      :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    />
                    <!-- </InputView> -->
                    <!-- <CustomTextInputImprove
                    ref="replacementPosition"
                    v-model="formData.detail"
                    placeholder="ตำแหน่งที่เสียหาย"
                    title="ตำแหน่งที่เสียหาย"
                    :isRequired="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุตำแหน่งที่เสียหาย']"
                  ></CustomTextInputImprove> -->
                    <!-- <InputView title="ตำแหน่งที่เสียหาย" :isRequired="true"> -->
                    <DynamicReportElement
                      v-model="formData.detail"
                      title="ตำแหน่งที่เสียหาย"
                      :isRequired="true"
                      column="detail"
                      :isMultiple="false"
                      :isChips="false"
                      placeholder="พิมพ์ค้นหาตำแหน่งที่เสียหายหรือเพิ่มตำแหน่งที่เสียหาย"
                      :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    />
                    <!-- </InputView> -->
                    <CustomTextInputImprove
                      ref="replacementNumber"
                      v-model="formData.amount"
                      inputType="numberWithoutSign"
                      placeholder="จำนวนชิ้น"
                      title="จำนวนชิ้น"
                      :expendMargin="false"
                      :isRequired="true"
                    ></CustomTextInputImprove>
                    <v-row>
                      <v-col cols="6">
                        <CustomTextInputImprove
                          ref="replacementNumber"
                          v-model="formData.pricePerUnit"
                          inputType="floatNumberWithOutSign"
                          placeholder="ราคาต่อชิ้น"
                          title="ราคาต่อชิ้น"
                          :expendMargin="false"
                          :isRequired="true"
                        ></CustomTextInputImprove>
                      </v-col>
                      <v-col cols="6">
                        <CustomTextInputImprove
                          ref="replacementNumber"
                          v-model="formData.itemTotalPrice"
                          inputType="floatNumberWithOutSign"
                          placeholder="ราคารวม"
                          title="ราคารวม"
                          :expendMargin="false"
                          :isRequired="true"
                          @blurHandler="handleBlur"
                        ></CustomTextInputImprove>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- <div class="px-6 sub-title-section" v-else>-</div> -->
                  <!-- <v-col cols="12" align="center">
                  <DetailButton
                    class="mt-0"
                    @on-click="handleGpsPayment"
                    >ยืนยันการชำระค่า GPS</DetailButton
                  >
                </v-col> -->
                </v-col>
              </CustomCardFormViewModern>
            </v-form></v-col
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import DynamicReportElement from "./DynamicReportElement.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "VehicleReplacementDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    formData: {
      parts: null,
      problem: null,
      detail: null,
      amount: 0,
      pricePerUnit: 0,
      itemTotalPrice: 0,
    },
  }),
  watch: {
    "formData.amount": function (newVal) {
      if (!_.isNil(this.formData["pricePerUnit"])) {
        if (this.formData["pricePerUnit"] > 0) {
          this.formData["itemTotalPrice"] =
            this.formData["pricePerUnit"] * newVal;
        }
      }
    },
    "formData.pricePerUnit": function (newVal) {
      if (!_.isNil(this.formData["amount"])) {
        if (this.formData["amount"] > 0) {
          this.formData["itemTotalPrice"] = this.formData["amount"] * newVal;
        }
      }
    },
    "formData.itemTotalPrice": function (newVal) {
      if (!_.isNil(this.formData["amount"])) {
        if (this.formData["amount"] > 0) {
          let unitPrice = newVal / this.formData["amount"];
          this.formData["pricePerUnit"] = unitPrice;
        }
      }
    },

    "formData.problem": function (newVal) {
      if (!_.isNil(newVal) && newVal.length === 0) {
        this.formData["problem"] = null;
      }
    },
  },
  computed: {
    // itemTotalPrice: {
    //   get() {
    //     let totalPrice = 0;
    //     if (
    //       !_.isNil(this.formData["amount"]) &&
    //       !_.isNil(this.formData["pricePerUnit"])
    //     ) {
    //       totalPrice = this.formData["amount"] * this.formData["pricePerUnit"];
    //     }
    //     return totalPrice;
    //   },
    //   set(val) {
    //     if (!_.isNil(this.formData["amount"])) {
    //       if (this.formData["amount"] > 0) {
    //         let unitPrice = val / this.formData["amount"];
    //         this.formData["pricePerUnit"] = unitPrice.toFixed(2);
    //       }
    //     }
    //   },
    // },
  },
  methods: {
    resetFormData() {
      this.formData = {
        parts: null,
        problem: null,
        detail: null,
        amount: 0,
        pricePerUnit: 0,
        itemTotalPrice: 0,
      };
    },
    setFormData() {
      this.formData["parts"] = null;
      this.formData["problem"] = null;
      this.formData["detail"] = null;
      this.formData["amount"] = 0;
      this.formData["pricePerUnit"] = 0;
      this.formData["itemTotalPrice"] = 0;
    },
    handleBlur(dataInputName) {
      if (!_.isNil(this.formData["amount"])) {
        if (this.formData["amount"] == 0) {
          this.formData["itemTotalPrice"] = 0;
        }
      }
    },
    open() {
      this.resetFormData();
      // this.setFormData();
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      if (this.$refs.form.validate() === true) {
        let _formData = _.clone(this.formData);
        this.resetFormData();
        this.$refs.form.reset();
        this.resolve(_formData);
        this.show = false;
      }
    },
    cancelStatus() {
      this.resetFormData();
      this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    DynamicReportElement,
    InputView,
  },
};
</script>

<style scoped></style>
