<template>
  <div name="DrivingLicenseDataView">
    <v-form ref="DrivingLicenseDataViewRef" v-model="valid">
      <CustomCardFormViewModern
        title="ข้อมูลใบขับขี่"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div v-for="(license, idx) in driverLicenseList" :key="idx">
          <v-row v-if="idx > 0" class="px-12 pl-9 pt-3 pb-6"
            ><v-divider></v-divider
          ></v-row>
          <v-row class="px-12" v-if="idx > 0">
            <v-spacer></v-spacer>
            <!-- <div
              class="text-decoration-underline error--text diplay--inline-block"
              :disabled="!canEdit"
              @click="handleDeleteItem(idx)"
            >
              ลบ
            </div> -->
            <v-btn icon :disabled="!canEdit" @click="handleDeleteItem(idx)">
              <v-icon color="error lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" sm="6" class="px-6 mb-6">
              <v-img
                :src="license.imageFile[0] ? license.imageFile[0].src : ''"
                height="365"
                contain
                class="backgroud-image"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" justify="center">
                    <v-icon x-large>mdi-image</v-icon>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="px-3">
                    <span class="title-input">
                      รูปภาพใบขับขี่
                      <span v-if="idx > 0">ที่ {{ idx + 1 }}</span></span
                    >
                    <!-- <span class="red--text"> * </span> -->
                    <div class="mt-2"></div>
                    <AppFilesUpload
                      placeholder="เลือกรูปภาพ"
                      :isClearInput="false"
                      :multiple="false"
                      :small-chips="false"
                      :allow-image-only="true"
                      :upload-with-scale="false"
                      :isPrependInnerIcon="true"
                      v-model="license.imageFile"
                      :ref="`AppFilesUploadRef${idx}`"
                      :disabled="!canEdit"
                      uploadTag="EMP_DRIVING_LICENSE"
                    />
                  </div>
                  <!-- :rules="[(v) => !!v || 'จำเป็นต้องระบุ']" -->
                </v-col>

                <v-col cols="12">
                  <CustomSelectsImprove
                    ref="driverLicenseType"
                    v-model="license.type"
                    title="ประเภทใบขับขี่"
                    :is-required="true"
                    :items="driverLicenseTypeItem"
                    :disabled="!canEdit"
                    data-input-name="driverLicenseType"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  />
                </v-col>

                <v-col cols="12">
                  <CustomDateTypingPickerFormInput
                    ref="renewalDate"
                    v-model="license.issueDate"
                    title="วันต่ออายุล่าสุด"
                    placeholder="วัน/เดือน/ปี"
                    class="px-3"
                    :expendMargin="false"
                    :is-required="true"
                    :rules="
                      !license.foreverIssueDate
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    :disabled="!canEdit || license.foreverIssueDate"
                    data-input-name="renewalDate"
                  />
                  <v-checkbox
                    class="px-3 pt-0 mb-3"
                    v-model="license.foreverIssueDate"
                    hide-details
                    dense
                    label="ตลอดชีวิต"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12">
                  <CustomDateTypingPickerFormInput
                    ref="expirationDate"
                    v-model="license.expiryDate"
                    placeholder="วัน/เดือน/ปี"
                    title="วันหมดอายุ"
                    class="px-3"
                    :expendMargin="false"
                    :is-required="true"
                    :rules="
                      !license.foreverExpirationDate
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    :disabled="!canEdit || license.foreverExpirationDate"
                    data-input-name="expirationDate"
                  />
                  <v-checkbox
                    class="px-3 pt-0 mb-3"
                    v-model="license.foreverExpirationDate"
                    hide-details
                    dense
                    label="ตลอดชีวิต"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row class="px-12" v-if="canEdit">
          <v-spacer></v-spacer>
          <PrimaryButton
            v-if="canEdit"
            icon="mdi-plus"
            :outlined="true"
            :disabled="this.driverLicenseList.length == 5"
            @on-click="handleAddClick()"
          >
            เพิ่มใบขับขี่
          </PrimaryButton>
          <v-spacer></v-spacer>
        </v-row>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          @on-click-skip="onClickSkip()"
          :is-enable-skip="isEnableSkip"
          :disabled="!canEdit"
          v-if="canEdit"
          text="บันทึกข้อมูลใบขับขี่รถ"
        />
      </CustomCardFormViewModern>
      <AppDialogConfirm ref="dialogError" />
    </v-form>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { errorMessageMapper } from "@/services/error";
import { DialogType } from "@/services/dialog";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import { getDateStringNow } from "@/services/appDate";

export default {
  name: "DrivingLicenseDataView",

  components: {
    CustomTextInput,
    CustomSelects,
    AppFilesUpload,
    AppDialogConfirm,
    CustomCardFormViewModern,
    AppSubmitFormButton,
    CustomSelectsImprove,
    PrimaryButton,
    CustomDateTypingPickerFormInput,
  },

  props: {
    driverLicence: {
      type: [Array, null],
      default: null,
    },
    isEnableSkip: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  // watch: {

  // },

  data() {
    return {
      valid: false,
      //
      driverLicenseNum: 1,
      driverLicenseImageInput: { fileKey: "" },
      driverLicenseImage: null,
      finalDate: "3000-12-31",
      //
      driverLicenseList: [],
      formData: {
        expiryDate: null,
        image: null,
        issueDate: null,
        type: null,
        imageFile: [],
        foreverIssueDate: false,
        foreverExpirationDate: false,
      },

      // expirationDateInput: null,
      // renewalDateInput: null,
      // driverLicenseTypeInput: null,
      //
      driverLicenseTypeItem: ["ส่วนบุคคล", "สาธารณะ"],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },

      rawData: null,
      // selectedDriverLicenseImageFile: [],
      resultDriverLicenseImageUpload: [],
      //
      disabled: false,
    };
  },

  mounted() {
    this.handleAddClick();
  },
  watch: {
    canEdit: function (newValue) {
      this.disabled = !newValue;
    },

    async driverLicence(newValue) {
      if (!_.isNil(newValue)) {
        this.rawData = _.cloneDeep(newValue); // for backup data
        let resp = [];
        if (newValue.length !== 0) {
          for (let idx in newValue) {
            let image = _.cloneDeep(newValue[idx].image);
            let imageFile = [image];
            if (newValue[idx]["issueDate"] == this.finalDate) {
              newValue[idx]["issueDate"] = getDateStringNow();
              newValue[idx]["foreverIssueDate"] = true;
            }

            if (newValue[idx]["expiryDate"] == this.finalDate) {
              newValue[idx]["expiryDate"] = getDateStringNow();
              newValue[idx]["foreverExpirationDate"] = true;
            }

            resp.push({
              ...newValue[idx],
              imageFile,
            });
          }
        }
        this.driverLicenseList = _.cloneDeep(resp);
        // if (newValue == false) {
        //   for (let idx in this.driverLicenseList) {
        //     // if (this.driverLicenseList[idx]["imageFile"].length !== 0) {
        //     await this.$refs[`AppFilesUploadRef${idx}`][0].clearInput();
        //     // }
        //   }
        // }
      }
    },

    // async isLoading(newValue) {
    //   if (newValue == false) {
    //     for (let idx in this.driverLicenseList) {
    //       // if (this.driverLicenseList[idx]["imageFile"].length !== 0) {
    //       await this.$refs[`AppFilesUploadRef${idx}`][0].clearInput();
    //       // }
    //     }
    //   }
    // },

    // async selectedDriverLicenseImageFile(newFile) {
    //   if (newFile.length === 0) {
    //     if (!_.isNil(this.rawData.image)) {
    //       this.formData.image = _.clone(this.rawData.image);
    //     } else {
    //       this.formData.image.fileKey = "";
    //     }
    //   } else {
    //     this.formData.image = newFile[0];
    //     this.formData.image.fileKey = newFile[0].src;
    //   }
    // },
  },

  methods: {
    handleAddClick() {
      // if (this.driverLicenseNum < 5) {
      //   this.driverLicenseNum += 1;
      // }
      if (this.driverLicenseList.length < 5) {
        let license = _.cloneDeep(this.formData);
        this.driverLicenseList.push(license);
      }
    },
    handleDeleteItem(idx) {
      this.driverLicenseList.splice(idx, 1);
    },

    async saveChange() {
      this.disabled = true;
      if (this.$refs["DrivingLicenseDataViewRef"].validate() === true) {
        // try {
        let returnData = { driverLicence: [] };
        for (let idx in this.driverLicenseList) {
          let fileKeyChecker = null;
          // console.log("imageFile:", this.driverLicenseList[idx]["imageFile"]);
          if (this.driverLicenseList[idx]["imageFile"].length !== 0) {
            let resultDriverLicenseImageUpload = await this.$refs[
              `AppFilesUploadRef${idx}`
            ][0].uploadAction();

            // console.log("UploadResult:", resultDriverLicenseImageUpload);

            if (resultDriverLicenseImageUpload.length !== 0) {
              if (resultDriverLicenseImageUpload[0]["code"] >= 400) {
                // is error
                throw resultDriverLicenseImageUpload[0];
                this.$refs[`AppFilesUploadRef${idx}`][0].clearInput();
              } else {
                fileKeyChecker = resultDriverLicenseImageUpload[0].data;
              }
            } else {
              if (!_.isNil(this.rawData[idx].image)) {
                fileKeyChecker = { fileKey: this.rawData[idx].image.fileKey };
              }
            }

            let license = _.cloneDeep(this.driverLicenseList[idx]);

            if (license["foreverIssueDate"]) {
              license["issueDate"] = this.finalDate;
            }

            if (license["foreverExpirationDate"]) {
              license["expiryDate"] = this.finalDate;
            }

            let licenseItem = { ...license, image: fileKeyChecker };
            delete licenseItem["imageFile"];
            delete licenseItem["foreverIssueDate"];
            delete licenseItem["foreverExpirationDate"];

            returnData["driverLicence"].push(licenseItem);
          }
        }
        this.$emit("on-click-save", returnData);
        // } catch (error) {
        //   this.showDialogError(error);
        // }
      } else {
        this.$refs["DrivingLicenseDataViewRef"].$el.scrollIntoView(this.focus);
      }
      // for (let idx in this.driverLicenseList) {
      //   await this.$refs[`AppFilesUploadRef${idx}`][0].clearInput();
      // }
      this.disabled = false;
    },

    async showDialogError(responseData) {
      let text_error = "ไม่สามารถแก้ไขข้อมูลบัญชีได้ ลองใหม่อีกครั้ง";
      if (!_.isNil(responseData)) {
        text_error = `${text_error} (${
          errorMessageMapper[responseData["message"]]
        })`;
      }

      await this.$refs.dialogError.open(text_error, null, DialogType.ERROR, {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      });
    },

    onClickSkip() {
      this.$emit("on-click-skip");
    },
  },
};
</script>
<style>
.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
.backgroud-image {
  background: #f4f6f6;
}

.v-input {
  font-size: 14px;
}

.theme--light.v-file-input .v-file-input__text--placeholder {
  color: rgb(170, 183, 184) !important;
}
</style>
