<script>
import { faLocationPin, faCar } from "@fortawesome/free-solid-svg-icons";

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    markerData: null,
    infoWindow: null,
  }),

  methods: {
    processIcon(isActive) {
      let _markerIcon = { ...this.marker.icon };
      if (this.marker["anchorMode"] === "POINT") {
        const faLocationIcon = faLocationPin.icon;
        _markerIcon["labelOrigin"] = new this.google.maps.Point(
          faLocationIcon[0] / 2,
          faLocationIcon[1] / 3
        );

        _markerIcon["path"] = faLocationIcon[4];
        _markerIcon["anchor"] = new this.google.maps.Point(
          faLocationIcon[0] / 2,
          faLocationIcon[1]
        );
      } else if (this.marker["anchorMode"] === "VEH") {
        const faCarIcon = faCar.icon;
        _markerIcon = {
          ..._markerIcon,
          path: faCarIcon[4],
          fillOpacity: 1,
          anchor: new google.maps.Point(faCarIcon[0] / 2, faCarIcon[1]),
          labelOrigin: new this.google.maps.Point(faCarIcon[0] / 2, -80),
          fillColor: isActive ? "#E2802A" : "#00DCF5", //"#E2802A", //"#659bde",
          // strokeWeight: 1,
          // strokeColor: "#ffffff",
          scale: 0.075,
        };
      }

      // focus, #00DCF5
      // no focus #E2802A

      return _markerIcon;
    },
    processLabel() {
      if (this.marker["anchorMode"] === "VEH") {
        return {
          text: this.marker["labelText"],
          fontSize: "14px",
          fontWeight: "700",
          color: "#ffffff",
        };
      }

      return {
        text: String(this.marker.markerIdx + 1),
        fontSize: "18px",
        fontWeight: "700",
        color: "#ffffff",
      };
    },
    getInfoWindow() {
      const contentString = "";

      return contentString;
    },
    processMarker() {
      const { Marker, InfoWindow } = this.google.maps;

      const infowindow = new InfoWindow({
        content: this.getInfoWindow(),
      });
      // .includes(
      // this.marker.id
      // );

      const isActive = this.$store.state.jobsMonitoring.activeJobId.includes(
        this.marker.id
      );

      const _icon = this.processIcon(isActive);
      const _label = this.processLabel();
      this.markerData = new Marker({
        position: this.marker.position,
        map: this.map,
        marker: this.marker,
        icon: _icon,
        label: _label,
      });

      // set on click
      if (this.marker["anchorMode"] === "VEH") {
        this.markerData.addListener("click", () => {
          //
          this.$store.dispatch(`jobsMonitoring/selectMonitorVehicle`, {
            from: "VEH",
            id: this.marker["id"],
          });
          //
        });
      } else {
        this.markerData.addListener("click", () => {
          const html =
            '<div id="content"><h1 id="firstHeading" class="firstHeading">' +
            (this.marker.markerIdx + 1) +
            "</h1></div>";
          infowindow.setContent(html);
          infowindow.open({
            anchor: this.markerData,
            map: this.map,
          });
        });
      }
    },
  },

  mounted() {
    this.processMarker();
  },

  render() {},
  beforeDestroy() {
    this.markerData.setMap(null);
  },
};
</script>
