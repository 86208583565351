import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

// job template
export async function getRouteTemplateListService(store, queryString) {
  let uri = `/contract/route-template`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getRouteTemplateSeachingService(store, text) {
  let uri = `/contract/route-template-search?text=${text}`;
  return await authHttpClientGet(store, uri);
}

export async function getRouteTemplateInfoService(store, queryString) {
  let uri = `/contract/route-template-info`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postRouteTemplete(store, queryString, data) {
  let uri = "/contract/route-template";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

export async function putRouteTemplate(store, queryString, data) {
  let uri = "/contract/route-template";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function putRouteTemplatemStatusService(store, queryString, data) {
  let uri = "/contract/route-template-status";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteRouteTemplate(store, queryString) {
  let uri = "/contract/route-template";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

// income and recomponse
export async function putRouteTemplateIncomeAndRecomponseService(
  store,
  queryString,
  data
) {
  let uri = "/contract/route-template-income-recomponse";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// Illustration
export async function getRouteTemplateIllustration(store, queryString) {
  let uri = "/contract/route-template-illustration";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putRouteTemplateIllustration(store, queryString, data) {
  let uri = "/contract/route-template-illustration";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function putRouteTemplatePairRoute(store, queryString, data) {
  let uri = "/contract/route-template-pair-route";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

//
// for job get to import and expoert
//
export async function getRouteTemplateMarkerService(store, queryString) {
  let uri = "/job/route-template-marker";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putRouteTemplateMarkerService(store, queryString, data) {
  let uri = "/job/route-template-marker";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function putSpecialDriverTemplate(store, queryString, data) {
  let uri = "/contract/route-template-special-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteSpecialDriverTemplate(store, queryString) {
  let uri = "/contract/route-template-special-driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

export async function getTemplateTimeLabelList(store, tempId) {
  let uri = `/job/job-route-template-time-label?id=${tempId}`;

  return await authHttpClientGet(store, uri);
}

export async function postTimeLabelTemplate(store, queryString, data) {
  let uri = "/job/job-route-template-time-label";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

export async function deleteTimeLabelTemplate(store, queryString) {
  let uri = "/job/job-route-template-time-label";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}
