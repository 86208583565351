<template>
  <v-row>
    <v-col cols="6">
      <apexchart
        type="bar"
        :height="barChartHeight"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </v-col>
    <v-col>
      <v-card
        elevation="0"
        v-for="(sdItem, sdIdx) in seriesDeclearList"
        :key="sdItem.key"
      >
        <v-card-text>
          <p class="text-app-detail-md">{{ sdItem["name"] }}</p>
          <p class="dashboard-text-sm font-weight-bold black--text mb-1">
            {{ displayNumberLocaleString(sdItem["data"], 2) }} บาท
          </p>
          <p class="mb-0 text-app-title">
            {{
              seriesPercentList[sdIdx]
                ? `คิดเป็น ${seriesPercentList[sdIdx]} %`
                : ""
            }}
          </p>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { displayNumberLocaleString } from "@/services/appNumberHelper";
import _ from "lodash";

export default {
  name: "PaymentExpensesStackBarChart",

  props: {
    data: {
      require: true,
    },
    parentHeightChart: {
      require: true,
      type: Number,
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  watch: {
    data: function (newValue) {
      this.processData();
    },
  },

  data: () => {
    return {
      series: [
        // {
        //   name: "PRODUCT A",
        //   data: [44],
        // },
      ],
      seriesDeclearList: [],
      seriesPercentList: [],
      stackKeyMap: {
        contractTotalRecompense: "รายจ่ายพนักงาน",
        vehicleDocs: "รายจ่ายเอกสารรถ",
        vehicleEventAmount: "รายจ่ายซ่อมบำรุง",
        vehicleFuelAmount: "รายจ่ายค่าน้ำมัน",
        vehicleTotalInstallment: "รายจ่ายงวดรถ",
      },
      chartColors: [],
    };
  },

  computed: {
    barChartHeight() {
      return this.parentHeightChart === 0 ? 600 : this.parentHeightChart - 110;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          formatter: (val, opts) => {
            const dIdx = opts.seriesIndex;
            const lb = _.get(this.series, `${dIdx}.name`, "");
            if (val < 15) {
              return `${lb} ${_.round(val, 2)}%`;
            }
            return [lb, `${_.round(val, 2)}%`];
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 5,
          dashArray: 0,
        },

        colors: this.chartColors,
        grid: {
          show: false,
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [0],
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
          x: {
            show: false,
          },
        },
      };
    },
  },

  methods: {
    displayNumberLocaleString: displayNumberLocaleString,
    processData() {
      try {
        if (!_.isNil(this.data)) {
          let _s = [];
          let _sdl = [];
          this.seriesDeclearList = [];
          this.seriesPercentList = [];
          this.chartColors = [];
          let sumTotal = 0;
          //

          for (const [key, value] of Object.entries(this.stackKeyMap)) {
            if (!_.isNil(this.data[key])) {
              _sdl.push({
                name: value,
                data: this.data[key],
                key: key,
              });

              sumTotal += this.data[key];
            }
          }

          _sdl = _.reverse(_.sortBy(_sdl, ["data"]));
          for (let i = 0; i < _sdl.length; i++) {
            const percentTotal = _.round(
              _.divide(_sdl[i]["data"], sumTotal) * 100.0,
              2
            );

            _s.unshift({
              name: _sdl[i]["name"],
              data: [percentTotal],
            });

            this.seriesPercentList.push(percentTotal);

            if (this.chartColors.length < 1 && _sdl[i]["data"] > 0) {
              this.chartColors.unshift("#42A5F5");
            } else {
              this.chartColors.unshift("#AAB7B8");
            }
          }
          //

          this.series = _s;
          this.seriesDeclearList = _sdl;
        } else {
          throw "no data";
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  mounted() {
    this.processData();
  },
};
</script>
