<template>
  <v-alert
    dense
    colored-border
    color="#567DF4"
    icon="mdi-clock-fast"
    class="mb-2"
    border="left"
    ><span class="text-title" align="left">{{ title }}</span>

    <span class="float-right">
      <router-link
        v-if="docData !== null"
        class="d-block text-decoration-none text-app-detail-normal mt-1 text-decoration-underline"
        :to="`/vehicle/${getKeyDoc(keyDoc)}?text=${docData.licenseNumber}`"
        >ประวัติ
      </router-link>
    </span>
    <div
      v-if="
        docData[`last_${keyDoc}`] !== null &&
        docData[`last_${keyDoc}`]['remainingDay'] > 0
      "
      class="pt-1"
    >
      <div :class="getExpiredColor(docData[`last_${keyDoc}`]['remainingDay'])">
        <span class="text-h5 font-weight-bold"
          >{{ docData[`last_${keyDoc}`]["remainingDay"] }} วัน</span
        >
        <!-- <span> วัน</span> -->
      </div>
      <div
        class="text-app-small-detail"
        v-if="docData[`last_${keyDoc}`]['issueDate']"
      >
        <span>วันชำระ</span
        ><span> {{ processDate(docData[`last_${keyDoc}`]["issueDate"]) }}</span>
      </div>

      <div
        class="text-app-small-detail"
        v-if="docData[`last_${keyDoc}`]['expiryDate']"
      >
        <span>วันหมดอายุ</span
        ><span>
          {{ processDate(docData[`last_${keyDoc}`]["expiryDate"]) }}
        </span>
      </div>
    </div>
    <div v-else class="text-app-small-detail">ไม่มีข้อมูล</div>
  </v-alert>
</template>

<script>
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  props: {
    title: {
      default: "", // ประกันภัย
      type: String,
    },
    keyDoc: {
      require: true,
      type: String, // insurance
    },

    data: {
      type: [Object, null],
      default: null,
    },

    historyTo: {
      type: String,
      require: true,
    },
  },

  computed: {
    docData: {
      get() {
        return this.data || null;
      },
      set(val) {},
    },
  },

  methods: {
    getExpiredColor(day) {
      if (day < this.$store.getters["vehicle/vehicleExpiryDay"]["red"]) {
        return "red--text text--darken-1 font-weight-bold";
      } else if (
        day < this.$store.getters["vehicle/vehicleExpiryDay"]["orange"]
      ) {
        return "amber--text text--darken-2 font-weight-bold";
      }
      return "green--text text--darken-1 font-weight-bold";
    },
    getKeyDoc(_keyDoc) {
      switch (_keyDoc) {
        case "insurance":
          return "ins";
        case "tax":
          return "tax";
        case "act":
          return "act";
        case "loan":
          return "loan";
      }
    },
    processDate(_value) {
      return dateStringToDateFormat(_value, {
        returnThai: false,
      });
    },
  },
};
</script>

<style scoped>
/* .detail-section {
  font-size: 14px;
  color: var(--v-bodyText);
} */
</style>
