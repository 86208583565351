<template>
  <v-container fluid>
    <CustomCardFormViewModern
      title="Fleet Card"
      :isShowTitle="true"
      :isTitleHighlight="true"
    >
      <div class="px-9 pb-6">
        <div v-if="dataList.length == 0" class="pt-3">
          <v-divider></v-divider>
          <v-col align="center">{{ dataTableNoDataText }}</v-col>
        </div>
        <v-data-table
          v-else
          :headers="tableHeaders"
          :items="tableRows"
          :loading="tableLoading"
          :no-data-text="dataTableNoDataText"
          :loading-text="dataTableLoadingText"
          :hide-default-footer="true"
          class="app-datatable"
        >
          <template v-slot:[`item.tools`]="{ item }">
            <div class="d-flex">
              <v-btn
                v-if="canEdit"
                icon
                :disabled="tableLoading"
                @click="handleEditClick(item.idx)"
              >
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="canEdit"
                icon
                color="deep-orange"
                :disabled="tableLoading"
                @click="handleDeleteItem(item)"
              >
                <v-icon> mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <v-row class="px-12 pb-6 pt-3" v-if="canEdit">
        <v-spacer></v-spacer>
        <PrimaryButton
          v-if="canEdit"
          icon="mdi-plus"
          :outlined="true"
          :disabled="tableLoading"
          @on-click="handleAddClick()"
        >
          เพิ่มข้อมูล Fleet Card
        </PrimaryButton>
        <v-spacer></v-spacer>
      </v-row>
    </CustomCardFormViewModern>
    <VehicleFeedCardDialog ref="feedCardDialog" />
    <AppDialogConfirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import _ from "lodash";
//
import { mapGetters } from "vuex";
import { dateStringToDateFormat } from "@/services/appDate";
import { DialogType } from "@/services/dialog";
import {
  getVehicleFeedCardUserService,
  postVehicleFeedCardUserService,
  deleteVehicleFeedCardService,
  putVehicleFeedCardService,
} from "@/services/api/vehicle";
// Component
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import DetailButton from "@/components/DetailButton.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import VehicleFeedCardDialog from "@/views/vehicle/components/VehicleFeedCardDialog.vue";
// Service
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";

export default {
  props: {
    userId: {
      default: "",
      type: String,
    },
    canEdit: {
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: true,
      type: Boolean,
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
      vehicleTypeIconText: "vehicle/vehicleTypeIconText",
    }),
    tableRows() {
      let rows = this.dataList.map((row, idx) => {
        return {
          idx: idx,
          _id: row._id,
          name: row.name,
          code: row.code,
          expiryDate: row.expiryDate,
          updatedAt: dateStringToDateFormat(row.updatedAt),
          limitAmount: !_.isNil(row.limitAmount)
            ? row.limitAmount.toLocaleString()
            : "-",
          usedAmount: !_.isNil(row.usedAmount)
            ? row.usedAmount.toLocaleString()
            : "-",
          type: this.getTypeList(row.type),
        };
      });
      return rows;
    },
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    enableLoading() {
      this.$emit("loading-func", true);
    },
    disableLoading() {
      this.$emit("loading-func", false);
    },
    getTypeList(_type) {
      try {
        const findIdx = _.findIndex(this.fleetCardType, { value: _type });
        if (findIdx !== -1) {
          return this.fleetCardType[findIdx].text;
        } else {
          throw "FAIL";
        }
      } catch (error) {
        return "-";
      }
    },

    async getData() {
      this.tableLoading = true;
      const responseData = await getVehicleFeedCardUserService(
        this.$store,
        this.userId
      );

      if (responseData["code"] == 200) {
        this.fleetCardType = responseData["data"]["typeList"];
        this.dataList = responseData["data"]["dataList"];
      } else {
        this.dataList = [];
      }

      this.tableLoading = false;
    },

    async handleAddClick() {
      const updatefeedCardData = await this.$refs.feedCardDialog.open(
        null,
        this.fleetCardType
      );

      if (updatefeedCardData !== null) {
        this.tableLoading = true;
        const responseData = await postVehicleFeedCardUserService(
          this.$store,
          this.userId,
          updatefeedCardData
        );
        //
        if (responseData["code"] === 200) {
          this.getData();
        } else {
          this.dataList = [];
          this.tableLoading = false;
        }
      }
    },
    async handleEditClick(itemIdx) {
      const updatefeedCardData = await this.$refs.feedCardDialog.open(
        _.clone(this.dataList[itemIdx]),
        this.fleetCardType
      );

      if (updatefeedCardData !== null) {
        const requestBodyData = _.pick(updatefeedCardData, [
          "name",
          "type",
          "code",
          "limitAmount",
        ]);

        this.tableLoading = true;
        const responseData = await putVehicleFeedCardService(
          this.$store,
          this.dataList[itemIdx]._id,
          requestBodyData
        );
        //
        if (responseData["code"] === 200) {
          this.getData();
        } else {
          this.dataList = [];
          this.tableLoading = false;
        }
      }
    },

    async handleDeleteItem(item) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ERROR,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteVehicleFeedCardService(this.$store, item._id);
        this.getData();
      }
    },
  },

  mounted() {
    this.getData();
  },

  data: () => ({
    formVaild: false,
    fundData: {},
    _id: null,

    limit: 20,
    page: 1,

    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableLoading: false,
    dataList: [],
    tableHeaders: [
      {
        text: "ชื่อการ์ด",
        align: "start",
        sortable: false,
        value: "name",
        width: "20%",
      },
      {
        text: "ประเภทการ์ด",
        align: "start",
        sortable: false,
        value: "type",
        width: "20%",
      },
      {
        text: "หมายเลขการ์ด",
        align: "start",
        sortable: false,
        value: "code",
        width: "20%",
      },
      {
        text: "วงเงิน",
        align: "start",
        sortable: false,
        value: "limitAmount",
        width: "10%",
      },
      {
        text: "ใช้ไปแล้ว",
        align: "start",
        sortable: false,
        value: "usedAmount",
        width: "10%",
      },
      {
        text: "แก้ไขล่าสุด",
        align: "start",
        sortable: false,
        value: "updatedAt",
        width: "10%",
      },
      {
        text: "",
        value: "tools",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],

    vehicleStatus: {
      ACTIVE: null,
      INACTIVE: null,
      SALED: null,
    },
    statusIcon: {
      ACTIVE: "mdi-car-connected",
      INACTIVE: "mdi-car-clock",
      SALED: "mdi-alpha-x-circle",
    },
  }),
  components: {
    CustomCardFormViewModern,
    AppDialogConfirm,
    AppSubmitFormButton,
    PrimaryButton,
    DetailButton,
    VehicleFeedCardDialog,
  },
};
</script>
