<template>
  <section name="VehicleCreateViewManage">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />
    <v-container>
      <v-row dense name="vehicleInformationForm" v-if="isPermissionCanView">
        <v-col :cols="12">
          <VehicleFormData
            title="เพิ่มข้อมูลรถ"
            :is-loading="isLoading"
            :vehicle-data="data"
            :vehicle-fund-data="fundData"
            :is-permission-can-edit="isPermissionCanEdit"
            @loading-func="setLoading"
          />
        </v-col>
      </v-row>
    </v-container>
    <!--  -->
    <AppPageNotFound :show="isPermissionCanView" />
    <AppOverlay :isLoading="isLoading" />

    <AppDialogConfirm ref="alertSaving" />
    <AppDialogReloadForm ref="dialogReloadForm" />
  </section>
</template>

<script>
import _ from "lodash";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
//
import VehicleFormData from "./VehicleFormData.vue";
import AppDialogReloadForm from "@/components/AppDialogReloadForm.vue";
import { getVehicleFundamental } from "@/services/api/vehicle";
import { URL_VEHICLE_LIST } from "@/services/routerQueryPath";

export default {
  name: "VehicleCreateView",

  data() {
    return {
      title: "",
      permList: {},
      data: null,
      fundData: null,
      isLoading: false,
      breadcrumbsItems: [
        {
          text: "รายการรถ",
          disabled: false,
          to: `/vehicle${URL_VEHICLE_LIST}`,
        },
        {
          text: "เพิ่มข้อมูลรถ",
          disabled: true,
          to: "",
        },
      ],
      isPermissionCanView: false,
      isPermissionCanEdit: false,
    };
  },
  methods: {
    startPage() {
      this.checkUserPermissions();
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      //
      let respData = await getVehicleFundamental(this.$store, "type=input");
      if (respData["code"] === 200) {
        this.fundData = _.clone(respData["data"]);
      } else {
        this.$router.push(`/vehicle${URL_VEHICLE_LIST}`);
      }
      // this.setTestingData();
      this.isLoading = false;
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE");

        if (this.permList.vehicle_info) {
          this.isPermissionCanView = true;
        }

        if (this.permList.admin_all || this.permList.vehicle_info_crud) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    setLoading(currentStatus) {
      this.isLoading = currentStatus;
    },
    setTestingData() {
      this.data = {
        ...this.data,
        licenseNumber: "กช-1234",
        licenseType: {
          key: "WHITE",
          value: "ขาว",
        },
        cassieId: "ABCDEETT1234",
        type: {
          key: "TOUR_BUS_4W",
          value: "รถทัวร์ 4 ล้อ",
        },
        color: {
          key: "BLACK",
          value: "ดำ",
        },
        gear: {
          key: "AUTO",
          value: "อัตโนมัติ",
        },
        brand: "Toyota",
        model: "Computer",
        year: "2022",
        seat: 13,
        registerdSeat: 15,
        style: "หน้าสั้น",
        engine: {
          key: "BENZINE",
          value: "เบนซิน",
        },
        status: {
          key: "INACTIVE",
          value: "ไม่พร้อม",
        },
        gps: "thai gps",
        gpsExpiryDate: "2022-10-14",
        ownershipDate: "2022-10-14",
        accessories: {
          curtain: true,
          gps: true,
          dashcam: true,
          incarcam: true,
          softCloseAutomaticDoor: true,
        },
        note: "ทดสอบ ทดสอบ ทดสอบ",
        images: [
          // {
          //   fileKey:
          //     "dev/6319b1fb5ac53c6cd9c0eabb_bbc81b87-9a43-498a-aca8-345589a5a791.png",
          // },
        ],
        files: [
          // {
          //   fileKey:
          //     "dev/6319b1fb5ac53c6cd9c0eabb_7fbf96bb-093b-43fc-ae8c-f94af8902f8e.pdf",
          //   name: "deep_test_word.pdf",
          // },
        ],
      };
    },
  },
  components: {
    HeaderCardNormal,
    AppOverlay,
    AppDialogConfirm,
    AppBreadCrumbs,
    AppPageNotFound,
    VehicleFormData,
    AppDialogReloadForm,
  },
  mounted() {
    this.startPage();
  },
};
</script>

<style lang="scss" scoped>
@import "./VehicleStyle.scss";
</style>
