<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="1300px" scrollable persistent>
      <v-card v-if="show">
        <v-toolbar color="primary" dark>
          <v-card-title> สัดส่วนการทำรายได้ </v-card-title>
          <v-spacer></v-spacer
          ><v-btn icon @click="cancel()"
            ><v-icon>mdi-window-close</v-icon></v-btn
          ></v-toolbar
        >

        <v-divider></v-divider>
        <v-card-text class="px-0 py-0">
          <v-card class="my-6 py-3 mx-0 px-12" elevation="0">
            <div class="text-app-detail-large mb-3">
              รายได้ครึ่งหนึ่ง มากจาก
              <span class="font-weight-bold success--text">{{
                topListNum
              }}</span>
              คน
              <span class="font-weight-bold success--text"
                >({{ topListPercent.toFixed(2) }}%)</span
              >
              จากทั้งหมด
              <span class="font-weight-bold">{{ employeeNum }}</span> คน
            </div>
            <v-row
              no-gutters
              class="pt-0 pb-1"
              v-if="
                sortedSeriesProsDesc !== null && sortedSeriesProsDesc.length > 0
              "
            >
              <v-col
                cols="2"
                class="pr-1 pb-1 new-tab"
                v-for="(data, idx) in sortedSeriesProsDesc"
                :key="idx"
                @click="handleEmployeeReportClick(data._id, data.empId)"
              >
                <v-card outlined rounded="5" :color="data['color']">
                  <v-sheet class="mt-2 pa-3" color="#fff">
                    <div class="one_row_only">
                      <span class="text-app-title font-weight-bold">{{
                        data["name"] ? data["name"] : "-"
                      }}</span>
                    </div>
                    <span class="text-app-title"
                      >{{
                        data["totalIncomePercent"]
                          ? data["totalIncomePercent"].toFixed(2)
                          : 0
                      }}% |
                      {{
                        data["totalIncome"]
                          ? numberWithCommasHelper(data["totalIncome"])
                          : 0
                      }}
                      บาท</span
                    >
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import { processPermission } from "@/services/permissions";
import { mapGetters } from "vuex";
import { numberWithCommasHelper } from "@/services/appNumberHelper";

import { getWorksheetListService } from "@/services/api/worksheet";

const EmployeeStatus = Object.freeze({
  APPLIED: "ขอสมัคร",
  REJECTED: "ไม่รับ",
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "employeeHeatMapPreviewDialog",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    AppDialogConfirm,
    AppOverlay,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,

      componentLoading: false,

      fetchDataError: 1,

      sortedSeriesProsDesc: [],
      topListNum: 0,
      topListPercent: 0,
      employeeNum: 0,

      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },

      isPermissionCanEdit: false,
      isPermissionCanView: false,
    };
  },

  watch: {},

  computed: {
    ...mapGetters({
      worksheetStatusCardColor: "worksheet/worksheetStatusCardColor",
      worksheetStatusText: "worksheet/worksheetStatusText",
    }),
  },

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    numberWithCommasHelper: numberWithCommasHelper,
    resetFormData() {
      this.componentLoading = false;
      this.fetchDataError = 1;
      this.sortedSeriesProsDesc = [];
      this.topListNum = 0;
      this.topListPercent = 0;
      this.employeeNum = 0;
    },

    open(items, topListNum, topListPercent) {
      this.resetFormData();
      this.checkUserPermissions();

      if (!_.isNil(items) && !_.isNil(topListNum) && !_.isNil(topListPercent)) {
        this.sortedSeriesProsDesc = items;
        this.topListNum = topListNum;
        this.topListPercent = topListPercent;
        this.employeeNum = this.sortedSeriesProsDesc.length;
      }

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    cancel() {
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
      this.resetFormData();
    },

    async handleEmployeeReportClick(_id, empId) {
      this.componentLoading = true;
      if (!_.isNil(_id) && !_.isNil(empId)) {
        let penaltyTypeURL = `/employee/dashboard_personal/${_id}&${empId}`;
        window.open(penaltyTypeURL, "_blank");
      }

      this.componentLoading = false;
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.excel-preview-table {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#171b21, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: transparentize(#171b21, 0.3);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.excel-preview-table tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
  // min-height: 300px;
}

.excel-preview-table th td {
  // background: #f7faff;
  border: 2px solid #222831;
  background-color: #171b21;
  border-collapse: collapse;
  border-top: 0;
}

// .excel-preview-table tr:nth-child(even) td {
//   background-color: #222831;
// }

.excel-preview-table tr:first-child {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(1) {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(even) {
  background-color: transparentize(#171b21, 0.5);
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.status_text_style {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
}

.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.clickable-row {
  cursor: pointer;
}

.table_header_text_style {
  font-weight: bold;
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.new-tab {
  position: relative;
  cursor: pointer;
}
.border_add_button {
  border: 5px dashed red;
}
</style>
