<template>
  <v-dialog v-model="show" width="900">
    <v-card>
      <v-toolbar elevation="0" color="primary" dark>
        <p class="mt-4 px-3">
          <v-icon class="mr-2"> mdi-content-save </v-icon> บันทีกเส้นทาง
        </p>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-form ref="SaveRouteForm" v-model="valid">
          <v-row>
            <v-col>
              <p
                class="text-app-md app-custom-input font-weight-bold"
                v-if="jobData"
              >
                ผูกกับสัญญา: {{ jobData.contract.value }}
              </p>

              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-1" dense>
            <v-col cols="12">
              <CustomTextInputImprove
                dataInputName="routeName"
                v-model="formData.name"
                title="ชื่อเส้นทาง"
                :hide-detail="false"
                :expend-margin="false"
                :is-required="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :input-view-bottom-margin="false"
              />
            </v-col>
            <v-col cols="12">
              <CustomTextareaImprove
                v-model="formData.desc"
                title="คำอธิบายเส้นทาง"
                :placeholder="''"
                type="text"
                :height="139"
                :expend-margin="false"
                data-input-name="routeDesc"
                :hide-detail="true"
              />
            </v-col>
          </v-row>
        </v-form>

        <p class="text-app-title mt-3">รายการจุดวิ่งรถ</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับ</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทาง</th>
                <th class="text-left">ระยะเวลา</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="marker in markerList" :key="marker._id">
                <td>{{ marker.order }}</td>
                <td>{{ marker.name }}</td>
                <td>{{ processDistanceText(marker.distanceText) }}</td>
                <td>{{ processDurationText(marker.durationText) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-1" color="success" @click="save"> บันทึก </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import {
  processDurationText,
  processDistanceText,
} from "@/services/appFuncHelpper";

export default {
  name: "SaveRouteDialog",
  props: ["dialogStatus"],
  data() {
    return {
      valid: null,
      show: false,
      formData: {
        name: null,
        desc: null,
        contract: null,
      },
      markerList: [],
    };
  },
  computed: {
    jobData: function () {
      return this.$store.state.manageRoute.jobData || null;
    },
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    async open(markerData) {
      this.show = true;
      this.markerList = markerData["markerList"];
      this.resetForm();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    resetForm() {
      this.formData = {
        name: null,
        desc: null,
        contract: null,
      };
    },
    close() {
      this.resolve(null);
      this.show = false;
    },
    save() {
      if (this.$refs.SaveRouteForm.validate() === true) {
        this.formData["contract"] =
          this.$store.state.manageRoute.jobData.contract["key"]; // get objectId
        this.resolve(_.clone(this.formData));
        this.show = false;
      }
    },
  },
  components: {
    CustomTextInputImprove,
    CustomTextareaImprove,
  },
};
</script>
