var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"scrollable":"","width":"800","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-card-title',[_vm._v(" สรุปข้อมูลพฤติกรรม ")]),_vm._v(" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.cancelStatus.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-5 px-0"},[_c('v-card',{staticClass:"mb-6 pb-3 mx-0 px-12",attrs:{"elevation":"0"}},[(_vm.id !== null)?_c('div',{staticClass:"title-input pl-3"},[_vm._v("ผู้กระทำผิด")]):_vm._e(),(_vm.id !== null)?_c('div',{staticClass:"d-inline-flex px-3 mb-6 mt-3"},[_c('v-avatar',{attrs:{"size":"64"}},[_c('v-img',{attrs:{"src":!_vm.employee.profileImage
                    ? _vm.defaultPFImage
                    : _vm.employee.profileImage}})],1),_c('div',{staticClass:"pl-2 align-self-center"},[_c('p',{staticClass:"ma-0 font-weight-bold"},[_vm._v(" "+_vm._s(((_vm.employee.salutation === null ? "" : _vm.employee.salutation.th) + " " + (_vm.employee.firstName === null ? "" : _vm.employee.firstName.th) + " " + (_vm.employee.lastName === null ? "" : _vm.employee.lastName.th) + " "))+" "+_vm._s(_vm.employee.nickName === null ? "" : ("(" + (_vm.employee.nickName.th) + ")"))+" ")]),_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" เบอร์โทร: "),(_vm.id !== null)?_c('span',_vm._l((_vm.employee.phoneNumber),function(phoneNumber,index){return _c('span',{key:index},[(index > 0 && index < _vm.employee.phoneNumber.length)?_c('span',[_vm._v(" , ")]):_vm._e(),_vm._v(_vm._s(phoneNumber))])}),0):_c('span',[_vm._v("-")])])]),_c('v-spacer'),_c('v-divider',{staticClass:"mx-3 my-3",attrs:{"vertical":""}}),_c('div',{staticClass:"pl-2 align-self-center text-app-detail-normal"},[_c('p',{staticClass:"ma-0"},[_vm._v(" รหัสพนักงาน: "),(_vm.id !== null)?_c('span',[_vm._v(_vm._s(_vm.employee.empId))]):_c('span',[_vm._v("-")])]),_c('p',{staticClass:"ma-0"},[_vm._v(" ตำแหน่ง: "),(
                    _vm.employee.position !== null &&
                    _vm.employee.position !== undefined
                  )?_c('span',[_vm._v(_vm._s(_vm.employee.position))]):_c('span',[_vm._v("-")])])])],1):_vm._e(),_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-9",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-input text-app-detail-large"},[_vm._v(" คะแนนการกระทำผิดทั้งหมด ")]),_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนคงเหลือ")]),(_vm.employee !== null)?_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                      _vm.totalScore - _vm.employee.penaltyReport.sumScore
                    )) + "--text text-app-detail-large font-weight-black")},[(
                        _vm.totalScore - _vm.employee.penaltyReport.sumScore !==
                        parseInt(_vm.totalScore - _vm.employee.penaltyReport.sumScore)
                      )?_c('span',[_vm._v(" "+_vm._s(( _vm.totalScore - _vm.employee.penaltyReport.sumScore ).toFixed(2))+" ")]):_c('span',[_vm._v(_vm._s(_vm.totalScore - _vm.employee.penaltyReport.sumScore))])]):_vm._e()]),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนทั้งหมด")]),_c('span',{staticClass:"ma-0 text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(_vm.totalScore)+" ")])])],1),_c('v-progress-linear',{staticClass:"mt-1",attrs:{"height":"20","value":_vm.totalScore - _vm.employee.penaltyReport.sumScore,"striped":"","rounded":"","color":_vm.getPercentCategColor(
                    _vm.totalScore - _vm.employee.penaltyReport.sumScore
                  )}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ทำผิด "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[_vm._v(_vm._s(_vm.employee.penaltyReport.sumCounter))]),_vm._v(" ครั้ง ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ถูกหัก "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[(_vm.employee.penaltyReport.sumScore > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.employee.penaltyReport.sumScore !==
                          parseInt(_vm.employee.penaltyReport.sumScore)
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.sumScore ? _vm.employee.penaltyReport.sumScore.toFixed(2) : ""))]):_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.sumScore))])]),_vm._v(" คะแนน ")])])],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[(_vm.employee.penaltyReport.stats)?_c('v-col',{staticClass:"text-app-small-detail",attrs:{"cols":"8"}},[_c('p',{staticClass:"my-0 font-weight-bold text-app-detail-normal"},[_vm._v(" * หักคะแนนรวมคำนวนอย่างไร ")]),_c('p',{staticClass:"my-0"},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v("ด้านการปฏิบัติงาน")]),_vm._v(" มีความสำคัญ "+_vm._s(_vm.employee.penaltyReport.stats[0].weight)+"% และถูกหัก "),(_vm.employee.penaltyReport.stats[0].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                        _vm.employee.penaltyReport.stats[0].score !==
                        parseInt(_vm.employee.penaltyReport.stats[0].score)
                      )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[0].score.toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[0].score))]),_vm._v(" คะแนน คิดเป็น "),_c('span',{staticClass:"primary--text"},[(_vm.totalScoreRatio[0] > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.totalScoreRatio[0] !== parseInt(_vm.totalScoreRatio[0])
                        )?_c('span',[_vm._v(_vm._s(_vm.totalScoreRatio[0].toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.totalScoreRatio[0]))])])]),_c('p',{staticClass:"my-0"},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v("ด้านความปลอดภัยและจีพีเอส")]),_vm._v(" มีความสำคัญ "+_vm._s(_vm.employee.penaltyReport.stats[1].weight)+"% และถูกหัก "),(_vm.employee.penaltyReport.stats[1].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                        _vm.employee.penaltyReport.stats[1].score !==
                        parseInt(_vm.employee.penaltyReport.stats[1].score)
                      )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[1].score.toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[1].score))]),_vm._v(" คะแนน คิดเป็น "),_c('span',{staticClass:"primary--text"},[(_vm.totalScoreRatio[1] > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.totalScoreRatio[1] !== parseInt(_vm.totalScoreRatio[1])
                        )?_c('span',[_vm._v(_vm._s(_vm.totalScoreRatio[1].toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.totalScoreRatio[1]))])])]),_c('p',{staticClass:"my-0"},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v("ด้านทรัพยากรมนุษย์และผู้ดูแลระบบ")]),_vm._v(" มีความสำคัญ "+_vm._s(_vm.employee.penaltyReport.stats[2].weight)+"% และถูกหัก "),(_vm.employee.penaltyReport.stats[2].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                        _vm.employee.penaltyReport.stats[2].score !==
                        parseInt(_vm.employee.penaltyReport.stats[2].score)
                      )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[2].score.toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[2].score))]),_vm._v(" คะแนน คิดเป็น "),_c('span',{staticClass:"primary--text"},[(_vm.totalScoreRatio[2] > 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(_vm.totalScoreRatio[2]))])]),_c('p',{staticClass:"mt-6"},[(_vm.employee.penaltyReport.sumScore > 0)?_c('router-link',{staticClass:"text-decoration-none text-app-detail-normal text-decoration-underline",attrs:{"to":("/employee/penality/list?empId=" + (_vm.employee.empId))}},[_vm._v("** ดูประวัติการกระทำผิด ")]):_vm._e()],1)]):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end pt-3",attrs:{"cols":"4"}},[_c('div',{staticClass:"align-self-end",attrs:{"align":"right"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"60px"}},[(
                          _vm.totalScore - _vm.employee.penaltyReport.sumScore !==
                          parseInt(
                            _vm.totalScore - _vm.employee.penaltyReport.sumScore
                          )
                        )?_c('span',[_vm._v(_vm._s(( _vm.totalScore - _vm.employee.penaltyReport.sumScore ).toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.totalScore - _vm.employee.penaltyReport.sumScore))])]),_c('br'),_c('span',{staticClass:"text-app-md font-weight-bold"},[_vm._v("คะแนนที่เหลือ")]),_c('br'),_c('span',{staticClass:"text-app-small-detail"},[_vm._v("ไม่ควรเหลือต่ำกว่า ")]),_c('span',{staticClass:"text-app-detail-md font-weight-bold error--text"},[_vm._v(_vm._s(_vm.employee.penaltyReport.floorScore)+" ")]),_c('span',{staticClass:"text-app-small-detail"},[_vm._v("คะแนน")]),_c('br'),_c('span',{staticClass:"text-app-small-detail"},[_vm._v("มิฉะนั้นจะ "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("ถูกลงโทษร้ายแรง")])])])])],1)],1)],1),_c('v-divider',{staticClass:"mb-6"}),(_vm.employee.penaltyReport.stats)?_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-9",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-input text-app-detail-large"},[_vm._v(" คะแนนการกระทำผิดในแต่ละด้าน ")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-input"},[_vm._v(" ด้านการปฏิบัติงาน ")]),_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                      _vm.employee.penaltyReport.stats[0].maxScore -
                        _vm.employee.penaltyReport.stats[0].score
                    )) + "--text text-app-detail-large font-weight-black")},[(
                        _vm.employee.penaltyReport.stats[0].maxScore -
                          _vm.employee.penaltyReport.stats[0].score !==
                        parseInt(
                          _vm.employee.penaltyReport.stats[0].maxScore -
                            _vm.employee.penaltyReport.stats[0].score
                        )
                      )?_c('span',[_vm._v(" "+_vm._s(( _vm.employee.penaltyReport.stats[0].maxScore - _vm.employee.penaltyReport.stats[0].score ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[0].maxScore - _vm.employee.penaltyReport.stats[0].score)+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนทั้งหมด")]),_c('span',{staticClass:"ma-0 text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[0].maxScore)+" ")])])],1),_c('v-progress-linear',{staticClass:"mt-1",attrs:{"height":"20","value":_vm.employee.penaltyReport.stats[0].maxScore -
                  _vm.employee.penaltyReport.stats[0].score,"striped":"","rounded":"","color":_vm.getPercentCategColor(
                    _vm.employee.penaltyReport.stats[0].maxScore -
                      _vm.employee.penaltyReport.stats[0].score
                  )}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ทำผิด "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[0].counter))]),_vm._v(" ครั้ง ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ถูกหัก "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[(_vm.employee.penaltyReport.stats[0].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.employee.penaltyReport.stats[0].score !==
                          parseInt(_vm.employee.penaltyReport.stats[0].score)
                        )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[0].score.toFixed(2)))]):_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[0].score)+" ")])]),_vm._v(" คะแนน ")])])],1)],1)],1):_vm._e(),(_vm.employee.penaltyReport.stats)?_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-9",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-input"},[_vm._v(" ด้านความปลอดภัยและจีพีเอส ")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                      _vm.employee.penaltyReport.stats[1].maxScore -
                        _vm.employee.penaltyReport.stats[1].score
                    )) + "--text text-app-detail-large font-weight-black")},[(
                        _vm.employee.penaltyReport.stats[1].maxScore -
                          _vm.employee.penaltyReport.stats[1].score !==
                        parseInt(
                          _vm.employee.penaltyReport.stats[1].maxScore -
                            _vm.employee.penaltyReport.stats[1].score
                        )
                      )?_c('span',[_vm._v(" "+_vm._s(( _vm.employee.penaltyReport.stats[1].maxScore - _vm.employee.penaltyReport.stats[1].score ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[1].maxScore - _vm.employee.penaltyReport.stats[1].score)+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนทั้งหมด")]),_c('span',{staticClass:"ma-0 text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[1].maxScore)+" ")])])],1),_c('v-progress-linear',{staticClass:"mt-1",attrs:{"height":"20","value":_vm.employee.penaltyReport.stats[1].maxScore -
                  _vm.employee.penaltyReport.stats[1].score,"striped":"","rounded":"","color":_vm.getPercentCategColor(
                    _vm.employee.penaltyReport.stats[1].maxScore -
                      _vm.employee.penaltyReport.stats[1].score
                  )}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ทำผิด "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[1].counter))]),_vm._v(" ครั้ง ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ถูกหัก "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[(_vm.employee.penaltyReport.stats[1].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.employee.penaltyReport.stats[1].score !==
                          parseInt(_vm.employee.penaltyReport.stats[1].score)
                        )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[1].score.toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[1].score))])]),_vm._v(" คะแนน ")])])],1)],1)],1):_vm._e(),(_vm.employee.penaltyReport.stats)?_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-9",attrs:{"cols":"12","lg":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-input"},[_vm._v(" ด้านทรัพยากรมนุษย์และผู้ดูแลระบบ ")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนคงเหลือ")]),_c('span',{class:("ma-0 " + (_vm.getPercentCategColor(
                      _vm.employee.penaltyReport.stats[2].maxScore -
                        _vm.employee.penaltyReport.stats[2].score
                    )) + "--text text-app-detail-large font-weight-black")},[(
                        _vm.employee.penaltyReport.stats[2].maxScore -
                          _vm.employee.penaltyReport.stats[2].score !==
                        parseInt(
                          _vm.employee.penaltyReport.stats[2].maxScore -
                            _vm.employee.penaltyReport.stats[2].score
                        )
                      )?_c('span',[_vm._v(" "+_vm._s(( _vm.employee.penaltyReport.stats[2].maxScore - _vm.employee.penaltyReport.stats[2].score ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[2].maxScore - _vm.employee.penaltyReport.stats[2].score)+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('span',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v("คะแนนทั้งหมด")]),_c('span',{staticClass:"ma-0 text-app-detail-large font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employee.penaltyReport.stats[2].maxScore)+" ")])])],1),_c('v-progress-linear',{staticClass:"mt-1",attrs:{"height":"20","value":_vm.employee.penaltyReport.stats[2].maxScore -
                  _vm.employee.penaltyReport.stats[2].score,"striped":"","rounded":"","color":_vm.getPercentCategColor(
                    _vm.employee.penaltyReport.stats[2].maxScore -
                      _vm.employee.penaltyReport.stats[2].score
                  )}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ทำผิด "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[2].counter))]),_vm._v(" ครั้ง ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('p',{staticClass:"ma-0 text-app-detail-normal"},[_vm._v(" ถูกหัก "),_c('span',{staticClass:"black--text text-app-detail-large font-weight-bold"},[(_vm.employee.penaltyReport.stats[2].score > 0)?_c('span',[_vm._v("-")]):_vm._e(),(
                          _vm.employee.penaltyReport.stats[2].score !==
                          parseInt(_vm.employee.penaltyReport.stats[2].score)
                        )?_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[2].score.toFixed(2)))]):_c('span',[_vm._v(_vm._s(_vm.employee.penaltyReport.stats[2].score))])]),_vm._v(" คะแนน ")])])],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('AppDialogConfirm',{ref:"alertStatus"}),_c('AppDialogConfirm',{ref:"dialogAlert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }