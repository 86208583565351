<template>
  <div v-if="isImageType">
    <span class="title-input">
      <span :class="isDark ? 'white--text' : ''">{{ title }}</span></span
    >

    <v-row v-if="uploadedData.length !== 0" v-viewer dense>
      <v-col
        v-for="(file, fileIdx) in uploadedData"
        :key="'uploadedData_' + fileIdx"
        class="d-flex child-flex"
        cols="2"
      >
        <v-sheet class="image-viewer-container">
          <img width="100%" :src="file.fileURL" />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ImageDataImprove",
  components: {},
  props: {
    componentName: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isImageType: {
      type: Boolean,
      default: false,
    },
    uploadedData: {
      // uploaded form server
      type: [Array, null],
      default: () => [],
    },
    isPermissionCanEdit: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    uploadTag: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploadedFiles: [],
    };
  },
  methods: {},
  watch: {},
};
</script>
