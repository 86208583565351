<template>
  <v-card class="main-bg-color rounded-0 mapMarkerView" elevation="0">
    <v-app-bar dense elevation="0" color="primary">
      <p class="ma-0 text-app-detail white--text">
        <v-icon color="white"> mdi-map-marker </v-icon>
        จุดเป้าหมาย:
        {{ markerList.length }}
      </p>
    </v-app-bar>

    <v-sheet
      elevation="0"
      class="rounded-0 subMapMarkerView"
      color="#F2F5F9"
      v-if="markerList.length === 0"
    >
    </v-sheet>

    <v-sheet
      elevation="0"
      class="rounded-0 subMapMarkerView"
      color="#F2F5F9"
      v-if="markerList.length !== 0"
    >
      <v-card
        v-for="(marker, idx) in markerList"
        :key="marker.gplaceId + '-' + idx"
        outlined
        elevation="0"
        class="ma-2 rounded-lg"
        color="white"
      >
        <div class="pa-2 d-flex justify-space-between">
          <font-awesome-layers full-width class="fa-2x mt-3 mr-2">
            <font-awesome-icon
              icon="fa-solid fa-location-pin"
              :color="marker.markerColor"
            />
            <font-awesome-layers-text
              class="white--text"
              transform="top-1 shrink-9"
              :value="idx + 1"
            />
          </font-awesome-layers>
          <div class="marker-text-title-wrapper">
            <p class="marker-text-title pl-1 mt-4">{{ marker.name }}</p>
          </div>

          <!-- </div> -->

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="copyLocationText(idx)">
                <v-list-item-icon>
                  <v-icon> mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-title> คัดลอกตำแหน่ง </v-list-item-title>
              </v-list-item>
              <!--  -->

              <v-list-item
                @click="editDialog(idx, false)"
                v-if="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title> ดูข้อมูล</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="isPermissionCanEdit"
                @click="editDialog(idx, true)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title> แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="deleteDialog(idx)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title> ลบ</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-card-text class="pt-2" v-if="idx !== 0">
          <p class="marker-text-topic mb-0">ข้อมูลโดยประมาณ</p>
          <v-row no-gutters>
            <v-col cols="6">
              <p class="marker-text-headline mb-0">ระยะทาง</p>
              <p class="marker-text-data mb-0">
                {{ processDistanceText(marker.distanceText) }}
              </p>
            </v-col>
            <v-col cols="6">
              <p class="marker-text-headline mb-0">ระยะเวลา</p>
              <p class="marker-text-data mb-0">
                {{ processDurationText(marker.durationText) }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
    <v-card class="ma-0 pa-0 white" elevation="0" flat>
      <v-card-text class="d-flex flex-wrap py-1 px-0">
        <v-btn
          class="flex-grow-1 ma-1"
          elevation="0"
          color="primary"
          @click="openNewDataDialog(0)"
          :disabled="!isPermissionCanEdit"
        >
          <v-icon> mdi-plus </v-icon> เพิ่มตำแหน่งใหม่
        </v-btn>

        <v-btn
          class="flex-grow-1 ma-1"
          elevation="0"
          @click="sortDialog()"
          :disabled="markerList.length <= 1 || !isPermissionCanEdit"
        >
          <v-icon> mdi-sort-variant </v-icon>
          สลับตำแหน่ง
        </v-btn>
      </v-card-text>
    </v-card>
    <AppOverlay :absolute="true" :isLoading="componentLoading" :opacity="0.1" />
    <AppOverlayReloadData :isShow="componentfetchfail" @reload-data="getData" />
    <!-- <v-btn @click="clearMarker()" class="mt-2"> clear marker </v-btn> -->
    <CreateMarkerDialog
      ref="createMarkerDialog"
      vuex-store-name="routeSetting"
      vuex-store-main-key-name="templateData"
      :allow-zero-lnt-lng="true"
    />
    <SortMarkerDialog ref="sortMarkerDialog" />

    <AppDialogConfirm ref="confirmDelete" />
    <AppDialogConfirm ref="routeOperationAlertDialog" />
  </v-card>
</template>

<script>
import Vue from "vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CreateMarkerDialog from "@/views/jobs/manageView/components/marker/CreateMarkerDialog.vue";
import { CUSTOM_MARKER_ICON } from "@/services/map/mapSettings";
import _ from "lodash";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import {
  processDurationText,
  processDistanceText,
  processDurationObject,
  processDistanceObject,
  processDurationCeilHelper,
  processDistanceCeilHelper,
} from "@/services/appFuncHelpper";
//
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

import moment from "moment";

import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import SortMarkerDialog from "@/views/jobs/manageView/components/marker/SortMarkerDialog.vue";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import {
  convertLocationToGObject,
  getDurationFromResponseDataMapService,
  getDistanceFromResponseDataMapService,
  getMapDirection,
  getNewGoogleMapObj,
} from "@/views/jobs/manageView/googleMapComponents/GoogleMapService";

import {
  getRouteTemplateMarkerService,
  putRouteTemplateMarkerService,
} from "@/services/api/contract_job_routes";
import AppOverlay from "@/components/AppOverlay.vue";
import AppOverlayReloadData from "@/components/AppOverlayReloadData.vue";

export default {
  name: "RouteMarkerSubView",
  computed: {},
  mounted() {
    this.startComponent();
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    dateStringToDateFormat: dateStringToDateFormat,

    startComponent() {
      if (!_.isNil(this.$route.params.id)) {
        this.firstLoad = true;
        this.templateId = this.$route.params.id;
        this.getData();
      } else {
        this.$router.push("/routes");
      }
    },
    _collectMarkerDuration() {
      this.markerListDuration = [];
      for (let i = 0; i < this.markerList.length; i++) {
        if (_.isNil(this.markerList[i]["duration"])) {
          continue;
        }
        // 'value' in second
        this.markerListDuration.push(this.markerList[i]["duration"]);
      }
      this.$store.commit(
        "routeSetting/setMarkerListDuration",
        _.clone(this.markerListDuration)
      );
    },
    _processMarkerAction(newDetailData) {
      this.mapPlotMarker();
      this.mapPlotLine();
      this._collectMarkerDuration();

      if (!_.isNil(newDetailData)) {
        this.$store.dispatch("routeSetting/updateTemplateData", {
          public: newDetailData["public"],
          totalCheckpoint: this.markerList.length,
        });
      }
    },
    async _setMapObject() {
      if (this.googleMapObject === null) {
        this.googleMapObject = await getNewGoogleMapObj();
      }
    },
    async getData() {
      await this._setMapObject();
      try {
        let responseData = await getRouteTemplateMarkerService(
          this.$store,
          `id=${this.templateId}`
        );
        if (responseData["code"] === 200) {
          this.markerList = responseData["data"];
          this.processMarkerList();
        }
        // plot marker and line
        if (this.markerList.length > 0) {
          this._processMarkerAction(null);
        }
        this.componentfetchfail = false;
        this.componentLoading = false;
      } catch (error) {
        // error
        this.componentfetchfail = true;
      }
    },
    //
    async openNewDataDialog() {
      this.isOnOperation = true;
      let lastMarker = null;
      let selectedMarker = null;

      if (this.markerList.length !== 0) {
        // copy last
        lastMarker = _.cloneDeep(this.markerList[this.markerList.length - 1]);
      }
      //
      let markerData = await this.$refs.createMarkerDialog.open(
        lastMarker,
        selectedMarker,
        null,
        this.markerListPlaceId,
        this.markerList.length,
        false
      );

      // check and update
      if (!_.isNil(markerData)) {
        let { newData } = markerData;
        markerData = null;
        this.componentLoading = true;

        try {
          // create mockup
          let _requestBodyData = [];
          for (let i = 0; i < this.markerList.length; i++) {
            _requestBodyData.push({
              _id: this.markerList[i]["_id"],
              order: i + 1,
            });
          }

          newData["order"] = this.markerList.length + 1;
          _requestBodyData.push(newData);
          // TODO:: sending to database;
          let responseData = await putRouteTemplateMarkerService(
            this.$store,
            `id=${this.templateId}&mode=1`,
            {
              marker: _requestBodyData,
            }
          );

          let newDetailData = null;
          if (responseData["code"] === 200) {
            newDetailData = responseData["data"];

            newData["_id"] = responseData["data"]["insertId"]; // set id
            newData["parentMarkerId"] = responseData["data"]["parentId"][0]; // parent marker
            newData["order"] = newData["order"];
            newData["distanceText"] = processDistanceObject(
              newData["distance"]
            );
            newData["durationText"] = processDurationObject(
              newData["duration"]
            );

            this.markerList.push(newData);
          } else {
            // this disabled form
            // show reload data
            throw "Fail from server";
          }
          // plot marker and line
          this.processMarkerList();
          this._processMarkerAction(newDetailData);
        } catch (error) {
          // this disabled form
          // alert fail to from network
          console.log("add marker fail", error);
        }
      }
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async sortDialog() {
      // has mongo id
      let _orderData = [];
      this.isOnOperation = true;
      for (let i = 0; i < this.markerList.length; i++) {
        _orderData.push({
          _id: this.markerList[i]["_id"],
          order: i,
          orderText: i + 1,
          parentMarkerId: this.markerList[i]["parentMarkerId"],
          gPlaceId: this.markerList[i]["gplaceId"],
          name: this.markerList[i]["name"],
          //
          estimateDuration: getDurationFromResponseDataMapService(
            this.markerList[i]["gDirection"]
          ),
          estimateDistance: getDistanceFromResponseDataMapService(
            this.markerList[i]["gDirection"]
          ),
          duration: this.markerList[i]["duration"],
          distance: this.markerList[i]["distance"],
          distanceText: this.markerList[i]["distanceText"],
          durationText: this.markerList[i]["durationText"],
          //
          coordinates: this.markerList[i]["coordinates"],
          gDirectionOption: this.markerList[i]["gDirectionOption"],
        });
      }

      _orderData = await this.$refs.sortMarkerDialog.open(
        _orderData,
        "routeSetting"
      );

      let _reqBody = []; // for update data
      let _markerList = []; // for display data
      let changeIndex = false;

      const selected_reqBody_key = [
        "_id",
        "order",
        "duration",
        "distance",
        "parentMarkerId",
        "gDirection",
        "gDirectionOption",
        "edited",
      ];
      this.componentLoading = true;

      if (_orderData !== null) {
        for (let i = 0; i < _orderData.length; i++) {
          // set
          _markerList.push({
            ...this.markerList[_orderData[i]["order"]],
            order: i + 1,
            duration: _orderData[i]["duration"],
            distance: _orderData[i]["distance"],
            parentMarkerId: _orderData[i]["parentMarkerId"],
            gDirection:
              i === 0
                ? null
                : _orderData[i]["gDirection"] === null
                ? this.markerList[_orderData[i]["order"]]["gDirection"]
                : _orderData[i]["gDirection"],
            gDirectionOption: _orderData[i]["gDirectionOption"],
          });

          if (_orderData[i]["edited"] === true) {
            // order by select current order
            // update duration, distance, gDirection
            let item = _.pickBy(_markerList[i], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            item["edited"] = true;
            _reqBody.push(item);
            changeIndex = true;
          } else {
            // keep order,
            _reqBody.push({
              _id: this.markerList[_orderData[i]["order"]]["_id"],
              parentMarkerId: _orderData[i]["parentMarkerId"],
              edited: false,
              order: i + 1,
            });
          }
        }

        // calculate new distance and time
        if (changeIndex) {
          try {
            // DONE:: update to server
            let responseData = await putRouteTemplateMarkerService(
              this.$store,
              `id=${this.templateId}&mode=3`,
              {
                marker: _reqBody,
              }
            );

            if (responseData["code"] === 200) {
              let newDetailData = responseData["data"];
              // loop responseData data to updateMarkerList
              for (let i = 0; i < _markerList.length; i++) {
                if (_.isNumber(_markerList[i]["duration"])) {
                  _markerList[i]["durationText"] = processDurationObject(
                    _markerList[i]["duration"]
                  );
                  _markerList[i]["distanceText"] = processDistanceObject(
                    _markerList[i]["distance"]
                  );
                } else {
                  _markerList[i]["durationText"] = null;
                  _markerList[i]["distanceText"] = null;
                }
              }
              this.markerList = _.cloneDeep(_markerList);
              this.processMarkerList();
              this._processMarkerAction(newDetailData);
            } else {
              console.log("order marker fail from server");
            }
          } catch (error) {
            // DONE:: update to server
            console.log("order marker fail from process");
          }
        }
      }
      this.isOnOperation = false;
      this.componentLoading = false;
    },
    async editDialog(idx, canEdit) {
      this.isOnOperation = true;
      let lastMarker = null;
      let selectedMarker = _.cloneDeep(this.markerList[idx]);
      let nextMarker = null;

      if (idx > 0) {
        lastMarker = _.cloneDeep(this.markerList[idx - 1]);
      }

      if (idx < this.markerList.length - 1) {
        nextMarker = _.cloneDeep(this.markerList[idx + 1]);
      }
      //// OPEN DIALOG
      let markerData = await this.$refs.createMarkerDialog.open(
        lastMarker,
        selectedMarker,
        nextMarker,
        this.markerListPlaceId,
        idx,
        canEdit,
        false
      );

      if (_.isNil(markerData)) {
        return;
      }

      this.componentLoading = true;

      try {
        // TODO:: update to server
        const selected_reqBody_key = [
          "_id",
          "order",
          "name",
          "coordinates",
          "duration",
          "distance",
          //
          "gplaceId",
          "gplace",
          "gplaceGeocode",
          "gDirection",
          "gDirectionOption",
          //
          "checkpointRadiusInMeter",
          "checkpointDurationPercent",
          "checkpointLimitSpeedKmPerHours",
        ];

        let _reqBody = [];
        // process request body at index
        const currentIdx = idx;
        const childIdx = idx + 1;

        for (let i = 0; i < this.markerList.length; i++) {
          // if current marker
          if (i === currentIdx) {
            let _obj = _.pickBy(markerData["newData"], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            _obj["_id"] = this.markerList[i]["_id"];
            _obj["edit"] = true;
            _reqBody.push(_obj);
          } else if (i === childIdx && !_.isNil(markerData["newNextMarker"])) {
            // next of change marker
            let _obj = _.pickBy(markerData["newNextMarker"], (v, k) =>
              selected_reqBody_key.includes(k)
            );
            _obj["_id"] = this.markerList[i]["_id"];
            _obj["edit"] = true;
            _reqBody.push(_obj);
          } else {
            _reqBody.push({
              _id: this.markerList[i]["_id"],
              order: i + 1,
              edit: false,
            });
          }
        }

        // === CALL API
        let responseData = await putRouteTemplateMarkerService(
          this.$store,
          `id=${this.templateId}&mode=2`,
          {
            marker: _reqBody,
          }
        );

        if (responseData["code"] === 200) {
          let newDetailData = responseData["data"];
          if (!_.isNil(markerData["newNextMarker"])) {
            // update next marker direction (child marker)
            let _newNextMarker = {
              ...nextMarker,
              ...markerData["newNextMarker"],
            };
            this.markerList.splice(childIdx, 1);
            this.markerList.splice(childIdx, 0, _newNextMarker);
          }
          // update edit marker
          let _selectedMarker = { ...selectedMarker, ...markerData["newData"] };
          this.markerList.splice(currentIdx, 1);
          this.markerList.splice(currentIdx, 0, _selectedMarker);
          // plot marker and line
          this.processMarkerList();
          this._processMarkerAction(newDetailData);
        } else {
          throw "fail to update from server";
        }
      } catch (error) {
        console.log("edit marker fail", error);
      }
      this.componentLoading = false;
      this.isOnOperation = false;
    },
    async deleteDialog(idx) {
      this.isOnOperation = true;
      let marker = this.markerList[idx];
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบตำแหน่งนี้ ?",
          `ลบตำแหน่ง "${marker.name}" ใช่หรือไหม`,
          DialogType.ERROR,
          {
            width: 400,
            zIndex: 200,
            noconfirm: true,
          }
        )
      ) {
        // call main loading
        this.componentLoading = true;
        let updateRemaining = false;

        try {
          let _reqBody = {};
          let newDurationOfMarker = null;
          let newDistanceOfMarker = null;

          let _gDirectionOption = null;
          if (this.markerList.length > 1) {
            if (idx === 0) {
              // update next to first order
              _reqBody = {
                reId: marker["_id"],
                upId: this.markerList[idx + 1]["_id"],
                paId: null,
                newOrder: idx + 1, // 0 => 1 (readable number),
                duration: null,
                distance: null,
                gDirection: null,
              };
              updateRemaining = true;
            } else if (idx === this.markerList.length - 1) {
              // remove last index
              _reqBody = {
                reId: marker["_id"],
                upId: null,
                paId: null,
                newOrder: null,
              };
            } else {
              // get map direction from google
              _gDirectionOption = {
                ...this.markerList[idx]["gDirectionOption"],
              };

              // PROCESS: Next Departure date
              if (
                !_.isNil(this.markerList[idx - 1]["estimateArrivalTimePosix"])
              ) {
                let nowDate = new Date();
                let processDate = new Date(
                  this.markerList[idx - 1]["estimateArrivalTimePosix"]
                );
                if (nowDate.valueOf() < processDate.valueOf()) {
                  _gDirectionOption["drivingOptions"]["departureTime"] =
                    processDate;
                } else {
                  _gDirectionOption["drivingOptions"]["departureTime"] =
                    moment()
                      .add(60 * 10, "s", true)
                      .toDate();
                }
              } else {
                // get now if cannot estimat
                _gDirectionOption["drivingOptions"]["departureTime"] = moment()
                  .add(60 * 10, "s", true)
                  .toDate();
              }
              //

              // get last arrive time
              let newDirect = await getMapDirection(
                this.googleMapObject,
                convertLocationToGObject(
                  this.googleMapObject,
                  this.markerList[idx - 1]["coordinates"]
                ),
                convertLocationToGObject(
                  this.googleMapObject,
                  this.markerList[idx + 1]["coordinates"]
                ),
                _gDirectionOption
              );

              newDurationOfMarker = getDurationFromResponseDataMapService(
                newDirect["data"]
              );
              newDistanceOfMarker = getDistanceFromResponseDataMapService(
                newDirect["data"]
              );

              if (newDirect["status"] === "OK") {
                _reqBody = {
                  reId: marker["_id"],
                  upId: this.markerList[idx + 1]["_id"],
                  paId: this.markerList[idx - 1]["_id"],
                  newOrder: idx + 1, // 0 => 1 (readable number),
                  duration: !_.isNil(newDirect["data"])
                    ? processDurationCeilHelper(newDurationOfMarker)
                    : null,
                  distance: !_.isNil(newDirect["data"])
                    ? processDistanceCeilHelper(newDistanceOfMarker)
                    : null,
                  estimateDuration: getDurationFromResponseDataMapService(
                    newDirect["data"]
                  ),
                  estimateDistance: getDistanceFromResponseDataMapService(
                    newDirect["data"]
                  ),
                  gDirection: newDirect["data"],
                  gDirectionOption: _gDirectionOption,
                };
              } else {
                throw "Fetch direction fail";
              }

              updateRemaining = true;
            }
          } else {
            // only one
            _reqBody = {
              reId: marker["_id"],
              upId: null,
              paId: null,
              newOrder: null,
            };
          }

          let responseData = await putRouteTemplateMarkerService(
            this.$store,
            `id=${this.templateId}&mode=4`,
            _reqBody
          );

          if (responseData["code"] === 200) {
            let newDetailData = null;
            // updateRemaining
            // response must return new google option
            // response must return all posix time
            if (updateRemaining === true) {
              if (idx === 0) {
                this.markerList[idx + 1]["duration"] = null;
                this.markerList[idx + 1]["distance"] = null;
                this.markerList[idx + 1]["gDirection"] = null;
                this.markerList[idx + 1]["parentMarkerId"] = null;
                this.markerList[idx + 1]["order"] = 1;
              } else {
                this.markerList[idx + 1]["duration"] = _reqBody["duration"];
                this.markerList[idx + 1]["distance"] = _reqBody["distance"];
                this.markerList[idx + 1]["durationText"] =
                  processDurationObject(newDurationOfMarker);
                this.markerList[idx + 1]["distanceText"] =
                  processDistanceObject(newDistanceOfMarker);
                this.markerList[idx + 1]["gDirection"] = _reqBody["gDirection"];
                this.markerList[idx + 1]["parentMarkerId"] = _reqBody["paId"];
                this.markerList[idx + 1]["order"] = idx + 1;
              }
              // update new gDirectionOption
              this.markerList[idx + 1]["gDirectionOption"] =
                responseData["data"]["gDirectionOption"];
            }
            // remove current
            this.markerList.splice(idx, 1);
            newDetailData = responseData["data"];
            this.processMarkerList();
            this._processMarkerAction(newDetailData);
          } else {
            // TODO:: dialog error;
            throw "Web Request fail";
          }
        } catch (error) {
          console.error("Delete marker view from process::", error);
        }
        this.componentLoading = false;
        this.isOnOperation = false;
      }
    },

    async copyLocationText(idx) {
      let copyText = null;
      this.componentLoading = true;
      if (!_.isNil(this.markerList[idx]["coordinates"])) {
        try {
          copyText = `${this.markerList[idx]["coordinates"][0]},${this.markerList[idx]["coordinates"][1]}`;
          await navigator.clipboard.writeText(copyText);
          // console.log(`Content copied to clipboard ${copyText}`);
        } catch (err) {
          // console.error("Failed to copy: ", err);
        }
      }
      this.componentLoading = false;
    },
    mapPlotMarker() {
      // set icon to marker
      let processMarker = [];
      let processMapBound = [];
      // process each marker to pin object
      for (let i = 0; i < this.markerList.length; i++) {
        processMarker.push({
          id: `${this.markerList[i].gplaceId}_${new Date().getTime()}`,
          position: {
            lat: this.markerList[i]["coordinates"][0],
            lng: this.markerList[i]["coordinates"][1],
          },
          //
          duration: this.markerList[i]["duration"],
          distance: this.markerList[i]["distance"],
          //
          iconMode: i === 0 ? 0 : i === this.markerList.length - 1 ? 1 : 2,
          icon:
            i === 0
              ? _.clone(CUSTOM_MARKER_ICON.START_POINT)
              : i === this.markerList.length - 1
              ? _.clone(CUSTOM_MARKER_ICON.FINISH_POINT)
              : _.clone(CUSTOM_MARKER_ICON.CHECKPOINT),
          //
          disableAnimation: false,
          vehicleAnchor: false,
        });

        processMapBound.push(this.markerList[i]["coordinates"]);
      }

      // process pin
      for (let i = 0; i < processMarker.length; i++) {
        if (!_.isNil(processMarker[i]["icon"])) {
          let selectedIcon = faLocationPin.icon;
          // set text
          processMarker[i]["label"] = {
            text: String(i + 1),
            fontSize: "18px",
            fontWeight: "700",
            color: "#ffffff",
          };
          // https://stackoverflow.com/questions/33321440/google-maps-api-add-custom-svg-marker-with-label
          processMarker[i]["icon"]["labelOrigin"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1] / 3
            );
          //

          // console.log(processMarker[i]["icon"], selectedIcon);
          processMarker[i]["icon"]["path"] = selectedIcon[4];
          processMarker[i]["icon"]["anchor"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1]
            );
          //
        }
      }
      // return data and update center
      this.$store.dispatch("routeSetting/updateMarkerList", processMarker);
      this.$store.dispatch(
        "routeSetting/updateBoundLatLngCenter",
        processMapBound
      );
    },

    async mapPlotLine() {
      let lines = [];

      for (let i = 0; i < this.markerList.length; i++) {
        if (!_.isNil(this.markerList[i]["gDirection"])) {
          lines.push({
            id: `line_${
              this.markerList[i]["gplaceId"]
            }_${new Date().getTime()}`,
            dashLine: true,
            gDirectionFormat: true, // from server
            ...this.markerList[i]["gDirection"],
          });
        }
      }

      this.$store.dispatch("routeSetting/updatePolyLine", lines);
    },
    // v-card process
    processMarkerList() {
      // clear placeId
      this.clearPlaceIdFromList();
      for (let i = 0; i < this.markerList.length; i++) {
        // get pleceId
        this.addPlaceIdToList(this.markerList[i]["gplaceId"]);
        this.markerList[i]["markerStyle"] =
          i === 0
            ? "marker-start"
            : i === this.markerList.length - 1
            ? "marker-finish"
            : "marker-location";

        this.markerList[i]["markerColor"] = "#659bde";
        //
        this.processMarkerWhenBeformMissing(i);
      }
    },
    processMarkerWhenBeformMissing(markerIndex) {
      this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] = "";
      if (!_.isNil(this.markerList[markerIndex]["checkpointArrivalDuration"])) {
        this.markerList[markerIndex]["checkpointArrivalDurationText"] =
          processDurationText(
            this.markerList[markerIndex]["checkpointArrivalDuration"]
          );
      } else if (
        !_.isNil(
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateSign"
          ]
        )
      ) {
        let displayText = "(";
        if (
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateSign"
          ] === 1
        ) {
          displayText = `${displayText}+`;
          this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] =
            "red--text";
        } else {
          displayText = `${displayText}-`;
          this.markerList[markerIndex]["checkpointArrivalDurationTextStyle"] =
            "green--text";
        }
        displayText = `${displayText}${this.processDurationText(
          this.markerList[markerIndex][
            "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
          ]
        )}`;
        this.markerList[markerIndex][
          "checkpointArrivalDurationText"
        ] = `${displayText})`;
      } else {
        this.markerList[markerIndex]["checkpointArrivalDurationText"] = "-";
      }
    },
    addPlaceIdToList(placeId) {
      this.markerListPlaceId.push(placeId);
    },
    updatePlaceIdToList(idx, placeId) {
      this.markerListPlaceId[idx] = placeId;
    },
    removePlaceIdFromList(idx) {
      this.markerListPlaceId.splice(idx, 1);
    },
    clearPlaceIdFromList() {
      this.markerListPlaceId = [];
    },
    setStatusFromStoreData(newStatusList) {
      if (this.isOnOperation === false) {
        // console.log("Marker Updated");
        if (!_.isNil(newStatusList["markerList"])) {
          for (let i = 0; i < this.markerList.length; i++) {
            let _localMarker =
              newStatusList["markerList"][this.markerList[i]["_id"]];
            this.markerList[i]["closestDistance"] =
              _localMarker["closestDistance"];
            this.markerList[i]["status"] = _localMarker["status"];
            this.markerList[i]["checkpointArrivalDistance"] =
              _localMarker["checkpointArrivalDistance"];
            this.markerList[i]["checkpointArrivalDuration"] =
              _localMarker["checkpointArrivalDuration"];
            this.markerList[i]["checkpointArrivalDate"] =
              _localMarker["checkpointArrivalDate"];
            this.markerList[i]["checkpointArrivalDate"] =
              _localMarker["checkpointArrivalDate"];
            this.markerList[i][
              "checkpointArrivalDifferenceSecondEstimateSign"
            ] =
              _localMarker["checkpointArrivalDifferenceSecondEstimateSign"] ||
              null;
            this.markerList[i][
              "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
            ] =
              _localMarker[
                "checkpointArrivalDifferenceSecondEstimateWithMissingMarker"
              ] || null;
            this.processMarkerWhenBeformMissing(i);
          }
        }
      } else {
        console.log("Marker Under OnOperetation");
      }
    },
  },
  watch: {
    // "$store.state.routeSetting.routeData": {
    //   immediate: false,
    //   handler() {
    //     if (
    //       !_.isNil(this.$store.state.routeSetting.routeData) &&
    //       !this.firstLoad
    //     ) {
    //       this.startComponent();
    //     }
    //   },
    // },
    // "$store.state.routeSetting.reloadMarker": {
    //   immediate: false,
    //   handler() {
    //     this.componentLoading = true;
    //     this.getData();
    //   },
    // },
  },

  components: {
    CustomTextInputImprove,
    CreateMarkerDialog,
    SortMarkerDialog,
    AppDialogConfirm,
    AppOverlay,
    AppOverlayReloadData,
  },
  data() {
    return {
      isOnOperation: false,
      componentLoading: true,
      componentfetchfail: false,
      firstLoad: false,
      jobId: null,
      isPermissionCanEdit: true, //false,
      isPermissionCanView: true,
      isEditView: false,
      //
      markerListDuration: [],
      markerList: [],
      markerListPlaceId: [],
      orderMapping: null,
      ployLine: [],
      googleMapObject: null,
    };
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/views/jobs/manageView/components/marker/MarkerView.scss";
.mapMarkerView {
  height: 100vh;
}
</style>
