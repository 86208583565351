<template>
  <v-container
    name="driverDetailOnMapFloat"
    v-if="$store.state.manageRoute.isPermissionCanView"
  >
    <v-row no-gutters class="px-3">
      <v-col v-if="showSelectedDriver" cols="6" xl="6">
        <div class="d-flex px-3 py-1">
          <div class="d-inline-flex">
            <!-- <v-avatar>
              <img
                :src="jobData.driverId.profileImage || defaultPFImage"
                alt="DriverImage"
              />
            </v-avatar> -->
            <v-list-item-avatar size="60" class="mr-3">
              <v-img :src="jobData.driverId.profileImage || defaultPFImage">
              </v-img>
            </v-list-item-avatar>
            <div class="pl-2">
              <p class="ma-0 text-app-detail font-weight-bold black--text">
                {{ jobData.driverId.dpNameTh }}
                {{
                  jobData.driverId.nickName !== null
                    ? jobData.driverId.nickName.th !== null
                      ? `(${jobData.driverId.nickName.th})`
                      : "-"
                    : "-"
                }}
              </p>
              <p class="ma-0 text-app-small-detail">
                รหัสพนักงาน:
                {{ jobData.driverId.empId }}
              </p>
              <p class="ma-0 text-app-small-detail">
                ติดต่อ:
                <span
                  v-for="(number, idx) in jobData.driverId.phoneNumber"
                  :key="idx"
                >
                  {{ number }}
                  <span v-if="idx < jobData.driverId.phoneNumber.length - 1"
                    >,</span
                  >
                </span>
                <span v-if="jobData.driverId.email"
                  >, {{ jobData.driverId.email }}</span
                >
              </p>
              <p class="ma-0 text-app-small-detail">
                ใบขับขี่:
                <span v-if="jobData.driverId.driverLicence">
                  <span
                    v-for="(licenseData, licenseDataIdx) in jobData.driverId
                      .driverLicence"
                    :key="`driverlicense-${licenseDataIdx}`"
                  >
                    {{ licenseData.type }} หมดอายุ
                    {{
                      dateStringToDateFormat(licenseData.expiryDate, {
                        type: "date",
                        returnThai: false,
                      })
                    }}<span
                      v-if="
                        licenseDataIdx <
                        jobData.driverId.driverLicence.length - 1
                      "
                      >,</span
                    >
                  </span>
                </span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              fab
              dark
              text
              small
              elevation="0"
              color="subtitle"
              v-if="jobData.driverId.canEdit && isPermissionCanEdit"
              @click="addDriverDialog(true)"
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
          </div>
          <div class="px-3">
            <v-btn
              fab
              text
              dark
              small
              elevation="0"
              color="subtitle"
              v-if="jobData.driverId.canEdit && isPermissionCanEdit"
              @click="removeDriverDialog()"
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </div>
        </div>
        <!-- <v-sheet
            outlined
            width="300"
            class="rounded-lg pt-2"
            :color="
              isUsingVehicle
                ? 'primary'
                : vehicleStatus === 1
                ? 'red'
                : 'success'
            "
          > -->
        <v-row no-gutters justify="center" class="pr-6 pt-2">
          <v-col cols="6" class="pr-2">
            <v-sheet
              outlined
              class="rounded-lg pt-2"
              :color="jobData.driverFaceMatched === true ? 'success' : 'error'"
            >
              <v-card outlined elevation="0" class="rounded-b-lg">
                <v-card-text>
                  <v-row>
                    <v-col cols="9">
                      <p
                        class="ma-0 text-app-detail font-weight-bold black--text"
                      >
                        สถานะการสแกนใบหน้า
                      </p>
                      <div class="one_row_only">
                        <span class="text-app-small-detail"> จาก: </span>
                        <span v-if="jobData.driverFaceRefId">
                          <a
                            target="_blank"
                            :href="`/caj/jobs/edit/${jobData.driverFaceRefId._id}`"
                          >
                            <span class="font-weight-bold my-0">{{
                              jobData.driverFaceRefId.name
                            }}</span>
                          </a>
                        </span>
                        <span v-else>-</span>
                      </div>
                    </v-col>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col
                      cols="3"
                      class="align-center d-flex font-weight-bold"
                    >
                      <v-btn
                        @click="openCheckFaceDialog()"
                        icon
                        color="error"
                        outlined
                        :disabled="
                          jobData.driverFaceMatched === null ||
                          jobData.driverFaceMatched === undefined
                        "
                        v-if="
                          jobData.driverFaceMatched === false ||
                          jobData.driverFaceMatched === null ||
                          jobData.driverFaceMatched === undefined
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        @click="openCheckFaceDialog()"
                        icon
                        color="success"
                        outlined
                        v-if="jobData.driverFaceMatched === true"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>
          <v-col cols="6" class="pl-2">
            <v-sheet
              outlined
              class="rounded-lg pt-2"
              :color="
                jobData.driverStatus === true
                  ? 'success'
                  : jobData.driverStatus === false
                  ? 'error'
                  : 'warning'
              "
            >
              <v-card outlined elevation="0" class="rounded-b-lg">
                <v-card-text>
                  <v-row>
                    <v-col cols="9">
                      <p
                        class="ma-0 text-app-detail font-weight-bold black--text"
                      >
                        สถานะความพร้อม
                      </p>
                      <div class="one_row_only">
                        <span class="text-app-small-detail"> จาก: </span>
                        <span v-if="jobData.driverFaceRefId">
                          <a
                            target="_blank"
                            :href="`/caj/jobs/edit/${jobData.driverFaceRefId._id}`"
                          >
                            <span class="font-weight-bold my-0">{{
                              jobData.driverFaceRefId.name
                            }}</span>
                          </a>
                        </span>
                        <span v-else>-</span>
                      </div>
                    </v-col>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col
                      cols="3"
                      class="align-center d-flex font-weight-bold"
                    >
                      <v-btn
                        @click="openCheckFaceDialog()"
                        icon
                        outlined
                        color="warning"
                        :disabled="
                          jobData.status === 'PLANNING' ||
                          jobData.status === 'PREPARING' ||
                          jobData.status === 'CANCEL'
                        "
                        v-if="
                          jobData.driverStatus === null ||
                          jobData.driverStatus === undefined
                        "
                      >
                        <v-icon>mdi-sync</v-icon>
                      </v-btn>
                      <v-btn
                        @click="openCheckFaceDialog()"
                        icon
                        color="success"
                        outlined
                        v-if="jobData.driverStatus === true"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn
                        @click="openCheckFaceDialog()"
                        color="error"
                        icon
                        outlined
                        v-if="jobData.driverStatus === false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
        <!-- <div class="px-4 py-2">
          <v-row dense>
            <v-col cols="4">
              <p class="text-app-small font-weight-light my-0">รหัสพนักงาน</p>
              <p class="text-app-detail font-weight-bold my-0">
                {{ jobData.driverId.empId }}
              </p>
            </v-col>
            <v-col cols="4">
              <p class="text-app-small font-weight-light my-0">
                การตรวจสอบใบหน้า
              </p>
              <a
                class="text-app-detail font-weight-bold my-0 green--text text-decoration-underline"
                v-if="jobData.driverFaceMatched === true"
                @click="openCheckFaceDialog()"
              >
                ผ่าน
              </a>
              <a
                class="text-app-detail font-weight-bold my-0 red--text text-decoration-underline"
                v-else-if="jobData.driverFaceMatched === false"
                @click="openCheckFaceDialog()"
              >
                ไม่ผ่าน
              </a>
              <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
            </v-col>
            <v-col>
              <p class="text-app-small font-weight-light my-0">
                สื่อยืนยันตัวตน
              </p>
              <p
                class="text-app-detail font-weight-bold my-0"
                v-if="!jobData.driverVideoSrc"
              >
                -
              </p>
              <v-icon dense v-if="jobData.driverVideoSrc"> mdi-video </v-icon>
              <a
                target="_blank"
                v-if="jobData.driverVideoSrc"
                :href="jobData.driverVideoSrc"
              >
                <span class="text-app-detail font-weight-bold my-0">แสดง</span>
              </a>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <p class="text-app-small font-weight-light my-0">ใบขับขี่</p>
              <div v-if="jobData.driverId.driverLicence">
                <p
                  class="text-app-small my-0"
                  v-for="(licenseData, licenseDataIdx) in jobData.driverId
                    .driverLicence"
                  :key="`driverlicense-${licenseDataIdx}`"
                >
                  {{ licenseData.type }} หมดอายุ:
                  {{
                    dateStringToDateFormat(licenseData.expiryDate, {
                      type: "date",
                      returnThai: false,
                    })
                  }}
                </p>
              </div>
              <div v-else>-</div>
            </v-col>
            <v-col cols="4" v-if="jobData.driverFaceRefId">
              <p class="text-app-small font-weight-light my-0">อ้างอิงจาก</p>
              <a
                target="_blank"
                :href="`/jobs/edit/${jobData.driverFaceRefId._id}`"
              >
                <span class="text-app-detail font-weight-bold my-0">{{
                  jobData.driverFaceRefId.name
                }}</span>
              </a>
            </v-col>
          </v-row>
        </div> -->

        <!-- <div v-if="$store.state.manageRoute.jobData.driverVideoSrc">
          <video style="width: 100%; max-height: 200px" controls>
            <source
              :src="$store.state.manageRoute.jobData.driverVideoSrc"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div> -->
        <!--  -->
      </v-col>
      <v-col v-else cols="6">
        <v-btn
          outlined
          width="100%"
          height="195"
          color="primary"
          :disabled="!isPermissionCanEdit"
          @click="addDriverDialog(false)"
        >
          <div class="text-app-detail-large">
            <v-icon color="primary">mdi-plus</v-icon>
            <span>เพิ่มผู้ขับ</span>
            <div><v-icon>mdi-account</v-icon></div>
          </div>
        </v-btn>
      </v-col>

      <v-divider class="divider" vertical />
      <v-col v-if="showSelectedVehicle" cols="6" xl="6">
        <v-row>
          <v-col cols="12">
            <!-- <v-row no-gutters> -->
            <div class="d-flex py-1 pl-6">
              <div>
                <p
                  class="ma-0 text-app-detail font-weight-bold black--text"
                  v-if="selectedVehicleDisplay.show"
                >
                  <v-icon
                    large
                    color="blue"
                    v-if="selectedVehicleDisplay.verified"
                  >
                    mdi-check-decagram</v-icon
                  >
                  {{ selectedVehicleDisplay.licenseNumber }}
                  {{ selectedVehicleDisplay.vehicleTypeText }}
                  <v-chip
                    fab
                    small
                    v-if="selectedVehicleDisplay.show"
                    class="text-app-title"
                    :color="selectedVehicleDisplay.statusColor"
                    dark
                  >
                    {{ selectedVehicleDisplay.statusText }}
                  </v-chip>
                </p>

                <p
                  class="ma-0 text-app-detail font-weight-bold black--text"
                  v-if="!selectedVehicleDisplay.show"
                >
                  ไม่มีข้อมูลรถที่เลือก
                </p>
                <span
                  class="text-app-small-detail pr-6"
                  v-if="selectedVehicleDisplay.show"
                >
                  <span class="mx-0 px-0">
                    <v-icon v-if="selectedVehicleDisplay.licenseType == 'WHITE'"
                      >mdi-card-text-outline</v-icon
                    >
                    <v-icon
                      v-else
                      :color="selectedVehicleDisplay.vehicleLicenseTypeColor"
                      >mdi-card-text</v-icon
                    >
                  </span>
                  {{ selectedVehicleDisplay.vehicleLicenseTypeText }}</span
                >
                <span
                  class="text-app-small-detail"
                  v-if="selectedVehicleDisplay.show"
                >
                  <span class="mx-0 px-0">
                    <v-icon>{{
                      selectedVehicleDisplay.vehicleTypeIcon
                    }}</v-icon>
                    {{ selectedVehicleDisplay.vehicleTypeText }}
                  </span>
                  {{ selectedVehicleDisplay.seat }}
                  ที่นั่ง</span
                >
                <div>
                  <span class="text-app-small-detail"> ข้อมูลรถ: </span>
                  <span
                    class="text-app-small-detail"
                    v-if="selectedVehicleDisplay.show"
                  >
                    <span
                      v-if="
                        selectedVehicleDisplay.brand ||
                        selectedVehicleDisplay.model
                      "
                    >
                      <span v-if="selectedVehicleDisplay.brand">{{
                        selectedVehicleDisplay.brand
                      }}</span>
                      <span v-if="selectedVehicleDisplay.model">{{
                        selectedVehicleDisplay.model
                      }}</span>
                    </span>
                    <span v-else> - </span>
                  </span>
                  <span class="text-app-small-detail" v-else>-</span>
                </div>
              </div>

              <v-spacer></v-spacer>
              <div>
                <v-btn
                  fab
                  dark
                  text
                  small
                  elevation="0"
                  color="subtitle"
                  v-if="
                    jobData.actualVehicleConfirmStatus === 2 &&
                    isPermissionCanEdit
                  "
                  @click="addVehicleDialog()"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
              </div>
              <div class="pl-3 pr-0">
                <v-btn
                  fab
                  dark
                  text
                  small
                  elevation="0"
                  color="subtitle"
                  v-if="
                    jobData.actualVehicleConfirmStatus === 2 &&
                    isPermissionCanEdit
                  "
                  @click="removeVehicleDialog()"
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </div>
            </div>
            <!-- </v-row> -->
            <v-divider class="mt-3 ml-5"></v-divider>
            <v-row no-gutters class="pl-2">
              <v-col cols="8" class="px-4 py-1">
                <div>
                  <p
                    class="ma-0 text-app-detail font-weight-bold black--text"
                    v-if="actualVehicleDisplay.show"
                  >
                    <v-icon
                      large
                      color="blue"
                      v-if="actualVehicleDisplay.verified"
                    >
                      mdi-check-decagram</v-icon
                    >
                    {{ actualVehicleDisplay.licenseNumber }}
                    {{ actualVehicleDisplay.vehicleTypeText }}
                  </p>

                  <p
                    class="ma-0 mt-1 text-app-detail font-weight-bold black--text"
                    v-if="!actualVehicleDisplay.show"
                  >
                    ไม่มีข้อมูลรถที่ใช้งานจริง
                  </p>
                  <span
                    class="text-app-small-detail pr-6"
                    v-if="
                      actualVehicleDisplay.show &&
                      actualVehicleDisplay.licenseType
                    "
                  >
                    <span class="mx-0 px-0">
                      <v-icon v-if="actualVehicleDisplay.licenseType == 'WHITE'"
                        >mdi-card-text-outline</v-icon
                      >
                      <v-icon
                        v-else
                        :color="actualVehicleDisplay.vehicleLicenseTypeColor"
                        >mdi-card-text</v-icon
                      >
                    </span>
                    {{ actualVehicleDisplay.vehicleLicenseTypeText }}</span
                  >
                  <span
                    class="text-app-small-detail"
                    v-if="
                      actualVehicleDisplay.show &&
                      actualVehicleDisplay.vehicleTypeText
                    "
                  >
                    <span class="mx-0 px-0">
                      <v-icon>{{
                        actualVehicleDisplay.vehicleTypeIcon
                      }}</v-icon>
                      {{ actualVehicleDisplay.vehicleTypeText }}
                    </span></span
                  >
                  <span
                    class="text-app-small-detail"
                    v-if="
                      actualVehicleDisplay.show && actualVehicleDisplay.seat
                    "
                  >
                    {{ actualVehicleDisplay.seat }}
                    ที่นั่ง</span
                  >
                </div>
                <div>
                  <span class="text-app-small-detail"> ข้อมูลรถ: </span>
                  <span
                    class="text-app-small-detail"
                    v-if="actualVehicleDisplay.show"
                  >
                    <span
                      v-if="
                        actualVehicleDisplay.brand || actualVehicleDisplay.model
                      "
                    >
                      <span v-if="actualVehicleDisplay.brand">{{
                        actualVehicleDisplay.brand
                      }}</span>
                      <span v-if="actualVehicleDisplay.model">{{
                        actualVehicleDisplay.model
                      }}</span>
                    </span>
                    <span v-else>-</span>
                  </span>
                  <span class="text-app-small-detail" v-else>-</span>
                </div>
                <v-spacer></v-spacer>
                <!-- <div>
                  <v-btn
                    fab
                    dark
                    text
                    small
                    elevation="0"
                    color="subtitle"
                    v-if="
                      jobData.actualVehicleConfirmStatus === 2 &&
                      isPermissionCanEdit
                    "
                    @click="addVehicleDialog()"
                  >
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-btn>
                </div>
                <div class="pl-3 pr-0">
                  <v-btn
                    fab
                    dark
                    text
                    small
                    elevation="0"
                    color="subtitle"
                    v-if="
                      jobData.actualVehicleConfirmStatus === 2 &&
                      isPermissionCanEdit
                    "
                    @click="removeVehicleDialog()"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </div> -->
              </v-col>
              <v-col
                cols="4"
                class="pt-8"
                v-if="
                  jobData.actualVehicleConfirmStatus === 1 ||
                  jobData.actualVehicleConfirmStatus === 3
                "
              >
                <p class="text-app-small font-weight-light my-0">
                  รถที่ใช้งานจริง
                </p>
                <p
                  :class="
                    getMatchActualVehicleClass(
                      jobData.actualVehicleConfirmStatus
                    )
                  "
                  v-if="jobData.actualVehicleConfirmStatus === 3"
                >
                  {{
                    getMatchActualVehicleDisplay(
                      jobData.actualVehicleConfirmStatus
                    )
                  }}
                </p>
                <a
                  :class="
                    getMatchActualVehicleClass(
                      jobData.actualVehicleConfirmStatus
                    )
                  "
                  @click="openVerifyVehicle()"
                  v-else-if="jobData.actualVehicleConfirmStatus === 1"
                >
                  {{
                    getMatchActualVehicleDisplay(
                      jobData.actualVehicleConfirmStatus
                    )
                  }}
                </a>
                <p class="text-app-detail font-weight-bold my-0" v-else>
                  -
                </p></v-col
              >
            </v-row>
          </v-col>
          <!-- <v-col cols="4" class="px-0">
            <div class="py-2"> -->
          <!-- <v-row dense>
            <v-col>
              <p class="text-app-small font-weight-light my-0">สถานะ</p>
              <v-chip
                fab
                small
                v-if="selectedVehicleDisplay.show"
                class="text-app-title"
                :color="selectedVehicleDisplay.statusColor"
                dark
              >
                {{ selectedVehicleDisplay.statusText }}
              </v-chip>
              <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
            </v-col>
            <v-col>
              <p class="text-app-small font-weight-light my-0">ข้อมูลรถ</p>
              <p
                class="text-app-detail font-weight-bold my-0"
                v-if="selectedVehicleDisplay.show"
              >
                {{ selectedVehicleDisplay.brand }}
                {{ selectedVehicleDisplay.model }}
              </p>
              <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
            </v-col>
            <v-col>
              <p class="text-app-small font-weight-light my-0">ผู้ขับล่าสุด</p>
              <p class="text-app-detail font-weight-bold my-0">-</p>
            </v-col>
          </v-row> -->
          <!-- <v-col>
                <p class="text-app-small font-weight-light my-0">
                  รถที่ใช้งานจริง
                </p>
                <p
                  :class="
                    getMatchActualVehicleClass(
                      jobData.actualVehicleConfirmStatus
                    )
                  "
                  v-if="jobData.actualVehicleConfirmStatus === 3"
                >
                  {{
                    getMatchActualVehicleDisplay(
                      jobData.actualVehicleConfirmStatus
                    )
                  }}
                </p>
                <a
                  :class="
                    getMatchActualVehicleClass(
                      jobData.actualVehicleConfirmStatus
                    )
                  "
                  @click="openVerifyVehicle()"
                  v-else-if="jobData.actualVehicleConfirmStatus === 1"
                >
                  {{
                    getMatchActualVehicleDisplay(
                      jobData.actualVehicleConfirmStatus
                    )
                  }}
                </a>
                <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
              </v-col> -->
          <!-- <v-col>
              <p class="text-app-small font-weight-light my-0">
                ทะเบียนรถที่ใช้งานจริง
              </p>
              <p
                class="text-app-detail font-weight-bold my-0"
                v-if="actualVehicleDisplay.show"
              >
                {{ actualVehicleDisplay.licenseNumber }}
                <v-icon large color="blue" v-if="actualVehicleDisplay.verified">
                  mdi-check-decagram</v-icon
                >
              </p>
              <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
            </v-col> -->
          <!-- <v-col>
                <p class="text-app-small font-weight-light my-0">ประเภทรถ</p>
                <p
                  class="text-app-detail font-weight-bold my-0"
                  v-if="actualVehicleDisplay.show"
                >
                  <a
                    class="text-app-small-detail font-weight-bold my-0 error--text"
                    v-if="jobData.actualVehicleConfirmStatus === 1"
                    @click="moveToTabAction(1)"
                  >
                    ไม่ตรงกัน โปรดแก้ไขค่าตอบแทน
                  </a>
                </p>
                <p class="text-app-detail font-weight-bold my-0" v-else>-</p>
              </v-col> -->
          <!-- </div>
          </v-col> -->
        </v-row>
      </v-col>

      <v-col v-else cols="6" class="pl-6" align="center">
        <v-btn
          outlined
          width="100%"
          height="195"
          color="primary"
          :disabled="!isPermissionCanEdit || !showSelectedDriver"
          @click="addVehicleDialog()"
        >
          <div class="text-app-detail-large">
            <v-icon color="primary">mdi-plus</v-icon>
            <span>เพิ่มรถ</span>
            <div><v-icon>mdi-car</v-icon></div>
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <AddDriverDialog :key="`dd${refreshCounter}`" ref="addDriverDialog" />
    <AddVehicleDialog :key="`vd${refreshCounter}`" ref="addVehicleDialog" />
    <AppDialogConfirm :key="`dc${refreshCounter}`" ref="removeDialog" />
    <CheckFaceDialog :key="`cf${refreshCounter}`" ref="checkFaceDialog" />
    <VerifyVehicleDialog
      :key="`vvd${refreshCounter}`"
      ref="verifyVehicleDialog"
    />
  </v-container>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import AddDriverDialog from "../dialog/AddDriverDialog.vue";
import AddVehicleDialog from "../dialog/AddVehicleDialog.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import CheckFaceDialog from "../dialog/CheckFaceDialog.vue";
import { dateStringToDateFormat } from "@/services/appDate";

import { mapGetters } from "vuex";
import {
  putVehicleToJobService,
  putDriverToJobService,
  deleteDriverFromJobService,
  deleteVehicleFromJobService,
  putJobCheckFaceService,
  putJobVerifyVehicle,
} from "@/services/api/jobs";
import { DialogType } from "@/services/dialog";
import defaultImage from "@/assets/user.png";
import VerifyVehicleDialog from "../dialog/VerifyVehicleDialog.vue";

export default {
  name: "DriverDetailOnMapFloatComponent",
  data() {
    return {
      refreshCounter: 0,
      defaultPFImage: defaultImage,
      valid: false,
      isContractCompleted: true,
      isConflictVehicle: false,
      isSetDriver: false,
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      isPermissionCanEdit: false,
      defaultVehicleData: {
        show: false,
        verified: false,
        statusColor: null,
        statusText: null,
        licenseNumber: null,
        vehicleTypeText: null,
        licenseType: null,
        vehicleLicenseTypeColor: null,
        vehicleLicenseTypeText: null,
        vehicleTypeIcon: null,
        seat: null,
        sameType: null,
      },
      selectedVehicleDisplay: {
        show: false,
        verified: false,
        statusColor: null,
        statusText: null,
        licenseNumber: null,
        vehicleTypeText: null,
        licenseType: null,
        vehicleLicenseTypeColor: null,
        vehicleLicenseTypeText: null,
        vehicleTypeIcon: null,
        seat: null,
        sameType: null,
      },
      actualVehicleDisplay: {
        show: false,
        verified: false,
        statusColor: null,
        statusText: null,
        licenseNumber: null,
        vehicleTypeText: null,
        licenseType: null,
        vehicleLicenseTypeColor: null,
        vehicleLicenseTypeText: null,
        vehicleTypeIcon: null,
        seat: null,
        sameType: null,
      },
      showSelectedVehicle: false,
      isMounted: true,
    };
  },

  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
    }),
    jobData() {
      // console.log("jobData", this.$store.state.manageRoute.jobData);
      return this.$store.state.manageRoute.jobData;
    },
    showSelectedDriver() {
      return !_.isNil(
        _.get(this.$store, "state.manageRoute.jobData.driverId", null)
      );
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    moveToTabAction(tabIndex) {
      this.$store.commit("manageRoute/setMoveToTab", {
        key: new Date().valueOf(),
        idx: tabIndex,
        p: "editRecompense",
      });
    },
    async addDriverDialog() {
      // this.isDriverAdded = true;
      let driver = await this.$refs.addDriverDialog.open();
      this.$store.dispatch("manageRoute/updateMainLoading", true);
      if (!_.isNil(driver)) {
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData._id,
        }).toString();

        let respData = await putDriverToJobService(this.$store, queryString, {
          driverId: driver["_id"],
        });

        if (respData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
        // reload data
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },
    async addVehicleDialog() {
      let vehicle = await this.$refs.addVehicleDialog.open();
      this.$store.dispatch("manageRoute/updateMainLoading", true);
      if (!_.isNil(vehicle)) {
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData._id,
        }).toString();

        let respData = await putVehicleToJobService(this.$store, queryString, {
          vehicleId: vehicle["_id"],
        });

        if (respData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
        // reload data
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },
    getMatchActualVehicleDisplay(actualVehicleConfirmStatus) {
      const _verified = _.get(
        this.$store,
        "state.manageRoute.jobData.verifiedVehicleId",
        null
      );
      let _statusReturn = "";
      switch (actualVehicleConfirmStatus) {
        case 3:
          _statusReturn += "ตรงกัน";
          break;
        default:
          _statusReturn += "ไม่ตรงกัน";
          break;
      }

      if (!_.isNil(_verified)) {
        _statusReturn += " (ตรวจสอบแล้ว)";
      }

      return _statusReturn;
    },
    getMatchActualVehicleClass(actualVehicleConfirmStatus) {
      const _verified = _.get(
        this.$store,
        "state.manageRoute.jobData.verifiedVehicleId",
        null
      );

      if (actualVehicleConfirmStatus === 3) {
        return "text-app-small font-weight-bold my-0 success--text";
      } else if (actualVehicleConfirmStatus === 1 && !_.isNil(_verified)) {
        return "text-app-small font-weight-bold my-0 success--text";
      } else {
        return "text-app-small font-weight-bold my-0 red--text text-decoration-underline";
      }
    },
    processSelectedVehicleDisplay(_jobData) {
      // console.log("_jobData", _jobData);
      if (!_.isNil(_jobData)) {
        // selected vehicle vehicleId
        this.showSelectedVehicle = false;
        if (!_.isNil(_jobData["vehicleId"])) {
          this.selectedVehicleDisplay = {
            show: true,
            ..._jobData["vehicleId"],
          };

          if (
            this.selectedVehicleDisplay["_id"] === _jobData["verifiedVehicleId"]
          ) {
            this.selectedVehicleDisplay["verified"] = true;
          }

          if (!_.isNil(this.selectedVehicleDisplay["licenseType"])) {
            this.selectedVehicleDisplay["vehicleLicenseTypeColor"] =
              this.$store.getters["vehicle/vehicleLicenseTypeColor"](
                this.selectedVehicleDisplay["licenseType"]
              );
            //
            this.selectedVehicleDisplay["vehicleTypeText"] =
              this.$store.getters["vehicle/vehicleTypeText"](
                this.selectedVehicleDisplay["type"]
              );
            //
            this.selectedVehicleDisplay["vehicleTypeIcon"] =
              this.$store.getters["vehicle/vehicleTypeIcon"](
                this.selectedVehicleDisplay["type"]
              );
          }

          if (!_.isNil(this.selectedVehicleDisplay["licenseType"])) {
            this.selectedVehicleDisplay["vehicleLicenseTypeText"] =
              this.$store.getters["vehicle/vehicleLicenseTypeText"](
                this.selectedVehicleDisplay["licenseType"]
              );
          }

          if (!_.isNil(this.selectedVehicleDisplay["status"])) {
            this.selectedVehicleDisplay["statusColor"] = this.$store.getters[
              "vehicle/vehicleStatusColor"
            ](this.selectedVehicleDisplay["status"]);
            //
            this.selectedVehicleDisplay["statusText"] = this.$store.getters[
              "vehicle/vehicleStatusText"
            ](this.selectedVehicleDisplay["status"]);
          }

          this.showSelectedVehicle = true;
        }

        // actual vehicle (actualVehicleId)
        if (!_.isNil(_jobData["actualVehicleId"])) {
          this.actualVehicleDisplay = {
            show: true,
            ..._jobData["actualVehicleId"],
          };

          if (
            this.actualVehicleDisplay["_id"] === _jobData["verifiedVehicleId"]
          ) {
            this.actualVehicleDisplay["verified"] = true;
          }

          if (!_.isNil(this.actualVehicleDisplay["type"])) {
            this.actualVehicleDisplay["vehicleTypeText"] = this.$store.getters[
              "vehicle/vehicleTypeText"
            ](this.actualVehicleDisplay["type"]);

            this.actualVehicleDisplay["vehicleTypeIcon"] = this.$store.getters[
              "vehicle/vehicleTypeIcon"
            ](this.actualVehicleDisplay["type"]);
          }

          if (!_.isNil(this.actualVehicleDisplay["licenseType"])) {
            this.actualVehicleDisplay["vehicleLicenseTypeColor"] =
              this.$store.getters["vehicle/vehicleLicenseTypeColor"](
                this.actualVehicleDisplay["licenseType"]
              );
            this.actualVehicleDisplay["vehicleLicenseTypeText"] =
              this.$store.getters["vehicle/vehicleLicenseTypeText"](
                this.actualVehicleDisplay["licenseType"]
              );
          }

          this.showSelectedVehicle = true;
        }
        // this.checkCompletedForm();
      }
    },
    checkCompletedForm() {
      if (this.isMounted) {
        if (!_.isNil(this.jobData)) {
          const _verified = _.get(
            this.$store,
            "state.manageRoute.jobData.verifiedVehicleId",
            null
          );
          // console.log("status", this.jobData["status"]);
          if (
            !_.isNil(this.jobData["driverFaceMatched"]) &&
            !_.isNil(this.jobData["driverStatus"]) &&
            !_.isNil(_verified) &&
            (this.jobData["status"] == "PROCESS" ||
              this.jobData["status"] == "SUCCESS")
          ) {
            this.$store.dispatch("manageRoute/updateMoveToTab", 3);
          }
          this.isMounted = false;
        }
      }
    },
    //
    async removeDriverDialog() {
      if (
        await this.$refs.removeDialog.open(
          "ต้องการลบผู้ขับที่ถูกกำหนด ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData["_id"],
        }).toString();

        let responseData = await deleteDriverFromJobService(
          this.$store,
          queryString
        );
        if (responseData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
          this._setVehicleToEmpty();
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },

    async removeVehicleDialog() {
      if (
        await this.$refs.removeDialog.open(
          "ต้องการลบรถที่ถูกกำหนด ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData["_id"],
        }).toString();

        let responseData = await deleteVehicleFromJobService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
          this._setVehicleToEmpty();
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },
    _setVehicleToEmpty() {
      this.selectedVehicleDisplay = _.clone(this.defaultVehicleData);
      this.actualVehicleDisplay = _.clone(this.defaultVehicleData);
      this.showSelectedVehicle = false;
    },

    async openCheckFaceDialog() {
      const dialogResponseData = await this.$refs.checkFaceDialog.open(
        this.$store.state.manageRoute.jobData,
        false
      );
      if (dialogResponseData !== null) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData["_id"],
        }).toString();

        const requestResponseData = await putJobCheckFaceService(
          this.$store,
          queryString,
          dialogResponseData
        );
        //
        if (requestResponseData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },

    async openVerifyVehicle() {
      const dialogResponseData = await this.$refs.verifyVehicleDialog.open(
        this.$store.state.manageRoute.jobData,
        false
      );
      if (dialogResponseData !== null) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData["_id"],
        }).toString();

        const requestResponseData = await putJobVerifyVehicle(
          this.$store,
          queryString,
          {
            verifiedVehicleId: dialogResponseData["_id"],
          }
        );
        //
        if (requestResponseData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
        } else {
          this.$store.dispatch("manageRoute/updateMainLoading", false);
        }
      }
      this.refreshCounter += 1;
      this.$store.dispatch("manageRoute/updateMainLoading", false);
    },
  },

  components: {
    CustomTextInputImprove,
    InputView,
    CustomSelectsImprove,
    AddDriverDialog,
    AddVehicleDialog,
    AppDialogConfirm,
    CheckFaceDialog,
    VerifyVehicleDialog,
  },
  watch: {
    "$store.state.manageRoute.jobData": {
      immediate: false,
      handler(newVal) {
        this.processSelectedVehicleDisplay(newVal);
      },
    },
    "$store.state.manageRoute.isActivateInput": async function (newValue) {
      if (!_.isNil(newValue)) {
        this.isPermissionCanEdit = newValue;
      }
    },
  },
  mounted() {
    // this.isMounted = true;
    this.isPermissionCanEdit = this.$store.state.manageRoute.isActivateInput;
    this.processSelectedVehicleDisplay(this.$store.state.manageRoute.jobData);
  },
};
</script>

<style>
.divider {
  border-width: 1px !important;
  /* border-color: black !important; */
  /* height: 100%; */
}
.one_row_only {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
