<template>
  <div>
    <v-autocomplete
      class="app-custom-input"
      v-model="valueInput"
      :items="itemList"
      :loading="loading"
      :label="label"
      :placeholder="placeholder"
      hide-no-data
      :hide-details="hideNoDetail"
      chips
      multiple
      return-object
      outlined
      dense
      item-value="_id"
      item-text="value"
      :disabled="disabled"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { getContractBySeachingService } from "@/services/api/contract";
import _ from "lodash";
export default {
  name: "EmployeeGroupContractSelectorInput",
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideNoDetail: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    itemList: [],
    loading: false,
  }),

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("searchItemChange", val);
      },
    },
  },

  methods: {
    async callApi() {
      this.loading = true;
      let respData = await getContractBySeachingService(
        this.$store,
        null,
        "list"
      );

      if (respData["code"] === 200) {
        this.itemList = respData["data"];
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },
  },

  mounted() {
    this.callApi();
  },
};
</script>

<style></style>
