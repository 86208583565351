<template>
  <div name="addressForm">
    <v-row no-gutters>
      <v-col cols="12" class="pb-5">
        <!-- <v-card elevation="0" class="pt-4 pb-2 px-3">
          <div class="title-form">{{ title }}</div>
        </v-card> -->
        <v-row v-if="isTitleHighlight" class="px-3 pt-3">
          <v-col cols="12"
            ><v-card color="main-bg-color" flat
              ><v-card-title class="px-6 py-3 text-title font-weight-bold">
                {{ title }}</v-card-title
              >
            </v-card></v-col
          ></v-row
        >
        <div v-else>{{ title }}</div>
      </v-col>
      <v-col cols="12" class="px-3">
        <v-card elevation="0">
          <slot name="checkbox"></slot>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="pl-6">
        <v-card elevation="0" class="px-0">
          <v-row no-gutters>
            <v-col cols="12">
              <CustomTextareaImprove
                v-model="addressInput"
                title="ที่อยู่"
                type="text"
                placeholder="บ้านเลขที่ ห้อง ชั้น ซอย"
                :is-required="isRequired"
                :height="136"
                :disabled="disabled"
                :rules="isRequired ? [(v) => !!v || 'จำเป็นต้องระบุ'] : []"
                :dataInputName="`${prefixName}Location`"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="pr-6">
        <v-card elevation="0" class="px-0">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <CustomAutocomplete
                title="จังหวัด"
                menu-props="auto"
                itemText="nameTh"
                itemValue="provinceId"
                v-model="provinceInput"
                :is-required="isRequired"
                :loading="loading"
                :items="provinceInputList"
                :disabled="disabled"
                :returnObject="true"
                @on-change="changeProvice"
                placeholder="เลือก"
                :dataInputName="`${prefixName}Province`"
              >
              </CustomAutocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <CustomAutocomplete
                title="เขต/อำเภอ"
                menu-props="auto"
                itemText="nameTh"
                itemValue="districtId"
                v-model="districtInput"
                :is-required="isRequired"
                :items="districtInputList"
                :disabled="disabled"
                :returnObject="true"
                :loading="loading"
                @on-change="changeDistrict"
                :noDataText="'เลือกจังหวัดก่อน'"
                placeholder="เลือก"
                :dataInputName="`${prefixName}District`"
              ></CustomAutocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <CustomAutocomplete
                title="แขวง/ตำบล"
                menu-props="auto"
                :is-required="isRequired"
                itemText="nameTh"
                itemValue="subDistrictId"
                v-model="subdistrictInput"
                :disabled="disabled"
                :items="subDistrictInputList"
                :returnObject="true"
                :loading="loading"
                :noDataText="'เลือกจังหวัด / อำเภอ ก่อน'"
                @on-change="changeSubdistrict"
                :dataInputName="`${prefixName}SubDistrict`"
                placeholder="เลือก"
              ></CustomAutocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <CustomSelectsImprove
                title="รหัสไปรษณีย์"
                menu-props="auto"
                v-model="postalCodeInput"
                :is-required="isRequired"
                :items="postalCodeInputList"
                :disabled="disabled"
                placeholder="เลือก"
                :noDataText="'เลือกจังหวัด / อำเภอ / ตำบล ก่อน'"
                :rules="isRequired ? [(v) => !!v || 'จำเป็นต้องระบุ'] : []"
                :dataInputName="`${prefixName}Code`"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="px-3">
        <v-card elevation="0">
          <slot name="fileupload"></slot>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import {
  getDistrictService,
  getSubDistrictService,
  getProvinceService,
} from "@/services/api/app";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";

export default {
  name: "AddressForm",

  components: {
    CustomTextarea,
    CustomAutocomplete,
    CustomSelectsImprove,
    CustomTextareaImprove,
  },

  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    isTitleHighlight: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      require: true,
    },
    addressData: {
      type: [Object, null],
      default: null,
    },
    prefixName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,

      provinceInputList: [],
      districtInputList: [],
      subDistrictInputList: [],
      postalCodeInputList: [],
    };
  },

  watch: {
    addressData(newValue) {
      if (_.isNil(newValue) === false) {
        this.districtInputList = newValue.districtList;
        this.subDistrictInputList = newValue.subDistrictList;
        this.addressInput = newValue.prefix;

        if (_.isNil(newValue["subDistrictId"]) === false) {
          let postalObjectList = this.subDistrictInputList.find(
            (v) => v["subDistrictId"] == newValue["subDistrictId"]
          );
          this.postalCodeInputList = _.clone(postalObjectList.postCodes);
        }
      }
    },
  },

  computed: {
    disabledInput: {
      get() {
        return this.disabled;
      },
      set() {},
    },
    addressInput: {
      get() {
        return this.addressData ? this.addressData.prefix : null;
      },
      set(val) {
        this.addressData.prefix = val;
      },
    },
    provinceInput: {
      get() {
        return this.addressData ? this.addressData.province : null;
      },
      set(val) {
        this.addressData.province = val;
      },
    },
    districtInput: {
      get() {
        return this.addressData ? this.addressData.district : null;
      },
      set(val) {
        this.addressData.district = val;
      },
    },
    subdistrictInput: {
      get() {
        return this.addressData ? this.addressData.subdistrict : null;
      },
      set(val) {
        this.addressData.subdistrict = val;
      },
    },
    postalCodeInput: {
      get() {
        return this.addressData ? this.addressData.postalCode : null;
      },
      set(val) {
        this.addressData.postalCode = val;
      },
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      this.provinceInputList = await getProvinceService(this.$store);
      this.loading = false;
    },

    async changeProvice(provideObj) {
      this.districtInputList = [];
      this.subDistrictInputList = [];
      this.postalCodeInputList = [];
      this.districtInput = null;
      this.subdistrictInput = null;
      this.postalCodeInput = null;

      if (provideObj !== null) {
        this.loading = true;
        this.districtInputList = await getDistrictService(
          this.$store,
          provideObj.provinceId
        );
        this.loading = false;
      }
    },

    async changeDistrict(districtObj) {
      this.subDistrictInputList = [];
      this.postalCodeInputList = [];

      if (districtObj !== null) {
        this.loading = true;
        this.subDistrictInputList = await getSubDistrictService(
          this.$store,
          districtObj.districtId
        );
        this.loading = false;
      }
    },

    changeSubdistrict(subdistrictObj) {
      if (subdistrictObj !== null) {
        this.postalCodeInputList = subdistrictObj.postCodes;
      }
    },

    getCurrentStateData() {
      return {
        ...this.addressData,
        provinceId: !_.isNil(this.provinceInput)
          ? this.provinceInput.provinceId
          : null,
        subDistrictId: !_.isNil(this.subdistrictInput)
          ? this.subdistrictInput.subDistrictId
          : null,
        districtId: !_.isNil(this.districtInput)
          ? this.districtInput.districtId
          : null,
        subDistrictList: this.subDistrictInputList,
        districtList: this.districtInputList,
      };
    },
  },
};
</script>
<style></style>
