<template>
  <div name="headerCardNormal">
    <v-card
      flat
      :color="color"
      :class="rounded ? 'rounded-t-lg' : ''"
      rounded="0"
    >
      <div class="title-section px-9 py-4">{{ title }}</div>
      <div><v-divider></v-divider></div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "headerCardNormal",

  props: {
    title: String,
    rounded: { default: true, type: Boolean },
  },

  data() {
    return {
      color: this.$vuetify.theme.themes.light.primary,
    };
  },
};
</script>
<style scoped>
.title-section {
  font-size: 16px;
  color: white;
  font-weight: bold;
}
</style>
