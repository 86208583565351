<template>
  <v-dialog v-model="show" width="20%" :persistent="true">
    <v-card>
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>รายรับ / ค่าตอบแทน</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="cancelStatus">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-form ref="RecompenseForm" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12">
              <CustomTextInputCurrency
                ref="recompenseInput"
                v-model="formData.recompenseAmount"
                placeholder="ถ้าไม่มี ณ ตอนนี้ ให้ใส่ 0"
                title="ค่าตอบแทนผู้ขับต่อเที่ยว (บาท)"
                :is-required="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :input-view-bottom-margin="false"
                :expend-margin="false"
              >
              </CustomTextInputCurrency>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import _ from "lodash";

export default {
  name: "RecompemseFillDialog",
  components: { CustomTextInputCurrency },
  data() {
    return {
      valid: null,
      resolve: null,
      reject: null,
      show: false,
      //
      formData: {
        recompenseAmount: null,
        incomePerRound: null,
      },
    };
  },
  methods: {
    resetForm() {
      this.formData = {
        recompenseAmount: null,
        incomePerRound: null,
      };
    },
    open(inputData) {
      this.resetForm();
      this.show = true;
      this.formData = _.clone(inputData);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      if (this.$refs["RecompenseForm"].validate() === true) {
        this.resolve(_.clone(this.formData));
        this.$refs["RecompenseForm"].reset();
        this.show = false;
      } else {
        console.error("RECOMPENSE_FORM_INVALIDATE");
      }
    },
    cancelStatus() {
      this.resolve(null);
      this.$refs["RecompenseForm"].reset();
      this.resetForm();
      this.show = false;
    },
  },
};
</script>
