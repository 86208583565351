<template>
  <v-row no-gutters>
    <v-col cols="12" class="pl-6 pr-6">
      <div id="chartLine" class="pl-0">
        <apexchart
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <span
        class="mx-12 px-0 text-app-small-detail font-weight-bold black--text"
      >
        เดือน
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { getShortMonthNameHelper } from "@/services/appDate";
import { numberWithCommasHelper } from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "UserPaymentMonthLineChart",
  props: {
    data: {
      required: true,
    },
  },

  data: () => {
    return {
      series: [],
      categories: [],
    };
  },

  components: {
    apexchart: VueApexCharts,
  },

  mounted() {
    this.processData();
  },

  watch: {
    data: function (newValue) {
      this.processData();
    },
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          id: "line",
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return `${nFormatterHelper(value)}`;
          },
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#567DF4"],
        annotations: {
          yaxis: [
            // {
            //   y: 11000,
            //   strokeDashArray: 5,
            //   borderWidth: 2,
            //   borderColor: "#AAB7B8",
            //   label: {
            //     text: "เฉลี่ย: 11,000 บาท",
            //   },
            // },
          ],
        },

        grid: {
          show: false,
        },
        xaxis: {
          categories: this.categories,
        },

        yaxis: {
          title: {
            text: "จำนวนเงินที่จ่าย",
          },
          labels: {
            formatter: (value) => {
              return nFormatterHelper(value);
            },
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
      };
    },
  },

  methods: {
    processThMonth(dm) {
      try {
        const sdm = dm.split("-");
        const syear = parseInt(sdm[0]) + 543;
        const smonth = getShortMonthNameHelper(parseInt(sdm[1]) - 1);
        return `${smonth} ${syear}`;
      } catch (error) {
        console.error(error);
        return dm;
      }
    },
    processData() {
      try {
        if (!_.isNil(this.data)) {
          const _month = _.get(this.data, "amountPerMonth", []);

          let _tmp = [];
          let _monthYear = [];
          for (let i = 0; i < _month.length; i++) {
            _tmp.push(_month[i]["amount"]);
            const thMonth = this.processThMonth(_month[i]["name"]);
            _monthYear.push(thMonth);
          }

          this.series = [
            {
              name: "จำนวนเงินที่จ่าย",
              data: _tmp,
            },
          ];

          this.categories = _monthYear;
        }
      } catch (error) {
        this.series = [
          {
            name: "จำนวนเงินที่จ่าย",
            data: [],
          },
        ];
        console.error(error);
      }
    },
  },
};
</script>
