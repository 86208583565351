<template>
  <div name="CustomSelectImport" class="px-3">
    <InputView
      :title="title"
      :isRequired="isRequired"
      :bottomMargin="inputViewBottomMargin"
      :small="small"
    >
      <v-select
        v-model="valueInput"
        :class="
          small
            ? 'app-custom-input custom-placeholer-color-sm'
            : 'app-custom-input custom-placeholer-color'
        "
        placeholder="เลือก"
        :no-data-text="noDataText"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        dense
        outlined
        :readonly="readonly"
        :items="items"
        :rules="rules"
        :name="dataInputName"
        :hide-details="hideDetail"
      ></v-select>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "CustomSelectImport",
  components: {
    InputView,
  },
  props: {
    value: {},
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    noDataText: {
      type: String,
      default: "ไม่มีข้อมูล",
    },
    dataInputName: {
      type: String,
      default: "",
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("on-change", val);
      },
    },
  },

  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>
<style></style>
