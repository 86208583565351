<template>
  <div class="mx-3 my-3">
    <v-card width="100%" rounded="lg" elevation="5">
      <v-tabs v-model="viewTab" v-show="!isShowJobsForm">
        <v-tab class="text-app-title font-weight-bold" key="0">
          <div :class="viewTab == 0 ? 'primary--text' : 'black--text'">
            ข้อมูล
          </div>
        </v-tab>
        <v-tab class="text-app-title font-weight-bold" key="1">
          <div :class="viewTab == 1 ? 'primary--text' : 'black--text'">
            รายรับรายจ่าย
          </div>
        </v-tab>
        <v-tab class="text-app-title font-weight-bold" key="2">
          <div :class="viewTab == 2 ? 'primary--text' : 'black--text'">
            ผู้ขับพิเศษ
          </div>
        </v-tab>
        <v-tab class="text-app-title font-weight-bold" key="3">
          <div :class="viewTab == 3 ? 'primary--text' : 'black--text'">
            ป้ายกำกับเวลา
          </div>
        </v-tab>
        <v-tab class="text-app-title font-weight-bold" key="4">
          <div :class="viewTab == 4 ? 'primary--text' : 'black--text'">
            สื่อประกอบ
          </div>
        </v-tab>
        <v-tab class="text-app-title font-weight-bold" key="5">
          <div :class="viewTab == 5 ? 'primary--text' : 'black--text'">
            คู่เส้นทาง
          </div>
        </v-tab>

        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="isShowJobsForm = !isShowJobsForm"
              >
                <v-icon>
                  {{ isShowJobsForm ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>ขยายดูฟอร์ม / ปิดฟอร์ม</span>
        </v-tooltip>
      </v-tabs>

      <v-divider v-show="!isShowJobsForm"></v-divider>
      <v-expand-transition>
        <v-tabs-items v-model="viewTab" v-show="!isShowJobsForm">
          <v-tab-item>
            <v-card flat height="250" rounded="0">
              <RouteTemplateDetailFormDataView />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="250" rounded="0">
              <RouteTemplateIncomeRecompenseTab />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="250" rounded="0" style="overflow-y: scroll">
              <RouteTemplateSpecialDriverTab />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="250" rounded="0" style="overflow-y: scroll">
              <RouteTemplateTimeLabelTab />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="250" rounded="0" style="overflow-y: scroll">
              <RouteTemplateIllustrationTab />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="250" rounded="0" style="overflow-y: scroll">
              <RouteTemplatePairRouteTab />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-expand-transition>
    </v-card>

    <v-expand-transition>
      <v-card
        v-show="isShowJobsForm"
        width="100%"
        rounded="lg"
        elevation="5"
        class="mb-6"
      >
        <v-row class="py-0 px-6">
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-4 px-0">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="isShowJobsForm = !isShowJobsForm"
                >
                  <v-icon>{{
                    isShowJobsForm ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
            <span>ขยายดูฟอร์ม / ปิดฟอร์ม</span>
          </v-tooltip>
        </v-row>
      </v-card>
    </v-expand-transition>
  </div>
</template>
<script>
import RouteTemplateDetailFormDataView from "./detailTab/RouteTemplateDetailFormDataView.vue";
import RouteTemplateIncomeRecompenseTab from "./recompenseTab/RouteTemplateIncomeRecompenseTab.vue";
import RouteTemplateSpecialDriverTab from "./specialDriverTab/RouteTemplateSpecialDriverTab.vue";
import RouteTemplateTimeLabelTab from "./timeLabelTab/RouteTemplateTimeLabelTab.vue";
import RouteTemplateIllustrationTab from "./IllustrationTab/RouteTemplateIllustrationTab.vue";
import RouteTemplatePairRouteTab from "./pairRouteTab/RouteTemplatePairRouteTab.vue";
export default {
  name: "RouteTemplateDetailSubComponent",
  data() {
    return {
      viewTab: 0,
      isShowJobsForm: false,
    };
  },
  components: {
    RouteTemplateDetailFormDataView,
    RouteTemplateIncomeRecompenseTab,
    RouteTemplateSpecialDriverTab,
    RouteTemplateTimeLabelTab,
    RouteTemplateIllustrationTab,
    RouteTemplatePairRouteTab,
  },
};
</script>
<style scoped></style>
