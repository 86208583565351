import vehicleFuelPaymentListVue from "@/views/vehicle/vehicle_fuel_payment/vehicleFuelPaymentList.vue";

export default [
  {
    path: "/vehicle/fuel",
    name: "vehicleFeulList",
    component: vehicleFuelPaymentListVue,
    meta: { requiresAuth: true, topic: "vehicleFuel" },
  },
  // {
  //   path: "/vehicle/fuel/search/:licenseNumber",
  //   name: "vehicleFuelSearchList",
  //   component: vehicleFuelPaymentListVue,
  //   meta: { requiresAuth: true },
  // },
];
