<template>
  <v-dialog v-model="show" width="80%" persistent>
    <v-card v-if="show">
      <!-- <v-toolbar color="white" flat>
        <v-toolbar-title>
          {{ driverItem ? driverItem.fullName : "" }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end">
          <p class="">สถานะปัจจุบัน</p>
        </v-row>
      </v-toolbar> -->
      <v-card-title>
        <v-avatar size="32">
          <img :src="driverItem ? driverItem.profileImage : ''" />
        </v-avatar>
        <p class="mb-0 mx-2">{{ driverItem ? driverItem.fullName : "" }}</p>
        <v-spacer></v-spacer>
        <p class="mb-0">
          <span>
            วิ่ง:
            {{
              sumDriverStatOnSheet[driverId]
                ? sumDriverStatOnSheet[driverId]["jobs"]
                : "-"
            }}
            [{{ currentDriverStatusOnTable["jobs"] }}]
          </span>
          <span class="ml-2">
            ฟรี:
            {{
              sumDriverStatOnSheet[driverId]
                ? sumDriverStatOnSheet[driverId]["free"]
                : "-"
            }}
            [{{ currentDriverStatusOnTable["free"] }}]
          </span>
        </p>
      </v-card-title>
      <v-card-text v-if="fetchDataError == 0">
        <table class="table-checker-main">
          <tbody>
            <tr>
              <td
                class="text-center white--text"
                style="width: 30px; height: 60px; background-color: #7b7f9e"
              >
                วันที่ <br />
                สายวิ่ง
              </td>
              <td
                v-for="(header, idx) in columnsData"
                :key="`dc-${header.row}-${header.col}-${idx}`"
                v-bind:class="[
                  {
                    'table-text-noData': header.noData,
                    'table-text-pair-cols': header.isBetweenPair,
                    'table-data-bg-cols': !header.isBetweenPair,
                  },
                  'text-center',
                ]"
                v-bind:rowspan="header.noData ? rowData.length + 1 : 1"
                style="width: 50px"
              >
                <div v-if="header.noData === 0" style="vertical-align: top">
                  <p
                    :class="[
                      'mb-0',
                      {
                        'red--text':
                          header.outputStartDateLessThanInputStartDate,
                      },
                    ]"
                  >
                    <span class="font-weight-bold">
                      {{ header.type === 1 ? "เข้า" : "ออก" }}</span
                    >
                    {{ header.title }}
                  </p>
                </div>
                <div v-else>
                  <p class="font-weight-bold">
                    {{ header.type === 1 ? "ไม่มีขาเข้า" : "ไม่มีขาออก" }}
                  </p>
                </div>
              </td>
            </tr>
            <tr
              v-for="(rowData, dataRowIdx) in rowData"
              :key="`${rowData.row}-${rowData.col}`"
            >
              <td class="text-center" style="height: 60px">
                <p class="mb-1 text-app-md font-weight-bold">
                  {{ rowData.title }}
                </p>
                <p class="text-app-title font-weight-bold">
                  {{ rowData.date }}
                </p>
              </td>
              <!--  -->
              <td
                v-for="(data, dataColIdx) in rowData.data"
                :key="`dc-${dataRowIdx}r${dataColIdx}`"
                v-bind:class="
                  columnMatch[`r${data['row']}c${data['col']}`]
                    ? columnMatch[`r${data['row']}c${data['col']}`]['className']
                    : ''
                "
                @click="
                  selectedCellHandler(
                    columnMatch[`r${data['row']}c${data['col']}`]
                  )
                "
              >
                <!-- {{ data }} -->
                <p
                  v-if="
                    calculateNumberOfSelectedDriver(
                      columnMatch[`r${data['row']}c${data['col']}`]
                    )
                  "
                  :class="[
                    {
                      'red--text':
                        columnMatch[`r${data['row']}c${data['col']}`][
                          'equalSelectedDriver'
                        ],
                    },
                    'text-center pt-2 mb-1 text-app-detail-md font-weight-bold',
                  ]"
                >
                  {{
                    columnMatch[`r${data["row"]}c${data["col"]}`]["drivers"]
                  }}/{{
                    columnMatch[`r${data["row"]}c${data["col"]}`][
                      "numberOfSelectedDriver"
                    ]
                  }}
                  <span
                    v-if="
                      columnMatch[`r${data['row']}c${data['col']}`]['overlap']
                    "
                  >
                    [!!!]
                  </span>
                </p>
                <p
                  v-else
                  class="text-center pt-2 mb-1 text-app-detail-md font-weight-bold"
                >
                  -
                </p>
                <!-- {{ columnMatch[`r${dataRowIdx}c${dataColIdx}`] }} -->
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <!--  -->
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>
      <!--  -->
      <v-divider v-if="fetchDataError <= 0"></v-divider>
      <v-card-actions v-if="fetchDataError <= 0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndCloseHandler" v-if="fetchDataError <= 0">
          ยกเลิก
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveAndCloseHandler"
          v-if="fetchDataError === 0"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  getCheckDriverOnTableCellService,
  putDriverToMultipleJobService,
} from "@/services/api/assign_multiple_job";
export default {
  name: "AssignMultipleJobDriverCellChecker",
  data: () => ({
    show: false,
    fetchDataError: 1,
    tableId: null,
    driverItem: null,
    columnMatch: null,
    driverInCellOnSheet: [],
    driverId: null,
    currentDriverStatusOnTable: {
      jobs: 0,
      free: 0,
      diff: 0,
      freeX: null,
    },
    cellIdOtherTable: [],
    validateCellData: null,
    sheetId: null,
  }),
  computed: {
    ...mapGetters({
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    tableSheetData: function () {
      return this.$store.state.assignMultipleJob.tableSheetData[this.tableId];
    },
    columnsData: function () {
      return this.$store.state.assignMultipleJob.columnsData[this.tableId];
    },
    rowData: function () {
      return this.$store.state.assignMultipleJob.rowData[this.tableId];
    },
  },
  methods: {
    calculateJobFreeHelper(columnsRowsWithDriverInCell, driverId) {
      let returnData = {
        jobs: 0,
        free: 0,
        diff: 0,
        freeX: null,
      };
      let cJob = 0;
      let cFree = 0;
      for (let j = 0; j < columnsRowsWithDriverInCell.length; j += 2) {
        let _job = 0;
        let _free = 0;

        if (columnsRowsWithDriverInCell[j].includes(driverId)) {
          _job += 1;
        }
        if (columnsRowsWithDriverInCell[j + 1].includes(driverId)) {
          _job += 1;
        }

        if (_job === 1) {
          _free += 1;
        }

        cJob += _job;
        cFree += _free;
      }
      //
      returnData["jobs"] = cJob;
      returnData["free"] = cFree;
      returnData["diff"] = cJob - cFree;
      returnData["freeX"] = cFree > 0 ? 1 / cFree : null;
      //
      return returnData;
    },

    open(tableId, driverItem) {
      this.resetForm();
      this.tableId = tableId;
      this.driverItem = driverItem;
      this.sheetId = this.$route.query.id;
      this.show = true;
      this.processData();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    calculateAndSetSelectedOverlapEvent() {
      //
      try {
        if (this.columnMatch === null) {
          return;
        }

        const _columnMatch = _.cloneDeep(_.values(this.columnMatch));
        let overlapCell = [];

        for (let i = 0; i < _columnMatch.length; i++) {
          // validate job
          let currentClassNameList = [_columnMatch[i]["className"]];
          const jobValidateCell = _.get(
            this.validateCellData,
            `${_columnMatch[i]["cellMId"]}.job`,
            []
          );

          for (let i = 0; i < jobValidateCell.length; i++) {
            // avoid same cellMId
            if (jobValidateCell[i]["matchCellId"] === false) {
              currentClassNameList.push("cell-data-duplicate");
              break;
            }
          }
          _columnMatch[i]["overlap"] = false;
          if (_columnMatch[i]["isSelect"]) {
            const innerTableCell = _.get(
              this.validateCellData,
              `${_columnMatch[i]["cellMId"]}.innerTableCell`,
              []
            );

            overlapCell = _.union(
              overlapCell,
              _.map(innerTableCell, (item) => item.id)
            );
          }

          if (_columnMatch[i]["overlap"] === false) {
            const outerTabelCell = _.get(
              this.validateCellData,
              `${_columnMatch[i]["cellMId"]}.outerTableCell`,
              []
            );

            for (let j = 0; j < outerTabelCell.length; j++) {
              if (outerTabelCell[j]["matchDriverId"] === true) {
                _columnMatch[i]["overlap"] = true;
                break;
              }
            }
          }

          // set class name
          _columnMatch[i]["className"] = currentClassNameList.join(" ");
        }

        for (let i = 0; i < _columnMatch.length; i++) {
          if (_columnMatch[i]["cellMId"] === null) {
            continue;
          }
          //
          if (overlapCell.includes(_columnMatch[i]["cellMId"])) {
            _columnMatch[i]["overlap"] = true;
          }
        }

        // cell overlap
        // for (let i = 0; i < _columnMatch.length; i++) {
        //   if (_columnMatch[i]["cellMId"] === null) {
        //     continue;
        //   }
        //   //
        //   if (overlapCell.includes(_columnMatch[i]["cellMId"])) {
        //     _columnMatch[i]["overlap"] = true;
        //   } else {
        //     _columnMatch[i]["overlap"] = false;
        //   }
        // }
        // // overlap on sheet
        // for (let i = 0; i < _columnMatch.length; i++) {
        //   for (let j = 0; j < this.cellIdOtherTable.length; j++) {
        //     if (this.cellIdOtherTable[j] === null) {
        //       continue;
        //     }

        //     if (
        //       _columnMatch[i]["overlapCell"].includes(
        //         this.cellIdOtherTable[j]["cellMId"]
        //       ) &&
        //       this.cellIdOtherTable[j]["drivers"].includes(this.driverId)
        //     ) {
        //       _columnMatch[i]["overlap"] = true;
        //       break;
        //     }
        //   }
        // }

        for (let i = 0; i < _columnMatch.length; i++) {
          this.columnMatch[_columnMatch[i]["key"]] = _columnMatch[i];
        }
      } catch (err) {
        console.error(error);
        this.fetchDataError = -1;
        return;
      }
    },

    calculateClassNameAtKey(
      cKey,
      isSelect,
      isPairColumns,
      numberOfSelectedDriver
    ) {
      // calculateClassNameAtKey
      let className = ["text-center"];
      let _isSelect = _.isNil(isSelect)
        ? this.columnMatch[cKey]["isSelect"]
        : isSelect;
      let _isPairColumns = _.isNil(isPairColumns)
        ? this.columnMatch[cKey]["isPairColumns"]
        : isPairColumns;
      let _numberOfSelectedDriver = _.isNil(numberOfSelectedDriver)
        ? this.columnMatch[cKey]["numberOfSelectedDriver"]
        : numberOfSelectedDriver;
      //
      if (_isSelect) {
        className.push("table-data-bg-active");
      } else if (_isPairColumns) {
        className.push("table-text-pair-cols");
      } else {
        className.push("table-data-bg-cols");
      }

      if (_numberOfSelectedDriver) {
        className.push("app-link-text");
      }

      return className.join(" ");
    },
    calculateNumberOfSelectedDriver(item) {
      return _.isNil(item) ? false : item["numberOfSelectedDriver"];
    },
    async getData() {
      try {
        const responseData = await getCheckDriverOnTableCellService(
          this.$store,
          new URLSearchParams({
            id: this.tableId,
            driver: this.driverId,
          }).toString()
        );

        if (responseData["code"] === 200) {
          this.validateCellData = responseData["data"];
        } else {
          throw "RESPONSE_FAIL";
        }

        return true;
      } catch (error) {
        console.error(error);
        this.fetchDataError = -2;
        return false;
      }
    },

    async processData() {
      this.driverId = this.driverItem["_id"];
      let _cellIdOtherTable = _.filter(
        _.values(this.$store.state.assignMultipleJob.cellData),
        (item) =>
          item["sheetId"] === this.sheetId && item["tableId"] !== this.tableId
      );

      for (let i = 0; i < _cellIdOtherTable.length; i++) {
        this.cellIdOtherTable.push({
          cellMId: _cellIdOtherTable[i]["cellMId"],
          drivers: _.map(_cellIdOtherTable[i]["drivers"], "_id"),
        });
      }

      const isOk = await this.getData();
      if (!isOk) {
        return;
      }
      //
      let _cellMatch = {};
      let driverInCellOnSheet = [];
      for (let i = 0; i < this.rowData.length; i++) {
        for (let j = 0; j < this.columnsData.length; j++) {
          const cellOnRowData = _.get(
            this.$store.state.assignMultipleJob.cellData,
            `T${this.tableId}R${i}C${j}`,
            null
          );
          const _currentColumn = this.columnsData[j];

          const cKey = `r${i}c${j}`;
          let driverInCell = [];
          if (!_.isNil(cellOnRowData)) {
            const _driverInCell = cellOnRowData["drivers"];
            const isDriverOnCell = _.filter(_driverInCell, {
              _id: this.driverId,
            });

            driverInCell = _.map(_driverInCell, (item) => item._id);
            _cellMatch[cKey] = {
              key: cKey,
              alreadySelect: isDriverOnCell.length > 0 ? true : false,
              isSelect: isDriverOnCell.length,
              isPairColumns: _currentColumn["isBetweenPair"],
              className: this.calculateClassNameAtKey(
                cKey,
                isDriverOnCell.length,
                _currentColumn["isBetweenPair"],
                _.isNil(cellOnRowData["numberOfSelectedDriver"])
                  ? 0
                  : cellOnRowData["numberOfSelectedDriver"]
              ),
              drivers: _driverInCell.length,
              currentDriverList: _driverInCell,
              numberOfSelectedDriver: cellOnRowData["numberOfSelectedDriver"],
              equalSelectedDriver:
                _driverInCell.length !==
                cellOnRowData["numberOfSelectedDriver"],
              ..._.pick(cellOnRowData, [
                "cellMId",
                "rowsCode",
                "tableCellOrder",
                "tableColsId",
                "tableId",
                "tableRowsId",
              ]),
            };
          } else {
            _cellMatch[cKey] = {
              key: cKey,
              alreadySelect: false,
              isSelect: 0,
              isPairColumns: _currentColumn["isBetweenPair"],
              className: this.calculateClassNameAtKey(
                cKey,
                0,
                _currentColumn["isBetweenPair"],
                0
              ),
              drivers: 0,
              numberOfSelectedDriver: 0,
              equalSelectedDriver: null,
            };
          }

          driverInCellOnSheet.push(driverInCell);
        }
        //
      }

      this.columnMatch = _.clone(_cellMatch);
      this.driverInCellOnSheet = driverInCellOnSheet;
      //
      this.currentDriverStatusOnTable = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCellStat.${this.tableId}.${this.driverId}`,
        {}
      );

      this.calculateAndSetSelectedOverlapEvent();

      // ready to render
      setTimeout(() => {
        this.fetchDataError = 0;
      }, 1000);
    },
    resetForm() {
      this.fetchDataError = 1;
      this.tableId = null;
      this.driverItem = null;
    },
    async saveAndCloseHandler() {
      this.fetchDataError = 2;
      // check new and remove cell
      let loopPayload = [];

      const _cM = _.values(this.columnMatch);
      for (let i = 0; i < _cM.length; i++) {
        if (_cM[i]["isSelect"] === true && _cM[i]["alreadySelect"] === false) {
          // isSelect = true and alreadySelect = false ===> add new
          let _drivers = _.concat(_cM[i]["currentDriverList"], [
            { _id: this.driverId },
          ]);

          _drivers = _.map(_drivers, (item) => item["_id"]);

          loopPayload.push({
            cellMId: _cM[i]["cellMId"],
            drivers: _drivers,
            numberOfSelectedDriver: _cM[i]["numberOfSelectedDriver"],
          });
        } else if (
          _cM[i]["isSelect"] === false &&
          _cM[i]["alreadySelect"] === true
        ) {
          // isSelect = false and alreadySelect = true ===> remove
          let _drivers = _.filter(
            _cM[i]["currentDriverList"],
            (item) => item["_id"] !== this.driverId
          );

          _drivers = _.map(_drivers, (item) => item["_id"]);

          loopPayload.push({
            cellMId: _cM[i]["cellMId"],
            drivers: _drivers,
            numberOfSelectedDriver: _cM[i]["numberOfSelectedDriver"],
          });
        }
      }
      // for loop payload
      for (let i = 0; i < loopPayload.length; i++) {
        try {
          const queryString = new URLSearchParams({
            id: loopPayload[i].cellMId,
          }).toString();
          //
          const responseData = await putDriverToMultipleJobService(
            this.$store,
            queryString,
            _.pick(loopPayload[i], ["drivers", "numberOfSelectedDriver"])
          );
          if (responseData["code"] !== 200) {
            throw "Response fail";
          }
        } catch (error) {
          console.error(error);
          this.fetchDataError = -1;
          return;
        }
      }

      this.show = false;
      this.resolve("OK");
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve("!OK");
    },
    // view method
    getTime(dateTime) {
      return moment(dateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    },
    selectedCellHandler(selectedItem) {
      if (selectedItem["numberOfSelectedDriver"] === null) {
        return;
      }

      let newUpdateData = {
        ...this.columnMatch[selectedItem["key"]],
      };

      if (newUpdateData["isSelect"]) {
        newUpdateData = {
          ...newUpdateData,
          isSelect: false,
          drivers: this.columnMatch[selectedItem["key"]]["drivers"] - 1,
        };

        newUpdateData["equalSelectedDriver"];
      } else {
        // remove to select and update cell
        newUpdateData = {
          ...newUpdateData,
          isSelect: true,
          drivers: this.columnMatch[selectedItem["key"]]["drivers"] + 1,
        };
      }

      newUpdateData["className"] = this.calculateClassNameAtKey(
        selectedItem["key"],
        newUpdateData["isSelect"],
        newUpdateData["isPairColumns"],
        newUpdateData["numberOfSelectedDriver"]
      );
      newUpdateData["equalSelectedDriver"] =
        newUpdateData["drivers"] !== newUpdateData["numberOfSelectedDriver"];

      this.columnMatch[selectedItem["key"]] = newUpdateData;
      this.calculateAndSetSelectedOverlapEvent();
    },
  },
};
</script>

<style scoped lang="scss">
table.table-checker-main {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#ccc, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    background: transparentize(#ccc, 0.8);
  }
}

table.table-checker-main tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.table-checker-main,
th,
td {
  background: #f7faff;
  border: 1px solid #c7ced7;
  // border-collapse: collapse;
  // border-top: 0;
}

table.table-checker-main tr td:first-child {
  border-left: none;
}
.table-text-noData {
  vertical-align: middle;
}

.table-text-pair-cols {
  background: #ecf1f8;
}

.table-data-bg-cols {
  background: #ffffff;
}

.table-data-bg-active {
  background: #e1f5fe;
}

.table-data-bg-inactive {
  background: #424242;
}

.cell-data-duplicate {
  border: 2px solid #ff000066;
}
</style>
