<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500">
      <v-card>
        <v-card-title class="text-h5"> ชำระค่างวดรถ </v-card-title>

        <v-card-text>
          <v-col cols="12" class="pt-0 px-0">
            <CustomCardFormViewModern :elevation="0" :isShowTitle="false">
              <v-col class="pt-0 px-0" cols="12">
                <div>
                  <v-chip
                    v-if="docsData.expiry_diff_loan !== null"
                    class="mt-0 pt-1 mx-9 title-input"
                    color="red"
                    label
                    outlined
                  >
                    <v-icon left> mdi-timer-outline </v-icon>
                    ต้องชำระภายใน
                    {{ docsData.expiry_diff_loan }} วัน
                  </v-chip>
                  <v-chip
                    v-if="formData.loanCounter !== null"
                    class="mt-2 pt-1 mx-9 title-input"
                    color="green"
                    label
                    outlined
                  >
                    <v-icon left> mdi-order-bool-descending-variant </v-icon>
                    ชำระไปแล้ว {{ docsData.loanCounter }} งวด
                  </v-chip>
                  <v-divider
                    v-if="
                      (docsData.expiry_diff_loan !== null) &
                      (docsData.loanCounter !== null)
                    "
                    class="my-6"
                  ></v-divider>
                  <!-- <CustomTextInput
                    class="mx-6"
                    ref="loanAmount"
                    v-model="formData.loanAmount"
                    title="ราคาค่างวดรถ (บาท)"
                    placeholder="ราคาค่างวดรถ"
                    :is-required="true"
                    :value="formData.loanAmount"
                  ></CustomTextInput> -->
                  <CustomTextInputCurrency
                    class="mx-6"
                    ref="loanAmount"
                    v-model="formData.loanAmount"
                    title="ราคาค่างวดรถ (บาท)"
                    placeholder="ราคาค่างวดรถ"
                    :is-required="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุราคาค่างวดรถ']"
                  >
                  </CustomTextInputCurrency>

                  <CustomSelectsArrOfObj
                    ref="loanRoundpayDay"
                    v-model="formData.loanRoundpayDay"
                    class="custom-placeholer-color mx-6"
                    :items="dayNumber"
                    :is-required="true"
                    title="วันที่ต้องชำระประจำเดือน"
                    :return-object="true"
                  >
                  </CustomSelectsArrOfObj>
                  <CustomTextInputImprove
                    class="mx-6"
                    ref="financeCompany"
                    v-model="formData.financeCompany"
                    title="ชื่อไฟแนนซ์"
                    placeholder="ชื่อไฟแนนซ์"
                    :is-required="true"
                    :expend-margin="true"
                  ></CustomTextInputImprove>
                </div>
                <!-- <div class="px-6 sub-title-section" v-else>-</div> -->
                <!-- <v-col cols="12" align="center">
                  <DetailButton
                    v-if="formData.loanCounter !== null"
                    class="mt-0"
                    @on-click="handleLoanPayment"
                    >ยืนยันการชำระค่างวดรถ</DetailButton
                  >
                  <DetailButton
                    v-else
                    class="mt-0"
                    @on-click="handleLoanPayment"
                    >เริ่มต้นชำระค่างวดรถ</DetailButton
                  >
                </v-col> -->
              </v-col>
            </CustomCardFormViewModern></v-col
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";

export default {
  name: "VehicleLoanStatusDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    dayNumber: [],
    docsData: {
      loanAmount: null,
      loanCounter: null,
      loanRoundpayDay: null,
      financeCompany: null,
      loanExpiryDate: null,
      expiry_diff_loan: null,
    },
    formData: {
      loanAmount: null,
      loanCounter: null,
      loanRoundpayDay: null,
      financeCompany: null,
      loanExpiryDate: null,
      expiry_diff_loan: null,
    },
  }),
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.getDayNumber();
      this.formData = {
        loanAmount: null,
        loanCounter: null,
        loanRoundpayDay: null,
        financeCompany: null,
        loanExpiryDate: null,
        expiry_diff_loan: null,
      };
    },
    setFormData(item) {
      this.formData["loanAmount"] = item["loanAmount"];
      this.formData["loanCounter"] = item["loanCounter"];
      this.formData["loanRoundpayDay"] = item["loanRoundpayDay"];
      this.formData["financeCompany"] = item["financeCompany"];
      this.formData["loanExpiryDate"] = item["loanExpiryDate"];
      this.formData["expiry_diff_loan"] = item["expiry_diff_loan"];

      this.docsData = _.clone(this.formData);
    },
    getDayNumber() {
      let i = 1;
      while (i <= 31) {
        this.dayNumber.push(i);
        i++;
      }
    },
    open(item) {
      this.resetFormData();
      this.setFormData(item);
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      let _formData = _.clone(this.formData);
      this.resetFormData();
      this.resolve(_formData);
      this.show = false;
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
    CustomSelectsArrOfObj,
    CustomTextInputImprove,
    CustomTextInputCurrency,
  },
};
</script>

<style scoped></style>
