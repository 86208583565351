import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
Vue.use(Vuex);
// nest component
import { employee } from "./employee";
import { worksheet } from "./worksheet";
import { vehicle } from "./vehicle";
import { vehicle_checklist } from "./vehicle_checklist";
import { jobs } from "./jobs";
import { manageRoute } from "./manageRoute";
import { routeSetting } from "./routeSetting";
import { assignMultipleJob } from "./assignMultipleJob";
import { jobsMap } from "./jobsMap";
import { jobsMonitoring } from "./jobsMonitoring";

export default new Vuex.Store({
  state: {
    initStoreCompleted: false,
    userInfo: null,
    accessToken: null,
    refreshToken: null,
    refreshingAccessToken: false,
    forceToLogin: false,

    appDrawer: true,
    showErrorDialog: false,
    errorMessage: "",

    appProvince: [],
    preSearchAndFilter: null,
    reloadViewKey: 1,
  },
  getters: {
    initStoreCompleted(state) {
      return state.initStoreCompleted;
    },
    userInfo(state) {
      return state.userInfo;
    },
    accessToken(state) {
      return state.accessToken;
    },
    refreshToken(state) {
      return state.refreshToken;
    },
    refreshingAccessToken(state) {
      return state.refreshingAccessToken;
    },
    forceToLogin(state) {
      return state.forceToLogin;
    },
    //
    appDrawer(state) {
      return state.appDrawer;
    },
    showErrorDialog(state) {
      return state.showErrorDialog;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    appProvince(state) {
      return state.appProvince;
    },
  },
  mutations: {
    setInitStoreCompleted(state, initStoreCompleted) {
      state.initStoreCompleted = initStoreCompleted;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setRefreshingAccessToken(state, refreshingAccessToken) {
      state.refreshingAccessToken = refreshingAccessToken;
    },
    setForceToLogin(state, forceToLogin) {
      state.forceToLogin = forceToLogin;
    },
    toggleAppDrawer(state) {
      state.appDrawer = !state.appDrawer;
    },
    setAppDrawer(state, status) {
      state.appDrawer = status;
    },
    setShowErrorDialog(state, showErrorDialog) {
      state.showErrorDialog = showErrorDialog;
    },
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setAppProvince(state, province) {
      state.appProvince = province;
    },
    setReloadViewKey(state) {
      state.reloadViewKey += 1;
    },
  },
  actions: {
    toggleDrawerVuex({ commit }) {
      commit("toggleAppDrawer");
    },
    updateDrawerVuex({ commit }, payload) {
      commit("setAppDrawer", payload);
    },
    updateReloadViewKey({ commit }) {
      commit("setReloadViewKey");
    },
  },
  modules: {
    employee,
    worksheet,
    vehicle,
    vehicle_checklist,
    jobs,
    manageRoute,
    routeSetting,
    assignMultipleJob,
    jobsMap,
    jobsMonitoring,
  },
});
