<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import {
  getGoogleHeatMap,
  getLocationAutocomplete,
  getMapDirection,
  getNewGoogleMapObj,
  getPlace,
  getPlaceByGeocoder,
} from "./GoogleMapService";
import { MAP_STYLE_SETTINGS } from "@/services/map/mapSettings";
// import ThreeJSOverlayView from "@ubilabs/threejs-overlay-view";
// import { CatmullRomCurve3, Vector3 } from "three";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { Line2 } from "three/examples/jsm/lines/Line2.js";
// import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
// import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
// import CAR_MODEL_URL from "@/assets/lowpoly-sedan.glb";
// import { getMapsApiOptions, loadMapsApi } from "@/services/loadMapApi";

export default {
  name: "GoogleMapLoader",
  props: {
    mapConfig: {
      type: [Object, null],
      default: () => {},
    },
    googleAPIKey: {
      type: String,
    },
    vuexStatusActionName: {
      type: [String, null],
      default: null,
    },
    // main route
    boundPloyLine: {
      type: [Array, null],
      default: null,
    },
    // bound center
    boundLatLngCenterList: {
      type: [Array, null],
      default: null,
    },
    vuexStoreName: {
      type: [String, null],
      default: "manageRoute",
    },
  },

  data() {
    return {
      google: null,
      map: null,
      heapMapData: null,
    };
  },

  watch: {
    boundPloyLine: function (newData) {
      this.updateBoundMap(newData);
    },
    boundLatLngCenterList: function (newData) {
      this.processLatLngBound(newData);
    },
    mapConfig: function () {
      this.processMapSetting(this.mapConfig);
    },
  },

  async mounted() {
    this.google = await getNewGoogleMapObj();
    this.initializeMap();
    // this.overlay();
    // this.carModel = CAR_MODEL_URL;
  },

  methods: {
    async initializeMap() {
      const mapConfiguration = {
        // mapId: process.env.VUE_APP_GMAP_ID,
        disableDefaultUI: true,
        backgroundColor: "transparent",
        gestureHandling: "greedy",
        zoom: 15,
        ...MAP_STYLE_SETTINGS,
        ...this.mapConfig,
      };
      //
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, mapConfiguration);

      // wait for init
      this.google.maps.event.addListenerOnce(this.map, "idle", () => {
        // call vuex status
        this.processMapSetting(this.mapConfig);
        this.$store.dispatch(
          `${this.vuexStoreName}/${this.vuexStatusActionName}`,
          true
        );
      });
      //
      // var myoverlay = new google.maps.OverlayView();
      // myoverlay.draw = function () {
      //   console.log(this.getPanes().markerLayer);
      //   this.getPanes().markerLayer.id = "markerLayer";
      // };
      // myoverlay.setMap(this.map);
    },

    //
    async setCenterFromLocation(_location, isRawLocation) {
      if (this.map) {
        if (isRawLocation) {
          _location = new this.google.maps.LatLng(_location.lat, _location.lng);
        }
        this.map.setCenter(_location);
      }
    },

    processListOfLocationToGoogleObject(_location) {
      if (!(_location instanceof this.google.maps.LatLng)) {
        return new this.google.maps.LatLng(_location[0], _location[1]);
      }
      return _location;
    },

    processLatLngBound(latlngArr) {
      if (this.map) {
        let bounds = new this.google.maps.LatLngBounds();
        for (let i = 0; i < latlngArr.length; i++) {
          let latlng = new google.maps.LatLng(latlngArr[i][0], latlngArr[i][1]);
          bounds.extend(latlng);
        }
        //
        this.map.fitBounds(bounds);
        // this.map.initialZoom = true;
        let _sumDistance = 0;
        let zoomLevel = 13;

        for (
          let i = 0;
          i < this.$store.state[this.vuexStoreName].markerList.length;
          i++
        ) {
          if (
            !_.isNil(
              this.$store.state[this.vuexStoreName].markerList[i]["distance"]
            )
          ) {
            _sumDistance +=
              this.$store.state[this.vuexStoreName].markerList[i]["distance"];
          }
        }
        _sumDistance = _sumDistance / 1000;
        if (_sumDistance > 50) {
          zoomLevel = 10;
        } else if (_sumDistance > 20) {
          zoomLevel = 11;
        } else {
          zoomLevel = 15;
        }
        // console.log(`processLatLngBound => set new ${zoomLevel}`);
        this.map.setZoom(zoomLevel);
      }
    },

    processMapSetting(settingObject) {
      // check map existing
      if (this.map === null) {
        return;
      }

      if (!_.isNil(settingObject["center"])) {
        this.map.setCenter(
          new this.google.maps.LatLng(
            settingObject["center"]["lat"],
            settingObject["center"]["lng"]
          )
        );
      }

      if (!_.isNil(settingObject["zoom"])) {
        this.map.setZoom(settingObject["zoom"]);
      }
    },

    // for direction TODO:: soon
    updateBoundMap(_param) {
      // this function set center
      // TODO:: later
      if (this.map) {
        let bounds = new this.google.maps.LatLngBounds();
        bounds.extend(_param["northeast"]);
        bounds.extend(_param["southwest"]);

        // https://stackoverflow.com/questions/16180104/get-a-polyline-from-google-maps-directions-v3
        // this.map.initialZoom = true;
        this.map.fitBounds(bounds);
      }
    },

    // get direction by google services.
    async getDirectionByGService(origin, destination, options) {
      return getMapDirection(this.google, origin, destination, options);
    },

    // get place by google services.
    async getPlaceByGService(queryStr) {
      return getPlace(this.google, queryStr);
    },

    async getPlaceUsePlaceIdByGService(queryStr) {
      return getPlaceByGeocoder(this.google, queryStr);
    },

    convertLatLngByGService(lat, lng) {
      return new this.google.maps.LatLng(lat, lng);
    },

    // get auto complete by google service.
    async getAutoCompleteGService(queryStr) {
      return getLocationAutocomplete(this.google, queryStr);
    },

    setCenterByCoordinateList(mapPositionList) {
      let bounds = new this.google.maps.LatLngBounds();

      for (var i = 0; i < mapPositionList.length; i++) {
        bounds.extend({
          lat: mapPositionList[i][0],
          lng: mapPositionList[i][1],
        });
      }
      this.map.fitBounds(bounds);
    },

    setHeatMap(mapPositionList, heatList) {
      if (this.heapMapData !== null) {
        this.heapMapData.setMap(null);
      }
      this.heapMapData = getGoogleHeatMap(
        this.google,
        this.map,
        mapPositionList,
        heatList
      );
    },

    // overlay() {
    // this function create line
    // const CAR_FRONT = new Vector3(0, 1, 0);
    // // TODO
    // const overlay = new ThreeJSOverlayView(this.mapConfig.center);
    // const scene = overlay.getScene();
    // overlay.setMap(this.map);

    // const points = ANIMATION_POINTS.map((p) => overlay.latLngAltToVector3(p));
    // const curve = new CatmullRomCurve3(points, true, "catmullrom", 0.2);
    // curve.updateArcLengths();

    // // create track line
    // const trackLine = this.createTrackLine(curve);

    // let carModel = null;
    // // this.loadCarModel().then((obj) => {
    // //   carModel = obj;
    // //   scene.add(carModel);

    // //   // since loading the car-model happened asynchronously, we need to
    // //   // explicitly trigger a redraw.
    // //   overlay.requestRedraw();
    // // });

    // // the update-function will animate the car along the spline
    // overlay.update = () => {
    //   trackLine.material.resolution.copy(overlay.getViewportSize());
    //   // console.log("carModel", carModel);
    //   if (!carModel) return;

    //   const animationProgress =
    //     (performance.now() % ANIMATION_DURATION) / ANIMATION_DURATION;

    //   curve.getPointAt(animationProgress, carModel.position);
    //   curve.getTangentAt(animationProgress, tmpVec3);
    //   carModel.quaternion.setFromUnitVectors(CAR_FRONT, tmpVec3);

    //   overlay.requestRedraw();
    // };
    // },
    // createTrackLine(curve) {
    //   const numPoints = 10 * curve.points.length;
    //   const curvePoints = curve.getSpacedPoints(numPoints);
    //   const positions = new Float32Array(numPoints * 3);

    //   for (let i = 0; i < numPoints; i++) {
    //     curvePoints[i].toArray(positions, 3 * i);
    //   }

    //   const trackLine = new Line2(
    //     new LineGeometry(),
    //     new LineMaterial({
    //       color: 0x0f9d58,
    //       linewidth: 5,
    //     })
    //   );

    //   trackLine.geometry.setPositions(positions);

    //   return trackLine;
    // },
    // loadCarModel() {
    //   const loader = new GLTFLoader();

    //   return new Promise((resolve) => {
    //     loader.load(CAR_MODEL_URL, (gltf) => {
    //       const group = gltf.scene;
    //       const carModel = group.getObjectByName("sedan");

    //       carModel.scale.setScalar(3);
    //       carModel.rotation.set(Math.PI / 2, 0, Math.PI, "ZXY");

    //       resolve(group);
    //     });
    //   });
    // },
  },

  destroyed() {},
};
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
