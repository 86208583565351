<template>
  <div name="duplicateJobSelectDriverAndVehicleSection">
    <DuplicateDriverDialog
      :key="`ldd${refreshCounter}`"
      ref="addDriverDialog"
    />

    <DuplicateVehicleDialog
      :key="`lvd${refreshCounter}`"
      ref="addVehicleDialog"
    />

    <v-row dense>
      <v-col v-if="showSelectedDriver" cols="6">
        <div class="d-flex px-3 py-1">
          <div class="d-inline-flex">
            <v-avatar>
              <img
                :src="jobData.driverId.profileImage || defaultPFImage"
                alt="DriverImage"
              />
            </v-avatar>
            <div class="pl-2">
              <p class="ma-0 text-app-detail font-weight-bold black--text">
                {{ jobData.driverId.dpNameTh }}
              </p>
              <p class="ma-0 text-app-small-detail">
                เบอร์โทร:
                {{ jobData.driverId.dpPhoneNumeber }}
              </p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              fab
              dark
              text
              small
              elevation="0"
              color="subtitle"
              @click="addDriverDialog(true)"
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="px-4 py-2">
          <v-row dense>
            <v-col cols="3">
              <p class="text-app-small font-weight-light my-0">รหัสพนักงาน</p>
              <p class="text-app-detail font-weight-bold my-0">
                {{ jobData.driverId.empId }}
              </p>
            </v-col>
            <v-col cols="9">
              <p class="text-app-small font-weight-light my-0">ใบขับขี่</p>
              <div v-if="jobData.driverId.driverLicence">
                <p
                  class="text-app-small my-0"
                  v-for="(licenseData, licenseDataIdx) in jobData.driverId
                    .driverLicence"
                  :key="`driverlicense-${licenseDataIdx}`"
                >
                  {{ licenseData.type }} หมดอายุ:
                  {{
                    dateStringToDateFormat(licenseData.expiryDate, {
                      type: "date",
                      returnThai: false,
                    })
                  }}
                </p>
              </div>
              <div v-else>-</div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-else cols="6">
        <v-btn
          width="100%"
          height="100%"
          color="primary"
          @click="addDriverDialog(false)"
        >
          <div class="text-app-detail-large">
            <v-icon color="primary">mdi-plus</v-icon>
            <span>เพิ่มผู้ขับ</span>
            <div><v-icon>mdi-account</v-icon></div>
          </div>
        </v-btn>
      </v-col>
      <v-divider class="divider" vertical />
      <v-col v-if="showSelectedVehicle" cols="6">
        <div class="d-flex px-4 py-1">
          <div>
            <p class="ma-0 text-app-detail font-weight-bold black--text">
              {{ selectedVehicleDisplay.licenseNumber }}
              {{ selectedVehicleDisplay.vehicleTypeText }}
              <v-icon large color="blue" v-if="selectedVehicleDisplay.verified">
                mdi-check-decagram</v-icon
              >
            </p>

            <span class="text-app-small-detail pr-6">
              <span class="mx-0 px-0">
                <v-icon v-if="selectedVehicleDisplay.licenseType == 'WHITE'"
                  >mdi-card-text-outline</v-icon
                >
                <v-icon
                  v-else
                  :color="selectedVehicleDisplay.vehicleLicenseTypeColor"
                  >mdi-card-text</v-icon
                >
              </span>
              {{ selectedVehicleDisplay.vehicleLicenseTypeText }}</span
            >
            <span class="text-app-small-detail">
              <span class="mx-0 px-0">
                <v-icon>{{ selectedVehicleDisplay.vehicleTypeIcon }}</v-icon>
                {{ selectedVehicleDisplay.vehicleTypeText }}
              </span>
              {{ selectedVehicleDisplay.seat }}
              ที่นั่ง</span
            >
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              fab
              dark
              text
              small
              elevation="0"
              color="subtitle"
              @click="addVehicleDialog()"
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-else cols="6">
        <v-btn
          outlined
          width="100%"
          height="100%"
          color="primary"
          @click="addVehicleDialog()"
        >
          <div class="text-app-detail-large">
            <v-icon color="primary">mdi-plus</v-icon>
            <span>เพิ่มรถ</span>
            <div><v-icon>mdi-car</v-icon></div>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DuplicateDriverDialog from "./DuplicateDriverDialog.vue";
import DuplicateVehicleDialog from "./DuplicateVehicleDialog.vue";
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  name: "DuplicateJobSelectDriverAndVehicleSection",
  components: { DuplicateDriverDialog, DuplicateVehicleDialog },
  data() {
    return {
      refreshCounter: 0,
      showSelectedVehicle: false,
      selectedVehicleDisplay: null,
    };
  },
  watch: {
    "$store.state.jobs.jobData": {
      immediate: true,
      handler(newVal) {
        this.processSelectedVehicleDisplay(newVal);
      },
    },
  },
  computed: {
    jobData() {
      return this.$store.state.jobs.jobData;
    },
    showSelectedDriver() {
      return !_.isNil(_.get(this.$store, "state.jobs.jobData.driverId", null));
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    async addDriverDialog() {
      let driver = await this.$refs.addDriverDialog.open();
      if (driver !== null) {
        this.$store.dispatch("jobs/updateJobData", { driverId: driver });
        this.$store.dispatch("jobs/updateRefreshFormDuplicateCounter");
      }
    },
    async addVehicleDialog() {
      let vehicle = await this.$refs.addVehicleDialog.open();
      if (vehicle !== null) {
        this.$store.dispatch("jobs/updateJobData", { vehicleId: vehicle });
        this.$store.dispatch("jobs/updateRefreshFormDuplicateCounter");
      }
    },
    processSelectedVehicleDisplay(_jobData) {
      // selected vehicle vehicleId
      if (!_.isNil(_jobData["vehicleId"])) {
        this.selectedVehicleDisplay = {
          show: true,
          ..._jobData["vehicleId"],
        };

        if (!_.isNil(this.selectedVehicleDisplay["licenseType"])) {
          this.selectedVehicleDisplay["vehicleLicenseTypeColor"] =
            this.$store.getters["vehicle/vehicleLicenseTypeColor"](
              this.selectedVehicleDisplay["licenseType"]
            );
          //
          this.selectedVehicleDisplay["vehicleTypeText"] = this.$store.getters[
            "vehicle/vehicleTypeText"
          ](this.selectedVehicleDisplay["type"]);
          //
          this.selectedVehicleDisplay["vehicleTypeIcon"] = this.$store.getters[
            "vehicle/vehicleTypeIcon"
          ](this.selectedVehicleDisplay["type"]);
        }

        if (!_.isNil(this.selectedVehicleDisplay["licenseType"])) {
          this.selectedVehicleDisplay["vehicleLicenseTypeText"] =
            this.$store.getters["vehicle/vehicleLicenseTypeText"](
              this.selectedVehicleDisplay["licenseType"]
            );
        }

        if (!_.isNil(this.selectedVehicleDisplay["status"])) {
          this.selectedVehicleDisplay["statusColor"] = this.$store.getters[
            "vehicle/vehicleStatusColor"
          ](this.selectedVehicleDisplay["status"]);
          //
          this.selectedVehicleDisplay["statusText"] = this.$store.getters[
            "vehicle/vehicleStatusText"
          ](this.selectedVehicleDisplay["status"]);
        }

        this.showSelectedVehicle = true;
      }
    },
  },
};
</script>
<style scoped></style>
