<template>
  <div>
    <div class="pl-4">
      <span
        v-for="(legend, idx) in serires"
        :key="idx"
        :style="{ color: `${chartOptions.fill.colors[idx]}` }"
        class="text-app-detail-md"
        >{{ legend.name }}
        <span class="text-app-detail-normal" v-if="idx < serires.length - 1">
          |
        </span>
      </span>
    </div>

    <apexchart
      height="90"
      :options="chartOptions"
      :series="serires"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";

export default {
  name: "VehicleEventTypeSbarChart",
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      serires: [],
    };
  },
  computed: {
    ...mapGetters({
      vehicleEventTypeValue: "vehicle/vehicleEventTypeValue",
    }),
    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          offsetY: -20,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (val) {
              return val + " ครั้ง";
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#567DF4", "#AAB7B8", "#AAB7B8", "#AAB7B8"], //["#B71C1C", "#4A148C", "#E65100", "#0D47A1"],
        },
        legend: {
          show: false,
        },
      };
    },
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        const vehicleEventType = _.get(
          this.data,
          "events.vehicleEventType",
          []
        );

        let _tmp = [];

        const sortedVet = _.orderBy(
          _.map(vehicleEventType, (value, type) => ({ type, value })),
          ["value"],
          ["desc"]
        );

        for (let i = 0; i < sortedVet.length; i++) {
          if (sortedVet[i]["value"] !== 0) {
            _tmp.push({
              name: this.vehicleEventTypeValue(sortedVet[i]["type"]),
              data: [sortedVet[i]["value"]],
            });
          }
        }

        // for (const [key, value] of Object.entries(vehicleEventType)) {
        //   if (value !== 0) {
        //     _tmp.push({
        //       name: this.vehicleEventTypeValue(key),
        //       data: [value],
        //     });
        //   }
        // }
        this.serires = _tmp;
      }
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  mounted() {
    this.processData();
  },
};
</script>
