<template>
  <v-dialog v-model="show" width="85%" :persistent="true">
    <v-card class="card-bg" v-if="!dialogError && show">
      <AppOverlay :absolute="false" :isLoading="componentLoading" />

      <v-app-bar elevation="0" class="card-bg" color="transparent">
        <v-toolbar-title class="text-h6"> เพิ่มรถ </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="cancelStatus()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col lg="4" cols="12" class="px-0">
            <v-row dense class="px-3">
              <v-col cols="12" lg="6" md="6" sm="12">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาเริ่มงาน"
                  title="เวลาเริ่มงาน"
                  v-model="formData.startDate"
                  @input="processStartDateFromPicker"
                  :isRequired="false"
                  :hideDetail="true"
                  :clearable="false"
                  :expendMargin="false"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาจบงาน"
                  title="เวลาจบงาน"
                  v-model="formData.endDate"
                  @input="processEndDateFromPicker"
                  :isRequired="false"
                  :minDate="today"
                  :hideDetail="true"
                  :clearable="false"
                  :expendMargin="false"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12">
                <InputView title="ค้นหารถ" :isRequired="false">
                  <v-text-field
                    class="white"
                    outlined
                    dense
                    placeholder="พิมพ์ชื่อและกด Enter"
                    v-model="searchVehicleValue"
                    @keyup="EnterToSearchDriverHandler"
                    :disabled="isStartEndDateFiled"
                    prepend-inner-icon="mdi-magnify"
                    hide-details=""
                    @click:clear="clearInput()"
                  ></v-text-field>
                </InputView>
              </v-col>
            </v-row>
            <v-row class="pr-0 mt-3">
              <v-col cols="12" class="px-0 py-0 my-0">
                <v-list
                  v-if="vehicles.length !== 0"
                  dense
                  style="overflow-y: auto"
                  class="card-bg white-list"
                  transition="scale-transition"
                >
                  <v-list-item-group v-model="selectedValueIdxList">
                    <div v-for="(vehicle, i) in vehicles" :key="`ve${i}`">
                      <v-list-item two-line class="px-3">
                        <v-list-item-content>
                          <v-col cols="12" lg="12" sm="12" class="py-1 d-flex">
                            <div>
                              <p
                                class="ma-0 text-app-detail font-weight-bold black--text"
                              >
                                {{ vehicle.licenseNumber }}
                                {{ vehicleTypeText(vehicle.type) }}
                              </p>

                              <span class="text-app-small-detail pr-6">
                                <span class="mx-0 px-0">
                                  <v-icon
                                    v-if="
                                      vehicleLicenseTypeColor(
                                        vehicle.licenseType
                                      ) === 'white'
                                    "
                                    >mdi-card-text-outline</v-icon
                                  >
                                  <v-icon
                                    v-else
                                    :color="
                                      vehicleLicenseTypeColor(
                                        vehicle.licenseType
                                      )
                                    "
                                    >mdi-card-text</v-icon
                                  >
                                </span>
                                {{
                                  vehicleLicenseTypeText(vehicle.licenseType)
                                }}</span
                              >

                              <span class="text-app-small-detail pr-6">
                                <span class="mx-0 px-0">
                                  <v-icon>mdi-bus</v-icon>
                                </span>
                                {{
                                  vehicle.seat
                                    ? `${vehicle.seat} ที่นั่ง`
                                    : "ไม่มีข้อมูลจำนวนที่นั่ง"
                                }}</span
                              >

                              <v-chip
                                small
                                label
                                color="primary"
                                v-if="vehicle.ownedVehicle"
                              >
                                รถประจำตัว
                              </v-chip>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="align-self-center" align="right">
                              <v-chip
                                small
                                color="success"
                                v-if="vehicle.available"
                              >
                                ไม่ถูกกำหนดงาน
                              </v-chip>
                              <v-chip
                                small
                                color="error"
                                v-if="!vehicle.available"
                              >
                                ถูกกำหนดงาน
                              </v-chip>
                            </div>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="i < vehicles.length - 1"
                        :key="`dv${i}`"
                      ></v-divider>
                    </div>
                  </v-list-item-group>
                </v-list>

                <v-card
                  v-else
                  elevation="0"
                  class="d-flex justify-center align-center card-bg"
                >
                  <p
                    v-if="componentLoading"
                    class="text-app-detail-normal align-stretch"
                  >
                    กำลังดึงข้อมูล...
                  </p>
                  <p
                    v-else-if="isStartEndDateFiled"
                    class="text-app-detail-normal align-stretch red--text"
                  >
                    โปรดระบุเวลาเริ่มงานและเวลาจบงานในแท็บข้อมูลงาน
                  </p>
                  <p v-else class="text-app-detail-normal align-stretch">
                    ไม่มีข้อมูล
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col lg="8" cols="12">
            <v-card class="mb-2" outlined v-if="selectedVehicle !== null">
              <v-card-text class="d-flex">
                <div>
                  <p class="ma-0 text-app-detail font-weight-bold black--text">
                    รถที่ถูกเลือก: {{ selectedVehicle.licenseNumber }}
                    {{ vehicleTypeText(selectedVehicle.type) }}
                  </p>
                  <span class="text-app-small-detail pr-6">
                    <span class="mx-0 px-0">
                      <v-icon
                        v-if="
                          vehicleLicenseTypeColor(
                            selectedVehicle.licenseType
                          ) === 'white'
                        "
                        >mdi-card-text-outline</v-icon
                      >
                      <v-icon
                        v-else
                        :color="
                          vehicleLicenseTypeColor(selectedVehicle.licenseType)
                        "
                        >mdi-card-text</v-icon
                      >
                    </span>
                    {{
                      vehicleLicenseTypeText(selectedVehicle.licenseType)
                    }}</span
                  >
                  <span
                    class="text-app-small-detail"
                    v-if="selectedVehicle.type !== null"
                  >
                    <span class="mx-0 px-0">
                      <v-icon>{{
                        vehicleTypeIcon(selectedVehicle.type)
                      }}</v-icon>
                    </span>

                    {{
                      selectedVehicle.seat
                        ? `${selectedVehicle.seat} ที่นั่ง`
                        : "ไม่มีข้อมูลจำนวนที่นั่ง"
                    }}</span
                  >
                </div>
                <v-spacer></v-spacer>
                <div
                  class="mx-0 px-0 pb-0 mb-0 align-self-center"
                  align="right"
                >
                  <PrimaryButton
                    v-if="isNewVehicleSelected"
                    @on-click="saveStatus()"
                  >
                    มอบหมายงาน
                  </PrimaryButton>
                  <!-- <v-btn
                    v-else
                    :color="$vuetify.theme.themes.light.borderColor"
                    class="black--text font-weight-bold px-11"
                    elevation="0"
                    light
                    large
                    @click="cancelStatus()"
                  >
                    ยกเลิก
                  </v-btn> -->
                </div>
              </v-card-text>
            </v-card>

            <v-card class="pa-0" outlined elevation="0">
              <v-sheet height="100%">
                <ScheduleCalenDarComponent
                  v-if="show"
                  :key="componetKeyCounter"
                  ref="AddVehicleDialogCalendar"
                  :selected-calendar-of-driver="selectedCalendar"
                  :selected-start-date="formData.startDate"
                  :selected-end-date="formData.endDate"
                  @is-overlap-selected-event="checkOverLapSelectedEvent"
                  vuex-store-name="jobs"
                />
              </v-sheet>
            </v-card>

            <!--  -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title> เกิดข้อผิดพลาด </v-card-title>
      <v-card-text>
        <v-btn @click="cancelStatus()" text> ปิด </v-btn>
      </v-card-text>
    </v-card>
    <AppDialogConfirm ref="alertVehicleNotAvailable" />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput";
import CustomSelectsRoleOfIcon from "@/components/CustomSelectsRoleOfIcon.vue";
import InputView from "@/components/InputView.vue";
import SaveJobsFormButton from "../SaveJobsFormButton.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import NormalButton from "@/components/NormalButton.vue";
import magnify_image from "@/assets/search.png";
import { mapGetters } from "vuex";
import AppOverlay from "@/components/AppOverlay.vue";
import { DialogType } from "@/services/dialog";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";

import moment from "moment";
import ScheduleCalenDarComponent from "./ScheduleCalendarComponent.vue";
import {
  getJobCalendarOfVehicleService,
  getAvailableVehicleService,
} from "@/services/api/jobs";

export default {
  name: "AddVehicleDialogComponent",
  props: {
    vuexStoreName: {
      type: String,
      default: "manageRoute",
    },
    alwaysReturnData: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchIcon: magnify_image,
    searchVehicleValue: "",
    vehicles: [],
    selectedValueIdxList: null,
    selectedVehicle: null,
    selectedCalendar: null,

    isNewVehicleSelected: true,
    today: null,
    // event
    isNewEventAdded: false,
    // dialog
    show: false,
    resolve: null,
    reject: null,
    dialogError: false,

    fundData: {
      roletype: [
        { key: "OWNER", value: "Owner" },
        { key: "DRIVER", value: "ผู้ขับ" },
      ],
    },
    formData: {
      roletype: null,
      startDate: null,
      endDate: null,
    },

    // for api
    queryStringObject: null,
    componentLoading: false,
    componetKeyCounter: 0,
  }),
  watch: {
    selectedValueIdxList: {
      immediate: false,
      handler(valueIdx) {
        if (!_.isNil(valueIdx)) {
          this.selectedVehicleHandler(this.vehicles[valueIdx]);
        } else {
          this.selectedVehicle = null;
          this.selectedCalendar = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleStatusText: "vehicle/vehicleStatusText",
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    isStartEndDateFiled() {
      if (
        !_.isNil(this.formData["startDate"]) &&
        !_.isNil(this.formData["endDate"])
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.formData = {
        roletype: null,
        startDate: null,
        endDate: null,
      };
      this.clearInput();
    },
    getDate(val) {
      if (!_.isNil(val)) {
        return val.split(" ")[0];
      }
      return null;
    },
    getTime(val) {
      if (!_.isNil(val)) {
        return val.split(" ")[1];
      }
      return null;
    },
    selectedVehicleHandler(vehicle) {
      this.selectedVehicle = vehicle;
      this.getCalendarApi(vehicle);
    },
    // for datetime picker
    processStartDateFromPicker(value) {
      this.formData["startDate"] = value;
      if (!_.isNil(this.formData["endDate"])) {
        let start = new Date(this.formData["startDate"]);
        let end = new Date(this.formData["endDate"]);

        if (start > end) {
          this.formData["endDate"] = this.formData["startDate"];
        }

        this.getDataFromApi({
          assignActiveVehicle: false,
          getCalendar: true,
        });
      }
      this.today = this.getDate(this.formData["startDate"]);
    },

    processEndDateFromPicker(value) {
      this.formData["endDate"] = value;
      if (!_.isNil(this.formData["endDate"])) {
        this.getDataFromApi({
          assignActiveVehicle: false,
          getCalendar: true,
        });
      }
    },

    // api call
    setDateTimeToQueryParams() {
      if (
        !_.isNil(this.formData["startDate"]) &&
        !_.isNil(this.formData["endDate"])
      ) {
        let _startDate = moment(this.formData["startDate"]); // +07.00
        let _endDate = moment(this.formData["endDate"]); // +07.00

        return {
          startdate: _startDate.format("YYYY-MM-DDTHH:mm:ss"),
          enddate: _endDate.format("YYYY-MM-DDTHH:mm:ss"),
        };
      } else {
        return { startdate: null, enddate: null };
      }
    },

    async getCalendarApi(vehicle) {
      let _queryStringObj = this.setDateTimeToQueryParams();
      if (
        !_.isNil(_queryStringObj["startdate"]) &&
        !_.isNil(_queryStringObj["enddate"]) &&
        !_.isNil(vehicle)
      ) {
        _queryStringObj["id"] = vehicle["_id"];
        let queryString = new URLSearchParams(_queryStringObj).toString();

        let respData = await getJobCalendarOfVehicleService(
          this.$store,
          queryString
        );
        if (respData["code"] === 200) {
          this.selectedCalendar = respData["data"];
        } else {
          console.error("getCalendarAPI: ERROR 1");
          this.dialogError = true;
        }
      }
    },
    async getDataFromApi(settingParams) {
      this.componentLoading = true;
      // startdate, enddate and id
      if (settingParams["assignActive"]) {
        this.assignActive();
      }
      if (settingParams["getCalendarApi"]) {
        await this.getCalendarApi(this.selectedVehicle);
      }

      this.queryStringObject = {};
      this.vehicles = [];
      this.queryStringObject = {
        ...this.queryStringObject,
        ...this.setDateTimeToQueryParams(),
      };

      // check text to query user
      if (this.searchVehicleValue.length !== 0) {
        this.queryStringObject["text"] = this.searchVehicleValue;
      }
      this.queryStringObject["id"] = _.get(
        this.$store.state,
        `${this.vuexStoreName}.jobData._id`,
        null
      );
      // TODO:: for test
      this.queryStringObject["limit"] = 50;

      if (
        !_.isNil(this.queryStringObject["startdate"]) &&
        !_.isNil(this.queryStringObject["enddate"])
      ) {
        let queryString = new URLSearchParams(
          this.queryStringObject
        ).toString();
        let respData = await getAvailableVehicleService(
          this.$store,
          queryString
        );

        if (respData["code"] === 200) {
          this.vehicles = respData["data"];
          // check active now driver
        } else {
          console.error("getDataFromApi: ERROR 1");
          this.dialogError = true;
        }
      } else {
        // load fail
        console.error("getDataFromApi: ERROR 2");
        // this.dialogError = false;
      }
      this.componentLoading = false;
    },

    async assignActive() {
      // === query to calendar selected driver
      const whoIsActive = _.get(
        this.$store.state,
        `${this.vuexStoreName}.jobData.vehicleId`,
        null
      );

      if (!_.isNil(whoIsActive)) {
        this.selectedVehicle = _.cloneDeep(whoIsActive);
      }
    },

    // searching input
    async EnterToSearchDriverHandler(event) {
      if (event.code == "Enter") {
        this.selectedValueIdxList = null;
        this.componetKeyCounter += 1;
        this.getDataFromApi({
          assignActiveVehicle: false,
          getCalendar: false,
        });
      }
    },

    async vehicleNotAvailableDialog() {
      await this.$refs.alertVehicleNotAvailable.open(
        "รถคันนี้มีปัญหา ไม่สามารถใช้งานได้",
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
    },
    // from child callback function
    checkOverLapSelectedEvent(isOverLap) {
      if(this.alwaysReturnData) {
        this.isNewVehicleSelected = true
      } else {
        this.isNewVehicleSelected = !isOverLap;
      }
    },

    clearInput() {
      this.searchVehicleValue = "";
      this.vehicles = [];
      this.isNewVehicleSelected = false;
      this.selectedVehicle = null;
      this.dialogError = false;
    },

    open() {
      this.clearInput();
      this.show = true;
      //
      if (this.vuexStoreName === "manageRoute") {
        this.formData["startDate"] =
          this.$store.state[this.vuexStoreName].startDate || null;
        this.formData["endDate"] =
          this.$store.state[this.vuexStoreName].endDate ||
          this.$store.state[this.vuexStoreName].jobData.estimateEndDate ||
          null;
      } else {
        if (!_.isNil(this.$store.state[this.vuexStoreName]["jobData"])) {
          this.formData["startDate"] =
            this.$store.state[this.vuexStoreName].jobData.startDate || null;
          this.formData["endDate"] =
            this.$store.state[this.vuexStoreName].jobData.endDate ||
            this.$store.state[this.vuexStoreName].jobData.estimateEndDate ||
            null;
        }
      }

      //
      this.today = this.getDate(this.formData["startDate"]);
      this.getDataFromApi({
        assignActive: true,
        getCalendarApi: true,
      });

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    saveStatus() {
      this.resolve(this.selectedVehicle);
      this.resetFormData();
      this.show = false;
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
    AppDialogConfirm,
    CustomDateTimePickerFormInput,
    InputView,
    CustomSelectsRoleOfIcon,
    SaveJobsFormButton,
    PrimaryButton,
    CustomTextInputImprove,
    NormalButton,
    ScheduleCalenDarComponent,
    AppOverlay,
  },
  mounted() {},
};
</script>

<style scoped>
/* .title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
} */
.landing-not-selected {
  border: dashed;
  height: 600px;
}
.card-bg {
  background-color: #eff1f7;
}
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.white-list .v-list-item-group .v-list-item--active {
  background-color: white;
  color: white;
}
.v-list {
  height: 400px; /* or any height you want */
  overflow-y: auto;
}
.image {
  opacity: 0.7;
}
</style>
