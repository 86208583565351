<template>
  <v-combobox
    :items="itemList"
    :loading="loading"
    :readonly="tableLoading"
    :item-value="itemValueKey"
    v-model="modelVal"
    @change="itemChange"
    placeholder="รหัสใบงาน, code"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    item-text="value"
    return-object
    filled
    dense
    hide-no-data
    hide-selected
    hide-details
    flat
    height="44"
    outlined
    class="app-custom-input"
    :dark="isDark"
  >
  </v-combobox>
  <!-- hide-no-data -->
  <!--     auto-select-first -->
  <!-- @keyup="enterToReturnEmit" -->
</template>
<script>
import { getEmployeeBySeachingService } from "@/services/api/employee";
import _ from "lodash";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "AppAutoCompleteUserSearching",
  props: {
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    isDark: {
      default: false,
      type: Boolean,
    },
    tableLoading: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.getParamQuery();
  },
  data: () => ({
    modelVal: null,
    itemList: [],
    loading: false,
    // searchingAction: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryOrder: {
      text: "text",
      empid: "empId",
    },
    formData: {
      text: null,
      empId: null,
    },
    firstInit: false,
  }),
  // watch: {
  //   searchingAction: {
  //     immediate: false,
  //     handler: function (val) {
  //       if (!_.isNil(val) && this.firstInit) {
  //         if (val.length > 2) {
  //           clearTimeout(this.searchCountTimer);
  //           this.loading = true;
  //           this.searchCountTimer = setTimeout(() => {
  //             this.callApi(val); // call api function
  //           }, 1500);
  //         }
  //       }
  //       this.firstInit = true;
  //     },
  //   },
  // },
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "text":
              this.formData[this.queryOrder[key]] =
                appDecodeSingleURIComponentHelper(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });

      if (!_.isNil(this.formData["text"])) {
        this.modelVal = this.formData["text"];
      }
    },

    // async callApi(val) {
    //   let resp_data = await getEmployeeBySeachingService(this.$store, val);
    //   if (resp_data.code === 200) {
    //     this.itemList = resp_data["data"];
    //   } else {
    //     this.itemList = [];
    //   }
    //   this.loading = false;
    // },

    // itemChange() {
    //   if (!_.isString(this.modelVal) && !_.isNil(this.modelVal)) {
    //     this.$emit("searchItemChange", this.modelVal);
    //     this.modelVal = this.modelVal["value"];
    //     this.itemList = [];
    //   }
    // },
    itemChange() {
      this.$emit("isSearching", false);
      if (!_.isString(this.modelVal) && !_.isNil(this.modelVal)) {
        this.$emit("searchItemChange", this.modelVal);
        this.modelVal = this.modelVal["value"];
        this.itemList = [];
      } else if (_.isString(this.modelVal) === true) {
        this.$emit("searchItemChange", {
          value: this.modelVal,
        });
        this.itemList = [];
      } else {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.$emit("searchItemChange", {
          value: null,
        });
        this.itemList = [];
      }
      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },
    // enter
    enterToReturnEmit(event) {
      if (this.enableEnterSearch) {
        if (event.code === "Enter") {
          clearTimeout(this.searchCountTimer);
          this.loading = false;
          //
          if (_.isString(this.modelVal) === true) {
            this.$emit("searchItemChange", {
              value: this.modelVal,
            });
          } else {
            this.$emit("searchItemChange", null);
          }
          //
          this.itemList = [];
          return;
        } else {
          this.selectFromListChecker = false;
          clearTimeout(this.searchCountTimer);
          this.loading = false;

          this.$emit("searchItemChange", {
            value: null,
          });
          this.itemList = [];
        }

        clearTimeout(this.searchCountTimer);
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
}
</style>
