import vehicleEventListView from "@/views/vehicle/vehicle_event/vehicleEventListView.vue";
import vehicleEventDetailView from "@/views/vehicle/vehicle_event/vehicleEventDetailView";
import vehicleEventManageVueView from "@/views/vehicle/vehicle_event/vehicleEventManage.vue";
export default [
  {
    path: "/vehicle/events",
    name: "vehicleEventList",
    component: vehicleEventListView,
    meta: { requiresAuth: true, topic: "vehicleEvent" },
  },
  {
    path: "/vehicle/event/detail/:id",
    name: "vehicleEventInfo",
    component: vehicleEventDetailView,
    meta: { requiresAuth: true, topic: "vehicleEvent" },
  },
  {
    path: "/vehicle/event/create",
    name: "vehicleEventCreate",
    component: vehicleEventManageVueView,
    meta: { requiresAuth: true, topic: "vehicleEvent" },
  },
  {
    path: "/vehicle/event/edit/:id",
    name: "vehicleEventEdit",
    component: vehicleEventManageVueView,
    meta: { requiresAuth: true, topic: "vehicleEvent" },
  },
  {
    path: "/vehicle/events/search/:licenseNumber",
    name: "vehicleEventSearchList",
    component: vehicleEventListView,
    meta: { requiresAuth: true, topic: "vehicleEvent" },
  },
];
