<template>
  <CustomAutocomplete
    v-model="valueInput"
    :placeholder="placeholder"
    :title="title"
    :isRequired="isRequired"
    :disabled="disabled"
    :rules="rules"
    :loading="componentLoading"
    :returnObject="returnObject"
    :items="items"
    itemTextListTextIf="value"
    selectionTextIf="value"
    class="app-custom-input"
  />
</template>

<script>
import CustomAutocomplete from "./CustomAutocomplete.vue";
import { getEmployeeSelectList } from "@/services/api/employee";

export default {
  name: "AppAutoCompleteUser",
  components: {
    CustomAutocomplete,
  },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
      componentLoading: false,
    };
  },

  mounted() {
    this.getData();
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        console.log("input", val);
      },
    },
  },

  methods: {
    async getData() {
      this.componentLoading = true;
      const resp = await getEmployeeSelectList(this.$store);
      if (resp["code"] === 200) {
        this.items = resp["data"];
      }
      this.componentLoading = false;
    },
  },
};
</script>
<style></style>
