<template>
  <div class="d-flex justify-sm-start justify-md-space-between">
    <CustomCardFormViewModern
      title="ผู้ขับร่วม"
      class="job-stat-sm-width mr-md-2"
      :is-show-title="true"
      :is-add-title-margin="false"
      :fill-height="true"
    >
      <v-row no-gutters class="px-3 pb-2">
        <v-col class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">ทั้งหมด</span>
        </v-col>
        <v-spacer />
        <v-col class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ totalDriver || "-" }}
          </span>
          <span class="text-app-detail-normal"> คน</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">รายได้เฉลี่ยต่อคน</span>
        </v-col>
        <v-spacer />
        <v-col class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ averageIncomeEachDriver || "-" }}
          </span>

          <span class="text-app-detail-normal"> บาท</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col cols="8" class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">
            จำนวนงานโดยเฉลี่ยต่อคนต่อวัน
          </span>
        </v-col>
        <v-spacer />
        <v-col cols="4" class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ countJobEachDriverEachDay || "-" }}
          </span>
          <span class="text-app-detail-normal"> งาน</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col cols="8" class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">
            ระยะทางโดยเฉลี่ยต่อคนต่อวัน
          </span>
        </v-col>
        <v-spacer />
        <v-col cols="4" class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ distanceEachDriverEachDay || "-" }}
          </span>
          <span class="text-app-detail-normal"> กม.</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col cols="8" class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text"
            >เวลาโดยเฉลี่ยต่อคนต่อวัน</span
          >
        </v-col>
        <v-spacer />
        <v-col cols="4" class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ durationEachDriverEachDay || "-" }}
          </span>
          <span class="text-app-detail-normal"> ชม.</span>
        </v-col>
      </v-row>
    </CustomCardFormViewModern>
    <CustomCardFormViewModern
      title="รถร่วม"
      class="job-stat-sm-width"
      :is-show-title="true"
      :is-add-title-margin="false"
      :fill-height="true"
    >
      <v-row no-gutters class="px-3 pb-2">
        <v-col class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">ทั้งหมด</span>
        </v-col>
        <v-spacer />
        <v-col class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ totalVehicle || "-" }}
          </span>
          <span class="text-app-detail-normal"> คัน</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text">รายได้เฉลี่ยต่อคัน</span>
        </v-col>
        <v-spacer />
        <v-col class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ averageIncomeEachVehicle || "-" }}
          </span>
          <span class="text-app-detail-normal"> บาท</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3 pb-2">
        <v-col cols="8" class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text"> จำนวนประเภทรถ </span>
        </v-col>
        <v-spacer />
        <v-col cols="4" class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ totalTypeVehicleCount || "-" }}
          </span>
          <span class="text-app-detail-normal"> ประเภท</span>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="px-3 pb-2"
        v-for="(vt, vtIdx) in totalIncomeAndExpensesVehicleTypes"
        :key="`income-vetype-${vtIdx}`"
      >
        <v-col cols="8" class="px-3 mt-3">
          <span class="dashboard-report-keyunit-text ml-3">
            - {{ vt["typeName"] }}
          </span>
        </v-col>
        <v-spacer />
        <v-col cols="4" class="px-3 mt-2 text-end">
          <span class="dashboard-small font-weight-bold black--text">
            {{ vt["incomePerRound"] || "-" }}
          </span>
          <span class="text-app-detail-normal"> บาท</span>
        </v-col>
      </v-row>
    </CustomCardFormViewModern>
  </div>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import {
  calculateAverageHelper,
  calculatePercentHelper,
  displayNumberLocaleString,
} from "@/services/appNumberHelper";
import { mapGetters } from "vuex";

export default {
  name: "JobStatDriversAndVehicles",
  components: {
    CustomCardFormViewModern,
  },
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    this.processData();
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    ...mapGetters({
      vehicleTypeText: "vehicle/vehicleTypeText",
    }),
  },
  data() {
    return {
      currentData: null,
      totalDriver: 0,
      averageIncomeEachDriver: 0,
      countJobEachDriverEachDay: 0,
      distanceEachDriverEachDay: 0,
      durationEachDriverEachDay: 0,

      totalVehicle: 0,
      averageIncomeEachVehicle: 0,
      totalTypeVehicleCount: 0,
      averageIncomeEachTypeVehicle: 0,
      totalIncomeAndExpensesVehicleTypes: [],
    };
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        if (!_.isEmpty(this.data["dvAndVe"])) {
          this.currentData = this.data["dvAndVe"];
          this.totalDriver = this.currentData.totalDriver;
          this.totalVehicle = this.currentData.totalVehicle;
          this.totalTypeVehicleCount = this.currentData.totalTypeVehicleCount;
          //
          this.countJobEachDriverEachDay = displayNumberLocaleString(
            this.currentData["countJobEachDriverEachDay"],
            2
          );

          this.averageIncomeEachDriver = displayNumberLocaleString(
            this.currentData["averageIncomeEachDriver"],
            2
          );
          this.averageIncomeEachVehicle = displayNumberLocaleString(
            this.currentData["averageIncomeEachVehicle"],
            2
          );
          this.distanceEachDriverEachDay = displayNumberLocaleString(
            this.currentData["distanceEachDriverEachDay"],
            2
          );
          this.durationEachDriverEachDay = displayNumberLocaleString(
            this.currentData["durationEachDriverEachDay"],
            2
          );
          this.averageIncomeEachTypeVehicle = displayNumberLocaleString(
            this.currentData["averageIncomeEachTypeVehicle"],
            2
          );

          const _totalIncomeAndExpensesVehicleTypes = _.get(
            this.currentData,
            "totalIncomeAndExpensesVehicleTypes",
            []
          );
          //
          let _convertedTotalIncomeAndExpensesVehicleTypes = [];
          for (let i = 0; i < _totalIncomeAndExpensesVehicleTypes.length; i++) {
            if (i === 3) {
              break;
            }
            _convertedTotalIncomeAndExpensesVehicleTypes.push({
              ..._totalIncomeAndExpensesVehicleTypes[i],
              typeName: this.vehicleTypeText(
                _totalIncomeAndExpensesVehicleTypes[i]["type"]
              ),
              incomePerRound: displayNumberLocaleString(
                _totalIncomeAndExpensesVehicleTypes[i]["incomePerRound"],
                2
              ),
            });
          }
          this.totalIncomeAndExpensesVehicleTypes =
            _convertedTotalIncomeAndExpensesVehicleTypes;
        }
      }
    },
  },
};
</script>

<style scoped>
.job-stat-sm-width {
  width: 100%;
}
</style>
