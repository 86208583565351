<template>
  <div>
    <input-view
      title="เส้นทาง"
      :is-required="false"
      :bottom-margin="false"
      :small="true"
    >
      <v-autocomplete
        class="app-custom-input"
        outlined
        dense
        chips
        small-chips
        hide-details
        hide-no-data
        hide-selected
        :items="items"
        v-model="modelVal"
        @mouseup="openImportDialog()"
        :append-icon="''"
        :disabled="isDisabled"
        :background-color="getBackgrounddColor()"
        no-data-text="เลือกสัญญาหรือไม่มีเส้นทาง"
        :clearable="true"
        return-object
        ref="jobDynamicRouteAutoCompleteInput"
      />
    </input-view>
    <ImportRouteFromContractDialog ref="importRouteFromContractDialog" />
  </div>
</template>

<script>
import InputView from "@/components/InputView.vue";
// import { getGeneratedRouteListService } from "@/services/api/jobs";
import _ from "lodash";
import ImportRouteDialog from "../marker/ImportRouteDialog.vue";
import ImportRouteFromContractDialog from "../marker/ImportRouteFromContractDialog.vue";

export default {
  components: { InputView, ImportRouteDialog, ImportRouteFromContractDialog },
  name: "JobDynamicRouteInput",
  data: () => {
    return {
      items: [],
      contractId: null,
    };
  },
  props: {
    value: {},
    routeItems: {
      type: [Array, null],
      default: null,
    },
    currentContract: {
      type: [Object, null],
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    currentContract: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          this.contractId = newValue["key"];
        }
      },
    },
    routeItems: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          this.items = newValue;
        } else {
          this.items = [];
        }
      },
    },
  },
  computed: {
    isDisabled: function () {
      return !(this.canEdit && !_.isNil(this.currentContract));
    },
    modelVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change-route-value", val);
      },
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.isDisabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
    async openImportDialog() {
      if (this.isDisabled === false) {
        this.items = [];
        const responseDialogData = await this.$refs[
          "importRouteFromContractDialog"
        ].open(this.contractId);
        if (responseDialogData !== null) {
          const selectedItem = _.pick(responseDialogData, [
            "text",
            "value",
            "timeLabel",
            "totalDuration",
          ]);
          this.items = [selectedItem];
          this.modelVal = selectedItem;
        } else {
          this.items = [];
          this.modelVal = null;
        }

        this.$refs.jobDynamicRouteAutoCompleteInput.blur();
      }
    },
  },
};
</script>
