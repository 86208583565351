<template>
  <v-menu v-if="inputVal" :disabled="disabled">
    <template v-slot:activator="{ props, on }">
      <v-btn
        outlined
        small
        v-bind="props"
        v-on="on"
        :color="vehicleCheckListItemStatusCardColor(inputVal.status)"
        :loading="isLoading"
      >
        <div>
          {{ vehicleCheckListItemStatusText(inputVal.status) }}
        </div>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(statusName, statusKey) in vehicleCheckListItemStatusTextList"
        :key="`${checkItemIdx}-itemOptions-${statusKey}`"
        :value="statusKey"
        @click="updateCheckListItemStatus(checkItemIdx, checkItem, statusKey)"
        :disabled="inputVal.status == statusKey"
      >
        <v-list-item-title
          :class="`text-app-title ${vehicleCheckListItemStatusCardColorDisabled(
            statusKey,
            inputVal.status
          )}`"
        >
          {{ statusName }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { putSetCheckListStatus } from "@/services/api/vehicle_checklist";

export default {
  name: "VehicleCheckListItemMenuBtn",
  props: {
    id: {
      type: String,
      required: true,
    },
    checkItem: {
      type: [Object, null],
      default: null,
    },
    checkItemIdx: {
      type: [Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      inputVal: null,
    };
  },
  mounted() {
    this.inputVal = this.checkItem;
  },
  watch: {
    checkItem: function () {
      this.inputVal = this.checkItem;
    },
  },
  computed: {
    ...mapGetters({
      vehicleCheckListStatusText:
        "vehicle_checklist/vehicleCheckListStatusText",
      vehicleCheckListItemStatusText:
        "vehicle_checklist/vehicleCheckListItemStatusText",
      vehicleCheckListItemStatusCardColor:
        "vehicle_checklist/vehicleCheckListItemStatusCardColor",
    }),
    vehicleCheckListStatusTextList: function () {
      return this.$store.state.vehicle_checklist.vehicleCheckListStatusText;
    },
    vehicleCheckListItemStatusTextList: function () {
      return this.$store.state.vehicle_checklist.vehicleCheckListItemStatusText;
    },
  },
  methods: {
    vehicleCheckListItemStatusCardColorDisabled(status, disabledStatus) {
      if (status === disabledStatus) {
        return "borderColor--text";
      } else {
        return `${this.vehicleCheckListItemStatusCardColor(status)}--text`;
      }
    },

    async updateCheckListItemStatus(itemIdx, item, selectStatus) {
      // console.log(itemIdx, item, selectStatus);
      this.isLoading = true;

      try {
        // setTimeout(() => {
        //   this.isLoading = false;
        //   this.inputVal.status = selectStatus;
        // }, 2000);
        const requestData = {
          checkListId: item.itemId,
          checkListStatus: selectStatus,
        };
        const responseData = await putSetCheckListStatus(
          this.$store,
          this.id,
          requestData
        );

        if (responseData["code"] === 200) {
          this.inputVal.status = selectStatus;
        } else {
          throw "REQUEST_FAIL";
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
};
</script>
