<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters ref="mapWrapper">
      <v-col lg="9" md="12" sm="12">
        <DetailBar />
        <!-- toolbox -->
        <div class="wrapper">
          <!-- main map -->
          <!--TODO:: responsive to md sm size -->
          <CoreMap class="core-map" store-name="manageRoute" />

          <div class="overlap-bottom">
            <!-- v-card detail -->
            <StatusOnMapFloat />
          </div>
        </div>
      </v-col>
      <v-col lg="3" md="12" sm="12">
        <MarkerView />
      </v-col>
    </v-row>
    <AppOverlay :isLoading="mainLoading" />
  </v-container>
</template>

<script>
import CoreMap from "./CoreMap";
import DetailBar from "./components/DetailBar.vue";
import DetailOnMapFloat from "./components/DetailOnMapFloat.vue";
import MarkerView from "./components/marker/MarkerView.vue";
import StatusOnMapFloat from "./components/StatusOnMapFloat.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import { getJobInfo, getJobStatusService } from "@/services/api/jobs";
import { processPermission } from "@/services/permissions";

export default {
  name: "JobsEditView",
  components: {
    CoreMap,
    DetailBar,
    DetailOnMapFloat,
    MarkerView,
    StatusOnMapFloat,
    AppOverlay,
  },
  watch: {
    "$store.state.manageRoute.forceGetData": {
      immediate: false,
      handler(forceUpdateValue) {
        if (forceUpdateValue) {
          this.getData(false);
        }
      },
    },
    "$store.state.manageRoute.jobData": {
      immediate: false,
      handler() {
        this.checkJobStatus();
      },
    },
  },
  computed: {
    mainLoading: function () {
      return this.$store.state.manageRoute.mainLoading;
    },
  },
  data: () => ({
    isEdit: 0,
    disabledForm: true,
    jobId: null,
    workerInterval: undefined,
    allowIntervalStatus: ["PREPARING", "CONFIRM", "PROCESS"],
    permList: {
      admin_all: false,
      jobs_list: false,
      jobs_info: false,
      jobs_crud: false,
      jobs_report: false,
      jobs_total_report: false,
    },
  }),
  methods: {
    setStore() {
      // set default status
      this.$store.dispatch("manageRoute/updateActivateForm", false);
      this.$store.dispatch("manageRoute/updateActivateMarker", false);
      this.$store.commit("manageRoute/setJobData", null);
      this.$store.commit("manageRoute/setEstimateEndDateFromMarker", null);
      // set default marker and line on map
      this.$store.dispatch("manageRoute/updateMarkerList", []);
      this.$store.dispatch("manageRoute/updateBoundLatLngCenter", []);
      this.$store.dispatch("manageRoute/updatePolyLine", []);
    },
    async getData(firstInit) {
      this.$store.dispatch("manageRoute/updateMainLoading", true);
      // first get date forceGetData === false
      if (this.$store.state.manageRoute.forceGetData === false && firstInit) {
        this.setStore();
      }

      // set default marker and line on map
      // await this.$store.dispatch("manageRoute/getJobData", {
      //   jobId: this.jobId,
      // });
      try {
        //
        if (!_.isNil(this.workerInterval)) {
          clearInterval(this.workerInterval);
        }

        const respData = await getJobInfo(this.$store, `id=${this.jobId}`);
        if (respData["code"] === 200) {
          this.$store.dispatch(
            "manageRoute/updateJobAndStatusData",
            respData["data"]
          );

          this.$store.dispatch(
            "manageRoute/updateVehicleOverSpeedLimitLine",
            respData["data"]
          );

          // driver and vehicle form
          this.$store.dispatch("manageRoute/updateActivateForm", true);

          // left panle marker
          //
          this.$store.dispatch("manageRoute/updateActivateInput", true);
        } else {
          // getData fail return return to list
          this.$router.push("/caj/jobs/list");
        }

        this.$store.dispatch("manageRoute/updateforceGetData", false);
        this.$store.dispatch("manageRoute/updateMainLoading", false);
      } catch (error) {
        this.$store.dispatch("manageRoute/updateforceGetData", false);
        this.$store.dispatch("manageRoute/updateMainLoading", false);
        this.$router.push("/caj/jobs/list");
      }
    },
    checkJobStatus() {
      if (!_.isNil(this.workerInterval)) {
        clearInterval(this.workerInterval);
      }

      let getCurrentStatus = _.get(
        this.$store.state.manageRoute,
        "jobData.status",
        null
      );

      if (!_.isNil(getCurrentStatus)) {
        if (this.allowIntervalStatus.includes(getCurrentStatus)) {
          this.setIntervalRequest();
        }
      }
    },
    setIntervalRequest() {
      // TODO:: check job status
      let loadingBackgroundRequest = false;

      this.workerInterval = setInterval(async () => {
        try {
          if (loadingBackgroundRequest === false) {
            loadingBackgroundRequest = true;
            let responseData = await getJobStatusService(
              this.$store,
              `id=${this.jobId}`
            );

            const getJobStatus = _.get(
              this.$store.state.manageRoute,
              "jobData.status",
              null
            );

            if (responseData["code"] === 200) {
              let requestJobStatus = responseData["data"]["status"]; // new status

              this.$store.dispatch(
                "manageRoute/updateJobStatus",
                responseData["data"]
              );
              this.$store.dispatch(
                "manageRoute/updateVehiclePositionMarker",
                responseData["data"]
              );
              this.$store.dispatch(
                "manageRoute/updateVehicleOverSpeedLimitLine",
                responseData["data"]
              );

              if (getJobStatus !== requestJobStatus) {
                // update main data
                await this.getData(false);
              } else {
                // do noting
              }
            } else {
              throw "GET_REQUEST_FAIL";
            }

            loadingBackgroundRequest = false;
          }
        } catch (error) {
          clearInterval(this.workerInterval);
          loadingBackgroundRequest = false;
          console.error(error);
        }
      }, 5000);
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");
        let isUserAllow = false;

        if (this.permList.admin_all === true) {
          this.$store.commit("manageRoute/setIsPermissionCanEdit", true);
          this.$store.commit("manageRoute/setIsPermissionCanView", true);

          return;
        }

        if (this.permList.jobs_info) {
          isUserAllow = true;
          this.$store.commit("manageRoute/setIsPermissionCanView", true);
        }

        if (this.permList.jobs_crud) {
          isUserAllow = true;
          this.$store.commit("manageRoute/setIsPermissionCanEdit", true);
        }
        //
        if (isUserAllow === false) {
          this.$router.replace("/caj/jobs/list");
        }
      }
    },
  },
  mounted() {
    this.checkUserPermissions();
    if (!_.isNil(this.$route.params.id)) {
      this.$store.commit("manageRoute/setMode", 1);
      this.isEdit = true;
      this.jobId = this.$route.params.id;
      this.getData(true);
    } else {
      this.$router.push("/caj/jobs/list");
    }
  },

  destroyed() {
    // exist and reset mode
    this.setStore();
    this.$store.dispatch("manageRoute/updateMainGMapActive", false);
    this.$store.dispatch("manageRoute/updateMainLoading", false);
    this.$store.commit("manageRoute/setMode", 0);
    this.$store.dispatch("manageRoute/updateResetStore", true);
    //
    clearInterval(this.workerInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "./JobsStyle.scss";
</style>
