import moment from "moment";

// EMPLOYEE
export const URL_EMPLOYEE_LIST = "/list?limit=20&by=joindate&order=desc&page=1";
export const URL_EMPLOYEE_TIMEOFF =
  "/timeoff/list?limit=20&by=createddate&order=desc&page=1";

let startDate = moment().subtract(2, "days").format("YYYY-MM-DD");
let endDate = moment().format("YYYY-MM-DD");
export const URL_EMPLOYEE_PENALTY = `/penality/list?limit=20&by=createddate&order=desc&page=1&startdate=${startDate}&enddate=${endDate}`;
export const URL_EMPLOYEE_OVERVIEW_LIST =
  "/penality-overview/list?limit=20&by=createddate&order=desc&page=1&year=2023";
export const URL_EMPLOYEE_OVERVIEW_DASHBOARD = "/dashboard";

// VEHICLE
export const URL_VEHICLE_LIST =
  "/list?limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_DOC_INS =
  "/ins?doc=ins&limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_DOC_ACT =
  "/act?doc=act&limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_DOC_TAX =
  "/tax?doc=tax&limit=20&by=createddate&order=desc&page=1";

export const URL_VEHICLE_DOC_GPS =
  "/gps?limit=20&by=createddate&order=desc&page=1";

export const URL_VEHICLE_DOC_LIVE = "/live";

export const URL_VEHICLE_DOC_LOAN =
  "/loan?limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_DOC_FUEL = "/fuel?limit=10&order=desc&page=1&status=P";
export const URL_VEHICLE_DOC_EVENT_LIST =
  "/events?limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_CREATE_CHECKLIST = "/checklist-create";
export const URL_VEHICLE_CHECKLIST =
  "/checklist?limit=20&by=createddate&order=desc&page=1";
export const URL_VEHICLE_OVERVIEW_DASHBOARD = "/dashboard";
// CONTRACT
export const URL_CONTRACT_LIST =
  "/contract?limit=20&by=createdat&order=desc&page=1";
export const URL_CONTRACT_OVERVIEW_DASHBOARD = "/contract/dashboard";

export const URL_ROUTES_LIST =
  "/routes?limit=20&by=createddate&order=desc&page=1";
export const URL_JOBS_LIST =
  "/jobs/list?limit=20&by=createddate&order=desc&page=1&status=all";
export const URL_MJOBS_LIST =
  "/multiple-job/list?limit=20&by=createddate&order=desc&page=1";
export const URL_JOB_MONITOR_LIST = "/jobs/monitoring";
export const URL_EMPLOYEE_GROUP_LIST =
  "/employee_group?limit=20&by=createddate&order=desc&page=1";
// Worksheet (Payment)
export const URL_WORKSHEET_LIST =
  "/list?limit=30&by=createddate&order=desc&page=1";
///&startdate=${startDate}&enddate=${endDate}
export const URL_EVIDENCE_LIST = `/evidence-list?limit=20&by=createddate&order=desc&page=1`;
export const URL_WORKSHEET_OVERVIEW_DASHBOARD = "/dashboard";
export const URL_WORKSHEET_OVERVIEW_PROPORTION = "/proportion-dashboard";
