<template>
  <v-dialog v-model="show" width="50%" persistent>
    <v-card v-if="show">
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>เลือกวัน</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text v-if="fetchDataError === 0">
        <!--  -->
        <template>
          <v-row dense>
            <v-col cols="12">
              <v-date-picker
                v-model="formData.dates"
                multiple
                full-width
                no-title
                locale="th"
                :min="today"
                :allowed-dates="allowedDates"
              ></v-date-picker>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <!--  -->
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>
      <!--  -->
      <v-divider v-if="fetchDataError <= 0"></v-divider>
      <v-card-actions v-if="fetchDataError <= 0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndCloseHandler" v-if="fetchDataError <= 0">
          ยกเลิก
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveAndCloseHandler"
          v-if="fetchDataError === 0"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "AssignMultipleJobDayDialog",
  data: () => ({
    show: false,
    fetchDataError: 1,
    // day
    formData: { dates: null },
    menu: null,
    //
    selectRow: null,
    tableKeyName: null,
  }),

  methods: {
    open(tableKeyName, selectRow) {
      this.resetForm();
      this.tableKeyName = tableKeyName;
      this.selectRow = selectRow; //
      this.show = true;
      this.getData();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    getData() {
      this.fetchDataError = 0;
    },

    resetForm() {
      this.formData = { dates: null };
    },

    saveAndCloseHandler() {
      const formData = _.cloneDeep(this.formData);
      this.show = false;
      this.resolve(formData);
    },

    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },

    allowedDates(val) {
      let _mapDate = [];
      const _date = _.map(
        _.get(
          this.$store.state,
          `assignMultipleJob.rowData.${this.tableKeyName}`,
          []
        ),
        "date"
      );

      for (let i = 0; i < _date.length; i++) {
        _mapDate.push(moment(new Date(_date[i])).format("YYYY-MM-DD"));
      }

      return !_mapDate.includes(val);
    },
  },

  computed: {
    today: function () {
      return new Date().toISOString();
    },
  },
};
</script>

<style scoped></style>
