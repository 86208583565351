<template>
  <div name="customSelects" class="px-3">
    <InputView :title="title" :isRequired="isRequired">
      <v-select
        v-model="valueInput"
        class="app-custom-input custom-placeholer-color"
        :items="items"
        :placeholder="placeholder"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        :rules="rules"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        dense
        outlined
        hide-no-data
      >
        <template slot="item" slot-scope="data">
          <v-col cols="12">
            <v-row>
              <v-col cols="2"
                ><v-icon>{{ vehicleTypeIcon(data.item.key) }}</v-icon></v-col
              >
              <v-col>
                <span class="text-select font-weight-medium">
                  {{ data.item.value }}</span
                ></v-col
              ></v-row
            ></v-col
          >
        </template>
        <template v-slot:selection="{ item }">
          <v-row>
            <v-col cols="2" class="mx-2"
              ><v-icon>{{ vehicleTypeIcon(item.key) }}</v-icon></v-col
            >
            <v-col>
              <span> {{ item.value }}</span></v-col
            ></v-row
          >
        </template>
      </v-select>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
import { mapGetters } from "vuex";
export default {
  name: "customSelectsArrOfIconObj",

  components: {
    InputView,
  },

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "value",
    },
    itemValue: {
      type: String,
      default: "key",
    },
    returnObject: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      // vehicleTypeIcon: {
      //   NO_DATA: null,
      //   PICKUP: "mdi-truck-flatbed",
      //   SEDAN: "mdi-car-side",
      //   TOUR_BUS_4W: "mdi-bus",
      //   TOUR_BUS_6W: "mdi-bus-school",
      //   TOUR_BUS_8W: "mdi-bus-side",
      //   TOUR_BUS_10W: "mdi-bus-double-decker",
      //   VAN: "mdi-van-utility",
      // },
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
  components: { InputView },
};
</script>
<style>
.text-select {
  font-size: 13px;
}
</style>
