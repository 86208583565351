<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters ref="mapWrapper">
      <v-col lg="9" md="12" sm="12">
        <RouteTemplateDetailTopBar />
        <!-- toolbox -->
        <div class="wrapper">
          <CoreMap class="core-map" store-name="routeSetting" />
          <div class="overlap-bottom">
            <RouteTemplateDetailSubComponent />
          </div>
        </div>
      </v-col>
      <v-col lg="3" md="12" sm="12">
        <RouteMarkerSubView />
      </v-col>
    </v-row>
    <AppOverlay :isLoading="mainLoading" />
  </v-container>
</template>
<script>
import AppOverlay from "@/components/AppOverlay.vue";
import CoreMap from "@/views/jobs/manageView/CoreMap";
import RouteMarkerSubView from "./components/RouteMarkerSubView.vue";
import RouteTemplateDetailSubComponent from "./components/RouteTemplateDetailSubComponent.vue";
import { getRouteTemplateInfoService } from "@/services/api/contract_job_routes";
import RouteTemplateDetailTopBar from "./components/RouteTemplateDetailTopBar.vue";

export default {
  name: "RouteEditView",
  components: {
    CoreMap,
    AppOverlay,
    RouteMarkerSubView,
    RouteTemplateDetailSubComponent,
    RouteTemplateDetailTopBar,
  },
  computed: {
    mainLoading: function () {
      return this.$store.state.routeSetting.mainLoading;
    },
  },
  watch: {
    "$store.state.routeSetting.forceGetData": function (newValue) {
      if (newValue !== 0) {
        this.getData();
      }
    },
  },
  mounted() {
    // this.checkUserPermissions();
    if (!_.isNil(this.$route.params["id"])) {
      this.templateId = this.$route.params["id"];
      this.getData(true);
    } else {
      this.$router.push("/caj/routes");
    }
  },
  data: () => ({
    mainStore: "routeSetting",
  }),
  methods: {
    async getData() {
      this.$store.dispatch("routeSetting/updateSetIsOk", false);
      this.$store.dispatch("routeSetting/updateMainLoading", true);
      const respData = await getRouteTemplateInfoService(
        this.$store,
        `id=${this.templateId}`
      );
      if (respData["code"] === 200) {
        this.$store.dispatch(
          "routeSetting/updateTemplateData",
          respData["data"]
        );
        this.$store.dispatch("routeSetting/updateSetIsOk", true);
      } else {
        this.$router.push("/caj/routes");
      }
      this.$store.dispatch("routeSetting/updateMainLoading", false);
    },
  },
  destroyed() {
    this.$store.dispatch(`${this.mainStore}/updateActivateForm`, false);
    this.$store.dispatch(`${this.mainStore}/updateActivateMarker`, false);
    this.$store.commit(`${this.mainStore}/setTemplateData`, null);
    this.$store.commit(`${this.mainStore}/setMarkerListDuration`, null);
    this.$store.dispatch(`${this.mainStore}/updateMarkerList`, []);
    this.$store.dispatch(`${this.mainStore}/updateBoundLatLngCenter`, []);
    this.$store.dispatch(`${this.mainStore}/updatePolyLine`, []);
  },
};
</script>
<style scoped>
@import "@/views/jobs/manageView/JobsStyle.scss";

.core-map {
  height: 94vh;
}
</style>
