<template>
	<div>
		<div>
			<v-dialog v-model="show" persistent max-width="1500">
				<v-card>
					<v-card-title class="text-h5">
						ดูกล้อง <v-spacer></v-spacer>
						<v-btn @click="closePlayer()">ปิด</v-btn></v-card-title
					>
					<v-card-text>
						<v-row justify="center">
							<div class="playerSwitch pb-4 pl-5 pr-5 mr-5">
								<v-switch
									v-model="frontCamera"
									label="กล้องหน้า"
									color="success"
									value="on"
									hide-details
								></v-switch>
							</div>
							<div class="playerSwitch pb-4 pl-5 pr-5">
								<v-switch
									v-model="driverCamera"
									label="กล้องคนขับ"
									color="success"
									value="on"
									hide-details
								>
								</v-switch>
							</div> </v-row
					>
				
					<v-row justify="center" class="pa-0">
							<v-col v-if="frontCamera"  lg="6">
								<DeepPlayerCoreV2
									cameraType="F"
									:serialNumber="serialNumber"
									:playerName="playerName"
									ref="playerF"
								></DeepPlayerCoreV2>
							</v-col>
							<v-col v-if="driverCamera" lg="6">
								<DeepPlayerCoreV2
									cameraType="I"
									:serialNumber="serialNumber"
									:playerName="playerName"
									ref="playerI"
								></DeepPlayerCoreV2>
							</v-col>
						</v-row>
				</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>
<script>
	import _ from "lodash";

	export default {
		name: "customDeepPlayer",
		beforeCreate: function () {
			this.$options.components.DeepPlayerCoreV2 =
				require("@/components/DeepPlayerCoreV2.vue").default;
		},
		// components: {
		//   DeepPlayerCore: () => import("@/components/DeepPlayerCore.vue"),
		// },

		props: {
			closePlayerCallBack: {
				default: null,
				type: Function,
			},
		},

		data: () => ({
			frontCamera: false,
			driverCamera: false,
			show: false,
			serialNumber: null,
			playerName: null,
			resolve: null,
			reject: null,
		}),

		mounted() {},

		watch: {},
		methods: {
			open(serialNumber, playerName) {
				this.frontCamera = false;
				this.driverCamera = false;
				this.show = true;
				this.serialNumber = serialNumber;
				this.playerName = playerName;
				console.log("serialNumber", serialNumber);
				console.log("serialNumber", playerName);
			},
			closePlayer() {
				console.log("closing F");
				if(!_.isNil(this.$refs.playerF)){
					this.$refs.playerF.parentCloseEvent();
				}
				console.log("closing I");
				if(!_.isNil(this.$refs.playerI)){
					this.$refs.playerI.parentCloseEvent();
				}
				console.log("closing done");
				
				this.show = false;
				if (this.closePlayerCallBack != null) {
					this.closePlayerCallBack();
				}
			},
		},
		components: {},
	};
</script>

<style scoped>
	.playerSwitch {
		border: 1px;
		border-style: solid;
	}
</style>
