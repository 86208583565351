<template>
  <v-row no-gutters>
    <v-col cols="12">
      <apexchart
        v-if="chartIsOK"
        id="contractTotalRecompenseChart"
        type="line"
        height="150"
        :options="contractTotalRecompenseChartOpts"
        :series="contractTotalRecompenseChartList"
      ></apexchart>
    </v-col>
    <v-col cols="12">
      <apexchart
        v-if="chartIsOK"
        id="vehicleFuelAmount"
        type="line"
        height="150"
        :options="vehicleFuelAmountChartOpts"
        :series="vehicleFuelAmountChartList"
      ></apexchart>
    </v-col>
    <v-col cols="12">
      <apexchart
        v-if="chartIsOK"
        id="vehicleDocsChartList"
        type="line"
        height="150"
        :options="vehicleDocsChartOpts"
        :series="vehicleDocsChartList"
      ></apexchart>
    </v-col>
    <v-col cols="12">
      <apexchart
        v-if="chartIsOK"
        id="vehicleDocsChartList"
        type="line"
        height="150"
        :options="vehicleEventAmountChartOpts"
        :series="vehicleEventAmountChartList"
      ></apexchart>
    </v-col>
    <v-col cols="12">
      <apexchart
        v-if="chartIsOK"
        id="vehicleDocsChartList"
        type="line"
        height="150"
        :options="vehicleTotalInstallmentChartOpts"
        :series="vehicleTotalInstallmentChartList"
      ></apexchart>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {
  displayNumberLocaleString,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";
import _ from "lodash";
import moment from "moment";
import { getShortMonthNameHelper } from "@/services/appDate";

export default {
  name: "PaymentExpensesWeeklyLineChart",
  props: {
    data: {
      require: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data: () => {
    return {
      chartIsOK: false,
      contractTotalRecompenseChartList: [],
      vehicleDocsChartList: [],
      vehicleEventAmountChartList: [],
      vehicleFuelAmountChartList: [],
      vehicleTotalInstallmentChartList: [],
      //

      // options
      contractTotalRecompenseChartOpts: {},
      vehicleDocsChartOpts: {},
      vehicleEventAmountChartOpts: {},
      vehicleFuelAmountChartOpts: {},
      vehicleTotalInstallmentChartOpts: {},

      categories: [],
      colors: [],
      selectKeyMap: {
        contractTotalRecompense: "รายจ่ายพนักงาน",
        vehicleDocs: "รายจ่ายเอกสารรถ",
        vehicleEventAmount: "รายจ่ายซ่อมบำรุง",
        vehicleFuelAmount: "รายจ่ายค่าน้ำมัน",
        vehicleTotalInstallment: "รายจ่ายงวดรถ",
      },
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  // computed: {
  //   chartOptions() {
  //     return {
  //       chart: {
  //         type: "line",
  //         parentHeightOffset: 0,
  //         toolbar: {
  //           show: false,
  //         },
  //         zoom: {
  //           enabled: false,
  //         },
  //         fontFamily: "'Noto Sans Thai', sans-serif",
  //       },
  //       dataLabels: {
  //         enabled: false,
  //         formatter: (value) => {
  //           return `${nFormatterHelper(value)}`;
  //         },
  //       },
  //       stroke: {
  //         curve: "smooth",
  //       },
  //       colors: [], //this.colors,
  //       annotations: {
  //         yaxis: [],
  //       },

  //       grid: {
  //         show: false,
  //       },
  //       xaxis: {
  //         categories: this.categories,
  //       },

  //       yaxis: {
  //         title: {
  //           text: "รายจ่าย",
  //         },
  //         labels: {
  //           formatter: (value) => {
  //             return nFormatterHelper(value);
  //           },
  //         },
  //       },
  //       tooltip: {
  //         x: {
  //           show: true,
  //         },
  //         y: {
  //           formatter: (val) => {
  //             return `${numberWithCommasHelper(val)} บาท`;
  //           },
  //         },
  //       },
  //     };
  //   },
  // },
  mounted() {
    this.processData();
  },
  methods: {
    getBaseChartOpts(xCat, yLabel) {
      return {
        chart: {
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: false,
          formatter: (value) => {
            return `${nFormatterHelper(value)}`;
          },
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#42A5F5"], //this.colors,
        annotations: {
          yaxis: [],
        },

        grid: {
          show: false,
        },
        xaxis: {
          categories: xCat,
        },

        yaxis: {
          title: {
            text: `${yLabel} (บาท)`,
          },
          labels: {
            formatter: (value) => {
              return nFormatterHelper(value);
            },
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
      };
    },
    displayNumberLocaleString: displayNumberLocaleString,
    rankings(arr) {
      const sorted = [...arr].sort((a, b) => b - a);
      return arr.map((x) => sorted.indexOf(x) + 1);
    },
    processData() {
      try {
        if (!_.isNil(this.data)) {
          this.chartIsOK = false;
          const weekYearList = _.get(this.data, "weekYearList", []);
          let _sumEachKeyList = [];
          // process

          // process x label
          let weekName = [];
          for (let i = 0; i < weekYearList.length; i++) {
            const startDateOnWeek = moment()
              .day(0)
              .year(weekYearList[i]["year"])
              .week(weekYearList[i]["week"]);
            const endDateOnWeek = moment()
              .day(0)
              .year(weekYearList[i]["year"])
              .week(weekYearList[i]["week"])
              .add(6, "days");

            let startDateStr = startDateOnWeek.format("DD");
            let endDateStr = endDateOnWeek.format("DD");
            if (startDateOnWeek.format("MM") !== endDateOnWeek.format("MM")) {
              startDateStr = `${startDateStr} ${getShortMonthNameHelper(
                startDateOnWeek.month()
              )}`;
              endDateStr = `${endDateStr} ${getShortMonthNameHelper(
                endDateOnWeek.month()
              )}`;
            } else {
              endDateStr = `${endDateStr} ${getShortMonthNameHelper(
                endDateOnWeek.month()
              )}`;
            }
            if (i % 2 === 0) {
              weekName.push(`${startDateStr} - ${endDateStr}`);
            } else {
              weekName.push(``);
            }
          }

          for (const [key, value] of Object.entries(this.selectKeyMap)) {
            const _cd = [
              {
                name: value,
                data: _.map(weekYearList, key),
              },
            ];
            if (key === "contractTotalRecompense") {
              this.contractTotalRecompenseChartList = _cd;
              this.contractTotalRecompenseChartOpts = this.getBaseChartOpts(
                weekName,
                value
              );
            } else if (key === "vehicleFuelAmount") {
              this.vehicleFuelAmountChartList = _cd;
              this.vehicleFuelAmountChartOpts = this.getBaseChartOpts(
                weekName,
                value
              );
            } else if (key === "vehicleDocs") {
              this.vehicleDocsChartList = _cd;
              this.vehicleDocsChartOpts = this.getBaseChartOpts(
                weekName,
                value
              );
            } else if (key === "vehicleEventAmount") {
              this.vehicleEventAmountChartList = _cd;
              this.vehicleEventAmountChartOpts = this.getBaseChartOpts(
                weekName,
                value
              );
            } else if (key === "vehicleTotalInstallment") {
              this.vehicleTotalInstallmentChartList = _cd;
              this.vehicleTotalInstallmentChartOpts = this.getBaseChartOpts(
                weekName,
                value
              );
            }

            _sumEachKeyList.push(_.sumBy(weekYearList, key));
          }

          this.chartIsOK = true;

          // const _sumRankEachKeyList = this.rankings(_sumEachKeyList);
          // let _colors = [];
          // for (let i = 0; i < _sumRankEachKeyList.length; i++) {
          //   _colors.push(_sumRankEachKeyList[i] <= 2 ? "#567DF4" : "#AAB7B8");
          // }

          // this.colors = _colors;
        }
      } catch (error) {
        this.series = [];
        console.error(error);
      }
    },
  },
};
</script>
