<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card v-if="show" min-height="80vh">
      <v-card-title class="local-card-text px-3 pt-3">
        <v-row dense>
          <v-col cols="4">
            <v-row>
              <v-col cols="5" class="text-right" v-if="colsDataPrev">
                <div
                  class="pa-5 subtitle--text text--darken-2"
                  v-if="colsDataPrev.startDate"
                >
                  <p class="text-app-detail-large mb-1">
                    {{ rowsData.title ? `วัน${rowsData.title}` : "-" }}
                  </p>

                  <p style="font-size: 16px">
                    {{ rowsData.date ? `${rowsData.date}` : "-" }}
                  </p>
                </div>
              </v-col>

              <v-col cols="7" class="text-left" v-if="colsDataPrev">
                <div style="position: relative" v-if="colsDataPrev.startDate">
                  <div class="pa-5 subtitle--text text--darken-2">
                    <p class="text-app-detail-large mb-1">
                      {{ colsDataPrev.type === 1 ? "เข้า" : "ออก" }}
                      <span
                        v-if="
                          colsDataPrev.title ||
                          colsDataPrev.endDate ||
                          colsDataPrev.estimateEndDate
                        "
                      >
                        {{ colsDataPrev.title ? colsDataPrev.title : "" }}
                        -
                        {{
                          colsDataPrev.endDate
                            ? getTime(colsDataPrev.endDate)
                            : getTime(colsDataPrev.estimateEndDate)
                        }}
                        น.
                      </span>
                      <span v-else>-</span>
                    </p>
                    <p style="font-size: 16px">
                      {{
                        colsDataPrev.subTitle ? `${colsDataPrev.subTitle}` : "-"
                      }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-divider dark vertical />
          <v-col cols="4" class="text-right">
            <v-row>
              <v-col cols="5">
                <div class="pa-5 white--text">
                  <p class="text-app-detail-large mb-1">
                    {{ rowsData.title ? `วัน${rowsData.title}` : "-" }}
                  </p>

                  <p style="font-size: 16px">
                    {{ rowsData.date ? `${rowsData.date}` : "-" }}
                  </p>
                </div>
              </v-col>

              <v-col cols="7" class="text-left">
                <div style="position: relative">
                  <div class="pa-5 white--text">
                    <p class="text-app-detail-large mb-1">
                      {{ colsData.type === 1 ? "เข้า" : "ออก" }}
                      <span
                        v-if="
                          colsData.title ||
                          colsData.endDate ||
                          colsData.estimateEndDate
                        "
                      >
                        {{ colsData.title ? colsData.title : "" }}
                        -
                        {{
                          colsData.endDate
                            ? getTime(colsData.endDate)
                            : getTime(colsData.estimateEndDate)
                        }}
                        น.
                      </span>
                      <span v-else>-</span>
                    </p>
                    <p style="font-size: 16px">
                      {{ colsData.subTitle ? `${colsData.subTitle}` : "-" }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-divider dark vertical />
          <v-col cols="4">
            <v-row>
              <v-col cols="5" class="text-right" v-if="colsDataNext">
                <div
                  class="pa-5 subtitle--text text--darken-2"
                  v-if="colsDataNext.startDate"
                >
                  <p class="text-app-detail-large mb-1">
                    {{ rowsData.title ? `วัน${rowsData.title}` : "-" }}
                  </p>

                  <p style="font-size: 16px">
                    {{ rowsData.date ? `${rowsData.date}` : "-" }}
                  </p>
                </div>
              </v-col>

              <v-col cols="7" class="text-left">
                <div style="position: relative" v-if="colsDataNext">
                  <div
                    class="pa-5 subtitle--text text--darken-2"
                    v-if="colsDataNext.startDate"
                  >
                    <p class="text-app-detail-large mb-1">
                      {{ colsDataNext.type === 1 ? "เข้า" : "ออก" }}
                      <span
                        v-if="
                          colsDataNext.title ||
                          colsDataNext.endDate ||
                          colsDataNext.estimateEndDate
                        "
                      >
                        {{ colsDataNext.title ? colsDataNext.title : "" }}
                        -
                        {{
                          colsDataNext.endDate
                            ? getTime(colsDataNext.endDate)
                            : getTime(colsDataNext.estimateEndDate)
                        }}
                        น.
                      </span>
                      <span v-else>-</span>
                    </p>
                    <p style="font-size: 16px">
                      {{
                        colsDataNext.subTitle ? `${colsDataNext.subTitle}` : "-"
                      }}
                    </p>
                  </div>
                </div>
                <div style="position: absolute; top: 0; right: 0">
                  <v-btn
                    icon
                    dark
                    @click="cancelAndCloseHandler"
                    v-if="fetchDataError <= 0 || fetchDataError === 1"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="fetchDataError === 0">
        <v-row no-gutters>
          <v-col cols="6" class="pl-6 pr-3">
            <v-card-text class="pt-3 pb-1">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-icon large>mdi-account-group</v-icon>
                  <p class="text-app-detail-md font-weight-bold mb-0 ml-2 mt-2">
                    รายชื่อผู้ขับ
                  </p>
                </div>

                <div>
                  <div class="text-app-detail-md">
                    <div class="d-inline mr-3">
                      จำนวน
                      <span
                        :class="
                          tableItems.length > formData.newNumberOfSelectedDriver
                            ? 'red--text'
                            : ''
                        "
                        >{{ tableItems.length }}</span
                      >
                      /
                    </div>
                    <div class="d-inline-block">
                      <v-text-field
                        outlined
                        dense
                        background-color="#EFF1F7"
                        :height="20"
                        style="width: 70px"
                        hide-details
                        type="number"
                        min="0"
                        oninput="validity.valid||(value='');"
                        v-model="formData.newNumberOfSelectedDriver"
                      />
                    </div>
                    <div class="d-inline ml-3">คน</div>
                  </div>
                </div>
              </div>
              <!--  -->
            </v-card-text>
            <v-autocomplete
              v-if="!selectedItemStatus"
              hide-detail
              dense
              placeholder="ค้นหาและเพิ่มรายชื่อ"
              :items="filterDriverList"
              item-text="fullName"
              item-value="_id"
              return-object
              filled
              hide-no-data
              hide-selected
              hide-details
              flat
              height="44"
              outlined
              class="app-custom-input mb-3 px-2"
              @change="itemChange"
            >
              <template v-slot:item="data">
                <v-avatar size="32" left class="mr-2">
                  <v-img :src="data.item.profileImage"></v-img>
                </v-avatar>
                {{ data.item.fullName }}
                <span
                  v-if="
                    checkUserCellHasOverlap(data.item._id) ||
                    checkUserJobHasOverlap(data.item._id)
                  "
                  class="red--text text--lighten-1 font-weight-medium pl-1"
                >
                  [!!!]
                </span>
                <v-spacer />

                <span class="text-app-normal">
                  วิ่ง:

                  {{ data.item.statOnSheet ? data.item.statOnSheet.jobs : "-" }}

                  [{{
                    data.item.driverCellStat
                      ? data.item.driverCellStat.jobs
                      : "-"
                  }}]</span
                >
                <span class="text-app-normal ml-6">
                  ฟรี:
                  {{ data.item.statOnSheet ? data.item.statOnSheet.jobs : "-" }}
                  [{{
                    data.item.driverCellStat
                      ? data.item.driverCellStat.free
                      : "-"
                  }}]</span
                >
              </template>
            </v-autocomplete>

            <v-alert
              color="info"
              v-if="selectedItemStatus"
              class="ma-0 rounded-0"
              dense
            >
              <div class="d-flex justify-center">
                <p class="mb-0 white--text">
                  ผู้ขับ {{ tabledCheckSelected.length }} คนที่ถูกเลือก
                </p>
                <v-divider vertical class="mx-5" color="white"></v-divider>
                <div>
                  <v-btn depressed small @click="deleteSelection"> ลบ </v-btn>
                  <v-btn
                    outlined
                    color="white"
                    class="ml-2"
                    small
                    @click="cancelSelection"
                  >
                    ยกเลิก
                  </v-btn>
                </div>
              </div>
            </v-alert>

            <!--  -->
            <v-data-table
              id="mytable"
              v-model="tabledCheckSelected"
              :headers="tableHeaders"
              :items="tableItems"
              :item-class="itemClassHandler"
              :single-select="false"
              show-select
              class="elevation-1"
              disable-pagination
              hide-default-footer
              item-key="_id"
              :items-per-page="-1"
              no-data-text="ไม่มีผู้ขับ"
              @click:row="tableClickHandler"
            >
              <template v-slot:[`item.rowNum`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-avatar size="32">
                  <img :src="item.profileImage" />
                </v-avatar>

                <span
                  :class="[
                    {
                      'red--text text--lighten-1 font-weight-medium':
                        checkUserJobHasOverlap(item._id),
                    },
                  ]"
                >
                  {{ item.fullName }}
                  <span
                    v-if="checkUserCellHasOverlap(item._id)"
                    class="red--text text--lighten-1 font-weight-medium"
                  >
                    [!!!]
                  </span>
                </span>
              </template>
              <!--  -->
              <template v-slot:[`item.jobs`]="{ item }">
                <p class="d-inline mb-0">
                  {{ item.statOnSheet.jobs }} [{{ item.jobs }}]
                </p>
              </template>
              <template v-slot:[`item.free`]="{ item }">
                <p class="d-inline mb-0">
                  {{ item.statOnSheet.free }} [{{ item.free }}]
                </p>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6" class="pl-3 pr-6">
            <CurrentDriverStatusChart
              :refresh-key="chartKey"
              :table-id="tableId"
              :selected-driver-list="selectedDriverList"
              :sheet-table-data="sheetTableData"
              :overlap-job="overlapJob"
              :cell-data="cellData"
              @update-table-driver="updateTableDriverFromChartHandler"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>

      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndCloseHandler" v-if="fetchDataError <= 0">
          ยกเลิก
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveAndCloseHandler"
          v-if="fetchDataError === 0"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CurrentDriverStatusChart from "../chart/CurrentDriverStatusChart.vue";
import {
  putDriverToMultipleJobService,
  getTableCellDriverInCartHasJobService,
} from "@/services/api/assign_multiple_job";

import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "AssignMultipleJobCellManageDialog",
  props: {},
  data: () => ({
    show: false,
    fetchDataError: 1,
    //
    tableId: null,
    sheetTableData: null,
    // input
    selectedDriverList: [],
    filterDriverList: [],
    categoryName: [],

    // table
    tabledCheckSelected: [],
    tableHeaders: [
      {
        text: "#",
        value: "rowNum",
        sortable: false,
        align: "start",
        width: "5%",
      },
      {
        text: "ชื่อผู้ขับ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "วิ่ง", value: "jobs", align: "end", width: "10%" },
      { text: "ฟรี", value: "free", align: "end", width: "10%" },
    ],
    tableItems: [],
    formData: {
      newNumberOfSelectedDriver: null,
    },
    //
    cellData: null,
    onCartDriver: [],
    jobs: [],
    overlapJob: {},
    overlapCell: [],
    // jobs: [
    //   { work: -4, free: 3, name: "นายมนตรี งามอัครกุล (ตรี)" },
    //   { work: -10, free: 2, name: "นายกติกา กองศิล (แชมป์)" },
    //   { work: -15, free: 0, name: "นายผู้ขับสอง มือสอง (สอง)" },
    //   { work: -9, free: 4, name: "นายผู้ขับหนึ่ง มือหนึ่ง (หนึ่ง)" },
    //   { work: -13, free: 2, name: "นายผู้ขับสาม มือสาม (สาม)" },
    // ],
    jobWork: [],
    jobFree: [],
    //
    chartKey: 0,
  }),

  computed: {
    ...mapGetters({
      existingDriverInCellJobStatusById:
        "assignMultipleJob/existingDriverInCellJobStatusById",
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    selectedItemStatus() {
      return this.tabledCheckSelected.length > 0;
    },
    rowsData() {
      return _.get(
        this.$store.state,
        `assignMultipleJob.rowData.${this.tableId}.${this.sheetTableData["row"]}`,
        null
      );
    },
    colsDataPrev() {
      return _.get(
        this.$store.state,
        `assignMultipleJob.columnsData.${this.tableId}.${
          this.sheetTableData["col"] - 1
        }`,
        null
      );
    },

    colsData() {
      return _.get(
        this.$store.state,
        `assignMultipleJob.columnsData.${this.tableId}.${this.sheetTableData["col"]}`,
        null
      );
    },

    // cellData: function () {
    //   return _.get(
    //     this.$store.state,
    //     `assignMultipleJob.cellData.T${this.tableId}R${this.vuexCellRow}C${this.vuexCellCol}`,
    //     null
    //   );
    //   // return {};
    // },

    colsDataNext() {
      return _.get(
        this.$store.state,
        `assignMultipleJob.columnsData.${this.tableId}.${
          this.sheetTableData["col"] + 1
        }`,
        null
      );
    },

    numberOfSelectedDriver() {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.sheetTableData["row"]}C${this.sheetTableData["col"]}.numberOfSelectedDriver`,
        null
      );
    },
  },

  methods: {
    open(tableId, sheetTableData) {
      this.fetchDataError = 1;
      this.resetForm();
      this.tableId = tableId;
      this.sheetTableData = sheetTableData;
      // this.settingData();

      this.show = true;
      this.fetchData();
      // this.fetchDataError = 0;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    itemClassHandler(item) {
      if (!_.isNil(item["jobStatus"])) {
        switch (item["jobStatus"]) {
          case "OK":
            return "green lighten-5";
          case "!OK":
            return "red lighten-5";
          default:
            return "";
        }
      }
      return "";
    },
    async fetchData() {
      this.cellData = _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.sheetTableData.tableId}R${this.sheetTableData.row}C${this.sheetTableData.col}`,
        null
      );
      const queryString = new URLSearchParams({
        id: this.cellData.cellMId,
      }).toString();
      //
      const responseData = await getTableCellDriverInCartHasJobService(
        this.$store,
        queryString
      );

      if (responseData["code"] === 200) {
        const { data } = responseData;
        // update DriverInCell job status:: loop driver
        this.overlapJob = data["drivers"];
        this.overlapCell = data["driversHasCell"];
        this.settingData();
      } else {
        console.error("fetch_data_error");
        this.fetchDataError = -2;
        return;
      }
      this.fetchDataError = 0;
    },
    settingData() {
      if (!_.isNil(this.cellData)) {
        for (let i = 0; i < this.cellData["drivers"].length; i++) {
          const _driverId = this.cellData["drivers"][i]["_id"];
          let _selectedDriver = _.cloneDeep(this.cellData["drivers"][i]);
          _selectedDriver["statOnSheet"] = _.get(
            this.sumDriverStatOnSheet,
            _driverId,
            { free: 0, jobs: 0 }
          );

          this.selectedDriverList.push(_driverId);

          this.addDriverToTable(_.cloneDeep(_selectedDriver));
        }
        // process onCartDriver
        const onCartDriver = _.get(
          this.$store.state["assignMultipleJob"],
          `driverInCart.${this.tableId}`,
          null
        );
        let _onCartDriver = [];
        onCartDriver.forEach((item) => {
          const _driverId = item["_id"];
          const driverCellStat = _.get(
            this.$store.state,
            `assignMultipleJob.driverInCellStat.${this.tableId}.${item["_id"]}`,
            {}
          );
          _onCartDriver.push({
            ...item,
            driverCellStat: _.clone(driverCellStat),
            statOnSheet: _.get(this.sumDriverStatOnSheet, _driverId, {
              free: 0,
              jobs: 0,
            }),
          });
          item["driverCellStat"] = _.cloneDeep(driverCellStat);
        });
        this.defaultDriverItems = _onCartDriver;
        this.formData["newNumberOfSelectedDriver"] =
          this.numberOfSelectedDriver;
        this.onfilterListChange();
        this.chartKey += 1;
      }
    },

    checkUserJobHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapJob, `${driverId}`, []);
        const jobInOverlop = _.map(_d, (item) => item["_id"]);
        const _driverInCell = _.filter(
          this.cellData.drivers,
          (item) => item["_id"] === driverId
        );
        // check duplication
        let jobIntersect = [];
        if (_driverInCell.length > 0) {
          jobIntersect = _.intersection(jobInOverlop, [
            _driverInCell[0]["jobId"],
          ]);
        }
        return _d.length > 0 && jobIntersect.length === 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },
    checkUserCellHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapCell, `${driverId}`, []);
        const cellInOverlop = _.map(_d, (item) => item["matchCellId"]);
        // check duplication
        let cellIntersect = [];
        if (cellInOverlop.length > 0) {
          cellIntersect = _.filter(
            cellInOverlop,
            (item) => item !== this.cellData.cellMId
          );
        }
        return cellIntersect.length > 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },

    getTime(dateTime) {
      return moment(dateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    },
    addDriverToTable(newValue) {
      const driverCellStat = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCellStat.${this.tableId}.${newValue["_id"]}`,
        {}
      );
      this.tableItems.push({ ..._.cloneDeep(newValue), ...driverCellStat });
    },
    onfilterListChange() {
      this.filterDriverList = this.defaultDriverItems.filter(
        (item) => this.selectedDriverList.indexOf(item._id) === -1
      );
    },
    itemChange(newValue) {
      this.selectedDriverList.push(newValue["_id"]);
      this.addDriverToTable(newValue);
      this.onfilterListChange();

      // TODO update chart
      this.chartKey += 1;
    },
    saveAndCloseHandler() {
      this.fetchDataError = 2;
      const insertData = {
        drivers: this.selectedDriverList,
        tableItems: this.tableItems,
      };

      if (!_.isNil(this.formData.newNumberOfSelectedDriver)) {
        insertData["numberOfSelectedDriver"] =
          this.formData.newNumberOfSelectedDriver;
      }

      // save
      // this.saveData(insertData);
      this.resolve(insertData);
      this.show = false;
    },
    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
    async saveData(insertData) {
      try {
        const queryString = new URLSearchParams({
          id: this.cellData.cellMId,
        }).toString();

        // loading cell
        this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
          updateAtCellId: this.cellData.cellId,
          updateData: {
            inputState: -1,
          },
        });
        const responseData = await putDriverToMultipleJobService(
          this.$store,
          queryString,
          insertData
        );

        if (responseData["code"] === 200) {
          //
          let updateData = {
            inputState: 1,
            drivers: this.tableItems,
          };

          if (!_.isNil(insertData["numberOfSelectedDriver"])) {
            updateData["numberOfSelectedDriver"] =
              insertData["numberOfSelectedDriver"];
          }
          //
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: this.cellData.cellId,
            updateData: updateData,
          });
          //
        } else {
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: this.cellData.cellId,
            updateData: {
              inputState: -2,
            },
          });
          this.fetchDataError = -1;
        }
        //
      } catch (error) {
        this.fetchDataError = -1;
      }
    },
    resetForm() {
      this.selectedDriverList = [];
      this.tableItems = [];
      this.defaultDriverItems = [];

      this.tableId = null;
      this.sheetTableData = null;
      this.tabledCheckSelected = [];
      this.formData = {
        newNumberOfSelectedDriver: null,
      };
    },
    deleteSelection() {
      const checkSelectedDriverList = this.tabledCheckSelected.map(
        (item) => item._id
      );

      for (let i = 0; i < checkSelectedDriverList.length; i++) {
        let driverIdx = this.selectedDriverList.findIndex(
          (item) => item === checkSelectedDriverList[i]
        );

        if (driverIdx !== -1) {
          this.selectedDriverList.splice(driverIdx, 1);
        }
      }

      this.tableItems = this.tableItems.filter(
        (item) => !checkSelectedDriverList.includes(item["_id"])
      );

      this.filterDriverList = this.defaultDriverItems.filter(
        (item) => this.selectedDriverList.indexOf(item._id) === -1
      );

      this.chartKey += 1;
      this.tabledCheckSelected = [];
    },
    cancelSelection() {
      this.tabledCheckSelected = [];
    },
    tableClickHandler(newItem) {
      // add item to select
      const checkSelectedDriverList = this.tabledCheckSelected.map(
        (item) => item._id
      );

      if (checkSelectedDriverList.includes(newItem["_id"])) {
        // remove at _id
        this.tabledCheckSelected = this.tabledCheckSelected.filter(
          (item) => item["_id"] !== newItem["_id"]
        );
      } else {
        // add new item
        this.tabledCheckSelected.push(newItem);
      }
    },
    // chart handler
    updateTableDriverFromChartHandler(selectedItem) {
      const driverCellStat = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCellStat.${this.tableId}.${selectedItem["_id"]}`,
        {
          free: 0,
          jobs: 0,
        }
      );
      const newItems = {
        ...selectedItem,
        driverCellStat: _.clone(driverCellStat),
        statOnSheet: _.get(this.sumDriverStatOnSheet, selectedItem["_id"], {
          free: 0,
          jobs: 0,
        }),
      };
      //
      this.itemChange(newItems);
    },
  },
  components: { CustomTextInputImprove, CurrentDriverStatusChart },
};
</script>

<style scoped lang="scss">
.local-card-text {
  background-color: #354253;
}
</style>
