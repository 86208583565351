<template>
  <v-dialog v-model="show" width="50%" :persistent="true">
    <v-card>
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>เพิ่มเส้นทางหลัก</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="cancelStatus" v-if="fetchDataError === 0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 0">
        <v-form ref="RoutesTemplateForm" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12">
              <CustomTextInputImprove
                title="ชื่อเส้นทางหลัก"
                ref="name"
                v-model="formData.name"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องกรอกชื่อเส้นทางหลัก']"
              />
              <CustomTextareaImprove
                title="คำอธิบายเส้นทาง"
                ref="desc"
                v-model="formData.desc"
              />
              <InputView
                class="px-3"
                title="สัญญา"
                :is-required="true"
                :bottom-margin="false"
                :small="true"
              >
                <JobContractSelectorInput
                  ref="JobContractSelectorInputRef"
                  @searchItemChange="getSearchContractItem"
                  :value="formData.contract"
                  :hide-no-detail="false"
                />
              </InputView>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 1">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 0"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 0"
          color="primary"
          text
          @click="saveStatus"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import _ from "lodash";
import InputView from "@/components/InputView.vue";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import { postRouteTemplete } from "@/services/api/contract_job_routes";

export default {
  name: "CreateRoutesTemplateDialog",
  components: {
    CustomTextInputImprove,
    CustomTextareaImprove,
    InputView,
    JobContractSelectorInput,
  },
  data() {
    return {
      valid: null,
      resolve: null,
      reject: null,
      show: false,
      fetchDataError: 0,
      formData: {
        name: null,
        desc: null,
        contract: null,
      },
    };
  },
  methods: {
    resetForm() {
      this.formData = {
        name: null,
        desc: null,
        contract: null,
      };
      this.fetchDataError = 0;
    },
    open() {
      this.resetForm();
      this.show = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async saveStatus() {
      this.fetchDataError = 1;
      if (this.$refs["RoutesTemplateForm"].validate() === true) {
        const responseData = await postRouteTemplete(
          this.$store,
          null,
          this.formData
        );
        if (responseData["code"] === 200) {
          this.show = false;
          this.$router.push(
            `/caj/routes/edit/${responseData["data"]["templateId"]}`
          );
        } else {
          this.fetchDataError = -1;
        }
      } else {
        this.fetchDataError = -1;
        console.error("ROUTES_TEMPLATE_FORM_INVALIDATE");
      }
    },
    cancelStatus() {
      this.resolve(null);
      this.$refs["RoutesTemplateForm"].reset();
      this.resetForm();
      this.show = false;
    },
    //
    getSearchContractItem(searchItem) {
      this.formData["contract"] = searchItem;
    },
  },
};
</script>
