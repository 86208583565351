<template>
  <apexchart
    type="area"
    height="350"
    :options="chartOptions"
    :series="seriesArea"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import moment from "moment";
import { getShortMonthNameHelper } from "@/services/appDate";
import {
  displayNumberLocaleString,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";

export default {
  name: "ContractIncomeAndExpensesWeekly",
  props: {
    data: {
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  data() {
    return {
      seriesArea: [],
      xLabelList: [],
      annotationPoints: [],
      annotationYaxis: [],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "area",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#49c496", "#ed5555"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: this.xLabelList,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return nFormatterHelper(value);
            },
          },
        },
        annotations: {
          yaxis: this.annotationYaxis,
          points: this.annotationPoints,
          //[
          // {
          //   x: "1",
          //   y: 5000,
          //   marker: {
          //     size: 0,
          //   },
          //   label: {
          //     borderColor: "#AAB7B8",
          //     offsetY: 0,
          //     style: {
          //       color: "#fff",
          //       background: "#AAB7B8",
          //     },
          //     text: "1,234",
          //   },
          // },
          // {
          // ]
        },
        tooltip: {
          x: {
            show: true,
            // formatter: function (value, opts) {
            //   if (opts) {
            //     // var val =
            //     //   opts.w.config.series[opts.seriesIndex]["data"][
            //     //     opts.dataPointIndex
            //     //   ];
            //     // console.log("val", val);
            //     return `<span class="text-app-title font-weight-bold black--text">สัปดาห์ที่ ${
            //       opts.dataPointIndex + 1
            //     }</span>`;
            //   }
            // },
          },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
      };
    },
  },
  methods: {
    processData() {
      let weekName = [];
      try {
        if (!_.isNil(this.data)) {
          if (this.data.weeklyChart.length === 0) {
            throw "data not found";
          }

          for (let i = 0; i < this.data.weeklyChart.length; i++) {
            const startDateOnWeek = moment()
              .day(0)
              .year(this.data.weeklyChart[i]["year"])
              .week(this.data.weeklyChart[i]["week"]);
            const endDateOnWeek = moment()
              .day(0)
              .year(this.data.weeklyChart[i]["year"])
              .week(this.data.weeklyChart[i]["week"])
              .add(6, "days");

            // DD - DD MM YYYY
            // DD MM - DD MM YYY
            // DD MM YYYY -DD MMM -YYYY
            let startDateStr = startDateOnWeek.format("DD");
            let endDateStr = endDateOnWeek.format("DD");
            if (startDateOnWeek.format("MM") !== endDateOnWeek.format("MM")) {
              startDateStr = `${startDateStr} ${getShortMonthNameHelper(
                startDateOnWeek.month()
              )}`;
              endDateStr = `${endDateStr} ${getShortMonthNameHelper(
                endDateOnWeek.month()
              )}`;
            } else {
              endDateStr = `${endDateStr} ${getShortMonthNameHelper(
                endDateOnWeek.month()
              )}`;
            }

            // if (startDateOnWeek.format("YYYY") !== endDateOnWeek.format("YYYY")) {
            //   startDateStr = `${startDateStr} ${startDateOnWeek.format("YYYY")}`;
            //   endDateStr = `${endDateStr} ${endDateOnWeek.format("YYYY")}`;
            // } else {
            //   endDateStr = `${endDateStr} ${startDateOnWeek.format("YYYY")}`;
            // }

            weekName.push(`${startDateStr} - ${endDateStr}`);
          }

          this.xLabelList = weekName;
          const totalIncome = _.map(this.data.weeklyChart, "totalIncome");
          const totalExpenses = _.map(this.data.weeklyChart, "totalExpenses");
          const totalAverageIncome =
            _.divide(_.sum(totalIncome), this.xLabelList.length) || 0;
          const totalAverageExpenses =
            _.divide(_.sum(totalExpenses), this.xLabelList.length) || 0;

          let _annotationPoints = [];

          if (totalIncome.length === totalExpenses.length) {
            for (let i = 0; i < totalIncome.length; i++) {
              const _res = _.subtract(totalIncome[i], totalExpenses[i]) || 0;
              _annotationPoints.push({
                x: this.xLabelList[i],
                y: _res,
                marker: {
                  size: 0,
                },
                label: {
                  borderColor: "#AAB7B8",
                  offsetY: 0,
                  style: {
                    color: "#fff",
                    background: "#AAB7B8",
                  },
                  text: displayNumberLocaleString(_res, 2),
                },
              });
            }
          }

          this.annotationYaxis = [
            {
              y: totalAverageIncome,
              strokeDashArray: 1,
              borderWidth: 2,
              borderColor: "#775DD0", //"#AAB7B8",
              label: {
                text: `รายได้เฉลี่ย: ${displayNumberLocaleString(
                  totalAverageIncome,
                  2
                )}`,
              },
            },
            {
              y: totalAverageExpenses,
              strokeDashArray: 1,
              borderWidth: 2,
              borderColor: "#775DD0",
              label: {
                text: `รายจ่ายเฉลี่ย: ${displayNumberLocaleString(
                  totalAverageExpenses,
                  2
                )}`,
              },
            },
          ];

          this.annotationPoints = _annotationPoints;
          //
          this.seriesArea = [
            {
              name: "รายได้ที่ได้รับทั้งหมด",
              data: totalIncome,
            },
            {
              name: "รายจ่ายทั้งหมด",
              data: totalExpenses,
            },
          ];
        } else {
          throw "no data";
        }
      } catch (error) {
        this.seriesArea = [
          {
            name: "รายได้ที่ได้รับทั้งหมด",
            data: [],
          },
          {
            name: "รายจ่ายทั้งหมด",
            data: [],
          },
        ];
        this.xLabelList = [];
        this.annotationPoints = [];
        this.annotationYaxis = [];
      }
    },
  },

  mounted() {
    this.processData();
  },
};
</script>
