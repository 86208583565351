<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="600px" persistent>
      <v-form v-if="show" ref="form" v-model="valid">
        <v-card dark color="#222831">
          <v-sheet color="#323841" height="60">
            <v-card-title class="mx-2">
              <div style="font-size: 16px">แก้ไขรายรับหักเพิ่มเติม</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="cancel()">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
          </v-sheet>
          <v-divider></v-divider>
          <v-row no-gutters class="mx-3 mt-3">
            <v-col cols="12" lg="12" sm="12">
              <v-radio-group
                v-model="extraPaymentForm.type"
                row
                class="mt-1 mb-0 mx-3"
              >
                <!-- <template v-slot:label></template> -->
                <v-radio label="หักเงิน" :value="0"></v-radio>
                <v-radio label="เพิ่มเงิน" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-3">
            <v-col cols="12" lg="12" sm="12">
              <CustomTextInputImprove
                v-model="extraPaymentForm.name"
                ref="extraPaymentName"
                title="ชื่อรายการหักเพิ่มเติม"
                :isDark="true"
                :isRequired="true"
                placeholder="ชื่อรายการหักเพิ่มเติม"
                dataInputName="extraPaymentName"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อรายการหักเพิ่มเติม']"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-3 mt-1">
            <v-col cols="12" lg="12" sm="12">
              <!-- <CustomTextInputImprove
                v-model="extraPaymentForm.date"
                ref="extraPaymentDate"
                title="วันที่หัก"
                :isDark="true"
                :isRequired="false"
                dataInputName="extraPaymentDate"
              /> -->
              <CustomDateTypingPickerFormInput
                placeholder="เลือก"
                title="ณ วันที่ (วัน/เดือน/ปี)"
                :isDark="true"
                v-model="extraPaymentForm.date"
                :isRequired="true"
                ref="extraPaymentDate"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุวันที่หัก']"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-3 mt-1">
            <v-col cols="12" lg="12" sm="12">
              <CustomTextInputCurrency
                ref="extraPaymentAmount"
                v-model="extraPaymentForm.amount"
                title="จำนวนเงิน (บาท)"
                :isDark="true"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุจำนวนเงิน']"
              />
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-sheet color="#323841" class="px-3 py-2">
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="white"
                text
                @click="cancel()"
                class="font-weight-bold mr-6"
              >
                ยกเลิก
              </v-btn> -->
              <v-btn
                color="primary"
                @click="saveChange()"
                class="font-weight-bold"
              >
                บันทึก
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </v-card>
      </v-form>
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import moment from "moment";

export default {
  name: "worksheetExtraPayment",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    AppDialogConfirm,
    CustomTextInputImprove,
    CustomDateTypingPickerFormInput,
    CustomTextInputCurrency,
  },

  data() {
    return {
      show: false,
      valid: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,
      componentRefreshCounter: 0,
      extraPaymentForm: {
        name: null,
        date: null,
        amount: 0,
        type: 0,
      },
    };
  },

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.extraPaymentForm = {
        name: null,
        date: null,
        amount: 0,
        type: 0,
      };
    },

    open(payment) {
      this.resetFormData();
      if (!_.isNil(payment)) {
        this.setFormData(payment);
      }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    setFormData(payment) {
      if (!_.isNil(payment)) {
        this.extraPaymentForm = _.clone(payment);
        this.extraPaymentForm["date"] = payment["date"].split("T")[0];
      }
    },

    saveChange() {
      if (this.$refs.form.validate() === true) {
        let _formData = _.clone(this.extraPaymentForm);
        this.resolve(_formData);
        this.show = false;
      }
    },

    cancel() {
      this.resolve(null);
      this.show = false;
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getPriceColor(number) {
      return number < 0 ? "#FF6745" : "#FFFFFF";
    },
  },
};
</script>

<style scoped>
.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.table_header_text_style {
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
