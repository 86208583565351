<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="835px" persistent>
      <v-card dark color="#222831" v-if="show">
        <v-sheet color="#323841" height="60">
          <v-card-title class="mx-2">
            <div style="font-size: 16px">แก้ไขสรุปใบงาน</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancel()">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-sheet>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="6" class="mt-2">
              <v-row no-gutters>
                <v-list-item-avatar size="60" class="mr-3">
                  <v-img
                    :src="
                      worksheetForm.profileImage === null
                        ? defaultPFImage
                        : worksheetForm.profileImage
                    "
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div>
                    <v-list-item-title
                      class="text-app-detail-large white--text font-weight-bold"
                    >
                      {{
                        `${
                          worksheetForm.salutation === null
                            ? ""
                            : worksheetForm.salutation.th
                        }${
                          worksheetForm.firstName === null
                            ? ""
                            : worksheetForm.firstName.th
                        } ${
                          worksheetForm.lastName === null
                            ? ""
                            : worksheetForm.lastName.th
                        } `
                      }}
                      {{
                        worksheetForm.nickName !== null
                          ? worksheetForm.nickName.th !== null
                            ? `(${worksheetForm.nickName.th})`
                            : "-"
                          : "-"
                      }}</v-list-item-title
                    >

                    <span class="text-app-normal" v-if="worksheetForm.empId">
                      {{ worksheetForm.empId }}
                    </span>
                    <span
                      class="text-app-normal"
                      v-if="worksheetForm.empId && worksheetForm.position"
                    >
                      |
                    </span>
                    <span class="text-app-normal" v-if="worksheetForm.position">
                      {{ worksheetForm.position }}
                    </span>
                  </div>
                  <span class="text-app-normal" v-if="worksheetForm.email"
                    >อีเมล: {{ worksheetForm.email }}</span
                  >

                  <div v-if="worksheetForm.phoneNumber">
                    <span class="text-app-normal"> โทร: </span>
                    <span
                      class="text-app-normal"
                      v-for="(number, idx) in worksheetForm.phoneNumber"
                      :key="idx"
                    >
                      {{ number }}
                      <span v-if="idx < worksheetForm.phoneNumber.length - 1"
                        >,</span
                      >
                    </span>
                  </div>
                </v-list-item-content>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right mt-6" v-if="isPermissionCanEdit">
              <PrimaryButtonVue
                :icon="'mdi-download'"
                :height="30"
                @on-click="handleDownloadPDF(id)"
                >ดาวน์โหลดใบเสร็จ</PrimaryButtonVue
              ></v-col
            >
          </v-row>

          <v-card-subtitle class="mt-0">
            <v-row>
              <v-col cols="6" md="3">
                <v-list-item-title class="subtitle_style">
                  รหัสใบงาน
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="value_style">
                    {{
                      worksheetForm.paymentName
                        ? worksheetForm.paymentName
                        : "-"
                    }}
                  </div>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="6" md="3">
                <v-list-item-title class="subtitle_style">
                  วันที่เริ่มงาน
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="value_style">
                    {{
                      worksheetForm.createdDate
                        ? dateStringToDateFormat(worksheetForm.createdDate)
                        : "-"
                    }}
                  </div>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="6" md="3">
                <v-list-item-title class="subtitle_style">
                  วันที่สรุปยอด
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="value_style">
                    {{
                      worksheetForm.summarizedDate
                        ? dateStringToDateFormat(worksheetForm.summarizedDate)
                        : "-"
                    }}
                  </div>
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="6" md="3">
                <v-list-item-title class="subtitle_style">
                  จำนวนงาน
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div
                    v-if="worksheetForm.contractJobAmount"
                    class="value_style"
                  >
                    {{ worksheetForm.contractJobAmount }}
                  </div>
                  <div v-else class="value_style">-</div>
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider class="mt-6 mb-5"></v-divider>
          <div
            class="white--text text-app-detail-large font-weight-bold mt-0 mb-5"
          >
            รายการวิ่งรถ
          </div>
          <v-simple-table dense style="background-color: #222831">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table_item_name">
                    <div class="table_header_text_style">ชื่อรายการ</div>
                  </th>
                  <th class="text-left">
                    <div class="table_header_text_style">วันที่</div>
                  </th>
                  <th class="text-right">
                    <div class="table_header_text_style mr-2">
                      จำนวนเงิน (บาท)
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) in worksheetForm.paymentList"
                  :key="idx"
                  class="clickable-row"
                  @click="handleJobOnClick(item.id)"
                >
                  <td class="table_body_text_style table_item_name">
                    <div>{{ item.name }}</div>
                  </td>
                  <td class="table_body_text_style">
                    {{ dateStringToDateFormat(item.date) }}
                  </td>
                  <td
                    class="text-right table_body_text_style"
                    :style="{
                      color: getPriceColor(item.amount),
                      fontWeight: 'bold',
                    }"
                  >
                    <div class="mr-2">
                      {{ getPriceString(item.amount.toFixed(2)) }}
                    </div>
                  </td>
                  <!-- <td>
                    <v-menu bottom left
                      >s
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon color="#c5ccd2">mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in menuMoreItems"
                          :key="i"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row no-gutters class="my-2">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
              <span class="white--text text-subtitle-2 font-weight-bold mb-2">
                รายได้รวม:
                <span
                  :style="{
                    color: getTotalPriceColor(paymentListIncome),
                    fontWeight: 'bold',
                  }"
                  >&nbsp;
                  {{
                    paymentListIncome ? getPriceString(paymentListIncome) : "-"
                  }}
                  บาท</span
                >
              </span>
            </v-col>
            <v-col cols="auto" class="text-right">
              <span
                class="ml-6 white--text text-subtitle-2 font-weight-bold mb-2"
              >
                รายหักรวม:
                <span
                  :style="{
                    color: getTotalPriceColor(paymentListOutcome),
                    fontWeight: 'bold',
                  }"
                  >&nbsp;
                  {{
                    paymentListOutcome
                      ? getPriceString(paymentListOutcome)
                      : "-"
                  }}
                  บาท</span
                >
              </span>
            </v-col>
          </v-row>
          <v-divider class="mt-6 mb-5"></v-divider>
          <div
            class="mt-0 mb-5 white--text text-app-detail-large font-weight-bold"
          >
            รายการเพิ่มเติม
          </div>
          <v-simple-table dense style="background-color: #222831">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table_item_name">
                    <div class="table_header_text_style">ชื่อรายการ</div>
                  </th>
                  <th class="text-left">
                    <div class="table_header_text_style">วันที่</div>
                  </th>
                  <th class="text-right">
                    <div class="table_header_text_style pr-3">
                      จำนวนเงิน (บาท)
                    </div>
                  </th>
                  <th
                    v-if="
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED'
                    "
                  ></th>
                </tr>
              </thead>
              <tbody>
                <!-- <div v-if="worksheetForm.extraPayment.length !== 0"> -->
                <tr
                  v-for="(item, idx) in worksheetForm.extraPayment"
                  :key="idx"
                >
                  <td class="table_body_text_style table_item_name">
                    <div>{{ item.name }}</div>
                  </td>
                  <td class="table_body_text_style">
                    {{ dateStringToDateFormat(item.date) }}
                  </td>
                  <td
                    class="text-right table_body_text_style pr-7"
                    :style="{
                      color: getExtraPriceColor(item.type),
                      fontWeight: 'bold',
                    }"
                  >
                    <span v-if="item.type == 1">{{
                      getPriceString(item.amount.toFixed(2))
                    }}</span>
                    <span v-else>{{
                      getPriceString((item.amount * -1).toFixed(2))
                    }}</span>
                  </td>
                  <td
                    class="text-right"
                    v-if="
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED' &&
                      isPermissionCanEdit
                    "
                  >
                    <v-btn
                      @click="handleDeleteClick(idx)"
                      class="mx-2"
                      icon
                      color="primary"
                    >
                      <v-icon> mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="handleEditClick(item, idx)"
                      icon
                      ><v-icon> mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <!-- <v-card
                v-else
                elevation="0"
                class="d-flex justify-center align-center card-bg"
              >
                <p
                  v-if="componentLoading"
                  class="text-app-detail-normal align-stretch"
                >
                  กำลังดึงข้อมูล...
                </p>
                <p v-else class="text-app-detail-normal align-stretch">
                  ไม่มีข้อมูล
                </p>
              </v-card> -->
            </template>
          </v-simple-table>

          <div
            color="black"
            style="
              border-top: 1px dashed #74838c;
              border-bottom: 1px dashed #74838c;
            "
            v-if="
              worksheetOriStatus !== 'HOLD' &&
              worksheetOriStatus !== 'PAID' &&
              worksheetOriStatus !== 'CANCELLED' &&
              isPermissionCanEdit
            "
          >
            <v-sheet height="46" color="#272D36">
              <v-card-actions class="justify-center">
                <v-btn text height="30" @click="handleAddClick()">
                  + เพิ่มรายการ
                </v-btn>
              </v-card-actions>
            </v-sheet>
          </div>
          <v-row no-gutters class="my-2">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
              <span class="white--text text-subtitle-2 font-weight-bold mb-2">
                รายได้เพิ่มเติมรวม:
                <span
                  :style="{
                    color: getTotalPriceColor(extraIncomePaymentTotal),
                    fontWeight: 'bold',
                  }"
                >
                  &nbsp;
                  {{
                    extraIncomePaymentTotal
                      ? getPriceString(extraIncomePaymentTotal)
                      : "-"
                  }}
                  บาท
                </span>
              </span>
            </v-col>
            <v-col cols="auto" class="text-right">
              <span
                class="ml-7 white--text text-subtitle-2 font-weight-bold mb-2"
              >
                รายหักเพิ่มเติมรวม:
                <span
                  :style="{
                    color: getTotalPriceColor(extraOutcomePaymentTotal),
                    fontWeight: 'bold',
                  }"
                >
                  &nbsp;
                  {{
                    extraOutcomePaymentTotal
                      ? getPriceString(extraOutcomePaymentTotal)
                      : "-"
                  }}
                  บาท
                </span>
              </span>
            </v-col>
          </v-row>
          <v-divider class="mt-6 mb-5"></v-divider>
          <div
            class="mt-0 mb-5 white--text text-app-detail-large font-weight-bold"
          >
            เงินเดือน
          </div>
          <v-simple-table dense style="background-color: #222831">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table_item_name">
                    <div class="table_header_text_style">ชื่อรายการ</div>
                  </th>
                  <th class="text-right">
                    <div class="table_header_text_style pr-3">
                      จำนวนเงิน (บาท)
                    </div>
                  </th>
                  <th
                    v-if="
                      worksheetOriStatus !== 'NOT_PAID' &&
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED'
                    "
                  ></th>
                </tr>
              </thead>
              <tbody>
                <!-- <div v-if="worksheetForm.extraPayment.length !== 0"> -->
                <tr>
                  <td class="table_body_text_style table_item_name">
                    <div>เงินเดือน</div>
                  </td>
                  <td
                    class="text-right table_body_text_style pr-7"
                    :style="{
                      fontWeight: 'bold',
                    }"
                  >
                    <span
                      v-if="
                        worksheetForm['salary'] !== null &&
                        worksheetForm['salary'] !== undefined
                      "
                      >{{
                        getPriceString(worksheetForm["salary"].toFixed(2))
                      }}</span
                    >
                  </td>
                  <td
                    class="text-right"
                    v-if="
                      worksheetOriStatus !== 'NOT_PAID' &&
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED' &&
                      isPermissionCanEdit
                    "
                  >
                    <v-btn color="primary" @click="handleEditSalaryClick()" icon
                      ><v-icon> mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <!-- <v-card
                v-else
                elevation="0"
                class="d-flex justify-center align-center card-bg"
              >
                <p
                  v-if="componentLoading"
                  class="text-app-detail-normal align-stretch"
                >
                  กำลังดึงข้อมูล...
                </p>
                <p v-else class="text-app-detail-normal align-stretch">
                  ไม่มีข้อมูล
                </p>
              </v-card> -->
            </template>
          </v-simple-table>
          <v-divider class="mt-6 mb-5"></v-divider>

          <div
            class="mt-0 mb-5 white--text text-app-detail-large font-weight-bold"
          >
            รายการหักจากพฤติกรรม
          </div>
          <v-simple-table dense style="background-color: #222831">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table_item_name">
                    <div class="table_header_text_style">ชื่อรายการ</div>
                  </th>
                  <th class="text-left">
                    <div class="table_header_text_style">วันที่</div>
                  </th>
                  <th class="text-right">
                    <div class="table_header_text_style pr-3">
                      จำนวนเงิน (บาท)
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- <div v-if="worksheetForm.extraPayment.length !== 0"> -->
                <tr
                  v-for="(item, idx) in worksheetForm.behaviorList"
                  class="clickable-row"
                  @click="handleBehaviorOnClick(item.id)"
                  :key="idx"
                >
                  <td class="table_body_text_style table_item_name">
                    <div>{{ item.name }}</div>
                  </td>
                  <td class="table_body_text_style">
                    {{ dateStringToDateFormat(item.date) }}
                  </td>
                  <td class="text-right table_body_text_style pr-7">
                    <span v-if="item.amount">
                      <span
                        :style="{
                          color: getPriceColor(item.amount * -1),
                          fontWeight: 'bold',
                        }"
                        >{{
                          getPriceString((item.amount * -1).toFixed(2))
                        }}</span
                      >
                    </span>
                    <span v-else
                      ><span
                        :style="{
                          color: getPriceColor(0),
                          fontWeight: 'bold',
                        }"
                        >{{ getPriceString((0).toFixed(2)) }}</span
                      ></span
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="
              worksheetForm.behaviorList === null ||
              worksheetForm.behaviorList.length === 0
            "
            class="d-flex justify-center pt-3"
          >
            <p class="text-app-detail-normal align-stretch">ไม่มีข้อมูล</p>
          </div>

          <v-divider class="mt-6 mb-5"></v-divider>
          <div class="py-2">
            <v-row no-gutters>
              <v-col cols="6" class="pa-0 pr-6 ma-0">
                <v-col cols="12" class="pa-0 ma-0 mb-6">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <CustomTextInputImprove
                        class="ma-0"
                        ref="brokerPercent"
                        input-type="floatNumberWithOutSign"
                        :hide-detail="true"
                        :is-dark="true"
                        :disabled="
                          worksheetOriStatus == 'HOLD' ||
                          worksheetOriStatus == 'PAID' ||
                          worksheetOriStatus == 'CANCELLED' ||
                          !isPermissionCanEdit
                        "
                        v-model="worksheetForm.brokerPercent"
                        title="ค่าคอมมิชชั่น (%)"
                        placeholder=""
                        :is-required="false"
                        :dense="true"
                      ></CustomTextInputImprove>
                    </v-col>
                    <PrimaryButtonVue
                      v-show="
                        worksheetOriStatus !== 'HOLD' &&
                        worksheetOriStatus !== 'PAID' &&
                        worksheetOriStatus !== 'CANCELLED' &&
                        isPermissionCanEdit
                      "
                      class="mt-9"
                      :height="30"
                      @on-click="updatebrokerPercent()"
                      >บันทึก</PrimaryButtonVue
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0 mb-6">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <CustomTextInputImprove
                        class="ma-0"
                        ref="vat"
                        inputType="floatNumberWithOutSign"
                        :hide-detail="true"
                        v-model="worksheetForm.vatPercent"
                        title="หัก ณ ที่จ่าย (%)"
                        placeholder=""
                        :disabled="
                          worksheetOriStatus == 'HOLD' ||
                          worksheetOriStatus == 'PAID' ||
                          worksheetOriStatus == 'CANCELLED' ||
                          !isPermissionCanEdit
                        "
                        :is-dark="true"
                        :is-required="false"
                        :dense="true"
                      ></CustomTextInputImprove
                    ></v-col>
                    <PrimaryButtonVue
                      v-show="
                        worksheetOriStatus !== 'HOLD' &&
                        worksheetOriStatus !== 'PAID' &&
                        worksheetOriStatus !== 'CANCELLED' &&
                        isPermissionCanEdit
                      "
                      class="mt-9"
                      :height="30"
                      @on-click="updateVatPercent()"
                      >บันทึก
                    </PrimaryButtonVue>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <CustomTextInputImprove
                        class="ma-0"
                        ref="vat"
                        inputType="floatNumberWithOutSign"
                        :hide-detail="true"
                        v-model="worksheetForm.socialInsurancePercent"
                        title="ประกันสังคม (%)"
                        placeholder=""
                        :disabled="
                          worksheetOriStatus == 'HOLD' ||
                          worksheetOriStatus == 'PAID' ||
                          worksheetOriStatus == 'CANCELLED' ||
                          !isPermissionCanEdit
                        "
                        :is-dark="true"
                        :is-required="false"
                        :dense="true"
                      ></CustomTextInputImprove
                    ></v-col>
                    <PrimaryButtonVue
                      v-show="
                        worksheetOriStatus !== 'HOLD' &&
                        worksheetOriStatus !== 'PAID' &&
                        worksheetOriStatus !== 'CANCELLED' &&
                        isPermissionCanEdit
                      "
                      class="mt-9"
                      :height="30"
                      @on-click="updateSocialInsurancePercent()"
                      >บันทึก
                    </PrimaryButtonVue>
                  </v-row>
                </v-col>
              </v-col>
              <v-col cols="6">
                <v-sheet color="#222831" class="mt-0 mb-9 mr-3">
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้รวม</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div style="font-size: 14px">
                        {{
                          worksheetForm["sumPaymentAmount"]
                            ? getPriceString(
                                worksheetForm["sumPaymentAmount"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">
                        ค่าคอมมิชชั่น ({{
                          currentBrokerPercent ? currentBrokerPercent : "0"
                        }}%)
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div v-if="worksheetForm['brokerAmount']">
                        <div
                          :style="{
                            color: getTotalPriceColor(
                              worksheetForm['brokerAmount'] * -1
                            ),
                            fontSize: '14px',
                          }"
                        >
                          {{
                            worksheetForm["brokerAmount"]
                              ? getPriceString(
                                  (worksheetForm["brokerAmount"] * -1).toFixed(
                                    2
                                  )
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังค่าคอมมิชชั่น</div>
                    </v-col>
                    <v-col
                      v-if="worksheetForm['sumAfterBroker']"
                      cols="6"
                      class="text-right"
                    >
                      <div
                        v-if="worksheetForm['sumAfterBroker'] > 0"
                        style="font-size: 14px; font-weight: bold"
                      >
                        {{
                          worksheetForm["sumAfterBroker"]
                            ? getPriceString(
                                worksheetForm["sumAfterBroker"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>

                      <div
                        v-else
                        :style="{
                          color: getTotalPriceColor(paymentListOutcome),
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["sumAfterBroker"]
                            ? getPriceString(
                                worksheetForm["sumAfterBroker"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet color="#222831" class="mt-0 mb-9 mr-3">
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังค่าคอมมิชชั่น</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div style="font-size: 14px">
                        {{
                          worksheetForm["sumAfterBroker"]
                            ? getPriceString(
                                worksheetForm["sumAfterBroker"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายหักรวม</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div
                        :style="{
                          color: getTotalPriceColor(
                            worksheetForm['sumReductAmount'] * -1
                          ),
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["sumReductAmount"]
                            ? getPriceString(
                                (worksheetForm["sumReductAmount"] * -1).toFixed(
                                  2
                                )
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้เพิ่มเติมรวม</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div style="font-size: 14px">
                        {{
                          worksheetForm["sumExtraPaymentAmount"]
                            ? getPriceString(
                                worksheetForm["sumExtraPaymentAmount"].toFixed(
                                  2
                                )
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายหักเพิ่มเติมรวม</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div v-if="worksheetForm['sumExtraReductAmount']">
                        <div
                          :style="{
                            color: getTotalPriceColor(
                              worksheetForm['sumExtraReductAmount'] * -1
                            ),
                            fontSize: '14px',
                          }"
                        >
                          {{
                            worksheetForm["sumExtraReductAmount"]
                              ? getPriceString(
                                  (
                                    worksheetForm["sumExtraReductAmount"] * -1
                                  ).toFixed(2)
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังหัก</div>
                    </v-col>
                    <v-col
                      v-if="worksheetForm['sumAfterReduct']"
                      cols="6"
                      class="text-right"
                    >
                      <div
                        v-if="worksheetForm['sumAfterReduct'] > 0"
                        style="font-size: 14px; font-weight: bold"
                      >
                        {{
                          worksheetForm["sumAfterReduct"]
                            ? getPriceString(
                                worksheetForm["sumAfterReduct"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>

                      <div
                        v-else
                        :style="{
                          color: getTotalPriceColor(
                            worksheetForm['sumAfterReduct']
                          ),
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["sumAfterReduct"]
                            ? getPriceString(
                                worksheetForm["sumAfterReduct"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet color="#222831" class="mt-0 mr-3">
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังหัก</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div style="font-size: 14px">
                        {{
                          worksheetForm["sumAfterReduct"]
                            ? getPriceString(
                                worksheetForm["sumAfterReduct"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">เงินเดือน</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div
                        v-if="
                          worksheetForm['salary'] !== null &&
                          worksheetForm['salary'] !== undefined
                        "
                      >
                        <div style="font-size: 14px">
                          {{
                            worksheetForm["salary"]
                              ? getPriceString(
                                  worksheetForm["salary"].toFixed(2)
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายหักจากพฤติกรรม</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div v-if="worksheetForm['sumBehaviorReductAmount']">
                        <div
                          :style="{
                            color: getTotalPriceColor(
                              worksheetForm['sumBehaviorReductAmount'] * -1
                            ),
                            fontSize: '14px',
                          }"
                        >
                          {{
                            worksheetForm["sumBehaviorReductAmount"]
                              ? getPriceString(
                                  (
                                    worksheetForm["sumBehaviorReductAmount"] *
                                    -1
                                  ).toFixed(2)
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">
                        ประกันสังคม ({{
                          currentInsurancePercent
                            ? currentInsurancePercent
                            : "0"
                        }}%)
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div v-if="worksheetForm['socialInsuranceAmount']">
                        <div
                          :style="{
                            color: getTotalPriceColor(
                              worksheetForm['socialInsuranceAmount'] * -1
                            ),
                            fontSize: '14px',
                          }"
                        >
                          {{
                            worksheetForm["socialInsuranceAmount"]
                              ? getPriceString(
                                  (
                                    worksheetForm["socialInsuranceAmount"] * -1
                                  ).toFixed(2)
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">
                        หัก ณ ที่จ่าย ({{
                          currentVatPercent ? currentVatPercent : "0"
                        }}%)
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div v-if="worksheetForm['vatAmount']">
                        <div
                          :style="{
                            color: getTotalPriceColor(
                              worksheetForm['vatAmount'] * -1
                            ),
                            fontSize: '14px',
                          }"
                        >
                          {{
                            worksheetForm["vatAmount"]
                              ? getPriceString(
                                  (worksheetForm["vatAmount"] * -1).toFixed(2)
                                )
                              : getPriceString((0).toFixed(2))
                          }}
                        </div>
                      </div>
                      <div v-else>{{ getPriceString((0).toFixed(2)) }}</div>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังประกันภาษี</div>
                    </v-col>
                    <v-col
                      v-if="worksheetForm['sumAfterVat']"
                      cols="6"
                      class="text-right"
                    >
                      <div
                        v-if="worksheetForm['sumAfterVat'] > 0"
                        style="font-size: 14px; font-weight: bold"
                      >
                        {{
                          worksheetForm["sumAfterVat"]
                            ? getPriceString(
                                worksheetForm["sumAfterVat"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>

                      <div
                        v-else
                        :style="{
                          color: getTotalPriceColor(
                            worksheetForm['sumAfterVat']
                          ),
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["sumAfterVat"]
                            ? getPriceString(
                                worksheetForm["sumAfterVat"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                    <v-col v-else cols="6" class="text-right">{{
                      getPriceString((0).toFixed(2))
                    }}</v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="auto">
                <v-sheet width="76"> </v-sheet>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mt-6 mb-5"></v-divider>
          <div
            class="mt-0 mb-5 white--text text-app-detail-large font-weight-bold"
          >
            รายการเพิ่มเติมหลังประกันภาษี
          </div>
          <v-simple-table dense style="background-color: #222831">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table_item_name">
                    <div class="table_header_text_style">ชื่อรายการ</div>
                  </th>
                  <th class="text-left">
                    <div class="table_header_text_style">วันที่</div>
                  </th>
                  <th class="text-right">
                    <div class="table_header_text_style pr-3">
                      จำนวนเงิน (บาท)
                    </div>
                  </th>
                  <th
                    v-if="
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED'
                    "
                  ></th>
                </tr>
              </thead>
              <tbody>
                <!-- <div v-if="worksheetForm.extraPayment.length !== 0"> -->
                <tr
                  v-for="(item, idx) in worksheetForm.extraPaymentAfterVat"
                  :key="idx"
                >
                  <td class="table_body_text_style table_item_name">
                    <div>{{ item.name }}</div>
                  </td>
                  <td class="table_body_text_style">
                    {{ dateStringToDateFormat(item.date) }}
                  </td>
                  <td
                    class="text-right table_body_text_style pr-7"
                    :style="{
                      color: getExtraPriceColor(item.type),
                      fontWeight: 'bold',
                    }"
                  >
                    <span v-if="item.type == 1">{{
                      getPriceString(item.amount.toFixed(2))
                    }}</span>
                    <span v-else>{{
                      getPriceString((item.amount * -1).toFixed(2))
                    }}</span>
                  </td>
                  <td
                    class="text-right"
                    v-if="
                      worksheetOriStatus !== 'HOLD' &&
                      worksheetOriStatus !== 'PAID' &&
                      worksheetOriStatus !== 'CANCELLED' &&
                      isPermissionCanEdit
                    "
                  >
                    <v-btn
                      @click="handleAfterVatDeleteClick(idx)"
                      class="mx-2"
                      icon
                      color="primary"
                    >
                      <v-icon> mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="handleAfterVatEditClick(item, idx)"
                      icon
                      ><v-icon> mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            color="black"
            style="
              border-top: 1px dashed #74838c;
              border-bottom: 1px dashed #74838c;
            "
            v-if="
              worksheetOriStatus !== 'HOLD' &&
              worksheetOriStatus !== 'PAID' &&
              worksheetOriStatus !== 'CANCELLED' &&
              isPermissionCanEdit
            "
          >
            <v-sheet height="46" color="#272D36">
              <v-card-actions class="justify-center">
                <v-btn text height="30" @click="handleAfterVatAddClick()">
                  + เพิ่มรายการ
                </v-btn>
              </v-card-actions>
            </v-sheet>
          </div>
          <v-row no-gutters class="my-2">
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right">
              <span
                class="ml-7 white--text text-subtitle-2 font-weight-bold mb-2"
              >
                รายรับเพิ่มเติมหลังประกันภาษี:
                <span
                  :style="{
                    color: getTotalPriceColor(
                      worksheetForm['paymentAfterVatAmount']
                    ),
                    fontWeight: 'bold',
                  }"
                >
                  &nbsp;
                  {{
                    worksheetForm["paymentAfterVatAmount"]
                      ? getPriceString(worksheetForm["paymentAfterVatAmount"])
                      : getPriceString((0).toFixed(2))
                  }}
                  บาท
                </span>
              </span>
              <span
                class="ml-7 white--text text-subtitle-2 font-weight-bold mb-2"
              >
                รายหักเพิ่มเติมหลังประกันภาษี:
                <span
                  :style="{
                    color: getTotalPriceColor(
                      worksheetForm['reductAfterVatAmount'] * -1
                    ),
                    fontWeight: 'bold',
                  }"
                >
                  &nbsp;
                  {{
                    worksheetForm["reductAfterVatAmount"]
                      ? getPriceString(
                          worksheetForm["reductAfterVatAmount"] * -1
                        )
                      : getPriceString((0).toFixed(2))
                  }}
                  บาท
                </span>
              </span>
            </v-col>
          </v-row>
          <v-divider class="mt-6 mb-5"></v-divider>
          <div class="py-2">
            <v-row no-gutters>
              <v-col cols="6" class="pa-0 pr-6 ma-0"> </v-col>
              <v-col cols="6">
                <v-sheet color="#222831" class="mt-0 mr-3">
                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้หลังประกันภาษี</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div style="font-size: 14px">
                        {{
                          worksheetForm["sumAfterVat"]
                            ? getPriceString(
                                worksheetForm["sumAfterVat"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">
                        รายรับเพิ่มเติมหลังประกันภาษี
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div
                        :style="{
                          color: getTotalPriceColor(
                            worksheetForm['paymentAfterVatAmount']
                          ),
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["paymentAfterVatAmount"]
                            ? getPriceString(
                                worksheetForm["paymentAfterVatAmount"].toFixed(
                                  2
                                )
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">
                        รายหักเพิ่มเติมหลังประกันภาษี
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <div
                        :style="{
                          color: getTotalPriceColor(
                            worksheetForm['reductAfterVatAmount'] * -1
                          ),
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["reductAfterVatAmount"]
                            ? getPriceString(
                                (
                                  worksheetForm["reductAfterVatAmount"] * -1
                                ).toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row no-gutters class="my-2">
                    <v-col cols="6">
                      <div style="font-size: 14px">รายได้สุทธิ</div>
                    </v-col>
                    <v-col
                      v-if="worksheetForm['netAmount']"
                      cols="6"
                      class="text-right"
                    >
                      <div
                        v-if="worksheetForm['netAmount'] > 0"
                        style="font-size: 14px; font-weight: bold"
                      >
                        {{
                          worksheetForm["netAmount"]
                            ? getPriceString(
                                worksheetForm["netAmount"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>

                      <div
                        v-else
                        :style="{
                          color: getTotalPriceColor(worksheetForm['netAmount']),
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }"
                      >
                        {{
                          worksheetForm["netAmount"]
                            ? getPriceString(
                                worksheetForm["netAmount"].toFixed(2)
                              )
                            : getPriceString((0).toFixed(2))
                        }}
                      </div>
                    </v-col>
                    <v-col v-else cols="6" class="text-right">{{
                      getPriceString((0).toFixed(2))
                    }}</v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="auto">
                <v-sheet width="76"> </v-sheet>
              </v-col>
            </v-row>
          </div>

          <v-divider class="mt-6 mb-5"></v-divider>

          <div class="pt-5 mb-5">
            <p class="white--text text-app-detail-large font-weight-bold mb-2">
              สถานะและเอกสาร
            </p>

            <v-row>
              <v-col cols="12">
                <WorksheetStatusInput
                  :key="componentRefreshCounter"
                  v-model="worksheetForm.status"
                  :worksheetOriStatus="worksheetOriStatus"
                  :permission="permList"
                  :disabled="!isPermissionCanEdit"
                />
              </v-col>
            </v-row>
          </div>

          <div
            class="mb-6"
            v-if="
              worksheetOriStatus == 'PAID' && worksheetForm.status == 'PAID'
            "
          >
            <CustomDateTypingPickerFormInput
              placeholder="เลือก"
              title="จ่าย ณ วันที่ (วัน/เดือน/ปี)"
              :isDark="true"
              :disabled="worksheetOriStatus == 'PAID' && !isPermissionCanEdit"
              v-model="worksheetForm.paidDate"
              :isRequired="true"
              ref="paidDate"
              :rules="[(v) => !!v || 'จำเป็นต้องระบุวันที่หัก']"
              :expend-margin="false"
            />
          </div>
          <!-- <v-divider></v-divider> -->

          <div v-if="isPermissionCanEdit">
            <div class="white--text text-subtitle-2 font-weight-bold mb-2">
              หมายเหตุ
            </div>
            <v-textarea
              class="app-custom-input"
              outlined
              v-model="worksheetForm.note"
            ></v-textarea>
          </div>

          <FormDataUploadInput
            ref="worksheetCRUDUploadFile"
            component-name="worksheetCRUDUploadFileForm"
            title="ชุดเอกสาร (pdf)"
            placeholder="เพิ่มเอกสารกดที่นี่"
            :is-dark="true"
            :uploaded-data="worksheetForm.files"
            @waitToUploadFiles="isWaitToUploadFiles"
            uploadTag="WORKSHEET"
            :is-permission-can-edit="!isPermissionCanEdit"
          />

          <FormDataUploadInput
            ref="worksheetCRUDUploadImageFile"
            component-name="worksheetCRUDUploadImageFileForm"
            title="รูปภาพ (jpg, png)"
            placeholder="เพิ่มรูปภาพที่นี่"
            :is-dark="true"
            :isImageType="true"
            :uploaded-data="worksheetForm.images"
            @waitToUploadImages="isWaitToUploadImages"
            uploadTag="WORKSHEET"
            :is-permission-can-edit="!isPermissionCanEdit"
          />

          <v-row class="mb-3 mt-0 pt-0" v-if="isPermissionCanEdit">
            <v-col class="text-right">
              <v-btn
                color="primary"
                @click="saveChange()"
                class="font-weight-bold px-6"
              >
                บันทึก
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <!-- <v-sheet color="#323841" class="px-3 py-2">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="saveChange()"
              class="font-weight-bold px-6"
            >
              บันทึก
            </v-btn>
          </v-card-actions>
        </v-sheet> -->
      </v-card>
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
    <AppDialogConfirm ref="confirmDelete" />
    <WorksheetExtraPayment ref="worksheetExtraPaymentDialog" />
    <WorksheetExtraPayment ref="worksheetExtraPaymentAfterVatDialog" />
    <WorksheetSalary ref="worksheetSalaryDialog" />
  </v-row>
</template>

<script>
import _ from "lodash";
import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  getWorksheetInfoService,
  postDownloadPDFService,
} from "@/services/api/worksheet";
import WorksheetStatusInput from "../components/WorksheetStatusInput.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import WorksheetExtraPayment from "./WorksheetExtraPayment.vue";
import WorksheetSalary from "./WorksheetSalary.vue";
import { DialogType } from "@/services/dialog";
import { putWorksheetInfoService } from "@/services/api/worksheet";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import { processPermission } from "@/services/permissions";

export default {
  name: "worksheetSummaryDetail",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    WorksheetStatusInput,
    AppDialogConfirm,
    WorksheetExtraPayment,
    WorksheetSalary,
    AppOverlay,
    CustomDateTypingPickerFormInput,
    CustomTextInputImprove,
    FormDataUploadInput,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,
      componentRefreshCounter: 0,
      filterSelected: [],
      extraIncomePaymentTotal: 0,
      extraOutcomePaymentTotal: 0,

      extraIncomeAfterVatPaymentTotal: 0,
      extraOutcomeAfterVatPaymentTotal: 0,

      componentLoading: false,
      isWaitToUploadAttachFiles: false,
      isWaitToUploadAttachImages: false,
      financialDocURL: null,
      jobDetailURL: null,
      behaviorDetailURL: null,
      paymentListIncome: 0,
      paymentListOutcome: 0,
      currentVatPercent: 0,
      currentInsurancePercent: 0,
      currentBrokerPercent: 0,
      resturnWorksheetObj: null,
      defaultPFImage: defaultImage,
      worksheetOriStatus: "NOT_PAID",
      statusKeyList: ["NOT_PAID", "CUT", "HOLD", "PAID", "CANCELLED"],

      worksheetForm: {
        salutation: null,
        firstName: null,
        lastName: null,
        nickName: null,
        paymentName: null,
        email: null,
        empId: null,
        position: null,
        phoneNumber: null,
        code: null,
        name: null,
        createdDate: null,
        summarizedDate: null,
        salary: null,
        contractJobAmount: null,
        behaviorList: [],
        extraPayment: [],
        paymentList: [],
        netAmount: null,
        sumPaymentAmount: null,
        sumPaymentSubAmount: null,
        paymentAfterVatAmount: null,
        brokerAmount: null,
        vatAmount: null,
        sumReductAmount: null,
        paidDate: null,
        vatPercent: null,
        socialInsurancePercent: null,
        brokerPercent: null,
        files: [],
        images: [],
        status: null,
        note: null,
        id: null,
      },
      formData: {
        id: null,
        note: null,
        files: [],
        images: [],
        status: null,
        paidDate: null,
      },
      menuMoreItems: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },

      isPermissionCanEdit: false,
      isPermissionCanView: false,
    };
  },

  watch: {
    "worksheetForm.extraPayment"(newVal) {
      let extraIncomePayment = 0;
      let extraOutcomePayment = 0;
      if (!_.isNil(newVal)) {
        // this.formData["sumPriceBeforeVat"] = "0";
        if (newVal.length > 0) {
          for (let idx in newVal) {
            if (newVal[idx]["type"] == 1) {
              extraIncomePayment += newVal[idx]["amount"];
            } else {
              extraOutcomePayment -= newVal[idx]["amount"];
            }
          }
        }
        this.extraIncomePaymentTotal = extraIncomePayment.toFixed(2);
        this.extraOutcomePaymentTotal = extraOutcomePayment.toFixed(2);
      }
    },

    "worksheetForm.extraPaymentAfterVat"(newVal) {
      let extraIncomeAfterVatPayment = 0;
      let extraOutcomeAfterVatPayment = 0;
      if (!_.isNil(newVal)) {
        // this.formData["sumPriceBeforeVat"] = "0";
        if (newVal.length > 0) {
          for (let idx in newVal) {
            if (newVal[idx]["type"] == 1) {
              extraIncomeAfterVatPayment += newVal[idx]["amount"];
            } else {
              extraOutcomeAfterVatPayment -= newVal[idx]["amount"];
            }
          }
        }
        this.extraIncomeAfterVatPaymentTotal =
          extraIncomeAfterVatPayment.toFixed(2);
        this.extraOutcomeAfterVatPaymentTotal =
          extraOutcomeAfterVatPayment.toFixed(2);
      }
    },

    "worksheetForm.paymentList"(newVal) {
      let incomePayment = 0;
      let outcomePayment = 0;

      if (!_.isNil(newVal)) {
        // this.formData["sumPriceBeforeVat"] = "0";
        if (newVal.length > 0) {
          for (let idx in newVal) {
            if (newVal[idx]["amount"] >= 0) {
              incomePayment += newVal[idx]["amount"];
            } else {
              outcomePayment += newVal[idx]["amount"];
            }
          }
        }
        this.paymentListIncome = incomePayment.toFixed(2);
        this.paymentListOutcome = outcomePayment.toFixed(2);
      }
    },
  },

  computed: {
    // paymentListTotal() {
    //   let nPrice = 0;
    //   if (this.worksheetForm["paymentList"].length > 0) {
    //     for (let idx in this.worksheetForm["paymentList"]) {
    //       nPrice += this.worksheetForm["paymentList"][idx]["amount"];
    //     }
    //   }
    //   return nPrice.toFixed(2);
    // },
    // paymentListIncome() {
    //   let nPrice = 0;
    //   if (this.worksheetForm["paymentList"].length > 0) {
    //     for (let idx in this.worksheetForm["paymentList"]) {
    //       nPrice += this.worksheetForm["paymentList"][idx]["amount"];
    //     }
    //   }
    //   return nPrice.toFixed(2);
    // },
  },

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.worksheetForm = {
        salutation: null,
        firstName: null,
        lastName: null,
        nickName: null,
        paymentName: null,
        email: null,
        empId: null,
        position: null,
        phoneNumber: null,
        code: null,
        name: null,
        createdDate: null,
        summarizedDate: null,
        contractJobAmount: null,
        behaviorList: [],
        extraPayment: [],
        paymentList: [],
        files: [],
        images: [],
      };
      this.paymentListIncome = 0;
      this.paymentListOutcome = 0;
      this.extraIncomePaymentTotal = 0;
      this.extraOutcomePaymentTotal = 0;
      this.extraIncomeAfterVatPaymentTotal = 0;
      this.extraOutcomeAfterVatPaymentTotal = 0;
      this.currentVatPercent = 0;
      this.currentInsurancePercent = 0;
      this.currentBrokerPercent = 0;
      this.id = null;
      this.isNotEdited = true;
      this.resturnWorksheetObj = null;
    },

    open(_id) {
      this.resetFormData();
      this.id = _id;
      this.getData();
      this.checkUserPermissions();
      // if (!_.isNil(id)) {
      //   this.setFormData(id);
      // }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getData() {
      this.componentLoading = true;
      if (!_.isNil(this.id)) {
        let respWorksheetInfo = await getWorksheetInfoService(
          this.$store,
          this.id
        );
        if (respWorksheetInfo["code"] === 200) {
          this.worksheetForm = _.clone(respWorksheetInfo["data"]);
          if (!_.isNil(this.worksheetForm)) {
            this.worksheetForm[
              "name"
            ] = `${this.worksheetForm["firstName"]["th"]} ${this.worksheetForm["lastName"]["th"]}`;

            if (!_.isNil(this.worksheetForm["extraPayment"])) {
              if (this.worksheetForm["extraPayment"].length > 0) {
                let extraInit = _.cloneDeep(this.worksheetForm["extraPayment"]);
                for (let idx in this.worksheetForm["extraPayment"]) {
                  this.worksheetForm["extraPayment"][idx]["date"] =
                    extraInit[idx]["date"].split("T")[0];
                }
              }
            }

            if (!_.isNil(this.worksheetForm["extraPaymentAfterVat"])) {
              if (this.worksheetForm["extraPaymentAfterVat"].length > 0) {
                let extraAfterVatInit = _.cloneDeep(
                  this.worksheetForm["extraPaymentAfterVat"]
                );
                for (let idx in this.worksheetForm["extraPaymentAfterVat"]) {
                  this.worksheetForm["extraPaymentAfterVat"][idx]["date"] =
                    extraAfterVatInit[idx]["date"].split("T")[0];
                }
              }
            }

            if (!_.isNil(this.worksheetForm["paidDate"])) {
              this.worksheetForm["paidDate"] = _.clone(
                this.worksheetForm["paidDate"]
              ).split("T")[0];
            }

            if (!_.isNil(this.worksheetForm["vatPercent"])) {
              this.currentVatPercent = _.clone(
                this.worksheetForm["vatPercent"]
              );
            }
            if (!_.isNil(this.worksheetForm["socialInsurancePercent"])) {
              this.currentInsurancePercent = _.clone(
                this.worksheetForm["socialInsurancePercent"]
              );
            }
            if (!_.isNil(this.worksheetForm["brokerPercent"])) {
              this.currentBrokerPercent = _.clone(
                this.worksheetForm["brokerPercent"]
              );
            }

            if (!_.isNil(this.worksheetForm["status"])) {
              this.worksheetOriStatus = _.clone(this.worksheetForm["status"]);
            }

            let sumPaymentAmountTotal = 0;
            let sumPayment = 0;
            let sumPaymentSub = 0;
            if (!_.isNil(this.worksheetForm["sumPaymentAmount"])) {
              sumPayment = _.clone(this.worksheetForm["sumPaymentAmount"]);
            }

            if (!_.isNil(this.worksheetForm["sumPaymentSubAmount"])) {
              sumPaymentSub = _.clone(
                this.worksheetForm["sumPaymentSubAmount"]
              );
            }
            sumPaymentAmountTotal = sumPayment + sumPaymentSub;
            this.worksheetForm["sumPaymentAmount"] = _.clone(
              sumPaymentAmountTotal
            );
          }
        }
        // console.log("this.worksheetForm", this.worksheetForm);
      }
      this.componentLoading = false;
    },
    async handleDownloadPDF(id) {
      this.componentLoading = true;
      if (!_.isNil(id)) {
        let queryString = { id: id };
        let respData = await postDownloadPDFService(this.$store, queryString);
        //
        if (respData["code"] === 200) {
          this.financialDocURL = respData["data"]["pdfURL"];
          window.open(this.financialDocURL, "_blank");
          // await this.getData();
        } else {
          // insert fail
        }
      }
      this.componentLoading = false;
    },

    async handleAddClick() {
      const newExtra = await this.$refs.worksheetExtraPaymentDialog.open(null);
      this.componentLoading = true;
      if (!_.isNil(newExtra)) {
        this.worksheetForm["extraPayment"].push(newExtra);
        this.updateExtraPayment();
      } else {
        this.componentLoading = false;
      }
    },

    async handleEditClick(payment, index) {
      const extra = await this.$refs.worksheetExtraPaymentDialog.open(payment);
      this.componentLoading = true;
      if (!_.isNil(extra)) {
        this.worksheetForm["extraPayment"][index] = _.clone(extra);
        this.updateExtraPayment();
      } else {
        this.componentLoading = false;
      }
    },

    async handleDeleteClick(index) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ERROR,
          {
            noconfirm: true,
          }
        )
      ) {
        let extraPaymentList = _.cloneDeep(this.worksheetForm["extraPayment"]);
        extraPaymentList.splice(index, 1);
        this.worksheetForm["extraPayment"] = _.cloneDeep(extraPaymentList);
        this.componentLoading = true;
        if (!_.isNil(extraPaymentList)) {
          this.updateExtraPayment();
        } else {
          this.componentLoading = false;
        }
      }
    },

    async handleAfterVatAddClick() {
      const newExtra =
        await this.$refs.worksheetExtraPaymentAfterVatDialog.open(null);
      this.componentLoading = true;
      if (!_.isNil(newExtra)) {
        // let _newExtra = {
        //   amount: newExtra["amount"],
        //   date: newExtra["date"],
        //   name: newExtra["name"],
        //   type: newExtra["type"],
        // };
        this.worksheetForm["extraPaymentAfterVat"].push(newExtra);
        this.updateExtraPaymentAfterVat();
      } else {
        this.componentLoading = false;
      }
      // this.componentLoading = false;
    },

    async handleAfterVatEditClick(payment, index) {
      let _payment = null;
      if (!_.isNil(payment)) {
        _payment = _.clone(payment);
        _payment["date"] = payment["date"];
      }
      const extraAfterVat =
        await this.$refs.worksheetExtraPaymentAfterVatDialog.open(_payment);
      this.componentLoading = true;
      if (!_.isNil(extraAfterVat)) {
        // let _extraAfterVat = {
        //   amount: extraAfterVat["amount"],
        //   date: extraAfterVat["date"],
        //   name: extraAfterVat["name"],
        //   type: extraAfterVat["type"],
        // };

        this.worksheetForm["extraPaymentAfterVat"][index] =
          _.clone(extraAfterVat);
        this.updateExtraPaymentAfterVat();
      } else {
        this.componentLoading = false;
      }
    },

    async handleAfterVatDeleteClick(index) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ERROR,
          {
            noconfirm: true,
          }
        )
      ) {
        let extraPaymentAfterVatList = _.cloneDeep(
          this.worksheetForm["extraPaymentAfterVat"]
        );
        extraPaymentAfterVatList.splice(index, 1);
        this.worksheetForm["extraPaymentAfterVat"] = _.cloneDeep(
          extraPaymentAfterVatList
        );
        this.componentLoading = true;
        if (!_.isNil(extraPaymentAfterVatList)) {
          this.updateExtraPaymentAfterVat();
        } else {
          this.componentLoading = false;
        }
      }
    },

    async handleEditSalaryClick() {
      let salaryForm = { salary: this.worksheetForm["salary"] };
      const newSalary = await this.$refs.worksheetSalaryDialog.open(salaryForm);
      this.componentLoading = true;
      if (!_.isNil(newSalary)) {
        this.worksheetForm["salary"] = _.clone(newSalary["salary"]);
        this.updateSalary();
      } else {
        this.componentLoading = false;
      }
    },

    async handleJobOnClick(id) {
      this.componentLoading = true;
      if (!_.isNil(id)) {
        this.jobDetailURL = "/caj/jobs/edit/" + id;
        window.open(this.jobDetailURL, "_blank");
      }
      this.componentLoading = false;
    },

    async handleBehaviorOnClick(id) {
      this.componentLoading = true;
      if (!_.isNil(id)) {
        this.behaviorDetailURL =
          "/employee/timeoff/list?limit=20&by=createddate&order=desc&page=1&timeoffId=" +
          id;
        window.open(this.behaviorDetailURL, "_blank");
      }
      this.componentLoading = false;
    },

    async updateSalary() {
      this.componentLoading = true;
      const sendingFormData = {
        id: this.worksheetForm["_id"],
        salary: this.worksheetForm["salary"],
      };
      if (!_.isNil(sendingFormData["salary"])) {
        let respData = await putWorksheetInfoService(
          this.$store,
          sendingFormData
        );
        //
        if (respData["code"] === 200) {
          this.resturnWorksheetObj = _.cloneDeep(respData);
          await this.getData();
        } else {
          // insert fail
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    async updateExtraPayment() {
      this.componentLoading = true;
      const sendingFormData = {
        id: this.worksheetForm["_id"],
        extraPayment: this.worksheetForm["extraPayment"],
      };
      if (!_.isNil(sendingFormData["extraPayment"])) {
        let respData = await putWorksheetInfoService(
          this.$store,
          sendingFormData
        );
        //
        if (respData["code"] === 200) {
          this.resturnWorksheetObj = _.cloneDeep(respData);
          await this.getData();
        } else {
          // insert fail
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    async updateExtraPaymentAfterVat() {
      this.componentLoading = true;
      const sendingFormData = {
        id: this.worksheetForm["_id"],
        extraPaymentAfterVat: this.worksheetForm["extraPaymentAfterVat"],
      };
      if (!_.isNil(sendingFormData["extraPaymentAfterVat"])) {
        let respData = await putWorksheetInfoService(
          this.$store,
          sendingFormData
        );
        //
        if (respData["code"] === 200) {
          this.resturnWorksheetObj = _.cloneDeep(respData);
          await this.getData();
        } else {
          // insert fail
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    async updateVatPercent() {
      this.componentLoading = true;
      if (!_.isNil(this.worksheetForm["vatPercent"])) {
        if (this.worksheetForm["vatPercent"].length > 0) {
          const sendingFormData = {
            id: this.worksheetForm["_id"],
            vatPercent: parseFloat(this.worksheetForm["vatPercent"]),
          };
          let respData = await putWorksheetInfoService(
            this.$store,
            sendingFormData
          );
          //
          if (respData["code"] === 200) {
            this.resturnWorksheetObj = _.cloneDeep(respData);
            await this.getData();
          } else {
            // insert fail
            this.componentLoading = false;
          }
        } else {
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    async updateSocialInsurancePercent() {
      this.componentLoading = true;
      if (!_.isNil(this.worksheetForm["socialInsurancePercent"])) {
        if (this.worksheetForm["socialInsurancePercent"].length > 0) {
          const sendingFormData = {
            id: this.worksheetForm["_id"],
            socialInsurancePercent: parseFloat(
              this.worksheetForm["socialInsurancePercent"]
            ),
          };
          let respData = await putWorksheetInfoService(
            this.$store,
            sendingFormData
          );
          //
          if (respData["code"] === 200) {
            this.resturnWorksheetObj = _.cloneDeep(respData);
            await this.getData();
          } else {
            // insert fail
            this.componentLoading = false;
          }
        } else {
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    async updatebrokerPercent() {
      this.componentLoading = true;
      if (!_.isNil(this.worksheetForm["brokerPercent"])) {
        if (this.worksheetForm["brokerPercent"].length > 0) {
          const sendingFormData = {
            id: this.worksheetForm["_id"],
            brokerPercent: parseFloat(this.worksheetForm["brokerPercent"]),
          };
          let respData = await putWorksheetInfoService(
            this.$store,
            sendingFormData
          );
          //
          if (respData["code"] === 200) {
            this.resturnWorksheetObj = _.cloneDeep(respData);
            await this.getData();
          } else {
            // insert fail
            this.componentLoading = false;
          }
        } else {
          this.componentLoading = false;
        }
      } else {
        this.componentLoading = false;
      }
    },

    isWaitToUploadFiles(isWait) {
      this.isWaitToUploadAttachFiles = isWait;
    },

    isWaitToUploadImages(isWait) {
      this.isWaitToUploadAttachImages = isWait;
    },

    async saveChange() {
      this.componentLoading = true;
      // if (this.$refs.form.validate() === true) {
      let sendingFormData = null;
      let resUploadFile = await this.$refs["worksheetCRUDUploadFile"].upload();
      let resUploadImage = await this.$refs[
        "worksheetCRUDUploadImageFile"
      ].upload();

      if (!resUploadFile.status || !resUploadImage.status) {
        // is upload has error response restore all file
        this.callUploadFileErrorDialog();
        this.$refs["worksheetCRUDUploadFile"].restoreFile();
        this.$refs["worksheetCRUDUploadImageFile"].restoreFile();
        // this.disableLoading();
      } else {
        sendingFormData = _.cloneDeep(this.formData);
        sendingFormData["id"] = this.worksheetForm["_id"];
        sendingFormData["note"] = this.worksheetForm["note"];
        sendingFormData["files"] = resUploadFile.result;
        sendingFormData["images"] = resUploadImage.result;
        sendingFormData["status"] = this.worksheetForm["status"];
        if (this.worksheetForm["status"] === "PAID") {
          sendingFormData["paidDate"] = this.worksheetForm["paidDate"];
        } else {
          this.worksheetForm["paidDate"] = null;
          sendingFormData["paidDate"] = null;
        }
      }

      // console.log("sendingFormData", sendingFormData);

      let respData = await putWorksheetInfoService(
        this.$store,
        sendingFormData
      );
      this.componentLoading = false;
      this.show = false;
      if (respData["code"] === 200) {
        this.resolve(respData);
      } else {
        this.resolve(null);
      }
      // this.$refs.form.reset();
      // this.resetFormData();
      // }
    },

    cancel() {
      // this.$refs.form.reset();
      this.$refs["worksheetCRUDUploadFile"].clear();
      this.$refs["worksheetCRUDUploadImageFile"].clear();
      if (!_.isNil(this.resturnWorksheetObj)) {
        this.resolve(this.resturnWorksheetObj);
      } else {
        this.resolve(null);
      }
      this.show = false;
      // this.resetFormData();
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    async alertSaveChange() {
      await this.$refs.alertSaving.open(
        "แก้ไขข้อมูลสำเร็จ!",
        null,
        DialogType.POSITIVE,
        this.alertOptions
      );
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getPriceColor(number) {
      return number < 0 ? "#FF6745" : "#FFFFFF";
    },
    getExtraPriceColor(type) {
      return type == 0 ? "#FF6745" : "#FFFFFF";
    },

    getTotalPriceColor(number) {
      return number < 0 ? "#FF6745" : "#FFFFFF";
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        // console.log("permission:", this.permList)
        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
        //
      }
    },
  },
};
</script>

<style scoped>
.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.clickable-row {
  cursor: pointer;
}

.table_header_text_style {
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
