import AssignMultipleJobListViewVue from "@/views/assign_multiple_job/AssignMultipleJobListView.vue";
import AssignMutipleJobCreateViewVue from "@/views/assign_multiple_job/AssignMutipleJobCreateView.vue";

export default [
  {
    path: "/caj/multiple-job/list",
    name: "multipleJobList",
    component: AssignMultipleJobListViewVue,
    meta: { requiresAuth: true, topic: "multiple-job" },
  },
  {
    path: "/caj/multiple-job/create",
    name: "multipleJobCreate",
    component: AssignMutipleJobCreateViewVue,
    meta: { requiresAuth: true, topic: "multiple-job" },
  },
];
