<template>
  <div name="duplicateJobTimeDateFormDetailSection">
    <v-row dense>
      <v-col cols="4">
        <CustomTimePickerInput
          title="เวลายืนยันรับงาน"
          :value="formData.confirmDate"
          :is-required="true"
          :small="true"
          :input-view-bottom-margin="false"
          :expend-margin="false"
          :hide-detail="true"
          @input="handlerInputConfirmDateChange"
          :return-empty-value="true"
        />
      </v-col>
      <v-col cols="4">
        <CustomTimePickerInput
          title="เวลาเริ่มงาน"
          :value="formData.startDate"
          :is-required="true"
          :small="true"
          :input-view-bottom-margin="false"
          :expend-margin="false"
          :hide-detail="true"
          @input="handlerInputStartDateChange"
          :return-empty-value="true"
        />
      </v-col>
      <v-col cols="4">
        <CustomTimePickerInput
          title="เวลาจบงาน"
          :value="formData.endDate"
          :is-required="false"
          :small="true"
          :input-view-bottom-margin="false"
          :expend-margin="false"
          :hide-detail="true"
          @input="handlerInputEndDateChange"
          :return-empty-value="true"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <p class="title-input-small">
          เวลาจบงานโดยประมาณ:
          {{ estimateEndDate ? timeStringFormat(estimateEndDate, true) : "-" }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput.vue";
import CustomDateTimeTypingPickerFormInput from "@/components/CustomDateTimeTypingPickerFormInput.vue";
import moment from "moment";
import { dateStringToDateFormat, timeStringFormat } from "@/services/appDate";
import CustomTimePickerInput from "@/components/CustomTimePickerInput.vue";
export default {
  name: "DuplicateJobTimeDateFormDetailSection",
  components: {
    CustomDateTimePickerFormInput,
    CustomDateTimeTypingPickerFormInput,
    CustomTimePickerInput,
  },
  data() {
    return {
      refreshCounter: 0,
      formData: {
        startDate: null,
        endDate: null,
        confirmDate: null,
        estimateEndDate: null,
      },
    };
  },
  computed: {
    estimateEndDate: function () {
      return this.$store.state.jobs.jobData.estimateEndDate || null;
    },
  },
  mounted() {
    this.formData = _.pick(this.$store.state.jobs.jobData, [
      "startDate",
      "endDate",
      "confirmDate",
      "estimateEndDate",
    ]);
  },
  watch: {
    // formData: {
    //   deep: true,
    //   immediate: false,
    //   handler(newValue) {
    //     this.processDataToUpdate(newValue);
    //   },
    // },
  },
  methods: {
    timeStringFormat: timeStringFormat,
    dateStringToDateFormat: dateStringToDateFormat,
    handlerInputConfirmDateChange(newValue) {
      this.processDataToUpdate(newValue, "confirmDate");
    },
    handlerInputStartDateChange(newValue) {
      this.processDataToUpdate(newValue, "startDate");
    },
    handlerInputEndDateChange(newValue) {
      this.processDataToUpdate(newValue, "endDate");
    },
    processDataToUpdate(newValue, assignAtValue) {
      // calculate new estimate value
      try {
        const getHourAndMinute = function (m) {
          return m.minutes() + m.hours() * 60;
        };
        // this.formData[assignAtValue] = newValue;
        // let _date = { ...this.formData };

        if (assignAtValue === "confirmDate") {
          this.formData[assignAtValue] = newValue;
          if (!_.isNil(this.formData["startDate"])) {
            if (!_.isNil(this.formData["startDate"])) {
              const confirmDate = moment(this.formData["confirmDate"]);
              let startDate = moment(this.formData["startDate"]);
              if (getHourAndMinute(confirmDate) > getHourAndMinute(startDate)) {
                this.formData["startDate"] = null;
                this.formData["estimateEndDate"] = null;
              } else {
                // not do thing
              }
            }
          } else {
            // not do thing
          }
        } else if (assignAtValue === "startDate") {
          this.formData[assignAtValue] = newValue;
          if (_.isNil(this.formData[assignAtValue])) {
            this.formData["estimateEndDate"] = null;
          } else {
            // check startDate > endDate
            let startDate = moment(this.formData["startDate"]);
            if (!_.isNil(this.formData["endDate"])) {
              const _endDateMoment = moment(this.formData["endDate"]);
              if (startDate.valueOf() > _endDateMoment.valueOf()) {
                this.formData["endDate"] = null;
              }
            }

            // check estimate enddate
            startDate.add(this.$store.state.jobs.jobData.totalDuration, "s");
            const newEstimateEnddate = startDate.format("YYYY-MM-DDTHH:mm:ss");
            if (newEstimateEnddate !== this.formData["estimateEndDate"]) {
              this.formData["estimateEndDate"] = newEstimateEnddate;
            } else {
              // not do thing
            }

            // check more than end
          }
        } else if (assignAtValue === "endDate") {
          // end date
          this.formData[assignAtValue] = newValue;
          if (!_.isNil(this.formData[assignAtValue])) {
            const startDate = moment(this.formData["startDate"]);
            const endDate = moment(this.formData["endDate"]);
            if (getHourAndMinute(startDate) > getHourAndMinute(endDate)) {
              this.formData["startDate"] = null;
              this.formData["estimateEndDate"] = null;
            } else {
              // not do thing
            }
          }
        }

        this.$store.dispatch("jobs/updateJobData", this.formData);
        this.$store.dispatch("jobs/updateRefreshFormDuplicateCounter");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped></style>
