<template>
  <div name="RouteFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

      <div class="pa-6">
        <v-row dense>
          <v-col cols="12">
            <InputView title="รหัสสัญญา" :isRequired="false">
              <v-select
                :items="filterContractTitleList"
                v-model="formData.contractNameValue"
                placeholder="เลือก"
                item-value="key"
                item-text="value"
                outlined
                dense
                filled
                :loading="isLoading"
              ></v-select>
            </InputView>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { getContractTitleListService } from "@/services/api/contract";

export default {
  name: "EmployeeFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    InputView,
    CustomTextInputImprove,
  },

  mounted() {
    this.getParamQuery();
    this.getData();
  },

  data() {
    return {
      queryOrder: {
        contract: "contractNameValue",
      },
      formData: {
        contractNameValue: null,
      },
      copyFormData: null,
      // from api
      isLoading: false,
      filterContractTitleList: [],
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      this.isLoading = true;
      // call api
      try {
        let responseData = await getContractTitleListService(this.$store);
        if (responseData["code"] === 200) {
          this.filterContractTitleList = responseData["data"];
        }
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      this.copyFormData = _.cloneDeep(this.formData);
      this.formData = {
        contractNameValue: null,
      };
    },
  },
  computed: {},
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
