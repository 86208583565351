import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getVehicleFuelPaymentService(store, queryString) {
  let uri = "/vehicle-fuel";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putVehicleFuelPaymentUpdateStatusService(
  store,
  queryString,
  data
) {
  let uri = "/vehicle-fuel/fuel-status";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function getVehicleFuelPaymentInfoService(store, queryString) {
  let uri = "/vehicle-fuel/info";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
