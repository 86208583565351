<template>
  <div name="normalButton">
    <v-btn
      @click="onClick()"
      :color="borderColor"
      elevation="0"
      light
      dark
      large
      outlined
      :height="height"
    >
      <v-icon left v-if="icon"> {{ icon }} </v-icon>
      <div class="white--text">
        <slot></slot>
      </div>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "normalButton",
  props: {
    text: String,
    icon: String,
    height: Number,
  },

  data() {
    return {
      backgroundColor: this.$vuetify.theme.themes.light.backgroundNormalButton,
      textColor: this.$vuetify.theme.themes.light.bodyText,
      borderColor: this.$vuetify.theme.themes.light.borderColor,
    };
  },

  methods: {
    onClick() {
      this.$emit("on-click");
    },
  },
};
</script>
<style>
.normal-button-text {
  color: #4d5656 !important;
  font-weight: normal !important;
}
</style>
