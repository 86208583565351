<template>
  <div name="login">
    <v-container fluid pa-0>
      <v-row align-content="center" justify="center" dense style="height: 80vh">
        <v-col cols="12" align-content="center" justify="center">
          <v-card
            class="mx-auto pa-10"
            max-width="374"
            elevation="0"
            rounded="xl"
          >
            <div class="mb-10">
              <div class="text-login">เข้าสู่ระบบ</div>
              <div class="red-alert" v-if="showDev">!!!Dev Version!!!</div>
              <div class="red-alert" v-if="showTest">!!!Test Version!!!</div>
              <div class="red-alert" v-if="showDemo">!!!Demo Version!!!</div>
            </div>

            <v-text-field
              class="custom-placeholer-color .v-label"
              placeholder="ชื่อผู้ใช้ (อีเมล์ หรือ เบอร์โทรศัพท์)"
              v-model="email"
              outlined
              rounded
              dense
              :disabled="loading"
              v-on:keyup.enter="onEnter"
            ></v-text-field>

            <v-text-field
              class="custom-placeholer-color .v-label"
              placeholder="รหัสผ่าน"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              v-model="password"
              @click:append="show = !show"
              outlined
              rounded
              dense
              :disabled="loading"
              v-on:keyup.enter="onEnter"
            ></v-text-field>

            <v-btn
              class="mt-2"
              color="primary"
              block
              large
              :loading="loading"
              :disabled="loading"
              @click="submit()"
            >
              <div class="font-weight-bold">เข้าสู่ระบบ</div>
              <template v-slot:loader>
                <span>กำลังเข้าสู่ระบบ...</span>
              </template>
            </v-btn>
            <div class="pt-5">
              <a href="/forgot-password/" class="text-decoration-none"
                >ลืมรหัสผ่าน</a
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { userLogin } from "@/services/api/user";

export default {
  name: "LoginView",

  data() {
    return {
      valid: false,
      show: false,
      email: "",
      password: "",
      loading: false,
      errorIcon: "mdi-alert-circle",
      showDev: false,
      showTest: false,
      showDemo: false,
    };
  },

  mounted() {
    this.initPage();
  },

  watch: {},

  methods: {
    initPage() {
      if (process.env.VUE_APP_DEV == "true") {
        this.showDev = true;
      }
      if (process.env.VUE_APP_TEST == "true") {
        this.showTest = true;
      }
      if (process.env.VUE_APP_DEMO == "true") {
        this.showDemo = true;
      }

      if (
        this.$store.state.accessToken &&
        this.$store.state.refreshToken &&
        this.$store.state.userInfo
      ) {
        this.$router.push("/");
      }
    },
    // selectRememberMe(event) {
    //   console.log("selectRememberMe");
    // },

    // goToForgotPassword() {
    //   this.$router.push({ name: "forgotPassword" });
    // },
    onEnter() {
      this.submit();
    },

    async submit() {
      if (!this.email && !this.password) {
        this.$store.commit(
          "setErrorMessage",
          "LOGIN_EMAIL_AND_PASSWORD_NOT_FOUND"
        );
        this.$store.commit("setShowErrorDialog", true);
      } else if (!this.email) {
        this.$store.commit("setErrorMessage", "LOGIN_EMAIL_REQUIRED");
        this.$store.commit("setShowErrorDialog", true);
      } else if (!this.password) {
        this.$store.commit("setErrorMessage", "LOGIN_PASSWORD_REQUIRED");
        this.$store.commit("setShowErrorDialog", true);
      } else {
        this.loading = true;
        let loginResult = await userLogin(
          this.$store,
          this.email,
          this.password
        );

        this.loading = false;

        if (loginResult.code == 200) {
          this.$router.push("/");
        } else {
          // login fail
        }
      }
    },
  },
};
</script>
<style scoped>
.text-login {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(103, 107, 111);
}

.red-alert {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 0, 0);
}

.text-forgot-password {
  font-size: 14px;
  font-weight: normal;
  color: rgb(73, 134, 233);
}
</style>
