<template>
  <div name="customSelects" :class="expendMargin ? 'pl-3' : ''">
    <InputView :title="title" :isRequired="isRequired">
      <v-menu
        v-model="open"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            slot="activator"
            prepend-icon="mdi-clock-time-four-outline"
            v-model="valueInput"
            :placeholder="placeholder"
            v-on="on"
            dense
            outlined
            :rules="rules"
            :disabled="disabled"
            :readonly="true"
            clearable
            @click:clear="valueInput = null"
            :background-color="getBackgrounddColor()"
          ></v-text-field>
        </template>
        <v-time-picker
          format="24hr"
          v-model="valueInput"
          @input="open = false"
        ></v-time-picker>
      </v-menu>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "CustomTimePickerFormInput",

  components: {
    InputView,
  },

  data: () => ({
    dateMin: null,
    open: false,
  }),

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },

  mounted() {},
};
</script>
<style></style>
