<template>
  <v-card elevation="2" class="rounded-lg">
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-row no-gutters>
            <v-col cols="4">
              <div class="d-flex justify-space-between">
                <div>
                  <p
                    class="mb-1 text-app-detail-md font-weight-bold black--text"
                  >
                    {{ vehicle.licenseNumber }}
                    <span> ({{ vehicleTypeText(vehicle.type) }}) </span>
                  </p>
                  <span class="text-app-detail-md pr-6">
                    <span class="mx-0 px-0">
                      <v-icon v-if="vehicle.licenseType === 'WHITE'">
                        mdi-card-text-outline
                      </v-icon>
                      <v-icon
                        v-else
                        :color="vehicleLicenseTypeColor(vehicle.licenseType)"
                        >mdi-card-text</v-icon
                      >
                    </span>
                    {{ vehicleLicenseTypeText(vehicle.licenseType) }}
                  </span>
                  <span class="text-app-detail-md">
                    <span class="mx-0 px-0">
                      <v-icon>
                        {{ vehicleTypeIcon(vehicle.type) }}
                      </v-icon>
                    </span>
                    {{ vehicle.seat }}
                    ที่นั่ง
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <p class="text-app-detail-normal font-weight-light my-0">
                สถานะรถ
              </p>
              <p class="text-app-detail-md font-weight-bold my-0">
                <v-chip
                  fab
                  small
                  class="text-app-title"
                  :color="vehicleStatusColor(vehicle.status)"
                  dark
                >
                  {{ vehicleStatusText(vehicle.status) }}
                </v-chip>
              </p>
            </v-col>
            <v-col cols="3">
              <p class="text-app-detail-normal font-weight-light my-0">
                ข้อมูลรถ
              </p>
              <p class="text-app-detail-md font-weight-bold my-0">
                {{ vehicle.brMoYe }}
              </p>
            </v-col>
            <v-col cols="3">
              <p class="text-app-detail-normal font-weight-light my-0">
                ถูกใช้งานล่าสุด
              </p>
              <p class="text-app-detail-md font-weight-bold my-0">
                {{
                  dateStringToDateFormat(vehicle.updatedAt, {
                    returnThai: false,
                    type: "datetime",
                  }) || "-"
                }}
              </p></v-col
            >
          </v-row>
        </v-col>
        <v-divider vertical v-if="driver.empId" />
        <v-col cols="4" class="px-6" v-if="driver.empId">
          <v-row no-gutters>
            <v-list-item-avatar size="60" class="mr-6">
              <v-img :src="driver.profileImage || defaultPFImage"> </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <v-list-item-title
                  class="text-app-detail-md black--text font-weight-bold"
                >
                  {{ driver.dpNameTh }} ({{ driver.dpNickNameTh }})
                </v-list-item-title>
                <span class="text-app-normal">
                  {{ driver.empId }}
                </span>
                <span class="text-app-normal" v-if="driver.position">|</span>
                <span class="text-app-normal">
                  {{ driver.position }}
                </span>
              </div>
              <span class="text-app-normal">อีเมล: {{ driver.email }} </span>
              <div>
                <span class="text-app-normal"> โทร:</span>
                <span
                  class="text-app-normal"
                  v-for="(number, idx) in driver.phoneNumber"
                  :key="idx"
                >
                  {{ number }}
                  <span v-if="idx < driver.phoneNumber.length - 1">,</span>
                </span>
              </div>
            </v-list-item-content>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import { dateStringToDateFormat } from "@/services/appDate";
export default {
  name: "VehicleInformationCard",
  props: {
    data: {},
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusTextInvert: "vehicle/vehicleStatusTextInvert",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  data() {
    return {
      defaultPFImage: defaultImage,
      vehicle: {
        status: null,
        type: null,
        licenseNumber: null,
        licenseType: null,
        seat: null,
        brMoYe: null,
        updatedAt: null,
      },
      driver: {
        profileImage: null,
        empId: null,
        position: null,
        email: null,
        phoneNumber: [],
        dpNickNameTh: null,
        dpNickNameEn: null,
      },
    };
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    processData() {
      if (!_.isNil(this.data)) {
        this.vehicle = {
          ...this.vehicle,
          ..._.get(this.data, "vehicle", {}),
        };

        this.driver = {
          ...this.driver,
          ..._.get(this.data, "ownedVehicle", {}),
        };
      }
    },
  },
  mounted() {
    this.processData();
  },
};
</script>
