<template>
	<div>
		<div>
			<v-dialog v-model="show" max-width="300">
				<v-card>
					<v-card-title>
						ดาวน์โหลดวีดีโอย้อนหลัง
					</v-card-title>
					<v-card-text class="pt-4">
						<v-row>
							<CustomDateTypingPickerFormInput
							placeholder="เลือก"
							title="วันที่"
							v-model="date"
							:disabled="loading"
							:is-required="false">
							</CustomDateTypingPickerFormInput>
						</v-row>
						<v-row>
						<CustomTimePickerInput
							title="เวลา"
							v-model="time"
							:disabled="loading"
							:is-required="false">
						</CustomTimePickerInput>
					</v-row>
						<v-row>
							<div class="pl-3"><b>ชนิดกล้อง</b></div>
							<v-radio-group 
								class="pl-4"
								v-model="cameraType"
      							row
							>
							<v-radio label="กล้องหน้า" value="F"></v-radio>
							<v-radio label="กล้องหลัง" value="I"></v-radio>
							</v-radio-group>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						
						<PrimaryButton
							:large="true"
							icon="mdi-download"
							:disabled="loading"
							:loading="loading"
							@on-click="handleDownloadVideo()"
						>
							ดาวน์โหลด
						</PrimaryButton>

					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>
<script>
	import moment from "moment";
	import { requestGPSVideo, triggerGPSVideo } from "@/services/api/box";

	export default {
		name: "downloadVideoDialog",
		beforeCreate: function () {
			this.$options.components.PrimaryButton =
				require("@/components/PrimaryButton.vue").default;
			this.$options.components.CustomTimePickerInput =
				require("@/components/CustomTimePickerInput.vue").default;
			this.$options.components.CustomDateTypingPickerFormInput =
				require("@/components/CustomDateTypingPickerFormInput.vue").default;
		},
		props: {
		},

		data: () => ({
			show: false,
			serialNumber: null,
			date: null,
			time: null,
			cameraType: "F",
			loading: false,
		}),

		mounted() {
		},

		watch: {},
		methods: {
			open(serialNumber) {
				this.show = true;
				this.loading = false;
				this.serialNumber = serialNumber;
				this.date = moment().format("YYYY-MM-DD");
				this.time = moment().format("YYYY-MM-DDTHH:mm:ss");
			},
			
			async handleDownloadVideo() {
				this.loading = true;
				const strDateTime = this.date + " " + this.time.split("T")[1];
				const timeStamp = moment(strDateTime, "YYYY-MM-DD HH:mm:ss").valueOf();
				const result = await requestGPSVideo(this.$store, this.serialNumber, timeStamp, this.cameraType, false);

				if (result.code == 200){
					let requestId = result['data']['requestId'];
					console.log(requestId);
					while(true) {
						let result2 = await triggerGPSVideo(this.$store, requestId);
						console.log(result2);
						if (result2.code == 200) {
							const fileLinkList = result2['data']['fileLinkList'];
							if(fileLinkList != null){
								for(const item of fileLinkList){
									window.open(item, "_blank");
								}

								if (fileLinkList.length == 0){
        							this.$store.commit("setErrorMessage", "ไม่พบวีดีโอในวันเวลาที่เลือก");
        							this.$store.commit("setShowErrorDialog", true);
								}

								this.loading = false;
								break;
							}
						} else {
							this.loading = false;
							break;
						}
						await new Promise(resolve => setTimeout(resolve, 1000));
					}
				}
			},
		},
		components: {},
	};
</script>

