import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { POINT_MARKER_ICON_CONFIG } from "@/services/map/mapSettings";

Vue.use(Vuex);

export const routeSetting = {
  namespaced: true,
  state: {
    isEdit: 0,
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    forceGetData: 0,
    isOk: false,
    mainLoading: false,
    mainGMapActive: false,
    createGMapActive: false,

    // job data
    templateData: null,
    markerListDuration: [],

    isActivateInput: false,
    isActivateForm: false,
    reloadMarker: 0,
    boundLatLngCenterList: [],
    // for marker
    markerList: [],
    currentFocusMarkerId: "",
    // for line
    polylineList: [],
    boundPloyLine: null,
  },
  getters: {
    getAllMarker(state) {
      return state.markerList;
    },
    getTemplateData(state) {
      return state.templateData;
    },
  },
  mutations: {
    // main loading
    setMainLoading(state, payload) {
      state.mainLoading = payload;
    },
    setIsPermissionCanEdit(state, payload) {
      state.isPermissionCanEdit = payload;
    },
    setIsPermissionCanView(state, payload) {
      state.isPermissionCanView = payload;
    },
    // marker
    setMainGMapActive(state, payload) {
      state.mainGMapActive = payload;
    },
    setCreateGMapActive(state, payload) {
      state.createGMapActive = payload;
    },
    setMapMarker(state, payload) {
      state.markerList = payload;
    },
    setPolyline(state, payload) {
      state.polylineList = payload;
    },
    setBoundPloyLine(state, payload) {
      state.boundPloyLine = payload;
    },
    setBoundLatLngCenter(state, payload) {
      state.boundLatLngCenterList = payload;
    },
    // bottom detail
    setActivateInput(state, payload) {
      state.isActivateInput = payload;
    },
    setActiveForm(state, payload) {
      state.isActivateForm = payload;
    },

    // main data
    setTemplateData(state, payload) {
      state.templateData = payload;
    },
    setForceGetDate(state, payload) {
      state.forceGetData = payload;
    },
    setIsOk(state, payload) {
      state.isOk = payload;
    },
    setMarkerListDuration(state, payload) {
      state.markerListDuration = payload;
    },
    setVehiclePositionMarker(state, payload) {
      state.vehicleMarkerLocation = payload;
    },
    setReloadMarker(state, payload) {
      state.reloadMarker = state.reloadMarker + 1;
    },
    setRecompenseToTemplateData(state, value) {
      state.templateData = {
        ...state.templateData,
        ...value,
      };
    },
  },
  actions: {
    updateMainGMapActive(context, payload) {
      context.commit("setMainGMapActive", payload);
    },
    updateCreateGMapActive(context, payload) {
      context.commit("setCreateGMapActive", payload);
    },
    updateMainLoading(context, payload) {
      context.commit("setMainLoading", payload);
    },
    updateActivateMarker(context, payload) {
      context.commit("setActivateInput", payload);
    },
    // map and marker view
    updateMarkerList(context, payload) {
      context.commit("setMapMarker", payload);
    },
    updatePolyLine(context, payload) {
      context.commit("setPolyline", payload);
    },
    updateBoundPloyLine(context, payload) {
      context.commit("setBoundPloyLine", payload);
    },

    updateBoundLatLngCenter(context, payload) {
      context.commit("setBoundLatLngCenter", payload);
    },
    updateforceGetData(context, payload) {
      context.commit("setForceGetDate", context.state.forceGetData + 1);
    },
    updateSetIsOk(context, payload) {
      context.commit("setIsOk", payload);
    },
    updateActivateForm(context, payload) {
      context.commit("setActiveForm", payload);
    },
    updateTemplateData(context, payload) {
      // calculate endDate from marker where user not set endDate checkFromJobData
      if (!_.isNil(payload)) {
        context.commit("setTemplateData", {
          ...context.state.templateData,
          ...payload,
        });
      }
    },

    updateResetStore(context, payload) {
      if (payload === true) {
        context.commit("setActivateInput", false);
        context.commit("setIsPermissionCanEdit", false);
        context.commit("setIsPermissionCanView", false);
      }
    },

    updateRecompenseDate(context, payload) {
      context.commit("setRecompenseToTemplateData", payload);
    },
  },
  modules: {},
};
