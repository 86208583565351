<template>
  <div name="detailButton">
    <v-btn
      class="white--text font-weight-bold"
      :disabled="disabled"
      :color="color"
      light
      :block="block"
      elevation="0"
      @click="onClick()"
      :height="height"
    >
      <div>
        <v-icon left v-if="icon != null"> {{ icon }} </v-icon>
      </div>
      <slot></slot>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "detailButton",
  props: {
    text: String,
    icon: String,
    color: {
      type: String,
      default: "primary",
    },
    height: Number,
    large: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("on-click");
    },
  },
};
</script>
<style scoped></style>
