<template>
	<div name="contractOverviewDashboard">
		<v-container v-if="isPermissionCanView">
			<v-row>
				<v-col col="6">
					<div class="text-h5 font-weight-bold mt-1">รายงานภาพรวมสัญญา</div>
					<p class="ext-app-detail-md ma-0" v-if="data">
						ข้อมูลล่าสุด:
						{{
							dateStringToDateFormat(data.date, {
								returnThai: true,
							})
						}}
					</p>
				</v-col>
				<v-col cols="6">
					<div class="d-flex justify-end mt-3">
						<div class="mr-2">
							<CustomDatePickerInput
								data-input-name="reportDate"
								label="เริ่มต้น"
								:clearable="false"
								:value="startDate"
								@change="startDateHandler"
							/>
						</div>

						<div class="mr-2">
							<CustomDatePickerInput
								data-input-name="reportDate"
								label="สิ้นสุด"
								:clearable="false"
								:value="endDate"
								@change="endDateHandler"
							/>
						</div>
					</div>
				</v-col>
			</v-row>
			<ContractIncomeAndExpensesSummary :data="data" class="pb-1" />
			<ContractStatSummary :data="data" class="pt-0 pb-3" />

			<v-card class="px-4 mb-3" elevation="2" rounded="lg">
				<v-row no-gutters>
					<v-col cols="12" class="px-6 pb-6">
						<v-card-title class="px-0">
							<div class="pt-3 text-title font-weight-bold black--text">
								รายได้/รายจ่าย
							</div>
						</v-card-title>
						<v-row no-gutters>
							<v-col cols="12" class="pr-9">
								<ContractIncomeAndExpensesWeekly :data="data" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<ContractIncomeAndExpensesBarChart
					:data="data"
					@open-chart-info="openContractReportInfo"
				/>
				<ContractIncomeAndExpensesTable
					:data="data"
					@open-chart-info="openContractReportInfo"
				/>
			</v-card>

			<v-row class="mb-0">
				<v-col cols="12">
					<v-btn block large color="white" @click="heatMapHandler()">
						<div :style="`width: 10rem; height: 1rem;`">
							<div
								class="main-map-gd"
								:style="`width: 100%; height: 100%`"
							></div>
						</div>
						<span class="ml-2"> รายงานการกระจุกตัว (Heat Map)</span>
					</v-btn>
				</v-col>
			</v-row>

			<JobStatSummary :data="data" />
			<JobStatScatterChart
				class="pb-2"
				:data="data"
				@open-chart-info="openContractReportInfo"
			/>
			<JobStatDriversAndVehicles :data="data" />

			<ContractChartReportInfo ref="contractChartReportInfo" />
		</v-container>
		<AppOverlay :isLoading="isLoading" />
		<AppPageNotFound :show="isPermissionCanView" />
	</div>
</template>
<script>
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import AppOverlay from "@/components/AppOverlay.vue";
	import InputView from "@/components/InputView.vue";
	import _ from "lodash";

	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { processPermission } from "@/services/permissions";

	import VueApexCharts from "vue-apexcharts";

	import ContractStatSummary from "./chart-components/ContractStatSummary.vue";
	import ContractIncomeAndExpensesWeekly from "./chart-components/ContractIncomeAndExpensesWeekly.vue";
	import ContractIncomeAndExpensesSummary from "./chart-components/ContractIncomeAndExpensesSummary.vue";
	import ContractIncomeAndExpensesTable from "./chart-components/ContractIncomeAndExpensesTable.vue";
	import ContractIncomeAndExpensesBarChart from "./chart-components/ContractIncomeAndExpensesBarChart.vue";
	import JobStatSummary from "./chart-components/JobStatSummary.vue";
	import JobStatScatterChart from "./chart-components/JobStatScatterChart.vue";
	import JobStatDriversAndVehicles from "./chart-components/JobStatDriversAndVehicles.vue";
	import { getContractsReportService } from "@/services/api/report";
	import CustomDatePickerInput from "@/components/CustomDatePickerInput.vue";

	import {
		appDateYearMonthDayWithEnDashFormat,
		dateStringToDateFormat,
	} from "@/services/appDate";
	import moment from "moment";
	import ContractChartReportInfo from "../components/ContractChartReportInfo.vue";
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import NormalButton from "@/components/NormalButton.vue";

	export default {
		name: "ContractOverviewDashboard",
		components: {
			CustomCardFormViewModern,
			AppOverlay,
			InputView,
			AppPageNotFound,
			apexchart: VueApexCharts,
			ContractStatSummary,
			ContractIncomeAndExpensesSummary,
			ContractIncomeAndExpensesTable,
			ContractIncomeAndExpensesWeekly,
			ContractIncomeAndExpensesBarChart,
			JobStatSummary,
			JobStatScatterChart,
			JobStatDriversAndVehicles,
			CustomDatePickerInput,
			ContractChartReportInfo,
			PrimaryButton,
			NormalButton,
		},
		data() {
			return {
				durationRange: 90,
				isPermissionCanView: false,
				isLoading: false,
				permList: {},
				graphType: {
					DISTANCE: "ระยะทาง",
					HOURS: "ชั่วโมงทำงาน",
					JOBS: "จำนวนงาน",
				},
				uriQueryObj: {
					startdate: null,
					enddate: null,
				},
				data: null,
				startDate: null,
				endDate: null,
			};
		},

		computed: {},
		watch: {},
		methods: {
			dateStringToDateFormat: dateStringToDateFormat,
			setRouterQuery(queryObj) {
				this.$router
					.replace({
						path: this.$router.path,
						query: queryObj,
					})
					.catch(() => {});
			},
			startPage() {
				//
				if (Object.keys(this.$route.query).length > 0) {
					Object.keys(this.$route.query).forEach((key) => {
						if (!_.isNil(this.$route.query[key])) {
							this.uriQueryObj[key] = this.$route.query[key];
						}
					});
				}
				this.validateQueryDate();
				this.getData();
			},
			validateQueryDate() {
				if (!_.isNil(this.uriQueryObj["startdate"])) {
					this.startDate = this.uriQueryObj["startdate"];
				} else {
					this.startDate = moment()
						.subtract(this.durationRange, "days")
						.format(appDateYearMonthDayWithEnDashFormat);
					this.uriQueryObj["startdate"] = this.startDate;
				}

				if (!_.isNil(this.uriQueryObj["enddate"])) {
					this.endDate = this.uriQueryObj["startdate"];
				} else {
					this.endDate = moment().format(appDateYearMonthDayWithEnDashFormat);
					this.uriQueryObj["enddate"] = this.endDate;
				}
			},
			async getData() {
				this.isLoading = true;
				try {
					const queryParams = new URLSearchParams(this.uriQueryObj);
					// this.setRouterQuery(this.uriQueryObj);

					const responseData = await getContractsReportService(
						this.$store,
						queryParams.toString()
					);
					if (responseData["code"] === 200) {
						this.data = responseData["data"];
					} else {
						throw "response failed";
					}
				} catch (error) {
					console.error(error);
				}

				this.isLoading = false;
			},

			startDateHandler(value) {
				this.startDate = value;
				this.uriQueryObj["startdate"] = value;
				this.getData();
			},
			endDateHandler(value) {
				this.endDate = value;
				this.uriQueryObj["enddate"] = value;
				this.getData();
			},

			async openContractReportInfo(contractId) {
				if (!_.isNil(contractId)) {
					const arg = {
						contractId: contractId,
						startDate: this.startDate,
						endDate: this.endDate,
						isInputSearching: false,
					};
					await this.$refs.contractChartReportInfo.open(arg);
				}
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					const getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "CONTRACT");
					if (
						this.permList.admin_all === true ||
						this.permList.contract_total_report === true
					) {
						this.isPermissionCanView = true;
					}
				}
			},

			heatMapHandler() {
				this.$store.dispatch("jobsMap/openDialog", { show: true });
			},
		},
		mounted() {
			this.checkUserPermissions();
			this.startPage();
		},
	};
</script>
<style scoped>
	.container {
		max-width: 1500px;
	}
	.image_list_float_left {
		float: right;
	}
</style>
