import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getWorksheetListService(store, queryString) {
  let uri = "/user-job-payment/admin/list";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeBySeachingService(store, text) {
  let uri = `/user/search?text=${text}`;
  return await authHttpClientGet(store, uri);
}

export async function getWorksheetInfoService(
  store,
  queryString
) {
  let uri = "/user-job-payment/admin/info";
  if (queryString !== null) {
    uri = `${uri}?id=${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putWorksheetInfoService(store, data) {
  var url = `/user-job-payment/admin/info`;

  return await authHttpClientPut(store, url, data);
}

export async function postDownloadPDFService(store, payload) {
  let uri = `/user-job-payment/admin/pdf`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function postFinanceCSVService(store, payload) {
  let uri = `/user-job-payment/admin/import/extrapayment`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function postStatusCSVService(store, payload) {
  let uri = `/user-job-payment/admin/import/status`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function getExportBankPayrollService(
  store,
  queryString
) {
  let uri = "/user-job-payment/admin/export/bankpayment";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}