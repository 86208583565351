<template>
  <div name="customSelects" :class="expendMargin ? 'px-3' : ''">
    <InputView
      :title="title"
      :isRequired="isRequired"
      :bottomMargin="inputViewBottomMargin"
      :small="small"
    >
    </InputView>
    <v-text-field
      v-mask="'##/##/#### ##:##'"
      :value="inputData"
      @input="handleInput"
      placeholder="วว/ดด/ปปปป ชช:นน"
      @blur="validateHandler($event)"
      :rules="rules"
      dense
      outlined
      :disabled="disabled"
      :background-color="getBackgroundColor()"
      :name="dataInputName"
      :hide-details="hideDetail"
      class="app-custom-input"
    >
      <!--   -->
      <template v-slot:prepend-inner>
        <v-menu
          v-model="open"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pa-1">mdi-calendar</v-icon>
          </template>

          <div class="v-date-time-widget-container">
            <v-date-picker v-model="datepicker" no-title :min="minDate" />
            <v-time-picker
              format="24hr"
              v-model="timepicker"
              :min="minTime"
              :landscape="$vuetify.breakpoint.mdAndUp"
            >
            </v-time-picker>
            <v-row class="pt-3">
              <v-col>
                <p class="text-app-detail-large px-3">
                  {{ datetimePickerStatusText }}
                </p>
              </v-col>
              <v-col align="right"
                ><v-btn text @click="cancel()">Cancel</v-btn>
                <v-btn color="primary" text @click="confirm()">Confirm</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import moment from "moment";
import { mask } from "vue-the-mask";
import InputView from "./InputView.vue";

export default {
  name: "CustomDateTimeTypingPickerFormInput",
  directives: { mask },
  components: { InputView },
  props: {
    value: {},
    isRequired: {
      type: Boolean,
      default: false,
    },
    requiredText: {
      type: String,
      default: "(วัน/เดือน/ปีคศ ชั่วโมง:นาที)",
    },
    title: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    minDate: null,
    minTime: null,
    hideDetail: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    inputData: null,
    formData: null,
    //
    open: false,
    datepicker: null,
    timepicker: null,
    datetimePickerStatusText: null,
    rules: [],
  }),

  watch: {
    value: {
      immediate: false,
      handler(newValue) {
        if (this.newValue !== this.formData) {
          this.setToTextField(newValue);
        }
      },
    },
  },

  mounted() {
    if (this.isRequired) {
      this.setRequireToRules();
    }
  },

  methods: {
    confirm() {
      try {
        if (!_.isNil(this.datepicker) && !_.isNil(this.timepicker)) {
          this.formData = this.parseInputDate(
            `${this.datepicker}T${this.timepicker}`
          );
          this.decodeDate();
          this.open = false;
        } else {
          this.datetimePickerStatusText = "ระบุค่าให้ถูกต้อง";
        }
      } catch (error) {
        console.log(error);
      }
    },
    setRequireToRules() {
      this.rules = [(v) => !!v || this.requiredText];
    },
    handleInput(newValue) {
      this.formData = newValue;
    },
    validateHandler() {
      this.decodeDate();
    },
    parseDate(newValue) {
      const _d = moment(newValue, "DD/MM/YYYY HH:mm");
      return _d.format("YYYY-MM-DDTHH:mm:ss");
    },
    //
    parseInputDate(newValue) {
      const _d = moment(newValue, "YYYY-MM-DDTHH:mm");
      return _d.format("DD/MM/YYYY HH:mm");
    },

    decodeDate() {
      if (this.formData === null) {
        this.setRequireToRules();
        return;
      } else if (this.formData.length === 0) {
        this.setRequireToRules();
        return;
      }

      try {
        const res = moment(this.formData, "DD/MM/YYYY HH:mm", true).isValid();
        if (!res) {
          throw "Parse date fail";
        } else {
          this.$emit("input", this.parseDate(this.formData));
          this.setRequireToRules();
          this.setDateTimeToPicker();
        }
      } catch (error) {
        this.rules = [this.requiredText];
        console.log(error);
      }
    },
    setToTextField(newValue) {
      if (_.isNil(newValue)) {
        return;
      }

      const isOkDate = moment(newValue, "YYYY-MM-DDTHH:mm:ss", true).isValid();

      if (isOkDate) {
        const _d = moment(newValue);
        this.inputData = _d.format("DD/MM/YYYY HH:mm");
        this.formData = _d.format("DD/MM/YYYY HH:mm");
      }
    },
    setDateTimeToPicker() {
      if (!_.isNil(this.formData)) {
        const _d = moment(this.formData, "DD/MM/YYYY HH:mm");
        if (_d.isValid()) {
          this.datepicker = _d.format("YYYY-MM-DD");
          this.timepicker = _d.format("HH:mm");
        } else {
          this.datepicker = null;
          this.timepicker = null;
        }
      }
    },
    clearToPicker() {
      this.open = false;
    },
    cancel() {
      this.clearToPicker();
    },
    getBackgroundColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}
</style>
