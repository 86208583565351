<template>
  <div name="customSelects" :class="expendMargin ? 'px-3' : ''">
    <InputView
      :title="title"
      :isRequired="isRequired"
      :bottomMargin="inputViewBottomMargin"
      :small="small"
    >
      <v-menu
        ref="menu"
        v-model="show"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :class="
              small
                ? 'custom-placeholer-color-sm white'
                : 'custom-placeholer-color white'
            "
            slot="activator"
            prepend-inner-icon="mdi-calendar"
            v-model="datetimeDisplay"
            :placeholder="placeholder"
            v-on="on"
            dense
            outlined
            :rules="rules"
            :disabled="disabled"
            :readonly="true"
            :clearable="clearable"
            @click:clear="clearInput()"
            :background-color="getBackgroundColor()"
            :name="dataInputName"
            :hide-details="hideDetail"
            class="app-custom-input"
          ></v-text-field>
        </template>
        <div class="v-date-time-widget-container">
          <v-date-picker
            v-model="datepicker"
            no-title
            :min="minDate"
            locale="th"
          ></v-date-picker>

          <v-time-picker
            format="24hr"
            v-model="timepicker"
            :min="minTime"
            :landscape="$vuetify.breakpoint.mdAndUp"
          ></v-time-picker>
          <v-row class="pt-3"
            ><v-col
              ><p class="text-app-detail-large px-3">
                {{ currentSelection }}
              </p></v-col
            ><v-col align="right"
              ><v-btn text @click="cancel()">ยกเลิก</v-btn>
              <v-btn
                color="primary"
                text
                @click="confirm()"
                :disabled="!isSetDateTime"
                >ยืนยัน</v-btn
              ></v-col
            ></v-row
          >
        </div>
      </v-menu>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
import moment from "moment";

export default {
  name: "CustomDateTimePickerFormInput",
  components: { InputView },
  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    minDate: null,
    minTime: null,
    hideDetail: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    show: false,
    dateTimeValue: "",
    datepicker: "",
    timepicker: "",
  }),

  watch: {
    value: function (newVal) {
      if (!_.isNil(newVal)) {
        this.dateTimeValue = newVal;
        this.datetimeDisplay = this.dateTimeValue;
        // split and set
        this.spliteData(newVal);
      } else {
        this.reset();
      }
    },
  },

  computed: {
    datetimeDisplay: {
      // display day/month/year hh::mm
      get() {
        // console.log("datetimeDisplay", this.dateTimeValue);
        return this.formatDate(this.dateTimeValue);
      },
      set(newVal) {
        return this.formatDate(newVal);
      },
    },
    currentSelection() {
      let selectedTime = this.timepicker;
      return this.formatDatePicker(this.datepicker) + " " + selectedTime;
    },
    isSetDateTime() {
      if (this.datepicker !== "" && this.timepicker !== "") {
        return true;
      }
      return false;
    },
  },

  methods: {
    spliteData(newVal) {
      let setValue = newVal.split("T");
      this.datepicker = setValue[0];
      this.timepicker = setValue[1];
    },
    getBackgroundColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },

    formatDate(date) {
      if (!date) return null;
      //
      const splitValue = date.split("T");
      const [year, month, day] = splitValue[0].split("-");
      const [hours, minute] = splitValue[1].split(":");

      return `${day}/${month}/${year} ${hours}:${minute}`;
    },

    updateTime() {
      // return year/month/day hh:mm
      this.$emit("input", this.datepicker + "T" + this.timepicker);
    },

    formatDatePicker(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    confirm() {
      // this.onUpdateDateTime();
      this.updateTime();
      this.confimUpdateDataHandler();
      this.show = false;
    },
    cancel() {
      this.show = false;
    },

    // onUpdateDateTime() {
    //   if (!this.datepicker || !this.timepicker) return false;

    //   let selectedTime = this.timepicker;
    //   this.datetimeDisplay =
    //     this.formatDate(this.datepicker) + " " + selectedTime;

    //   // this.model = this.datepicker + " " + selectedTime;
    //   this.$emit("input", this.datepicker + " " + selectedTime);
    // },

    clearInput() {
      this.reset();
    },

    reset() {
      // var d = new Date();
      // var currentHour = d.getHours(); // AM,PM format
      // var minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
      // var currentTime = currentHour + ":" + minutes;
      // this.timepicker = currentTime;
      // this.datepicker = d.toISOString().substr(0, 10);
      this.timepicker = "";
      this.datepicker = "";
      this.dateTimeValue = "";
      this.$emit("input", null);
    },
    confimUpdateDataHandler() {
      let _m = null;
      try {
        const d = this.datepicker + "T" + this.timepicker;
        if (!_.isEmpty(d)) {
          _m = moment(d, "YYYY/MM/DDTHH:mm").valueOf();
        }
      } catch (error) {
        console.error("confimHandler fail");
      }

      this.$emit("comfim-update", _m);
    },
  },

  mounted() {
    // if (_.isNil()) console.log(this.value);
    if (!_.isNil(this.value)) {
      this.dateTimeValue = this.value;
      // split and set
      this.spliteData(this.value);
    }
  },
};
</script>
<style lang="scss" scoped>
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}
</style>
