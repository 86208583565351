<template>
  <v-col cols="3" lg="3" md="12" sm="12">
    <!-- QRCODE section -->
    <VehicleEmbededQRCode :data="vehicleData" />
    <!-- Expenses -->

    <VehicleManageExpensesBox
      v-if="isPermissionExpensesView"
      title="ประกันภัย"
      keyDoc="insurance"
      :data="formData"
    />

    <VehicleManageExpensesBox
      v-if="isPermissionExpensesView"
      title="พ.ร.บ."
      keyDoc="act"
      :data="formData"
    />

    <VehicleManageExpensesBox
      v-if="isPermissionExpensesView"
      title="ภาษี"
      keyDoc="tax"
      :data="formData"
    />

    <!-- <VehicleManageExpensesBox
      v-if="isPermissionExpensesView"
      title="ค่างวดรถ"
      keyDoc="loan"
      :data="docsData"
    /> -->

    <VehicleManangeEventBox
      v-if="isPermissionRepairationView"
      :data="formData"
    />
    <VehicleManageFuelBox v-if="isPermissionFuelView" :data="formData" />
    <!--  -->
    <CustomCardFormViewModern
      :elevation="0"
      title="GPS"
      :isShowTitle="true"
      class="pb-1"
    >
      <div class="d-flex flex-column mb-1">
        <v-chip
          v-if="docsData.gps.gpsRemainingDay !== null"
          class="pt-1 mb-1 title-input"
          :color="getChipExpiredColor(docsData.gps.gpsRemainingDay)"
          label
          outlined
        >
          <v-icon left> mdi-timer-outline </v-icon>
          ต้องชำระภายใน {{ docsData.gps.gpsRemainingDay }} วัน
        </v-chip>
        <v-chip
          v-if="docsData.gps.gpsRemainingDay !== null"
          class="pt-1 mb-6 title-input"
          :color="getChipPrimaryColor()"
          label
          outlined
        >
          <v-icon left> mdi-update </v-icon>
          หมดอายุวันที่
          {{
            dateStringToDateFormat(formData.gpsExpiryDate, {
              returnThai: false,
            })
          }}
        </v-chip>
      </div>

      <CustomTextInputImprove
        ref="gpsBrand"
        v-model="docsData.gps.gpsName"
        title="GPS ที่ใช้"
        placeholder="ชื่อของ GPS ที่ใช้"
        :isRequired="false"
        :expendMargin="false"
        :disabled="!isPermissionCanEdit"
      ></CustomTextInputImprove>
      <CustomDateTypingPickerFormInput
        placeholder="เลือก"
        title="วันหมดอายุรอบถัดไป (วัน/เดือน/ปีคศ)"
        v-model="docsData.gps.gpsExpiryDate"
        :disabled="!isPermissionCanEdit"
        :isRequired="false"
        :expendMargin="false"
        :minDate="formData.gpsExpiryDate"
      />

      <!-- <div class="px-6 sub-title-section" v-else>-</div> -->
      <div class="text-center">
        <DetailButton
          v-if="isPermissionCanEdit"
          :disabled="!docsData.gps.gpsName && !docsData.gps.gpsExpiryDate"
          class="mt-2"
          @on-click="handleGpsPayment"
          >ยืนยันการชำระค่า GPS</DetailButton
        >
      </div>
    </CustomCardFormViewModern>

    <CustomCardFormViewModern
      v-if="isPermissionExpensesView"
      :elevation="0"
      title="ค่างวดรถ"
      :redirectLink="`/vehicle/loan?text=${formData.licenseNumber}`"
      :isShowTitle="true"
    >
      <div
        v-if="
          formData.loanExpiryDate === null &&
          formData.loanCounter !== null &&
          !isHandleLoanPayment
        "
        class="mx-0"
      >
        <v-icon left class="primary--text mr-0 mb-2">
          mdi-check-circle-outline
        </v-icon>
        <span class="text-h6 font-weight-bold primary--text">
          ปิดงวดรถสำเร็จ</span
        >
      </div>
      <div class="d-flex flex-column mb-1">
        <v-chip
          v-if="formData.loanExpiryRemainingDay !== null"
          class="pt-1 mb-1 title-input"
          :color="getChipExpiredColor(formData.loanExpiryRemainingDay)"
          label
          outlined
        >
          <v-icon left> mdi-timer-outline </v-icon>
          ต้องชำระภายใน
          {{ formData.loanExpiryRemainingDay }} วัน
        </v-chip>

        <v-chip
          v-if="
            formData.loanCounter !== null && formData.loanCodeNumber !== null
          "
          class="pt-1 mb-6 title-input"
          :color="getChipPrimaryColor()"
          label
          outlined
        >
          <v-icon left> mdi-order-bool-descending-variant </v-icon>
          ชำระไปแล้ว {{ formData.loanCounter }} งวด
        </v-chip>
      </div>
      <div
        v-if="
          !(
            (formData.loanCounter == null && !isHandleLoanPayment) ||
            (formData.loanCounter !== null &&
              formData.loanExpiryDate === null &&
              !isHandleLoanPayment)
          )
        "
      >
        <CustomTextInputImprove
          v-if="formData.loanCodeNumber === null"
          ref="loanCount"
          v-model="formData.loanCounter"
          title="จำนวนครั้งค่างวดที่จ่ายไปแล้วก่อนหน้านี้ (กรณีมี)"
          placeholder=""
          input-type="numberWithoutSign"
          :is-required="false"
          :expend-margin="false"
          :disabled="!isPermissionCanEdit"
        ></CustomTextInputImprove>

        <CustomTextInputCurrency
          ref="loanAmount"
          v-model="formData.loanAmount"
          :disabled="!isPermissionCanEdit"
          title="ราคาค่างวดรถ (บาท)"
          placeholder="ราคาค่างวดรถ (บาท)"
          :isRequired="true"
          :expendMargin="false"
        >
        </CustomTextInputCurrency>

        <CustomSelectsArrOfObj
          ref="loanRoundpayDay"
          v-model="formData.loanRoundpayDay"
          class="custom-placeholer-color"
          :items="dayNumber"
          :isRequired="true"
          :disabled="!isPermissionCanEdit"
          :loading="false"
          title="วันที่ต้องชำระประจำเดือน"
          item-text="value"
          item-value="key"
          :returnObject="true"
          :expendMargin="false"
        >
        </CustomSelectsArrOfObj>

        <CustomTextInputImprove
          ref="financeCompany"
          v-model="formData.financeCompany"
          title="ชื่อไฟแนนซ์"
          placeholder="ชื่อไฟแนนซ์"
          :disabled="!isPermissionCanEdit"
          :isRequired="true"
          :expendMargin="false"
        ></CustomTextInputImprove>
      </div>
      <v-row class="py-3 px-2" align="center" justify="center">
        <v-col
          class="text-center px-0 py-1"
          cols="12"
          v-if="
            (formData.loanCounter == null && !isHandleLoanPayment) ||
            (formData.loanCounter !== null &&
              formData.loanExpiryDate === null &&
              !isHandleLoanPayment)
          "
        >
          <DetailButton
            v-if="isPermissionCanEdit"
            class="mt-0"
            @on-click="isHandleLoanPayment = true"
            >เริ่มชำระค่างวด</DetailButton
          >
        </v-col>

        <v-col class="text-center px-1 py-1" v-else>
          <DetailButton
            v-if="isPermissionCanEdit"
            class="mt-0"
            @on-click="handleLoanPayment"
            :block="true"
            >ยืนยันชำระค่างวด</DetailButton
          >
        </v-col>
        <v-col
          class="text-center px-1 py-1"
          v-if="
            formData.loanCounter !== null &&
            formData.loanExpiryDate !== null &&
            isPermissionCanEdit
          "
        >
          <DetailButton
            :block="true"
            class="mt-0"
            color="deep-orange"
            @on-click="handleDeleteLoanPayment"
            >ปิดงวดรถ</DetailButton
          >
        </v-col>
      </v-row>
    </CustomCardFormViewModern>
    <AppDialogConfirm ref="confirmSaving" />
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-col>
</template>

<script>
import CustomSelectsArrOfIconObj from "@/components/CustomSelectsArrOfIconObj.vue";
import DetailButton from "@/components/DetailButton.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
// import VehicleQRCode from "./components/VehicleQRCode.vue";
import VehicleEmbededQRCode from "./components/VehicleEmbededQRCode.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import { processPermission } from "@/services/permissions";
import VehicleManageExpensesBox from "./components/VehicleManageExpensesBox.vue";
import VehicleManageFuelBox from "./components/VehicleManageFuelBox.vue";
import VehicleManangeEventBox from "./components/VehicleManangeEventBox.vue";
import { DialogType } from "@/services/dialog";
import {
  postVehicleGpsService,
  postVehicleLoanService,
  delVehicleLoanService,
} from "@/services/api/vehicle";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";

export default {
  props: {
    mongoId: {
      type: String,
      default: "",
    },
    vehicleData: {
      type: Object,
      default: () => {},
    },
    // gpsBoxStatus: {
    //   type: Boolean,
    //   default: false,
    // },
    loadingFunc: {
      type: Function,
      default: () => {},
    },
    callApiFunc: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    vehicleData: {
      immediate: false,
      handler: function (newValue) {
        this.startComponent(newValue);
      },
    },
    // gpsBoxStatus: {
    //   immediate: false,
    //   handler: function (newValue) {
    //     this.formData.accessories.gps = newValue;
    //   },
    // },
  },
  data: () => ({
    dateStringToDateFormat: dateStringToDateFormat,
    isPermissionCanEdit: false,
    isPermissionExpensesView: false,
    isPermissionFuelView: false,
    isPermissionRepairationView: false,
    isHandleLoanPayment: false,
    permList: {},
    dayNumber: [],
    formData: {
      qrcodeImages: null,
      // accessories: {
      //   curtain: null,
      //   gps: null,
      //   dashcam: null,
      //   incarcam: null,
      //   softCloseAutomaticDoor: null,
      // },
      loanCodeNumber: null,
      loanCounter: null,
      loanAmount: null,
      loanRoundpayDay: null,
      financeCompany: null,
    },
    docsData: {
      last_insurance: {
        remainingDay: 0,
        issueDate: "",
        expiryDate: "",
      },
      last_act: {
        remainingDay: 0,
        issueDate: "",
        expiryDate: "",
      },
      last_tax: {
        remainingDay: 0,
        issueDate: "",
        expiryDate: "",
      },
      // last_loan: {
      //   remainingDay: 0,
      //   issueDate: "",
      //   expiryDate: "",
      //   licenseNumber: "",
      // },
      events: {
        eventCount: 0,
        eventTotalAmount: 0,
      },
      fuel: {
        last_diff_fuel_payment: 0,
        last_fuel_payment: "",
      },
      gps: { gpsName: null, gpsExpiryDate: null, gpsRemainingDay: null },
    },
  }),
  methods: {
    enableLoading() {
      this.$emit("loading-func", true);
    },
    disableLoading() {
      this.$emit("loading-func", false);
    },
    getDayNumber() {
      let i = 1;
      while (i <= 31) {
        this.dayNumber.push(i);
        i++;
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE");
        if (this.permList.vehicle_info) {
          this.isPermissionCanView = true;
        }

        if (this.permList.admin_all || this.permList.vehicle_expenses) {
          this.isPermissionExpensesView = true;
        }

        if (this.permList.admin_all || this.permList.vehireparation_list) {
          this.isPermissionRepairationView = true;
        }

        if (this.permList.admin_all || this.permList.fuelpayment_list) {
          this.isPermissionFuelView = true;
        }

        if (this.permList.admin_all || this.permList.vehicle_info_crud) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    startComponent() {
      // set data
      this.formData = _.cloneDeep(this.vehicleData);
      this.setDocsData();
      this.getDayNumber();
      this.checkUserPermissions();
    },
    getChipExpiredColor(day) {
      if (day < 3) {
        return "red darken-1";
      } else if (day < 10) {
        return "amber darken-2";
      }
      return "green darken-1";
    },
    getChipPrimaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    setDocsData() {
      if (!_.isNil(this.formData["last_insurance"])) {
        this.docsData["last_insurance"] = this.formData["last_insurance"];
        if (this.docsData.last_insurance["remainingDay"] === null) {
          this.docsData.last_insurance["remainingDay"] = 0;
        }
      }

      if (!_.isNil(this.formData["last_act"])) {
        this.docsData["last_act"] = this.formData["last_act"];
        if (this.docsData.last_act["remainingDay"] === null) {
          this.docsData.last_act["remainingDay"] = 0;
        }
      }

      if (!_.isNil(this.formData["last_tax"])) {
        this.docsData["last_tax"] = this.formData["last_tax"];
        if (this.docsData.last_tax["remainingDay"] === null) {
          this.docsData.last_tax["remainingDay"] = 0;
        }
      }
      // events
      if (!_.isNil(this.formData["eventCount"])) {
        this.docsData.events["eventCount"] = this.formData["eventCount"];
      }
      if (!_.isNil(this.formData["eventTotalAmount"])) {
        this.docsData.events["eventTotalAmount"] =
          this.formData["eventTotalAmount"];
      }
      // fuel
      if (!_.isNil(this.formData["last_diff_fuel_payment"])) {
        this.docsData.fuel["last_diff_fuel_payment"] =
          this.formData["last_diff_fuel_payment"];
      }
      if (!_.isNil(this.formData["last_fuel_payment"])) {
        this.docsData.fuel["last_fuel_payment"] =
          this.formData["last_fuel_payment"];
      }

      // GPS
      if (!_.isNil(this.formData["gps"])) {
        this.docsData.gps["gpsName"] = this.formData["gps"];
      }
      if (!_.isNil(this.formData["gpsExpiryDate"])) {
        this.docsData.gps["gpsExpiryDate"] = this.formData["gpsExpiryDate"];
      }
      if (!_.isNil(this.formData["gpsRemainingDay"])) {
        this.docsData.gps["gpsRemainingDay"] = this.formData["gpsRemainingDay"];
      }

      // Loan

      // if (this.formData["loanExpiryDate"] !== null) {
      //   this.docsData["last_loan"]["expiryDate"] =
      //     this.formData["loanExpiryDate"];
      // }
      // if (this.formData["loanExpiryRemainingDay"] !== null) {
      //   this.docsData["last_loan"]["remainingDay"] =
      //     this.formData["loanExpiryRemainingDay"];
      // }

      // if (this.formData["licenseNumber"] !== null) {
      //   this.docsData["licenseNumber"] = this.formData["licenseNumber"];
      // }
    },
    //
    async handleGpsPayment() {
      if (this.mongoId !== null) {
        if (
          (this.docsData.gps["gpsName"] !== null) &
          (this.docsData.gps["gpsName"] !== "") &
          (this.docsData.gps["gpsExpiryDate"] !== null)
        ) {
          if (
            await this.$refs.confirmSaving.open(
              "คุณได้ชำระค่า GPS เรียบร้อยแล้ว ใช่หรือไม่ ?",
              null,
              DialogType.ACTION,
              {
                noconfirm: true,
              }
            )
          ) {
            this.updateGpsPayment();
          }
        } else {
          await this.$refs.dialogAlert.open(
            "กรุณากรอกข้อมูล GPS ทั้งหมด",
            null,
            DialogType.ERROR,
            {
              noconfirm: false,
            }
          );
        }
      }
    },
    async updateGpsPayment() {
      if (this.mongoId !== null) {
        this.enableLoading();
        let gpsData = {
          gps: this.docsData.gps["gpsName"],
          gpsAmount: null,
          gpsExpiryDate: this.docsData.gps["gpsExpiryDate"],
        };
        let resp = await postVehicleGpsService(
          this.$store,
          this.mongoId,
          gpsData
        );
        if (resp["code"] === 200) {
          this.disableLoading();
          if (resp.data["insertTrans"]) {
            this.alertGpsSaveChange();
            this.$emit("call-api-func");
          } else {
            this.alertGpsFailedChange();
          }
        }
      }
    },
    // loan
    async handleLoanPayment() {
      if (this.formData.loanCounter !== null) {
        if (this.mongoId !== null) {
          if (
            (this.formData["loanAmount"] !== null) &
            (this.formData["loanAmount"] !== "") &
            (this.formData["loanRoundpayDay"] !== null) &
            (this.formData["financeCompany"] !== null) &
            (this.formData["financeCompany"] !== "")
          ) {
            this.updateLoanPayment();
          } else {
            await this.$refs.dialogAlert.open(
              "กรุณากรอกข้อมูลค่างวดทั้งหมด",
              null,
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
          }
        }
      } else {
        if (this.mongoId !== null) {
          if (
            (this.formData["loanAmount"] !== null) &
            (this.formData["loanAmount"] !== "") &
            (this.formData["loanRoundpayDay"] !== null) &
            (this.formData["financeCompany"] !== null) &
            (this.formData["financeCompany"] !== "")
          ) {
            this.updateLoanPayment();
          } else {
            await this.$refs.dialogAlert.open(
              "กรุณากรอกข้อมูลค่างวดทั้งหมด",
              null,
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
          }
        }
      }
    },
    async updateLoanPayment() {
      if (this.mongoId !== null) {
        this.enableLoading();
        let loanData = {
          loanCounter: this.formData["loanCounter"],
          loanAmount: this.formData["loanAmount"],
          loanRoundpayDay: this.formData["loanRoundpayDay"],
          financeCompany: this.formData["financeCompany"],
          loanCodeNumber: this.formData["loanCodeNumber"],
        };

        let responseData = await postVehicleLoanService(
          this.$store,
          this.mongoId,
          loanData
        );
        if (responseData["code"] === 200) {
          this.disableLoading();
          if (this.formData.loanCounter !== null) {
            if (responseData.data["insertTrans"]) {
              // this.alertLoanSaveChange();
              this.$emit("call-api-func");
            } else {
              this.alertLoanFailedChange();
            }
          } else {
            if (responseData.data["insertTrans"]) {
              // this.alertLoanCreate();
              this.$emit("call-api-func");
            } else {
              this.alertLoanFailedCreate();
            }
          }
        }
      }
    },
    async handleDeleteLoanPayment() {
      if (this.formData.loanCounter !== null) {
        if (this.mongoId !== null) {
          if (
            (this.formData["loanAmount"] !== null) &
            (this.formData["loanAmount"] !== "") &
            (this.formData["loanRoundpayDay"] !== null) &
            (this.formData["financeCompany"] !== null) &
            (this.formData["financeCompany"] !== "")
          ) {
            this.deleteLoanPayment();
          } else {
            await this.$refs.dialogAlert.open(
              "กรุณากรอกข้อมูลค่างวดทั้งหมด",
              null,
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
          }
        }
      }
    },
    async deleteLoanPayment() {
      if (this.mongoId !== null) {
        this.enableLoading();
        let resp = await delVehicleLoanService(this.$store, this.mongoId);
        if (resp["code"] === 200) {
          if (resp.data["status"]) {
            this.isHandleLoanPayment = false;
            // this.alertDeleteLoanSuccess();
            this.$emit("call-api-func");
          } else {
            this.alertDeleteLoanFailedChange();
          }
          this.disableLoading();
        }
      }
    },
    // dialog
    async alertGpsSaveChange() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ยืนยันการชำระค่า GPS สำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    async alertGpsFailedChange() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ยืนยันการชำระค่า GPS ไม่สำเร็จ!",
          "กรุณาตรวจสอบข้อมูลอีกครั้ง",
          DialogType.ERROR,
          this.alertOptions
        );
      }
    },
    async alertLoanCreate() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "เริ่มต้นการชำระค่างวดสำเร็จ!",
          "กรุณากดปุ่ม ยืนยันชำระค่างวดรถ เพื่อชำระค่างวดแรก!",
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    async alertLoanFailedCreate() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "เริ่มต้นการชำระค่างวดไม่สำเร็จ!",
          "กรุณาตรวจสอบข้อมูลอีกครั้ง",
          DialogType.ERROR,
          this.alertOptions
        );
      }
    },
    async alertLoanSaveChange() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ยืนยันการชำระค่างวดสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    async alertLoanFailedChange() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ยืนยันการชำระค่างวดไม่สำเร็จ!",
          null,
          DialogType.ERROR,
          this.alertOptions
        );
      }
    },
    async alertDeleteLoanSuccess() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ปิดงวดรถสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },
    async alertDeleteLoanFailedChange() {
      if (this.mongoId !== null) {
        await this.$refs.alertSaving.open(
          "ปิดงวดรถไม่สำเร็จ!",
          null,
          DialogType.ERROR,
          this.alertOptions
        );
      }
    },
  },
  components: {
    CustomSelectsArrOfIconObj,
    DetailButton,
    AppDialogConfirm,
    CustomTextInputImprove,
    VehicleEmbededQRCode,
    CustomCardFormViewModern,
    CustomSelectsArrOfObj,
    CustomDateTypingPickerFormInput,
    VehicleManageExpensesBox,
    VehicleManageFuelBox,
    VehicleManangeEventBox,
    CustomTextInputCurrency,
  },
};
</script>
