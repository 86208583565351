<template>
	<div name="saveEmployeeFormButton">
		<v-card flat class="rounded-b-lg py-4 mt-5" rounded="0">
			<div v-if="isDivider" class="mb-4">
				<v-divider></v-divider>
			</div>
			<v-row justify="end" :class="isMarginBtn ? 'mx-6' : 'mx-1'">
				<v-col cols="auto">
					<NormalButton v-if="isEnableSkip" @on-click="clickSkip()"
						>ข้าม</NormalButton
					>
				</v-col>
				<v-col cols="auto">
					<PrimaryButton
						color="success"
						@on-click="saveChange()"
						:disabled="disabled"
					>
						{{ text }}
					</PrimaryButton>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>
<script>
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import NormalButton from "@/components/NormalButton.vue";

	export default {
		name: "AppSubmitFormButton",

		components: {
			PrimaryButton,
			NormalButton,
		},

		props: {
			isMarginBtn: {
				type: Boolean,
				default: true,
			},

			isEnableSkip: {
				type: Boolean,
				default: false,
			},
			isDivider: {
				type: Boolean,
				default: true,
			},
			text: {
				type: String,
				default: "บันทึก",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},

		methods: {
			clickSkip() {
				this.$emit("on-click-skip");
			},

			saveChange() {
				this.$emit("on-click-save");
			},
		},
	};
</script>
<style></style>
