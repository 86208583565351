<template>
  <v-dialog v-model="show" width="70%" :persistent="true">
    <v-card v-if="show">
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>สร้างงานซ้ำ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="cancelStatus" v-if="fetchDataError <= 0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" v-if="fetchDataError === 0">
        <div class="text-app-detail-large">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>เส้นทางตามงานเดินรถ</td>
                  <td>
                    {{ jobData.name }}
                    <span v-if="jobData.template">
                      (เส้นทาง: {{ jobData.template.name }})
                    </span>
                    <p class="mb-0">
                      {{ jobData.markerStartName }}
                      <v-icon> mdi-arrow-right-thick </v-icon>
                      {{ jobData.markerEndName }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>จำนวนจุดเป้าหมาย</td>
                  <td>{{ jobData.checkpoint }} จุด</td>
                </tr>
                <tr>
                  <td>ระยะเวลาโดยประมาณ</td>
                  <td>{{ processDurationText(jobData.durationObj) }}</td>
                </tr>
                <tr>
                  <td>ระยะทางโดยประมาณ</td>
                  <td>{{ processDistanceText(jobData.distanceObj) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-divider class="my-3"></v-divider>

        <DuplicateJobSelectDriverAndVehicleSection />

        <!-- <DuplicateJobRecompenseSection
          @componentValueChange="setRecompenseValue"
        /> -->
        <v-divider class="my-3"></v-divider>

        <DuplicateJobTimeDateFormDetailSection />

        <v-divider class="my-3"></v-divider>
        <!--  table list -->
        <v-list dense class="ma-0 pa-0">
          <v-subheader>
            <p>วันที่ต้องการ</p>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" icon @click="dateTableRefreshHandler()">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
            <v-menu
              v-model="datePickerShow"
              right
              offset-y
              :close-on-click="false"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  :loading="dateGroupLoading"
                >
                  เพิ่มวันที่ต้องการ
                </v-btn>
              </template>

              <v-date-picker
                v-model="selectedDates"
                multiple
                no-title
                scrollable
                :min="jobData.today"
                locale="th"
              >
                <p class="mb-0 red--text" v-if="datePickerErrorMessage">
                  {{ datePickerErrorMessage }}
                </p>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelSelectDateDialog()">
                  ยกเลิก
                </v-btn>
                <v-btn text color="primary" @click="confirmSelectDateDialog()">
                  ตกลง
                </v-btn>
              </v-date-picker>
            </v-menu></v-subheader
          >

          <!-- <v-list-item
            v-if="dateGroupList === null && dateGroupLoading === false"
          >
            <v-list-item-content>
              <v-list-item-title> ไม่มีข้อมูล </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            v-if="dateGroupList.length !== 0 && dateGroupLoading === true"
          >
            <v-list-item-content>
              <v-list-item-title> กำลังดึงข้อมูล... </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">เวลายืนยันงาน</th>
                    <th class="text-left">เวลาเริ่มงาน</th>
                    <th class="text-left">เวลาจบงาน</th>
                    <th class="text-left">สถานะ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="dateGroupList.length !== 0">
                  <tr v-for="(item, idx) in dateGroupList" :key="idx">
                    <td>{{ item.confirmDateStr }}</td>
                    <td>{{ item.startDateStr }}</td>
                    <td>
                      {{ item.endDateStr || item.estimateEndDateStr }}
                    </td>
                    <td>
                      <div v-if="item.status">
                        <span class="success--text"> ว่าง </span>
                      </div>
                      <div v-else>
                        <a
                          v-for="(error, errIdx) in item.errorJobList"
                          :key="`er${errIdx}`"
                          :href="getJobURL(item.duplicateJobList[errIdx]._id)"
                          target="_blank"
                          class="error--text"
                          v-html="error"
                        ></a>
                      </div>
                    </td>
                    <td>
                      <v-icon
                        color="grey lighten-1"
                        @click="removeDateGroup(idx)"
                      >
                        mdi-window-close
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-list>
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider v-if="fetchDataError === 0"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="fetchDataError === 0"
          :disabled="!canSave"
          color="primary"
          text
          @click="saveStatus"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DuplicateJobSelectDriverAndVehicleSection from "./DuplicateJobSelectDriverAndVehicleSection.vue";
import DuplicateJobTimeDateFormDetailSection from "./DuplicateJobTimeDateFormDetailSection";
import DuplicateJobRecompenseSection from "./DuplicateJobRecompenseSection";
import {
  processDistanceText,
  processDurationText,
} from "@/services/appFuncHelpper";
import {
  getJobInfoForDuplicateService,
  getJobInfoForDuplicateCheckTimeService,
  postJobInfoForDuplicateService,
} from "@/services/api/jobs";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { dateStringToDateFormat, getDayNameHelper } from "@/services/appDate";

export default {
  name: "DuplicateJobsDialog",
  components: {
    DuplicateJobSelectDriverAndVehicleSection,
    DuplicateJobTimeDateFormDetailSection,
    DuplicateJobRecompenseSection,
    PrimaryButton,
  },
  computed: {
    jobData() {
      // console.log(this.$store.state.jobs.jobData);
      return this.$store.state.jobs.jobData;
    },
    formRefresh() {
      return this.$store.state.jobs.refreshFormDuplicateCounter;
    },
    canSave() {
      return this.checkCanSave(this.dateGroupList);
    },
  },
  watch: {
    formRefresh(newVal) {
      // console.log("duplicate"newVal);
      this.dateTableRefreshHandler();
    },
  },
  data() {
    return {
      jobId: null,
      valid: null,
      resolve: null,
      reject: null,
      show: false,
      fetchDataError: 0,
      formData: {},
      // jobData: null,
      datePickerShow: false,
      datePickerErrorMessage: null,
      selectedDates: null,
      dateGroupList: [],
      dateGroupLoading: false,
    };
  },
  methods: {
    processDistanceText: processDistanceText,
    processDurationText: processDurationText,
    getJobURL(jobId) {
      return `${window.location.origin}/jobs/edit/${jobId}`;
    },
    resetForm() {
      this.fetchDataError = 0;
      this.datePickerShow = false;
      this.selectedDates = null;
      this.dateGroupList = [];
      this.dateGroupLoading = false;
    },
    open(jobId) {
      this.jobId = jobId;
      this.fetchDataError = 1;
      this.show = true;
      this.getData();

      // driver
      // vehicle
      // checkpoint
      // totalDistance
      // totalDuration
      // confirmDate
      // startDate
      // estimateEndDate
      // endDate

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async getData() {
      try {
        const responseData = await getJobInfoForDuplicateService(
          this.$store,
          `id=${this.jobId}`
        );
        if (responseData["code"] === 200) {
          this.$store.dispatch("jobs/updateJobData", responseData["data"]);
          this.fetchDataError = 0;
        } else {
          throw "API_FETCH_FAIL";
        }
      } catch (error) {
        this.fetchDataError = -1;
      }
    },
    async saveStatus() {
      let _selectedDate = [];

      const payload = {
        date: [],
        vehicleId: _.get(this.$store, "state.jobs.jobData.vehicleId._id", null),
        driverId: _.get(this.$store, "state.jobs.jobData.driverId._id", null),
      };

      let isPayloadOk = true;

      if (this.dateGroupList.length === 0) {
        isPayloadOk = false;
      }

      for (let i = 0; i < this.dateGroupList.length; i++) {
        if (this.dateGroupList[i]["status"] === false) {
          isPayloadOk = false;
        }
        _selectedDate.push(this.dateGroupList[i]["rawDate"]);
      }

      if (_.isNil(payload["vehicleId"]) || _.isNil(payload["driverId"])) {
        isPayloadOk = false;
      }

      for (let i = 0; i < this.dateGroupList.length; i++) {
        payload["date"].push({
          confirmDate: this.dateGroupList[i]["confirmDate"],
          startDate: this.dateGroupList[i]["startDate"],
          endDate: this.dateGroupList[i]["endDate"],
          estimateEndDate: this.dateGroupList[i]["estimateEndDate"],
        });
      }

      if (isPayloadOk) {
        try {
          this.fetchDataError = 2;
          let jobId = _.get(this.$store, "state.jobs.jobData._id", null);
          let queryString = new URLSearchParams({
            id: jobId,
          }).toString();

          if (jobId === null) {
            throw "JOB_ID_NOT_FOUND";
          }

          const responseData = await postJobInfoForDuplicateService(
            this.$store,
            queryString,
            payload
          );
          if (responseData["code"] === 200) {
            this.resolve(true);
            this.resetForm();
            this.$store.dispatch("jobs/updateJobData", null);
            this.show = false;
          } else {
            throw "RESPONSE_DATA_ERROR";
          }
        } catch (error) {
          console.error(error);
          this.fetchDataError = -1;
        }
      } else {
        console.log(isPayloadOk);
      }
    },
    cancelStatus() {
      this.resolve(null);
      this.resetForm();
      this.$store.dispatch("jobs/updateJobData", null);
      this.show = false;
    },
    checkCanSave(data) {
      if (_.isNil(data)) {
        return false;
      }

      if (data.length === 0) {
        return false;
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i]["status"] === false) {
          return false;
        }
      }
      return true;
    },

    //
    async confirmSelectDateDialog() {
      this.datePickerErrorMessage = null;
      if (this.selectedDates === null) {
        return;
      }
      // get start
      const _startDate = _.get(
        this.$store.state,
        "jobs.jobData.startDate",
        null
      );

      if (_.isNil(_startDate)) {
        this.dateGroupList = [];
        this.selectedDates = null;
        this.datePickerErrorMessage = "ไม่มีเวลาเริ่มงาน";
        return;
      }
      // sort date
      this.dateGroupList = [];
      this.selectedDates = _.sortBy(this.selectedDates, function (_date) {
        return new Date(_date);
      });
      this.dateGroupLoading = true;
      this.datePickerShow = false;

      if (this.selectedDates.length === 0) {
        return;
      }

      const selectedDates = this.selectedDates.join(";");
      const payload = {
        id: _.get(this.$store.state, "jobs.jobData._id", null),
        confirmdate: _.get(this.$store.state, "jobs.jobData.confirmDate", null),
        startdate: _.get(this.$store.state, "jobs.jobData.startDate", null),
        enddate: _.get(this.$store.state, "jobs.jobData.endDate", null),
        estimateenddate: _.get(
          this.$store.state,
          "jobs.jobData.estimateEndDate",
          null
        ),
        date: selectedDates,
        vehicleId: _.get(this.$store.state, "jobs.jobData.vehicleId._id", null),
        driverId: _.get(this.$store.state, "jobs.jobData.driverId._id", null),
      };

      try {
        let responseData = await getJobInfoForDuplicateCheckTimeService(
          this.$store,
          new URLSearchParams(payload).toString()
        );

        if (responseData["code"] === 200) {
          // ว่าง ไม่ว่าง (ผู้ขับ, รถ)
          let _processData = [];
          for (let i = 0; i < responseData["data"].length; i++) {
            let d = { ...responseData["data"][i] };
            d["rawDate"] = this.selectedDates[i];
            d["errorJobList"] = [];
            if (d["status"] === false) {
              if (d["duplicateJobList"].length > 0) {
                d["dupTypeColor"] = "error--text";
                for (let j = 0; j < d["duplicateJobList"].length; j++) {
                  d["dupTypeText"] = "";
                  let st = [];
                  if (d["duplicateJobList"][j]["type"].includes("D")) {
                    st.push("ผู้ขับ");
                  }
                  if (d["duplicateJobList"][j]["type"].includes("V")) {
                    st.push("รถ");
                  }
                  d["errorJobList"].push(
                    `ไม่ว่าง เนื่องจาก <br/> ${
                      d["duplicateJobList"][j]["name"]
                    } (${st.join(", ")})`
                  );
                }
              } else {
                // status = false but list empty
                throw "LOAD_DATE_DATA_FIAL";
              }
            }

            d["confirmWeekDayStr"] = getDayNameHelper(
              parseInt(d["confirmWeekDay"])
            );
            d["confirmDateStr"] = dateStringToDateFormat(d["confirmDate"], {
              returnThai: true,
              type: "datetime",
              showSecond: false,
            });
            d[
              "confirmDateStr"
            ] = `${d["confirmWeekDayStr"]} ${d["confirmDateStr"]}`;

            d["startWeekDayStr"] = getDayNameHelper(
              parseInt(d["startWeekDay"])
            );
            d["startDateStr"] = dateStringToDateFormat(d["startDate"], {
              returnThai: true,
              type: "datetime",
              showSecond: false,
            });
            d["startDateStr"] = `${d["startWeekDayStr"]} ${d["startDateStr"]}`;

            //
            if (!_.isNil(d["endDate"])) {
              d["endWeekDayStr"] = getDayNameHelper(parseInt(d["endWeekDay"]));
              d["endDateStr"] = dateStringToDateFormat(d["endDate"], {
                returnThai: true,
                type: "datetime",
                showSecond: false,
              });
              d["endDateStr"] = `${d["endWeekDayStr"]} ${d["endDateStr"]}`;
            } else {
              d["estimateWeekDayStr"] = getDayNameHelper(
                parseInt(d["endWeekDay"])
              );
              d["estimateEndDateStr"] = dateStringToDateFormat(
                d["estimateEndDate"],
                {
                  returnThai: true,
                  type: "datetime",
                  showSecond: false,
                }
              );
              d[
                "estimateEndDateStr"
              ] = `${d["estimateWeekDayStr"]} ${d["estimateEndDateStr"]}`;
            }

            d["statusStr"] = ``;

            _processData.push(d);
          }

          this.dateGroupList = _processData;
          // this.selectedDates = [];
        } else {
          this.selectedDates = [];
          throw "API_REQUEST_ERROR";
        }
      } catch (error) {
        console.error(error);
        this.fetchDataError = -1;
      }

      this.dateGroupLoading = false;
      this.datePickerShow = false;
    },
    //
    dateTableRefreshHandler() {
      this.confirmSelectDateDialog();
    },
    //
    removeDateGroup(idx) {
      this.dateGroupList.splice(idx, 1);
      this.selectedDates.splice(idx, 1);
    },
    //
    cancelSelectDateDialog() {
      this.selectedDates = null;
      this.datePickerShow = false;
    },
  },

  destroyed() {
    this.$store.dispatch("jobs/updateJobData", null);
  },
};
</script>
<style scoped></style>
