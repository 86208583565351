<template>
  <v-dialog v-model="show" width="30%" :persistent="true">
    <v-card>
      <v-toolbar color="primary" flat dark>
        <v-card-title class="pl-0"> เลือกรถที่ถูกต้อง </v-card-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!onRequest" icon @click.native="cancelStatus"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4 ma-0" v-if="fetchDataError === 1">
        <v-row v-if="callUpdateOnComponent">
          <v-col>
            <p
              class="text-app-detail app-custom-input font-weight-bold"
              v-if="jobData"
            >
              ชื่องาน: {{ jobData.name }}
            </p>

            <v-divider class="pb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                lg="6"
                class="px-1"
                v-for="(vehicleOptionsData, vehicleOptionIdx) in vehicleOptions"
                :key="`status-${vehicleOptionIdx}`"
              >
                <v-card
                  v-if="vehicleOptionsData !== null"
                  elevation="0"
                  :color="getStatusColor(vehicleOptionsData)"
                  :outlined="getStatusOutline(vehicleOptionsData)"
                  @click="setStatusHanlder(vehicleOptionsData)"
                >
                  <v-card-text>
                    <div :class="`${getStatusClass(vehicleOptionsData)}`">
                      <p class="ma-0 text-app-detail font-weight-bold">
                        {{ vehicleOptionsData.licenseNumber }}
                        {{ vehicleOptionsData.vehicleTypeText }}
                      </p>

                      <span class="mx-0 px-0">
                        <v-icon
                          :dark="!getStatusOutline(vehicleOptionsData)"
                          v-if="vehicleOptionsData.licenseType == 'WHITE'"
                        >
                          mdi-card-text-outline
                        </v-icon>
                        <v-icon
                          v-else
                          :color="vehicleOptionsData.vehicleLicenseTypeColor"
                        >
                          mdi-card-text
                        </v-icon>
                      </span>
                      {{ vehicleOptionsData.vehicleLicenseTypeText }}

                      <span class="mx-0 px-0">
                        <v-icon :dark="!getStatusOutline(vehicleOptionsData)">{{
                          vehicleOptionsData.vehicleTypeIcon
                        }}</v-icon>
                        {{ vehicleOptionsData.vehicleTypeText }}
                      </span>
                      {{ vehicleOptionsData.seat }}
                      ที่นั่ง
                    </div>
                  </v-card-text>
                </v-card>
                <v-card class="fill-height" outlined elevation="0" v-else>
                  <v-card-text class="text-center">
                    <p class="ma-0 text-app-detail font-weight-bold">
                      ไม่มีข้อมูลรถ
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 0">
        {{ fetchDataText }}
      </v-card-text>

      <v-divider v-if="fetchDataError === 1"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="saveStatus"
          :disabled="selectedVehicle._id === null"
          v-if="fetchDataError === 1"
        >
          ยืนยัน
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getJobVerifyVehicle, putJobVerifyVehicle } from "@/services/api/jobs";
import _ from "lodash";

export default {
  name: "VerifyVehicleDialog",
  components: {},
  data() {
    return {
      show: false,
      fetchDataError: 0, // 0 not data, 1 ok -1 error
      vehicleOptions: [],
      selectedVehicle: null,
      jobData: null,
      callUpdateOnComponent: false,
      fetchDataText: "กำลังดึงข้อมูล",
      onRequest: false,
    };
  },
  computed: {},
  methods: {
    resetForm() {
      this.fetchDataError = 0;
    },

    open(jobData, callUpdateOnComponent) {
      this.resetForm();
      this.show = true;
      this.jobData = jobData;
      this.callUpdateOnComponent = callUpdateOnComponent;
      this.selectedVehicle = {
        _id: this.jobData["verifiedVehicleId"] || null,
      };
      this.getDataFromService();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getDataFromService() {
      let queryString = new URLSearchParams({
        id: this.jobData["_id"],
      }).toString();
      this.fetchDataError = 0;
      //
      try {
        const responseData = await getJobVerifyVehicle(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.vehicleOptions = [
            this.processVehicleDisplay(responseData["data"]["selectedVehicle"]),
            this.processVehicleDisplay(responseData["data"]["actualVehicle"]),
          ];
        }
        this.fetchDataError = 1;
      } catch (error) {
        this.fetchDataError = -1;
      }
    },

    getStatusColor(vehicleData) {
      if (!_.isNil(this.selectedVehicle)) {
        if (vehicleData["_id"] === this.selectedVehicle["_id"]) {
          return "success";
        }
      }

      return "";
    },
    getStatusOutline(vehicleData) {
      if (!_.isNil(this.selectedVehicle)) {
        if (vehicleData["_id"] === this.selectedVehicle["_id"]) {
          return false;
        }
      }
      return true;
    },
    getStatusClass(vehicleData) {
      if (!_.isNil(this.selectedVehicle)) {
        if (vehicleData["_id"] === this.selectedVehicle["_id"]) {
          return "white--text";
        }
      }
      return "black--text";
    },
    setStatusHanlder(newData) {
      this.selectedVehicle = newData;
    },
    processVehicleDisplay(vehicleData) {
      // selected vehicle vehicleId
      let returnVehicleData = { ...vehicleData };
      if (_.isEmpty(returnVehicleData)) {
        return null;
      }

      if (!_.isNil(returnVehicleData["licenseType"])) {
        returnVehicleData["vehicleLicenseTypeColor"] = this.$store.getters[
          "vehicle/vehicleLicenseTypeColor"
        ](returnVehicleData["licenseType"]);
        //
        returnVehicleData["vehicleTypeText"] = this.$store.getters[
          "vehicle/vehicleTypeText"
        ](returnVehicleData["type"]);
        //
        returnVehicleData["vehicleTypeIcon"] = this.$store.getters[
          "vehicle/vehicleTypeIcon"
        ](returnVehicleData["type"]);
      }

      if (!_.isNil(returnVehicleData["licenseType"])) {
        returnVehicleData["vehicleLicenseTypeText"] = this.$store.getters[
          "vehicle/vehicleLicenseTypeText"
        ](returnVehicleData["licenseType"]);
      }

      if (!_.isNil(returnVehicleData["status"])) {
        returnVehicleData["statusColor"] = this.$store.getters[
          "vehicle/vehicleStatusColor"
        ](returnVehicleData["status"]);
        //
        returnVehicleData["statusText"] = this.$store.getters[
          "vehicle/vehicleStatusText"
        ](returnVehicleData["status"]);
      }

      return returnVehicleData;
    },

    async saveStatus() {
      this.fetchDataError = 0;
      if (this.callUpdateOnComponent) {
        let queryString = new URLSearchParams({
          id: this.jobData["_id"],
        }).toString();

        this.onRequest = true;
        this.fetchDataText = "กำลังบันทึกข้อมูล...";
        const requestData = {
          verifiedVehicleId: this.selectedVehicle["_id"],
        };
        //
        const requestResponseData = await putJobVerifyVehicle(
          this.$store,
          queryString,
          requestData
        );

        if (requestResponseData["code"] === 200) {
          this.resolve(requestData);
        } else {
          this.resolve(null);
        }
      } else {
        this.resolve(this.selectedVehicle);
      }
      this.show = false;
    },
    cancelStatus() {
      this.resolve(null);
      this.show = false;
    },
    imageLoadError() {
      console.log("Image failed to load");
    },
  },
};
</script>
