<template>
  <div name="customSelects" :class="expendMargin ? 'px-3 pb-6' : ''">
    <InputView
      :title="title"
      :isShowTitle="isShowTitle"
      :isDark="isDark"
      :isRequired="isRequired"
      :bottomMargin="titleBottomMargin"
    >
      <!-- <v-menu
        v-model="open"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            slot="activator"
            prepend-inner-icon="mdi-calendar"
            v-model="dateFormatted"
            :placeholder="placeholder"
            v-on="on"
            dense
            outlined
            :rules="rules"
            :disabled="disabled"
            :readonly="false"
            class="app-custom-input"
            clearable
            @click:clear="valueInput = null"
            @click:prepend-inner="open = true"
            :background-color="getBackgroundColor()"
            :name="dataInputName"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="valueInput"
          no-title
          @input="open = false"
          :min="minDate"
        ></v-date-picker>
      </v-menu> -->
      <v-text-field
        v-model="dateFormatted"
        :placeholder="placeholder"
        outlined
        :dense="isDense"
        hide-details
        :rules="rules"
        :disabled="disabled"
        :readonly="false"
        clearable
        class="app-custom-input"
        @click:clear="valueInput = null"
        @keyup="filterTextReleaseInput"
        @keydown="filterKeyDownInput"
        @keypress="filterTextInput"
        @blur="handleUpdateItem"
        :background-color="getBackgroundColor()"
        :name="dataInputName"
      >
        <template #label>
          <span v-if="label">
            {{ label
            }}<span class="required-color" v-if="isRequired && !disabled">
              *
            </span>
          </span>
        </template>
        <template v-slot:prepend-inner>
          <v-menu
            v-model="open"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-icon :disabled="disabled" v-on="on" class="pa-1"
                >mdi-calendar</v-icon
              >
            </template>
            <v-date-picker
              v-model="valueInput"
              no-title
              @input="open = false"
              :min="minDate"
              locale="th"
            />
          </v-menu>
        </template>
      </v-text-field>
      <span v-if="textFooter" class="text-app-normal">{{ textFooter }}</span>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "CustomDateTypingPickerFormInput",

  components: {
    InputView,
  },

  data: () => ({
    dateFormatted: null,
    dateMin: null,
    open: false,
    // foreverDate: false,
    dateTyping: null,
    isDeleting: false,
    // finalDate: "31/12/3000",
  }),

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "วว/ดด/ปปปป",
    },
    title: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    titleBottomMargin: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },

    textFooter: {
      type: String,
      default: null,
    },

    isMaxToday: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: true,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    minDate: null,
  },

  computed: {
    valueInput: {
      get() {
        this.dateFormatted = this.formatDate(this.value);
        // if (this.dateMin == null) {
        //   this.dateMin = this.value;
        // }
        return this.value;
      },
      set(val) {
        this.dateFormatted = this.formatDate(val);
        this.$emit("input", val);
      },
    },
  },
  watch: {
    // foreverDate(newValue) {
    //   if (newValue) {
    //     this.disabled = true;
    //   } else {
    //     this.disabled = false;
    //   }
    // },
    open(newValue) {
      if (!newValue) {
        this.$emit("handle-update-input", this.valueInput);
      }
    },

    dateFormatted(newValue) {
      if (newValue) {
        this.dateTyping = newValue;
        // console.log("dateTyping", this.dateTyping);
        const [day, month, year] = newValue.split("/");

        if (day && month && year) {
          this.valueInput = this.parseDatePicker(newValue);
        }
      }
    },
  },

  methods: {
    getBackgroundColor() {
      if (this.disabled) {
        if (this.isDark) {
          return this.$vuetify.theme.themes.light.backgroudDarkDisableInputView;
        } else {
          return this.$vuetify.theme.themes.light.backgroudDisableInputView;
        }
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    parseDatePicker(date) {
      if (!date) return null;
      let [day, month, year] = date.split("/");
      let yearNum = parseInt(year);
      let todayDate = new Date();
      // console.log("today", todayDate.getFullYear() - parseInt(year));

      if (this.isMaxToday) {
        if (yearNum >= todayDate.getFullYear()) {
          let allowedAge = 18;
          let generatedYear = todayDate.getFullYear() - allowedAge;
          year = generatedYear.toString();
        }
      }

      if (parseInt(month) > 12) {
        month = "12";
      }

      var d = new Date(parseInt(year), parseInt(month), 0);
      let maxDay = d.getDate();
      if (parseInt(day) > maxDay) {
        day = maxDay.toString();
      }

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      // }
    },
    handleUpdateItem(e) {
      let updatedDate = e.target.value;
      if (!this.open) {
        this.$emit("handle-update-input", this.valueInput);
      }
    },

    filterTextReleaseInput(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString();
      // console.log("dateTyping", this.dateTyping);

      if (this.dateTyping && !this.isDeleting) {
        if (this.dateTyping.length === 2) {
          this.dateFormatted = this.dateTyping.slice(0, 3) + "/";
        } else if (this.dateTyping.length === 5) {
          this.dateFormatted = this.dateTyping.slice(0, 6) + "/";
        }
      }

      return true;
    },

    filterTextInput(evt) {
      evt = evt ? evt : window.event;
      let expect = null;

      if (evt.key !== undefined) {
        expect = evt.target.value.toString() + evt.key.toString();
      } else {
        expect = evt.target.value.toString();
      }
      expect = expect.replace(/\//g, "");

      if (!/^\d+$/.test(expect) || expect.length > 8) {
        evt.preventDefault();
        return;
      }
      return true;
    },
    filterKeyDownInput(evt) {
      evt = evt ? evt : window.event;
      const key = evt.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        this.isDeleting = true;
        return false;
      } else {
        this.isDeleting = false;
      }
    },
  },

  components: { InputView },
};
</script>
<style lang="scss" scoped></style>
