<template>
  <div v-if="cellData !== null" style="height: 100%">
    <div
      v-if="cellData.inputState === 0"
      class="d-flex flex-column justify-center align-center fill-height"
    >
      <AssignMultipleJobDriverTableCellInputState
        :id-name="`${tableId}-input-${cellData.row}-${cellData.col}`"
        :vuex-row-idx="cellData.row"
        :vuex-cols-idx="cellData.col"
        :data-row-idx="loopRowIdx"
        :data-cols-idx="loopColsIdx"
        :table-id="tableId"
        @next-input-handler="nextInputHandler"
      />
    </div>
    <div
      v-if="cellData.inputState === 1"
      style="vertical-align: top; height: 100%"
      :class="[{ 'red lighten-5': cellData.isDriverHasGetJobOpsError }]"
    >
      <AssignMultipleSelectedDrivers
        :id-name="`${tableId}-input-${cellData.row}-${cellData.col}`"
        :vuex-row-idx="cellData.row"
        :vuex-cols-idx="cellData.col"
        :data-row-idx="loopRowIdx"
        :data-cols-idx="loopColsIdx"
        :table-id="tableId"
        @open-manage-dialog="openCellManageDialog"
      />
    </div>

    <div
      v-if="cellData.inputState === -1"
      class="d-flex flex-column justify-center align-center fill-height"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AssignMultipleJobDriverTableCellInputState from "./AssignMultipleJobDriverTableCellInputState.vue";
import AssignMultipleSelectedDrivers from "./AssignMultipleSelectedDrivers.vue";

export default {
  name: "AssignMultipleJobDriverTableCellBox",
  props: {
    tableId: {
      type: String,
      required: true,
    },
    loopRowIdx: {
      type: Number,
      required: true,
    },
    loopColsIdx: {
      type: Number,
      required: true,
    },
    vuexCellRow: {
      type: Number,
      required: true,
    },
    vuexCellCol: {
      type: Number,
      required: true,
    },
  },

  computed: {
    cellData: function () {
      return _.get(
        this.$store.state,
        `assignMultipleJob.cellData.T${this.tableId}R${this.vuexCellRow}C${this.vuexCellCol}`,
        null
      );
      // return {};
    },
  },
  methods: {
    nextInputHandler(data) {
      this.$emit("next-input-func", data);
    },
    openCellManageDialog(data) {
      this.$emit("open-cell-dialog-func", data);
    },
  },
  components: {
    AssignMultipleJobDriverTableCellInputState,
    AssignMultipleSelectedDrivers,
  },
};
</script>

<style scoped></style>
