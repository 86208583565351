<template>
  <v-dialog v-model="show" width="900">
    <v-card>
      <v-toolbar elevation="0" color="primary" dark>
        <p class="mt-4 px-3">
          <v-icon class="mr-2"> mdi-download </v-icon> นำเข้าเส้นทาง
        </p>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              class="app-custom-input"
              v-model="searchValueInput"
              :items="searchItem"
              :loading="searchLoading"
              :disabled="searchLoading"
              :label="searchLoading ? 'กำลังโหลดเส้นทาง' : 'เลือกเส้นทาง'"
              placeholder="ค้นหาโดยชื่อ"
              hide-details
              return-object
              filled
              outlined
              item-value="_id"
              item-text="name"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <div v-if="routeSelect !== null">
          <p class="text-app-title font-weight-medium mt-2 mb-0">คำอธิบาย</p>
          <p class="text-app-normal">{{ routeSelect.desc }}</p>
        </div>

        <p class="text-app-title font-weight-medium mt-3 mb-0">
          รายการจุดวิ่งรถ
        </p>
        <v-simple-table v-if="routeSelect !== null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับ</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทาง</th>
                <th class="text-left">ระยะเวลา</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="marker in routeSelect.route" :key="marker._id">
                <td>{{ marker.order }}</td>
                <td>{{ marker.name }}</td>
                <td>{{ processDistanceText(marker.distanceText) }}</td>
                <td>{{ processDurationText(marker.durationText) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-if="routeSelect === null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับ</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทาง</th>
                <th class="text-left">ระยะเวลา</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" colspan="4">
                  {{ searchInput ? `กำลังโหลดเส้นทาง` : `เลือกเส้นทาง` }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
        <v-row v-if="routeSelect">
          <!-- <v-col>
            <v-radio-group v-model="formData.replaceIncome">
              <template v-slot:label> ต้องการบันทึกรายรับ: </template>
              <v-radio label="ระบุเองภายหลัง" value="INCOMET1"></v-radio>
              <v-radio
                v-if="incomeAndRecompenseData.contract.incomePerRound"
                :label="`จากสัญญา (${incomeAndRecompenseData.contract.incomePerRound.toLocaleString()} บาท)`"
                value="INCOMET2"
              ></v-radio>
              <v-radio
                v-if="incomeAndRecompenseData.route.incomePerRound"
                :label="`จากเส้นทางหลักนี้ (${incomeAndRecompenseData.route.incomePerRound.toLocaleString()} บาท)`"
                value="INCOMET3"
              ></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col>
            <v-radio-group v-model="formData.replaceRecompense">
              <template v-slot:label>
                ต้องการบันทึกค่าตอบแทนผู้ขับต่อเที่ยว:
              </template>
              <v-radio label="ระบุเองภายหลัง" value="RECOMPT1"></v-radio>
              <v-radio
                v-if="incomeAndRecompenseData.contract.recompenseAmount"
                :label="`จากสัญญา (${incomeAndRecompenseData.contract.recompenseAmount.toLocaleString()} บาท)`"
                value="RECOMPT2"
              ></v-radio>
              <v-radio
                v-if="incomeAndRecompenseData.route.recompenseAmount"
                :label="`จากเส้นทางหลักนี้ (${incomeAndRecompenseData.route.recompenseAmount.toLocaleString()} บาท)`"
                value="RECOMPT3"
              ></v-radio>
              <v-radio
                v-if="incomeAndRecompenseData.specialDriver"
                :label="`เป็นผู้ขับพิเศษ (${incomeAndRecompenseData.specialDriver.recompenseAmount.toLocaleString()} บาท)`"
                value="RECOMPT4"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-1"
          color="success"
          @click="save"
          :disabled="routeSelect === null"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppDialogConfirm ref="importRouteConfirmDialog" />
  </v-dialog>
</template>
<script>
import _ from "lodash";
import {
  getJobMarkerDetailTemplateImportService,
  getJobMarkerTemplateImportService,
} from "@/services/api/jobs";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import {
  processDurationText,
  processDistanceText,
} from "@/services/appFuncHelpper";
export default {
  name: "ImportRouteDialog",
  props: ["dialogStatus"],
  mounted() {
    if (!_.isNil(this.$route.params.id)) {
      this.jobId = this.$route.params.id;
    }
  },
  data() {
    return {
      //
      searchValueInput: null,
      routeSelect: null, // from detail route
      searchItem: [],
      searchLoading: true,
      searchInput: false,
      //
      valid: null,
      show: false,
      jobId: null,
      // form data
      incomeAndRecompenseData: null,
      formData: {
        replaceIncome: null,
        replaceRecompense: null,
      },
      //
      resolve: null,
      reject: null,
    };
  },
  watch: {
    searchValueInput: {
      immediate: false,
      handler(newValue) {
        if (!_.isNil(newValue)) {
          this.getRouteDetail();
        }
      },
    },
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    async open() {
      this.show = true;
      this.resetForm();
      this.getDataList();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(null);
      this.show = false;
    },
    resetForm() {
      this.searchValueInput = null;
      this.routeSelect = null;
      this.searchItem = [];
    },
    async getDataList() {
      this.searchLoading = true;
      try {
        let queryString = new URLSearchParams({ id: this.jobId }).toString();
        let responseData = await getJobMarkerTemplateImportService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.searchItem = responseData["data"];
        } else {
          throw "API CALL ERROR";
        }
      } catch (error) {
        console.log("error getDataList:", error);
      }
      this.searchLoading = false;
    },
    //
    selectToDriverOptions() {
      const _driverRecompense = _.get(
        this.incomeAndRecompenseData,
        "specialDriver.recompenseAmount"
      );
      if (!_.isNil(_driverRecompense)) {
        this.formData.replaceRecompense = "RECOMPT4";
      } else {
        this.formData.replaceRecompense = "RECOMPT1";
      }

      //
      this.formData.replaceIncome = "INCOMET1";
    },
    async getRouteDetail() {
      this.searchInput = true;
      this.routeSelect = null;
      try {
        let queryStringParams = {
          id: this.searchValueInput["_id"],
        };

        if (!_.isNil(this.$store.state.manageRoute.jobData.driverId)) {
          queryStringParams["driverId"] =
            this.$store.state.manageRoute.jobData.driverId["_id"];
        }

        let queryString = new URLSearchParams(queryStringParams).toString();
        const responseData = await getJobMarkerDetailTemplateImportService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.routeSelect = responseData["data"];
          this.incomeAndRecompenseData = _.get(
            responseData,
            "data.incomeAndRecomponse",
            null
          );
          this.selectToDriverOptions();
        } else {
          throw "API_CALL_ERROR";
        }
      } catch (error) {
        console.log("error getRouteList:", error);
      }
      this.searchInput = false;
    },

    getReplaceAmount(key) {
      switch (key) {
        case "INCOMET2":
          return _.get(
            this.incomeAndRecompenseData,
            "contract.incomePerRound",
            null
          );
        case "RECOMPT2":
          return _.get(
            this.incomeAndRecompenseData,
            "contract.recompenseAmount",
            null
          );
        case "INCOMET3":
          return _.get(
            this.incomeAndRecompenseData,
            "route.incomePerRound",
            null
          );
        case "RECOMPT3":
          return _.get(
            this.incomeAndRecompenseData,
            "route.recompenseAmount",
            null
          );
        case "RECOMPT4":
          return _.get(
            this.incomeAndRecompenseData,
            "specialDriver.recompenseAmount",
            null
          );
        default:
          return null;
      }
    },

    async save() {
      const incomeAndRecompenseData = {
        incomePerRound: null,
        recompenseAmount: this.getReplaceAmount(
          this.formData.replaceRecompense
        ),
      };
      if (
        await this.$refs.importRouteConfirmDialog.open(
          "ต้องนำเข้าข้อมูลเส้นทางที่เลือก ?",
          `เส้นทางที่สร้างปัจจุบันจะถูกทับทั้งหมด`,
          DialogType.ACTION,
          {
            width: 400,
            zIndex: 200,
            noconfirm: true,
          }
        )
      ) {
        this.resolve({
          templateId: this.searchValueInput["_id"],
          disableMarker: true,
          ...incomeAndRecompenseData,
        });
        this.show = false;
      }
    },
  },
  components: { AppDialogConfirm },
};
</script>
