<template>
  <v-dialog persistent v-model="show" width="900">
    <v-card v-if="show">
      <v-toolbar elevation="0" color="primary" dark>
        <p class="mt-4 px-3">
          <v-icon class="mr-1"> mdi-sort-variant </v-icon> สลับตำแหน่ง
        </p>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="px-4 py-2">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ลำดับก่อนเดิม</th>
                <th class="text-left">ลำดับใหม่</th>
                <th class="text-left">ชื่อตำแหน่ง</th>
                <th class="text-left">ระยะทางจากจุดลำดับก่อนหน้า (กิโลเมตร)</th>
                <th class="text-left">ระยะเวลาจากจุดลำดับก่อนหน้า (นาที)</th>
                <th class="text-left"></th>
              </tr>
            </thead>

            <draggable
              tag="tbody"
              v-model="list"
              :disabled="!enabled"
              ghost-class="ghost"
              @start="drag = true"
              @end="drag = false"
              @change="changeMove"
              handle=".handle"
            >
              <tr v-for="(item, idx) in list" :key="idx">
                <td width="10%">{{ item.orderText }}</td>
                <td width="10%">{{ idx + 1 }}</td>
                <td width="40%">{{ item.name }}</td>
                <td>{{ processDistanceText(item.distanceText) }}</td>
                <td>{{ processDurationText(item.durationText) }}</td>
                <td width="10%">
                  <v-icon class="handle"> mdi-reorder-horizontal </v-icon>
                </td>
              </tr>
              <!-- :move="checkMove" -->
            </draggable>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-alert class="ma-0" color="blue" dense text v-if="showTextLoading">
          กำลังคำนวณระยะทางและเวลา...
        </v-alert>
        <v-alert class="ma-0" color="red" dense text v-if="falseLoadDirection">
          ไม่สามารถคำนวณระยะและเวลาได้
          โปรดตรวจสอบการเชื่อมต่ออินเตอร์เน็ตอีกครั้ง
        </v-alert>
        <v-spacer></v-spacer>

        <v-btn
          class="ma-1"
          color="success"
          :disabled="disabledSave"
          @click="save"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
      <v-overlay :absolute="true" :value="dialogLoading" :opacity="0.3">
        <v-progress-circular indeterminate size="64"></v-progress-circular
      ></v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import moment from "moment";

import {
  getMapDirection,
  getNewGoogleMapObj,
  convertLocationToGObject,
  getDurationFromResponseDataMapService,
  getDistanceFromResponseDataMapService,
} from "../../googleMapComponents/GoogleMapService";

import {
  processDurationText,
  processDistanceText,
  processDurationObject,
  processDistanceObject,
  processMetertoKmDistanceCeil,
  processSecondtoMinuteDurationCeil,
  processKmtoMeterDistance,
  processMinuteToSecondDuration,
} from "@/services/appFuncHelpper";

export default {
  name: "SortMarkerDialog",
  props: ["dialogStatus"],
  data() {
    return {
      enabled: true,
      list: [],
      dragging: false,
      show: false,
      showTextLoading: false,
      setTimeOutVar: null,
      googleMapObject: null,
      dialogLoading: true,
      storeName: "manageRoute",

      falseLoadDirection: false,
      disabledSave: true,
      jobStartPosixDate: null,
      forceDateToNow: false,
    };
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    async open(markerList, storeName) {
      this.show = true;
      this.storeName = storeName;
      this.list = _.cloneDeep(markerList);
      this.prepareData();
      this.googleMapObject = await getNewGoogleMapObj();
      this.dialogLoading = false;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(null);
      this.show = false;
    },
    save() {
      for (let i = 0; i < this.list.length; i++) {
        if (!_.isNil(this.list[i]["distance"])) {
          this.list[i]["distance"] = processKmtoMeterDistance(
            this.list[i]["distance"]
          );
          this.list[i]["distanceText"] = processDistanceObject(
            this.list[i]["distance"]
          );
        }
        //
        if (!_.isNil(this.list[i]["duration"])) {
          this.list[i]["duration"] = processMinuteToSecondDuration(
            this.list[i]["duration"]
          );
          this.list[i]["durationText"] = processDurationObject(
            this.list[i]["duration"]
          );
        }
      }

      this.resolve(this.list);
      this.show = false;
    },
    prepareData() {
      // get job startdate from vuex store
      this.jobStartPosixDate = null;
      //
      for (let i = 0; i < this.list.length; i++) {
        this.list[i]["gDirection"] = null;
        this.list[i]["edited"] = false;
        if (i === 0) {
          // keep Arrival time of firstMarker
          if (
            !_.isNil(
              this.list[i]["gDirectionOption"]["drivingOptions"][
                "departureTime"
              ]
            )
          ) {
            // have the data
            this.jobStartPosixDate = moment(
              this.list[i]["gDirectionOption"]["drivingOptions"][
                "departureTime"
              ]
            );
          } else {
            // job startdate
            if (this.storeName === "manageRoute") {
              this.jobStartPosixDate = moment(
                this.$store.state.manageRoute.jobData.startDatePosix
              );
            } else {
              this.forceDateToNow = true;
            }
          }
        } else {
          this.list[i]["distanceText"] = processDistanceObject(
            this.list[i]["distance"]
          );
          this.list[i]["distance"] = processMetertoKmDistanceCeil(
            this.list[i]["distance"]
          );

          this.list[i]["durationText"] = processDurationObject(
            this.list[i]["duration"]
          );
          this.list[i]["duration"] = processSecondtoMinuteDurationCeil(
            this.list[i]["duration"]
          );
        }
      }
    },
    changeMove: function (e) {
      this.showTextLoading = true;
      this.getNewDirection();
    },
    //
    setDepartureOption(idx, departureList) {
      let nowDate = moment();
      // jobStart is posix date
      let indexDate = this.jobStartPosixDate;
      //
      if (this.forceDateToNow) {
        return moment()
          .add(60 * 10, "s", true)
          .toDate();
      }

      // only one row
      if (departureList.length !== 0) {
        if (nowDate.valueOf() > indexDate.valueOf()) {
          // return if job startdate is less than today
          return moment()
            .add(60 * 10, "s", true)
            .toDate();
        }
        // get last of departureList
        let getDepartureTime = indexDate;
        if (idx !== 1) {
          getDepartureTime = departureList[departureList.length - 1];
        }
        indexDate = moment(getDepartureTime);
        // go next (2)
      } else {
        return null;
      }

      // (2) process getDepartureTime
      if (nowDate.valueOf() < indexDate.utc().valueOf()) {
        if (idx === 1) {
          //  departure of marker 2 is arrival from marker 1;
          return indexDate.toDate();
        } else {
          //  from marker 3 more is calculate from before duration
          // ** it's estimate
          let estimateArrialTimeForNextMarkerDataDate = indexDate.add(
            this.list[idx - 1]["estimateDuration"],
            "s",
            true
          );

          return estimateArrialTimeForNextMarkerDataDate.toDate();
        }
      } else {
        return moment()
          .add(60 * 10, "s", true)
          .toDate();
      }
    },

    // get detail from response
    async getNewDirection() {
      this.enabled = false;
      let departureTimeOptionList = [];
      for (let i = 0; i < this.list.length; i++) {
        let getReturn = this.setDepartureOption(i, departureTimeOptionList);
        departureTimeOptionList.push(getReturn);

        if (this.falseLoadDirection === false) {
          if (i === 0 && this.list[i]["parentMarkerId"] !== null) {
            // change to first index marker
            this.list[i]["gDirection"] = null;
            this.list[i]["distance"] = null;
            this.list[i]["duration"] = null;
            this.list[i]["distanceText"] = null;
            this.list[i]["durationText"] = null;
            this.list[i]["parentMarkerId"] = null;
            this.list[i]["gDirectionOption"]["drivingOptions"][
              "departureTime"
            ] = departureTimeOptionList[i];
            this.list[i]["edited"] = true;
          } else if (
            i !== 0 &&
            this.list[i - 1]["_id"] !== this.list[i]["parentMarkerId"]
          ) {
            // update parent marker
            let gDirectionOptionProcessData = _.cloneDeep(
              this.list[i]["gDirectionOption"]
            );
            gDirectionOptionProcessData["drivingOptions"]["departureTime"] =
              departureTimeOptionList[i];
            this.list[i]["gDirectionOption"] = gDirectionOptionProcessData;

            // get google Direction
            let _gDirection = await getMapDirection(
              this.googleMapObject,
              convertLocationToGObject(
                this.googleMapObject,
                this.list[i - 1]["coordinates"]
              ),
              convertLocationToGObject(
                this.googleMapObject,
                this.list[i]["coordinates"]
              ),
              this.list[i]["gDirectionOption"]
            );

            //
            if (_gDirection["status"] === "OK") {
              _gDirection = _gDirection["data"]; // pass by reference
              this.list[i]["gDirection"] = _gDirection;

              this.list[i]["estimateDistance"] =
                getDistanceFromResponseDataMapService(_gDirection);
              this.list[i]["distance"] = processMetertoKmDistanceCeil(
                getDistanceFromResponseDataMapService(_gDirection)
              );
              this.list[i]["distanceText"] = processDistanceObject(
                getDistanceFromResponseDataMapService(_gDirection)
              );

              this.list[i]["estimateDuration"] =
                getDurationFromResponseDataMapService(_gDirection);
              this.list[i]["duration"] = processSecondtoMinuteDurationCeil(
                getDurationFromResponseDataMapService(_gDirection)
              );
              this.list[i]["durationText"] = processDurationObject(
                processMinuteToSecondDuration(this.list[i]["duration"])
              );

              this.list[i]["parentMarkerId"] = this.list[i - 1]["_id"];
              this.list[i]["edited"] = true;
            } else {
              // fail to download
              this.falseLoadDirection = true;
            }
          }
        }
      }

      // console.log("finally check", departureTimeOptionList);

      if (this.falseLoadDirection === true) {
        this.disabledSave = true;
        this.showTextLoading = false;
        this.enabled = false;
      } else {
        this.disabledSave = false;
        this.showTextLoading = false;
        this.enabled = true;
      }
    },
  },
  components: {
    draggable,
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.handle:hover {
  cursor: pointer;
  color: #2196f3;
}
</style>
