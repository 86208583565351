<template>
  <v-container fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการสรุปข้อมูลพฤติกรรมพนักงาน</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteUserSearching
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="AppAutoCompleteUserSearchingRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>

            <PenaltyFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
        <!-- <v-col cols="auto" v-if="permList.user_info_crud || permList.admin_all">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col> -->
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="employeeDataList"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="สรุปผลประจำปี"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>
            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <!-- <template v-slot:[`item.createdDate`]="{ item }">
          <div>
            {{
              dateStringToDateFormat(item.createdDate, {
                returnThai: false,
                type: "datetime",
              }) || "-"
            }}
          </div>
        </template> -->

        <template v-slot:[`item.name`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-avatar>
              <v-img
                :src="
                  item.profileImage === null
                    ? defaultPFImage
                    : item.profileImage
                "
              >
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-app-title font-weight-bold">
                <a
                  v-if="isPermissionCanEdit"
                  class="text-decoration-none"
                  @click="editPenaltyAction(item)"
                >
                  {{ item.dpfullName }}
                </a>
                <span v-else> {{ item.dpfullName }}</span>
              </v-list-item-title>
              <span class="text-app-normal">{{
                item.email === null ? "-" : `${item.email}`
              }}</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.empId`]="{ item }">
          <span class="text-app-title">
            {{ item.empId ? item.empId : "-" }}
          </span>
        </template>

        <template v-slot:[`item.workingPenalityScore`]="{ item }">
          <!-- <div class="font-weight-bold error--text">
            {{ item.score || "-" }}
          </div> -->
          <div
            v-if="
              item.penaltyReport.stats[0].score !== null &&
              item.penaltyReport.stats[0].score !== undefined
            "
          >
            <div class="text-app-detail-normal">
              {{ item.penaltyReport.stats[0].counter }} ครั้ง
            </div>
            <!-- <div class="font-weight-bold">
              -{{ item.penaltyReport.stats[0].score }} คะแนน
            </div> -->
            <div>
              <span class="ma-0 text-app-detail-normal"> คะแนนคงเหลือ</span>
              <span
                :class="`ma-0 ${getPercentCategColor(
                  item.penaltyReport.stats[0].maxScore -
                    item.penaltyReport.stats[0].score
                )}--text font-weight-black`"
              >
                <span
                  v-if="
                    item.penaltyReport.stats[0].maxScore -
                      item.penaltyReport.stats[0].score !==
                    parseInt(
                      item.penaltyReport.stats[0].maxScore -
                        item.penaltyReport.stats[0].score
                    )
                  "
                >
                  {{
                    (
                      item.penaltyReport.stats[0].maxScore -
                      item.penaltyReport.stats[0].score
                    ).toFixed(2)
                  }}
                </span>
                <span v-else>{{
                  item.penaltyReport.stats[0].maxScore -
                  item.penaltyReport.stats[0].score
                }}</span>
              </span>
            </div>
          </div>
          <span v-else> - </span>
        </template>

        <template v-slot:[`item.securityPenalityScore`]="{ item }">
          <!-- <div class="font-weight-bold error--text">
            {{ item.score || "-" }}
          </div> -->
          <div
            v-if="
              item.penaltyReport.stats[1].score !== null &&
              item.penaltyReport.stats[1].score !== undefined
            "
          >
            <div class="text-app-detail-normal">
              {{ item.penaltyReport.stats[1].counter }} ครั้ง
            </div>
            <!-- <div class="font-weight-bold">
              -{{ item.penaltyReport.stats[1].score }} คะแนน
            </div> -->
            <div>
              <span class="ma-0 text-app-detail-normal"> คะแนนคงเหลือ</span>
              <span
                :class="`ma-0 ${getPercentCategColor(
                  item.penaltyReport.stats[1].maxScore -
                    item.penaltyReport.stats[1].score
                )}--text font-weight-black`"
              >
                <span
                  v-if="
                    item.penaltyReport.stats[1].maxScore -
                      item.penaltyReport.stats[1].score !==
                    parseInt(
                      item.penaltyReport.stats[1].maxScore -
                        item.penaltyReport.stats[1].score
                    )
                  "
                >
                  {{
                    (
                      item.penaltyReport.stats[1].maxScore -
                      item.penaltyReport.stats[1].score
                    ).toFixed(2)
                  }}
                </span>
                <span v-else>
                  {{
                    item.penaltyReport.stats[1].maxScore -
                    item.penaltyReport.stats[1].score
                  }}
                </span>
              </span>
            </div>
          </div>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.hrPenalityScore`]="{ item }">
          <!-- <div class="font-weight-bold error--text">
            {{ item.score || "-" }}
          </div> -->
          <div
            v-if="
              item.penaltyReport.stats[2].score !== null &&
              item.penaltyReport.stats[2].score !== undefined
            "
          >
            <div class="text-app-detail-normal">
              {{ item.penaltyReport.stats[2].counter }} ครั้ง
            </div>
            <!-- <div class="font-weight-bold">
              -{{ item.penaltyReport.stats[2].score }} คะแนน
            </div> -->
            <div>
              <span class="ma-0 text-app-detail-normal"> คะแนนคงเหลือ</span>
              <span
                :class="`ma-0 ${getPercentCategColor(
                  item.penaltyReport.stats[2].maxScore -
                    item.penaltyReport.stats[2].score
                )}--text font-weight-black`"
              >
                <span
                  v-if="
                    item.penaltyReport.stats[2].maxScore -
                      item.penaltyReport.stats[2].score !==
                    parseInt(
                      item.penaltyReport.stats[2].maxScore -
                        item.penaltyReport.stats[2].score
                    )
                  "
                >
                  {{
                    (
                      item.penaltyReport.stats[2].maxScore -
                      item.penaltyReport.stats[2].score
                    ).toFixed(2)
                  }}
                </span>
                <span v-else>
                  {{
                    item.penaltyReport.stats[2].maxScore -
                    item.penaltyReport.stats[2].score
                  }}
                </span>
              </span>
            </div>
          </div>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.totalPenalityScore`]="{ item }">
          <!-- <div class="font-weight-bold error--text">
            {{ item.score || "-" }}
          </div> -->
          <v-row>
            <v-divider vertical class="mr-3" />
            <div
              v-if="
                item.penaltyReport.sumScore !== null &&
                item.penaltyReport.sumScore !== undefined
              "
            >
              <div class="text-app-md">
                {{ getTotalCounter(item.penaltyReport.stats) }} ครั้ง
              </div>
              <!-- <div class="font-weight-bold text-app-md">
                -{{ item.penaltyReport.sumScore }} คะแนน
              </div> -->
              <div>
                <span class="ma-0 text-app-detail-normal"> คะแนนคงเหลือ</span>
                <span
                  v-if="item !== null"
                  :class="`ma-0 ${getPercentCategColor(
                    totalScore - item.penaltyReport.sumScore
                  )}--text text-app-detail-large font-weight-black`"
                >
                  <span
                    v-if="
                      totalScore - item.penaltyReport.sumScore !==
                      parseInt(totalScore - item.penaltyReport.sumScore)
                    "
                  >
                    {{ (totalScore - item.penaltyReport.sumScore).toFixed(2) }}
                  </span>
                  <span v-else>
                    {{ totalScore - item.penaltyReport.sumScore }}
                  </span>
                </span>
              </div>
            </div>
            <span v-else> - </span>
          </v-row>
        </template>
        <!-- <template v-slot:[`item.penaltyTopic`]="{ item }">
          <div v-if="item.topic !== null && item.topic !== undefined">
            <div>{{ item.topicEn }}</div>
            <div class="text-decoration-underline">{{ item.topicTh }}</div>
          </div>
          <span v-else> - </span>
        </template> -->

        <template v-slot:[`item.actions`]="{ item }" v-if="isPermissionCanEdit">
          <!-- <v-btn @click="editTimeOffAction(item)" icon
            ><v-icon> mdi-pencil</v-icon>
          </v-btn> -->
          <v-btn @click="editPenaltyAction(item)" icon
            ><v-icon> mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <EmployeePenaltyOverviewDetailDialog ref="penaltyOverviewDialog" />
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";
import PenaltyFilterGroup from "@/views/employee/components/PenaltyFilterGroup.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  getEmployeePenaltyListService,
  postEmployeePenaltyService,
  putEmployeePenaltyService,
  getPenaltyReportService,
  getEmployeeListService,
} from "@/services/api/employee";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import EmployeePenaltyOverviewDetailDialog from "../components/EmployeePenaltyOverviewDetailDialog";
import { DialogType } from "@/services/dialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import TimeOffFilterStatus from "@/views/employee/components/TimeOffFilterStatus.vue";
import {
  getEmployeeSelectList,
  getPenaltyTitleSelectList,
} from "@/services/api/employee";

export default {
  name: "EmployeePenaltyOverviewListView",
  components: {
    AppDialogConfirm,
    PrimaryButton,
    NormalButton,
    SortButton,
    PenaltyFilterGroup,
    AppAutoCompleteUserSearching,
    AppPageNotFound,
    TimeOffFilterStatus,
    EmployeePenaltyOverviewDetailDialog,
  },
  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    sortList: [
      {
        text: "2023",
        value: "2023",
      },
    ],
    // sortList: [
    //   {
    //     text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
    //     value: "createddate-asc",
    //   },
    //   {
    //     text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
    //     value: "createddate-desc",
    //   },
    //   {
    //     text: "ชื่อ-นามสกุล เรียงจาก A-Z",
    //     value: "name-asc",
    //   },
    //   {
    //     text: "ชื่อ-นามสกุล เรียงจาก Z-A",
    //     value: "name-desc",
    //   },
    //   {
    //     text: "รหัสพนักงาน เรียงจาก A-Z",
    //     value: "empid-asc",
    //   },
    //   {
    //     text: "รหัสพนักงาน เรียงจาก Z-A",
    //     value: "empid-desc",
    //   },
    // ],
    defaultPFImage: defaultImage,
    tableLoading: false,

    totalScore: 100,
    categoryTotalScore: 100,
    serverItemsLength: 0,
    employeeDataList: [],
    titleList: [],
    empList: [],
    queryFilterList: {},
    queryStringSearch: "", // combin filter and search text
    querySearchAndFilterList: {}, // combine filter and search
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    sortVal: null,
    isShowMenu: false,
    initYear: 2023,
    //
    autoCompleteLoading: false,
    autoCompleteList: [],
    searchEmployeeList: null,
    searchCountTimer: undefined,
    //
    permList: {
      admin_all: false,
      user_list: false,
      user_info: false,
      user_info_fin: false,
      user_info_crud: false,
    },
    editTextBtn: "แก้ไขข้อมูล",
    rejectTextBtn: "ปฏิเสธ",
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    //
  }),

  mounted() {
    this.getFundData();
    this.checkUserPermissions();
    this.processRouterQuery(this.$route.query);
    // this.startPage();
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      // this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.sortVal = `${this.queryOptionsTable["year"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    // async handleAddClick() {
    //   // this.$router.push("/employee/penality/create");
    //   let fundObj = {
    //     id: null,
    //     titleList: this.titleList,
    //   };
    //   const newPenalty = await this.$refs.penaltyOverviewDialog.open(fundObj);
    //   if (!_.isNil(newPenalty)) {
    //     let respData = await postEmployeePenaltyService(
    //       this.$store,
    //       newPenalty
    //     );
    //     //
    //     if (respData["code"] === 200) {
    //       await this.getData();
    //     } else {
    //       // insert fail
    //     }
    //   }
    // },
    handleEditClick(_id) {
      this.$router.push(`/employee/penality/edit/${_id}`);
    },

    async editPenaltyAction(employee) {
      await this.$refs.penaltyOverviewDialog.open(employee);
    },

    async rejectPenaltyAction(employee) {},

    // === end action button of table
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          // let _fieldSortBy = value.split("-");
          // this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          // this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["year"] = parseInt(value);
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },

    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };

      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;

      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["empid"]; // id
      delete this.querySearchAndFilterList["text"]; // name
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["empId"]) {
          // is true => select at
          _queryParameter["empid"] = _searchingParameter.key;
        } else if (
          !_searchingParameter["empId"] &&
          !_.isNil(_searchingParameter.key)
        ) {
          if (_searchingParameter.key.length > 0) {
            // not empty
            _queryParameter["text"] = _searchingParameter.key;
          }
        } else if (!_.isNil(_searchingParameter["value"])) {
          _queryParameter["text"] = _searchingParameter["value"];
        }
      }
      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getPercentCategColor(percent) {
      if (percent < 50) {
        return "error";
      } else if (percent < this.categoryTotalScore) {
        return "warning";
      }
      return "success";
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    //
    //  end method scope
    //
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
        year: this.currentYear,
      };
      // this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.sortVal = `${this.queryOptionsTable["year"]}`;
      this.querySearchAndFilterList = {};
      // this.querySearchAndFilterList = { status: "all" };
      this.processSearchingParams();
      this.getData();
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.user_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.user_penality === true) {
          this.isPermissionCanView = true;
          // this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.user_penality === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },
    async getFundData() {
      let respPenaltyTitleList = await getPenaltyTitleSelectList(this.$store);
      if (respPenaltyTitleList["code"] === 200) {
        this.titleList = respPenaltyTitleList["data"];
      }

      let today = new Date();
      this.currentYear = today.getFullYear();
      if (this.currentYear > this.initYear) {
        let newYear = null;
        for (let y = this.initYear + 1; y <= this.currentYear; y++) {
          newYear = { text: y.toString(), value: y.toString() };
          this.sortList.unshift(newYear);
        }
      }
      this.sortVal = this.currentYear.toString();

      // let respEmpList = await getEmployeeSelectList(this.$store);
      // if (respEmpList["code"] === 200) {
      //   this.empList = respEmpList["data"];
      // }
    },

    async getData() {
      this.tableLoading = true;
      this.employeeDataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseEmpData = await getEmployeeListService(
        this.$store,
        this.queryStringSearch
      );
      let idList = responseEmpData["data"]["userList"].map((a) => a._id);
      let idListString = idList.join();

      let queryPayload = {
        year: parseInt(this.querySearchAndFilterList["year"]),
        userId: idListString,
      };
      let queryStringReport = new URLSearchParams(queryPayload).toString();

      let responseReportData = await getPenaltyReportService(
        this.$store,
        queryStringReport
      );

      if (responseReportData.code == 200 && responseEmpData.code == 200) {
        //
        this.serverItemsLength = responseEmpData.data["total"];
        //
        let userList = responseEmpData.data.userList.map((e) => ({
          ...e,
          penaltyReport: {
            ...responseReportData.data[e._id],
            sumCounter: this.getTotalCounter(
              responseReportData.data[e._id]["stats"]
            ),
          },
        }));

        userList.forEach((item, idx) => {
          userList[idx]["dpfullName"] = `${
            userList[idx].salutation === null ? "" : userList[idx].salutation.th
          }${
            userList[idx].firstName === null ? "" : userList[idx].firstName.th
          } ${
            userList[idx].lastName === null ? "" : userList[idx].lastName.th
          } ${
            item.nickName !== null
              ? item.nickName.th !== null
                ? `(${item.nickName.th})`
                : "-"
              : "-"
          }`;
        });

        this.employeeDataList = userList;
      } else {
        this.employeeDataList = [];
      }
      this.tableLoading = false;
    },

    getTotalCounter(stats) {
      if (!_.isNil(stats)) {
        let sumCounter = 0;
        for (let st in stats) {
          sumCounter += stats[st].counter;
        }

        return sumCounter;
      }
      return 0;
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "employee/vehicleStatusColor",
      empStatusColor: "employee/empStatusColor",
      vehicleStatusText: "employee/vehicleStatusText",
      empStatusText: "employee/empStatusText",
      empPenaltyStatusColor: "employee/empPenaltyStatusColor",
      empPenaltyStatusTextColor: "employee/empPenaltyStatusTextColor",
      empPenaltyStatusText: "employee/empPenaltyStatusText",
    }),
    tableHeaders() {
      let defaultdata = [
        // {
        //   text: "วันที่สร้าง",
        //   align: "start",
        //   sortable: false,
        //   value: "createdDate",
        //   width: "15%",
        // },
        {
          text: "ชื่อ-นามสกุล ",
          align: "start",
          sortable: false,
          value: "name",
          width: "20%",
        },
        {
          text: "รหัสพนักงาน",
          value: "empId",
          sortable: false,
          align: "left",
          width: "15%",
        },

        // {
        //   text: "หัวข้อที่โดนหัก",
        //   align: "start",
        //   sortable: false,
        //   value: "penaltyTopic",
        //   // width: "20%",
        // },
        {
          text: "การปฏิบัติงาน",
          value: "workingPenalityScore",
          sortable: false,
          align: "left",
          width: "15%",
        },
        {
          text: "ความปลอดภัยและ GPS",
          value: "securityPenalityScore",
          sortable: false,
          align: "left",
          width: "15%",
        },
        {
          text: "ทรัพยากรมนุษย์และผู้ดูแลระบบ",
          value: "hrPenalityScore",
          sortable: false,
          align: "left",
          width: "15%",
        },
        {
          text: "คะแนนคงเหลือทั้งหมด",
          value: "totalPenalityScore",
          sortable: false,
          align: "left",
          width: "15%",
        },
      ];
      //
      // if (this.isPermissionCanEdit) {
      //   defaultdata.push({
      //     text: "",
      //     value: "actions",
      //     sortable: false,
      //     align: "center",
      //   });
      // }
      //
      return defaultdata;
    },
    // currentYear() {
    //   let today = new Date();
    //   return today.getFullYear();
    // },
    currentYear: {
      get() {
        let today = new Date();
        return today.getFullYear();
      },
      set(val) {},
    },
  },
};
</script>
<style scoped></style>
