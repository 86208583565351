<template>
  <v-autocomplete
    class="app-custom-input"
    v-model="searchValueInput"
    :items="searchItem"
    :loading="searchLoading"
    :disabled="searchLoading || disabled"
    :label="searchLoading ? 'กำลังโหลดเส้นทาง' : 'เลือกเส้นทาง'"
    placeholder="ค้นหาโดยชื่อ"
    hide-details
    return-object
    filled
    outlined
    title="เส้นทางหลัก"
    :is-dark="true"
    item-value="_id"
    item-text="name"
    :background-color="getBackgrounddColor()"
    :rules="_rules"
  >
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import { getRouteListByContractIdService } from "@/services/api/assign_multiple_job";
export default {
  name: "AsignMultipleJobRouteSelect",
  props: {
    value: {},
    tableId: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getData();
  },

  data() {
    return {
      // searchValueInput: null,
      routeSelect: null, // from detail route
      searchItem: [],
      searchLoading: true,
      searchInput: false,
    };
  },

  computed: {
    searchValueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ");
        }
        return r;
      },
      set(val) {},
    },
  },

  methods: {
    async getData() {
      const contractId = _.get(
        this.$store.state,
        `assignMultipleJob.tableSheetData.${this.tableId}.contractId`,
        null
      );

      try {
        const queryString = new URLSearchParams({
          id: contractId,
        }).toString();

        const responseData = await getRouteListByContractIdService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.searchItem = responseData["data"];
          this.searchLoading = false;
        } else {
          throw "GET ROUTE FAIL";
        }
      } catch (error) {
        console.error(error);
      }
    },
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        if (this.isDark) {
          return "#ffffff";
        }
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>
