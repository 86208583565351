<script>
import { faCar } from "@fortawesome/free-solid-svg-icons";

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    data: null,
  }),

  methods: {
    processIcon() {
      if (this.marker.vehicleAnchor) {
        const faCarIcon = faCar.icon;
        return {
          path: faCarIcon[4],
          fillOpacity: 1,
          anchor: new google.maps.Point(faCarIcon[0] / 2, faCarIcon[1]),
          labelOrigin: new this.google.maps.Point(faCarIcon[0] / 2, -80),
          fillColor: "#E2802A",
          scale: 0.075,
        };
      } else if (this.marker.processMarkerPoint) {
        return {
          ...this.marker.icon,
          labelOrigin: new this.google.maps.Point(
            this.marker.icon["anchor"][0] / 2,
            this.marker.icon["anchor"][1] / 1.5
          ),
          anchor: new this.google.maps.Point(
            this.marker.icon["anchor"][0] / 2,
            this.marker.icon["anchor"][1]
          ),
        };
      }
      return this.marker.icon;
    },
  },

  mounted() {
    const { Marker } = this.google.maps;
    let _icon = this.processIcon();
    this.data = new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: _icon, //POINT_MARKER_ICON_CONFIG,
      label: this.marker.label || null,
      iconColor: this.marker.iconColor || null,
      animation:
        this.marker.disableAnimation === true
          ? null
          : this.google.maps.Animation.DROP,
    });

    // const infoWindow = new this.google.maps.InfoWindow();
    // this.data.addListener("click", () => {
    //   infoWindow.close();
    //   infoWindow.setContent(this.data.getTitle());
    //   infoWindow.open(this.data.getMap(), this.data);
    // });

    // let myoverlay = new google.maps.OverlayView();
    // myoverlay.draw = function () {
    //   // add an id to the layer that includes all the markers so you can use it in CSS
    //   this.getPanes().markerLayer.id = "markerLayer";
    // };
    // myoverlay.setMap(this.map);
    // console.log(myoverlay);
  },

  render() {},
  beforeDestroy() {
    this.data.setMap(null);
  },
};
</script>
<style scoped>
#markerLayer {
  border: 2px solid red !important;
  width: 85% !important;
  height: 90% !important;
  border-radius: 5px;
}
</style>
