<template>
  <v-container fluid>
    <div class="d-flex flex-column" v-if="sheetData">
      <div class="d-flex mb-2">
        <p class="text-h5 mb-0">ตารางานเดินรถ: {{ sheetData.name }}</p>
        <v-btn
          color="#354253"
          elevation="0"
          outlined
          small
          class="ml-1"
          @click="handleEditClick(sheetData._id)"
        >
          <v-icon> mdi-pencil </v-icon>
          แก้ไขแผนการเดินรถ
        </v-btn>
        <v-btn
          color="#354253"
          elevation="0"
          outlined
          small
          class="ml-1"
          @click="getSummaryTableInfo()"
        >
          <v-icon> mdi-poll </v-icon>
          แสดงสถิติผู้รับงาน
        </v-btn>
        <v-btn
          color="#354253"
          elevation="0"
          outlined
          small
          class="ml-1"
          @click="openDuplicateMultipleTableDialog()"
        >
          <v-icon>mdi-content-copy</v-icon>
          คัดลอกแผนงานเดินรถ
        </v-btn>
      </div>

      <div>
        <p class="text-app-title" v-if="sheetData.contract">
          สัญญาเดินรถ: {{ sheetData.contract.title }} ({{
            sheetData.contract.contractNumber
          }})
        </p>
      </div>
    </div>

    <!--  -->
    <v-row v-for="(tableKey, idx) in tables" :key="`job-table-${idx}`" dense>
      <v-col cols="12">
        <AssignMultipleJobSection
          :sheet-id="sheetId"
          :table-id="tableKey"
          :table-idx="idx"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-btn
          x-large
          color="#354253"
          @click="createNewTable"
          block
          outlined
          v-if="canEdit"
        >
          <v-icon> mdi-plus </v-icon> สร้างตารางใหม่
        </v-btn>
      </v-col>
    </v-row>
    <AppOverlay :isLoading="mainLoading" />
    <AssignMultipleCreateTableDialog ref="assignMultipleCreateTableDialog" />
    <AssignMultipleJobSummaryDialog ref="assignMultipleJobSummaryDialog" />
    <AppPageNotFound :show="canView" />
    <AssignMultipleJobCreateSheetDialog
      ref="assignMultipleJobCreateSheetDialog"
    />
    <AssignMultipleCreateDuplicateTable ref="assignMultipleJobDuplicateTable" />
  </v-container>
</template>

<script>
import { getMultipleJobTableService } from "@/services/api/assign_multiple_job";
import AppOverlay from "@/components/AppOverlay.vue";
import AssignMultipleJobSection from "./AssignMultipleJobComponents/AssignMultipleJobSection.vue";
import AssignMultipleCreateTableDialog from "./AssignMultipleJobComponents/dialog/AssignMultipleCreateTableDialog.vue";
import { getUserAppDataService } from "@/services/api/app";
import { getEmployeeGroupListSelectService } from "@/services/api/employeeGroup";
import { processPermission } from "@/services/permissions";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import AssignMultipleJobSummaryDialog from "@/views/assign_multiple_job/AssignMultipleJobComponents/dialog/AssignMultipleJobSummaryDialog.vue";
import AssignMultipleJobCreateSheetDialog from "./components/AssignMultipleJobCreateSheetDialog.vue";
import AssignMultipleCreateDuplicateTable from "./AssignMultipleJobComponents/dialog/AssignMultipleCreateDuplicateTable.vue";

export default {
  name: "AssignMutipleTasksCreateView",
  mounted() {
    this.checkUserPermissions();
    if (!_.isNil(this.$route.query.id) && this.canView) {
      this.sheetId = this.$route.query.id;
      this.getData();
      this.$store.dispatch("updateDrawerVuex", false);
    } else {
      this.$router.push("/");
    }
  },
  data: () => ({
    sheetId: null,
    sheetData: null,
    permList: {
      admin_all: false,
      jobs_multiple_crud: false,
      jobs_multiple_tracking: false,
    },
  }),
  watch: {
    "$store.state.assignMultipleJob.forceGetData": {
      immediate: false,
      handler(forceUpdateValue) {
        if (forceUpdateValue !== 0) {
          this.setStore();
          this.getData();
        }
      },
    },
  },
  computed: {
    tables() {
      return this.$store.state["assignMultipleJob"]["tableSheet"] || [];
    },
    mainLoading: function () {
      return this.$store.state["assignMultipleJob"]["mainLoading"];
    },
    canEdit: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanEdit"];
    },
    canView: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanView"];
    },
  },
  methods: {
    setStore() {
      this.$store.dispatch("assignMultipleJob/updateEmptyData");
    },
    async createNewTable() {
      const responseData = await this.$refs[
        "assignMultipleCreateTableDialog"
      ].open({ _id: this.sheetId, data: this.sheetData });

      if (responseData !== null) {
        if (responseData["status"] === "OK") {
          this.$store.dispatch(
            "assignMultipleJob/createNewTable",
            responseData
          );
        } else {
          // insert table fail
          console.error("INSERT_NEW_DATA_TABLE_FAIL");
        }
      }
    },

    async getData(firstInit) {
      this.$store.dispatch("assignMultipleJob/updateMainLoading", true);

      const queryString = new URLSearchParams({
        id: this.sheetId,
      }).toString();
      //
      try {
        //
        this.getAppData();
        //
        const responseData = await getMultipleJobTableService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.sheetData = responseData["data"]["sheet"];

          const tableData = responseData["data"]["table"];
          for (let i = 0; i < tableData.length; i++) {
            this.$store.dispatch(
              "assignMultipleJob/createNewTable",
              tableData[i]
            );

            this.$store.dispatch(
              "assignMultipleJob/createColsandRowToTable",
              tableData[i]
            );
          }
        } else {
          // TODO:: ERROR LOG
          console.error("get data fail");
        }
        //
        this.$store.dispatch("assignMultipleJob/updateMainLoading", false);
      } catch (error) {
        // TODO:: ERROR LOG
        console.log(error);
      }
    },
    async getAppData() {
      const responseZoneData = await getUserAppDataService(this.$store);
      if (responseZoneData["code"] === 200) {
        if (!_.isNil(responseZoneData["data"]["zone"])) {
          this.$store.commit(
            "assignMultipleJob/setZoneList",
            responseZoneData["data"]["zone"]
          );
          // this.zoneList = this.appCompanyData["zone"];
        }
      } else {
        console.error("FAIL_TO_GET_ZONE");
      }

      const responseDriverGroupsData = await getEmployeeGroupListSelectService(
        this.$store
      );
      if (responseDriverGroupsData["code"] === 200) {
        if (!_.isNil(responseDriverGroupsData["data"])) {
          this.$store.commit(
            "assignMultipleJob/setDriverGroupsList",
            responseDriverGroupsData["data"]
          );
          // this.zoneList = this.appCompanyData;
        }
      } else {
        console.error("FAIL_TO_GET_USER_GROUPS");
      }
    },

    async getSummaryTableInfo() {
      if (!this.canEdit) {
        return;
      }

      const toUpdateData = await this.$refs[
        "assignMultipleJobSummaryDialog"
      ].open();
    },

    async openDuplicateMultipleTableDialog() {
      if (!this.canEdit) {
        return;
      }

      const toUpdateData = await this.$refs[
        "assignMultipleJobDuplicateTable"
      ].open(this.sheetId);

      if (toUpdateData !== null) {
        if (toUpdateData["status"] === "!OK") {
          return;
        }

        const routeData = this.$router.resolve({
          name: "multipleJobCreate",
          query: { id: toUpdateData["sheetId"] },
        });
        window.open(routeData.href, "_blank");
      }
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");
        this.$store.commit("assignMultipleJob/setIsPermissionCanEdit", false);
        this.$store.commit("assignMultipleJob/setIsPermissionCanView", false);
        if (this.permList.admin_all === true) {
          this.$store.commit("assignMultipleJob/setIsPermissionCanEdit", true);
          this.$store.commit("assignMultipleJob/setIsPermissionCanView", true);
          return;
        }

        if (this.permList.jobs_multiple_crud) {
          this.$store.commit("assignMultipleJob/setIsPermissionCanEdit", true);
          this.$store.commit("assignMultipleJob/setIsPermissionCanView", true);
        }

        if (this.permList.jobs_multiple_tracking) {
          this.$store.commit("assignMultipleJob/setIsPermissionCanView", true);
        }
      }
    },

    //
    async handleEditClick(_id) {
      const responseData = await this.$refs[
        "assignMultipleJobCreateSheetDialog"
      ].open(_id);

      if (!_.isNil(responseData)) {
        if (responseData["status"]) {
          // update sheet data
          this.sheetData = responseData["updatedData"];
        }
      }
    },
  },
  destroyed() {
    this.setStore();
    this.$store.commit("assignMultipleJob/setZoneList", []);
    this.$store.commit("assignMultipleJob/setDriverGroupsList", []);
    this.$store.commit("assignMultipleJob/setIsPermissionCanEdit", false);
    this.$store.commit("assignMultipleJob/setIsPermissionCanView", false);
  },
  components: {
    AppOverlay,
    AssignMultipleJobSection,
    AssignMultipleCreateTableDialog,
    AssignMultipleJobSummaryDialog,
    AppPageNotFound,
    AssignMultipleJobCreateSheetDialog,
    AssignMultipleCreateDuplicateTable,
  },
};
</script>
