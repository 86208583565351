var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.show)?_c('v-card',{attrs:{"min-height":"80vh"}},[_c('v-card-title',{staticClass:"local-card-text px-3 pt-3"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[(_vm.colsDataPrev)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}},[(_vm.colsDataPrev.startDate)?_c('div',{staticClass:"pa-5 subtitle--text text--darken-2"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.rowsData.title ? ("วัน" + (_vm.rowsData.title)) : "-")+" ")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.rowsData.date ? ("" + (_vm.rowsData.date)) : "-")+" ")])]):_vm._e()]):_vm._e(),(_vm.colsDataPrev)?_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[(_vm.colsDataPrev.startDate)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"pa-5 subtitle--text text--darken-2"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.colsDataPrev.type === 1 ? "เข้า" : "ออก")+" "),(
                        _vm.colsDataPrev.title ||
                        _vm.colsDataPrev.endDate ||
                        _vm.colsDataPrev.estimateEndDate
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.colsDataPrev.title ? _vm.colsDataPrev.title : "")+" - "+_vm._s(_vm.colsDataPrev.endDate ? _vm.getTime(_vm.colsDataPrev.endDate) : _vm.getTime(_vm.colsDataPrev.estimateEndDate))+" น. ")]):_c('span',[_vm._v("-")])]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.colsDataPrev.subTitle ? ("" + (_vm.colsDataPrev.subTitle)) : "-")+" ")])])]):_vm._e()]):_vm._e()],1)],1),_c('v-divider',{attrs:{"dark":"","vertical":""}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"pa-5 white--text"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.rowsData.title ? ("วัน" + (_vm.rowsData.title)) : "-")+" ")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.rowsData.date ? ("" + (_vm.rowsData.date)) : "-")+" ")])])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"pa-5 white--text"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.colsData.type === 1 ? "เข้า" : "ออก")+" "),(
                        _vm.colsData.title ||
                        _vm.colsData.endDate ||
                        _vm.colsData.estimateEndDate
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.colsData.title ? _vm.colsData.title : "")+" - "+_vm._s(_vm.colsData.endDate ? _vm.getTime(_vm.colsData.endDate) : _vm.getTime(_vm.colsData.estimateEndDate))+" น. ")]):_c('span',[_vm._v("-")])]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.colsData.subTitle ? ("" + (_vm.colsData.subTitle)) : "-")+" ")])])])])],1)],1),_c('v-divider',{attrs:{"dark":"","vertical":""}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[(_vm.colsDataNext)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}},[(_vm.colsDataNext.startDate)?_c('div',{staticClass:"pa-5 subtitle--text text--darken-2"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.rowsData.title ? ("วัน" + (_vm.rowsData.title)) : "-")+" ")]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.rowsData.date ? ("" + (_vm.rowsData.date)) : "-")+" ")])]):_vm._e()]):_vm._e(),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[(_vm.colsDataNext)?_c('div',{staticStyle:{"position":"relative"}},[(_vm.colsDataNext.startDate)?_c('div',{staticClass:"pa-5 subtitle--text text--darken-2"},[_c('p',{staticClass:"text-app-detail-large mb-1"},[_vm._v(" "+_vm._s(_vm.colsDataNext.type === 1 ? "เข้า" : "ออก")+" "),(
                        _vm.colsDataNext.title ||
                        _vm.colsDataNext.endDate ||
                        _vm.colsDataNext.estimateEndDate
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.colsDataNext.title ? _vm.colsDataNext.title : "")+" - "+_vm._s(_vm.colsDataNext.endDate ? _vm.getTime(_vm.colsDataNext.endDate) : _vm.getTime(_vm.colsDataNext.estimateEndDate))+" น. ")]):_c('span',[_vm._v("-")])]),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.colsDataNext.subTitle ? ("" + (_vm.colsDataNext.subTitle)) : "-")+" ")])]):_vm._e()]):_vm._e(),_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0"}},[(_vm.fetchDataError <= 0 || _vm.fetchDataError === 1)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.cancelAndCloseHandler}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)])],1)],1)],1)],1),(_vm.fetchDataError === 0)?_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-6 pr-3",attrs:{"cols":"6"}},[_c('v-card-text',{staticClass:"pt-3 pb-1"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-group")]),_c('p',{staticClass:"text-app-detail-md font-weight-bold mb-0 ml-2 mt-2"},[_vm._v(" รายชื่อผู้ขับ ")])],1),_c('div',[_c('div',{staticClass:"text-app-detail-md"},[_c('div',{staticClass:"d-inline mr-3"},[_vm._v(" จำนวน "),_c('span',{class:_vm.tableItems.length > _vm.formData.newNumberOfSelectedDriver
                          ? 'red--text'
                          : ''},[_vm._v(_vm._s(_vm.tableItems.length))]),_vm._v(" / ")]),_c('div',{staticClass:"d-inline-block"},[_c('v-text-field',{staticStyle:{"width":"70px"},attrs:{"outlined":"","dense":"","background-color":"#EFF1F7","height":20,"hide-details":"","type":"number","min":"0","oninput":"validity.valid||(value='');"},model:{value:(_vm.formData.newNumberOfSelectedDriver),callback:function ($$v) {_vm.$set(_vm.formData, "newNumberOfSelectedDriver", $$v)},expression:"formData.newNumberOfSelectedDriver"}})],1),_c('div',{staticClass:"d-inline ml-3"},[_vm._v("คน")])])])])]),(!_vm.selectedItemStatus)?_c('v-autocomplete',{staticClass:"app-custom-input mb-3 px-2",attrs:{"hide-detail":"","dense":"","placeholder":"ค้นหาและเพิ่มรายชื่อ","items":_vm.filterDriverList,"item-text":"fullName","item-value":"_id","return-object":"","filled":"","hide-no-data":"","hide-selected":"","hide-details":"","flat":"","height":"44","outlined":""},on:{"change":_vm.itemChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32","left":""}},[_c('v-img',{attrs:{"src":data.item.profileImage}})],1),_vm._v(" "+_vm._s(data.item.fullName)+" "),(
                  _vm.checkUserCellHasOverlap(data.item._id) ||
                  _vm.checkUserJobHasOverlap(data.item._id)
                )?_c('span',{staticClass:"red--text text--lighten-1 font-weight-medium pl-1"},[_vm._v(" [!!!] ")]):_vm._e(),_c('v-spacer'),_c('span',{staticClass:"text-app-normal"},[_vm._v(" วิ่ง: "+_vm._s(data.item.statOnSheet ? data.item.statOnSheet.jobs : "-")+" ["+_vm._s(data.item.driverCellStat ? data.item.driverCellStat.jobs : "-")+"]")]),_c('span',{staticClass:"text-app-normal ml-6"},[_vm._v(" ฟรี: "+_vm._s(data.item.statOnSheet ? data.item.statOnSheet.jobs : "-")+" ["+_vm._s(data.item.driverCellStat ? data.item.driverCellStat.free : "-")+"]")])]}}],null,false,1360915278)}):_vm._e(),(_vm.selectedItemStatus)?_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"color":"info","dense":""}},[_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" ผู้ขับ "+_vm._s(_vm.tabledCheckSelected.length)+" คนที่ถูกเลือก ")]),_c('v-divider',{staticClass:"mx-5",attrs:{"vertical":"","color":"white"}}),_c('div',[_c('v-btn',{attrs:{"depressed":"","small":""},on:{"click":_vm.deleteSelection}},[_vm._v(" ลบ ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"white","small":""},on:{"click":_vm.cancelSelection}},[_vm._v(" ยกเลิก ")])],1)],1)]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"mytable","headers":_vm.tableHeaders,"items":_vm.tableItems,"item-class":_vm.itemClassHandler,"single-select":false,"show-select":"","disable-pagination":"","hide-default-footer":"","item-key":"_id","items-per-page":-1,"no-data-text":"ไม่มีผู้ขับ"},on:{"click:row":_vm.tableClickHandler},scopedSlots:_vm._u([{key:"item.rowNum",fn:function(ref){
                var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":item.profileImage}})]),_c('span',{class:[
                  {
                    'red--text text--lighten-1 font-weight-medium':
                      _vm.checkUserJobHasOverlap(item._id),
                  } ]},[_vm._v(" "+_vm._s(item.fullName)+" "),(_vm.checkUserCellHasOverlap(item._id))?_c('span',{staticClass:"red--text text--lighten-1 font-weight-medium"},[_vm._v(" [!!!] ")]):_vm._e()])]}},{key:"item.jobs",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"d-inline mb-0"},[_vm._v(" "+_vm._s(item.statOnSheet.jobs)+" ["+_vm._s(item.jobs)+"] ")])]}},{key:"item.free",fn:function(ref){
                var item = ref.item;
return [_c('p',{staticClass:"d-inline mb-0"},[_vm._v(" "+_vm._s(item.statOnSheet.free)+" ["+_vm._s(item.free)+"] ")])]}}],null,true),model:{value:(_vm.tabledCheckSelected),callback:function ($$v) {_vm.tabledCheckSelected=$$v},expression:"tabledCheckSelected"}})],1),_c('v-col',{staticClass:"pl-3 pr-6",attrs:{"cols":"6"}},[_c('CurrentDriverStatusChart',{attrs:{"refresh-key":_vm.chartKey,"table-id":_vm.tableId,"selected-driver-list":_vm.selectedDriverList,"sheet-table-data":_vm.sheetTableData,"overlap-job":_vm.overlapJob,"cell-data":_vm.cellData},on:{"update-table-driver":_vm.updateTableDriverFromChartHandler}})],1)],1)],1):_vm._e(),(_vm.fetchDataError === -2)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" ไม่สามารถดึงข้อมูลได้ ")]):_vm._e(),(_vm.fetchDataError === -1)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" ไม่สามารถบันทึกข้อมูลได้ ")]):_vm._e(),(_vm.fetchDataError === 1)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" กำลังดึงข้อมูล... ")]):_vm._e(),(_vm.fetchDataError === 2)?_c('v-card-text',{staticClass:"pa-5 text-center"},[_vm._v(" กำลังบันทึกข้อมูล... ")]):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.fetchDataError <= 0)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelAndCloseHandler}},[_vm._v(" ยกเลิก ")]):_vm._e(),(_vm.fetchDataError === 0)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveAndCloseHandler}},[_vm._v(" บันทึก ")]):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }