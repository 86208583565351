<template>
  <CustomCardFormViewModern
    title="จำนวนการซ่อม"
    :is-show-title="true"
    :is-add-title-margin="true"
  >
    <div class="pl-6">
      <v-row no-gutters>
        <v-col cols="3">
          <div class="custom-text-inline-height">
            <p class="text-app-detail-md mb-2">ทั้งหมด</p>
            <p class="font-weight-bold black--text dashboard-large">
              {{ vehicleEventCount || "-"
              }}<span class="text-app-detail-normal"> ครั้ง</span>
            </p>
          </div>
        </v-col>
        <v-col cols="9">
          <VehicleEventTypeSbarChart :data="data" />
          <VehicleEventProblemSbarChart :data="data" />
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <!-- sub line chart -->
      <VehicleEventExpensesLineChart :data="data" />
      <!-- sub scatter chart -->
      <v-row>
        <v-col cols="6">
          <VehicleEventScatterChart
            :data="eventDurationScatterPlotDataAndOps"
            :title="'ค่าใช้จ่าย/เวลาที่ซ่อม'"
            :axis-x-label="'เวลาที่ซ่อม'"
            :axis-y-label="'ค่าใช้จ่าย'"
            :height="300"
          />
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <VehicleEventScatterChart
            :data="eventJobIncomeScatterPlotDataAndOps"
            :title="'ค่าใช้จ่าย/รายได้'"
            :axis-x-label="'รายได้'"
            :axis-y-label="'ค่าใช้จ่าย'"
            :height="300"
          />
        </v-col>
      </v-row>
    </div>
  </CustomCardFormViewModern>
</template>

<script>
import _ from "lodash";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import VehicleEventExpensesLineChart from "./VehicleEventExpensesLineChart.vue";
import VehicleEventProblemSbarChart from "./VehicleEventProblemSbarChart.vue";
import VehicleEventTypeSbarChart from "./VehicleEventTypeSbarChart.vue";
import VehicleEventScatterChart from "./VehicleEventScatterChart.vue";

export default {
  name: "VehicleEventChartCard",
  components: {
    VehicleEventExpensesLineChart,
    CustomCardFormViewModern,
    VehicleEventProblemSbarChart,
    VehicleEventTypeSbarChart,
    VehicleEventScatterChart,
  },
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    this.processData();
  },
  data() {
    return {
      vehicleEventCount: null,
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      this.vehicleEventCount = _.get(
        this.data,
        "events.vehicleEventCount",
        null
      );
    },
    processOptions(_data, keyField, xField, yField, xAnnoField, yAnnoField) {
      try {
        let _d = [];
        let vehicleMapId = [];
        const groupVehiclesByJobsAndEventsWeek = _.get(_data, keyField, []);
        //
        for (let i = 0; i < groupVehiclesByJobsAndEventsWeek.length; i++) {
          // let shortCassie = "";
          // if (!_.isNil(groupVehiclesByJobsAndEventsWeek[i]["cassieId"])) {
          //   shortCassie = groupVehiclesByJobsAndEventsWeek[i][
          //     "cassieId"
          //   ].substring(0, 8);
          // }
          _d.push({
            name: `${groupVehiclesByJobsAndEventsWeek[i]["licenseNumber"]}`,
            data: [
              [
                groupVehiclesByJobsAndEventsWeek[i][xField],
                groupVehiclesByJobsAndEventsWeek[i][yField],
              ],
            ],
          });
          vehicleMapId.push(groupVehiclesByJobsAndEventsWeek[i]["vehicleId"]);
        }

        //
        return {
          data: _d,
          options: {
            annoYaxis: [
              {
                y: _.get(_data, yAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
            annoXaxis: [
              {
                x: _.get(_data, xAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
          },
          vehicleMapId: vehicleMapId,
        };
      } catch (error) {
        return { data: [], options: [] };
      }
    },
  },
  computed: {
    eventDurationScatterPlotDataAndOps() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "groupVehiclesByJobsAndEventsWeek",
          "normalizeEventDuration",
          "normalizeEventAmount",
          "vehicleEventDurationAvg",
          "vehicleEventAmountAvg"
        );
      }

      return {};
    },
    eventJobIncomeScatterPlotDataAndOps() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "groupVehiclesByJobsAndEventsWeek",
          "normalizeIncomePerRound",
          "normalizeEventAmount",
          "vehicleJobIncomePerRoundAvg",
          "vehicleEventAmountAvg"
        );
      }

      return {};
    },
  },
};
</script>

<style scoped>
.custom-text-inline-height {
  line-height: 1;
}
</style>
