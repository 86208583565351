<template>
  <CustomCardFormViewModern
    title="งานเดินรถ"
    :is-show-title="false"
    :is-add-title-margin="false"
  >
    <v-row v-if="isOk">
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-title class="pb-1">
            <span class="text-title font-weight-bold black--text">
              รายได้ / ระยะทาง
            </span>
            <v-list-item-subtitle>โดยเฉลี่ยต่อวัน</v-list-item-subtitle>
          </v-card-title>

          <apexchart
            height="250"
            :options="averageDistanceOption"
            :series="averageDistanceSeries"
          ></apexchart>
          <span
            class="mx-12 text-app-small-detail font-weight-bold black--text"
          >
            ระยะทางโดยเฉลี่ยต่อวัน
          </span>
        </v-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-title class="pb-1">
            <span class="text-title font-weight-bold black--text">
              รายได้ / ชั่วโมงทำงาน
            </span>
            <v-list-item-subtitle>โดยเฉลี่ยต่อวัน</v-list-item-subtitle>
          </v-card-title>

          <apexchart
            height="250"
            :options="averageDurationOption"
            :series="averageDurationSeries"
          ></apexchart>
          <span
            class="mx-12 text-app-small-detail font-weight-bold black--text"
          >
            ชั่วโมงทำงานโดยเฉลี่ยต่อวัน
          </span>
        </v-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-title class="pb-1">
            <span class="text-title font-weight-bold black--text">
              รายได้ / จำนวนงาน
            </span>
            <v-list-item-subtitle>โดยเฉลี่ยต่อวัน</v-list-item-subtitle>
          </v-card-title>

          <apexchart
            height="250"
            :options="averageJobOption"
            :series="averageJobSeries"
          ></apexchart>
          <span
            class="mx-12 text-app-small-detail font-weight-bold black--text"
          >
            จำนวนงานโดยเฉลี่ยต่อวัน
          </span>
        </v-card>
      </v-col>
    </v-row>
  </CustomCardFormViewModern>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { calculateHours, calculateKm } from "@/services/appSummaryHelper";
import {
  displayNumberFixedPointHelper,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";

export default {
  name: "JobStatScatterChart",
  props: {
    data: {
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
    CustomCardFormViewModern,
  },
  data() {
    return {
      tableData: null,
      averageJobSeries: [],
      averageDistanceSeries: [],
      averageDurationSeries: [],
      seriesScatter: [
        // {
        //   data: [
        //     [16.4, 5.4],
        //     [21.7, 2],
        //     [25.4, 3],
        //     [8.1, 4.7],
        //     [19, 1.8],
        //     [27.1, 0.8],
        //     [22.1, 2],
        //   ],
        // },
      ],
      averageJobOption: {},
      averageDistanceOption: {},
      averageDurationOption: {},
      isOk: false,
    };
  },
  watch: {
    data: function (newValue) {
      this.isOk = false;
      this.processData();
    },
  },
  methods: {
    chartOptionsScatter(avgX, avgY) {
      return {
        chart: {
          type: "scatter",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          events: {
            click: (event, chartContext, config) => {
              // console.log(event);
              // console.log(chartContext);
              // console.log(config);
              this.$emit(
                "open-chart-info",
                _.get(
                  config.config.series[config.seriesIndex],
                  "contractId",
                  null
                )
              );
            },
          },
        },
        legend: { show: false },
        grid: {
          show: false,
        },
        markers: {
          colors: ["#567DF4"], // "#AAB7B8"]
          size: [7], //10],
        },
        annotations: {
          xaxis: [
            {
              x: avgX,
              borderColor: "#775DD0",
              // label: {
              //   borderColor: "#00E396",
              //   orientation: "horizontal",
              // },
            },
          ],
          yaxis: [
            {
              y: avgY,
              borderColor: "#775DD0",
              // label: {
              //   borderColor: "#00E396",
              //   orientation: "horizontal",
              // },
            },
          ],
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.4,
            },
          },
        },
        xaxis: {
          // title: {
          //   text: titleX,
          //   style: {
          //     fontSize: "13px",
          //   },
          //   offsetY: 0,
          // },
          labels: {
            formatter: (value) => {
              return `${displayNumberFixedPointHelper(value, 2)}`;
            },
          },
        },
        yaxis: {
          // tickAmount: 7,
          title: {
            text: "รายได้โดยเฉลี่ยต่อวัน",
            offsetX: 0,
            offsetY: -50,
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            formatter: (value) => {
              return `${displayNumberFixedPointHelper(value, 1)}`;
            },
          },
        },
        tooltip: {
          // x: {
          //   show: true,
          //   formatter: (val) => {
          //     return `${numberWithCommasHelper(val)}`;
          //   },
          // },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(
                displayNumberFixedPointHelper(val, 3)
              )}`;
            },
          },
        },
      };
    },
    processData() {
      this.averageJobSeries = [];
      this.averageDistanceSeries = [];
      this.averageDurationSeries = [];

      if (!_.isEmpty(this.data)) {
        this.tableData = this.data["table"];

        let _averageJobData = [];
        const tableDataList = _.values(this.tableData);
        if (tableDataList.length === 0) {
          this.averageJobSeries = [{ type: "scatter", data: [] }];
          this.averageDistanceSeries = [{ type: "scatter", data: [] }];
          this.averageDurationSeries = [{ type: "scatter", data: [] }];
          this.averageDistanceOption = {
            chart: {
              type: "scatter",
            },
          };
          this.averageDurationOption = {
            chart: {
              type: "scatter",
            },
          };
          this.averageJobOption = {
            chart: {
              type: "scatter",
            },
          };
          this.isOk = true;
          return;
        }
        //
        for (const [key, value] of Object.entries(this.tableData)) {
          _averageJobData.push({
            type: "scatter",
            name: `${value["contractNumber"]} <br/> ${value["title"]}`,
            data: [[value["normalizeTotalJob"], value["normalizeTotalIncome"]]],
            contractId: key,
          });
        }

        let _averageDistance = [];
        for (const [key, value] of Object.entries(this.tableData)) {
          _averageDistance.push({
            type: "scatter",
            name: `${value["contractNumber"]} <br/> ${value["title"]}`,
            data: [
              [value["normalizeTotalDistance"], value["normalizeTotalIncome"]],
            ],
            contractId: key,
          });
        }

        let _averageDuration = [];
        for (const [key, value] of Object.entries(this.tableData)) {
          _averageDuration.push({
            type: "scatter",
            name: `${value["contractNumber"]} <br/> ${value["title"]}`,
            data: [
              [value["normalizeTotalDuration"], value["normalizeTotalIncome"]],
            ],
            contractId: key,
          });
        }

        // calculate option
        const xAxisJobOption =
          _.divide(
            _.divide(
              _.sum(_.map(tableDataList, "totalJob")),
              tableDataList.length
            ),
            this.data["durationDays"]
          ) || 0;
        const yAxisIncomeOption =
          _.divide(
            _.divide(
              _.sum(_.map(tableDataList, "totalIncome")),
              tableDataList.length
            ),
            this.data["durationDays"]
          ) || 0;
        const xAxisDistanceOption =
          _.divide(
            _.divide(
              calculateKm(_.sum(_.map(tableDataList, "totalDistance"))),
              tableDataList.length
            ),
            this.data["durationDays"]
          ) || 0;
        const xAxisDurationOption =
          _.divide(
            _.divide(
              calculateHours(_.sum(_.map(tableDataList, "totalDuration"))),
              tableDataList.length
            ),
            this.data["durationDays"]
          ) || 0;

        this.averageJobOption = this.chartOptionsScatter(
          xAxisJobOption,
          yAxisIncomeOption,
          "จำนวนงาน"
        );
        this.averageDistanceOption = this.chartOptionsScatter(
          xAxisDistanceOption,
          yAxisIncomeOption,
          "ระยะทาง"
        );
        this.averageDurationOption = this.chartOptionsScatter(
          xAxisDurationOption,
          yAxisIncomeOption,
          "เวลาทำงาน"
        );
        //
        this.averageJobSeries = _averageJobData;
        this.averageDistanceSeries = _averageDistance;
        this.averageDurationSeries = _averageDuration;

        this.isOk = true;
      }
    },
  },

  mounted() {
    this.processData();
  },
};
</script>
