<template>
  <v-dialog fullscreen v-model="show" transition="dialog-bottom-transition">
    <v-card v-if="show">
      <!-- <v-toolbar color="primary" dark>
        <v-card-title> HeatMap </v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar> -->

      <!-- <v-card-text v-if="loading"> กำลังดึงข้อมูล </v-card-text> -->
      <!-- <div v-if="!loading"> -->
      <!-- <v-autocomplete
              class="app-custom-input mt-2 mr-2"
              :items="contractRouteTitleList"
              v-model="contractRoute"
              label="สัญญา/เส้นทาง"
              placeholder="เลือก"
              item-value="key"
              item-text="value"
              outlined
              dense
              filled
              clearable
              hide-details
              return-object
              :append-icon="''"
              :loading="contractLoading"
              @change="tableContractRouteChangeHandler"
            ></v-autocomplete> -->
      <v-card>
        <v-card-text class="py-2">
          <v-row>
            <v-col cols="5">
              <CustomFilterValueDateTypingPickerRange
                class="mr-3"
                :value="joinDate"
                :is-required="false"
                :is-same-line="true"
                placeholder="เลือก"
                :hide-detail="true"
                @update-join-date="updateJoinDateHandler"
                :num-days-item="[1, 3, 5, 7]"
              />
            </v-col>
            <v-col cols="7">
              <div class="d-flex align-end">
                <div style="width: 50%">
                  <p class="mb-0 caption">สัญญาเดินรถ</p>
                  <JobContractSelectorInput
                    class="mr-2"
                    ref="JobContractSelectorInputRef"
                    :value="selectSearchContractItem"
                    @searchItemChange="getSearchContractItem"
                    placeholder=""
                    :required="false"
                    @update-items="JobContractSelectorInputItems"
                    :append-all-items="true"
                    :value-all-item="contractAllOption"
                  />
                </div>

                <div style="width: 40%">
                  <p class="mb-0 caption">เส้นทางเดินรถ</p>
                  <v-autocomplete
                    :items="routeItemList"
                    :loading="routeLoading"
                    :value="selectedRouteItem"
                    placeholder=""
                    append-icon=""
                    clearable
                    return-object
                    filled
                    dense
                    hide-no-data
                    hide-selected
                    hide-details
                    flat
                    outlined
                    class="app-custom-input mr-2"
                    @change="routeItemChange"
                    item-text="name"
                    item-value="_id"
                  ></v-autocomplete>
                </div>

                <v-btn color="primary mr-2" large @click="searchHandler">
                  คัดกรอง
                </v-btn>
                <v-btn icon large class="" @click.native="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-card-text>
      </v-card>
      <div
        id="jobCpHeapMapOverlay"
        style="width: 100%; height: 88vh; position: relative"
      >
        <div class="map-label-card">
          <div class="ma-2" style="height: 100%">
            <v-sheet class="py-1 px-2 rounded d-flex">
              <div class="d-flex">
                <div class="d-flex flex-column justify-end mb-1">
                  <div class="d-flex justify-space-between">
                    <p class="ma-0 caption">น้อย</p>
                    <p class="ma-0 caption">มาก</p>
                  </div>
                  <div :style="`width: 10rem; height: 1rem;`">
                    <div
                      class="main-map-gd"
                      :style="`width: 100%; height: 100%`"
                    ></div>
                  </div>
                  <!-- <v-checkbox
                    v-model="showJobMarkerValue"
                    label="แสดงจุดรับ"
                    color="red darken-3"
                    value="1"
                    hide-details
                    class="mt-0"
                  ></v-checkbox> -->
                </div>
                <div class="d-flex flex-column justify-start mx-2">
                  <div class="d-flex mb-1">
                    <v-sheet
                      :class="[
                        'rounded-circle',
                        {
                          'map-label-inactive-circle':
                            showStartPointValue === null,
                        },
                        {
                          'map-label-active-start-point':
                            showStartPointValue === '1',
                        },
                      ]"
                      :width="20"
                      :height="20"
                      @click="showStartPointHandler"
                    ></v-sheet>
                    <p
                      class="mb-0 ml-1 caption"
                      :class="[
                        {
                          'map-label-inactive-text':
                            showStartPointValue === null,
                        },
                      ]"
                      @click="showStartPointHandler"
                    >
                      จุดเริ่มงาน
                    </p>
                  </div>
                  <div class="d-flex mb-1">
                    <v-sheet
                      :class="[
                        'rounded-circle',
                        {
                          'map-label-inactive-circle':
                            showEndPointValue === null,
                        },
                        {
                          'map-label-active-end-point':
                            showEndPointValue === '1',
                        },
                      ]"
                      :width="20"
                      :height="20"
                      @click="showEndPointHandler"
                    ></v-sheet>
                    <p
                      class="mb-0 ml-1 caption"
                      :class="[
                        {
                          'map-label-inactive-text': showEndPointValue === null,
                        },
                      ]"
                      @click="showEndPointHandler"
                    >
                      จุดจบงาน
                    </p>
                  </div>
                </div>
                <div class="d-flex flex-column justify-start">
                  <div class="d-flex">
                    <v-sheet
                      :class="[
                        'rounded-circle',
                        {
                          'map-label-inactive-circle':
                            showJobMarkerValue === null,
                        },
                        {
                          'map-label-active-job-marker':
                            showJobMarkerValue === '1',
                        },
                      ]"
                      :width="20"
                      :height="20"
                      @click="showJobMarkerHandler"
                    ></v-sheet>
                    <p
                      class="mb-0 ml-1 caption"
                      :class="[
                        {
                          'map-label-inactive-text':
                            showJobMarkerValue === null,
                        },
                      ]"
                      @click="showJobMarkerHandler"
                    >
                      จุดรับ
                    </p>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
        <!-- <div
          class="overlay d-flex flex-column justify-center align-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div> -->
        <GoogleMapLoader
          ref="jobCpHeapMap"
          vuex-status-action-name="updateCreateGMapActive"
          vuex-store-name="jobsMap"
          style="position: relative; height: 100%"
          :map-config="mapConfig"
        >
          <template slot-scope="{ google, map }">
            <HeatMapMarker
              v-for="sMarker in startMarker"
              :key="`shmm_${sMarker.id}`"
              :marker="sMarker"
              :google="google"
              :map="map"
            />
            <HeatMapMarker
              v-for="eMarker in endMarker"
              :key="`shmm_${eMarker.id}`"
              :marker="eMarker"
              :google="google"
              :map="map"
            />
            <GoogleMapMarker
              v-for="pMarker in pointMarker"
              :key="pMarker.id"
              :marker="pMarker"
              :google="google"
              :map="map"
            />
          </template>
        </GoogleMapLoader>
      </div>
    </v-card>
    <AppOverlay :isLoading="loading" />
  </v-dialog>
</template>

<script>
import AppOverlay from "@/components/AppOverlay.vue";
import GoogleMapLoader from "../jobs/manageView/googleMapComponents/GoogleMapLoader.vue";
import {
  getContractRouteHeatMapReportService,
  getRouteTemplateNameListByContractIdService,
} from "@/services/api/report";
import HeatMapMarker from "./heatmap-components/HeatMapMarker.vue";
import CustomFilterValueDateTypingPickerRange from "@/components/CustomFilterValueDateTypingPickerRange.vue";
import JobContractSelectorInput from "@/views/jobs/manageView/components/jobDetail/JobContractSelectorInput.vue";
import { getContractRouteTitleListService } from "@/services/api/jobs";
import { CUSTOM_MARKER_ICON } from "@/services/map/mapSettings";
import moment from "moment";
import {
  appDateYearMonthDayWithEnDashFormat,
  dateStringToDateFormat,
} from "@/services/appDate";
import GoogleMapMarker from "../jobs/manageView/googleMapComponents/GoogleMapMarker.vue";
import { getNewGoogleMapObj } from "@/views/jobs/manageView/googleMapComponents/GoogleMapService";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "JobCheckpointHeatMap",
  components: {
    GoogleMapLoader,
    AppOverlay,
    HeatMapMarker,
    CustomFilterValueDateTypingPickerRange,
    JobContractSelectorInput,
    GoogleMapMarker,
  },
  data: () => ({
    resolve: null,
    reject: null,
    loading: false,
    currentData: null,

    startMarker: [],
    tmpStartMarker: [],
    endMarker: [],
    tmpEndMarker: [],

    pointMarker: [],
    tmpPointMarker: [],
    // filter
    joinDate: [],
    contract: null,
    // joinDate: ["2023-08-02", "2023-10-31"],
    contractRouteTitleList: [],
    selectSearchContractItem: null,
    contractRoute: null,
    contractLoading: false,
    routeItemList: [],
    routeLoading: false,
    selectedRouteItem: null,
    formData: {
      startdate: undefined,
      enddate: undefined,
      contract: undefined,
      route: undefined,
    },
    gradientColorBar: [
      "rgba(102, 255, 0, 0)",
      "rgba(102, 255, 0, 1)",
      "rgba(147, 255, 0, 1)",
      "rgba(193, 255, 0, 1)",
      "rgba(238, 255, 0, 1)",
      "rgba(244, 227, 0, 1)",
      "rgba(249, 198, 0, 1)",
      "rgba(255, 170, 0, 1)",
      "rgba(255, 113, 0, 1)",
      "rgba(255, 57, 0, 1)",
      "rgba(255, 0, 0, 1)",
    ],
    gradientBarStyle: "",
    allOption: {
      name: "ทั้งหมด",
      _id: undefined,
    },
    contractAllOption: {
      value: "ทั้งหมด",
      _id: undefined,
    },
    googleMapObject: null,
    showJobMarkerValue: null,
    showStartPointValue: null,
    showEndPointValue: null,
  }),
  computed: {
    show() {
      return this.$store.state.jobsMap.show;
    },
    mapConfig() {
      return {
        center: { lat: 13.283859, lng: 100.915876 },
        zoom: 18,
      };
    },
  },
  methods: {
    // open() {
    //   return new Promise((resolve, reject) => {
    //     this.resolve = resolve;
    //     this.reject = reject;
    //   });
    // },
    // cancelStatus() {
    //   this.show = false;
    //   this.resolve(null);
    // },
    async _setMapObject() {
      if (this.googleMapObject === null) {
        this.googleMapObject = await getNewGoogleMapObj();
      }
    },

    createJoinDate() {
      this.formData.startdate = moment()
        .subtract(3, "days")
        .format(appDateYearMonthDayWithEnDashFormat);
      this.formData.enddate = moment().format(
        appDateYearMonthDayWithEnDashFormat
      );

      this.joinDate = [this.formData.startdate, this.formData.enddate];
    },
    close() {
      this.$store.dispatch("jobsMap/closeDialog", { show: false });
      this.loading = false;
      this.$store.dispatch("jobsMap/updateSelectedContractId", null);
    },

    async getData(isFilter) {
      try {
        this.loading = true;
        const filtedFormData = _.pickBy(this.formData, (v) => v !== undefined);
        const queryParams = new URLSearchParams(filtedFormData);
        const rp = await getContractRouteHeatMapReportService(
          this.$store,
          queryParams.toString()
        );

        if (rp["code"] !== 200) {
          throw "FETCH_DATA";
        }

        this.currentData = rp["data"];
        this.startMarker = [];
        this.endMarker = [];
        this.pointMarker = [];

        if (this.currentData["heat"] !== null) {
          if (!isFilter) {
            this.$refs.jobCpHeapMap.setHeatMap([], []);
          }

          this.$refs.jobCpHeapMap.setHeatMap(
            this.currentData["heat"]["M"],
            this.currentData["heat"]["H"]
          );

          this.$refs.jobCpHeapMap.setCenterByCoordinateList(
            this.currentData["heat"]["M"]
          );
          this.processMarker(
            this.currentData["heat"]["LoS"],
            this.currentData["heat"]["LoE"]
          );
        } else {
          this.$refs.jobCpHeapMap.setHeatMap([], []);
        }

        if (this.currentData["markers"].length > 0) {
          let _positionList = this.processPointMarker(
            this.currentData["markers"]
          );
          if (this.currentData["heat"] === null) {
            this.$refs.jobCpHeapMap.setCenterByCoordinateList(_positionList);
          }
        }
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },

    processMarker(startMarker, endMarker) {
      let _startMarker = [];
      for (let i = 0; i < startMarker.length; i++) {
        _startMarker.push({
          id: `SM_${i}_${new Date().getTime()}`,
          position: {
            lat: startMarker[i][0],
            lng: startMarker[i][1],
          },
          type: "S",
        });
      }

      let _endMarker = [];
      for (let i = 0; i < endMarker.length; i++) {
        _endMarker.push({
          id: `EM_${i}_${new Date().getTime()}`,
          position: {
            lat: endMarker[i][0],
            lng: endMarker[i][1],
          },
          type: "E",
        });
      }

      this.tmpStartMarker = _startMarker;
      if (this.showStartPointValue === "1") {
        this.startMarker = _.cloneDeep(this.tmpStartMarker);
      }

      this.tmpEndMarker = _endMarker;
      if (this.showEndPointValue === "1") {
        this.endMarker = _.cloneDeep(this.tmpEndMarker);
      }
    },

    processPointMarker(pointMarker) {
      let _pointMarker = [];
      let _positionList = [];
      for (let i = 0; i < pointMarker.length; i++) {
        _pointMarker.push({
          id: `${pointMarker[i]["gplaceId"]}_${new Date().getTime()}`,
          position: {
            lat: pointMarker[i]["lat"],
            lng: pointMarker[i]["lng"],
          },
          iconMode: i === 0 ? 0 : i === pointMarker.length - 1 ? 1 : 2,
          icon:
            i === 0
              ? _.clone(CUSTOM_MARKER_ICON.START_POINT)
              : i === pointMarker.length - 1
              ? _.clone(CUSTOM_MARKER_ICON.FINISH_POINT)
              : _.clone(CUSTOM_MARKER_ICON.CHECKPOINT),
          disableAnimation: false,
          vehicleAnchor: false,
          order: pointMarker[i]["order"],
        });

        _positionList.push([pointMarker[i]["lat"], pointMarker[i]["lng"]]);
      }

      for (let i = 0; i < _pointMarker.length; i++) {
        if (!_.isNil(_pointMarker[i]["icon"])) {
          let selectedIcon = faLocationPin.icon;
          _pointMarker[i]["label"] = {
            text: String(_pointMarker[i]["order"]),
            fontSize: "18px",
            fontWeight: "700",
            color: "#ffffff",
          };
          _pointMarker[i]["icon"]["labelOrigin"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1] / 3
            );

          _pointMarker[i]["icon"]["path"] = selectedIcon[4];
          _pointMarker[i]["icon"]["anchor"] =
            new this.googleMapObject.maps.Point(
              selectedIcon[0] / 2,
              selectedIcon[1]
            );
        }
      }
      this.tmpPointMarker = _pointMarker;

      if (this.showJobMarkerValue === "1") {
        this.pointMarker = _.cloneDeep(this.tmpPointMarker);
      }

      return _positionList;
    },

    updateJoinDateHandler(newValue) {
      if (newValue.length === 2) {
        this.formData.startdate = newValue[0];
        this.formData.enddate = newValue[1];
      } else if (newValue === 1) {
        this.formData.startdate = newValue[0];
        this.formData.enddate = undefined;
      }
    },
    // tableContractRouteChangeHandler(newItem) {
    //   if (newItem === null) {
    //     this.formData["contract"] = undefined;
    //     this.formData["route"] = undefined;
    //     return;
    //   }
    //   if (newItem["type"] === "R") {
    //     this.formData["route"] = newItem["key"];
    //     this.formData["contract"] = undefined;
    //   } else {
    //     this.formData["contract"] = newItem["key"];
    //     this.formData["route"] = undefined;
    //   }
    // },
    getSearchContractItem(newItem) {
      if (newItem === null) {
        this.formData["contract"] = undefined;
      } else {
        this.formData["contract"] = newItem["key"];
        if (this.formData["contract"] !== undefined) {
          this.getRouteListByContractId(newItem["key"]);
        } else {
          this.routeItemList = [this.allOption];
          this.selectedRouteItem = this.allOption;
        }
      }
    },
    async getRouteListByContractId(contractId) {
      this.routeLoading = true;
      this.routeItemList = [];
      this.formData["route"] = undefined;

      const queryString = new URLSearchParams({
        id: contractId,
      }).toString();

      try {
        const rsp = await getRouteTemplateNameListByContractIdService(
          this.$store,
          queryString
        );
        if (rsp["code"] === 200) {
          let _item = rsp["data"];

          _item.push(
            {
              name: "อื่นๆ",
              _id: "0",
            },
            this.allOption
          );

          this.routeItemList = _item;
          this.selectedRouteItem = this.allOption;
        } else {
          throw "FETCH_ERROR";
        }
      } catch (error) {
        console.error(error);
      }

      this.routeLoading = false;
    },
    routeItemChange(newItem) {
      if (newItem === null) {
        this.formData["route"] = undefined;
      } else {
        this.formData["route"] = newItem["_id"];
      }
      this.selectedRouteItem = newItem;
    },
    // async getContractRouteList() {
    //   try {
    //     this.contractLoading = true;

    //     const rp = await getContractRouteTitleListService(this.$store);
    //     if (rp["code"] === 200) {
    //       const routeRaw = _.clone(rp["data"]);
    //       const sortedContractRoute = routeRaw.sort((a, b) => {
    //         if (a.value < b.value) {
    //           return -1;
    //         }
    //         if (a.value > b.value) {
    //           return 1;
    //         }
    //         return 0;
    //       });
    //       this.contractRouteTitleList = sortedContractRoute;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   this.contractLoading = false;
    // },
    JobContractSelectorInputItems(newItems) {
      if (newItems.length > 0) {
        const currentContractId = this.$store.state.jobsMap.selectedContractId;
        const selectedItems = _.filter(
          newItems,
          (item) => item["key"] === currentContractId
        );
        if (selectedItems.length > 0) {
          this.selectSearchContractItem = selectedItems[0];
          this.getRouteListByContractId(currentContractId);
          this.formData.contract = currentContractId;
        } else {
          // select all option
          this.selectSearchContractItem = this.contractAllOption;
          this.routeItemList = [this.allOption];
          this.selectedRouteItem = this.allOption;
        }
      }
    },
    searchHandler() {
      this.getData(true);
    },
    clearForm() {
      this.selectSearchContractItem = null;
      this.selectedRouteItem = null;
      this.formData = {
        startdate: undefined,
        enddate: undefined,
        contract: undefined,
        route: undefined,
      };
      this.showJobMarkerValue = null;
      this.showStartPointValue = null;
      this.showEndPointValue = null;
    },

    createGradientBar() {
      let gradientCss = "(left";
      for (let i = 0; i < this.gradientColorBar.length; ++i) {
        gradientCss += ", " + this.gradientColorBar[i];
      }
      gradientCss += ")";
      this.gradientBarStyle = `background:-webkit-linear-gradient${gradientCss};-moz-linear-gradient:${gradientCss};-o-linear-gradient:${gradientCss};linear-gradient:${gradientCss};`;
    },
    showJobMarkerHandler() {
      if (this.showJobMarkerValue === null) {
        this.pointMarker = _.cloneDeep(this.tmpPointMarker);
        this.showJobMarkerValue = "1";
      } else {
        this.tmpPointMarker = _.cloneDeep(this.pointMarker);
        this.pointMarker = [];
        this.showJobMarkerValue = null;
      }
    },
    showStartPointHandler() {
      if (this.showStartPointValue === null) {
        this.startMarker = _.cloneDeep(this.tmpStartMarker);
        this.showStartPointValue = "1";
      } else {
        this.tmpStartMarker = _.cloneDeep(this.startMarker);
        this.startMarker = [];
        this.showStartPointValue = null;
      }
    },
    showEndPointHandler() {
      if (this.showEndPointValue === null) {
        this.endMarker = _.cloneDeep(this.tmpEndMarker);
        this.showEndPointValue = "1";
      } else {
        this.tmpEndMarker = _.cloneDeep(this.tmpEndMarker);
        this.endMarker = [];
        this.showEndPointValue = null;
      }
    },
  },
  watch: {
    "$store.state.jobsMap.createGMapActive": function () {
      if (this.$store.state.jobsMap.createGMapActive === true) {
        this._setMapObject();
        // this.getData(false);
      }
    },
    "$store.state.jobsMap.show": function () {
      if (this.$store.state.jobsMap.show === true) {
        this.clearForm();
        this.createJoinDate();
        // this.createGradientBar();
        // this.getContractRouteList();
      }
    },
  },

  mounted() {
    this.$store.dispatch("jobsMap/updateCreateGMapActive", false);
  },

  destroyed() {},
};
</script>

<style scoped>
#jobCpHeapMapOverlay .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  z-index: 1;
}

#jobCpHeapMapOverlay .map-label-card {
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: #fff; */
  /* opacity: 0.8; */
  z-index: 1;
  height: 100%;
}
#jobCpHeapMapOverlay .hidden {
  display: none;
}

.map-label-inactive-text {
  color: rgba(0, 0, 0, 0.3);
}

.map-label-inactive-circle {
  background: rgba(0, 0, 0, 0.1);
}

.map-label-inactive-circle {
  background: rgba(0, 0, 0, 0.1);
}

.map-label-active-job-marker {
  background: #659bde;
}

.map-label-active-end-point {
  background: #f5487f;
}

.map-label-active-start-point {
  background: #00ffff;
}
</style>
