<template>
  <div class="table-container">
    <v-toolbar elevation="0" color="#354253" v-if="tableSheetData">
      <div class="d-flex">
        <div>
          <p class="ma-0 text-app-normal text--white">สัญญาเดินรถ</p>
          <p
            class="ma-0 mt-1 text-app-title font-weight-bold"
            style="color: #fff"
          >
            #{{ tableIdx + 1 }}
            {{ tableSheetData.contract.title }}
            ({{ tableSheetData.contract.contractNumber }})
          </p>
        </div>
        <div class="d-flex justify-end">
          <v-btn icon dark class="ml-2" @click="duplicateTable()">
            <v-icon> mdi-content-copy </v-icon>
          </v-btn>
          <v-btn
            icon
            color="red accent-2"
            @click="removeTable()"
            v-if="rowData.length === 0 && canEdit"
          >
            <v-icon> mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>

      <v-spacer></v-spacer>
      <v-btn
        v-if="canEdit"
        class="mr-2"
        solo
        dense
        dark
        outlined
        @click="createJobFromDataTable()"
      >
        <v-icon class="mr-1">mdi-stamper</v-icon>
        เผยแพร่ทั้งหมด
      </v-btn>
      <v-btn
        v-if="canEdit"
        class="mr-2"
        solo
        dense
        dark
        outlined
        @click="getAllocateDriverToCell()"
      >
        <v-icon class="mr-1">mdi-puzzle</v-icon>
        จัดงานอัตโนมัติ
      </v-btn>
      <v-btn
        v-if="canEdit"
        class="mr-2"
        solo
        dense
        dark
        outlined
        @click="clearAllocateDriverTCell()"
      >
        <v-icon class="mr-1">mdi-broom</v-icon>
        ล้างการจัดงาน
      </v-btn>
      <v-btn @click="handleSchedule()" class="mr-5" elevation="0" dark outlined>
        <v-icon class="mr-1">mdi-bird</v-icon>
        เส้นเวลา
      </v-btn>
      <v-btn
        dark
        text
        :input-value="isOpenDriverDrawer"
        @click="() => (isOpenDriverDrawer = !isOpenDriverDrawer)"
      >
        <v-icon large>mdi-account-group</v-icon>
        <span class="ml-1"> {{ driverInCart.length }} </span>
      </v-btn>
    </v-toolbar>
    <div :id="`tableview-${tableId}`">
      <table class="app-assign-mul-job">
        <tbody>
          <tr>
            <td
              style="width: 125px; height: 60px; background-color: #7b7f9e"
              class="text-center white--text"
            >
              วันที่ / สายวิ่ง
            </td>
            <td
              v-for="(header, idx) in columnsData"
              :key="`${header.row}-${header.col}`"
              v-bind:class="[
                {
                  'table-text-noData': header.noData,
                  'table-text-pair-cols': header.isBetweenPair,
                  'table-data-bg-cols': !header.isBetweenPair,
                },
                'text-center',
              ]"
              v-bind:rowspan="header.noData ? rowData.length + 1 : 1"
              style="width: 250px; cursor: pointer"
              @click="editNewColumn(header)"
            >
              <div v-if="header.noData === 0" style="vertical-align: top">
                <p
                  :class="[
                    'mb-0',
                    {
                      'red--text': header.outputStartDateLessThanInputStartDate,
                    },
                  ]"
                >
                  <span class="font-weight-bold">
                    {{ header.type === 1 ? "เข้า" : "ออก" }}</span
                  >
                  {{ header.title }} -
                  {{
                    header.endDate
                      ? getTime(header.endDate)
                      : getTime(header.estimateEndDate)
                  }}

                  <v-icon small v-if="getColumnsHasJob(header)">
                    mdi-lock
                  </v-icon>
                </p>
                <p class="mb-0">
                  {{ header.subTitle }}
                </p>
              </div>
              <div v-else>
                <p class="font-weight-bold mt-3">
                  {{ header.type === 1 ? "ไม่มีขาเข้า" : "ไม่มีขาออก" }}
                  <v-icon small v-if="getColumnsHasJob(header)">
                    mdi-lock
                  </v-icon>
                </p>
              </div>
            </td>
            <td style="background-color: #f0f4f9">
              <v-btn
                v-if="canEdit"
                class="mx-3"
                x-small
                fab
                outlined
                color="primary"
                @click="addNewColumn"
                :disabled="isTableOnProcess"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </td>
          </tr>
          <!-- data section -->
          <tr v-for="(rd, dataRowIdx) in rowData" :key="`${rd.row}-${rd.col}`">
            <td
              style="
                width: 125px;
                height: 172px;
                z-index: 1;
                background-color: #f0f4f9;
              "
              class="text-center py-0"
            >
              <v-col cols="12" class="pa-0" style="position: absolute; top: 0">
                <v-row no-gutters class="pa-0">
                  <v-col cols="6" class="pa-0 text-left">
                    <v-btn
                      @click="handleRowSchedule(rd)"
                      color="primary"
                      elevation="0"
                      light
                      icon
                      ><v-icon>mdi-bird</v-icon></v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      v-if="canEdit"
                      icon
                      @click="removeRow(rd.rowsCode)"
                      color="grey lighten-1"
                      ><v-icon> mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <div style="position: absolute; top: 0; right: 0">
                <v-btn
                  v-if="canEdit"
                  icon
                  @click="removeRow(rd.rowsCode)"
                  color="grey lighten-1"
                  ><v-icon> mdi-close</v-icon>
                </v-btn>
              </div> -->

              <div class="mt-6">
                <p class="mb-1 text-app-md font-weight-bold">
                  {{ rd.title }}
                </p>
                <p class="text-app-title font-weight-bold">
                  {{ rd.date }}
                </p>
              </div>

              <div>
                <v-btn
                  v-if="canEdit"
                  small
                  text
                  color="primary"
                  @click="createJobFromDateRow(rd)"
                  :loading="rd.jobOpsLoading"
                >
                  <v-icon> mdi-stamper </v-icon>
                  <span class="text-decoration-underline"> เผยแพร่งาน </span>
                </v-btn>
              </div>
              <!-- <div class="mt-3">
                <v-btn
                  @click="handleRowSchedule(rd)"
                  class="mx-3"
                  color="primary"
                  elevation="0"
                  light
                  small
                  outlined
                  ><v-icon>mdi-bird</v-icon></v-btn
                >
              </div> -->
            </td>
            <!-- IMPORT -->
            <td
              v-for="(data, dataColIdx) in rd.data"
              :key="`c${dataRowIdx}r${dataColIdx}`"
              :name="`data_c${dataRowIdx}r${dataColIdx}+view_r${data.row}c${data.col}`"
              style="width: 250px; height: 172px"
              v-bind:class="[
                {
                  'table-text-pair-cols': columnsData[data.col].isBetweenPair,
                  'table-data-bg-cols': !columnsData[data.col].isBetweenPair,
                },
                'text-center',
              ]"
            >
              <AssignMultipleJobDriverTableCellBox
                :table-id="tableId"
                :loop-row-idx="dataRowIdx"
                :loop-cols-idx="dataColIdx"
                :vuex-cell-row="data.row"
                :vuex-cell-col="data.col"
                @next-input-func="nextInputHandler"
                @open-cell-dialog-func="openCellManageDialog"
              />
            </td>

            <!--  -->
            <td></td>
          </tr>
          <!--  -->
          <tr v-if="canEdit">
            <td
              style="
                text-align: center;
                vertical-align: top;
                background-color: #f0f4f9;
              "
            >
              <v-btn
                x-small
                fab
                outlined
                color="primary"
                @click="addNewRow"
                class="mt-3 mb-5 mx-5"
                :disabled="isTableOnProcess"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <EmployeeJobDialog ref="employeeJobDialogRef" />
      <AssignMultipleJobTimeDialog ref="assignMultipleJobTimeDialog" />
      <AssignMultipleJobDayDialog ref="assignMultipleJobDayDialog" />
      <AssignMultipleJobCellManageDialog
        ref="assignMultipleJobCellManageDialog"
      />
      <AppDialogConfirm ref="removeRowConfirmDialog" />
      <AppDialogConfirm ref="confirmTableRemoveDialog" />
      <AppDialogConfirm ref="confirmAllocateDriver" />
      <AppDialogConfirm ref="clearAllocateDriver" />
      <AppDialogConfirm ref="confirmAssignMultiJobs" />
      <AssignMultipleDuplicateTable ref="duplicateTableRef" />
      <AppOverlay :absolute="true" :is-loading="isLoading" />
    </div>
    <AssignMultipleJobWaitingDriverDrawer
      :is-open="isOpenDriverDrawer"
      :table-id="tableId"
      :table-view-height="tableViewHeight"
      @active-table-loading="setLoadingFromChild"
      @active-table-data-loading="refreshTableDataFromChild"
      @close="closeDriverDrawerHandler"
    />
  </div>
</template>

<script>
import _ from "lodash";
import AssignMultipleSelectedDrivers from "./AssignMultipleSelectedDrivers.vue";
import AssignMultipleJobWaitingDriverDrawer from "./drivers/AssignMultipleJobWaitingDriverDrawer.vue";
import AssignMultipleJobDriverTableCellInputState from "./AssignMultipleJobDriverTableCellInputState.vue";
import AssignMultipleJobTimeDialog from "./dialog/AssignMultipleJobTimeDialog.vue";
import AssignMultipleJobCellManageDialog from "./dialog/AssignMultipleJobCellManageDialog.vue";
import AssignMultipleDuplicateTable from "./dialog/AssignMultipleDuplicateTable.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";
import moment from "moment";
import { DialogType } from "@/services/dialog";

import {
  deleteMultipleJobTableHeaderService,
  deleteMultipleJobTableRowDataService,
  putAllocateToMultipleJobTableCellDataService,
  getMultipleJobTableInfoService,
  putCreateJobFromTableRowService,
  putDriverToMultipleJobService,
  putMultipleJobTableHeaderService,
  putMultipleJobTableRowDataService,
  deleteMultipleJobTableService,
  deleteClearAllocatedDriverInCellService,
} from "@/services/api/assign_multiple_job";
import AssignMultipleJobDayDialog from "./dialog/AssignMultipleJobDayDialog.vue";
import AssignMultipleJobDriverTableCellBox from "./AssignMultipleJobDriverTableCellBox.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "AssignMultipleJobTable",
  props: {
    tableId: {
      required: true,
    },
    sheetId: {
      required: true,
    },
    tableIdx: {
      required: true,
    },
  },
  data: () => ({
    tableViewHeight: 0,
    refId: null,
    tableResizeObserver: null,
    isLoading: false,
    isOpenDriverDrawer: false,
    isTableOnProcess: false,
    employeeDataList: [],
    scheduleDate: null,
  }),
  mounted() {
    this.matchHeight();
  },
  computed: {
    ...mapGetters({
      cellInTableById: "assignMultipleJob/cellInTableById",
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    tableSheetData: function () {
      return this.$store.state.assignMultipleJob.tableSheetData[this.tableId];
    },
    columnsData: function () {
      return this.$store.state.assignMultipleJob.columnsData[this.tableId];
    },
    rowData: function () {
      return this.$store.state.assignMultipleJob.rowData[this.tableId];
    },
    zoneList: function () {
      return this.$store.state.assignMultipleJob.zoneList;
    },
    driverInCart: function () {
      return this.$store.state.assignMultipleJob.driverInCart[this.tableId];
    },
    canEdit: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanEdit"];
    },
    canView: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanView"];
    },

    // tableViewHeight: function () {
    //   return this.$refs.tableViewRef ? this.$refs.tableViewRef.clientHeight : 0;
    // },
  },

  methods: {
    matchHeight() {
      this.refId = document.getElementById(`tableview-${this.tableId}`);

      if (this.refId !== null) {
        this.tableResizeObserver = new ResizeObserver(() => {
          this.tableViewHeight = this.refId.clientHeight;
        });
        this.tableResizeObserver.observe(this.refId);
      }
    },
    async getNewTableInfo() {
      try {
        const newResponseData = await getMultipleJobTableInfoService(
          this.$store,
          new URLSearchParams({
            id: this.tableId,
          }).toString()
        );
        if (newResponseData["code"] === 200) {
          this.$store.dispatch("assignMultipleJob/updateClearInputCell", {
            tableId: this.tableId,
          });
          this.$store.dispatch(
            "assignMultipleJob/createColsandRowToTable",
            newResponseData["data"]["table"]
          );
        } else {
          throw "GET_NEW_TABLE_FAIL";
        }
      } catch (error) {
        return error;
      }
    },
    async addNewColumn() {
      if (this.isTableOnProcess) {
        return;
      }

      const newColData = await this.$refs["assignMultipleJobTimeDialog"].open(
        this.tableId,
        null
      );

      // refresh all table
      if (newColData["status"] === 1) {
        this.isLoading = true;
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();

        try {
          // this.$store.dispatch("assignMultipleJob/updateMainLoading", true);
          const responseData = await putMultipleJobTableHeaderService(
            this.$store,
            queryString,
            newColData["data"]
          );

          if (responseData["code"] === 200) {
            // console.log("responseData", responseData);
            this.$store.dispatch("assignMultipleJob/updateClearInputCell", {
              tableId: this.tableId,
            });

            if (responseData["data"]["status"] === "OK") {
              this.$store.dispatch(
                "assignMultipleJob/createColsandRowToTable",
                responseData["data"]["table"]
              );
            }
          } else {
            throw "INSERT_FAIL";
          }
          // this.$store.dispatch("assignMultipleJob/updateMainLoading", false);
        } catch (error) {
          // this.$store.dispatch("assignMultipleJob/updateMainLoading", false);
        }

        this.isLoading = false;
      }
      // if (!_.isNil(newColData)) {
      //   this.$store.dispatch("assignMultipleJob/addNewColumn", {
      //     tableId: this.tableId,
      //     header: newColData,
      //   });
      //   this.$store.dispatch("assignMultipleJob/addNewColToRow", {
      //     tableId: this.tableId,
      //   });
      // }
    },
    async editNewColumn(header) {
      const { colsCode } = header;
      // this.getColumnsHasJob(header)
      if (this.isTableOnProcess || !this.canEdit) {
        return;
      }

      const editColData = await this.$refs["assignMultipleJobTimeDialog"].open(
        this.tableId,
        colsCode
      );

      if (editColData["status"] === 1) {
        this.isLoading = true;
        const queryString = new URLSearchParams({
          id: this.tableId,
          code: colsCode,
          mode: 1,
        }).toString();

        try {
          const responseData = await putMultipleJobTableHeaderService(
            this.$store,
            queryString,
            editColData["data"]
          );
          if (responseData["code"] === 200) {
            if (responseData["data"]["status"] === "OK") {
              this.$store.dispatch("assignMultipleJob/updateClearInputCell", {
                tableId: this.tableId,
              });

              this.$store.dispatch(
                "assignMultipleJob/createColsandRowToTable",
                responseData["data"]["table"]
              );
            }
          } else {
            throw "INSERT_FAIL";
          }
        } catch (error) {
          // TODO::
        }

        this.isLoading = false;
      } else if (editColData["status"] === -1) {
        // delete
        this.isLoading = true;
        try {
          const queryString = new URLSearchParams({
            id: this.tableId,
            code: colsCode,
          }).toString();

          const deleteResponseData = await deleteMultipleJobTableHeaderService(
            this.$store,
            queryString
          );

          if (deleteResponseData["code"] === 200) {
            await this.getNewTableInfo();
          } else {
            throw "DELETE_TABLE_COLS_FAIL";
          }
        } catch (error) {
          // TODO::
          console.error(error);
        }

        this.isLoading = false;
      }
    },
    async addNewRow() {
      if (this.isTableOnProcess) {
        return;
      }

      // NOTE:: add row
      const newRowData = await this.$refs["assignMultipleJobDayDialog"].open(
        this.tableId,
        null
      );

      if (newRowData !== null) {
        this.isLoading = true;
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();
        // table key
        const responseData = await putMultipleJobTableRowDataService(
          this.$store,
          queryString,
          newRowData
        );

        if (responseData["code"] === 200) {
          if (responseData["data"]["status"] === "OK") {
            this.$store.dispatch("assignMultipleJob/updateClearInputCell", {
              tableId: this.tableId,
            });

            this.$store.dispatch(
              "assignMultipleJob/createColsandRowToTable",
              responseData["data"]["table"]
            );
          }
        } else {
          console.error("INSERT_NEW_DATA_ROW_FAIL");
        }
        this.isLoading = false;
      }
    },
    async removeRow(rowsCode) {
      if (this.isTableOnProcess) {
        return;
      }

      if (
        await this.$refs.removeRowConfirmDialog.open(
          "ต้องการลบแถวข้อมูลนี้ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        //
        this.isLoading = true;
        try {
          const queryString = new URLSearchParams({
            id: this.tableId,
            code: rowsCode,
          }).toString();

          const deleteResponseData = await deleteMultipleJobTableRowDataService(
            store,
            queryString
          );

          if (deleteResponseData["code"] === 200) {
            await this.getNewTableInfo();
          } else {
            throw "DELETE_TABLE_ROWS_FAIL";
          }
        } catch (error) {
          console.error(error);
        }
        this.isLoading = false;
      }
    },

    // testHanlder() {
    //   this.$store.dispatch("assignMultipleJob/updateClearInputCell", {
    //     tableId: this.tableId,
    //   });
    // },
    //
    closeDriverDrawerHandler(val) {
      this.isOpenDriverDrawer = val;
    },

    //
    // job
    async createJobFromDateRow(selectRowData) {
      this.isTableOnProcess = true;
      if (
        await this.$refs.confirmAssignMultiJobs.open(
          "คุณต้องการเผยแพร่งาน ใช่หรือไม่ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.$store.dispatch("assignMultipleJob/updateRowDirectly", {
          tableId: this.tableId,
          rowIdx: selectRowData["row"],
          data: { jobOpsLoading: true },
        });

        try {
          const queryString = new URLSearchParams({
            id: this.tableId,
          }).toString();

          const responseData = await putCreateJobFromTableRowService(
            this.$store,
            queryString,
            {
              row: selectRowData["rowsCode"],
            }
          );

          if (responseData["code"] === 200) {
            this.$store.dispatch(
              "assignMultipleJob/updateJobStatusToDriverInCell",
              {
                tableId: this.tableId,
                data: responseData["data"],
              }
            );
          } else {
            throw "UPDATE_CREATED_JOB_DRIVER_FAIL";
          }
        } catch (error) {
          console.error(error);
        }

        this.$store.dispatch("assignMultipleJob/updateRowDirectly", {
          tableId: this.tableId,
          rowIdx: selectRowData["row"],
          data: { jobOpsLoading: false },
        });
      }
      this.isTableOnProcess = false;
    },
    async createJobFromDataTable() {
      this.isTableOnProcess = true;
      if (
        await this.$refs.confirmAssignMultiJobs.open(
          "คุณต้องการเผยแพร่งานทั้งหมด ใช่หรือไม่ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        const totalRowData = this.rowData.length;
        if (totalRowData === 0) {
          return;
        }
        for (let i = 0; i < totalRowData; i++) {
          this.$store.dispatch("assignMultipleJob/updateRowDirectly", {
            tableId: this.tableId,
            rowIdx: i,
            data: { jobOpsLoading: true },
          });
        }

        try {
          const queryString = new URLSearchParams({
            id: this.tableId,
          }).toString();
          for (let i = 0; i < totalRowData; i++) {
            const responseData = await putCreateJobFromTableRowService(
              this.$store,
              queryString,
              {
                row: this.rowData[i]["rowsCode"],
              }
            );
            if (responseData["code"] === 200) {
              this.$store.dispatch(
                "assignMultipleJob/updateJobStatusToDriverInCell",
                {
                  tableId: this.tableId,
                  data: responseData["data"],
                }
              );
            } else {
              throw "UPDATE_CREATED_JOB_DRIVER_FAIL";
            }
          }
        } catch (error) {
          console.error(error);
        }

        for (let i = 0; i < totalRowData; i++) {
          this.$store.dispatch("assignMultipleJob/updateRowDirectly", {
            tableId: this.tableId,
            rowIdx: i,
            data: { jobOpsLoading: false },
          });
        }
      }
      this.isTableOnProcess = false;
    },

    async getAllocateDriverToCell() {
      if (
        await this.$refs.confirmAllocateDriver.open(
          "คุณต้องการจัดงานอัตโนมัติ ใช่หรือไม่ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        if (this.isTableOnProcess) {
          return;
        }

        this.isLoading = true;
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();

        this.sumDriverStatOnSheet;

        const filterSumDriverStatOnSheet = Object.keys(
          this.sumDriverStatOnSheet
        ).map((key) => ({
          _id: key,
          jobs: this.sumDriverStatOnSheet[key]["jobs"],
          free: this.sumDriverStatOnSheet[key]["free"],
        }));

        const requestBody = {
          driverJobState: filterSumDriverStatOnSheet,
        };

        const responseData = await putAllocateToMultipleJobTableCellDataService(
          this.$store,
          queryString,
          requestBody
        );

        if (responseData["code"] === 200) {
          await this.getNewTableInfo();
        } else {
          console.error("ALLOCATE_DRIVER_FAIL");
        }
      }

      this.isLoading = false;
    },

    async clearAllocateDriverTCell() {
      if (
        await this.$refs.clearAllocateDriver.open(
          "คุณล้างการจัดงาน ใช่หรือไม่ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        if (this.isTableOnProcess) {
          return;
        }

        this.isLoading = true;
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();

        const responseData = await deleteClearAllocatedDriverInCellService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          await this.getNewTableInfo();
        } else {
          console.error("CLEAR_ALLOCATE_DRIVER_FAIL");
        }
      }

      this.isLoading = false;
    },

    // FORM INPUT
    nextInputHandler(currentInputValue) {
      try {
        const getCurrentCell = _.get(
          this.$store.state.assignMultipleJob.cellData,
          `T${currentInputValue.tableId}R${currentInputValue.rowIdx}C${currentInputValue.colIdx}`,
          null
        );

        if (getCurrentCell !== null) {
          const getCellAtTable = _.keys(
            _.pickBy(
              this.$store.state.assignMultipleJob.cellData,
              (item) => item["tableId"] === this.tableId
            )
          );

          for (
            let i = getCurrentCell["tableCellOrder"] + 1;
            i < getCellAtTable.length;
            i++
          ) {
            const _cell = _.get(
              this.$store.state.assignMultipleJob.cellData,
              `${getCellAtTable[i]}`,
              null
            );

            if (_cell["inputState"] === 0) {
              const nextRowElementId = document.getElementById(
                `${currentInputValue.tableId}-input-${_cell["row"]}-${_cell["col"]}`
              );

              if (nextRowElementId !== null) {
                nextRowElementId.focus();
                return;
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openCellManageDialog(selectedCellData) {
      // console.log("=> openCellManageDialog", selectedCellData);
      if (!this.canEdit) {
        return;
      }

      let cellData = _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${selectedCellData.tableId}R${selectedCellData.row}C${selectedCellData.col}`,
        null
      );

      const toUpdateData = await this.$refs[
        "assignMultipleJobCellManageDialog"
      ].open(this.tableId, selectedCellData);

      if (toUpdateData !== null) {
        try {
          const oldDriverIdInCell = _.cloneDeep(cellData["drivers"]).map(
            (item) => item._id
          );
          if (_.isNil(cellData)) {
            throw "CELL_DATA_NOT_FOUND";
          }

          const queryString = new URLSearchParams({
            id: cellData.cellMId,
          }).toString();

          // loading cell
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: cellData.cellId,
            updateData: {
              inputState: -1,
            },
          });
          const responseData = await putDriverToMultipleJobService(
            this.$store,
            queryString,
            _.pick(toUpdateData, ["drivers", "numberOfSelectedDriver"])
          );

          if (responseData["code"] === 200) {
            const { data } = responseData;

            // UpdateDriverCell ==>
            let updateCellData = {
              inputState: 1,
              drivers: toUpdateData.tableItems,
            };

            if (!_.isNil(toUpdateData["numberOfSelectedDriver"])) {
              updateCellData["numberOfSelectedDriver"] =
                toUpdateData["numberOfSelectedDriver"];
            }

            this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
              updateAtCellId: cellData.cellId,
              updateData: updateCellData,
            });
            // UpdateDriverCell <===
            //
            // updateCountToDriverInCartToTable ===>
            const diffDriverOld = _.difference(
              oldDriverIdInCell,
              toUpdateData["drivers"]
            );
            const diffDriverNew = _.difference(
              toUpdateData["drivers"],
              oldDriverIdInCell
            );

            let toUpdateDriverInCell = [];
            for (let i = 0; i < diffDriverOld.length; i++) {
              toUpdateDriverInCell.push({
                driverId: diffDriverOld[i],
                countCell: -1,
              });
            }
            for (let i = 0; i < diffDriverNew.length; i++) {
              toUpdateDriverInCell.push({
                driverId: diffDriverNew[i],
                countCell: 1,
              });
            }

            this.$store.dispatch(
              "assignMultipleJob/updateCountToDriverInCartToTable",
              {
                tableId: this.tableId,
                data: toUpdateDriverInCell,
              }
            );
            // <== updateCountToDriverInCartToTable
            //
            // update DriverInCellJobStatusByCellId ==>
            this.$store.commit(
              "assignMultipleJob/removeDriverInCellJobStatusByCellMId",
              { cellMId: cellData.cellMId }
            );

            const countOverlapJob = data["countOverlapJob"];

            // loop driver
            for (const [key, value] of Object.entries(data.drivers)) {
              this.$store.commit(
                "assignMultipleJob/setDriverInCellJobStatus",
                value
              );
            }

            //
            this.$store.commit("assignMultipleJob/setCellDataAt", {
              at: cellData.cellId,
              data: {
                isDriverHasGetJobOpsError: countOverlapJob ? true : false,
              },
            });

            // <== DriverInCellJobStatusByCellId
          } else {
            this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
              updateAtCellId: cellData.cellId,
              updateData: {
                inputState: -2,
              },
            });
          }
        } catch (error) {
          console.log(error);
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: cellData.cellId,
            updateData: {
              inputState: -2,
            },
          });
          console.error("UPDATE_DRIVER_TO_CELL_FAIL");
        }
      }
    },

    processDateSchedule() {
      // console.log("tableSheetData", this.tableSheetData["rows"]);
      let table = this.tableSheetData["rows"];
      this.scheduleDate = null;
      if (table.length > 1) {
        this.scheduleDate = {
          startDate: moment(table[0]["date"]).format("YYYY-MM-DD"),
          endDate: moment(table[table.length - 1]["date"]).format("YYYY-MM-DD"),
        };
      } else if (table.length == 1) {
        this.scheduleDate = {
          startDate: moment(table[0]["date"])
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      }
      // console.log("this.scheduleDate", this.scheduleDate);
    },

    processDateRowSchedule(row) {
      // console.log("row", row);
      // console.log("tableSheetData", this.tableSheetData["rows"]);

      this.scheduleDate = null;
      let table = _.values(
        _.pickBy(
          this.tableSheetData["rows"],
          (item) => item["id"] === row["id"]
        )
      );
      if (!_.isNil(table)) {
        if (table.length > 0) {
          this.scheduleDate = {
            startDate: moment(table[0]["date"])
              .add(1, "days")
              .format("YYYY-MM-DD"),
          };
        }
      }
      // console.log("this.scheduleDate", this.scheduleDate);
    },

    async handleRowSchedule(row) {
      const getCurrentRow = _.keys(
        _.pickBy(
          this.$store.state.assignMultipleJob.cellData,
          (item) => item["tableRowsId"] === row["id"]
        )
      );

      let employeeRowDataList = [];

      if (getCurrentRow.length > 0) {
        for (let i = 0; i < getCurrentRow.length; i++) {
          let drivers = _.get(
            this.$store.state.assignMultipleJob.cellData,
            `${getCurrentRow[i]}`,
            null
          );

          drivers["drivers"].forEach((employee) => {
            let fullName = `${
              employee["nickName"] ? employee["nickName"]["th"] : ""
            } ${employee["firstName"] ? employee["firstName"]["th"] : ""} ${
              employee["lastName"] ? employee["lastName"]["th"] : ""
            } ${employee["empId"] ? "(" + employee["empId"] + ")" : ""}`;
            if (!employeeRowDataList.some((e) => e.key === employee["_id"])) {
              employeeRowDataList.push({
                key: employee["_id"],
                value: fullName,
              });
            }
          });
        }
      }

      // console.log("employeeRowDataList", employeeRowDataList);
      this.processDateRowSchedule(row);
      let empJob = null;
      if (employeeRowDataList.length > 0) {
        empJob = await this.$refs.employeeJobDialogRef.open(
          employeeRowDataList,
          this.scheduleDate
        );
      } else {
        empJob = await this.$refs.employeeJobDialogRef.open();
      }
    },

    async handleSchedule() {
      this.employeeDataList = _.clone(this.driverInCart);
      // console.log("employeeDataList", this.employeeDataList);
      if (!_.isNil(this.employeeDataList)) {
        let initedEmpList = [];
        this.employeeDataList.forEach((employee) => {
          let fullName = `${
            employee["empId"] ? employee["empId"] + " - " : ""
          } ${employee["dpNameTh"] ? employee["dpNameTh"] : ""} ${
            employee["nickName"] ? "(" + employee["nickName"]["th"] + ")" : ""
          }`;

          if (!initedEmpList.some((e) => e.key === employee["_id"])) {
            initedEmpList.push({
              key: employee["_id"],
              value: fullName,
            });
          }
        });
        this.processDateSchedule();
        let empJob = null;
        if (initedEmpList.length > 0) {
          empJob = await this.$refs.employeeJobDialogRef.open(
            initedEmpList,
            this.scheduleDate
          );
        } else {
          empJob = await this.$refs.employeeJobDialogRef.open();
        }
      }
    },

    getTime(dateTime) {
      return moment(dateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    },

    // waiting driver dialog
    setLoadingFromChild(value) {
      this.isLoading = value;
    },
    async refreshTableDataFromChild() {
      await this.getNewTableInfo();
      this.isLoading = false;
    },
    // table
    getColumnsHasJob(header) {
      const { colsCode } = header;
      return (
        this.$store.state.assignMultipleJob.columnsStat[this.tableId][
          colsCode
        ] > 0
      );
    },
    async duplicateTable() {
      const updateData = await this.$refs.duplicateTableRef.open(this.tableId);
      if (updateData !== null) {
        if (updateData["sheetId"] === this.sheetId) {
          console.debug("in");
          this.$store.commit("assignMultipleJob/setForceGetData", null);
        }
      }
    },
    async removeTable() {
      this.isLoading = true;
      if (
        await this.$refs.confirmTableRemoveDialog.open(
          "ต้องการลบตารางนี้ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        try {
          const deleteResponseData = await deleteMultipleJobTableService(
            store,
            this.tableId
          );

          if (deleteResponseData["code"] === 200) {
            this.$store.dispatch("assignMultipleJob/clearDataByTableId", {
              tableId: this.tableId,
            });
          } else {
            throw "DELETE_TABLE_FAIL";
          }
        } catch (error) {
          console.error(error);
        }
      }

      this.isLoading = false;
    },
  },
  components: {
    AssignMultipleSelectedDrivers,
    AssignMultipleJobWaitingDriverDrawer,
    AssignMultipleJobDriverTableCellInputState,
    // dialog
    AssignMultipleJobTimeDialog,
    AssignMultipleJobCellManageDialog,
    AssignMultipleJobDayDialog,
    AssignMultipleJobDriverTableCellBox,
    AssignMultipleDuplicateTable,
    EmployeeJobDialog,
    AppOverlay,
    AppDialogConfirm,
  },

  beforeDestroy() {
    this.tableResizeObserver.disconnect();
    this.tableResizeObserver = null;
  },
};
</script>

<style scoped lang="scss">
table.app-assign-mul-job {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#ccc, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: transparentize(#ccc, 0.8);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

table.app-assign-mul-job tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
  min-height: 400px;
}

table.app-assign-mul-job,
th,
td {
  background: #f7faff;
  border: 1px solid #c7ced7;
  border-collapse: collapse;
  border-top: 0;
}

table.app-assign-mul-job tr td:first-child {
  border-left: none;
}

.table-container {
  position: relative;
  // padding-right: 3.5em;
  min-height: 400px;
}

.table-text-noData {
  vertical-align: top;
}

.table-text-pair-cols {
  background: #ecf1f8;
}

table.app-assign-mul-job td:first-child {
  position: sticky;
  left: 0;
}

.table-data-bg-cols {
  background: #ffffff;
}
</style>
