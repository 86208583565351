<template>
  <h2 v-if="!show" class="ma-6 text-center">ไม่พบหน้านี้</h2>
</template>
<script>
export default {
  name: "AppPageNotFound",

  components: {},

  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {};
  },

  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
