<template>
  <v-app-bar dense color="white" v-if="jobData">
    <div>
      <p class="ma-0 text-detail-bar">รหัสงาน</p>
      <p class="ma-0 text-detail-bar font-weight-bold black--text">
        {{ jobData.jobCode }}
      </p>
    </div>
    <v-divider class="mx-4 py-3" vertical />
    <div class="d-inline-flex">
      <v-avatar size="32">
        <v-img
          :src="
            jobData
              ? defaultPFImage
              : jobData.userId.profileimage === null
              ? defaultPFImage
              : jobData.userId.profileimage
          "
        >
        </v-img>
      </v-avatar>
      <div class="pl-2">
        <p class="ma-0 text-detail-bar font-weight-bold black--text">
          {{ jobData.userId.dpNameTh }}
        </p>
        <p class="ma-0 text-detail-bar">
          เบอร์โทร: {{ jobData.userId.dpPhoneNumeber }}
        </p>
      </div>
    </div>
    <!-- <v-divider class="mx-6 py-3" vertical />
    <div>
      <p class="ma-0 text-detail-bar font-weight-bold black--text">
        {{ jobData.contract.title }}
      </p>
      <p class="ma-0 text-detail-bar">
        เลขที่สัญญา: {{ jobData.contract.contractNumber }}
      </p>
    </div> -->
    <v-spacer></v-spacer>
    <v-chip class="mr-1" v-if="jobPublic" color="green" dark>
      <v-icon left> mdi-earth </v-icon>
      กำลังเผยแพร่
    </v-chip>
    <v-chip class="mr-1" v-if="!jobPublic" color="red" dark>
      <v-icon left> mdi-alert-octagon </v-icon>
      ยังไม่ได้กำลังเผยแพร่
    </v-chip>
    <v-menu offset-y left :disabled="!isPermissionCanEdit">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-detail-bar"
          :color="jobsStatusColor(jobStatus)"
          dark
          v-bind="attrs"
          v-on="on"
          elevation="0"
        >
          {{ jobsStatusText(jobStatus) }}
          <v-icon> mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group v-model="selectedStatus">
          <v-list-item
            v-for="(item, index) in jobStatusList"
            :key="index"
            :disabled="item.disabled === 1 ? true : false"
          >
            <v-list-item-title>{{
              jobsStatusText(item.key)
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- Add dialog confirm -->
    <AppDialogConfirm ref="DetailBarAppDialongConfirm" />
  </v-app-bar>
  <v-app-bar dense color="white" v-else>
    <v-app-bar-title> เพิ่มข้อมูลงาน </v-app-bar-title>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { putJobStatusService } from "@/services/api/jobs";
import { processPermission } from "@/services/permissions";
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  name: "JobsDetailBarComponent",
  data: () => ({
    defaultPFImage: defaultImage,
    jobStatusList: [],
    selectedStatus: null,
    previousSelectedStatus: null,
    jobData: null,
    jobStatus: null,
    jobPublic: null,
    isPermissionCanEdit: false,
  }),
  mounted() {
    this.checkUserPermissions();
  },
  watch: {
    selectedStatus: function (newValue) {
      if (!_.isNil(newValue)) {
        if (newValue !== this.previousSelectedStatus) {
          this.openConfirmDialog();
        }
      }
    },
    "$store.state.manageRoute.jobData": async function (newJobData) {
      if (!_.isNil(newJobData)) {
        this.setData(newJobData);
      }
    },
  },

  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
    }),
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");

        if (this.permList.admin_all === true) {
          this.isPermissionCanEdit = true;
          return;
        }

        if (this.permList.jobs_info) {
          this.isPermissionCanEdit = false;
        }

        if (this.permList.jobs_crud) {
          this.isPermissionCanEdit = true;
        }
      }
    },

    setData(jobData) {
      // console.log("JobData", jobData);
      this.jobData = _.cloneDeep(this.$store.state.manageRoute.jobData);
      this.jobPublic = _.get(this.jobData, "public", false);
      this.jobStatusList = _.cloneDeep(jobData["jobStatusList"]);
      this.processJobStatusList();
    },
    processJobStatusList() {
      for (let i = 0; i < this.jobStatusList.length; i++) {
        if (this.jobStatusList[i]["selected"]) {
          this.jobStatus = this.jobStatusList[i]["key"];
          this.previousSelectedStatus = i;
          this.selectedStatus = i;

          break;
        }
      }
    },

    async openConfirmDialog() {
      let _dialogStatus = await this.$refs.DetailBarAppDialongConfirm.open(
        "ต้องการเปลี่ยนสถานะของงาน ?",
        null,
        DialogType.ACTION,
        {
          noconfirm: true,
        }
      );

      if (_dialogStatus) {
        // call services
        // show reload
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let respData = await putJobStatusService(
          this.$store,
          `id=${this.jobData["_id"]}`,
          { status: this.jobStatusList[this.selectedStatus]["key"] }
        );

        this.jobStatusList = [];

        if (respData["code"] === 200) {
          this.$store.dispatch("manageRoute/updateforceGetData", true);
        } else {
          // fail to update
          this.selectedStatus = this.previousSelectedStatus;
          this.jobStatus =
            this.jobStatusList[this.previousSelectedStatus]["key"];
        }

        this.$store.dispatch("manageRoute/updateMainLoading", false);
      } else {
        this.selectedStatus = this.previousSelectedStatus;
      }
    },
  },
  components: { AppDialogConfirm },
};
</script>

<style lang="scss" scoped>
.text-detail-bar {
  font-size: 12px;
}
</style>
