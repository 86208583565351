<template>
	<div>
		<v-item-group
			:mandatory="customDate.length == 0"
			v-model="selectedMonthTab"
			class="pb-1"
		>
			<v-row no-gutters class="pa-0">
				<v-col
					v-for="(month, idx) in tabTitleList"
					:key="idx"
					cols="12"
					sm="2"
					md="1"
					class="pa-1"
				>
					<v-item v-slot="{ active, toggle }">
						<v-card
							:color="active ? 'darkItemColor' : ''"
							style="background-color: #323841"
							:outlined="!active"
							class="d-flex align-center"
							dark
							height="40"
							@click="toggle"
						>
							<div
								class="text-app-title font-weight-bold flex-grow-1 text-center"
							>
								{{ month }}
							</div>
						</v-card>
					</v-item>
				</v-col>
				<v-divider vertical class="mx-1 my-1" />
				<v-col cols="4" class="pa-1">
					<CustomWorksheetFilterDateTypingPickerRange
						placeholder="เลือก"
						title="เลือกช่วงเวลา"
						:isShowTitle="false"
						v-model="customDate"
						:isRequired="false"
						:isOptionRange="false"
						:hideDetail="true"
					/>
				</v-col>
			</v-row>
		</v-item-group>
	</div>
</template>
<script>
	import moment from "moment";
	import CustomWorksheetFilterDateTypingPickerRange from "@/components/CustomWorksheetFilterDateTypingPickerRange.vue";
	export default {
		name: "WorksheetMonthlyTab",
		components: { CustomWorksheetFilterDateTypingPickerRange },
		props: {
			selectedMonth: {
				type: Number,
				default: undefined,
			},
		},
		data() {
			return {
				selectedMonthTab: 0,
				tabTitleList: [],
				tabDataList: [],
				customDate: [],
				currentLoadingDate: [],
				// isTabClicked: false,
			};
		},
		mounted() {
			this.initMonthlyTab();
		},
		watch: {
			customDate(val) {
				// custom v-text-field range child
				if (this.currentLoadingDate == val) {
					return;
				}
				this.currentLoadingDate = val;

				this.countCustomDateAccess += 1;
				if (val.length == 0) {
					if (_.isNil(this.selectedMonthTab)) {
						this.selectedMonthTab = 0;
					}
					return;
				}

				if (val.length == 1) {
					this.selectedMonthTab = undefined;
					return;
				}

				// startDate and endDate are both specified
				let item = { startDate: val[0], endDate: val[1] };

				this.$emit("processSelectedMonthTitle", 5);
				this.$emit("processSelectedMonth", item);
			},
			selectedMonth(val) {
				// props from parent
				if (_.isNil(val)) {
					this.selectedMonthTab = undefined;
				} else if (val == 0) {
					this.selectedMonthTab = 0;
				}
			},

			selectedMonthTab(val) {
				// local tab variable
				if (_.isNil(val)) {
					return;
				}

				if (this.customDate.length > 0) {
					this.currentLoadingDate = [];
					this.customDate = [];

					// return;
				}
				this.$emit("processSelectedMonthTitle", val);
				this.$emit("processSelectedMonth", this.tabDataList[val]);
			},
		},
		methods: {
			initMonthlyTab() {
				this.tabTitleList = [];
				this.tabDataList = [];
				let tabTitleItem = null;
				let startOfMonth = null;
				let endOfMonth = null;
				let tabItem = null;
				for (let i = 0; i < 5; i++) {
					tabTitleItem = moment().subtract(i, "month");
					this.tabTitleList.push(tabTitleItem.format("YYYY/MM"));

					startOfMonth = tabTitleItem.startOf("month").format("YYYY-MM-DD");
					endOfMonth = tabTitleItem.endOf("month").format("YYYY-MM-DD");
					tabItem = { startDate: startOfMonth, endDate: endOfMonth };
					this.tabDataList.push(tabItem);
				}
			},
		},
	};
</script>
