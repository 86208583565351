const appTimeZone = "Asia/Bangkok";
const appTimeStringEng = "en-US";
const appTimeStringThai = "th";
export const appDateYearMonthDayWithEnDashFormat = "YYYY-MM-DD";

const month_names = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const month_names_short = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export function getDateStringNow() {
	var now = new Date();
	var year = now.getFullYear();
	var month = now.getMonth() + 1;
	var day = now.getDate();
	var hour = now.getHours();
	var minute = now.getMinutes();
	var second = now.getSeconds();
	if (month.toString().length == 1) {
		month = "0" + month;
	}
	if (day.toString().length == 1) {
		day = "0" + day;
	}
	if (hour.toString().length == 1) {
		hour = "0" + hour;
	}
	if (minute.toString().length == 1) {
		minute = "0" + minute;
	}
	if (second.toString().length == 1) {
		second = "0" + second;
	}
	var dateTime = year + "-" + month + "-" + day;
	return dateTime;
}

function formatDate(date) {
	// console.log("formatDate", date);
	var d = new Date(date),
		month = "" + month_names_short[d.getMonth()],
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [day, month, year].join(" ");
}

function formatNumericMonthYear(date) {
	// console.log("formatDate", date);
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		year = d.getFullYear();

	// console.log(month.length);
	if (month.length < 2) month = "0" + month;

	return [month, year].join("/");
}

function formatTimeDate(date, showSecond) {
	let d = new Date(date),
		month = "" + month_names_short[d.getMonth()],
		day = "" + d.getDate(),
		year = d.getFullYear(),
		_hour = `${d.getHours()}`,
		_min = `${d.getMinutes()}`,
		_second = showSecond ? `${d.getSeconds()}` : "",
		_milimin = `${d.getMilliseconds()}`;

	let timeSequence = [];
	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	if (_hour.length < 2) _hour = "0" + _hour;
	timeSequence.push(_hour);
	if (_min.length < 2) _min = "0" + _min;
	timeSequence.push(_min);
	if (showSecond) {
		if (_second.length < 2) _second = 0 + `${_second}`;
		timeSequence.push(_second);
	}

	// if (_second.length < 2) _milimin = "0" + _milimin;
	let dateStr = [day, month, year].join(" ");
	let timeStr = timeSequence.join(":");
	//

	return dateStr + " " + timeStr;
	// return d.toLocaleTimeString(appTimeStringEng, {
	//   year: "numeric",
	//   month: "short",
	//   day: "numeric",
	//   hour: "2-digit",
	//   minute: "2-digit",
	//   second: "2-digit",
	//   hour12: false,
	// });
}

export function dateStringToDateFormat(dateData, localParams) {
	let _type = "date";
	let _locationformatString = appTimeStringEng;
	let _showSecond = true;
	if (dateData === null || dateData === undefined) {
		return null;
	}

	if (localParams !== undefined && localParams !== null) {
		if (localParams["type"] !== undefined) {
			_type = localParams["type"];
		}
		if (localParams["returnThai"] !== undefined) {
			_locationformatString =
				localParams["returnThai"] === true
					? appTimeStringThai
					: appTimeStringEng;
		}
		if (localParams["showSecond"] !== undefined) {
			_showSecond = localParams["showSecond"] === true ? true : false;
		}
	}
	//https://stackoverflow.com/questions/27939773/tolocaledatestring-short-format
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options

	switch (_type) {
		case "date":
			if (_locationformatString === appTimeStringThai) {
				let _rd = new Date(dateData);
				return _rd.toLocaleString(_locationformatString, {
					timeZone: appTimeZone,
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			} else {
				return formatDate(dateData, false);
			}

		case "datetime":
			if (_locationformatString === appTimeStringThai) {
				let _rd = new Date(dateData);

				let _prd = _rd.toLocaleTimeString(_locationformatString, {
					timeZone: appTimeZone,
					year: "numeric",
					month: "short",
					day: "numeric",
				});

				if (!_showSecond) {
					return _prd.slice(0, -3);
				} else {
					return _prd;
				}
			} else {
				return formatTimeDate(dateData, _showSecond);
			}

		case "monthYear":
			return formatNumericMonthYear(dateData);

		default:
			return null;
	}
}

export function timeStringFormat(dateData, showSecond) {
	let _d = new Date(dateData);
	let timeSequence = [];
	let _hour = `${_d.getHours()}`,
		_min = `${_d.getMinutes()}`,
		_second = `${_d.getSeconds()}`,
		_milimin = `${_d.getMilliseconds()}`;

	timeSequence.push(_hour);

	if (_min.length < 2) {
		_min = "0" + _min;
	}
	timeSequence.push(_min);

	if (showSecond) {
		if (_second.length < 2) {
			_second = 0 + `${_second}`;
		}
		timeSequence.push(_second);
	}

	return timeSequence.join(":");
}

export function checkDateValidate(d) {
	if (Object.prototype.toString.call(d) === "[object Date]") {
		// it is a date
		if (isNaN(d)) {
			// d.getTime() or d.valueOf() will also work
			// date object is not valid
			return false;
		} else {
			// date object is valid
			return true;
		}
	} else {
		// not a date object
		return false;
	}
}
export function getDayNameHelper(dayNumber) {
	switch (dayNumber) {
		case 0:
			return "อาทิตย์";
		case 1:
			return "จันทร์";
		case 2:
			return "อังคาร";
		case 3:
			return "พุธ";
		case 4:
			return "พฤหัส";
		case 5:
			return "ศุกร์";
		case 6:
			return "เสาร์";
	}
}

export function getShortMonthNameHelper(dayNumber) {
	switch (dayNumber) {
		case 0:
			return "มค";
		case 1:
			return "กพ";
		case 2:
			return "มีค";
		case 3:
			return "เมย";
		case 4:
			return "พค";
		case 5:
			return "มิย";
		case 6:
			return "กค";
		case 7:
			return "สค";
		case 8:
			return "กย";
		case 9:
			return "ตค";
		case 10:
			return "พย";
		case 11:
			return "ธค";
		default:
			return "";
	}
}

// let strTest = "2022-09-02T17:00:00.000Z";
// r1 = dateStringToDateFormat(strTest);
// r2 = dateStringToDateFormat(strTest, { type: "datetime" });
// r3 = dateStringToDateFormat(strTest, { type: "date", returnThai: true });
// r4 = dateStringToDateFormat(strTest, { type: "datetime", returnThai: true });

// console.log(r1);
// console.log(r2);
// console.log(r3);
// console.log(r4);
