<template>
  <section name="VehicleCreateViewManage">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />
    <v-container>
      <v-row dense name="vehicleInformationForm" v-if="isPermissionCanView">
        <v-col lg="9" md="12" sm="12">
          <VehicleFormData
            title="แก้ไขข้อมูลรถ"
            :vehicle-fund-data="fundData"
            :vehicle-data="data"
            :is-edit="true"
            :is-loading="isLoading"
            :is-permission-can-edit="isPermissionCanEdit"
            @loading-func="setLoading"
            @set-gps-status="setGpsStatus"
            @call-api-func="callGetData"
          />
          <VehicleFeedCardData
            class="pt-1 px-0"
            title="บัตรเติมน้ำมัน (Fleet card)"
            :vehicle-fund-data="fundData"
            :vehicle-id="_id"
            :is-edit="true"
            :is-loading="isLoading"
            :is-permission-can-edit="isPermissionCanEdit"
          />
        </v-col>
        <VehicleFormDataEditRightSide
          :mongo-id="_id"
          :vehicle-data="data"
          :gps-box-status="gpsBoxStatus"
          @loading-func="setLoading"
          @call-api-func="callGetData"
        />
      </v-row>
    </v-container>
    <!--  -->
    <AppPageNotFound :show="isPermissionCanView" />
    <AppOverlay :isLoading="isLoading" />

    <AppDialogConfirm ref="alertSaving" />
    <AppDialogReloadForm ref="dialogReloadForm" />
  </section>
</template>

<script>
import _ from "lodash";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
//
import VehicleFormData from "./VehicleFormData.vue";
import VehicleFeedCardData from "./VehicleFeedCardData.vue";
import AppDialogReloadForm from "@/components/AppDialogReloadForm.vue";
import {
  getVehicleFundamental,
  getVehicleInfoService,
} from "@/services/api/vehicle";
import VehicleFormDataEditRightSide from "./VehicleFormDataEditRightSide.vue";
import { URL_VEHICLE_LIST } from "@/services/routerQueryPath";

export default {
  name: "VehicleEditView",

  data() {
    return {
      title: "",
      permList: {},
      _id: null,
      isLoading: false,
      data: null,
      fundData: null,
      breadcrumbsItems: [
        {
          text: "รายการรถ",
          disabled: false,
          to: `/vehicle${URL_VEHICLE_LIST}`,
        },
        {
          text: "แก้ไขข้อมูลรถ",
          disabled: true,
          to: "",
        },
      ],
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      //
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      gpsBoxStatus: false,
    };
  },
  methods: {
    async startPage() {
      if (_.isNil(this.$route.params.id)) {
        this.$router.push(`/vehicle${URL_VEHICLE_LIST}`);
      }
      this._id = this.$route.params.id;
      this.checkUserPermissions();
      this.isLoading = true;
      await this.getFundData();
      await this.getData();
      this.isLoading = false;
    },
    async getFundData() {
      // get fundation data
      let respData = await getVehicleFundamental(this.$store, "type=input");
      if (respData["code"] === 200) {
        this.fundData = _.clone(respData["data"]);
      } else {
        this.$router.push(`/vehicle${URL_VEHICLE_LIST}`);
      }
    },
    async getData() {
      let respData = await getVehicleInfoService(
        this.$store,
        new URLSearchParams({
          id: this._id,
          type: "input",
        }).toString()
      );
      if (respData["code"] === 200) {
        this.data = _.clone(respData["data"]);
      } else {
        this.$router.push(`/vehicle${URL_VEHICLE_LIST}`);
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE");

        if (this.permList.vehicle_info) {
          this.isPermissionCanView = true;
        }

        if (this.permList.admin_all || this.permList.vehicle_info_crud) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    setLoading(currentStatus) {
      this.isLoading = currentStatus;
    },
    async callGetData() {
      this.isLoading = true;
      await this.getData();
      this.isLoading = false;
    },

    setGpsStatus(toggleValue) {
      this.gpsBoxStatus = toggleValue;
      // console.log("get to set gps status", this.gpsBoxStatus);
    },
  },
  components: {
    HeaderCardNormal,
    AppOverlay,
    AppDialogConfirm,
    AppBreadCrumbs,
    AppPageNotFound,
    VehicleFormData,
    VehicleFeedCardData,
    AppDialogReloadForm,
    VehicleFormDataEditRightSide,
  },
  mounted() {
    this.checkUserPermissions();
    this.startPage();
  },
};
</script>

<style lang="scss" scoped>
@import "./VehicleStyle.scss";
</style>
