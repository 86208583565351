import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

import axios from "axios";

export async function getJobMonitorListService(store, queryString) {
  let uri = "/job-monitor";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobMonitorInfoService(store, queryString) {
  let uri = `/job-monitor/info`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

//
export async function getJobMonitorAllVehiclePositionService(
  store,
  queryString
) {
  let uri = `/job-monitor/all-vehicle-location`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobMonitorVehiclePositionService(store, queryString) {
  let uri = `/job-monitor/vehicle-location`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
