<template>
  <v-card width="300">
    <v-simple-table v-if="items.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, headIdx) in headers"
              :key="headIdx"
              class="text-left black--text table_header_text_style px-4 py-3"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, idx) in items"
            :key="idx"
            @click="handleClick(row)"
            :bgcolor="getColor(row)"
          >
            <td>
              <span>{{ row["code"] }}</span>
            </td>
            <td>
              <span>{{ row["name"] }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table v-if="items.length === 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, headIdx) in headers"
              :key="headIdx"
              class="text-left black--text table_header_text_style px-4 py-3"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">
              <!-- font-weight-bold  -->
              <p class="mb-0 text-center">ไม่พบใบสรุปการเงิน</p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  name: "evidenceWorksheetPreviewDialog",
  components: {},
  props: {
    // items: [],
    selectedItem: {},
  },

  data() {
    return {
      headers: ["รหัสใบสรุปงาน", "ชื่อใบสรุปงาน"],
      currentPaymenId: "",
      items: [],
    };
  },
  watch: {},

  computed: {},

  mounted() {
    this.currentPaymenId = this.selectedItem.paymentId || "";
  },

  unmounted() {},

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    handleClick(row) {
      this.currentPaymenId = row._id;
      this.$emit("on-select", row);
    },
    getColor(row) {
      return row._id === this.currentPaymenId ? "#C8E6C9" : "";
    },
  },
};
</script>

<style scoped lang="scss"></style>
