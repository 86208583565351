import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getJobEvidencePaymentListService(store, queryString) {
  let uri = "/job/job-evidence-payment";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobEvidenceWorksheetListService(store, queryString) {
  let uri = "/job/job-evidence-worksheet";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postJobEvidenceWorksheetListService(store, queryString) {
  let uri = "/job/job-evidence-worksheet";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putJobEvidenceWorksheetToPaymentService(
  store,
  queryString,
  data
) {
  let uri = "/job/job-evidence-payment";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}
