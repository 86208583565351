<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { getNewGoogleMapObj } from "@/views/jobs/manageView/googleMapComponents/GoogleMapService";
import { MAP_STYLE_SETTINGS } from "@/services/map/mapSettings";

export default {
  name: "MonitorGMapLoader",
  props: {
    mapConfig: {
      type: [Object, null],
      default: () => {},
    },
    googleAPIKey: {
      type: String,
    },
    vuexStatusActionName: {
      type: [String, null],
      default: null,
    },
    vuexStoreName: {
      type: [String, null],
      default: "jobsMonitoring",
    },
  },

  watch: {
    "$store.state.jobsMonitoring.currentLatLngCenterBoundList": function (
      newData
    ) {
      this.updateLatLngBound(newData);
    },
    mapConfig: function () {
      this.processMapSetting(this.mapConfig);
    },
  },

  data() {
    return {
      google: null,
      map: null,
    };
  },
  async mounted() {
    this.google = await getNewGoogleMapObj();
    this.initializeMap();
  },

  methods: {
    async initializeMap() {
      const mapConfiguration = {
        // mapId: process.env.VUE_APP_GMAP_ID,
        disableDefaultUI: true,
        backgroundColor: "transparent",
        gestureHandling: "greedy",
        zoom: 10,
        ...MAP_STYLE_SETTINGS,
        ...this.mapConfig,
      };
      //
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, mapConfiguration);

      // wait for init
      this.google.maps.event.addListenerOnce(this.map, "idle", () => {
        this.processMapSetting(this.mapConfig);
        this.$store.dispatch(
          `${this.vuexStoreName}/${this.vuexStatusActionName}`,
          true
        );
      });
    },
    processMapSetting(settingObject) {
      if (this.map === null) {
        return;
      }

      if (!_.isNil(settingObject["center"])) {
        this.map.setCenter(
          new this.google.maps.LatLng(
            settingObject["center"]["lat"],
            settingObject["center"]["lng"]
          )
        );
      }

      if (!_.isNil(settingObject["zoom"])) {
        this.map.setZoom(settingObject["zoom"]);
      }
    },
    updateBoundMap(_param) {
      if (this.map) {
        let bounds = new this.google.maps.LatLngBounds();
        bounds.extend(_param["northeast"]);
        bounds.extend(_param["southwest"]);
        this.map.fitBounds(bounds);
      }
    },
    updateLatLngBound(latlngArr) {
      if (this.map) {
        if (latlngArr.length === 0) {
          const _lnt = 13.736717;
          const _lng = 100.523186;
          const defaultPoisition = new google.maps.LatLng(_lnt, _lng);

          let bounds = new this.google.maps.LatLngBounds();
          bounds.extend(defaultPoisition);

          this.map.setCenter(defaultPoisition);
          this.map.fitBounds(bounds);
          this.map.setZoom(15);

          return;
        }

        let bounds = new this.google.maps.LatLngBounds();
        for (let i = 0; i < latlngArr.length; i++) {
          let latlng = new google.maps.LatLng(latlngArr[i][0], latlngArr[i][1]);
          bounds.extend(latlng);
        }
        this.map.fitBounds(bounds);
        let _sumDistance = 0;
        let zoomLevel = 13;

        const jobDataList =
          this.$store.state[this.vuexStoreName].currentRouteInfo;

        for (let i = 0; i < jobDataList.length; i++) {
          for (let j = 0; j < jobDataList[i]["markers"].length; j++) {
            const marker = jobDataList[i]["markers"][j];
            if (!_.isNil(marker["distance"])) {
              _sumDistance += marker["distance"];
            }
          }
        }
        //
        _sumDistance = _sumDistance / 1000;
        if (_sumDistance > 50) {
          zoomLevel = 10;
        } else if (_sumDistance > 20) {
          zoomLevel = 11;
        } else {
          zoomLevel = 14;
        }
        this.map.setZoom(zoomLevel);
      }
    },
  },

  destroyed() {},
};
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
