<template>
  <div name="TimeOffFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

      <div class="pa-6">
        <v-row dense>
          <v-col cols="12">
            <div>
              <InputView title="เลือกหัวข้อที่โดนหัก" :isRequired="false">
                <v-select
                  :items="titleList"
                  v-model="formData.topic"
                  placeholder="เลือก"
                  item-value="topic"
                  item-text="topic"
                  outlined
                  rounded
                  dense
                  filled
                  class="app-custom-input"
                  :loading="isLoading"
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row dense>
          <v-col cols="12">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="เลือกช่วงเวลาการลา"
                v-model="formData.timeoffDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row> -->
        <!-- 
        <v-row dense> </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="6">
            <div>
              <InputView title="ประเภทการลา" :isRequired="false">
                <v-btn-toggle
                  v-model="formData.empTimeOffType"
                  dense
                  color="primary"
                  mandatory
                >
                  <v-btn v-for="(status, idx) in empTimeOffType" :key="idx">
                    {{ status }}
                  </v-btn>
                </v-btn-toggle>
              </InputView>
            </div>
          </v-col>
        </v-row> -->
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import { getAppDataService } from "@/services/api/app";
import { getContractTitleListService } from "@/services/api/contract";
import { getPenaltyTitleSelectList } from "@/services/api/employee";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import InputView from "@/components/InputView.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";

export default {
  name: "TimeOffFilterGroup",

  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    InputView,
    CustomTextInputImprove,
    CustomFilterDateTypingPickerRange,
  },

  mounted() {
    this.getData();
    // set from query
    this.getParamQuery();
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        topic: "topic",
      },
      formData: {
        topic: null,
      },
      copyFormData: null,
      // from api
      isLoading: false,
      titleList: [],
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      this.isLoading = true;
      try {
        let respPenaltyTitleList = await getPenaltyTitleSelectList(this.$store);
        if (respPenaltyTitleList["code"] === 200) {
          this.titleList = respPenaltyTitleList["data"];
          // console.log("titleList", this.titleList);
        }
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      // console.log("filterParams", filterParams);
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      // clear all
      this.copyFormData = _.cloneDeep(this.formData);

      this.formData = {
        topic: null,
      };
    },
  },
  computed: {
    empTimeOffType() {
      return this.$store.getters["employee/empTimeOffTypeList"] || [];
    },
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
