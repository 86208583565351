<template>
	<div name="vehicleOverviewDashboard">
		<v-container v-if="isPermissionCanView">
			<v-row dense>
				<v-col cols="auto">
					<div class="text-h5 mt-1">รายงานภาพรวมรถ</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<OverallVehicleCard :data="data" />
				</v-col>
				<v-col cols="12" class="pb-2">
					<v-card class="px-6" rounded="lg">
						<v-card-text>
							<CustomFilterDateTypingPickerRange
								placeholder="เลือก"
								title="เลือกช่วงเวลา"
								v-model="joinDate"
								:isRequired="false"
								:isSameLine="true"
								:initRange="initRangeDate"
							/>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<VehicleBreakEvenPointChart :data="data" :numDays="numDays" />
				</v-col>

				<v-col cols="12">
					<VehicleIncomeExpenseCard :data="data" />
				</v-col>

				<v-col cols="12">
					<VehicleJobInfoCard :data="data" />
				</v-col>

				<v-col cols="12">
					<InstallmentLineChart :data="data" />
				</v-col>

				<v-col cols="12">
					<VehicleEventChartCard :data="data" />
				</v-col>
			</v-row>
		</v-container>
		<AppOverlay :isLoading="isLoading" />
		<AppPageNotFound v-if="!isPermissionCanView" />
	</div>
</template>
<script>
	import _ from "lodash";
	import AppOverlay from "@/components/AppOverlay.vue";
	import InputView from "@/components/InputView.vue";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { processPermission } from "@/services/permissions";
	import { mapGetters } from "vuex";
	import VueApexCharts from "vue-apexcharts";
	import moment from "moment";
	import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
	import OverallVehicleCard from "./chart-components/OverallVehicleCard.vue";
	import InstallmentLineChart from "./chart-components/InstallmentLineChart.vue";
	import VehicleEventChartCard from "./chart-components/VehicleEventChartCard.vue";
	import { getOverAllVehicleReportService } from "@/services/api/vehicle_report";
	import VehicleBreakEvenPointChart from "./chart-components/VehicleBreakEvenPointChart.vue";
	import VehicleJobInfoCard from "./chart-components/VehicleJobInfoCard.vue";
	import VehicleIncomeExpenseCard from "./chart-components/VehicleIncomeExpenseCard.vue";

	export default {
		name: "VehicleOverviewDashboard",
		components: {
			AppOverlay,
			InputView,
			AppPageNotFound,
			CustomFilterDateTypingPickerRange,
			apexchart: VueApexCharts,
			OverallVehicleCard,
			InstallmentLineChart,
			VehicleEventChartCard,
			VehicleBreakEvenPointChart,
			VehicleJobInfoCard,
			VehicleIncomeExpenseCard,
		},
		mounted() {
			this.checkUserPermissions();
			if (this.isPermissionCanView) {
				this.startPage();
			}
		},
		data() {
			return {
				isPermissionCanView: false,
				isLoading: false,
				joinDate: [], // for date
				startDate: null,
				endDate: null,
				startDateCurrent: null,
				endDateCurrent: null,
				initRangeDate: 90,
				numDays: 90,
				data: null,
			};
		},

		computed: {},
		watch: {
			joinDate(newDate, oldDate) {
				// console.log("joinDate", newDate);
				if (!_.isNil(newDate)) {
					// console.log("newDate", newDate);
					if (newDate.length === 2) {
						this.startDate = newDate[0];
						this.endDate = newDate[1];

						if (
							this.startDateCurrent !== this.startDate ||
							this.endDateCurrent !== this.endDate
						) {
							let isSorted =
								moment(this.startDate).diff(moment(this.endDate)) <= 0;
							// console.log(moment(this.startDate).diff(moment(this.endDate)));
							if (isSorted) {
								this.startDateCurrent = this.startDate;
								this.endDateCurrent = this.endDate;
								this.numDays = moment(this.endDate).diff(
									moment(this.startDate),
									"days"
								);
								this.getData();
							}
						}
					}
				}
			},
		},
		methods: {
			startPage() {
				this.initDate();
				// this.getData();
			},
			initDate() {
				let start = moment()
					.subtract(this.initRangeDate, "days")
					.format("YYYY-MM-DD");
				let end = moment().format("YYYY-MM-DD");
				// console.log(start, end);
				this.startDate = start;
				this.endDate = end;
				this.joinDate = [start, end];
			},
			async getData() {
				this.isLoading = true;
				try {
					const queryParams = new URLSearchParams({
						startdate: this.startDate,
						enddate: this.endDate,
					});

					const responseData = await getOverAllVehicleReportService(
						this.$store,
						queryParams.toString()
					);
					if (responseData["code"] === 200) {
						this.data = responseData["data"];
					} else {
						throw "response failed";
					}
				} catch (err) {
					console.error(err);
				}
				this.isLoading = false;
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "VEHICLE");
					if (
						this.permList.admin_all === true ||
						this.permList.vehicle_total_report === true
					) {
						this.isPermissionCanView = true;
					}
				}
			},
		},
	};
</script>
<style scoped>
	.container {
		max-width: 1500px;
	}
	.image_list_float_left {
		float: right;
	}

	.custom-text-inline-height {
		line-height: 1;
	}
</style>
