import HomeView from "../views/HomeView.vue";
import UploadView from "../views/UploadView.vue";
import ExampleView from "../views/ExampleView.vue";
import WorksheetSummaryListView from "../views/worksheet_summary_list/WorksheetSummaryListView.vue";

export default [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true, topic: "home" },
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadView,
    meta: { requiresAuth: true, topic: "home" },
  },
  {
    path: "/example",
    name: "example",
    component: ExampleView,
    meta: { requiresAuth: true, topic: "home" },
  },
];
