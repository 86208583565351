<template>
  <div name="logout">
    <v-container fluid pa-0>
      <v-row align-content="center" justify="center" dense style="height: 80vh">
        <v-col cols="12" align-content="center" justify="center">
          <v-card
            class="mx-auto pa-10"
            max-width="374"
            elevation="0"
            rounded="xl"
          >
            <div class="text-center">
              <p class="text-h5">ออกจากระบบเรียบร้อย</p>
              <p class="text-subtitle-1">Chokchaiaree system</p>
              <v-btn color="primary" to="/login"> กลับไปหน้าเข้าสู่ระบบ </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { userLogout } from "@/services/api/user";

export default {
  name: "LogoutView",

  data() {
    return {};
  },

  watch: {},

  methods: {
    async logoutAction() {
      await userLogout(this.$store);
    },
  },
  mounted() {
    if (
      this.$store.state.accessToken ||
      this.$store.state.refreshToken ||
      this.$store.state.userInfo
    ) {
      this.logoutAction();
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
<style></style>
