<template>
  <v-container fluid>
    <v-row class="ml-3">
      <v-col>
        <h1 class="headline mt-1">Example Pages</h1>
      </v-col>
    </v-row>
    <v-row class="ml-3">
      <v-col>
        <CustomTextInputCurrency
          ref="amount"
          v-model="currencyInput"
          placeholder="หน่วยบาท"
          title="จำนวนเงิน"
          :isRequired="true"
          :disabled="false"
          :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
          :input-view-bottom-margin="false"
          :expendMargin="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="checkDateTimeFormDateFormRef">
          <CustomDateTimeTypingPickerFormInput
            v-model="currencyDateTimeInput"
            :is-required="true"
            :title="'เวลา'"
            :disabled="false"
          />
          <v-btn @click="checkDateTimeForm()"> ทดสอบ </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-select
          :items="provinceList"
          v-model="province"
          placeholder="จังหวัด"
          item-value="provinceId"
          item-text="nameTh"
          outlined
          dense
          rounded
          filled
          return-object
          @input="changeProvice"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="districtList"
          v-model="district"
          placeholder="อำเภอ"
          item-value="districtId"
          item-text="nameTh"
          outlined
          dense
          rounded
          filled
          return-object
          @input="changeDistrict"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="subDistrictList"
          v-model="subDistrict"
          placeholder="ตำบล"
          item-value="nameTh"
          item-text="nameTh"
          outlined
          dense
          rounded
          filled
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <AppFilesUploadVue
          placeholder="ทดสอบ"
          :multiple="true"
          :smallChips="true"
          :allowImageOnly="true"
          :uploadWithScale="false"
          v-model="selectedFile"
          ref="AppFillesUploadRef"
        />
      </v-col>
      <v-col lg="12">
        <v-btn @click="parentUploadFile"> บันทึกไฟล์ </v-btn>
      </v-col>
      <v-col>
        <v-img
          :src="previewImage"
          height="300"
          width="300"
          contain
          class="backgroud-image"
        />
      </v-col>
    </v-row> -->
    <v-col class="ml-3">
      <v-btn @click="callUploadErrorDialog"> error upload</v-btn>
      <AppDialogConfirm ref="dialogAlert" />
    </v-col>
    <v-col>
      <CustomAutocomplete
        ref="test"
        v-model="customInput"
        title="ทดสอบ"
        placeholder="ทดสอบ"
        :isRequired="false"
        :items="provinceList"
        :returnObject="true"
        itemText="nameTh"
        itemValue="provinceId"
      />
    </v-col>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  getDistrictService,
  getSubDistrictService,
  getProvinceService,
} from "@/services/api/app";

import {
  getFileErrorTooLarge,
  getFileErrorInvaild,
  getFileErrorKeyNotFound,
} from "@/services/api/error_testing";

import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "../components/AppDialogConfirm.vue";
import { errorMessageMapper } from "../services/error";
import { DialogType } from "@/services/dialog";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
//
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import CustomDateTimeTypingPickerFormInput from "@/components/CustomDateTimeTypingPickerFormInput.vue";
export default {
  name: "ExampleView",
  components: {
    AppFilesUploadVue,
    AppDialogConfirm,
    CustomAutocomplete,
    CustomTextInputCurrency,
    CustomDateTimeTypingPickerFormInput,
  },
  data() {
    return {
      currencyInput: 200000.59,
      currencyDateTimeInput: null,
      province: {},
      district: {},
      subDistrict: "",
      provinceList: [],
      districtList: [],
      subDistrictList: [],
      selectedFile: [],
      previewImage: "",
      resultUpload: [],
      customInput: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.currencyDateTimeInput = "2023-02-27T18:00:00";
    }, 2000);
  },
  watch: {
    selectedFile(val) {
      // ***** IMPORTANT FOR UPLOAD
      console.log(val);
      if (val.length == 0) {
        // clear review
        this.previewImage = "";
      } else {
        // set review
        this.previewImage = val[0].src;
      }
      // ***** END IMPORTANT FOR UPLOAD
    },
    customInput(val) {
      console.log(val);
    },
    currencyInput(val) {
      console.log(val);
    },
    currencyDateTimeInput(val) {
      console.log("==>root comp value ", val);
    },
  },
  methods: {
    ...mapActions(["appProvinceVuex"]),
    async getData() {
      // this.provinceList = await this.appProvinceVuex();
      this.provinceList = await getProvinceService(this.$store);
    },
    async changeProvice(provideObj) {
      this.districtList = await getDistrictService(
        this.$store,
        provideObj.provinceId
      );
      console.log(this.districtList);
    },
    async changeDistrict(districtObj) {
      this.subDistrictList = await getSubDistrictService(
        this.$store,
        districtObj.districtId
      );
    },
    // ***** START IMPORTANT FOR UPLOAD
    async parentUploadFile() {
      let resultUpload = await this.$refs.AppFillesUploadRef.uploadAction();
      // return list of response
      let respIsError = false;
      let respMessage = [];
      console.log(resultUpload);
      for (let i = 0; i < resultUpload.length; i++) {
        if (resultUpload[i] !== 200) {
          respIsError = true;
          respMessage.push(resultUpload[i]["message"]);
        }
      }
      if (respIsError) {
        this.callUploadErrorDialog(respMessage);
      }
    },
    // ***** END IMPORTANT FOR UPLOAD

    //
    async callUploadErrorDialog(respMessage) {
      // const resp = await getFileErrorInvaild(this.$store);
      // const resp = await getFileErrorTooLarge(this.$store);
      // const resp = await getFileErrorKeyNotFound(this.$store);
      // if (resp.code === 400) {
      // let custom_error_text = `ข้อมูลรูปภาพ .... ${
      //   errorMessageMapper[resp.message]
      // }`;
      let custom_error_text =
        "บอยคอตต์แกรนด์หล่อฮังก้วย สกาย โครนาซื่อบื้อรีวิวครัวซองต์บิ๊ก";
      console.log(custom_error_text);
      await this.$refs.dialogAlert.open(
        "คุณมีข้อมูลที่ยังไม่ได้บันทึก",
        custom_error_text,
        DialogType.ACTION,
        {
          width: 400,
          zIndex: 200,
          noconfirm: false,
        }
      );

      // clear data
      // }
    },

    //

    checkDateTimeForm() {
      console.log(
        "validate",
        this.$refs["checkDateTimeFormDateFormRef"].validate()
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>
