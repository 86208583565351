<template>
	<apexchart :series="series" :options="chartOptions" :height="343"></apexchart>
</template>

<script>
	import VueApexCharts from "vue-apexcharts";
	import _ from "lodash";
	import { nFormatterHelper } from "@/services/appSummaryHelper";
	export default {
		name: "VehicleIncomeRatioBarChart",
		props: {
			data: {
				required: true,
			},
		},
		data() {
			return {
				series: [],
				maxYAxis: 0,
				divideMonth: 12,
				maxValue: 0,
				minValue: 0,
				binwidth: 0,
				categories: [],
			};
		},
		computed: {
			chartOptions() {
				return {
					chart: {
						type: "bar",
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
					},
					legend: { show: false },
					dataLabels: {
						enabled: true,
					},
					grid: {
						show: false,
					},
					plotOptions: {
						bar: {
							columnWidth: "95%",
							strokeWidth: 2,
							borderRadius: 5,
							borderRadiusApplication: "end",
						},
					},
					// fill: {
					//   colors: "#ff4040",
					//   opacity: 0.3,
					// },
					// stroke: {
					//   width: 2,
					//   colors: ["#ee8080"],
					// },
					xaxis: {
						title: { text: "ช่วงรายได้สุทธิ" },
						categories: this.categories,
					},
					yaxis: {
						title: { text: "จำนวนรถ" },
						type: "numeric",
						labels: {
							formatter: function (val) {
								return val.toFixed(0);
							},
						},
					},
					tooltip: {
						//   onDatasetHover: {
						//     highlightDataSeries: true,
						//   },
						x: {
							formatter: (x, config) => {
								const _c = _.get(this.categories, config.dataPointIndex, null);

								if (_c !== null) {
									return `${_c[0]} ถึง ${_c[1]} บาท`;
								} else {
									return x;
								}
							},
						},
					},
				};
			},
		},
		components: {
			apexchart: VueApexCharts,
		},
		watch: {
			data: function (newValue) {
				this.processData();
			},
		},
		methods: {
			histogram(X, binRange) {
				//inclusive of the first number
				// var max = Math.max(...X);
				// var min = Math.min(...X);
				// var len = max - min + 1;
				// var numberOfBins = Math.ceil(len / binRange);
				// var bins = new Array(numberOfBins).fill(0);
				// //-min to normalise values for the array
				// X.forEach((x) => bins[Math.floor((x - min) / binRange)]++);
				// return bins;
			},
			calculateBinWidth(minV, maxV, binwidth) {
				let wb = [Math.floor(minV)];
				let cm = minV;
				for (let i = 0; i < this.divideMonth; i++) {
					wb.push(Math.ceil(cm + binwidth));
					cm += binwidth;
				}
				return wb;
			},
			processData() {
				if (_.isNil(this.data)) {
					return;
				}

				if (!_.isNil(this.data)) {
					const profit = _.map(this.data, "profit");
					const pass = _.map(this.data, "pass");
					this.maxValue = _.max(profit) || 0;
					this.minValue = _.min(profit) || 0;
					this.binwidth = _.divide(
						_.subtract(this.maxValue, this.minValue) + 1,
						this.divideMonth
					);

					let bins = new Array(this.divideMonth).fill(0);
					let hasPass = new Array(this.divideMonth).fill(false);
					for (let i = 0; i < profit.length; i++) {
						let tmp = _.divide(profit[i] - this.minValue, this.binwidth) || 0;
						const atIndex = Math.floor(tmp);
						bins[atIndex]++;
						if (pass[i] === true) {
							hasPass[atIndex] = true;
						}
					}

					const tmpXLabel = this.calculateBinWidth(
						this.minValue,
						this.maxValue,
						this.binwidth
					);

					let formatXLabel = [];
					let xLabel = [];
					if (tmpXLabel.length > 0) {
						for (let i = 0; i < tmpXLabel.length; i++) {
							formatXLabel.push(nFormatterHelper(tmpXLabel[i]));
						}
					}

					for (let i = 0; i < formatXLabel.length - 1; i++) {
						xLabel.push([[formatXLabel[i]], [formatXLabel[i + 1]]]);
					}

					let binsData = [];
					for (let i = 0; i < bins.length; i++) {
						binsData.push({
							x: xLabel[i],
							y: bins[i],
							fillColor: hasPass[i] ? "#49c496" : "#AAB7B8",
						});
					}

					this.categories = xLabel;
					this.series = [
						{
							name: "จำนวนรถ",
							data: binsData,
						},
					];
				}
			},
		},
		mounted() {
			this.processData();
		},
	};
</script>
