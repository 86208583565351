<template>
  <div name="permissionSettings">
    <v-form ref="formCompanyData" v-model="valid">
      <CustomCardFormViewModern
        title="ข้อมูลที่ทำงาน"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div class="px-6">
          <!-- <v-row no-gutters class="px-3 pb-6">
            <v-col cols="12">
              <span class="title-input"> สถานะพนักงาน </span>
              <span class="required-color"> * </span>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" class="px-0">
                <v-row no-gutters class="px-0">
                  <v-col cols="12">
                    <v-chip-group
                      v-model="formData.company.empStatus"
                      mandatory
                      column
                    >
                      <v-chip
                        v-for="status in employeeStatusItems"
                        :key="status"
                        :value="status"
                        filter
                        outlined
                        :color="getStatusEmployeeColor(status)"
                      >
                        <span class="px-1">{{
                          getStatusEmployeeText(status)
                        }}</span>
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4"></v-col>
                </v-row>
              </v-card></v-col
            ></v-row
          > -->
          <v-row no-gutters>
            <v-col cols="12" sm="8">
              <CustomAutocomplete
                ref="companyName"
                v-model="formData.company.companyName"
                :items="companyNameList"
                title="ชื่อบริษัทที่สังกัด"
                placeholder="ชื่อบริษัทที่สังกัด"
                :isRequired="true"
                itemText="nameTh"
                itemValue="key"
                :returnObject="true"
                :readonly="!canEdit"
                dataInputName="companyName"
              >
              </CustomAutocomplete>
            </v-col>

            <v-col cols="12" sm="4">
              <CustomAutocomplete
                ref="zone"
                v-model="formData.company.zone"
                title="โซน"
                placeholder="โซน"
                :isRequired="true"
                :items="zoneList"
                itemText="nameTh"
                itemValue="key"
                :returnObject="true"
                :readonly="!canEdit"
                dataInputName="zone"
              ></CustomAutocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <!-- <v-col cols="12" sm="3">
              <CustomDateTypingPickerFormInput
                ref="probationDate"
                v-model="formData.company.probationDate"
                :isRequired="true"
                placeholder="ไม่มีให้เว้นว่างไว้"
                :disabled="!canEdit"
                title="วันเดือนปีที่เริ่มทดลองงาน"
                dataInputName="probationDate"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <CustomDateTypingPickerFormInput
                ref="joinDate"
                v-model="formData.company.joinDate"
                :isRequired="true"
                :disabled="!canEdit"
                placeholder="ไม่มีให้เว้นว่างไว้"
                :title="
                  workingPeriodView
                    ? workingPeriodView
                    : 'วันเดือนปีที่เริ่มงาน'
                "
                dataInputName="joinDate"
              />
            </v-col> -->

            <v-col cols="12" sm="3">
              <CustomDateTypingPickerFormInput
                ref="sesoDate"
                placeholder="ไม่มีให้เว้นว่างไว้"
                v-model="formData.company.sesoDate"
                :isRequired="false"
                :disabled="!canEdit"
                title="วันเดือนปีที่เข้าประกันสังคม"
                dataInputName="sesoDate"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <CustomTextInputImprove
                v-model="formData.company.sesoHospital"
                ref="sesoHospital"
                title="ชื่อสถานพยาบาลประกันสังคม"
                :isRequired="false"
                placeholder="ชื่อสถานพยาบาลประกันสังคม"
                :disabled="!canEdit"
                dataInputName="sesoHospital"
              />
            </v-col>
          </v-row>
        </div>
        <div class="px-6">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <CustomAutocomplete
                ref="position"
                :value="formData.company.position"
                title="ตำแหน่งงาน"
                placeholder="ตำแหน่งงาน"
                :isRequired="true"
                :items="positionList"
                itemText="nameTh"
                itemValue="key"
                :returnObject="true"
                :readonly="!canEdit"
                dataInputName="position"
                @on-change="positionChangeHandler"
              ></CustomAutocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="px-6">
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <CustomSelectsImprove
                ref="jobGroup"
                v-model="formData.company.jobGroup"
                placeholder="เลือก"
                title="กลุ่มงาน"
                :isRequired="true"
                :items="jobGroupItems"
                :disabled="!canEdit"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="jobGroup"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <CustomTextInputImprove
                v-model="formData.company.jobGroupText"
                ref="jobGroupText"
                title="ระบุกลุ่มงาน (กรณี อื่นๆ)"
                placeholder="กลุ่มงาน"
                :isRequired="true"
                :rules="
                  formData.company.jobGroup == 'อื่นๆ'
                    ? [(v) => !!v || 'จำเป็นต้องระบุ']
                    : []
                "
                :disabled="!canEdit || formData.company.jobGroup !== 'อื่นๆ'"
                dataInputName="jobGroupText"
              />
            </v-col>

            <v-col cols="12" sm="3">
              <CustomSelectsImprove
                ref="positionLevel"
                v-model="formData.company.positionLevel"
                placeholder="เลือก"
                title="ระดับตำแหน่ง "
                :isRequired="true"
                :items="positionLevelItems"
                :disabled="!canEdit"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="positionLevel"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <CustomTextInputImprove
                v-model="formData.company.positionLevelText"
                ref="positionLevelText"
                title="ระบุระดับตำแหน่ง (กรณี อื่นๆ)"
                placeholder="ระดับตำแหน่ง"
                :isRequired="true"
                :rules="
                  formData.company.positionLevel == 'อื่นๆ'
                    ? [(v) => !!v || 'จำเป็นต้องระบุ']
                    : []
                "
                :disabled="
                  !canEdit || formData.company.positionLevel !== 'อื่นๆ'
                "
                dataInputName="positionLevelText"
              />
            </v-col>
          </v-row>
        </div>
        <!--  -->
        <div
          class="pt-6 pb-6 px-0"
          v-for="(section, index) in processedPermissionSettingItems"
          :key="`${index}-${section.key}`"
        >
          <div class="mb-8">
            <v-divider></v-divider>
          </div>

          <v-row no-gutters v-if="section.key === 'CONTRACT_JOB'">
            <v-col cols="12">
              <v-card elevation="0" class="px-9">
                <div class="title-form">{{ section.title }}</div>
              </v-card>
            </v-col>

            <v-col class="pl-6 pr-5" cols="12">
              <v-row class="px-3">
                <v-col cols="3" class="d-flex">
                  <v-checkbox
                    class="pr-6"
                    v-model="contractPermissionAll"
                    label="ทั้งหมด"
                    hide-details
                    @click="handlePermissionOptionAll(true)"
                    :disabled="!canEdit"
                    color="primary"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="contractPermissionSome"
                    label="กำหนดเอง"
                    hide-details
                    @click="handlePermissionOptionAll(false)"
                    :disabled="!canEdit"
                    color="primary"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col cols="3">
                  
                </v-col> -->
              </v-row>
              <!-- <v-col class="py-0" cols="3">
                <v-checkbox
                  v-model="contractPermissionSome"
                  label="กำหนดเอง"
                  hide-details
                  @click="handlePermissionOptionAll(false)"
                  :disabled="!canEdit"
                  color="primary"
                ></v-checkbox>
              </v-col> -->
              <v-expand-transition>
                <v-col class="pl-4 pb-0" v-show="contractPermissionSome">
                  <InputView :isRequired="false">
                    <PermissionContractElement
                      v-model="formData.permissionSelectedContractId"
                      :disabled="!canEdit || !contractPermissionSome"
                      label="พิมพ์ค้นหาสัญญาเดินรถ"
                    />
                  </InputView>
                </v-col>
              </v-expand-transition>
            </v-col>
            <v-row no-gutters class="pt-6">
              <v-col cols="6" class="px-7">
                <v-col class="px-10 pb-0"
                  ><div class="title-form">สัญญาเดินรถ</div></v-col
                >
                <v-col
                  class="pl-9 pr-9 py-0"
                  cols="12"
                  md="12"
                  v-for="premissionContractItem in section.leftItem"
                  :key="premissionContractItem.key"
                >
                  <v-checkbox
                    v-model="formData.permissions"
                    :label="premissionContractItem.nameTh"
                    :value="premissionContractItem.key"
                    hide-details
                    :disabled="
                      !canEdit ||
                      (!contractPermissionSome && !contractPermissionAll)
                    "
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-col>
              <v-divider vertical class="mt-2"></v-divider>
              <v-col cols="6">
                <v-col class="px-10 pb-0"
                  ><div class="title-form">งาน</div></v-col
                >
                <v-col
                  class="pl-9 pr-9 py-0"
                  cols="12"
                  md="12"
                  v-for="premissionJobItem in section.rightItem"
                  :key="premissionJobItem.key"
                >
                  <v-checkbox
                    v-model="formData.permissions"
                    :label="premissionJobItem.nameTh"
                    :value="premissionJobItem.key"
                    hide-details
                    :disabled="
                      !canEdit ||
                      (!contractPermissionSome && !contractPermissionAll)
                    "
                    color="primary"
                  ></v-checkbox>
                </v-col>
              </v-col>
            </v-row>
          </v-row>

          <!--  -->
          <v-row no-gutters v-else>
            <v-col cols="12">
              <v-card elevation="0" class="px-9">
                <div class="title-form">{{ section.title }}</div>
              </v-card>
            </v-col>

            <v-col class="pl-9 pr-9" cols="12" md="6">
              <div
                v-for="premissionItem in section.leftItem"
                :key="premissionItem.key"
              >
                <v-checkbox
                  v-model="formData.permissions"
                  :label="premissionItem.nameTh"
                  :value="premissionItem.key"
                  hide-details
                  :disabled="!canEdit"
                  color="primary"
                ></v-checkbox>
              </div>
            </v-col>

            <v-col class="pl-9 pr-9" cols="12" md="6">
              <div
                v-for="premissionItem in section.rightItem"
                :key="premissionItem.key"
              >
                <v-checkbox
                  v-model="formData.permissions"
                  :label="premissionItem.nameTh"
                  :value="premissionItem.key"
                  hide-details
                  :disabled="!canEdit"
                  color="primary"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <!--  -->
        </div>
        <div class="pb-10" v-if="!canEdit"></div>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          v-if="canEdit"
          text="บันทึกข้อมูลที่ทำงาน"
        />
      </CustomCardFormViewModern>
    </v-form>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import HeaderCardModern from "@/components/HeaderCardModern.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import PermissionContractElement from "./PermissionContractElement.vue";
import InputView from "@/components/InputView.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { processPermission } from "@/services/permissions";
import { mapGetters } from "vuex";

const EmployeeStatus = Object.freeze({
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "CompanyDataView",

  components: {
    CustomSelects,
    CustomAutocomplete,
    CustomTextInput,
    CustomDateTypingPickerFormInput,
    CustomCardFormViewModern,
    CustomSelectsImprove,
    HeaderCardModern,
    AppSubmitFormButton,
    PermissionContractElement,
    InputView,
    CustomTextInputImprove,
  },

  props: {
    data: {
      type: [Object, null],
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    canEdit: true,
  },
  data() {
    return {
      valid: false,
      permissionContractList: [],
      contractPermissionAll: false,
      contractPermissionSome: false,
      formData: {
        company: {
          // empStatus: null,
          companyName: null,
          // joinDate: null,
          // probationDate: null,
          // resignedDate: null,
          sesoDate: null,
          // department: null,
          sesoHospital: null,
          // reasonTermination: null,
          // reasonTerminationText: null,
          jobGroup: null,
          jobGroupText: null,
          positionLevel: null,
          positionLevelText: null,
          position: null,
          // affiliation: null,
          zone: null,
          // level: null,
        },
        permissions: [],
        permissionSelectedContractId: [],
      },
      // app data
      companyNameList: [],
      // departmentList: [],
      positionList: [],
      zoneList: [],
      // affiliationList: [],
      // levelList: [],

      // employeeStatusItems: [
      //   EmployeeStatus.PROBATION,
      //   EmployeeStatus.ACTIVE,
      //   EmployeeStatus.FIRED,
      //   EmployeeStatus.RESIGNED,
      // ],
      workingPeriodView: null,
      employeeStatusItems: [
        "PROBATION",
        "ACTIVE",
        "RESIGNED",
        "TERMINATED",
        "FIRED",
      ],
      terminationTitle: "ลาออก/เลิกจ้าง/ไล่ออก",
      reasonItems: {
        RESIGNED: [
          "ได้งานใหม่",
          "ค่าตอบแทน",
          "สวัสดิการ",
          "ธุระส่วนตัว",
          "ปัญหาสุขภาพ",
          "อื่นๆ",
        ],
        TERMINATED: [
          "ขาดงานเกิน 3 วัน",
          "ไม่ผ่านทดลองงาน",
          "ทำผิดกฎระเบียบข้อบังคับบริษัทฯ",
          "เสียชีวิต",
          "อื่นๆ",
        ],
        FIRED: ["กระทำความผิดอย่างรุนแรง", "ทุจริตต่อหน้าที่", "อื่นๆ"],
      },
      jobGroupItems: [
        "Operation",
        "Accounting & Finance",
        "HR & Admin",
        "Sales & Marketing",
        "Maintenance",
        "Business Development",
        "Safety & GPS",
        "Management",
        "Driver",
        "อื่นๆ",
      ],
      positionLevelItems: [
        "Staff",
        "Officer",
        "Executive",
        "Supervisor",
        "Manager",
        "Management",
        "อื่นๆ",
      ],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },

      permissionSettingItems: [], // all item;
      defaultPermissionSettingItems: [], // for select
    };
  },

  watch: {
    data(newValue) {
      if (_.isNil(newValue) === false) {
        this.setAppCompData(newValue["_appCompanyData"]);
        if (_.isNil(newValue["company"]) === false) {
          this.formData.company = _.clone(newValue.company);
          // if (this.formData["company"]["jobGroup"] === undefined) {
          //   this.formData["company"]["jobGroup"] = null;
          // }
          // if (this.formData["company"]["positionLevel"] === undefined) {
          //   this.formData["company"]["positionLevel"] = null;
          // }

          // this.formData.company.empStatus = this.getStatusEmployeeText(
          //   this.formData.company.empStatus
          // );

          this.formData.permissions = _.clone(newValue.permissions);
          this.formData.permissionSelectedContractId = _.clone(
            newValue.permissionSelectedContractId
          );

          this.checkPermissionSelectedContractIdHandler(this.formData);
          this.defaultPermissionSettingItems = _.clone(newValue.permissions);
        }
      }
    },

    // "formData.company.position": function (newVal, oldVal) {
    //   if (_.isNil(newVal) === false) {
    //     if (this.isEdit && !this.positionPermissionCheckpointInit) {
    //       this.positionPermissionCheckpointInit = true;
    //       return;
    //     }
    //     this.formData.permissions = _.clone(newVal.permission);
    //   }
    // },

    // "formData.company.joinDate"(newValue) {
    //   if (newValue !== null) {
    //     var currentData = new Date();
    //     var joinDate = new Date(newValue);

    //     var diff_period = this.calcDate(currentData, joinDate);
    //     this.workingPeriodView = `วันเดือนปีที่เริ่มงาน (อายุงาน ${diff_period.result})`;
    //   } else {
    //     this.workingPeriodView = "";
    //   }
    // },

    // "formData.company.empStatus"(newValue) {
    //   if (newValue == "PROBATION" || newValue == "ACTIVE") {
    //     this.terminationTitle = "ลาออก/เลิกจ้าง/ไล่ออก";
    //   } else if (newValue == "RESIGNED") {
    //     this.terminationTitle = "ลาออก";
    //   } else if (newValue == "TERMINATED") {
    //     this.terminationTitle = "เลิกจ้าง";
    //   } else if (newValue == "FIRED") {
    //     this.terminationTitle = "ไล่ออก";
    //   }
    // },
  },
  computed: {
    processedPermissionSettingItems() {
      return this.processLeftRightPermission(this.permissionSettingItems);
    },
  },
  mounted() {},

  methods: {
    setAppCompData(_appcompdata) {
      if (_.isNil(_appcompdata) === false) {
        this.companyNameList = _appcompdata["com_user_cont"];
        // this.affiliationList = _appcompdata["affiliation"];
        // this.departmentList = _appcompdata["department"];
        this.positionList = _appcompdata["position"];
        // this.levelList = this.getArrangeNoData(_appcompdata["level"]);
        this.zoneList = _appcompdata["zone"];
        this.permissionSettingItems = _appcompdata["permission"];
      }
    },
    getArrangeNoData(data) {
      let noDataTh = data.at(-1);
      if (noDataTh !== null && noDataTh.nameEn == "No Data") {
        noDataTh.nameEn = "";
        noDataTh.nameTh = "ไม่ระบุ";
        data.pop();
        data.unshift(noDataTh);
      }
      return data;
    },

    getStatusEmployeeText(status) {
      switch (status) {
        case "PROBATION":
          return EmployeeStatus.PROBATION;
        case "ACTIVE":
          return EmployeeStatus.ACTIVE;
        case "RESIGNED":
          return EmployeeStatus.RESIGNED;
        case "TERMINATED":
          return EmployeeStatus.TERMINATED;
        case "FIRED":
          return EmployeeStatus.FIRED;
        default:
          return null;
      }
    },

    getStatusEmployeeColor(status) {
      switch (status) {
        case "PROBATION":
          return "primary";
        case "ACTIVE":
          return "success";
        case "RESIGNED":
          return "warning";
        case "TERMINATED":
          return "error";
        case "FIRED":
          return "error";
        default:
          return null;
      }
    },

    getStatusEmployeeKey(status) {
      switch (status) {
        case EmployeeStatus.PROBATION:
          return "PROBATION";
        case EmployeeStatus.ACTIVE:
          return "ACTIVE";
        case EmployeeStatus.RESIGNED:
          return "RESIGNED";
        case EmployeeStatus.TERMINATED:
          return "TERMINATED";
        case EmployeeStatus.FIRED:
          return "FIRED";
      }
    },
    calcDate(date1, date2) {
      /*
       * calcDate() : Calculates the difference between two dates
       * @date1 : "First Date in the format MM-DD-YYYY"
       * @date2 : "Second Date in the format MM-DD-YYYY"
       * return : Array
       */
      //new date instance
      // const dt_date1 = new Date(date1);
      // const dt_date2 = new Date(date2);
      const dt_date1 = date1;
      const dt_date2 = date2;

      //Get the Timestamp
      const date1_time_stamp = dt_date1.getTime();
      const date2_time_stamp = dt_date2.getTime();

      let calc;

      //Check which timestamp is greater
      if (date1_time_stamp > date2_time_stamp) {
        calc = new Date(date1_time_stamp - date2_time_stamp);
      } else {
        calc = new Date(date2_time_stamp - date1_time_stamp);
      }
      //Retrieve the date, month and year
      const calcFormatTmp =
        calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
      //Convert to an array and store
      const calcFormat = calcFormatTmp.split("-");
      //Subtract each member of our array from the default date
      const days_passed = Number(Math.abs(calcFormat[0]) - 1);
      const months_passed = Number(Math.abs(calcFormat[1]) - 1);
      const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

      //Set up custom text
      const yrsTxt = "ปี";
      const mnthsTxt = "เดือน";
      const daysTxt = "วัน";

      //Convert to days and sum together
      const total_days =
        years_passed * 365 + months_passed * 30.417 + days_passed;

      //display result with custom text
      let result;
      if (date1_time_stamp > date2_time_stamp) {
        result =
          (years_passed > 0 ? years_passed + " " + yrsTxt + " " : "") +
          (months_passed > 0 ? months_passed + " " + mnthsTxt + " " : "") +
          (days_passed > 0 ? days_passed + " " + daysTxt : "");
      } else {
        result =
          (years_passed > 0 ? "-" + years_passed + " " + yrsTxt + " " : "") +
          (months_passed > 0
            ? "-" + months_passed + " " + mnthsTxt + " "
            : "") +
          (days_passed > 0 ? "-" + days_passed + " " + daysTxt : "");
      }
      //return the result
      return {
        total_days: Math.round(total_days),
        result: result.trim(),
      };
    },

    // statusOnClick(selectedStatus) {
    //   this.formData.company["empStatus"] = selectedStatus;
    // },

    getSelectedContractNum(isAll) {
      this.contractPermissionAll = isAll;
    },

    handlePermissionOptionAll(isAll) {
      if (isAll) {
        if (this.contractPermissionSome) {
          this.contractPermissionSome = false;
        }
      } else {
        if (this.contractPermissionAll) {
          this.contractPermissionAll = false;
        }
      }
    },

    // getStatusOutline(status) {
    //   if (this.formData.company["empStatus"] == status) {
    //     return false;
    //   }
    //   return true;
    // },

    checkCompleteData() {
      this.$refs.formCompanyData.$el.scrollIntoView();
    },

    checkPermissionSelectedContractIdHandler(_data) {
      const _permission = _.get(_data, "permissions", []);

      const _permissionSelectedContractId = _.get(
        _data,
        "permissionSelectedContractId",
        null
      );

      // if (_permission.length === 0) {
      //   this.contractPermissionAll = false;
      //   this.contractPermissionSome = false;
      //   return;
      // }
      let permissionInclude = false;
      for (let i = 0; i < _permission.length; i++) {
        if (
          _permission[i].includes("CONTRACT") ||
          _permission[i].includes("JOBS")
        ) {
          permissionInclude = true;
          break;
        }
      }

      //
      if (_.isArray(_permissionSelectedContractId)) {
        if (_permissionSelectedContractId.length > 0) {
          this.contractPermissionAll = false;
          this.contractPermissionSome = true;
        } else if (permissionInclude) {
          this.contractPermissionAll = true;
          this.contractPermissionSome = false;
        } else {
          this.contractPermissionAll = false;
          this.contractPermissionSome = false;
        }
      } else {
        // is null
        if (permissionInclude === false) {
          this.contractPermissionSome = false;
          this.contractPermissionAll = false;
        } else {
          this.contractPermissionSome = false;
          this.contractPermissionAll = true;
        }
      }
    },

    positionChangeHandler(newValue) {
      if (_.isNil(newValue) === false) {
        const currentPosition = _.get(this.formData, "company.position", null);
        if (!_.isNil(currentPosition)) {
          if (newValue.key !== currentPosition.key) {
            this.formData.company.position = newValue;
            this.formData.permissions = _.clone(newValue.permission);
          }
        } else {
          this.formData.company.position = newValue;
          this.formData.permissions = _.clone(newValue.permission);
        }
        this.formData.permissionSelectedContractId = null;
        this.checkPermissionSelectedContractIdHandler(this.formData);
      }
    },

    processLeftRightPermission(permissions) {
      let _permission = [];
      try {
        if (_.isArray(permissions)) {
          for (let i = 0; i < permissions.length; i++) {
            let _p = { ...permissions[i] };
            // left
            _p["leftItem"] = _.sortBy(
              _.filter(permissions[i]["items"], { cols: 1 }),
              (o) => o.order
            );
            // right
            _p["rightItem"] = _.sortBy(
              _.filter(permissions[i]["items"], { cols: 2 }),
              (o) => o.order
            );

            _permission.push(_p);
          }
        }
      } catch (error) {
        console.error(error);
      }

      return _permission;
    },

    saveChange() {
      if (this.$refs.formCompanyData.validate() === true) {
        if (!this.contractPermissionAll && !this.contractPermissionSome) {
          this.formData["permissionSelectedContractId"] = []; // not select
          //  filter out of contract job and contract job by check only
          let filterPremission = [];
          for (let i = 0; i < this.formData["permissions"].length; i++) {
            let permissionSubByUnderscore =
              this.formData["permissions"][i].split("_");
            if (
              permissionSubByUnderscore[0] !== "JOBS" &&
              permissionSubByUnderscore[0] !== "CONTRACT"
            ) {
              filterPremission.push(this.formData["permissions"][i]);
            }
          }
          this.formData["permissions"] = filterPremission;
        } else if (this.contractPermissionAll && !this.contractPermissionSome) {
          this.formData["permissionSelectedContractId"] = null; // select all
        } else if (!this.contractPermissionAll && this.contractPermissionSome) {
          if (_.isNil(this.formData["permissionSelectedContractId"])) {
            this.formData["permissionSelectedContractId"] = [];
          }

          if (this.formData["permissionSelectedContractId"].length === 0) {
            let filterPremission = [];
            for (let i = 0; i < this.formData["permissions"].length; i++) {
              let permissionSubByUnderscore =
                this.formData["permissions"][i].split("_");
              if (
                permissionSubByUnderscore[0] !== "JOBS" &&
                permissionSubByUnderscore[0] !== "CONTRACT"
              ) {
                filterPremission.push(this.formData["permissions"][i]);
              }
            }
            this.formData["permissions"] = filterPremission;
          } else {
            // not notting
          }
        }

        // if (
        //   this.formData["company"]["empStatus"] == "PROBATION" ||
        //   this.formData["company"]["empStatus"] == "ACTIVE"
        // ) {
        //   this.formData.company.resignedDate = null;
        // }

        let requestData = _.cloneDeepWith(this.formData);
        let filterOutField = ["empId"];

        requestData["company"] = _.pickBy(
          requestData["company"],
          (value, key) => !filterOutField.includes(key)
        );

        // requestData["company"]["empStatus"] = this.getStatusEmployeeKey(
        //   requestData["company"]["empStatus"]
        // );

        if (!_.isNil(requestData["permissionSelectedContractId"])) {
          if (requestData["permissionSelectedContractId"].length > 0) {
            requestData["permissionSelectedContractId"] = requestData[
              "permissionSelectedContractId"
            ].map(function (item) {
              return item._id;
            });
          }
        }
        this.$emit("on-click-save", requestData);
      } else {
        this.checkCompleteData();
      }
    },
  },
};
</script>
<style lang="scss">
.required-color {
  color: var(--v-error);
}
</style>
