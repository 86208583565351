<template>
  <MonitorGMapLoader
    refs="mainMap"
    vuex-status-action-name="updateMainGMapActive"
    :vuex-store-name="storeName"
    :map-config="mapConfig"
  >
    <template slot-scope="{ google, map }">
      <MonitorGMapMarker
        v-for="gmarker in currentMarkerList"
        :key="gmarker.gplaceId"
        :google="google"
        :map="map"
        :marker="gmarker"
      />

      <MonitorGMapLine
        v-for="dotLine in mainRouteLine"
        :key="dotLine.id"
        :google="google"
        :map="map"
        :data="dotLine"
      />

      <MonitorGMapMarker
        v-for="gmarker in currentVehicleMarkerList"
        :key="gmarker.id"
        :google="google"
        :map="map"
        :marker="gmarker"
      />

      <MonitorGMapMarker
        v-for="gmarker in focusVehicleList"
        :key="gmarker.id"
        :google="google"
        :map="map"
        :marker="gmarker"
      />

      <MonitorGMapLine
        v-for="solidLine in focusVehicleLineList"
        :key="solidLine.id"
        :google="google"
        :map="map"
        :data="solidLine"
      />
    </template>
  </MonitorGMapLoader>
</template>

<script>
import MonitorGMapLoader from "./gmap/MonitorGMapLoader.vue";
import MonitorGMapMarker from "./gmap/MonitorGMapMarker.vue";
import MonitorGMapLine from "./gmap/MonitorGMapLine.vue";

import { CUSTOM_MARKER_ICON } from "@/services/map/mapSettings";
import {
  getJobMonitorAllVehiclePositionService,
  getJobMonitorVehiclePositionService,
} from "@/services/api/jobs_monitor";
export default {
  name: "JobsMonitorCoreMap",
  props: {
    storeName: {
      type: String,
      default: "jobsMonitoring",
    },
  },
  data: () => ({
    firstInit: false,
    mapConfig: { center: { lat: 13.736717, lng: 100.523186 } },
    currentMarkerList: [],
    currentVehicleMarkerList: [],
    mainRouteLine: [],
    focusVehicleList: [],
    focusVehicleLineList: [],
    // interval
    viewState: 0,
    stillDashboardFetch: false,
    currentJobToVehicleDataStrQuery: "",
    currentVehicleDataStrQuery: "",
    dashboardTimer: undefined,
  }),
  computed: {},
  watch: {
    "$store.state.jobsMonitoring.currentRouteInfo": function (newVal) {
      this.drawFocusJob(newVal);
    },
    "$store.state.jobsMonitoring.isCurrentJobToVehicleLocation": function (
      newVal
    ) {
      if (newVal) {
        this.fetchObjectMapStatus();
        this.startCallApiTimer();
      } else {
        this.stopCallApiTimer();
      }
    },
  },
  methods: {
    clearAllObject() {
      this.currentMarkerList = [];
      this.currentVehicleMarkerList = [];
      this.mainRouteLine = [];
      this.focusVehicleList = [];
      this.focusVehicleLineList = [];
    },
    drawFocusJob(_items) {
      let processMarker = [];
      let processMarkerMapBound = [];
      let dotLine = [];

      this.currentMarkerList = [];
      this.mainRouteLine = [];
      this.focusVehicleList = [];

      this.stopCallApiTimer();

      for (let i = 0; i < _items.length; i++) {
        // random color

        for (let j = 0; j < _items[i]["markers"].length; j++) {
          const marker = _items[i]["markers"][j];
          let currentIcon =
            j === 0
              ? _.clone(CUSTOM_MARKER_ICON.START_POINT)
              : j === _items.length - 1
              ? _.clone(CUSTOM_MARKER_ICON.FINISH_POINT)
              : _.clone(CUSTOM_MARKER_ICON.CHECKPOINT);

          // currentIcon["fillColor"] = "#00E5F5"; //_items[i]["rColor"];
          processMarker.push({
            id: marker["_id"],
            jobId: marker["jobId"],
            jobCode: marker["jobCode"],
            position: {
              lat: marker["coordinates"][0],
              lng: marker["coordinates"][1],
            },
            duration: marker["duration"],
            distance: marker["distance"],
            iconMode: j === 0 ? 0 : j === _items.length - 1 ? 1 : 2,
            icon: currentIcon,
            anchorMode: "POINT",
            markerIdx: j,
          });
          processMarkerMapBound.push(marker["coordinates"]);
          const _line = this.processVehicleDotLine(
            marker,
            "#03BAFA"
            // currentIcon["fillColor"]
          );

          if (_line !== null) {
            dotLine.push(_line);
          }
        }
      }

      if (_items.length > 0) {
        this.viewState = 1;
      } else {
        this.viewState = 0;
      }

      setTimeout(() => {
        this.currentVehicleMarkerList = [];
        this.currentMarkerList = processMarker;
        this.mainRouteLine = dotLine;
        this.fetchObjectMapStatus();
        this.startCallApiTimer();
      }, 1000);

      if (!this.firstInit || processMarkerMapBound.length > 0) {
        this.$store.dispatch(
          "jobsMonitoring/updateCurrentLatLngCenterBoundList",
          processMarkerMapBound
        );
        this.firstInit = true;
      }
    },
    processVehicleDotLine(marker, lineColor) {
      if (!_.isNil(marker["gDirection"])) {
        return {
          id: `line_${marker["_id"]}`,
          jobId: marker["jobId"],
          dashLine: true,
          gDirectionFormat: true,
          lineColor: lineColor,
          ...marker["gDirection"],
        };
      }

      return null;
    },
    startCallApiTimer() {
      this.dashboardTimer = setInterval(() => {
        this.fetchObjectMapStatus();
      }, 8000);
    },
    stopCallApiTimer() {
      if (!_.isNil(this.dashboardTimer)) {
        clearInterval(this.dashboardTimer);
        this.stillDashboardFetch = false;
        this.clearAllObject();
        console.log("#dashboard: clear");
      }
    },
    //
    fetchObjectMapStatus() {
      if (this.stillDashboardFetch) {
        return;
      }
      //
      if (this.viewState === 0) {
        this.drawVehicleMarker();
      } else if (this.viewState === 1) {
        this.getCurrentVehicleService();
      }
    },
    //
    async drawVehicleMarker() {
      this.stillDashboardFetch = true;
      try {
        const currentJobToVehicleLocation =
          this.$store.state[this.storeName]["currentJobToVehicleLocation"];
        const allJobId = _.keys(currentJobToVehicleLocation);

        if (allJobId.length === 0) {
          return;
        }

        this.currentJobToVehicleDataStrQuery = new URLSearchParams({
          id: allJobId,
        }).toString();

        const rsp = await getJobMonitorAllVehiclePositionService(
          this.$store,
          this.currentJobToVehicleDataStrQuery
        );

        this.currentVehicleMarkerList = [];
        let vehicleMarker = [];
        let _idx = 0;

        if (rsp["code"] === 200 && this.stillDashboardFetch) {
          const _currentPosition = await this.$store.dispatch(
            "jobsMonitoring/updatePositionJobToVehicleLocation",
            rsp["data"]
          );

          for (const [key, value] of Object.entries(_currentPosition)) {
            if (value["position"] === null) {
              continue;
            }
            let textLabelVehicle = "";
            if (!_.isNil(value["vehicle"])) {
              textLabelVehicle += `${value["vehicle"]["licenseNumber"]} `;
            }
            if (!_.isNil(value["driver"])) {
              textLabelVehicle += `${value["driver"]["dpNickNameTh"]}`;
            }
            vehicleMarker.push({
              id: key,
              position: {
                lat: value["position"]["lat"],
                lng: value["position"]["lng"],
              },
              labelText: textLabelVehicle,
              anchorMode: "VEH",
              markerIdx: _idx,
            });
            _idx += 1;
          }

          setTimeout(() => {
            this.currentVehicleMarkerList = vehicleMarker;
          }, 500);
        } else {
          throw "GET ALL VEHICLE POSITION FAIL!";
        }
      } catch (error) {
        console.log(error);
      }

      this.stillDashboardFetch = false;
    },
    async getCurrentVehicleService() {
      this.stillDashboardFetch = true;
      try {
        const activeJobId = this.$store.state[this.storeName]["activeJobId"];
        this.currentVehicleDataStrQuery = new URLSearchParams({
          id: activeJobId,
        }).toString();

        const rsp = await getJobMonitorVehiclePositionService(
          this.$store,
          this.currentVehicleDataStrQuery
        );
        if (rsp["code"] === 200 && this.stillDashboardFetch) {
          const { hasPosition } = rsp["data"];
          if (!hasPosition) {
            return;
          }
          this.drawFocusVehicleMarker(rsp["data"]);
          this.drawVehicleSolidLine(rsp["data"]);
        }
      } catch (error) {
        console.error(error);
      }

      this.stillDashboardFetch = false;
    },
    drawFocusVehicleMarker(newPosition) {
      const activeJobId = this.$store.state[this.storeName]["activeJobId"];
      const currentJobToVehicleLocation =
        this.$store.state[this.storeName]["currentJobToVehicleLocation"][
          activeJobId[0]
        ];

      if (_.isNil(currentJobToVehicleLocation)) {
        return;
      }

      let textLabelVehicle = "";
      if (!_.isNil(currentJobToVehicleLocation["vehicle"])) {
        textLabelVehicle += `${currentJobToVehicleLocation["vehicle"]["licenseNumber"]} `;
      }
      if (!_.isNil(currentJobToVehicleLocation["driver"])) {
        textLabelVehicle += `${currentJobToVehicleLocation["driver"]["dpNickNameTh"]}`;
      }

      let vehicleMarker = [];
      this.focusVehicleList = [];

      vehicleMarker = [
        {
          id: activeJobId[0],
          position: {
            lat: newPosition["position"]["lat"],
            lng: newPosition["position"]["lng"],
          },
          labelText: textLabelVehicle,
          anchorMode: "VEH",
          markerIdx: 0,
        },
      ];

      setTimeout(() => {
        this.focusVehicleList = vehicleMarker;
      }, 500);
    },

    drawVehicleSolidLine(dataPayLoad) {
      if (_.isNil(dataPayLoad["directionList"])) {
        return;
      }
      const templateData = {
        id: `veline_${new Date().getTime()}`,
        dashLine: false,
        gDirectionFormat: false,
        path: [],
        // geodesic: true,
        strokeColor: "#4FCAF8",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        zIndex: 10,
      };

      let _vehicleLineList = _.clone(templateData);
      for (let i = 0; i < dataPayLoad["directionList"].length; i++) {
        _vehicleLineList["path"].push({
          lat: dataPayLoad["directionList"][i]["lat"],
          lng: dataPayLoad["directionList"][i]["lng"],
        });
      }

      setTimeout(() => {
        this.focusVehicleLineList = [_vehicleLineList];
      }, 500);
    },
  },
  mounted() {},
  destroyed() {
    this.stopCallApiTimer();
  },

  components: { MonitorGMapLoader, MonitorGMapMarker, MonitorGMapLine },
};
</script>
