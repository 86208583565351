import EmployeeListView from "@/views/employee/EmployeeListView.vue";
import EmployeeTimeOffListView from "@/views/employee/employee_time_off/EmployeeTimeOffListView.vue";
import EmployeePenaltyOverviewListView from "@/views/employee/employee_penalty/EmployeePenaltyOverviewListView.vue";
import EmployeePenaltyListView from "@/views/employee/employee_penalty/EmployeePenaltyListView.vue";
import EmployeeNewView from "@/views/employee/employee_detail/EmployeeNewView.vue";
import EmployeeDetailView from "@/views/employee/employee_detail/EmployeeDetailView.vue";
import EmployeeDashboardView from "@/views/employee/employee_detail/employee_dashboard/EmployeeDashboardView.vue";
import EmployeeOverviewDashboardView from "@/views/employee/employee_detail/employee_dashboard/EmployeeOverviewDashboardView.vue";
import EmployeeJobOverview from "@/views/EmployeeJobOverview.vue"

export default [
  {
    path: "/employee/list",
    name: "employeeList",
    component: EmployeeListView,
    meta: { requiresAuth: true, topic: "employee" },
  },
  {
    path: "/employee/timeoff/list",
    name: "employeeTimeOffList",
    component: EmployeeTimeOffListView,
    meta: { requiresAuth: true, topic: "employeeTimeOff" },
  },
  {
    path: "/employee/penality/list",
    name: "employeePenaltyList",
    component: EmployeePenaltyListView,
    meta: { requiresAuth: true, topic: "employeePenalty" },
  },
  {
    path: "/employee/penality-overview/list",
    name: "employeePenaltyOverviewList",
    component: EmployeePenaltyOverviewListView,
    meta: { requiresAuth: true, topic: "employeePenaltyOverview" },
  },
  {
    path: "/employee/edit/:id",
    name: "employeeEdit",
    component: EmployeeDetailView,
    meta: { requiresAuth: true, topic: "employee" },
  },
  {
    path: "/employee/create",
    name: "employeeNew",
    component: EmployeeNewView,
    meta: { requiresAuth: true, topic: "employee" },
  },
  {
    path: "/employee/dashboard_personal/:id&:empId",
    name: "employeeDashboard",
    component: EmployeeDashboardView,
    meta: { requiresAuth: true, topic: "employee" },
  },
  {
    path: "/employee/dashboard",
    name: "employeeOverview",
    component: EmployeeOverviewDashboardView,
    meta: { requiresAuth: true, topic: "employeeOverviewDashboard" },
  },

  {
    path: "/employee/job",
    name: "employeeJob",
    component: EmployeeJobOverview,
    meta: { requiresAuth: true, topic: "employeeJob" },
  },
];
