<template>
	<div name="GPSFilterGroup">
		<v-card flat rounded="lg">
			<HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
			<div class="pa-6">
				<v-row>
					<v-col cols="12" sm="6">
						<div>
							<InputView :title="'บริษัท' + docsTitle" :isRequired="false">
								<v-select
									class="app-custom-input"
									:items="filterDocsCompanyList"
									v-model="formData.company"
									placeholder="เลือก"
									item-value="key"
									item-text="nameTh"
									outlined
									dense
									rounded
									filled
								></v-select>
							</InputView>
						</div>
					</v-col>

					<v-col cols="12" sm="6">
						<div>
							<InputView :title="'โมเดล' + docsTitle" :isRequired="false">
								<v-select
									class="app-custom-input"
									:items="filterDocsModelList"
									v-model="formData.model"
									placeholder="เลือก"
									item-value="key"
									item-text="nameTh"
									outlined
									dense
									rounded
									filled
								></v-select>
							</InputView>
						</div>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<div>
							<CustomFilterDateTypingPickerRange
								placeholder="เลือก"
								:title="'เวลาจ่าย' + docsTitle + 'อยู่ในช่วงนี้'"
								v-model="formData.joinIssueDate"
								:isRequired="false"
							/>
						</div>
					</v-col>

					<v-col cols="12" sm="6">
						<div>
							<CustomFilterDateTypingPickerRange
								placeholder="เลือก"
								:title="'เวลาหมด' + docsTitle + 'อยู่ในช่วงนี้'"
								v-model="formData.joinExpDate"
								:isRequired="false"
							/>
						</div>
					</v-col>
				</v-row>
			</div>

			<v-divider class="mt-5 mb-2"></v-divider>
			<v-card-actions class="px-6 pb-3">
				<NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
				<v-spacer></v-spacer>
				<PrimaryButton :height="35" @on-click="findAction()"
					>กรอง</PrimaryButton
				>
				<NormalButton :height="35" class="ml-5" @on-click="close()"
					>ยกเลิก</NormalButton
				>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import { getAppDataService } from "@/services/api/app";
	import { getGPSModelService } from "@/services/api/vehicle";
	import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
	import NormalButton from "@/components/NormalButton.vue";
	import CustomTextInput from "@/components/CustomTextInput.vue";
	import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
	import InputView from "@/components/InputView.vue";
	import _ from "lodash";

	export default {
		name: "GPSFilterGroup",
		props: {
			docsType: String,
		},
		components: {
			PrimaryButton,
			HeaderCardNormal,
			NormalButton,
			CustomTextInput,
			CustomFilterDateTypingPickerRange,
			InputView,
		},

		data() {
			return {
				queryOrder: {
					// query : formData
					company: "company",
					model: "model",
					///
					lastPaidDateStart: "joinIssueDate",
					lastPaidDateEnd: "joinIssueDate",
					//
					expDateStart: "joinExpDate",
					expDateEnd: "joinExpDate",
				},
				copyFormData: null,
				formData: {
					company: null,
					model: null,
					joinIssueDate: [],
					joinExpDate: [],
				},

				// input selection
				docsTitle: "",
				companyType: "",

				// from api
				filterDocsCompanyList: [],
				filterDocsModelList: [],
			};
		},

		methods: {
			getParamQuery() {
				Object.keys(this.queryOrder).forEach((key) => {
					if (!_.isNil(this.$route.query[key])) {
						switch (key) {
							case "lastPaidDateStart":
							case "lastPaidDateEnd":
								this.formData.joinIssueDate.push(this.$route.query[key]);
								break;
							case "expDateStart":
							case "expDateEnd":
								this.formData.joinExpDate.push(this.$route.query[key]);
								break;
							default:
								this.formData[this.queryOrder[key]] = this.$route.query[key];
						}
					}
				});
			},
			async getData() {
				this.filterDocsCompanyList = await getAppDataService(
					this.$store,
					this.companyType
				);

				this.filterDocsModelList = await getGPSModelService(this.$store);
			},
			getDocsType() {
				if (this.docsType === "ins") {
					this.docsTitle = "ประกัน";
					this.companyType = "com_ins";
				} else if (this.docsType === "act") {
					this.docsTitle = "พ.ร.บ.";
					this.companyType = "com_act";
				} else if (this.docsType === "tax") {
					this.docsTitle = "ภาษี";
					this.companyType = "com_tax";
				} else if (this.docsType === "gps") {
					this.docsTitle = " GPS ";
					this.companyType = "gps_com";
				} else if (this.docsType === "loan") {
					this.docsTitle = "ค่างวดรถ";
					this.companyType = "com_tax";
				}
			},
			async findAction() {
				this.copyFormData = _.cloneDeep(this.formData);
				let filterParams = {};
				// process by check formdata
				Object.keys(this.queryOrder).forEach((key) => {
					if (!_.isNil(this.formData[this.queryOrder[key]])) {
						switch (this.queryOrder[key]) {
							case "joinIssueDate":
								if (this.formData.joinIssueDate.length === 1) {
									filterParams["lastPaidDateStart"] =
										this.formData.joinIssueDate[0];
								} else if (this.formData.joinIssueDate.length === 2) {
									filterParams["lastPaidDateStart"] =
										this.formData.joinIssueDate[0];
									filterParams["lastPaidDateEnd"] =
										this.formData.joinIssueDate[1];
								}
								break;
							case "joinExpDate":
								if (this.formData.joinExpDate.length === 1) {
									filterParams["expDateStart"] = this.formData.joinExpDate[0];
								} else if (this.formData.joinExpDate.length === 2) {
									filterParams["expDateStart"] = this.formData.joinExpDate[0];
									filterParams["expDateEnd"] = this.formData.joinExpDate[1];
								}
								break;
							default:
								filterParams[key] = this.formData[this.queryOrder[key]];
						}
					}
				});
				this.$emit("returnFiltedData", filterParams);
				this.$emit("close-menu");
			},
			close() {
				if (this.copyFormData !== null) {
					this.formData = this.copyFormData;
					this.copyFormData = null;
				}
				this.$emit("close-menu");
			},
			clear() {
				this.copyFormData = _.cloneDeep(this.formData);

				this.formData = {
					company: null,
					model: null,
					joinIssueDate: [],
					joinExpDate: [],
				};
			},
		},
		computed: {},
		mounted() {
			this.getDocsType();
			this.getData();
			this.getParamQuery();
		},
	};
</script>
<style scoped>
	.title-filter {
		font-size: 14px;
		padding-bottom: 6px;
		color: rgb(137, 137, 137);
	}

	.v-input__control {
		height: 40px !important;
	}
</style>
