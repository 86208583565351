<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters ref="mapWrapper">
      <v-col lg="9" md="12" sm="12">
        <DetailBar />
        <!-- toolbox -->
        <div class="wrapper">
          <!-- main map -->
          <!--TODO:: responsive to md sm size -->
          <CoreMap class="core-map" store-name="manageRoute" />

          <div class="overlap-bottom">
            <!-- v-card detail -->
            <StatusOnMapFloat />
          </div>
        </div>
      </v-col>
      <v-col lg="3" md="12" sm="12">
        <MarkerView />
      </v-col>
    </v-row>
    <AppOverlay :isLoading="mainLoading" />
  </v-container>
</template>

<script>
import CoreMap from "./CoreMap";
import DetailBar from "./components/DetailBar.vue";
import DetailOnMapFloat from "./components/DetailOnMapFloat.vue";
import MarkerView from "./components/marker/MarkerView.vue";
import StatusOnMapFloat from "./components/StatusOnMapFloat.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import { processPermission } from "@/services/permissions";

export default {
  name: "JobsCreateView",
  components: {
    CoreMap,
    DetailBar,
    DetailOnMapFloat,
    MarkerView,
    StatusOnMapFloat,
    AppOverlay,
  },
  watch: {},
  computed: {
    mainLoading: function () {
      return this.$store.state.manageRoute.mainLoading;
    },
  },
  data: () => ({
    disabledForm: true,
    jobId: null,
  }),
  methods: {
    setStore() {
      // set default status
      this.$store.dispatch("manageRoute/updateActivateMarker", false);
      this.$store.commit("manageRoute/setJobData", null);
      // set default marker and line on map
      this.$store.dispatch("manageRoute/updateMarkerList", []);
      this.$store.dispatch("manageRoute/updateBoundLatLngCenter", []);
      this.$store.dispatch("manageRoute/updatePolyLine", []);
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");
        if (this.permList.admin_all === true) {
          this.$store.commit("manageRoute/setIsPermissionCanEdit", true);
          this.$store.commit("manageRoute/setIsPermissionCanView", true);
          return;
        }

        if (this.permList.jobs_crud === true) {
          this.$store.commit("manageRoute/setIsPermissionCanView", true);
          this.$store.commit("manageRoute/setIsPermissionCanEdit", true);
          return;
        }

        this.$router.replace("/caj/jobs/list");
      }
    },
  },
  mounted() {
    this.$store.commit("manageRoute/setMode", -1);
    this.checkUserPermissions();
  },
  destroyed() {
    // exist and reset mode
    this.setStore();
    this.$store.dispatch("manageRoute/updateMainGMapActive", false);
    this.$store.dispatch("manageRoute/updateMainLoading", false);
    this.$store.commit("manageRoute/setActivateInput", false);
    this.$store.commit("manageRoute/setIsPermissionCanEdit", false);
    this.$store.commit("manageRoute/setIsPermissionCanView", false);
  },
};
</script>

<style lang="scss" scoped>
@import "./JobsStyle.scss";
</style>
