import axios from "axios";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "@/services/local_storage";

const DEVICE_UUID = "DEVICE_UUID";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const baseURL = process.env.VUE_APP_API_URL;

export async function getUUID() {
  let deviceUUID = getLocalStorage(DEVICE_UUID);
  if (deviceUUID) return deviceUUID;

  try {
    const response = await axios.get(baseURL + "/tool/uuid", config);

    if (response.status == 200) {
      deviceUUID = response.data.data.uuid;
      setLocalStorage(DEVICE_UUID, deviceUUID);
    }
  } catch (error) {
    deviceUUID = null;
  }
  return deviceUUID;
}
