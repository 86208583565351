<template>
  <v-dialog fullscreen v-model="show" @keydown="handleKeyDown">
    <v-card v-if="show">
      <!-- <v-card-title class="text-h5"> ใบลางาน </v-card-title> -->
      <v-toolbar color="primary" dark>
        <v-card-title> บุคคลและงาน </v-card-title> <v-spacer></v-spacer
        ><v-btn icon @click.native="cancelStatus"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >

      <!-- <v-text-field @keydown="handleKeyDown"> </v-text-field> -->

      <v-card-text class="px-6">
        <!--  -->
        <v-row no-gutters class="mt-6 ml-12 pl-12" v-if="series.length > 0">
          <v-col class="py-0 ma-0 pl-12 ml-13" cols="12" lg="4" sm="4">
            <CustomTextInputImprove
              v-model="driverFilter"
              ref="driverFilter"
              title=""
              :clearable="true"
              :hideDetail="true"
              :placeholder="`ค้นหาชื่อพนักงาน`"
              dataInputName="driverFilter"
              :expend-margin="false"
            />
          </v-col>

          <v-col class="pl-3" cols="12" lg="2" sm="2">
            <!-- <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title=""
              :hideDetail="true"
              :isOptionRange="false"
              v-model="dateRange"
              :isRequired="false"
            /> -->

            <CustomDateTypingPickerFormInput
              ref="startDate"
              v-model="startDatePicker"
              title=""
              placeholder="วัน/เดือน/ปี"
              :expendMargin="false"
              :isRequired="false"
              :disabled="false"
              @handle-update-input="startDatePickerUpdate"
              dataInputName="startDate"
            />
          </v-col>
          <v-col class="pl-3" cols="12" lg="2" sm="2">
            <CustomDateTypingPickerFormInput
              ref="endDate"
              v-model="endDatePicker"
              title=""
              placeholder="วัน/เดือน/ปี"
              :expendMargin="false"
              :isRequired="false"
              :disabled="false"
              @handle-update-input="endDatePickerUpdate"
              dataInputName="endDate"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="ml-12 pl-12" v-if="series.length > 0">
          <v-col class="ma-0 pt-3 pl-12 ml-13" cols="12">
            <span v-for="(value, key) in jobsStatusObj" :key="key" class="mr-2">
              <span v-if="key !== 'PLANNING'">
                <v-icon :color="jobsStatusColor(key)">mdi-circle</v-icon>
                <span :class="['px-1', `${jobsStatusColor(key)}--text`]">{{
                  value
                }}</span>
              </span>
            </span>
          </v-col>
        </v-row>
        <div class="mt-3" v-if="series.length > 0">
          <div id="chart">
            <apexchart
              type="rangeBar"
              :height="getChartHeight"
              :options="chartOptions"
              :series="filteredSeries"
            ></apexchart>
          </div>
        </div>
        <v-row v-else no-gutters class="mt-9 text-center text-app-detail-large"
          ><v-col>
            <span v-if="driverFilter === null"
              >กรุณาเพิ่มคนขับเพื่อดูตารางงาน</span
            >
            <span v-else>ไม่พบคนขับ กรุณาเพิ่มคนขับเพื่อดูตารางงาน</span>
          </v-col></v-row
        >
        <v-row no-gutters :class="filteredSeries.length > 0 ? 'mt-0' : 'mt-6'">
          <v-col class="text-center">
            <!-- <PrimaryButton
              small
              :outlined="true"
              :isFullWidth="false"
              icon="mdi-plus"
              @on-click="handleAddClick()"
            >
            </PrimaryButton> -->

            <v-btn
              @click="handleAddClick()"
              class="mx-3"
              color="primary"
              elevation="0"
              light
              large
              icon
              outlined
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <EmployeeNameSearchingDialog ref="groupNameDialog" />
    <AppDialogConfirm ref="alertStatus" />
    <AppDialogConfirm ref="dialogAlert" />
    <AppOverlay :isLoading="isLoading" />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import AppOverlay from "@/components/AppOverlay.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { getEmployeeJobScheduleList } from "@/services/api/employee";
import { processPermission } from "@/services/permissions";
import moment from "moment";
import { mapGetters } from "vuex";
import PrimaryButton from "@/components/PrimaryButton.vue";
import EmployeeNameSearchingDialog from "@/components/EmployeeNameSearchingDialog.vue";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";

export default {
  name: "EmployeeJobDialog",
  // props: ["dialogStatus"],
  data: () => ({
    isLoading: false,
    valid: false,
    show: false,
    resolve: null,
    reject: null,
    initChartHeight: 70,
    barHeight: 50,
    scopeDate: 0,

    numUniqueRows: 0,

    driverFilter: null,
    empList: [],
    dateRange: { startDate: null, endDate: null },

    startDate: null,
    endDate: null,

    startDatePicker: null,
    endDatePicker: null,

    itemsSchedule: [],
    requestSchedule: [],
    componentLoading: false,
    searchCountTimer: null,
    // series: [{ data: [] }],
    series: [],
    filteredSeries: [],

    currentAnnotations: [],
    jobColors: [],
    isInited: false,

    // userIdString: "633d0dc63bdedeb4e8df21a6",
    userIdString: null,

    fetchDataError: 0,
    isPermissionCanViewContract: false,
    isPermissionCanViewJob: false,
    isPermissionCanViewVehicle: false,
    // contract job vehicle
    contractJobVehicleSelectedData: {},
  }),

  watch: {
    series(val) {
      if (val.length > 0) {
        this.currentAnnotations = [];
        this.processAnnoations();
      } else {
        this.currentAnnotations = [];
      }
    },

    filteredSeries(val) {
      this.numUniqueRows = 0;
      let empIdList = val.map((a) => a.data[0].x);
      this.numUniqueRows = new Set(empIdList).size;
    },

    driverFilter(empName) {
      this.filteredSeries = [
        {
          data: [
            {
              x: "",
              y: ["", ""],
              jobURL: "",
              jobName: "",
              fillColor: "",
              titleColor: "",
            },
          ],
        },
      ];
      if (!_.isNil(empName)) {
        this.series.forEach((e) => {
          let searchPos = e.data[0].x.search(empName);
          if (searchPos !== -1) {
            this.filteredSeries.push(e);
          }
        });
        if (this.filteredSeries.length > 1) {
          this.filteredSeries.shift();
        }
      } else {
        this.filteredSeries = _.cloneDeep(this.series);
      }
    },

    "dateRange.startDate": function (val) {
      if (
        !_.isNil(this.dateRange["startDate"]) &&
        !_.isNil(this.dateRange["endDate"])
      ) {
        if (this.isInited) {
          this.itemsSchedule = [];
          this.processDate(this.dateRange);
          this.processAnnoations();
          this.getData();
        }
      }
    },

    "dateRange.endDate": function (val) {
      if (
        !_.isNil(this.dateRange["startDate"]) &&
        !_.isNil(this.dateRange["endDate"])
      ) {
        if (this.isInited) {
          this.itemsSchedule = [];
          this.processDate(this.dateRange);
          this.processAnnoations();
          this.getData();
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusObj: "jobs/jobsStatusObj",
    }),

    getChartHeight: function () {
      if (!_.isNil(this.numUniqueRows)) {
        if (this.numUniqueRows === 1) {
          return this.initChartHeight * 2;
        }
        let height = this.numUniqueRows * this.barHeight + this.initChartHeight;
        return height;
      }
      return this.initChartHeight * 2;
    },

    chartOptions: function () {
      return {
        chart: {
          type: "rangeBar",
          parentHeightOffset: 0,
          fontFamily: "'Noto Sans Thai', sans-serif",
          componentRefreshCounter: 0,
          toolbar: {
            offsetY: -90,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
          },
          zoom: {
            enabled: true,
          },

          // events: {
          //   scrolled: function (chartContext, { xaxis }) {
          //     let start = moment(xaxis["min"]);
          //     let end = moment(xaxis["max"]);
          //     console.log(chartContext);
          //   },
          // },
          events: {
            beforeResetZoom: (chartContext, opts) => {
              return {
                xaxis: {
                  min: moment(this.startDate).valueOf(),
                  max: moment(this.startDate).add(1, "days").valueOf(),
                },
              };
            },
            beforeZoom: (e, { xaxis }) => {
              let maindifference =
                moment(this.startDate + "T00:00:00")
                  .add(6, "hours")
                  .valueOf() - moment(this.startDate + "T00:00:00").valueOf();

              let zoomdifference = xaxis.max - xaxis.min;

              if (zoomdifference < maindifference)
                return {
                  // dont zoom out any further
                  xaxis: {
                    min: xaxis.min,
                    max: moment(xaxis.min).add(6, "hours").valueOf(),
                  },
                };
              else {
                return {
                  // keep on zooming
                  xaxis: {
                    min: xaxis.min,
                    max: xaxis.max,
                  },
                };
              }
            },

            click: function (event, chartContext, config) {
              if (config.seriesIndex > -1) {
                var jobURL =
                  config.config.series[config.seriesIndex]["data"][
                    config.dataPointIndex
                  ]["jobURL"];
                window.open(jobURL, "_blank");
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "5",
            isDumbbell: true,
            rangeBarGroupRows: true,
            dataLabels: {
              position: "center",
              // maxItems: 100,
              hideOverflowingLabels: true,
              orientation: "horizontal",
            },
            dumbbellColors: this.jobColors,
          },
        },
        xaxis: {
          type: "datetime",
          position: "top",
          min: moment(this.startDate + "T00:00:00").valueOf(),
          // max: moment(this.endDate + "T00:00:00").valueOf(),
          max: moment(this.startDate + "T00:00:00")
            .add(1, "days")
            .valueOf(),
          labels: {
            datetimeUTC: false,
            trim: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        annotations: {
          xaxis: this.currentAnnotations,
        },
        stroke: {
          width: 0,
        },
        yaxis: {
          labels: {
            minWidth: 200,
            maxWidth: 200,
            style: {
              background: "#fff",
              fontSize: "14px",
            },
            formatter: function (val, opts) {
              if (opts !== undefined) {
                if (opts.w.globals.series.length === 0) {
                  return "";
                } else if (opts.w.globals.series[opts.seriesIndex][0] === NaN) {
                  return "";
                }
              }
              return val;
            },
          },
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          // x: {
          //   show: true,
          // },
        },
        // fill: {
        //   type: "solid",
        //   opacity: 0.6,
        // },
        // colors: ["#000"],
        dataLabels: {
          enabled: true,
          offsetY: -10,
          formatter: function (val, opts) {
            var label = opts.w.globals.labels[opts.dataPointIndex];
            var a = moment(val[0]);
            var b = moment(val[1]);
            // var diff = b.diff(a, "days");
            var jobName =
              opts.w.config.series[opts.seriesIndex]["data"][
                opts.dataPointIndex
              ]["jobName"];

            if (
              val[0] >= opts.config.xaxis.max ||
              val[1] <= opts.config.xaxis.min
            )
              return "";
            return jobName;
          },
          style: {
            colors: ["#AAB7B8"],
            fontSize: "10px",
          },
        },
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        tooltip: {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: function (value, opts) {
              if (opts) {
                var routeName =
                  opts.w.config.series[opts.seriesIndex]["data"][
                    opts.dataPointIndex
                  ]["routeName"];
                var contractTitle =
                  opts.w.config.series[opts.seriesIndex]["data"][
                    opts.dataPointIndex
                  ]["contractTitle"];
                if (typeof value === "string") {
                  // return `<p class="my-0">เส้นทาง: <span class="text-app-normal">${routeName}<span></p>
                  //     <p class="my-0">สัญญา: <span class="text-app-normal">${contractTitle}</span></p>
                  //     <p class="my-0">ผู้ขับ: <span class="text-app-normal">${value}</span></p>
                  //     <span class="my-0">เวลา</span>`;
                  return `<p class="my-0 text-app-normal">เส้นทาง: <span class="text-app-title font-weight-bold black--text">${routeName}<span></p>
                      <p class="my-0 text-app-normal">สัญญา: <span class="text-app-title font-weight-bold black--text">${contractTitle}</span></p>
                      <p class="my-0 text-app-normal">ผู้ขับ: <span class="text-app-title font-weight-bold black--text">${value}</span></p>
                      <span class="my-0 text-app-normal">เวลา</span>`;
                }
              }
              return `<span class="text-app-title font-weight-bold black--text">${moment(
                value
              ).format("HH:mm")}</span>`;
            },
          },

          y: {
            title: {
              formatter: function (value, opts) {
                // if (opts !== undefined) {
                // console.log(value);
                var jobName =
                  opts.w.config.series[opts.seriesIndex]["data"][
                    opts.dataPointIndex
                  ]["jobName"];
                var titleColor =
                  opts.w.config.series[opts.seriesIndex]["data"][
                    opts.dataPointIndex
                  ]["titleColor"];
                return (
                  value + `<span class='${titleColor}--text'>${jobName}</span>`
                );
                // }
                // return value;
              },
            },
          },
        },
      };
    },
  },

  // destroyed() {
  //   window.removeEventListener("scroll", this.actionScroll);
  // },
  // created() {
  //   window.addEventListener("scroll", this.actionScroll);
  // },

  mounted() {
    // document.body.onclick = function (e) {
    //   if (e.ctrlKey) {
    //     console.log("ctr key was pressed during the click");
    //   }
    // };
    // this.processEventListener();
    // clearTimeout(this.searchCountTimer);
    // this.searchCountTimer = setTimeout(() => {
    //   this.addCurrentTimeAnnotation();
    // }, 3000);
    // this.updateCurrentTime();
  },

  methods: {
    // actionScroll(event) {
    //   const scroll = window.scrollY;
    //   console.log(scroll); // shows pixel position of window scroll
    //   // You can also perform any action while using this method
    // },

    handleKeyDown(event) {
      // console.log("keyDown event:", event);
    },

    isKeyPressed() {
      console.log("The CTRL key was pressed!");
    },

    processDate(date) {
      if (!_.isNil(date) && date !== undefined) {
        if (date["startDate"] && date["endDate"]) {
          this.startDate = date["startDate"];
          this.endDate = date["endDate"];
        } else {
          this.startDate = moment(date["startDate"])
            .subtract(6, "hours")
            .format("YYYY-MM-DD");
          this.endDate = moment(date["startDate"])
            .add(5, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        this.startDate = moment().subtract(6, "hours").format("YYYY-MM-DD");
        this.endDate = moment().add(5, "days").format("YYYY-MM-DD");
      }

      this.startDatePicker = _.clone(this.startDate);
      this.endDatePicker = _.clone(this.endDate);

      this.dateRange = {
        startDate: _.clone(this.startDate),
        endDate: _.clone(this.endDate),
      };

      // console.log(this.startDate, this.endDate);
    },

    // updateCurrentTime() {
    //   window.setInterval(() => {
    //     this.addCurrentTimeAnnotation();
    //   }, 5000);
    // },

    processAnnoations() {
      if (this.filteredSeries.length > 0) {
        let now = moment(this.startDate).clone();
        while (now.isSameOrBefore(moment(this.endDate))) {
          this.addAnnotation(now);
          now.add(1, "days");
        }
        this.addInitCurrentTimeAnnotation();
      }
    },

    addAnnotation(date) {
      this.currentAnnotations.push({
        x: date.valueOf(),
        strokeDashArray: 0,

        borderColor: "#4d5656",
        label: {
          offsetY: -8,
          offsetX: 10,
          borderColor: "#4d5656",
          style: {
            color: "#fff",
            background: "#4d5656",
            fontSize: "10px",
          },
          text: date.format("DD-MMM-YYYY"),
        },
      });
    },

    addInitCurrentTimeAnnotation() {
      this.currentAnnotations.push({
        id: "currentDateTime",
        x: moment().valueOf(),
        strokeDashArray: 0,
        borderColor: "#49c496",
        label: {
          offsetY: -8,
          offsetX: 13,
          borderColor: "#49c496",
          style: {
            color: "#fff",
            background: "#49c496",
            fontSize: "12px",
          },
          text: "Now",
        },
      });
    },

    getSearchItem(_searchingParameter) {},

    processUserIdString() {
      // console.log("requestSchedule", this.requestSchedule);
      if (!_.isNil(this.requestSchedule)) {
        if (this.requestSchedule.length > 0) {
          this.userIdString = "";
          for (let i = 0; i < this.requestSchedule.length; i++) {
            if (i < this.requestSchedule.length - 1) {
              this.userIdString =
                this.userIdString + this.requestSchedule[i]["key"] + ",";
            } else {
              this.userIdString =
                this.userIdString + this.requestSchedule[i]["key"];
            }
          }
          // console.log(this.userIdString);
        }
      }
    },

    async getData() {
      this.isLoading = true;
      // this.resetfilteredSeries();

      // console.log("startDate:", this.startDate, ", endDate:", this.endDate);
      // console.log("filteredSeries", this.filteredSeries);

      //get employees' schedule
      if (!_.isNil(this.userIdString)) {
        let queryString = new URLSearchParams({
          userId: this.userIdString,
          start: this.startDate,
          end: this.endDate,
        }).toString();
        const respSchedule = await getEmployeeJobScheduleList(
          this.$store,
          queryString
        );
        if (respSchedule["code"] === 200) {
          if (respSchedule["data"].length > 0) {
            this.itemsSchedule = [
              ...this.itemsSchedule,
              ...respSchedule["data"],
            ];
          }
        }

        this.processScheduleToSeries();
      }

      this.isLoading = false;
    },

    async handleAddClick() {
      const newEmployee = await this.$refs.groupNameDialog.open();
      if (!_.isNil(newEmployee)) {
        if (!this.requestSchedule.some((e) => e.key === newEmployee.key)) {
          // this.userIdString = newEmployee["key"];
          this.requestSchedule.push(newEmployee);
          this.processUserIdString();

          this.getData();
        }
      }

      // if (!_.isNil(newGroup)) {
      //   let respData = await postEmployeeGroupService(this.$store, newGroup);

      //   if (respData["code"] === 200) {
      //     this.handleEditClick(respData["data"]["id"]);
      //   } else {
      //     // insert fail
      //   }
      // }
    },

    processScheduleToSeries() {
      //set schedule to series
      let scheduleSeries = [];
      let searchEmpList = [];
      this.driverFilter = null;
      this.jobColors = [];

      if (this.itemsSchedule.length > 0) {
        this.itemsSchedule.forEach((employee) => {
          scheduleSeries.push({
            data: [
              {
                x: employee["userName"],
                y: [
                  moment(employee["startDate"]).valueOf(),
                  moment(employee["endDate"]).valueOf(),
                ],
                jobURL: this.$router.resolve({
                  name: "jobsEdit",
                  params: { id: employee["_id"] },
                }).href,
                jobName: employee["name"],
                routeName: employee["routeName"] ? employee["routeName"] : "-",
                contractTitle: employee["contractTitle"]
                  ? employee["contractTitle"]
                  : "-",
                fillColor: this.jobsStatusColorValue(employee["status"]),
                titleColor: this.jobsStatusColor(employee["status"]),
              },
            ],
          });
          searchEmpList.push({
            key: employee["_id"],
            value: employee["userName"],
          });
          this.jobColors.push(this.jobsStatusColorValue(employee["status"]));
        });
      }

      this.requestSchedule.forEach((emp) => {
        if (
          !this.itemsSchedule.some((e) => e.userId === emp.key) ||
          scheduleSeries.length === 0 ||
          this.itemsSchedule.length === 0
        ) {
          // console.log;
          scheduleSeries.push({
            data: [
              {
                x: emp.value,
                y: ["", ""],
                jobURL: "",
                jobName: "",
                routeName: "",
                contractTitle: "",
                fillColor: "",
                titleColor: "",
              },
            ],
          });

          searchEmpList.push({ key: emp.key, value: emp.value });
        }
      });

      this.series = _.cloneDeep(scheduleSeries);
      this.filteredSeries = _.cloneDeep(scheduleSeries);
      this.empList = _.clone(searchEmpList);
      if (!this.isInited) {
        this.isInited = true;
      }

      // console.log("filteredSeries", this.filteredSeries);
      // if (!this.isInited) {
      //   this.processAnnoations();
      // }
    },

    startDatePickerUpdate(date) {
      this.dateRange["startDate"] = _.clone(date);
    },

    endDatePickerUpdate(date) {
      this.dateRange["endDate"] = _.clone(date);
    },

    processEventListener() {
      const element = document.querySelector("#chart");

      element.addEventListener("wheel", (event) => {
        event.preventDefault();

        element.scrollBy({
          left: event.deltaY < 0 ? -30 : 30,
        });
      });
    },

    open(items, date) {
      this.processDate(date);
      this.processAnnoations();
      if (!_.isNil(items) && items !== undefined) {
        this.requestSchedule = _.clone(items);
        this.processUserIdString();
      }
      this.getData();
      this.checkUserPermissions();
      // this.getStat();

      this.show = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    cancelStatus() {
      this.reset();
      this.show = false;
      this.resolve(null);
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.user_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.user_info === true) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.user_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },

    // resetfilteredSeries() {
    //   this.filteredSeries = [
    //     {
    //       data: [
    //         {
    //           x: "",
    //           y: ["", ""],
    //           jobURL: "",
    //           jobName: "",
    //           fillColor: "",
    //           titleColor: "",
    //         },
    //       ],
    //     },
    //   ];
    // },

    reset() {
      this.isInited = false;
      this.userIdString = null;
      this.series = [];
      this.dateRange = { startDate: null, endDate: null };
      this.filteredSeries = [];
      this.itemsSchedule = [];
      this.requestSchedule = [];
      this.currentAnnotations = [];
    },
  },
  components: {
    AppDialogConfirm,
    AppOverlay,
    PrimaryButton,
    EmployeeNameSearchingDialog,
    AppAutoCompleteUserSearching,
    CustomTextInputImprove,
    CustomDateTypingPickerFormInput,
    apexchart: VueApexCharts,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}

.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}

/* #chart {
  overflow: auto;
} */
</style>
