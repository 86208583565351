<template>
  <div name="customCardView">
    <v-card flat rounded="lg">
      <v-card
        flat
        :color="color"
        :class="rounded ? 'rounded-t-lg' : ''"
        rounded="0"
      >
        <div class="title-section">
          <v-card-title>
            <slot name="title"></slot>
          </v-card-title>
        </div>
        <v-divider></v-divider>
      </v-card>

      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CustomCardView",

  components: {},

  props: {
    rounded: { default: true, type: Boolean },
  },

  data() {
    return {
      color: this.$vuetify.theme.themes.light.primary,
    };
  },
};
</script>

<style scoped>
.title-section {
  font-size: 16px;
  color: white;
  font-weight: bold;
}
</style>
