<template>
  <v-container name="RecompenseTabComponent" v-if="isPermissionCanView">
    <v-row dense class="px-3">
      <v-col cols="6">
        <v-alert
          v-if="isError === 1"
          dense
          color="red lighten-2"
          class="mb-0"
          dark
        >
          ไม่สามารถบันทึกค่าตอบแทนผู้ขับต่อเที่ยวได้
        </v-alert>
        <v-alert
          v-if="isError === 2"
          dense
          color="red lighten-2"
          class="mb-0"
          dark
        >
          ไม่สามารถบันทึกรายการอื่นได้
        </v-alert>
        <v-alert
          v-if="isError === 3"
          dense
          color="red lighten-2"
          class="mb-0"
          dark
        >
          ไม่สามารถลบรายการได้
        </v-alert>
        <!-- <div class="d-flex flex-column fill-height"> -->
        <v-card class="fill-height" elevation="0">
          <v-card-text>
            <v-row no-gutters>
              <v-col auto>
                <p class="text-app-detail-large font-weight-bold">
                  ค่าตอบแทนผู้ขับต่อเที่ยว
                </p>
                <div v-if="!recompenseAmountFormEditMode">
                  <p class="text-h4 font-weight-bold mb-4">
                    {{
                      formData["recompenseAmount"]
                        ? `${formData["recompenseAmount"].toLocaleString()} บาท`
                        : "0"
                    }}
                    <v-btn
                      icon
                      class="mr-4"
                      @click="
                        recompenseAmountFormEditMode =
                          !recompenseAmountFormEditMode
                      "
                      v-if="isFormCantEdit"
                    >
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                  </p>

                  <p class="text-body-1 text-app-detail my-0">
                    หลังหักค่าใช้จ่าย:
                    {{
                      formData["recompenseTotal"]
                        ? `${formData["recompenseTotal"].toLocaleString()} บาท`
                        : "0"
                    }}
                  </p>
                </div>

                <div class="my-1" v-if="recompenseAmountFormEditMode">
                  <v-row dense>
                    <v-col cols="12">
                      <CustomTextInputCurrency
                        ref="recompenseAmount"
                        v-model="newRecompenseAmount"
                        class="custom-fill-width mr-2"
                        title=""
                        placeholder="หน่วยบาท"
                        :hide-detail="true"
                        :small="true"
                        :input-view-bottom-margin="false"
                        :expend-margin="false"
                        :autofocus="true"
                        :disabled="isDisabled"
                      >
                      </CustomTextInputCurrency>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        class="mr-1"
                        color="success"
                        outlined
                        :disabled="isDisabled"
                        @click="saveRecompenseAmount"
                      >
                        บันทึก
                      </v-btn>
                      <v-btn
                        class="mr-3"
                        color="error"
                        outlined
                        :disabled="isDisabled"
                        @click="cancelRecompenseAmount"
                      >
                        ยกเลิก
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- -->
      <v-divider class="divider" vertical />
      <!-- -->
      <v-col cols="6">
        <div class="pl-1">
          <div class="d-flex mb-1">
            <p class="text-app-detail font-weight-light mb-0 ml-3">
              รายการอื่นๆ
            </p>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isFormCantEdit"
              color="primary"
              small
              @click="addIncomeAndExpensesDialog"
            >
              <v-icon> mdi-plus </v-icon> เพิ่มข้อมูล
            </v-btn>
          </div>

          <v-simple-table
            fixed-header
            dense
            height="150px"
            v-if="formData.recompenseIncomeAndExpensesList.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ชื่อรายการ</th>
                  <th>จำนวนเงิน</th>
                  <th v-if="isFormCantEdit"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ie, idx) in formData.recompenseIncomeAndExpensesList"
                  :key="idx"
                >
                  <td width="70%">{{ ie.title }}</td>
                  <td>
                    <span :class="ie.type === 1 ? 'green--text' : 'red--text'">
                      {{ ie.type === 1 ? "+" : "-" }}
                      {{ `${ie.amount.toLocaleString()} บาท` }}
                    </span>
                  </td>

                  <td v-if="isFormCantEdit">
                    <v-btn
                      icon
                      @click="deleteIncomeAndExpensesDialog(ie._id)"
                      color="subtitle"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            class="text-center"
            v-if="formData.recompenseIncomeAndExpensesList.length === 0"
          >
            ไม่มีข้อมูล
          </div>
        </div>
      </v-col>
    </v-row>
    <RecompenseOtherInputAndExpensesDialog
      ref="recompenseOtherInputAndExpensesDialog"
    />
    <AppDialogConfirm ref="recompenseOtherInputAndExpenseConfirmDialog" />
  </v-container>
</template>

<script>
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import RecompenseOtherInputAndExpensesDialog from "./RecompenseOtherInputAndExpensesDialog.vue";
import _ from "lodash";
import {
  deleteRecompenseIncomeAndExpensesToJobService,
  putRecompenseIncomeAndExpensesToJobService,
} from "@/services/api/jobs";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";

export default {
  name: "RecompenseTab",
  components: {
    CustomTextInputCurrency,
    RecompenseOtherInputAndExpensesDialog,
    AppDialogConfirm,
  },
  data() {
    return {
      isError: 0,
      recompenseAmountFormEditMode: false,
      incomePerRoundAmountFormEditMode: false,
      newRecompenseAmount: null,
      newIncomePerRoundAmount: null,
      formData: {
        incomePerRound: null,
        recompenseAmount: null,
        recompenseTotal: null,
        recompenseInAndExpTotal: null,
        recompenseIncomeAndExpensesList: [],
      },
      isMounted: true,
    };
  },

  computed: {
    isDisabled: function () {
      return !this.isPermissionCanEdit;
    },
    isFormCantEdit: function () {
      return this.isPermissionCanEdit && this.isPermissionForceCanEdit;
    },
    isPermissionForceCanEdit: function () {
      return _.get(
        this.$store.state.manageRoute,
        "jobData.canEditPayment",
        true
      );
    },
    isPermissionCanEdit: function () {
      return this.$store.state.manageRoute.isPermissionCanEdit;
    },
    isPermissionCanView: function () {
      return this.$store.state.manageRoute.isPermissionCanView;
    },
  },

  watch: {
    "$store.state.manageRoute.jobData": function (newValue) {
      this.processData(newValue);
    },
    "$store.state.manageRoute.tabData": {
      immediate: false,
      handler(moveToTabIndex) {
        this.changeByMoveingTab();
      },
    },
  },

  methods: {
    processData(jobData) {
      this.formData = {
        incomePerRound: _.get(jobData, "incomePerRound", null),
        recompenseAmount: _.get(jobData, "recompenseAmount", null),
        recompenseTotal: _.get(jobData, "recompenseTotal", null),
        recompenseInAndExpTotal: _.get(
          jobData,
          "recompenseInAndExpTotal",
          null
        ),
        recompenseIncomeAndExpensesList: _.get(
          jobData,
          "recompenseIncomeAndExpensesList",
          []
        ),
      };
      // this.checkCompletedForm();
    },
    checkCompletedForm() {
      if (this.isMounted) {
        if (!_.isNil(this.formData)) {
          if (!_.isNil(this.formData["recompenseAmount"])) {
            this.$store.dispatch("manageRoute/updateMoveToTab", 2);
            this.isMounted = false;
          }
        }
      }
    },
    // list
    async addIncomeAndExpensesDialog() {
      let recompenseOtherData =
        await this.$refs.recompenseOtherInputAndExpensesDialog.open();
      if (!_.isNil(recompenseOtherData)) {
        // send to database
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData._id,
          type: "list",
        }).toString();

        try {
          let respData = await putRecompenseIncomeAndExpensesToJobService(
            this.$store,
            queryString,
            {
              ...recompenseOtherData,
            }
          );

          if (respData["code"] === 200) {
            let _newDataObj = {
              ...recompenseOtherData,
              _id: respData["data"]["reIncomeAndExpen"],
            };
            this.formData["recompenseIncomeAndExpensesList"].push(_newDataObj);
            this.formData["recompenseTotal"] =
              respData["data"]["recompenseTotal"];
            this.isError = 0;
          } else {
            this.isError = 2; // 2 : error from insert list
          }
        } catch (error) {
          console.error(error);
          this.isError = 2; // 2 : error from insert list
        }

        this.$store.dispatch("manageRoute/updateMainLoading", false);
      }
    },

    async deleteIncomeAndExpensesDialog(incomeAndExpensesId) {
      // call comfirm diallog
      if (
        await this.$refs.recompenseOtherInputAndExpenseConfirmDialog.open(
          "ต้องการลบรายการนี้ ?",
          null,
          DialogType.ACTION,
          {
            width: 400,
            zIndex: 200,
            noconfirm: true,
          }
        )
      ) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData._id,
          recomid: incomeAndExpensesId,
        }).toString();

        try {
          let respData = await deleteRecompenseIncomeAndExpensesToJobService(
            this.$store,
            queryString
          );

          if (respData["code"] === 200) {
            // find Index
            let atLocation = _.findIndex(
              this.formData["recompenseIncomeAndExpensesList"],
              { _id: incomeAndExpensesId }
            );

            this.formData["recompenseIncomeAndExpensesList"].splice(
              atLocation,
              1
            );

            this.formData["recompenseTotal"] =
              respData["data"]["recompenseTotal"];
            this.isError = 0;
          } else {
            this.isError = 3; // 2 : error from insert list
          }
        } catch (error) {
          console.error(error);
          this.isError = 3; // 2 : error from insert list
        }
        this.$store.dispatch("manageRoute/updateMainLoading", false);
      }
    },
    // end linse
    changeByMoveingTab() {
      if (!_.isNil(this.$store.state.manageRoute.tabData)) {
        if (this.$store.state.manageRoute.tabData["p"] === "editRecompense") {
          setTimeout(() => {
            this.recompenseAmountFormEditMode = true;
            // this.$refs.recompenseAmount.focus();
          }, 100);

          // this.$refs.newRecompenseAmount.$el.focus();
        }
      }
    },

    async saveRecompenseAmount() {
      if (
        !_.isNil(this.newRecompenseAmount) ||
        !_.isNil(this.newIncomePerRoundAmount)
      ) {
        this.$store.dispatch("manageRoute/updateMainLoading", true);
        let queryString = new URLSearchParams({
          id: this.$store.state.manageRoute.jobData._id,
          type: "amount",
        }).toString();
        //
        try {
          let _newIncomePerRoundAmount = null;
          if (this.newIncomePerRoundAmount !== null) {
            _newIncomePerRoundAmount = this.newIncomePerRoundAmount;
          } else {
            _newIncomePerRoundAmount = this.formData["incomePerRound"];
          }

          let _newRecompenseAmount = null;
          if (this.newRecompenseAmount !== null) {
            _newRecompenseAmount = this.newRecompenseAmount;
          } else {
            _newRecompenseAmount = this.formData["recompenseAmount"];
          }

          //
          const responseData = await putRecompenseIncomeAndExpensesToJobService(
            this.$store,
            queryString,
            {
              incomePerRound: _newIncomePerRoundAmount,
              recompenseAmount: _newRecompenseAmount,
            }
          );

          if (responseData["code"] === 200) {
            this.formData = {
              ...this.formData,
              recompenseTotal: responseData["data"]["recompenseTotal"],
              recompenseAmount: _.toNumber(
                responseData["data"]["recompenseAmount"]
              ),
              incomePerRound: _.toNumber(
                responseData["data"]["incomePerRound"]
              ),
            };
            // update job status
            this.$store.dispatch("manageRoute/updateJobData", {
              ...this.formData,
              jobStatusList: responseData["data"]["jobStatusList"],
            });

            this.isError = 0;
          } else {
            this.isError = 1; // 2 : error from insert amount
          }
        } catch (error) {
          console.error(error);
          this.isError = 1; // 2 : error from insert amount
        }

        this.$store.dispatch("manageRoute/updateMainLoading", false);
      }
      this.cancelRecompenseAmount();
    },
    cancelRecompenseAmount() {
      this.newRecompenseAmount = null;
      this.newIncomePerRoundAmount = null;
      this.recompenseAmountFormEditMode = false;
      this.incomePerRoundAmountFormEditMode = false;
    },
  },

  mounted() {
    // this.isMounted = true;
    let jobData = _.get(this.$store, "state.manageRoute.jobData", null);
    if (jobData !== null) {
      this.processData(jobData);
    }
  },
};
</script>

<style scoped>
.custom-fill-width {
  width: 100%;
}
.recompense-list-view {
  height: 180px;
  overflow-y: auto !important;
}
</style>
