<template>
  <div name="vehicleEventDetailView">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

    <v-container grid-list-xs v-if="isPermissionCanView">
      <v-row dense>
        <v-col lg="7" sm="12" cols="12">
          <div class="d-flex flex-column justify-start fill-height">
            <v-card class="mb-2 rounded-lg" outlined>
              <v-list-item two-line>
                <v-list-item-avatar size="60" color="primary">
                  <v-img :src="pageData.userId.profileImage || null"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="mb-1">
                    <span class="text-app-title font-weight-bold">
                      {{
                        pageData.userId
                          ? pageData.userId.dpNameTh
                          : "ไม่มีข้อมูล"
                      }}</span
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-app-detail-normal">
                      {{
                        pageData.userId.empId
                          ? "(" + pageData.userId.empId + ")"
                          : "..."
                      }}
                      , เบอร์โทรศัพท์:
                      {{
                        pageData.userId.dpPhoneNumeber
                          ? pageData.userId.dpPhoneNumeber
                          : "-"
                      }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <!--  -->
            <v-card class="fill-height rounded-lg" outlined>
              <v-card-title class="pb-0">
                <span>{{ vehicleEventTypeValue(pageData.type) }}</span
                >: {{ pageData.title }}
                <v-spacer></v-spacer>
                <v-btn
                  elevation="0"
                  outlined
                  class="mr-3"
                  :to="'/vehicle/event/edit/' + pageData._id"
                  :disabled="!isPermissionCanEdit"
                >
                  <v-icon> mdi-pencil</v-icon>แก้ไขข้อมูล
                </v-btn>

                <v-menu open-on-hover offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      :class="vehicleEventProcessStatusColor(pageData.status)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ vehicleEventProcessStatus(pageData.status) }}
                      <v-icon> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in statusItem"
                      :key="index"
                      :disabled="
                        item.value == pageData.status ||
                        canEditPermissionStatus[item.value] === false
                      "
                      @click="statusActionClick(item)"
                    >
                      <v-list-item-title>{{ item.title }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div>
                      <span class="text-app-detail-normal">
                        {{ pageData.detail || "ไม่มีรายละเอียด" }}
                      </span>

                      <span class="text-app-detail-normal error--text">
                        {{ pageData.cancelCommentStr }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <!-- -->

                <v-row>
                  <!-- <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-title font-weight-bold mb-0">
                        ค่าใช้จ่ายทั้งหมด
                      </p>
                      <span class="text-body-1 font-weight-bold">
                        {{ pageData.totalAmountStr }}
                      </span>
                    </div>
                  </v-col> -->
                  <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-detail-normal ma-0">จำนวนบันทึก</p>
                      <span class="text-body-1 font-weight-bold black--text">
                        {{ pageData.noteList.length || 0 }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-detail-normal ma-0">ใช้เวลาทั้งหมด</p>
                      <span class="text-body-1 font-weight-bold black--text">
                        {{ pageData.totalWorkingDayStr }}</span
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-detail-normal ma-0">สร้างเมื่อ</p>
                      <span class="text-body-1 font-weight-bold black--text">
                        {{
                          dateStringToDateFormat(pageData.createdAt, {
                            returnThai: false,
                            type: "datetime",
                          }) || "-"
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-detail-normal ma-0">แก้ไขล่าสุด</p>
                      <span class="text-body-1 font-weight-bold black--text">
                        {{
                          dateStringToDateFormat(pageData.updatedAt, {
                            returnThai: false,
                            type: "date",
                          }) || "-"
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4">
                    <div>
                      <p class="text-app-detail-normal ma-0">
                        วันนัดหมายครั้งถัดไป
                      </p>
                      <span class="text-body-1 font-weight-bold black--text">
                        {{
                          dateStringToDateFormat(pageData.appointmentDate, {
                            returnThai: false,
                            type: "date",
                          }) || "-"
                        }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>

        <v-col lg="5" sm="12" cols="12">
          <div class="d-flex flex-column justify-start fill-height">
            <v-card outlined class="mb-2 rounded-lg">
              <v-card-title class="">รายละเอียดรถ</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col>
                    <div class="mb-2">
                      <p class="text-app-detail-normal ma-0">ป้ายทะเบียน</p>
                      <router-link
                        :to="'/vehicle/edit/' + pageData['vehicleId']"
                        link
                        class="d-block text-decoration-none text-body-1 font-weight-bold text-decoration-underline"
                        >{{ pageData.licenseNumber || "-" }}
                        <!-- ประเภทป้าย -->
                      </router-link>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="mb-2">
                      <p class="text-app-detail-normal ma-0">เลขตัวถังรถ</p>
                      <span class="text-body-1 font-weight-bold black--text"
                        >{{ pageData.cassieId || "-" }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <div class="mb-2">
                      <p class="text-app-detail-normal ma-0">ประเภทรถ</p>
                      <span class="text-body-1 font-weight-bold black--text"
                        >{{ pageData.veData.type || "-" }}
                      </span>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="mb-2">
                      <p class="text-app-detail-normal ma-0">จำนวนที่นั่ง</p>
                      <span class="text-body-1 font-weight-bold black--text"
                        >{{ pageData.veData.seat || "-" }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="fill-height rounded-lg" outlined>
              <v-card-title class="">ผู้ที่เกี่ยวข้อง</v-card-title>
              <div v-if="Object.keys(pageData.assigneeList).length !== 0">
                <v-list-item
                  v-for="item in pageData.assigneeList"
                  :key="'resp_' + item._id"
                  two-line
                  dense
                >
                  <v-list-item-avatar>
                    <v-img :src="item.profileImage"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-app-title">
                      <router-link
                        :to="'/employee/edit/' + item['_id']"
                        link
                        class="d-block text-decoration-none text-app-detail-normal"
                      >
                        {{ item.dpNameTh }} ({{ item.dpNickNameTh }})
                      </router-link>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-app-text">
                      เบอร์โทรศัพท์: {{ item.dpPhoneNumeber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-else>
                <v-list dense class="text-center">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-app-title"
                        >ไม่มีข้อมูล
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card class="rounded-lg" outlined>
            <v-toolbar flat>
              <v-toolbar-title> สรุปผล </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                elevation="0"
                :disabled="!isPermissionCanEdit"
                @click="addReportAction()"
              >
                เพิ่มรายการ
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="tableHeaders"
              :items="pageData.reportList"
              :loading="tableLoading"
              :no-data-text="dataTableNoDataText"
              :loading-text="dataTableLoadingText"
              :hide-default-footer="true"
              class="app-datatable"
            >
              <template v-slot:[`item.garages`]="{ item }">
                {{ item.garages ? item.garages.text : "-" }}
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                <!-- {{ item.startDateStr ? item.startDateStr : "-" }} -->
                {{
                  dateStringToDateFormat(item.startDateStr, {
                    type: "datetime",
                    returnThai: false,
                  }) || "-"
                }}
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                <!-- {{ item.endDateStr ? item.endDateStr : "-" }} -->
                {{
                  dateStringToDateFormat(item.endDateStr, {
                    type: "datetime",
                    returnThai: false,
                  }) || "-"
                }}
              </template>
              <template v-slot:[`item.partAmount`]="{ item }">
                {{ item.partAmount ? item.partAmount : "-" }}
              </template>
              <template v-slot:[`item.billTotalAmount`]="{ item }">
                {{ item.billTotalAmount ? item.billTotalAmount : "-" }}
              </template>
              <template v-slot:[`item.tools`]="{ item }">
                <!-- <DetailButton
                      text
                      class="mt-0"
                      color="deep-orange"
                      @on-click="handleDeleteItem(item)"
                      >ลบ</DetailButton
                    > -->
                <div class="text-right">
                  <v-btn
                    @click="handleEventDeleteClick(item._id)"
                    class="mx-2"
                    icon
                    color="primary"
                    :disabled="!isPermissionCanEdit"
                  >
                    <v-icon> mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    @click="editReportAction(item)"
                    class="mx-2"
                    icon
                    color="primary"
                    :disabled="!isPermissionCanEdit"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-card-actions
              ><v-row class="px-2">
                <v-col class="success--text text--darken-1">
                  <span>จำนวนชิ้นรวม: </span>
                  <span class="font-weight-bold">{{ billsTotalNum }}</span>
                  <v-divider vertical class="mx-6"></v-divider>
                  <span>ราคารวม: </span>
                  <span class="font-weight-bold">{{ billsTotalPrice }}</span>
                </v-col>
              </v-row></v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col lg="12" sm="12" cols="12">
          <v-card class="rounded-lg" outlined>
            <v-toolbar flat>
              <v-toolbar-title> บันทึก </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  lg="7"
                  md="7"
                  :order="this.$vuetify.breakpoint.mdAndUp ? 1 : 2"
                >
                  <v-timeline dense v-if="pageData.noteList.length > 0">
                    <v-timeline-item
                      v-for="note in pageData.noteList"
                      :key="note._id"
                    >
                      <template v-slot:icon>
                        <v-avatar>
                          <img :src="note.userId.profileImage" />
                        </v-avatar>
                      </template>
                      <v-card class="rounded-lg" outlined>
                        <v-card-title
                          class="text-app-detail-title text-permanent pb-0"
                        >
                          {{ note.title }}
                          <v-spacer></v-spacer>
                          <span class="text-app-detail-normal"> </span>
                        </v-card-title>

                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12">
                              <div class="mb-2">
                                <span class="text-app-detail-normal">
                                  {{ note.detail }}
                                </span>
                              </div>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="4">
                              <div class="mb-2">
                                <p class="text-app-title font-weight-bold ma-0">
                                  สร้างเมื่อ
                                </p>
                                <span
                                  class="text-app-detail-normal font-weight-bold"
                                >
                                  {{
                                    dateStringToDateFormat(note.createdAt, {
                                      returnThai: false,
                                      type: "datetime",
                                    }) || "-"
                                  }}
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="4">
                              <div class="mb-2">
                                <p class="text-app-title font-weight-bold ma-0">
                                  สร้างโดย
                                </p>
                                <span class="text-app-detail-normal">
                                  {{ note.userId ? note.userId.dpNameTh : "-" }}
                                </span>
                              </div>
                            </v-col>

                            <v-col cols="4" v-if="note.assigneeUserId">
                              <div class="mb-2">
                                <p class="text-app-title font-weight-bold ma-0">
                                  ผู้ที่เกี่ยวข้อง
                                </p>
                                <span class="text-app-detail-normal">
                                  {{
                                    note.assigneeUserId
                                      ? note.assigneeUserId.dpNameTh
                                      : "-"
                                  }}
                                </span>
                              </div>
                            </v-col>

                            <v-col> </v-col>
                          </v-row>
                          <!--  -->

                          <v-row v-if="note.images.length !== 0" v-viewer dense>
                            <v-col
                              v-for="(file, fileIdx) in note.images"
                              :key="file.title + '_' + fileIdx"
                              class="d-flex child-flex"
                              cols="2"
                            >
                              <div class="image-viewer-container">
                                <img width="100%" :src="file.src" />
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                  <v-alert
                    color="text-center text-app-normal"
                    v-if="pageData.noteList.length === 0"
                  >
                    ไม่มีข้อมูล
                  </v-alert>
                </v-col>
                <v-col
                  cols="12"
                  lg="5"
                  md="5"
                  :order="this.$vuetify.breakpoint.mdAndUp ? 2 : 1"
                >
                  <v-card class="rounded-lg" flat>
                    <v-card-title>
                      <span class="px-3"> เพิ่มบันทึก </span>
                    </v-card-title>
                    <v-card-text>
                      <v-form ref="form" v-model="valid">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <!-- <AppAutoCompleteUser
                              v-model="formData.assigneeUserId"
                              title="ผู้ที่เกี่ยวข้อง"
                              :isRequired="false"
                            /> -->
                            <InputView
                              class="px-3"
                              title="ผู้ที่เกี่ยวข้อง"
                              :isRequired="false"
                            >
                              <AppComboboxUserInputSearching
                                ref="AppAutoCompleteUserSearchingRef"
                                :disabled="!isPermissionCanEdit"
                                @searchItemChange="employeeInputSearchingAction"
                              />
                            </InputView>
                          </v-col>
                          <v-col cols="12">
                            <CustomTextInput
                              v-model="formData.title"
                              title="หัวข้อ"
                              placeholder=""
                              :isRequired="true"
                              :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                              :disabled="!isPermissionCanEdit"
                            />
                          </v-col>
                          <v-col cols="12">
                            <CustomTextareaImprove
                              v-model="formData.detail"
                              title="รายละเอียด"
                              :isRequired="true"
                              :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                              :disabled="!isPermissionCanEdit"
                            />
                          </v-col>
                          <v-col cols="12">
                            <InputView class="px-3" title="รูปภาพประกอบ">
                              <AppFilesUpload
                                icon=""
                                placeholder="คลิกเพิ่มรูปภาพ"
                                :multiple="true"
                                :smallChips="false"
                                :allowImageOnly="true"
                                :uploadWithScale="false"
                                v-model="upImages"
                                ref="mulImagesUpload"
                                uploadTag="VEHICLE_EVENT"
                                :disabled="!isPermissionCanEdit"
                              />
                            </InputView>
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="upImages.length !== 0"
                            class="pb-3"
                          >
                            <v-row class="px-3" v-viewer dense>
                              <v-col
                                v-for="(file, fileIdx) in upImages"
                                :key="file.title + '_' + fileIdx"
                                class="d-flex child-flex"
                                cols="3"
                              >
                                <v-card
                                  class="image-viewer-container px-2 pt-2 pb-0"
                                  outlined
                                >
                                  <img width="100%" :src="file.src" />
                                  <v-btn
                                    class="image_list_float_left"
                                    icon
                                    color="grey lighten-1"
                                    @click="remove_imagefile(fileIdx)"
                                    :disabled="!isPermissionCanEdit"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <div class="px-3">
                              <v-btn
                                class="success"
                                @click="submitForm()"
                                :disabled="!isPermissionCanEdit"
                              >
                                บันทึก
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <AppOverlay :isLoading="isLoading" />
    <VehicleEventReportDialog ref="eventReportDialog" />
    <VehicleEventStatusDialog ref="eventStatusDialog" />
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
    <AppDialogConfirm ref="confirmToRemoveReport" />
    <AppPageNotFound :show="isPermissionCanView" />
  </div>
</template>
<script>
import Vue from "vue";
import {
  getVehicleEventInfoService,
  postVehicleEventServiceNoteService,
  putVehicleEventServiceStatusService,
  putVehicleEventReportService,
  postVehicleEventReportService,
  getVehicleEventReportService,
  deleteVehicleEventReportService,
} from "@/services/api/vehicle_event";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import { mapGetters } from "vuex";
import { dateStringToDateFormat } from "@/services/appDate";
import AppOverlay from "@/components/AppOverlay.vue";
import CustomCardView from "@/components/CustomCardView.vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import AppAutoCompleteUser from "@/components/AppAutoCompleteUser.vue";
import InputView from "@/components/InputView.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import VehicleEventStatusDialog from "./vehicleEventStatusDialog.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import _ from "lodash";
import VehicleEventReportDialog from "./vehicleEventReportDialog.vue";
import NormalButton from "@/components/NormalButton.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import AppComboboxUserInputSearching from "@/components/AppComboboxUserInputSearching.vue";

Vue.use(VueViewer);
export default {
  name: "VehicleEventDetailView",
  components: {
    AppOverlay,
    CustomCardView,
    AppBreadCrumbs,
    AppFilesUpload,
    CustomTextareaImprove,
    AppAutoCompleteUser,
    InputView,
    CustomTextInput,
    VehicleEventStatusDialog,
    AppDialogConfirm,
    VehicleEventReportDialog,
    NormalButton,
    AppPageNotFound,
    AppComboboxUserInputSearching,
  },

  data: () => ({
    //
    breadcrumbsItems: [
      {
        text: "รายการซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ",
        disabled: false,
        to: "/vehicle/events",
      },
      {
        text: "ข้อมูลเหตุการณ์",
        disabled: true,
        to: "",
      },
    ],
    statusItem: [
      { title: "กำลังดำเนินการ", value: "P" },
      { title: "ตรวจสอบ", value: "I" },
      { title: "สำเร็จ", value: "S" },
      { title: "ยกเลิก", value: "C" },
    ],
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableLoading: false,
    tableHeaders: [
      {
        text: "ชื่อร้าน",
        align: "start",
        sortable: false,
        value: "garages",
      },
      {
        text: "เวลาเริ่ม",
        align: "start",
        sortable: false,
        value: "startDate",
      },
      {
        text: "เวลาสิ้นสุด",
        align: "start",
        sortable: false,
        value: "endDate",
      },
      {
        text: "จำนวนชิ้น",
        align: "start",
        sortable: false,
        value: "partAmount",
        width: "15%",
      },
      {
        text: "ราคารวม",
        align: "start",
        sortable: false,
        value: "billTotalAmount",
        width: "15%",
      },
      {
        text: "",
        value: "tools",
        sortable: false,
        align: "center",
        width: "15%",
      },
    ],
    billsTotalNum: 0,
    billsTotalPrice: 0,
    pageData: {
      type: null,
      status: null,
      billTotalAmount: 0,

      userId: {
        phoneNumber: null,
        empId: null,
        profileImage: null,
      },
      veData: {
        type: null,
      },
      assigneeList: {},
      noteList: [],
      reportList: [],
      totalWorkingDay: null,
      totalWorkingDayStr: null,
      cancelCommentStr: null,
      appointmentDate: null,
      createdAt: null,
      updatedAt: null,
    },
    isLoading: null,
    // form data
    formDisabled: false,
    valid: false,
    formData: {
      eventId: null,
      title: null,
      detail: null,
      assigneeUserId: null,
      images: [],
    },
    //
    upImages: [],

    // dialog
    alertOptions: {
      color: "grey lighten-3",
      width: 400,
      zIndex: 200,
      noconfirm: false,
    },
    //
    permList: {},
    isPermissionCanView: false,
    canEditPermissionStatus: {
      P: false,
      I: false,
      S: false,
      C: false,
    },

    isPermissionCanEdit: false,
  }),

  watch: {},
  computed: {
    ...mapGetters({
      vehicleEventTypeValue: "vehicle/vehicleEventTypeValue",
      vehicleEventTypeColor: "vehicle/vehicleEventTypeColor",
      vehicleEventProcessStatus: "vehicle/vehicleEventProcessStatus",
      vehicleEventProcessStatusColor: "vehicle/vehicleEventProcessStatusColor",
    }),
    sumAmountFromSummary() {
      let totalAmount = 0;
      if (this.pageData.reportList.length > 0) {
        for (let i = 0; i < this.pageData.reportList.length; i++) {
          totalAmount += !_.isNil(this.pageData.reportList[i]["totalAmount"])
            ? this.pageData.reportList[i]["totalAmount"]
            : 0;
        }
      }
      return totalAmount;
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    employeeInputSearchingAction(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          this.formData["assigneeUserId"] = _.cloneDeep(value);
        } else {
          this.formData["assigneeUserId"] = null;
        }
      }
    },
    startPage() {
      this.isLoading = true;
      if (this.$route.params.id !== undefined) {
        this._id = this.$route.params.id;
      } else {
        return;
      }
      //
      if (this.isPermissionCanView) {
        this.getData();
      } else {
        this.isLoading = false;
      }
    },
    async getData() {
      let params = new URLSearchParams({
        id: this._id,
      }).toString();
      const respData = await getVehicleEventInfoService(this.$store, params);

      if (respData["code"] === 200) {
        let preData = _.clone(respData["data"]);
        preData["totalAmountStr"] =
          preData["totalAmount"] !== null
            ? `${preData["totalAmount"].toLocaleString()} บาท`
            : "-";
        let _totalWorkingDay =
          preData["totalWorkingDay"] !== null
            ? `(${preData["totalWorkingDay"]} วัน)`
            : "";
        preData["totalWorkingDayStr"] =
          preData["totalWorkingDay"] !== null
            ? `${dateStringToDateFormat(preData["issueDate"], {
                returnThai: false,
                type: "date",
              })} ${_totalWorkingDay}`
            : "-";
        preData["cancelCommentStr"] =
          preData["cancelComment"] !== null
            ? `(เหตุผล: ${preData["cancelComment"]})`
            : "";

        let billsReport = this.processEventReportDateStr(
          _.clone(preData["reportList"])
        );

        preData["reportList"] = this.calulateBillTotalPrice(billsReport);

        // this.getEventReportList();

        this.pageData = preData;
      } else {
        this.isFail = true;
      }
      this.isLoading = false;
    },
    //
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    resetFormData() {
      this.formData = {
        eventId: this.eventId,
        title: null,
        detail: null,
        assigneeUserId: null,
        images: [],
      };
    },
    //
    async submitForm() {
      this.isLoading = true;
      if (this.$refs.form.validate() === true) {
        let upImageResult = [];
        let respImageIsError = false;
        let respErrMessageImages = [];
        if (this.upImages.length !== 0) {
          let resp_image = await this.$refs.mulImagesUpload.uploadAction();

          // for each image
          for (let i = 0; i < resp_image.length; i++) {
            if (resp_image[i]["error"] === 0) {
              upImageResult.push(resp_image[i]["data"]);
            } else {
              respImageIsError = true;
              respErrMessageImages.push(resp_image[i]["message"]);
            }
          }
        }

        if (respImageIsError === false) {
          // prepare data to server
          if (!_.isNil(this.formData["assigneeUserId"])) {
            this.formData["assigneeUserId"] =
              this.formData["assigneeUserId"]["_id"];
          }

          this.formData["images"] = upImageResult;
          this.formData["eventId"] = this._id;
          // sending to server
          const response = await postVehicleEventServiceNoteService(
            this.$store,
            this.formData
          );
          // ** check http responseonse
          if (response["code"] === 200) {
            this.resetFormData();
            this.$refs.form.reset();
            this.getData();
          } else {
            await this.$refs.dialogAlert.open(
              "เกิดข้อผิดพลาดในการเพิ่มข้อมูลรายงาน",
              null,
              DialogType.ERROR,
              {
                noconfirm: false,
              }
            );
            this.isLoading = false;
          }
          // ** end http responseonse
        } else {
          // else: update image error
          this.callUploadImageErrorDialog(respErrMessageImages);
          this.isLoading = false;
        }
        // clear upload files
        this.upImages = [];
      } else {
        // check form
        this.isLoading = false;
      }
    },
    async statusActionClick(statusItems) {
      const _item = {
        appointmentDate: !_.isNil(this.pageData["appointmentDate"])
          ? this.pageData["appointmentDate"].split("T")[0]
          : null,
        issueDate: !_.isNil(this.pageData["issueDate"])
          ? this.pageData["issueDate"].split("T")[0]
          : null,
        cancelComment: this.pageData["cancelComment"],
        totalAmount: this.pageData["totalAmount"] || this.sumAmountFromSummary,
        status: statusItems["value"],
      };

      const updateStatusData = await this.$refs.eventStatusDialog.open(_item);

      if (updateStatusData !== null) {
        this.isLoading = true;
        let params = new URLSearchParams({
          id: this._id,
        }).toString();
        //
        const respData = await putVehicleEventServiceStatusService(
          this.$store,
          params,
          updateStatusData
        );
        //
        if (respData["code"] === 200) {
          this.getData();
          await this.$refs.alertSaving.open(
            "เปลี่ยนสถานะสำเร็จ!",
            null,
            DialogType.POSITIVE,
            this.alertOptions
          );
        } else {
          await this.$refs.dialogAlert.open(
            "เกิดข้อผิดพลาดในการเปลี่ยนสถานะ",
            null,
            DialogType.ERROR,
            {
              noconfirm: false,
            }
          );
          this.isLoading = false;
        }
        //
      }
    },
    //
    //
    //
    processEventReportDateStr(reportListData) {
      let prefixKey = ["start", "end"];
      for (let i = 0; i < reportListData.length; i++) {
        for (let j = 0; j < prefixKey.length; j++) {
          if (!_.isNil(reportListData[i][`${prefixKey[j]}Date`])) {
            reportListData[i][`${prefixKey[j]}DateStr`] = `${
              reportListData[i][`${prefixKey[j]}Date`]
            }`;
            reportListData[i][`${prefixKey[j]}DateStr`].replace("T", " ");
          }
        }
      }
      return reportListData;
    },
    calulateBillTotalPrice(bills) {
      let totalPrice = 0;
      let totalNumber = 0;
      let billList = _.clone(bills);
      if (!_.isNil(billList) && billList.length > 0) {
        for (let idx in billList) {
          let sumPrice = 0;
          if (billList[idx]["vatCalculate"]) {
            sumPrice =
              billList[idx]["sumPriceBeforeVat"] *
              (1 + billList[idx]["vatPercent"] / 100);
          } else {
            sumPrice = billList[idx]["sumPriceBeforeVat"];
          }
          billList[idx]["billTotalAmount"] = sumPrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
          totalPrice += sumPrice;
          totalNumber += billList[idx]["partAmount"];
        }
      }
      this.billsTotalNum = totalNumber;
      this.billsTotalPrice = totalPrice
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return billList;
    },
    async getEventReportList() {
      let reportDataResp = await getVehicleEventReportService(
        this.$store,
        this._id
      );
      if (reportDataResp["code"] === 200) {
        //
        let billsReport = _.clone(reportDataResp["data"]);
        let billsReportTotal = this.calulateBillTotalPrice(billsReport);
        this.pageData.reportList =
          this.processEventReportDateStr(billsReportTotal);
      } else {
        // get report data resp
      }

      return;
    },
    async addReportAction() {
      const insertData = await this.$refs.eventReportDialog.open();
      this.isLoading = true;
      if (!_.isNil(insertData)) {
        let respData = await postVehicleEventReportService(
          this.$store,
          this._id,
          insertData
        );
        //
        if (respData["code"] === 200) {
          await this.getEventReportList();
        } else {
          // insert fail
        }
      }
      this.isLoading = false;
    },

    async editReportAction(bill) {
      const editData = await this.$refs.eventReportDialog.open(bill);
      this.isLoading = true;
      if (!_.isNil(editData)) {
        let respData = await putVehicleEventReportService(
          this.$store,
          bill._id,
          editData
        );
        //
        if (respData["code"] === 200) {
          await this.getEventReportList();
        } else {
          // insert fail
        }
      }
      this.isLoading = false;
    },
    //
    //
    //
    async handleEventDeleteClick(reportId) {
      if (
        await this.$refs.confirmToRemoveReport.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.isLoading = true;
        await deleteVehicleEventReportService(this.$store, reportId);
        await this.getEventReportList();
        this.isLoading = false;
      }
    },
    //
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_REPARATION");

        if (this.permList.vehireparation_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.vehireparation_info === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.vehireparation_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;

          if (this.permList.vehireparation_update_status) {
            this.canEditPermissionStatus["P"] = true;
            this.canEditPermissionStatus["I"] = true;
          }

          if (this.permList.vehireparation_close_status) {
            this.canEditPermissionStatus["S"] = true;
            this.canEditPermissionStatus["C"] = true;
          }

          if (this.permList.admin_all) {
            this.canEditPermissionStatus["P"] = true;
            this.canEditPermissionStatus["I"] = true;
            this.canEditPermissionStatus["S"] = true;
            this.canEditPermissionStatus["C"] = true;
          }
        }
      }
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.startPage();
  },
};
</script>

<style lang="scss" scoped>
.text-permanent {
  font-weight: 700;
}

.v-timeline:before {
  background: var(--v-secondary-base);
}
</style>
