<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    updateLocation: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    mapMarker: null,
  }),

  mounted() {
    const { Marker } = this.google.maps;
    let processLocation = null;
    if (this.marker.isRawLocation) {
      processLocation = new this.google.maps.LatLng(
        this.marker.location.geometry.location.lat,
        this.marker.location.geometry.location.lng
      );
    } else {
      processLocation = this.marker.location.geometry.location;
    }

    this.mapMarker = new Marker({
      map: this.map,
      placeId: this.marker.place_id,
      position: processLocation,
      animation: this.google.maps.Animation.DROP,
    });

    this.google.maps.event.addListener(this.mapMarker, "dragend", () => {
      this.$emit("updateLocation", this.mapMarker);
    });

    this.mapMarker.setDraggable(true);
  },

  render() {},
  beforeDestroy() {
    this.mapMarker.setMap(null);
  },
};
</script>
