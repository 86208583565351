<template>
  <CustomTextInputImprove
    ref="contractName"
    v-model="valueInput"
    :placeholder="placeholder"
    :title="title"
    :isRequired="true"
    :disabled="disabled"
    :rules="_rules"
    :loading="loading"
    @input="searching"
  ></CustomTextInputImprove>
  <!-- <v-combobox
      class="app-custom-input"
      v-model="valueInput"
      :items="itemList"
      :loading="loading"
      :search-input.sync="searching"
      :label="label"
      :placeholder="placeholder"
      return-object
      filled
      hide-selected
      hide-no-data
      hide-details
      outlined
      item-value="_id"
      item-text="contractNumber"
      dense
      append-icon=""
      @keyup="enterToReturnEmit"
      :disabled="disabled"
      :rules="newRules"
    >
    </v-combobox> -->
</template>

<script>
import { getContractBySeachingService } from "@/services/api/contract";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import _ from "lodash";

export default {
  name: "DynamicContractIdElement",
  components: { CustomTextInputImprove },

  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    previousId: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    itemList: [],
    loading: false,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryList: [],
    hideText: true,
    // rules: [(v) => !!v || "จำเป็นต้องระบุ รหัสสัญญา"],
    validId: true,
    resp_data: null,
  }),
  watch: {},

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        console.log("emit", val);
        this.$emit("input", val);
      },
    },
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ รหัสสัญญาใหม่");
          if (!_.isNil(this.resp_data)) {
            r.push(
              (v) =>
                (!!v && this.resp_data.data.countData == 0) ||
                "จำเป็นต้องระบุ รหัสสัญญาใหม่"
            );
          }
        }
        return r;
      },
      set(val) {},
    },
  },

  methods: {
    searching(val) {
      // this.validId = true;
      console.log("Searching ", val);
      if (!_.isNil(val) && _.isString(val)) {
        this.currentSearchText = val;
        if (val.length >= 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.callApi(val); // call api function
          // this.searchCountTimer = setTimeout(() => {
          //   this.callApi(val); // call api function
          // }, 1500);
        } else {
          this.hideText = true;
        }
      } else {
        this.hideText = true;
        // this.validId = false;
      }
    },
    async callApi(val) {
      //
      let output = "check";
      this.resp_data = await getContractBySeachingService(
        this.$store,
        val,
        output
      );

      if (this.resp_data.code === 200) {
        this.itemList = this.resp_data.data.dataList;
        if (this.resp_data.data.countData > 0) {
          this.validId = false;

          this._rules.push(
            (v) =>
              (!!v && this.resp_data.data.countData == 0) ||
              "จำเป็นต้องระบุ รหัสสัญญาใหม่"
          );
        } else {
          this.validId = true;
        }
        // if (val !== this.previousId) {
        //   this.rules = [
        //     (v) =>
        //       (!!v && resp_data.data.countData == 0) ||
        //       "จำเป็นต้องระบุ รหัสสัญญาใหม่",
        //   ];
        // } else {
        //   this.rules = [(v) => !!v || "จำเป็นต้องระบุ รหัสสัญญาใหม่"];
        // }

        this.hideText = false;
      } else {
        this.itemList = [];
        this.hideText = true;
        this.validId = true;
      }
      this.loading = false;
    },

    enterToReturnEmit(event) {
      if (event.code == "Enter") {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        this.itemList = [];
        return;
      } else if (event.code == "Backspace") {
        this.hideText = true;
      }
    },
  },

  mounted() {},
};
</script>

<style scope></style>
