<template>
  <div name="vehicleContractFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
      <div class="pa-6">
        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <InputView title="บริษัทคู่สัญญา" :isRequired="false">
                <v-select
                  class="app-custom-input"
                  :items="contractCompanyList"
                  v-model="formData.contractCompany"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="value"
                  outlined
                  dense
                  rounded
                  filled
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="ช่วงเวลาที่เริ่มสัญญา"
                v-model="formData.startDate"
                :isRequired="false"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="ช่วงเวลาที่จะสิ้นสุดสัญญา"
                v-model="formData.endDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { getContractCompanyElementService } from "@/services/api/contract";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "vehicleContractFilterGroup",
  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    CustomTextInput,
    CustomFilterDateTypingPickerRange,
    InputView,
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        contractcompany: "contractCompany",
        minstartdate: "startDate",
        maxstartdate: "startDate",
        minenddate: "endDate",
        maxenddate: "endDate",
      },

      formData: {
        contractCompany: null,
        startDate: [],
        endDate: [],
      },

      contractCompanyList: [],
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "minstartdate":
            case "maxstartdate":
              this.formData.startDate.push(this.$route.query[key]);
              break;
            case "minenddate":
            case "maxenddate":
              this.formData.endDate.push(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      let resp = await getContractCompanyElementService(this.$store);
      if (resp.code === 200) {
        this.contractCompanyList = resp.data;
      }
    },

    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "startDate":
              if (this.formData.startDate.length === 1) {
                filterParams["minstartdate"] = this.formData.startDate[0];
              } else if (this.formData.startDate.length === 2) {
                filterParams["minstartdate"] = this.formData.startDate[0];
                filterParams["maxstartdate"] = this.formData.startDate[1];
              }
              break;
            case "endDate":
              if (this.formData.endDate.length === 1) {
                filterParams["minenddate"] = this.formData.endDate[0];
              } else if (this.formData.endDate.length === 2) {
                filterParams["minenddate"] = this.formData.endDate[0];
                filterParams["maxenddate"] = this.formData.endDate[1];
              }
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      // console.log("filterParams", filterParams);
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },

    // async findAction() {
    //   let filterParams = {};

    //   if (this.contractCompany !== null) {
    //     filterParams["contractcompany"] = this.contractCompany;
    //   }

    //   if (this.startDate.length !== 0) {
    //     if (this.startDate.length === 2) {
    //       filterParams["minstartdate"] = this.startDate[0];
    //       filterParams["maxstartdate"] = this.startDate[1];
    //     } else {
    //       filterParams["minstartdate"] = this.startDate[0];
    //     }
    //   }

    //   if (this.endDate.length !== 0) {
    //     if (this.endDate.length === 2) {
    //       filterParams["minenddate"] = this.endDate[0];
    //       filterParams["maxenddate"] = this.endDate[1];
    //     } else {
    //       filterParams["minenddate"] = this.endDate[0];
    //     }
    //   }

    //   // return
    //   this.$emit("returnFiltedData", filterParams);
    //   this.$emit("close-menu");
    // },

    close() {
      this.$emit("close-menu");
    },
    clear() {
      this.contractCompany = null;
      this.formData = {
        contractCompany: null,
        startDate: [],
        endDate: [],
      };
    },
  },
  computed: {},
  mounted() {
    this.getData();
    this.getParamQuery();
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
