<template>
  <v-container name="RouteTemplateRecompenseTab">
    <v-card elevation="0">
      <v-card-text>
        <v-row no-gutters>
          <v-col auto>
            <p class="text-app-detail-large font-weight-bold">
              รายรับต่อเที่ยว <span class="required-color"> * </span>
            </p>
            <div v-if="!incomePerRoundAmountFormEditMode">
              <p class="text-h4 font-weight-bold mb-4">
                {{
                  formData["incomePerRound"] !== null
                    ? `${formData["incomePerRound"].toLocaleString()} บาท`
                    : "-"
                }}
                <v-btn
                  icon
                  class="mr-4"
                  @click="
                    incomePerRoundAmountFormEditMode =
                      !incomePerRoundAmountFormEditMode
                  "
                  v-if="isPermissionCanEdit"
                >
                  <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
              </p>
            </div>

            <div class="my-1" v-if="incomePerRoundAmountFormEditMode">
              <v-row dense>
                <v-col cols="12">
                  <CustomTextInputCurrency
                    ref="incomePerRound"
                    v-model="newIncomePerRoundAmount"
                    class="custom-fill-width mr-2"
                    title=""
                    placeholder="หน่วยบาท"
                    :hide-detail="true"
                    :small="true"
                    :input-view-bottom-margin="false"
                    :expend-margin="false"
                    :autofocus="true"
                    :disabled="!isPermissionCanEdit"
                  >
                  </CustomTextInputCurrency>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    class="mr-1"
                    color="success"
                    outlined
                    :disabled="!isPermissionCanEdit"
                    @click="saveRecompenseAmount()"
                  >
                    บันทึก
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    color="error"
                    outlined
                    :disabled="!isPermissionCanEdit"
                    @click="cancelRecompenseAmount()"
                  >
                    ยกเลิก
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col auto>
            <p class="text-app-detail-large font-weight-bold">
              ค่าตอบแทนให้ผู้ขับต่อเที่ยว
              <span class="required-color"> * </span>
            </p>
            <div v-if="!recompenseAmountFormEditMode">
              <p class="text-h4 font-weight-bold mb-4">
                {{
                  formData["recompenseAmount"] !== null
                    ? `${formData["recompenseAmount"].toLocaleString()} บาท`
                    : "-"
                }}
                <v-btn
                  icon
                  class="mr-4"
                  @click="
                    recompenseAmountFormEditMode = !recompenseAmountFormEditMode
                  "
                  v-if="isPermissionCanEdit"
                >
                  <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
              </p>
            </div>

            <div class="my-1" v-if="recompenseAmountFormEditMode">
              <v-row dense>
                <v-col cols="12">
                  <CustomTextInputCurrency
                    ref="recompenseAmount"
                    v-model="newRecompenseAmount"
                    class="custom-fill-width mr-2"
                    title=""
                    placeholder="หน่วยบาท"
                    :hide-detail="true"
                    :small="true"
                    :input-view-bottom-margin="false"
                    :expend-margin="false"
                    :autofocus="true"
                    :disabled="!isPermissionCanEdit"
                  >
                  </CustomTextInputCurrency>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    class="mr-1"
                    color="success"
                    outlined
                    :disabled="!isPermissionCanEdit"
                    @click="saveRecompenseAmount()"
                  >
                    บันทึก
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    color="error"
                    outlined
                    :disabled="!isPermissionCanEdit"
                    @click="cancelRecompenseAmount()"
                  >
                    ยกเลิก
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import { putRouteTemplateIncomeAndRecomponseService } from "@/services/api/contract_job_routes";

export default {
  name: "RouteTemplateIncomeRecompenseTab",
  props: {
    mainStore: {
      default: "routeSetting",
      type: String,
    },
  },
  data: () => ({
    isError: 0,
    recompenseAmountFormEditMode: false,
    incomePerRoundAmountFormEditMode: false,
    newRecompenseAmount: null,
    newIncomePerRoundAmount: null,
    formData: {
      incomePerRound: null,
      recompenseAmount: null,
      recompenseTotal: null,
    },
    isPermissionCanEdit: true,
  }),

  mounted() {
    let jobData = _.get(this.$store, "state.routeSetting.templateData", null);
    if (jobData !== null) {
      this.processData(jobData);
    }
  },

  watch: {
    "$store.state.routeSetting.templateData": function (newValue) {
      if (!_.isNil(newValue)) {
        this.processData(newValue);
      }
    },
  },

  methods: {
    processData(newData) {
      this.formData = {
        incomePerRound: _.get(newData, "incomePerRound", null),
        recompenseAmount: _.get(newData, "recompenseAmount", null),
      };
    },
    async saveRecompenseAmount() {
      if (
        !_.isNil(this.newRecompenseAmount) ||
        !_.isNil(this.newIncomePerRoundAmount)
      ) {
        this.$store.dispatch("routeSetting/updateMainLoading", true);
        const queryString = new URLSearchParams({
          id: this.$store.state.routeSetting["templateData"]["_id"],
        }).toString();
        //
        try {
          const responseData = await putRouteTemplateIncomeAndRecomponseService(
            this.$store,
            queryString,
            {
              incomePerRound:
                this.newIncomePerRoundAmount === null
                  ? this.formData["incomePerRound"]
                  : this.newIncomePerRoundAmount,
              recompenseAmount:
                this.newRecompenseAmount === null
                  ? this.formData["recompenseAmount"]
                  : this.newRecompenseAmount,
            }
          );

          if (responseData["code"] === 200) {
            const toUpdateData = {
              incomePerRound: !_.isNil(responseData["data"]["incomePerRound"])
                ? _.toNumber(responseData["data"]["incomePerRound"])
                : null,
              recompenseAmount: !_.isNil(
                responseData["data"]["recompenseAmount"]
              )
                ? _.toNumber(responseData["data"]["recompenseAmount"])
                : null,
            };
            //
            this.formData = {
              ...this.formData,
              ...toUpdateData,
            };
            // set to store
            this.$store.dispatch(
              `${this.mainStore}/updateRecompenseDate`,
              toUpdateData
            );
            //
            this.isError = 0;
          } else {
            this.isError = 1; // 2 : error from insert amount
          }
        } catch (error) {
          console.error(error);
          this.isError = 1; // 2 : error from insert amount
        }
        this.$store.dispatch(`${this.mainStore}/updateMainLoading`, false);
      }
      this.cancelRecompenseAmount();
    },
    cancelRecompenseAmount() {
      this.newRecompenseAmount = null;
      this.newIncomePerRoundAmount = null;
      this.recompenseAmountFormEditMode = false;
      this.incomePerRoundAmountFormEditMode = false;
    },
  },
  components: { CustomTextInputCurrency },
};
</script>

<style lang="scss" scoped></style>
