import LoginView from "../views/LoginView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import LogoutView from "@/views/LogoutView.vue";
import UserProfileViewVue from "@/views/users/UserProfileView.vue";
import ChangePasswordViewVue from "@/views/ChangePasswordView.vue";

export default [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/profile",
    name: "userProfile",
    component: UserProfileViewVue,
    meta: { requiresAuth: true, topic: "user" },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/change-password/:code",
    name: "changePasswordWithCode",
    component: ChangePasswordViewVue,
  },
  {
    path: "/change-password",
    name: "changePasswordNoCode",
    component: ChangePasswordViewVue,
  },
];
