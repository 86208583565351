<template>
	<div name="WorksheetFilterGroup">
		<v-card flat rounded="lg">
			<HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>

			<div class="pa-6">
				<!-- <v-row dense>
					<v-col cols="12">
						<div>
							<CustomFilterDateTypingPickerRange
								placeholder="เลือก"
								title="เลือกช่วงเวลา"
								v-model="formData.worksheetDate"
								:isRequired="false"
							/>
						</div>
					</v-col>
				</v-row>
				<v-row dense> </v-row> -->
				<v-row dense>
					<v-col cols="12">
						<InputView title="ช่วงยอดสุทธิ" :isRequired="false">
							<v-row>
								<v-col class="px-0 pt-1">
									<CustomTextInputImprove
										v-model="formData.minNetAmount"
										ref="minNetAmount"
										placeholder=""
										inputType="numberWithoutSign"
										dataInputName="searchingMaxAge"
									/>
								</v-col>
								<p class="title-filter pt-5">—</p>

								<v-col class="px-0 pt-1">
									<CustomTextInputImprove
										v-model="formData.maxNetAmount"
										ref="maxNetAmount"
										placeholder=""
										inputType="numberWithoutSign"
										dataInputName="searchingMinAge"
									/>
								</v-col>
							</v-row>
						</InputView>
					</v-col>
				</v-row>
			</div>

			<v-divider class="mt-5 mb-2"></v-divider>
			<v-card-actions class="px-6 pb-3">
				<!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
				<NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
				<v-spacer></v-spacer>
				<PrimaryButton :height="35" @on-click="findAction()"
					>กรอง</PrimaryButton
				>
				<NormalButton :height="35" class="ml-5" @on-click="close()"
					>ยกเลิก</NormalButton
				>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import { mapGetters } from "vuex";
	import { getAppDataService } from "@/services/api/app";
	import { getContractTitleListService } from "@/services/api/contract";
	import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
	import NormalButton from "@/components/NormalButton.vue";
	import InputView from "@/components/InputView.vue";
	import _ from "lodash";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";

	export default {
		name: "WorksheetFilterGroup",

		components: {
			PrimaryButton,
			HeaderCardNormal,
			NormalButton,
			InputView,
			CustomTextInputImprove,
			CustomFilterDateTypingPickerRange,
		},

		mounted() {
			// set from query
			this.getParamQuery();
		},

		data() {
			return {
				queryOrder: {
					minnetamount: "minNetAmount",
					maxnetamount: "maxNetAmount",
				},
				formData: {
					minNetAmount: null,
					maxNetAmount: null,
				},
				copyFormData: null,
				// from api
				isLoading: false,
			};
		},

		methods: {
			getParamQuery() {
				Object.keys(this.queryOrder).forEach((key) => {
					if (!_.isNil(this.$route.query[key])) {
						switch (key) {
							default:
								this.formData[this.queryOrder[key]] = this.$route.query[key];
						}
					}
				});
			},
			async findAction() {
				this.copyFormData = _.cloneDeep(this.formData);
				let filterParams = {};
				// process by check formdata
				Object.keys(this.queryOrder).forEach((key) => {
					if (!_.isNil(this.formData[this.queryOrder[key]])) {
						switch (this.queryOrder[key]) {
							default:
								filterParams[key] = this.formData[this.queryOrder[key]];
						}
					}
				});
				// console.log("filterParams", filterParams);
				this.$emit("returnFiltedData", filterParams);
				this.$emit("close-menu");
			},
			close() {
				if (this.copyFormData !== null) {
					this.formData = this.copyFormData;
					this.copyFormData = null;
				}
				this.$emit("close-menu");
			},
			clear() {
				// clear all
				this.copyFormData = _.cloneDeep(this.formData);

				this.formData = {
					minNetAmount: null,
					maxNetAmount: null,
				};
			},
		},
		computed: {},
	};
</script>
<style scoped>
	.title-filter {
		font-size: 14px;
		padding-bottom: 6px;
		color: rgb(137, 137, 137);
	}

	.v-input__control {
		height: 40px !important;
	}
</style>
