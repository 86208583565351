<template>
  <v-container name="RouteTemplateSpecialDriverTab">
    <v-row no-gutters class="pr-3">
      <v-col cols="10">
        <v-form ref="RouteTemplateSpecialDriverForm">
          <v-row no-gutters>
            <v-col cols="12" lg="8" sm="8">
              <CustomMultiLineAutocomplete
                v-model="formData.driverId"
                title="ชื่อ-นามสกุล"
                :isRequired="true"
                :items="empList"
                menu-props="auto"
                selectionTextIf="value"
                itemTextListTextIf="value"
                itemText="value"
                itemValue="key"
                :placeholder="`ค้นหาพนักงาน`"
                :small="true"
                :input-view-bottom-margin="false"
                :returnObject="false"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
              >
              </CustomMultiLineAutocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <CustomTextInputCurrency
                title="ค่าตอบแทน (฿)"
                ref="specialPayment"
                v-model="formData.recompenseAmount"
                placeholder="จำนวนเงินหน่วยบาท"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :small="true"
                :input-view-bottom-margin="false"
                dataInputName="specialPayment"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
      <v-col cols="2" class="align-self-center pt-0 pl-3">
        <PrimaryButton
          small
          :height="39"
          :isFullWidth="true"
          icon="mdi-plus"
          @on-click="handleAddClick()"
        >
          เพิ่ม
        </PrimaryButton>
      </v-col>
    </v-row>

    <div class="px-3 pb-3">
      <!-- <div v-if="tableData !== null && tableData.length == 0" class="pt-3">
        <v-divider></v-divider>
        <v-col align="center">{{ dataTableNoDataText }}</v-col>
      </div> -->
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :loading="tableLoading"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        :hide-default-footer="true"
        class="app-datatable"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-list-item v-if="item.dpNameTh !== null" class="px-0">
            <v-list-item-content>
              <v-list-item-title class="text-app-title">
                <span>
                  {{ item.dpNickNameTh }} {{ item.dpNameTh }} ({{ item.empId }})
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          <v-list-item two-line v-if="item.contact !== null" class="px-0">
            <v-list-item-content>
              <span> อีเมล {{ item.dpPhoneNumeber }} </span>
              <span> เบอร์โทร {{ item.dpPhoneNumeber }} </span>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.workingPeriod`]="{ item }">
          <v-list-item
            v-if="
              item.workingPeriod !== null && item.workingPeriod !== undefined
            "
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title class="text-app-title">
                <span> {{ item.workingPeriod }} </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.compensation`]="{ item }">
          <v-list-item v-if="item.compensation !== null" class="px-0">
            <v-list-item-content>
              <v-list-item-title class="text-app-title">
                <span>
                  {{ item.recompenseAmount }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }" v-if="isPermissionCanEdit">
          <v-btn
            plain
            class="text-decoration-underline error--text diplay--inline-block"
            @click="handleDeleteItem(item._id)"
          >
            ลบ
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <AppDialogConfirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import CustomMultiLineAutocomplete from "@/components/CustomMultiLineAutocomplete.vue";
import { getEmployeeSelectDetailList } from "@/services/api/employee";
import { getContractSpecialDriverList } from "@/services/api/contract";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { processPermission } from "@/services/permissions";
import {
  putSpecialDriverTemplate,
  deleteSpecialDriverTemplate,
} from "@/services/api/contract_job_routes";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import _ from "lodash";

export default {
  name: "RouteTemplateSpecialDriverTab",
  data: () => ({
    empList: null,
    templateId: null,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableLoading: false,
    tableData: [],
    // respTableData: [],
    formData: {
      driverId: null,
      recompenseAmount: null,
    },
    isPermissionCanEdit: false,
  }),
  computed: {
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อ-นามสกุล",
          align: "start",
          sortable: false,
          value: "name",
          width: "30%",
        },
        {
          text: "ข้อมูลการติดต่อ",
          align: "start",
          sortable: false,
          value: "contact",
          width: "25%",
        },
        {
          text: "อายุงาน",
          align: "start",
          sortable: false,
          value: "workingPeriod",
          width: "20%",
        },
        {
          text: "ค่าตอบแทน",
          align: "start",
          sortable: false,
          value: "compensation",
        },
      ];
      if (this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        });
      }
      return defaultdata;
    },
  },
  methods: {
    async getData() {
      this.tableLoading = true;
      // this.templateId = "63fc13de5e4115bfaf98596b";
      if (!_.isNil(this.templateId)) {
        let responseData = await getContractSpecialDriverList(
          this.$store,
          this.templateId
        );
        if (responseData.code == 200) {
          this.tableData = responseData.data;
        } else {
          this.tableData = [];
        }
      }
      // load employee list
      let respEmpList = await getEmployeeSelectDetailList(this.$store);
      if (respEmpList["code"] === 200) {
        this.empList = respEmpList["data"];
      } else {
        this.isFail = true;
      }

      this.tableLoading = false;
    },
    async handleAddClick() {
      // this.$store.dispatch("routeSetting/updateMainLoading", true);
      if (this.$refs["RouteTemplateSpecialDriverForm"].validate() === true) {
        const requestBodyData = _.clone(this.formData);
        const responseData = await putSpecialDriverTemplate(
          this.$store,
          `id=${this.templateId}`,
          requestBodyData
        );

        if (responseData["code"] !== 200) {
          console.error("SAVE_FAIL");
        }
        this.resetFormData();
        this.getData();

        // this.$store.dispatch("routeSetting/updateMainLoading", false);
      } else {
        console.error("FORM_INVALIDATE");
      }
    },
    async handleDeleteItem(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        let queryString = `id=${this.templateId}&driverId=${_id}`;
        await deleteSpecialDriverTemplate(this.$store, queryString);
        this.resetFormData();
        this.getData();
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.user_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.user_info === true) {
          this.isPermissionCanView = true;
          // this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.user_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },
    resetFormData() {
      this.formData = {
        driverId: null,
        recompenseAmount: null,
      };
      this.$refs["RouteTemplateSpecialDriverForm"].reset();
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.resetFormData();
    if (!_.isNil(this.$route.params["id"])) {
      this.templateId = this.$route.params["id"];
      this.getData();
    }
    // this.getData();
  },
  components: {
    CustomMultiLineAutocomplete,
    CustomTextInputCurrency,
    PrimaryButton,
    AppDialogConfirm,
  },
};
</script>

<style lang="scss" scoped></style>
