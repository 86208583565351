import {
  getUploadAuthConfig,
  handlerHttpClientError,
} from "@/services/api/my_axios";

import axios from "axios";

const baseURL = process.env.VUE_APP_FACE_API_URL;

export async function regUserFace(store, file, userId) {
  const uri = baseURL + "/regfacev2?admin=1";
  const showErrorDialog = true;
  const config = await getUploadAuthConfig(store);

  let formData = new FormData();
  formData.append("fileupload", file);
  formData.append("userId", userId);

  try {
    let response = await axios.put(uri, formData, {
      ...config,
    });
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}
