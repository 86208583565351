<template>
  <div class="d-flex flex-column align-center">
    <v-btn-toggle
      v-model="selectedStatus"
      dense
      color="primary"
      class="pb-0"
      :disabled="disabledAll"
    >
      <v-btn outlined small v-for="(status, idx) in statusItems" :key="idx">
        {{ status }}
      </v-btn>
    </v-btn-toggle>
    <div>
      <v-menu v-model="isOpenMenu" left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="
              selectedStatus === null ||
              selectedStatus === undefined ||
              disabledAll
            "
            :loading="loading || mainLoading"
            rounded
            small
            class="mt-2"
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openMenu"
          >
            ส่ง
          </v-btn>
        </template>
        <EvidenceWorksheetPreviewDialog
          :selectedItem="item"
          :items="paymentItems"
          :key="`ewpd-${item._id}`"
          @on-select="setPayment"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import {
  getJobEvidenceWorksheetListService,
  putJobEvidenceWorksheetToPaymentService,
} from "@/services/api/job_evidence_payment";
import EvidenceWorksheetPreviewDialog from "./EvidenceWorksheetPreviewDialog.vue";
import _ from "lodash";
export default {
  name: "EvidencePaymentSelectedStatus",
  props: {
    item: {},
    disabledAll: { type: Boolean, default: false },
    mainLoading: { type: Boolean, default: false },
  },
  components: {
    EvidenceWorksheetPreviewDialog,
  },
  data: () => ({
    selectedStatus: null,
    statusItems: ["จ่ายแล้ว", "ก่อนหัก", "หลังหัก"],
    statusItemsValue: ["PAID", "BEFORE_PAID", "AFTER_PAID"],
    isOpenMenu: false,
    loading: false,
    paymentItems: [],
  }),

  mounted() {
    if (!_.isNil(this.item.status)) {
      this.selectedStatus = this.statusItemsValue.indexOf(this.item.status);
    }
  },
  methods: {
    async openMenu() {
      this.paymentItems = [];
      const params = new URLSearchParams({ id: this.item._id }).toString();
      try {
        this.loading = true;
        const rpData = await getJobEvidenceWorksheetListService(
          this.$store,
          params
        );
        if (rpData["code"] === 200) {
          this.paymentItems = rpData["data"];

          this.loading = false;
          this.isOpenMenu = true;
        } else {
          throw "FATCH_ERROR";
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async setPayment(selectPayment) {
      this.isOpenMenu = false;
      this.$emit("set-main-loading", true);
      try {
        //
        const uriParams = new URLSearchParams({ id: this.item._id }).toString();
        let payload = {
          status: this.statusItemsValue[this.selectedStatus],
          paymentId: selectPayment._id,
        };
        const rpData = await putJobEvidenceWorksheetToPaymentService(
          this.$store,
          uriParams,
          payload
        );
        if (rpData["code"] !== 200) {
          throw "UPDATE_FAIL";
        } else {
          this.$emit("set-verify-at-index", {
            ...this.item,
            status: this.selectedStatus,
            verify: true,
          });
        }
      } catch (error) {
        console.error(error);
        this.selectedStatus = null;
      }

      this.$emit("set-main-loading", false);

      // setTimeout(() => {
      //   this.$emit("set-main-loading", false);
      // }, 2000);
    },
  },
};
</script>
