import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// font awesome icon add
import { library } from "@fortawesome/fontawesome-svg-core";
import VueCurrencyInput from "vue-currency-input";
import VueApexCharts from "vue-apexcharts";

import {
  faMapPin,
  faFlag,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
library.add(faMapPin);
library.add(faFlag);
library.add(faLocationPin);

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput);
Vue.use(VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
