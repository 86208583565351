<template>
  <v-container fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1" dense>
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการพฤติกรรมพนักงาน</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="6">
          <EmployeePenaltySearchInput
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>

            <PenaltyFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
        <v-col cols="auto" v-if="permList.user_info_crud || permList.admin_all">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="employeeDataList"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row pr-4" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>
            <div class="d-flex justify-end">
              <div class="mr-2">
                <CustomDatePickerInput
                  data-input-name="reportStartDate"
                  label="เริ่มต้น"
                  :clearable="false"
                  :value="startDate"
                  @change="startDateHandler"
                  :error-massage="dateErrorMsg"
                />
              </div>
              <div class="mr-2">
                <CustomDatePickerInput
                  data-input-name="reportStartDate"
                  label="สิ้นสุด"
                  :clearable="false"
                  :value="endDate"
                  @change="endDateHandler"
                />
              </div>
            </div>

            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:[`item.createdDate`]="{ item }">
          <div>
            {{
              dateStringToDateFormat(item.createdDate, {
                returnThai: false,
                type: "datetime",
              }) || "-"
            }}
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-avatar>
              <v-img
                :src="
                  item.profileImage === null
                    ? defaultPFImage
                    : item.profileImage
                "
              >
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-app-title font-weight-bold">
                <a
                  v-if="isPermissionCanEdit"
                  class="text-decoration-none"
                  @click="editPenaltyAction(item)"
                >
                  {{ item.dpfullName }}
                </a>
                <span v-else> {{ item.dpfullName }}</span>
              </v-list-item-title>
              <span class="text-app-normal">{{
                item.email === null ? "-" : `${item.email}`
              }}</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.empId`]="{ item }">
          <span class="text-app-title"> {{ item.empId }} </span>
        </template>

        <template v-slot:[`item.savedBy`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <div
                v-if="
                  item.updaterFirstName !== null &&
                  item.updaterFirstName !== undefined &&
                  item.updaterLastName !== null &&
                  item.updaterLastName !== undefined
                "
              >
                <v-list-item-title class="text-app-title font-weight-bold">
                  {{
                    `${
                      item.updaterSalutation === null ||
                      item.updaterSalutation === undefined
                        ? ""
                        : item.updaterSalutation.th
                    } ${
                      item.updaterFirstName === null ||
                      item.updaterFirstName === undefined
                        ? ""
                        : item.updaterFirstName.th
                    } ${
                      item.updaterLastName === null ||
                      item.updaterLastName === undefined
                        ? ""
                        : item.updaterLastName.th
                    } `
                  }}
                  {{
                    item.updaterNickName === null ||
                    item.updaterNickName === undefined
                      ? ""
                      : `(${item.updaterNickName.th})`
                  }}
                </v-list-item-title>
                <span class="text-app-normal">{{
                  item.updaterEmail === null || item.updaterEmail === undefined
                    ? "-"
                    : `${item.updaterEmail}`
                }}</span>
              </div>
              <div v-else>บันทึกโดยระบบ</div>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:[`item.penalityScore`]="{ item }">
          <div class="font-weight-bold error--text">
            {{ item.score || "-" }}
          </div>
        </template>
        <template v-slot:[`item.penaltyTopic`]="{ item }">
          <div v-if="item.topic !== null && item.topic !== undefined">
            <div>{{ item.topicEn }}</div>
            <div class="text-decoration-underline">{{ item.topicTh }}</div>
          </div>
          <span v-else> - </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div
            v-if="item.status !== null && item.status !== 'NEW'"
            :class="`${empPenaltyStatusTextColor(item.status)} text--darken-2`"
            dark
          >
            <span
              v-if="item.status !== 'NEW' && item.status !== null"
              class="text-app-title font-weight-bold"
            >
              {{ empPenaltyStatusText(item.status) }}
            </span>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }" v-if="isPermissionCanEdit">
          <!-- <v-btn @click="editTimeOffAction(item)" icon
            ><v-icon> mdi-pencil</v-icon>
          </v-btn> -->
          <v-btn @click="editPenaltyAction(item)" icon
            ><v-icon> mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <EmployeePenaltyDetailDialog ref="penaltyApprovalDialog" />
    <AppPageNotFound :show="isPermissionCanView" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";
import PenaltyFilterGroup from "@/views/employee/components/PenaltyFilterGroup.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  getEmployeePenaltyListService,
  postEmployeePenaltyService,
  putEmployeePenaltyService,
} from "@/services/api/employee";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import EmployeePenaltyDetailDialog from "../components/EmployeePenaltyDetailDialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import TimeOffFilterStatus from "@/views/employee/components/TimeOffFilterStatus.vue";
import { getPenaltyTitleSelectList } from "@/services/api/employee";
import EmployeePenaltySearchInput from "../components/EmployeePenaltySearchInput.vue";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import moment from "moment";
import CustomDatePickerInput from "@/components/CustomDatePickerInput.vue";
import { DialogType } from "@/services/dialog";

export default {
  name: "EmployeePenaltyListView",
  components: {
    AppDialogConfirm,
    PrimaryButton,
    NormalButton,
    SortButton,
    PenaltyFilterGroup,
    AppAutoCompleteUserSearching,
    AppPageNotFound,
    TimeOffFilterStatus,
    EmployeePenaltyDetailDialog,
    EmployeePenaltySearchInput,
    CustomFilterDateTypingPickerRange,
    CustomDatePickerInput,
  },
  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
      {
        text: "ชื่อ-นามสกุล เรียงจาก A-Z",
        value: "name-asc",
      },
      {
        text: "ชื่อ-นามสกุล เรียงจาก Z-A",
        value: "name-desc",
      },
      {
        text: "รหัสพนักงาน เรียงจาก A-Z",
        value: "empid-asc",
      },
      {
        text: "รหัสพนักงาน เรียงจาก Z-A",
        value: "empid-desc",
      },
    ],
    defaultPFImage: defaultImage,
    tableLoading: false,

    startDate: null,
    endDate: null,

    serverItemsLength: 0,
    employeeDataList: [],
    titleList: [],
    queryInit: {},
    queryFilterList: {},
    queryOrder: {
      topic: "topic",
    },
    queryStringSearch: "", // combin filter and search text
    querySearchAndFilterList: {}, // combine filter and search
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    sortVal: null,
    isShowMenu: false,
    //
    autoCompleteLoading: false,
    autoCompleteList: [],
    searchEmployeeList: null,
    searchCountTimer: undefined,
    dateErrorMsg: "",

    //
    permList: {
      admin_all: false,
      user_penality: false,
    },
    editTextBtn: "แก้ไขข้อมูล",
    rejectTextBtn: "ปฏิเสธ",
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    //
  }),

  mounted() {
    this.queryInit = this.$route.query;
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.getPenaltyTopicList();
      this.processRouterQuery(this.$route.query);
    }
  },

  watch: {},

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "startdate":
              case "enddate":
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
              case "text":
              case "cjvtext":
                this.querySearchAndFilterList[[key]] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // check validate date
      this.startDate = _.get(this.querySearchAndFilterList, "startdate", null);
      this.endDate = _.get(this.querySearchAndFilterList, "enddate", null);
      if (!this.checkDateRangeHandler()) {
        return;
      }
      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    async handleAddClick() {
      let fundObj = {
        id: null,
        titleList: this.titleList,
      };
      const newPenalty = await this.$refs.penaltyApprovalDialog.open(fundObj);
      if (!_.isNil(newPenalty)) {
        let respData = await postEmployeePenaltyService(
          this.$store,
          newPenalty
        );
        //
        if (respData["code"] === 200) {
          await this.getData();
        } else {
          // insert fail
        }
      }
    },
    handleEditClick(_id) {
      this.$router.push(`/employee/penality/edit/${_id}`);
    },

    async editPenaltyAction(employee) {
      let fundObj = {
        id: employee._id,
        titleList: null,
      };
      const editData = await this.$refs.penaltyApprovalDialog.open(fundObj);
      if (!_.isNil(editData)) {
        let respData = await putEmployeePenaltyService(this.$store, editData);
        //
        if (respData["code"] === 200) {
          await this.getData();
        } else {
          // insert fail
        }
      }
    },

    async rejectPenaltyAction(employee) {},

    // === end action button of table
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };

      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;

      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["c"] === "us") {
          delete this.querySearchAndFilterList["empId"]; // empId
          delete this.querySearchAndFilterList["text"]; // text
          if (_searchingParameter["key"]) {
            switch (_searchingParameter["c"]) {
              case "us":
                _queryParameter["empId"] = _searchingParameter["key"];
                break;
            }
          }

          //
          if (!_.isNil(_searchingParameter["value"])) {
            _queryParameter["text"] = _searchingParameter["value"];
          }
        } else {
          delete this.querySearchAndFilterList["job"]; // job
          delete this.querySearchAndFilterList["contract"]; // contract
          delete this.querySearchAndFilterList["vehicle"]; // job
          delete this.querySearchAndFilterList["cjvtext"]; // text
          if (_searchingParameter["_id"]) {
            switch (_searchingParameter["c"]) {
              case "ve":
                _queryParameter["vehicle"] = _searchingParameter["_id"];
                break;
              case "cot":
                _queryParameter["contract"] = _searchingParameter["_id"];
                break;
              case "job":
                _queryParameter["job"] = _searchingParameter["_id"];
                break;
            }
          }

          if (!_.isNil(_searchingParameter["value"])) {
            _queryParameter["cjvtext"] = _searchingParameter["value"];
          }
        }

        this.queryOptionsTable["page"] = 1;
      }
      //
      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    getfilterItem(_queryParameter) {
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    reset() {
      this.joinDate = [];

      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };

      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};

      this.processRouterQuery(this.queryInit);
      // // this.querySearchAndFilterList = { status: "all" };
      // this.processSearchingParams();
      // this.getData();
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.user_penality === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.user_penality === true) {
          this.isPermissionCanView = true;
          // this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.user_penality === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },
    async getPenaltyTopicList() {
      let respPenaltyTitleList = await getPenaltyTitleSelectList(this.$store);
      if (respPenaltyTitleList["code"] === 200) {
        this.titleList = respPenaltyTitleList["data"];
      }
    },
    async startDateHandler(value) {
      this.startDate = value;
      this.querySearchAndFilterList["startdate"] = value;
      const isOk = await this.checkDateRangeHandler();
      if (!isOk) {
        return;
      } else {
        this.getData();
      }
    },
    async endDateHandler(value) {
      this.endDate = value;
      this.querySearchAndFilterList["enddate"] = value;
      const isOk = await this.checkDateRangeHandler();
      if (!isOk) {
        return;
      } else {
        this.getData();
      }
    },
    async checkDateRangeHandler() {
      try {
        this.dateErrorMsg = "";
        let _startdate = moment(this.startDate);
        let _enddate = moment(this.endDate);
        if (!_startdate.isValid() || !_enddate.isValid()) {
          throw "E1";
        }
        const diffDays = _enddate.diff(_startdate, "day");
        if (diffDays < 0 || diffDays > 2) {
          throw "E2";
        }

        return true;
      } catch (error) {
        console.error(error);

        if (error === "E1") {
          this.dateErrorMsg = "รูปแบบวันเริ่มต้นหรือวันสิ้นสุดไม่ถูกต้อง";
        } else if (error === "E2") {
          this.dateErrorMsg = "ระยะเวลาของข้อมูลต้องห่างกันไม่เกิน 7 วัน";
        } else {
          this.dateErrorMsg = "ไม่สามารถทำงานได้";
        }
        // await this.$refs.dialogAlert.open(
        //   "ไม่สามารถดึงข้อมูลได้",
        //   error,
        //   DialogType.ERROR,
        //   {
        //     width: 400,
        //     zIndex: 200,
        //     noconfirm: false,
        //   }
        // );
        return false;
      }
    },

    async getData() {
      this.tableLoading = true;
      this.employeeDataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      const responseData = await getEmployeePenaltyListService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData["code"] == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let userList = responseData.data["paginatedResults"];
        if (userList.length > 0) {
          userList.forEach((item, idx) => {
            userList[idx][
              "name"
            ] = `${item["firstName"]["th"]} ${item["lastName"]["th"]}`;
            // process position
            if (userList[idx]["position"] === null) {
              userList[idx]["position"] = { nameEn: "-" };
            }
            userList[idx]["topicEn"] = item["topic"].split(" - ")[0].trim();
            userList[idx]["topicTh"] = item["topic"].split(" - ")[1].trim();

            userList[idx]["dpfullName"] = `${
              userList[idx].salutation === null
                ? ""
                : userList[idx].salutation.th
            }${
              userList[idx].firstName === null ? "" : userList[idx].firstName.th
            } ${
              userList[idx].lastName === null ? "" : userList[idx].lastName.th
            } ${
              item.nickName !== null
                ? item.nickName.th !== null
                  ? `(${item.nickName.th})`
                  : "-"
                : "-"
            }`;
          });
        }
        // console.log("employee list:", userList);
        this.employeeDataList = userList;
      } else {
        this.employeeDataList = [];
      }
      this.tableLoading = false;
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "employee/vehicleStatusColor",
      empStatusColor: "employee/empStatusColor",
      vehicleStatusText: "employee/vehicleStatusText",
      empStatusText: "employee/empStatusText",
      empPenaltyStatusColor: "employee/empPenaltyStatusColor",
      empPenaltyStatusTextColor: "employee/empPenaltyStatusTextColor",
      empPenaltyStatusText: "employee/empPenaltyStatusText",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "วันที่สร้าง",
          align: "start",
          sortable: false,
          value: "createdDate",
        },
        {
          text: "ชื่อ-นามสกุล ",
          align: "start",
          sortable: false,
          value: "name",
          width: "20%",
        },
        { text: "รหัสพนักงาน", value: "empId", sortable: false, align: "left" },

        {
          text: "ชื่อ-นามสกุลผู้บันทึก ",
          align: "start",
          sortable: false,
          value: "savedBy",
          width: "20%",
        },
        {
          text: "หัวข้อที่โดนหัก",
          align: "start",
          sortable: false,
          value: "penaltyTopic",
          // width: "20%",
        },
        {
          text: "คะแนนที่หัก",
          value: "penalityScore",
          sortable: false,
          align: "left",
        },
        {
          text: "สถานะ",
          align: "start",
          sortable: false,
          value: "status",
          // width: "20%",
        },
      ];
      //
      // if (this.isPermissionCanEdit) {
      //   defaultdata.push({
      //     text: "",
      //     value: "actions",
      //     sortable: false,
      //     align: "center",
      //   });
      // }
      //
      return defaultdata;
    },
  },
};
</script>
<style scoped></style>
