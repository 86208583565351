<template>
  <v-container name="RouteTemplateRecompenseTab">
    <v-card elevation="0" v-if="!isLoading">
      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <InputView :is-required="false" :bottom-margin="false">
              <v-autocomplete
                class="app-custom-input"
                v-model="searchValueInput"
                :items="searchItem"
                :loading="searchLoading"
                :disabled="searchLoading"
                :label="searchLoading ? 'กำลังโหลดเส้นทาง' : 'เลือกคู่เส้นทาง'"
                placeholder="ค้นหาโดยชื่อ"
                hide-details
                return-object
                filled
                outlined
                title="เส้นทางหลัก"
                :is-dark="true"
                item-value="_id"
                item-text="name"
                :clearable="true"
              >
              </v-autocomplete>
            </InputView>
          </v-col>
          <v-col cols="3">
            <PrimaryButton :height="55" @on-click="save()">
              บันทึก
            </PrimaryButton>
          </v-col>
        </v-row>

        <v-row> </v-row>
      </v-card-text>
    </v-card>
    <div v-else>
      <p class="text-app-detail-md font-weight-bold black--text">
        กำลังทำงาน....
      </p>
    </div>
  </v-container>
</template>

<script>
import InputView from "@/components/InputView.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { getRouteListByContractIdService } from "@/services/api/assign_multiple_job";
import { putRouteTemplatePairRoute } from "@/services/api/contract_job_routes";

export default {
  name: "RouteTemplatePairRouteTab",
  props: {
    mainStore: {
      default: "routeSetting",
      type: String,
    },
  },

  data: () => ({
    searchValueInput: null,
    searchItem: [],
    searchLoading: true,
    searchInput: false,
    isLoading: true,
    currentTemplateId: null,
  }),

  mounted() {
    const templateData = _.get(
      this.$store,
      `state.${this.mainStore}.templateData`,
      null
    );
    if (templateData !== null) {
      this.processData(templateData);
    }
  },

  watch: {},

  methods: {
    async processData(templateData) {
      try {
        this.currentTemplateId = templateData["_id"];
        const queryString = new URLSearchParams({
          id: templateData["contract"]["key"],
        }).toString();

        const responseData = await getRouteListByContractIdService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.searchItem = responseData["data"].filter(
            (item) => item["_id"] !== templateData["_id"]
          );
          this.searchLoading = false;
        } else {
          throw "GET ROUTE FAIL";
        }

        // if set pairRouteId
        if (!_.isNil(templateData["pairRouteId"])) {
          this.searchValueInput = responseData["data"].find(
            (item) => item["_id"] === templateData["pairRouteId"]
          );
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
    async save() {
      this.$store.dispatch("routeSetting/updateMainLoading", true);
      this.isLoading = true;
      try {
        //
        const queryString = new URLSearchParams({
          id: this.currentTemplateId,
        }).toString();

        const responseData = await putRouteTemplatePairRoute(
          this.$store,
          queryString,
          {
            pairRouteId: _.get(this.searchValueInput, "_id", null),
          }
        );

        if (responseData["code"] !== 200) {
          throw "GET ROUTE FAIL";
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
      this.$store.dispatch(`${this.mainStore}/updateMainLoading`, false);
    },
  },
  components: { InputView, PrimaryButton },
};
</script>

<style lang="scss" scoped></style>
