<template>
  <apexchart height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {
  calculateHours,
  processWeekYearStringList,
} from "@/services/appSummaryHelper";
import { processDistanceCeilHelper } from "@/services/appFuncHelpper";
export default {
  name: "VehicleEventExpensesLineChart",
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      series: [],
      avgHours: null,
      categories: [],
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },
        // colors: ["#49c496", "#ed5555"],
        colors: ["#567DF4"],
        annotations: {
          yaxis: [
            {
              y: this.avgHours,
              strokeDashArray: 5,
              borderWidth: 2,
              label: {
                text: `ชั่วโมงซ่อมโดยเฉลี่ย: ${this.avgHours} ชม.`,
              },
            },
          ],
        },

        grid: {
          show: false,
        },
        xaxis: {
          categories: this.categories,
        },

        yaxis: {
          title: {
            text: "จำนวนชั่วโมงการซ่อม (ชม.)",
            offsetX: 0,
          },
        },
      };
    },
  },
  methods: {
    processData() {
      if (!_.isNil(this.data)) {
        const _week = _.get(this.data, "weekSerials", []);
        this.avgHours = _.get(this.data, "events.vehicleEventAvgDuration", 0);

        let _tmp = [];
        for (let i = 0; i < _week.length; i++) {
          _tmp.push(calculateHours(_week[i]["duration"]));
        }

        this.categories = processWeekYearStringList(_week);
        this.series = [
          {
            name: "จำนวนชั่วโมงการซ่อม",
            data: _tmp,
          },
        ];
      }
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  mounted() {
    this.processData();
  },
};
</script>
