<template>
  <div>
    <h1>This is test upload page</h1>
    <!-- <input
      type="file"
      name="myfile"
      multiple
      accept="image/x-png, image/jpeg"
      @change="selectFile"
    />
    <v-btn @click="uploadAction"> Upload </v-btn> -->
    <v-img
      :src="selectedImage.fileKey"
      height="200"
      width="200"
      contain
      class="backgroud-image"
    ></v-img>

    <AppFilesUpload
      :multiple="false"
      :smallChips="true"
      :allowImageOnly="true"
      :uploadWithScale="true"
      v-model="selectedImage"
      ref="AppFillesUploadRefTest"
    />

    <v-btn @click="updateAction"> Upload </v-btn>
  </div>
</template>

<script>
// import { getUploadAuthConfig, uploadPath } from "@/services/api/my_axios";
// import axios from "axios";
import AppFilesUpload from "@/components/AppFilesUpload.vue";

export default {
  name: "App",
  data: () => ({
    selectedImage: { fileKey: "", name: "front" },
    // imgFile: [],
    // imgSrc: [],
  }),
  mounted() {},
  methods: {
    // async selectFile(event) {
    //   let totalFiles = event.target.files.length;
    //   for (var i = 0; i < totalFiles; i++) {
    //     let file = event.target.files[i];
    //     this.imgFile.push(file);
    //     this.imgSrc.push({
    //       name: file.name,
    //       src: await URL.createObjectURL(file),
    //       progressPercent: 0,
    //     });
    //   }
    // },
    // async uploadAction() {
    //   const config = await getUploadAuthConfig(this.$store);
    //   for (let i = 0; i < this.imgFile.length; i++) {
    //     const file = this.imgFile[i];
    //     let formData = new FormData();
    //     formData.append("fileupload", file);
    //     formData.append("title", file.name);
    //     const response = await axios.post(uploadPath, formData, {
    //       ...config,
    //       // onUploadProgress: this.updateView
    //       onUploadProgress: function (progressEvent) {
    //         let percent = parseInt(
    //           Math.round((progressEvent.loaded / progressEvent.total) * 100)
    //         );
    //         this.updateProgress(i, percent);
    //       },
    //     });
    //     console.log(response);
    //   }
    // },
    // updateView(progressEvent) {
    //   let percent = parseInt(
    //     Math.round((progressEvent.loaded / progressEvent.total) * 100)
    //   );
    //   this.updateProgress(i, percent);
    // },
    // updateProgress(index, percent) {
    //   this.imgSrc[index].progressPercent = percent;
    // },
    async updateAction() {
      console.log("in");
      await this.$refs.AppFillesUploadRefTest.uploadAction();
    },
  },
  watch: {
    selectedImage: function (newValue) {
      console.log(newValue);
    },
  },
  components: { AppFilesUpload },
};
</script>
