var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.jobData)?_c('v-app-bar',{attrs:{"dense":"","color":"white"}},[_c('div',[_c('p',{staticClass:"ma-0 text-detail-bar"},[_vm._v("รหัสงาน")]),_c('p',{staticClass:"ma-0 text-detail-bar font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.jobData.jobCode)+" ")])]),_c('v-divider',{staticClass:"mx-4 py-3",attrs:{"vertical":""}}),_c('div',{staticClass:"d-inline-flex"},[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":_vm.jobData
            ? _vm.defaultPFImage
            : _vm.jobData.userId.profileimage === null
            ? _vm.defaultPFImage
            : _vm.jobData.userId.profileimage}})],1),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"ma-0 text-detail-bar font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.jobData.userId.dpNameTh)+" ")]),_c('p',{staticClass:"ma-0 text-detail-bar"},[_vm._v(" เบอร์โทร: "+_vm._s(_vm.jobData.userId.dpPhoneNumeber)+" ")])])],1),_c('v-spacer'),(_vm.jobPublic)?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"green","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-earth ")]),_vm._v(" กำลังเผยแพร่ ")],1):_vm._e(),(!_vm.jobPublic)?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"red","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-octagon ")]),_vm._v(" ยังไม่ได้กำลังเผยแพร่ ")],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","left":"","disabled":!_vm.isPermissionCanEdit},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-detail-bar",attrs:{"color":_vm.jobsStatusColor(_vm.jobStatus),"dark":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.jobsStatusText(_vm.jobStatus))+" "),_c('v-icon',[_vm._v(" mdi-menu-down")])],1)]}}],null,false,2442305408)},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},_vm._l((_vm.jobStatusList),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":item.disabled === 1 ? true : false}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.jobsStatusText(item.key)))])],1)}),1)],1)],1),_c('AppDialogConfirm',{ref:"DetailBarAppDialongConfirm"})],1):_c('v-app-bar',{attrs:{"dense":"","color":"white"}},[_c('v-app-bar-title',[_vm._v(" เพิ่มข้อมูลงาน ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }