import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  httpClientPost,
  httpClientPut,
} from "@/services/api/my_axios";

import {
  setTokensAndInfo,
  removeTokensAndInfo,
} from "@/services/api/token_manager";

import Bowser from "bowser";

import { getUUID } from "@/services/api/uuid_manager";

export async function userLogin(store, email, password) {
  const deviceUUID = await getUUID();

  if (deviceUUID == null) {
    return { code: 400, message: "ERROR_GETTING_UUID" };
  }
  const browserDetail = Bowser.parse(window.navigator.userAgent);

  const data = {
    email: email,
    password: password,
    deviceId: deviceUUID,
    devicePlatform: "web",
    deviceOs: `${browserDetail.os.name} ${browserDetail.os.versionName}`,
  };

  const response = await httpClientPost(store, "/user/login", data);
  if (response.code == 200) setTokensAndInfo(store, response.data.data);

  return response;
}

export async function userLogout(store) {
  const response = await authHttpClientPost(store, "/user/logout", {}, false);
  removeTokensAndInfo(store);
  return response;
}

export async function forgotPassword(store, email) {
  const data = {
    email: email,
  };

  const response = await httpClientPost(store, "/user/forgotpassword", data);

  return response;
}

export async function getUserInfo(store) {
  const response = await authHttpClientGet(
    store,
    "/user/info?id=" +
      (store.state.userInfo ? store.state.userInfo["_id"] : null)
  );

  return response;
}

export async function getProfileImage(store) {
  const response = await authHttpClientGet(store, "/user/profile");

  return response;
}

export async function changePassword(
  store,
  chagePassToken,
  oldPssword,
  newPassword
) {
  const putData = {
    chagePassToken: chagePassToken,
    oldPssword: oldPssword,
    newPassword: newPassword,
  };
  let response = null;
  if (chagePassToken == null) {
    response = await authHttpClientPut(store, "/user/password", putData);
  } else {
    response = await httpClientPut(store, "/user/passwordwithtoken", putData);
  }

  return response;
}

// create and edit check duplicate email
export async function checkUserService(store, queryString) {
  let uri = "/user/check-user";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  const response = await authHttpClientGet(store, uri, false);
  return response;
}

// get reset password link
export async function getResetPasswordLinkService(store, payload) {
  let uri = "/user/passwordlink";

  return await authHttpClientPost(store, uri, payload);
}
