<template>
  <div name="saveEmployeeFormButton">
    <v-card flat class="rounded-b-lg" rounded="0">
      <v-row justify="end" class="mx-0">
        <v-col cols="auto">
          <NormalButton v-if="isEnableSkip" @on-click="clickSkip()"
            >ข้าม</NormalButton
          >
        </v-col>
        <v-col cols="auto">
          <PrimaryButton
            color="primary"
            @on-click="saveChange()"
            :disabled="primaryButtonDisabled"
          >
            {{ text }}
          </PrimaryButton>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";

export default {
  name: "saveEmployeeFormButton",

  components: {
    PrimaryButton,
    NormalButton,
  },

  props: {
    isEnableSkip: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "บันทึก",
    },
    primaryButtonDisabled: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    clickSkip() {
      this.$emit("on-click-skip");
    },

    saveChange() {
      this.$emit("on-click-save");
    },
  },
};
</script>
<style></style>
