<template>
  <div name="jobsFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
      <div class="pa-6">
        <v-row dense>
          <!-- <v-col cols="12" sm="4">
            <div>
              <InputView title="บริษัทคู่สัญญา" :isRequired="false">
                <v-select
                  :items="filterContractCompanyList"
                  v-model="formData.contractCompanyName"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="value"
                  outlined
                  dense
                  filled
                  :loading="componentLoading"
                ></v-select>
              </InputView>
            </div>
          </v-col> -->
          <v-col cols="12" sm="6">
            <div>
              <InputView title="รหัสสัญญา" :isRequired="false">
                <v-select
                  :items="filterContractTitleList"
                  v-model="formData.contractNumber"
                  placeholder="เลือก"
                  item-value="key"
                  item-text="value"
                  outlined
                  dense
                  filled
                  :loading="componentLoading"
                ></v-select>
              </InputView>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <InputView title="ช่วงจำนวน check point" :isRequired="false">
              <v-row>
                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    type="number"
                    v-model="formData.minCheckpoint"
                    placeholder="เริ่มต้น"
                    :isRequired="false"
                  ></CustomTextInputImprove>
                </v-col>
                <p class="title-filter pt-5">—</p>

                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    type="number"
                    v-model="formData.maxCheckpoint"
                    placeholder="สิ้นสุด"
                    :isRequired="false"
                  ></CustomTextInputImprove>
                </v-col>
              </v-row>
            </InputView>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลายืนยันรับงาน"
              v-model="formData.confirmDate"
              :isRequired="false"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลาเริ่มงาน"
              v-model="formData.startDate"
              :isRequired="false"
            />
          </v-col>
        </v-row>
        <!--  -->
        <v-row dense>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลาจบงาน"
              v-model="formData.endDate"
              :isRequired="false"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลายืนยันรับงานจริง"
              v-model="formData.actualConfirmDate"
              :isRequired="false"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลาเริ่มงานจริง"
              v-model="formData.actualStartDate"
              :isRequired="false"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <CustomFilterDateTypingPickerRange
              placeholder="เลือก"
              title="เวลาจบงานจริง"
              v-model="formData.actualEndDate"
              :isRequired="false"
            />
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import {
  getContractCompanyElementService,
  getContractTitleListService,
} from "@/services/api/contract";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import InputView from "@/components/InputView.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "jobsFilterGroup",
  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    CustomTextInput,
    CustomFilterDateTypingPickerRange,
    InputView,
    CustomTextInputImprove,
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        contractcompname: "contractCompanyName",
        contractnumber: "contractNumber",
        mincheckpoint: "minCheckpoint",
        maxcheckpoint: "maxCheckpoint",
        //
        minstartdate: "startDate",
        maxstartdate: "startDate",
        minenddate: "endDate",
        maxenddate: "endDate",
        minconfirmdate: "confirmDate",
        maxconfirmdate: "confirmDate",
        //
        minactualconfirmdate: "actualConfirmDate",
        maxactualconfirmdate: "actualConfirmDate",
        minactualstartdate: "actualStartDate",
        maxactualstartdate: "actualStartDate",
        minactualenddate: "actualEndDate",
        maxactualenddate: "actualEndDate",
      },
      queryOrderArray: {
        startDate: ["minstartdate", "maxstartdate"],
        endDate: ["minenddate", "maxenddate"],
        confirmDate: ["minconfirmdate", "maxconfirmdate"],
        actualConfirmDate: ["minactualconfirmdate", "maxactualconfirmdate"],
        actualStartDate: ["minactualstartdate", "maxactualstartdate"],
        actualEndDate: ["minactualenddate", "maxactualenddate"],
      },
      formData: {
        contractCompanyName: null,
        contractNumber: null,
        minCheckpoint: null,
        maxCheckpoint: null,
        startDate: [], // for date
        confirmDate: [],
        endDate: [],
        actualConfirmDate: [],
        actualStartDate: [],
        actualEndDate: [],
        //
      },
      copyFormData: null,

      filterContractCompanyList: [],
      filterContractTitleList: [],
      componentLoading: false,
    };
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "contractnumber":
              this.formData[this.queryOrder[key]] =
                appDecodeSingleURIComponentHelper(this.$route.query[key]);
              break;
            case "minstartdate":
            case "maxstartdate":
            case "minenddate":
            case "maxenddate":
            case "minconfirmdate":
            case "maxconfirmdate":
            case "minactualconfirmdate":
            case "maxactualconfirmdate":
            case "minactualstartdate":
            case "maxactualstartdate":
            case "minactualenddate":
            case "maxactualenddate":
              this.formData[this.queryOrder[key]].push(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async getData() {
      this.componentLoading = true;
      try {
        let responseData = await getContractCompanyElementService(this.$store);
        if (responseData["code"] === 200) {
          this.filterContractCompanyList = responseData["data"];
        }
      } catch (error) {
        //
      }

      try {
        let responseData = await getContractTitleListService(this.$store);
        if (responseData["code"] === 200) {
          this.filterContractTitleList = responseData["data"];
        }
      } catch (error) {
        //
      }
      this.componentLoading = false;
    },

    filParamsDate(atFormDataKey) {
      let outputObj = {};
      if (this.formData[atFormDataKey].length === 1) {
        outputObj[this.queryOrderArray[atFormDataKey][0]] =
          this.formData[atFormDataKey][0];
      } else if (this.formData[atFormDataKey].length === 2) {
        outputObj[this.queryOrderArray[atFormDataKey][0]] =
          this.formData[atFormDataKey][0];
        outputObj[this.queryOrderArray[atFormDataKey][1]] =
          this.formData[atFormDataKey][1];
      }
      return outputObj;
    },

    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};

      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          // querykey and formdata
          // minstartdate: "startDate",
          switch (this.queryOrder[key]) {
            case "minCheckpoint":
              filterParams["mincheckpoint"] = this.formData["minCheckpoint"];
              break;
            case "maxCheckpoint":
              filterParams["maxcheckpoint"] = this.formData["maxCheckpoint"];
              break;

            case "startDate":
            case "endDate":
            case "confirmDate":
            case "actualConfirmDate":
            case "actualStartDate":
            case "actualEndDate":
              filterParams = {
                ...filterParams,
                ...this.filParamsDate(this.queryOrder[key]),
              };
              break;
            default:
              filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      this.$emit("close-menu");
    },
    clear() {
      this.copyFormData = _.cloneDeep(this.formData);
      this.formData = {
        companyName: null,
        contractNumber: null,
        minCheckpoint: null,
        maxCheckpoint: null,
        confirmDate: [],
        startDate: [], // for date
        endDate: [],
        actualConfirmDate: [],
        actualStartDate: [],
        actualEndDate: [],
      };
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusText: "jobs/jobsStatusText",
    }),
  },
  mounted() {
    this.getData();
    this.getParamQuery();
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
