<template>
  <div name="worksheetOverviewDashboard">
    <v-container v-if="isPermissionCanView">
      <div class="d-flex justify-space-between pa-4">
        <div class="text-h5">รายงานภาพรวมการเงิน</div>

        <CustomMonthPickerBtn
          v-if="allMouth.length > 0"
          :value="allMouth"
          @updateValue="monthPickerValueHandler"
        />
      </div>

      <UserPaymentSummaryCard v-if="reportData" :data="reportData" />

      <v-card v-if="reportData" class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="pb-6">
            <v-card-title class="px-6">
              <div class="pt-3 text-title font-weight-bold black--text">
                สัดส่วนการจ่ายแต่ละแผนก
              </div>
            </v-card-title>

            <UserPaymentDepAndPosBarChart :data="reportData" />

            <v-divider class="my-3 ml-6 mr-6" />

            <UserPaymentMonthLineChart :data="reportData" />

            <v-divider class="my-3 ml-6 mr-6" />

            <UserPaymentCalendarChart
              :data="reportData"
              :start-date="uriQueryObj.startdate"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <AppOverlay :isLoading="isLoading" />
    <AppPageNotFound :show="isPermissionCanView" />
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import { mapGetters } from "vuex";
import { getWorksheetUserPaymentReport } from "@/services/api/worksheet_report";
import CustomMonthPickerBtn from "@/components/CustomMonthPickerBtn.vue";
import UserPaymentSummaryCard from "./chart-components/UserPaymentSummaryCard.vue";
import UserPaymentDepAndPosBarChart from "./chart-components/UserPaymentDepAndPosBarChart.vue";
import UserPaymentMonthLineChart from "./chart-components/UserPaymentMonthLineChart.vue";
import UserPaymentCalendarChart from "./chart-components/UserPaymentCalendarChart.vue";

export default {
  name: "WorksheetOverviewDashboard",
  components: {
    CustomCardFormViewModern,
    AppOverlay,
    AppDialogConfirm,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomMonthPickerBtn,
    UserPaymentSummaryCard,
    UserPaymentDepAndPosBarChart,
    UserPaymentMonthLineChart,
    UserPaymentCalendarChart,
  },
  data() {
    return {
      valid: false,
      isPermissionCanView: false,
      isLoading: false,

      uriQueryObj: {
        startdate: undefined,
        enddate: undefined,
      },
      allMouth: [],
      reportData: null,
      permList: {
        payment_view_total_report: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      jobsStatusObj: "jobs/jobsStatusObj",
    }),
  },
  watch: {},
  methods: {
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        const getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        if (
          this.permList.admin_all === true ||
          this.permList.payment_view_total_report === true
        ) {
          this.isPermissionCanView = true;
        }
      }
    },
    monthsBetweenDates(startDate, endDate) {
      const now = moment(startDate, "YYYY-MM");
      let dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format("YYYY-MM"));
        now.add(1, "months");
      }
      return dates;
    },
    setRouterQuery(queryObj) {
      // filter
      const fQueryObj = _.pickBy(queryObj, _.identity);
      this.$router
        .replace({
          path: this.$router.path,
          query: fQueryObj,
        })
        .catch(() => {});
    },

    startPage() {
      if (Object.keys(this.$route.query).length > 0) {
        Object.keys(this.$route.query).forEach((key) => {
          if (!_.isNil(this.$route.query[key])) {
            this.uriQueryObj[key] = this.$route.query[key];
          }
        });
      }
      this.validateQueryDate(undefined);
      this.getData();
    },
    validateQueryDate(fromComponentList) {
      if (_.isNil(this.uriQueryObj["startdate"])) {
        this.uriQueryObj["startdate"] = moment().format("YYYY-MM");
      }

      if (_.isNil(this.uriQueryObj["enddate"])) {
        this.uriQueryObj["enddate"] = moment().format("YYYY-MM");
      }

      if (fromComponentList === undefined) {
        let am = [this.uriQueryObj["startdate"]];
        if (!_.isNil(this.uriQueryObj["enddate"])) {
          am.push(this.uriQueryObj["enddate"]);
        }

        if (am.length > 1) {
          this.allMouth = this.monthsBetweenDates(
            this.uriQueryObj["startdate"],
            this.uriQueryObj["enddate"]
          );
        } else {
          this.allMouth = am;
        }
      } else {
        this.allMouth = fromComponentList;
      }

      this.setRouterQuery(this.uriQueryObj);
    },
    // form component
    monthPickerValueHandler(valueList) {
      if (valueList.length === 1) {
        this.uriQueryObj["startdate"] = valueList[0];
        this.uriQueryObj["enddate"] = valueList[0];
        this.setRouterQuery(this.uriQueryObj);
      } else if (valueList.length > 1) {
        this.uriQueryObj["startdate"] = valueList[0];
        this.uriQueryObj["enddate"] = valueList[valueList.length - 1];
        this.setRouterQuery(this.uriQueryObj);
      } else {
        validateQueryDate(valueList);
      }

      this.allMouth = valueList;
      this.getData();
    },

    async getData() {
      this.isLoading = true;
      try {
        this.reportData = null;
        const queryParams = new URLSearchParams(this.uriQueryObj);
        const responseData = await getWorksheetUserPaymentReport(
          this.$store,
          queryParams.toString()
        );
        if (responseData["code"] === 200) {
          this.reportData = responseData["data"];
        } else {
          throw "response failed";
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.startPage();
    }
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}
.image_list_float_left {
  float: right;
}
.dashboard-large {
  font-size: 80px !important;
}

.dashboard-medium {
  font-size: 60px !important;
}
.dashboard-small {
  font-size: 30px !important;
}
</style>
