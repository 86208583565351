<template>
  <div name="login">
    <v-container fluid pa-0>
      <v-row align-content="center" justify="center" dense style="height: 80vh">
        <v-col cols="12" align-content="center" justify="center">
          <v-card
            class="mx-auto pa-10"
            max-width="374"
            elevation="0"
            rounded="xl"
          >
            <div>
              <div class="text-login">เปลี่ยนรหัสผ่าน</div>
            </div>

            <v-form ref="formChangePassword" v-model="valid">
              <div v-if="changedPassword == false" class="mt-10">
                <v-text-field
                  class="custom-placeholer-color .v-label"
                  placeholder="รหัสผ่านปัจจุบัน"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="oldPssword"
                  @click:append="show1 = !show1"
                  outlined
                  rounded
                  dense
                  :disabled="loading"
                  v-on:keyup.enter="onEnter"
                  v-if="chagePassToken == null"
                ></v-text-field>

                <v-text-field
                  class="custom-placeholer-color .v-label"
                  placeholder="รหัสผ่านใหม่"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="newPassword"
                  @click:append="show2 = !show2"
                  outlined
                  rounded
                  dense
                  :disabled="loading"
                  v-on:keyup.enter="onEnter"
                  :rules="[
                    (v) => !!v || 'จำเป็นต้องระบุ',
                    (v) =>
                      (v && v.length >= 8) || 'ความยาวอย่างน้อย 8 ตัวอักษร',
                  ]"
                ></v-text-field>

                <v-text-field
                  class="custom-placeholer-color .v-label"
                  placeholder="ยืนยันรหัสผ่านใหม่"
                  :type="show3 ? 'text' : 'password'"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="confirmPassword"
                  @click:append="show3 = !show3"
                  outlined
                  rounded
                  dense
                  :disabled="loading"
                  v-on:keyup.enter="onEnter"
                  :rules="[
                    (v) => !!v || 'จำเป็นต้องระบุ',
                    (v) =>
                      (v && v.length >= 8) || 'ความยาวอย่างน้อย 8 ตัวอักษร',
                    (v) => v === newPassword || 'รหัสผ่านไม่ตรงกัน',
                  ]"
                ></v-text-field>

                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  large
                  :loading="loading"
                  :disabled="loading"
                  @click="submit()"
                >
                  <div class="font-weight-bold">เปลี่ยนรหัสผ่าน</div>
                </v-btn>
              </div>
            </v-form>
            <div v-if="changedPassword == true" class="mt-5">
              เปลี่ยนรหัสผ่านเสร็จสมบูรณ์
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { changePassword } from "@/services/api/user";
import _ from "lodash";

export default {
  name: "ChangePasswordView",

  data() {
    return {
      chagePassToken: null,
      oldPssword: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
      show1: false,
      show2: false,
      show3: false,
      changedPassword: false,
    };
  },
  mounted() {
    if (!_.isNil(this.$route.params.code)) {
      this.chagePassToken = this.$route.params.code;
    }
  },
  watch: {},

  methods: {
    onEnter() {
      this.submit();
    },
    toLoginPage() {
      this.$router.push("/login");
    },
    async submit() {
      if (this.$refs.formChangePassword.validate() === true) {
        await this.changePasswordAction();
      } else {
        this.$refs.formChangePassword.$el.scrollIntoView();
      }
    },

    async changePasswordAction() {
      this.loading = true;
      const result = await changePassword(
        this.$store,
        this.chagePassToken,
        this.oldPssword,
        this.newPassword
      );

      if (result.code == 200) {
        this.changedPassword = true;
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.text-login {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(103, 107, 111);
}

.text-forgot-password {
  font-size: 14px;
  font-weight: normal;
  color: rgb(73, 134, 233);
}

.password-not-matched {
  font-size: 14px;
  font-weight: normal;
}
</style>
