<template>
  <div name="duplicateJobRecompenseSection">
    <v-row dense>
      <v-col auto>
        <p class="text-app-detail font-weight-bold">รายรับต่อเที่ยว</p>
        <div v-if="!incomePerRoundAmountFormEditMode">
          <p class="text-h4 font-weight-bold mb-2">
            {{
              formData["incomePerRound"]
                ? `${formData["incomePerRound"].toLocaleString()} บาท`
                : "0"
            }}
            <v-btn
              icon
              class="mr-4"
              @click="
                incomePerRoundAmountFormEditMode =
                  !incomePerRoundAmountFormEditMode
              "
            >
              <v-icon color="grey lighten-1">mdi-pencil</v-icon>
            </v-btn>
          </p>
        </div>

        <div class="my-1" v-if="incomePerRoundAmountFormEditMode">
          <v-row dense>
            <v-col cols="12">
              <CustomTextInputCurrency
                ref="incomePerRound"
                v-model="newIncomePerRoundAmount"
                class="custom-fill-width mr-2"
                title=""
                placeholder="หน่วยบาท"
                :hide-detail="true"
                :small="true"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :autofocus="true"
                :dense="true"
              >
              </CustomTextInputCurrency>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="mr-1"
                color="success"
                outlined
                @click="saveRecompenseAmount"
              >
                บันทึก
              </v-btn>
              <v-btn
                class="mr-3"
                color="error"
                outlined
                @click="cancelRecompenseAmount"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col auto>
        <p class="text-app-detail font-weight-bold">ค่าตอบแทนผู้ขับต่อเที่ยว</p>
        <div v-if="!recompenseAmountFormEditMode">
          <p class="text-h4 font-weight-bold mb-4">
            {{
              formData["recompenseAmount"]
                ? `${formData["recompenseAmount"].toLocaleString()} บาท`
                : "0"
            }}
            <v-btn
              icon
              class="mr-4"
              @click="
                recompenseAmountFormEditMode = !recompenseAmountFormEditMode
              "
            >
              <v-icon color="grey lighten-1">mdi-pencil</v-icon>
            </v-btn>
          </p>
        </div>
        <div class="my-1" v-if="recompenseAmountFormEditMode">
          <v-row dense>
            <v-col cols="12">
              <CustomTextInputCurrency
                ref="recompenseAmount"
                v-model="newRecompenseAmount"
                class="custom-fill-width mr-2"
                title=""
                placeholder="หน่วยบาท"
                :hide-detail="true"
                :small="true"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :autofocus="true"
                :dense="true"
              >
              </CustomTextInputCurrency>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="mr-1"
                color="success"
                outlined
                @click="saveRecompenseAmount"
              >
                บันทึก
              </v-btn>
              <v-btn
                class="mr-3"
                color="error"
                outlined
                @click="cancelRecompenseAmount"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
export default {
  name: "DuplicateJobRecompenseSection",
  components: { CustomDateTimePickerFormInput, CustomTextInputCurrency },
  data() {
    return {
      refreshCounter: 0,
      incomePerRoundAmountFormEditMode: false,
      recompenseAmountFormEditMode: false,
      //
      newIncomePerRoundAmount: null,
      newRecompenseAmount: null,
      //
      formData: {
        incomePerRound: null,
        recompenseAmount: null,
      },
    };
  },
  watch: {
    // "formData.incomePerRound": {
    //   immediate: false,
    //   handler(newValue) {
    //     console.log("income per round", newValue);
    //   },
    // },
  },
  mounted() {
    this.formData = _.pick(this.$store.state.jobs.jobData, [
      "newIncomePerRoundAmount",
      "newRecompenseAmount",
    ]);
  },
  methods: {
    async saveRecompenseAmount() {
      this.formData = {
        incomePerRound: this.newIncomePerRoundAmount,
        recompenseAmount: this.newRecompenseAmount,
      };

      // dispatch on
      this.$store.dispatch("jobs/updateJobData", this.formData);

      // this.$emit("componentValueChange", this.formData);
    },
    cancelRecompenseAmount() {
      this.newRecompenseAmount = null;
      this.newIncomePerRoundAmount = null;
      this.recompenseAmountFormEditMode = false;
      this.incomePerRoundAmountFormEditMode = false;
    },
  },
};
</script>
<style scoped></style>
