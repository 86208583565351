<template>
  <v-row no-gutters v-if="data">
    <v-col cols="12" class="px-6 pb-6">
      <v-divider class="my-3" />
      <v-row no-gutters>
        <v-col cols="12" class="pr-9">
          <div id="compareContracts" class="pl-0">
            <apexchart
              type="bar"
              height="450"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import {
  displayNumberLocaleString,
  numberWithCommasHelper,
} from "@/services/appNumberHelper";
import { nFormatterHelper } from "@/services/appSummaryHelper";

export default {
  name: "ContractIncomeAndExpensesBarChart",
  props: {
    data: {
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 430,
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          events: {
            click: (event, chartContext, config) => {
              const selectedContractId = _.get(
                this.contractsId,
                config.dataPointIndex,
                null
              );
              if (selectedContractId) {
                this.$emit("open-chart-info", selectedContractId);
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#49c496", "#ed5555"],
        grid: {
          show: false,
        },
        annotations: {
          yaxis: this.annotationYaxis,
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
          formatter: (value, opt) => {
            // console.log(opt);
            return `${numberWithCommasHelper(value)}`;
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: (value) => {
              return `${nFormatterHelper(value)}`;
            },
          },
        },
        yaxis: {
          labels: {
            maxWidth: 260,
          },
        },
      };
    },
  },
  data() {
    return {
      currentData: null,
      series: [],
      categories: [],
      annotationYaxis: [],
      contractsId: [],
    };
  },
  mounted() {
    this.processData();
  },
  methods: {
    processData() {
      try {
        if (!_.isNil(this.data)) {
          if (!_.isEmpty(this.data["table"])) {
            this.currentData = this.data["table"];
            this.contractsId = _.keys(this.currentData);
            let _cg = [];
            //
            let _tableValue = _.values(this.currentData);

            if (_tableValue.length === 0) {
              return;
            }

            for (let i = 0; i < _tableValue.length; i++) {
              _cg.push([
                _tableValue[i]["contractNumber"],
                _tableValue[i]["title"],
              ]);
            }

            this.categories = _cg;

            const totalIncome = _.map(this.currentData, "totalIncome");
            const totalExpenses = _.map(this.currentData, "totalExpenses");

            this.series = [
              {
                name: "รายรับ",
                data: totalIncome,
              },
              {
                name: "รายจ่าย",
                data: totalExpenses,
              },
            ];

            let _annotationYaxis = [];
            if (totalIncome.length === totalExpenses.length) {
              for (let i = 0; i < totalIncome.length; i++) {
                const _res =
                  (_.divide(
                    _.subtract(totalIncome[i], totalExpenses[i]),
                    _.add(totalIncome[i], totalExpenses[i])
                  ) || 0) * 100;

                _annotationYaxis.push({
                  y: this.categories[i],
                  strokeDashArray: 1,
                  borderWidth: 2,

                  offsetY: -5,
                  label: {
                    borderColor: "#fff",
                    offsetY: 0,
                    style: {
                      color: "#fff",
                      background: _res > 0 ? "#83AB8D" : "#ED5555",
                    },
                    text: `${_res > 0 ? "+" : ""} ${displayNumberLocaleString(
                      _res,
                      2
                    )}%`,
                  },
                });
              }
            }
            this.annotationYaxis = _annotationYaxis;
          } else {
            throw "no data";
          }
        } else {
          throw "no data";
        }
      } catch (error) {
        this.series = [
          {
            name: "รายรับ",
            data: [],
          },
          {
            name: "รายจ่าย",
            data: [],
          },
        ];
        this.annotationYaxis = [];
      }
    },
  },
};
</script>
