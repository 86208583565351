import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getVehicleService(store, queryString) {
  let uri = "/vehicle";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getVehibleLocationService(store, empId) {
  let uri = `/vehicle/current-location?id=${empId}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehicleDocs(store, queryString) {
  let uri = "/vehicle-docs";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}
export async function getGpsDocs(store, queryString) {
  let uri = "/gps/list";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}

export async function getGpsSynced(store, queryString) {
  let uri = "/gps/deep/sync";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}

export async function getLiveSerialNumber(store, vehicleId) {
  let uri = `/gps/live/serialnumber?vehicleId=${vehicleId}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehicleDocsVelist(store) {
  let uri = "/vehicle-docs/velist";
  return await authHttpClientGet(store, uri);
}
export async function getVehicleFundamental(store, queryString) {
  let uri = "/vehicle/fund";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}
export async function getVehicleBySeachingService(store, text) {
  let uri = `/vehicle/search?text=${text}`;
  return await authHttpClientGet(store, uri);
}
export async function getVehicleSeachingSelectService(store, text) {
  let uri = `/vehicle/search?text=${text}&select=1`;
  return await authHttpClientGet(store, uri);
}
export async function getVehicleAndUserBySeachingService(store, text) {
  let uri = `/vehicle/veus-search?text=${text}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehicleInfoService(store, queryString) {
  let uri = `/vehicle/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehicleAccessoriesService(store, queryString) {
  let uri = `/vehicle/accessories-search`;
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}

export async function getVehicleFeedCardService(store, id) {
  let uri = `/vehicle/feedcard?id=${id}`;
  return await authHttpClientGet(store, uri);
}

export async function postVehicleFeedCardService(store, id, payload) {
  let uri = `/vehicle/feedcard?id=${id}`; // vehicle or user id
  return await authHttpClientPost(store, uri, payload, false);
}

export async function putVehicleFeedCardService(store, id, payload) {
  let uri = `/vehicle/feedcard?id=${id}`; // fleet card id
  return await authHttpClientPut(store, uri, payload, false);
}

export async function deleteVehicleFeedCardService(store, id) {
  let uri = `/vehicle/feedcard?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function getVehicleFeedCardUserService(store, id) {
  let uri = `/vehicle/feedcard?id=${id}&by=user`;
  return await authHttpClientGet(store, uri);
}

export async function postVehicleFeedCardUserService(store, id, payload) {
  let uri = `/vehicle/feedcard?id=${id}&by=user`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function getUsedVehicleHistoryService(store, queryString) {
  let uri = `/vehicle/owned-vehicle-history/?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function unsetUsedVehicleHistoryService(
  store,
  queryString,
  payload
) {
  let uri = `/vehicle/owned-vehicle-history/?${queryString}`;
  return await authHttpClientPut(store, uri, payload);
}

export async function setUsedVehicleHistoryService(
  store,
  queryString,
  payload
) {
  let uri = `/vehicle/owned-vehicle-history/?${queryString}`;
  return await authHttpClientPost(store, uri, payload);
}

export async function postVehicleService(store, payload) {
  let uri = "/vehicle";
  return await authHttpClientPost(store, uri, payload, false);
}
export async function putVehicleService(store, id, payload) {
  let uri = `/vehicle?id=${id}`;
  return await authHttpClientPut(store, uri, payload, false);
}

export async function deleteVehicleService(store, id) {
  let uri = `/vehicle?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function getVehicleDocsService(store, queryString) {
  let uri = `/vehicle-docs/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function postVehicleDocsService(store, payload) {
  let uri = "/vehicle-docs/";
  return await authHttpClientPost(store, uri, payload);
}

export async function putVehicleDocsService(store, id, payload) {
  let uri = `/vehicle-docs?id=${id}`;
  return await authHttpClientPut(store, uri, payload);
}

export async function deleteVehicleDocsService(store, id, docType) {
  let uri = `/vehicle-docs?id=${id}&doc=${docType}`;
  return await authHttpClientDelete(store, uri);
}

export async function getGpsInfoService(store, queryString) {
  let uri = `/gps/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getGpsDeepInfo(store, queryString) {
  let uri = `/gps/deep/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getGPSModelService(store) {
  let uri = "/gps/model";
  let responseData = await authHttpClientGet(store, uri);
  if (responseData.code == 200) {
    return responseData.data;
  }

  return [];
}

export async function postGPSService(store, payload) {
  let uri = "/gps/info/";
  return await authHttpClientPost(store, uri, payload);
}

export async function putGPSService(store, id, payload) {
  let uri = `/gps/info?id=${id}`;
  return await authHttpClientPut(store, uri, payload);
}

export async function deleteGPSService(store, id) {
  let uri = `/gps/info?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function postVehicleGpsInfoService(store, payload) {
  let uri = `/gps/info`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function putVehicleGpsInfoService(store, id, payload) {
  let uri = `/gps/info?id=${id}`;
  return await authHttpClientPut(store, uri, payload);
}

export async function postVehicleGpsService(store, id, payload) {
  let uri = `/vehicle/gps?id=${id}`;
  return await authHttpClientPost(store, uri, payload, false);
}

// installment
export async function getVehicleLoanListService(store, queryString) {
  let uri = `/vehicle/installment?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehicleLoanInfoService(store, queryString) {
  let uri = `/vehicle/installment-transaction/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function putVehicleLoanTransactionService(store, id, payload) {
  let uri = `/vehicle/installment-transaction?id=${id}`;
  return await authHttpClientPut(store, uri, payload);
}

export async function deleteVehicleLoanTransactionService(store, id) {
  let uri = `/vehicle/installment-transaction?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function postVehicleLoanService(store, id, payload) {
  let uri = `/vehicle/installment?id=${id}`;
  return await authHttpClientPost(store, uri, payload, false);
}

export async function delVehicleLoanService(store, id) {
  let uri = `/vehicle/installment?id=${id}`;
  return await authHttpClientDelete(store, uri, false);
}

export async function deleteRequestOwnedVehicleService(store, id) {
  let uri = `/vehicle/owned-vehicle-request?id=${id}`;
  return await authHttpClientDelete(store, uri, false);
}
