<template>
	<div v-if="isImageType">
		<InputView
			:isTitleDisabled="isTitleDisabled"
			:isDark="isDark"
			:title="title"
		>
			<AppFilesUpload
				:placeholder="placeholder"
				:multiple="multiple"
				:disabled="isPermissionCanEdit"
				:small-chips="false"
				:allow-image-only="true"
				:upload-with-scale="false"
				v-model="waitToUploadImages"
				:is-back-up-file="true"
				:is-prepend-inner-icon="true"
				icon="mdi-image"
				:ref="componentName"
				:uploadTag="uploadTag"
				:is-dark="isDark"
			/>
		</InputView>

		<v-row
			v-if="waitToUploadImages.length !== 0 || uploadedFiles.length !== 0"
			v-viewer
			dense
		>
			<v-col
				v-for="(file, fileIdx) in waitToUploadImages"
				:key="file.title + '_' + fileIdx"
				class="d-flex child-flex"
				:cols="isFullWidth ? '12' : '2'"
			>
				<v-card
					class="image-viewer-container px-2 pt-2 pb-0"
					outlined
					:disabled="isPermissionCanEdit"
				>
					<img width="100%" :src="file.src" />
					<v-btn
						class="image_list_float_left"
						icon
						color="grey lighten-1"
						:disabled="isPermissionCanEdit"
						@click="remove_upfile(fileIdx)"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</v-card>
			</v-col>
			<!--  -->
			<v-col
				v-for="(file, fileIdx) in uploadedFiles"
				:key="'uploadedFiles_' + fileIdx"
				class="d-flex child-flex"
				:cols="isFullWidth ? '12' : '2'"
			>
				<v-card
					class="image-viewer-container px-2 pt-2 pb-0"
					outlined
					:disabled="isPermissionCanEdit"
				>
					<img width="100%" :src="file.src" />
					<v-btn
						class="image_list_float_left"
						icon
						color="grey lighten-1"
						:disabled="isPermissionCanEdit"
						@click="remove_localUpfile(fileIdx)"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</div>

	<div v-else>
		<InputView :title="title" :isDark="isDark" :disabled="!isPermissionCanEdit">
			<AppFilesUpload
				v-model="waitToUploadFiles"
				:placeholder="placeholder"
				:disabled="isPermissionCanEdit"
				:multiple="multiple"
				:small-chips="false"
				:allow-image-only="false"
				:upload-with-scale="false"
				:is-back-up-file="true"
				:is-prepend-inner-icon="true"
				icon="mdi-file-multiple"
				:ref="componentName"
				:uploadTag="uploadTag"
				:is-dark="isDark"
			/>

			<!-- <v-list
        v-if="waitToUploadFiles.length !== 0 || uploadedFiles.length !== 0"
      >
        <v-list-item
          v-for="(file, fileIdx) in waitToUploadFiles"
          :key="file.title"
        >
          <v-list-item-content>
            <v-list-item-title v-text="file.name"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              :disabled="isPermissionCanEdit"
              @click="remove_upfile(fileIdx)"
            >
              <v-icon color="grey lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-for="(file, fileIdx) in uploadedFiles"
          :key="'uploadedFiles_' + fileIdx"
        >
          <v-list-item-content>
            <a :href="file.src" target="_blank">{{ file.name }}</a>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              :disabled="isPermissionCanEdit"
              @click="remove_localUpfile(fileIdx)"
            >
              <v-icon color="grey lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list> -->

			<v-simple-table
				:style="isDark ? `background-color: #222831` : ''"
				class="pb-6"
				v-if="waitToUploadFiles.length !== 0 || uploadedFiles.length !== 0"
			>
				<template v-slot:default>
					<tbody>
						<tr v-for="(file, fileIdx) in waitToUploadFiles" :key="file.title">
							<td>
								{{ file.name }}
							</td>
							<td align="right">
								<v-btn
									icon
									:disabled="isPermissionCanEdit"
									@click="remove_upfile(fileIdx)"
								>
									<v-icon color="grey lighten-1">mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
						<tr
							v-for="(file, fileIdx) in uploadedFiles"
							:key="'uploadedFiles_' + fileIdx"
						>
							<td>
								<a :href="file.src" target="_blank">{{
									file.name ? file.name : `เอกสารที่ ${fileIdx + 1}`
								}}</a>
							</td>
							<td align="right">
								<v-btn
									icon
									:disabled="isPermissionCanEdit"
									@click="remove_localUpfile(fileIdx)"
								>
									<v-icon color="grey lighten-1">mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</InputView>
	</div>
</template>

<script>
	import InputView from "@/components/InputView.vue";
	import AppFilesUpload from "./AppFilesUpload.vue";
	export default {
		name: "FormDataUploadInput",
		components: {
			InputView,
			AppFilesUpload,
		},
		props: {
			componentName: {
				type: String,
				require: true,
			},
			title: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: "",
			},
			isImageType: {
				type: Boolean,
				default: false,
			},
			multiple: {
				type: Boolean,
				default: true,
			},
			uploadedData: {
				// uploaded form server
				type: [Array, null],
				default: () => [],
			},
			isPermissionCanEdit: {
				type: Boolean,
				default: false,
			},
			isDark: {
				type: Boolean,
				default: false,
			},
			uploadTag: {
				type: String,
				default: "",
			},
			isTitleDisabled: {
				type: Boolean,
				default: false,
			},
			isFullWidth: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				uploadedFiles: [],
				waitToUploadFiles: [], // is new file
				waitToUploadImages: [],
				//
				uploadedResult: [],
				uploadedMessage: [],
			};
		},
		methods: {
			// ==== for app upload ====
			remove_upfile(index) {
				this.$refs[this.componentName].removeInput(index);
			},
			remove_localUpfile(atLocation) {
				this.uploadedFiles.splice(atLocation, 1);
			},
			restoreFile() {
				this.$refs[this.componentName].restoreFile();
			},
			//
			async upload() {
				let responseList = [];
				this.uploadResult = [];
				this.uploadedMessage = [];
				let isCompleted = true;

				try {
					let resp_file = await this.$refs[this.componentName].uploadAction();
					for (let i = 0; i < resp_file.length; i++) {
						if (resp_file[i]["error"] === 0) {
							this.uploadResult.push({
								...resp_file[i]["data"],
								name: resp_file[i]["name"],
							});
						} else {
							isCompleted = false;
							this.uploadedMessage.push(resp_file[i]["message"]);
						}
					}
				} catch (error) {
					// dev do something.
					// error while upload
					isCompleted = false;
				}

				// if (isCompleted) {
				//   // roll back all file
				//   this.restoreFile;
				// }

				if (this.uploadedFiles !== 0) {
					responseList = _.cloneDeep(this.uploadedFiles);
					for (let i = 0; i < this.uploadResult.length; i++) {
						responseList.unshift(this.uploadResult[i]);
					}
				} else {
					responseList = _.cloneDeep(this.uploadResult);
				}

				// reponse all
				return {
					status: isCompleted,
					result: responseList,
				};
			},
			clear() {
				if (this.waitToUploadFiles.length !== 0) {
					for (let fileIdx in this.waitToUploadFiles) {
						this.remove_upfile(fileIdx);
					}
				}

				if (this.waitToUploadImages.length !== 0) {
					for (let imgIdx in this.waitToUploadImages) {
						this.remove_upfile(imgIdx);
					}
				}
			},
		},
		watch: {
			uploadedData: {
				immediate: true,
				handler(newValue) {
					this.uploadedFiles = newValue;
				},
			},
			waitToUploadImages: {
				immediate: false,
				handler(newValue) {
					if (newValue.length > 0) {
						this.$emit("waitToUploadImages", true);
					} else {
						this.$emit("waitToUploadImages", false);
					}
				},
			},
			waitToUploadFiles: {
				immediate: false,
				handler(newValue) {
					if (newValue.length > 0) {
						this.$emit("waitToUploadFiles", true);
					} else {
						this.$emit("waitToUploadFiles", false);
					}
				},
			},
		},
	};
</script>
