var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showBar)?_c('v-app-bar',{attrs:{"dense":"","color":"orange lighten-4"}},[_c('div',{staticClass:"pr-4"},[_c('p',{staticClass:"ma-0 text-detail-bar font-weight-bold black--text"},[_vm._v(" ผู้วางแผนเส้นทางนี้ ")])]),(_vm.templateData)?_c('div',{staticClass:"d-inline-flex"},[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":_vm.templateData
            ? _vm.defaultPFImage
            : _vm.templateData.userId.profileimage === null
            ? _vm.defaultPFImage
            : _vm.templateData.userId.profileimage}})],1),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"ma-0 text-detail-bar font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.templateData.userId.dpNameTh)+" ")]),_c('p',{staticClass:"ma-0 text-detail-bar"},[_vm._v(" เบอร์โทร: "+_vm._s(_vm.templateData.userId.dpPhoneNumeber)+" ")])])],1):_vm._e(),_c('v-spacer'),(_vm.isPublic === true)?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"green","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-earth ")]),_vm._v(" กำลังเผยแพร่ ")],1):_vm._e(),(_vm.isPublic === false)?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"red","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-octagon ")]),_vm._v(" ยังไม่ได้กำลังเผยแพร่ ")],1):_vm._e(),(_vm.selectedStatus !== null)?_c('div',[_c('v-menu',{attrs:{"offset-y":"","left":"","disabled":!_vm.isPermissionCanEdit},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-detail-bar",attrs:{"dark":!_vm.canChangeStatus,"color":_vm.jobsStatusColor(_vm.selectedStatus),"elevation":"0","disabled":_vm.canChangeStatus}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.jobsStatusText(_vm.selectedStatus))+" "),_c('v-icon',[_vm._v(" mdi-menu-down")])],1)]}}],null,false,2414448711)},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},_vm._l((_vm.jobStatusList),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":index === _vm.selectedStatus ? true : false}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.jobsStatusText(index))+" ")])],1)}),1)],1)],1)],1):_vm._e(),_c('AppDialogConfirm',{ref:"DetailBarConfirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }