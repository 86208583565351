import axios from "axios";
import { getAccessToken } from "@/services/api/token_manager";
import { removeTokensAndInfo } from "@/services/api/token_manager";

const baseURL = process.env.VUE_APP_API_URL;
export const uploadPath = baseURL + "/file/uploadv2";
export const uploadWithScalePath = baseURL + "/file/upload-miniv2";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const uploadConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

async function getAuthConfig(store) {
  const authConfig = config;
  const accessToken = await getAccessToken(store);
  authConfig.headers["Authorization"] = "Bearer " + accessToken;
  return authConfig;
}

async function getAuthConfigRAW(store) {
  const authConfig = config;
  const accessToken = await getAccessToken(store);
  authConfig.headers["Authorization"] = "Bearer " + accessToken;
  authConfig.responseType = "text";
  authConfig.responseEncoding = "base64";
  return authConfig;
}

export async function getUploadAuthConfig(store) {
  const authConfig = uploadConfig;
  const accessToken = await getAccessToken(store);
  authConfig.headers["Authorization"] = "Bearer " + accessToken;
  return authConfig;
}

export function handlerHttpClientError(store, uri, error, showErrorDialog) {
  try {
    if (error.response) {
      if (!error.response.data) {
        // data is undefined
        throw error;
      }
      if (showErrorDialog) {
        store.commit("setErrorMessage", error.response.data.message);
        store.commit("setShowErrorDialog", true);

        if (error.response.status == 401) {
          removeTokensAndInfo(store);
          store.commit("setForceToLogin", true);
        }
      }
      return { code: error.response.status, data: error.response.data };
    } else {
      throw error;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      store.commit("setErrorMessage", error.code);
      store.commit("setShowErrorDialog", true);
      return {
        code: -1,
        data: { code: error.code, message: error.message },
      };
    }
    return { code: -2, data: { code: error.code, message: error.message } };
  }
}

export async function httpClientGet(store, uri, showErrorDialog = true) {
  try {
    const response = await axios.get(baseURL + uri, config);
    return { code: response.status, data: response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function httpClientPost(store, uri, data, showErrorDialog = true) {
  try {
    const response = await axios.post(baseURL + uri, data, config);
    return { code: response.status, data: response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function httpClientPut(store, uri, data, showErrorDialog = true) {
  try {
    const response = await axios.put(baseURL + uri, data, config);
    return { code: response.status, data: response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function authHttpClientGet(store, uri, showErrorDialog = true) {
  try {
    const response = await axios.get(baseURL + uri, await getAuthConfig(store));
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function authHttpClientPost(
  store,
  uri,
  data,
  showErrorDialog = true
) {
  try {
    const response = await axios.post(
      baseURL + uri,
      data,
      await getAuthConfig(store)
    );
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function authHttpClientPut(
  store,
  uri,
  data,
  showErrorDialog = true
) {
  try {
    const response = await axios.put(
      baseURL + uri,
      data,
      await getAuthConfig(store)
    );
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function authHttpClientDelete(store, uri, showErrorDialog = true) {
  try {
    const response = await axios.delete(
      baseURL + uri,
      await getAuthConfig(store)
    );
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}

export async function authHttpClientFullUrlPost(
  store,
  uri,
  data,
  showErrorDialog = true
) {
  try {
    const response = await axios.post(uri, data, await getAuthConfig(store));
    return { ...response.data };
  } catch (error) {
    return handlerHttpClientError(store, uri, error, showErrorDialog);
  }
}
