<template>
  <v-container name="employeeGroupListView" fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <h1 class="text-h5 mt-1">รายการกลุ่มเดินรถ</h1>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto" v-if="isPermissionCanEdit">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="valign-top pa-4">
              <div
                v-if="permList.jobs_multiple_crud || permList.admin_all"
                class="text-app-title font-weight-bold primary--text"
                style="cursor: pointer"
                @click="handleEditClick(item._id)"
              >
                {{ item.name }}
              </div>
            </td>
            <td class="valign-top pa-4">
              <div class="text-app-title" v-if="item.zone">
                {{ item.zone.nameTh ? item.zone.nameTh : "-" }}
              </div>
              <div v-else>-</div>
            </td>
            <td class="pa-4">
              <div v-if="item.contract === '-'">-</div>
              <div v-else>
                <div
                  class="text-app-title"
                  v-for="(cont, idx) in item.contract"
                  :key="idx"
                >
                  <v-chip
                    :class="idx == item.contract.length - 1 ? 'mb-0' : 'mb-1'"
                    >{{ cont.title }}</v-chip
                  >
                </div>
              </div>
            </td>
            <td class="valign-top pa-4">
              <span class="text-app-title">
                {{ item.driverCount || "-" }}
              </span>
              <v-btn
                icon
                @click="handleSchedule(item.drivers)"
                class="mx-3 mb-1"
              >
                <v-icon>mdi-bird</v-icon>
              </v-btn>
            </td>
            <td class="valign-top pa-4 px-9 text-right">
              <v-btn icon @click="handleDeleteClick(item._id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <AppDialogConfirm ref="confirmDelete" />
    </div>
    <EmployeeJobDialog ref="employeeJobDialogRef" />
    <NewEmployeeGroupNameDialog ref="groupNameDialog" />
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>
</template>

<script>
import CustomCardFormView from "@/components/CustomCardFormView.vue";
import { processPermission } from "@/services/permissions";
import NormalButton from "@/components/NormalButton.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import {
  getEmployeeGroupService,
  deleteEmployeeGroupService,
  postEmployeeGroupService,
} from "@/services/api/employeeGroup";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import NewEmployeeGroupNameDialog from "./components/NewEmployeeGroupNameDialog";
import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";

export default {
  name: "employeeGroupListView",
  components: {
    CustomCardFormView,
    NormalButton,
    PrimaryButton,
    AppDialogConfirm,
    AppPageNotFound,
    EmployeeJobDialog,
    NewEmployeeGroupNameDialog,
  },
  data() {
    return {
      componentRefreshCounter: 0,

      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      tableLoading: false,
      serverItemsLength: -1,
      options: {},
      queryStringSearch: "",
      sortVal: null,
      // itemsPerPage: 20,
      tableData: [],
      respTableData: [],

      queryOptionsTable: {
        limit: 20, // itemsPerPage = limit
        by: "createdat", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },

      queryStringSearchList: {},
      queryFilterList: {},
      querySearchAndFilterList: {},
      searchItem: "",
      isShowMenu: false,
      permList: {},

      isPermissionCanEdit: false,
      isPermissionCanView: false,
      isPermissionCanViewInfo: false,
      isPermissionCanViewReport: false,

      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createdat-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createdat-desc",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      vehicleContractStatusColor: "vehicle/vehicleContractStatusColor",
      vehicleContractStatusText: "vehicle/vehicleContractStatusText",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    tableRows() {
      let rows = this.respTableData.map((row) => {
        return {
          _id: row._id,
          name: this.checkUndefinedAndNull(row, "name", false),
          driverCount: this.checkUndefinedAndNull(row, "driverCount", false),
          contract: this.checkUndefinedAndNull(row, "contract", false),
          zone: this.checkUndefinedAndNull(row, "zone", false),
          userId: this.checkUndefinedAndNull(row, "userId", false),
          drivers: row.drivers,
          // tools: [],
        };
      });
      return rows;
    },
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อกลุ่ม",
          align: "start",
          sortable: false,
          value: "name",
          width: "25%",
        },
        {
          text: "โซน",
          align: "start",
          sortable: false,
          value: "zone",
          width: "15%",
        },
        {
          text: "สัญญาที่วิ่งบ่อย",
          align: "start",
          sortable: false,
          value: "contract",
          width: "40%",
        },
        {
          text: "จำนวนคน",
          align: "start",
          sortable: false,
          value: "driverCount",
          width: "10%",
        },
      ];
      if (this.isPermissionCanViewInfo || this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "tools",
          sortable: false,
          align: "center",
        });
      }

      return defaultdata;
    },
  },
  mounted() {
    this.checkUserPermissions();

    if (this.isPermissionCanView === false) {
      return;
    }

    if (Object.keys(this.$route.query).length > 0) {
      Object.keys(this.$route.query).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "limit":
            case "page":
              try {
                this.queryOptionsTable[key] = parseInt(this.$route.query[key]);
              } catch (error) {
                this.queryOptionsTable[key] = this.queryOptionsTable[key];
              }
              break;
            case "by":
            case "order":
              this.queryOptionsTable[key] = this.$route.query[key];
              break;
            default:
              // filter and search
              this.querySearchAndFilterList[key] = this.$route.query[key];
              break;
          }
        }
      });

      this.querySearchAndFilterList = {
        ...this.queryOptionsTable,
        ...this.querySearchAndFilterList,
      };
    } else {
      // set default parameter
      this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
    }
    //
    this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
    this.getData();
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    async getData() {
      this.tableLoading = true;
      this.tableData = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getEmployeeGroupService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        this.serverItemsLength = responseData.data["total"];
        let empList = responseData.data["list"];
        this.respTableData = empList;
        this.tableData = this.tableRows;
      } else {
        this.tableData = [];
      }

      this.tableLoading = false;
    },

    async handleAddClick() {
      const newGroup = await this.$refs.groupNameDialog.open();
      if (!_.isNil(newGroup)) {
        let respData = await postEmployeeGroupService(this.$store, newGroup);

        if (respData["code"] === 200) {
          this.handleEditClick(respData["data"]["id"]);
        } else {
          // insert fail
        }
      }
    },

    handleEditClick(_id) {
      this.$router.push(`/caj/employee_group/edit/${_id}`);
    },

    async handleSchedule(drivers) {
      if (!_.isNil(drivers) && drivers !== undefined) {
        let initedEmpList = [];
        drivers.forEach((driver) => {
          let fullName = `${
            driver["nickName"] ? driver["nickName"]["th"] : ""
          } ${driver["firstName"] ? driver["firstName"]["th"] : ""} ${
            driver["lastName"] ? driver["lastName"]["th"] : ""
          } ${driver["empId"] ? "(" + driver["empId"] + ")" : ""}`;

          if (!initedEmpList.some((e) => e.key === driver["_id"])) {
            initedEmpList.push({
              key: driver["_id"],
              value: fullName,
            });
          }
        });

        let empJob = null;
        if (initedEmpList.length > 0) {
          empJob = await this.$refs.employeeJobDialogRef.open(initedEmpList);
        } else {
          empJob = await this.$refs.employeeJobDialogRef.open();
        }
      }
    },

    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteEmployeeGroupService(this.$store, _id);
        this.getData();
      }
    },

    checkUndefinedAndNull(item, key, returnBoolean) {
      if (item.hasOwnProperty(key) === false) {
        return returnBoolean ? false : "-";
      }

      if (item[key] === null) {
        return returnBoolean ? false : "-";
      }
      // if string
      if (item[key].length === 0) {
        return returnBoolean ? false : "-";
      }

      return item[key];
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "JOBS");
        if (
          this.permList.admin_all === true ||
          this.permList.jobs_multiple_crud === true
        ) {
          this.isPermissionCanViewInfo = true;
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },

    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createdat", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },
  },
};
</script>

<style scoped>
.valign-top {
  vertical-align: top;
}
</style>
