<template>
  <div name="AppFormCardFooterComp">
    <v-card flat class="rounded-b-lg py-4 mt-5" rounded="0">
      <div class="mb-4">
        <v-divider></v-divider>
      </div>
      <v-row justify="end" class="mx-6">
        <v-col cols="auto">
          <PrimaryButton
            color="success"
            :disabled="disabled"
            @on-click="saveChange()"
          >
            บันทึก
          </PrimaryButton>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";

export default {
  name: "AppFormCardFooter",

  components: {
    PrimaryButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    saveChange() {
      this.$emit("on-click-save");
    },
  },
};
</script>
<style></style>
