<template>
  <v-dialog scrollable v-model="show" width="35%" :persistent="true">
    <v-card>
      <v-toolbar color="primary" flat dark>
        <v-card-title class="pl-0"> ตรวจสอบใบหน้า </v-card-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!onRequest" icon @click.native="cancelStatus"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-4 ma-0" v-if="fetchDataError === 1">
        <v-row dense>
          <v-col v-if="callUpdateOnComponent" cols="12">
            <p
              class="text-app-detail app-custom-input font-weight-bold"
              v-if="jobData"
            >
              ชื่องาน: {{ jobData.name }}
            </p>

            <v-divider class="pb-2"></v-divider>
          </v-col>
          <v-col cols="6">
            <v-img
              :src="faceImage"
              contain
              class="grey lighten-2"
              @error="imageLoadError"
              height="350px"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <p class="text-center mt-3 text-app-detail-large font-weight-bold">
              ภาพใบหน้า
            </p>
          </v-col>
          <v-col cols="6">
            <video width="100%" height="350px" controls>
              <source :src="jobData.driverVideoSrc" type="video/mp4" />
            </video>
            <p class="text-center mt-1 text-app-detail-large font-weight-bold">
              ภาพใบหน้า
            </p>
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row>
          <v-col cols="12">
            <div><span class="title-input"> การสแกนใบหน้า </span></div>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                lg="6"
                class="px-1"
                v-for="(statusOptionsData, statusOptionIdx) in statusOptions"
                :key="`status-${statusOptionIdx}`"
              >
                <v-card
                  :color="getStatusColor(statusOptionsData)"
                  :outlined="getStatusOutline(statusOptionsData)"
                  @click="setStatusHanlder(statusOptionIdx)"
                >
                  <v-card-text>
                    <p
                      v-if="getStatusOutline(statusOptionsData)"
                      class="ma-0 text-center text-app-md"
                    >
                      <v-icon :dark="!getStatusOutline(statusOptionsData)">
                        {{ getIcon(statusOptionsData) }}
                      </v-icon>
                      {{ statusOptionsData.value }}
                    </p>
                    <p v-else class="white--text ma-0 text-center text-app-md">
                      <v-icon :dark="!getStatusOutline(statusOptionsData)">
                        {{ getIcon(statusOptionsData) }}
                      </v-icon>
                      {{ statusOptionsData.value }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-divider class="mb-3 mt-5" vertical></v-divider> -->
          <v-col cols="12" class="mt-0 pt-0">
            <div><span class="title-input"> ความพร้อม </span></div>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                lg="6"
                class="px-1"
                v-for="(
                  statusDriverOptionsData, statusDriverOptionIdx
                ) in statusDriverOptions"
                :key="`driverStatus-${statusDriverOptionIdx}`"
              >
                <v-card
                  :color="getDriverStatusColor(statusDriverOptionsData)"
                  :outlined="getDriverStatusOutline(statusDriverOptionsData)"
                  @click="setDriverStatusHanlder(statusDriverOptionIdx)"
                >
                  <v-card-text>
                    <p
                      v-if="getDriverStatusOutline(statusDriverOptionsData)"
                      class="ma-0 text-center text-app-md"
                    >
                      <v-icon
                        :dark="!getDriverStatusOutline(statusDriverOptionsData)"
                      >
                        {{ getIcon(statusDriverOptionsData) }}
                      </v-icon>
                      {{ statusDriverOptionsData.value }}
                    </p>
                    <p v-else class="white--text ma-0 text-center text-app-md">
                      <v-icon
                        :dark="!getDriverStatusOutline(statusDriverOptionsData)"
                      >
                        {{ getIcon(statusDriverOptionsData) }}
                      </v-icon>
                      {{ statusDriverOptionsData.value }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === -1">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>

      <v-card-text class="my-2 pa-2 text-center" v-if="fetchDataError === 0">
        {{ fetchDataText }}
      </v-card-text>

      <v-divider v-if="fetchDataError === 1"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="saveStatus"
          v-if="fetchDataError === 1"
        >
          ยืนยัน
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getJobCheckFaceService,
  putJobCheckFaceService,
} from "@/services/api/jobs";
import _ from "lodash";

export default {
  name: "CheckFaceDriverDialog",
  components: {},
  data() {
    return {
      show: false,
      fetchDataError: 0, // 0 not data, 1 ok -1 error
      statusOptions: [
        { key: "APPROVED", value: "ผ่าน" },
        { key: "NOT_APPROVED", value: "ไม่ผ่าน" },
      ],
      statusDriverOptions: [
        { key: "APPROVED", value: "พร้อม" },
        { key: "NOT_APPROVED", value: "ไม่พร้อม" },
      ],
      formData: {
        checkFace: null,
        driverStatus: null,
      },
      jobData: null,
      callUpdateOnComponent: false,
      fetchDataText: "กำลังดึงข้อมูล",
      onRequest: false,
    };
  },
  methods: {
    resetForm() {
      this.fetchDataError = 0;
    },
    convertData(statusOptionIdx) {
      if (!_.isNil(statusOptionIdx)) {
        return _.clone(this.statusOptions[statusOptionIdx]);
      } else if (this.jobData.driverFaceMatched) {
        return _.clone(this.statusOptions[0]);
      }
      return _.clone(this.statusOptions[1]);
    },

    convertDriverData(statusOptionIdx) {
      if (!_.isNil(statusOptionIdx)) {
        return _.clone(this.statusDriverOptions[statusOptionIdx]);
      } else if (!_.isNil(this.jobData.driverStatus)) {
        if (this.jobData.driverStatus) {
          return _.clone(this.statusDriverOptions[0]);
        } else {
          return _.clone(this.statusDriverOptions[1]);
        }
      }
      return null;
    },

    convertDataBack(status) {
      switch (status.key) {
        case "APPROVED":
          return true;
        default:
          return false;
      }
    },

    convertDriverDataBack(status) {
      switch (status.key) {
        case "APPROVED":
          return true;
        default:
          return false;
      }
    },

    open(jobData, callUpdateOnComponent) {
      this.resetForm();
      this.jobData = jobData;
      // console.log("this.jobData", this.jobData);
      this.callUpdateOnComponent = callUpdateOnComponent;

      this.show = true;
      this.formData["checkFace"] = this.convertData(null);
      this.formData["driverStatus"] = this.convertDriverData(null);
      this.getDataFromService();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async getDataFromService() {
      let queryString = new URLSearchParams({
        id: this.jobData["_id"],
      }).toString();
      this.fetchDataError = 0;
      //
      try {
        const responseData = await getJobCheckFaceService(
          this.$store,
          queryString
        );

        this.jobData = {
          ...this.jobData,
          ...responseData["data"],
        };

        this.faceImage = _.get(responseData, "data.faceImage", "");
        this.fetchDataError = 1;
      } catch (error) {
        this.fetchDataError = -1;
      }
    },
    getStatusColor(statusOptions) {
      if (statusOptions.key === this.formData["checkFace"].key) {
        switch (statusOptions.key) {
          case "APPROVED":
            return "success";
          default:
            return "red";
        }
      }
      return "";
    },
    getDriverStatusColor(statusOptions) {
      if (_.isNil(this.formData["driverStatus"])) {
        return "";
      }
      if (statusOptions.key === this.formData["driverStatus"].key) {
        switch (statusOptions.key) {
          case "APPROVED":
            return "success";
          case "NOT_APPROVED":
            return "red";
          default:
            return "";
        }
      }
      return "";
    },
    getStatusOutline(statusOptions) {
      if (statusOptions.key === this.formData["checkFace"].key) {
        return false;
      }
      return true;
    },
    getDriverStatusOutline(statusOptions) {
      if (_.isNil(this.formData["driverStatus"])) {
        return true;
      }
      if (statusOptions.key === this.formData["driverStatus"].key) {
        return false;
      }
      return true;
    },
    getIcon(statusOptions) {
      switch (statusOptions.key) {
        case "APPROVED":
          return "mdi-check-bold";
        default:
          return "mdi-window-close";
      }
    },
    setStatusHanlder(statusOptionsIdx) {
      this.formData["checkFace"] = this.convertData(statusOptionsIdx);
    },
    setDriverStatusHanlder(statusOptionsIdx) {
      this.formData["driverStatus"] = this.convertDriverData(statusOptionsIdx);
    },
    async saveStatus() {
      this.fetchDataError = 0;
      if (this.callUpdateOnComponent) {
        let queryString = new URLSearchParams({
          id: this.jobData["_id"],
        }).toString();
        //
        this.onRequest = true;
        this.fetchDataText = "กำลังบันทึกข้อมูล...";
        let requestData = {};
        if (_.isNil(this.formData["driverStatus"])) {
          requestData = {
            driverFaceMatched: this.convertDataBack(this.formData["checkFace"]),
          };
        } else {
          requestData = {
            driverFaceMatched: this.convertDataBack(this.formData["checkFace"]),
            driverStatus: this.convertDriverDataBack(
              this.formData["driverStatus"]
            ),
          };
        }
        //

        const requestResponseData = await putJobCheckFaceService(
          this.$store,
          queryString,
          requestData
        );

        if (requestResponseData["code"] === 200) {
          // combine
          requestData = {
            ...requestData,
            ...requestResponseData["data"],
          };

          this.resolve(requestData);
        } else {
          this.resolve(null);
        }
      } else {
        let requestData = {};
        if (_.isNil(this.formData["driverStatus"])) {
          requestData = {
            driverFaceMatched: this.convertDataBack(this.formData["checkFace"]),
          };
        } else {
          requestData = {
            driverFaceMatched: this.convertDataBack(this.formData["checkFace"]),
            driverStatus: this.convertDriverDataBack(
              this.formData["driverStatus"]
            ),
          };
        }
        this.resolve(requestData);
      }

      this.show = false;
    },
    cancelStatus() {
      this.resolve(null);
      this.show = false;
    },
    imageLoadError() {
      console.log("Image failed to load");
    },
  },
};
</script>
