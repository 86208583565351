import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getEmployeeListService(store, queryString) {
  let uri = "/user";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeLocationService(store, empId) {
  let uri = `/user/current-location?id=${empId}`;
  return await authHttpClientGet(store, uri);
}

export async function getEmployeeBySeachingService(store, text) {
  let uri = `/user/search?text=${text}`;
  return await authHttpClientGet(store, uri);
}

export async function getDuplicatedRegistService(store, idCardNumber) {
  let uri = `/user/check-ident?id=${idCardNumber}`;

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeService(
  store,
  queryString,
  enableAddressList
) {
  let uri = "/user/info";
  if (queryString !== null) {
    uri = `${uri}?id=${queryString}`;
  }

  if (enableAddressList) {
    uri = uri + "&addr=1";
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeStatusService(store, empId) {
  let uri = `/user/info-status?id=${empId}`;

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeOwnedVehicleService(store, empId) {
  let uri = `/vehicle/owned-vehicle-request?id=${empId}`;

  return await authHttpClientGet(store, uri);
}

export async function postEmployeeStatusService(store, empId, data) {
  var url = `/user?type=status&id=${empId}`;

  return await authHttpClientPost(store, url, data);
}

export async function getEmployeeTimeOffListService(store, queryString) {
  let uri = "/user-timeoff/admin/list";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeTimeOffStatService(store, queryString) {
  let uri = `/user-timeoff/stats`;
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientGet(store, uri);
}

export async function putEmployeeTimeOffService(store, empId, data) {
  var url = `/user-timeoff/admin?id=${empId}`;

  return await authHttpClientPut(store, url, data);
}

export async function postUserRegisterInfoEmployee(
  store,
  type,
  id,
  data,
  disableAppDialog
) {
  var url = `/user?type=${type}`;

  if (id) {
    url = url + `&id=${id}`;
  }

  return await authHttpClientPost(store, url, data, disableAppDialog);
}

export async function deleteEmployeeService(store, id) {
  let uri = `/user?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function putUserInfoEmployee(store, type, empId, data) {
  let url = `/user?type=${type}&id=${empId}`;
  return await authHttpClientPut(store, url, data, false);
}

//
export async function getEmployeeSelectList(store) {
  return await authHttpClientGet(store, "/user/select-list");
}

export async function getEmployeeSelectDetailList(store) {
  return await authHttpClientGet(store, "/user/select-driver-list-info");
}

export async function getEmployeeJobScheduleList(store, queryString) {
  let uri = "/user/jobschedule";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// EMPLOYEE PENALTY SERVICE PART:::
export async function getPenaltyTitleSelectList(store) {
  return await authHttpClientGet(store, "/user-penality/topic/list");
}

export async function getEmployeePenaltyListService(store, queryString) {
  let uri = "/user-penality";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeePenaltyService(store, queryString) {
  let uri = "/user-penality/info";
  if (queryString !== null) {
    uri = `${uri}?id=${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getPenaltyReportService(store, queryString) {
  let uri = "/user-penality/admin/report";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putEmployeePenaltyService(store, data) {
  let url = `/user-penality/`;

  return await authHttpClientPut(store, url, data);
}

export async function postEmployeePenaltyService(store, data) {
  let url = `/user-penality/`;

  return await authHttpClientPost(store, url, data);
}

export async function getContractForEmployeePenaltyService(store) {
  let uri = "/user-penality/admin/contract";

  return await authHttpClientGet(store, uri);
}

export async function getJobVehicleForEmployeePenaltyService(
  store,
  queryString
) {
  let uri = "/user-penality/admin/job-vehicle";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getSearchForEmployeePenaltyService(store, queryString) {
  let uri = "/user-penality/search";

  if (queryString !== null) {
    uri = `${uri}?text=${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// end
