import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import {
  dateStringToDateFormat,
  getDayNameHelper,
  timeStringFormat,
  checkDateValidate,
} from "@/services/appDate";
import moment from "moment";

Vue.use(Vuex);

export const assignMultipleJob = {
  namespaced: true,
  state: {
    //
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    forceGetData: 0,
    mainLoading: false,
    //
    tableSheet: [],
    tableSheetData: {},
    columnsData: {},
    columnsStat: {},
    rowData: {},
    cellData: {},
    //
    driverInCart: {},
    driverInCell: {},
    driverInCellStat: {},
    // for check
    driverInCellJobStatus: [],
    //
    zoneList: [],
    driverGroupsList: [],
  },
  getters: {
    cellInTableById: (state) => (tableId, colsCode) => {
      return _.pickBy(state.cellData, function (v, k) {
        return v["tableId"] === tableId && v["colsCode"] === colsCode;
      });
    },
    existingDriverInCellJobStatusById:
      (state) => (tableId, driverId, cellId) => {
        return (
          _.filter(state.driverInCellJobStatus, function (item) {
            return (
              item["tableId"] === tableId &&
              item["driverId"] === driverId &&
              item["matchCellId"] === cellId
            );
          }).length > 0
        );
      },
    getDriverStatOnSheet: (state) => {
      const _driverInCellStat = _.get(state, `driverInCellStat`, {});
      const _driverInCart = _.get(state, `driverInCart`, {});

      let driverInCartOnSheet = {};
      for (const [tableKey, driverList] of Object.entries(_driverInCart)) {
        for (let i = 0; i < driverList.length; i++) {
          const _driverId = driverList[i]["_id"];
          if (_.isNil(driverInCartOnSheet[_driverId])) {
            driverInCartOnSheet[_driverId] = driverList[i];
          }
        }
      }

      let driverStatOnSheet = {};
      for (const [tableKey, driverObj] of Object.entries(_driverInCellStat)) {
        for (const [driverKey, driverStat] of Object.entries(driverObj)) {
          if (!_.isNil(driverStatOnSheet[driverKey])) {
            driverStatOnSheet[driverKey].push(driverStat);
          } else {
            driverStatOnSheet[driverKey] = [driverStat];
          }
        }
      }

      // reduct job and free
      let sumDriverStatOnSheet = {};
      for (const [driverIdKey, driverStat] of Object.entries(
        driverStatOnSheet
      )) {
        const _selectedDriverData = _.get(driverInCartOnSheet, driverIdKey, {});
        let _payload = {
          ..._selectedDriverData,
          jobs: 0,
          free: 0,
        };
        for (let i = 0; i < driverStat.length; i++) {
          _payload["jobs"] += driverStat[i]["jobs"];
          _payload["free"] += driverStat[i]["free"];
        }

        sumDriverStatOnSheet[driverIdKey] = _payload;
      }

      return sumDriverStatOnSheet;
    },
  },
  mutations: {
    // page load and permission
    setMainLoading(state, payload) {
      state.mainLoading = payload;
    },
    setForceGetData(state, payload) {
      if (payload === null) {
        state.forceGetData = state.forceGetData + 1;
      } else {
        state.forceGetData = payload;
      }
    },
    setIsPermissionCanEdit(state, payload) {
      state.isPermissionCanEdit = payload;
    },
    setIsPermissionCanView(state, payload) {
      state.isPermissionCanView = payload;
    },
    setZoneList(state, payload) {
      state.zoneList = payload;
    },
    setDriverGroupsList(state, payload) {
      state.driverGroupsList = payload;
    },
    setZoneToContractTable(state, payload) {
      state.tableSheetData = {
        ...state.tableSheetData,
        [payload["tableId"]]: {
          ...state.tableSheetData[payload["tableId"]],
          contract: payload["contract"],
        },
      };
    },
    setDriverGroupToTable(state, payload) {
      state.tableSheetData = {
        ...state.tableSheetData,
        [payload["tableId"]]: {
          ...state.tableSheetData[payload["tableId"]],
          driverGroup: payload["driverGroup"],
        },
      };
    },
    //
    setAllEmptyData(state) {
      state.forceGetData = 0;
      state.tableSheet = [];
      state.tableSheetData = {};
      state.columnsData = {};
      state.columnsStat = {};
      state.rowData = {};
      state.cellData = {};
      //
      state.driverInCart = {};
      state.driverInCell = {};
      state.driverInCellStat = {};
      state.driverInCellJobStatus = [];
      state.zoneList = [];
      state.driverGroupsList = [];
    },
    // table
    setNewTable(state, tableId) {
      // payload = _id
      state.tableSheet = _.concat(...state.tableSheet, [tableId]);

      state.columnsData = {
        ...state.columnsData,
        [tableId]: [],
      };

      state.rowData = {
        ...state.rowData,
        [tableId]: [],
      };

      state.driverInCart = {
        ...state.driverInCart,
        [tableId]: [],
      };
    },
    setByClearTableData(state, payload) {
      state.tableSheet = payload;
    },
    //
    setTableSheetData(state, payload) {
      state.tableSheetData = {
        ...state.tableSheetData,
        [payload["_id"]]: payload,
      };
    },

    setByClearTableSheetData(state, payload) {
      state.tableSheetData = payload;
    },
    // COL
    setResetCols(state, tableId) {
      state.columnsData = {
        ...state.columnsData,
        [tableId]: [],
      };
    },
    setByResetCols(state, payload) {
      state.columnsData = payload;
    },
    setPushColsData(state, payload) {
      state.columnsData = {
        ...state.columnsData,
        [payload["tableId"]]: _.concat(
          state.columnsData[payload["tableId"]],
          payload["data"]
        ),
      };
    },
    // NOTE:: ROW
    setNewAllRowData(state, payload) {
      state.rowData = {
        ...state.rowData,
        [payload["tableId"]]: payload["data"],
      };
    },
    setByClearRowsData(state, payload) {
      state.rowData = payload;
    },

    // NOTE:: CELL
    setCellData(state, payload) {
      state.cellData = {
        ...state.cellData,
        ...payload["data"],
      };
    },
    setCellDataAt(state, payload) {
      state.cellData = {
        ...state.cellData,
        [payload["at"]]: {
          ...state.cellData[payload["at"]],
          ...payload["data"],
        },
      };
    },
    setByClearCellData(state, payload) {
      state.cellData = payload;
    },

    // NOTE:: driverInCart
    setDriverInCartToTable(state, payload) {
      state.driverInCart[payload["tableId"]].unshift(payload["data"]);
    },
    setDeleteDriverInCardToTable(state, payload) {
      // DONE:: use filter
      // const i = state.driverInCart[payload["tableId"]]
      //   .map((item) => item._id)
      //   .indexOf(payload["data"]["driverId"]);
      // state.driverInCart[payload["tableId"]].splice(i, 1);

      state.driverInCart[payload["tableId"]] = _.filter(
        state.driverInCart[payload["tableId"]],
        (item) => item["_id"] !== payload["data"]["driverId"]
      );
    },
    setStatusDriverInCardToTable(state, payload) {
      const i = state.driverInCart[payload["tableId"]]
        .map((item) => item._id)
        .indexOf(payload["data"]["driverId"]);
      state.driverInCart[payload["tableId"]][i]["status"] =
        payload["data"]["status"];
    },
    setToClearDriverInCart(state, payload) {
      state.driverInCart[payload["tableId"]] = payload["data"];
    },
    setByClearDriverInCart(state, payload) {
      state.driverInCart = payload;
    },

    // NOTE:: driver in cell
    setDriverInCellToTable(state, payload) {
      state.driverInCell[payload["tableId"]] = payload["data"];
    },
    setCountDriverInCell(state, payload) {
      state.driverInCell[payload["tableId"]] = {
        ...state.driverInCell[payload["tableId"]],
        [payload["driverId"]]: payload["data"],
      };
    },
    setByClearDriverInCell(state, payload) {
      state.driverInCell = payload;
    },

    // NOTE:: driver in cart stat
    setDriverInCartStatToTable(state, payload) {
      state.driverInCellStat = {
        ...state.driverInCellStat,
        [payload["tableId"]]: payload["data"],
      };
    },
    setByClearDriverInCellStat(state, payload) {
      state.driverInCellStat = payload;
    },

    // NOTE:: column table
    setColumnsStatAtTable(state, payload) {
      state.columnsStat = {
        ...state.columnsStat,
        [payload["tableId"]]: payload["data"],
      };
    },

    // NOTE:: driverInCellJobStatus space
    setDriverInCellJobStatus(state, payload) {
      state.driverInCellJobStatus.push(payload);
    },
    removeDriverInCellJobStatus(state, payload) {
      state.driverInCellJobStatus = _.filter(
        state.driverInCellJobStatus,
        (item) =>
          !(
            item.driverId === payload["driverId"] &&
            item.tableId === item.tableId
          )
      );
    },
    removeDriverInCellJobStatusByCellMId(state, payload) {
      state.driverInCellJobStatus = _.filter(
        state.driverInCellJobStatus,
        (item) => !(item.matchCellId === payload.cellMId)
      );
    },
    removeDriverInCellJobStatusByTableId(state, payload) {
      state.driverInCellJobStatus = _.filter(
        state.driverInCellJobStatus,
        (item) => !(item.tableId === payload.tableId)
      );
    },
    removeDriverInCellJobStatusByRowId(state, payload) {
      state.driverInCellJobStatus = _.filter(
        state.driverInCellJobStatus,
        (item) => !(item.rowId === payload.rowId)
      );
    },
    removeDriverInCellJobStatusByColsId(state, payload) {
      state.driverInCellJobStatus = _.filter(
        state.driverInCellJobStatus,
        (item) => !(item.colsId === payload.colsId)
      );
    },
  },

  actions: {
    updateMainLoading(context, payload) {
      context.commit("setMainLoading", payload);
    },
    updateEmptyData(context) {
      context.commit("setAllEmptyData");
    },

    // main table function
    createNewTable(context, payload) {
      context.commit("setNewTable", payload["_id"]);
      context.commit("setTableSheetData", payload);
    },

    // Table => COL , ROW, CELL
    createColsandRowToTable(context, payload) {
      context.commit("setResetCols", payload["_id"]);
      for (let j = 0; j < payload["columns"].length; j += 2) {
        // insert with pair
        context.dispatch("addNewColumnsToTable", {
          tableId: payload["_id"],
          headers: [payload["columns"][j], payload["columns"][j + 1]],
        });
      }

      // assign row
      context.dispatch("addNewRowsToTable", {
        tableId: payload["_id"],
        rows: payload["rows"],
      });

      // assign driverInCart
      for (let j = 0; j < payload["driverInCart"].length; j++) {
        let currentDriverInCart = _.cloneDeep(payload["driverInCart"][j]);
        currentDriverInCart["status"] = 0;

        context.commit("setDriverInCartToTable", {
          tableId: payload["_id"],
          data: currentDriverInCart,
        });
      }
      // assign driverInCell
      context.commit("setDriverInCellToTable", {
        tableId: payload["_id"],
        data: payload["driverInCell"],
      });
      // assign driverInCell stat
      context.dispatch("updateDriverInCartStatTable", {
        tableId: payload["_id"],
      });
      //
      context.dispatch("updateColumnsStat", {
        tableId: payload["_id"],
      });
      // clear status
      context.commit("removeDriverInCellJobStatusByTableId", {
        tableId: payload["_id"],
      });
      for (let j = 0; j < payload["driverInCellJobStatus"].length; j++) {
        context.commit(
          "setDriverInCellJobStatus",
          payload["driverInCellJobStatus"][j]
        );
      }
      //
      context.dispatch("updateCellDataStatusAt", {
        cell: payload["driverInCellJobStatus"],
        updateData: { isDriverHasGetJobOpsError: true },
      });
    },

    // COLUMNS
    addNewColumnsToTable(context, payload) {
      const columnsDataLength =
        context.state.columnsData[payload["tableId"]].length;

      const futurePairNumber = (columnsDataLength + 2) / 2;
      const data = [
        {
          ...payload["headers"][0],
          // row: 0,
          // col: columnsDataLength,
          // type: 1,
          // title: null,
          // subTitle: null,
          // noData: 1,
          isBetweenPair: futurePairNumber % 2 === 0 ? true : false,
        },
        {
          ...payload["headers"][1],
          // row: 0,
          // col: columnsDataLength + 1,
          // type: 2,
          // title: null,
          // subTitle: null,
          // noData: 1,
          isBetweenPair: futurePairNumber % 2 === 0 ? true : false,
        },
      ];

      // if (payload["header"][0]["noData"] === 0) {
      //   data[0] = {
      //     ...data[0],
      //     confirmDate: payload["header"][0]["confirmDate"],
      //     startDate: payload["header"][0]["startDate"],
      //     endDate: payload["header"][0]["endDate"],
      //     estimateEndDate: payload["header"][0]["estimateEndDate"],
      //     title: payload["header"][0]["title"],
      //     subTitle: null,
      //     noData: 0,
      //   };
      // } else {
      //   // set no date
      //   data[0] = {
      //     ...data[0],
      //     confirmDate: null,
      //     startDate: null,
      //     endDate: null,
      //     confirmDate: null,
      //     noData: 1,
      //   };
      // }

      // if (payload["header"][1]["noData"] === 0) {
      //   data[1] = {
      //     ...data[1],
      //     confirmDate: payload["header"][1]["confirmDate"],
      //     startDate: payload["header"][1]["startDate"],
      //     endDate: payload["header"][1]["endDate"],
      //     estimateEndDate: payload["header"][1]["estimateEndDate"],
      //     title: payload["header"][1]["title"],
      //     subTitle: null,
      //     noData: 0,
      //   };
      // } else {
      //   // set no date
      //   data[1] = {
      //     ...data[1],
      //     confirmDate: null,
      //     startDate: null,
      //     endDate: null,
      //     noData: 1,
      //   };
      // }

      context.commit("setPushColsData", {
        tableId: payload["tableId"],
        data: data,
      });
    },
    // ROW
    addNewRowsToTable(context, payload) {
      let rowTemplateData = _.cloneDeep(payload["rows"]);
      let newCellData = {};
      for (let i = 0; i < rowTemplateData.length; i++) {
        const _d = moment(rowTemplateData[i]["date"]);
        const isDateValid = checkDateValidate(_d.toDate());

        if (isDateValid) {
          rowTemplateData[i]["title"] = getDayNameHelper(_d.weekday());
          rowTemplateData[i]["date"] = dateStringToDateFormat(
            rowTemplateData[i]["date"],
            {
              type: "date",
              returnThai: false,
            }
          );
        }

        for (let j = 0; j < rowTemplateData[i]["data"].length; j++) {
          newCellData[rowTemplateData[i]["data"][j]["cellId"]] =
            rowTemplateData[i]["data"][j];

          rowTemplateData[i]["data"][j] = _.pick(
            rowTemplateData[i]["data"][j],
            ["cellId", "row", "col", "tableRowsId"]
          );
        }
      }

      context.commit("setNewAllRowData", {
        tableId: payload["tableId"],
        data: rowTemplateData,
      });

      context.commit("setCellData", {
        data: newCellData,
      });
    },
    updateRowDirectly(context, payload) {
      let currentData = _.cloneDeep(
        context.state.rowData[`${payload["tableId"]}`]
      );

      currentData[payload["rowIdx"]] = {
        ...currentData[payload["rowIdx"]],
        ...payload["data"],
      };

      context.commit("setNewAllRowData", {
        tableId: payload["tableId"],
        data: currentData,
      });
    },

    // NOTE:: Table cell
    updateInputCell(context, payload) {
      const tableCellId = `T${payload["tableId"]}R${payload["rowIdx"]}C${payload["colIdx"]}`;
      let currentData = context.state.cellData[tableCellId];
      const newData = {
        [tableCellId]: {
          ...currentData,
          ...payload["data"],
          // numberOfSelectedDriver: payload["numberOfSelectedDriver"],
          // inputState: 1,
        },
      };
      //
      context.commit("setCellData", {
        data: newData,
      });
      //
    },
    updateInputCellDirectly(context, payload) {
      const currentData = context.state.cellData[payload["updateAtCellId"]];
      const newData = {
        [payload["updateAtCellId"]]: {
          ...currentData,
          ...payload["updateData"],
        },
      };
      //
      context.commit("setCellData", {
        data: newData,
      });
    },

    updateClearInputCell(context, payload) {
      const currentData = context.state.cellData;
      let newCurrentData = {};
      for (const [key, objValue] of Object.entries(currentData)) {
        if (objValue["tableId"] !== payload["tableId"]) {
          newCurrentData[key] = objValue;
        }
      }

      context.commit("setToClearDriverInCart", {
        tableId: payload["tableId"],
        data: [],
      });
      context.commit("setByClearCellData", newCurrentData);
      //
    },

    updateJobStatusToDriverInCell(context, payload) {
      // find cell by row
      let collectUpdateCell = [];
      // let collectUpdateCellMap = {};
      const updateAtCellKey = _.keys(payload["data"]["updatedCell"]);
      for (let i = 0; i < updateAtCellKey.length; i++) {
        for (const [key, value] of Object.entries(context.state.cellData)) {
          if (value["cellMId"] === updateAtCellKey[i]) {
            collectUpdateCell.push(_.cloneDeep(value));
          }
        }
      }

      // assign driver
      for (let i = 0; i < collectUpdateCell.length; i++) {
        collectUpdateCell[i]["isDriverHasGetJobOpsError"] = false;
        const newDriverData = _.get(
          payload,
          `data.updatedCell.${collectUpdateCell[i]["cellMId"]}`,
          null
        );

        for (let j = 0; j < newDriverData.length; j++) {
          const findIndex = _.findIndex(collectUpdateCell[i]["drivers"], {
            _id: newDriverData[j]["driverId"],
          });

          if (findIndex !== -1) {
            collectUpdateCell[i]["drivers"][findIndex] = {
              ...collectUpdateCell[i]["drivers"][findIndex],
              ..._.pick(newDriverData[j], [
                "jobId",
                "jobStatus",
                "alreadyJobDetail",
                "message",
              ]),
            };

            if (newDriverData[j]["jobStatus"] === "!OK") {
              collectUpdateCell[i]["isDriverHasGetJobOpsError"] = true;
              // collectUpdateCellMap[collectUpdateCell[i]["cellId"]] = {
              //   isDriverHasGetJobOpsError: true,
              // };
            }
          }
        }

        context.commit("setCellData", {
          data: {
            [collectUpdateCell[i]["cellId"]]: collectUpdateCell[i],
          },
        });
      }

      //
      // context.dispatch("updateCellDataStatus", collectUpdateCellMap);
      context.dispatch("updateColumnsStat", { tableId: payload["tableId"] });
    },

    updateCellDataStatusAt(context, payload) {
      // find cell
      for (let i = 0; i < payload["cell"].length; i++) {
        const _cell = _.pickBy(context.state.cellData, {
          cellMId: payload["cell"][i]["matchCellId"],
        });
        if (!_.isNil(_cell)) {
          context.commit("setCellDataAt", {
            at: _.keys(_cell)[0],
            data: payload["updateData"],
          });
        }
      }
    },

    // NOTE:: Driver In Cart
    updateDriverInCartToTable(context, payload) {
      context.commit("setDriverInCartToTable", payload);
      context.dispatch("updateDriverInCartStatTable", payload);
    },
    updateCountToDriverInCartToTable(context, payload) {
      for (let i = 0; i < payload["data"].length; i++) {
        const driverInCell = _.get(
          context.state.driverInCell,
          `${payload["tableId"]}.${payload["data"][i]["driverId"]}`,
          null
        );
        if (driverInCell === null) {
          // console.log({
          //   tableId: payload["tableId"],
          //   driverId: payload["data"][i]["driverId"],
          //   data:
          //     payload["data"][i]["countCell"] < 0
          //       ? 0
          //       : payload["data"][i]["countCell"],
          // });
          context.commit("setCountDriverInCell", {
            tableId: payload["tableId"],
            driverId: payload["data"][i]["driverId"],
            data: {
              countCell:
                payload["data"][i]["countCell"] < 0
                  ? 0
                  : payload["data"][i]["countCell"],
            },
          });
        } else {
          context.commit("setCountDriverInCell", {
            tableId: payload["tableId"],
            driverId: payload["data"][i]["driverId"],
            data: {
              countCell:
                driverInCell["countCell"] + payload["data"][i]["countCell"],
            },
          });
          // console.log({
          //   tableId: payload["tableId"],
          //   driverId: payload["data"][i]["driverId"],
          //   data: driverInCell["countCell"] + payload["data"][i]["countCell"],
          // });
        }
      }
      //
      context.dispatch("updateDriverInCartStatTable", {
        tableId: payload["tableId"],
      });
    },

    // NOTE:: Group zone
    updateZoneToTable(context, payload) {
      const currentContractData = _.cloneDeep(
        context.state.tableSheetData[payload["tableId"]]["contract"]
      );
      currentContractData["zone"] = payload["zone"];
      context.commit("setZoneToContractTable", {
        tableId: payload["tableId"],
        contract: currentContractData,
      });
    },
    updateGroupsToTable(context, payload) {
      context.commit("setDriverGroupToTable", payload);
    },

    // NOTE:: driver in cart stat
    updateDeleteDriverInCardToTable(context, payload) {
      context.commit("setDeleteDriverInCardToTable", payload);
      context.dispatch("updateDriverInCartStatTable", {
        tableId: payload["tableId"],
      });
    },
    updateDriverInCartStatTable(context, payload) {
      // loop row => loop col
      let driverInCartStat = {};
      let driverInCartList = [];
      // let cellOnTableKey = [];
      const driverInCartCell = context.state.driverInCart[payload["tableId"]];
      for (let i = 0; i < driverInCartCell.length; i++) {
        driverInCartStat[driverInCartCell[i]["_id"]] = {
          jobs: 0,
          free: 0,
          diff: 0,
          freeX: null,
          // name: _.get(driverInCartCell[i], "fullName", null),
        };
        driverInCartList.push(driverInCartCell[i]["_id"]);
      }
      //
      let columnsRowsWithDriverInCell = [];
      for (const [rowKey, rowValue] of Object.entries(
        context.state.rowData[payload["tableId"]]
      )) {
        for (
          let i = 0;
          i < context.state.columnsData[payload["tableId"]].length;
          i++
        ) {
          // console.log(
          //   rowValue["id"],
          //   context.state.columnsData[payload["tableId"]][i]["tableColsId"]
          // );
          const _cellData = _.find(context.state.cellData, {
            tableRowsId: rowValue["id"],
            tableColsId:
              context.state.columnsData[payload["tableId"]][i]["tableColsId"],
          });

          if (_.isNil(_cellData)) {
            columnsRowsWithDriverInCell.push([]);
          } else {
            const drivers = _cellData["drivers"].map((item) => item._id);
            columnsRowsWithDriverInCell.push(drivers);
          }
        }
      }

      for (let i = 0; i < driverInCartList.length; i++) {
        let cJob = 0;
        let cFree = 0;
        //
        for (let j = 0; j < columnsRowsWithDriverInCell.length; j += 2) {
          let _job = 0;
          let _free = 0;

          if (columnsRowsWithDriverInCell[j].includes(driverInCartList[i])) {
            _job += 1;
          }
          if (
            columnsRowsWithDriverInCell[j + 1].includes(driverInCartList[i])
          ) {
            _job += 1;
          }

          if (_job === 1) {
            _free += 1;
          }

          cJob += _job;
          cFree += _free;
        }
        //
        driverInCartStat[driverInCartList[i]]["jobs"] = cJob;
        driverInCartStat[driverInCartList[i]]["free"] = cFree;
        driverInCartStat[driverInCartList[i]]["diff"] = cJob - cFree;
        driverInCartStat[driverInCartList[i]]["freeX"] =
          cFree > 0 ? 1 / cFree : null;
      }

      context.commit("setDriverInCartStatToTable", {
        tableId: payload["tableId"],
        data: driverInCartStat,
      });
    },
    clearDataByTableId(context, payload) {
      // filter
      try {
        const updateTableSheetData = context.state.tableSheet.filter(
          (item) => item !== payload["tableId"]
        );

        const updateSheetData = _.omit(context.state.tableSheetData, [
          payload["tableId"],
        ]);

        const updateColumnsData = _.omit(context.state.columnsData, [
          payload["tableId"],
        ]);

        const updateRowData = _.omit(context.state.rowData, [
          payload["tableId"],
        ]);

        const updateDriverInCart = _.omit(context.state.driverInCart, [
          payload["tableId"],
        ]);

        const updateDriverInCell = _.omit(context.state.driverInCell, [
          payload["tableId"],
        ]);
        const updateDriverInCartStat = _.omit(context.state.driverInCellStat, [
          payload["tableId"],
        ]);

        const updateCellData = _.pickBy(
          context.state.cellData,
          function (v, k) {
            return v["tableId"] !== payload["tableId"];
          }
        );

        context.commit("setByClearTableData", updateTableSheetData);
        context.commit("setByClearTableSheetData", updateSheetData);
        context.commit("setByResetCols", updateColumnsData);
        context.commit("setByClearRowsData", updateRowData);

        context.commit("setByClearDriverInCart", updateDriverInCart);
        context.commit("setByClearDriverInCell", updateDriverInCell);
        context.commit("setByClearDriverInCellStat", updateDriverInCartStat);
        context.commit("setByClearDriverInCellStat", updateDriverInCartStat);
        context.commit("setByClearCellData", updateCellData);
        //
        // console.log(updateTableSheetData);
        // console.log(updateSheetData);
        // console.log(updateColumnsData);
        // console.log(updateRowData);
      } catch (error) {
        console.error(error);
      }
    },
    updateColumnsStat(context, payload) {
      // const cellData = this.cellInTableById(
      //   header["tableColsId"].split("_")[0],
      //   colsCode
      // );
      let payloadUpdateData = {};
      const columnsDataList = context.state.columnsData[payload["tableId"]];

      for (let i = 0; i < columnsDataList.length; i += 2) {
        let currentCountJob = 0;
        const cellData = _.pickBy(context.state.cellData, function (v, k) {
          return (
            v["tableId"] === payload["tableId"] &&
            v["colsCode"] === columnsDataList[i]["colsCode"]
          );
        });

        //
        const cellDataList = _.map(cellData, (v, k) => ({ ...v }));

        for (let j = 0; j < cellDataList.length; j++) {
          const { drivers } = cellDataList[j];
          if (drivers.length > 0) {
            const driverHasJob = _.takeWhile(drivers, function (o) {
              return !_.isNil(o.jobId);
            });
            currentCountJob += driverHasJob.length;
          }
        }
        //

        payloadUpdateData = {
          ...payloadUpdateData,
          [columnsDataList[i]["colsCode"]]: currentCountJob,
        };
      }

      context.commit("setColumnsStatAtTable", {
        tableId: payload["tableId"],
        data: payloadUpdateData,
      });
    },
  },
  modules: {},
};
