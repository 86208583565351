<template>
  <div name="vehicleDocsFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
      <div class="pa-6">
        <v-row>
          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="ช่วงเวลาสร้างรายการ"
                v-model="formData.createDate"
                :isRequired="false"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="ช่วงเวลาวันนัดหมาย"
                v-model="formData.appointmentDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <InputView title="ประเภทรายการ" :isRequired="false">
              <v-btn-toggle v-model="formData.eventType" dense color="primary">
                <v-btn
                  v-for="(status, idx) in vehicleEventTypeList"
                  :key="'vehicle' + idx"
                >
                  {{ status.value }}
                </v-btn>
              </v-btn-toggle>
            </InputView>
          </v-col>
          <!--  -->
          <v-col cols="12" class="pt-6">
            <InputView title="สถานะรายการ" :isRequired="false">
              <v-btn-toggle
                v-model="formData.eventStatus"
                dense
                color="primary"
              >
                <v-btn
                  v-for="(status, idx) in vehicleEventStatusList"
                  :key="'vehicle' + idx"
                >
                  {{ status.value }}
                </v-btn>
              </v-btn-toggle>
            </InputView>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import { ProcessSelectListFormatHelpper } from "@/services/arrayAndObjHelpper";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "VehicleEventFilterGroup",
  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    CustomTextInput,
    CustomFilterDateTypingPickerRange,
    InputView,
  },

  data() {
    return {
      queryOrder: {
        // query : formData
        type: "eventType",
        status: "eventStatus",
        ///
        mincreatedate: "createDate",
        maxcreatedate: "createDate",
        //
        minappointmentdate: "appointmentDate",
        maxappointmentdate: "appointmentDate",
      },
      copyFormData: null,
      formData: {
        createDate: [],
        appointmentDate: [],
        eventType: undefined,
        eventStatus: undefined,
      },
    };
  },

  methods: {
    async getData() {},
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "mincreatedate":
            case "maxcreatedate":
              this.formData.createDate.push(this.$route.query[key]);
              break;
            case "minappointmentdate":
            case "maxappointmentdate":
              this.formData.appointmentDate.push(this.$route.query[key]);
              break;
            case "type":
              this.formData["eventType"] = this.vehicleEventTypeList.findIndex(
                (p) => p.key == this.$route.query[key]
              );
            case "status":
              this.formData["eventStatus"] =
                this.vehicleEventStatusList.findIndex(
                  (p) => p.key == this.$route.query[key]
                );
            // default:
            //   this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};
      // process by check formdata
      // console.log("FormData:", this.formData);
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.formData[this.queryOrder[key]])) {
          switch (this.queryOrder[key]) {
            case "createDate":
              if (this.formData.createDate.length === 1) {
                filterParams["mincreatedate"] = this.formData.createDate[0];
              } else if (this.formData.createDate.length === 2) {
                filterParams["mincreatedate"] = this.formData.createDate[0];
                filterParams["maxcreatedate"] = this.formData.createDate[1];
              }
              break;
            case "appointmentDate":
              if (this.formData.appointmentDate.length === 1) {
                filterParams["minappointmentdate"] =
                  this.formData.appointmentDate[0];
              } else if (this.formData.appointmentDate.length === 2) {
                filterParams["minappointmentdate"] =
                  this.formData.appointmentDate[0];
                filterParams["maxappointmentdate"] =
                  this.formData.appointmentDate[1];
              }
              break;
            case "eventType":
              if (this.formData.eventType !== undefined) {
                filterParams["type"] =
                  this.vehicleEventTypeList[this.formData.eventType].key;
              }
            case "eventStatus":
              if (this.formData.eventStatus !== undefined) {
                filterParams["status"] =
                  this.vehicleEventStatusList[this.formData.eventStatus].key;
              }
            // default:
            //   filterParams[key] = this.formData[this.queryOrder[key]];
          }
        }
      });

      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },

    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      this.copyFormData = _.cloneDeep(this.formData);
      this.formData = {
        createDate: [],
        appointmentDate: [],
        eventType: undefined,
        eventStatus: undefined,
      };
    },
  },
  computed: {
    vehicleEventTypeList() {
      const typeList = this.$store.getters["vehicle/vehicleEventTypeObj"];
      let _items = ProcessSelectListFormatHelpper(typeList);
      return _items;
    },
    vehicleEventStatusList() {
      const typeList =
        this.$store.getters["vehicle/vehicleEventProcessStatusObj"];
      let _items = ProcessSelectListFormatHelpper(typeList);
      return _items;
    },
  },
  mounted() {
    this.getParamQuery();
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
