<template>
  <v-hover v-slot="{ hover }">
    <v-menu open-on-hover :close-on-content-click="false" top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="[
            'amsd-short',
            'd-flex',
            { 'amsd-has-job': driver.jobId },
            {
              'amsd-no-has-job': !driver.jobId && driver.jobStatus === '!OK',
            },
            { 'amsd-pair-background': isBetweenPair },
            { 'mr-1': (driverIdx + 1) % 3 !== 0 },
            { 'on-hover': hover },
          ]"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="26">
            <img :src="driver.profileImage" />
          </v-avatar>
          <span style="font-size: 13px; line-height: 2" class="ml-1">
            {{ driver.dpNickNameTh }}
          </span>
        </div>
      </template>

      <v-card v-if="driver.alreadyJobDetail" max-width="400">
        <v-list class="pa-0">
          <v-list-item class="px-2">
            <v-list-item-content class="py-2">
              <v-list-item-title style="white-space: normal">
                <router-link
                  class="text-decoration-none text-app-md"
                  :to="'/caj/jobs/edit/' + driver.alreadyJobDetail._id"
                  target="_blank"
                >
                  {{ driver.alreadyJobDetail.name }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>
                <p
                  class="ma-0"
                  :style="`color: ${jobsStatusColorValue(
                    driver.alreadyJobDetail.status
                  )}`"
                >
                  สถานะ{{ jobsStatusText(driver.alreadyJobDetail.status) }}
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <div class="pa-2">
          <p class="mb-1 text-app-small">
            เวลาเริ่มจบงาน:
            {{ procossRowDate(driver.alreadyJobDetail, "startDate") }}
          </p>
          <p class="mb-1 text-app-small">
            เวลาจบงาน:
            {{ procossRowDate(driver.alreadyJobDetail, "endDate") }}
          </p>
          <p class="mb-1 text-app-small">
            เวลาจบงานโดยประมาณ:
            {{ procossRowDate(driver.alreadyJobDetail, "estimateEndDate") }}
          </p>
          <p
            class="ma-0 text-caption green--text text--lighten-1"
            v-if="forceCreateJobData"
          >
            ยินยอมสร้างงานซ้ำ: {{ forceCreateJobData.jobCode }}
            {{ forceCreateJobData.name }}
          </p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small @click="forceCreateJob()" :loading="loading">
            ยินยอมสร้างงานซ้ำ
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="driver.message === 'NO_FIN'" max-width="400">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> ไม่สามารถสร้างงานได้ </v-list-item-title>
              <v-list-item-subtitle>
                เนื่องจากเส้นทางหลัก ยังไม่ได้ระบุรายรับและค่าตอบแทน
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card
        v-if="driver.message === 'NOT_AVAILABLE_ROUTE_DATA'"
        max-width="400"
      >
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> ไม่สามารถสร้างงานได้ </v-list-item-title>
              <v-list-item-subtitle>
                เนื่องจากเส้นทางหลักนี้ไม่พร้อม โปรดตรวจสอบ <br />
                1.ต้องมีรายรับและค่าตอบแทนของเส้นทาง <br />
                2. ต้องมีตำแหน่งจุดต้องมีมากกว่าหรือเท่ากับ 2 ขึ้นไป <br />
                3. ต้องมีเส้นทางมีสถานะเป็น "เผยแพร่" <br />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-hover>
</template>

<script>
import { dateStringToDateFormat } from "@/services/appDate";
import { mapGetters } from "vuex";
import { putForceCreateJobFromCellTableService } from "@/services/api/assign_multiple_job";
export default {
  name: "JobDuplicateMenuHoverOnChip",
  props: {
    driver: {
      type: [Object, null],
      default: null,
    },
    isBetweenPair: {
      type: Boolean,
      default: false,
    },
    driverIdx: {
      type: Number,
      required: true,
    },
    vuexRowIdx: {
      type: Number,
      require: true,
    },
    vuexColsIdx: {
      type: Number,
      require: true,
    },
    tableId: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      jobsStatusText: "jobs/jobsStatusText",
    }),
    cellData: function () {
      return _.get(
        this.$store.state,
        `assignMultipleJob.cellData.T${this.tableId}R${this.vuexRowIdx}C${this.vuexColsIdx}`,
        null
      );
      // return {};
    },
  },
  data: () => ({
    loading: false,
    forceCreateJobData: null,
  }),
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    procossRowDate(row, key) {
      if (_.isNil(row[key])) {
        return "-";
      }
      return dateStringToDateFormat(row[key], {
        type: "datetime",
        returnThai: false,
        showSecond: false,
      });
    },
    async forceCreateJob() {
      this.loading = true;
      this.forceCreateJobData = null;

      try {
        const requestBody = {
          driverId: this.driver._id,
          colsId: this.cellData.colsCode,
          rowId: this.cellData.rowsCode,
        };

        const responseData = await putForceCreateJobFromCellTableService(
          this.$store,
          new URLSearchParams({
            id: this.cellData["cellMId"],
          }).toString(),
          requestBody
        );

        if (responseData["code"] === 200) {
          this.forceCreateJobData = responseData["data"]["newJobData"];
        } else {
          throw "FAIL_TO_CREATE";
        }
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
  },
};
</script>
