<template>
  <v-combobox
    :items="itemList"
    :loading="loading"
    :readonly="tableLoading"
    :search-input.sync="searchingAction"
    :item-value="itemValueKey"
    v-model="modelVal"
    @change="itemChange"
    placeholder="ค้นหาจาก ชื่อเส้นทาง"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    item-text="value"
    :clearable="tableLoading === false"
    return-object
    filled
    dense
    hide-no-data
    hide-selected
    hide-details
    flat
    height="44"
    outlined
    class="app-custom-input"
  >
  </v-combobox>
  <!-- hide-no-data -->
  <!--     auto-select-first -->
  <!-- @keyup="enterToReturnEmit" -->
</template>
<script>
import _ from "lodash";
import { getRouteTemplateSeachingService } from "@/services/api/contract_job_routes";

export default {
  name: "AutoCompleteRouteSearching",
  props: {
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    tableLoading: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      // display id
      for (let i = 0; i < this.queryOrder.length; i++) {
        if (!_.isNil(this.$route.query[this.queryOrder[i]])) {
          // display text
          this.modelVal = this.$route.query[this.queryOrder[i]];
          break;
        }
      }
    }
  },
  data: () => ({
    modelVal: null,
    itemList: [],
    loading: false,
    searchingAction: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryOrder: ["text"],
    firstInit: false,
    selectFromListChecker: false,
  }),
  watch: {
    searchingAction(val) {
      if (!_.isNil(val) && this.firstInit && !this.selectFromListChecker) {
        this.currentSearchText = val;
        if (val.length > 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1500);
        }
      }
      this.firstInit = true;
      this.selectFromListChecker = false;
    },
  },
  methods: {
    async callApi(val) {
      let responseData = await getRouteTemplateSeachingService(
        this.$store,
        val
      );
      if (responseData["code"] === 200) {
        this.itemList = responseData["data"];
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },

    itemChange() {
      if (
        _.isString(this.modelVal) === false &&
        _.isNil(this.modelVal) === false
      ) {
        this.selectFromListChecker = true;
        this.$emit("searchItemChange", this.modelVal);
        this.modelVal = this.modelVal["value"];
        this.itemList = [];
      } else if (_.isString(this.modelVal) === true) {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;

        this.$emit("searchItemChange", {
          value: this.currentSearchText,
        });
        this.itemList = [];
      } else {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;

        this.$emit("searchItemChange", {
          value: null,
        });
        this.itemList = [];
      }

      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },
  },
};
</script>
<style>
.v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
}
</style>
