import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getContractsService(store, queryString) {
  let uri = "/contract";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

//
export async function getContractPassageQRCodeService(store, queryString) {
  let uri = "/contract/passager-qrcode";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// export async function putVehicleContractUpdateStatusService(
//   store,
//   queryString,
//   data
// ) {
//   let uri = "/vehicle-fuel/fuel-status";

//   if (queryString !== null) {
//     uri = `${uri}?${queryString}`;
//   }

//   return await authHttpClientPut(store, uri, data);
// }

export async function getContractBySeachingService(store, text, output) {
  let uri = `/contract/search?text=${text}&output=${output}`;
  return await authHttpClientGet(store, uri);
}

export async function getContractCompanyElementService(store) {
  let uri = "/contract/company";
  return await authHttpClientGet(store, uri);
}

export async function getContractTitleListService(store) {
  let uri = "/contract/title-list";
  return await authHttpClientGet(store, uri);
}

export async function searchContractCompanyElementService(store, queryString) {
  let uri = "/contract/company";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getContractInfoService(store, queryString) {
  let uri = `/contract/info?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getContractComlist(store, queryString) {
  let uri = `/contract/company?${queryString}`;
  return await authHttpClientGet(store, uri);
}

export async function getContractSpecialDriverList(store, tempId) {
  let uri = `/contract/route-template-special-driver?id=${tempId}`;

  return await authHttpClientGet(store, uri);
}

export async function postContractService(store, data) {
  let uri = "/contract/";
  return await authHttpClientPost(store, uri, data);
}

export async function putContractService(store, id, data) {
  let uri = `/contract?id=${id}`;
  return await authHttpClientPut(store, uri, data);
}

export async function deleteContractService(store, id) {
  let uri = `/contract?id=${id}`;
  return await authHttpClientDelete(store, uri);
}
