import { render, staticRenderFns } from "./VehicleCreateCheckListTable.vue?vue&type=template&id=1f1bfb63&scoped=true&"
import script from "./VehicleCreateCheckListTable.vue?vue&type=script&lang=js&"
export * from "./VehicleCreateCheckListTable.vue?vue&type=script&lang=js&"
import style0 from "./VehicleCreateCheckListTable.vue?vue&type=style&index=0&id=1f1bfb63&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f1bfb63",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VCardText,VCol,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VRow,VSubheader})
