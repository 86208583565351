<template>
  <v-navigation-drawer
    absolute
    right
    width="500px"
    :value="isOpen"
    :stateless="true"
  >
    <v-list-item class="px-2" dense v-if="canEdit">
      <v-btn icon @click.stop="$emit('close', false)">
        <v-icon v-if="mini">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
      </v-btn>
      <v-row no-gutters class="pl-4">
        <v-col cols="6">
          <div class="pr-2 my-auto">
            <v-select
              hide-details
              label="กลุ่มเดินรถ"
              outlined
              dense
              :items="driverGroupList"
              item-text="name"
              item-value="_id"
              :append-icon="''"
              @change="groupChangeHandler"
              return-object
            ></v-select>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="my-auto">
            <v-select
              outlined
              dense
              hide-details
              :items="zoneList"
              label="โซน"
              item-text="nameTh"
              item-value="key"
              :value="tableSheetData.contract.zone"
              :append-icon="''"
              @change="zoneChangeHandler"
              return-object
            ></v-select>
          </div>
        </v-col>
      </v-row>

      <!-- <v-spacer></v-spacer> -->

      <v-list-item-action v-if="canEdit">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey darken-1">mdi-download</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :disabled="driverGroupData === null"
              @click="openImportDriverDialogHandler(0)"
            >
              <v-list-item-title>
                {{
                  driverGroupData
                    ? `นำเข้าจากกลุ่ม ${driverGroupData.name}`
                    : "ไม่ได้เลือกกลุ่มผู้ขับ"
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openImportDriverDialogHandler(1)">
              <v-list-item-title>
                นำเข้าจากโซน {{ zoneData.nameTh }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <v-alert
      class="mx-2 my-1"
      color="red"
      dark
      dense
      :value="notFoundImportDriver"
      transition="fade-transition"
    >
      ไม่พบผู้ขับจากนำเข้า
    </v-alert>

    <div class="mx-2 my-1">
      <AssignMultipleJobDriverSearchInput
        v-if="canEdit"
        ref="AssignMultipleJobDriverSearchInputRef"
        @search-item-change="getSearchDriver"
        :table-id="tableId"
        class="my-2"
      />
      <div :class="['d-flex', { 'pt-2': !canEdit && canView }]">
        <v-btn
          icon
          small
          @click.stop="$emit('close', false)"
          v-if="!canEdit && canView"
        >
          <v-icon v-if="mini">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
        <p class="text-app-detail-normal mt-1 mb-0">
          รายชื่อพนักงานจำนวน
          <span class="black--text font-weight-bold">{{
            filtedDriverInCart.length
          }}</span>
          คน
        </p>
        <v-spacer></v-spacer>
        <v-btn
          @click="handleSchedule()"
          class="mx-3"
          color="primary"
          elevation="0"
          light
          small
          outlined
          ><v-icon>mdi-bird</v-icon></v-btn
        >
        <div v-if="canEdit">
          <v-btn
            v-if="isEditMode === 0"
            class="text-app-detail-normal"
            small
            depressed
            rounded
            @click="editDriver()"
          >
            แก้ไข
          </v-btn>
          <v-btn
            v-if="isEditMode === 1"
            color="success"
            class="text-app-detail-normal"
            small
            depressed
            rounded
            @click="saveDriver()"
          >
            <v-icon> mdi-check </v-icon>
            เสร็จ
          </v-btn>
        </div>
      </div>
    </div>

    <v-list
      v-if="fetchDriverInCardStatus >= 0 && !cartLoading"
      class="overflow-y-auto"
      :height="!canEdit && canView ? tableViewHeight : tableViewHeight - 140"
    >
      <v-list-item
        class="pa-0"
        v-for="(driver, dIdx) in filtedDriverInCart"
        :key="`driverInCart-idx-${dIdx}`"
      >
        <v-hover v-slot="{ hover }">
          <v-sheet
            :class="['d-flex pa-1 mx-2', { 'on-hover': hover }]"
            style="
              width: 100%;
              background-color: #ecf1f8;
              border-radius: 3em;
              cursor: pointer;
            "
            @click="openDriverCellChecker(driver)"
          >
            <div class="d-flex">
              <v-avatar size="32">
                <img :src="driver.profileImage" />
              </v-avatar>
              <p :class="['my-1 mx-2']">
                {{ driver.fullName }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div class="my-1 d-flex">
              <p class="mb-0" style="width: 80px">
                วิ่ง: {{ driver.statOnSheet.jobs }} [{{ driver.jobs }}]
              </p>
              <p class="mb-0" style="width: 80px">
                ฟรี: {{ driver.statOnSheet.free }} [{{ driver.free }}]
              </p>
            </div>

            <v-btn
              small
              class="my-auto"
              icon
              @click="removeDriver(driver._id)"
              v-if="
                isEditMode === 1 &&
                driver.status === 0 &&
                driver.disabled === false
              "
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-progress-circular
              v-if="isEditMode === 1 && driver.status === 1"
              indeterminate
              color="primary"
              class="my-auto"
              size="20"
              width="3"
            ></v-progress-circular>
          </v-sheet>
        </v-hover>
      </v-list-item>
    </v-list>
    <div
      v-else
      class="d-flex flex-column justify-center align-center"
      :style="{
        height:
          !canEdit && canView
            ? `${tableViewHeight}px`
            : `${tableViewHeight - 140}px`,
      }"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-auto"
        size="50"
        width="5"
      ></v-progress-circular>
    </div>

    <!-- <div
      class="d-flex fill-height flex-column justify-start align-center"
      v-if="!mini && fetchDriverInCardStatus === 0"
    >
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
    </div> -->
    <div
      class="d-flex fill-height flex-column justify-start align-center"
      v-if="fetchDriverInCardStatus < 0"
    >
      <p class="my-1 mx-2">ไม่สามารถดึงข้อมูลได้</p>
    </div>
    <!-- DIALOG -->
    <v-dialog v-model="combineOrReplaceStatusDialog" width="500">
      <v-card>
        <v-toolbar color="primary" flat dark>
          <v-toolbar-title v-if="selectedGroup === 0"
            >ยืนยันการนำเข้าคนจากกลุ่ม</v-toolbar-title
          >
          <v-toolbar-title v-if="selectedGroup === 1">
            ยืนยันการนำเข้าคนจากโซน {{ zoneData.nameTh }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="
              combineOrReplaceStatusDialog = !combineOrReplaceStatusDialog
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pt-3">
          <v-btn block depressed color="white" @click="saveImportDriver('c')">
            รวมกัน
          </v-btn>
          <v-btn
            class="mt-2"
            block
            depressed
            color="grey lighten-3"
            @click="saveImportDriver('r')"
          >
            แทนที่
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <EmployeeJobDialog ref="employeeJobDialogRef" />
    <AssignMultipleJobDriverCellChecker
      ref="assignMultipleJobDriverCellChecker"
    />
  </v-navigation-drawer>
</template>

<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AssignMultipleJobDriverSearchInput from "./AssignMultipleJobDriverSearchInput.vue";
import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";
import AssignMultipleJobDriverCellChecker from "../dialog/AssignMultipleJobDriverCellChecker.vue";
import moment from "moment";
import {
  deleteDriverFromCartTableService,
  putDriverToCartTableService,
  putImportDriverToCartTableService,
} from "@/services/api/assign_multiple_job";
import { mapGetters } from "vuex";

export default {
  name: "AssignMultipleJobWaitingDriverDrawer",
  data: () => ({
    drawer: false,
    mini: false,
    totalDriver: 0,
    //
    searchingDriver: null,
    fetchDriverInCardStatus: 0,
    //
    isEditMode: 0,
    selectedGroup: null,
    combineOrReplaceStatusDialog: false,
    notFoundImportDriver: false,
    filtedDriverInCart: [],
    employeeDataList: [],
    scheduleDate: null,
    cartLoading: false,
  }),
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      required: true,
    },
    tableViewHeight: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    mini: function (newValue) {
      // console.log("mini", newValue);
      // if (newValue === false) {
      //   this.getData();
      // } else {
      // }
    },
    drawer: function (newValue) {
      // console.log("drawer", newValue);
    },
    driverInCellStat: function () {
      this.filterDriverInCart();
    },
  },
  computed: {
    ...mapGetters({
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    tableSheetData: function () {
      return this.$store.state.assignMultipleJob.tableSheetData[this.tableId];
    },
    zoneData: function () {
      return _.get(
        this.$store.state,
        `assignMultipleJob.tableSheetData.${this.tableId}.contract.zone`,
        []
      );
    },
    driverGroupData: function () {
      return _.get(
        this.$store.state,
        `assignMultipleJob.tableSheetData.${this.tableId}.driverGroup`,
        null
      );
    },
    driverInCellStat: function () {
      return _.get(
        this.$store.state,
        `assignMultipleJob.driverInCellStat.${this.tableId}`,
        []
      );
    },
    zoneList: function () {
      return this.$store.state.assignMultipleJob.zoneList;
    },
    driverGroupList: function () {
      return this.$store.state.assignMultipleJob.driverGroupsList;
    },
    canEdit: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanEdit"];
    },
    canView: function () {
      return this.$store.state["assignMultipleJob"]["isPermissionCanView"];
    },
  },
  methods: {
    // groups
    groupChangeHandler(value) {
      this.$store.dispatch("assignMultipleJob/updateGroupsToTable", {
        tableId: this.tableId,
        driverGroup: value,
      });
    },
    // zone
    zoneChangeHandler(value) {
      this.$store.dispatch("assignMultipleJob/updateZoneToTable", {
        tableId: this.tableId,
        zone: value,
      });
    },

    //
    filterDriverInCart() {
      this.filtedDriverInCart = [];
      this.employeeDataList = [];

      const driverInCell = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCell.${this.tableId}`,
        null
      );

      const _driverInCart = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCart.${this.tableId}`,
        []
      );

      this.employeeDataList = _.cloneDeep(_driverInCart);

      for (let i = 0; i < _driverInCart.length; i++) {
        const driverCellData = _.get(
          driverInCell,
          `${_driverInCart[i]["_id"]}`,
          null
        );
        const driverCellStatData = _.get(
          this.$store.state,
          `assignMultipleJob.driverInCellStat.${this.tableId}.${_driverInCart[i]["_id"]}`,
          {}
        );

        const selectedSumDriver = _.get(
          this.sumDriverStatOnSheet,
          _driverInCart[i]["_id"],
          {
            free: 0,
            jobs: 0,
          }
        );

        if (driverCellData !== null) {
          if (driverCellData["countCell"] > 0) {
            this.filtedDriverInCart.push({
              ..._.cloneDeep(_driverInCart[i]),
              ...driverCellStatData,
              statOnSheet: selectedSumDriver,
              disabled: true,
            });
          } else {
            this.filtedDriverInCart.push({
              ..._.cloneDeep(_driverInCart[i]),
              ...driverCellStatData,
              statOnSheet: selectedSumDriver,
              disabled: false,
            });
          }
        } else {
          this.filtedDriverInCart.push({
            ..._.cloneDeep(_driverInCart[i]),
            ...driverCellStatData,
            statOnSheet: selectedSumDriver,
            disabled: false,
          });
        }
      }
      let sortedDrivers = this.filtedDriverInCart.sort(
        (a, b) => a.jobs - b.jobs
      );
      this.filtedDriverInCart = _.cloneDeep(sortedDrivers);
    },
    processDateSchedule() {
      // console.log("tableSheetData", this.tableSheetData["rows"]);
      let table = this.tableSheetData["rows"];
      this.scheduleDate = null;
      if (table.length > 1) {
        this.scheduleDate = {
          startDate: moment(table[0]["date"]).format("YYYY-MM-DD"),
          endDate: moment(table[table.length - 1]["date"]).format("YYYY-MM-DD"),
        };
      } else if (table.length == 1) {
        this.scheduleDate = {
          startDate: moment(table[0]["date"])
            .add(1, "days")
            .format("YYYY-MM-DD"),
        };
      }
      // console.log("this.scheduleDate", this.scheduleDate);
    },

    async handleSchedule() {
      if (!_.isNil(this.employeeDataList)) {
        let initedEmpList = [];
        this.employeeDataList.forEach((employee) => {
          let fullName = `${
            employee["empId"] ? employee["empId"] + " - " : ""
          } ${employee["dpNameTh"] ? employee["dpNameTh"] : ""} ${
            employee["nickName"] ? "(" + employee["nickName"]["th"] + ")" : ""
          }`;

          if (!initedEmpList.some((e) => e.key === employee["_id"])) {
            initedEmpList.push({
              key: employee["_id"],
              value: fullName,
            });
          }
        });

        this.processDateSchedule();

        let empJob = null;
        if (initedEmpList.length > 0) {
          empJob = await this.$refs.employeeJobDialogRef.open(
            initedEmpList,
            this.scheduleDate
          );
        } else {
          empJob = await this.$refs.employeeJobDialogRef.open();
        }
      }
    },
    editDriver() {
      this.isEditMode = 1;
    },
    saveDriver() {
      this.isEditMode = 0;
    },
    //
    // async getData() {
    //   try {
    //     const queryString = new URLSearchParams({
    //       id: this.tableId,
    //     }).toString();

    //     const responseData = await getDriverToCartTableService(
    //       this.$store,
    //       queryString
    //     );

    //     if (responseData["code"] === 200) {
    //       this.driverInCart = responseData["data"];
    //       this.fetchDriverInCardStatus = 1;
    //     } else {
    //       this.fetchDriverInCardStatus = -1;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     this.fetchDriverInCardStatus = -1;
    //   }
    // },

    getSearchDriver(selectedDriver) {
      this.insertDriverToTable(selectedDriver);
    },

    async insertDriverToTable(selectedDriver) {
      try {
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();

        const responseData = await putDriverToCartTableService(
          this.$store,
          queryString,
          {
            driverId: selectedDriver["_id"],
          }
        );

        if (responseData["code"] === 200) {
          let currentDriverInCart = _.cloneDeep(responseData["data"]);
          currentDriverInCart["status"] = 0;
          this.$store.dispatch("assignMultipleJob/updateDriverInCartToTable", {
            tableId: this.tableId,
            data: currentDriverInCart,
          });
        } else {
          throw "ERROR_TO_PULL_DATA";
        }
      } catch (error) {
        console.error(error);
        this.fetchDriverInCardStatus = -1;
      }
    },

    async removeDriver(driverId) {
      try {
        const queryString = new URLSearchParams({
          id: this.tableId,
          driver: driverId,
        }).toString();

        this.$store.commit("assignMultipleJob/setStatusDriverInCardToTable", {
          tableId: this.tableId,
          data: { driverId: driverId, status: 1 },
        });
        this.filterDriverInCart();

        const responseData = await deleteDriverFromCartTableService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.$store.dispatch(
            "assignMultipleJob/updateDeleteDriverInCardToTable",
            {
              tableId: this.tableId,
              data: { driverId: driverId },
            }
          );
        } else {
          throw "ERROR_TO_DELETE_DATA";
        }
      } catch (error) {
        console.error(error);
        this.$store.commit("assignMultipleJob/setStatusDriverInCardToTable", {
          tableId: this.tableId,
          data: { driverId: driverId, status: 0 },
        });
        this.fetchDriverInCardStatus = -1;
      }
    },
    // zone and group
    openImportDriverDialogHandler(value) {
      this.selectedGroup = value;
      if (this.filtedDriverInCart.length > 0) {
        this.combineOrReplaceStatusDialog = true;
      } else {
        this.saveImportDriver("r");
      }
    },
    async saveImportDriver(mode) {
      // tableId, by=zone, mode=r,
      // this.cartLoading = true;
      this.combineOrReplaceStatusDialog = false;
      this.$emit("active-table-loading", true);
      try {
        const queryString = new URLSearchParams({
          id: this.tableId,
        }).toString();

        let _by = null;
        let _value = null;
        if (this.selectedGroup === 1) {
          _by = "zone";
          _value = this.zoneData.key;
        } else {
          _by = "groups";
          _value = this.driverGroupData._id;
        }

        const reqBody = {
          mode: mode,
          by: _by,
          value: _value,
        };

        const responseData = await putImportDriverToCartTableService(
          this.$store,
          queryString,
          reqBody
        );

        if (responseData["code"] === 200) {
          this.$emit("active-table-data-loading", true);
          if (responseData["data"]["userCount"] === 0) {
            this.notFoundImportDriver = true;
            setTimeout(() => {
              this.notFoundImportDriver = false;
            }, 2000);
          }
        } else {
          throw "PUT_IMPORT_FAIL";
        }
      } catch (error) {
        console.log(error);
      }
      // this.$emit("active-table-loading", false);
      // this.cartLoading = false;
    },
    //
    async openDriverCellChecker(item) {
      if (this.isEditMode) {
        return;
      }
      const isOk = await this.$refs["assignMultipleJobDriverCellChecker"].open(
        this.tableId,
        item
      );
      if (isOk === "OK") {
        this.$emit("active-table-loading", true);
        this.$emit("active-table-data-loading", true);
      }
    },
  },
  mounted() {
    this.filterDriverInCart();
  },
  components: {
    CustomTextInputImprove,
    AssignMultipleJobDriverSearchInput,
    EmployeeJobDialog,
    AssignMultipleJobDriverCellChecker,
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-navigation-drawer__content {
  overflow-y: hidden !important;
}

.v-navigation-drawer__content {
  overflow-y: hidden !important;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
</style>
