<template>
	<v-container name="jobsProgressDetailOnMapFloat" v-if="isPermissionCanView">
		<v-card color="transparent" flat>
			<v-card-text class="pt-0">
				<div class="d-flex">
					<v-spacer></v-spacer>
					<div v-if="displayCheckpoint.length > 0">
						<div class="text-app-title ma-0 pa-0">
							จุดวิ่งรถ
							<span v-if="remainingDuration">
								(ถึงจุดต่อไปภายใน: {{ remainingDuration }})
							</span>
						</div>
					</div>
					<v-spacer></v-spacer>
				</div>

				<div class="d-flex" v-if="displayCheckpoint.length > 0">
					<div
						v-for="(checkpoint, idx) in displayCheckpoint"
						:style="checkpoint.customStyle"
						:key="`cp_${idx}`"
						class="d-flex"
					>
						<v-progress-linear
							v-if="idx !== 0"
							class="mt-2"
							height="20"
							:value="idx === 0 ? 0 : 100"
							striped
							:color="checkpoint.color"
						>
						</v-progress-linear>
						<div>
							<font-awesome-layers full-width class="fa-2x">
								<font-awesome-icon
									icon="fa-solid fa-location-pin"
									:color="checkpoint.markerColor"
								/>
								<font-awesome-layers-text
									:class="'white--text'"
									transform="top-1 shrink-9"
									:value="idx + 1"
								/>
							</font-awesome-layers>
						</div>

						<!-- <span class="text-app-title">{{ idx + 1 }}</span> -->
					</div>
				</div>
				<div v-if="displayCheckpoint.length === 0">
					<p class="text-app-title text-center my-0">จุดวิ่งรถ</p>
					<p class="text-app-detail text-center font-weight-bold my-0">
						ไม่มีจุดวิ่งรถ
					</p>
				</div>
			</v-card-text>
		</v-card>

		<!--  -->
		<v-card flat class="white mt-3">
			<v-row class="px-3" v-if="displayData !== null">
				<v-col cols="2">
					<p class="text-app-title text-center my-0">ความคืบหน้า</p>
					<p class="text-app-detail-large text-center font-weight-bold my-0">
						{{
							displayData.progressPercent === null
								? "-"
								: `${displayData.progressPercent} %`
						}}
					</p>

					<p class="text-app-small text-center my-0">
						{{ displayData.distanceText }}
					</p>
				</v-col>
				<v-col cols="2">
					<p class="text-app-title text-center my-0">ถึงเป้าหมาย</p>
					<p
						class="text-app-detail-large text-center font-weight-bold my-0 success--text"
					>
						{{
							displayData.passedStatusPercent === null
								? "-"
								: `${displayData.passedStatusPercent} %`
						}}
					</p>
					<p
						class="text-app-small text-center my-0"
						v-if="displayData.passedStatusPercent"
					>
						{{ displayData.passedStatusMarker }} :
						{{ displayData.totalMarker }} จุด
					</p>
				</v-col>
				<v-col cols="2">
					<p class="text-app-title text-center my-0">พลาดเป้าหมาย</p>
					<p
						class="text-app-detail-large text-center font-weight-bold my-0 error--text"
					>
						{{
							displayData.missingStatusPercent === null
								? "-"
								: `${displayData.missingStatusPercent} %`
						}}
					</p>

					<p
						class="text-app-small text-center my-0"
						v-if="displayData.missingStatusPercent"
					>
						{{ displayData.missingStatusMarker }} :
						{{ displayData.totalMarker }} จุด
					</p>
				</v-col>
				<v-col cols="2">
					<p class="text-app-title text-center my-0">เวลาที่ใช้</p>
					<p class="text-app-detail-large text-center font-weight-bold my-0">
						{{
							displayData.timeConsume === null
								? "-"
								: `${displayData.timeConsume.toFixed(2)} % `
						}}
					</p>
					<p
						class="text-app-small text-center my-0"
						v-if="displayData.totalCheckpointArrivalDurationText"
					>
						{{
							`${displayData.totalCheckpointArrivalDurationText.value} ${
								displayData.totalCheckpointArrivalDurationText.unit === "m"
									? "นาที"
									: "วินาที"
							}`
						}}
						/
						{{
							`${displayData.totalEstimateDurationText.value} ${
								displayData.totalEstimateDurationText.unit === "m"
									? "นาที"
									: "วินาที"
							}`
						}}
					</p>
					<p v-else class="text-app-small text-center my-0">- นาที / - นาที</p>
				</v-col>
				<v-col cols="2">
					<p class="text-app-title text-center my-0">ความเร็วเฉลี่ย</p>
					<p class="text-app-detail-large text-center font-weight-bold my-0">
						{{ displayData.speed === 0 ? "-" : `${displayData.speed} กม/ชม` }}
					</p>
				</v-col>
				<v-col cols="2">
					<p class="text-app-title text-center my-0">ความเร็วเกินกำหนด</p>
					<p
						class="text-app-detail-large text-center font-weight-bold my-0 danger--text"
					>
						{{
							displayData.overLimitSpeedCount === 0
								? "-"
								: `${displayData.overLimitSpeedCount} ครั้ง`
						}}
					</p>

					<div class="text-center">
						<a color="primary" class="text-app-small" @click="openPenalityTab">
							ดูพฤติกรรม
						</a>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
	import Vue from "vue";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import InputView from "@/components/InputView.vue";
	import DynamicContractElement from "../jobDetail/DynamicContractElement.vue";
	import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
	import SaveJobsFormButton from "../SaveJobsFormButton.vue";
	import {
		FontAwesomeIcon,
		FontAwesomeLayers,
		FontAwesomeLayersText,
	} from "@fortawesome/vue-fontawesome";
	Vue.component("font-awesome-icon", FontAwesomeIcon);
	Vue.component("font-awesome-layers", FontAwesomeLayers);
	Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
	import _ from "lodash";
	import {
		processDistanceText,
		processDurationText,
	} from "@/services/appFuncHelpper";
	import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";
	import { appEncodeSingleURIComponentHelper } from "@/services/appStringHelpper";
	export default {
		name: "JobsProgressDetailOnMapFloatTab",
		data() {
			return {
				isPermissionCanView: true,
				displayCheckpoint: [],
				displayData: null,
				roundAttr: [
					"progressPercent",
					"passedStatusPercent",
					"missingStatusPercent",
				],
			};
		},
		mounted() {
			const jobReport = _.get(this.$store, "state.manageRoute.jobReport", null);
			if (!_.isNil(jobReport)) {
				this.processData(jobReport);
			}
		},
		computed: {
			remainingDuration: function () {
				const duration = _.get(
					this.$store,
					"state.manageRoute.jobDetailData.remainingDuration",
					null
				);

				if (duration !== null) {
					return processDurationText(duration);
				}
				return null;
			},

			jobData: function () {
				return _.get(this.$store, "state.manageRoute.jobData", null);
			},
		},
		watch: {
			"$store.state.manageRoute.jobReport": {
				immediate: false,
				handler(newData) {
					// console.log("watch store state manageRoute jobReport");
					this.processData(newData);
				},
			},
		},
		methods: {
			processData(jobReport) {
				this.displayCheckpoint = [];
				this.displayData = _.cloneDeep(jobReport);
				//
				for (let i = 0; i < this.roundAttr.length; i++) {
					if (!_.isNil(this.displayData[this.roundAttr[i]])) {
						this.displayData[this.roundAttr[i]] =
							this.displayData[this.roundAttr[i]].toFixed(2);
					}
				}

				this.displayData["distanceText"] = `${processDistanceText(
					this.displayData["checkpointActualArrivalDistanceText"]
				)} / ${processDistanceText(
					this.displayData["totalEstimateDistanceText"]
				)}`;

				//
				for (let i = 0; i < jobReport["checkpoints"].length; i++) {
					this.displayCheckpoint.push({
						markerColor:
							jobReport["checkpoints"][i]["status"] === "P"
								? "green"
								: jobReport["checkpoints"][i]["status"] === "M"
								? "red"
								: "blue-grey lighten-5",
						customStyle: `flex-basis: ${
							jobReport["checkpoints"][i]["percent"] * 100.0
						}%; text-align: right`,
						color:
							jobReport["checkpoints"][i]["status"] === "P"
								? "success"
								: jobReport["checkpoints"][i]["status"] === "M"
								? "error"
								: "blue-grey lighten-3",
					});
				}
				// console.log("jobReport", jobReport);
				// console.log(this.displayCheckpoint);
			},
			openPenalityTab() {
				const params = new URLSearchParams({
					job: this.jobData["_id"],
					cjvtext: appEncodeSingleURIComponentHelper(
						`งาน ${this.jobData["jobCode"]} ${this.jobData["name"]}`
					),
				}).toString();

				const goTo = `${window.location.origin}/employee${URL_EMPLOYEE_PENALTY}&${params}`;
				window.open(goTo, "_blank");
			},
		},
		components: {
			CustomTextInputImprove,
			InputView,
			DynamicContractElement,
			CustomSelectsImprove,
			SaveJobsFormButton,
		},
	};
</script>
<style scoped>
	.box {
		width: 20px;
		height: 20px;
		border: 1px solid black;
	}
</style>
