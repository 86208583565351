<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable width="500px" persistent>
      <v-form v-if="show" ref="form" v-model="valid">
        <v-card dark color="#222831" v-if="show">
          <v-toolbar color="#323841" dark>
            <v-card-title> Export Bank-Payroll </v-card-title>
            <v-spacer></v-spacer
            ><v-btn icon @click="cancel()"
              ><v-icon>mdi-window-close</v-icon></v-btn
            ></v-toolbar
          >

          <v-divider></v-divider>
          <v-card-text class="px-5">
            <v-row no-gutters>
              <v-col cols="12" class="mt-3 mb-3">
                <CustomTextInputImprove
                  class="ma-0"
                  ref="vat"
                  :hide-detail="true"
                  v-model="formData.code"
                  title="CODE"
                  placeholder=""
                  :is-dark="true"
                  :isRequired="true"
                  :dense="true"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                ></CustomTextInputImprove
              ></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-2">
                <v-radio-group v-model="formData.hiringType" row dense>
                  <v-radio
                    v-for="item in hiringTypeItems"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3 pt-3 pb-5">
              <v-col cols="12">
                <InputView
                  :isRequired="formData.hiringType === 'ภายนอก' ? true : false"
                  :isDark="true"
                  title="สัญญาเดินรถ"
                >
                  <ContractElement
                    v-model="formData.contractIdList"
                    :disabled="
                      !isPermissionCanEdit || formData.hiringType !== 'ภายนอก'
                    "
                    :dense="true"
                    :isDark="true"
                    label="พิมพ์ค้นหาสัญญาเดินรถ"
                    :rules="
                      formData.hiringType === 'ภายนอก'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                  />
                </InputView>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="px-9"
              @click="saveChange"
              :loading="isSubmiting"
              dark
              elevation="0"
            >
              Download
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <AppOverlay :absolute="false" :isLoading="componentLoading" />
      </v-form>
    </v-dialog>
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="dialogAlert" />
  </v-row>
</template>

<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import InputView from "@/components/InputView.vue";
import ContractElement from "@/views/employee/employee_detail/components/ContractElement.vue";

import PrimaryButtonVue from "@/components/PrimaryButton.vue";
import NormalButtonVue from "@/components/NormalButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import {
  getExportBankPayrollService,
  postCSVService,
} from "@/services/api/worksheet";

export default {
  name: "worksheetExportBankPayrollDialog",

  components: {
    PrimaryButtonVue,
    NormalButtonVue,
    AppDialogConfirm,
    AppOverlay,
    CustomTextInputImprove,
    InputView,
    ContractElement,
  },

  data() {
    return {
      show: false,
      id: null,
      resolve: null,
      reject: null,
      windowHeight: document.documentElement.clientHeight,

      isSubmiting: false,
      componentLoading: false,

      valid: false,
      hiringTypeItems: ["ภายใน", "ภายนอก"],
      formData: {
        code: null,
        hiringType: "ภายใน",
        contractIdList: null,
      },

      permList: {
        admin_all: false,
        payment_list: false,
        payment_crud: false,
        payment_report: false,
        payment_update_status: false,
        payment_update_close_status: false,
        payment_view_total_report: false,
      },

      isPermissionCanEdit: false,
      isPermissionCanView: false,
    };
  },

  watch: {},

  computed: {},

  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },

  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.isSubmiting = false;
      this.componentLoading = false;

      this.formData = {
        code: null,
        hiringType: "ภายใน",
        contractIdList: null,
      };
    },

    open() {
      this.resetFormData();
      this.checkUserPermissions();
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getData() {
      this.componentLoading = true;
      this.componentLoading = false;
    },

    async alertSaveChange() {
      await this.$refs.alertSaving.open(
        "เพิ่มข้อมูลสำเร็จ!",
        null,
        DialogType.POSITIVE,
        this.alertOptions
      );
    },

    async alertErrorDialog() {
      await this.$refs.dialogAlert.open(
        "กรุณาตรวจสอบข้อมูลอีกครั้ง",
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
    },

    getQueryStringParams() {
      let queryParams = null;
      if (this.formData.hiringType === "ภายใน") {
        queryParams = {
          code: this.formData["code"],
          hiringType: this.formData["hiringType"],
          contractIdList: null,
        };
      } else {
        let contractIdListString = "";
        for (let i = 0; i < this.formData["contractIdList"].length; i++) {
          contractIdListString += this.formData["contractIdList"][i]["_id"];
          if (i < this.formData["contractIdList"].length - 1) {
            contractIdListString += ",";
          }
        }
        queryParams = {
          code: this.formData["code"],
          hiringType: this.formData["hiringType"],
          contractIdList: contractIdListString,
        };
      }
      return queryParams;
    },

    processTextCSV(data) {
      let prepareData = data.replaceAll(",#,", ",No.,");
      if (prepareData.includes("\n")) {
        let rowData = prepareData.split("\n");
        for (let i = 1; i < rowData.length - 1; i++) {
          let id = prepareData.split("\n")[i].split(",")[0];
          prepareData = prepareData.replaceAll(`\n${id}`, `\n"=""${id}"""`);
        }
      }
      return prepareData;
    },

    async saveChange() {
      this.isSubmiting = true;
      // console.log("this.excelSting", this.excelSting);
      if (this.$refs.form.validate() === true) {
        let queryStringParams = this.getQueryStringParams();
        let params = new URLSearchParams(queryStringParams).toString();
        // console.log("export params", queryStringParams);
        const respData = await getExportBankPayrollService(this.$store, params);

        if (respData["code"] === 200) {
          let textCSV = this.processTextCSV(respData["data"]);
          let csvContent = "data:text/csv;charset=utf-8," + textCSV;
          let encodedUri = encodeURI(csvContent);

          window.open(encodedUri);
          this.show = false;
          this.resolve(true);
        } else {
        }
      }
      this.isSubmiting = false;
    },

    cancel() {
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
      this.resetFormData();
    },

    getDimensions() {
      this.windowHeight = document.documentElement.clientHeight;
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        // console.log("permission:", this.permList)
        if (this.permList.payment_report === true) {
          this.isPermissionCanView = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.payment_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.excel-preview-table {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#171b21, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    background: transparentize(#171b21, 0.3);
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.excel-preview-table tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
  // min-height: 300px;
}

.excel-preview-table th td {
  // background: #f7faff;
  border: 2px solid #222831;
  background-color: #171b21;
  border-collapse: collapse;
  border-top: 0;
}

// .excel-preview-table tr:nth-child(even) td {
//   background-color: #222831;
// }

.excel-preview-table tr:first-child {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(2) {
  background-color: #171b21;
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr:nth-child(even) td:nth-child(n + 5) {
  background-color: transparentize(#171b21, 0.5);
  border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.excel-preview-table tr td:nth-child(1) {
  background-color: #171b21;
  // border: 2px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 2px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(1) {
//   // background-color: #171b21;
//   z-index: 4;
// }

.excel-preview-table td:nth-child(2) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 104px;
  z-index: 2;
}
// .excel-preview-table th:nth-child(2) {
//   // background-color: #171b21;
//   z-index: 4;
// }
.excel-preview-table td:nth-child(3) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 255px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(3) {
//   // background-color: #171b21;
//   z-index: 4;
// }
.excel-preview-table td:nth-child(4) {
  background-color: #171b21;
  // border: 1px solid #222831;
  // border-top: 0;
  // border-left: 0;
  // border-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 357px;
  z-index: 2;
}

// .excel-preview-table th:nth-child(4) {
//   // background-color: #171b21;
//   z-index: 4;
// }

// .excel-preview-table tr td:first-child {
//   border-left: none;
// }
.table_item_name {
  /* border: 1px solid black; */
  width: 45%;
}

.title_dialog_style {
  font-size: 16px;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  color: #8a96a4;
}

.value_style {
  font-size: 14px;
  font-weight: bold;
  color: #fcfdfd;
}

.clickable-row {
  cursor: pointer;
}

.table_header_text_style {
  font-weight: bold;
  font-size: 14px;
}

.table_body_text_style {
  font-size: 14px;
  color: #c5ccd2;
}

.border_add_button {
  border: 5px dashed red;
}
</style>
