import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

export const jobsMap = {
  namespaced: true,
  state: {
    show: false,
    createGMapActive: false,
    selectedContractId: null,
  },
  getters: {},
  mutations: {
    setShowDialog(state, payload) {
      state.show = payload;
    },

    setCreateGMapActive(state, payload) {
      state.createGMapActive = payload;
    },

    setSelectContractId(state, payload) {
      state.selectedContractId = payload;
    },
  },
  actions: {
    openDialog(context, payload) {
      context.commit("setShowDialog", payload.show);
    },
    closeDialog(context, payload) {
      context.commit("setShowDialog", payload.show);
      context.commit("setCreateGMapActive", false);
    },
    updateCreateGMapActive(context, payload) {
      context.commit("setCreateGMapActive", payload);
    },
    updateSelectedContractId(context, payload) {
      context.commit("setSelectContractId", payload);
    },
  },
  modules: {},
};
