const DialogType = {
  DEFAULT: "default",
  POSITIVE: "positive",
  ERROR: "error",
  DELETE: "delete",
  ACTION: "action",
};

module.exports = {
  DialogType,
};
