<template>
	<!-- <v-btn-toggle v-model="valueInput" dense color="blue accent-2" mandatory>
      <v-btn
        v-for="(value, key) in vehicleFuelStatusObj"
        :value="key"
        :key="key"
      >
        {{ value }}
      </v-btn>
    </v-btn-toggle> -->

	<v-chip-group v-model="valueInput" column mandatory>
		<div class="d-flex flex-nowrap-lg flex-md-wrap flex-sm-wrap flex-wrap">
			<v-chip
				filter
				outlined
				:class="['short', 'mx-0']"
				:key="'NOT_PAID'"
				:value="'NOT_PAID'"
				:color="
					disabledList['NOT_PAID']
						? worksheetStatusCardColor('NOT_PAID')
						: 'subtitle darken-4'
				"
				:disabled="!disabledList['NOT_PAID']"
			>
				<span class="px-1">{{ worksheetStatusListText["NOT_PAID"] }} </span>
			</v-chip>
			<v-icon dark class="mx-0" color="subtitle darken-4">mdi-minus</v-icon>
			<v-chip
				filter
				outlined
				:key="'CUT'"
				:value="'CUT'"
				:class="['short', 'mx-0']"
				:color="
					disabledList['CUT']
						? worksheetStatusCardColor('CUT')
						: 'subtitle darken-4'
				"
				:disabled="!disabledList['CUT']"
			>
				<span class="px-1">{{ worksheetStatusListText["CUT"] }} </span>
			</v-chip>
			<v-icon dark class="mx-0" color="subtitle darken-4">mdi-minus</v-icon>
			<v-chip
				filter
				outlined
				:key="'HOLD'"
				:value="'HOLD'"
				:class="['short', 'mx-0']"
				:color="
					disabledList['HOLD']
						? worksheetStatusCardColor('HOLD')
						: 'subtitle darken-4'
				"
				:disabled="!disabledList['HOLD']"
			>
				<span class="px-1">{{ worksheetStatusListText["HOLD"] }} </span>
			</v-chip>
			<v-icon dark class="mx-0" color="subtitle darken-4">mdi-minus</v-icon>
			<v-chip
				filter
				outlined
				:key="'PAID'"
				:value="'PAID'"
				:class="['short', 'mx-0']"
				:color="
					disabledList['PAID']
						? worksheetStatusCardColor('PAID')
						: 'subtitle darken-4'
				"
				:disabled="!disabledList['PAID']"
			>
				<span class="px-1">{{ worksheetStatusListText["PAID"] }} </span>
			</v-chip>
			<v-icon dark class="mx-0" color="subtitle darken-4">mdi-minus</v-icon>
			<v-chip
				filter
				outlined
				:key="'CANCELLED'"
				:value="'CANCELLED'"
				:class="['short', 'mx-0']"
				:color="
					disabledList['CANCELLED']
						? worksheetStatusCardColor('CANCELLED')
						: 'subtitle darken-4'
				"
				:disabled="!disabledList['CANCELLED']"
			>
				<span class="px-1">{{ worksheetStatusListText["CANCELLED"] }} </span>
			</v-chip>
		</div>
		<!-- <span v-for="(value, key) in _worksheetStatusObj" :key="key" :value="key"> -->

		<!-- </span> -->
	</v-chip-group>
</template>

<script>
	import { mapGetters } from "vuex";
	import _ from "lodash";

	export default {
		name: "WorksheetStatusInput",
		mounted() {},
		props: {
			value: {},
			permission: {
				type: Object,
				default: () => {},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			worksheetOriStatus: {
				default: "",
				type: String,
			},
		},
		data: () => ({
			minAllowList: ["HOLD", "PAYING", "CANCELLED"],
			disabledDefaultList: {
				NOT_PAID: false,
				CUT: false,
				HOLD: false,
				PAID: false,
				CANCELLED: false,
			},
			worksheetStatusListText: {
				NOT_PAID: "ยังไม่จ่าย",
				CUT: "ตัดยอด",
				HOLD: "กำลังพิจารณา",
				PAID: "จ่ายแล้ว",
				CANCELLED: "ยกเลิก",
			},
			worksheetStatusList: ["NOT_PAID", "CUT", "HOLD", "PAID"],
		}),
		computed: {
			...mapGetters({
				worksheetStatusCardColor: "worksheet/worksheetStatusCardColor",
			}),
			_worksheetStatusObj: function () {
				return this.$store.state.worksheet.worksheetStatusText;
			},
			valueInput: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
					this.$emit("on-change", val);
				},
			},
			disabledList: function () {
				const returnData = _.clone(this.disabledDefaultList);

				if (this.disabled) {
					return returnData;
				}

				if (this.permission.admin_all) {
					returnData["PAID"] = true;
					returnData["NOT_PAID"] = true;
					returnData["CUT"] = true;
					returnData["HOLD"] = true;
					returnData["CANCELLED"] = true;
				}

				if (this.permission.payment_update_close_status === true) {
					returnData["PAID"] = true;
					returnData["NOT_PAID"] = true;
					returnData["CUT"] = true;
					returnData["HOLD"] = true;
					returnData["CANCELLED"] = true;
				}

				if (this.permission.payment_update_status === true) {
					returnData["NOT_PAID"] = true;
					returnData["CUT"] = true;
					returnData["HOLD"] = true;
				}

				if (this.worksheetOriStatus === "CANCELLED") {
					for (let i = 0; i < this.worksheetStatusList.length; i++) {
						returnData[this.worksheetStatusList[i]] = false;
					}
				} else {
					let currentStatusIndex = this.worksheetStatusList.indexOf(
						this.worksheetOriStatus
					);
					let nextActivatedIndex = currentStatusIndex + 1;

					if (currentStatusIndex > 0) {
						returnData["NOT_PAID"] = false;
					}

					if (nextActivatedIndex < this.worksheetStatusList.length - 1) {
						for (
							let i = nextActivatedIndex + 1;
							i < this.worksheetStatusList.length;
							i++
						) {
							returnData[this.worksheetStatusList[i]] = false;
						}
					}
				}

				return returnData;
			},
		},
		methods: {},
	};
</script>

<style scoped>
	.long {
		width: 166px;
	}
	.long span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.short {
		width: 133px;
	}
	.short span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
