<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500">
      <v-card>
        <v-card-title class="text-h5"> ชำระค่า GPS </v-card-title>

        <v-card-text>
          <!-- status -->
          <v-col cols="12" class="pt-0 px-0">
            <CustomCardFormViewModern :elevation="0" :isShowTitle="false">
              <v-col class="pt-0 px-0" cols="12">
                <div>
                  <v-chip
                    v-if="docsData.gpsRemainingDay !== null"
                    class="mt-0 pt-1 mx-9 title-input"
                    color="red"
                    label
                    outlined
                  >
                    <v-icon left> mdi-timer-outline </v-icon>
                    ต้องชำระภายใน {{ docsData.gpsRemainingDay }} วัน
                  </v-chip>
                  <v-chip
                    v-if="docsData.gpsExpiryDate !== null"
                    class="mt-2 pt-1 mx-9 title-input"
                    color="warning"
                    label
                    outlined
                  >
                    <v-icon left> mdi-update </v-icon>
                    หมดอายุวันที่
                    {{
                      dateStringToDateFormat(docsData.gpsExpiryDate, {
                        returnThai: false,
                      })
                    }}
                  </v-chip>
                  <v-divider
                    v-if="
                      (docsData.gpsRemainingDay !== null) &
                      (docsData.gpsExpiryDate !== null)
                    "
                    class="my-6"
                  ></v-divider>
                  <CustomTextInput
                    class="mx-6"
                    ref="gpsBrand"
                    v-model="formData.gps"
                    title="GPS ที่ใช้"
                    placeholder="GPS ที่ใช้"
                    :isRequired="false"
                    :value="formData.gps"
                  ></CustomTextInput>
                  <CustomDateTypingPickerFormInput
                    class="mx-6"
                    placeholder="เลือกวันหมดอายุรอบถัดไป"
                    title="วันหมดอายุรอบถัดไป (วัน/เดือน/ปี)"
                    v-model="formData.gpsExpiryDate"
                    :minDate="docsData.gpsExpiryDate"
                    :isRequired="false"
                  />
                </div>
                <!-- <div class="px-6 sub-title-section" v-else>-</div> -->
                <!-- <v-col cols="12" align="center">
                  <DetailButton
                    class="mt-0"
                    @on-click="handleGpsPayment"
                    >ยืนยันการชำระค่า GPS</DetailButton
                  >
                </v-col> -->
              </v-col>
            </CustomCardFormViewModern></v-col
          >

          <!-- <div v-if="formData.status === 'S'">
            <p class="text-app-detail-normal">
              สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "สำเร็จ"
            </p>
            <v-form ref="form">
              <CustomTextInput
                v-model="formData.totalAmount"
                title="ค่าใช้จ่ายทั้งหมด"
                type="number"
                placeholder=""
              />
              <CustomDateTypingPickerFormInput
                title="วันที่ทำรายการ"
                v-model="formData.issueDate"
                :isRequired="false"
              />
              <CustomDateTypingPickerFormInput
                title="วันนัดหมายครั้งถัดไป"
                v-model="formData.appointmentDate"
                :isRequired="false"
              />
            </v-form>
          </div>

          <div v-if="formData.status === 'C'">
            <p class="text-app-detail-normal">
              สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "ยกเลิก"
            </p>
            <v-form ref="form">
              <CustomDateTypingPickerFormInput
                title="วันที่ทำรายการ"
                v-model="formData.issueDate"
                :isRequired="false"
              />
              <CustomTextareaImprove
                v-model="formData.cancelComment"
                title="เหตุผลในการยกเลิก"
              />
            </v-form>
          </div> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import { getDateStringNow } from "@/services/appDate";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { dateStringToDateFormat } from "@/services/appDate";

export default {
  name: "VehicleGpsStatusDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    docsData: {
      gps: null,
      gpsExpiryDate: null,
      gpsRemainingDay: null,
    },
    formData: {
      gps: null,
      gpsExpiryDate: null,
      gpsRemainingDay: null,
    },
  }),
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.formData = {
        gps: null,
        gpsExpiryDate: null,
        gpsRemainingDay: null,
      };
    },
    setFormData(item) {
      this.formData["gps"] = item["gps"];
      this.formData["gpsExpiryDate"] = item["gpsExpiryDate"];
      this.formData["gpsRemainingDay"] = item["gpsRemainingDay"];

      this.docsData = _.clone(this.formData);
    },
    open(item) {
      this.resetFormData();
      this.setFormData(item);
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      let _formData = _.clone(this.formData);
      this.resetFormData();
      this.resolve(_formData);
      this.show = false;
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomDateTypingPickerFormInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
  },
};
</script>

<style scoped></style>
