import VehicleCheckListView from "@/views/vehicle/vehicle_checklist/VehicleCheckListView.vue";
import VehicleCreateCheckListView from "@/views/vehicle/vehicle_checklist/create_checklist/VehicleCreateCheckListView.vue";
export default [
	{
		path: "/vehicle/checklist",
		name: "vehicleCheckList",
		component: VehicleCheckListView,
		meta: { requiresAuth: true, topic: "vehicleCheckList" },
	},
	{
		path: "/vehicle/checklist-create",
		name: "vehicleCreateCheckList",
		component: VehicleCreateCheckListView,
		meta: { requiresAuth: true, topic: "vehicleCreateCheckList" },
	},
];
