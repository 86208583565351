<template>
	<InputView :title="title" :isRequired="isRequired">
		<v-combobox
			class="app-custom-input custom-placeholer-color"
			ref="myCombobox"
			:items="items"
			:loading="loading"
			:readonly="tableLoading"
			item-value="key_system"
			v-model="valueInput"
			@keyup="enterToReturnEmit"
			:placeholder="placeholder"
			append-icon=""
			item-title="key_user"
			return-object
			outlined
			dense
			hide-no-data
			hide-details
			flat
			:background-color="getBackgroundColor()"
			:dark="isDark"
			:disabled="disabled"
		>
		</v-combobox>
	</InputView>
	<!-- hide-no-data -->
	<!--     auto-select-first -->
	<!-- @keyup="enterToReturnEmit" -->
	<!-- @keydown.enter.prevent="blurCombobox" -->
	<!-- @change="itemChange" -->

	<!-- :search-input.sync="searchingAction" -->
</template>
<script>
	// import { getEmployeeBySeachingService } from "@/services/api/employee";
	import _ from "lodash";
	import InputView from "./InputView.vue";
	// import { KEYS } from "@/states/keys";
	// import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

	export default {
		name: "AppAutoCompleteLocalSearching",
		components: {
			InputView,
		},
		props: {
			value: {},
			itemValueKey: {
				default: "key", // _id
				type: String,
			},
			placeholder: {
				type: String,
				default: "",
			},
			enableEnterSearch: {
				default: true,
				type: Boolean,
			},
			isDark: {
				default: false,
				type: Boolean,
			},
			tableLoading: {
				default: false,
				type: Boolean,
			},
			expendMargin: {
				type: Boolean,
				default: true,
			},
			title: {
				type: String,
				default: "",
			},
			items: {
				type: Array,
				default: () => [],
			},
			rules: {
				type: Array,
				default: () => [],
			},

			disabled: {
				type: Boolean,
				default: false,
			},

			isRequired: {
				type: Boolean,
				default: false,
			},
		},
		mounted() {
			// this.getParamQuery();
			// this.itemList = KEYS;
		},
		data: () => ({
			itemList: [],
			loading: false,
			// searchingAction: null,
			currentSearchText: "",
			searchCountTimer: undefined,
			queryOrder: {
				text: "text",
				empid: "empId",
			},
			formData: {
				text: null,
				empId: null,
			},
			firstInit: false,
			selectFromListChecker: false,
		}),
		watch: {},
		computed: {
			valueInput: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
					this.$emit("on-change", val);
				},
			},
			_rules: {
				get() {
					let r = _.clone(this.rules);
					if (this.isRequired) {
						r.push((v) => !!v || "จำเป็นต้องระบุ");
					}
					return r;
				},
				set(val) {},
			},
		},

		methods: {
			getBackgroundColor() {
				// console.log(
				// 	"disabled",
				// 	this.disabled,
				// 	this.$vuetify.theme.themes.light.backgroudDisableInputView
				// );
				if (this.disabled) {
					return this.$vuetify.theme.themes.light.backgroudDisableInputView;
				} else {
					return this.$vuetify.theme.themes.light.backgroundInputView;
				}
			},
			// blurCombobox() {
			//   // Programmatically blur the combobox when Enter key is pressed
			//   this.$refs.myCombobox.blur();
			// },
			// enter
			enterToReturnEmit(event) {
				if (this.enableEnterSearch) {
					if (event.code === "Enter") {
						// console.log("enterToReturnEmit Enter");
						// clearTimeout(this.searchCountTimer);
						this.loading = false;
						this.$refs.myCombobox.blur();
						return;
					}
				}
			},
		},
	};
</script>
<style scoped>
	/* .v-input__icon {
  height: 27px !important;
}
.v-icon.v-icon {
  font-size: 20px !important;
} */
</style>
