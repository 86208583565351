var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (['login', 'logout'].includes(_vm.currentRouteName) !== true)?_c('v-navigation-drawer',{attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"two-line":""}},[_c('v-divider'),_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.profileImageSrc}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
              _vm.$store.getters.userInfo.firstName.th +
              ' ' +
              _vm.$store.getters.userInfo.lastName.th
            )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$store.getters.userInfo.email)}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-cog-outline")])],1)],1)],1)]},proxy:true}],null,false,449941392),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('div',{staticClass:"text-h6 pl-6 py-1 font-weight-bold primary--text text-uppercase"},[_vm._v(" Chokchai aree "),(_vm.showDev)?_c('div',{staticClass:"red-alert"},[_vm._v("!!!Dev Version!!!")]):_vm._e(),(_vm.showTest)?_c('div',{staticClass:"red-alert"},[_vm._v("!!!Test Version!!!")]):_vm._e(),(_vm.showDemo)?_c('div',{staticClass:"red-alert"},[_vm._v("!!!Demo Version!!!")]):_vm._e()]),_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.currentMainMenu),callback:function ($$v) {_vm.currentMainMenu=$$v},expression:"currentMainMenu"}},[_vm._l((_vm.menuList),function(item,index){return [(!item.children)?_c('v-list-item',{key:(index + "-main"),staticClass:"pl-6",class:[
            { 'primary white--text': _vm.currentSubMenu === item.topic } ],attrs:{"to":("" + (item.path) + (item.query)),"link":"","active-class":"primary"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v(" "+_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"custom-text"},[_vm._v(_vm._s(item.title))])],1):_c('v-list-group',{key:(index + "-sub"),attrs:{"value":true,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"pl-2 mr-6"},[_c('v-icon',[_vm._v(" "+_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"custom-text"},[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.children),function(subItem){return _c('v-list-item',{key:subItem.name,class:[
              { 'primary white--text': _vm.currentSubMenu === subItem.topic } ],attrs:{"active-class":"primary","to":("" + (item.path) + (subItem.path)),"dense":"","link":""},on:{"click":function($event){return _vm.refreshKey(subItem.name)}}},[_c('v-list-item-content',[_c('v-list-item-title',{class:[
                  'custom-text',
                  'text-wrap',
                  {
                    'font-weight-bold': subItem.fontEmphasize,
                  } ]},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1),_c('v-list-item-action',[(subItem.fontEmphasize)?_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-star ")]):_vm._e()],1)],1)}),1)]})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }