<template>
  <v-dialog v-model="show" width="500" persistent scrollable>
    <v-card v-if="show">
      <v-card-title class="local-card-text px-3 pt-3">
        <v-spacer></v-spacer>
        <p class="text-app-detail-large white--text mb-1">
          เลือกตารางงานเดินรถ
        </p>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            icon
            dark
            @click="cancelAndCloseHandler"
            v-if="fetchDataError <= 0 || fetchDataError === 1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="fetchDataError === 0">
        <v-row no-gutters>
          <v-col cols="12" class="px-9 pt-9">
            <!-- <InputView title="" :isRequired="false">
              <v-select
                :items="tableTitleList"
                v-model="formData.contractNameValue"
                placeholder="เลือก"
                item-value="key"
                item-text="value"
                outlined
                dense
                filled
                :loading="isLoading"
              ></v-select>
            </InputView> -->

            <v-simple-table class="pb-6">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(table, idx) in tableTitleList"
                    :key="idx"
                    class="clickable-row"
                    @click="openDriverCellChecker(table['key'])"
                  >
                    <td>
                      {{ idx + 1 }}
                    </td>
                    <td>
                      {{ table.value }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
    <AssignMultipleJobDriverCellChecker
      ref="assignMultipleJobDriverCellChecker"
    />
  </v-dialog>
</template>

<script>
import SheetStatusChart from "../chart/SheetStatusChart.vue";
import AssignMultipleJobDriverCellChecker from "../dialog/AssignMultipleJobDriverCellChecker.vue";
import InputView from "@/components/InputView.vue";

import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "SelectTableDialog",
  props: {},
  data: () => ({
    show: false,
    //
    driver: null,

    tableTitleList: [],

    // formData: {
    //   contractNameValue: null,
    // },
    //
    cellData: null,
    onCartDriver: [],
    jobs: [],
    overlapJob: {},
    overlapCell: [],
    // jobs: [
    //   { work: -4, free: 3, name: "นายมนตรี งามอัครกุล (ตรี)" },
    //   { work: -10, free: 2, name: "นายกติกา กองศิล (แชมป์)" },
    //   { work: -15, free: 0, name: "นายผู้ขับสอง มือสอง (สอง)" },
    //   { work: -9, free: 4, name: "นายผู้ขับหนึ่ง มือหนึ่ง (หนึ่ง)" },
    //   { work: -13, free: 2, name: "นายผู้ขับสาม มือสาม (สาม)" },
    // ],
    jobWork: [],
    jobFree: [],
    //
    chartKey: 0,
  }),

  computed: {
    ...mapGetters({
      existingDriverInCellJobStatusById:
        "assignMultipleJob/existingDriverInCellJobStatusById",
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),

    tables() {
      return this.$store.state["assignMultipleJob"]["tableSheet"] || [];
    },
  },

  watch: {
    // "formData.contractNameValue": {
    //   immediate: false,
    //   handler(newVal) {
    //     if (newVal) {
    //       // console.log("tableId:", newVal);
    //       this.openDriverCellChecker(newVal);
    //     }
    //   },
    // },
  },

  methods: {
    open(driver) {
      this.fetchDataError = 1;
      this.resetForm();
      if (!_.isNil(driver)) {
        this.driver = driver;
      }
      // this.tableId = tableId;
      // this.sheetTableData = sheetTableData;
      // this.settingData();

      this.show = true;
      this.fetchData();
      this.fetchDataError = 0;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async fetchData() {
      this.settingData();
      this.fetchDataError = 0;
    },
    settingData() {
      this.isLoading = true;
      // this.tableTitleList = this.tables;
      this.tableTitleList = [];
      let tableSheetData =
        this.$store.state["assignMultipleJob"]["tableSheetData"];
      for (const [key, objValue] of Object.entries(tableSheetData)) {
        if (!_.isNil(objValue["contract"])) {
          this.tableTitleList.push({
            key: key,
            value: `${objValue["contract"]["contractNumber"]} ${objValue["contract"]["title"]}`,
          });
        }
      }
      // console.log("this.tableTitleList", this.tableTitleList);

      this.isLoading = false;
      this.chartKey += 1;
    },

    async openDriverCellChecker(tableId) {
      // console.log("tableId:", tableId, ", driver:", this.driver);
      if (!_.isNil(tableId) && !_.isNil(this.driver)) {
        const isOk = await this.$refs[
          "assignMultipleJobDriverCellChecker"
        ].open(tableId, this.driver);
      }

      // console.log("isOk", isOk);
      // if (isOk === "OK") {
      //   this.$emit("active-table-loading", true);
      //   this.$emit("active-table-data-loading", true);
      // }
    },

    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },

    resetForm() {
      this.driver = null;
      // this.formData = {
      //   contractNameValue: null,
      // };
    },
    // chart handler
  },
  components: {
    SheetStatusChart,
    InputView,
    AssignMultipleJobDriverCellChecker,
  },
};
</script>

<style scoped lang="scss">
.local-card-text {
  background-color: #354253;
}

.clickable-row {
  cursor: pointer;
}
</style>
