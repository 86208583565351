<template>
  <v-card elevation="2" rounded="lg">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <VehicleEventScatterChart
            :data="jobDistance"
            :title="'กำไรสุทธิ (บริษัท) /ระยะทาง'"
            :axis-x-label="'ระยะทางโดยเฉลี่ยต่อวัน'"
            :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
            :height="300"
          />
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="4">
          <VehicleEventScatterChart
            :data="jobDuration"
            :title="'กำไรสุทธิ (บริษัท) /ชั่วโมงทำงาน'"
            :axis-x-label="'ชั่วโมงทำงานโดยเฉลี่ยต่อวัน'"
            :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
            :height="300"
          />
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="4">
          <VehicleEventScatterChart
            :data="jobCount"
            :title="'กำไรสุทธิ (บริษัท) /จำนวนงาน'"
            :axis-x-label="'จำนวนงานโดยเฉลี่ยต่อวัน'"
            :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
            :height="300"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import VehicleEventScatterChart from "./VehicleEventScatterChart.vue";
export default {
  name: "VehicleInformationScatterChart",
  components: {
    VehicleEventScatterChart,
  },
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    this.processData();
  },
  data() {
    return {
      vehicleEventCount: null,
      _id: null,
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      this.vehicleEventCount = _.get(
        this.data,
        "events.vehicleEventCount",
        null
      );
    },
    processOptions(_data, keyField, xField, yField, xAnnoField, yAnnoField) {
      try {
        if (this.$route.params.id !== undefined) {
          this._id = this.$route.params.id;
        }
        let _d = [];
        let _c = [];
        const vehicleJobs = _.get(_data, keyField, []);
        //
        for (let i = 0; i < vehicleJobs.length; i++) {
          let shortCassie = "";
          if (!_.isNil(vehicleJobs[i]["cassieId"])) {
            shortCassie = vehicleJobs[i]["cassieId"].substring(0, 8);
          }
          if (vehicleJobs[i]["vehicleId"] === this._id) {
            _c.push("#567DF4");
          } else {
            _c.push("#AAB7B8");
          }
          _d.push({
            name: `${vehicleJobs[i]["licenseNumber"]} ${shortCassie}`,
            data: [[vehicleJobs[i][xField], vehicleJobs[i][yField]]],
          });
        }

        //
        return {
          data: _d,
          options: {
            colors: _c,
            annoYaxis: [
              {
                y: _.get(_data, yAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
            annoXaxis: [
              {
                x: _.get(_data, xAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
          },
        };
      } catch (error) {
        return { data: [], options: [] };
      }
    },
  },
  computed: {
    jobDistance() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "vehicleJobs",
          "normalizeDistance",
          "normalizeNetProfit",
          "vehicleDistanceJobAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
    jobDuration() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "vehicleJobs",
          "normalizeDuration",
          "normalizeNetProfit",
          "vehicleJobDurationAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
    jobCount() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "vehicleJobs",
          "normalizeCountJob",
          "normalizeNetProfit",
          "vehicleJobCountJobAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
  },
};
</script>

<style scoped>
.custom-text-inline-height {
  line-height: 1;
}
</style>
