<template>
  <v-autocomplete
    class="app-custom-input"
    :items="itemList"
    :loading="loading"
    :readonly="tableLoading"
    :search-input.sync="searchingAction"
    :item-value="itemValueKey"
    v-model="modelVal"
    @change="itemChange"
    :placeholder="placeholder"
    prepend-inner-icon="mdi-magnify"
    item-text="value"
    return-object
    filled
    dense
    flat
    height="44"
    loader-height="4"
    outlined
    no-data-text="ไม่พบลูกค้า ลองใหม่อีกครั้ง"
    :hide-no-data="showNoData"
    chips
  >
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import { getRegisteredAppCustomerSearchService } from "@/services/api/jobs_registered_customer";

export default {
  name: "AppCustomerDetailSearchInput",
  props: {
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    tableLoading: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      queryOrder: {
        text: "text",
      },
      formData: {
        text: null,
      },
      placeholder: "ค้นหาลูกค้าโดยอีเมลล์",
      modelVal: null,
      itemList: [],
      loading: false,
      searchingAction: null,
      currentSearchText: "",
      searchCountTimer: undefined,
      firstInit: false,
      selectFromListChecker: false,
      showNoData: true,
    };
  },

  watch: {
    searchingAction(val) {
      this.showNoData = true;
      if (!_.isNil(val) && this.firstInit && !this.selectFromListChecker) {
        this.currentSearchText = val;
        if (val.length > 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1500);
        }
      }
      this.firstInit = true;
      this.selectFromListChecker = false;
    },
  },

  methods: {
    // getParamQuery() {
    //   Object.keys(this.queryOrder).forEach((key) => {
    //     if (!_.isNil(this.$route.query[key])) {
    //       switch (key) {
    //         case "text":
    //           this.formData[this.queryOrder[key]] =
    //             appDecodeSingleURIComponentHelper(this.$route.query[key]);
    //           break;
    //         default:
    //           this.formData[this.queryOrder[key]] = this.$route.query[key];
    //       }
    //     }
    //   });

    //   if (!_.isNil(this.formData["text"])) {
    //     this.modelVal = this.formData["text"];
    //   }
    // },
    async callApi(val) {
      let responseData = await getRegisteredAppCustomerSearchService(
        this.$store,
        val
      );
      if (responseData["code"] === 200) {
        this.itemList = responseData["data"];
        if (this.itemList.length === 0) {
          this.showNoData = false;
        }
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },
    itemChange() {
      this.selectFromListChecker = true;
      this.$emit("searchItemChange", this.modelVal);
      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },
  },
  mounted() {
    // this.getParamQuery();
  },
  components: {},
  computed: {},
};
</script>

<style scoped></style>
