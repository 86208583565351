const appEncodeURIComponentHelper = (objectData) => {
  let returnObject = {};
  for (const [key, value] of Object.entries(objectData)) {
    returnObject[key] = encodeURIComponent(value);
  }
  return returnObject;
};

const appDecodeSingleURIComponentHelper = (value) => {
  return decodeURIComponent(value);
};

const appEncodeSingleURIComponentHelper = (value) => {
  return encodeURIComponent(value);
};

module.exports = {
  appEncodeURIComponentHelper,
  appEncodeSingleURIComponentHelper,
  appDecodeSingleURIComponentHelper,
};
