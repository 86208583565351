<template>
  <v-dialog v-model="show" width="20%" :persistent="true">
    <v-card class="card-bg">
      <AppOverlay :absolute="false" :isLoading="componentLoading" />

      <v-app-bar elevation="0" class="card-bg" color="transparent">
        <v-toolbar-title class="text-h6"> เพิ่มรายการอื่นๆ </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="cancelStatus()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form ref="recompenseForm" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12">
              <v-radio-group v-model="formData.type" row class="my-1">
                <template v-slot:label> ประเภท: </template>
                <v-radio label="หักเงิน" :value="0"></v-radio>
                <v-radio label="เพิ่มเงิน" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <CustomTextInputImprove
                ref="title"
                v-model="formData.title"
                placeholder=""
                title="ชื่อรายการ"
                :is-required="true"
                :disabled="!isPermissionCanEdit"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :input-view-bottom-margin="false"
                :expend-margin="false"
              >
              </CustomTextInputImprove>
            </v-col>
            <v-col cols="12">
              <CustomTextInputImprove
                ref="amount"
                v-model="formData.amount"
                placeholder="หน่วยบาท"
                title="จำนวนเงิน"
                :is-required="true"
                :disabled="!isPermissionCanEdit"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                inputType="floatNumberWithOutSign"
              ></CustomTextInputImprove>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
        <v-btn color="primary" text @click="processBeforeSave"> บันทึก </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppOverlay from "@/components/AppOverlay.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import AppFormCardFooter from "@/components/AppFormCardFooter.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";

export default {
  name: "RecompenseOtherInputAndExpensesDialog",
  props: {},
  components: {
    AppOverlay,
    CustomTextInput,
    AppFormCardFooter,
    CustomTextInput,
    CustomTextInputImprove,
  },
  mounted() {},
  data() {
    return {
      formData: {
        title: null,
        type: 0,
        amount: null,
      },
      componentLoading: false,
      show: false,
      // form data
      valid: null,
      resolve: null,
      reject: null,
      isPermissionCanEdit: true,
    };
  },
  methods: {
    open() {
      this.show = true;
      this.resetForm();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    processBeforeSave() {
      if (this.$refs.recompenseForm.validate() === true) {
        let returnData = _.cloneDeep(this.formData);
        try {
          returnData["amount"] = parseFloat(returnData["amount"]);
        } catch (error) {
          returnData["amount"] = null;
        }
        this.saveStatus(returnData);
      } else {
        console.error("JOB_RECOMPENSE_UPDATE_FAILED");
      }
    },

    resetForm() {
      this.formData = {
        title: null,
        type: 0,
        amount: null,
      };
    },

    saveStatus(returnData) {
      this.resolve(returnData);
      this.$refs.recompenseForm.reset();
      this.resetForm();
      this.show = false;
    },
    cancelStatus() {
      this.resolve(null);
      this.$refs.recompenseForm.reset();
      this.resetForm();
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
