<template>
	<CustomCardFormViewModern
		title="กำไรสุทธิโดยเฉลี่ยต่อเดือน"
		:is-show-title="true"
		:is-add-title-margin="true"
	>
		<div class="d-flex flex-column flex-md-row">
			<div :class="widthClass">
				<apexchart
					:series="series"
					:options="chartOptions"
					:height="400"
				></apexchart>
				<span class="mx-12 text-app-small-detail font-weight-bold black--text">
					รายจ่าย(บาท)
				</span>
			</div>
			<v-divider vertical class="mx-4"></v-divider>
			<div :class="widthClass">
				<v-row>
					<v-col cols="4">
						<p class="font-weight-bold mb-1">รถมีกำไร</p>
						<span class="dashboard-text-sm font-weight-bold black--text">
							{{ passCount || "-" }}
						</span>
						<span class="text-app-detail-normal">
							คันจาก {{ passCount + noPassCount || "" }} คัน</span
						>
					</v-col>
					<v-col cols="4">
						<p class="font-weight-bold mb-1">คิดเป็น</p>
						<span class="dashboard-text-sm font-weight-bold black--text">
							{{ breakEventPointPercent || "-" }}
						</span>
						<span class="text-app-detail-normal"> %</span>
					</v-col>
					<v-col cols="4">
						<p class="font-weight-bold mb-1">กำไรสุทธิโดยเฉลี่ย</p>
						<span class="dashboard-text-sm font-weight-bold black--text">
							{{ avgProfit || "-" }}
						</span>
						<span class="text-app-detail-normal">บาท</span>
						<p class="text-app-detail-normal">(ใน {{ numDays || "-" }} วัน)</p>
					</v-col>
				</v-row>
				<v-divider class="my-0"></v-divider>
				<!-- <v-row>
					<v-col cols="6">
						<div>
							<p class="mb-0 font-weight-bold">ค่าเป้าหมายสุทธิต่อ 1 คัน</p>
							<CustomTextInputCurrency
								v-model="objectiveValue"
								title=""
								:expend-margin="false"
								type="number"
								:hide-detail="true"
							/>
						</div>
					</v-col>
					<v-col cols="6">
						<div>
							<p class="mb-2 font-weight-bold">คิดเป็น</p>
							<p class="dashboard-text-sm font-weight-bold black--text mb-0">
								{{ objectiveResult || "-" }} เดือน
							</p>
							<div v-if="objectiveRawResult">
								<p v-if="objectiveRawResult < 0" class="red--text mb-0">
									ไม่สามารถผ่านจุดคุ้มทุน
								</p>
							</div>
						</div>
					</v-col>
				</v-row>
				<v-divider class="my-4"></v-divider> -->
				<v-row>
					<v-col cols="12">
						<VehicleIncomeRatioBarChart :data="processedVeData" />
					</v-col>
				</v-row>
			</div>
		</div>
	</CustomCardFormViewModern>
</template>

<script>
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import VueApexCharts from "vue-apexcharts";
	import {
		displayNumberFixedPointHelper,
		displayNumberLocaleString,
		numberWithCommasHelper,
	} from "@/services/appNumberHelper";
	// import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
	import VehicleIncomeRatioBarChart from "./VehicleIncomeRatioBarChart.vue";
	import { nFormatterHelper } from "@/services/appSummaryHelper";
	export default {
		name: "VehicleBreakEvenPointChart",
		props: {
			data: {
				required: true,
			},
			numDays: {
				type: Number,
				default: null,
			},
		},
		data() {
			return {
				series: [],
				profitMatch: {},
				axisMaxValue: 0,
				// objectiveValue: 100000,
				// objectiveRawResult: undefined,
				// objectiveResult: undefined,
				currentMarginRatio: undefined,
				breakEventPointPercent: undefined,
				passCount: undefined,
				noPassCount: undefined,
				monthRound: 30,
				avgProfit: undefined,
				processedVeData: [],
			};
		},
		watch: {
			data: function (newValue) {
				this.processData();
			},
			// objectiveValue: function (newValue) {
			// 	this.calculateObjective(newValue);
			// },
		},
		computed: {
			chartOptions() {
				return {
					chart: {
						type: "line",
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
						events: {
							click: (event, chartContext, config) => {
								const selectedVehicle = _.get(
									this.profitMatch,
									`${config.seriesIndex}_${config.dataPointIndex}`,
									null
								);

								if (selectedVehicle !== null) {
									const routeData = this.$router.resolve({
										name: "vehicleDashboard",
										params: { id: selectedVehicle["vehicleId"] },
									});
									window.open(routeData.href, "_blank");
								}
							},
						},
					},
					legend: { show: false },
					grid: {
						show: false,
					},
					colors: ["#49c496"],
					markers: {
						colors: ["#AAB7B8", "#49c496", ""],
						size: [7, 7, 0],
					},
					stroke: {
						width: [0, 0, 2],
						curve: "straight",
						dashArray: [0, 0, 5],
					},
					tooltip: {
						x: {
							show: false,
							formatter: (value, opts) => {
								return `รายจ่าย ${displayNumberLocaleString(value, 2)} บาท`;
							},
						},
						y: {
							show: true,
							format: "HH:mm",
							formatter: (value, opts) => {
								const selectedVehicle = _.get(
									this.profitMatch,
									`${opts.seriesIndex}_${opts.dataPointIndex}`,
									null
								);
								let name = "";
								let expense = "";
								if (selectedVehicle !== null) {
									name = selectedVehicle["licenseNumber"];
									expense =
										displayNumberLocaleString(selectedVehicle["expense"], 2) ||
										0;
								}
								return `${name} | รายได้ ${displayNumberLocaleString(
									value,
									2
								)} | รายจ่าย ${expense} `;
							},
						},
					},
					xaxis: {
						min: 0,
						max: this.axisMaxValue,
						tickAmount: 10,
						// title: {
						//   text: "รายจ่าย (บาท)",
						// },
						labels: {
							formatter: (value, opts) => {
								return `${nFormatterHelper(value)}`;
							},
						},
						tooltip: {
							enabled: false,
						},
						crosshairs: {
							show: false,
						},
					},
					yaxis: {
						min: 0,
						max: this.axisMaxValue,
						tickAmount: 10,
						axisBorder: {
							show: true,
							offsetX: 0,
							offsetY: 0,
						},
						axisTicks: {
							show: true,
							borderType: "solid",
							width: 6,
							offsetX: 0,
							offsetY: 0,
						},
						title: {
							text: "รายได้ (บาท)",
							offsetY: -120,
						},
						labels: {
							formatter: (value, opts) => {
								return `${nFormatterHelper(value, 0)}`;
							},
						},
					},
				};
			},

			widthClass() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
					case "sm":
						return "bep-width-100";
					case "md":
					case "lg":
					case "xl":
						return "bep-width-50";
				}
			},
		},
		methods: {
			// calculateObjective(value) {
			// 	const _v = _.toNumber(value);

			// 	if (!_.isNaN(_v)) {
			// 		if (this.currentMarginRatio === null) {
			// 			this.objectiveResult = undefined;
			// 		}

			// 		this.objectiveRawResult =
			// 			_.divide(
			// 				_.divide(_v, this.currentMarginRatio) || 0,
			// 				this.monthRound
			// 			) || 0;

			// 		this.objectiveResult = displayNumberFixedPointHelper(
			// 			this.objectiveRawResult,
			// 			2
			// 		);
			// 	}
			// },
			processData() {
				console.log("data", this.data);
				if (!_.isNil(this.data)) {
					// amount , length
					let passList = [];
					let noPassList = [];
					let valueList = [];

					let passListVeh = [];
					let noPassListVeh = [];
					this.currentMarginRatio = _.get(this.data, "rawDayToObjective", null);
					let _processedVeData = [];
					for (let i = 0; i < this.data["vehicleDataList"].length; i++) {
						const _v = this.data["vehicleDataList"][i];
						let pass = false;

						if (_v["income"] >= _v["expense"]) {
							passList.push([_v["expense"], _v["income"]]);
							passListVeh.push(_v);
							pass = true;
						} else {
							noPassList.push([_v["expense"], _v["income"]]);
							noPassListVeh.push(_v);
						}

						valueList.push(_v["income"], _v["expense"]);
						_processedVeData.push({
							..._v,
							pass: pass,
						});
					}

					this.processedVeData = _processedVeData;

					for (let i = 0; i < noPassList.length; i++) {
						this.profitMatch[`0_${i}`] = noPassListVeh[i];
					}

					for (let i = 0; i < passList.length; i++) {
						this.profitMatch[`1_${i}`] = passListVeh[i];
					}

					let _maxValue = _.max(valueList) || 0;

					this.series = [
						{
							name: "ไม่ผ่าน",
							type: "scatter",
							data: noPassList,
						},
						{
							name: "ผ่าน",
							type: "scatter",
							data: passList,
						},
						{
							name: "divier",
							type: "line",
							data: [
								[0, 0],
								[_maxValue, _maxValue],
							],
						},
					];

					setTimeout(() => {
						if (passList.length > 0 && noPassList.length > 0) {
							this.axisMaxValue = _maxValue;
						}
					}, 3000);

					this.passCount = passList.length;
					this.noPassCount = noPassList.length;

					this.breakEventPointPercent = displayNumberFixedPointHelper(
						_.multiply(
							_.divide(passList.length, this.data["vehicleDataList"].length) ||
								0,
							100
						),
						2
					);

					this.avgProfit = displayNumberLocaleString(
						_.divide(
							_.sumBy(this.data["vehicleDataList"], "profit"),
							this.data["vehicleDataList"].length
						) || 0,
						2
					);

					console.log("currentMarginRatio", this.currentMarginRatio);

					//
					// if (this.currentMarginRatio !== null) {
					// 	this.calculateObjective(this.objectiveValue);
					// }
				}
			},
		},
		components: {
			apexchart: VueApexCharts,
			CustomCardFormViewModern,
			// CustomTextInputImprove,
			CustomTextInputCurrency,
			VehicleIncomeRatioBarChart,
		},
		mounted() {
			this.processData();
		},
	};
</script>

<style scoped>
	.bep-width-100 {
		width: 100%;
	}

	.bep-width-50 {
		width: 50%;
	}
</style>
