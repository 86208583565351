<template>
  <div name="customSelects" class="px-3">
    <InputView :title="title" :isRequired="isRequired">
      <v-select
        v-model="valueInput"
        v-on:input="updateValue()"
        class="app-custom-input custom-placeholer-color"
        :items="items"
        placeholder="เลือก"
        :disabled="disabledInput"
        :background-color="getBackgrounddColor()"
        dense
        outlined
        no-data-text="ไม่มีข้อมูลในตาราง"
        :rules="rulesItems"
      ></v-select>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "customSelects",

  components: {
    InputView,
  },

  props: {
    items: [],
    value: String,
    placeholder: String,
    title: String,
    isRequired: Boolean,
    disabled: Boolean,
    rules: null,
  },
  data() {
    return {
      valueInput: this.value,
      rulesDefault: this.isRequired ? [(v) => !!v || "จำเป็นต้องระบุ"] : [],
      rulesItems: this.rulesDefault,
      disabledInput: this.disabled,
    };
  },
  watch: {
    value(newValue) {
      if (newValue != undefined && newValue != null) {
        this.valueInput = newValue;
      }
    },
    rules() {
      this.rulesItems = this.rules;
    },
    valueInput() {
      if (!this.rules) {
        this.rulesItems = this.rulesDefault;
      }
    },
    disabled() {
      this.disabledInput = this.disabled;
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.valueInput);
      this.$emit("on-change", this.valueInput);
    },
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
  components: { InputView },
};
</script>
<style></style>
