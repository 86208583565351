<template>
  <InputView :title="title" :isRequired="isRequired">
    <v-combobox
      :class="'app-custom-input custom-placeholer-color'"
      v-model="valueInput"
      :items="itemList"
      :loading="loading"
      :search-input.sync="searching"
      :label="label"
      :placeholder="placeholder"
      return-object
      hide-selected
      :hide-no-data="hideText"
      outlined
      item-value="_id"
      :multiple="isMultiple"
      :chips="isChips"
      dense
      append-icon=""
      @keyup="enterToReturnEmit"
      :disabled="disabled"
      :rules="rules"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              ไม่มีข้อมูล "<strong>{{ searching }}</strong
              >" กด <kbd>enter</kbd> เพื่อเพิ่มใหม่
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </InputView>
  <!-- @keyup="enterToReturnEmit" -->
  <!-- prepend-inner-icon="mdi-magnify" -->
  <!-- :item-value="itemValueKey" -->
  <!--  item-text="value" -->
</template>

<script>
import { searchVehicleEventReportElementService } from "@/services/api/vehicle_event";
import InputView from "@/components/InputView.vue";
import _ from "lodash";

export default {
  name: "DynamicReportElement",
  components: { InputView },

  props: {
    value: {},
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    column: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    isChips: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    itemList: [],
    loading: false,
    searching: null,
    currentSearchText: "",
    searchCountTimer: undefined,
    queryList: [],
    hideText: true,
  }),
  watch: {
    searching(val) {
      if (!_.isNil(val) && _.isString(val)) {
        this.currentSearchText = val;
        if (val.length >= 2) {
          clearTimeout(this.searchCountTimer);
          this.loading = true;
          this.searchCountTimer = setTimeout(() => {
            this.callApi(val); // call api function
          }, 1500);
        } else {
          this.hideText = true;
        }
      } else {
        this.hideText = true;
      }
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async callApi(val) {
      //
      let resp_data = await searchVehicleEventReportElementService(
        this.$store,
        new URLSearchParams({ column: this.column, text: val }).toString()
      );

      if (resp_data.code === 200) {
        this.itemList = resp_data.data;
        this.hideText = false;
      } else {
        this.itemList = [];
        this.hideText = true;
      }
      this.loading = false;
    },

    enterToReturnEmit(event) {
      if (event.code == "Enter") {
        clearTimeout(this.searchCountTimer);
        this.loading = false;
        this.hideText = true;
        this.itemList = [];
        return;
      } else if (event.code == "Backspace") {
        this.hideText = true;
      }
    },
  },

  mounted() {},
};
</script>

<style scope></style>
