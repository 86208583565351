<template>
  <div name="customTextInput" class="px-3">
    <InputView :title="title" :isRequired="isRequired">
      <v-text-field
        class="app-custom-input custom-placeholer-color"
        v-model="valueInput"
        v-on:input="updateValue()"
        :placeholder="placeholder"
        :type="typeInput"
        :background-color="getBackgrounddColor()"
        :disabled="disabledInput"
        :rules="rulesItems"
        :append-icon="appendIcon"
        :readonly="readOnlyInput"
        @click:append="clickAppend()"
        outlined
        :required="isRequired"
        dense
        @keypress="filterTextInput"
        :autofocus="autofocusInput"
      ></v-text-field>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";

export default {
  name: "customTextInput",

  components: {
    InputView,
  },

  props: {
    value: String,
    placeholder: String,
    title: String,
    isRequired: Boolean,
    type: String,
    disabled: Boolean,
    readonly: Boolean,
    autofocus: Boolean,
    rules: null,
  },
  data() {
    return {
      valueInput: this.value,
      isAppendIconShow: false,
      typeInput: this.type,
      appendIcon: null,
      disabledInput: this.disabled,
      readOnlyInput: this.readonly,
      rulesDefault: this.isRequired ? [(v) => !!v || "จำเป็นต้องระบุ"] : [],
      rulesItems: this.rulesDefault,
      autofocusInput: this.autofocus,
    };
  },
  created() {
    this.setAppendIcon();
  },

  watch: {
    autofocus() {
      this.autofocusInput = this.autofocus;
    },

    rules() {
      this.rulesItems = this.rules;
    },

    value(newValue) {
      if (newValue != undefined && newValue != null) {
        this.valueInput = newValue;
      }
    },

    disabled() {
      this.disabledInput = this.disabled;
    },

    readonly() {
      this.readOnlyInput = this.readonly;
    },

    valueInput() {
      if (!this.rules) {
        this.rulesItems = this.rulesDefault;
      }
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.valueInput);
    },
    clickAppend() {
      if (this.type == "password") {
        this.isAppendIconShow = !this.isAppendIconShow;
        this.typeInput = this.isAppendIconShow ? "text" : "password";
        this.appendIcon = this.isAppendIconShow ? "mdi-eye" : "mdi-eye-off";
      }
    },
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
    setAppendIcon() {
      if (this.type == "password") {
        this.appendIcon = "mdi-eye-off";
      }
      return null;
    },
    filterTextInput(evt) {
      if (this.type == "id" || this.type == "salary") {
        evt = evt ? evt : window.event;
        let expect = null;

        if (evt.key !== undefined) {
          expect = evt.target.value.toString() + evt.key.toString();
        } else {
          expect = evt.target.value.toString();
        }
        if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
          evt.preventDefault();
        }
      } else {
        return true;
      }
    },
  },
  components: { InputView },
};
</script>
<style></style>
