<template>
  <v-row no-gutters>
    <v-col cols="12" class="px-6 pb-6">
      <v-divider class="my-3" />
      <v-data-table
        :headers="tableHeaders"
        :items="dataList"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        :hide-default-footer="true"
        class="app-datatable"
        @click:row="handleClick"
      >
        <template v-slot:[`item.title`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title"
                v-if="item.title !== null"
              >
                {{ item.contractNumber }}<br />
                <span class="font-weight-bold">
                  {{ item.title }}
                </span>
              </v-list-item-title>
              <span v-else>-</span>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";

import { displayNumberLocaleString } from "@/services/appNumberHelper";

export default {
  name: "ContractIncomeAndExpensesTable",
  props: {
    data: {
      required: true,
    },
  },
  computed: {
    tableHeaders() {
      let defaultdata = [
        {
          text: "รหัส/ชื่อสัญญา",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "จำนวนผู้ขับร่วม",
          value: "totalDriver",
          sortable: true,
          align: "left",
          width: "10%",
        },
        {
          text: "รายได้เฉลี่ยต่อคน",
          align: "start",
          sortable: true,
          value: "avgIncome",
          width: "10%",
        },
        {
          text: "จำนวนงานที่ได้รับเฉลี่ยต่อคน",
          align: "start",
          sortable: true,
          value: "avgJobs",
          width: "10%",
        },
        {
          text: "รายได้เฉลี่ยต่องาน",
          align: "start",
          sortable: true,
          value: "avgIncomeByJobs",
          width: "10%",
        },
        {
          text: "ระยะทางที่วิ่งเฉลี่ยต่อคนต่อวัน (กม)",
          value: "avgDistance",
          sortable: true,
          align: "left",
          width: "11%",
        },
        {
          text: "รายได้เฉลี่ยต่อ 1 กม.",
          align: "start",
          sortable: true,
          value: "averageIncomeByDistance",
          width: "10%",
        },
        {
          text: "เวลาที่วิ่งเฉลี่ยต่อคนต่อวัน (ชม)",
          align: "start",
          sortable: true,
          value: "avgDuration",
          width: "10%",
        },
        {
          text: "รายได้เฉลี่ยต่อ 1 ชม.",
          align: "start",
          sortable: true,
          value: "averageIncomeByDuration",
          width: "10%",
        },
      ];

      return defaultdata;
    },
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  data() {
    return {
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      currentData: null,
      templateMap: {
        contractNumber: "",
        title: "",
        totalDriver: 0,
        avgIncome: 0,
        avgJobs: 0,
        avgDistance: 0,
        avgDuration: 0,
      },
      dataList: [],
    };
  },
  methods: {
    handleClick(value) {
      this.$emit("open-chart-info", _.get(value, "_id", null));
    },
    processData() {
      this.dataList = [];
      if (!_.isNil(this.data)) {
        if (!_.isEmpty(this.data["table"])) {
          this.currentData = this.data["table"];
          _.forEach(this.currentData, (value, key) => {
            this.dataList.push({
              _id: key,
              contractNumber: _.get(value, "contractNumber", "-"),
              title: _.get(value, "title", "-"),
              totalDriver: _.get(value, "totalDriver", "-", true),
              avgIncome:
                displayNumberLocaleString(value["averageIncome"], 2) || "-",
              avgJobs:
                displayNumberLocaleString(value["averageJobCount"], 2) || "-",
              avgIncomeByJobs:
                displayNumberLocaleString(
                  value["averageIncomeByJobCount"],
                  2
                ) || "-",
              avgDistance:
                displayNumberLocaleString(value["averageDistance"], 2) || "-",
              avgDuration:
                displayNumberLocaleString(value["averageDuration"], 2) || "-",
              averageIncomeByDistance:
                displayNumberLocaleString(
                  value["averageIncomeByDistance"],
                  2
                ) || "-",
              averageIncomeByDuration:
                displayNumberLocaleString(
                  value["averageIncomeByDuration"],
                  2
                ) || "-",
            });
          });
        }
      }
    },
  },
  mounted() {
    this.processData();
  },
};
</script>
