<template>
	<div :class="[{ white: !isPermissionCanView }]">
		<v-container v-if="isPermissionCanView" fluid class="pb-6">
			<v-sheet class="rounded card-symbol" width="100%">
				<v-card flat class="pa-3 d-flex flex-column">
					<v-row>
						<v-col cols="3">
							<VehicleCreateCheckListType
								:checklistTypeItems="checklistTypeItems"
								:isItemsReOrder="isItemsReOrder"
								@selectedType="processSelectedType"
								@newTypeOnSubmit="processNewTypeOnSubmit"
								@isReOrderTypes="processReOrderTypes"
								@typeOnChange="processTypeOnChange"
								@typeOrderOnChange="processTypeOrderList"
								@activeOnChange="processTypeOnChange"
							/>
						</v-col>
						<v-divider vertical />
						<v-col cols="4">
							<VehicleCreateCheckListTable
								:checklistItems="checklistItems"
								:selectedType="selectedType"
								:isTypesReOrder="isTypesReOrder"
								@is-reorder="processReOrderItems"
								@selectedChecklist="processSelectedChecklist"
								@orderListOnChange="processOrderList"
						/></v-col>
						<v-divider vertical />
						<v-col cols="5">
							<VehicleCreateCheckListManage
								:checklistInfo="checklistInfo"
								:selectedType="selectedType"
								:selectedTypeName="selectedTypeName"
								:isItemsReOrder="isItemsReOrder"
								:isTypesReOrder="isTypesReOrder"
								@checklistManageSaved="processCheckListTypeItems"
							/>
						</v-col>
					</v-row>
				</v-card>
			</v-sheet>
		</v-container>
		<AppOverlay :absolute="false" :isLoading="isLoading" />
		<AppPageNotFound :show="isPermissionCanView" />
	</div>
</template>

<script>
	import AppOverlay from "@/components/AppOverlay.vue";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import VehicleCreateCheckListType from "@/views/vehicle/vehicle_checklist/components/VehicleCreateCheckListType.vue";
	import VehicleCreateCheckListTable from "@/views/vehicle/vehicle_checklist/components/VehicleCreateCheckListTable.vue";
	import VehicleCreateCheckListManage from "@/views/vehicle/vehicle_checklist/components/VehicleCreateCheckListManage.vue";

	import {
		getCheckListGroupService,
		getCheckListItemService,
		getCheckListInfoService,
		postCheckListGroupService,
		putCheckListGroupService,
		putOrderListTypesService,
		putOrderListItemsService,
	} from "@/services/api/vehicle_checklist.js";

	import { mapGetters } from "vuex";

	export default {
		name: "vehicleCreateCheckListView",

		components: {
			AppOverlay,
			AppPageNotFound,
			VehicleCreateCheckListType,
			VehicleCreateCheckListTable,
			VehicleCreateCheckListManage,
		},

		data() {
			return {
				isLoading: false,
				componentRefreshCounter: 0,
				vehicleCheckListDataList: [],
				queryFilterList: {},
				queryStringSearch: "", // combin filter and search text
				querySearchAndFilterList: {}, // combine filter and search
				querySearchAndFilterListBackground: {},
				statusFilterValue: "ALL",
				pageDataLength: 0,
				currentPage: 1,
				checklistTypeItems: [],
				// selectedTypeIdx: null,
				selectedType: null,

				checklistItems: [],
				checklistInfo: null,
				selectedTypeName: null,

				queryOptionsTable: {
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				},

				isTypesReOrder: false,
				isItemsReOrder: false,
				selectedItem: null,
				items: [
					{ text: "Real-Time", icon: "mdi-clock" },
					{ text: "Audience", icon: "mdi-account" },
					{ text: "Conversions", icon: "mdi-flag" },
				],

				isPermissionCanEdit: true,
				isPermissionCanView: true,
				isPermissionCanViewInfo: true,
			};
		},
		watch: {
			"queryOptionsTable.page": function (newVal) {
				this.currentPage = newVal;
				this.vehicleCheckListDataList = [];
				this.tablePageHandler(newVal);
				// this.getData();
			},
			// selectedTypeIdx(val) {
			// 	this.processCheckListTypeItems(val);
			// },
		},
		computed: {
			...mapGetters({
				vehicleCheckListStatusColor:
					"vehicle_checklist/vehicleCheckListStatusColor",
				vehicleCheckListStatusCardColor:
					"vehicle_checklist/vehicleCheckListStatusCardColor",
				vehicleCheckListStatusText:
					"vehicle_checklist/vehicleCheckListStatusText",
			}),
			_vehicleCheckListStatusText: function () {
				return this.$store.state.vehicle_checklist.vehicleCheckListStatusText;
			},
		},
		mounted() {
			// this.checkUserPermissions();
			if (this.isPermissionCanView) {
				// this.processRouterQuery(this.$route.query);
				this.processCheckListType();
			}
		},
		methods: {
			setRouterQuery(queryObj) {
				this.$router
					.replace({
						path: this.$router.path,
						query: queryObj,
					})
					.catch(() => {});
			},
			setQueryStringSearch(queryObj) {
				this.queryStringSearch = new URLSearchParams(queryObj).toString();
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "PAYMENT");
					// TODO::
					// if (this.permList.payment_list === true) {
					//   this.isPermissionCanView = true;
					// }

					// if (this.permList.payment_report === true) {
					//   this.isPermissionCanView = true;
					//   // this.isPermissionCanEdit = true;
					//   this.isPermissionCanViewInfo = true;
					//   this.editTextBtn = "ดูข้อมูล";
					// }

					// if (
					//   this.permList.admin_all === true ||
					//   this.permList.payment_crud === true
					// ) {
					//   this.isPermissionCanView = true;
					//   this.isPermissionCanViewInfo = true;
					//   this.isPermissionCanEdit = true;
					//   this.editTextBtn = "แก้ไข";
					// }
				}
			},

			processRouterQuery(_queryRoute) {
				if (Object.keys(_queryRoute).length > 0) {
					Object.keys(_queryRoute).forEach((key) => {
						if (!_.isNil(_queryRoute[key])) {
							switch (key) {
								case "limit":
								// this.queryOptionsTable[key] = 2;
								// break;
								case "page":
									try {
										this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
									} catch (error) {
										this.queryOptionsTable[key] = this.queryOptionsTable[key];
									}
									break;
								case "by":
								case "order":
									this.queryOptionsTable[key] = _queryRoute[key];
									break;
								default:
									// filter and search
									this.querySearchAndFilterList[key] = _queryRoute[key];
									break;
							}
						}
					});

					this.querySearchAndFilterList = {
						...this.queryOptionsTable,
						...this.querySearchAndFilterList,
					};
				} else {
					// set default parameter
					this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
				}
				//
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.setRouterQuery(this.querySearchAndFilterList);
			},

			async processCheckListType() {
				this.isLoading = true;
				this.checklistTypeItems = [];
				let responseData = await getCheckListGroupService(this.$store);
				if (responseData.code == 200) {
					this.checklistTypeItems = _.cloneDeep(responseData["data"]);
				} else {
					this.checklistTypeItems = [];
				}
				// console.log("checklistTypeItems", this.checklistTypeItems);
				this.isLoading = false;
			},

			// CHECKLIST TYPES

			processSelectedType(val) {
				// console.log("processSelectedType", val);
				this.selectedType = _.cloneDeep(val);
				this.processCheckListTypeItems(val);
			},
			async processNewTypeOnSubmit(val) {
				// console.log("processNewTypeOnSubmit", val);
				this.isLoading = true;

				this.checklistTypeItems = [];
				// let queryString = new URLSearchParams(val).toString();
				let responseData = await postCheckListGroupService(this.$store, val);
				if (responseData.code == 200) {
					// reload check list items
					this.processCheckListType();
				}
				// this.processCheckListType();
				this.isLoading = false;
			},

			async processTypeOnChange(val) {
				// console.log("processTypeOnChange", val);
				this.isLoading = true;
				this.checklistTypeItems = [];
				let responseData = await putCheckListGroupService(this.$store, val);
				if (responseData.code == 200) {
					// reload check list items
					this.processCheckListType();
				}
				this.isLoading = false;
			},

			// CHECKLIST ITEMS

			processSelectedChecklist(val) {
				// console.log("processSelectedChecklist", val);
				this.processChecklistItem(val);
			},

			async processTypeOrderList(val) {
				this.isLoading = true;
				let responseData = await putOrderListTypesService(this.$store, {
					orderList: val,
				});
				if (responseData.code == 200) {
					this.processCheckListType();
				}
				this.isLoading = false;
			},

			async processOrderList(val) {
				// console.log("orderList", val);
				this.isLoading = true;
				let responseData = await putOrderListItemsService(this.$store, {
					orderList: val,
				});
				if (responseData.code == 200) {
					this.processCheckListTypeItems(this.selectedType);
				}
				this.isLoading = false;
			},

			async processCheckListTypeItems(type) {
				// reset checklistItems and formData
				// console.log("processCheckListTypeItems", type);
				this.checklistItems = [];
				this.processSelectedChecklist(null);

				// when type is selected
				if (!_.isNil(type)) {
					this.selectedTypeName = type["name"];

					let queryString = new URLSearchParams({
						group: this.selectedTypeName,
					}).toString();
					let responseData = await getCheckListItemService(
						this.$store,
						queryString
					);
					if (responseData.code == 200) {
						this.checklistItems = _.cloneDeep(responseData["data"]);
					} else {
						this.checklistItems = [];
					}
					// console.log("checklistItems", this.checklistItems);
				}
			},

			// CHECKLIST INFO

			async processChecklistItem(checklistItem) {
				this.checklistInfo = null;
				// console.log("checklistItem", checklistItem);
				if (!_.isNil(checklistItem)) {
					// console.log("checklistItems", this.checklistItems);
					let checklistId = checklistItem["_id"];
					// console.log("checklistId", checklistId);

					let responseData = await getCheckListInfoService(
						this.$store,
						checklistId
					);
					if (responseData.code == 200) {
						this.checklistInfo = _.cloneDeep(responseData["data"]);
					} else {
						this.checklistInfo = null;
					}
					// console.log("checklistInfo", this.checklistInfo);
				}
			},

			processReOrderItems(isItemsReOrder) {
				// console.log("isItemsReOrder", isItemsReOrder);
				this.isItemsReOrder = isItemsReOrder;
			},

			processReOrderTypes(isTypesReOrder) {
				this.isTypesReOrder = isTypesReOrder;
			},
		},
	};
</script>

<style scoped>
	.card-symbol {
		box-shadow: inset 0 0 0 #f9f9f9, 0 0 0px #d7e0e0;
	}
</style>
