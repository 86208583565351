var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amsd-fill-height"},[(_vm.assignedDrivers.length !== 0)?_c('div',{staticClass:"text-center",staticStyle:{"height":"100%","cursor":"pointer","position":"relative"},on:{"click":_vm.openManageDialogHandler}},[_c('p',{class:[
        {
          'red--text': _vm.assignedDriversTotal !== _vm.numberOfSelectedDriver,
        },
        'text-center pt-2 mb-1 text-app-detail-md font-weight-bold' ]},[_vm._v(" "+_vm._s(_vm.assignedDriversTotal)+"/"+_vm._s(_vm.numberOfSelectedDriver)+" ")]),(_vm.rowData.jobOpsLoading)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"0"}},[_c('v-progress-circular',{staticClass:"mr-1",attrs:{"size":20,"width":2,"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticStyle:{"padding":"0 8px"}},_vm._l((_vm.assignedDrivers),function(drivers,mainIdx){return _c('div',{key:mainIdx,staticClass:"d-flex"},[_vm._l((drivers),function(driver,idx){return _c('div',{key:(mainIdx + "-" + idx)},[(!driver.jobId && driver.jobStatus === '!OK')?_c('JobDuplicateMenuHoverOnChip',{attrs:{"driver":driver,"is-between-pair":_vm.isBetweenPair,"driver-idx":idx,"vuex-row-idx":_vm.vuexRowIdx,"vuex-cols-idx":_vm.vuexColsIdx,"table-id":_vm.tableId}}):_c('div',{class:[
              'amsd-short',
              'd-flex',
              { 'amsd-has-job': driver.jobId },
              { 'amsd-pair-background': _vm.isBetweenPair },
              { 'mr-1': (idx + 1) % 3 !== 0 } ]},[_c('v-avatar',{attrs:{"size":"26"}},[_c('img',{attrs:{"src":driver.profileImage}})]),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"13px","line-height":"2"}},[_c('span',{class:[
                  {
                    'red--text text--lighten-1 font-weight-medium':
                      _vm.existingDriverInCellJobStatusById(
                        _vm.tableId,
                        driver._id,
                        _vm.cellData.cellMId
                      ) && !driver.jobId,
                  } ]},[_vm._v(" "+_vm._s(driver.dpNickNameTh)+" ")])])],1)],1)}),(mainIdx === 3 && _vm.assignedDriversTotal > 11)?_c('div',{class:[
            'amsd-short',
            'mr-1',
            'text-center',
            { 'amsd-pair-background': _vm.isBetweenPair } ],staticStyle:{"font-size":"13px","line-height":"2"}},[_c('span',[_vm._v(" +"+_vm._s(_vm.assignedDriversTotal - 11))])]):_vm._e()],2)}),0)]):_vm._e(),(_vm.assignedDrivers.length === 0)?_c('div',{staticClass:"d-flex flex-column align-center amsd-fill-height"},[_c('p',{class:[
        {
          'red--text': _vm.assignedDriversTotal !== _vm.numberOfSelectedDriver,
        },
        'text-center mt-2 mb-1 text-app-detail-md font-weight-bold' ]},[_vm._v(" "+_vm._s(_vm.assignedDriversTotal)+"/"+_vm._s(_vm.numberOfSelectedDriver)+" ")]),_c('v-spacer'),_c('div',[(_vm.assignedDrivers.length === 0 && _vm.canEdit)?_c('v-btn',{attrs:{"fab":"","outlined":"","color":"primary"},on:{"click":_vm.openManageDialogHandler}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()],1),_c('v-spacer')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }