var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.colorBackgroudTopBar,"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"mx-2"},[(_vm.icon)?_c('v-icon',{staticClass:"mt-1 mr-2",attrs:{"color":_vm.colorTitle}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_c('span',{style:({
            color: _vm.colorTitle,
            fontSize: 14 + 'px',
            fontWeight: 'bold',
          })},[_vm._v(" "+_vm._s(_vm.textHeader)+" ")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"mt-5",style:({
          color: _vm.colorTitle,
          fontSize: 18 + 'px',
          fontWeight: 'bold',
        })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"mt-1",style:({
          fontSize: 12 + 'px',
          color: _vm.$vuetify.theme.themes.light.bodyText,
        })},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),(_vm.options.noconfirm)?_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" แก้ไขข้อมูล ")]):_vm._e(),_c('v-btn',{attrs:{"color":_vm.colorButtonSubmit,"dark":"","elevation":"0"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" ส่งใหม่อีกครั้ง ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }