<template>
  <v-combobox
    v-model="modelVal"
    :items="itemList"
    :loading="loading"
    :readonly="tableLoading"
    :search-input.sync="searchingAction"
    item-text="value"
    :item-value="itemValueKey"
    placeholder="ค้นหาจากเลขทะเบียนรถ / ตัวเลขตัวถัง"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    return-object
    filled
    dense
    hide-no-data
    hide-selected
    hide-details
    flat
    height="44"
    outlined
    @change="itemChange"
    :disabled="disabled"
    class="app-custom-input"
  ></v-combobox>
</template>
<script>
import { getVehicleBySeachingService } from "@/services/api/vehicle";
import _ from "lodash";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "AppAutoCompleteVehicleSearching",
  props: {
    itemValueKey: {
      default: "key", // _id
      type: String,
    },
    enableEnterSearch: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    tableLoading: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.getParamQuery();
  },
  data: () => ({
    modelVal: null,
    itemList: [],
    loading: false,
    searchingAction: null,
    searchCountTimer: undefined,
    queryOrder: {
      text: "text",
      cassieId: "cassieId",
    },
    formData: {
      text: null,
      cassieId: null,
    },
    firstInit: false,
    selectFromListChecker: false,
  }),
  watch: {
    searchingAction: {
      immediate: false,
      handler: function (val) {
        if (!_.isNil(val) && this.firstInit && !this.selectFromListChecker) {
          if (val.length > 2) {
            clearTimeout(this.searchCountTimer);
            this.loading = true;
            this.searchCountTimer = setTimeout(() => {
              this.callApi(val); // call api function
            }, 1500);
          }
        }
        this.firstInit = true;
        this.selectFromListChecker = false;
      },
    },
  },
  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "text":
              this.formData[this.queryOrder[key]] =
                appDecodeSingleURIComponentHelper(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });

      if (!_.isNil(this.formData["text"])) {
        this.modelVal = this.formData["text"];
      }
      //
    },

    async callApi(val) {
      let responseData = await getVehicleBySeachingService(this.$store, val);
      if (responseData["code"] === 200) {
        this.itemList = responseData["data"];
      } else {
        this.itemList = [];
      }
      this.loading = false;
    },

    itemChange() {
      if (
        _.isString(this.modelVal) === false &&
        _.isNil(this.modelVal) === false
      ) {
        this.selectFromListChecker = true;
        this.$emit("searchItemChange", this.modelVal);
        this.modelVal = this.modelVal["value"];
        this.itemList = [];
      } else if (_.isString(this.modelVal) === true) {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;

        this.$emit("searchItemChange", {
          value: this.modelVal,
        });
        this.itemList = [];
      } else {
        this.selectFromListChecker = false;
        clearTimeout(this.searchCountTimer);
        this.loading = false;

        this.$emit("searchItemChange", {
          value: null,
        });
        this.itemList = [];
      }

      clearTimeout(this.searchCountTimer);
      this.loading = false;
    },
  },
};
</script>
