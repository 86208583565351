<template>
  <v-container name="vehicletListView" fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <h1 class="text-h5 mt-1">รายการสัญญาเดินรถ</h1>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteContractSearching
            ref="AppAutoCompleteContractSearchingRef"
            :searchVal="searchItem"
            :tableLoading="tableLoading"
            @searchItemChange="getSearchItem"
            :key="componentRefreshCounter"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>
            <VehicleContractFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
        <v-col cols="auto" v-if="isPermissionCanEdit">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>

            <div>
              <!-- <NormalButton icon="mdi-maps" @on-click="heatMapHandler()">
                Heat Map
              </NormalButton> -->

              <NormalButton icon="mdi-refresh" @on-click="reset()">
                รีเซ็ต
              </NormalButton>
            </div>
          </div>
        </template>

        <template v-slot:[`item.contractNumber`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title font-weight-bold"
                v-if="item.contractNumber !== null"
              >
                <router-link
                  v-if="isPermissionCanViewInfo || isPermissionCanEdit"
                  class="text-decoration-none"
                  :to="{
                    name: 'vehicleContractEdit',
                    params: { id: item._id },
                  }"
                >
                  {{ item.contractNumber }}
                </router-link>
                <span v-else> {{ item.contractNumber }}</span>
              </v-list-item-title>
              <span v-else>-</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title"
                v-if="item.title !== null"
              >
                {{ item.title }}</v-list-item-title
              >
              <span v-else>-</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.contractCompany`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="text-app-title">
                {{ item.contractCompany || "-" }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.jobAmount`]="{ item }">
          <v-list-item
            v-if="item.remainingjob !== '-' || item.donejob !== '-'"
            two-line
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title font-weight-bold amber--text text--darken-2"
                >คงค้าง {{ item.remainingjob }} งาน</v-list-item-title
              >
              <span class="text-app-normal"
                >ทำเสร็จ {{ item.donejob }} งาน</span
              >
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.expenses`]="{ item }">
          <v-list-item v-if="item.expenses !== null" two-line class="px-0">
            <v-list-item-content>
              <v-row no-gutters>
                <v-col>-</v-col>
                <!-- <v-col
                ><div class="text-app-normal">สัปดาห์</div>
                <div class="text-app-title">
                  {{ itemAmount[0].toLocaleString() }}
                </div></v-col
              >
              <v-col><v-divider vertical /></v-col>
              <v-col
                ><div class="text-app-normal">เดือน</div>
                <div class="text-app-title">
                  {{ itemAmount[1].toLocaleString() }}
                </div></v-col
              >
              <v-col><v-divider vertical /></v-col>
              <v-col
                ><div class="text-app-normal">ทั้งหมด</div>
                <div class="text-app-title"></div>
                {{ itemAmount[2].toLocaleString() }}</v-col
              > -->
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.income`]="{ item }">
          <v-list-item v-if="item.income !== null" two-line class="px-0">
            <v-list-item-content>
              <span class="text-app-title">
                {{ item.income.toLocaleString() }}
              </span>
            </v-list-item-content></v-list-item
          >
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <v-list-item
            v-if="item.startDate !== null || item.endDate !== null"
            two-line
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title
                v-if="item.period !== null"
                class="text-app-title font-weight-bold green--text text--darken-1"
                >{{ item.periodStr }}</v-list-item-title
              >
              <span class="text-app-normal"
                >เปิด
                {{
                  dateStringToDateFormat(item.startDate, { returnThai: false })
                }}</span
              >
              <span class="text-app-normal"
                >สิ้นสุด
                {{
                  dateStringToDateFormat(item.endDate, { returnThai: false })
                }}</span
              >
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            label
            small
            class="text-app-title"
            :color="vehicleContractStatusColor(item.status)"
            dark
          >
            {{ vehicleContractStatusText(item.status) }}
          </v-chip>
        </template>

        <template v-slot:[`item.tools`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="isPermissionCanEdit || isPermissionCanViewInfo"
                @click="handleEditClick(item._id)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  {{ editTextBtn }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPermissionCanViewReport"
                @click="openContractDashboard(item._id)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-file-chart-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  รายงานสัญญา
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="openPenalityTab(item)"
                v-if="permList.user_penality || permList.admin_all"
              >
                <v-list-item-icon>
                  <v-icon> mdi-account-details </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  พฤติกรรม</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                v-if="
                  permList.contract_report ||
                  permList.contract_total_report ||
                  permList.admin_all
                "
                @click="openContractHeatMap(item._id)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-chart-bubble</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  Heat map
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="handleDeleteClick(item._id)"
                v-if="isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title"
                  >ลบข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <AppDialogConfirm ref="confirmDelete" />
    </div>
    <AppPageNotFound :show="isPermissionCanView" />
    <ContractChartReportInfo ref="contractChartReportInfo" />
  </v-container>
</template>

<script>
import CustomCardFormView from "@/components/CustomCardFormView.vue";
import { processPermission } from "@/services/permissions";
import NormalButton from "@/components/NormalButton.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { mapGetters } from "vuex";
import {
  getContractsService,
  deleteContractService,
} from "@/services/api/contract";
import VehicleContractFilterGroup from "@/views/contract/components/VehicleContractFilterGroup.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppAutoCompleteContractSearching from "@/components/AppAutoCompleteContractSearching.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { appEncodeSingleURIComponentHelper } from "@/services/appStringHelpper";
import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";
import ContractChartReportInfo from "./components/ContractChartReportInfo.vue";

export default {
  name: "ContractListView",
  components: {
    CustomCardFormView,
    NormalButton,
    PrimaryButton,
    VehicleContractFilterGroup,
    AppDialogConfirm,
    AppAutoCompleteContractSearching,
    AppPageNotFound,
    ContractChartReportInfo,
  },
  data() {
    return {
      componentRefreshCounter: 0,
      title: "รายการสัญญาเดินรถ",
      _licenseNumber: null,

      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      tableLoading: false,
      field_sort_by: null,
      serverItemsLength: -1,
      options: {},
      queryStringSearch: "",
      sortVal: null,
      // itemsPerPage: 20,
      tableData: [],
      respTableData: [],

      queryOptionsTable: {
        limit: 20, // itemsPerPage = limit
        by: "createdat", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },

      queryStringSearchList: {},
      queryFilterList: {},
      querySearchAndFilterList: {},
      searchItem: "",
      isShowMenu: false,
      permList: {},

      itemAmount: [1000, 2000, 100000],

      isPermissionCanEdit: false,
      isPermissionCanView: false,
      isPermissionCanViewInfo: false,
      isPermissionCanViewReport: false,
      editTextBtn: "",

      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createdat-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createdat-desc",
        },
        {
          text: "สถานะ เรียงจาก น้อย-มาก",
          value: "status-asc",
        },
        {
          text: "สถานะ เรียงจาก มาก-น้อย",
          value: "status-desc",
        },
        {
          text: "เวลาเปิดสัญญา เรียงจาก น้อย-มาก",
          value: "startdate-asc",
        },
        {
          text: "เวลาเปิดสัญญา เรียงจาก มาก-น้อย",
          value: "startdate-desc",
        },
        {
          text: "เวลาสิ้นสุดสัญญา เรียงจาก น้อย-มาก",
          value: "enddate-asc",
        },
        {
          text: "เวลาสิ้นสุดสัญญา เรียงจาก มาก-น้อย",
          value: "enddate-desc",
        },
        {
          text: "ค่าใช้จ่าย เรียงจาก น้อย-มาก",
          value: "expenses-asc",
        },
        {
          text: "ค่าใช้จ่าย เรียงจาก มาก-น้อย",
          value: "expenses-desc",
        },
        {
          text: "รายรับ เรียงจาก น้อย-มาก",
          value: "income-asc",
        },
        {
          text: "รายรับ เรียงจาก มาก-น้อย",
          value: "income-desc",
        },
        {
          text: "Jobs ที่ทำไปแล้ว เรียงจาก น้อย-มาก",
          value: "totaljob-asc",
        },
        {
          text: "Jobs ที่ทำไปแล้ว เรียงจาก มาก-น้อย",
          value: "totaljob-desc",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      vehicleContractStatusColor: "vehicle/vehicleContractStatusColor",
      vehicleContractStatusText: "vehicle/vehicleContractStatusText",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),
    tableRows() {
      let rows = this.respTableData.map((row) => {
        return {
          _id: row._id,
          contractNumber: row.contractNumber,
          title: row.title,
          contractCompany: this.checkUndefinedAndNull(
            row,
            "contractCompany",
            false
          ),
          startDate: this.checkUndefinedAndNull(row, "startDate", false),
          endDate: this.checkUndefinedAndNull(row, "endDate", false),
          remainingjob: this.checkUndefinedAndNull(row, "remainingjob", false),
          donejob: this.checkUndefinedAndNull(row, "donejob", false),
          expenses: this.checkUndefinedAndNullAmount(row, "expenses", false),
          income: this.checkUndefinedAndNullAmount(row, "income", false),
          period: this.checkUndefinedAndNull(row, "period", false),
          periodStr: this.checkUndefinedAndNull(row, "periodStr", false),
          status: this.checkUndefinedAndNull(row, "status", false),
          tools: [],
        };
      });
      return rows;
    },
    tableHeaders() {
      let defaultdata = [
        {
          text: "รหัสสัญญา",
          align: "start",
          sortable: false,
          value: "contractNumber",
          width: "15%",
        },
        {
          text: "ชื่อสัญญา",
          align: "start",
          sortable: false,
          value: "title",
          width: "15%",
        },
        {
          text: "บริษัทคู่สัญญา",
          align: "start",
          sortable: false,
          value: "contractCompany",
          width: "15%",
        },
        {
          text: "วันเวลา เปิด/สิ้นสุด",
          align: "start",
          sortable: false,
          value: "date",
          align: "left",
          width: "15%",
        },
        // {
        //   text: "Job คงค้าง",
        //   align: "start",
        //   sortable: false,
        //   value: "jobAmount",
        //   align: "left",
        //   width: "10%",
        // },
        {
          text: "ค่าใช้จ่ายย้อนหลัง (บาท)",
          align: "start",
          sortable: false,
          value: "expenses",
          align: "center",
          // width: "200px",
        },
        {
          text: "รายรับโดยประมาณ (บาท)",
          align: "start",
          sortable: false,
          value: "income",
          align: "center",
        },
        { text: "สถานะ", value: "status", sortable: false, align: "left" },
      ];
      //
      if (
        this.isPermissionCanViewReport ||
        this.isPermissionCanViewInfo ||
        this.isPermissionCanEdit
      ) {
        defaultdata.push({
          text: "",
          value: "tools",
          sortable: false,
          align: "center",
        });
      }
      //
      return defaultdata;
    },
  },
  mounted() {
    this.checkUserPermissions();

    if (Object.keys(this.$route.query).length > 0) {
      Object.keys(this.$route.query).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "limit":
            case "page":
              try {
                this.queryOptionsTable[key] = parseInt(this.$route.query[key]);
              } catch (error) {
                this.queryOptionsTable[key] = this.queryOptionsTable[key];
              }
              break;
            case "by":
            case "order":
              this.queryOptionsTable[key] = this.$route.query[key];
              break;
            default:
              // filter and search
              this.querySearchAndFilterList[key] = this.$route.query[key];
              break;
          }
        }
      });

      this.querySearchAndFilterList = {
        ...this.queryOptionsTable,
        ...this.querySearchAndFilterList,
      };
    } else {
      // set default parameter
      this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      // if (this._licenseNumber !== null) {
      //   this.querySearchAndFilterList["idname"] = this._licenseNumber;
      // }
    }
    //
    this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
    // this.setRouterQuery(this.querySearchAndFilterList);
    this.getData();
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    // startPage() {
    //   if (this.$route.params.contractNumber !== undefined) {
    //     this._licenseNumber = this.$route.params.contractNumber;
    //   } else {
    //     this._licenseNumber = null;
    //   }
    //   this.getSearchContract();
    // },
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    async getData() {
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      // let queryString = `${
      //   this.queryStringSearch.length === 0 ? "" : `${this.queryStringSearch}&`
      // }limit=${itemsPerPage}&page=${page}`;

      // if (sortBy.length !== 0) {
      //   queryString = `${queryString}&by=${sortBy}&order=${sortDesc}`;
      // }
      // this.tableLoading = true;

      // this.tableData = [];
      // let responseData = await getContractsService(this.$store, queryString);
      this.tableLoading = true;
      this.tableData = [];
      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getContractsService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData.code == 200) {
        this.serverItemsLength = responseData.data["total"];
        let vehicletListView = responseData.data["contractList"];
        this.respTableData = vehicletListView;
        this.tableData = this.tableRows;
      } else {
        this.tableData = [];
      }

      this.tableLoading = false;
    },
    // getSearchContract() {
    //   // reset value
    //   delete this.queryStringSearchList["idname"];
    //   if (this._licenseNumber !== null) {
    //     this.queryStringSearchList["idname"] = this._licenseNumber;
    //     this.$refs.AppAutoCompleteContractSearchingRef.setValue(
    //       this._licenseNumber
    //     );
    //   }
    //   // build query string
    //   this.queryStringSearch = new URLSearchParams(
    //     this.queryStringSearchList
    //   ).toString();
    //   this.getData();
    // },

    getSearchItem(_searchingParameter) {
      // // reset value
      // delete this.queryStringSearchList["text"];
      // this.queryStringSearchList["text"] = value.key;

      // this.querySearchAndFilterList = {
      //   ...this.queryFilterList,
      //   ...this.queryStringSearchList,
      // };
      // // build query string
      // this.queryStringSearch = new URLSearchParams(
      //   this.querySearchAndFilterList
      // ).toString();
      // this.getData();

      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["text"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["contractNumber"])) {
          _queryParameter["text"] = _searchingParameter["contractNumber"];
        } else {
          if (!_.isNil(_searchingParameter["key"])) {
            if (_searchingParameter["key"].length > 0) {
              _queryParameter["text"] = _searchingParameter["key"];
            }
          }
        }
      }
      // _searchingParameter
      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      // this.queryFilterList = value;
      // this.querySearchAndFilterList = {
      //   ...this.queryFilterList,
      //   ...this.queryStringSearchList,
      // };
      // this.queryStringSearch = new URLSearchParams(
      //   this.querySearchAndFilterList
      // ).toString();
      // this.getData();

      //
      // callback from filter group
      //

      // if (!_.isEmpty(this.queryFilterList)) {
      //   if (_.isEmpty(_queryParameter)) {
      //     Object.keys(this.queryFilterList).forEach((key) => {
      //       delete this.querySearchAndFilterList[key];
      //     });
      //     this.queryFilterList = {};
      //   }
      // } else {
      //   this.queryFilterList = _queryParameter;
      // }

      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    // sortByHandler(sortValue) {
    //   this.field_sort_by = sortValue.split("-");
    //   this.options["sortBy"] = [this.field_sort_by[0]];
    //   this.options["sortDesc"] = [this.field_sort_by[1]];
    // },
    handleAddClick() {
      this.$router.push("/caj/contract/create");
    },
    handleEditClick(_id) {
      this.$router.push(`/caj/contract/edit/${_id}`);
    },
    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteContractService(this.$store, _id);
        this.getData();
      }
    },
    checkUndefinedAndNullAmount(item, key, returnBoolean) {
      if (item.hasOwnProperty(key) === false) {
        return returnBoolean ? false : "-";
      }

      if (item[key] === null) {
        return returnBoolean ? false : "-";
      }
      // if string
      if (item[key].length === 0) {
        return returnBoolean ? false : "-";
      }

      return parseFloat(item[key]);
    },

    checkUndefinedAndNull(item, key, returnBoolean) {
      if (item.hasOwnProperty(key) === false) {
        return returnBoolean ? false : "-";
      }

      if (item[key] === null) {
        return returnBoolean ? false : "-";
      }
      // if string
      if (item[key].length === 0) {
        return returnBoolean ? false : "-";
      }

      return item[key];
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "CONTRACT");

        if (this.permList.contract_list === true) {
          this.isPermissionCanView = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (this.permList.contract_info === true) {
          this.isPermissionCanViewInfo = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (this.permList.admin_all === true || this.permList.contract_report) {
          this.isPermissionCanViewReport = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.contract_info_crud === true
        ) {
          this.isPermissionCanViewInfo = true;
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },

    getExpiredColor(period) {
      if (period.years == 0 && period.months == 0) {
        if (period.days < 3) {
          return "red--text text--darken-1 font-weight-bold";
        } else if (period.days < 10) {
          return "amber--text text--darken-2 font-weight-bold";
        }
      }
      return "green--text text--darken-1 font-weight-bold";
    },

    async openContractDashboard(contractId) {
      if (!_.isNil(contractId)) {
        const arg = {
          contractId: contractId,
          isInputSearching: true,
          startDate: null,
          endDate: null,
        };
        await this.$refs.contractChartReportInfo.open(arg);
      }
    },

    async openContractHeatMap(contractId) {
      if (!_.isNil(contractId)) {
        this.$store.dispatch("jobsMap/updateSelectedContractId", contractId);
        this.$store.dispatch("jobsMap/openDialog", { show: true });
      }
    },

    reset() {
      // if (this.queryStringSearch.length !== 0) {
      //   this.componentRefreshCounter += 1;
      // }
      // this.$refs.AppAutoCompleteContractSearchingRef.resetValue();
      // this.queryStringSearch = "";
      // this.options["sortBy"] = [];
      // this.options["sortDesc"] = [];
      // this.options["page"] = 1;
      // this.sortVal = null;
      // this.getData();

      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createdat", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },

    heatMapHandler() {
      this.$store.dispatch("jobsMap/openDialog", { show: true });
    },

    openPenalityTab(item) {
      const params = new URLSearchParams({
        contract: item["_id"],
        cjvtext: appEncodeSingleURIComponentHelper(
          `สัญญา ${item["title"]} ${item["contractNumber"]}`
        ),
      }).toString();

      const goTo = `${window.location.origin}/employee${URL_EMPLOYEE_PENALTY}&${params}`;
      window.open(goTo, "_blank");
    },
  },
  // watch: {
  //   options: {
  //     handler() {
  //       this.getData();
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
