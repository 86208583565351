<template>
  <div class="text-center">
    <v-dialog
      scrollable
      v-model="show"
      width="750px"
      :persistent="disabled"
      transition="dialog-top-transition"
      @click:outside="closeStatus"
    >
      <v-card>
        <v-toolbar elevation="0" color="primary" dark>
          <p class="mt-4 px-3">
            รหัสการเติมน้ำมัน:
            <span class="font-weight-bold">{{ formData.code }} </span>
          </p>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" icon @click="closeStatus" :disabled="disabled">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-6">
          <v-row dense v-viewer>
            <v-col class="pr-3 pb-3 d-flex child-flex" cols="6">
              <v-card
                class="image-viewer-container"
                elevation="5"
                v-if="dataStep.s1"
              >
                <img
                  width="100%"
                  @error="imageLoadError"
                  class="grey lighten-2"
                  :src="dataStep.s1 ? dataStep.s1.src : ''"
                />
                <p class="text-center pt-6">
                  <span class="text-app-detail-large font-weight-bold"
                    >ภาพหน้าปัดรถก่อนเติมน้ำมัน</span
                  >
                  <br />
                  {{
                    dateStringToDateFormat(dataStep.s1.createdAt, {
                      returnThai: false,
                      type: "datetime",
                    })
                  }}
                </p>
              </v-card>
              <v-card
                v-else
                class="pt-3 d-flex flex-column justify-center align-self-center fill-height"
                elevation="5"
                title
              >
                <p class="ma-0 pa-4 text-center">
                  <span class="text-app-detail-large font-weight-bold"
                    >ภาพหน้าปัดรถก่อนเติมน้ำมัน</span
                  >
                  <br />
                  ไม่มีข้อมูล
                </p>
              </v-card>
            </v-col>
            <v-col class="pl-3 pb-3 d-flex child-flex" cols="6">
              <v-card
                class="image-viewer-container"
                elevation="5"
                v-if="dataStep.s3"
              >
                <img
                  width="100%"
                  @error="imageLoadError"
                  class="grey lighten-2"
                  :src="dataStep.s3 ? dataStep.s3.src : ''"
                />
                <p class="text-center pt-6">
                  <span class="text-app-detail-large font-weight-bold">
                    ภาพหน้าปัดรถหลังเติมน้ำมัน
                  </span>
                  <br />
                  ( + {{ dataStep.s3.intervalTime }})
                </p>
              </v-card>

              <v-card
                v-else
                class="pt-3 d-flex flex-column justify-center align-self-center fill-height"
                elevation="5"
                title
              >
                <p class="ma-0 text-center">
                  <span class="text-app-detail-large font-weight-bold">
                    ภาพหน้าปัดรถหลังเติมน้ำมัน
                  </span>
                  <br />
                  ไม่มีข้อมูล
                </p>
              </v-card>
            </v-col>
            <!--  -->
            <v-col class="pt-3 pr-3 d-flex child-flex" cols="6">
              <v-card
                class="image-viewer-container"
                elevation="5"
                v-if="dataStep.s2"
              >
                <img
                  width="100%"
                  @error="imageLoadError"
                  class="grey lighten-2"
                  :src="dataStep.s2 ? dataStep.s2.src : ''"
                />
                <p class="text-center pt-6">
                  <span class="text-app-detail-large font-weight-bold">
                    ภาพหน้าตู้จ่ายน้ำมัน</span
                  >
                  <br />
                  ( + {{ dataStep.s2.intervalTime }})
                </p>
              </v-card>

              <v-card
                v-else
                class="pt-3 d-flex flex-column justify-center align-self-center fill-height"
                elevation="5"
                title
              >
                <p class="ma-0 text-center">
                  <span class="text-app-detail-large font-weight-bold">
                    ภาพหน้าตู้จ่ายน้ำมัน</span
                  >
                  <br />
                  ไม่มีข้อมูล
                </p>
              </v-card>
            </v-col>

            <v-col cols="6" class="pl-3 pt-3">
              <v-card
                elevation="5"
                class="rounded-lg d-flex flex-column fill-height local-card-text"
              >
                <v-card-text class="pa-9">
                  <v-row>
                    <v-col cols="12">
                      <p class="text-app-md white--text text-center">
                        ชนิดน้ำมัน
                      </p>
                      <p
                        class="text-app-md font-weight-bold white--text text-center"
                      >
                        {{ formData.fuelTypeStr }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-center text-app-md white--text">
                        ค่าใช้จ่าย
                      </p>
                      <v-spacer></v-spacer>
                      <p
                        class="text-center text-app-md font-weight-bold white--text"
                      >
                        {{ formData.amountStr }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-center text-app-md white--text">
                        จำนวนลิตรโดยประมาณ
                      </p>
                      <v-spacer></v-spacer>
                      <p
                        class="text-center text-app-md font-weight-bold white--text"
                      >
                        {{ formData.amountUnitStr || "-" }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-center text-app-md white--text">
                        จำนวนลิตรแท้จริง
                      </p>
                      <v-spacer></v-spacer>
                      <v-text-field
                        outlined
                        dense
                        color="primary"
                        background-color="white"
                        type="number"
                        v-model="formData.actualUnitAmount"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-center text-app-md white--text">
                        ราคาต่อลิตรโดยประมาณ
                      </p>
                      <v-spacer></v-spacer>
                      <p
                        class="text-center text-app-md font-weight-bold white--text"
                      >
                        {{ formData.pricePerUnitStr || "-" }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="pt-3 pr-3 d-flex child-flex" cols="6">
              <v-card
                class="image-viewer-container"
                elevation="5"
                v-if="formData"
              >
                <img
                  width="100%"
                  @error="imageLoadError"
                  class="grey lighten-2"
                  :src="formData.image ? formData.image : ''"
                />
                <p class="text-center pt-6">
                  <span class="text-app-detail-large font-weight-bold">
                    ภาพใบเสร็จ
                  </span>
                </p>
              </v-card>
            </v-col>
            <v-col cols="6" class="pl-3 pt-3">
              <v-card
                elevation="5"
                class="rounded-lg d-flex flex-column fill-height"
              >
                <v-card-text class="local-card-text">
                  <div class="text-center local-card-text">
                    <span class="text-app-md white--text"> ประเภทการจ่าย </span>

                    <div
                      v-if="formData.paymentMethod"
                      class="text-app-md font-weight-bold white--text"
                    >
                      <p class="mb-0">
                        {{ formData.paymentMethod.code || "-" }}
                      </p>
                      <p class="mb-1">
                        {{ formData.paymentMethod.name || "" }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text>
                  <p>
                    <span class="text-app-md">ข้อมูลรถ:</span>
                    <span align="right" class="text-app-md font-weight-medium">
                      {{ formData.veData.licenseNumber }}
                      {{ formData.veData.type ? formData.veData.value : "" }}
                      สี{{
                        formData.veData.color ? formData.veData.color.value : ""
                      }}
                      {{
                        formData.veData.engine
                          ? formData.veData.engine.value
                          : ""
                      }}
                    </span>
                  </p>

                  <p>
                    <span class="text-app-md">โดย:</span>
                    <span align="right" class="text-app-md font-weight-medium">
                      {{ formData.userId.dpNameTh }} ({{
                        formData.userId.empId
                      }})
                    </span>
                  </p>

                  <p>
                    <span class="text-app-md">เบอร์โทร:</span>
                    <span align="right" class="text-app-md font-weight-medium">
                      {{ formData.userId.dpPhoneNumeber }}
                    </span>
                  </p>
                </v-card-text>

                <div v-if="formData" class="px-4">
                  <CustomTextInputCurrency
                    ref="amount"
                    v-model="formData.amount"
                    placeholder=""
                    title="ค่าใช้จ่าย"
                    :is-required="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    :input-view-bottom-margin="false"
                    :expend-margin="false"
                    :disabled="!isPermissionCanEdit"
                  />

                  <!-- <v-btn
                    :loading="btnLoading"
                    color="success mb-4"
                    @click="handlerSaveHandler('amount')"
                    :disabled="!isPermissionCanEdit"
                  >
                    บันทึกค่าใช้จ่าย
                  </v-btn>
                  {{ saveAmoungMsg }} -->
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-card elevation="0">
                <v-card-text>
                  <div class="d-flex">
                    <p class="mt-2" v-if="formData.checklisttorId">
                      โดย: {{ formData.checklisttorId.dpNameTh }}
                    </p>
                    <v-spacer></v-spacer>
                    <div class="d-flex justify-center align-center">
                      <VehicleFuelFilterStatus
                        v-model="localStatusData"
                        :show-all="false"
                        :disabled="btnLoading || !isPermissionCanEdit"
                      />
                    </div>
                  </div>

                  <CustomTextareaImprove
                    :disabled="btnLoading"
                    ref="rejectComment"
                    v-if="localStatusData === 'R'"
                    v-model="formData.rejectComment"
                    title="ความคิดเห็น"
                    placeholder="ข้อความ..."
                    :input-view-bottom-margin="false"
                    :expend-margin="false"
                  />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <p>{{ saveStatusMsg }}</p>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="btnLoading"
                    color="success"
                    @click="handlerSaveHandler('status')"
                    :disabled="!isPermissionCanEdit"
                  >
                    บันทึกสถานะ
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <app-overlay :absolute="false" :isLoading="isLoading" />
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import VehicleFuelStatusSelectionBtn from "./components/VehicleFuelStatusSelectionBtn.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import VueViewer from "v-viewer";
import {
  getVehicleFuelPaymentInfoService,
  putVehicleFuelPaymentUpdateStatusService,
} from "@/services/api/vehicle_fuel_payment";
import AppOverlay from "@/components/AppOverlay.vue";
import {
  createAmountStr,
  createAmountUnitStr,
  createCreatedAtStr,
  createfuelTypeStr,
  createPriceUnitStr,
} from "./vehicleFuelPaymentHelper";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import VehicleFuelFilterStatus from "./components/VehicleFuelFilterStatus.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";

Vue.use(VueViewer);
export default {
  name: "VehicleFuelPaymentDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,

    disabled: false,
    isLoading: true,

    formData: null,
    localStatusData: null,

    defaultFormData: {
      code: null,
      status: null,
      veData: {
        licenseNumber: null,
        type: null,
        color: null,
        engine: null,
      },
      userId: {
        dpNameTh: null,
        empId: null,
        dpPhoneNumeber: null,
      },
      paymentMethod: {
        name: null,
        code: null,
      },
      actualUnitAmount: null,
    },

    dataStep: {
      s1: null,
      s2: null,
      s3: null,
    },

    btnLoading: false,
    saveAmoungMsg: "",
    saveStatusMsg: "",

    //
    isPermissionCanEdit: false,
  }),
  created() {
    this.resetFormData();
  },
  mounted() {
    this.checkUserPermissions();
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.formData = _.cloneDeep(this.defaultFormData);
    },
    setFormData(item) {
      this.formData = {
        ...this.formData,
        ...item,
      };
      this.saveStatusMsg = "";
      this.saveAmoungMsg = "";
      this.localStatusData = this.formData["status"];
      this.formData["fuelTypeStr"] = createfuelTypeStr(this.formData);
      this.formData["createdAtStr"] = createCreatedAtStr(this.formData);
      this.formData["amountStr"] = createAmountStr(this.formData);
      this.formData["amountUnitStr"] = createAmountUnitStr(this.formData);
      this.formData["pricePerUnitStr"] = createPriceUnitStr(this.formData);

      for (const [index, value] of Object.entries(this.formData.step)) {
        this.dataStep[index] = value;
      }
    },
    async setData(item) {
      this.resetFormData();
      this.show = true;
      this.isLoading = true;

      const respData = await getVehicleFuelPaymentInfoService(
        this.$store,
        `id=${item._id}`
      );

      if (respData["code"] === 200) {
        this.setFormData(respData["data"]);
        this.isLoading = false;
      } else {
        this.closeStatus();
      }
    },
    open(item) {
      this.setData(item);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    closeStatus() {
      this.resolve(_.clone(this.formData));
      this.isLoading = false;
      this.show = false;
    },
    pinghandler(processingStatus) {
      this.disabled = processingStatus;
    },
    imageLoadError() {
      console.log("Image failed to load");
    },
    //
    async handlerSaveHandler(updateType) {
      this.disabled = true;
      this.btnLoading = true;
      this.saveStatusMsg = "";
      this.saveAmoungMsg = "";

      let uriQuery = new URLSearchParams({
        id: this.formData["_id"],
      }).toString();

      let responseData = await putVehicleFuelPaymentUpdateStatusService(
        this.$store,
        uriQuery,
        {
          actualUnitAmount: this.formData["actualUnitAmount"],
          amount: this.formData["amount"],
          status: this.localStatusData,
          rejectComment: this.formData["rejectComment"],
        }
      );

      if (responseData["code"] === 200) {
        this.formData["status"] = this.localStatusData;
        this.saveStatusMsg = "บันทึกข้อมูลสถานะเรียบร้อย";
        this.closeStatus();
      } else {
        this.saveStatusMsg = "บันทึกข้อมูลสถานะเกิดข้อผิดพลาด";
      }
      this.btnLoading = false;
      this.disabled = false;
    },
    //
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_FUEL");

        if (
          this.permList.admin_all === true ||
          this.permList.fuelpayment_update_status === true
        ) {
          this.isPermissionCanEdit = true;
        }
      }
    },
  },

  watch: {},
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    VehicleFuelStatusSelectionBtn,
    AppOverlay,
    CustomTextInputCurrency,
    VehicleFuelFilterStatus,
    CustomTextInputImprove,
  },
};
</script>

<style scoped>
.local-card-text {
  background-color: #7b7f9e;
}
</style>
