import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#567DF4",
				secondary: "#B2C8DF",
				error: "#ed5555",
				warning: "#ff8c26",
				success: "#49c496",
				bodyText: "#4d5656",
				title: "#4d5656",
				subtitle: "#AAB7B8",
				backgroundNormalButton: "#F8F9F9",
				borderColor: "#D5DBDB",
				backgroundInputView: "#FFFFFF",
				backgroudDisableInputView: "#F4F6F6",
				backgroudDarkDisableInputView: "#303845",
				placeholerColor: "#AAB7B8",
				darkPageBackgroundColor: "#222831",
				darkItemColor: "#505a68",
				default: "#CACFD2",
			},
			dark: {
				// primary: colors.blue.lighten3,
			},
		},
	},
});
