<template>
  <div name="employeeOverviewDashboard">
    <!-- <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" /> -->
    <v-col cols="auto">
      <div class="text-h5 mt-1">รายงานภาพรวมพนักงาน</div>
    </v-col>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <div v-if="isPermissionCanView">
            <CustomCardFormViewModern
              title="จำนวนพนักงาน"
              :isShowTitle="false"
              :isAddTitleMargin="true"
            >
              <v-row no-gutters class="px-3 mb-3">
                <v-col cols="12" class="px-3 mt-3">
                  <v-row no-gutters class="mt-3">
                    <v-col cols="6">
                      <v-row no-gutters>
                        <v-col cols="3">
                          <div class="mt-4 mb-0">
                            <span
                              v-if="
                                employeeNumberStats &&
                                Object.keys(employeeNumberStats).length > 0
                              "
                              class="dashboard-medium font-weight-bold black--text"
                              >{{ totalEmployee }}</span
                            >
                            <span
                              v-else
                              class="dashboard-medium font-weight-bold black--text"
                              >0</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span class="font-weight-bold px-1">ทั้งหมด</span>
                          </div>
                        </v-col>
                        <v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div class="pl-3">
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["ACTIVE"]
                                  ? employeeEachStatus["ACTIVE"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div class="pl-3">
                            <span
                              :class="`font-weight-bold  px-1 ${getStatusEmployeeColor(
                                'ACTIVE'
                              )}`"
                              >{{ getStatusEmployeeText("ACTIVE") }}</span
                            >
                          </div></v-col
                        >
                        <v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["PROBATION"]
                                  ? employeeEachStatus["PROBATION"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'PROBATION'
                              )}`"
                              >{{ getStatusEmployeeText("PROBATION") }}</span
                            >
                          </div></v-col
                        ><v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["RESIGNED"]
                                  ? employeeEachStatus["RESIGNED"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'RESIGNED'
                              )}`"
                              >{{ getStatusEmployeeText("RESIGNED") }}</span
                            >
                          </div></v-col
                        >
                      </v-row></v-col
                    >
                    <v-col cols="6">
                      <v-row no-gutters>
                        <v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["TERMINATED"]
                                  ? employeeEachStatus["TERMINATED"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'TERMINATED'
                              )}`"
                              >{{ getStatusEmployeeText("TERMINATED") }}</span
                            >
                          </div></v-col
                        ><v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["FIRED"]
                                  ? employeeEachStatus["FIRED"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'FIRED'
                              )}`"
                              >{{ getStatusEmployeeText("FIRED") }}</span
                            >
                          </div></v-col
                        >
                        <v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["APPLIED"]
                                  ? employeeEachStatus["APPLIED"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'APPLIED'
                              )}`"
                              >{{ getStatusEmployeeText("APPLIED") }}</span
                            >
                          </div></v-col
                        ><v-divider vertical class="mb-2 mt-6" />
                        <v-col cols="3" class="pl-3 pt-6"
                          ><div>
                            <span
                              v-if="employeeEachStatus !== null"
                              class="dashboard-small font-weight-bold black--text"
                              >{{
                                employeeEachStatus["REJECTED"]
                                  ? employeeEachStatus["REJECTED"]
                                  : 0
                              }}</span
                            >
                            <span class="text-app-detail-normal"> คน</span>
                          </div>
                          <div>
                            <span
                              :class="`font-weight-bold px-1 ${getStatusEmployeeColor(
                                'REJECTED'
                              )}`"
                              >{{ getStatusEmployeeText("REJECTED") }}</span
                            >
                          </div></v-col
                        ></v-row
                      ></v-col
                    >
                  </v-row>
                </v-col></v-row
              >
            </CustomCardFormViewModern>
          </div>
        </v-col>
      </v-row>
      <v-card class="px-4 mt-2" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                สัดส่วนพนักงาน
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="3"
                ><div>
                  <span class="text-app-detail-medium">คนใน</span>
                </div>
                <div class="mb-0">
                  <span
                    v-if="
                      employeeNumberStats && employeeNumberStats['hiringType']
                    "
                    class="dashboard-large font-weight-bold black--text"
                    >{{
                      employeeNumberStats["hiringType"]["ภายใน"]
                        ? getPriceString(
                            employeeNumberStats["hiringType"]["ภายใน"]
                          )
                        : 0
                    }}</span
                  >
                  <span
                    v-else
                    class="dashboard-large font-weight-bold black--text"
                    >0</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </div>
                <div class="mt-0">
                  <span class="text-app-detail-normal"
                    >คิดเป็น {{ insideEmpPercent.toFixed(2) }}%</span
                  >
                </div>
              </v-col>
              <v-col cols="3" class="pt-1"
                ><div class="mt-12">
                  <span class="text-app-detail-medium"
                    ><v-icon>mdi-check-circle</v-icon> มีประกันสังคม</span
                  >
                </div>
                <div>
                  <span
                    v-if="
                      employeeNumberStats &&
                      employeeNumberStats['socialInsurance']
                    "
                    class="dashboard-small font-weight-bold black--text"
                    >{{
                      employeeNumberStats["socialInsurance"]["true"]
                        ? getPriceString(
                            employeeNumberStats["socialInsurance"]["true"]
                          )
                        : 0
                    }}</span
                  >
                  <span
                    v-else
                    class="dashboard-small font-weight-bold black--text"
                    >0</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </div></v-col
              >
              <v-col cols="3" class="pt-1"
                ><div class="mt-12">
                  <span class="text-app-detail-medium"
                    ><v-icon>mdi-close</v-icon> ไม่มีประกันสังคม</span
                  >
                </div>
                <div>
                  <span
                    v-if="
                      employeeNumberStats &&
                      employeeNumberStats['socialInsurance']
                    "
                    class="dashboard-small font-weight-bold black--text"
                    >{{
                      employeeNumberStats["socialInsurance"]["false"]
                        ? getPriceString(
                            employeeNumberStats["socialInsurance"]["false"]
                          )
                        : 0
                    }}</span
                  >
                  <span
                    v-else
                    class="dashboard-small font-weight-bold black--text"
                    >0</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </div></v-col
              >
              <v-divider vertical class="mb-1 mt-2" />
              <v-col cols="3" class="pl-6">
                <div>
                  <span class="text-app-detail-medium">คนนอก</span>
                </div>
                <div>
                  <span
                    v-if="
                      employeeNumberStats && employeeNumberStats['hiringType']
                    "
                    class="dashboard-large font-weight-bold black--text"
                    >{{
                      employeeNumberStats["hiringType"]["ภายนอก"]
                        ? getPriceString(
                            employeeNumberStats["hiringType"]["ภายนอก"]
                          )
                        : 0
                    }}</span
                  >
                  <span
                    v-else
                    class="dashboard-large font-weight-bold black--text"
                    >0</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </div>
                <div class="mt-0">
                  <span class="text-app-detail-normal"
                    >คิดเป็น {{ outsideEmpPercent.toFixed(2) }}%</span
                  >
                </div></v-col
              >
            </v-row>
            <v-divider class="my-3" />
            <v-row no-gutters class="pt-6">
              <v-col cols="12">
                <div v-if="isPermissionCanView">
                  <v-col
                    cols="12"
                    class="py-0 my-0 px-0"
                    v-if="seriesJobGroup.length > 0"
                  >
                    <span
                      v-for="(legend, idx) in seriesJobGroup"
                      :key="idx"
                      :style="{ color: `${chartOptions.fill.colors[idx]}` }"
                      class="text-app-detail-md"
                      >{{ legend.name
                      }}<span
                        class="text-app-detail-normal"
                        v-if="idx < seriesJobGroup.length - 1"
                      >
                        |
                      </span></span
                    >
                  </v-col>
                  <div id="chart" class="px-0">
                    <apexchart
                      type="bar"
                      height="100"
                      class="pl-0 ml-0"
                      :options="chartOptionsDriverOffice"
                      :series="seriesJobGroup"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col
                cols="6"
                class="pr-6"
                v-if="
                  isPermissionCanView &&
                  jobGroupDriverSubType[0]['data'].length > 0
                "
              >
                <div>
                  <span class="text-app-detail-medium">สัดส่วน Driver</span>
                </div>
                <div>
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="0"
                      :options="chartOptionsTypes('jobDataDriver')"
                      :series="jobGroupDriverSubType"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
              <v-divider vertical class="mb-6 mt-1" />
              <v-col
                cols="6"
                class="pl-6"
                v-if="
                  isPermissionCanView &&
                  jobGroupOfficeSubType[0]['data'].length > 0
                "
              >
                <div>
                  <span class="text-app-detail-medium">สัดส่วน Office</span>
                </div>
                <div>
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="0"
                      :options="chartOptionsTypes('jobDataOffice')"
                      :series="jobGroupOfficeSubType"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row dense class="mt-2">
        <v-col cols="12">
          <div class="mb-3" v-if="isPermissionCanView">
            <v-card elevation="2" rounded="lg" class="px-4 pb-3 pt-6">
              <v-row no-gutters align="center">
                <v-col cols="9" class="px-6">
                  <div>
                    <CustomFilterDateTypingPickerRange
                      placeholder="เลือก"
                      title="เลือกช่วงเวลา"
                      v-model="joinDate"
                      :isRequired="false"
                      :isSameLine="true"
                      :initRange="initRangeDate"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-card class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                สัดส่วนการทำรายได้
              </div>
            </v-card-title>
            <div class="text-app-detail-large mb-3">
              รายได้ครึ่งหนึ่ง มากจาก
              <span class="font-weight-bold success--text">{{
                sumTopListEmployees
              }}</span>
              คน
              <span class="font-weight-bold success--text"
                >({{ topListEmployeePercent.toFixed(2) }}%)</span
              >
              จากทั้งหมด
              <span class="font-weight-bold">{{
                sortedSeriesProsDesc.length
              }}</span>
              คน
            </div>
            <v-row
              no-gutters
              class="pt-0 pb-1"
              v-if="
                sortedSeriesProsDescShort !== null &&
                sortedSeriesProsDescShort.length > 0
              "
            >
              <v-col
                cols="2"
                class="pr-1 pb-1 new-tab"
                v-for="(data, idx) in sortedSeriesProsDescShort"
                :key="idx"
                @click="handleEmployeeReportClick(data._id, data.empId)"
              >
                <!-- <v-card outlined rounded="5" color="#49c496"></v-card> -->
                <v-card outlined rounded="5" :color="data['color']">
                  <v-sheet class="mt-2 pa-3" color="#fff">
                    <div class="one_row_only">
                      <span class="text-app-title font-weight-bold">{{
                        data["name"] ? data["name"] : "-"
                      }}</span>
                    </div>
                    <span class="text-app-title"
                      >{{
                        data["totalIncomePercent"]
                          ? data["totalIncomePercent"].toFixed(2)
                          : 0
                      }}% |
                      {{
                        data["totalIncome"]
                          ? numberWithCommasHelper(data["totalIncome"])
                          : 0
                      }}
                      บาท</span
                    >
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
            <v-card-text class="pa-5 text-center" v-else>
              ไม่มีข้อมูล
            </v-card-text>
            <v-row
              no-gutters
              class="mt-3"
              v-if="
                sortedSeriesProsDesc !== null &&
                sortedSeriesProsDesc.length > 18
              "
            >
              <v-spacer />
              <div @click="handleSeeMoreHeatMapDialog()">
                <span class="text-decoration-underline new-tab primary--text"
                  >เพิ่มเติม</span
                >
              </div>
              <v-spacer />
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="4" class="pr-6 pb-6">
            <v-card-title class="px-6 pb-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ (บริษัท) /ระยะทาง(กม.)
              </div>
            </v-card-title>
            <div class="text-app-detail-normal px-6">โดยเฉลี่ยต่อวัน</div>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptionsEvent('DISTANCE')"
                  :series="seriesDistance"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >ระยะทางโดยเฉลี่ยต่อวัน</span
            >
          </v-col>
          <v-divider class="my-6" vertical />
          <v-col cols="4" class="pl-3 pr-6 pb-6">
            <v-card-title class="px-6 pb-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ (บริษัท) /ชั่วโมงทำงาน
              </div>
            </v-card-title>
            <div class="text-app-detail-normal px-6">โดยเฉลี่ยต่อวัน</div>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptionsEvent('HOURS')"
                  :series="seriesHour"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >ชั่วโมงทำงานโดยเฉลี่ยต่อวัน</span
            >
          </v-col>
          <v-divider class="my-6" vertical />
          <v-col cols="4" class="px-3 pb-6">
            <v-card-title class="px-6 pb-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายได้สุทธิ (บริษัท) /จำนวนงาน
              </div>
            </v-card-title>
            <div class="text-app-detail-normal px-6">โดยเฉลี่ยต่อวัน</div>
            <div v-if="isPermissionCanView">
              <div id="chart">
                <apexchart
                  type="scatter"
                  height="250"
                  :options="chartOptionsEvent('JOBS')"
                  :series="seriesJob"
                ></apexchart>
              </div>
            </div>
            <span
              class="mx-12 text-app-small-detail font-weight-bold black--text"
              >จำนวนงานโดยเฉลี่ยต่อวัน</span
            >
          </v-col>
        </v-row>
      </v-card>
      <v-card class="px-4 mb-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                รายรับ/รายจ่าย
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="pr-9">
                <apexchart
                  type="line"
                  height="350"
                  :options="chartBreakEvenOptionsArea()"
                  :series="seriesBreakEvenArea"
                ></apexchart>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="px-4" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                อัตราการเข้าออกพนักงาน
              </div>
            </v-card-title>

            <v-row no-gutters class="pt-3">
              <v-col cols="2"
                ><div class="mt-1">
                  <span class="text-app-detail-medium">พนักงานใหม่</span>
                </div>
                <div>
                  <span
                    v-if="employeeEachStatus !== null"
                    class="dashboard-large font-weight-bold black--text"
                    >{{
                      employeeEachStatus["NEW"] ? employeeEachStatus["NEW"] : 0
                    }}</span
                  >
                  <span class="text-app-detail-normal"> คน</span>
                </div></v-col
              >
              <v-col cols="10">
                <v-row no-gutters>
                  <v-col cols="4" class="pr-6">
                    <v-card-title class="px-0 pt-0">
                      <div class="pt-0 text-title font-weight-bold black--text">
                        รายชื่อพนักงาน
                        <span :class="`${getStatusEmployeeColor('PROBATION')}`">
                          ทดลองงาน</span
                        >
                        {{
                          userListProbationAll ? userListProbationAll.length : 0
                        }}
                        คน
                      </div>
                    </v-card-title>
                    <div
                      v-if="
                        userListProbationTopList !== null &&
                        userListProbationTopList.length > 0
                      "
                    >
                      <v-simple-table class="pb-0">
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(
                                employee, idx
                              ) in userListProbationTopList"
                              :key="idx"
                            >
                              <td>
                                {{ idx + 1 }}
                              </td>
                              <td>
                                {{ employee["name"] }}
                              </td>
                              <td align="right">
                                {{
                                  dateStringToDateFormat(
                                    employee["date"].split("T")[0],
                                    {
                                      returnThai: false,
                                      type: "date",
                                    }
                                  ) || "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div class="text-right">
                        <v-btn
                          icon
                          @click="
                            handleStatusDialog(
                              'PROBATION',
                              userListProbationAll
                            )
                          "
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-card-text class="pa-5 text-center" v-else>
                      ไม่มีข้อมูล
                    </v-card-text>
                  </v-col>
                  <v-divider vertical class="mb-6 mt-3" />
                  <v-col cols="4" class="pl-6 pr-6">
                    <v-card-title class="px-0 pt-0">
                      <div class="pt-0 text-title font-weight-bold black--text">
                        รายชื่อพนักงาน
                        <span :class="`${getStatusEmployeeColor('RESIGNED')}`"
                          >ลาออก</span
                        >
                        {{
                          userListResignedAll ? userListResignedAll.length : 0
                        }}
                        คน
                      </div>
                    </v-card-title>
                    <div
                      v-if="
                        userListResignedTopList !== null &&
                        userListResignedTopList.length > 0
                      "
                    >
                      <v-simple-table class="pb-0">
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(employee, idx) in userListResignedTopList"
                              :key="idx"
                            >
                              <td>
                                {{ idx + 1 }}
                              </td>
                              <td>
                                {{ employee["name"] }}
                              </td>
                              <td align="right">
                                {{
                                  dateStringToDateFormat(
                                    employee["date"].split("T")[0],
                                    {
                                      returnThai: false,
                                      type: "date",
                                    }
                                  ) || "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div class="text-right">
                        <v-btn
                          icon
                          @click="
                            handleStatusDialog('RESIGNED', userListResignedAll)
                          "
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-card-text class="pa-5 text-center" v-else>
                      ไม่มีข้อมูล
                    </v-card-text>
                  </v-col>
                  <v-divider vertical class="mb-6 mt-3" />
                  <v-col cols="4" class="pl-6 pr-0">
                    <v-card-title class="px-0 pt-0">
                      <div class="pt-0 text-title font-weight-bold black--text">
                        รายชื่อพนักงาน
                        <span :class="`${getStatusEmployeeColor('TERMINATED')}`"
                          >เลิกจ้าง</span
                        >
                        {{
                          userListTerminatedAll
                            ? userListTerminatedAll.length
                            : 0
                        }}
                        คน
                      </div>
                    </v-card-title>
                    <div
                      v-if="
                        userListTerminatedTopList !== null &&
                        userListTerminatedTopList.length > 0
                      "
                    >
                      <v-simple-table class="pb-0">
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(
                                employee, idx
                              ) in userListTerminatedTopList"
                              :key="idx"
                            >
                              <td>
                                {{ idx + 1 }}
                              </td>
                              <td>
                                {{ employee["name"] }}
                              </td>
                              <td align="right">
                                {{
                                  dateStringToDateFormat(
                                    employee["date"].split("T")[0],
                                    {
                                      returnThai: false,
                                      type: "date",
                                    }
                                  ) || "-"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div class="text-right">
                        <v-btn
                          icon
                          @click="
                            handleStatusDialog(
                              'TERMINATED',
                              userListTerminatedAll
                            )
                          "
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-card-text class="pa-5 text-center" v-else>
                      ไม่มีข้อมูล
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="px-4 mt-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                จำนวนกระทำผิดรวม
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="3"
                ><div>
                  <span class="text-app-detail-medium">ทั้งหมด</span>
                </div>
                <div>
                  <span
                    v-if="
                      penaltyChartData && penaltyChartData[0]['data'].length > 0
                    "
                    class="dashboard-large font-weight-bold black--text"
                    >{{ penaltyTotal ? getPriceString(penaltyTotal) : 0 }}</span
                  >
                  <span
                    v-else
                    class="dashboard-large font-weight-bold black--text"
                    >0</span
                  >
                  <span class="text-app-detail-normal"> ครั้ง</span>
                </div></v-col
              >
              <v-col cols="9">
                <div
                  v-if="
                    isPermissionCanView &&
                    penaltyChartData[0]['data'].length > 0
                  "
                >
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="500"
                      :options="chartOptionsPenaltyTypes()"
                      :series="penaltyChartData"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <!-- 
            <v-row no-gutters>
              <v-col cols="6">
                <div v-if="isPermissionCanView">
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="500"
                      :options="chartOptionsPenaltyTypes"
                      :series="seriesPenaltyTypes"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
            </v-row> -->
            <v-row no-gutters>
              <v-col cols="8" class="pr-9">
                <div id="chartLine" class="pl-0">
                  <apexchart
                    type="line"
                    height="350"
                    :options="chartOptionsLinePenalty"
                    :series="penaltyGraphData"
                  ></apexchart>
                </div>
                <span
                  class="mx-12 px-0 text-app-small-detail font-weight-bold black--text"
                  >สัปดาห์</span
                >
              </v-col>
              <v-col cols="4">
                <v-card-title class="px-0">
                  <div class="pt-3 text-title font-weight-bold black--text">
                    รายชื่อพนักงานที่ทำผิดเกิน(กำหนด)
                  </div>
                </v-card-title>

                <v-simple-table
                  class="pb-6"
                  v-if="penaltyTopList !== null && penaltyTopList.length > 0"
                >
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(employee, idx) in penaltyTopList"
                        :key="idx"
                        class="new-tab"
                        @click="handlePenaltyClick(employee)"
                      >
                        <td>
                          {{ idx + 1 }}
                        </td>
                        <td>
                          {{ employee.name }}
                        </td>
                        <td align="right">
                          {{ (employee.value * -1).toFixed(2) }} คะแนน
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-text class="pa-5 text-center" v-else>
                  ไม่มีข้อมูล
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="px-4 mt-3" elevation="2" rounded="lg">
        <v-row no-gutters>
          <v-col cols="12" class="px-6 pb-6">
            <v-card-title class="px-0">
              <div class="pt-3 text-title font-weight-bold black--text">
                จำนวนการลางาน
              </div>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="3"
                ><div>
                  <span class="text-app-detail-medium">ทั้งหมด</span>
                </div>
                <div>
                  <span class="dashboard-large font-weight-bold black--text">{{
                    totalTimeOff ? totalTimeOff : 0
                  }}</span
                  ><span class="text-app-detail-normal"> วัน</span>
                </div></v-col
              >
              <v-col cols="9">
                <div v-if="isPermissionCanView">
                  <v-col
                    cols="12"
                    class="py-0 my-0 px-7"
                    v-if="timeOffChartData.length > 0"
                  >
                    <span
                      v-for="(legend, idx) in timeOffChartData"
                      :key="idx"
                      :style="{ color: `${chartOptions.fill.colors[idx]}` }"
                      class="text-app-detail-md"
                      >{{ legend.name
                      }}<span
                        class="text-app-detail-normal"
                        v-if="idx < timeOffChartData.length - 1"
                      >
                        |
                      </span></span
                    >
                  </v-col>
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="100"
                      :options="chartOptions"
                      :series="timeOffChartData"
                    ></apexchart>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row no-gutters>
              <v-col cols="8" class="pr-9">
                <div id="chartLine" class="pl-0">
                  <apexchart
                    type="line"
                    height="350"
                    :options="chartOptionsLine"
                    :series="timeOffGraphData"
                  ></apexchart>
                </div>
                <span
                  class="mx-12 px-0 text-app-small-detail font-weight-bold black--text"
                  >สัปดาห์</span
                >
              </v-col>
              <v-col cols="4">
                <v-card-title class="px-0">
                  <div class="pt-3 text-title font-weight-bold black--text">
                    รายชื่อพนักงานที่ลาเกิน(กำหนด)
                  </div>
                </v-card-title>

                <v-simple-table
                  class="pb-6"
                  v-if="timeOffTopList !== null && timeOffTopList.length > 0"
                >
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(employee, idx) in timeOffTopList"
                        :key="idx"
                        class="new-tab"
                        @click="handleTimeoffClick(employee)"
                      >
                        <td>
                          {{ idx + 1 }}
                        </td>
                        <td>
                          {{ employee.name }}
                        </td>
                        <td align="right">{{ employee.value }} วัน</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-text class="pa-5 text-center" v-else>
                  ไม่มีข้อมูล
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <AppDialogConfirm ref="alertSaving" />
      <AppDialogConfirm ref="dialogAlert" />
      <AppOverlay :isLoading="isLoading" />
      <AppPageNotFound :show="isPermissionCanView" />
      <EmployeeStatusPreviewDialog ref="employeeStatusPreviewDialog" />
      <EmployeeHeatMapPreviewDialog ref="employeeHeatMapPreviewDialog" />
    </v-container>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { getAppDataService } from "@/services/api/app";
import { errorMessageMapper } from "@/services/error";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
import _ from "lodash";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import { URL_VEHICLE_DOC_INS } from "@/services/routerQueryPath";
import { URL_EMPLOYEE_LIST } from "@/services/routerQueryPath";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import EmployeeStatusPreviewDialog from "./EmployeeStatusPreviewDialog.vue";
import EmployeeHeatMapPreviewDialog from "./EmployeeHeatMapPreviewDialog.vue";
import { numberWithCommasHelper } from "@/services/appNumberHelper";
import {
  getEmployeeReportPenaltyService,
  getEmployeeReportTimeoffService,
  getEmployeeReportStatsService,
  getEmployeeReportPercentService,
  getEmployeeIncomeService,
} from "@/services/api/report";

const EmployeeStatus = Object.freeze({
  APPLIED: "ขอสมัคร",
  REJECTED: "ไม่รับ",
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "EmployeeOverviewDashboard",
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    CustomAutocomplete,
    AppOverlay,
    CustomSelectsArrOfObj,
    CustomDateTypingPickerFormInput,
    AppDialogConfirm,
    AppFilesUploadVue,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomTextInputImprove,
    AppSubmitFormButton,
    FormDataUploadInput,
    CustomTextInputCurrency,
    AppComboboxVehicleSearching,
    CustomFilterDateTypingPickerRange,
    EmployeeStatusPreviewDialog,
    EmployeeHeatMapPreviewDialog,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "รายการพนักงาน",
          disabled: false,
          to: `/employee${URL_EMPLOYEE_LIST}`,
        },
        {
          text: "รายงานภาพรวมพนักงาน",
          disabled: true,
          to: "",
        },
      ],
      title: "เพิ่มข้อมูลประกันภัยรถ",

      valid: false,
      isPermissionCanView: false,
      isPermissionCanEdit: false,
      isLoading: false,
      docType: "ins",
      input: 1,
      companyType: "com_ins",
      docsCompanySelected: null,
      docsCompanyList: [],
      // vehicleList: null,
      _id: null,
      isFail: false,
      info: null,

      joinDate: [], // for date
      startDate: null,
      endDate: null,
      startDateCurrent: null,
      endDateCurrent: null,
      initRangeDate: 90,

      upFiles: [], // wait from appFile component
      upImages: [], // wait from appFile component
      localUpFile: [],
      localUpImages: [],
      timeOffTopList: [],
      penaltyTopList: [],
      penaltyAllList: [],

      userListProbationTopList: [],
      userListResignedTopList: [],
      userListTerminatedTopList: [],

      userListProbationAll: [],
      userListResignedAll: [],
      userListTerminatedAll: [],
      userListStatus: {},

      minProsData: 0,
      maxProsData: 0,
      midProsData: 0,

      colorTranparencyDesc: [],
      colorTranparencyAsc: [],
      randomEmployeeName: [],

      countDays: 90,
      avgTimeOff: 0,
      avgPenalty: 0,
      penaltyTotal: 0,
      totalTimeOff: 0,
      totalEmployee: 0,
      employeeEachStatus: {},

      employeeNumberStats: {},
      employeeHeatmap: [],
      employeeIncome: [],
      sortedSeriesProsDesc: [],
      sortedSeriesProsDescShort: [],
      timeOffChart: [],
      timeOffGraphData: [],
      penaltyGraphData: [],
      timeOffCategory: [],
      timeOffGraph: [],
      insideEmpPercent: 0,
      outsideEmpPercent: 0,

      sumTopListEmployees: 0,
      topListEmployeePercent: 0,
      employeeName: [],

      seriesDistance: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      seriesHour: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      seriesJob: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],

      timeOffChartCategory: [],
      timeOffChartData: [],

      penaltyGraph: [],
      penaltyChart: {},

      penaltyChartCategory: [],
      penaltyChartData: [
        {
          name: "",
          data: [],
        },
      ],

      jobGroup: {},
      jobGroupDriver: {},
      jobGroupOffice: {},
      jobGroupDriverTotal: 0,
      jobGroupOfficeTotal: 0,

      jobGroupDriverCategory: [],
      jobGroupDriverSubType: [
        {
          name: "",
          data: [],
        },
      ],
      jobGroupOfficeCategory: [],
      jobGroupOfficeSubType: [
        {
          name: "",
          data: [],
        },
      ],

      formData: {
        doc: null,
        vehicle: null,
        type: null,
        amount: null,
        company: null,
        issueDate: null,
        expiryDate: null,
        files: [],
        images: [],
      },
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {},
      distanceAvg: null,
      hourAvg: null,
      jobAvg: null,
      distanceXaxisRange: null,
      distanceYaxisRange: null,
      hourXaxisRange: null,
      hourYaxisRange: null,
      jobXaxisRange: null,
      jobYaxisRange: null,

      seriesPenaltyTypes: [
        {
          data: [12, 11, 11, 11, 10, 9, 8, 7, 6, 5, 4, 4, 3, 3, 2, 1, 0],
        },
      ],

      seriesBreakEvenArea: [
        {
          name: "รายรับ",
          data: [],
        },
        {
          name: "รายจ่าย",
          data: [],
        },
      ],

      xLabelList: [],

      seriesJobGroup: [
        {
          name: "Driver",
          data: [],
        },
        {
          name: "Office",
          data: [],
        },
      ],

      graphType: {
        DISTANCE: "ระยะทาง",
        HOURS: "ชั่วโมงทำงาน",
        JOBS: "จำนวนงาน",
      },
    };
  },

  computed: {
    ...mapGetters({
      vehicleTypeText: "vehicle/vehicleTypeText",
    }),

    // sortedSeriesProsDesc() {
    //   let range = Array.from({ length: 36 }, () =>
    //     Math.floor(Math.random() * 1234)
    //   );
    //   let sortedRange = range.sort((a, b) => b - a);
    //   // console.log("sortedRange", sortedRange);

    //   let gap = parseInt(100 / 18);
    //   // console.log("gap", gap);
    //   this.colorTranparencyAsc = [];
    //   for (let i = 18 - gap; i <= 100; i += gap) {
    //     let tran = Math.floor((i / 100) * 255).toString(16);
    //     this.colorTranparencyAsc.push("#ed5555" + tran.toString());
    //   }

    //   this.colorTranparencyDesc = [];
    //   for (let i = 100; i > 18 - gap; i -= gap) {
    //     let tran = Math.floor((i / 100) * 255).toString(16);
    //     this.colorTranparencyDesc.push("#49c496" + tran.toString());
    //   }

    //   if (!_.isNil(this.penaltyAllList) && this.penaltyAllList.length > 0) {
    //     for (let i = 0; i < this.colorTranparencyAsc.length * 2; i++) {
    //       let randomIdx = i % (this.penaltyAllList.length - 1);
    //       this.randomEmployeeName.push(this.penaltyAllList[randomIdx]);
    //     }
    //   }

    //   // console.log("randomEmployeeName", this.randomEmployeeName);

    //   return sortedRange;
    // },

    chartOptionsPros() {
      return {
        chart: {
          height: 350,
          type: "heatmap",
          fontFamily: "'Noto Sans Thai', sans-serif",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#fff"],
          },
          // style: {
          //   colors: ["#AAB7B8"],
          //   fontSize: "10px",
          // },
          formatter: (val, opts) => {
            // console.log(
            //   "randomIdx",
            //   Math.floor(Math.random()),
            //   this.penaltyAllList
            // );
            if (
              !_.isNil(this.penaltyAllList) &&
              this.penaltyAllList.length > 0
            ) {
              let randomIdx =
                Math.floor(Math.random()) % this.penaltyAllList.length;
              // console.log(
              //   "randomIdx",
              //   Math.floor(Math.random()),
              //   this.penaltyAllList
              // );
              return `${(val / 100).toFixed(2)} % | ${val} บาท`;
            }
            return ["AAA", `${(val / 100).toFixed(2)} % | ${val} บาท`];
          },
        },
        xaxis: {
          tickAmount: 6,
          categories: [1, 2, 3, 4, 5, 6],
          labels: {
            show: false,
          },

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        // colors: ["#ffbf00"],
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: this.minProsData,
                  to: this.midProsData,
                  name: "low",
                  color: "#00A100",
                },
                {
                  from: this.midProsData + 1,
                  to: this.maxProsData,
                  name: "extreme",
                  color: "#008FFB",
                },
              ],
            },
          },
        },
        title: {
          text: "",
        },
      };
    },
    chartOptionsLine() {
      return {
        chart: {
          height: 350,
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
        },
        annotations: {
          yaxis: [
            {
              y: this.avgTimeOff,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
              label: {
                text: `จำนวนวันลาเฉลี่ย: ${this.avgTimeOff}`,
              },
            },
            // {
            //   y: 3,
            //   strokeDashArray: 0,
            //   borderWidth: 0,
            //   borderColor: "#AAB7B8",
            //   label: {
            //     text: "ไม่มีข้อมูลการลา",
            //   },
            // },
          ],
        },

        tooltip: {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `สัปดาห์ที่ ${value}`;
            },
          },
        },

        noData: {
          text: this.totalTimeOff === 0 ? "ไม่มีข้อมูลการลา" : "",
          align: "center",
          verticalAlign: "middle",
        },
        stroke: {
          curve: "straight",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.timeOffCategory,
        },

        yaxis: {
          title: {
            text: "จำนวนวันลา",
            offsetX: 0,
            offsetY: -110,
          },
        },
      };
    },

    chartOptionsLinePenalty() {
      return {
        chart: {
          height: 350,
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
        },
        annotations: {
          yaxis: [
            {
              y: this.avgPenalty,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
              label: {
                text: `จำนวนกระทำผิดเฉลี่ย: ${this.avgPenalty}`,
              },
            },
          ],
        },
        tooltip: {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `สัปดาห์ที่ ${value}`;
            },
          },
        },
        stroke: {
          curve: "straight",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.penaltyCategory,
        },

        noData: {
          text: this.penaltyTotal === 0 ? "ไม่มีข้อมูลการกระทำผิด" : "",
          align: "center",
          verticalAlign: "middle",
        },

        yaxis: {
          title: {
            text: "จำนวนการกระทำผิด",
            offsetX: 0,
            offsetY: -95,
          },
        },
      };
    },

    chartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        // title: {
        //   text: "100% Stacked Bar",
        // },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          x: {
            show: false,
            // format: "HH:mm",
          },
          y: {
            formatter: function (val) {
              return val + " วัน";
            },
          },
        },

        fill: {
          opacity: 1,
          colors: [
            "#567DF4",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
          ],
        },
        noData: {
          text: this.totalTimeOff === 0 ? "ไม่มีข้อมูลการลา" : "",
          align: "center",
          verticalAlign: "middle",
        },
        legend: {
          show: false,
          // position: "top",
          // horizontalAlign: "center",
          // offsetX: 0,
        },
      };
    },

    chartOptionsDriverOffice() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          offsetX: -10,
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
            columnWidth: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        // title: {
        //   text: "100% Stacked Bar",
        // },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          x: {
            show: false,
            // format: "HH:mm",
          },
          y: {
            formatter: function (val) {
              return val + " คน";
            },
          },
        },

        fill: {
          opacity: 1,
          colors: [
            "#567DF4",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
            "#AAB7B8",
          ],
        },
        noData: {
          text: this.totalTimeOff === 0 ? "ไม่มีข้อมูลการลา" : "",
          align: "center",
          verticalAlign: "middle",
        },
        legend: {
          show: false,
          // position: "top",
          // horizontalAlign: "center",
          // offsetX: 0,
        },
      };
    },
  },
  watch: {
    upFiles(val) {
      // console.log(val);
    },
    upImages(val) {
      // console.log(val);
    },
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },

    joinDate(newDate, oldDate) {
      if (!_.isNil(newDate)) {
        if (newDate.length === 2) {
          this.startDate = newDate[0];
          this.endDate = newDate[1];
          this.countDays = moment
            .duration(moment(this.endDate).diff(moment(this.startDate)))
            .asDays();

          if (
            this.startDateCurrent !== this.startDate ||
            this.endDateCurrent !== this.endDate
          ) {
            let isSorted =
              moment(this.startDate).diff(moment(this.endDate)) <= 0;
            if (isSorted) {
              this.startDateCurrent = this.startDate;
              this.endDateCurrent = this.endDate;

              this.getData();
            }
          }
        }
      }
    },

    employeeNumberStats(value) {
      this.totalEmployee = 0;
      this.employeeEachStatus = {};
      if (!_.isNil(value) && value["status"]) {
        if (!_.isNil(value["status"]["eachStatus"])) {
          this.employeeEachStatus = _.clone(value["status"]["eachStatus"]);
          Object.keys(EmployeeStatus).forEach((key) => {
            if (!_.isNil(key) && value["status"]["eachStatus"][key]) {
              this.totalEmployee += value["status"]["eachStatus"][key];
            }
          });
        }
      }
    },

    employeeHeatmap(value) {
      // sortedSeriesProsDesc
      if (!_.isNil(value) && value.length > 0) {
        let seriesProsDesc = [];
        for (let i = 0; i < value.length; i++) {
          let empObj = {
            name: value[i]["name"],
            totalIncome: value[i]["totalIncome"],
            totalIncomePercent: value[i]["totalIncomePercent"],
            _id: value[i]["_id"],
            empId: value[i]["empId"],
          };
          seriesProsDesc.push(empObj);
        }
        this.sortedSeriesProsDesc = seriesProsDesc.sort(
          (a, b) => b["totalIncome"] - a["totalIncome"]
        );

        let maxIncome = Math.max(
          ...this.sortedSeriesProsDesc.map((o) => o.totalIncome)
        );
        let changePosition = 0.5;
        let currentPosition = 0;
        let sumPercent = 0;
        this.sumTopListEmployees = 0;
        this.topListEmployeePercent = 0;

        for (let i = 0; i < this.sortedSeriesProsDesc.length; i++) {
          currentPosition =
            this.sortedSeriesProsDesc[i]["totalIncome"] / maxIncome;
          if (sumPercent >= changePosition) {
            let tran = Math.floor((1 - currentPosition) * 255).toString(16);
            this.sortedSeriesProsDesc[i]["color"] = "#ed5555" + tran.toString();
          } else {
            let tran = Math.floor(currentPosition * 255).toString(16);
            this.sortedSeriesProsDesc[i]["color"] = "#49c496" + tran.toString();
            this.sumTopListEmployees += 1;
          }

          sumPercent +=
            this.sortedSeriesProsDesc[i]["totalIncomePercent"] / 100;
        }
        this.topListEmployeePercent =
          (100 * this.sumTopListEmployees) / this.sortedSeriesProsDesc.length;
        this.sortedSeriesProsDescShort = _.clone(
          this.sortedSeriesProsDesc.slice(0, 18)
        );

        // scatter
        let distanceUserData = [];
        let durationUserData = [];
        let jobAmountUserData = [];
        this.employeeName = [];

        let distanceX = [];
        let durationX = [];
        let jobX = [];
        let incomeY = [];

        for (let i = 0; i < value.length; i++) {
          distanceUserData.push([
            (value[i]["totalDistance"] / (this.countDays * 1000)).toFixed(2),
            (value[i]["totalIncome"] / this.countDays).toFixed(2),
          ]);
          durationUserData.push([
            (value[i]["totalDuration"] / (this.countDays * 3600)).toFixed(2),
            (value[i]["totalIncome"] / this.countDays).toFixed(2),
          ]);
          jobAmountUserData.push([
            (value[i]["amount"] / this.countDays).toFixed(2),
            (value[i]["totalIncome"] / this.countDays).toFixed(2),
          ]);
          this.employeeName.push(value[i]["name"]);

          distanceX.push(value[i]["totalDistance"] / (this.countDays * 1000));
          durationX.push(value[i]["totalDuration"] / (this.countDays * 3600));
          jobX.push(value[i]["amount"] / this.countDays);
          incomeY.push(value[i]["totalIncome"] / this.countDays);
        }

        let distanceXAvg = this.avg(distanceX);
        let durationXAvg = this.avg(durationX);
        let jobXAvg = this.avg(jobX);
        let incomeYAvg = this.avg(incomeY);

        let graphEvent = [
          { avg: [distanceXAvg, incomeYAvg], user: distanceUserData },
          { avg: [durationXAvg, incomeYAvg], user: durationUserData },
          { avg: [jobXAvg, incomeYAvg], user: jobAmountUserData },
        ];
        this.setGraphData(graphEvent, distanceX, durationX, jobX, incomeY);
      }
    },

    employeeIncome(value) {
      if (!_.isNil(value) && value.length > 0) {
        this.seriesBreakEvenArea = [
          {
            name: "รายรับ",
            data: [],
          },
          {
            name: "รายจ่าย",
            data: [],
          },
        ];

        this.xLabelList = [];
        for (let i = 0; i < value.length; i++) {
          this.seriesBreakEvenArea[0]["data"].push(value[i]["totalIncome"]);
          this.seriesBreakEvenArea[1]["data"].push(value[i]["totalRecompense"]);
          this.xLabelList.push(
            dateStringToDateFormat(value[i]["date"], {
              returnThai: false,
            })
          );
        }
      }
    },

    // "employeeNumberStats.graphs"(newGraphs, oldGraphs) {
    //   if (!_.isNil(newGraphs)) {
    //     this.setGraphData(newGraphs);
    //   }
    // },

    jobGroup(value) {
      if (!_.isNil(value)) {
        let data = [
          {
            name: "Driver",
            data: [value["driver"]["total"]],
          },
          {
            name: "Office",
            data: [value["office"]["total"]],
          },
        ];

        // this.seriesJobGroup = data.sort((a, b) => b.data[0] - a.data[0]);
        this.seriesJobGroup = _.cloneDeep(data);

        if (
          !_.isNil(value) &&
          !_.isNil(value["driver"]) &&
          !_.isNil(value["office"])
        ) {
          this.jobGroupDriver = value["driver"];
          this.jobGroupOffice = value["office"];
        } else {
          this.jobGroupDriver = {};
          this.jobGroupOffice = {};
        }
      }
    },

    timeOffGraph(value) {
      if (!_.isNil(value) && value["avg"] && value["dataList"]) {
        this.avgTimeOff = value["avg"].toFixed(2);
        this.timeOffGraphData = [
          {
            name: "จำนวนการลา",
            data: [],
          },
        ];
        if (!_.isNil(value["dataList"]) && value["dataList"].length > 0) {
          this.timeOffCategory = [];

          let sumWeekly = 0;
          let count = 0;
          for (let i = 0; i < value["dataList"].length; i++) {
            sumWeekly += value["dataList"][i];
            if (i % 7 === 0 || i === value["dataList"].length - 1) {
              count++;
              this.timeOffCategory.push(count.toString());
              this.timeOffGraphData[0]["data"].push(sumWeekly);
              sumWeekly = 0;
            }
          }
        }
      }
    },

    penaltyGraph(value) {
      if (!_.isNil(value) && value["avg"] && value["dataList"]) {
        this.avgPenalty = value["avg"].toFixed(2);
        this.penaltyGraphData = [
          {
            name: "จำนวนการกระทำผิด",
            data: [],
          },
        ];

        if (!_.isNil(value["dataList"]) && value["dataList"].length > 0) {
          this.penaltyCategory = [];

          let sumWeekly = 0;
          let count = 0;
          for (let i = 0; i < value["dataList"].length; i++) {
            sumWeekly += value["dataList"][i];
            if (i % 7 === 0 || i === value["dataList"].length - 1) {
              count++;
              this.penaltyCategory.push(count.toString());
              this.penaltyGraphData[0]["data"].push(sumWeekly.toFixed(2));
              sumWeekly = 0;
            }
          }
        }
      }
    },

    penaltyChart(value) {
      if (!_.isNil(value)) {
        this.penaltyChartCategory = [];
        this.penaltyChartData = [{ name: "", data: [] }];
        let items = _.clone(value);
        if (_.isNil(items) || Object.keys(items).length === 0) {
          return;
        }
        let sortedItems = items.sort((a, b) => b.value - a.value);

        for (let i = 0; i < sortedItems.length; i++) {
          this.penaltyChartCategory.push(sortedItems[i]["topic"]);
          this.penaltyChartData[0]["data"].push(
            sortedItems[i]["value"].toFixed(2)
          );
        }
      }
    },

    timeOffChart(value) {
      if (!_.isNil(value)) {
        // this.timeOffChartCategory = [];
        let chartData = [];
        for (let i = 0; i < value.length; i++) {
          // this.timeOffChartCategory.push(value[i]["type"]);
          chartData.push({
            name: value[i]["type"],
            data: [value[i]["value"]],
          });
        }

        this.timeOffChartData = chartData.sort((a, b) => b.data[0] - a.data[0]);
      }
    },

    jobGroupDriver(value) {
      if (!_.isNil(value) && !_.isNil(value["eachGroup"])) {
        this.jobGroupDriverTotal = value["total"];
        this.jobGroupDriverCategory = [];
        this.jobGroupDriverSubType = [
          {
            name: "",
            data: [],
          },
        ];

        let items = [];
        Object.keys(value["eachGroup"]).forEach((key) => {
          if (!_.isNil(key) && value["eachGroup"][key]) {
            items.push({ key: key, value: value["eachGroup"][key] });
          }
        });
        let sortedItems = items.sort((a, b) => b.value - a.value);
        // let sortedItem = items.sort((a, b) => b.data[0] - a.data[0]);
        for (let i = 0; i < sortedItems.length; i++) {
          let keyName =
            sortedItems[i]["key"] !== "null"
              ? this.vehicleTypeText(sortedItems[i]["key"])
              : "ไม่ระบุ";
          this.jobGroupDriverCategory.push(keyName);
          this.jobGroupDriverSubType[0]["data"].push(sortedItems[i]["value"]);
        }
      }
    },

    jobGroupOffice(value) {
      if (!_.isNil(value) && !_.isNil(value["eachGroup"])) {
        this.jobGroupOfficeTotal = value["total"];
        this.jobGroupOfficeCategory = [];
        this.jobGroupOfficeSubType = [
          {
            name: "",
            data: [],
          },
        ];

        let items = [];
        Object.keys(value["eachGroup"]).forEach((key) => {
          if (!_.isNil(key) && value["eachGroup"][key]) {
            items.push({ key: key, value: value["eachGroup"][key] });
          }
        });
        let sortedItems = items.sort((a, b) => b.value - a.value);

        for (let i = 0; i < sortedItems.length; i++) {
          let keyName =
            sortedItems[i]["key"] !== "null"
              ? sortedItems[i]["key"]
              : "ไม่ระบุ";
          this.jobGroupOfficeCategory.push(keyName);
          this.jobGroupOfficeSubType[0]["data"].push(sortedItems[i]["value"]);
        }
      }
    },

    userListStatus(value) {
      if (!_.isNil(value)) {
        if (!_.isNil(value["PROBATION"])) {
          this.userListProbationTopList = _.clone(
            value["PROBATION"].slice(0, 5)
          );
          this.userListProbationAll = _.clone(value["PROBATION"]);
        } else {
          this.userListProbationTopList = [];
          this.userListProbationAll = [];
        }

        if (!_.isNil(value["RESIGNED"])) {
          this.userListResignedTopList = _.clone(value["RESIGNED"].slice(0, 5));
          this.userListResignedAll = _.clone(value["RESIGNED"]);
        } else {
          this.userListResignedTopList = [];
          this.userListResignedAll = [];
        }

        if (!_.isNil(value["TERMINATED"])) {
          this.userListTerminatedTopList = _.clone(
            value["TERMINATED"].slice(0, 5)
          );
          this.userListTerminatedAll = _.clone(value["TERMINATED"]);
        } else {
          this.userListTerminatedTopList = [];
          this.userListTerminatedAll = [];
        }
      } else {
        this.userListProbationTopList = [];
        this.userListProbationAll = [];
        this.userListResignedTopList = [];
        this.userListResignedAll = [];
        this.userListTerminatedTopList = [];
        this.userListTerminatedAll = [];
      }
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    numberWithCommasHelper: numberWithCommasHelper,
    startPage() {
      if (this.$route.params.id !== undefined) {
        // this.title = "แก้ไขข้อมูลประกันภัยรถ";
        this._id = this.$route.params.id;
        this.empId = this.$route.params.empId;
      } else {
        this._id = null;
        this.empId = null;
        // this.title = "เพิ่มข้อมูลประกันภัยรถ";
      }

      if (!_.isNil(this.empId)) {
        this.getSearchItem({ value: this.empId });
      }
      this.initDate();
      // this.getData();
    },
    initDate() {
      let start = moment()
        .subtract(this.initRangeDate, "days")
        .format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      this.startDate = start;
      this.endDate = end;
      this.joinDate = [start, end];
    },

    async getData() {
      if (this.isPermissionCanView) {
        // Stat
        this.isLoading = true;
        let params = new URLSearchParams({
          start: this.startDate,
          end: this.endDate,
        }).toString();

        // this.totalEmployee = 0;
        this.totalTimeOff = 0;
        this.penaltyTotal = 0;
        this.employeeNumberStats = {};
        this.employeeHeatmap = [];
        this.employeeIncome = [];

        let respStatsData = await getEmployeeReportStatsService(
          this.$store,
          params
        );
        if (respStatsData["code"] === 200) {
          if (!_.isNil(respStatsData["data"])) {
            this.employeeNumberStats = _.clone(respStatsData["data"]);
            this.userListStatus = respStatsData["data"]["userList"];

            if (!_.isNil(this.employeeNumberStats)) {
              this.jobGroup = _.clone(this.employeeNumberStats["jobGroup"]);
              if (!_.isNil(this.employeeNumberStats["hiringType"])) {
                let total =
                  this.employeeNumberStats["hiringType"]["ภายใน"] +
                  this.employeeNumberStats["hiringType"]["ภายนอก"];

                this.insideEmpPercent =
                  (this.employeeNumberStats["hiringType"]["ภายใน"] * 100) /
                  total;
                this.outsideEmpPercent =
                  (this.employeeNumberStats["hiringType"]["ภายนอก"] * 100) /
                  total;
              }
            }
          } else {
            this.employeeNumberStats = {};
            this.insideEmpPercent = 0;
            this.outsideEmpPercent = 0;
          }
        } else {
          this.isFail = true;
        }
        this.isLoading = false;

        let respPercentData = await getEmployeeReportPercentService(
          this.$store,
          params
        );

        // console.log("respPercentData", respPercentData);
        if (respPercentData["code"] === 200) {
          if (!_.isNil(respPercentData["data"])) {
            this.employeeHeatmap = _.clone(respPercentData["data"]);
          } else {
            this.employeeHeatmap = [];
          }
        } else {
          this.isFail = true;
        }

        // incomes
        let respIncomeData = await getEmployeeIncomeService(
          this.$store,
          params
        );
        // console.log("respIncomeData", respIncomeData);
        if (respIncomeData["code"] === 200) {
          if (!_.isNil(respIncomeData["data"])) {
            this.employeeIncome = _.clone(respIncomeData["data"]["timeseries"]);
          } else {
            this.employeeIncome = [];
          }
        } else {
          this.isFail = true;
        }

        // TimeOff

        let respTimeoffData = await getEmployeeReportTimeoffService(
          this.$store,
          params
        );
        if (respTimeoffData["code"] === 200) {
          if (!_.isNil(respTimeoffData["data"])) {
            this.totalTimeOff = _.clone(
              respTimeoffData["data"]["stat"]["total"]
            );
            this.timeOffTopList = _.clone(
              respTimeoffData["data"]["stat"]["topList"].slice(0, 5)
            );
            this.timeOffChart = _.clone(
              respTimeoffData["data"]["stat"]["chart"]
            );

            this.timeOffGraph = _.clone(
              respTimeoffData["data"]["stat"]["graph"]
            );
          } else {
            this.totalTimeOff = 0;
            this.timeOffTopList = [];
            this.timeOffChart = {};
            this.timeOffGraph = [];
          }
        } else {
          this.isFail = true;
        }

        // Penalty
        let respPenaltyData = await getEmployeeReportPenaltyService(
          this.$store,
          params
        );
        if (respPenaltyData["code"] === 200) {
          if (!_.isNil(respPenaltyData["data"])) {
            this.penaltyTotal = _.clone(respPenaltyData["data"]["time"]);
            this.penaltyChart = _.clone(
              respPenaltyData["data"]["percentChart"]
            );
            this.penaltyTopList = _.clone(
              respPenaltyData["data"]["topList"].slice(0, 5)
            );
            this.penaltyAllList = _.clone(respPenaltyData["data"]["topList"]);
            // console.log("penaltyAllList", this.penaltyAllList);
            this.penaltyGraph = _.clone(respPenaltyData["data"]["graph"]);
          } else {
            this.penaltyTotal = 0;
            this.penaltyChart = {};
            this.penaltyTopList = [];
            this.penaltyAllList = [];
            this.penaltyGraph = {};
          }
        } else {
          this.isFail = true;
        }
      }
    },

    avg(array) {
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
        sum += parseInt(array[i]);
      }
      return sum / array.length;
    },

    setGraphData(val, distanceX, durationX, jobX, incomeY) {
      this.distanceAvg = val[0].avg;
      this.hourAvg = val[1].avg;
      this.jobAvg = val[2].avg;

      // distance
      this.distanceXaxisRange = [
        Math.min(val[0].avg[0] / 1000, Math.min(...distanceX)) - 3,
        Math.max(val[0].avg[0] / 1000, Math.max(...distanceX)) + 3,
      ];
      this.distanceYaxisRange = [
        Math.min(val[0].avg[1], Math.min(...incomeY)) - 100,
        Math.max(val[0].avg[1], Math.max(...incomeY)) + 100,
      ];

      // duration
      this.hourXaxisRange = [
        Math.min(val[1].avg[0] / 3600, Math.min(...durationX)) - 3,
        Math.max(val[1].avg[0] / 3600, Math.max(...durationX)) + 3,
      ];
      this.hourYaxisRange = [
        Math.min(val[1].avg[1], Math.min(...incomeY)) - 100,
        Math.max(val[1].avg[1], Math.max(...incomeY)) + 100,
      ];

      // job amount
      this.jobXaxisRange = [
        Math.min(val[2].avg[0], Math.min(...jobX)) - 3,
        Math.max(val[2].avg[0], Math.max(...jobX)) + 3,
      ];
      this.jobYaxisRange = [
        Math.min(val[2].avg[1], Math.min(...incomeY)) - 100,
        Math.max(val[2].avg[1], Math.max(...incomeY)) + 100,
      ];

      // console.log(
      //   this.distanceXaxisRange,
      //   this.distanceXaxisRange,
      //   this.hourXaxisRange,
      //   this.hourYaxisRange,
      //   this.jobXaxisRange,
      //   this.jobYaxisRange
      // );

      this.seriesDistance = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: val[0]["user"],
        },
      ];
      this.seriesHour = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: val[1]["user"],
        },
      ];
      this.seriesJob = [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: val[2]["user"],
        },
      ];
    },

    chartOptionsEvent(val) {
      let graphAnnotation = {};
      let graphXaxis = {};
      let graphYaxis = {};
      let tooltipInfo = {};
      if (val === "DISTANCE") {
        graphAnnotation = {
          yaxis: [
            {
              y: this.distanceAvg
                ? this.distanceAvg[1]
                  ? this.distanceAvg[1].toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.distanceAvg
                ? this.distanceAvg[0]
                  ? this.distanceAvg[0].toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };
        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.distanceXaxisRange ? this.distanceXaxisRange[0] : null,
          max: this.distanceXaxisRange ? this.distanceXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.distanceYaxisRange ? this.distanceYaxisRange[0] : null,
          max: this.distanceYaxisRange ? this.distanceYaxisRange[1] : null,
          title: {
            text: "รายได้โดยเฉลี่ยต่อวัน",
            offsetX: 0,
            offsetY: -42,
          },
        };
        tooltipInfo = {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `ระยะทางโดยเฉลี่ยต่อวัน: ${value}`;
            },
          },
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `${this.employeeName[opts.dataPointIndex]}: ${value} บาท`;
            },
          },
        };
      } else if (val === "HOURS") {
        graphAnnotation = {
          yaxis: [
            {
              y: this.hourAvg
                ? this.hourAvg[1]
                  ? this.hourAvg[1].toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.hourAvg
                ? this.hourAvg[0]
                  ? this.hourAvg[0].toFixed(2)
                  : 0
                : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };

        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.hourXaxisRange ? this.hourXaxisRange[0] : null,
          max: this.hourXaxisRange ? this.hourXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.hourYaxisRange ? this.hourYaxisRange[0] : null,
          max: this.hourYaxisRange ? this.hourYaxisRange[1] : null,
          title: {
            text: "รายได้โดยเฉลี่ยต่อวัน",
            offsetX: 0,
            offsetY: -42,
          },
        };
        tooltipInfo = {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `ชั่วโมงทำงานโดยเฉลี่ยต่อวัน: ${value}`;
            },
          },
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `${this.employeeName[opts.dataPointIndex]}: ${value} บาท`;
            },
          },
        };
      } else {
        graphAnnotation = {
          yaxis: [
            {
              y: this.jobAvg ? (this.jobAvg[1] ? this.jobAvg[1] : 0) : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
          xaxis: [
            {
              x: this.jobAvg ? (this.jobAvg[0] ? this.jobAvg[0] : 0) : 0,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#AAB7B8",
            },
          ],
        };

        graphXaxis = {
          tickAmount: 10,
          floating: false,
          min: this.jobXaxisRange ? this.jobXaxisRange[0] : null,
          max: this.jobXaxisRange ? this.jobXaxisRange[1] : null,
        };
        graphYaxis = {
          tickAmount: 7,
          // max: 200,
          floating: false,
          min: this.jobYaxisRange ? this.jobYaxisRange[0] : null,
          max: this.jobYaxisRange ? this.jobYaxisRange[1] : null,
          title: {
            text: "รายได้โดยเฉลี่ยต่อวัน",
            offsetX: 0,
            offsetY: -42,
          },
        };
        tooltipInfo = {
          x: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `จำนวนงานโดยเฉลี่ยต่อวัน: ${value}`;
            },
          },
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              return `${this.employeeName[opts.dataPointIndex]}: ${value} บาท`;
            },
          },
        };
      }

      return {
        chart: {
          height: 250,
          type: "scatter",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
          // events: {
          //   click: (event, chartContext, config) => {
          //     console.log("config", config)
          //     if (
          //       config.seriesIndex === 1 &&
          //       this.sortedSeriesProsDesc !== null &&
          //       this.sortedSeriesProsDesc.length > 0
          //     ) {

          //       let data =
          //         config.config.series[config.seriesIndex]["data"][
          //           config.dataPointIndex
          //         ];
          //       this.handleEmployeeReportClick(data._id, data.empId);
          //     }
          //   },
          // },
        },
        legend: { show: false },
        grid: {
          show: false,
        },
        markers: {
          colors: ["#567DF4"],
          size: [7],
        },
        tooltip: tooltipInfo,
        annotations: graphAnnotation,
        xaxis: graphXaxis,
        yaxis: graphYaxis,
      };
    },

    chartBreakEvenOptionsArea() {
      return {
        chart: {
          height: 350,
          type: "line",
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return `${numberWithCommasHelper(val)}`;
          },
        },
        stroke: {
          width: [5, 2],
          curve: "smooth",
          dashArray: [0, 5],
        },
        // colors: ["#49c496", "#ed5555"],
        colors: ["#567DF4", "#AAB7B8"],
        xaxis: {
          categories: this.xLabelList,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${numberWithCommasHelper(value)}`;
            },
          },
        },
        // annotations: {
        //   yaxis: this.annotationYaxis,
        //   points: this.annotationPoints,
        //   //[
        //   // {
        //   //   x: "1",
        //   //   y: 5000,
        //   //   marker: {
        //   //     size: 0,
        //   //   },
        //   //   label: {
        //   //     borderColor: "#AAB7B8",
        //   //     offsetY: 0,
        //   //     style: {
        //   //       color: "#fff",
        //   //       background: "#AAB7B8",
        //   //     },
        //   //     text: "1,234",
        //   //   },
        //   // },
        //   // {
        //   // ]
        // },
        tooltip: {
          x: {
            show: true,
            // formatter: function (value, opts) {
            //   if (opts) {
            //     // var val =
            //     //   opts.w.config.series[opts.seriesIndex]["data"][
            //     //     opts.dataPointIndex
            //     //   ];
            //     // console.log("val", val);
            //     return `<span class="text-app-title font-weight-bold black--text">สัปดาห์ที่ ${
            //       opts.dataPointIndex + 1
            //     }</span>`;
            //   }
            // },
          },
          y: {
            formatter: (val) => {
              return `${numberWithCommasHelper(val)} บาท`;
            },
          },
        },
      };
    },

    chartOptionsTypes(type) {
      let chartCategoryType = [];
      let groupTotal = 0;
      if (type === "jobDataDriver") {
        chartCategoryType = this.jobGroupDriverCategory;
        groupTotal = this.jobGroupDriverTotal;
      } else if (type === "jobDataOffice") {
        chartCategoryType = this.jobGroupOfficeCategory;
        groupTotal = this.jobGroupOfficeTotal;
      }
      let chartHeight = 0;
      if (chartCategoryType.length > 0) {
        chartHeight = chartCategoryType.length * 25 + 75;
      }
      return {
        chart: {
          type: "bar",
          height: chartHeight,
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
            dataLabels: {
              enabled: false,
            },
          },
        },
        // plotOptions: {
        //   bar: {
        //     barHeight: "100%",
        //     distributed: true,
        //     horizontal: true,
        //     dataLabels: {
        //       position: "bottom",
        //     },
        //   },
        // },
        // colors: ["#AAB7B8"],
        colors: [
          "#567DF4",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
        ],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        noData: {
          text: groupTotal === 0 ? "ไม่มีข้อมูล" : "",
          align: "center",
          verticalAlign: "middle",
        },
        tooltip: {
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              // let numGroup = 0;
              // if (!_.isNil(groupTotal) && groupTotal > 0) {
              //   numGroup = parseInt((value * groupTotal) / 100);
              // }
              return `${value} คน`;
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (val) {
              if (parseInt(val) !== val) {
                return val.toFixed(2);
              }
              return val;
            },
          },
          categories: chartCategoryType,
        },
        yaxis: {
          labels: {
            maxWidth: 260,
          },
        },
      };
    },
    chartOptionsPenaltyTypes() {
      let chartCategoryType = this.penaltyChartCategory;
      return {
        chart: {
          type: "bar",
          parentHeightOffset: 0,
          height: 200,
          toolbar: {
            show: false,
          },
          fontFamily: "'Noto Sans Thai', sans-serif",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
            dataLabels: {
              enabled: false,
            },
          },
        },
        // plotOptions: {
        //   bar: {
        //     barHeight: "100%",
        //     distributed: true,
        //     horizontal: true,
        //     dataLabels: {
        //       position: "bottom",
        //     },
        //   },
        // },
        // colors: ["#AAB7B8"],
        colors: [
          "#567DF4",
          "#567DF4",
          "#567DF4",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
          "#AAB7B8",
        ],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        noData: {
          text: this.penaltyTotal === 0 ? "ไม่มีข้อมูลการกระทำผิด" : "",
          align: "center",
          verticalAlign: "middle",
        },
        tooltip: {
          y: {
            show: true,
            // format: "HH:mm",
            formatter: (value, opts) => {
              let numPenalty = 0;
              if (!_.isNil(this.penaltyTotal) && this.penaltyTotal > 0) {
                numPenalty = parseInt((value * this.penaltyTotal) / 100);
              }
              return `${value} % | ${numPenalty} ครั้ง`;
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (val) {
              return val + "%";
            },
          },
          categories: chartCategoryType,
        },
        yaxis: {
          labels: {
            maxWidth: 260,
          },
        },
      };
    },

    measure(lat1, lon1, lat2, lon2) {
      // generally used geo measurement function
      var R = 6378.137; // Radius of earth in KM
      var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
      var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d * 1000; // meters
    },

    getUniqueLatLong(array2D) {
      let latLongReformat2D = [];
      let latLongReformat2DCount = [];
      for (let i = 0; i < array2D.length; i++) {
        for (let j = 0; j < array2D[i].length; j++) {
          // console.log(array2D[i][j], latLongReformat2D);
          if (latLongReformat2D.length == 0) {
            latLongReformat2D.push(array2D[i][j]);
            latLongReformat2DCount.push(1);
          } else {
            for (let k = 0; k < latLongReformat2D.length; k++) {
              if (
                latLongReformat2D[k][0] === array2D[i][j][0] &&
                latLongReformat2D[k][1] === array2D[i][j][1]
              ) {
                latLongReformat2DCount[k] += 1;
                break;
              }

              if (k === latLongReformat2D.length - 1) {
                latLongReformat2D.push(array2D[i][j]);
                latLongReformat2DCount.push(1);
                break;
              }
            }
          }
        }
      }
      return [latLongReformat2D, latLongReformat2DCount];
    },

    getMergedLongLat(array2D, heatmap) {
      // let latLongReformat2D = [];
      // let latLongReformat2DCount = [];
      let LoS = [];
      let LoE = [];
      let M = [];
      let H = [];

      for (let i = 0; i < array2D.length; i++) {
        for (let j = 0; j < array2D[i].length; j++) {
          LoS.push(array2D[i][0]);
          LoE.push(array2D[i][array2D[i].length - 1]);
          M.push(array2D[i][j]);
        }
      }
      for (let i = 0; i < M.length; i++) {
        for (let k = 0; k < heatmap[0].length; k++) {
          if (heatmap[0][k][0] === M[i][0] && heatmap[0][k][1] === M[i][1]) {
            H.push(heatmap[1][k]);
            break;
          }
        }
      }
      // console.log(LoS, LoE, M, H);
      // return { latLongReformat2D, latLongReformat2DCount };
    },

    processHeatMapData(decimalDigit) {
      let latLong = [
        [
          [13.283996016365414, 100.91565842381155],
          [13.284087491203762, 100.91637118143441],
          [13.284659206173815, 100.91752256428792],
          [13.285284289508398, 100.92049108423691],
          [13.285520594633576, 100.92187744109233],
          [13.285558711850191, 100.92224556888739],
          [13.285894115120382, 100.92403921701396],
          [13.286153292930365, 100.92539424378072],
        ],
        [
          [13.284604258469397, 100.91748827810605],
          [13.284047632368031, 100.91641590501062],
          [13.283885280978653, 100.91569622538404],
          [13.284636729582742, 100.91490981800331],
          [13.285615467340687, 100.91410911145952],
          [13.286343715517928, 100.91349905325565],
          [13.289456147474155, 100.91091106087003],
        ],
      ];
      let latLongReformat = [];
      let numDec = decimalDigit.toString().split(".")[1].length || 2;
      for (let i = 0; i < latLong.length; i++) {
        let routeLoc = [];
        for (let j = 0; j < latLong[i].length; j++) {
          let re = latLong[i][j].map((x) => parseFloat(x.toFixed(numDec)));
          routeLoc.push(re);
        }
        latLongReformat.push(routeLoc);
      }
      // console.log("latLongReformat", latLongReformat);
      let uniqueLatLong = this.getUniqueLatLong(latLongReformat);
      this.getMergedLongLat(latLongReformat, uniqueLatLong);
      // let mergeLongLat = this.getMergedLongLat(latLongReformat);
      // console.log("uniqueLatLong", uniqueLatLong);
    },

    async handleStatusDialog(status, topList) {
      if (!_.isNil(status) && !_.isNil(topList)) {
        const respStatus = await this.$refs.employeeStatusPreviewDialog.open(
          status,
          topList
        );
      }
    },

    async handleSeeMoreHeatMapDialog() {
      if (!_.isNil(this.sortedSeriesProsDesc)) {
        const respStatus = await this.$refs.employeeHeatMapPreviewDialog.open(
          this.sortedSeriesProsDesc,
          this.sumTopListEmployees,
          this.topListEmployeePercent
        );
      }
    },

    async handleEmployeeReportClick(_id, empId) {
      this.isLoading = true;
      if (!_.isNil(_id) && !_.isNil(empId)) {
        let penaltyTypeURL = `/employee/dashboard_personal/${_id}&${empId}`;
        window.open(penaltyTypeURL, "_blank");
      }

      this.isLoading = false;
    },

    async handlePenaltyClick(emp) {
      this.isLoading = true;
      if (!_.isNil(emp["empId"])) {
        let employeeId = encodeURIComponent(emp["empId"]);
        let penaltyEmpIdURL = `/employee/penality/list?limit=20&by=createddate&order=desc&page=1&text=${employeeId}`;
        window.open(penaltyEmpIdURL, "_blank");
      }
      this.isLoading = false;
    },

    async handleTimeoffClick(emp) {
      this.isLoading = true;
      if (!_.isNil(emp["empId"])) {
        let employeeId = encodeURIComponent(emp["empId"]);
        let timeoffEmpIdURL = `/employee/timeoff/list?limit=20&by=createddate&order=desc&page=1&text=${employeeId}&idname=${employeeId}`;
        window.open(timeoffEmpIdURL, "_blank");
      }
      this.isLoading = false;
    },

    // ==== for app upload ====
    remove_upfile(index) {
      this.$refs.mulFilesUpload.removeInput(index);
    },
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    // ====
    remove_localImagefile(atLocation) {
      this.localUpImages.splice(atLocation, 1);
    },
    remove_localUpfile(atLocation) {
      this.localUpFile.splice(atLocation, 1);
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },
    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async callUploadImageErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },
    getStatusEmployeeText(status) {
      switch (status) {
        case "APPLIED":
          return EmployeeStatus.APPLIED;
        case "REJECTED":
          return EmployeeStatus.REJECTED;
        case "PROBATION":
          return EmployeeStatus.PROBATION;
        case "ACTIVE":
          return EmployeeStatus.ACTIVE;
        case "RESIGNED":
          return EmployeeStatus.RESIGNED;
        case "TERMINATED":
          return EmployeeStatus.TERMINATED;
        case "FIRED":
          return EmployeeStatus.FIRED;
        default:
          return null;
      }
    },

    getStatusEmployeeColor(status) {
      switch (status) {
        case "APPLIED":
          return "subtitle--text darken-2";
        case "REJECTED":
          return "subtitle--text darken-4";
        case "PROBATION":
          return "primary--text";
        case "ACTIVE":
          return "success--text";
        case "RESIGNED":
          return "warning--text";
        case "TERMINATED":
          return "error--text lighten-1";
        case "FIRED":
          return "error--text darken-1";
        default:
          return null;
      }
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (
          this.permList.admin_all === true ||
          this.permList.user_total_report === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },
    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },

    resetFormData() {
      this.countDays = 90;
      this.avgTimeOff = 0;
      this.penaltyTotal = 0;
      this.joinDate = [];
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.startPage();
    // this.sortedSeries();
    this.processHeatMapData(0.0001);
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}

.new-tab {
  position: relative;
  cursor: pointer;
}

.image_list_float_left {
  float: right;
}
.dashboard-large {
  font-size: 80px !important;
}

.dashboard-medium {
  font-size: 60px !important;
}
.dashboard-small {
  font-size: 30px !important;
}

.one_row_only {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
