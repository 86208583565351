<template>
	<v-card elevation="2" rounded="lg">
		<v-card-title>
			<div class="text-title font-weight-bold black--text">กำไรสุทธิ</div>
		</v-card-title>
		<apexchart
			:series="series"
			:options="chartOptions"
			:height="350"
		></apexchart>
	</v-card>
</template>

<script>
	import VueApexCharts from "vue-apexcharts";
	import {
		nFormatterHelper,
		processWeekYearStringList,
	} from "@/services/appSummaryHelper";
	import {
		displayNumberLocaleString,
		numberWithCommasHelper,
	} from "@/services/appNumberHelper";

	export default {
		name: "VehicleInformationNetProfitChart",
		components: {
			apexchart: VueApexCharts,
		},
		props: {
			data: {},
		},
		data() {
			return {
				series: [],
				annotationYaxis: [],
				avgIncome: null,
				avgExpenses: null,
				categories: [],
			};
		},
		watch: {
			data: function (newValue) {
				this.processData();
			},
		},
		computed: {
			chartOptions() {
				return {
					chart: {
						type: "area",
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
					},
					dataLabels: {
						enabled: true,
						formatter: (value, opt) => {
							// console.log(opt);
							return `${numberWithCommasHelper(
								displayNumberLocaleString(value, 2)
							)} บาท`;
						},
					},
					colors: ["#49c496", "#ed5555"],
					stroke: {
						curve: "smooth",
					},
					annotations: {
						yaxis: this.annotationYaxis,
					},
					grid: {
						show: false,
					},
					xaxis: {
						categories: this.categories,
						tooltip: {
							enabled: false,
						},
					},
					yaxis: {
						title: {
							text: "จำนวนเงิน",
							offsetX: 0,
						},
						labels: {
							formatter: (value) => {
								return `${nFormatterHelper(value, 2)}`;
							},
						},
					},
					tooltip: {
						x: {
							show: true,
						},
						y: {
							formatter: (val) => {
								return `${numberWithCommasHelper(
									displayNumberLocaleString(val, 2)
								)} บาท`;
							},
						},
					},
				};
			},
		},
		methods: {
			processData() {
				if (!_.isNil(this.data)) {
					const _week = _.get(this.data, "incomeAndExpensesWeekly", []);
					let _income = [];
					let _expenses = [];
					for (let i = 0; i < _week.length; i++) {
						_income.push(_week[i]["totalIncome"]);
						_expenses.push(_week[i]["totalExpenses"]);
					}

					this.avgIncome = _.get(this.data, "totalAverageIncome", 0);
					this.avgExpenses = _.get(this.data, "totalAverageExpenses", 0);

					this.annotationYaxis = [
						{
							y: this.avgIncome,
							strokeDashArray: 5,
							borderWidth: 2,
							borderColor: "#AAB7B8",
							label: {
								text: `ค่าเฉลี่ยรายได้ ${
									numberWithCommasHelper(
										displayNumberLocaleString(this.avgIncome, 2)
									) || 0
								} บาท`,
							},
						},
						{
							y: this.avgExpenses,
							strokeDashArray: 5,
							borderWidth: 2,
							borderColor: "#AAB7B8",
							label: {
								text: `ค่าเฉลี่ยรายจ่าย ${
									numberWithCommasHelper(
										displayNumberLocaleString(this.avgExpenses, 2)
									) || 0
								} บาท`,
							},
						},
					];

					this.categories = processWeekYearStringList(_week);
					this.series = [
						{
							name: "รายได้",
							data: _income,
						},
						{
							name: "รายจ่าย",
							data: _expenses,
						},
					];
				}
			},
		},
		mounted() {
			this.processData();
		},
	};
</script>
