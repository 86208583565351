<template>
  <v-container fluid>
    <div v-if="isPermissionCanEdit">
      <v-row class="mb-1" dense>
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการหลักฐานเพิ่มเติม</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="6">
          <JobSearchingInput
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="JobSearchingInputRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row pr-4" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>
            <v-row no-gutters>
              <v-col cols="9" class="pa-0">
                <CustomFilterDateTypingPickerRange
                  v-if="initDate"
                  v-model="joinDate"
                  class="pr-6"
                  placeholder="เลือก"
                  title="เลือกช่วงเวลา"
                  :isRequired="false"
                  :isSameLine="true"
                  :isShowTitle="false"
                  :initRange="initRangeDate"
                />
              </v-col>
              <v-checkbox
                dense
                class="pl-2 pt-1"
                label="ยังไม่ได้ตรวจ"
                v-model="checkboxData.nonverify"
                @change="changeValueOfCheckboxStatus()"
                hide-details
              ></v-checkbox>
            </v-row>

            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <div>
            {{
              dateStringToDateFormat(item.createdAt, {
                returnThai: false,
                type: "datetime",
              }) || "-"
            }}
          </div>
        </template>

        <template v-slot:[`item.driver`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-avatar>
              <v-img
                :src="
                  item.driver.profileImage === null
                    ? defaultPFImage
                    : item.driver.profileImage
                "
              >
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-app-title font-weight-bold">
                <span> {{ item.driver.dpNameTh }}</span>
              </v-list-item-title>
              <span class="text-app-normal">
                {{ item.empId }}
              </span>
              <span class="text-app-normal">
                {{ item.driver.email === null ? "-" : `${item.driver.email}` }}
              </span>
              <p class="text-app-normal mb-0">
                {{ item.driver.dpPhoneNumeber }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.job`]="{ item }">
          <!-- for assigner -->
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <!-- <v-list-item-title
              v-if="
                permList.jobs_info || permList.jobs_crud || permList.admin_all
              "
              class="text-app-title font-weight-bold primary--text"
              style="cursor: pointer"
              @click="handleEditClick(item._id)"
            >
              {{ item.name }}
            </v-list-item-title> -->
              <v-list-item-title
                class="text-app-title font-weight-bold primary--text"
              >
                {{ item.job.jobName }}
              </v-list-item-title>
              <p class="text-app-normal">
                {{ item.job.contractName }} : {{ item.job.contractTitle }}
              </p>
              <p class="text-app-normal">รหัสงาน: {{ item.job.jobCode }}</p>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <span class="text-app-title"> {{ item.desc }} </span>
        </template>

        <template v-slot:[`item.view`]="{ item }">
          <v-menu
            :close-on-content-click="false"
            :open-on-hover="true"
            max-height="300"
            offset-x
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small color="primary" v-on="on" v-bind="attrs">
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>

            <v-card height="300" width="500" class="overflow-auto">
              <v-img
                v-for="(img, idx) in item.img"
                :key="`${item._id}-${idx}`"
                :src="img"
              />
            </v-card>
          </v-menu>
        </template>

        <template v-slot:[`item.cost`]="{ item }">
          <span class="text-app-title">
            {{ displayNumberLocaleString(item.cost, 2) }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <EvidencePaymentSelectedStatus
            :item="item"
            :key="`epss_${item._id}`"
            :main-loading="actionMainLoading"
            @set-main-loading="setMainLoading"
            @set-verify-at-index="setVerifyAtIndex"
          />
        </template>

        <template v-slot:[`item.verify`]="{ item }">
          <v-icon v-if="item.verify" color="success">mdi-check-decagram</v-icon>
        </template>
      </v-data-table>
    </div>

    <AppPageNotFound v-if="!isPermissionCanEdit" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";

import { dateStringToDateFormat } from "@/services/appDate";

import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import JobSearchingInput from "@/views/jobs/components/JobsSearchingInput.vue";

import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";

// import EmployeePenaltySearchInput from "@/views/employee/components/EmployeePenaltySearchInput.vue";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import moment from "moment";
import { displayNumberLocaleString } from "@/services/appNumberHelper";

import { getJobEvidencePaymentListService } from "@/services/api/job_evidence_payment";
import EvidencePaymentSelectedStatus from "./components/EvidencePaymentSelectedStatus.vue";

export default {
  name: "EvidenceListView",
  components: {
    AppDialogConfirm,
    PrimaryButton,
    NormalButton,
    SortButton,
    AppAutoCompleteUserSearching,
    AppPageNotFound,
    // EmployeePenaltySearchInput,
    CustomFilterDateTypingPickerRange,
    EvidencePaymentSelectedStatus,
    JobSearchingInput,
  },
  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
    ],
    defaultPFImage: defaultImage,
    tableLoading: false,
    joinDate: [], // for date
    startDate: null,
    endDate: null,
    startDateCurrent: null,
    endDateCurrent: null,
    initRangeDate: null,
    initDate: false,

    serverItemsLength: 0,
    dataList: [],
    titleList: [],
    queryFilterList: {},

    queryStringSearch: "", // combin filter and search text
    querySearchAndFilterList: {}, // combine filter and search
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    sortVal: null,
    autoCompleteLoading: false,
    autoCompleteList: [],
    searchEmployeeList: null,
    searchCountTimer: undefined,
    //
    permList: {
      admin_all: false,
    },
    isPermissionCanEdit: false,
    actionMainLoading: false,
    checkboxData: {
      nonverify: 0,
    },
    //
  }),

  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanEdit) {
      this.processRouterQuery(this.$route.query);
    }
  },

  watch: {
    joinDate(newDate, oldDate) {
      if (!_.isNil(newDate)) {
        if (newDate.length === 2) {
          this.startDate = newDate[0];
          this.endDate = newDate[1];
          this.countDays = moment
            .duration(moment(this.endDate).diff(moment(this.startDate)))
            .asDays();

          if (
            this.startDateCurrent !== this.startDate ||
            this.endDateCurrent !== this.endDate
          ) {
            let isSorted =
              moment(this.startDate).diff(moment(this.endDate)) <= 0;
            if (isSorted) {
              this.startDateCurrent = this.startDate;
              this.endDateCurrent = this.endDate;

              this.queryOptionsTable["startdate"] = this.startDate;
              this.queryOptionsTable["enddate"] = this.endDate;

              this.querySearchAndFilterList = {
                ...this.querySearchAndFilterList,
                ...this.queryOptionsTable,
              };

              this.querySearchAndFilterList["page"] = 1;
              this.queryOptionsTable["page"] = 1;

              this.setRouterQuery(this.querySearchAndFilterList);
              this.getData();
            }
          }
        }
      }
    },
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    displayNumberLocaleString: displayNumberLocaleString,
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "startdate":
              case "enddate":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "text":
                this.querySearchAndFilterList[[key]] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "nonverify":
                this.checkboxData[key] = _queryRoute[key] === "1" ? 1 : 0;
                if (this.checkboxData[key] === 1) {
                  this.querySearchAndFilterList[key] = 1;
                }
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }

      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.initRangeDate = 90;
      this.initDate = true;
    },

    // === end action button of table
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },

    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["fieldid"]; // id
      delete this.querySearchAndFilterList["text"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isUndefined(_searchingParameter["key"])) {
          _queryParameter["fieldid"] = _searchingParameter.key;
          _queryParameter["text"] = _searchingParameter.value;
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter.value;
          }
        }
      }

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryFilterList = _queryParameter;
      // console.log("_queryParameter", _queryParameter);

      this.processSearchingParams(_queryParameter);
      this.getData();

      // Object.keys(this.queryOrder).forEach((key) => {
      //   delete this.querySearchAndFilterList[key];
      // });
      // this.processSearchingParams(_queryParameter);
      // this.getData();
    },
    //
    //  end method scope
    //
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
        this.queryStringSearch = "";
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.$store.dispatch("updateReloadViewKey");
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        if (
          this.permList.admin_all === true ||
          this.permList.payment_evidence_crud
        ) {
          this.isPermissionCanEdit = true;
        }
      }
    },
    changeValueOfCheckboxStatus() {
      let searchObj = {};
      Object.keys(this.checkboxData).forEach((key) => {
        if (this.checkboxData[key]) {
          searchObj[key] = "1";
        } else {
          delete this.querySearchAndFilterList[key];
        }
      });

      this.processSearchingParams(searchObj);
      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    async getData() {
      this.tableLoading = true;
      this.dataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      const responseData = await getJobEvidencePaymentListService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData.code == 200) {
        this.serverItemsLength = responseData.data["total"];
        let dataList = responseData.data["dataList"];
        for (let i = 0; i < dataList.length; i++) {
          dataList[i]["i"] = i;
        }
        this.dataList = dataList;
      } else {
        this.dataList = [];
      }
      this.tableLoading = false;
    },

    setMainLoading(value) {
      this.actionMainLoading = value;
    },
    setVerifyAtIndex(newItem) {
      let currentData = _.cloneDeep(this.dataList);
      currentData[newItem["i"]] = newItem;
      this.dataList = currentData;
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "employee/vehicleStatusColor",
      empStatusColor: "employee/empStatusColor",
      vehicleStatusText: "employee/vehicleStatusText",
      empStatusText: "employee/empStatusText",
      empPenaltyStatusColor: "employee/empPenaltyStatusColor",
      empPenaltyStatusTextColor: "employee/empPenaltyStatusTextColor",
      empPenaltyStatusText: "employee/empPenaltyStatusText",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "วันที่สร้าง",
          align: "start",
          sortable: false,
          value: "createdAt",
          width: "10%",
        },
        {
          text: "ชื่อ-นามสกุล",
          align: "start",
          sortable: false,
          value: "driver",
          width: "10%",
        },
        {
          text: "ชื่องาน",
          align: "start",
          sortable: false,
          value: "job",
          width: "20%",
        },
        {
          text: "คำอธิบาย",
          align: "start",
          sortable: false,
          value: "desc",
          width: "15%",
        },
        {
          text: "จำนวนเงิน",
          align: "start",
          sortable: false,
          value: "cost",
        },
        {
          text: "",
          value: "view",
          sortable: false,
          align: "center",
        },
      ];

      if (this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
          width: "20%",
        });
      }

      defaultdata.push({
        text: "",
        value: "verify",
        sortable: false,
        align: "center",
      });

      return defaultdata;
    },
    tableData() {
      return this.dataList;
    },
  },
};
</script>
<style scoped></style>
