const errorMessageMapper = {
  BAD_REQUEST: "ไม่สามารถทำงานได้",
  ERR_NETWORK: "ไม่สามารถเชื่อมต่อ Server ได้",
  MISSING_PARAMETER: "ค่าพารามิเตอร์หายไป",
  INVALID_USERNAME_OR_PASSWORD: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
  INVALID_PASSWORD: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
  EMAIL_ALREAY_BE_USED: "อีเมลล์ถูกใช้งานแล้ว",
  PASSWORD_IS_TOO_SHORT: "รหัสผ่านสั้นเกินไป ต้องมากกว่า 8 ตัว",
  VEHICLE_ALREAY_BE_USED: "ทะเบียนหรือรหัสตัวถังซ้ำกัน",
  INTERNAL_SERVER_ERROR: "เกิดข้อผิดพลาดไม่สามารถเชื่อมต่อได้",
  ID_NOT_FOUND: "ไม่พบข้อมูล",
  FILE_KEY_NOT_FOUND: "ไม่พบข้อมูลไฟล์",
  INVALID_FILE_TYPE: "ประเภทของไฟล์ไม่ถูกต้อง",
  FILE_TOO_LARGE: "ไฟล์มีขนาดใหญ่เกินไป",
  FORBIDDEN: "ไม่มีสิทธิเข้าถึงข้อมูล",
  //
  LOGIN_EMAIL_AND_PASSWORD_NOT_FOUND: "กรุณากรอกอีเมล และรหัสผ่าน",
  LOGIN_EMAIL_REQUIRED: "กรุณากรอกชื่อผู้ใช้ (อีเมล์ หรือ เบอร์โทรศัพท์)",
  LOGIN_PASSWORD_REQUIRED: "กรุณากรอกรหัสผ่าน",
  // JOB
  JOB_ERROR_CANNOT_VEHICLE_ADD_DRIVER:
    "ไม่สามารถเพิ่มรถได้ กรุณาลบและเลือกผู้ขับใหม่อีกครั้ง",
  JOB_ADD_DRIVER_NOT_READY_T1:
    "บุคคลนี้มีสถานะเปลี่ยนแปลงกระทันหัน โปรดมอบหมายให้บุคคลอื่น (ผู้ขับมีงานอื่่นอยู่)",
  JOB_ADD_DRIVER_NOT_READY_T2:
    "บุคคลนี้มีสถานะเปลี่ยนแปลงกระทันหัน โปรดมอบหมายให้บุคคลอื่น (ผู้ขับลางาน)",
};

module.exports = {
  errorMessageMapper,
};
