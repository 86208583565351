<template>
	<div name="vehicleTaxManage">
		<AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

		<v-container>
			<div
				name="vehicleInformationForm"
				class="mb-5"
				v-if="isPermissionCanView"
			>
				<v-form ref="form" v-model="valid">
					<CustomCardFormViewModern
						:title="title"
						:isShowTitle="true"
						:isTitleHighlight="true"
					>
						<v-row no-gutters class="px-6">
							<v-col cols="12">
								<v-card elevation="0" class="px-0">
									<v-row no-gutters>
										<v-col cols="6">
											<InputView
												class="px-3"
												title="เลขป้ายทะเบียน"
												:isRequired="false"
											>
												<AppComboboxVehicleSearching
													ref="AppAutoCompleteVehicleSearchingRef"
													v-model="vehicleSearch"
													:disabled="!isPermissionCanEdit"
													:isRequired="false"
												/>
											</InputView>
										</v-col>
										<v-col cols="6">
											<CustomAutocomplete
												v-model="formData.company"
												class="custom-placeholer-color"
												title="บริษัท GPS"
												placeholder="บริษัท GPS"
												:isRequired="true"
												:items="docsCompanyList"
												menu-props="auto"
												:disabled="!isPermissionCanEdit"
												selectionTextIf="nameTh"
												itemTextListTextIf="nameTh"
												itemText="nameTh"
												itemValue="key"
												:returnObject="true"
												:rules="[(v) => !!v || 'จำเป็นต้องระบุบริษัท GPS']"
											>
											</CustomAutocomplete>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											<CustomTextInputImprove
												ref="gpsSerialNumber"
												v-model="formData.serialNumber"
												title="Serial Number"
												placeholder="Serial Number ของ GPS ที่ใช้งาน"
												:isRequired="true"
												:rules="[(v) => !!v || 'จำเป็นต้องระบุ Serial Number']"
											></CustomTextInputImprove>
										</v-col>
										<v-col cols="6">
											<!-- <CustomAutocompleteBase
												name="sheet-selector"
												title="โมเดล GPS"
												:items="gpsModelList"
												v-model="formData.model"
												:disabled="!isPermissionCanEdit || isDeepBox"
												:isRequired="!isDeepBox"
												:rules="
													!isDeepBox
														? [(v) => !!v || 'จำเป็นต้องเลือกโมเดล GPS']
														: []
												"
											/> -->

											<AppAutoCompleteLocalSearchingGPSModel
												class="px-3 pb-3"
												ref="AppAutoCompleteLocalSearchingGPSModelRef"
												title="โมเดล GPS"
												v-model="formData.model"
												placeholder="เลือกโมเดล GPS หรือระบุใหม่"
												:items="gpsModelList"
												:tableLoading="isLoading"
												:disabled="!isPermissionCanEdit || isDeepBox"
												:isRequired="!isDeepBox"
												:rules="
													!isDeepBox
														? [(v) => !!v || 'จำเป็นต้องเลือกโมเดล GPS']
														: []
												"
											/>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											<CustomTextInputCurrency
												data-input-name="cost"
												v-model="formData.cost"
												title="ค่าใช้จ่าย (บาท)"
												placeholder="ค่าใช้จ่าย"
												ref="gpsCost"
												:is-required="true"
												:readonly="!isPermissionCanEdit"
												:rules="[(v) => !!v || 'จำเป็นต้องระบุค่าใช้จ่าย']"
											>
											</CustomTextInputCurrency>
										</v-col>
										<v-col cols="6">
											<CustomDateTypingPickerFormInput
												placeholder="เลือก"
												title="ณ วันที่ (วัน/เดือน/ปี)"
												v-model="formData.lastPaidDate"
												:disabled="!isPermissionCanEdit"
												:isRequired="true"
												:rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่']"
											/>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											<CustomDateTypingPickerFormInput
												placeholder="เลือก"
												title="วันหมดอายุรอบถัดไป (วัน/เดือน/ปี)"
												v-model="formData.expDate"
												:disabled="!isPermissionCanEdit || isDeepBox"
												:isRequired="!isDeepBox"
												:rules="
													!isDeepBox
														? [
																(v) =>
																	!!v || 'จำเป็นต้องเลือกวันหมดอายุรอบถัดไป',
														  ]
														: []
												"
											/>
										</v-col>
									</v-row>
									<v-row no-gutters class="px-3">
										<v-col cols="12">
											<FormDataUploadInput
												ref="gpsCRUDUploadFile"
												component-name="vehicleCRUDUploadFileForm"
												title="ชุดเอกสาร (pdf)"
												placeholder="เพิ่มเอกสารกดที่นี่"
												:uploaded-data="formData.files"
												:is-permission-can-edit="!isPermissionCanEdit"
												uploadTag="VEHICLE_GPS"
											/>
										</v-col>
									</v-row>
									<v-row dense class="px-3">
										<v-col cols="12">
											<FormDataUploadInput
												ref="gpsCRUDUploadImageFile"
												component-name="vehicleCRUDUploadImageFileForm"
												title="รูปภาพ (jpg, png)"
												placeholder="เพิ่มรูปภาพที่นี่"
												:isImageType="true"
												:uploaded-data="formData.images"
												:is-permission-can-edit="!isPermissionCanEdit"
												uploadTag="VEHICLE_GPS"
											/>
										</v-col>
									</v-row> </v-card></v-col
						></v-row>
						<AppSubmitFormButton @on-click-save="saveChange()" />
					</CustomCardFormViewModern>
				</v-form>
				<AppDialogConfirm ref="alertSaving" />
				<AppDialogConfirm ref="dialogAlert" />
			</div>
			<AppOverlay :isLoading="isLoading" />
			<AppPageNotFound :show="isPermissionCanView" />
		</v-container>
	</div>
</template>
<script>
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import AppOverlay from "@/components/AppOverlay.vue";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
	import { getAppDataService } from "@/services/api/app";
	import CustomAutocompleteBase from "@/components/CustomAutocompleteBase.vue";
	import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
	import { DialogType } from "@/services/dialog";
	import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
	import { errorMessageMapper } from "@/services/error";
	import InputView from "@/components/InputView.vue";
	import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
	import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
	import {
		getGpsInfoService,
		getGPSModelService,
		postGPSService,
		putGPSService,
	} from "@/services/api/vehicle";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { processPermission } from "@/services/permissions";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
	import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
	import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
	import { URL_VEHICLE_DOC_GPS } from "@/services/routerQueryPath";
	import AppAutoCompleteLocalSearchingGPSModel from "@/components/AppAutoCompleteLocalSearchingGPSModel.vue";

	export default {
		name: "VehicleTaxManage",
		components: {
			CustomCardFormViewModern,
			AppOverlay,
			CustomAutocomplete,
			AppDialogConfirm,
			CustomDateTypingPickerFormInput,
			AppFilesUploadVue,
			InputView,
			AppBreadCrumbs,
			AppPageNotFound,
			CustomTextInputImprove,
			AppSubmitFormButton,
			FormDataUploadInput,
			CustomTextInputCurrency,
			AppComboboxVehicleSearching,
			CustomAutocompleteBase,
			AppAutoCompleteLocalSearchingGPSModel,
		},
		data() {
			return {
				breadcrumbsItems: [
					{
						text: "รายการ GPS",
						disabled: false,
						to: `/vehicle${URL_VEHICLE_DOC_GPS}`,
						exact: true,
					},
					{
						text: "จัดการข้อมูล GPS",
						disabled: true,
						to: "",
					},
				],
				isPermissionCanEdit: false,
				isPermissionCanView: false,
				title: "เพิ่มข้อมูล GPS",
				valid: false,
				isLoading: false,
				// vehicleList: null,
				vehicleSearch: null,
				input: 1,
				companyType: "gps_com",
				docsCompanyList: [],
				gpsModelList: [],
				isFail: false,
				_id: null,

				upFiles: [], // wait from appFile component
				upImages: [], // wait from appFile component
				localUpFile: [],
				localUpImages: [],
				isDeepBox: false,

				formData: {
					doc: null,
					vehicle: null,
					type: null,
					cost: null,
					company: null,
					deepBox: null,
					serialNumber: null,
					model: null,
					issueDate: null,
					lastPaidDate: null,
					expDate: null,
					files: [],
					images: [],
				},
				alertOptions: {
					color: "grey lighten-3",
					width: 400,
					zIndex: 200,
					noconfirm: false,
				},
				permList: {
					admin_all: false,
					vehicle_list: false,
					vehicle_info: false,
					vehicle_info_crud: false,
				},
			};
		},
		watch: {
			upFiles(val) {
				// console.log(val);
			},
			upImages(val) {
				// console.log(val);
			},
			"$route.params.id"(newId, oldId) {
				if (newId !== undefined) {
					this.startPage();
				}
			},
			"formData.company"(newCom) {
				if (!_.isNil(newCom)) {
					if (
						!_.isNil(this.formData["company"]["key"]) &&
						this.formData["company"]["key"] == "gpscom00"
					) {
						this.isDeepBox = true;
					} else {
						this.isDeepBox = false;
					}
				}
			},
			vehicleSearch(value) {
				if (!_.isNil(value)) {
					if (!_.isString(value)) {
						this.formData["vehicle"] = _.cloneDeep(value);
					} else {
						this.formData["vehicle"] = null;
					}
				} else {
					this.formData["vehicle"] = null;
				}
			},
		},
		methods: {
			startPage() {
				if (this.$route.params.id !== undefined) {
					this.title = "แก้ไขข้อมูล GPS";
					this._id = this.$route.params.id;
				} else {
					this._id = null;
					this.title = "เพิ่มข้อมูล GPS";
				}
				this.getData();
			},
			async getData() {
				this.isLoading = true;

				if (this.isPermissionCanView) {
					// get vehicle list
					// let respVelist = await getVehicleDocsVelist(this.$store);
					// if (respVelist["code"] === 200) {
					//   this.vehicleList = respVelist.data;
					// } else {
					//   this.isFail = true;
					// }

					if (!this.isFail) {
						// get company list
						this.docsCompanyList = await getAppDataService(
							this.$store,
							this.companyType
						);

						// get company list
						this.gpsModelList = await getGPSModelService(this.$store);
						// edit
						if (this._id !== null) {
							let params = new URLSearchParams({
								id: this._id,
								input: this.input,
							}).toString();
							let respData = await getGpsInfoService(this.$store, params);
							if (respData["code"] === 200) {
								this.formData = _.clone(respData["data"]);
								if (!_.isNil(this.formData["vehicle"])) {
									this.vehicleSearch = _.cloneDeep(this.formData["vehicle"]);
								}

								let cost = parseInt(respData["data"]["cost"]);
								this.formData["cost"] = _.clone(cost);

								let paidDate = this.getStringDate(
									respData["data"]["lastPaidDate"]
								);
								this.formData["lastPaidDate"] = _.clone(paidDate);

								let expDate = this.getStringDate(respData["data"]["expDate"]);
								this.formData["expDate"] = _.clone(expDate);

								if (
									!_.isNil(this.formData["company"]["key"]) &&
									this.formData["company"]["key"] == "gpscom00"
								) {
									this.isDeepBox = true;
								} else {
									this.isDeepBox = false;
								}

								// reset
								this.upImages = [];
								this.upFiles = [];
								this.localUpFile = [];
								this.localUpImages = [];
								this.localUpFile = _.clone(this.formData["files"]);
								this.localUpImages = _.clone(this.formData["images"]);
							} else {
								this.isFail = true;
							}
						}
					}
				}

				this.isLoading = false;
			},

			getSearchItemByKeyName(key) {
				console.log("key", key);
			},

			getStringDate(date) {
				if (!_.isNil(date)) {
					let strDate = date.split("T")[0];
					return strDate;
				}
				return null;
			},

			// async callApi(val) {
			// 	let responseData = await getVehicleBySeachingService(this.$store, val);
			// 	if (responseData["code"] === 200) {
			// 		this.itemList = responseData["data"];
			// 	} else {
			// 		this.itemList = [];
			// 	}
			// 	this.loading = false;
			// },

			getSavingFormData() {
				// console.log("formData", this.formData);
				let savedFormData = {
					vehicleId: this.formData["vehicle"]
						? _.cloneDeep(this.formData["vehicle"]["key"])
						: undefined,
					company: _.cloneDeep(this.formData["company"]),
					model: this.isDeepBox ? "" : _.cloneDeep(this.formData["model"]),
					cost: _.cloneDeep(this.formData["cost"]),
					expDate: this.isDeepBox ? "" : _.cloneDeep(this.formData["expDate"]),
					lastPaidDate: _.cloneDeep(this.formData["lastPaidDate"]),
					files: _.cloneDeep(this.formData["files"]),
					images: _.cloneDeep(this.formData["images"]),
					serialNumber: _.cloneDeep(this.formData["serialNumber"]),
				};

				return savedFormData;
			},

			async saveChange() {
				if (this.$refs.form.validate() === true) {
					this.isLoading = true;
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});

					let _toUpdateByAPI = false;
					let resUploadFile = await this.$refs["gpsCRUDUploadFile"].upload();
					let respUploadImage = await this.$refs[
						"gpsCRUDUploadImageFile"
					].upload();

					// check file upload is faill alert error
					// TODO::
					if (!resUploadFile.status || !respUploadImage.status) {
						// is upload has error response restore all file
						this.callUploadFileErrorDialog();
						this.$refs["gpsCRUDUploadFile"].restoreFile();
						this.$refs["gpsCRUDUploadImageFile"].restoreFile();
						// this.disableLoading();
					} else {
						const sendingFormData = this.getSavingFormData();
						sendingFormData["files"] = resUploadFile["result"];
						sendingFormData["images"] = respUploadImage["result"];

						
						if (respUploadImage["result"].length > 0) {
								delete respUploadImage["result"][0]["src"];
						}
						sendingFormData["images"] = respUploadImage["result"];

						if (resUploadFile["result"].length > 0) {
								delete resUploadFile["result"][0]["src"];
						}
						sendingFormData["files"] = resUploadFile["result"];

						if (this._id !== null) {
							// edit
							let resp = await putGPSService(
								this.$store,
								this._id,
								sendingFormData
							);
							if (resp["code"] === 200) {
								this.isLoading = false;
								this.alertSaveChange(this._id);
								_toUpdateByAPI = true;
								this.getData();
							} else {
								_toUpdateByAPI = false;
							}
						} else {
							// add
							let resp = await postGPSService(this.$store, sendingFormData);
							this.isLoading = false;
							if (resp["code"] === 200) {
								_toUpdateByAPI = true;
								this.alertSaveChange(this._id);
								this.resetFormData();
								this.$router.push(`/vehicle${URL_VEHICLE_DOC_GPS}`);
							} else {
								_toUpdateByAPI = false;
							}
						}
					}
					if (_toUpdateByAPI === false) {
						// alert fail to update data
						this.$refs["gpsCRUDUploadFile"].restoreFile();
						this.$refs["gpsCRUDUploadImageFile"].restoreFile();
					}
					this.isLoading = false;
				}
			},

			// ==== for app upload ====
			remove_upfile(index) {
				this.$refs.mulFilesUpload.removeInput(index);
			},
			remove_imagefile(index) {
				this.$refs.mulImagesUpload.removeInput(index);
			},
			// ====
			remove_localImagefile(atLocation) {
				this.localUpImages.splice(atLocation, 1);
			},
			remove_localUpfile(atLocation) {
				this.localUpFile.splice(atLocation, 1);
			},

			async callUploadFileErrorDialog(respMessage) {
				let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
				for (let i = 0; i < respMessage.length; i++) {
					custom_error_text =
						custom_error_text + errorMessageMapper[respMessage[i]];
				}
				await this.$refs.dialogAlert.open(
					custom_error_text,
					null,
					DialogType.ERROR,
					{
						noconfirm: false,
					}
				);
				// clear data
			},

			async callUploadImageErrorDialog(respMessage) {
				let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
				for (let i = 0; i < respMessage.length; i++) {
					custom_error_text =
						custom_error_text + errorMessageMapper[respMessage[i]];
				}
				await this.$refs.dialogAlert.open(
					custom_error_text,
					null,
					DialogType.ERROR,
					{
						noconfirm: false,
					}
				);
				// clear data
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "VEHICLE_EXPENSES");
					if (
						this.permList.admin_all === true ||
						this.permList.vehicle_info_crud === true
					) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
					}
				}
			},

			async alertSaveChange(_id) {
				if (_id !== null) {
					await this.$refs.alertSaving.open(
						"แก้ไขข้อมูลสำเร็จ!",
						null,
						DialogType.POSITIVE,
						this.alertOptions
					);
				} else {
					await this.$refs.alertSaving.open(
						"เพิ่มข้อมูลสำเร็จ!",
						null,
						DialogType.POSITIVE,
						this.alertOptions
					);
				}
			},
			resetFormData() {
				this.formData = {
					doc: null,
					vehicle: null,
					type: null,
					cost: null,
					company: null,
					issueDate: null,
					lastPaidDate: null,
					expiryDate: null,
					files: [],
					images: [],
				};
				this.clearInput();
			},
			clearInput() {
				this.docsCompanyList = [];
				this.gpsModelList = [];
				// this.vehicleList = null;
				this.vehicleSearch = null;
				this.upFiles = [];
				this.upImages = [];
				this.localUpFile = [];
				this.localUpImages = [];
			},
		},
		mounted() {
			this.checkUserPermissions();
			this.startPage();
		},
	};
</script>
<style scoped>
	.container {
		max-width: 1500px;
	}
</style>
