<template>
  <div name="vehicleFuelPaymentFilterGroup">
    <v-card flat rounded="lg">
      <HeaderCardNormal title="ตัวกรอง"></HeaderCardNormal>
      <div class="pa-6">
        <v-row>
          <v-col cols="12">
            <InputView title="ค่าใช้จ่าย" :isRequired="false">
              <v-row>
                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    type="number"
                    v-model="formData.minAmount"
                    placeholder="ต่ำสุด"
                    :isRequired="false"
                  />
                </v-col>
                <p class="title-filter pt-5">—</p>

                <v-col class="px-0 pt-1">
                  <CustomTextInputImprove
                    type="number"
                    v-model="formData.maxAmount"
                    placeholder="สูงสุด"
                    :isRequired="false"
                  />
                </v-col>
              </v-row>
            </InputView>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <div class="title-filter">ช่วงเวลาที่เติม</div>
            <v-menu
              v-model="menuFilterCreatedAtRange"
              :close-on-content-click="false"
              :nudge-left="60"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="createdAtRangeText"
                  placeholder="เลือก"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  rounded
                  filled
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCreatedAtRangeSelected"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="title-filter">ช่วงเวลาวันนัดหมาย</div>
            <v-menu
              v-model="menuFilterApointmentDateRange"
              :close-on-content-click="false"
              :nudge-left="60"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="appointmentRangeText"
                  placeholder="เลือก"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  rounded
                  filled
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterAppointmentDateRangeSelected"
                range
              ></v-date-picker>
            </v-menu>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- <div class="title-filter">ช่วงเวลาที่เติม</div>
            <v-menu
              v-model="menuFilterCreatedAtRange"
              :close-on-content-click="false"
              :nudge-left="60"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="createdAtRangeText"
                  placeholder="เลือก"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  rounded
                  filled
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCreatedAtRangeSelected"
                range
              ></v-date-picker>
            </v-menu> -->
            <div>
              <CustomFilterDateTypingPickerRange
                placeholder="เลือก"
                title="ช่วงเวลาที่เติม"
                v-model="formData.createdDate"
                :isRequired="false"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-5 mb-2"></v-divider>
      <v-card-actions class="px-6 pb-3">
        <!-- <v-btn text @click="clear()">ล้าง</v-btn> -->
        <NormalButton :height="35" @on-click="clear()">ล้าง</NormalButton>
        <v-spacer></v-spacer>
        <PrimaryButton :height="35" @on-click="findAction()"
          >กรอง</PrimaryButton
        >
        <NormalButton :height="35" class="ml-5" @on-click="close()"
          >ยกเลิก</NormalButton
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import HeaderCardNormal from "@/components/HeaderCardNormal.vue";
import NormalButton from "@/components/NormalButton.vue";
import CustomTextInput from "@/components/CustomTextInput.vue";
import { ProcessSelectListFormatHelpper } from "@/services/arrayAndObjHelpper";
import { checkEmptyInputValueHelpper } from "@/services/appFuncHelpper";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomFilterDateTypingPickerRange from "@/components/CustomFilterDateTypingPickerRange.vue";
import InputView from "@/components/InputView.vue";

export default {
  name: "VehicleEventFilterGroup",
  components: {
    PrimaryButton,
    HeaderCardNormal,
    NormalButton,
    CustomTextInput,
    CustomTextInputImprove,
    CustomFilterDateTypingPickerRange,
    InputView,
  },

  data() {
    return {
      copyFormData: null,
      queryOrder: {
        mincreatedate: "createdate",
        maxcreatedate: "createdate",
        minamount: "minAmount",
        maxamount: "maxAmount",
      },
      formData: {
        minAmount: null,
        maxAmount: null,
        createdDate: [],
      },
    };
  },

  mounted() {
    this.getParamQuery();
  },

  methods: {
    getParamQuery() {
      Object.keys(this.queryOrder).forEach((key) => {
        if (!_.isNil(this.$route.query[key])) {
          switch (key) {
            case "mincreatedate":
            case "maxcreatedate":
              this.formData.createdDate.push(this.$route.query[key]);
              break;
            default:
              this.formData[this.queryOrder[key]] = this.$route.query[key];
          }
        }
      });
    },
    async findAction() {
      this.copyFormData = _.cloneDeep(this.formData);
      let filterParams = {};

      if (checkEmptyInputValueHelpper(this.formData["minAmount"]) === false) {
        filterParams["minamount"] = this.formData["minAmount"];
      }
      if (checkEmptyInputValueHelpper(this.formData["maxAmount"]) === false) {
        filterParams["maxamount"] = this.formData["maxAmount"];
      }
      if (this.formData["createdDate"].length !== 0) {
        if (this.formData["createdDate"].length === 2) {
          filterParams["mincreatedate"] = this.formData["createdDate"][0];
          filterParams["maxcreatedate"] = this.formData["createdDate"][1];
        } else {
          filterParams["mincreatedate"] = this.formData["createdDate"][0];
        }
      }

      // return
      this.$emit("returnFiltedData", filterParams);
      this.$emit("close-menu");
    },
    close() {
      if (this.copyFormData !== null) {
        this.formData = this.copyFormData;
        this.copyFormData = null;
      }
      this.$emit("close-menu");
    },
    clear() {
      this.copyFormData = _.cloneDeep(this.formData);

      this.formData = {
        minamount: null,
        maxAmount: null,
        createdDate: [],
      };
    },
  },
  computed: {
    // createdAtRangeText() {
    //   this.filterCreatedAtRangeSelectedValue =
    //     this.filterCreatedAtRangeSelected;
    //   return this.filterCreatedAtRangeSelected.sort().join(" - ");
    // },
  },
};
</script>
<style scoped>
.title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
}

.v-input__control {
  height: 40px !important;
}
</style>
