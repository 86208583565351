<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card v-if="show" min-height="80vh">
      <v-card-title class="local-card-text px-3 pt-3">
        <v-spacer></v-spacer>
        <p class="text-app-detail-large white--text mb-1">สรุปตารางงานเดินรถ</p>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            icon
            dark
            @click="cancelAndCloseHandler"
            v-if="fetchDataError <= 0 || fetchDataError === 1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <!-- <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>สรุปตารางงานเดินรถ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelAndCloseHandler" v-if="fetchDataError <= 0 || fetchDataError === 1">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar> -->
      <v-card-text v-if="fetchDataError === 0">
        <v-row no-gutters class="pt-0 mt-0">
          <v-col cols="12" class="pl-0 pr-9 pt-0 mt-0">
            <SheetStatusChart
              :refresh-key="chartKey"
              :overlap-job="overlapJob"
              @update-table-driver="updateTableDriverFromChartHandler"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -2">
        ไม่สามารถดึงข้อมูลได้
      </v-card-text>

      <v-card-text class="pa-5 text-center" v-if="fetchDataError === -1">
        ไม่สามารถบันทึกข้อมูลได้
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 1">
        กำลังดึงข้อมูล...
      </v-card-text>
      <v-card-text class="pa-5 text-center" v-if="fetchDataError === 2">
        กำลังบันทึกข้อมูล...
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import SheetStatusChart from "../chart/SheetStatusChart.vue";
import {
  putDriverToMultipleJobService,
  getTableCellDriverInCartHasJobService,
} from "@/services/api/assign_multiple_job";

import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "AssignMultipleJobSummaryDialog",
  props: {},
  data: () => ({
    show: false,
    fetchDataError: 1,
    //
    tableId: null,
    sheetTableData: null,
    // input
    selectedDriverList: [],
    filterDriverList: [],
    categoryName: [],

    // table
    tabledCheckSelected: [],
    tableHeaders: [
      {
        text: "#",
        value: "rowNum",
        sortable: false,
        align: "start",
        width: "5%",
      },
      {
        text: "ชื่อผู้ขับ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "วิ่ง", value: "jobs", align: "end", width: "10%" },
      { text: "ฟรี", value: "free", align: "end", width: "10%" },
    ],
    tableItems: [],
    formData: {
      newNumberOfSelectedDriver: null,
    },
    //
    cellData: null,
    onCartDriver: [],
    jobs: [],
    overlapJob: {},
    overlapCell: [],
    // jobs: [
    //   { work: -4, free: 3, name: "นายมนตรี งามอัครกุล (ตรี)" },
    //   { work: -10, free: 2, name: "นายกติกา กองศิล (แชมป์)" },
    //   { work: -15, free: 0, name: "นายผู้ขับสอง มือสอง (สอง)" },
    //   { work: -9, free: 4, name: "นายผู้ขับหนึ่ง มือหนึ่ง (หนึ่ง)" },
    //   { work: -13, free: 2, name: "นายผู้ขับสาม มือสาม (สาม)" },
    // ],
    jobWork: [],
    jobFree: [],
    //
    chartKey: 0,
  }),

  computed: {
    ...mapGetters({
      existingDriverInCellJobStatusById:
        "assignMultipleJob/existingDriverInCellJobStatusById",
      sumDriverStatOnSheet: "assignMultipleJob/getDriverStatOnSheet",
    }),
    selectedItemStatus() {
      return this.tabledCheckSelected.length > 0;
    },

    numberOfSelectedDriver() {
      return _.get(
        this.$store.state["assignMultipleJob"],
        `cellData.T${this.tableId}R${this.sheetTableData["row"]}C${this.sheetTableData["col"]}.numberOfSelectedDriver`,
        null
      );
    },
  },

  methods: {
    // open() {
    //   this.show = true;
    //   console.log("this.sumDriverStatOnSheet", this.sumDriverStatOnSheet);
    //   // this.fetchDataError = 0;
    //   return new Promise((resolve, reject) => {
    //     this.resolve = resolve;
    //     this.reject = reject;
    //   });
    // },

    open() {
      this.fetchDataError = 1;
      this.resetForm();
      // this.tableId = tableId;
      // this.sheetTableData = sheetTableData;
      // this.settingData();

      this.show = true;
      this.fetchData();
      // this.fetchDataError = 0;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async fetchData() {
      this.settingData();
      this.fetchDataError = 0;
    },
    settingData() {
      // if (!_.isNil(this.cellData)) {
      //   for (let i = 0; i < this.cellData["drivers"].length; i++) {
      //     const _driverId = this.cellData["drivers"][i]["_id"];
      //     let _selectedDriver = _.cloneDeep(this.cellData["drivers"][i]);
      //     _selectedDriver["statOnSheet"] = _.get(
      //       this.sumDriverStatOnSheet,
      //       _driverId,
      //       { free: 0, jobs: 0 }
      //     );
      //     this.selectedDriverList.push(_driverId);
      //     this.addDriverToTable(_.cloneDeep(_selectedDriver));
      //   }
      //   // process onCartDriver
      //   const onCartDriver = _.get(
      //     this.$store.state["assignMultipleJob"],
      //     `driverInCart.${this.tableId}`,
      //     null
      //   );
      //   let _onCartDriver = [];
      //   onCartDriver.forEach((item) => {
      //     const _driverId = item["_id"];
      //     const driverCellStat = _.get(
      //       this.$store.state,
      //       `assignMultipleJob.driverInCellStat.${this.tableId}.${item["_id"]}`,
      //       {}
      //     );
      //     _onCartDriver.push({
      //       ...item,
      //       driverCellStat: _.clone(driverCellStat),
      //       statOnSheet: _.get(this.sumDriverStatOnSheet, _driverId, {
      //         free: 0,
      //         jobs: 0,
      //       }),
      //     });
      //     item["driverCellStat"] = _.cloneDeep(driverCellStat);
      //   });
      //   this.defaultDriverItems = _onCartDriver;
      //   this.formData["newNumberOfSelectedDriver"] =
      //     this.numberOfSelectedDriver;
      //   this.onfilterListChange();
      //   this.chartKey += 1;
      // }
      this.chartKey += 1;
    },

    checkUserJobHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapJob, `${driverId}`, []);
        const jobInOverlop = _.map(_d, (item) => item["_id"]);
        const _driverInCell = _.filter(
          this.cellData.drivers,
          (item) => item["_id"] === driverId
        );
        // check duplication
        let jobIntersect = [];
        if (_driverInCell.length > 0) {
          jobIntersect = _.intersection(jobInOverlop, [
            _driverInCell[0]["jobId"],
          ]);
        }
        return _d.length > 0 && jobIntersect.length === 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },
    checkUserCellHasOverlap(driverId) {
      try {
        const _d = _.get(this.overlapCell, `${driverId}`, []);
        const cellInOverlop = _.map(_d, (item) => item["matchCellId"]);
        // check duplication
        let cellIntersect = [];
        if (cellInOverlop.length > 0) {
          cellIntersect = _.filter(
            cellInOverlop,
            (item) => item !== this.cellData.cellMId
          );
        }
        return cellIntersect.length > 0 ? true : false;
      } catch (error) {
        console.error(error);
      }

      return false;
    },

    getTime(dateTime) {
      return moment(dateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    },
    addDriverToTable(newValue) {
      const driverCellStat = _.get(
        this.$store.state,
        `assignMultipleJob.driverInCellStat.${this.tableId}.${newValue["_id"]}`,
        {}
      );
      this.tableItems.push({ ..._.cloneDeep(newValue), ...driverCellStat });
    },
    onfilterListChange() {
      this.filterDriverList = this.defaultDriverItems.filter(
        (item) => this.selectedDriverList.indexOf(item._id) === -1
      );
    },
    itemChange(newValue) {
      this.selectedDriverList.push(newValue["_id"]);
      this.addDriverToTable(newValue);
      this.onfilterListChange();

      // TODO update chart
      this.chartKey += 1;
    },

    cancelAndCloseHandler() {
      this.show = false;
      this.resolve(null);
    },
    async saveData(insertData) {
      try {
        const queryString = new URLSearchParams({
          id: this.cellData.cellMId,
        }).toString();

        // loading cell
        this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
          updateAtCellId: this.cellData.cellId,
          updateData: {
            inputState: -1,
          },
        });
        const responseData = await putDriverToMultipleJobService(
          this.$store,
          queryString,
          insertData
        );

        if (responseData["code"] === 200) {
          //
          let updateData = {
            inputState: 1,
            drivers: this.tableItems,
          };

          if (!_.isNil(insertData["numberOfSelectedDriver"])) {
            updateData["numberOfSelectedDriver"] =
              insertData["numberOfSelectedDriver"];
          }
          //
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: this.cellData.cellId,
            updateData: updateData,
          });
          //
        } else {
          this.$store.dispatch("assignMultipleJob/updateInputCellDirectly", {
            updateAtCellId: this.cellData.cellId,
            updateData: {
              inputState: -2,
            },
          });
          this.fetchDataError = -1;
        }
        //
      } catch (error) {
        this.fetchDataError = -1;
      }
    },
    resetForm() {
      this.selectedDriverList = [];
      this.tableItems = [];
      this.defaultDriverItems = [];

      this.tableId = null;
      this.sheetTableData = null;
      this.tabledCheckSelected = [];
      this.formData = {
        newNumberOfSelectedDriver: null,
      };
    },
    // chart handler
    updateTableDriverFromChartHandler(newItems) {
      this.itemChange(newItems);
    },
  },
  components: { SheetStatusChart },
};
</script>

<style scoped lang="scss">
.local-card-text {
  background-color: #354253;
}
</style>
