<template>
  <div name="headerCardModern">
    <v-card flat :class="rounded ? 'rounded-t-lg' : ''" rounded="3">
      <div v-if="isShowTitle" class="title-section px-9 py-4">{{ title }}</div>
      <!-- <div class="pt-3"></div> -->
      <!-- <div><v-divider></v-divider></div> -->
    </v-card>
  </div>
</template>
<script>
export default {
  name: "headerCardModern",

  props: {
    title: String,
    rounded: { default: true, type: Boolean },
    isShowTitle: Boolean,
  },

  data() {
    return {
      // color: this.$vuetify.theme.themes.light.primary,
    };
  },
};
</script>
<style scoped>
.title-section {
  font-size: 20px;
  /* color: white; */
  font-weight: bold;
}
</style>
