<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    data: null,
  }),

  methods: {
    processIcon() {
      return {
        path: this.google.maps.SymbolPath.CIRCLE,
        fillColor: this.marker.type === "S" ? "#00ffff" : "#f5487f",
        fillOpacity: 0.8,
        strokeWeight: 1,
        strokeColor: "white",
        scale: 10,
        anchor: new this.google.maps.Point(0, 0),
      };
    },
  },

  mounted() {
    const { Marker } = this.google.maps;
    const _icon = this.processIcon();
    this.data = new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: _icon,
    });
  },

  render() {},
  beforeDestroy() {
    this.data.setMap(null);
  },
};
</script>
<style scoped></style>
