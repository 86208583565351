<template>
  <v-container name="AppCustomerDetailTab">
    <v-row dense class="px-3" v-if="!isLoading">
      <v-col cols="12">
        <div class="d-flex mb-1">
          <p class="text-app-detail mb-0 ml-3">
            {{ tableStructure ? tableStructure["tableHeader"] : "หัวข้อ" }}
          </p>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="addNewCustomer">
            <v-icon> mdi-plus </v-icon> เพิ่มลูกค้า
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{
                    tableStructure ? tableStructure["col_ident"] : "ไม่มีชื่อ"
                  }}
                </th>
                <th class="text-left">
                  {{
                    tableStructure ? tableStructure["col_role"] : "ไม่มีชื่อ"
                  }}
                </th>
                <th class="text-left" width="5%">เครื่องมือ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in tableData" :key="idx">
                <td>{{ item.email }}</td>
                <td>{{ rolesList[item.role] }}</td>
                <td>
                  <v-btn icon color="primary" @click="removeCustomer(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-if="tableData.length === 0">
                <td colspan="3" class="text-center font-weight-bold">
                  ไม่มีรายชื่อ
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <AppCustomerDetailDialog ref="appCustomerDetailDialog" />
  </v-container>
</template>

<script>
import _ from "lodash";
import {
  deleteRegisteredAppCustomerToJobService,
  getRegisteredAppCustomerService,
} from "@/services/api/jobs_registered_customer";
import AppCustomerDetailDialog from "./AppCustomerDetailDialog.vue";
import AppOverlay from "@/components/AppOverlay.vue";

export default {
  name: "AppCustomerDetailTab",
  components: { AppCustomerDetailDialog, AppOverlay },
  data() {
    return {
      tableData: [],
      tableStructure: null,
      isMounted: false,
      rolesList: {},
      isLoading: false,
      currentJobId: null,
    };
  },

  computed: {},

  watch: {},

  methods: {
    async processData() {
      this.isLoading = true;
      try {
        const queryString = new URLSearchParams({
          id: this.currentJobId,
          type: "list",
        }).toString();
        const rsp = await getRegisteredAppCustomerService(
          this.$store,
          queryString
        );

        if (rsp["code"] === 200) {
          const { data } = rsp;
          this.rolesList = data["rolesList"];
          this.tableData = data["dataList"];
          this.tableStructure = data["tableText"];
        } else {
          throw "";
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async addNewCustomer() {
      const result = await this.$refs.appCustomerDetailDialog.open(
        this.currentJobId
      );
      if (result) {
        this.processData();
      }
    },

    async removeCustomer(item) {
      this.isLoading = true;
      try {
        const queryString = new URLSearchParams({
          id: this.currentJobId,
          psId: item.registeredPassengerId,
        }).toString();

        const rsp = await deleteRegisteredAppCustomerToJobService(
          this.$store,
          queryString
        );

        if (rsp["code"] === 200) {
          this.processData();
        } else {
          throw "";
        }
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
  },

  mounted() {
    let jobData = _.get(this.$store, "state.manageRoute.jobData", null);
    if (jobData !== null) {
      this.currentJobId = jobData._id;
      this.processData();
    }
  },
};
</script>

<style scoped></style>
