<template>
  <v-card v-if="items" elevation="5" rounded="lg">
    <v-slide-group>
      <v-slide-item v-for="item of items" :key="item.name">
        <v-card max-width="200" elevation="0" flat class="pa-1">
          <v-list-item dense class="text-center">
            <v-list-item-icon class="mr-2">
              <v-icon color="success" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.value }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-card>
</template>

<script>
import _ from "lodash";
import {
  processDurationText,
  processDistanceText,
} from "@/services/appFuncHelpper";
export default {
  name: "DetailOnMapFloat",
  data: () => ({
    defaultItem: [
      {
        name: "location",
        icon: "mdi-navigation-variant-outline",
        title: "ตำแหน่งปัจจุบัน",
        value: "-",
      },
      {
        name: "time",
        icon: "mdi-timer-outline",
        title: "ถึงภายใน",
        value: "-",
      },
      {
        name: "distance",
        icon: "mdi-map-outline",
        title: "ระยะทาง",
        value: "-",
      },
      {
        name: "speed",
        icon: "mdi-speedometer",
        title: "ความเร็วเฉลี่ย",
        value: "-",
      },
    ],
    items: null,
  }),
  mounted() {
    this.items = _.cloneDeep(this.defaultItem);
  },
  watch: {
    "$store.state.manageRoute.jobDetailData": {
      immediate: false,
      handler(newData) {
        if (!_.isNil(newData)) {
          this.processData(newData);
        }
      },
    },
  },
  methods: {
    processDurationText: processDurationText,
    processDistanceText: processDistanceText,
    processData(newData) {
      if (!_.isNil(newData["totalLocation"])) {
        this.items = [
          {
            name: "location",
            icon: "mdi-navigation-variant-outline",
            title: "ตำแหน่งปัจจุบัน",
            value: `${newData.passedCount || "-"} / ${newData.totalLocation}`,
          },
          {
            name: "time",
            icon: "mdi-timer-outline",
            title: "ถึงภายใน",
            value: `${processDurationText(newData.dpPassedDuration)}`,
          },
          {
            name: "distance",
            icon: "mdi-map-outline",
            title: "ระยะทาง",
            value: `${processDistanceText(
              newData.dpPassedDistance
            )} / ${processDistanceText(newData.dpTotalDistance)}`,
          },
          {
            name: "speed",
            icon: "mdi-speedometer",
            title: "ความเร็วเฉลี่ย",
            value: `${newData.avgTotalSpeed} กม./ชม.`,
          },
        ];
      } else {
        this.items = _.cloneDeep(this.defaultItem);
      }
    },
    // demoData() {
    // this.items = [
    //   {
    //     name: "location",
    //     icon: "mdi-navigation-variant-outline",
    //     title: "ตำแหน่งปัจจุบัน",
    //     value: "3 / 8",
    //   },
    //   {
    //     name: "time",
    //     icon: "mdi-timer-outline",
    //     title: "ถึงภายใน",
    //     value: "18 นาที",
    //   },
    //   {
    //     name: "distance",
    //     icon: "mdi-map-outline",
    //     title: "ระยะทาง",
    //     value: "20 / 55 กม.",
    //   },
    //   {
    //     name: "speed",
    //     icon: "mdi-speedometer",
    //     title: "ความเร็วเฉลี่ย",
    //     value: "80 กม./ชม.",
    //   },
    // ];
    // },
  },
  computed: {},
};
</script>

<style></style>
