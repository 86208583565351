var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"name":"vehicleLoanList","fluid":""}},[(_vm.isPermissionCanView)?_c('div',[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-h5 mt-1"},[_vm._v("รายการค่างวดรถ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AppAutoCompleteVehicleSearching',{key:_vm.componentRefreshCounter,ref:"AppAutoCompleteVehicleSearchingRef",attrs:{"tableLoading":_vm.tableLoading},on:{"searchItemChange":_vm.getSearchItem}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"nudge-bottom":10,"nudge-left":100,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({
                backgroundColor: 'white !important',
              }),attrs:{"color":"#D5DBDB","light":"","large":"","elevation":"0","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"#4D5656"}},[_vm._v(" mdi-filter ")]),_c('div',{staticClass:"normal-button-text"},[_vm._v(_vm._s("ตัวกรอง"))])],1)]}}],null,false,2816123789),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c('VehicleDocsFilterGroup',{key:_vm.componentRefreshCounter,attrs:{"docsType":'loan'},on:{"close-menu":function($event){_vm.isShowMenu = false},"returnFiltedData":_vm.getfilterItem}})],1)],1)],1),_c('v-data-table',{staticClass:"app-datatable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"items-per-page":_vm.queryOptionsTable.limit,"page":_vm.queryOptionsTable.page,"loading":_vm.tableLoading,"server-items-length":_vm.serverItemsLength,"footer-props":_vm.dataTableFooterProps,"no-data-text":_vm.dataTableNoDataText,"loading-text":_vm.dataTableLoadingText},on:{"update:page":_vm.tablePageHandler,"update:items-per-page":_vm.tableItemPerPageHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"},[_c('v-card',{staticClass:"d-flex flex-row",attrs:{"flat":"","tile":"","width":"320"}},[_c('v-select',{staticClass:"app-custom-input",attrs:{"items":_vm.sortList,"label":"เรียงลำดับโดย","dense":"","outlined":""},on:{"change":_vm.tableSortHandler},model:{value:(_vm.sortVal),callback:function ($$v) {_vm.sortVal=$$v},expression:"sortVal"}})],1),_c('NormalButton',{attrs:{"icon":"mdi-refresh"},on:{"on-click":function($event){return _vm.reset()}}},[_vm._v(" รีเซ็ต ")])],1)]},proxy:true},{key:"item.licenseNumber",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title font-weight-bold"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/vehicle/loan/edit/' + item._id}},[_vm._v(_vm._s(item.licenseNumber))])],1),(
                (item.licenseType !== null) & (item.licenseType !== undefined)
              )?_c('span',{staticClass:"text-app-normal"},[_c('span',{staticClass:"mx-0 px-0"},[(_vm.vehicleLicenseTypeColor(item.licenseType) === 'white')?_c('v-icon',[_vm._v("mdi-card-text-outline")]):_c('v-icon',{attrs:{"color":_vm.vehicleLicenseTypeColor(item.licenseType)}},[_vm._v("mdi-card-text")])],1),_vm._v(" "+_vm._s(_vm.vehicleLicenseTypeText(item.licenseType)))]):_vm._e()],1)],1)]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[(item.type !== null)?_c('v-list-item-title',{staticClass:"text-app-title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.vehicleTypeText(item.type)))]):_vm._e(),((item.seat !== null) & (item.seat !== undefined))?_c('span',{staticClass:"mx-0 px-0 text-app-normal"},[_c('v-icon',[_vm._v(_vm._s(_vm.vehicleTypeIcon(item.type)))]),_vm._v(" "+_vm._s(item.seat)+" ที่นั่ง ")],1):_c('span',[_vm._v("-")])],1)],1)]}},{key:"item.licenseType",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(_vm.vehicleLicenseTypeText(item.licenseType)))]),(item.type !== null)?_c('span',{staticClass:"text-app-normal"},[_vm._v(_vm._s(item.type))]):_vm._e()],1)],1)]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.loanAmount.toLocaleString())+" ")]}},{key:"item.company",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.company.nameTh || "-"))])],1)],1)]}},{key:"item.loanExpiryDate",fn:function(ref){
              var item = ref.item;
return [(item.loanExpiryDate !== null)?_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.loanExpiryDate, { returnThai: false, }))+" ")])],1)],1):_c('div',[_vm._v("-")])]}},{key:"item.payDate",fn:function(ref){
              var item = ref.item;
return [(item.payDate !== null)?_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.payDate, { returnThai: false, }))+" ")])],1)],1):_c('div',[_vm._v("-")])]}},{key:"item.expiryDate",fn:function(ref){
              var item = ref.item;
return [(item.expiryDate !== null)?_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{class:'text-app-title font-weight-bold ' +
                _vm.getExpiredColor(item.remainingDay)},[_vm._v(" "+_vm._s(item.remainingDay)+" วัน")]),_c('span',{staticClass:"text-app-normal"},[_vm._v(_vm._s(_vm.dateStringToDateFormat(item.expiryDate, { returnThai: false })))])],1)],1):_c('div',[_vm._v("-")])]}},{key:"item.paidLoanCount",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" 10")])]}},{key:"item.financeCompany",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.financeCompany)+" ")])]}},{key:"item.userId",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(item.userId)+" ")])]}},{key:"item.tools",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.isPermissionCanEdit},on:{"click":function($event){return _vm.handleEditClick(item._id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-pencil")])],1),_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" แก้ไขข้อมูล")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.isPermissionCanEdit},on:{"click":function($event){return _vm.handleDeleteClick(item._id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-delete")])],1),_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v("ลบข้อมูล")])],1)],1)],1)]}}],null,true)})],1):_vm._e(),_c('AppPageNotFound',{attrs:{"show":_vm.isPermissionCanView}}),_c('AppDialogConfirm',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }