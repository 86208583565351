<template>
  <v-container class="pa-0" fluid>
    <div class="wrapper">
      <v-btn
        style="position: absolute; top: 10px; left: 10px; z-index: 12"
        fab
        small
        @click="
          () => {
            this.$store.dispatch('jobsMonitoring/toggleLeftPanel');
          }
        "
        v-if="!showLeftPanel"
      >
        <v-icon> {{ showLeftPanel ? "" : "mdi-menu" }}</v-icon>
      </v-btn>
      <!-- <v-btn
        color="white"
        class="toggle-btn px-0 subtitle--text"
        min-width="20px"
        min-height="50px"
        style="position: absolute; top: 20px; left: 0; z-index: 12"
        @click="
          () => {
            this.$store.dispatch('jobsMonitoring/toggleLeftPanel');
          }
        "
        v-if="!showLeftPanel"
      >
        <v-icon class="pa-1">
          {{ showLeftPanel ? "" : "mdi-map-outline" }}</v-icon
        >
      </v-btn> -->
      <JobsMonitorJobList
        :class="[
          'custom-job-list',
          showLeftPanel ? 'custom-job-list-active' : 'custom-job-list-hidden',
        ]"
      />
      <JobsMonitorCoreMap class="custom-core-map" />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import JobsMonitorJobList from "./components/JobsMonitorJobList.vue";
import JobsMonitorCoreMap from "./components/JobsMonitorCoreMap.vue";
//
export default {
  name: "JobsMonitorPagesView",
  props: {},
  mounted() {
    this.$store.dispatch("updateDrawerVuex", false);
    this.viewWidthObserver = new ResizeObserver(() => {
      this.viewWidthView = window.innerWidth;
      this.checkLeftPanelSize(this.viewWidthView);
    });
    this.viewWidthObserver.observe(document.body);
  },
  data: () => ({
    viewWidthObserver: undefined,
    viewWidthView: null,
    minDisplaySmall: 500,
    isDesktop: true,
  }),
  computed: {
    showLeftPanel: function () {
      return this.$store.state["jobsMonitoring"]["showLeftPanel"];
    },
  },
  methods: {
    checkLeftPanelSize(currentView) {
      if (currentView < this.minDisplaySmall) {
        this.isDesktop = false;
        this.$store.dispatch("jobsMonitoring/updateLeftPanel", true);
      } else {
        this.isDesktop = true;
        this.$store.dispatch("jobsMonitoring/updateLeftPanel", true);
      }
    },
  },
  components: { JobsMonitorJobList, JobsMonitorCoreMap },
  beforeDestroy() {
    this.viewWidthObserver.disconnect();
    this.viewWidthObserver = undefined;
    this.$store.dispatch("jobsMonitoring/updateClear");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
}

$viewHeight: calc(100vh - 1rem);

.custom-core-map {
  height: $viewHeight;
  top: 0;
  left: 0;
  z-index: 1;
}

$dpsmall: 500px;

.custom-job-list-active {
  visibility: visible;
  opacity: 1;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.custom-job-list-hidden {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
  visibility: hidden;
  opacity: 0;
}

.custom-job-list {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 400px;
  height: 100%;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);

  @media screen and (max-width: $dpsmall) {
    width: 100% !important;
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
</style>
