import {
	authHttpClientGet,
	authHttpClientPut,
	authHttpClientPost,
	authHttpClientDelete,
} from "@/services/api/my_axios";
import axios from "axios";

export async function getGPSVideoStreamingService(store, queryString) {
	let uri = `/deepbox/video/streaming?${queryString}`;
	return await authHttpClientGet(store, uri, false);
}

export async function stopGPSVideoStreamingService(store, queryString) {
	let uri = `/deepbox/video/streaming?${queryString}`;
	return await authHttpClientDelete(store, uri, false);
}

export async function requestGPSVideo(store, serialNumber, timestamp, camera, force) {
	let uri = `/deepbox/video/request`;
	const data = {
		serialNumber: serialNumber,
		timestamp: timestamp,
		camera: camera,
		force: force,
	}
	return await authHttpClientPost(store, uri, data, true);
}

export async function triggerGPSVideo(store, requestId) {
	let uri = `/deepbox/video/request/trigger?requestId=${requestId}`;
	return await authHttpClientGet(store, uri, true);
}
