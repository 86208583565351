// import RouteEditView from "@/views/routes/RouteEditView.vue";
import EmployeeGroupListView from "@/views/employee_group/EmployeeGroupListView.vue";
import EmployeeGroupManageView from "@/views/employee_group/EmployeeGroupManageView.vue";

export default [
  {
    path: "/caj/employee_group",
    name: "employeeGroupList",
    component: EmployeeGroupListView,
    meta: { requiresAuth: true, topic: "employeeGroup" },
  },
  {
    path: "/caj/employee_group/edit/:id",
    name: "employeeGroupEdit",
    component: EmployeeGroupManageView,
    meta: { requiresAuth: true, topic: "employeeGroup" },
  },
];
