import {
  authHttpClientGet,
  authHttpClientPost,
  authHttpClientPut,
  authHttpClientDelete,
} from "@/services/api/my_axios";

export async function getVehicleEventService(store, queryString) {
  let uri = "/vehicle-event";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getVehicleEventInfoService(store, queryString) {
  let uri = "/vehicle-event/info";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postVehicleEventServiceNoteService(store, data) {
  let uri = "/vehicle-event/note";
  return await authHttpClientPost(store, uri, data);
}

export async function putVehicleEventServiceStatusService(
  store,
  queryString,
  data
) {
  let uri = "/vehicle-event/event-status";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function postVehicleEventService(store, data) {
  let uri = "/vehicle-event/";
  return await authHttpClientPost(store, uri, data);
}

export async function putVehicleEventService(store, id, data) {
  let uri = `/vehicle-event?id=${id}`;
  return await authHttpClientPut(store, uri, data);
}

export async function deleteVehicleEventServiceService(store, id) {
  let uri = `/vehicle-event?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function searchVehicleEventReportElementService(
  store,
  queryString
) {
  let uri = "/vehicle-event/report-elements";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getVehicleEventReportService(store, id) {
  let uri = `/vehicle-event/bill?eventId=${id}`;
  return await authHttpClientGet(store, uri);
}

export async function getVehiclePartsService(store, id) {
  let uri = `/vehicle-event/bill/parts?billId=${id}`;
  return await authHttpClientGet(store, uri);
}

export async function postVehicleEventReportService(store, id, data) {
  let uri = `/vehicle-event/bill?eventId=${id}`;
  return await authHttpClientPost(store, uri, data);
}

// export async function postVehicleEventReportService(store, id, data) {
//   let uri = `/vehicle-event/report?id=${id}`;
//   return await authHttpClientPost(store, uri, data);
// }

export async function putVehicleEventReportService(store, id, data) {
  let uri = `/vehicle-event/bill?billId=${id}`;
  return await authHttpClientPut(store, uri, data);
}

export async function deleteVehicleEventReportService(store, id) {
  let uri = `/vehicle-event/bill?billId=${id}`;
  return await authHttpClientDelete(store, uri);
}
