import { authHttpClientGet } from "@/services/api/my_axios";

// Overview
export async function getSummaryJobReportService(store) {
  let uri = "/report/summary/job";

  return await authHttpClientGet(store, uri);
}

export async function getSummaryPenaltyReportService(store) {
  let uri = "/report/summary/penality";

  return await authHttpClientGet(store, uri);
}

export async function getSummaryOtherReportService(store) {
  let uri = "/report/summary/other";

  return await authHttpClientGet(store, uri);
}

// Emoployee
export async function getEmployeeReportService(store, queryString) {
  let uri = "/report/user/numberical";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeePenaltyService(store, queryString) {
  let uri = "/user-penality/admin/report";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeReportPenaltyService(store, queryString) {
  let uri = "/report/user/penality";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeReportTimeoffService(store, queryString) {
  let uri = "/report/user/timeoff";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeReportStatsService(store, queryString) {
  let uri = "/report/user/stats";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeReportPercentService(store, queryString) {
  let uri = "/report/user/percent";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getEmployeeIncomeService(store, queryString) {
  let uri = "/report/user/incomets";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// Report
export async function getContractsReportService(store, queryString) {
  let uri = "/contract/report";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getContractInfoReportService(store, queryString) {
  let uri = "/contract/report-info";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// HeatMap
export async function getContractRouteHeatMapReportService(store, queryString) {
  let uri = "/contract/report-contract-route-heatmap";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getRouteTemplateNameListByContractIdService(
  store,
  queryString
) {
  let uri = "/contract/report-contract-route-list";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
