<template>
  <div class="px-3">
    <InputView
      :title="title"
      :isRequired="true"
      :bottomMargin="true"
      :small="false"
    >
      <v-text-field
        outlined
        :class="processStyle"
        v-model="valueInput"
        :placeholder="placeholder"
        type="text"
        :background-color="getBackgroundColor"
        :disabled="disabled"
        :rules="rules"
        :readonly="readonly"
        :autofocus="autofocusInput"
        @blur="handleUpdateItem($event)"
        @update:error="handlerVaildateError"
        name="email"
        :dense="dense"
        :label="label"
        :hide-details="false"
        :error="isInputError"
        :success="isInputSuccess"
        :messages="successMessage"
        :loading="loading"
        autocomplete="null"
      ></v-text-field>
    </InputView>
  </div>
</template>
<script>
import InputView from "@/components/InputView.vue";
import { checkUserService } from "@/services/api/user";
import _ from "lodash";

export default {
  name: "AccountUsernameInputComponent",
  components: {
    InputView,
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isEmailOnly: {
      type: Boolean,
      default: false,
    },
    handlerProcessingFunc: {
      type: Function,
      default: () => {},
    },
    //
    editAtEmail: {
      type: [String, null],
      default: null,
    },
  },

  data: () => ({
    loading: false,
    massageMapper: {
      NO_DATA: "ต้องระบุอีเมลล์หรือเบอร์โทรศัพท์",
      DUP: "อีเมลล์หรือเบอร์โทรศัพท์ถูกใช้งานแล้ว",
      FAIL: "รูปแบบอีเมลล์หรือเบอร์โทรศัพท์ไม่ถูกต้อง", // Email and Telephone FORMAT FAIL
    },
    rules: [(v) => !!v || "ต้องระบุอีเมลล์หรือเบอร์โทรศัพท์"],

    massageEmailMapper: {
      NO_DATA: "ต้องระบุอีเมลล์",
      DUP: "อีเมลล์ถูกใช้งานแล้ว",
      FAIL: "รูปแบบอีเมลล์ไม่ถูกต้อง", // Email and Telephone FORMAT FAIL
    },
    rulesEmail: [(v) => !!v || "ต้องระบุอีเมลล์"],

    successMessage: "",
    searchCountTimer: undefined,
    //
    isInputError: false,
    isInputSuccess: false,
  }),

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.inputVaildate(val);
      },
    },

    autofocusInput: {
      get() {
        return this.autofocus;
      },
      set(val) {},
    },
    getBackgroundColor: function () {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },

    processStyle: function () {
      if (!this.isInputError && !this.isInputSuccess) {
        return "custom-placeholer-color app-custom-input";
      } else if (this.isInputError) {
        return "custom-placeholer-color  error-outline";
      } else {
        return "custom-placeholer-color success-outline";
      }
    },
  },

  methods: {
    _vaildateEmail(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value);
    },
    _vaildatePhoneNumber(value) {
      const pattern = /^\d{10}$/;
      return pattern.test(value);
    },
    handlerVaildateError(isError) {
      // if true
      if (isError) {
        this.isInputError = true;
      }
    },
    setStatus(errorType) {
      this.successMessage = "";
      this.rules = [];
      switch (errorType) {
        case "NO_DATA":
        case "DUP":
        case "FAIL":
          if (this.isEmailOnly) {
            this.rules = [(v) => this.massageEmailMapper[errorType]];
          } else {
            this.rules = [(v) => this.massageMapper[errorType]];
          }
          this.isInputSuccess = false;
          this.isInputError = true;
          break;
        case "OK":
          if (this.isEmailOnly) {
            this.successMessage = "";
            this.isInputSuccess = false;
          } else {
            this.successMessage = "อีเมลล์หรือเบอร์โทรศัพท์สามารถใช้งานได้";
            this.isInputSuccess = true;
          }
          this.isInputError = false;
          break;
        default:
          this.isInputSuccess = false;
          this.isInputError = false;
          break;
      }
      this.loading = false;
      this.$emit("handler-processing-func", this.loading);
    },

    inputVaildate(newValue) {
      this.setStatus("DEFAULT");
      this.isInputError = false;
      this.isInputSuccess = false;

      if (_.isNil(newValue)) {
        this.setStatus("NO_DATA");
      } else if (newValue.length === 0) {
        this.setStatus("NO_DATA");
      } else if (this.isEdit && newValue === this.editAtEmail) {
        this.setStatus("DEFAULT");
      } else {
        clearTimeout(this.searchCountTimer);
        this.loading = true;
        this.$emit("handler-processing-func", this.loading);
        this.searchCountTimer = setTimeout(() => {
          this.callApi(); // call api function
        }, 1250);
      }
    },

    async callApi() {
      try {
        if (this.valueInput.length !== 0) {
          // vaildate again.
          if (
            !this._vaildateEmail(this.valueInput) &&
            !this._vaildatePhoneNumber(this.valueInput)
          ) {
            throw "FAIL";
          } else if (this.isEdit && this.valueInput === this.editAtEmail) {
            throw "DEFAULT";
          }

          if (this.isEmailOnly) {
            this.setStatus("OK");
          } else {
            let uriParams = new URLSearchParams({
              field: "email",
              value: this.valueInput,
            }).toString();

            const respData = await checkUserService(this.$store, uriParams);
            if (respData["code"] >= 400) {
              this.setStatus(respData["data"]["message"]);
            } else {
              this.setStatus("OK");
            }
          }
        } else {
          this.setStatus("NO_DATA");
        }
      } catch (error) {
        this.setStatus(error);
      }
      this.loading = false;
      this.$emit("handler-processing-func", this.loading);
    },

    handleUpdateItem(evt) {
      // set name for testing ???
      this.$emit("blurHandler", this.dataInputName);
    },
  },
};
</script>
<style lang="scss" scoped>
.error-outline fieldset {
  border-color: var(--v-error) !important;
  border-width: 2px;
}

.success-outline fieldset {
  border-color: var(--v-success) !important;
  border-width: 2px;
}
</style>
