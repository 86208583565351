<template>
  <div name="VehicleOwnedForm">
    <v-form v-model="valid">
      <CustomCardFormViewModern
        title="รถประจำตัว"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <v-row class="px-3">
          <v-col v-if="formData === null" class="ml-6 my-3 mr-3">
            <v-btn
              outlined
              class="rounded-lg"
              width="100%"
              height="100"
              color="primary"
              :disabled="!canEdit"
              @click="openDialog()"
            >
              <div class="text-app-detail-large">
                <v-icon color="primary">mdi-plus</v-icon>
                <span>เพิ่มรถประจำตัว</span>
              </div>
            </v-btn>
          </v-col>

          <v-col
            lg="6"
            md="6"
            sm="12"
            v-if="formData !== null"
            class="pl-6 pr-6"
          >
            <v-card elevation="1" class="rounded-lg">
              <v-card-text class="pt-1">
                <v-row>
                  <v-col>
                    <div class="d-flex justify-space-between">
                      <div>
                        <p
                          class="mb-1 text-app-title font-weight-bold black--text"
                        >
                          {{ formData.licenseNumber }}
                          <span v-if="formData.type !== null"
                            >({{ vehicleTypeText(formData.type) }})</span
                          >
                        </p>
                        <span class="text-app-detail-normal pr-6">
                          <span class="mx-0 px-0">
                            <v-icon v-if="formData.licenseType === 'WHITE'"
                              >mdi-card-text-outline</v-icon
                            >
                            <v-icon
                              v-else
                              :color="
                                vehicleLicenseTypeColor(formData.licenseType)
                              "
                              >mdi-card-text</v-icon
                            >
                          </span>
                          {{
                            vehicleLicenseTypeText(formData.licenseType)
                          }}</span
                        >
                        <span class="text-app-detail-normal">
                          <span class="mx-0 px-0">
                            <v-icon>{{
                              vehicleTypeIcon(formData.type)
                            }}</v-icon>
                          </span>
                          {{ formData.seat }}
                          ที่นั่ง</span
                        >
                      </div>
                      <v-btn icon v-if="canEdit">
                        <v-icon dark @click="openDialog()"> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <p class="text-app-small font-weight-light my-0">สถานะรถ</p>
                    <p class="text-app-detail font-weight-bold my-0">
                      <v-chip
                        fab
                        small
                        class="text-app-title"
                        :color="vehicleStatusColor(formData.status)"
                        dark
                      >
                        {{ vehicleStatusText(formData.status) }}
                      </v-chip>
                    </p>
                  </v-col>
                  <v-col cols="5">
                    <p class="text-app-small font-weight-light my-0">
                      ข้อมูลรถ
                    </p>
                    <p
                      class="text-app-detail font-weight-bold my-0"
                      v-if="
                        formData.brand !== null ||
                        formData.model !== null ||
                        formData.year !== null
                      "
                    >
                      {{ formData.brand }} {{ formData.model }}
                      {{ formData.year }}
                    </p>
                    <p v-else>-</p>
                  </v-col>
                  <v-col cols="3">
                    <p class="text-app-small font-weight-light my-0">
                      ถูกใช้งานล่าสุด
                    </p>
                    <p class="text-app-detail font-weight-bold my-0">
                      {{
                        dateStringToDateFormat(formData.lastUsed, {
                          returnThai: false,
                          type: "datetime",
                        }) || "-"
                      }}
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
            <!-- <v-flex text-xs-center align-center>
              <v-btn
                color="primary"
                dark
                absolute
                fab
                :style="{ left: '48%', transform: 'translateY(-170%)' }"
              >
                <v-icon>mdi-chevron-left-circle-outline</v-icon>
              </v-btn>
            </v-flex> -->
          </v-col>
          <!-- <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp" /> -->
          <v-divider
            vertical
            v-if="formData == null || requestedFormData == null"
          />
          <v-col
            lg="6"
            md="6"
            sm="12"
            v-if="requestedFormData !== null"
            class="pl-6 pr-9"
          >
            <v-card outlined class="rounded-lg pt-1">
              <v-flex text-xs-center align-center>
                <v-btn
                  color="primary"
                  outlined
                  absolute
                  fab
                  @click="openNewRequest()"
                  :style="{
                    left: '-20px',
                    background: 'white',
                    transform: 'translateY(70%)',
                  }"
                >
                  <v-icon>mdi-arrow-left-bold</v-icon>
                </v-btn>
              </v-flex>
              <v-card-text class="pt-1 pl-12">
                <v-row>
                  <v-col>
                    <div class="d-flex justify-space-between">
                      <div>
                        <p
                          class="mb-1 text-app-title font-weight-bold black--text"
                        >
                          {{ requestedFormData.licenseNumber }}
                          ({{ vehicleTypeText(requestedFormData.type) }})
                        </p>
                        <span class="text-app-detail-normal pr-6">
                          <span class="mx-0 px-0">
                            <v-icon
                              v-if="requestedFormData.licenseType === 'WHITE'"
                              >mdi-card-text-outline</v-icon
                            >
                            <v-icon
                              v-else
                              :color="
                                vehicleLicenseTypeColor(
                                  requestedFormData.licenseType
                                )
                              "
                              >mdi-card-text</v-icon
                            >
                          </span>
                          {{
                            vehicleLicenseTypeText(
                              requestedFormData.licenseType
                            )
                          }}</span
                        >
                        <span class="text-app-detail-normal">
                          <span class="mx-0 px-0">
                            <v-icon>{{
                              vehicleTypeIcon(requestedFormData.type)
                            }}</v-icon>
                          </span>
                          {{ requestedFormData.seat }}
                          ที่นั่ง</span
                        >
                      </div>
                      <div>
                        <p class="text-app-detail my-0">
                          <span class="font-weight-bold">ร้องขอเมื่อ:</span>
                          <!-- {{
                            dateStringToDateFormat(requestedFormData.lastUsed, {
                              returnThai: false,
                              type: "datetime",
                            }) || "-"
                          }} -->
                          {{
                            dateStringToDateFormat("2023-02-10", {
                              returnThai: false,
                              type: "datetime",
                            }) || "-"
                          }}
                        </p>
                      </div>
                      <!-- <v-btn
                        fab
                        x-small
                        elevation="0"
                        color="error"
                        v-if="canEdit"
                        @click="handleDeleteClick()"
                      >
                        <v-icon dark> mdi-close </v-icon>
                      </v-btn> -->
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <p class="text-app-small font-weight-light my-0">สถานะรถ</p>
                    <p class="text-app-detail font-weight-bold my-0">
                      <v-chip
                        fab
                        small
                        class="text-app-title"
                        :color="vehicleStatusColor(requestedFormData.status)"
                        dark
                      >
                        {{ vehicleStatusText(requestedFormData.status) }}
                      </v-chip>
                    </p>
                  </v-col>
                  <v-col cols="5">
                    <p class="text-app-small font-weight-light my-0">
                      ข้อมูลรถ
                    </p>
                    <p
                      class="text-app-detail font-weight-bold my-0"
                      v-if="
                        requestedFormData.brand !== null ||
                        requestedFormData.model !== null ||
                        requestedFormData.year !== null
                      "
                    >
                      {{ requestedFormData.brand }}
                      {{ requestedFormData.model }}
                      {{ requestedFormData.year }}
                    </p>
                    <p v-else>-</p>
                  </v-col>
                  <v-col cols="3">
                    <p class="text-app-small font-weight-light my-0">
                      ถูกใช้งานล่าสุด
                    </p>
                    <p class="text-app-detail font-weight-bold my-0">
                      {{
                        dateStringToDateFormat(requestedFormData.lastUsed, {
                          returnThai: false,
                          type: "datetime",
                        }) || "-"
                      }}
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else lg="6" md="6" sm="12" class="pl-3 pr-6">
            <v-card elevation="1" :style="{ transform: 'translateY(50%)' }">
              <v-card-text class="text-app-detail-large d-flex justify-center">
                <p>ไม่มีการร้องขอเปลี่ยนรถประจำตัว</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </CustomCardFormViewModern>
      <AppDialogConfirm ref="dialogVehicleForm" />
      <!-- <AppDialogConfirm ref="confirmDelete" /> -->
      <EmployeeOwnedVehicleDialog ref="employeeOwnedVehicleDialog" />
    </v-form>
  </div>
</template>
<script>
import AppFilesUpload from "@/components/AppFilesUpload.vue";

import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import InputView from "@/components/InputView.vue";
import { DialogType } from "@/services/dialog";
import { mapGetters } from "vuex";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import EmployeeOwnedVehicleDialog from "../../components/EmployeeOwnedVehicleDialog.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { deleteRequestOwnedVehicleService } from "@/services/api/vehicle";
export default {
  name: "VehicleOwnedFormView",

  components: {
    AppFilesUpload,
    CustomCardFormViewModern,
    InputView,
    AppDialogConfirm,
    AppSubmitFormButton,
    EmployeeOwnedVehicleDialog,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      require: true,
    },
  },

  data() {
    return {
      valid: false,
      formData: null,
      requestedFormData: null,
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
    };
  },

  watch: {
    data: {
      immediate: false,
      handler() {
        this.setFormData();
      },
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
    }),
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setFormData() {
      if (!_.isNil(this.data["ownedVehicle"])) {
        this.formData = this.data["ownedVehicle"];
      } else {
        this.formData = null;
      }

      if (!_.isNil(this.data["requestOwnedVehicle"])) {
        this.requestedFormData = this.data["requestOwnedVehicle"];
      } else {
        this.requestedFormData = null;
      }
    },
    async openDialog() {
      let toMainUpdate = await this.$refs.employeeOwnedVehicleDialog.open(
        this.userId,
        this.data
      );

      // get new data form parent
      this.$emit("on-click-save");
    },

    async openNewRequest() {
      // let requestData = {
      //   cassieId: "ABCX123SSE123",
      //   key: "6369c201860dfd648eb758e1",
      //   licenseNumber: "ขก-4321",
      //   value: "ขก-4321 / ABCX123SSE123",
      // };
      let requestData = this.requestedFormData.select
        ? this.requestedFormData.select
        : null;
      if (!_.isNil(this.data["ownedVehicle"])) {
        this.data["ownedVehicle"]["select"] = requestData;
      }
      let toMainUpdate = await this.$refs.employeeOwnedVehicleDialog.open(
        this.userId,
        this.data
      );

      // get new data form parent
      this.$emit("on-click-save");
    },

    // async handleDeleteClick(_id) {
    //   if (
    //     await this.$refs.confirmDelete.open(
    //       "ต้องการลบข้อมูลนี้ ?",
    //       null,
    //       DialogType.ERROR,
    //       {
    //         noconfirm: true,
    //       }
    //     )
    //   ) {
    //     let requestId = this.requestedFormData._id;
    //     await deleteRequestOwnedVehicleService(this.$store, requestId);

    //     this.$emit("on-click-save");
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.vehicle-card-border-right {
  border-right: 0.2em solid rgba(0, 0, 0, 0.12);
}
</style>
