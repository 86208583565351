<template>
  <!-- <v-container color="transparent" name="jobsEvidenceDetailOnMapFloat"> -->
  <v-card color="transparent" flat>
    <v-card-text class="pt-0 d-flex">
      <v-row no-gutters>
        <v-col>
          <div>
            <p class="text-app-md text-center font-weight-medium mt-2">
              หลักฐานเพิ่มเติม
            </p>
            <div v-if="fetchStatus === 1 && evidenceTimeLine.length > 0">
              <v-timeline align-top dense clipped>
                <v-slide-x-transition group>
                  <v-timeline-item
                    v-for="event in evidenceTimeLine"
                    :key="event._id"
                    class="mb-4"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                  >
                    <v-row no-gutters justify="space-between">
                      <v-col cols="9" class="pl-0">
                        <span
                          class="text-app-title-large font-weight-bold black--text"
                        >
                          {{ event.mappedType }}
                        </span>
                        <span class="text-app-title-md">
                          -
                          {{
                            dateStringToDateFormat(event.createdAt, {
                              returnThai: false,
                              type: "datetime",
                            }) || "-"
                          }}
                        </span>
                        <p class="text-app-title-large mb-0">
                          {{ event.desc }}
                        </p>
                      </v-col>
                      <v-col class="text-right" cols="3" v-if="event.cost">
                        <div class="pr-5">
                          <span class="font-weight-bold text-app-detail-large">
                            {{ event.cost }}</span
                          >
                          บาท
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="event.countImg > 0" v-viewer>
                      <img
                        v-for="(img, imgIdx) in event.fileKey"
                        :key="`${event._id}-${imgIdx}`"
                        class="image-viewer-container pa-1"
                        width="200"
                        :src="img"
                      />
                    </v-row>
                  </v-timeline-item>
                </v-slide-x-transition>
              </v-timeline>
            </div>
            <p
              v-if="fetchStatus === 1 && evidenceTimeLine.length === 0"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              ไม่มีข้อมูลเหตุการณ์
            </p>
            <p
              v-if="fetchStatus === -1"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              ไม่สามารถดึงข้อมูลเหตุการณ์ได้
            </p>
            <p
              v-if="fetchStatus === 0"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              กำลังดึงข้อมูลเหตุการณ์....
            </p>
          </div>
        </v-col>
        <v-divider vertical style="height: 100%"></v-divider>
        <v-col>
          <div>
            <p class="text-app-md text-center font-weight-medium mt-2 mb-2">
              การเข้า-ออกแอป
            </p>

            <div v-if="fetchStatus === 1 && deviceAccess.length > 0">
              <v-timeline align-top dense clipped>
                <v-slide-x-transition group>
                  <v-timeline-item
                    v-for="access in deviceAccess"
                    :key="access.ts"
                    class="mb-4"
                    color="grey"
                    icon-color="grey lighten-2"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col cols="7" class="pl-0">
                        <span
                          class="text-app-title-large font-weight-bold black--text"
                          >{{ access.type }}</span
                        >
                        <span class="text-app-title-large">
                          -
                          {{
                            dateStringToDateFormat(access.ts, {
                              returnThai: false,
                              type: "datetime",
                            }) || "-"
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-slide-x-transition>
              </v-timeline>
            </div>
            <p
              v-if="fetchDeviceAccessStatus === 1 && deviceAccess.length === 0"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              ไม่มีข้อมูลการการเชื่อมต่อ
            </p>
            <p
              v-if="fetchDeviceAccessStatus === -1"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              ไม่สามารถดึงข้อมูลการเชื่อมต่อได้
            </p>
            <p
              v-if="fetchDeviceAccessStatus === 0"
              class="text-app-sm text-center font-weight-medium mt-5"
            >
              กำลังดึงข้อมูลการเชื่อมต่อ....
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <!-- </v-container> -->
</template>

<script>
import _ from "lodash";
import {
  getJobsEvidenceService,
  getJobsDeviceAccessService,
} from "@/services/api/jobs";
import { dateStringToDateFormat } from "@/services/appDate";
export default {
  name: "JobsEvidenceDetailOnMapFloatTab",
  data() {
    return {
      evidenceTimeLine: [],
      deviceAccess: [],
      roundAttr: [
        "progressPercent",
        "passedStatusPercent",
        "missingStatusPercent",
      ],
      fetchStatus: 0,
      fetchDeviceAccessStatus: 0,
    };
  },
  mounted() {
    this.fetchEventData();
    this.fetchDeviceAccessData();
  },
  computed: {
    jobData: function () {
      return _.get(this.$store, "state.manageRoute.jobData", null);
    },
  },
  watch: {},
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    async fetchEventData() {
      if (_.isNil(this.jobData)) {
        return;
      }

      try {
        const queryString = new URLSearchParams({
          id: this.jobData["_id"],
        }).toString();

        const responseData = await getJobsEvidenceService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.evidenceTimeLine = responseData["data"];
          this.fetchStatus = 1;
        } else {
          throw "jobs evidence fail";
        }
      } catch (error) {
        this.fetchStatus = -1;
        console.error(error);
      }
    },
    async fetchDeviceAccessData() {
      if (_.isNil(this.jobData)) {
        this.fetchDeviceAccessStatus = 1;
        return;
      }

      this.deviceAccess = this.jobData.eventAccess || [];
      if (!_.isNil(this.jobData.eventAccess)) {
        this.deviceAccess = _.orderBy(
          this.jobData.eventAccess,
          ["ts"],
          ["desc"]
        );
      }
      this.fetchDeviceAccessStatus = 1;
    },
  },
  components: {},
};
</script>
<style scoped>
.box {
  width: 20px;
  height: 20px;
  border: 1px solid black;
}
</style>
