import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const worksheet = {
  namespaced: true,
  state: {
    worksheetStatusColor: {
      NOT_PAID: "white--text",
      CUT: "white--text",
      HOLD: "white--text",
      PAID: "white--text",
      CANCELLED: "white--text",
    },
    worksheetStatusCardColor: {
      ALL: "white",
      NOT_PAID: "secondary darken-4",
      CUT: "orange lighten-2",
      HOLD: "orange darken-2",
      PAID: "success",
      CANCELLED: "red",
    },
    worksheetStatusText: {
      NOT_PAID: "ยังไม่จ่าย",
      CUT: "ตัดยอด",
      HOLD: "กำลังพิจารณา",
      PAID: "จ่ายแล้ว",
      CANCELLED: "ยกเลิก",
    },
    worksheetStatusAllText: {
      ALL:"ทั้งหมด",
      NOT_PAID: "ยังไม่จ่าย",
      CUT: "ตัดยอด",
      HOLD: "กำลังพิจารณา",
      PAID: "จ่ายแล้ว",
      CANCELLED: "ยกเลิก",
    },
  },
  getters: {
    // worksheetStatusTextList: (state) => Object.keys(state.worksheetStatusText),
    // worksheetStatusList: (state) =>
    //   Object.keys(
    //     Object.fromEntries(
    //       Object.entries(state.worksheetStatusText).map((a) => a.reverse())
    //     )
    //   ),
    worksheetStatusColor: (state) => (key) => state.worksheetStatusColor[key],
    worksheetStatusCardColor: (state) => (key) => state.worksheetStatusCardColor[key],
    worksheetStatusText: (state) => (key) => state.worksheetStatusText[key],
    worksheetStatusAllText: (state) => (key) => state.worksheetStatusAllText[key],
  },
  mutations: {
  },
  actions: {
  },
  modules: {},
};
