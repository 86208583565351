import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import { getLocalStorage } from "@/services/local_storage";
import systemRoute from "./system";
import userRoute from "./user";
import employeeRoute from "./employee";
import vehicleRoute from "./vehicle";
import vehicleDocsRoute from "./vehicleDocs";
import vehicleEvent from "./vehicleEvent";
import vehicleCheckList from "./vehicleCheckList";
import vehicleFuel from "./vehicleFuel";
import contractRoute from "./contract";
import employeeGroup from "./employeeGroup";
import VehiclePageWrapper from "@/views/vehicle/VehiclePageWrapper.vue";
import EmployeePageWrapper from "@/views/employee/EmployeePageWrapper.vue";
import ContractsAndJobsPageWrapper from "@/views/contract/ContractsAndJobsPageWrapper";
import WorksheepPageWrapper from "@/views/worksheet_summary_list/WorksheepPageWrapper";
import jobsRoute from "./jobs";
import assignMultipleJob from "./assignMultipleJob";
import contractRouteRoute from "./routes";
import worksheet from "./worksheet";
import CsvGpsViewVue from "@/views/test/CsvGpsView.vue";
//
Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  ...systemRoute,
  ...userRoute,
  {
    path: "/employee",
    name: "employee",
    component: EmployeePageWrapper,
    children: [...employeeRoute],
  },
  {
    path: "/vehicle",
    name: "vehicle",
    component: VehiclePageWrapper,
    children: [
      ...vehicleRoute,
      ...vehicleDocsRoute,
      ...vehicleEvent,
      ...vehicleCheckList,
      ...vehicleFuel,
      ...contractRoute,
    ],
  },
  {
    path: "/caj",
    name: "contractAndJob",
    component: ContractsAndJobsPageWrapper,
    children: [
      ...contractRouteRoute,
      ...jobsRoute,
      ...assignMultipleJob,
      ...employeeGroup,
    ],
  },
  {
    path: "/worksheet",
    name: "worksheet",
    component: WorksheepPageWrapper,
    children: [...worksheet],
  },
  {
    path: "/gps-csv",
    name: "gpsCSVFetch",
    component: CsvGpsViewVue,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: { name: "home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // checkForUpdates()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const hasData = getLocalStorage("ACCESS_TOKEN");

  if (requiresAuth && !hasData) {
    return next("/logout");
  }

  // console.log(to, from);
  // checkIfTokenNeedsRefresh()
  // store.commit(types.SUCCESS, null)
  // store.commit(types.ERROR, null)
  return next();
});

export default router;
