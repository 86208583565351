<template>
  <div class="text-center">
    <v-dialog v-model="show" width="500">
      <v-card>
        <v-card-title class="text-h5"> เปลี่ยนสถานะ </v-card-title>

        <v-card-text>
          <!-- status -->
          <div v-if="formData.status === 'P'">
            สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "กำลังนำดำเนินการ"
          </div>

          <div v-if="formData.status === 'I'">
            สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "ตรวจสอบ"
          </div>

          <div v-if="formData.status === 'S'">
            <p class="text-app-detail-normal">
              สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "สำเร็จ"
            </p>
            <v-form ref="form">
              <!-- <CustomTextInput
                v-model="formData.totalAmount"
                title="ค่าใช้จ่ายทั้งหมด (บาท)"
                type="number"
                placeholder=""
              /> -->
              <CustomDateTypingPickerFormInput
                title="วันที่ทำรายการ"
                v-model="formData.issueDate"
                :isRequired="false"
              />
              <CustomDateTypingPickerFormInput
                title="วันนัดหมายครั้งถัดไป"
                v-model="formData.appointmentDate"
                :isRequired="false"
              />
            </v-form>
          </div>

          <div v-if="formData.status === 'C'">
            <p class="text-app-detail-normal">
              สถานะของเหตุการณ์นี้จะถูกเปลี่ยนเป็นสถานะ "ยกเลิก"
            </p>
            <v-form ref="form">
              <CustomDateTypingPickerFormInput
                title="วันที่ทำรายการ"
                v-model="formData.issueDate"
                :isRequired="false"
              />
              <CustomTextareaImprove
                v-model="formData.cancelComment"
                title="เหตุผลในการยกเลิก"
              />
            </v-form>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import { getDateStringNow } from "@/services/appDate";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";

export default {
  name: "VehicleEventStatusDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    formDataKey: [
      "status",
      "totalAmount",
      "appointmentDate",
      "issueDate",
      "cancelComment",
    ],
    formData: {
      status: null,
      totalAmount: null,
      appointmentDate: null,
      issueDate: null,
      cancelComment: null,
    },
  }),
  methods: {
    resetFormData() {
      this.formData = {
        status: null,
        appointmentDate: null,
        issueDate: getDateStringNow(),
        cancelComment: null,
        totalAmount: null,
      };
    },
    setFormData(item) {
      console.log(item);
      for (let i = 0; i < this.formDataKey.length; i++) {
        this.formData[this.formDataKey[i]] = item[this.formDataKey[i]];
      }
    },
    open(item) {
      this.resetFormData();
      this.setFormData(item);
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      let _formData = _.clone(this.formData);
      this.resetFormData();
      this.resolve(_formData);
      this.show = false;
    },
    cancelStatus() {
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomDateTypingPickerFormInput,
    CustomTextareaImprove,
  },
};
</script>

<style scoped></style>
