<template>
  <CustomCardFormViewModern
    title="งานเดินรถ"
    :is-show-title="false"
    :is-add-title-margin="false"
  >
    <v-row>
      <v-col cols="4">
        <VehicleEventScatterChart
          :data="jobDistance"
          :title="'กำไรสุทธิ (บริษัท) / ระยะทาง'"
          :axis-x-label="'ระยะทางโดยเฉลี่ยต่อวัน'"
          :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
          :height="300"
        />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <VehicleEventScatterChart
          :data="jobDuration"
          :title="'กำไรสุทธิ (บริษัท) / ชั่วโมงทำงาน'"
          :axis-x-label="'ชั่วโมงทำงานโดยเฉลี่ยต่อวัน'"
          :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
          :height="300"
        />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <VehicleEventScatterChart
          :data="jobCount"
          :title="'กำไรสุทธิ (บริษัท) / จำนวนงาน'"
          :axis-x-label="'จำนวนงานโดยเฉลี่ยต่อวัน'"
          :axis-y-label="'กำไรสุทธิโดยเฉลี่ยต่อวัน'"
          :height="300"
        />
      </v-col>
    </v-row>
  </CustomCardFormViewModern>
</template>

<script>
import _ from "lodash";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import VehicleEventExpensesLineChart from "./VehicleEventExpensesLineChart.vue";
import VehicleEventProblemSbarChart from "./VehicleEventProblemSbarChart.vue";
import VehicleEventTypeSbarChart from "./VehicleEventTypeSbarChart.vue";
import VehicleEventScatterChart from "./VehicleEventScatterChart.vue";

export default {
  name: "VehicleJobInfoCard",
  components: {
    VehicleEventExpensesLineChart,
    CustomCardFormViewModern,
    VehicleEventProblemSbarChart,
    VehicleEventTypeSbarChart,
    VehicleEventScatterChart,
  },
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    this.processData();
  },
  data() {
    return {
      vehicleEventCount: null,
    };
  },
  watch: {
    data: function (newValue) {
      this.processData();
    },
  },
  methods: {
    processData() {
      this.vehicleEventCount = _.get(
        this.data,
        "events.vehicleEventCount",
        null
      );
    },
    processOptions(_data, keyField, xField, yField, xAnnoField, yAnnoField) {
      try {
        let _d = [];
        let vehicleMapId = [];
        const vehicleJobs = _.get(_data, keyField, []);
        //
        for (let i = 0; i < vehicleJobs.length; i++) {
          // let shortCassie = "";
          // if (!_.isNil(vehicleJobs[i]["cassieId"])) {
          //   shortCassie = vehicleJobs[i]["cassieId"].substring(0, 8);
          // }
          _d.push({
            name: `${vehicleJobs[i]["licenseNumber"]}`,
            data: [[vehicleJobs[i][xField], vehicleJobs[i][yField]]],
          });
          vehicleMapId.push(vehicleJobs[i]["vehicleId"]);
        }

        //
        return {
          data: _d,
          options: {
            annoYaxis: [
              {
                y: _.get(_data, yAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
            annoXaxis: [
              {
                x: _.get(_data, xAnnoField, 0),
                strokeDashArray: 5,
                borderWidth: 2,
                borderColor: "#AAB7B8",
              },
            ],
          },
          vehicleMapId: vehicleMapId,
        };
      } catch (error) {
        return { data: [], options: [] };
      }
    },
  },
  computed: {
    jobDistance() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "groupVehiclesByJobsAndEventsWeek",
          "normalizeDistance",
          "normalizeNetProfit",
          "vehicleDistanceJobAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
    jobDuration() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "groupVehiclesByJobsAndEventsWeek",
          "normalizeDuration",
          "normalizeNetProfit",
          "vehicleJobDurationAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
    jobCount() {
      if (!_.isNil(this.data)) {
        return this.processOptions(
          this.data,
          "groupVehiclesByJobsAndEventsWeek",
          "normalizeCountJob",
          "normalizeNetProfit",
          "vehicleJobCountJobAvg",
          "vehicleJobIncomePerRoundAvg"
        );
      }

      return {};
    },
  },
};
</script>

<style scoped>
.custom-text-inline-height {
  line-height: 1;
}
</style>
