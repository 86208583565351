<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    :value="open"
    :close-on-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="open = true">
        {{ monthVal }}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-date-picker
          :value="months"
          type="month"
          range
          locale="th"
          @input="inputHandler"
        ></v-date-picker>
        <div class="d-flex justify-end">
          <v-btn color="cancel" outlined class="mr-2" @click="cancel">
            ปิด
          </v-btn>
          <v-btn color="success" outlined @click="confirm"> ตกลง </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { getShortMonthNameHelper } from "@/services/appDate";
import _ from "lodash";
import moment from "moment";
export default {
  name: "CustomMonthPickerBtn",
  props: {
    value: [],
  },
  data: () => ({
    months: [],
    bkMonths: [],
    open: false,
  }),
  mounted() {
    this.months = _.clone(this.value);
    this.bkMonths = _.clone(this.value);
  },
  computed: {
    monthVal() {
      if (this.months.length === 0) {
        return "เดือน / ปี";
      } else if (this.months.length === 1) {
        return `${this.processTextMonth(this.months[0])}`;
      } else {
        return `${this.processTextMonth(
          this.months[0]
        )} - ${this.processTextMonth(this.months[this.months.length - 1])}`;
      }
    },
  },
  methods: {
    processTextMonth(m) {
      try {
        const mm = moment(m, "YYYY-MM");
        return `${getShortMonthNameHelper(mm.month())} ${mm.year() + 543}`;
      } catch (error) {
        return m;
      }
    },
    inputHandler(newVal) {
      this.months = newVal;
    },
    confirm() {
      this.open = false;
      this.months = this.months.sort(
        (a, b) => new moment(a, "YYYY-MM") - new moment(b, "YYYY-MM")
      );
      this.bkMonths = this.months;
      this.$emit("updateValue", this.months);
    },
    cancel() {
      this.open = false;
      this.months = this.bkMonths;
    },
  },
};
</script>
