<template>
  <div class="text-center">
    <v-dialog scrollable persistent v-model="show" width="800">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title> เปลี่ยนสถานะพนักงาน </v-card-title>
          <v-spacer></v-spacer
          ><v-btn
            v-if="currentEmpStatus !== null"
            icon
            @click.native="cancelStatus"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-toolbar
        >
        <v-card-text>
          <v-form ref="form">
            <!-- status -->
            <v-row no-gutters class="px-6 pt-6">
              <v-col cols="12">
                <span class="title-input"> สถานะพนักงาน </span>
                <span class="required-color"> * </span>
              </v-col>
              <v-row no-gutters class="px-0">
                <v-chip-group v-model="formData.status" mandatory column>
                  <v-chip
                    v-for="status in employeeStatusItems"
                    :key="status"
                    :value="status"
                    filter
                    outlined
                    :color="getStatusEmployeeColor(status)"
                  >
                    <span class="px-1">{{
                      getStatusEmployeeText(status)
                    }}</span>
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-row>
            <v-col cols="12" class="pt-6 px-3">
              <v-col class="pt-0 px-0" cols="12">
                <CustomTextareaImprove
                  v-model="formData.detail"
                  :hideDetail="true"
                  title="สาเหตุ"
                  :isRequired="false"
                ></CustomTextareaImprove>
              </v-col>
              <v-col class="px-0" cols="12">
                <CustomDateTypingPickerFormInput
                  placeholder="เลือก"
                  title="เวลาที่เปลี่ยนสถานะ (วัน/เดือน/ปี)"
                  v-model="formData.issueDate"
                  :isRequired="true"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                />
              </v-col>
            </v-col>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";

const EmployeeStatus = Object.freeze({
  APPLIED: "ขอสมัคร",
  REJECTED: "ไม่รับ",
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "EmployeeChangeStatusDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    currentEmpStatus: null,
    formData: {
      status: null,
      detail: null,
      issueDate: null,
    },

    employeeStatusItems: [
      "APPLIED",
      "REJECTED",
      "PROBATION",
      "ACTIVE",
      "RESIGNED",
      "TERMINATED",
      "FIRED",
    ],
  }),
  watch: {},
  methods: {
    getStatusEmployeeText(status) {
      switch (status) {
        case "APPLIED":
          return EmployeeStatus.APPLIED;
        case "REJECTED":
          return EmployeeStatus.REJECTED;
        case "PROBATION":
          return EmployeeStatus.PROBATION;
        case "ACTIVE":
          return EmployeeStatus.ACTIVE;
        case "RESIGNED":
          return EmployeeStatus.RESIGNED;
        case "TERMINATED":
          return EmployeeStatus.TERMINATED;
        case "FIRED":
          return EmployeeStatus.FIRED;
        default:
          return null;
      }
    },

    getStatusEmployeeColor(status) {
      switch (status) {
        case "APPLIED":
          return "subtitle darken-2";
        case "REJECTED":
          return "subtitle darken-4";
        case "PROBATION":
          return "primary";
        case "ACTIVE":
          return "success";
        case "RESIGNED":
          return "warning";
        case "TERMINATED":
          return "error lighten-1";
        case "FIRED":
          return "error darken-1";
        default:
          return null;
      }
    },
    resetFormData() {
      this.currentEmpStatus = null;
      this.formData = {
        status: null,
        detail: null,
        issueDate: null,
      };
    },
    setFormData() {
      this.currentEmpStatus = null;
      this.formData["status"] = null;
      this.formData["detail"] = null;
      this.formData["issueDate"] = null;
    },
    open(currentStatus) {
      this.resetFormData();
      this.setFormData();
      this.currentEmpStatus = currentStatus;
      if (!_.isNil(currentStatus)) {
        this.formData["status"] = currentStatus;
      }

      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    saveStatus() {
      if (this.$refs.form.validate() === true) {
        let _formData = _.clone(this.formData);
        this.resetFormData();
        this.resolve(_formData);
        this.show = false;
      }
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  watch: {
    show() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
  },
  components: {
    CustomTextareaImprove,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    CustomDateTypingPickerFormInput,
  },
};
</script>

<style scoped></style>
