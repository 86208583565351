<template>
  <div name="customSelects" :class="expendMargin ? 'px-3' : ''">
    <InputView
      :title="title"
      :is-required="isRequired"
      :bottom-margin="inputViewBottomMargin"
      :small="small"
    >
    </InputView>
    <v-text-field
      v-mask="'##:##'"
      :value="inputData"
      @input="handleInput"
      placeholder="ชช:นน"
      @blur="validateHandler($event)"
      :rules="rules"
      dense
      outlined
      :disabled="disabled"
      :background-color="getBackgroundColor()"
      :name="dataInputName"
      :hide-details="hideDetail"
      class="app-custom-input"
    >
      <!--   -->
      <template #label>
        <span v-if="label">
          {{ label
          }}<span class="required-color" v-if="isRequired && !disabled">
            *
          </span>
        </span>
      </template>
      <template v-slot:prepend-inner>
        <v-menu
          v-model="open"
          :close-on-content-click="false"
          transition="scale-transition"
          :min-width="locationStart ? `` : `auto`"
          :location="locationStart ? `start` : ``"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pa-1">mdi-clock-outline</v-icon>
          </template>

          <div class="v-date-time-widget-container">
            <v-time-picker
              format="24hr"
              v-model="timepicker"
              :landscape="$vuetify.breakpoint.mdAndUp"
              :disabled="disabled"
            >
            </v-time-picker>
            <v-row class="pt-3">
              <v-col>
                <p class="text-app-detail-large px-3">
                  {{ datetimePickerStatusText }}
                </p>
              </v-col>
              <v-col align="right">
                <v-btn :disabled="disabled" text @click="cancel()"
                  >ยกเลิก</v-btn
                >
                <v-btn
                  :disabled="disabled"
                  color="primary"
                  text
                  @click="confirm()"
                  >ยันยัน</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import moment from "moment";
import { mask } from "vue-the-mask";
import InputView from "./InputView.vue";

export default {
  name: "CustomTimePickerInput",
  directives: { mask },
  components: { InputView },
  props: {
    value: {},
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    requiredText: {
      type: String,
      default: "ชั่วโมง:นาที",
    },
    title: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locationStart: {
      type: Boolean,
      default: false,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inputViewBottomMargin: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    returnEmptyValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    inputData: null,
    formData: null,
    //
    open: false,
    timepicker: null,
    datetimePickerStatusText: null,
    rules: [],
  }),

  watch: {
    value: {
      immediate: false,
      handler(newValue) {
        if (newValue !== this.formData) {
          this.setToTextField(newValue);
          if (!_.isNil(newValue)) {
            this.timepicker = null;
          }
        }
      },
    },
  },

  mounted() {
    if (this.isRequired) {
      this.setRequireToRules();
    }
    if (!_.isNil(this.value)) {
      this.setToTextField(this.value);
    }
  },

  methods: {
    confirm() {
      try {
        if (!_.isNil(this.timepicker)) {
          this.formData = this.parseInputDate(this.timepicker);
          this.decodeDate();
          this.open = false;
        } else {
          this.datetimePickerStatusText = "ระบุค่าให้ถูกต้อง";
        }
      } catch (error) {
        console.log(error);
      }
    },
    setRequireToRules() {
      if (this.isRequired) {
        this.rules = [(v) => !!v || this.requiredText];
      }
    },
    handleInput(newValue) {
      this.formData = newValue;
    },
    validateHandler() {
      this.decodeDate();
    },
    parseDate(newValue) {
      const _d = moment(newValue, "DD/MM/YYYY HH:mm");
      return _d.format("YYYY-MM-DDTHH:mm:ss");
    },
    //
    parseInputDate(newValue) {
      const currentDate = `${moment().format("DD/MM/YYYY")}T${newValue}`;
      const _d = moment(currentDate, "YYYY-MM-DDTHH:mm");
      return _d.format("HH:mm");
    },

    decodeDate() {
      if (this.formData === null) {
        if (!this.isRequired || this.returnEmptyValue) {
          this.$emit("input", null);
          this.setDateTimeToPicker();
        }
        this.setRequireToRules();
        return;
      } else if (this.formData.length === 0) {
        if (!this.isRequired || this.returnEmptyValue) {
          this.$emit("input", null);
          this.setDateTimeToPicker();
        }
        this.setRequireToRules();
        return;
      }

      // console.log(this.formData);
      let currentDate = `${moment().format("DD/MM/YYYY")} ${this.formData}`;
      try {
        const isValidate = moment(
          currentDate,
          "DD/MM/YYYY HH:mm",
          true
        ).isValid();
        if (!isValidate) {
          throw "PARSE_DATE_FAIL";
        } else {
          this.$emit("input", this.parseDate(currentDate));
          this.rules = [];
          this.setRequireToRules();
          this.setDateTimeToPicker();
        }
      } catch (error) {
        this.rules = [this.requiredText];
        console.log(error);
      }
    },
    setToTextField(newValue) {
      if (_.isNil(newValue)) {
        this.inputData = null;
        this.formData = null;
        this.setRequireToRules();
        return;
      }

      const isOkDate = moment(newValue, "YYYY-MM-DDTHH:mm:ss", true).isValid();

      if (isOkDate) {
        const _d = moment(newValue);
        this.inputData = _d.format("HH:mm");
        this.formData = _d.format("HH:mm");
      }
    },
    setDateTimeToPicker() {
      if (!_.isNil(this.formData)) {
        const _d = moment(this.formData, "HH:mm");
        if (_d.isValid()) {
          this.timepicker = _d.format("HH:mm");
        } else {
          this.timepicker = null;
        }
      }
    },
    clearToPicker() {
      this.open = false;
    },
    cancel() {
      this.clearToPicker();
    },
    getBackgroundColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        if (this.isDark) {
          return "#ffffff";
        }
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}
</style>
