import WorksheetSummaryListExcelView from "@/views/worksheet_summary_list/WorksheetSummaryListExcelView.vue";
import EvidenceListView from "@/views/worksheet_summary_list/EvidenceListView.vue";
import WorksheetOverviewDashboardView from "@/views/worksheet_summary_list/worksheet_dashboard/WorksheetOverviewDashboardView.vue";
import WorksheetPaymentOverAllDashboardView from "@/views/worksheet_summary_list/worksheet_overall_dashboard/WorksheetPaymentOverAllDashboardView.vue";
export default [
	{
		path: "/worksheet/list",
		name: "worksheetList",
		component: WorksheetSummaryListExcelView,
		meta: { requiresAuth: true, topic: "worksheet" },
	},
	{
		path: "/worksheet/evidence-list",
		name: "evidenceList",
		component: EvidenceListView,
		meta: { requiresAuth: true, topic: "evidence" },
	},
	{
		path: "/worksheet/dashboard",
		name: "worksheetOverview",
		component: WorksheetOverviewDashboardView,
		meta: { requiresAuth: true, topic: "worksheetOverviewDashboard" },
	},
	{
		path: "/worksheet/proportion-dashboard",
		name: "worksheetOverallPaymentDashboard",
		component: WorksheetPaymentOverAllDashboardView,
		meta: { requiresAuth: true, topic: "worksheetOverallPaymentDashboard" },
	},
];
