import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const vehicle_checklist = {
  namespaced: true,
  state: {
    vehicleCheckListStatusColor: {
      CHECKING: "white--text",
      SENT: "white--text",
      CLOSED: "white--text",
      CANCELLED: "white--text",
    },
    vehicleCheckListStatusCardColor: {
      CHECKING: "orange",
      SENT: "primary",
      CLOSED: "success",
      CANCELLED: "red",
    },
    vehicleCheckListItemStatusCardColor: {
      NOT_CHECKED: "subtitle",
      PASSED: "success",
      FAILED: "red",
    },
    vehicleCheckListStatusText: {
      CHECKING: "กำลังตรวจสอบ",
      SENT: "ส่งแล้ว",
      CLOSED: "ปิด",
      CANCELLED: "ถูกยกเลิก",
    },
    vehicleCheckListStatusAllText: {
      ALL: "ทั้งหมด",
      CHECKING: "กำลังตรวจสอบ",
      SENT: "ส่งแล้ว",
      CLOSED: "ปิด",
      CANCELLED: "ถูกยกเลิก",
    },

    vehicleCheckListItemStatusText: {
      NOT_CHECKED: "ยังไม่ตรวจ",
      PASSED: "ผ่าน",
      FAILED: "ไม่ผ่าน",
    },
  },
  getters: {
    // vehicleCheckListStatusTextList: (state) => Object.keys(state.vehicleCheckListStatusText),
    vehicleCheckListStatusObj: (state) => state.vehicleCheckListStatusText,
    // vehicleCheckListStatusList: (state) =>
    //   Object.keys(
    //     Object.fromEntries(
    //       Object.entries(state.vehicleCheckListStatusText).map((a) => a.reverse())
    //     )
    //   ),
    vehicleCheckListStatusColor: (state) => (key) =>
      state.vehicleCheckListStatusColor[key],
    vehicleCheckListStatusCardColor: (state) => (key) =>
      state.vehicleCheckListStatusCardColor[key],
    vehicleCheckListItemStatusCardColor: (state) => (key) =>
      state.vehicleCheckListItemStatusCardColor[key],
    vehicleCheckListStatusText: (state) => (key) =>
      state.vehicleCheckListStatusText[key],
    vehicleCheckListStatusAllText: (state) => (key) =>
      state.vehicleCheckListStatusAllText[key],
    vehicleCheckListItemStatusText: (state) => (key) =>
      state.vehicleCheckListItemStatusText[key],
  },
  mutations: {},
  actions: {},
  modules: {},
};
