<template>
  <v-container fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการใบลางาน</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteUserSearching
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="AppAutoCompleteUserSearchingRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>

            <TimeOffFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="employeeDataList"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>
            <v-divider class="mx-3 mb-6" vertical></v-divider>
            <TimeOffFilterStatus
              :key="componentRefreshCounter"
              :value="statusFilterValue"
              @input="processStatus"
            />
            <v-spacer></v-spacer>
            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:[`item.createdDate`]="{ item }">
          <div>
            {{
              dateStringToDateFormat(item.createdDate, {
                returnThai: false,
                type: "datetime",
              }) || "-"
            }}
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-avatar>
              <v-img
                :src="
                  item.profileImage === null
                    ? defaultPFImage
                    : item.profileImage
                "
              >
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-app-title font-weight-bold">
                <a
                  v-if="isPermissionCanEdit"
                  class="text-decoration-none"
                  @click="editTimeOffAction(item)"
                >
                  {{ item.dpfullName }}
                </a>
                <span v-else> {{ item.dpfullName }}</span>
              </v-list-item-title>
              <span class="text-app-normal">{{
                item.email === null ? "-" : `${item.email}`
              }}</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.phoneNumber`]="{ item }">
          <div v-if="item.phoneNumber.length !== 0">
            <div
              class="d-block"
              v-for="(tel, idx) in item.phoneNumber"
              :key="'phone_row' + idx"
            >
              <span class="text-app-title"> {{ tel }} </span>
            </div>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.empId`]="{ item }">
          <span class="text-app-title"> {{ item.empId }} </span>
        </template>

        <template v-slot:[`item.position`]="{ item }">
          <span class="text-app-title">
            {{ item.position }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div
            v-if="item.status !== null"
            :class="
              item.status == `APPROVED`
                ? `${empTimeOffStatusTextColor(item.status)} text--darken-2`
                : empTimeOffStatusTextColor(item.status)
            "
            dark
          >
            <span class="text-app-title font-weight-bold">
              {{ empTimeOffStatusText(item.status) }}
            </span>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.issueDate`]="{ item }">
          <div>
            <span class="text-app-normal">เริ่มลา:</span>
            <span class="font-weight-bold">
              {{
                dateStringToDateFormat(item.startDate, {
                  returnThai: false,
                  type: "date",
                }) || "-"
              }}
            </span>
          </div>
          <div>
            <span class="text-app-normal">ลาถึง:</span>
            <span class="font-weight-bold">
              {{
                dateStringToDateFormat(item.endDate, {
                  returnThai: false,
                  type: "date",
                }) || "-"
              }}
            </span>
          </div>
        </template>

        <!-- <template v-slot:[`item.endDate`]="{ item }">
          <div>
            {{
              dateStringToDateFormat(item.endDate, {
                returnThai: false,
                type: "date",
              }) || "-"
            }}
          </div>
        </template> -->

        <template v-slot:[`item.amount`]="{ item }">
          <div class="font-weight-bold">
            {{ item.amount || "-" }}
            <span v-if="item.amount">วัน</span>
            {{
              `
              ${item.timeType == "FIRST_HALF" ? "(เช้า)" : ""}
              ${item.timeType == "SECOND_HALF" ? "(บ่าย)" : ""}
              `
            }}
          </div>
        </template>

        <template v-slot:[`item.note`]="{ item }">
          <div class="font-weight-bold">
            {{ item.timeoffType }}
          </div>
          <div>
            <span class="time-off-note">{{
              item.note || item.detail ? item.detail + item.note : "-"
            }}</span>
          </div>
        </template>
      </v-data-table>
    </div>
    <EmployeeTimeOffApprovalDialog ref="timeOffApprovalDialog" />
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";
import TimeOffFilterGroup from "@/views/employee/components/TimeOffFilterGroup.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import {
  getEmployeeTimeOffListService,
  putEmployeeTimeOffService,
} from "@/services/api/employee";
import { appDecodeSingleURIComponentHelper } from "@/services/appStringHelpper";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import { mapGetters } from "vuex";
import defaultImage from "@/assets/user.png";
import { processPermission } from "@/services/permissions";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import EmployeeTimeOffApprovalDialog from "../components/EmployeeTimeOffApprovalDialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import TimeOffFilterStatus from "@/views/employee/components/TimeOffFilterStatus.vue";

export default {
  name: "EmployeeTimeOffListView",
  components: {
    AppDialogConfirm,
    PrimaryButton,
    NormalButton,
    SortButton,
    TimeOffFilterGroup,
    AppAutoCompleteUserSearching,
    AppPageNotFound,
    TimeOffFilterStatus,
    EmployeeTimeOffApprovalDialog,
  },
  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    statusFilterValue: "all",
    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
      {
        text: "วันที่แก้ไขข้อมูล เก่าสุด-ล่าสุด",
        value: "updateddate-asc",
      },
      {
        text: "วันที่แก้ไขข้อมูล ล่าสุด-เก่าสุด",
        value: "updateddate-desc",
      },
      {
        text: "ชื่อ-นามสกุล เรียงจาก A-Z",
        value: "name-asc",
      },
      {
        text: "ชื่อ-นามสกุล เรียงจาก Z-A",
        value: "name-desc",
      },
      {
        text: "รหัสพนักงาน เรียงจาก A-Z",
        value: "empid-asc",
      },
      {
        text: "รหัสพนักงาน เรียงจาก Z-A",
        value: "empid-desc",
      },
    ],
    defaultPFImage: defaultImage,
    tableLoading: false,

    serverItemsLength: 0,
    employeeDataList: [],

    queryFilterList: {},
    queryStringSearch: "", // combin filter and search text
    querySearchAndFilterList: {}, // combine filter and search
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    sortVal: null,
    isShowMenu: false,
    //
    autoCompleteLoading: false,
    autoCompleteList: [],
    searchEmployeeList: null,
    searchCountTimer: undefined,
    //
    permList: {
      admin_all: false,
      user_list: false,
      user_info: false,
      user_info_fin: false,
      user_info_crud: false,
    },
    editTextBtn: "แก้ไขข้อมูล",
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    //
  }),

  mounted() {
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.processRouterQuery(this.$route.query);
    }
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },

    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "timeoffId":
                this.getSearchItemById({ timeoffId: _queryRoute[key] });
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    async editTimeOffAction(employee) {
      const editData = await this.$refs.timeOffApprovalDialog.open(employee);
      this.tableLoading = true;
      if (!_.isNil(editData)) {
        let respData = await putEmployeeTimeOffService(
          this.$store,
          employee._id,
          editData
        );
        //
        if (respData["code"] === 200) {
          await this.getData();
        } else {
          // insert fail
          this.tableLoading = false;
        }
      } else {
        this.tableLoading = false;
      }
    },

    processStatus(newStatusVal) {
      this.statusFilterValue = newStatusVal;
      if (_.isNil(this.statusFilterValue)) {
        // remove from list
        delete this.querySearchAndFilterList["status"];
        return;
      }
      this.querySearchAndFilterList["status"] =
        this.statusFilterValue.toLowerCase();

      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;

      this.setQueryStringSearch(this.querySearchAndFilterList);
      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    // === end action button of table
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          this.queryOptionsTable["page"] = 1;
          break;
        case "limit":
          this.queryOptionsTable["limit"] = value;
          this.queryOptionsTable["page"] = 1;
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },

    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };

      this.querySearchAndFilterList["page"] = 1;
      this.queryOptionsTable["page"] = 1;

      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //

      console.log("_searchingParameter", _searchingParameter);
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["empid"]; // id
      delete this.querySearchAndFilterList["text"]; // text
      delete this.querySearchAndFilterList["idname"];
      delete this.querySearchAndFilterList["timeoffId"];

      let _queryParameter = {};

      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["empId"])) {
          _queryParameter["empid"] = _searchingParameter["key"];
          _queryParameter["text"] = _searchingParameter["value"];
        } else if (!_.isNil(_searchingParameter["timeoffId"])) {
          _queryParameter["text"] = _searchingParameter["timeoffId"];
          _queryParameter["timeoffId"] = _searchingParameter["timeoffId"];
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter["value"];
            _queryParameter["idname"] = _searchingParameter["value"];
          }
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    async getSearchItemById(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["text"]; // text
      delete this.querySearchAndFilterList["timeoffId"];

      let _queryParameter = {};

      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["timeoffId"])) {
          _queryParameter["text"] = _searchingParameter["timeoffId"];
          _queryParameter["timeoffId"] = _searchingParameter["timeoffId"];
          // _searchingParameter
          this.processSearchingParams(_queryParameter);
        }
      }
      await this.getData();
      if (
        !_.isNil(this.employeeDataList) &&
        this.employeeDataList.length === 1
      ) {
        this.editTimeOffAction(this.employeeDataList[0]);
      }
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    //
    //  end method scope
    //
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.querySearchAndFilterList = {};
      this.querySearchAndFilterList = { status: "all" };
      this.processSearchingParams();
      this.getData();
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        const getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (
          this.permList.admin_all === true ||
          this.permList.user_timeoff === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },
    async getData() {
      this.tableLoading = true;
      this.employeeDataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();
      let responseData = await getEmployeeTimeOffListService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData.code == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let userList = responseData.data["paginatedResults"];
        if (userList.length > 0) {
          userList.forEach((item, idx) => {
            // userList[idx]["profileImage"] = item["profileImage"];
            userList[idx][
              "name"
            ] = `${item["firstName"]["th"]} ${item["lastName"]["th"]}`;
            // process position
            if (userList[idx]["position"] === null) {
              userList[idx]["position"] = { nameEn: "-" };
            }

            userList[idx]["email"] = item["email"];
            // let startDate = new Date();
            // let endDate = new Date();
            // endDate.setDate(startDate.getDate() + 3);

            // let dd = String(today.getDate()).padStart(2, "0");
            // let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            // let yyyy = today.getFullYear();

            // today = mm + "/" + dd + "/" + yyyy;

            userList[idx]["startDate"] = item["startDate"];
            userList[idx]["endDate"] = item["endDate"];

            // let diff_days = parseInt(
            //   (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
            // );

            userList[idx]["amount"] = item["period"];
            userList[idx]["timeoffType"] = item["timeoffType"];
            userList[idx]["note"] = item["note"];
            userList[idx]["status"] = item["status"];

            userList[idx]["dpfullName"] = `${
              userList[idx].salutation === null
                ? ""
                : userList[idx].salutation.th
            }${
              userList[idx].firstName === null ? "" : userList[idx].firstName.th
            } ${
              userList[idx].lastName === null ? "" : userList[idx].lastName.th
            } ${
              item.nickName !== null
                ? item.nickName.th !== null
                  ? `(${item.nickName.th})`
                  : "-"
                : "-"
            }`;

            // userList[idx][
            //   "name"
            // ] = `${item["firstName"]["en"]} ${item["lastName"]["en"]}`;
          });
        }
        this.employeeDataList = userList;
      } else {
        this.employeeDataList = [];
      }
      this.tableLoading = false;
    },
  },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "employee/vehicleStatusColor",
      empStatusColor: "employee/empStatusColor",
      vehicleStatusText: "employee/vehicleStatusText",
      empStatusText: "employee/empStatusText",
      empTimeOffStatusColor: "employee/empTimeOffStatusColor",
      empTimeOffStatusTextColor: "employee/empTimeOffStatusTextColor",
      empTimeOffStatusText: "employee/empTimeOffStatusText",
    }),
    tableHeaders() {
      let defaultdata = [
        {
          text: "วันที่สร้าง",
          align: "start",
          sortable: false,
          value: "createdDate",
        },
        {
          text: "ชื่อ-นามสกุล ",
          align: "start",
          sortable: false,
          value: "name",
          width: "20%",
        },
        { text: "รหัสพนักงาน", value: "empId", sortable: false, align: "left" },
        {
          text: "เบอร์ติดต่อ",
          value: "phoneNumber",
          sortable: false,
          align: "left",
        },
        { text: "ตำแหน่ง", value: "position", sortable: false, align: "left" },
        {
          text: "วันที่ลา",
          value: "issueDate",
          sortable: false,
          align: "left",
        },
        // {
        //   text: "ลาถึงวันที่",
        //   value: "endDate",
        //   sortable: false,
        //   align: "left",
        // },
        {
          text: "จำนวนวัน",
          value: "amount",
          sortable: false,
          align: "left",
        },
        {
          text: "หมายเหตุ",
          value: "note",
          sortable: false,
          align: "left",
        },
        {
          text: "สถานะใบลา",
          value: "status",
          sortable: false,
          align: "left",
        },
        // { text: "อายุ", value: "age", sortable: false, align: "center" },
        // {
        //   text: "การใช้รถ",
        //   value: "status",
        //   align: "center",
        //   sortable: false,
        // },
      ];
      //
      // if (this.isPermissionCanEdit) {
      //   defaultdata.push({
      //     text: "",
      //     value: "actions",
      //     sortable: false,
      //     align: "center",
      //   });
      // }
      //
      return defaultdata;
    },
  },
};
</script>
<style scoped>
.time-off-note {
  width: 150px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* .time-off-note:hover {
  white-space: normal;
} */
</style>
