<template>
  <v-autocomplete
    class="app-custom-input"
    :items="driverList"
    :loading="loading"
    v-model="modelVal"
    @change="itemChange"
    :search-input.sync="searchingAction"
    placeholder="ค้นหาจากรหัสพนักงาน, ชื่อ, นามสกุล, ชื่อเล่น"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    item-value="key"
    item-text="value"
    return-object
    filled
    dense
    hide-no-data
    hide-selected
    hide-details
    flat
    height="44"
    outlined
    auto-select-first
    loader-height="4"
  >
    <!-- @keyup="enterToReturnEmit" -->
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
// import { getEmployeeBySeachingService } from "@/services/api/employee";
import { getSearchDriverService } from "@/services/api/assign_multiple_job";

export default {
  name: "AssignMultipleJobDriverSearchInput",
  props: {
    tableId: {
      type: String,
      required: true,
    },
  },
  mounted() {},
  data: () => ({
    modelVal: undefined,
    driverList: [],
    loading: false,
    searchingAction: "",
    currentSearchText: "",
    searchCountTimer: undefined,
    firstInit: false,
    selectFromListChecker: false,
  }),

  watch: {
    searchingAction: {
      immediate: false,
      handler: function (val) {
        if (!_.isNil(val) && this.firstInit && !this.selectFromListChecker) {
          if (val.length > 2) {
            clearTimeout(this.searchCountTimer);
            this.loading = true;
            this.searchCountTimer = setTimeout(() => {
              this.callApi(val); // call api function
            }, 1500);
          }
        }
        this.firstInit = true;
        this.selectFromListChecker = false;
      },
    },
  },

  methods: {
    async callApi(val) {
      const queryString = new URLSearchParams({
        id: this.tableId,
        text: val,
      }).toString();

      const responseData = await getSearchDriverService(
        this.$store,
        queryString
      );
      if (responseData["code"] === 200) {
        this.driverList = responseData["data"];
      } else {
        this.driverList = [];
      }
      this.loading = false;
    },

    itemChange() {
      this.selectFromListChecker = true;
      this.$emit("search-item-change", this.modelVal);
      this.$nextTick(() => {
        this.seacrhingAction = "";
        this.modelVal = null;
      });
    },
  },
};
</script>
