<template>
  <div name="customSelects" :class="expendMargin ? 'px-3' : ''">
    <InputView :title="title" :isRequired="isRequired">
      <v-select
        v-model="valueInput"
        class="app-custom-input custom-placeholer-color"
        :items="items"
        :placeholder="placeholder"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        :rules="rules"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        dense
        outlined
        hide-no-data
      ></v-select>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
export default {
  name: "customSelectsArrOfObj",

  components: {
    InputView,
  },

  props: {
    value: {}, // for v-model
    placeholder: {
      type: String,
      default: "เลือก",
    },
    title: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "value",
    },
    itemValue: {
      type: String,
      default: "key",
    },
    returnObject: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
  components: { InputView },
};
</script>
<style></style>
