import VehicleListView from "../views/vehicle/VehicleList.vue";
// import VehicleManageViewModern from "@/views/vehicle/VehicleManageViewModern.vue";
// import VehicleManageView from "@/views/vehicle/VehicleManageView.vue";
import VehicleCreateView from "@/views/vehicle/vehicle_create_edit/VehicleCreateView.vue";
import VehicleEditView from "@/views/vehicle/vehicle_create_edit/VehicleEditView.vue";
import VehicleOverviewDashboardView from "@/views/vehicle/vehicle_dashboard/VehicleOverviewDashboardView.vue";
import VehicleDashboardView from "@/views/vehicle/vehicle_dashboard/VehicleDashboardView.vue";

export default [
  {
    path: "/vehicle/list",
    name: "vehicleList",
    component: VehicleListView,
    meta: { requiresAuth: true, topic: "vehicle" },
  },
  //
  {
    path: "/vehicle/create",
    name: "vehicleAdd",
    component: VehicleCreateView,
    meta: { requiresAuth: true, topic: "vehicle" },
  },
  {
    path: "/vehicle/edit/:id",
    name: "vehicleEdit",
    component: VehicleEditView,
    meta: { requiresAuth: true, topic: "vehicle" },
  },

  {
    path: "/vehicle/dashboard_personal/:id",
    name: "vehicleDashboard",
    component: VehicleDashboardView,
    meta: { requiresAuth: true, topic: "vehicle" },
  },

  {
    path: "/vehicle/dashboard",
    name: "vehicleOverview",
    component: VehicleOverviewDashboardView,
    meta: { requiresAuth: true, topic: "vehicleOverviewDashboard" },
  },
  // Modern
  // {
  //   path: "/vehicle-new-modern",
  //   name: "vehicleAdd",
  //   component: VehicleManageViewModern,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/vehicle-edit-modern/:id",
  //   name: "vehicleEdit",
  //   component: VehicleManageViewModern,
  //   meta: { requiresAuth: true },
  // },
];
