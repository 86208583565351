const ProcessSelectListFormatHelpper = (processList) => {
  let _items = [];
  for (const [key, value] of Object.entries(processList)) {
    _items.push({
      key: key,
      value: value,
    });
  }
  return _items;
};

module.exports = {
  ProcessSelectListFormatHelpper,
};
