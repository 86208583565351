<template>
  <div name="CustomAutocomplete" :class="expendMargin ? 'px-3' : ''">
    <InputView :title="title" :isRequired="isRequired">
      <v-autocomplete
        class="app-custom-input custom-placeholer-color"
        outlined
        :hide-no-data="noDataText !== '' ? false : true"
        :hide-details="hideDetails"
        v-model="valueInput"
        :items="items"
        :placeholder="placeholder"
        :disabled="disabled"
        :background-color="getBackgrounddColor()"
        :rules="_rules"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        :loading="loading"
        :no-data-text="noDataText"
        :readonly="readonly"
        :name="dataInputName"
        :label="label"
        :dense="dense"
      >
      </v-autocomplete>
    </InputView>
  </div>
</template>
<script>
import InputView from "./InputView.vue";
import _ from "lodash";

export default {
  name: "CustomAutocompleteBase",

  components: {
    InputView,
  },

  props: {
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isCassieSub: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      // <select> text </select>
      type: String,
      default: "value",
    },
    itemValue: {
      // <select value="{{key}}">
      type: String,
      default: "key",
    },
    returnObject: {
      default: true,
      type: Boolean,
    },
    noDataText: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dataInputName: {
      type: String,
      default: "",
    },
    expendMargin: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: true,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, undefined],
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("on-change", val);
      },
    },
    _rules: {
      get() {
        let r = _.clone(this.rules);
        if (this.isRequired) {
          r.push((v) => !!v || "จำเป็นต้องระบุ");
        }
        return r;
      },
      set(val) {},
    },
  },
  methods: {
    getBackgrounddColor() {
      if (this.disabled) {
        return this.$vuetify.theme.themes.light.backgroudDisableInputView;
      } else {
        if (this.isDark) {
          return "#ffffff";
        }
        return this.$vuetify.theme.themes.light.backgroundInputView;
      }
    },
  },
};
</script>

<style scope>
.theme--light.v-select .v-select__selections {
  color: rgb(86, 82, 77) !important;
  font-size: 14px;
}
.text-select {
  font-size: 13px;
}
</style>
